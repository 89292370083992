import Vue from 'vue';
import { orderBy } from 'lodash';

/**
 * Utility functions for calculating the last week in the weekly calendar view.
 *
 * @module client/js/utils/weekly-calendar-view-restriction-functions/last-week-calendar-view-restriction-functions
 */

/**
 * Get the last week of the current year.
 */
function lastWeekOfCurrentYear({ getNumberOfWeeksInYear, getYearWeek }) {
    const currentYear = Vue.moment().year();
    const numberOfWeeksInYear = getNumberOfWeeksInYear(currentYear);

    return getYearWeek({
        year: currentYear,
        weekNumber: numberOfWeeksInYear,
    });
}

/**
 * Get the week which is X weeks following the current week, where X is defined
 * by the limitationPeriod config.
 */
function limitationPeriod({ getWeekByDate, limitationPeriodConfig }) {
    const offset = limitationPeriodConfig;
    const rightPeriodBorder = Vue.moment().add(offset * 7, 'days');
    return getWeekByDate(rightPeriodBorder);
}

/**
 * Get the week of the end date of the latest campaign.
 */
function weekOfEndDateOfLatestCampaign({
    getWeekByDate,
    getNumberOfWeeksInYear,
    getYearWeek,
    campaigns,
}) {
    const orderedCampaignsByEndDate = orderBy(campaigns, 'endDate', 'desc');

    if (orderedCampaignsByEndDate.length) {
        const { endDate } = orderedCampaignsByEndDate[0];
        return getWeekByDate(endDate);
    }

    // Default to the last week of the current year where there are no campaigns.
    return lastWeekOfCurrentYear({ getNumberOfWeeksInYear, getYearWeek });
}

/**
 * Get the last week of the following year.
 */
function lastWeekOfFollowingYear({ getYearWeek, getNumberOfWeeksInYear }) {
    const followingYear = Vue.moment().year() + 1;
    const numberOfWeeksInFollowingYear = getNumberOfWeeksInYear(followingYear);

    return getYearWeek({
        year: followingYear,
        weekNumber: numberOfWeeksInFollowingYear,
    });
}

export default {
    limitationPeriod,
    lastWeekOfCurrentYear,
    weekOfEndDateOfLatestCampaign,
    lastWeekOfFollowingYear,
};
