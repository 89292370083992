import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VLayout, [_c("p", [_vm._v(_vm._s(_vm.data.title))])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }