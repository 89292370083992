var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-pair" },
    [
      _c(
        "vuex-date-picker",
        _vm._b(
          {
            ref: "fromDate",
            staticClass:
              "date-pair__date-picker date-pair__date-picker--from-date",
            attrs: {
              "vuex-module": _vm.vuexModule,
              namespace: _vm.namespace,
              placeholder: _vm._f("toSentenceCase")(
                _vm.$t("general.labels.from")
              ),
              "max-date": _vm.toValue,
              context: _vm.context,
              disabled: _vm.disabled,
            },
          },
          "vuex-date-picker",
          _vm.from,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "vuex-date-picker",
        _vm._b(
          {
            ref: "toDate",
            staticClass:
              "date-pair__date-picker date-pair__date-picker--to-date",
            attrs: {
              "vuex-module": _vm.vuexModule,
              namespace: _vm.namespace,
              placeholder: _vm._f("toSentenceCase")(
                _vm.$t("general.labels.to")
              ),
              "min-date": _vm.fromValue,
              context: _vm.context,
              disabled: _vm.disabled,
            },
          },
          "vuex-date-picker",
          _vm.to,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }