import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.getNotifications, function (notification, index) {
      return _c(
        VSnackbar,
        {
          key: index + notification.message,
          attrs: {
            value: true,
            timeout: notification.popupTimeout || _vm.popupTimeout,
            color: notification.severity || "success",
            bottom: "",
          },
        },
        [_vm._v("\n        " + _vm._s(notification.message) + "\n    ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }