import tabNames from '@enums/tab-names';
import routeNames from '@enums/route-names';
import promotionVersionReasons from '@enums/promotion-version-reasons';
import { includes, isNil } from 'lodash';
import store from '@/js/store';
import commonGuardMap from '@/js/pages/common-guard-map';

const guardsMap = {
    ...commonGuardMap(tabNames.planning),

    async [routeNames.promotionTabView](to, from, next) {
        const { promotionId } = to.params;
        const selectedPromotion = store.getters['promotions/selectedPromotion'];
        if (!selectedPromotion || selectedPromotion._id !== promotionId) {
            const promotion = store.getters['promotions/getPromotionById'](promotionId);

            if (!promotion) {
                // If the promotion cannot be found, then either it does not exist or the user does not have access
                // to it, so redirect to the 404 page.
                next(`/error/404`);
            } else {
                const { scenarioId } = promotion;
                await store.dispatch('scenarios/setSelectedScenarioId', scenarioId);
                await store.dispatch('promotions/setSelectedPromotion', { promotionId });

                // store promotion version when promotion is opened
                await store.dispatch('promotionVersions/createPromotionVersion', {
                    reason: promotionVersionReasons.automaticCreation,
                    promotionId,
                });
            }
        }

        next();
    },

    async [routeNames.scenarioView](to, from, next) {
        let hasAccess;

        await store.dispatch('scenarios/clearSelectedScenario');
        const { promotionId, subCampaignId } = to.params;
        let { scenarioId } = to.params;

        // if the scenario was not passed explicitly, infer it from the promotion
        if (isNil(scenarioId)) {
            const promotion = store.getters['promotions/getPromotionById'](promotionId);
            scenarioId = promotion.scenarioId;
        }

        const selectedScenario = store.getters['scenarios/getScenarioById']({
            _id: scenarioId,
            usePluralResourceName: true,
        });

        const subCampaign = store.getters['subCampaigns/getSubCampaignById']({
            _id: subCampaignId,
            usePluralResourceName: true,
        });

        if (store.state.subCampaigns.selectedSubCampaignId !== subCampaignId) {
            // clear promotions in state before fetching them again in setSelectedSubCampaign
            await Promise.all([
                store.dispatch('promotions/resetPromotions'),
                store.dispatch('promotions/resetPromotionsInView'),
            ]);
            await store.dispatch('subCampaigns/setSelectedSubCampaign', subCampaign);
        }

        if (selectedScenario) {
            const userCategories = store.getters['context/userCategoryKeys'];
            hasAccess = selectedScenario.categories.some(category => {
                return includes(userCategories, category);
            });

            await store.dispatch('scenarios/setSelectedScenarioId', scenarioId);
        }
        if (!hasAccess || !selectedScenario) {
            await store.dispatch('scenarios/clearSelectedScenario');
            // If the scenario cannot be found, then either it does not exist or the user does not have access
            // to it, so redirect to the 404 page.
            next(`/error/404`);
        }

        next();
    },
};

export default guardsMap;
