var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: ["icon-btn", _vm.iconBtnClassObject, _vm.verticalAlignmentClass],
      attrs: { "data-id-e2e": _vm.dataIdE2e, disabled: _vm.isDisabled },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick($event)
        },
      },
    },
    [
      _c("icon", {
        staticClass: "icon-btn__icon",
        attrs: {
          "icon-name": _vm.icon,
          "material-icon-class": _vm.materialIconClass,
          disabled: _vm.isDisabled,
        },
      }),
      _vm._v(" "),
      _vm.btnText
        ? _c("span", { staticClass: "icon-btn__text font-weight-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.btnText))) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }