<template>
    <v-expansion-panels
        :value="isOpenState"
        class="rtls-expansion-panels"
        :class="cssClass"
        :readonly="isAlwaysExpanded"
        @change="change"
    >
        <v-expansion-panel class="rtls-expansion-panel">
            <v-expansion-panel-header v-if="label" class="rtls-expansion-panel-header" hide-actions>
                <template v-slot:default="{ open }">
                    <p class="rtls-expansion-panel-header__label">
                        {{ $t(label) | toSentenceCase }}
                    </p>
                    <span v-if="isStoreGroups" class="asterisk">*</span>
                    <main-expand-button :is-expanded="open" />
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
                class="rtls-expansion-panel-content"
                :class="expansionPanelClass"
            >
                <slot />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { includes } from 'lodash';

export default {
    props: {
        label: String,
        cssClass: String,
        expansionPanelClass: String,
        isOpenState: {
            type: Number,
            default: 0, // 0 - open
        },
        change: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            isAlwaysExpanded: false,
        };
    },

    computed: {
        isStoreGroups() {
            return includes(this.label, 'storeGroups');
        },
    },
};
</script>

<style lang="scss" scoped>
.rtls-expansion-panel {
    .rtls-expansion-panel-header {
        padding: 0;
        > * {
            flex-grow: 0;
        }

        .asterisk {
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2rem;
            padding-left: 0.3rem;
        }

        &__label {
            font-size: 1.2rem;
            line-height: 1.6rem;
            font-weight: 600;
        }
    }
}
</style>
