<template>
    <div>
        <free-gifts-delete-dialog
            v-if="isEditMode"
            :resource="freeGiftsResource"
            :resource-id="freeGiftId"
            :child-dependencies-warning="false"
        />
        <delete-button v-else background @delete="cancelCreation" />
    </div>
</template>

<script>
import Vue from 'vue';
import { freeGifts as freeGiftsResource } from '@enums/resources';

export default Vue.extend({
    data() {
        return {
            freeGiftsResource,
        };
    },
    computed: {
        freeGiftId() {
            return this.params.data._id;
        },
        isEditMode() {
            return this.freeGiftId;
        },
    },
    methods: {
        cancelCreation() {
            const selectedNode = this.params.node;
            const selectedData = selectedNode.data;
            this.params.api.applyTransaction({ remove: [selectedData] });
            this.params.context.componentParent.enableGridControls();
        },
    },
});
</script>
