<template>
    <v-container class="rtls-calendar-picker" :class="cssClass">
        <div class="rtls-calendar-picker__label-wrapper">
            <p v-if="label" class="rtls-calendar-picker__label">
                {{ $t(label) | toSentenceCase }}
            </p>
            <span v-if="isRequired" class="asterisk">*</span>
        </div>
        <div class="rtls-calendar-picker__control">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                nudge-bottom="25"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        :data-id-e2e="dataIdE2E"
                        :class="
                            `rtls-text-field rtls-text-field--${theme} rtls-text-field--reverse-order`
                        "
                        :value="dateText"
                        :placeholder="$t('general.dates.startEnd') | toSentenceCase"
                        :rules="rules"
                        readonly
                        :disabled="isDisabled"
                        v-on="on"
                    >
                        <template slot="append">
                            <v-icon small @click="menu = true">$date-picker</v-icon>
                        </template>
                    </v-text-field>
                </template>
                <date-picker
                    :dates="dateRange"
                    :fixed-start-date="computedFixedStartDate"
                    :make-read-only="isDisabled"
                    :min="computedMin"
                    :max="max"
                    range
                    @complete="closeWhenComplete"
                />
            </v-menu>
        </div>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import vuexComponentMixin from '../../mixins/vuex-component';
import vuexDateMixin from '../../mixins/vuex-date-bounds';

export default {
    mixins: [vuexComponentMixin, vuexDateMixin],

    props: {
        from: {
            type: Object, // TODO: custom types
            required: true,
        },
        to: {
            type: Object, // TODO: custom types
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        cssClass: String,
        isRequired: {
            type: Boolean,
            default: false,
        },
        dataIdE2E: String,
        useVuexStagingArea: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: 'grey',
        },
        fixedStartDate: {
            type: String,
            default: '',
            required: false,
        },
        entity: {
            type: String,
            default: '',
            required: false,
        },
        skipComputedMin: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            menu: false,
            dateRange: [],
            minDateValue: null,
        };
    },
    computed: {
        computedFixedStartDate() {
            const allowedEntities = ['campaign', 'sub-campaign', 'scenario'];

            if (this.fixedStartDate && this.fixedStartDate.length) {
                return this.fixedStartDate;
            }
            const isAllowEntity = allowedEntities.includes(this.entity);
            const startDate = this.modelDates && this.modelDates[0];
            if (startDate && isAllowEntity) {
                const isStartDateInThePast = this.$moment(startDate).isBefore(
                    this.$moment().startOf('day')
                );

                if (isStartDateInThePast) {
                    return startDate;
                }
            }
            return '';
        },
        computedMin() {
            if (this.skipComputedMin) {
                return this.min;
            }
            return this.minDateValue || this.min;
        },
        dateText() {
            return this.modelDates
                .map(date => this.$options.filters.localShortDate(date))
                .join(this.$t('general.dates.separator'));
        },
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
        modelDates() {
            if (!this.useVuexStagingArea) {
                return this.getter() || [];
            }

            const dates = [];
            const stagingArea = this.$store.state[this.vuexModule].stagingArea[this.namespace];

            const fromFieldPath = this.from.fieldPath ? `${this.from.fieldPath}.` : '';
            // Retrieve any current dates from the store.
            const fromDate = get(stagingArea, `${fromFieldPath}${this.from.fieldName}`);

            const toFieldPath = this.to.fieldPath ? `${this.to.fieldPath}.` : '';
            const toDate = get(stagingArea, `${toFieldPath}${this.to.fieldName}`);

            // If dates exist push them to array and return to the component.
            if (fromDate) {
                dates.push(fromDate);
            }
            if (toDate) {
                dates.push(toDate);
            }

            return dates.map(date => this.$options.filters.datepickerFormat(date));
        },
    },
    watch: {
        // A watch is required because we need to ensure the dateRange (the dates selected in the menu)
        // update when the date picker menu is opened. This can't be done in the normal reactive ways
        // because of difficulties around the fact that this component represents two different fields in the staging area.
        menu() {
            this.dateRange = this.modelDates;
        },
    },
    created() {
        if (this.modelDates && this.modelDates[1]) {
            this.overrideReadOnly = this.$moment(this.modelDates[1]).isAfter(
                this.$moment().startOf('day')
            );
            if (this.overrideReadOnly) {
                this.minDateValue = this.$moment().format('YYYY-MM-DD');
            }
        }
    },
    methods: {
        ...mapActions({
            emitEventsOnMutation(dispatch, payload) {
                return dispatch(`${this.vuexModule}/emitEventsOnMutation`, payload);
            },
        }),
        async closeWhenComplete(dateRange) {
            dateRange.sort();
            // Only save and close the date picker when the second date has been selected.
            if (dateRange.length > 1) {
                if (this.useVuexStagingArea) {
                    const fromFieldUpdate = {
                        fieldPath: this.from.fieldPath,
                        fieldName: this.from.fieldName,
                        value: dateRange[0],
                    };

                    // Save the larger value to the to field. This will be undefined if only
                    // one date has been selected.
                    const toFieldUpdate = {
                        fieldPath: this.to.fieldPath,
                        fieldName: this.to.fieldName,
                        value: dateRange[1],
                    };

                    await this.setStagingAreaFields({
                        namespace: this.namespace,
                        fieldsToUpdate: [fromFieldUpdate, toFieldUpdate],
                    });

                    // emit events on nextTick as form is not valid in setStagingAreaFields action
                    // as rules are set in extra v-text-field
                    // TODO: https://owlabs.atlassian.net/browse/PROWEB-1355
                    this.$nextTick(() => {
                        this.emitEventsOnMutation({
                            namespace: this.namespace,
                            fieldsToUpdate: [fromFieldUpdate, toFieldUpdate],
                        });
                    });
                }

                this.$emit('change', {
                    [this.from.fieldName]: dateRange[0],
                    [this.to.fieldName]: dateRange[1],
                });

                this.menu = false;
            }
        },
    },
};
</script>
