import { render, staticRenderFns } from "./promotion-rag-colour.vue?vue&type=template&id=23d526cc&scoped=true&functional=true"
import script from "./promotion-rag-colour.vue?vue&type=script&lang=js"
export * from "./promotion-rag-colour.vue?vue&type=script&lang=js"
import style0 from "./promotion-rag-colour.vue?vue&type=style&index=0&id=23d526cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "23d526cc",
  null
  
)

export default component.exports