<template>
    <v-dialog v-model="dialog" persistent class="confirm-dialog">
        <template v-if="hasActivator" v-slot:activator="{ on }">
            <slot :activator="on" name="actions">
                <v-btn color="primary" dark small depressed v-on="on">
                    {{ $t(buttonText) | toSentenceCase }}
                </v-btn>
            </slot>
        </template>
        <v-card class="confirm-dialog__container" tile>
            <div class="confirm-dialog__header">
                <v-btn icon @click="closeDialog">
                    <icon icon-name="cancel" small icon-colour-type="primary-path" />
                </v-btn>
            </div>
            <confirm-action
                class="confirm-dialog__action"
                :question-text="questionText"
                :warning-text="warningText"
                :action-text="actionText"
                @cancel="closeDialog()"
                @confirm="confirm()"
            >
                <template v-slot:warningContent>
                    <slot name="warningContent" />
                </template>
            </confirm-action>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        questionText: {
            type: String,
            required: true,
        },
        warningText: {
            type: String,
            required: false,
        },
        actionText: {
            type: String,
            required: true,
        },
        hasActivator: {
            type: Boolean,
            default: true,
        },
        buttonText: String,
        autoCloseOnConfirm: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        dialog: false,
    }),

    methods: {
        close() {
            this.dialog = false;
        },
        open() {
            this.dialog = true;
        },
        closeDialog() {
            this.$emit('close');

            if (!this.preventDefault) {
                this.close();
            }
        },
        confirm() {
            this.$emit('confirm');

            if (this.autoCloseOnConfirm) {
                this.closeDialog();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.confirm-dialog {
    &__header {
        text-align: right;
    }

    &__container {
        width: 45rem;
        border-left: 0.4rem solid $promo-primary-colour;
    }

    &__action {
        padding: 0 3.5rem;
    }
}
</style>
