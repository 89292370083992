import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rate-cards-viewer" }, [
    _c(
      "div",
      { staticClass: "rate-cards-viewer__top rate-cards-grid" },
      _vm._l(_vm.rateCardFields, function (field, index) {
        return _c(
          "div",
          {
            key: `rateCardHeader::${index}`,
            class: ["rate-cards-viewer__header", field.headerClasses],
          },
          [
            field.header
              ? _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getHeader(field)) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rate-cards-viewer__bottom rate-cards-grid" },
      [
        _vm._l(_vm.rateCards, function (rateCard) {
          return [
            _c(
              "vuex-form-viewer",
              {
                key: rateCard._id,
                attrs: {
                  context: rateCard,
                  fields: _vm.rateCardFields,
                  "make-read-only": _vm.selectedRateCardId !== rateCard._id,
                  form: _vm.getFullForm(rateCard),
                },
                on: { edit: _vm.onEdit },
              },
              [
                _vm.selectedRateCardId === rateCard._id
                  ? [
                      _c(
                        "div",
                        {
                          key: `${rateCard._id}_edit`,
                          staticClass: "rate-cards-viewer__icons",
                        },
                        [
                          _c("icon-button", {
                            attrs: { icon: "edit" },
                            on: {
                              click: function ($event) {
                                return _vm.setSelectedRateCardId(null)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          key: `${rateCard._id}_delete`,
                          staticClass: "rate-cards-viewer__icons",
                        },
                        [
                          _c("common-delete-dialog", {
                            attrs: {
                              resource: _vm.rateCardsEnum,
                              "child-dependencies-warning": false,
                            },
                            on: {
                              delete: function ($event) {
                                return _vm.deleteRateCard({ id: rateCard._id })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { key: `${rateCard._id}_filler`, staticClass: "filler" }),
          ]
        }),
        _vm._v(" "),
        _vm.isNewRateCard
          ? _c(
              "vuex-form-viewer",
              {
                ref: "newFormViewer",
                attrs: { context: {}, fields: [], form: _vm.getFullForm() },
              },
              [
                _c("div", { staticClass: "rate-cards-viewer__icons" }),
                _vm._v(" "),
                _c("div", { staticClass: "rate-cards-viewer__icons" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "rate-cards-viewer__buttons" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel-button",
                        attrs: { text: "" },
                        on: { click: _vm.cancelCardRate },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "simple-button",
                      {
                        attrs: { disabled: _vm.isSaveNewRateCardDisabled },
                        on: {
                          onClick: function ($event) {
                            return _vm.saveCardRate()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.save"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rate-cards-viewer__new-card" },
      [
        _c("create-new-button", {
          attrs: {
            "btn-text": _vm._f("toSentenceCase")(
              _vm.$tkey("rateCards.newRateCardButton")
            ),
            disabled: _vm.isNewRateCard,
          },
          on: { createNew: _vm.addNewRateCard },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }