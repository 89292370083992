<template>
    <div>
        <feature-toggle :toggle="allowAlternativeMechanics">
            <alternative-mechanics
                v-if="isAlternativeMechanicsVisible"
                :namespace="namespace"
                :disabled="isReadOnly || splitPromotion"
                :disabled-generation="isPromotionInPast"
                @set-offer-mechanics="setGeneratedOfferMechanics"
                @get-alternative-mechanics="getAlternativeMechanics"
            />
        </feature-toggle>
        <div class="offer-mechanic-preset">
            <div class="offer-mechanic-preset__offer-title">
                {{ $tkey('labels.offer') | toSentenceCase }}
            </div>
            <div class="offer-mechanic-preset__preset-selector">
                <div class="offer-mechanic-preset-selector__preset--text">
                    {{ $tkey('labels.preset') | toSentenceCase }}
                </div>
                <vuex-select
                    :getter="() => getOfferMechanicTemplate()"
                    :setter="value => setOfferMechanicToPreset(value)"
                    :options="offerMechanicPresetTemplateOptions"
                    :placeholder="$tkey('placeholders.selectPreset') | toSentenceCase"
                    :disabled="isReadOnly || splitPromotion"
                    item-text="text"
                    item-value="value"
                    class="offer-mechanic-preset__preset-selector--select-preset"
                />
            </div>
        </div>
        <div id="offer-mechanic" class="offer-mechanic">
            <div class="offer-mechanic__offer">
                {{ !isPresetTemplate ? $tkey('labels.custom') : '' | toSentenceCase }}
            </div>
            <div class="offer-mechanic__tiers">
                <div
                    v-for="(tier, tierIndex) in offerMechanic.tiers"
                    :key="tierIndex"
                    class="offer-mechanic__tier"
                >
                    <div v-if="isTierExpanded(tierIndex)">
                        <div v-if="conditions[tierIndex].length" class="offer-mechanic__conditions">
                            <div
                                v-for="(rewardRequirement, conditionIndex) in conditions[tierIndex]"
                                :key="conditionIndex"
                                class="offer-mechanic__conditions--inner-container"
                            >
                                <div class="offer-mechanic__reward-requirement">
                                    <div
                                        v-for="(option, optionIndex) in rewardRequirement.options"
                                        :key="optionIndex"
                                        class="flex-row"
                                    >
                                        <reward-requirement-option
                                            :reward-requirement="option"
                                            :tier-index="tierIndex"
                                            :condition-index="conditionIndex"
                                            :change-option-to-drop-down-options="
                                                changeOptionToDropDownOptions(
                                                    tierIndex,
                                                    conditionIndex,
                                                    optionIndex
                                                )
                                            "
                                            :additional-reward-drop-down-options="
                                                additionalRewardDropDownOptions(option.type)
                                            "
                                            :should-prefix-with-and="
                                                shouldPrefixWithAnd(
                                                    tierIndex,
                                                    conditionIndex,
                                                    optionIndex
                                                )
                                            "
                                            :is-reward-requirement-editable="
                                                isConditionEditable(tierIndex, conditionIndex)
                                            "
                                            :is-reward-requirement-type-editable="
                                                isConditionTypeEditable(tierIndex, conditionIndex)
                                            "
                                            @remove="removeOption"
                                            @update="updateOption"
                                            @updateAdditionalReward="updateAdditionalReward"
                                            @save="mapConditionsToOfferMechanic({ tierIndex })"
                                        />

                                        <v-menu
                                            v-if="
                                                optionIndex === rewardRequirement.options.length - 1
                                            "
                                            bottom
                                            offset-y
                                            :disabled="isReadOnly || splitPromotion"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    class="offer-mechanic__drop-down offer-mechanic__drop-down--no-label"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <div
                                                        class="offer-mechanic-icon-wrapper"
                                                        :class="{
                                                            disabled: isReadOnly || splitPromotion,
                                                        }"
                                                    >
                                                        <v-icon small>mdi-dots-vertical</v-icon>
                                                    </div>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <template
                                                    v-if="
                                                        isConditionTypeEditable(
                                                            tierIndex,
                                                            conditionIndex
                                                        )
                                                    "
                                                >
                                                    <v-list-item
                                                        v-for="dropDownOption in addToClearDropDownOptions(
                                                            tierIndex,
                                                            conditionIndex
                                                        )"
                                                        :key="dropDownOption"
                                                        @click="
                                                            addOption(
                                                                tierIndex,
                                                                conditionIndex,
                                                                dropDownOption
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            $tkey(
                                                                `rewardRequirementOptions.${dropDownOption}`
                                                            ) | toSentenceCase
                                                        }}
                                                    </v-list-item>
                                                </template>

                                                <v-list-item
                                                    v-if="canAddTier()"
                                                    class="offer-mechanic__dropdown-icon-item"
                                                    @click="addTier(tierIndex)"
                                                >
                                                    <icon icon-name="add" />
                                                    <div
                                                        class="offer-mechanic__dropdown-icon-label"
                                                    >
                                                        {{
                                                            $tkey('buttons.addTier')
                                                                | toSentenceCase
                                                        }}
                                                    </div>
                                                </v-list-item>

                                                <v-list-item
                                                    v-if="tierIndex !== 0"
                                                    class="offer-mechanic__dropdown-icon-item"
                                                    @click="removeTier(tierIndex)"
                                                >
                                                    <icon icon-name="cancel" />
                                                    <div
                                                        class="offer-mechanic__dropdown-icon-label"
                                                    >
                                                        {{
                                                            $tkey('buttons.removeTier')
                                                                | toSentenceCase
                                                        }}
                                                    </div>
                                                </v-list-item>

                                                <template
                                                    v-if="
                                                        isConditionTypeEditable(
                                                            tierIndex,
                                                            conditionIndex
                                                        )
                                                    "
                                                >
                                                    <v-divider />

                                                    <v-list-item
                                                        class="offer-mechanic__dropdown-icon-item"
                                                        @click="
                                                            removeCondition(
                                                                tierIndex,
                                                                conditionIndex
                                                            )
                                                        "
                                                    >
                                                        <icon icon-name="cancel" />
                                                        <div
                                                            class="offer-mechanic__dropdown-icon-label"
                                                        >
                                                            {{
                                                                $tkey('buttons.removeCondition')
                                                                    | toSentenceCase
                                                            }}
                                                        </div>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </div>

                                <div class="offer-mechanic__product-offer-group-inputs">
                                    <div class="flex-row">
                                        <div
                                            v-if="
                                                shouldShowProductOfferGroupDropDown(
                                                    rewardRequirement
                                                )
                                            "
                                            class="offer-mechanic__product-offer-group-drop-down"
                                        >
                                            <div class="offer-mechanic__on-label">
                                                {{ $tkey('labels.on') | toSentenceCase }}
                                            </div>
                                            <vuex-select
                                                :getter="() => rewardRequirement.productOfferGroup"
                                                :setter="
                                                    value =>
                                                        setProductOfferGroupOnCondition(
                                                            value,
                                                            conditionIndex,
                                                            tierIndex
                                                        )
                                                "
                                                :options="
                                                    getProductOfferGroupOptions(
                                                        tierIndex,
                                                        rewardRequirement.productOfferGroup,
                                                        conditionIndex
                                                    )
                                                "
                                                :placeholder="
                                                    $tkey('placeholders.selectProductOfferGroup')
                                                        | toSentenceCase
                                                "
                                                :disabled="
                                                    !isConditionTypeEditable(
                                                        tierIndex,
                                                        conditionIndex
                                                    )
                                                "
                                                class="offer-mechanic__select"
                                                item-text="text"
                                                item-value="value"
                                                clearable
                                            />
                                        </div>

                                        <vuex-checkbox
                                            v-if="
                                                shouldShowProductOfferGroupOptionalCheckBox({
                                                    conditionIndex,
                                                    tierIndex,
                                                })
                                            "
                                            class="offer-mechanic__optional-check-box"
                                            :getter="() => rewardRequirement.isOptionalOfferGroup"
                                            :setter="
                                                value =>
                                                    setIsOptionalOfferGroup(
                                                        value,
                                                        conditionIndex,
                                                        tierIndex
                                                    )
                                            "
                                            :disabled="
                                                shouldDisableIsOptionalOfferGroupCheckBox({
                                                    tierIndex,
                                                    conditionIndex,
                                                })
                                            "
                                            :label="$tkey('labels.optional') | toSentenceCase"
                                        />
                                    </div>

                                    <div class="offer-mechanic__errors">
                                        <v-tooltip
                                            v-if="
                                                getValidationErrors(tierIndex, conditionIndex)
                                                    .length
                                            "
                                            z-index="400"
                                            top
                                            :max-width="500"
                                            content-class="error-tooltip"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <icon
                                                    icon-name="warning"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <div
                                                v-for="(error, errorIndex) in getValidationErrors(
                                                    tierIndex,
                                                    conditionIndex
                                                )"
                                                :key="errorIndex"
                                            >
                                                {{ error }}
                                            </div>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="offer-mechanic__drop-down offer-mechanic__drop-down--add-condition"
                                    :disabled="shouldDisableAddConditionDropDown({ tierIndex })"
                                    v-bind="attrs"
                                    :class="{
                                        'offer-mechanic__drop-down--with-conditions':
                                            conditions[tierIndex].length,
                                    }"
                                    v-on="on"
                                >
                                    <div class="offer-mechanic-icon-wrapper">
                                        <v-icon
                                            v-if="!conditions[tierIndex].length"
                                            small
                                            color="primary"
                                        >
                                            mdi-dots-vertical
                                        </v-icon>
                                        <v-icon v-else small color="primary">
                                            add
                                        </v-icon>
                                    </div>
                                    <span v-if="!conditions[tierIndex].length">
                                        {{ $tkey('buttons.addCondition') | toSentenceCase }}
                                    </span>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                    v-for="option in getConditionOptions(tierIndex)"
                                    :key="option.key"
                                    @click="addCondition(tierIndex, option.key)"
                                >
                                    {{ option.value }}
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <!-- Restrictions should only appear in the first tier -->
                        <div v-if="tierIndex === 0" class="offer-mechanic__restrictions">
                            <v-divider />

                            <offer-restrictions
                                :vuex-module="vuexModule"
                                :namespace="namespace"
                                :context="context"
                                :form-ref="formRef"
                            />
                        </div>
                    </div>

                    <div
                        v-if="offerMechanic.tiers.length > 1"
                        class="offer-mechanic__expand"
                        :class="{ 'offer-mechanic__expand--collapsed': !isTierExpanded(tierIndex) }"
                    >
                        <main-expand-button
                            :is-expanded="isTierExpanded(tierIndex)"
                            horizontal
                            background
                            @expand="toggleTierCollapse(tierIndex)"
                        />
                        <div>{{ tierIndex + 1 }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
    cloneDeep,
    get,
    omit,
    sortBy,
    find,
    map,
    isEmpty,
    flatten,
    keys,
    size,
    has,
    isNil,
} from 'lodash';
import { allowAlternativeMechanics, maxNumberOfAlternativeMechanics } from '@enums/feature-flags';
import { requirement } from '@enums/offer-mechanic-option-type';
import rewardTypes from '@enums/reward-types';
import requirementTypes from '@enums/requirement-types';
import rewardRequirementKeys from '@enums/reward-requirement-keys';
import offerMechanicTemplates from '@enums/offer-mechanic-templates';
import { offer } from '@enums/promotion-tabs';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import { toSentenceCase } from '@/js/utils/string-utils';
import { getActualCustomerAvailabilityForPromotion } from '@/js/utils/promotion-products-utils';
import validationUtils from '@/js/validators/utils';
import optionsConfig from './reward-requirement-options-config';
import i18n from '@/js/vue-i18n';

// For a condition that has no assigned product offer group, we use -1
// so we can identify it as a global condition.
const GLOBAL_CONDITION = -1;

const OFFER_MECHANIC_FIELD = 'offerMechanic';
const IS_ALTERNATIVE_MECHANICS_SELECTED = 'isAlternativeMechanicSelected';

export default {
    mixins: [vuexComponentMixin],

    localizationKey: 'planning.promotionsMaintenance.offer.offerMechanic',

    props: {
        formRef: {
            type: Object,
            required: false,
        },
        isPastPromotion: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            conditions: [[]],
            tiersExpanded: [],
            primaryRewards: [],
            requirements: [],
            allowAlternativeMechanics,
        };
    },

    computed: {
        ...mapState('clientConfig', ['mechanicsMatrixConfig', 'generalConfig', 'toggleLogic']),
        ...mapState('freeGifts', ['freeGifts']),
        ...mapState('loyaltyPoints', ['loyaltyPoints']),
        ...mapState('promotions', ['saveInProgress']),
        ...mapGetters('scenarios', ['selectedScenario']),
        ...mapGetters('freeGifts', ['getActiveFreeGifts']),
        ...mapGetters('promotions', [
            'getStagingAreaField',
            'getStagingAreaPromotionById',
            'selectedPromotion',
        ]),
        ...mapGetters('loyaltyPoints', [
            'getRedeemLoyaltyPointsOptions',
            'getAwardLoyaltyPointsOptions',
        ]),
        ...mapGetters('clientConfig', ['currencySymbol']),
        ...mapGetters('offerMechanicPresets', ['offerMechanicPresetsKeyed']),

        isAlternativeMechanicsVisible() {
            return (
                !!this.toggleLogic[maxNumberOfAlternativeMechanics] &&
                !this.isPastPromotion &&
                this.lastForecastDate &&
                this.products &&
                this.products.length
            );
        },
        lastForecastDate() {
            return this.selectedPromotion && this.selectedPromotion.lastForecastDate;
        },
        offerMechanic() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: OFFER_MECHANIC_FIELD,
            });
        },
        splitPromotion() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'splitPromotion',
            });
        },
        products() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'products',
            });
        },
        isPromotionInPast() {
            const endDate = this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'endDate',
            });
            const today = this.$moment();
            return this.$moment(endDate).diff(today, 'days') < 0;
        },

        isPresetTemplate() {
            return has(this.offerMechanicPresetsKeyed, this.offerMechanic.uiFields.offerTemplate);
        },

        productOfferGroups() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'productOfferGroups',
            });
        },

        rewardRequirementOptionsConfig() {
            return optionsConfig(i18n, this.currencySymbol);
        },

        // Retrieve all global options from config, as logic exists throughout which
        // depends on whether or not options are global.
        globalOptions() {
            const globalOptions = [];

            Object.keys(this.rewardRequirementOptionsConfig).forEach(option => {
                if (this.rewardRequirementOptionsConfig[option].isGlobalOnly) {
                    globalOptions.push(option);
                }
            });

            return globalOptions;
        },

        offerMechanicPresetTemplateOptions() {
            return keys(this.offerMechanicPresetsKeyed)
                .filter(templateKey => {
                    // if template key has a match in the mechanics matrix and display is set to false,
                    // hide the template from the presets options
                    return get(this.mechanicsMatrixConfig, `${templateKey}.display`, true);
                })
                .map(templateKey => {
                    return {
                        value: templateKey,
                        text: this.$tkey(`templates.${templateKey}`, {
                            currencySymbol: this.currencySymbol,
                        }),
                    };
                });
        },
    },

    watch: {
        // We need to reset conditions when we toggle between what has been
        // updated for an offer mechanic, and the original.
        offerMechanic() {
            this.setConditions();
        },
    },

    created() {
        // Map the rewards/requirements defined in the offer mechanic to the
        // 'conditions' array.  This is because the rewards/requirements
        // defined on the offer mechanic schema are complex and span across
        // multiple attributes.  The 'conditions' array is a flattened structure
        // of all rewards/requirements which helps with manipulating the offer
        // mechanic in the front-end.
        this.setConditions();

        // Expand all tiers by default
        this.tiersExpanded = this.offerMechanic.tiers.map(() => true);

        // Retrieve from config all primary rewards and requirements.
        this.primaryRewards = Object.keys(this.rewardRequirementOptionsConfig).filter(
            option => this.rewardRequirementOptionsConfig[option].rewardType === 'primary'
        );

        this.requirements = Object.keys(this.rewardRequirementOptionsConfig).filter(
            option => this.rewardRequirementOptionsConfig[option].type === 'requirement'
        );

        if (this.splitPromotion) {
            // Split promotions default to new price per item
            this.setOfferMechanicToPreset(offerMechanicTemplates.newPricePerItem, true);
        }
    },

    methods: {
        ...mapActions('promotions', [
            'setStagingAreaField',
            'updateOfferMechanicDescription',
            'submitPromotion',
            'generateAlternativeMechanics',
        ]),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        getAlternativeMechanics() {
            this.generateAlternativeMechanics({
                promotionId: this.namespace,
            });
        },

        isGlobalOptionSelectedOnPrimaryReward({ tierIndex }) {
            return this.conditions[tierIndex].some(
                ({ productOfferGroup, options }) =>
                    productOfferGroup === GLOBAL_CONDITION &&
                    this.isPrimaryReward({ type: this.getOptionType({ options }) })
            );
        },

        selectedProductOfferGroupsOnPrimaryRewards({ tierIndex }) {
            return this.conditions[tierIndex]
                .filter(({ options, productOfferGroup }) => {
                    const type = this.getOptionType({ options });
                    return this.isPrimaryReward({ type }) && productOfferGroup !== GLOBAL_CONDITION;
                })
                .map(({ productOfferGroup }) => productOfferGroup);
        },

        shouldDisableAddConditionDropDown({ tierIndex }) {
            const allProductGroups = this.conditions[tierIndex].map(
                ({ productOfferGroup }) => productOfferGroup
            );

            // If there are any conditions that don't have a product offer group,
            // then the user shouldn't be able to add a new condition.
            return allProductGroups.includes(undefined) || this.isReadOnly || this.splitPromotion;
        },

        getOptionType({ options }) {
            return get(options, '[0].type');
        },

        hasRequiredPrimaryReward({ conditions }) {
            return conditions.some(
                ({ options, isOptionalOfferGroup }) =>
                    // There will always be at least 1 option in a condition.
                    this.isPrimaryReward({ type: this.getOptionType({ options }) }) &&
                    !isOptionalOfferGroup
            );
        },

        isPrimaryReward({ type }) {
            return this.primaryRewards.includes(type);
        },

        resetProductOfferGroupOnGlobalConditionOnTier({ tierIndex }) {
            const selectedProductOfferGroupsOnPrimaryRewards = this.selectedProductOfferGroupsOnPrimaryRewards(
                { tierIndex }
            );

            const doesGlobalOptionCoverAtLeastTwoPogs =
                this.productOfferGroups.length - selectedProductOfferGroupsOnPrimaryRewards.length >
                1;

            // If the global option has been selected yet it is no longer available
            // i.e. when the global condition no longer spans across at least 2
            // product offer groups, then we need to reset the condition that is
            // assigned as global with the last remaining product offer group.
            if (
                this.isGlobalOptionSelectedOnPrimaryReward({ tierIndex }) &&
                !doesGlobalOptionCoverAtLeastTwoPogs
            ) {
                const selectedProductOfferGroups = map(
                    this.conditions[tierIndex],
                    'productOfferGroup'
                );

                // Only a single product offer group should be remaining.
                const unselectedProductOfferGroup = this.productOfferGroups.filter(({ _id }) => {
                    return !selectedProductOfferGroups.includes(_id);
                });

                const conditionToUpdate = this.conditions[tierIndex].find(
                    ({ productOfferGroup, options }) => {
                        return (
                            productOfferGroup === GLOBAL_CONDITION &&
                            this.isPrimaryReward({ type: this.getOptionType({ options }) })
                        );
                    }
                );

                conditionToUpdate.productOfferGroup = unselectedProductOfferGroup[0]._id;
            }
        },

        resetProductOfferGroupOnGlobalCondition({ tierIndex }) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.resetProductOfferGroupOnGlobalConditionOnTier({ tierIndex: index });
                });
            } else {
                this.resetProductOfferGroupOnGlobalConditionOnTier({ tierIndex });
            }
        },

        getConditionOptions(tierIndex, ignoreConditionIndex = null) {
            // The condition options are the first options which are defined in
            // the mechanics-matrix config.
            const availableOptions = Object.keys(this.mechanicsMatrixConfig)
                .filter(
                    rewardRequirementOption =>
                        this.mechanicsMatrixConfig[rewardRequirementOption].display
                )
                .map(rewardRequirementOption => {
                    return {
                        key: rewardRequirementOption,
                        value: toSentenceCase(
                            this.$tkey(`rewardRequirementOptions.${rewardRequirementOption}`, {
                                currencySymbol: this.currencySymbol,
                            })
                        ),
                    };
                });

            // If no conditions have yet been created then we can show all options.
            if (!this.conditions[tierIndex].length) {
                return availableOptions;
            }

            const selectedProductOfferGroups = this.conditions[tierIndex]
                .filter(({ productOfferGroup }, index) => {
                    // Note that when we call this function not for a particular condition,
                    // the ignoreConditionIndex will still return true as it is null
                    // by default.
                    return (
                        productOfferGroup &&
                        productOfferGroup !== GLOBAL_CONDITION &&
                        index !== ignoreConditionIndex
                    );
                })
                .map(({ productOfferGroup }) => productOfferGroup);

            const areAllProductOfferGroupsExplicitlySet = this.productOfferGroups.every(
                ({ _id }) => {
                    return selectedProductOfferGroups.includes(_id);
                }
            );

            // If all product offer group options have been explicity set, then
            // we can only show global only options.
            if (areAllProductOfferGroupsExplicitlySet) {
                return availableOptions.filter(({ key }) => {
                    return this.globalOptions.includes(key);
                });
            }

            const noOfUnselectedProductOfferGroups =
                this.productOfferGroups.length - selectedProductOfferGroups.length;

            // If the global option has already been selected on the primary reward,
            // and we don't have any more offer groups to select - then we filter out
            // the primary rewards
            if (
                this.isGlobalOptionSelectedOnPrimaryReward({ tierIndex }) &&
                noOfUnselectedProductOfferGroups === 0
            ) {
                return availableOptions.filter(({ key }) => {
                    return !this.isPrimaryReward({ type: key });
                });
            }

            return availableOptions;
        },

        isRequirement(rewardRequirement) {
            return this.rewardRequirementOptionsConfig[rewardRequirement].type === requirement;
        },

        isNoDiscount(rewardRequirement) {
            return (
                this.rewardRequirementOptionsConfig[rewardRequirement].mappedType ===
                rewardTypes.noDiscount
            );
        },

        isGetFreeGift(rewardRequirement) {
            return rewardRequirement === rewardRequirementKeys.getFreeGift;
        },

        isLoyaltyPoints(rewardRequirement) {
            return ['getPoints', 'redeemPoints'].includes(rewardRequirement);
        },

        isGetPoints(rewardRequirement) {
            return rewardRequirement === rewardRequirementKeys.getPoints;
        },

        isRedeemPoints(rewardRequirement) {
            return rewardRequirement === rewardRequirementKeys.redeemPoints;
        },

        filterDisabledMechanicOptions(options) {
            const enabledKeys = Object.keys(options).filter(o => options[o].display);

            return enabledKeys.reduce((acc, key) => {
                acc[key] = options[key];
                return acc;
            }, {});
        },

        getProductOfferGroupOptions(tierIndex, productOfferGroup, conditionIndex) {
            // Retrieve a list of all selected product offer groups associated
            // with conditions, where the condition is not for a global only
            // option.
            const selectedProductOfferGroups = this.conditions[tierIndex]
                .filter(({ options }) => {
                    const type = this.getOptionType({ options });
                    return !this.rewardRequirementOptionsConfig[type].isGlobalOnly;
                })
                .map(c => c.productOfferGroup);

            const productOfferGroupOptions = [...this.productOfferGroups];
            const { options, isOptionalOfferGroup } = this.conditions[tierIndex][conditionIndex];
            const type = this.getOptionType({ options });

            const selectedProductOfferGroupsOnPrimaryRewards = this.selectedProductOfferGroupsOnPrimaryRewards(
                { tierIndex }
            );

            // We use greater than 0 here instead of greater than 1, as we don't want to include the current condition.
            const doesGlobalOptionCoverAtLeastTwoPogs =
                this.productOfferGroups.length - selectedProductOfferGroupsOnPrimaryRewards.length >
                0;

            if (
                type &&
                this.isPrimaryReward({ type }) &&
                !isOptionalOfferGroup &&
                !this.rewardRequirementOptionsConfig[type].isGlobalDisabled &&
                doesGlobalOptionCoverAtLeastTwoPogs
            ) {
                // Add global as the first option in the drop down.
                productOfferGroupOptions.unshift({
                    _id: GLOBAL_CONDITION,
                    description: toSentenceCase(this.$tkey('labels.global')),
                });
            }

            return productOfferGroupOptions.map(({ _id, description }) => {
                return {
                    value: _id,
                    text: description,
                    // We can only select an option once across all conditions
                    // unless it's global, which can only be explicitly set
                    // once, but is implicitly set for all free gift and
                    // loyalty point options.
                    disabled: selectedProductOfferGroups.includes(_id) && _id !== productOfferGroup,
                };
            });
        },

        shouldPrefixWithAnd(tierIndex, conditionIndex, optionIndex) {
            const option = this.conditions[tierIndex][conditionIndex].options[optionIndex];
            const selectedRewardsAndRequirements = this.conditions[tierIndex][
                conditionIndex
            ].options.slice(0, optionIndex + 1);
            const indexOfFirstRequirement = selectedRewardsAndRequirements.findIndex(({ type }) =>
                this.isRequirement(type)
            );

            // Return true if the option is a requirement, and that it is not
            // the first occurence of a requirement in the condition.
            return this.isRequirement(option.type) && indexOfFirstRequirement !== optionIndex;
        },

        shouldShowProductOfferGroupDropDown({ options }) {
            // All options other than the global options can be set for a
            // particular product offer group.
            return !options.some(({ type }) => this.globalOptions.includes(type));
        },

        shouldShowProductOfferGroupOptionalCheckBox({ conditionIndex, tierIndex }) {
            const type = this.getOptionType({
                options: this.conditions[tierIndex][conditionIndex].options,
            });

            return (
                type &&
                [...this.primaryRewards, ...this.requirements].includes(type) &&
                !this.rewardRequirementOptionsConfig[type].isGlobalOnly
            );
        },

        isFirstTierAndKeepInSync(tierIndex) {
            return this.generalConfig.keepOfferMecahnicTiersInSync && tierIndex === 0;
        },

        setProductOfferGroupOnCondition(productOfferGroup, conditionIndex, tierIndex) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.setProductOfferGroupOnTierCondition(
                        productOfferGroup,
                        conditionIndex,
                        index
                    );
                });
            } else {
                this.setProductOfferGroupOnTierCondition(
                    productOfferGroup,
                    conditionIndex,
                    tierIndex
                );
            }

            this.mapConditionsToOfferMechanic({ tierIndex });
        },

        setProductOfferGroupOnTierCondition(productOfferGroup, conditionIndex, tierIndex) {
            this.conditions[tierIndex][conditionIndex].productOfferGroup = productOfferGroup;

            // A primary reward cannot be optional if it has been set as global.
            const type = this.getOptionType({
                options: this.conditions[tierIndex][conditionIndex].options,
            });

            if (productOfferGroup === GLOBAL_CONDITION && this.isPrimaryReward({ type })) {
                this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = false;
            }
        },

        setIsOptionalOfferGroup(value, conditionIndex, tierIndex) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.conditions[index][conditionIndex].isOptionalOfferGroup = value;
                });
            } else {
                this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = value;
            }

            this.mapConditionsToOfferMechanic({ tierIndex });
        },

        setConditions() {
            this.conditions = this.offerMechanic.tiers.map(tier => {
                const { globalRequirements, globalRewards, freeGifts } = tier;

                // Filter out product offer groups that have no rewards or requirements.
                const productOfferGroupsWithRewardsAndRequirements = tier.productOfferGroups.filter(
                    ({ rewards, requirements }) => rewards.length || requirements.length
                );

                const flattenUiFieldsAndMapType = ({ array }) => {
                    return (
                        array
                            .map(obj => {
                                // The type that we store in the database is the type that
                                // we send for execution i.e SAVE_PERCENT, however we need
                                // to map this type to get the offer type that we define
                                // in config i.e savePercent.
                                const type = Object.keys(this.rewardRequirementOptionsConfig).find(
                                    key => {
                                        return (
                                            this.rewardRequirementOptionsConfig[key].mappedType ===
                                            obj.type
                                        );
                                    }
                                );

                                return {
                                    ...obj,
                                    type,
                                    colOrder: obj.uiFields && obj.uiFields.colOrder,
                                    rowOrder: obj.uiFields && obj.uiFields.rowOrder,
                                };
                            })
                            // No discount rewards should be removed from the conditions
                            .filter(flattenedObj => !this.isNoDiscount(flattenedObj.type))
                    );
                };

                const productOfferGroups = productOfferGroupsWithRewardsAndRequirements.map(
                    ({ _id, isOptionalOfferGroup, rewards, requirements }) => {
                        const flattenedRewards = flattenUiFieldsAndMapType({ array: rewards });
                        const flattenedRequirements = flattenUiFieldsAndMapType({
                            array: requirements,
                        });
                        const sortedOptions = sortBy(
                            [...flattenedRewards, ...flattenedRequirements],
                            [option => option.colOrder]
                        );

                        return {
                            productOfferGroup: _id,
                            isOptionalOfferGroup,
                            // There can only be 1 condition (row) per product offer
                            // group, so we can get the rowOrder from any of the
                            // reward/requirement options.
                            rowOrder: sortedOptions[0].rowOrder,
                            // We can omit uiFields as this will be recreated when
                            // we save the offer mechanic, and we can omit rowOrder
                            // as this now lives at the condition level.
                            options: sortedOptions.map(o => {
                                return omit(o, ['rowOrder', 'uiFields']);
                            }),
                        };
                    }
                );

                const allGlobalRewardsAndRequirements = [
                    ...flattenUiFieldsAndMapType({ array: globalRequirements }),
                    ...flattenUiFieldsAndMapType({ array: globalRewards }),
                    ...flattenUiFieldsAndMapType({ array: freeGifts }),
                ];

                // Group the global rewards and requirements by condition (row).
                const globalConditions = allGlobalRewardsAndRequirements.reduce((acc, value) => {
                    if (acc[value.rowOrder]) {
                        acc[value.rowOrder].push(value);
                    } else {
                        acc[value.rowOrder] = [value];
                    }

                    return acc;
                }, {});

                // Merge and order the global and product offer group rewards/requirements.
                return sortBy(
                    Object.keys(globalConditions)
                        .map(rowOrder => {
                            const sortedOptions = sortBy(globalConditions[rowOrder], [
                                option => option.colOrder,
                            ]);

                            return {
                                // Global condition, so productOfferGroup can be null.
                                productOfferGroup: GLOBAL_CONDITION,
                                rowOrder,
                                options: sortedOptions.map(o => {
                                    // We can omit uiFields as this will be recreated when
                                    // we save the offer mechanic, and we can omit rowOrder
                                    // as this now lives at the condition level.
                                    return omit(o, ['rowOrder', 'uiFields']);
                                }),
                            };
                        })
                        .concat(productOfferGroups),
                    [i => i.rowOrder]
                );
            });
        },

        addToClearDropDownOptions(tierIndex, conditionIndex) {
            const selectedRewardsAndRequirements = this.conditions[tierIndex][conditionIndex]
                .options;
            const pathToNextOptions = selectedRewardsAndRequirements.map(({ type }) => type);

            // Generate the addToClear drop-down options by traversing through
            // the mechanics-matrix config based on the selected rewards/requirements.
            // For example, if 'selectedRewardsAndRequirements' is:
            //     [{ type: 'savePercent' }, { type: 'whenYouBuy' }]
            // then 'pathToNextOptions' will be:
            //     ['savePercent', 'whenYouBuy']
            // and we can find the next set of options at:
            //     mechanicsMatrixConfig['savePercent']['whenYouBuy].options
            let rewardRequirementOptions = this.mechanicsMatrixConfig;
            pathToNextOptions.forEach(rewardRequirement => {
                rewardRequirementOptions = get(
                    rewardRequirementOptions,
                    `[${rewardRequirement}].options`
                )
                    ? this.filterDisabledMechanicOptions(
                          rewardRequirementOptions[rewardRequirement].options
                      )
                    : {};
            });

            return Object.keys(rewardRequirementOptions);
        },

        additionalRewardDropDownOptions(rewardRequirement) {
            if (this.isGetFreeGift(rewardRequirement)) {
                let curentFreeGiftsOptions = [];
                this.offerMechanic.tiers.forEach(tier => {
                    const filtredGifts = (tier.freeGifts || []).filter(fg => !!fg.identifier);
                    if (filtredGifts) {
                        curentFreeGiftsOptions = [
                            ...curentFreeGiftsOptions,
                            ...filtredGifts.map(item => ({
                                value: item.identifier,
                                text: item.description,
                            })),
                        ];
                    }
                });
                return [
                    ...this.getActiveFreeGifts.map(({ identifier, description }) => {
                        return {
                            text: description,
                            value: identifier,
                        };
                    }),
                    ...curentFreeGiftsOptions,
                ];
            }

            if (this.isGetPoints(rewardRequirement)) {
                let curentLoyaltyPointOptions = [];
                this.offerMechanic.tiers.forEach(tier => {
                    const curentLoyaltyPoint = tier.globalRewards.filter(
                        reward => reward.type === rewardTypes.getLoyaltyPoints && reward.identifier
                    );
                    if (curentLoyaltyPoint) {
                        curentLoyaltyPointOptions = [
                            ...curentLoyaltyPointOptions,
                            ...curentLoyaltyPoint.map(item => ({
                                value: item.identifier,
                                text: item.description,
                            })),
                        ];
                    }
                });
                return [
                    ...this.getAwardLoyaltyPointsOptions.map(({ value, text }) => {
                        return {
                            text,
                            value,
                        };
                    }),
                    ...curentLoyaltyPointOptions,
                ];
            }

            if (this.isRedeemPoints(rewardRequirement)) {
                let curentRedeemPointOptions = [];
                this.offerMechanic.tiers.forEach(tier => {
                    const curentReedemPoint = tier.globalRequirements.filter(
                        globalReq =>
                            globalReq.type === requirementTypes.redeemLoyaltyPoints &&
                            globalReq.identifier
                    );

                    if (curentReedemPoint) {
                        curentRedeemPointOptions = [
                            ...curentRedeemPointOptions,
                            ...curentReedemPoint.map(item => ({
                                value: item.identifier,
                                text: item.description,
                            })),
                        ];
                    }
                });
                return [
                    ...this.getRedeemLoyaltyPointsOptions.map(({ value, text }) => {
                        return {
                            text,
                            value,
                        };
                    }),
                    ...curentRedeemPointOptions,
                ];
            }

            return null;
        },

        changeOptionToDropDownOptions(tierIndex, conditionIndex, optionIndex) {
            if (optionIndex === 0) {
                // If we are changing the first option on a condition, then
                // we need to ignore said condition when determining what
                // options we can change the first option to.
                return this.getConditionOptions(tierIndex, conditionIndex).map(({ key }) => key);
            }

            const selectedRewardsAndRequirements = this.conditions[tierIndex][conditionIndex]
                .options;

            // Get all options that were set prior to the current reward/requirement.
            // For example, if 'selectedRewardsAndRequirements' is:
            //     [{ type: 'savePercent' }, { type: 'whenYouBuy' }, { type: 'whenYouSpend' }]
            // Then we want to return:
            //     [{ type: 'savePercent' }, { type: 'whenYouBuy' }]
            // So that we can then find other options that are available at that
            // position instead of 'whenYouSpend':
            //     mechanicsMatrixConfig['savePercent']['whenYouBuy].options
            const pathToNextOptions = selectedRewardsAndRequirements
                .filter(({ colOrder }) => {
                    return colOrder < optionIndex;
                })
                .map(({ type }) => type);

            let rewardRequirementOptions = this.mechanicsMatrixConfig;
            pathToNextOptions.forEach(rewardRequirement => {
                if (rewardRequirementOptions[rewardRequirement].options) {
                    rewardRequirementOptions = this.filterDisabledMechanicOptions(
                        rewardRequirementOptions[rewardRequirement].options
                    );
                }
            });

            return Object.keys(rewardRequirementOptions).filter(
                rewardRequirementOption => rewardRequirementOptions[rewardRequirementOption].display
            );
        },

        addOption(tierIndex, conditionIndex, rewardRequirement) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.addOptionToTier(index, conditionIndex, rewardRequirement);
                });
            } else {
                this.addOptionToTier(tierIndex, conditionIndex, rewardRequirement);
            }

            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        addOptionToTier(tierIndex, conditionIndex, rewardRequirement) {
            const {
                attributeToMapToNumberInput,
                defaultNumberInputValue = 0,
            } = this.rewardRequirementOptionsConfig[rewardRequirement];

            this.conditions[tierIndex][conditionIndex].options.push({
                type: rewardRequirement,
                [attributeToMapToNumberInput]: defaultNumberInputValue,
                colOrder: this.conditions[tierIndex][conditionIndex].options.length,
            });
        },

        getDefaultProductOfferGroup({ tierIndex, type, ignoreConditionIndex = null }) {
            const selectedProductOfferGroups = this.conditions[tierIndex]
                .filter((condition, index) => {
                    return isNil(ignoreConditionIndex) || index !== ignoreConditionIndex;
                })
                .map(({ productOfferGroup }) => productOfferGroup);

            const selectedProductOfferGroupsOnPrimaryRewards = this.selectedProductOfferGroupsOnPrimaryRewards(
                { tierIndex }
            );

            // We use greater than 0 here instead of greater than 1, as we don't want to include the current condition.
            const doesGlobalOptionCoverAtLeastTwoPogs =
                this.productOfferGroups.length - selectedProductOfferGroupsOnPrimaryRewards.length >
                0;

            const hasRequiredPrimaryReward = this.hasRequiredPrimaryReward({
                conditions: this.conditions[tierIndex],
            });

            if (
                this.globalOptions.includes(type) ||
                (!this.isGlobalOptionSelectedOnPrimaryReward({ tierIndex }) &&
                    this.isPrimaryReward({ type }) &&
                    doesGlobalOptionCoverAtLeastTwoPogs &&
                    !this.rewardRequirementOptionsConfig[type].isGlobalDisabled &&
                    !hasRequiredPrimaryReward)
            ) {
                return GLOBAL_CONDITION;
            }

            // Get the next product offer group in sequence that hasn't already
            // been selected.
            const defaultProductOfferGroup = this.productOfferGroups.find(({ _id }) => {
                return !selectedProductOfferGroups.includes(_id);
            });

            return get(defaultProductOfferGroup, '_id', GLOBAL_CONDITION);
        },

        removeOption({ rewardRequirement, tierIndex, conditionIndex }) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.removeOptionFromTier({
                        rewardRequirement,
                        tierIndex: index,
                        conditionIndex,
                    });
                });
            } else {
                this.removeOptionFromTier({ rewardRequirement, tierIndex, conditionIndex });
            }

            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        removeOptionFromTier({ rewardRequirement, tierIndex, conditionIndex }) {
            // If the reward/requirement is the only option set on the condition,
            // then we can remove the condition entirely.
            if (rewardRequirement.colOrder === 0) {
                // Note that we trigger save within removeCondition.
                this.removeConditionFromTier(tierIndex, conditionIndex);
            } else {
                // If the reward/requirement to remove is not the only option that
                // has been set on the condition, then we need to remove all the
                // rewards/requirements that follow.
                const selectedRewardsAndRequirements = this.conditions[tierIndex][conditionIndex]
                    .options;
                const colIndex = rewardRequirement.colOrder;
                const noOfItemsToRemove = selectedRewardsAndRequirements.length - colIndex;
                this.conditions[tierIndex][conditionIndex].options.splice(
                    colIndex,
                    noOfItemsToRemove
                );
            }
        },

        removeCondition(tierIndex, conditionIndex) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.removeConditionFromTier(index, conditionIndex);
                });
            } else {
                this.removeConditionFromTier(tierIndex, conditionIndex);
            }

            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        removeConditionFromTier(tierIndex, index) {
            this.conditions[tierIndex].splice(index, 1);

            // Re-order the rows when a condition has been removed to maintain
            // sequantial row indexes.
            this.conditions[tierIndex].forEach((condition, conditionIndex) => {
                condition.rowOrder = conditionIndex;
            });
        },

        updateOption({ newValue, tierIndex, conditionIndex, colIndex }) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.updateTierOption({ newValue, tierIndex: index, conditionIndex, colIndex });
                });
            } else {
                this.updateTierOption({ newValue, tierIndex, conditionIndex, colIndex });
            }

            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        updateTierOption({ newValue, tierIndex, conditionIndex, colIndex }) {
            // For global options we should reset the productOfferGroup and
            // isOptionalOfferGroup attributes.
            if (this.globalOptions.includes(newValue)) {
                this.conditions[tierIndex][conditionIndex].productOfferGroup = null;
                this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = null;
            }

            // Remove all options following (and including) the option to remove.
            this.conditions[tierIndex][conditionIndex].options.splice(colIndex);

            const {
                attributeToMapToNumberInput,
                defaultNumberInputValue = 0,
            } = this.rewardRequirementOptionsConfig[newValue];

            const isRequirement = this.isRequirement(newValue);
            const isPrimaryReward = this.isPrimaryReward({ type: newValue });

            if (isPrimaryReward || isRequirement) {
                const hasRequiredPrimaryReward = this.hasRequiredPrimaryReward({
                    conditions: this.conditions[tierIndex],
                });

                const defaultProductOfferGroup = this.getDefaultProductOfferGroup({
                    tierIndex,
                    type: newValue,
                    ignoreConditionIndex: conditionIndex,
                });

                this.conditions[tierIndex][
                    conditionIndex
                ].productOfferGroup = defaultProductOfferGroup;

                if (hasRequiredPrimaryReward) {
                    this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = true;
                } else if (isRequirement) {
                    this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = false;
                } else if (isPrimaryReward && defaultProductOfferGroup === GLOBAL_CONDITION) {
                    this.conditions[tierIndex][conditionIndex].isOptionalOfferGroup = false;
                }
            }

            this.$set(this.conditions[tierIndex][conditionIndex].options, 0, {
                type: newValue,
                colOrder: this.conditions[tierIndex][conditionIndex].options.length,
                [attributeToMapToNumberInput]: defaultNumberInputValue,
            });

            this.resetProductOfferGroupOnGlobalConditionOnTier({ tierIndex });
        },

        updateAdditionalReward({ newValue, tierIndex, conditionIndex, colIndex }) {
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.updateAdditionalRewardInTier({
                        newValue,
                        tierIndex: index,
                        conditionIndex,
                        colIndex,
                    });
                });
            } else {
                this.updateAdditionalRewardInTier({
                    newValue,
                    tierIndex,
                    conditionIndex,
                    colIndex,
                });
            }

            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        updateAdditionalRewardInTier({ newValue, tierIndex, conditionIndex, colIndex }) {
            const additionalReward = this.conditions[tierIndex][conditionIndex].options[colIndex];

            if (this.isGetFreeGift(additionalReward.type)) {
                const freeGift = this.freeGifts.find(fg => fg.identifier === newValue);

                this.conditions[tierIndex][conditionIndex].options[colIndex] = {
                    ...additionalReward,
                    _id: freeGift._id,
                    identifier: freeGift.identifier,
                    description: freeGift.description,
                };
            } else {
                // The additional reward is for loyalty points.
                const loyaltyPoint = this.loyaltyPoints.find(lp => lp.identifier === newValue);

                this.conditions[tierIndex][conditionIndex].options[colIndex] = {
                    ...additionalReward,
                    _id: loyaltyPoint._id,
                    identifier: loyaltyPoint.identifier,
                    description: loyaltyPoint.description,
                };
            }
        },

        addCondition(tierIndex, rewardRequirement) {
            const {
                attributeToMapToNumberInput,
                defaultNumberInputValue = 0,
            } = this.rewardRequirementOptionsConfig[rewardRequirement];

            // Default to true where we are adding a primary reward, when one
            // is already present which is required.
            const defaultIsOptionalOfferGroup =
                this.isPrimaryReward({ type: rewardRequirement }) &&
                this.hasRequiredPrimaryReward({ conditions: this.conditions[tierIndex] });

            const defaultProductOfferGroup = this.getDefaultProductOfferGroup({
                tierIndex,
                type: rewardRequirement,
            });

            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                this.offerMechanic.tiers.forEach((tier, index) => {
                    this.conditions[index].push({
                        productOfferGroup: defaultProductOfferGroup,
                        isOptionalOfferGroup: defaultIsOptionalOfferGroup,
                        rowOrder: this.conditions[index].length,
                        options: [
                            {
                                type: rewardRequirement,
                                [attributeToMapToNumberInput]: defaultNumberInputValue,
                                colOrder: 0,
                            },
                        ],
                    });
                });
            } else {
                this.conditions[tierIndex].push({
                    productOfferGroup: defaultProductOfferGroup,
                    isOptionalOfferGroup: defaultIsOptionalOfferGroup,
                    rowOrder: this.conditions[tierIndex].length,
                    options: [
                        {
                            type: rewardRequirement,
                            [attributeToMapToNumberInput]: defaultNumberInputValue,
                            colOrder: 0,
                        },
                    ],
                });
            }

            this.resetProductOfferGroupOnGlobalCondition({ tierIndex });
            this.mapConditionsToOfferMechanic({ tierIndex, setCustom: true });
        },

        mapConditionsToOfferMechanic({ tierIndex, setCustom = false }) {
            const updatedOfferMechanic = this.offerMechanic;
            if (setCustom) updatedOfferMechanic.uiFields.offerTemplate = 'custom';
            if (this.isFirstTierAndKeepInSync(tierIndex)) {
                updatedOfferMechanic.tiers.forEach((tier, index) =>
                    this.mapConditionsToOfferMechanicTier(updatedOfferMechanic, index)
                );
            } else {
                this.mapConditionsToOfferMechanicTier(updatedOfferMechanic, tierIndex);
            }

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldName: OFFER_MECHANIC_FIELD,
                value: updatedOfferMechanic,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
        },

        mapConditionsToOfferMechanicTier(updatedOfferMechanic, tierIndex) {
            // Reset the rewards/requirements across the whole offer mechanic.
            const globalRewards = [];
            const globalRequirements = [];
            const freeGifts = [];

            updatedOfferMechanic.tiers[tierIndex].productOfferGroups = updatedOfferMechanic.tiers[
                tierIndex
            ].productOfferGroups
                .filter(pog => pog._id !== null)
                .map(pog => {
                    return {
                        ...pog,
                        rewards: [],
                        requirements: [],
                    };
                });

            this.conditions[tierIndex].forEach(condition => {
                if (
                    condition.productOfferGroup &&
                    condition.productOfferGroup !== GLOBAL_CONDITION
                ) {
                    let productOfferGroup = find(
                        updatedOfferMechanic.tiers[tierIndex].productOfferGroups,
                        { _id: condition.productOfferGroup }
                    );

                    if (!productOfferGroup) {
                        const existingPOG = find(this.productOfferGroups, {
                            _id: condition.productOfferGroup,
                        });
                        productOfferGroup = {
                            _id: existingPOG._id,
                            description: existingPOG.description,
                            isOptionalOfferGroup: false,
                            rewards: [],
                            requirements: [],
                        };
                        updatedOfferMechanic.tiers[tierIndex].productOfferGroups.push(
                            productOfferGroup
                        );
                    }

                    condition.options.forEach(option => {
                        // Convert the type to that which the execution api
                        // expects i.e. 'savePercent' -> 'SAVE_PERCENT'
                        const { mappedType } = this.rewardRequirementOptionsConfig[option.type];

                        const rewardRequirementToAdd = {
                            type: mappedType,
                            amount: option.amount,
                            uiFields: {
                                rowOrder: condition.rowOrder,
                                colOrder: option.colOrder,
                            },
                        };

                        if (this.isRequirement(option.type)) {
                            productOfferGroup.requirements.push(rewardRequirementToAdd);
                        } else {
                            productOfferGroup.rewards.push(rewardRequirementToAdd);
                        }
                    });

                    productOfferGroup.isOptionalOfferGroup = condition.isOptionalOfferGroup;
                } else {
                    // Handle global rewards/requirements.
                    condition.options.forEach(option => {
                        // Convert the type to that which the execution api
                        // expects i.e. 'savePercent' -> 'SAVE_PERCENT'
                        const { mappedType } = this.rewardRequirementOptionsConfig[option.type];

                        const rewardRequirementToAdd = {
                            type: mappedType,
                            amount: option.amount,
                            uiFields: {
                                rowOrder: condition.rowOrder,
                                colOrder: option.colOrder,
                            },
                        };

                        // Only include the identifier and description attributes
                        // if it's a loyalty point option.
                        if (this.isLoyaltyPoints(option.type)) {
                            rewardRequirementToAdd.identifier = option.identifier;
                            rewardRequirementToAdd.description = option.description;
                        }

                        if (this.isGetFreeGift(option.type)) {
                            freeGifts.push({
                                _id: option._id,
                                identifier: option.identifier,
                                description: option.description,
                                type: mappedType,
                                quantity: option.quantity,
                                uiFields: {
                                    rowOrder: condition.rowOrder,
                                    colOrder: option.colOrder,
                                },
                            });
                        } else if (this.isRequirement(option.type)) {
                            globalRequirements.push(rewardRequirementToAdd);
                        } else {
                            globalRewards.push(rewardRequirementToAdd);
                        }
                    });
                }
            });

            updatedOfferMechanic.tiers[tierIndex].globalRewards = globalRewards;
            updatedOfferMechanic.tiers[tierIndex].globalRequirements = globalRequirements;
            updatedOfferMechanic.tiers[tierIndex].freeGifts = freeGifts;
        },

        canAddTier() {
            return this.offerMechanic.tiers.length < this.generalConfig.maxOfferMechanicTierCount;
        },

        addTier(tierIndex) {
            const { tiers } = this.offerMechanic;

            // Subsequent tiers should update to be the same reward as the first tier
            tiers.splice(tierIndex + 1, 0, cloneDeep(tiers[0]));
            this.tiersExpanded.splice(tierIndex + 1, 0, true);

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldPath: OFFER_MECHANIC_FIELD,
                fieldName: 'tiers',
                value: tiers,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });

            this.setConditions();
        },

        removeTier(tierIndex) {
            const { tiers } = this.offerMechanic;

            tiers.splice(tierIndex, 1);
            this.tiersExpanded.splice(tierIndex, 1);

            // if only 1 tier left - expand it
            if (tiers.length === 1) {
                this.$set(this.tiersExpanded, 0, true);
            }

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldPath: OFFER_MECHANIC_FIELD,
                fieldName: 'tiers',
                value: tiers,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });

            this.setConditions();
        },

        isTierExpanded(tierIndex) {
            return !!this.tiersExpanded[tierIndex];
        },

        toggleTierCollapse(tierIndex) {
            this.$set(this.tiersExpanded, tierIndex, !this.tiersExpanded[tierIndex]);
        },

        isConditionEditable(tierIndex, conditionIndex) {
            // enable editing of condition values if set in config,
            // they are in the first tier,
            // or the first tier doesn't have that conditionIndex
            return (
                !this.splitPromotion &&
                (this.generalConfig.allowOfferMechanicSubsequentTiersEdit ||
                    tierIndex === 0 ||
                    !this.conditions[0][conditionIndex])
            );
        },

        isConditionTypeEditable(tierIndex, conditionIndex) {
            // enable editing of condition types if set in config,
            // they are in the first tier,
            // or the first tier doesn't have that conditionIndex
            return (
                !this.splitPromotion &&
                (this.generalConfig.allowOfferMechanicSubsequentTiersTypeEdit ||
                    tierIndex === 0 ||
                    !this.conditions[0][conditionIndex])
            );
        },

        getValidationErrors(tierIndex, conditionIndex) {
            const options = this.conditions[tierIndex][conditionIndex].options;
            const errors = options.map(option => {
                const rewardRequirementOptionConfig = this.rewardRequirementOptionsConfig[
                    option.type
                ];

                return validationUtils.getErrorMessages(
                    option[rewardRequirementOptionConfig.attributeToMapToNumberInput],
                    rewardRequirementOptionConfig.validations
                );
            });

            return flatten(errors);
        },

        shouldDisableIsOptionalOfferGroupCheckBox({ tierIndex, conditionIndex }) {
            if (this.splitPromotion || !this.isConditionTypeEditable(tierIndex, conditionIndex)) {
                return true;
            }

            const productOfferGroup = find(this.offerMechanic.tiers[tierIndex].productOfferGroups, {
                _id: this.conditions[tierIndex][conditionIndex].productOfferGroup,
            });
            // If requirements is empty then disable the optional checkbox
            if (productOfferGroup && isEmpty(productOfferGroup.requirements)) return true;
            const type = this.getOptionType({
                options: this.conditions[tierIndex][conditionIndex].options,
            });

            if (
                this.conditions[tierIndex][conditionIndex].productOfferGroup === GLOBAL_CONDITION &&
                this.isPrimaryReward({ type })
            ) {
                return true;
            }

            const areAllPrimaryRewardsOptional = this.conditions[tierIndex]
                .filter(({ options }) =>
                    this.isPrimaryReward({ type: this.getOptionType({ options }) })
                )
                .every(({ isOptionalOfferGroup }) => isOptionalOfferGroup);

            if (areAllPrimaryRewardsOptional) {
                // If all primary rewards are optional then we don't want to
                // disable the check-box, as the user needs to be able to
                // deselect a check-box in order to make a primary reward
                // required.
                return false;
            }

            const conditionIndexOfRequiredPrimaryReward = this.conditions[tierIndex].findIndex(
                ({ options, isOptionalOfferGroup }) => {
                    // Find the primary reward that is not optional.
                    return (
                        this.isPrimaryReward({ type: this.getOptionType({ options }) }) &&
                        !isOptionalOfferGroup
                    );
                }
            );

            if (
                this.isPrimaryReward({
                    type: this.getOptionType({
                        options: this.conditions[tierIndex][conditionIndex].options,
                    }),
                })
            ) {
                return conditionIndex !== conditionIndexOfRequiredPrimaryReward;
            }

            // Requirements are always optional.
            return !this.requirements.includes(
                this.getOptionType({
                    options: this.conditions[tierIndex][conditionIndex].options,
                })
            );
        },

        getOfferMechanicTemplate() {
            if (!this.isPresetTemplate) return null;
            return this.offerMechanicPresetTemplateOptions.find(
                opt => opt.value === this.offerMechanic.uiFields.offerTemplate
            );
        },

        setOfferMechanicToPreset(value, automaticallySet = false) {
            if (!this.offerMechanicPresetsKeyed[value]) return;
            const chosenPresetTemplate = cloneDeep(this.offerMechanicPresetsKeyed[value]);
            const numberOfPogsRequiredForTemplate = size(
                chosenPresetTemplate.tiers[0].productOfferGroups
            );
            const numberOfPromotionProductOfferGroups = size(this.productOfferGroups);
            if (numberOfPromotionProductOfferGroups < numberOfPogsRequiredForTemplate) {
                // create product offer groups on promotion
                const numberOfProductOfferGroupsToCreate =
                    numberOfPogsRequiredForTemplate - numberOfPromotionProductOfferGroups;
                this.$emit('create-pog', { numberOfProductOfferGroupsToCreate });
            } else if (numberOfPogsRequiredForTemplate < numberOfPromotionProductOfferGroups) {
                // if we select a preset which requires only one pog, delete other pogs that have no products
                const restOfPogs = this.productOfferGroups.slice(1);
                restOfPogs.forEach(pog => {
                    if (isEmpty(pog.products)) {
                        this.$emit('delete-pog', {
                            productOfferGroupId: pog._id,
                            skipOMUpdates: true,
                        });
                    }
                });
            }
            // set default product offer groups
            chosenPresetTemplate.tiers[0].productOfferGroups = chosenPresetTemplate.tiers[0].productOfferGroups.map(
                (pog, index) => {
                    return {
                        ...pog,
                        description: this.productOfferGroups[index].description,
                        _id: this.productOfferGroups[index]._id,
                    };
                }
            );

            // check that customerAvailability configured in preset is allowed for current scenario
            if (!isNil(this.selectedScenario)) {
                chosenPresetTemplate.customerAvailability = getActualCustomerAvailabilityForPromotion(
                    {
                        defaultCustomerAvailability: chosenPresetTemplate.customerAvailability,
                        scenario: this.selectedScenario,
                    }
                );
            }

            const fieldsToUpdate = [
                {
                    fieldName: OFFER_MECHANIC_FIELD,
                    value: chosenPresetTemplate,
                },
                {
                    fieldName: IS_ALTERNATIVE_MECHANICS_SELECTED,
                    value: false,
                },
            ];
            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate,
            });
            if (!automaticallySet) {
                this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
            }
        },

        setGeneratedOfferMechanics({ mechanics, isAlternativeMechanicSelected }) {
            const fieldsToUpdate = [
                {
                    fieldName: OFFER_MECHANIC_FIELD,
                    value: { ...mechanics },
                },
                {
                    fieldName: IS_ALTERNATIVE_MECHANICS_SELECTED,
                    value: isAlternativeMechanicSelected,
                },
            ];
            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
        },
    },

    events: {
        onResetProductOfferGroupsOnGlobalSelections() {
            this.conditions.forEach((tier, tierIndex) => {
                this.resetProductOfferGroupOnGlobalConditionOnTier({ tierIndex });
            });
        },
        onPromotionProductsUpdatedForGridRefresh(payload) {
            if (payload && payload.source !== 'bulkAddProducts') {
                return;
            }
            // was checked that here we have new products from the bulk update in the state
            this.updateOfferMechanicDescription({
                namespace: this.namespace,
            });
            this.submitPromotion({
                namespace: this.namespace,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.offer-mechanic-alternatives {
    margin: 2rem 0 1rem 1rem;
}

.offer-mechanic-preset {
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &__preset-selector {
        display: flex;
        &--text {
            margin-top: 0.9rem;
        }
        column-gap: 2.1rem;
        &--select-preset {
            height: 2.1rem;

            ::v-deep {
                .v-input__slot,
                .v-select__slot {
                    height: 2.1rem !important;
                    width: 30rem;
                }
            }
        }
    }
}

.offer-mechanic {
    display: grid;
    grid-template-columns: 5rem auto;
    max-width: calc(100vw - 29rem);
    overflow-x: auto;

    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;

    &__offer {
        color: $promo-text-colour;
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 2.4rem;
        margin-top: 0.8rem;
    }

    &__tiers {
        border-top: 0.1rem solid $promo-grey-3;
        margin-left: 0.6rem;
        display: flex;
    }

    &__tier {
        display: flex;
    }

    &__conditions {
        &--inner-container {
            padding: 0.5rem;
            background-color: $promo-grey-3;
            margin-bottom: 0.3rem;
            margin-top: 0.3rem;
            padding-bottom: 0.1rem;
        }
    }

    &__reward-requirement {
        @include flex-row;
        .flex-row {
            align-items: center;
        }
    }

    &__expand {
        margin-right: 0.6rem;
        padding: 0.5rem 0.5rem 0.5rem 0.6rem;
        text-align: center;
        border-right: 0.1rem solid $promo-grey-4;

        &--collapsed {
            padding-left: 0;
        }
    }

    &__product-offer-group-inputs {
        @include flex-row;

        align-items: center;
        justify-content: space-between;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0.6rem;
        color: $promo-text-colour;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.4rem;
    }

    &__product-offer-group-drop-down {
        @include flex-row;

        align-items: center;
    }

    &__optional-check-box {
        margin-left: 0.9rem;
    }

    &__drop-down {
        padding-right: 0.5rem !important;
        background-color: transparent !important;
        border: none;
        border-radius: 0;
        box-shadow: none;
        color: $promo-primary-colour;
        font-size: 1.2rem;
        letter-spacing: 0;
        line-height: 1.4rem;
        text-align: right;
        margin-top: 0.8rem;
        &:before {
            background-color: transparent !important;
        }

        &--add-condition {
            margin-bottom: 0.7rem;
            padding-left: 0 !important;
        }

        .v-btn__content {
            .offer-mechanic-icon-wrapper {
                border-radius: 0.3rem;
                border: 0.1rem solid $promo-primary-colour;
                width: 1.9rem;
                height: 1.9rem;
                margin-right: 0.5rem;
                display: flex;
                justify-content: center;

                &.disabled {
                    opacity: 0.5;
                }
            }

            &:hover {
                .offer-mechanic-icon-wrapper {
                    border-color: $promo-black;
                }

                i {
                    color: $promo-black !important;
                }
            }
        }
        :hover {
            background: transparent !important;
            color: $promo-black;
        }

        &--no-label {
            padding: 0 !important;
            min-width: 1.9rem !important;
            margin-top: 0;

            ::v-deep .v-btn__content {
                margin-left: 1rem;
            }
        }

        &--with-conditions {
            min-width: 1rem !important;
            padding-left: 1rem !important;
        }
    }

    &__dropdown-icon-item {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    &__dropdown-icon-label {
        margin-left: 0.5rem;
    }

    &__on-label {
        margin-right: 0.6rem;
    }

    &__select {
        height: 2.1rem;

        ::v-deep {
            .v-input__slot,
            .v-select__slot {
                height: 2.1rem !important;
            }
        }
    }

    &__restrictions {
        max-width: 41rem;
    }

    &__errors {
        margin: 0 1rem;
    }

    .expand-btn {
        width: 1.8rem;
        height: 1.8rem;
        margin-top: 0.8rem;
    }
}

.v-btn {
    :hover {
        background-color: $promo-offer-mechanic-hover-colour;
    }
}

.v-list-item {
    font-size: 1.2rem;
    min-height: 2rem;
    font-size: 1.2rem;
    letter-spacing: 0;
    line-height: 1.4rem;
    padding-left: 0.8rem;
}
</style>
