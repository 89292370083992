import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alternative-mechanics" }, [
    _c(
      "div",
      { staticClass: "alternative-mechanics__header" },
      [
        _c(
          "main-expand-button",
          {
            attrs: {
              "is-expanded": _vm.isGridExpanded,
              disabled: !_vm.alternativeMechanicsLoaded,
            },
            on: { expand: _vm.expandGrid },
          },
          [
            _c("p", { staticClass: "alternative-mechanics__collapse" }, [
              _vm._v(_vm._s(_vm.$tkey("mechanicComparison"))),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.disabledGeneration
          ? _c(
              VBtn,
              {
                staticClass: "alternative-mechanics__generate",
                attrs: {
                  text: "",
                  secondary: "",
                  "data-id-e2e": "btnGenerateAlternativeMechanicsId",
                  disabled: _vm.saveInProgress || _vm.disabled,
                },
                on: { click: _vm.getAlternativeMechanics },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("actions.generateAlternativeMechanics")
                      )
                    ) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.lastAlternativeMechanicsGenerationDate &&
        _vm.alternativeMechanicsLoaded
          ? _c(
              "div",
              { staticClass: "alternative-mechanics__generated-last" },
              [
                _c("em", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tkey("alternativesGenerated")) +
                      ":\n                " +
                      _vm._s(_vm.lastAlternativeMechanicsGenerationDate) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "strong",
                  {
                    staticClass:
                      "alternative-mechanics__generated-last-warning",
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tkey("kpisCalculatedWithConstantFunding")) +
                        "\n                " +
                        _vm._s(_vm.$tkey("alternativeMechanicGenerated")) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          VTooltip,
          {
            attrs: { "z-index": "300", bottom: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "span",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass:
                              "alternative-mechanics__overrides-warning",
                          },
                          "span",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _vm.promotionContainsOverrides &&
                        _vm.alternativeMechanicsLoaded
                          ? _c("icon", {
                              attrs: {
                                "icon-name": "orange-warning-circle",
                                small: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$tkey("volumeOverridePresented")) +
                  "\n            "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "alternative-mechanics-viewer" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isGridExpanded,
                expression: "isGridExpanded",
              },
            ],
            staticClass: "alternative-mechanics-viewer__grid",
          },
          [
            _vm.alternativeMechanicsLoaded
              ? _c("promo-ag-grid", {
                  ref: "agGrid",
                  attrs: {
                    "row-data": _vm.rowData,
                    "column-defs": _vm.columnDefs,
                    "grid-options": _vm.gridOptions,
                    "default-col-def": _vm.defaultColDef,
                    "grid-style": _vm.gridStyle,
                    "grid-class": _vm.gridClass,
                    "dom-layout": "autoHeight",
                  },
                  on: { "grid-ready": _vm.onGridReady },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.isGridExpanded ? _c(VDivider) : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }