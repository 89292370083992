import { canAccessLanding } from '@enums/feature-flags';
import LandingPage from './landing-page';

const routes = [
    {
        path: '/',
        name: 'landing',
        featureToggle: 'canAccessLanding',
        id: 'landing',
        component: LandingPage,
        linkText: 'Landing',
        meta: { canAccess: canAccessLanding },
        index: 0,
        omitFromNav: true,
    },
];

export default routes;
