<template>
    <div v-if="isVisible" class="ag-button-container">
        <v-btn depressed outlined class="ag-btn" @click="onClick">
            {{ params.text }}
        </v-btn>
    </div>
</template>

<script>
import Vue from 'vue';
import { has } from 'lodash';

export default Vue.extend({
    computed: {
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },
    },

    methods: {
        onClick() {
            this.params.onClick(this.params);
            this.params.api.refreshCells();
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.ag-button-container {
    display: flex;
    flex-direction: column;
}
</style>
