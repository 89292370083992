<template>
    <v-container fluid>
        <header>
            <v-app-bar app class="promo-toolbar elevation-3">
                <v-toolbar-items class="toolbar-items">
                    <v-img :src="logo" width="180px" />
                </v-toolbar-items>
            </v-app-bar>
        </header>
        <v-form v-model="valid" class="form-signin">
            <h2 class="form-signin-heading">Please sign in</h2>
            <v-text-field
                v-model="user.username"
                label="email"
                type="username"
                :rules="rules.email"
                autocomplete="false"
                required
            />
            <v-text-field
                v-model="user.password"
                label="password"
                type="password"
                :rules="rules.password"
                autocomplete="false"
                required
                @keyup.enter="onLogin()"
            />
            <v-btn id="submit" color="primary" :disabled="!valid" @click="onLogin()">
                submit
            </v-btn>
        </v-form>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import logo from '../../../img/retail-promotions-logo.png';
import defaultRouteMixin from '../mixins/default-route-mixin';

export default {
    mixins: [defaultRouteMixin],
    data() {
        return {
            user: {
                username: '',
                password: '',
            },
            rules: {
                email: [v => !!v || 'Email is required'],
                password: [v => !!v || 'Password is required'],
            },
            valid: true,
            logo,
        };
    },
    computed: {
        returnPath() {
            let returnPath =
                this.defaultRoute !== '/'
                    ? this.defaultRoute
                    : this.currentReturnPathRoute || this.defaultRoute;
            if (returnPath === '/login') returnPath = this.defaultRoute;
            return returnPath;
        },
    },
    methods: {
        ...mapActions('context', ['loginHardcoded']),
        onLogin() {
            return this.loginHardcoded(this.user).then(() => {
                this.$router.push({ path: this.returnPath });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.form-signin {
    max-width: 33rem;
    padding: 1.5rem;
    margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 1rem;
}
.form-signin .checkbox {
    font-weight: normal;
}
.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.6rem;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type='email'] {
    margin-bottom: -0.1rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type='password'] {
    margin-bottom: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.promo-toolbar {
    background-color: $promo-white;
    z-index: $main-navbar-z-index;
}

.toolbar-items {
    align-items: center;
}
</style>
