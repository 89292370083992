var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page__filter-bar" },
        [_c("filter-bar", { attrs: { "max-filter-count": 3 } })],
        1
      ),
      _vm._v(" "),
      _c("promo-planner", {
        attrs: { "preparation-view": "" },
        scopedSlots: _vm._u([
          {
            key: "details-component",
            fn: function () {
              return [_c("promotion-resources-form-wrapper")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }