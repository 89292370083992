var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ag-grid-vue", {
    staticClass: "ag-theme-custom",
    class: _vm.gridClass,
    style: _vm.gridStyle,
    attrs: {
      "row-data": _vm.rowData,
      "column-defs": _vm.columnDefs,
      "grid-options": _vm.gridOptionsExtended,
      "default-col-def": _vm.defaultColDefExtended,
      "edit-type": _vm.editType,
      "single-click-edit": _vm.singleClickEdit,
      modules: _vm.modules,
      "group-headers": "",
      "auto-params-refresh": "",
      "dom-layout": _vm.domLayout,
    },
    on: {
      "grid-ready": _vm.onReady,
      "cell-value-changed": _vm.onCellValueChanged,
      "cell-key-down": _vm.onCellKeyDown,
      "row-data-updated": _vm.onRowDataUpdated,
      "model-updated": _vm.onModelUpdated,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }