import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parkinglot-grid-container" },
    [
      _c("overview-filter", {
        attrs: { "include-date-filter": "" },
        on: { change: _vm.handleSelectorChange },
      }),
      _vm._v(" "),
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 100%;",
          "grid-class": "ag-theme-custom__parkinglot-grid",
          "dom-layout": "normal",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _vm._v(" "),
      _c("promotion-viewer-dialog", {
        key: `promotion-viewer::${_vm.selectedPromotion._id}::${_vm.promotionToggle}`,
        ref: "promotion-viewer-dialog",
        attrs: { promotion: _vm.selectedPromotion, "is-past-promotions": "" },
        on: {
          close: function ($event) {
            _vm.promotionToggle = !_vm.promotionToggle
          },
        },
      }),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "status-bar__action--right",
          attrs: { primary: "", disabled: _vm.allocationDialogDisabled },
          on: { click: _vm.openAllocationDialog },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("parkingLot.allocateSelectedPromotions")
                )
              ) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("allocate-past-promotions", {
        ref: "promotion-allocation-dialog",
        attrs: {
          "promotion-ids": _vm.selectedPromotionIds,
          "has-activator": false,
          "is-past-promotions": "",
        },
        on: { confirm: _vm.confirmAllocatedPromotions },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }