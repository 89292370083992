<template functional>
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Locked</title>
        <g id="Icon-/-Locked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                id="Combined-Shape"
                d="M7.72500022,2 C9.35512515,2 10.6781251,3.344 10.6781251,5 L10.6781251,5 L10.6781251,6.2 L11.2687501,6.2 C11.9184375,6.2 12.45,6.74 12.45,7.4 L12.45,7.4 L12.45,13.4 C12.45,14.06 11.9184375,14.6 11.2687501,14.6 L11.2687501,14.6 L4.18125009,14.6 C3.53156262,14.6 3,14.06 3,13.4 L3,13.4 L3,7.4 C3,6.74 3.53156262,6.2 4.18125009,6.2 L4.18125009,6.2 L4.77187506,6.2 L4.77187506,5 C4.77187506,3.344 6.0948753,2 7.72500022,2 Z M7.72500022,9.2 C7.07531275,9.2 6.54375028,9.74 6.54375028,10.4 C6.54375028,11.06 7.07531275,11.6 7.72500022,11.6 C8.37468769,11.6 8.90625017,11.06 8.90625017,10.4 C8.90625017,9.74 8.37468769,9.2 7.72500022,9.2 Z M7.72500022,3.14 C6.71503152,3.14 5.89406281,3.974 5.89406281,5 L5.89406281,5 L5.89406281,6.2 L9.55593764,6.2 L9.55593764,5 C9.55593764,3.974 8.73496892,3.14 7.72500022,3.14 Z"
                fill="#2F477C"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Locked',
};
</script>
