var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.renderNotes
    ? _c("notes", {
        attrs: { notes: _vm.notes, disabled: _vm.isDisabled, "open-right": "" },
        on: { delete: _vm.removeNote, post: _vm.postNote, save: _vm.saveNote },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }