import Vue from 'vue';
import i18n from '@/js/vue-i18n';

/**
 * See the following stack overflow for more in depth description: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @param {Blob} data - Blob should be passed in
 * @param {String} resource - Name of the resource we are downloading
 */
async function downloadExcelData(data, resource) {
    const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a temporary link to download the data and remove when done
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${resource}-${i18n.d('dates.default.shortDate', Vue.moment())}.xlsx`;
    link.click();
    link.remove();
}

export default downloadExcelData;
