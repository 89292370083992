var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promotion-allocation-area" }, [
    _vm.selectedResourceDefinition
      ? _c(
          "div",
          { staticClass: "promotion-allocation-area__grid" },
          [
            _c(
              "div",
              {
                staticClass:
                  "promotion-allocation-area__grid-cell promotion-allocation-area__title",
                style: _vm.getSpanCss(9),
              },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("preparation.allocation.promotions")
                        )
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell--sidebar-heading",
              style: _vm.getSpanCss(9),
            }),
            _vm._v(" "),
            _vm._l(_vm.filteredPromotions, function (promotion, rowIndex) {
              return [
                _vm._l(_vm.fields, function (item, colIndex) {
                  return _c(
                    "div",
                    {
                      key: promotion._id + "::" + rowIndex + "::" + colIndex,
                      ref: `promotion_${promotion._id}`,
                      refInFor: true,
                      staticClass:
                        "promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell--promotion-content",
                      class: [item.cellClasses, item.contentClasses],
                      on: {
                        click: function ($event) {
                          return _vm.toggleSelectedPromotion({
                            promotionId: promotion._id,
                          })
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "promotion-allocation-area__data",
                          class: _vm.getColourClass(promotion),
                        },
                        [
                          item.functionName
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.callAction(
                                        item.functionName,
                                        promotion
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : item.component
                            ? _c(
                                "div",
                                [
                                  item.featureToggle
                                    ? _c(
                                        "feature-toggle",
                                        {
                                          attrs: { toggle: item.featureToggle },
                                        },
                                        [
                                          _c(
                                            item.component.name,
                                            _vm._g(
                                              _vm._b(
                                                { tag: "component" },
                                                "component",
                                                _vm.constructVBindObj({
                                                  context: promotion,
                                                  field: item.field,
                                                  ...item.component,
                                                }),
                                                false
                                              ),
                                              _vm.constructVBindEventObj({
                                                events: _vm.getAttribute(
                                                  item.component,
                                                  "events"
                                                ),
                                                context: promotion,
                                              })
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("toSentenceCase")(
                                                    _vm.$t(item.component.text)
                                                  )
                                                ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        item.component.name,
                                        _vm._g(
                                          _vm._b(
                                            { tag: "component" },
                                            "component",
                                            _vm.constructVBindObj({
                                              context: promotion,
                                              field: item.field,
                                              ...item.component,
                                            }),
                                            false
                                          ),
                                          _vm.constructVBindEventObj({
                                            events: _vm.getAttribute(
                                              item.component,
                                              "events"
                                            ),
                                            context: promotion,
                                          })
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toSentenceCase")(
                                                _vm.$t(item.component.text)
                                              )
                                            ) + "\n                        "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getAttribute(promotion, item.field)
                                  )
                                ),
                              ]),
                        ]
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.isSelected(promotion)
                  ? _c("promotion-products", {
                      key: "promotion-products" + rowIndex + promotion._id,
                      attrs: {
                        "promotion-id": promotion._id,
                        "is-products-disabled":
                          _vm.isProductsDisabled(promotion),
                        "assigned-products": _vm.getAssignedProducts(promotion),
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }