var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pagination-container",
      class: {
        "pagination-container__disabled": !_vm.selectedResourceDefinition,
      },
    },
    [
      _c("span", { staticClass: "pagination-container__label" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("preparation.templates.numberOfPages")
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.hasAccesToPageCountDropDown
        ? _c("vuex-select", {
            staticClass: "pagination-container__select",
            attrs: {
              options: _vm.pages,
              getter: _vm.pagesGetter,
              setter: _vm.pagesSetter,
              disabled: !_vm.selectedResourceDefinition,
              placeholder: "-",
            },
          })
        : _c("div", { staticClass: "pagination-container__no-of-pages" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.selectedResourceDefinition.pageCount) +
                "\n    "
            ),
          ]),
      _vm._v(" "),
      _c("span", { staticClass: "pagination-container__label" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(_vm.$t("preparation.templates.page"))
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("pagination", {
        staticClass: "pagination-container__pagination",
        attrs: {
          length: _vm.pageCount,
          disabled: _vm.isPaginationDisabled,
          "colored-pages": _vm.completedPages,
          "disabled-pages": _vm.disabledPages,
          "highlighted-pages": _vm.highlightedPages,
          "is-basic-navigate-button": "",
          "use-arrow-as-pagination": "",
        },
        model: {
          value: _vm.selectedPage,
          callback: function ($$v) {
            _vm.selectedPage = $$v
          },
          expression: "selectedPage",
        },
      }),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "dialog_confirm",
        staticClass: "confirm-dialog",
        attrs: {
          "has-activator": false,
          "question-text": _vm.$t("preparation.templates.confirmModal.heading"),
          "warning-text": _vm.$t("preparation.templates.confirmModal.message"),
          "action-text": _vm.$t("preparation.templates.confirmModal.confirm"),
          "auto-close-on-confirm": false,
        },
        on: { confirm: _vm.updatePageCount, close: _vm.cancelUpdatePageCount },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }