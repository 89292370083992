import {
    merge,
    find,
    maxBy,
    filter,
    orderBy,
    uniq,
    forEach,
    get,
    isEmpty,
    uniqBy,
    map,
    reduce,
    each,
} from 'lodash';
import comparableWeeksEnum from '@enums/comparable-weeks';
import Vue from 'vue';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import storeMixin from '@/js/store/mixins/vuex-store';

const metricStrings = [
    'actualSalesExcTax',
    'incrementalSalesExcTax',
    'promoMarginRate',
    'incrementalMargin',
];

const getInitialState = () => ({
    weeks: [],
    comparableWeek: 0,

    /**
     * Week and comparable week metrics Object
     * @typedef {{ w: KPIMetrics, cw: KPIMetrics} ComparableWeekMetrics
     */

    /**
     * KPI metrics Object
     * @typedef {{ actualSalesExcTax: number, incrementalSalesExcTax: number, promoMarginRate: number, incrementalMargin: number }} KPIMetrics
     */

    /**
     * Map object to store week/comparable week delta KPI metrics
     *
     * @const
     * @type {Object.<string, ComparableWeekMetrics>}
     */
    deltaKPIMetricsMap: {},
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getWeeksById
     */
    getters: {
        getWeeksForCampaign: (state, getters) => campaign => {
            const startWeek = getters.getWeekByDate(campaign.startDate);
            const endWeek = getters.getWeekByDate(campaign.startDate);
            return filter(state.weeks, week => {
                return (
                    Vue.moment(startWeek.startDate)
                        .utc()
                        .isSameOrBefore(week.startDate, 'day') &&
                    Vue.moment(endWeek.endDate)
                        .utc()
                        .isSameOrAfter(week.endDate, 'day')
                );
            }).map(week => Number(`${week.year}${week.weekOfYear}`));
        },
        getCampaignsByWeek: (state, getters) => ({ resources }) => {
            return reduce(
                resources,
                (acc, resource) => {
                    const weeks = getters.getWeeksForCampaign(resource);
                    each(weeks, week => {
                        acc[week] = (acc[week] || new Set()).add(resource._id);
                    });
                    return acc;
                },
                {}
            );
        },
        getYearWeek: state => ({ year, weekNumber }) =>
            find(state.weeks, week => {
                // We don't have weekNumber field in weeks collection
                // https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1213727239/Weeks+collection
                // use weekOfYear
                return week.year === year && week.weekOfYear === weekNumber;
            }),

        // Weeks are splitted into quarters to improve searching week by date performance
        weeksSplitByYearQuarter: state => {
            return state.weeks.reduce((acc, week) => {
                const startDate = Vue.moment(week.startDate).utc();
                const endDate = Vue.moment(week.endDate).utc();

                // handling edge case when week is on the edge of quartes or years (quarters will also be different)
                // by adding it into both quarters' arrays
                if (startDate.quarter() !== endDate.quarter()) {
                    const startDateYear = startDate.year();
                    const endDateYear = endDate.year();
                    const startDateQuarter = startDate.quarter();
                    const endDateQuarter = endDate.quarter();

                    if (!acc[startDateYear]) {
                        acc[startDateYear] = {};
                    }
                    if (!acc[endDateYear]) {
                        acc[endDateYear] = {};
                    }
                    if (!acc[startDateYear][startDateQuarter]) {
                        acc[startDateYear][startDateQuarter] = [];
                    }
                    if (!acc[endDateYear][endDateQuarter]) {
                        acc[endDateYear][endDateQuarter] = [];
                    }
                    acc[startDateYear][startDateQuarter].push(week);
                    acc[endDateYear][endDateQuarter].push(week);
                    return acc;
                }

                if (!acc[week.year]) {
                    acc[week.year] = {};
                }
                if (!acc[week.year][week.quarter]) {
                    acc[week.year][week.quarter] = [];
                }
                acc[week.year][week.quarter].push(week);
                return acc;
            }, {});
        },

        getWeekByDate: (state, getters) => date => {
            const momentDate = Vue.moment(date).utc();
            const quarterWeeksForDate = get(getters.weeksSplitByYearQuarter, [
                momentDate.year(),
                momentDate.quarter(),
            ]);
            if (quarterWeeksForDate) {
                const match = find(quarterWeeksForDate, week => {
                    // '[]' ensures an inclusive check between the start and end dates is performed.
                    return momentDate.isBetween(
                        Vue.moment(week.startDate).utc(),
                        Vue.moment(week.endDate).utc(),
                        'day',
                        '[]'
                    );
                });
                if (match) return match;
            }
            // If there is some weird mapping finish with a brute force
            return find(state.weeks, week => {
                // '[]' ensures an inclusive check between the start and end dates is performed.
                return momentDate.isBetween(
                    Vue.moment(week.startDate).utc(),
                    Vue.moment(week.endDate).utc(),
                    'day',
                    '[]'
                );
            });
        },

        getNumberOfWeeksInYear: state => year => {
            const weeksInYear = filter(state.weeks, week => {
                return week.year === year;
            });

            const maxWeekObj = maxBy(weeksInYear, 'weekOfYear');

            return get(maxWeekObj, 'weekOfYear');
        },

        getWeeksBetweenDates: state => ({ startDate, endDate }) => {
            startDate = Vue.moment(startDate);
            endDate = Vue.moment(endDate);

            // Return the weeks which start and end within the given date boundary.
            return filter(state.weeks, week => {
                return (
                    startDate.isSameOrBefore(week.startDate, 'day') &&
                    endDate.isSameOrAfter(week.endDate, 'day')
                );
            });
        },

        getWeeksInScopeOfYearAndWeeklyView: (state, getters) => year => {
            const firstWeek = getters.firstWeekObj;
            const lastWeek = getters.lastWeekObj;

            // Filter weeks in scope of the year and the weekly view (defined by firstWeek & lastWeek)
            const weeksInScope = filter(state.weeks, week => {
                return (
                    (Vue.moment(week.startDate)
                        .utc()
                        .isBetween(
                            Vue.moment(firstWeek.startDate).utc(),
                            Vue.moment(lastWeek.endDate).utc()
                        ) ||
                        Vue.moment(week.endDate)
                            .utc()
                            .isBetween(
                                Vue.moment(firstWeek.startDate).utc(),
                                Vue.moment(lastWeek.endDate).utc()
                            )) &&
                    week.year === year
                );
            });

            return weeksInScope.map(week => week.weekOfYear);
        },

        getAllYearsInWeeklyView: (state, getters) => {
            const firstWeek = getters.firstWeekObj;
            const lastWeek = getters.lastWeekObj;

            // Filter weeks in scope of the first/last week objects.
            const weeksInScope = filter(state.weeks, week => {
                return (
                    Vue.moment(week.startDate)
                        .utc()
                        .isBetween(
                            Vue.moment(firstWeek.startDate).utc(),
                            Vue.moment(lastWeek.endDate).utc()
                        ) ||
                    Vue.moment(week.endDate)
                        .utc()
                        .isBetween(
                            Vue.moment(firstWeek.startDate).utc(),
                            Vue.moment(lastWeek.endDate).utc()
                        )
                );
            });

            return orderBy(uniq(weeksInScope.map(week => week.year)));
        },

        firstWeekObj: (state, getters, rootState) => {
            const firstWeekCalendarViewRestrictionFunction = createFeatureAwareFactory(
                rootState.clientConfig.toggleLogic
            ).getFirstWeekCalendarViewRestrictionFunction();

            return firstWeekCalendarViewRestrictionFunction({
                getWeekByDate: getters.getWeekByDate,
                limitationPeriodConfig: rootState.clientConfig.generalConfig.limitationPeriod,
            });
        },

        lastWeekObj: (state, getters, rootState) => {
            const lastWeekCalendarViewRestrictionFunction = createFeatureAwareFactory(
                rootState.clientConfig.toggleLogic
            ).getLastWeekCalendarViewRestrictionFunction();

            return lastWeekCalendarViewRestrictionFunction({
                getWeekByDate: getters.getWeekByDate,
                getYearWeek: getters.getYearWeek,
                getNumberOfWeeksInYear: getters.getNumberOfWeeksInYear,
                limitationPeriodConfig: rootState.clientConfig.generalConfig.limitationPeriod,
                campaigns: rootState.campaigns.campaigns,
            });
        },

        isComparableWeekW: state => {
            return state.comparableWeek === comparableWeeksEnum.w;
        },

        isComparableWeekCW: state => {
            return state.comparableWeek === comparableWeeksEnum.cw;
        },

        getDeltaKPIMetrics: (state, getters) => weekKey => {
            let deltaKPIMetrics = state.deltaKPIMetricsMap[weekKey];
            if (deltaKPIMetrics) {
                deltaKPIMetrics = getters.isComparableWeekCW
                    ? deltaKPIMetrics.cw
                    : deltaKPIMetrics.w;
            }
            return deltaKPIMetrics;
        },

        getYearOptions: state => {
            const yearOptions = map(uniqBy(state.weeks, 'year'), ({ year }) => {
                return {
                    key: year.toString(),
                    value: year.toString(),
                };
            });

            return yearOptions;
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setWeeks
     * - deleteWeek
     * - updateWeek
     * - addWeek
     * - resetState
     */
    mutations: {
        setComparableWeek(state, comparableWeek) {
            state.comparableWeek = comparableWeek;
        },
        updateDeltaKPIMetricsMap(state, { weeklyMetrics }) {
            const getDeltaKPIMetric = (currentWeek, previousWeek) => {
                const currentKPIMetrics = get(weeklyMetrics[currentWeek], 'KPIMetrics');
                const previousKPIMetrics = get(weeklyMetrics[previousWeek], 'KPIMetrics');

                const weekDeltasMap = {};
                if (isEmpty(currentKPIMetrics)) return;

                forEach(metricStrings, metric => {
                    const deltaValue =
                        currentKPIMetrics[metric] - get(previousKPIMetrics, metric, 0);
                    weekDeltasMap[metric] = deltaValue;
                });

                return weekDeltasMap;
            };

            // save delta for KPI metrics for each week
            forEach(state.weeks, weekObj => {
                const currentWeekKey = `${weekObj.year}${weekObj.weekOfYear}`;

                let previousWeekKey = `${weekObj.mappedYear}${weekObj.mappedWeek}`;

                if (!state.deltaKPIMetricsMap[currentWeekKey]) {
                    Vue.set(state.deltaKPIMetricsMap, currentWeekKey, {});
                }

                Vue.set(
                    state.deltaKPIMetricsMap[currentWeekKey],
                    'cw',
                    getDeltaKPIMetric(currentWeekKey, previousWeekKey)
                );

                previousWeekKey = `${weekObj.year - 1}${weekObj.weekOfYear}`;
                Vue.set(
                    state.deltaKPIMetricsMap[currentWeekKey],
                    'w',
                    getDeltaKPIMetric(currentWeekKey, previousWeekKey)
                );
            });
        },
    },

    /**
     * Default actions available:
     * - fetchWeeks
     * - createWeek
     * - deleteWeek
     * - updateWeek
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        updateDeltaKPIMetricsMap({ commit }, options) {
            commit('updateDeltaKPIMetricsMap', options);
        },
    },
};

const mixinParams = {
    resource: 'week',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
