import Vue from 'vue';
import axios from 'axios';
import to from 'await-to-js';
import { camelCase, findIndex, filter, pick } from 'lodash';
import namespaces from '@enums/namespaces';
import i18n from '../../vue-i18n';

const createWriteVuexAddOns = ({ resource, pluralResourceName, apiPath }) => {
    return {
        state: {},
        getters: {},
        mutations: {
            [camelCase(`delete-${resource}`)](state, id) {
                state[camelCase(pluralResourceName)] = filter(
                    state[camelCase(pluralResourceName)],
                    item => item._id !== id
                );
            },
            [camelCase(`update-${resource}`)](state, { id, updates }) {
                const targetIx = findIndex(
                    state[camelCase(pluralResourceName)],
                    item => item._id === id
                );
                const document = state[camelCase(pluralResourceName)][targetIx];
                const updatedDocument = { ...document, ...updates };
                Vue.set(state[camelCase(pluralResourceName)], targetIx, updatedDocument);
            },

            [camelCase(`add-${resource}`)](state, document) {
                state[camelCase(pluralResourceName)].push(document);
            },
        },
        actions: {
            // CREATE Resource
            async [camelCase(`create-${resource}`)](
                { commit, dispatch },
                { document, refreshData = false, refreshParams = {} }
            ) {
                commit('setLoading', true);
                const [error, response] = await to(axios.post(apiPath, document));
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notifications.saveSuccess`, { resource }),
                    errorMessage: i18n.t(`notifications.saveError`, { resource }),
                });
                if (error) {
                    commit('setLoading', false);
                    return { error }; // Return error so components can choose whether to perform another action
                }
                if (refreshData) {
                    dispatch(camelCase(`fetch-${resource}`), { params: refreshParams });
                } else {
                    commit(camelCase(`add-${resource}`), response.data);
                    commit('setLoading', false);
                }
                return { result: response.data };
            },

            // Bulk upload resources.
            async [camelCase(`bulk-upload-${pluralResourceName}`)](
                { dispatch },
                { dataFile, onUpload }
            ) {
                const [error, response] = await to(
                    axios.post(`${apiPath}/upload`, dataFile, {
                        headers: {
                            // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
                            // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
                            'Content-Type': undefined,
                        },
                    })
                );

                await dispatch('handleResponseNotifications', {
                    response,
                    error,
                    successMessage: i18n.t('notifications.uploadSuccess', {
                        resource: pluralResourceName,
                    }),
                    errorMessage: i18n.t('notifications.uploadError', {
                        resource: pluralResourceName,
                    }),
                });

                if (onUpload) {
                    return onUpload(dispatch);
                }
            },

            // DELETE Resource
            async [camelCase(`delete-${resource}`)](
                { dispatch, commit },
                { id, refreshData = false, refreshParams = {} }
            ) {
                commit('setLoading', true);
                const [error, response] = await to(axios.delete(`${apiPath}/${id}`));
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notifications.deleteSuccess`, { resource }),
                    errorMessage: i18n.t(`notifications.deleteError`, { resource }),
                });
                if (error) return { error }; // Return error so components can choose whether to perform another action
                if (refreshData) {
                    dispatch(camelCase(`fetch-${pluralResourceName}`, { params: refreshParams }));
                } else {
                    commit(camelCase(`delete-${resource}`), id);
                    commit('setLoading', false);
                }
                return { result: response.data };
            },

            // UPDATE RESOURCE
            async [camelCase(`update-${resource}`)](
                { commit, dispatch },
                { id, updates, refreshData = false, refreshParams = {} }
            ) {
                commit('setLoading', true);
                const [error, response] = await to(axios.patch(`${apiPath}/${id}`, updates));

                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notifications.saveSuccess`, { resource }),
                    errorMessage: i18n.t(`notifications.saveError`, { resource }),
                });
                if (error) {
                    commit('setLoading', false);
                    return { error }; // Return error so components can choose whether to perform another action
                }
                if (refreshData) {
                    dispatch(camelCase(`fetch-${pluralResourceName}`, { params: refreshParams }));
                } else {
                    commit(camelCase(`update-${resource}`), { id, updates: response.data });
                    commit('setLoading', false);
                }
                return { result: response.data };
            },

            // UPDATE MANY RESOURCES
            // updateMany is not supported by default on the BE side
            // check the following PR for additional work that needs to be done on the BE side
            // https://bitbucket.org/oliverwymantechssg/rtls-promo-v3/pull-requests/596/feature-proweb-755-assign-a-proxy-to-a
            async [camelCase(`update-${pluralResourceName}`)](
                { commit, dispatch },
                { updates, refreshData = true, refreshParams = {} }
            ) {
                commit('setLoading', true);
                const [error, response] = await to(axios.patch(`${apiPath}`, updates));

                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notifications.saveSuccess`, { pluralResourceName }),
                    errorMessage: i18n.t(`notifications.saveError`, { pluralResourceName }),
                });
                if (error) return { error }; // Return error so components can choose whether to perform another action
                if (refreshData) {
                    dispatch(camelCase(`fetch-${pluralResourceName}`, { params: refreshParams }));
                } else {
                    commit('setLoading', false);
                }

                return { result: response.data };
            },

            async submitForm(
                { state, dispatch },
                {
                    editMode = false,
                    editableFields,
                    namespace = namespaces.default,
                    refreshData = false,
                    refreshParams = {},
                    submitAction,
                } = {}
            ) {
                const payload = state.stagingArea[namespace];
                if (!editMode) {
                    // When adding a new document from the front end it will never have a valid mongoId
                    // In some cases we create a temporary _id so we can use it before committing it to mongo
                    // and therefore we need to make sure it is not present on save
                    delete payload._id;
                    return dispatch(submitAction || camelCase(`create-${resource}`), {
                        document: payload,
                        refreshData,
                        refreshParams,
                    });
                }

                // the stagingArea contains values for all fields that are visible in the form
                // not all of these are editable and so should not be included
                const updates = editableFields ? pick(payload, editableFields) : payload;
                return dispatch(submitAction || camelCase(`update-${resource}`), {
                    updates,
                    id: namespace,
                    refreshData,
                    refreshParams,
                });
            },
        },
    };
};

export default createWriteVuexAddOns;
