<template functional>
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
        <title>arrows-alt</title>
        <desc>Created with Sketch.</desc>
        <defs>
            <polygon
                id="path-1x1"
                points="12.3821049 14.625 9 18 5.62039123 14.625 7.875 14.625 7.875 10.125 3.375 10.125 3.375 12.3796961 0 9.00002197 3.375 5.625 3.375 7.875 7.875 7.875 7.875 3.375 5.62039123 3.375 9 0 12.3821049 3.375 10.125 3.375 10.125 7.875 14.65327 7.875 14.65327 5.61799237 18 9.00002197 14.65327 12.3796961 14.65327 10.125 10.125 10.125 10.125 14.625"
            />
        </defs>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-/-Move-/-Blue">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1x1" />
                </mask>
                <use id="arrows-alt" fill="#2F477C" fill-rule="nonzero" xlink:href="#path-1x1" />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CrossMove',
};
</script>
