import Vue from 'vue';
import axios from 'axios';
import to from 'await-to-js';
import { camelCase, find, get, merge, keyBy, isMatch } from 'lodash';
import CustomHTTPHeaders from '@enums/custom-http-headers';
import workflowUtilsFactory from '@sharedModules/workflow-utils-factory';
import i18n from '../../vue-i18n';
import severityEnums from '@enums/severity';

import createNominationMatrixVuexAddOns from './vuex-nomination-matrix-store-add-ons';
import createNotesVuexAddOns from './vuex-notes-store-add-ons';
import createWorkflowVuexAddOns from './vuex-workflow-store-add-ons';
import { createFiltersStoreAddOns, getInitialFilterState } from './vuex-filters-store-add-ons';
import createWriteAddOns from './vuex-write-store-add-ons';
import filtersMap from './vuex-filters-map';
import { storeFormMixin, getInitialFormState } from './vuex-form-store';
import { toSentenceCase } from '@/js/utils/string-utils';

const storeMixin = ({
    resource,
    alternativeApiPath,
    getInitialState = () => ({}),
    isPlural = false,
    useForm = false,
    useNotes = false,
    useWorkflow = false,
    useFilters = false,
    useNominationMatrix = false,
    readOnly = false,
}) => {
    const workflowUtils = workflowUtilsFactory(Vue.moment);

    const pluralResourceName = isPlural ? resource : `${resource}s`;
    const pluralResourceNameCamelCase = camelCase(pluralResourceName);
    const apiPath = alternativeApiPath
        ? `/api/${alternativeApiPath}`
        : `/api/${pluralResourceName}`;
    let module = {
        state: {
            loading: false,
        },

        getters: {
            [camelCase(`get-${resource}-by-id`)]: state => ({ _id, usePluralResourceName }) => {
                const resourceName = usePluralResourceName ? `${resource}s` : resource;
                return find(state[`${camelCase(resourceName)}`], { _id });
            },
            /**
             * Iterates over a series of child objects for a given entity ID, aggregating all workflow states together,
             * and producing a single object as the output which represents the states across all children. The states
             * are aggregated using AND logic, where a single state field is true only if that state exists and is true
             * for all children.
             */
            getChildWorkflowStates: (state, getters, rootState, rootGetters) => ({
                namespace,
                getter,
                id,
            }) => {
                const children = rootGetters[`${namespace}/${getter}`](id);

                if (!children) {
                    throw new Error(
                        `Expected a value for 'children'. This indicates that ${namespace}/${getter} could not be executed for ID ${id}.`
                    );
                }

                const childWorkflowStates = children.map(child =>
                    rootGetters[`${namespace}/entityWorkflowStateById`]({
                        id: child._id,
                        getParentEntities: false,
                        getChildEntities: true,
                    })
                );

                return workflowUtils.combineStates(childWorkflowStates);
            },
        },

        mutations: {
            setLoading(state, isLoading) {
                state.loading = isLoading;
            },
            [camelCase(`set-${pluralResourceName}`)](state, data) {
                state[`${camelCase(pluralResourceName)}`] = data;
            },

            resetState(state) {
                let initialState = getInitialState();

                if (useForm) {
                    initialState = { ...initialState, ...getInitialFormState() };
                }

                if (useFilters) {
                    initialState = {
                        ...initialState,
                        ...getInitialFilterState(pluralResourceName),
                    };
                }

                Object.assign(state, initialState);
            },
        },

        actions: {
            // Fetch Resources
            async [camelCase(`fetch-${pluralResourceName}`)](
                { state, commit, dispatch, rootState },
                {
                    params = {},
                    useFilter = false,
                    patchState = false,
                    patchOptions = {
                        fieldToMatchOn: '_id',
                        isMatchFunction: isMatch,
                    },
                    returnDocuments = false,
                    storeDocuments = true,
                } = {}
            ) {
                if (useFilter) {
                    const filters = filtersMap[pluralResourceNameCamelCase];
                    const filterKeys = Object.keys(filters);

                    const where = filterKeys.reduce((acc, key) => {
                        // by default filter key name is the same as field key
                        // can be set explicitly with fieldKey in filtersMap config
                        const fieldKey = filters[key].fieldKey || key;

                        if (rootState[filters[key].module].filter !== undefined) {
                            acc[fieldKey] = rootState[filters[key].module].filter[fieldKey];
                        }
                        return acc;
                    }, {});
                    params = { ...params, where: { ...params.where, ...where } };
                }

                commit('setLoading', true);

                const [error, response] = await to(axios.get(apiPath, { params }));

                if (error) {
                    dispatch('handleResponseNotifications', {
                        error,
                        errorMessage: i18n.t('notifications.fetchError', {
                            resource: `${resource}s`,
                        }),
                    });

                    commit('setLoading', false);

                    return;
                }

                const { data: newResources } = response;

                // returnDocuments controls whether or not we return the documents here,
                // or if we carry on to mutate state.
                if (returnDocuments) {
                    commit('setLoading', false);
                    return newResources;
                }

                // If patchState is false, then the resources array is replaced by the data in the response.
                if (!patchState && storeDocuments) {
                    commit(camelCase(`set-${pluralResourceName}`), newResources);
                }

                // If patchState is true, then we want to persist the original array and make modifications to it based
                // on the data in the response.
                if (patchState && storeDocuments) {
                    if (!params.where) {
                        throw new Error(
                            'patchState is true, but where property is not specified in params'
                        );
                    }

                    // updatedResources will be the new array which be set in the store.
                    let updatedResources = [];

                    // Key the new resources for a more efficient lookup in the loop below.
                    const keyedResponseData = keyBy(newResources, patchOptions.fieldToMatchOn);

                    // Loop through the original resources array.
                    state[`${camelCase(pluralResourceName)}`].forEach(resourceItem => {
                        // If the original resource item does not match the supplied filter, then we know this item
                        // should remain unchanged in the new array.
                        if (!patchOptions.isMatchFunction(resourceItem, { ...params.where })) {
                            updatedResources.push(resourceItem);
                        }
                        // Otherwise, this resource item will either have been updated in the response, or removed
                        // completely.
                        else {
                            // Attempt to retrieve the matching item from the new resources array.
                            const updatedResourceItem =
                                keyedResponseData[resourceItem[patchOptions.fieldToMatchOn]];

                            // If a matching item was found, add it to the updated resources array. This essentially
                            // replaces the existing original item, preserving its order in the array.
                            // Otherwise, do nothing, indicating that the item has been removed.
                            if (updatedResourceItem) {
                                updatedResources.push(updatedResourceItem);

                                // Remove the updated item to indicate that it has already been processed.
                                delete keyedResponseData[resourceItem[patchOptions.fieldToMatchOn]];
                            }
                        }
                    });

                    // The final step is to add any remaining resources from keyedResponseData. This will include all new
                    // resources which need adding.
                    updatedResources = [...updatedResources, ...Object.values(keyedResponseData)];

                    commit(camelCase(`set-${pluralResourceName}`), updatedResources);
                }

                commit('setLoading', false);
            },

            async [camelCase(`fetch-${resource}-by-id`)](
                { state, commit, dispatch },
                { id, patchState = true, returnDocument = false } = {}
            ) {
                const [error, response] = await to(axios.get(`${apiPath}/${id}`));

                if (error) {
                    dispatch('handleResponseNotifications', {
                        error,
                        errorMessage: i18n.t('notifications.fetchError', { resource }),
                    });

                    return;
                }

                const { data: newResource } = response;

                // returnDocuments controls whether or not we return the documents here,
                // or if we carry on to mutate state.
                if (returnDocument) {
                    return newResource;
                }

                // If patchState is false, then the resources array is replaced by the data in the response.
                if (!patchState) {
                    commit(camelCase(`set-${pluralResourceName}`), [newResource]);
                }

                // If patchState is true, then we want to persist the original array and either append or replace the
                // resource being retrieved.
                if (patchState) {
                    // updatedResources will be the new array which be set in the store. This will container all entries
                    // in the store.
                    const updatedResources = state[`${camelCase(pluralResourceName)}`];

                    const resourceIndex = updatedResources.findIndex(({ _id }) => _id === id);

                    // If the resource does not already exist, then add it to the array.
                    if (resourceIndex === -1) updatedResources.push(newResource);
                    else {
                        // Otherwise, replace the entry in the array with the new data.
                        updatedResources.splice(resourceIndex, 1, newResource);
                    }

                    commit(camelCase(`set-${pluralResourceName}`), updatedResources);
                }
            },

            handleResponseNotifications(
                { dispatch, rootState },
                { error, response, successMessage, errorMessage, warningMessage }
            ) {
                let message = successMessage;
                let severity = severityEnums.success;

                const warning = get(
                    response,
                    ['headers', CustomHTTPHeaders.rtlsWarning.toLowerCase()],
                    false
                );
                if (error) {
                    // The error message may have a custom Joi error message which needs to be translated.
                    // `isCustomJoiMessage` is used to perform an explicit check on whether the error response is a custom
                    // Joi error or not. This ensures backwards compatibility with other server errors which are not Joi
                    // custom errors, but still need translating.
                    const errorDetails = get(error, 'response.data.details', []);
                    const customJoiErrorMessages = [];

                    errorDetails.forEach(detail => {
                        // Custom Joi messages are provided as JSON strings which allow params to be included, so we need to try
                        // parse this into a JS object.
                        let errorMessageParsed = {
                            isCustomJoiMessage: false,
                        };
                        try {
                            // to parse JSON correctly we need to escape \n and \r with \\n and \\r
                            errorMessageParsed = JSON.parse(
                                detail.message.replace(/\n/g, '\\n').replace(/\r/g, '\\r')
                            );
                        } catch {
                            // message is not a JSON object, and so is not a custom JOI message
                        }
                        if (errorMessageParsed.isCustomJoiMessage) {
                            // If a custom joi message has been provided, get the corresponding error message using
                            // translation key and params supplied in the error response.
                            const { translationKey, params } = errorMessageParsed;
                            const parsedParams = {};
                            Object.entries(params || {}).forEach(([key, value]) => {
                                if (typeof value === 'object' && value.format) {
                                    parsedParams[key] = i18n.n(value.format, value.display);
                                } else {
                                    parsedParams[key] = value;
                                }
                            });
                            message = i18n.t(
                                `validation.customJoiMessages.${translationKey}`,
                                parsedParams
                            );
                            customJoiErrorMessages.push(message);
                        }
                    });

                    if (customJoiErrorMessages.length) {
                        // At least one custom error message was received. Combine these and return them.
                        message = customJoiErrorMessages.join(', ');
                    } else {
                        let messageKey = get(error, 'response.data.messageKey');
                        let messageParams = get(error, 'response.data.messageParams');

                        if (messageKey === 'identifier-unique') {
                            messageKey = 'middleware.errors.identifierNeedsToBeUnique';
                            messageParams = { resource: toSentenceCase(resource) };
                        }
                        if (i18n.translationExists(messageKey)) {
                            message = i18n.t(messageKey, messageParams);
                        } else if (
                            i18n.translationExists(
                                `validation.failureMessages.${camelCase(messageKey)}`
                            )
                        ) {
                            message = i18n.t(
                                `validation.failureMessages.${camelCase(messageKey)}`,
                                messageParams
                            );
                        } else {
                            message = errorMessage || i18n.t('general.errors.generalServerError');
                        }
                    }

                    severity = severityEnums.error;
                } else if (warning) {
                    const { duration, granularity } = get(
                        rootState,
                        `clientConfig.validations.${camelCase(warning)}.params`,
                        { duration: 1, granularity: 'days' }
                    );
                    const granularityMessage = i18n.tc(`time.${granularity}`, duration);
                    message = i18n.t(`validation.failureMessages.${camelCase(warning)}`, {
                        duration,
                        granularityMessage,
                    });
                    severity = severityEnums.warning;
                } else if (warningMessage) {
                    message = i18n.t(warningMessage.key, warningMessage.params);
                    severity = severityEnums.warning;
                }

                dispatch(
                    'notifications/addNotification',
                    {
                        message,
                        severity,
                    },
                    { root: true }
                );
            },

            resetState({ commit }) {
                commit('resetState');
            },
        },
    };
    if (!readOnly) {
        const writeAddOns = createWriteAddOns({ resource, pluralResourceName, apiPath });
        module = merge({}, module, writeAddOns);
    }
    if (useForm) {
        module = merge({}, module, storeFormMixin(resource));
    }
    if (useNotes) {
        const notesAddOns = createNotesVuexAddOns({ resource, apiPath });
        module = merge({}, module, notesAddOns);
    }
    if (useWorkflow) {
        const workflowAddOns = createWorkflowVuexAddOns({ resource, apiPath });
        module = merge({}, module, workflowAddOns);
    }
    if (useFilters) {
        const filtersAddOns = createFiltersStoreAddOns({
            pluralResourceName,
            pluralResourceNameCamelCase,
        });
        module = merge({}, module, filtersAddOns);
    }
    if (useNominationMatrix) {
        const matrixAddOns = createNominationMatrixVuexAddOns();
        module = merge({}, module, matrixAddOns);
    }
    return module;
};

export default storeMixin;
