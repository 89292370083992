<template>
    <feature-toggle :toggle="canCreatePromotion">
        <div
            :class="[
                'create-promotion--prefix',
                'create-promotion--first-item',
                !splitPromotionsEnabled ? 'create-promotion--prefix__margin' : '',
            ]"
        >
            <div>{{ $tkey(getPromotionTypeText()) | toSentenceCase }}</div>
            <v-menu
                v-if="splitPromotionsEnabled"
                v-model="isMenuOpen"
                :close-on-content-click="true"
                offset-y
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                    <div @mouseover="toggleCreateNewMenu(!isCreatePromotionMenuDisabled)">
                        <icon-button
                            v-bind="attrs"
                            :disabled="isCreatePromotionMenuDisabled"
                            icon="expand_more"
                            class="create-promotion--menu"
                            icon-on-right
                            v-on="on"
                        />
                    </div>
                </template>
                <v-list class="create-promotion--menu__list">
                    <template v-for="isSplitPromotion in [false, true]">
                        <v-list-item
                            v-if="isSplitPromotion !== splitPromotion"
                            :key="'promotion-type-split-' + isSplitPromotion"
                            @click="setSplitPromotion(isSplitPromotion)"
                        >
                            <div>
                                {{ $tkey(getPromotionTypeText(isSplitPromotion)) | toSentenceCase }}
                            </div>
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
        </div>

        <vuex-text-field
            v-if="isPromotionNameFieldVisible"
            :getter="() => promotionNameGetter()"
            :setter="value => promotionNameSetter(value)"
            :placeholder="$tkey('promotionName') | toSentenceCase"
            :make-read-only="isCreateProcess"
            white
            save-whitespace
            update-on-input
            :class="['create-promotion--promotion-name']"
            @click.stop
            @keydown.enter="
                !isCreatePromotionDisabled &&
                    $refs.createPromotionBtn.$refs.createResourceBtn.createNewResource()
            "
        />
        <create-promotion-btn
            ref="createPromotionBtn"
            v-bind="{ background: true }"
            :btn-text="'actions.create'"
            :make-read-only="isCreatePromotionDisabled"
            :read-only-reason="subCampaignEditingState.reason"
            :promotion-name="promotionNameGetter()"
            :split-promotion="splitPromotion"
            :before-create-function="beforeCreateProcess"
            :class="['ml-1', 'create-promotion--first-item', 'font-weight-bold']"
            @promotionCreationFailed="afterCreateProcess()"
            @promotionCreated="afterCreateProcess($event)"
        />
    </feature-toggle>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import namespaces from '@enums/namespaces';
import UXEvents from '@enums/ux-events';
import {
    promotionNameIsRequired,
    canCreatePromotion,
    enableSplitPromotions,
    canCreateSplitPromotion,
} from '@enums/feature-flags';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [configDrivenGridComponentMixin],
    localizationKey: 'planning.promotionsViewer',
    data() {
        return {
            canCreatePromotion,
            isCreateProcess: false,
            isMenuOpen: false,
            splitPromotion: false,
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('scenarios', ['selectedScenarioId', 'getScenarioById']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign', 'getSubCampaignById']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        isCreatePromotionDisabled() {
            return (
                this.promotionCreationState.disabled ||
                this.isPromotionMissingName ||
                this.isCreateProcess
            );
        },

        isCreatePromotionMenuDisabled() {
            return this.promotionCreationState.disabled || this.isCreateProcess;
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        splitPromotionsEnabled() {
            return (
                this.toggleLogic[enableSplitPromotions] && this.toggleLogic[canCreateSplitPromotion]
            );
        },

        promotionCreationState() {
            return this.featureAwareFactory.isPromotionCreationDisabled(
                {
                    scenario: this.getScenarioById({
                        _id: this.selectedScenarioId,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            );
        },

        subCampaignEditingState() {
            return this.featureAwareFactory.isSubCampaignEditingDisabled(
                {
                    subCampaign: this.getSubCampaignById({
                        _id: this.selectedSubCampaign._id,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            );
        },

        isPromotionMissingName() {
            if (this.isPromotionNameFieldVisible) {
                const stagingArea = this.getStagingAreaPromotionById(namespaces.default);
                return stagingArea ? !stagingArea.name : false;
            }

            return false;
        },

        isPromotionNameFieldVisible() {
            return this.toggleLogic[promotionNameIsRequired];
        },
    },
    created() {
        this.addEventListener();
    },
    beforeDestroy() {
        this.$root.$off(UXEvents.promotionCreated, this.resetFields);
    },
    methods: {
        ...mapActions('promotions', ['setStagingAreaField']),

        toggleCreateNewMenu(value) {
            this.isMenuOpen = value;
        },

        promotionNameGetter() {
            const stagingArea = this.getStagingAreaPromotionById(namespaces.default);
            return stagingArea ? stagingArea.name : '';
        },

        promotionNameSetter(value) {
            this.setStagingAreaField({
                namespace: namespaces.default,
                fieldName: 'name',
                value,
            });
        },

        resetFields() {
            this.promotionNameSetter('');
            this.splitPromotion = false;
        },

        addEventListener() {
            this.$root.$on(UXEvents.promotionCreated, this.resetFields);
        },

        beforeCreateProcess() {
            this.isCreateProcess = true;
        },

        afterCreateProcess($event) {
            this.isCreateProcess = false;
            if ($event && $event._id) {
                this.$emit('promotionCreated', $event._id);
            }
        },

        getPromotionTypeText(splitPromotion = this.splitPromotion) {
            return splitPromotion ? 'createNewSplitPromotion' : 'createNewPromotion';
        },

        setSplitPromotion(value) {
            this.splitPromotion = value;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.create-promotion {
    &--first-item {
        padding-left: 0.6rem;
    }

    &--promotion-name {
        width: 20rem;
    }

    &--menu {
        &__list {
            background: $buttons-menu-list-bg;
            padding: 0;
        }
        padding: 0.5rem;
    }

    &--prefix {
        display: flex;
        align-items: center;
        div {
            justify-content: flex-start;
            color: $buttons-menu-text-color;
            font-weight: 700;
            font-size: 1.2rem;
        }

        &__margin {
            margin-right: 1rem;
        }
    }
}

.v-list-item {
    padding: 0rem;
    min-height: 3.2rem;
    min-width: 14rem;
    border: none;
    text-align: center;

    &:hover {
        background: $buttons-menu-hover;
    }

    div,
    ::v-deep div {
        width: 100%;
        height: 100%;

        justify-content: flex-start;
        color: $buttons-menu-text-color;
        font-weight: 700;
        font-size: 1.2rem;

        &:hover {
            background: none;
            &:before {
                opacity: 0;
            }
        }
    }
}

.v-menu__content {
    z-index: $menu-z-index !important;
    margin-top: 0.5rem;
}
</style>
