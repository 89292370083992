<template>
    <offer-tab v-bind="form" :form-ref="formRef" :cache-dom="cacheDom" />
</template>

<script>
import promotionTabFormWrapperMixin from '@/js/pages/planning/components/mixins/promotion-tab-form-wrapper';

export default {
    mixins: [promotionTabFormWrapperMixin],
    props: {
        formRef: {
            type: Object,
            required: false,
        },
    },
    computed: {
        form() {
            return {
                vuexModule: 'promotions',
                editMode: this.editMode,
                submitAction: null,
                preventDefault: true,
                namespace: this.namespace,
                context: this.context,
                editContext: this.context.selectedPromotion,
                isPastPromotion: this.isPastPromotion,
                fields: [
                    {
                        fieldName: 'products',
                    },
                    {
                        fieldName: 'offerMechanic',
                    },
                    {
                        fieldName: 'productOfferGroups',
                    },
                    {
                        fieldName: 'splitPromotion',
                    },
                ],
            };
        },
    },
};
</script>
