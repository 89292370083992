import tabStates from '@enums/tab-states';
import { every, isNil, filter } from 'lodash';

function validateSupplyTab({ promotion }) {
    if (isNil(promotion.products) || promotion.products.length === 0) {
        return tabStates.invalid;
    }

    // All products should have at list one case size/supplier selected
    return every(
        filter(promotion.products, { isProposed: false }),
        product => product.supplierCaseInformation && (product.supplierCaseInformation || []).length
    )
        ? tabStates.completed
        : tabStates.invalid;
}

export default {
    validateSupplyTab,
};
