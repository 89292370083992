var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "promotion-resources",
    _vm._b(
      {
        key: _vm.selectedSubCampaign._id,
        attrs: { "edit-context": _vm.selectedSubCampaign },
      },
      "promotion-resources",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }