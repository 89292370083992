<template>
    <div class="supplier-commitment-viewer-wrapper">
        <div v-if="!isExpanded" class="supplier-commitment-viewer">
            <div
                v-for="(field, index) in fields"
                :key="`supplierCommitment::${supplierCommitment._id}::field::${index}`"
            >
                <div class="supplier-commitment-viewer__grid-cell" @click="$emit('click')">
                    <div v-if="field.functionName">
                        <span>{{ callAction(field.functionName, supplierCommitment) }}</span>
                    </div>
                    <div v-else-if="field.component">
                        <component
                            :is="field.component.name"
                            v-bind="
                                constructVBindObj({
                                    context: supplierCommitment,
                                    field: field.name,
                                    ...field.component,
                                })
                            "
                            v-on="
                                constructVBindEventObj({
                                    events: getAttribute(field.component, 'events'),
                                    context: supplierCommitment,
                                })
                            "
                        />
                    </div>
                    <span v-else>{{ getAttribute(supplierCommitment, field.name) }}</span>
                </div>
            </div>
            <div v-if="isPromotionSupplierFundingView">
                <div class="supplier-commitment-viewer__grid-cell">
                    <allocation-checkbox
                        :supplier-commitment="supplierCommitment"
                        :promotion-namespace="promotionNamespace"
                    />
                </div>
            </div>
        </div>
        <div v-else style="display: contents">
            <supplier-commitment-form-wrapper
                :edit-context="supplierCommitment"
                :form-fields="formFields"
                :is-promotion-supplier-funding-view="isPromotionSupplierFundingView"
                :promotion-namespace="promotionNamespace"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { get, join, map } from 'lodash';
import measurements from '@enums/measurements';
import NotesFieldKeys from '@enums/notes-field-keys';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [configDrivenGridComponentMixin],
    props: {
        supplierCommitment: {
            required: true,
            type: Object,
        },
        fields: {
            required: true,
            type: Array,
        },
        formFields: {
            required: true,
            type: Array,
        },
        isPromotionSupplierFundingView: {
            type: Boolean,
            default: false,
        },
        promotionNamespace: String,
    },
    computed: {
        ...mapState('supplierCommitments', ['selectedSupplierCommitmentId']),

        isExpanded() {
            return this.supplierCommitment._id === this.selectedSupplierCommitmentId;
        },
    },
    created() {
        this.fields = Object.freeze(this.fields);
    },

    methods: {
        ...mapActions('supplierCommitments', ['addNote', 'deleteNote', 'updateNote']),

        getTotalCurrentAllocations({
            total = 0,
            productCurrent = 0,
            promotionCurrent = 0,
            measurement = measurements.promotion,
        }) {
            return `${
                measurement === measurements.promotion ? promotionCurrent : productCurrent
            } / ${total}`;
        },

        getPeriod({ startDate, endDate }) {
            const formattedStartDate = this.$options.filters.dateShorthand(startDate);
            const formattedEndDate = this.$options.filters.dateShorthand(endDate);
            return `${formattedStartDate} - ${formattedEndDate}`;
        },

        getValue({ value }) {
            return this.$n('numbers.default.currencyRounded', value);
        },

        getCategoryNames({ hierarchy }) {
            return join(map(hierarchy, 'levelEntryDescription'), ', ');
        },

        postNote(params, note) {
            this.addNote({
                note,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },

        removeNote(params, noteId) {
            this.deleteNote({
                noteId,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },

        saveNote(params, note) {
            this.updateNote({
                note,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.supplier-commitment-viewer-wrapper {
    display: contents;
    z-index: $menu-z-index;
}
.supplier-commitment-viewer {
    display: contents;
    position: relative;

    &__grid-cell {
        @include flex-column;
        padding: 0.6rem 1rem;
        background-color: $promo-white;
        border-top: $promo-divider-colour 0.1rem solid;
        font-weight: 400;
        line-height: 3rem;
        font-size: 1.2rem;
        color: $promo-text-colour;
        letter-spacing: 0;
        height: 100%;

        & > div {
            display: flex;
        }

        &:hover {
            cursor: pointer;
        }

        &::v-deep {
            .theme--light.v-label {
                color: $promo-text-colour;
            }
            .measurement .v-input--selection-controls__input {
                margin-right: 0;
                width: 1.8rem;

                .v-icon.v-icon {
                    font-size: 1.4rem;
                }
            }
        }
    }

    & > div:first-child &__grid-cell {
        font-weight: 600;
    }

    &__expanded-area {
        grid-column: 1 / span 8;
        background-color: $promo-white;
        padding: 0.6rem 1rem;
    }
}

.supplier-commitments-viewer__header + .supplier-commitment-viewer-wrapper,
.supplier-commitments-viewer__historical-title + .supplier-commitment-viewer-wrapper {
    .supplier-commitment-viewer__grid-cell {
        border-top-color: $promo-grey;
    }
}
</style>
