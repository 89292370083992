<template>
    <main-dialog
        ref="dialog"
        :translated-header="$tkey('header')"
        :btn-confirm-text="btnConfirmText"
        btn-cancel-text="actions.cancel"
        :has-activator="false"
        @confirm="$emit('confirm')"
        @close="close"
    >
        <template v-slot:default>
            <div v-if="renderGrid" class="confirm-dialog">
                <p class="confirm-dialog__description">
                    {{ $tkey('actionableText') | toSentenceCase }}
                </p>
                <promo-ag-grid
                    ref="agGrid"
                    :row-data="actionableEntities"
                    :column-defs="columnDefs"
                    :grid-options="gridOptions"
                    :default-col-def="defaultColDef"
                    grid-style="width: 65rem; height: 20rem;"
                />
                <v-divider />
                <div v-if="excludedEntities.length">
                    <p class="confirm-dialog__description">
                        {{ $tkey('notActionableText') | toSentenceCase }}
                    </p>
                    <promo-ag-grid
                        ref="agGrid"
                        :row-data="excludedEntities"
                        :column-defs="columnDefs"
                        :grid-options="gridOptions"
                        :default-col-def="defaultColDef"
                        grid-style="width: 65rem; height: 20rem;"
                    />
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { difference } from 'lodash';
import { mapState } from 'vuex';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { toSentenceCase } from '@/js/utils/string-utils';
import { mapWorkflowStateIcons } from '@/js/utils/workflow-utils';
import workflowEntityOperations from './workflow-entity-operations';
import iconRenderer from './icon-renderer';

export default {
    localizationKey: 'workflow.taskButton.confirmDialog',
    props: {
        btnConfirmText: {
            type: String,
            required: true,
        },
        entityIds: {
            type: Array,
            required: true,
        },
        actionableEntityIds: {
            type: Array,
            required: true,
        },
        entityType: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: false,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter

                frameworkComponents: {
                    iconRenderer,
                },
            },
            columnDefs: [],
            renderGrid: false,
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        excludedEntities() {
            const excludedIds = difference(this.entityIds, this.actionableEntityIds);
            return excludedIds.map(id =>
                workflowEntityOperations[this.entityType].getEntityById({ entityId: id })
            );
        },
        actionableEntities() {
            return this.actionableEntityIds.map(id =>
                workflowEntityOperations[this.entityType].getEntityById({ entityId: id })
            );
        },

        workflowStateIconsMap() {
            return createFeatureAwareFactory(
                this.toggleLogic
            ).getSubCampaignAllocationWorkflowStateIconsMap();
        },
    },

    created() {
        const confirmationFields = workflowEntityOperations[
            this.entityType
        ].getConfirmationFields();

        this.columnDefs = [
            {
                headerName: toSentenceCase(this.$tkey('gridHeadings.clientSate')),
                cellRenderer: 'iconRenderer',
                valueGetter: params => {
                    const workflowStateIcon = mapWorkflowStateIcons({
                        workflowState: params.data.workflowState,
                        ...this.workflowStateIconsMap,
                    });

                    return {
                        icon: workflowStateIcon.icon,
                        text: this.$t(
                            this.$t(`workflow.taskButton.states.${workflowStateIcon.text}`)
                        ),
                    };
                },
            },
            ...confirmationFields.map(field => {
                return {
                    field: field.field,
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.${field.headerName}`)),
                };
            }),
        ];
    },

    methods: {
        open() {
            this.renderGrid = true;
            this.$refs.dialog.open();
        },

        close() {
            this.renderGrid = false;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.confirm-dialog {
    font-size: 1.2rem;

    &__description {
        padding: 1rem 0;
    }
}
</style>
