<template>
    <div class="promotion-resources">
        <div class="channel-provision-title ml-4 my-2">
            {{ $t('preparation.resourceProvision') | toSentenceCase }}
        </div>
        <v-tabs
            v-model="selectedTab"
            height="2.5rem"
            background-color="transparent"
            class="promo-tabs"
            active-class="promo-tabs__tab--active"
            hide-slider
            @change="resetSelectedDefinition"
        >
            <template v-for="tab in tabs">
                <!-- Used to fill and style the space between tabs. -->
                <div :key="`spacer-${tab.label}`" class="promo-tabs__spacer" />
                <v-tab :key="`tab-${tab.label}`" :disabled="tab.disabled" class="promo-tabs__tab">
                    {{ tab.label | toSentenceCase }}
                </v-tab>
            </template>

            <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
            <div class="promo-tabs__filler" />

            <v-tab-item
                v-for="tab in tabs"
                :key="`tab-item-${tab.label}`"
                :transition="false"
                :reverse-transition="false"
            >
                <promo-resource-tab-form-wrapper :promo-resource="tab.resource" />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import PromoResourcesEnum from '@enums/promo-resources';
import PromoResourceSectionsEnum from '@enums/promo-resource-sections';
import vuexFormMixin from '@/js/mixins/vuex-form';

export default {
    mixins: [vuexFormMixin],
    props: {},
    data() {
        return {
            selectedTab: 0,
        };
    },
    computed: {
        ...mapState('clientConfig', ['promoResources']),
        ...mapGetters('subCampaigns', [
            'selectedSubCampaign',
            'getResourceDefinitionsForResourceType',
        ]),
        tabs() {
            return this.promoResources
                .filter(resource => resource.showInPreparationArea)
                .map(resource => {
                    const { key } = resource;

                    return {
                        resource: key,
                        label: this.$t(`preparation.promoResources.${key}`),
                        content: this.$t(`preparation.promoResources.${key}`),
                        disabled: !this.selectedSubCampaign.resources.some(r => r.type === key),
                    };
                });
        },
    },
    created() {
        this.setDefaultTab();
    },
    mounted() {
        this.setActiveDocumentSection(PromoResourceSectionsEnum.templates);
    },
    methods: {
        ...mapMutations('subCampaigns', [
            'setSelectedResourceDefinitionKey',
            'setSelectedResourceDefinitionPageNumber',
            'setActiveDocumentSection',
            'setSelectedAreaForPromotionAllocation',
        ]),
        setDefaultTab() {
            if (
                this.selectedSubCampaign.resources.some(r => r.type === PromoResourcesEnum.leaflet)
            ) {
                this.selectedTab = this.promoResources.findIndex(
                    resource => resource.key === PromoResourcesEnum.leaflet
                );
            } else if (
                this.selectedSubCampaign.resources &&
                this.selectedSubCampaign.resources.length
            ) {
                this.selectedTab = this.promoResources.findIndex(
                    resource => resource.key === this.selectedSubCampaign.resources[0].type
                );
            }
        },
        resetSelectedDefinition() {
            const { resource } = this.tabs[this.selectedTab];
            const definitionsByType = this.getResourceDefinitionsForResourceType(resource);
            if (definitionsByType.length) {
                const key = definitionsByType[0].key;
                // we already have documentSelectedHandler in promotion-resource.vue
                // fire the event for reusing it
                this.globalEmit('document-selected', { key, resource });
            } else {
                this.setActiveDocumentSection(PromoResourceSectionsEnum.templates);
                this.setSelectedResourceDefinitionPageNumber(null);
                this.setSelectedResourceDefinitionKey(null);
                this.setSelectedAreaForPromotionAllocation(null);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.promotion-resources {
    border-top: 1px solid $promo-grey-4;
}
.channel-provision-title {
    font-size: 1.2rem;
    font-weight: 700;
    display: inline-block;
}
</style>
