import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isCreateMode
    ? _c("create-new-button", {
        attrs: { disabled: _vm.isCreateDisabled, background: "" },
        on: { createNew: _vm.addProductProxy },
      })
    : _c(
        "div",
        [
          _vm.isEditMode
            ? _c(
                VBtn,
                { attrs: { primary: "" }, on: { click: _vm.addProductProxy } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.save"))) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VBtn,
            {
              attrs: { outlined: "" },
              on: { click: _vm.openRemoveProductProxyModal },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("removeProxy"))) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("confirm-dialog", {
            ref: `dialog_${_vm.productId}`,
            staticClass: "confirm-dialog",
            attrs: {
              "has-activator": false,
              "question-text": _vm.$t("dialogs.removeProxy.header"),
              "action-text": _vm.$t("actions.remove"),
            },
            on: {
              confirm: function ($event) {
                return _vm.removeProductProxy()
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }