<template>
    <feature-toggle :toggle="allowApplyAllNotifications">
        <v-btn
            v-if="isChild"
            primary
            depressed
            :disabled="isDisabled"
            @click.stop="$refs.dialog.open()"
        >
            {{ $t(`acceptAllNotifications.acceptAllNotifications`) }}
        </v-btn>

        <confirm-dialog
            ref="dialog"
            :question-text="
                isScenario
                    ? $t(`acceptAllNotifications.confirmTextScenario`)
                    : $t(`acceptAllNotifications.confirmTextPromotion`)
            "
            :action-text="$t('actions.confirm')"
            :has-activator="false"
            @confirm="$store.dispatch(`${entity}/acceptAllNotifications`, { entityId })"
        />
    </feature-toggle>
</template>

<script>
import { allowApplyAllNotifications } from '@enums/feature-flags';

export default {
    props: {
        entity: {
            required: true,
            type: String,
        },
        entityId: {
            required: true,
            type: String,
        },
        applyingNotifications: {
            type: Boolean,
        },
        isChild: {
            type: Boolean,
            default: false,
        },
        hasNotifications: {
            type: Boolean,
            default: false,
        },
        isExecutingPromotionsWithPendingNotifications: {
            type: Boolean,
            default: false,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            allowApplyAllNotifications,
        };
    },
    computed: {
        isScenario() {
            return this.entity === 'scenarios';
        },
        isDisabled() {
            return (
                this.disabled ||
                this.applyingNotifications ||
                !this.hasNotifications ||
                !this.entityId ||
                this.isExecutingPromotionsWithPendingNotifications
            );
        },
    },
};
</script>
