var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid pt-3 px-5" }, [
    _c("h3", [_vm._v("LabsKit")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table table-striped table-hover table-bordered" },
      [
        _c(
          "tbody",
          _vm._l(_vm.labskitrc, function (rc) {
            return _c("tr", { key: rc.key }, [
              _c("th", [_vm._v(_vm._s(rc.key))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(rc.value))]),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Context")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table table-striped table-hover table-bordered" },
      [
        _c(
          "tbody",
          _vm._l(_vm.currentContextEntries, function ([key, val]) {
            return _c("tr", { key: key }, [
              _c("th", [_vm._v(_vm._s(key))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(val))]),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "h3",
      [
        _vm._v("\n        Health checks: "),
        _c("b-badge", { attrs: { pill: "" } }, [
          _vm._v(_vm._s(_vm.statusChecks.length)),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.statusChecks.length
      ? _c(
          "table",
          { staticClass: "table table-striped table-hover table-bordered" },
          [
            _c(
              "tbody",
              _vm._l(_vm.statusChecks, function (check) {
                return _c("tr", { key: check.basename }, [
                  _c("th", [_vm._v(_vm._s(check.basename))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(check.status))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(check.description))]),
                ])
              }),
              0
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("h3", [_vm._v("Build details")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table table-striped table-hover table-bordered" },
      [
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("Git revision")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.revision))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("Build time")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.buildTime))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("Build number")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.buildNumber))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("Startup time")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.startupTime))]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("th", [_vm._v("CWD (Current Working Dir)")]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.cwd))]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Active Configuration")]),
    _vm._v(" "),
    _c("h6", [_vm._v("*Any relative path/dir is relative to CWD")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table table-striped table-hover table-bordered" },
      [
        _c(
          "tbody",
          _vm._l(_vm.config, function (conf) {
            return _c("tr", { key: conf.key }, [
              _c("th", [_vm._v(_vm._s(conf.key))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(conf.value))]),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _c("h3", [_vm._v("Dependencies")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "table",
          { staticClass: "table table-striped table-hover table-bordered" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.dependencies, function (dep) {
                return _c("tr", { key: dep.depname }, [
                  _c("th", [_vm._v(_vm._s(dep.depname))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(dep.version))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(dep.installedVersion))]),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Configured version")]),
        _vm._v(" "),
        _c("th", [_vm._v("Installed version")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }