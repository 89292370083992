<template>
    <div class="page">
        <div class="page__filter-bar">
            <filter-bar :max-filter-count="3" />
        </div>
        <promo-planner preparation-view>
            <template v-slot:details-component>
                <promotion-resources-form-wrapper />
            </template>
        </promo-planner>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import guardsMap from '@/js/pages/preparation/preparation-guard-map';

export default {
    beforeRouteUpdate(to, from, next) {
        if (to.name === from.name) {
            // navigating to the same route only params has changed
            // manually fire beforeEntered hook because it is not called automatically
            guardsMap[to.name](to, from, next);
        } else {
            next();
        }
    },
    destroyed() {
        //  We need to clean state when user leave preparation page
        //  for prevent issues in computed properties when we back without reloading
        this.setSelectedResourceDefinitionKey(null);
        this.setSelectedResourceDefinitionPageNumber(null);
    },
    methods: {
        ...mapMutations('subCampaigns', [
            'setSelectedResourceDefinitionKey',
            'setSelectedResourceDefinitionPageNumber',
        ]),
    },
};
</script>

<style scoped lang="scss">
.page {
    height: 100%;

    &__filter-bar {
        padding: 1rem;
    }
}
</style>
