import Vue from 'vue';
import axios from 'axios';
import { camelCase, find, get } from 'lodash';
import to from 'await-to-js';
import workflowUtilsFactory from '@sharedModules/workflow-utils-factory';

import i18n from '../../vue-i18n';

/**
 * Extends a vuex module with logic relating to workflows.
 *
 * @module client/js/store/mixins/vuex-workflow-store-add-ons
 */

/**
 * Generate workflow functions for the given vuex resource.
 *
 * @param {string} RORO.resource - the vuex resource to be added to.
 */
const createWorkflowVuexOptions = ({ resource }) => {
    const workflowUtils = workflowUtilsFactory(Vue.moment);

    return {
        mutations: {
            /**
             * Update the workflow state in vuex.
             *
             * @param {string} RORO.entityToUpdate - The entity being updated.
             * @param {Array} RORO.states - The array of workflow states to be updated.
             */
            updateWorkflowState(state, { entityToUpdate, states }) {
                if (!entityToUpdate.workflowState) {
                    Vue.set(entityToUpdate, 'workflowState', []);
                }

                states.forEach(s => {
                    const workflowState = find(entityToUpdate.workflowState, {
                        entity: s.entityToUpdate,
                        state: s.stateToUpdate,
                    });

                    if (workflowState) {
                        workflowState.value = s.value;
                        workflowState.actionDateTime = s.actionDateTime;
                    } else {
                        entityToUpdate.workflowState.push({
                            entity: s.entityToUpdate,
                            state: s.stateToUpdate,
                            value: s.value,
                            actionDateTime: s.actionDateTime,
                        });
                    }
                });

                Vue.set(entityToUpdate, 'workflowState', entityToUpdate.workflowState);
            },
        },

        actions: {
            /**
             * Update the workflow state in the mongo database.
             *
             * @param {string} RORO.entityId -The id of the entity being updated.
             * @param {Array} RORO.states -The array of workflow states to be updated.
             */
            async updateWorkflowState({ dispatch, getters, commit }, { entityIds, states }) {
                const payload = {
                    resource,
                    entityIds,
                    states,
                };

                // Update the actionDateTime attribute of all the updated states.
                const updatedActionDateTime = Vue.moment();
                states.forEach(state => {
                    state.actionDateTime = updatedActionDateTime;
                });

                // Send api request to update state
                const [error, response] = await to(
                    axios.post(`/api/workflow/workflowState`, payload)
                );

                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`workflow.updateState.notifications.updateSuccess`),
                    errorMessage: i18n.t(`workflow.updateState.notifications.updateError`),
                });
                if (error) return { error };

                // Update state in vuex
                entityIds.forEach(entityId => {
                    const entityToUpdate = getters[camelCase(`get-${resource}-by-id`)](entityId);

                    commit('updateWorkflowState', { entityToUpdate, states });
                });
            },

            /**
             * Update the client workflow state in the mongo database.
             *
             * @param {string} RORO.entityId -The id of the entity being updated.
             */
            async updateClientState({ commit, dispatch, getters, rootGetters }, { entityIds }) {
                const updates = [];
                entityIds.forEach(entityId => {
                    const entityToCheck = getters[camelCase(`get-${resource}-by-id`)](entityId);
                    const clientStateMap = rootGetters['clientConfig/getWorkflowClientStatesMap'](
                        resource
                    );
                    const workflowState = getters.entityWorkflowStateById({
                        id: entityId,
                    });

                    const clientState = workflowUtils.determineClientState({
                        entityToCheck,
                        workflowState,
                        clientStateMap,
                    });

                    if (clientState !== entityToCheck.clientState) {
                        updates.push({
                            entityId,
                            clientState,
                        });
                    }
                });

                if (updates.length) {
                    // Send api request to update state
                    const [error, response] = await to(
                        axios.post(`/api/workflow/clientState`, { resource, updates })
                    );

                    dispatch('handleResponseNotifications', {
                        error,
                        response,
                        successMessage: i18n.t(`workflow.updateState.notifications.updateSuccess`),
                        errorMessage: i18n.t(`workflow.updateState.notifications.updateError`),
                    });
                    if (error) return { error };

                    // Update state in vuex
                    updates.forEach(({ entityId, clientState }) => {
                        commit(camelCase(`update-${resource}`), {
                            id: entityId,
                            updates: { clientState },
                        });
                    });
                }
            },
            async runTask(
                { dispatch, commit, state },
                { task, isNegativeAction, workflowEntity, entityIds, owningEntityId }
            ) {
                const [error, response] = await to(
                    axios.post('/api/workflow/actionTask', {
                        task,
                        isNegativeAction,
                        workflowEntity,
                        entityIds,
                        owningEntityId,
                    })
                );
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`workflow.updateState.notifications.updateSuccess`),
                    errorMessage: i18n.t(`workflow.updateState.notifications.updateError`),
                });
                if (error) return { error };

                const entities = get(response, 'data.entities', []);
                const pluralResourceName = `${resource}s`;
                const updatedResources = state[`${camelCase(pluralResourceName)}`];

                const entitiesByKey = {};

                entities.forEach(e => {
                    entitiesByKey[e._id] = e;
                });

                const stateUpdates = updatedResources.map(ur => {
                    if (entitiesByKey[ur._id]) {
                        return entitiesByKey[ur._id];
                    }

                    return ur;
                });

                commit(camelCase(`set-${pluralResourceName}`), stateUpdates);

                // update staging area for resources will run only for subcampaigns
                if (state.selectedSubCampaignId && state.selectedResourceDefinitionKey) {
                    const subCampaign = entities.find(
                        sc => String(sc._id) === state.selectedSubCampaignId
                    );
                    if (subCampaign) {
                        const selectedResourceDefinition = find(
                            state.stagingArea[state.selectedSubCampaignId].resourceDefinitions,
                            { key: state.selectedResourceDefinitionKey }
                        );

                        const newResourceDefinitions = subCampaign.resourceDefinitions.find(
                            rd => rd.key === state.selectedResourceDefinitionKey
                        );

                        if (selectedResourceDefinition && newResourceDefinitions) {
                            selectedResourceDefinition.workflowState =
                                newResourceDefinitions.workflowState;
                        }
                    }
                }
            },
        },
    };
};

export default createWorkflowVuexOptions;
