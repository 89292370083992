var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "workflow-task-alert clickable",
      class: [
        _vm.props.compact
          ? "workflow-task-alert--one-column"
          : "workflow-task-alert--two-column",
        `workflow-task-alert--${_vm.props.priority.toLowerCase()}`,
      ],
      on: {
        click: function ($event) {
          return _vm.$options.methods.alertClicked({
            subCampaignId: _vm.props.subCampaignId,
            listeners: _vm.listeners,
          })
        },
      },
    },
    [
      _c("div", { staticClass: "workflow-task-alert__body" }, [
        _c("div", { staticClass: "workflow-task-alert__header" }, [
          _c("div", { staticClass: "workflow-task-alert__header--name" }, [
            _vm._v(_vm._s(_vm.props.subCampaignName)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "workflow-task-alert__header--tag",
              class: `workflow-task-alert__header--${_vm.props.priority.toLowerCase()}`,
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.$options.methods.getTag({
                      deadlineDateTime: _vm.props.deadlineDateTime,
                      dueDateTime: _vm.props.dueDateTime,
                    })
                  ) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "workflow-task-alert__hierarchyGroup" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.$options.methods.getHierarchyGroup(_vm.props.hierarchyGroup)
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "workflow-task-alert__priority" }, [
          _vm._v(_vm._s(_vm.props.priority)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "workflow-task-alert__message",
            class: `workflow-task-alert__message--${_vm.props.priority.toLowerCase()}`,
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$options.methods.getAlertMessage({
                    alertMessageKey: _vm.props.alertMessageKey,
                    entity: _vm.props.entity,
                    task: _vm.props.task,
                    deadlineDateTime: _vm.props.deadlineDateTime,
                  })
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.props.compact
        ? _c(
            "div",
            {
              staticClass: "workflow-task-alert__arrow",
              class: `workflow-task-alert__arrow--${_vm.props.priority.toLowerCase()}`,
            },
            [_c("icon", { attrs: { "icon-name": "navigate_next" } })],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }