var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "20px",
        height: "19px",
        viewBox: "0 0 20 19",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Empty star")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Planner",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Copy-promo-2-6",
                transform: "translate(-57.000000, -1132.000000)",
                fill: "#F7B500",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group-35",
                    transform: "translate(46.000000, 1125.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Icon-/-Empty-star",
                        transform: "translate(11.000000, 6.000000)",
                      },
                    },
                    [
                      _c("polygon", {
                        attrs: {
                          id: "Icon-colour",
                          points:
                            "10 16.25 4.12214748 19.3401699 5.24471742 12.795085 0.489434837 8.15983006 7.06107374 7.20491503 10 1.25 12.9389263 7.20491503 19.5105652 8.15983006 14.7552826 12.795085 15.8778525 19.3401699",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }