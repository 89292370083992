import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "copy-resource-dialog",
    attrs: {
      "show-footer": !_vm.showConfirmCopy,
      "confirm-btn-disabled": _vm.confirmBtnDisabled,
      heading: "preparation.copyDialog.heading",
      "btn-confirm-text": "actions.copy",
    },
    on: {
      confirm: function ($event) {
        return _vm.toggleShowConfirmCopy(true)
      },
      close: _vm.close,
    },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ activator }) {
          return [
            _c(
              VBtn,
              _vm._g(
                {
                  attrs: {
                    depressed: "",
                    outlined: "",
                    disabled: _vm.copyDocumentBtnDisabled,
                  },
                },
                activator
              ),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("preparation.buttons.copyDocument")
                      )
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            !_vm.showConfirmCopy
              ? _c(
                  "div",
                  [
                    _vm.isPagesResource
                      ? _c(
                          "div",
                          { staticClass: "choose-pages-wrapper pt-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "choose-pages-wrapper__cover-inside-pages",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "choose-pages-wrapper__cover-inside-pages--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(
                                              `preparation.copyDialog.cover`
                                            )
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("vuex-checkbox", {
                                  attrs: {
                                    getter: () =>
                                      _vm.pagesSelections[_vm.pageTypes.cover],
                                    setter: (value) =>
                                      _vm.pagesSelectionsSetter({
                                        value,
                                        type: _vm.pageTypes.cover,
                                        applyToAllPages: true,
                                      }),
                                    disabled: _vm.pagesSectionDisabled,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "choose-pages-wrapper__cover-inside-pages",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "choose-pages-wrapper__cover-inside-pages--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(
                                              `preparation.copyDialog.insidePages`
                                            )
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("vuex-checkbox", {
                                  attrs: {
                                    getter: () =>
                                      _vm.pagesSelections[_vm.pageTypes.inside],
                                    setter: (value) =>
                                      _vm.pagesSelectionsSetter({
                                        value,
                                        type: _vm.pageTypes.inside,
                                        applyToAllPages: true,
                                      }),
                                    disabled: _vm.insidePagesDisabled,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "choose-pages-wrapper__all-pages",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t(
                                            `preparation.copyDialog.choosePages`
                                          )
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  VBtn,
                                  {
                                    staticClass:
                                      "choose-pages-wrapper__all-pages--btn",
                                    attrs: {
                                      text: "",
                                      disabled: _vm.insidePagesDisabled,
                                    },
                                    on: { click: _vm.selectAllHandler },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(_vm.selectAllPagesLabel)
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "choose-pages-wrapper__pages-scroller",
                              },
                              [
                                _c("pagination", {
                                  staticClass:
                                    "pagination-container__pagination",
                                  attrs: {
                                    length: _vm.paginationLength,
                                    disabled: _vm.insidePagesDisabled,
                                    "start-from-second": true,
                                    "multi-select-values": _vm.selectedPages,
                                    "is-multi-select": "",
                                    "items-in-screen": _vm.itemsInScreen,
                                    "is-basic-navigate-button": "",
                                  },
                                  on: { input: _vm.paginationHandler },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isPagesResource
                      ? _c(
                          "div",
                          { staticClass: "checkbox-wrapper" },
                          _vm._l(_vm.checkboxes, function (checkbox, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "checkbox-wrapper__row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "checkbox-wrapper__row--title",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(
                                              `preparation.copyDialog.${checkbox.key}`
                                            )
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "checkbox-wrapper__row--cover",
                                  },
                                  [
                                    _c("vuex-checkbox", {
                                      attrs: {
                                        getter: () =>
                                          _vm.checkboxesGetter({
                                            checkboxKey: checkbox.key,
                                            pageType: _vm.pageTypes.cover,
                                          }),
                                        setter: (value) =>
                                          _vm.checkboxesSetter({
                                            value,
                                            checkboxKey: checkbox.key,
                                            pageType: _vm.pageTypes.cover,
                                          }),
                                        disabled: _vm.isDisabled(
                                          checkbox.deps,
                                          _vm.pageTypes.cover
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "checkbox-wrapper__row--inside",
                                  },
                                  [
                                    _c("vuex-checkbox", {
                                      attrs: {
                                        getter: () =>
                                          _vm.checkboxesGetter({
                                            checkboxKey: checkbox.key,
                                            pageType: _vm.pageTypes.inside,
                                          }),
                                        setter: (value) =>
                                          _vm.checkboxesSetter({
                                            value,
                                            checkboxKey: checkbox.key,
                                            pageType: _vm.pageTypes.inside,
                                          }),
                                        disabled: _vm.isDisabled(
                                          checkbox.deps,
                                          _vm.pageTypes.inside
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "copy-resource__text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("preparation.copyDialog.copyTo")
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.resourceDefinitions.length
                      ? _c("document-scroller", {
                          staticClass: "copy-resource__document-scroller",
                          attrs: {
                            documents: _vm.resourceDefinitions,
                            "selected-document-key": _vm.documentToCopyTo,
                            "num-documents-to-show": 3,
                            "select-document-on-open": false,
                          },
                          on: { "document-selected": _vm.setDocumentToCopyTo },
                        })
                      : _c("div", { staticClass: "copy-resource__text" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t(
                                  "preparation.copyDialog.noDocumentsAvailable"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                  ],
                  1
                )
              : _c("confirm-action", {
                  attrs: {
                    "action-text": _vm.$t(
                      "preparation.copyDialog.confirmCopyAction"
                    ),
                    "question-text": _vm.$t(
                      "preparation.copyDialog.confirmCopyQuestion"
                    ),
                    "warning-text": _vm.$t(
                      "preparation.copyDialog.confirmCopyWarning"
                    ),
                  },
                  on: {
                    cancel: function ($event) {
                      return _vm.toggleShowConfirmCopy(false)
                    },
                    confirm: _vm.copyDocument,
                  },
                }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }