var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v("\n    Please use "),
    _c("a", { attrs: { href: _vm.loginSamlUrl } }, [_vm._v("the Login link")]),
    _vm._v(" if you aren't forwarded to login page.\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }