import tabNames from '@enums/tab-names';
import routeNames from '@enums/route-names';
import commonGuardMap from '@/js/pages/common-guard-map';

const guardsMap = {
    ...commonGuardMap(tabNames.products),
    async [routeNames.productsView](to, from, next) {
        // Add logic to run on route load
        next();
    },
};
export default guardsMap;
