<template>
    <div v-if="isVisible && isEntityEverWasSubmitted" class="task-buttons-dropdown">
        <v-menu v-model="isOpenModel" offset-y nudge-right="6" nudge-top="2" min-width="26">
            <template v-slot:activator="{ on, attrs }">
                <div class="task-buttons-dropdown__activator-wrapper">
                    <v-btn v-bind="attrs" icon color="primary" class="notes-icon" v-on="on">
                        <icon :icon-name="activatorIcon" color="promo-light-blue" />
                    </v-btn>
                </div>
            </template>
            <div
                v-for="task in tasks"
                :key="`${task.task}::${task.isNegativeAction}`"
                class="task-buttons-dropdown__task"
            >
                <task-button
                    v-bind="task"
                    :entity-type="entityType"
                    :entity-ids="entityIds"
                    :sub-campaign-id="subCampaignId"
                    :owning-entity-id="owningEntityId"
                    :icon="true"
                    :categories="categories"
                    :is-store-wide-promotion="isStoreWidePromotion"
                    :disabled="isDisabled"
                    :disabled-reason="disabledReason"
                    @click="() => $emit('click')"
                    @action-completed="() => $emit('action-completed')"
                />
            </div>
        </v-menu>
    </div>
</template>

<script>
import { find } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import workflowStates from '@enums/workflow-states';
import { mapWorkflowStateIcons } from '@/js/utils/workflow-utils';

export default {
    props: {
        tasks: {
            required: true,
            type: Array,
        },
        entityType: {
            required: true,
            type: String,
        },
        entityInstance: {
            required: true,
            type: Object,
        },
        entityIds: {
            required: true,
            type: Array,
        },
        subCampaignId: {
            required: true,
            type: String,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        owningEntityId: {
            required: false,
            type: String,
        },
        categories: {
            required: true,
            type: Array,
        },
        isStoreWidePromotion: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabledReason: {
            type: String,
        },
    },
    data() {
        return {
            isOpenModel: this.isOpen,
        };
    },
    computed: {
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapState('clientConfig', ['toggleLogic']),

        workflowState() {
            return this.entityInstance.workflowState || [];
        },
        isEntitySubmitted() {
            const submittedEntity = find(this.workflowState, {
                entity: this.entityType,
                state: workflowStates.submitted,
                value: true,
            });

            return !!submittedEntity;
        },
        isEntityApproved() {
            const approvedEntity = find(this.workflowState, {
                entity: this.entityType,
                state: workflowStates.approved,
                value: true,
            });

            return !!approvedEntity;
        },
        isEntityRejected() {
            const rejectedEntity = find(this.workflowState, {
                entity: this.entityType,
                state: workflowStates.rejected,
                value: true,
            });

            return !!rejectedEntity;
        },
        isFirstTimeSubmitted() {
            return this.isEntitySubmitted && !this.isEntityApproved && !this.isEntityRejected;
        },
        activatorIcon() {
            if (this.isFirstTimeSubmitted) {
                return this.isOpenModel ? 'expand_less' : 'expand_more';
            }
            const workflowStateIconsMap = createFeatureAwareFactory(
                this.toggleLogic
            ).getPromotionStateIconsMap();

            return mapWorkflowStateIcons({
                workflowState: this.entityInstance.workflowState,
                resourceType: this.entity,
                ...workflowStateIconsMap,
            }).icon;
        },
        isEntityEverWasSubmitted() {
            return this.isEntitySubmitted || (!this.isEntitySubmitted && this.isEntityRejected);
        },
        isVisible() {
            const { workflow } = this.getSubCampaignById({
                _id: this.subCampaignId,
                usePluralResourceName: true,
            });

            if (!workflow || !workflow.steps) return;
            const taskNames = this.tasks.map(task => task.task);

            return workflow.steps.find(
                step => taskNames.includes(step.task) && step.entity === this.entityType
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.task-buttons-dropdown {
    border-bottom: 0.1rem solid $promo-grey-4;
    padding: 0.1rem 1rem 0.1rem;

    &__activator-wrapper {
        border-left: 0.1rem solid $promo-grey;
        border-right: 0.1rem solid $promo-grey;
    }

    &__task {
        display: flex;
        background: $promo-white;
        justify-content: space-around;
        padding: 0.3rem 0;
        cursor: pointer;

        &:hover {
            background: $promo-light-blue-2;
        }
    }

    &::v-deep {
        .promo-light-blue--text {
            color: $promo-light-blue;
            border-bottom: 0.1rem solid $promo-primary-colour;
            margin-bottom: 0.3rem;
        }
    }
}

.v-menu__content {
    z-index: $workflow-tasks-z-index !important;
}
</style>
