<template>
    <div class="promotion-tag-selector">
        <vuex-select
            class="promotion-tag-selector__select"
            :placeholder="$t('planning.createPromotion.selectTags') | toSentenceCase"
            clearable
            return-object
            :options="subCampaignTags"
            :getter="() => getPromotionTagsModel()"
            :setter="value => setPromotionTagsModel(value)"
            finite-list
            item-text="tagName"
            item-value="tagKey"
            chips
            deletable-chips
            multiple
            :disabled="disabled"
            @change="saveTags"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { get } from 'lodash';

export default {
    props: {
        subCampaignTags: Array,
        selectedPromotionTags: Array,
        promotionId: String,
        disabled: Boolean,
    },
    data() {
        return {
            promotionTagsModel: this.selectedPromotionTags || [],
        };
    },
    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
    },
    watch: {
        selectedPromotionTags() {
            this.promotionTagsModel = this.selectedPromotionTags || [];
        },
    },
    methods: {
        ...mapActions('promotions', ['setStagingAreaField']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        getPromotionTagsModel() {
            const promotion = this.getStagingAreaPromotionById(this.promotionId || 'default');
            return get(promotion, 'tags');
        },
        setPromotionTagsModel(value) {
            this.promotionTagsModel = value;
        },
        async saveTags() {
            const tags = this.promotionTagsModel;
            this.setUnsavedPromotion({
                namespace: this.promotionId,
                tab: 'root',
                value: true,
            });
            this.setStagingAreaField({
                namespace: this.promotionId,
                fieldName: 'tags',
                value: tags,
            });
        },
    },
    events: {
        onTagsUpdated() {
            // ensure correct tags are selected after tag update/merge
            this.setPromotionTagsModel(this.selectedPromotionTags || []);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.promotion-tag-selector {
    display: flex;

    &__select {
        font-size: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        min-width: 25%;
        max-width: 100%;
    }
}
</style>
