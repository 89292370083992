var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "supply-percentage-container" }, [
    _c("div", { staticClass: "ag-percentage-input" }, [
      _c(
        "div",
        { staticClass: "input-container" },
        [
          _c("vuex-percentage-input", {
            class: _vm.params.cellClass,
            attrs: {
              disabled: _vm.isDisabled,
              getter: () => _vm.params.value,
              setter: (value) => _vm.setValue(value),
              validations: _vm.validationRules,
              "dynamic-placeholder": _vm.params.cellPlaceholder,
              plain: _vm.params.plainValue,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }