<template>
    <v-btn
        :disabled="disabled"
        x-small
        :primary="!isBasic"
        :text="isBasic"
        depressed
        max-width="2rem"
        min-width="2rem"
        class="navigate-button ml-2"
        :class="{ basic: isBasic }"
        @click="$emit('click')"
    >
        <v-icon v-if="left">
            navigate_before
        </v-icon>
        <v-icon v-else-if="right">
            navigate_next
        </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        left: {
            required: false,
            type: Boolean,
            default: false,
        },
        right: {
            required: false,
            type: Boolean,
            default: false,
        },
        // If true arrow button will not have background
        isBasic: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins';
@import '@style/base/_variables';

.navigate-button {
    color: white;

    &.basic {
        color: $promo-primary-colour;
    }
}
</style>
