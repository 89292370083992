var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "funding-viewer-grid-container" },
    [
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "resize-to-fit": false,
          "grid-style": "width: 100%; height: 64rem;",
          "grid-class": "ag-theme-custom__funding-viewer-grid",
          "dom-layout": "normal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }