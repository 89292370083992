import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VExpansionPanels,
    {
      staticClass: "px-1",
      attrs: { readonly: _vm.isExpanded, accordian: "" },
      model: {
        value: _vm.isOpenState,
        callback: function ($$v) {
          _vm.isOpenState = $$v
        },
        expression: "isOpenState",
      },
    },
    [
      _c(
        VExpansionPanel,
        { staticClass: "product-group" },
        [
          _c(VExpansionPanelHeader, {
            staticClass: "pa-1 expansion-header",
            attrs: { "hide-actions": "" },
            on: {
              click: function ($event) {
                return _vm.pgClicked(_vm.isOpenState)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ open }) {
                  return [
                    _c(
                      VRow,
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          VCol,
                          { attrs: { cols: "1" } },
                          [
                            _c(
                              VRow,
                              {
                                staticClass: "justify-center",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c("badge", {
                                  staticClass: "mt-1",
                                  attrs: { type: _vm.badgeType },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VRow,
                              {
                                staticClass: "justify-center mt-1",
                                attrs: { "no-gutters": "" },
                              },
                              [
                                _c("main-expand-button", {
                                  staticClass: "text-center",
                                  attrs: { "is-expanded": open },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          { attrs: { cols: "10" } },
                          [
                            _c(
                              VRow,
                              { attrs: { "no-gutters": "" } },
                              [
                                !_vm.editable
                                  ? _c(
                                      VChip,
                                      {
                                        staticClass: "font-weight-bold",
                                        attrs: {
                                          small: "",
                                          label: "",
                                          color: "transparent",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.name) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "vuex-text-field",
                                      _vm._b(
                                        {
                                          staticClass: "pg-name",
                                          attrs: { white: "" },
                                          on: {
                                            change:
                                              _vm.onChangePromotionProductGroup,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.openExpansionPanel.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                            keypress: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              $event.preventDefault()
                                              return _vm.blurPGName.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        "vuex-text-field",
                                        _vm.nameField,
                                        false
                                      )
                                    ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VRow,
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  VChip,
                                  {
                                    staticClass:
                                      "font-weight-bold indigo--text",
                                    attrs: {
                                      small: "",
                                      label: "",
                                      color: "transparent",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$tc(
                                              "planning.promotionsMaintenance.products.productItem.count",
                                              _vm.isNew
                                                ? _vm.editableCount
                                                : _vm.products.length
                                            )
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isDraggable
                          ? _c(
                              VCol,
                              { attrs: { cols: "1" } },
                              [
                                _c("icon", {
                                  staticClass: "product-group__move_icon",
                                  attrs: {
                                    "icon-name": "cross-move",
                                    small: "",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            VExpansionPanelContent,
            [
              _vm.editable
                ? _c(
                    "product-group-draggable-area",
                    _vm._b(
                      {
                        attrs: {
                          "product-lozenge-layout": _vm.productLozengeLayout,
                        },
                        on: { draggableChange: _vm.onDraggableChange },
                      },
                      "product-group-draggable-area",
                      _vm.productsField,
                      false
                    )
                  )
                : _c(
                    "div",
                    [
                      _vm._l(_vm.pgProducts, function (product) {
                        return [
                          !product.hideProduct
                            ? _c("product", {
                                key: `productgroup-${_vm._id}-product-${product.productKey}`,
                                staticClass: "mx-2 product-group-product",
                                attrs: {
                                  product: product,
                                  draggable: false,
                                  selectable: _vm.selectable,
                                  layout: _vm.productLozengeLayout,
                                  disabled: _vm.disabled,
                                  selected: _vm.isProductSelected(product),
                                },
                                on: {
                                  "product-selector-update":
                                    _vm.productSelectorUpdate,
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
              _vm._v(" "),
              _vm.editable
                ? _c(
                    "div",
                    { staticClass: "product-group__delete-container" },
                    [
                      _c("common-delete-dialog", {
                        attrs: {
                          resource: _vm.entityType,
                          "child-dependencies-warning": false,
                        },
                        on: {
                          delete: function ($event) {
                            return _vm.$emit("delete")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }