import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "client-systems-testing" }, [
    _c(
      "div",
      { staticClass: "client-systems-testing__switch" },
      [
        _c("div", { staticClass: "client-systems-testing__switch--test" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("admin.clientSystemsTesting.systemToTest")
                )
              ) +
              ":\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          VBtnToggle,
          {
            attrs: { mandatory: "" },
            model: {
              value: _vm.clientSystem,
              callback: function ($$v) {
                _vm.clientSystem = $$v
              },
              expression: "clientSystem",
            },
          },
          [
            _c(VBtn, { attrs: { value: _vm.clientSystems.forecasting } }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("admin.clientSystemsTesting.forecastingSystem")
                    )
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(VBtn, { attrs: { value: _vm.clientSystems.execution } }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("admin.clientSystemsTesting.executionSystem")
                    )
                  ) +
                  "\n            "
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "client-systems-testing__headers" }, [
      _c("div", { staticClass: "client-systems-testing__headers--input" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("admin.clientSystemsTesting.jsonInput")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "client-systems-testing__headers--input" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("admin.clientSystemsTesting.jsonResponse")) +
            "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "client-systems-testing__editors" },
      [
        _c("v-jsoneditor", {
          ref: "editor",
          attrs: { options: _vm.options, plus: false },
          on: { error: _vm.onError },
          model: {
            value: _vm.json[_vm.clientSystem],
            callback: function ($$v) {
              _vm.$set(_vm.json, _vm.clientSystem, $$v)
            },
            expression: "json[clientSystem]",
          },
        }),
        _vm._v(" "),
        _c("v-jsoneditor", {
          ref: "response-editor",
          staticClass: "client-systems-testing__editors--response",
          attrs: {
            value: !_vm.isEmpty(_vm.errorJson)
              ? _vm.errorJson
              : _vm.responseJson,
            plus: false,
            options: _vm.responseOptions,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "client-systems-testing__buttons" },
      [
        _c(
          VBtn,
          {
            attrs: { primary: "", depressed: "" },
            on: { click: _vm.transformPromo },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.transform"))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(VDivider, {
          staticClass: "client-systems-testing__buttons--divider",
          attrs: { vertical: "" },
        }),
        _vm._v(" "),
        _c(
          VBtn,
          {
            attrs: {
              primary: "",
              depressed: "",
              disabled: _vm.disabled || _vm.isEmpty(_vm.transformedPromotion),
            },
            on: { click: _vm.submit },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.submit"))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "client-systems-testing__buttons--clear",
            attrs: { primary: "", depressed: "", disabled: _vm.disabled },
            on: { click: _vm.clear },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.clear"))) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }