var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scenarios-viewer" }, [
    _c(
      "div",
      [
        _vm._l(_vm.categories, function (category, categoryIndex) {
          return [
            _c(
              "div",
              {
                key: category.name + ":heading:" + categoryIndex,
                staticClass: "scenarios-viewer__heading",
              },
              [
                _c("truncate-text-tooltip", {
                  attrs: { text: category.name, "max-width": 800 },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(category.scenarios, function (scenario, rowIndex) {
              return [
                _c("scenario-viewer", {
                  key: "scenario::" + scenario._id + "::" + rowIndex,
                  ref: `scenario_${scenario._id}`,
                  refInFor: true,
                  attrs: {
                    scenario: scenario,
                    "sub-campaign-context": _vm.subCampaignContext(category),
                    "make-read-only":
                      _vm.subCampaignEditingState.disabled &&
                      !_vm.toggleLogic[_vm.canEditInFlightPromotion],
                    "read-only-reason": _vm.subCampaignEditingState.reason,
                    "is-locked": _vm.isScenarioEditingDisabled(scenario._id),
                    "is-private":
                      _vm.toggleLogic[_vm.enablePublicPrivateEntities] &&
                      scenario.isPrivate,
                  },
                }),
              ]
            }),
            _vm._v(" "),
            _c(
              "div",
              { key: category.name + ":btn:" + categoryIndex },
              [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.canCreateScenario } },
                  [
                    _c("scenario-form-dialog", {
                      attrs: {
                        context: _vm.subCampaignContext(category),
                        "make-read-only": _vm.subCampaignEditingState.disabled,
                        "read-only-reason": _vm.subCampaignEditingState.reason,
                        "add-btn": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        }),
        _vm._v(" "),
        _vm.categories.length === 0
          ? _c(
              "div",
              [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.canCreateScenario } },
                  [
                    _c("scenario-form-dialog", {
                      attrs: {
                        context: _vm.subCampaignContext(),
                        "make-read-only": _vm.subCampaignEditingState.disabled,
                        "read-only-reason": _vm.subCampaignEditingState.reason,
                        "add-btn": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }