import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: _vm.dialogTitle,
      "has-activator": _vm.hasActivator,
      "ignore-parent-read-only": "",
      "show-positive-action-button": "",
      "btn-close-text": "actions.cancel",
    },
    on: {
      close: function ($event) {
        return _vm.closeDialog()
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c("div", { staticStyle: { display: "contents" } }, [
                    _vm.showUnlockButton
                      ? _c(
                          "div",
                          { staticClass: "icons-container" },
                          [
                            _vm.isPromotionUnlockRequested &&
                            _vm.canUnlockPromotion
                              ? _c(VAvatar, {
                                  staticClass: "notification-indicator",
                                  attrs: { size: "5" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "icon",
                              _vm._g(
                                {
                                  attrs: {
                                    "icon-name": _vm.iconName,
                                    disabled: _vm.dialogDisabled,
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                activator
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _vm.canUnlockPromotion
                ? _c("div", { staticClass: "message" }, [
                    _vm.isPromotionUnlockRequested
                      ? _c("div", { staticClass: "mb-1" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    "dialogs.promotionUnlock.notesForDetails"
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-1" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("dialogs.promotionUnlock.unlockMessage")
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("dialogs.promotionUnlock.unlockWarning")
                          )
                        )
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "message" }, [
                    _vm.isPromotionUnlockRequested
                      ? _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    "dialogs.promotionUnlock.unlockAlreadyRequested"
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "mb-1" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(
                                      "dialogs.promotionUnlock.requestUnlockMessage"
                                    )
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-1" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(
                                      "dialogs.promotionUnlock.requestUnlockNote"
                                    )
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "notes__input-container" }, [
                            _c(
                              "div",
                              { staticClass: "notes__input" },
                              [
                                _c(VTextarea, {
                                  attrs: {
                                    label: _vm._f("toSentenceCase")(
                                      _vm.$t(
                                        "dialogs.promotionUnlock.notePrompt"
                                      )
                                    ),
                                    "hide-details": "",
                                    "no-resize": "",
                                    solo: "",
                                    autocomplete: "",
                                    flat: "",
                                    rows: "4",
                                  },
                                  model: {
                                    value: _vm.noteText,
                                    callback: function ($$v) {
                                      _vm.noteText = $$v
                                    },
                                    expression: "noteText",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                  ]),
            ]
          },
          proxy: true,
        },
        {
          key: "positiveActionButton",
          fn: function () {
            return [
              _vm.canUnlockPromotion && _vm.isPromotionUnlockRequested
                ? _c("task-button", {
                    staticClass: "task-button",
                    attrs: {
                      "is-negative-action": true,
                      task: _vm.workflowTasks.unlock,
                      "entity-type": _vm.workflowEntities.promotion,
                      "entity-ids": [_vm.promotion._id],
                      "sub-campaign-id": _vm.selectedSubCampaignId,
                      "show-action-date-time": false,
                      categories: _vm.promotion.userSelectedCategories || [],
                      "is-store-wide-promotion": _vm.isStoreWidePromotion,
                      "button-style": { "margin-left": "1rem" },
                      small: false,
                      "sub-campaign": _vm.subCampaign,
                      "short-text": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeDialog()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.canUnlockPromotion
                ? _c("task-button", {
                    staticClass: "task-button",
                    attrs: {
                      task: _vm.workflowTasks.unlock,
                      "entity-type": _vm.workflowEntities.promotion,
                      "entity-ids": [_vm.promotion._id],
                      "sub-campaign-id": _vm.selectedSubCampaignId,
                      "show-action-date-time": false,
                      categories: _vm.promotion.userSelectedCategories || [],
                      "is-store-wide-promotion": _vm.isStoreWidePromotion,
                      "button-style": { "margin-left": "1rem" },
                      small: false,
                      "sub-campaign": _vm.subCampaign,
                      "short-text": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeDialog()
                      },
                    },
                  })
                : _c("task-button", {
                    staticClass: "task-button",
                    attrs: {
                      disabled: !_vm.noteText.length,
                      task: _vm.workflowTasks.requestUnlock,
                      "entity-type": _vm.workflowEntities.promotion,
                      "entity-ids": [_vm.promotion._id],
                      "sub-campaign-id": _vm.selectedSubCampaignId,
                      "show-action-date-time": false,
                      categories: _vm.promotion.userSelectedCategories || [],
                      "is-store-wide-promotion": _vm.isStoreWidePromotion,
                      "button-style": { "margin-left": "1rem" },
                      small: false,
                      "sub-campaign": _vm.subCampaign,
                      "short-text": "",
                    },
                    on: {
                      "action-completed": _vm.createNote,
                      click: function ($event) {
                        return _vm.closeDialog({ resetNote: false })
                      },
                    },
                  }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }