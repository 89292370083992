<template>
    <div v-if="isVisible" class="revert-container">
        <div v-if="!readOnly" class="ag-revert-input">
            <div class="button-container">
                <icon-button
                    v-if="isRevertVisible"
                    class="button"
                    icon="revert"
                    @click="revert()"
                />
            </div>
            <div class="input-container">
                <vuex-number-input
                    class="input"
                    :getter="() => overrideValue"
                    :setter="value => setValue(value)"
                    :placeholder="initialValue"
                    filled
                    positive-only
                    format="numbers.default.overwrittenVolumeEntry"
                />
            </div>
        </div>
        <div class="initial-value">
            {{ readOnlyValue }}
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { get, has, isNil } from 'lodash';

export default Vue.extend({
    computed: {
        isRevertVisible() {
            return !isNil(this.overrideValue);
        },

        // This computed is used on the supplier funding page where we use `visible`
        // field to hide it depending on the ag-grid renderer params.
        // 'visible` param is  optional and if not provided this method will just return true and component will be visible
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },

        readOnly() {
            return has(this.params, 'readOnly') ? this.params.readOnly(this.params) : false;
        },

        initialValue() {
            const initialValue = get(this.params.value, 'defaultValue');
            return this.$n('numbers.default.overwrittenVolumeEntry', initialValue, {
                usePlaceholder: true,
            });
        },

        overrideValue() {
            return get(this.params.value, 'isOverridden', false)
                ? get(this.params.value, 'overrideValue')
                : null;
        },

        readOnlyValue() {
            if (this.readOnly) {
                // in readOnly mode user should see overrideValue if it's defined
                return isNil(this.overrideValue)
                    ? this.initialValue
                    : this.$n('numbers.default.overwrittenVolumeEntry', this.overrideValue, {
                          usePlaceholder: true,
                      });
            }
            return this.initialValue;
        },
    },

    methods: {
        setValue(value) {
            const params = {
                oldValue: isNil(this.overrideValue) ? this.initialValue : this.overrideValue,
                newValue: value,
                data: this.params.data,
                colDef: {
                    fieldName: this.params.colDef.overrideFieldName,
                    fieldPath: this.params.colDef.overrideFieldPath,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },

        revert() {
            this.setValue(null);
        },
    },
});
</script>

<style scoped lang="scss">
.revert-container {
    display: flex;
    flex-direction: column;

    .ag-revert-input {
        display: flex;
        width: 8rem;

        .button-container {
            width: 2rem;
        }

        .input-container {
            width: 6.5rem;

            &::v-deep .v-input__slot {
                height: 1.8rem;
            }
        }
    }

    .initial-value {
        display: flex;
        justify-content: flex-end;
        padding-right: 0.45rem;
        font-size: 1.1rem;
    }
}
</style>
