import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VTextField,
    {
      staticClass: "rtls-search rtls-search--white",
      attrs: { "hide-details": "", placeholder: _vm.placeholder },
    },
    [
      _c(
        VIcon,
        {
          attrs: { slot: "append", small: "", color: "primary" },
          slot: "append",
        },
        [_vm._v("search")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }