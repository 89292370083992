import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["week", _vm.selectedFadedClass],
      attrs: {
        id: "week" + _vm.weekObj.weekOfYear + _vm.weekObj.year,
        "data-week": _vm.weekObj.weekOfYear,
        "data-year": _vm.weekObj.year,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "week__heading",
          class: {
            active:
              _vm.weekObj.weekOfYear === _vm.currentWeek &&
              _vm.weekObj.year === _vm.currentYear,
          },
        },
        [
          _c("div", [
            _c("span", { staticClass: "week__number" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm._f("weekNumberClient")(_vm.currentWeekOfYear))
                ),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.weekObj.year) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                " +
                  _vm._s(_vm._f("datesInYear")(_vm.weekObj.startDate)) +
                  " -\n                " +
                  _vm._s(_vm._f("datesInYear")(_vm.weekObj.endDate)) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("promotion-rag-colours", {
            attrs: {
              vertical: true,
              "promotion-rag-colour-counts": _vm.effectivenessRatings,
              "display-total": true,
            },
          }),
          _vm._v(" "),
          _vm.displaySummaryKpis
            ? _c(
                "div",
                { staticClass: "summary-kpis" },
                [
                  _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("general.dates.comparedAgainst")
                          )
                        ) +
                        "\n                "
                    ),
                    _c("b", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("general.dates.weekFirstLetter")) +
                          _vm._s(
                            _vm.isComparableWeekCW
                              ? _vm.weekObj.mappedWeek
                              : _vm.weekObj.weekOfYear
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.isComparableWeekCW
                            ? _vm.weekObj.mappedYear
                            : _vm.weekObj.year - 1
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("campaigns-weekly-view-header-kpis", {
                    attrs: {
                      header: _vm.$tkey("kpiSummary.header"),
                      "is-header-row": "",
                    },
                  }),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c("campaigns-weekly-view-header-kpis", {
                    attrs: {
                      header: _vm.$tkey("kpiSummary.salesHeader"),
                      metric: "actualSalesExcTax",
                      "week-obj": _vm.weekObj,
                      "is-header-bold": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("campaigns-weekly-view-header-kpis", {
                    attrs: {
                      header: _vm.$tkey("kpiSummary.salesIncrementalHeader"),
                      metric: "incrementalSalesExcTax",
                      "week-obj": _vm.weekObj,
                    },
                  }),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c("campaigns-weekly-view-header-kpis", {
                    attrs: {
                      header: _vm.$tkey("kpiSummary.marginHeader"),
                      metric: "promoMarginRate",
                      "week-obj": _vm.weekObj,
                      "is-header-bold": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("campaigns-weekly-view-header-kpis", {
                    attrs: {
                      header: _vm.$tkey("kpiSummary.marginIncrementalHeader"),
                      metric: "incrementalMargin",
                      "week-obj": _vm.weekObj,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }