import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "btn-checkbox-wrapper" },
    [
      !_vm.params.data.promotionId &&
      !_vm.isCategoryOrStoreWidePromotion(_vm.params.data.id)
        ? _c(
            VBtn,
            {
              attrs: { "x-small": "", depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.btnClickedHandler()
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("subCampaignAllocation.forecastsModal.view")
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }