<template>
    <create-new-button v-bind="$attrs" background @createNew="createNewResource" />
</template>

<script>
import vuexFormMixin from '../../mixins/vuex-form';

export default {
    mixins: [vuexFormMixin],

    props: {
        beforeCreateFunction: {
            type: Function,
            default: () => {},
        },
    },

    methods: {
        async createNewResource() {
            this.beforeCreateFunction();
            const { error, result } = await this.submit();
            if (error) {
                this.$emit('resourceCreationFailed', error);
            } else {
                this.$emit('resourceCreated', result);
            }
        },
    },
};
</script>
