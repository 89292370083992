<template>
    <vuex-currency-input
        :getter="() => params.value"
        :setter="value => setValue(value)"
        filled
        :disabled="isDisabled"
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    computed: {
        isDisabled() {
            const data = this.params.data;
            return this.params.isDisabled(data);
        },
    },

    methods: {
        setValue(value) {
            const params = {
                oldValue: this.params.value,
                newValue: value,
                data: this.params.data,
                colDef: {
                    field: this.params.colDef.field,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },
    },
});
</script>
