import {
    find,
    map,
    has,
    flatten,
    includes,
    flattenDeep,
    cloneDeep,
    isEmpty,
    forEach,
    union,
} from 'lodash';
import uuid from 'uuid/v4';

const createNominationMatrixVuexOptions = () => {
    return {
        getters: {
            getDefaultResources: () => ({ resources, storeGroups }) => {
                return map(resources, option => {
                    return has(option, 'type') && has(option, 'instances')
                        ? option
                        : {
                              type: option.key,
                              clientKey: option.clientKey,
                              instances: [{ key: uuid(), storeGroups: [...storeGroups] }],
                          };
                });
            },
            getResourceOptions: (state, getters, rootState, rootGetters) => ({
                parentModule,
                parentGetter,
                parentContextId,
                contextId,
                childModule,
                childGetter,
                contextModule,
                contextGetter,
            }) => {
                const selectedParentContext = parentContextId
                    ? rootGetters[`${parentModule}/${parentGetter}`]({
                          _id: parentContextId,
                          usePluralResourceName: true,
                      })
                    : rootGetters[`${parentModule}/${parentGetter}`];
                const availableResources =
                    rootGetters['clientConfig/getPromoResourcesWithSubTypes'];

                if (selectedParentContext && availableResources && availableResources.length) {
                    let nominatedResources = [];
                    let plannedInstanceKeys = [];
                    const plannedInstancesStoreGroups = {};

                    // If a contextId has been provided then we need to identify
                    // what resources have been nominated at child (promotion/scenario) level, so
                    // we can disable those resources at scenario/sub-campaign level.
                    if (contextId) {
                        const childResources = rootGetters[`${childModule}/${childGetter}`](
                            contextId
                        );
                        // Retrieve a list of all resources that have been nominated.
                        nominatedResources = flatten(
                            map(childResources, promotion => map(promotion.resources, 'type'))
                        );

                        // Retrieve a list of all instance keys that have been nominated.
                        plannedInstanceKeys = flattenDeep(
                            map(childResources, p =>
                                map(p.resources, r =>
                                    r.instances.map(i => {
                                        if (!plannedInstancesStoreGroups[i.key]) {
                                            plannedInstancesStoreGroups[i.key] = [];
                                        }
                                        // Storing all combinations of store groups used in planned instance as an array of keys
                                        plannedInstancesStoreGroups[i.key].push(
                                            map(i.storeGroups, 'key')
                                        );
                                        return i.key;
                                    })
                                )
                            )
                        );
                    }

                    // Take a copy of the parent resources, as we're adding a disabled
                    // property to each instance, and we don't want this property to
                    // persist in the vuex store.
                    let parentResources = selectedParentContext.resources;

                    if (parentResources) {
                        // for a scenario and promotion
                        // if resources are provided by parent, clone them
                        parentResources = cloneDeep(parentResources);
                    } else if (contextId) {
                        // for a sub-campaign
                        // if item is in edit mode, get resources from itself
                        const context =
                            rootGetters[`${contextModule}/${contextGetter}`]({
                                _id: contextId,
                                usePluralResourceName: true,
                            }) || {};
                        const contextResources = context.resources;
                        parentResources = map(
                            selectedParentContext.promoResources,
                            promoResource => {
                                // find resource by type, or create an empty one,
                                // that can be used as option in matrix
                                const contextResource = find(contextResources, {
                                    type: promoResource.key,
                                }) || {
                                    type: promoResource.key,
                                    instances: [],
                                };
                                return cloneDeep(contextResource);
                            }
                        );
                    } else {
                        // for a sub-campaign
                        // if item is in create mode
                        // create default resources, that can be used as option in matrix
                        parentResources = getters.getDefaultResources({
                            resources: selectedParentContext.promoResources,
                            storeGroups: selectedParentContext.storeGroups,
                        });
                    }

                    // Build up a list of available resource options based on what
                    // has been selected on the parent object.
                    return map(parentResources || [], parentResource => {
                        const resourceOption = find(availableResources, {
                            key: parentResource.type,
                        });

                        // Traverse through each instance to set the disabled property
                        // where an instance has been nominated at promotion level.
                        parentResource.instances.forEach(instance => {
                            instance.disabled = includes(plannedInstanceKeys, instance.key);
                            if (instance.disabled) {
                                // if instance is disabled we attach info about store groups that can not be deselected
                                instance.disabledStoreGroups = union(
                                    ...plannedInstancesStoreGroups[instance.key]
                                );
                            }
                        });

                        return {
                            ...parentResource,
                            ...resourceOption,
                            // Set disabled property to true where the resource has been
                            // nominated at promotion level.
                            disabled: contextId
                                ? includes(nominatedResources, resourceOption.key)
                                : false,
                        };
                    });
                }

                return [];
            },

            getParkingLotResourceOptions: (state, getters, rootState, rootGetters) => ({
                contextId,
            }) => {
                const availableResources = rootGetters['clientConfig/getPromoResources'];
                if (availableResources && availableResources.length) {
                    const contextResources = rootGetters[`promotions/getStagingAreaField`]({
                        namespace: contextId,
                        fieldName: 'resources',
                    });

                    // for a promotion without parent scenario
                    // create default resources, that can be used as option in matrix,
                    // using it's own resources to save ID
                    const defaultStoreGroups = rootGetters['storeGroups/getStoreGroupsOptions'];
                    const defaultResources = getters.getDefaultResources({
                        resources: availableResources,
                        storeGroups: defaultStoreGroups,
                    });

                    const updatedContextResources = map(defaultResources, promoResource => {
                        // find resource by type, or create an empty one,
                        // that can be used as option in matrix
                        const contextResource = find(contextResources, {
                            type: promoResource.type,
                        }) || {
                            type: promoResource.type,
                        };

                        const contextResourceCopy = cloneDeep(contextResource);

                        // if no instances, add default one
                        if (isEmpty(contextResourceCopy.instances)) {
                            contextResourceCopy.instances = [
                                { key: uuid(), storeGroups: [...defaultStoreGroups] },
                            ];
                        }
                        // add default storeGroups for original promotion instances,
                        // if they where changed by user,
                        // or for default instance
                        forEach(contextResourceCopy.instances, instance => {
                            if (instance.storeGroups.length !== defaultStoreGroups.length) {
                                instance.storeGroups = [...defaultStoreGroups];
                            }
                        });
                        return contextResourceCopy;
                    });

                    return map(updatedContextResources || [], resource => {
                        const resourceOption = find(availableResources, {
                            key: resource.type,
                        });

                        return {
                            ...resource,
                            ...resourceOption,
                            disabled: false,
                        };
                    });
                }

                return [];
            },
        },
        mutations: {},
        actions: {},
    };
};

export default createNominationMatrixVuexOptions;
