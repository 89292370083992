var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "17px",
        viewBox: "0 0 16 17",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Newspaper")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-106.000000, -214.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Newspaper",
                    transform: "translate(105.999980, 214.800510)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M11.7576989,1 L11.7575762,3.001 L14.2434238,3.00198828 L14.2434238,15 L4.24230106,15 L4.24157622,12.998 L1.75657622,12.9980117 L1.75657622,1 L11.7576989,1 Z M13.2431945,4.00173547 L11.7575762,4.001 L11.7576989,12.9980117 L5.24157622,12.998 L5.24219454,13.9997355 L13.2431945,13.9997355 L13.2431945,4.00173547 Z M10.7574697,1.99974719 L2.75646969,1.99974719 L2.75646969,11.9977472 L10.7574697,11.9977472 L10.7574697,1.99974719 Z M9.75657622,10.5 L9.75657622,11.3 L3.75657622,11.3 L3.75657622,10.5 L9.75657622,10.5 Z M9.75657622,9 L9.75657622,9.8 L3.75657622,9.8 L3.75657622,9 L9.75657622,9 Z M7.25657622,4.5 L7.25657622,8.5 L3.75657622,8.5 L3.75657622,4.5 L7.25657622,4.5 Z M9.75657622,7.5 L9.75657622,8.3 L7.75657622,8.3 L7.75657622,7.5 L9.75657622,7.5 Z M9.75657622,6 L9.75657622,6.8 L7.75657622,6.8 L7.75657622,6 L9.75657622,6 Z M9.75657622,4.5 L9.75657622,5.3 L7.75657622,5.3 L7.75657622,4.5 L9.75657622,4.5 Z M9.75657622,2.7 L9.75657622,3.9 L3.75657622,3.9 L3.75657622,2.7 L9.75657622,2.7 Z",
                      fill: "#2F477C",
                      "fill-rule": "nonzero",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }