var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "supplier-commitment-viewer-wrapper" }, [
    !_vm.isExpanded
      ? _c(
          "div",
          { staticClass: "supplier-commitment-viewer" },
          [
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                "div",
                {
                  key: `supplierCommitment::${_vm.supplierCommitment._id}::field::${index}`,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "supplier-commitment-viewer__grid-cell",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click")
                        },
                      },
                    },
                    [
                      field.functionName
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.callAction(
                                    field.functionName,
                                    _vm.supplierCommitment
                                  )
                                )
                              ),
                            ]),
                          ])
                        : field.component
                        ? _c(
                            "div",
                            [
                              _c(
                                field.component.name,
                                _vm._g(
                                  _vm._b(
                                    { tag: "component" },
                                    "component",
                                    _vm.constructVBindObj({
                                      context: _vm.supplierCommitment,
                                      field: field.name,
                                      ...field.component,
                                    }),
                                    false
                                  ),
                                  _vm.constructVBindEventObj({
                                    events: _vm.getAttribute(
                                      field.component,
                                      "events"
                                    ),
                                    context: _vm.supplierCommitment,
                                  })
                                )
                              ),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getAttribute(
                                  _vm.supplierCommitment,
                                  field.name
                                )
                              )
                            ),
                          ]),
                    ]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm.isPromotionSupplierFundingView
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "supplier-commitment-viewer__grid-cell" },
                    [
                      _c("allocation-checkbox", {
                        attrs: {
                          "supplier-commitment": _vm.supplierCommitment,
                          "promotion-namespace": _vm.promotionNamespace,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          2
        )
      : _c(
          "div",
          { staticStyle: { display: "contents" } },
          [
            _c("supplier-commitment-form-wrapper", {
              attrs: {
                "edit-context": _vm.supplierCommitment,
                "form-fields": _vm.formFields,
                "is-promotion-supplier-funding-view":
                  _vm.isPromotionSupplierFundingView,
                "promotion-namespace": _vm.promotionNamespace,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }