var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isExpanded
    ? _c("div", { staticClass: "summary-header lump-funding__header" }, [
        _c("div", { staticClass: "summary-header__title" }, [
          _c("label", { staticClass: "summary-header__label" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.params.displayName) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary-header__info" }, [
          _c("div", { staticClass: "line1" }, [
            _c("div", { staticClass: "total" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.formattedValue(_vm.totalAllocatedFunding)) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "indication-icons" },
              [
                !_vm.allAssignedMatchExpected
                  ? _c("icon", {
                      attrs: { "icon-name": "failure-circle", small: "" },
                    })
                  : _vm.allRateCardsAssigned
                  ? _c("icon", {
                      attrs: { "icon-name": "success-circle", small: "" },
                    })
                  : _c("icon", {
                      attrs: { "icon-name": "empty-success-circle", small: "" },
                    }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "line2" },
            [
              _c("advertisement-fees", {
                attrs: {
                  namespace: _vm.params.promotionId,
                  "assigned-rate-cards": _vm.assignedRateCards,
                  "field-name": "rateCards",
                  "vuex-module": "promotions",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }