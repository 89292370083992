<template>
    <div class="campaign" :style="gridCss" :class="cssClasses" @click="onClick">
        <span class="campaign-name"
            >{{ context.name }} {{ context.briefing ? '/' : '' }} {{ context.briefing }}</span
        >
        <div>
            <parent-child-chips :context="context || {}" />
            <div v-if="isLocked" class="lock-icon">
                <icon icon-name="locked" small />
            </div>
            <div v-if="isPrivate" class="eye-icon">
                <icon icon-name="eye-closed" small />
            </div>
            <div v-if="!isReadOnly" class="icons">
                <template v-for="icon in icons">
                    <feature-toggle :key="icon.name" :toggle="icon.featureToggle">
                        <component
                            :is="icon.component.name"
                            v-if="
                                icon.component.showPropName
                                    ? getComputed(icon.component.showPropName)
                                    : true
                            "
                            v-bind="
                                constructVBindObj({
                                    context: context,
                                    field: icon.field,
                                    ...icon.component,
                                })
                            "
                            v-on="
                                constructVBindEventObj({
                                    events: getAttribute(icon.component, 'events'),
                                    context: context,
                                })
                            "
                        />
                    </feature-toggle>
                </template>
            </div>
            <div class="clearfix" />
        </div>
    </div>
</template>

<script>
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [configDrivenGridComponentMixin],

    data() {
        return {};
    },

    computed: {
        itemStartDate() {
            const startDate = this.$moment(this.context.startDate);
            return startDate.isSameOrAfter(this.parentStartDate, 'day')
                ? startDate
                : this.parentStartDate;
        },

        itemEndDate() {
            const endDate = this.$moment(this.context.endDate);
            return endDate.isSameOrBefore(this.parentEndDate, 'day') ? endDate : this.parentEndDate;
        },

        gridCss() {
            return {
                'grid-column': `${this.getDateDifference(this.itemStartDate, this.parentStartDate) +
                    1} /
                 ${this.getDateDifference(this.itemEndDate, this.parentStartDate) + 2}`,
            };
        },

        // Abstract method declaration. Could be implemented at child level
        // for specifying css classes.
        cssClasses() {
            return [];
        },
    },
    methods: {
        getDateDifference(d1, d2) {
            d1 = this.$moment(d1);
            return d1.diff(d2, 'days');
        },
    },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.campaign {
    border-color: $promo-campaign-border;
    border-style: solid;
    border-width: 2px 0 0 5px;
    background-color: $promo-white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 1px 5px;
    height: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    color: $promo-text-colour;
    display: flex;
    position: relative;
    flex-direction: column;

    // fade in
    opacity: 1;
    animation: fade 0.5s linear;

    &:hover {
        background-color: $promo-campaign-color-2;
        border-color: $promo-campaign-border-2;
        cursor: pointer;
    }

    .campaign-name {
        line-height: 1.3rem;
        padding-bottom: 1.5rem;
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icons {
        float: right;
    }

    .lock-icon {
        float: left;
    }

    .eye-icon {
        float: left;
        padding-top: 0.1rem;
    }

    .inheritance-chips {
        transform: scale(0.75);
        margin-left: -0.8rem;
        .v-chip {
            height: 2.1rem;
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
