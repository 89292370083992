<template>
    <channels-stores-tab :form="form" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { canEditPromotion } from '@enums/feature-flags';
import { sourceTypes } from '@enums/vuex-form';
import { componentTypes } from '@enums/custom-components';
import { promotions } from '@enums/resources';
import { channels } from '@enums/promotion-tabs';
import { bind } from 'lodash';
import promotionTabFormWrapperMixin from '../mixins/promotion-tab-form-wrapper';
import { getDefaultValuePropName, getDefaultValuePropValue } from '@/js/utils/component-utils';
import i18n from '@/js/vue-i18n';

export default {
    mixins: [promotionTabFormWrapperMixin],

    data: () => ({
        // We open detailedProvisions panel as soon as detailed provision was added.
        // null represents that this should default to being closed. 0 - opened.
        detailedProvisionsOpenState: null,
    }),
    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'additionalDetailsDefaultSelection']),
        ...mapState('storeGroups', ['storeGroups']),
        ...mapGetters('promotions', ['isSelectedPromotionCategoryOrStoreWide']),

        isEditable() {
            return !!this.toggleLogic[canEditPromotion];
        },

        form() {
            const form = {
                vuexModule: 'promotions',
                editMode: this.editMode,
                submitAction: this.isNewPromotion ? null : 'prepareAndUpdatePromotion',
                preventDefault: true,
                namespace: this.namespace,
                context: this.context,
                editContext: this.context.selectedPromotion,
                fields: [
                    {
                        type: componentTypes.expansionPanel,
                        isContainer: true,
                        label: i18n.t(
                            'planning.promotionsMaintenance.resources.resourceStoreGroupProvision.heading'
                        ),
                        cssClass: 'channels-stores-expansion-panel',
                        isOpenState: 0, // Represents that this should default to being opened.
                        children: [
                            {
                                type: componentTypes.nominationMatrix,
                                tab: channels,
                                editable: this.isEditable,
                                showExcludedStores: true,
                                showDetailedProvisions: true,
                                showResourceState:
                                    this.hasSubCampaign &&
                                    !this.newPromotionInSubCampaignAllocation,
                                parentContextType: promotions,
                                storeGroups: {
                                    fieldName: 'storeGroups',
                                    editable: this.isEditable,
                                    options: {
                                        source: sourceTypes.getter,
                                        identifier: 'getCheckboxListOptions',
                                        module: 'promotions',
                                        params: {
                                            attributeName: 'storeGroups',
                                            attributeKey: 'key',
                                            resource: 'storeGroups',
                                            getOptionsFunction: 'getStoreGroupsOptions',
                                            getUserAccessOptionsMap: 'userStoreGroupsMap',
                                            scenarioId: {
                                                source: this.newPromotionInSubCampaignAllocation
                                                    ? sourceTypes.context
                                                    : sourceTypes.editContext,
                                                path: this.newPromotionInSubCampaignAllocation
                                                    ? 'selectedScenario._id'
                                                    : 'scenarioId',
                                            },
                                        },
                                    },
                                    itemText: 'description',
                                    itemValue: 'key',
                                    [getDefaultValuePropName(
                                        this.additionalDetailsDefaultSelection.promotion.storeGroups
                                    )]: getDefaultValuePropValue(
                                        this.additionalDetailsDefaultSelection.promotion
                                            .storeGroups,
                                        {
                                            source: this.newPromotionInSubCampaignAllocation
                                                ? sourceTypes.context
                                                : sourceTypes.editContext,
                                            path: this.newPromotionInSubCampaignAllocation
                                                ? 'selectedScenario.storeGroups'
                                                : 'storeGroups',
                                        }
                                    ),
                                },
                                resources: {
                                    isRequired: true,
                                    fieldName: 'resources',
                                    editable: this.isEditable,
                                    itemText: 'description',
                                    itemValue: 'key',
                                    options: {
                                        source: sourceTypes.getter,
                                        identifier: this.hasScenario
                                            ? 'getResourceOptions'
                                            : 'getParkingLotResourceOptions',
                                        module: 'promotions',
                                        params: this.hasScenario
                                            ? {
                                                  parentModule: 'scenarios',
                                                  parentGetter: 'getScenarioById',
                                                  parentContextId: {
                                                      source: sourceTypes.context,
                                                      path: 'selectedScenario._id',
                                                  },
                                              }
                                            : {
                                                  contextId: this.namespace,
                                              },
                                    },
                                    [getDefaultValuePropName(
                                        this.additionalDetailsDefaultSelection.promotion
                                            .promoResources
                                    )]: getDefaultValuePropValue(
                                        this.additionalDetailsDefaultSelection.promotion
                                            .promoResources,
                                        {
                                            source: sourceTypes.getter,
                                            identifier: 'getDefaultResources',
                                            module: 'promotions',
                                            params: {
                                                resources: this.hasScenario
                                                    ? {
                                                          source: this
                                                              .newPromotionInSubCampaignAllocation
                                                              ? sourceTypes.context
                                                              : sourceTypes.editContext,
                                                          path: this
                                                              .newPromotionInSubCampaignAllocation
                                                              ? 'selectedScenario.resources'
                                                              : 'resources',
                                                      }
                                                    : {
                                                          source: sourceTypes.getter,
                                                          identifier: 'getPromoResources',
                                                          module: 'clientConfig',
                                                      },
                                            },
                                        }
                                    ),
                                },
                            },
                        ],
                    },
                    {
                        type: componentTypes.expansionPanel,
                        isContainer: true,
                        label: i18n.t(
                            'planning.promotionsMaintenance.resources.additionalResourceDetails.heading'
                        ),
                        cssClass: 'channels-stores-expansion-panel',
                        isOpenState: this.detailedProvisionsOpenState,
                        change: bind(
                            this.onExpansionPanelStateChange,
                            this,
                            'detailedProvisionsOpenState'
                        ),
                        children: [
                            {
                                fieldName: 'detailedProvisions',
                                type: componentTypes.detailedProvisions,
                                namespace: this.namespace,
                            },
                        ],
                    },
                ],
            };

            if (!this.isSelectedPromotionCategoryOrStoreWide) {
                form.fields.push({
                    type: componentTypes.expansionPanel,
                    isContainer: true,
                    label: i18n.t(
                        'planning.promotionsMaintenance.resources.featuredProducts.heading'
                    ),
                    cssClass: 'channels-stores-expansion-panel',
                    isOpenState: null, // Represents that this should default to being closed.
                    children: [
                        {
                            fieldName: 'isDefault',
                            type: componentTypes.featuredProducts,
                            namespace: this.namespace,
                            promotionEditingState: this.promotionEditingState,
                            cacheDom: this.cacheDom,
                        },
                    ],
                });
            }
            return form;
        },
    },

    methods: {
        onExpansionPanelStateChange(isOpenState, value) {
            // 0 represents the open state as vuetify expansion-panels component uses
            // an index to render the currently opened panel
            // undefined value is not recognized as changed one
            this[isOpenState] = value === undefined ? null : 0;
        },
    },

    events: {
        onDetailedProvisionAdded() {
            this.detailedProvisionsOpenState = 0;
        },
    },
};
</script>
