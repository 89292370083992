<template>
    <div class="promotion-allocation-area">
        <div v-if="selectedResourceDefinition" class="promotion-allocation-area__grid">
            <div
                class="promotion-allocation-area__grid-cell promotion-allocation-area__title"
                :style="getSpanCss(9)"
            >
                <label class="font-weight-bold">
                    {{ $t('preparation.allocation.promotions') | toSentenceCase }}
                </label>
            </div>
            <div
                class="promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell--sidebar-heading"
                :style="getSpanCss(9)"
            >
                <!--Was commented out according PROWEB-1359-->
                <!--<div class="search-bar">-->
                <!--    <div class="search-bar__label">-->
                <!--        {{ $t('preparation.allocation.storeGroup') | toSentenceCase }}-->
                <!--    </div>-->
                <!--    <vuex-select-->
                <!--        class="col-5"-->
                <!--        :placeholder="-->
                <!--            $t('preparation.allocation.selectStoreGroups') | toSentenceCase-->
                <!--        "-->
                <!--        :options="[]"-->
                <!--        :getter="() => null"-->
                <!--        :setter="() => null"-->
                <!--        :edit-mode="false"-->
                <!--    />-->
                <!--</div>-->
            </div>
            <!--Was commented out according PROWEB-1359-->
            <!--<div-->
            <!--    class="promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell&#45;&#45;sidebar-heading promotion-allocation-area__grid-cell&#45;&#45;shadow"-->
            <!--    :style="getSpanCss(1)"-->
            <!-- >-->
            <!--    {{ $t('preparation.allocation.sort') }}-->
            <!--    <promotion-sorting />-->
            <!--</div>-->
            <!--<div-->
            <!--    class="promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell&#45;&#45;sidebar-heading"-->
            <!--   :style="getSpanCss(1)"-->
            <!-- >-->
            <!--    {{ $t('preparation.allocation.sort') }}-->
            <!--    <promotion-sorting />-->
            <!--</div>-->

            <template v-for="(promotion, rowIndex) in filteredPromotions">
                <div
                    v-for="(item, colIndex) in fields"
                    :key="promotion._id + '::' + rowIndex + '::' + colIndex"
                    :ref="`promotion_${promotion._id}`"
                    class="promotion-allocation-area__grid-cell promotion-allocation-area__grid-cell--promotion-content"
                    :class="[item.cellClasses, item.contentClasses]"
                    @click="toggleSelectedPromotion({ promotionId: promotion._id })"
                >
                    <div class="promotion-allocation-area__data" :class="getColourClass(promotion)">
                        <div v-if="item.functionName">
                            <span>{{ callAction(item.functionName, promotion) }}</span>
                        </div>

                        <div v-else-if="item.component">
                            <feature-toggle v-if="item.featureToggle" :toggle="item.featureToggle">
                                <component
                                    :is="item.component.name"
                                    v-bind="
                                        constructVBindObj({
                                            context: promotion,
                                            field: item.field,
                                            ...item.component,
                                        })
                                    "
                                    v-on="
                                        constructVBindEventObj({
                                            events: getAttribute(item.component, 'events'),
                                            context: promotion,
                                        })
                                    "
                                    >{{ $t(item.component.text) | toSentenceCase }}
                                </component>
                            </feature-toggle>
                            <component
                                :is="item.component.name"
                                v-else
                                v-bind="
                                    constructVBindObj({
                                        context: promotion,
                                        field: item.field,
                                        ...item.component,
                                    })
                                "
                                v-on="
                                    constructVBindEventObj({
                                        events: getAttribute(item.component, 'events'),
                                        context: promotion,
                                    })
                                "
                                >{{ $t(item.component.text) | toSentenceCase }}
                            </component>
                        </div>

                        <span v-else>{{ getAttribute(promotion, item.field) }}</span>
                    </div>
                </div>
                <promotion-products
                    v-if="isSelected(promotion)"
                    :key="'promotion-products' + rowIndex + promotion._id"
                    :promotion-id="promotion._id"
                    :is-products-disabled="isProductsDisabled(promotion)"
                    :assigned-products="getAssignedProducts(promotion)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import categoryAllocationsTypes from '@enums/category-allocations-types';
import { mapGetters, mapState, mapActions } from 'vuex';
import { filter, map, includes, find, flatMap, isNil, keyBy } from 'lodash';
import leafletPageTypes from '@enums/leaflet-page-types';
import detailedProvisionsNamesEnum from '@enums/detailed-provisions-names';
import { isCategoryOrStoreWidePromotion } from '@sharedModules/promotion-utils';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { toSentenceCase } from '@/js/utils/string-utils';
import fields from './promotion-allocation-area-fields';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin],

    data() {
        return {
            fields,
            selectedArea: null,
        };
    },

    computed: {
        ...mapState('clientConfig', ['hierarchyConfig', 'toggleLogic']),
        ...mapState('promotions', ['selectedPromotionId']),
        ...mapState('subCampaigns', ['selectedResourceDefinitionPageNumber']),
        ...mapGetters('subCampaigns', [
            'selectedSubCampaign',
            'selectedResourceDefinition',
            'selectedResourceDefinitionPage',
            'isPagesResource',
            'isCover',
        ]),
        ...mapGetters('scenarios', ['activeScenariosBySubCampaignId']),
        ...mapGetters('promotions', ['getNominatedPromotions']),

        nominatedPromotions() {
            return this.getNominatedPromotions(instance =>
                this.isLocationInstanceHasCurrentPageType(instance)
            );
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        filteredPromotions() {
            // Retrieve the active scenarios for the sub-campaign i.e. where 'isFavourite' is true.
            const activeScenariosForSelectedSubCampaign = this.activeScenariosBySubCampaignId(
                this.selectedSubCampaign._id
            );
            // all promotions that appear in the favourite scenario
            const filteredNominatedPromotions = filter(this.nominatedPromotions, promotion => {
                return includes(
                    map(activeScenariosForSelectedSubCampaign, '_id'),
                    promotion.scenarioId
                );
            });
            // Return all promotions that appear in the favourite scenario
            // if no areas have been selected or blank area is selected
            if (!this.selectedArea || this.selectedArea.categoryKey === null) {
                return filteredNominatedPromotions;
            }

            const promotionAllocationFilterFunction = this.featureAwareFactory.getPromotionAllocationFilterFunction();

            return promotionAllocationFilterFunction({
                scenarios: activeScenariosForSelectedSubCampaign,
                promotions: filteredNominatedPromotions,
                categoryKey: this.selectedArea.categoryKey,
                categoryLevel: this.getHierarchyConfig.categoryLevel,
            });
        },

        // ex: {'promotionId': {'promotionId': '1111', 'categoryKey": '1010', products: []}}
        selectedResourceDefinitionAssignmentsMap() {
            if (this.selectedResourceDefinition && this.selectedResourceDefinition.pageCount) {
                const assignmentsArray = flatMap(
                    this.selectedResourceDefinition.pages,
                    page => page.assignment
                );

                const assignmentsWithPromotions = assignmentsArray.filter(a => a.promotionId);
                return keyBy(assignmentsWithPromotions, 'promotionId');
            }
            return {};
        },
    },
    methods: {
        ...mapActions('promotions', ['toggleSelectedPromotion', 'setSelectedPromotion']),

        setPromotion({ _id }, event) {
            this.globalEmit('set-promotion', _id);

            event.preventDefault();
            event.stopPropagation();
        },

        getProductCount(params) {
            const numberOfProducts = (params.products && params.products.length) || 0;
            return this.$tc(`preparation.allocation.products`, numberOfProducts);
        },

        isPromotionDisabled({ _id }) {
            if (this.isReadOnly) return true;

            // as a promotion can only appear once in a leaflet,
            // check if current promotion _id is assigned to any space
            if (this.selectedArea && isNil(this.selectedArea.promotionId)) {
                return !!this.selectedResourceDefinitionAssignmentsMap[_id];
            }
            return true;
        },

        isSelected(promotion) {
            return (
                promotion._id === this.selectedPromotionId &&
                !isCategoryOrStoreWidePromotion(promotion)
            );
        },

        isProductsDisabled({ _id }) {
            return this.selectedArea ? this.selectedArea.promotionId !== _id : true;
        },

        getAssignedProducts({ _id }) {
            const assignments = this.selectedResourceDefinitionAssignmentsMap[_id];
            return assignments ? assignments.products || [] : [];
        },

        getColourClass({ effectivenessRating }) {
            if (effectivenessRating) {
                return `promotion-allocation-area__border-colour--${effectivenessRating}`;
            }
        },

        isLocationInstanceHasCurrentPageType(instance) {
            if (this.isPagesResource) {
                const locationProvision = instance.detailedProvision.find(
                    dp => dp.name === detailedProvisionsNamesEnum.location
                );

                if (locationProvision) {
                    if (locationProvision.value === leafletPageTypes.unspecified) {
                        return true;
                    }
                    return this.isCover
                        ? locationProvision.value === leafletPageTypes.cover
                        : locationProvision.value === leafletPageTypes.inside;
                }
            }
            // leaflet: if locationProvision is not defined, show promotions for any page
            // tv, radio: don't additionally check promotion resources instances
            return true;
        },

        getSlotSize({ resources }) {
            const { resourceKey, key } = this.selectedResourceDefinition;
            const selectedResource = find(resources, {
                type: resourceKey,
            });
            if (selectedResource) {
                const filteredLocationInstance = find(
                    selectedResource.instances,
                    instance =>
                        instance.key === key && this.isLocationInstanceHasCurrentPageType(instance)
                );
                if (filteredLocationInstance) {
                    const slotSizeDP = find(filteredLocationInstance.detailedProvision, {
                        name: detailedProvisionsNamesEnum.slotSize,
                    });
                    if (slotSizeDP) {
                        return toSentenceCase(slotSizeDP.value);
                    }
                }
            }
            return '';
        },
    },
    events: {
        updateSelectedArea(area) {
            const assignedArea = find(this.selectedResourceDefinitionPage.assignment, { area });

            if (
                assignedArea &&
                assignedArea.categoryKey !== categoryAllocationsTypes.nonAllocated
            ) {
                if (this.selectedArea === assignedArea.area) {
                    this.selectedArea = null;
                } else {
                    this.selectedArea = assignedArea;
                }
            } else if (!area) {
                this.selectedArea = null;
            }
            this.setSelectedPromotion({
                promotionId: this.selectedArea ? this.selectedArea.promotionId : null,
            });
        },
        changeSelectedPage() {
            this.selectedArea = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

$grid-padding: 0.5rem;

.promotion-allocation-area {
    padding-bottom: $grid-padding;
    font-size: 1.2rem;
    padding-left: 1rem;

    &__title {
        padding-bottom: 0.5rem;
    }

    &__grid {
        display: grid;
        grid-template-columns:
            repeat(7, auto)
            repeat(2, calc(#{$health-sidebar-width / 2}));

        position: relative;
    }

    &__data {
        @include flex-column;
        padding-top: 0.75rem;
        padding-left: 1rem;
        min-height: 3.5rem;
        background-color: $promo-white;
        border-top: $promo-divider-accent-colour 0.2rem solid;
        border-bottom: $border-shadow 0.1rem solid;
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 1.2rem;
        color: $text-colour;
        letter-spacing: 0;
        box-shadow: 0 0.4rem 0.4rem 0 $box-shadow-color, 0 0.2rem 0 0 $box-shadow-color;

        &:hover {
            cursor: pointer;
        }
    }

    &__border-colour {
        @include promo-rag-colours(border-top-color);
    }

    &__grid-cell {
        &--sidebar-heading {
            padding-left: 1rem;
            display: flex;
            z-index: $promo-details-z-index;
            background-color: $promo-table-blue-bg-colour-3;
            align-items: center;
            @include promo-component-border($top: true);
            height: 4.5rem;
        }

        &--shadow {
            box-shadow: -0.5rem 0 0.5rem -0.5rem $box-shadow-color;
        }

        &--sidebar {
            z-index: $promo-details-z-index;
            background-color: $promo-white;

            .promotion-allocation-area__data {
                border-bottom: none;
                box-shadow: none;
            }
        }

        &--promotion-content {
            padding-top: $grid-padding;
            background-color: $promo-grey-3;
        }

        &--button {
            .promotion-allocation-area__data {
                padding-top: 0.45rem;
            }
        }

        &--truncated-content {
            padding-bottom: 0.2rem;
        }
    }
}

.search-bar {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: baseline;

    &__label {
        margin-right: 0.75rem;
    }
}

.last-column {
    padding-right: 0.75rem;

    .promotion-allocation-area__data {
        padding-top: 0;
        justify-content: center;
    }
}
</style>
