<template functional>
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <title>Icon / Expand sub-campaign</title>
        <desc>Created with Sketch.</desc>
        <g id="Icon-/-Expand" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                id="Colour"
                d="M3,13 C1.8954305,13 1,12.1045695 1,11 L1,3 C1,1.8954305 1.8954305,1 3,1 L11,1 C12.1045695,1 13,1.8954305 13,3 L14,3 C15.1045695,3 16,3.8954305 16,5 L16,13 C16,14.1045695 15.1045695,15 14,15 L6,15 C4.8954305,15 4,14.1045695 4,13 L3,13 Z M14,4 L6,4 C5.48716416,4 5.06449284,4.38604019 5.00672773,4.88337887 L5,5 L5,13 C5,13.5128358 5.38604019,13.9355072 5.88337887,13.9932723 L6,14 L14,14 C14.5128358,14 14.9355072,13.6139598 14.9932723,13.1166211 L15,13 L15,5 C15,4.44771525 14.5522847,4 14,4 Z"
                fill="#2F477C"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'ExpandSubCampaign',
};
</script>
