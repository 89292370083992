var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "promotion-rag-colours" },
    [
      _vm._l(_vm.props.promotionRagColourCounts, function (count, colour) {
        return _c(
          "span",
          { key: `${colour}`, staticClass: "promotion-rag-colours__colour" },
          [
            _c("promotion-rag-colour", {
              attrs: {
                vertical: _vm.props.vertical,
                colour: Number(colour),
                count: count,
                "big-circle": _vm.props.bigCircle,
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.props.displayTotal
        ? _c("span", { staticClass: "promotion-rag-colours__total" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("countNumber")(
                    _vm.$options.methods.getRagColourTotal({
                      ragColours: _vm.props.promotionRagColourCounts,
                    })
                  )
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }