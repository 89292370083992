import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "year-dates" }, [
    _c(
      "div",
      { staticClass: "year-dates__nav" },
      [
        _c("main-expand-button", {
          attrs: {
            "is-expanded": _vm.isExpanded,
            "expanded-icon": "expand_less",
            "collapsed-icon": "expand_more",
          },
          on: { expand: _vm.toggleYearDates },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "year-dates__divider" }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isExpanded,
            expression: "isExpanded",
          },
        ],
      },
      [
        _c(
          "div",
          { staticClass: "year-dates__carousel" },
          [
            _c(
              VCarousel,
              {
                ref: "carousel",
                attrs: {
                  height: "5rem",
                  "hide-delimiters": "",
                  "show-arrows": false,
                  continuous: false,
                },
                model: {
                  value: _vm.carouselIndex,
                  callback: function ($$v) {
                    _vm.carouselIndex = $$v
                  },
                  expression: "carouselIndex",
                },
              },
              _vm._l(_vm.getAllYearsInWeeklyView, function (carouselYear) {
                return _c(VCarouselItem, { key: carouselYear }, [
                  _c("div", { staticClass: "year-dates__carousel-item" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("general.dates.yearWeeks", {
                            year: carouselYear,
                          })
                        ) +
                        "\n                    "
                    ),
                  ]),
                ])
              }),
              1
            ),
            _vm._v(" "),
            _c("icon-button", {
              staticClass:
                "year-dates__carousel-button year-dates__carousel-button--prev",
              attrs: {
                icon: "navigate_before",
                disabled: _vm.isCarouselPrevButtonDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.onCarouselButtonClick({ isPrev: true })
                },
              },
            }),
            _vm._v(" "),
            _c("icon-button", {
              staticClass:
                "year-dates__carousel-button year-dates__carousel-button--next",
              attrs: {
                icon: "navigate_next",
                disabled: _vm.isCarouselNextButtonDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.onCarouselButtonClick()
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.monthsAndWeeks, function (monthAndWeeks) {
          return _c("div", { key: monthAndWeeks.key }, [
            _c("div", { staticClass: "year-dates__month" }, [
              _vm._v(_vm._s(monthAndWeeks.monthName)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "year-dates__divider" }),
            _vm._v(" "),
            _c(
              "ol",
              _vm._l(monthAndWeeks.weekNumbers, function (weekNumberObj) {
                return _c(
                  "li",
                  {
                    key: weekNumberObj.weekNumber,
                    staticClass: "year-dates__week",
                    class: {
                      highlighted: _vm.isWeekHighlighted(
                        weekNumberObj.weekNumber
                      ),
                      roundedLeft: _vm.isLeftBoundaryWeek(
                        weekNumberObj.weekNumber,
                        monthAndWeeks.weekNumbers
                      ),
                      roundedRight: _vm.isRightBoundaryWeek(
                        weekNumberObj.weekNumber,
                        monthAndWeeks.weekNumbers
                      ),
                      disabled: !weekNumberObj.isWeekInScopeOfYear,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.navigateToWeek(weekNumberObj)
                      },
                    },
                  },
                  [
                    _c(
                      VTooltip,
                      {
                        attrs: { right: "", "content-class": "rtls-tooltip" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(_vm._b({}, "div", attrs, false), on),
                                    [_vm._v(_vm._s(weekNumberObj.weekNumber))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("b", [
                          _vm._v("week " + _vm._s(weekNumberObj.weekNumber)),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("localShortDate")(
                                  weekNumberObj.startDate
                                )
                              ) +
                              "\n                            -\n                            " +
                              _vm._s(
                                _vm._f("localShortDate")(weekNumberObj.endDate)
                              ) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }