<template>
    <div class="layout-container" :style="gridStyle">
        <page-layout-grid-space
            v-for="area in gridAreaNames"
            :key="`${area}-${selectedResourceDefinitionKey}`"
            :area="area"
            :initial-area-class="initialAreaClass"
            :category-key="getFieldValue(area, 'categoryKey')"
            :promotion-id="getFieldValue(area, 'promotionId')"
            :products="getAreaProducts(area, 'products')"
            :selected-area-for-promotion-allocation="selectedAreaForPromotionAllocation"
            @area-toggled="toggleAreaSelected"
            @remove-item="removeItem"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { uniq, flattenDeep, remove, includes } from 'lodash';

export default {
    props: ['layoutKey', 'layout', 'selected', 'model'],
    data() {
        return {
            selectedAreas: [],
            areasCategoryPromotion: {},
        };
    },
    computed: {
        ...mapState('subCampaigns', [
            'selectedResourceDefinitionKey',
            'selectedResourceDefinitionPageNumber',
            'activeDocumentSection',
            'selectedAreaForPromotionAllocation',
            'areaForCategoryAllocationSelected',
        ]),
        ...mapGetters('subCampaigns', [
            'selectedResourceDefinition',
            'selectedResourceDefinitionPage',
            'isPromotionsSection',
            'isCategoriesSection',
            'isTemplatesSection',
            'isSlotsResource',
            'isPagesResource',
        ]),
        ...mapGetters('promotions', ['getPromotionRankedProducts']),

        gridStyle() {
            const style = {
                gridTemplateAreas: this.layoutStr,
            };
            if (this.isPagesResource) {
                return {
                    ...style,
                    // Ensure each row and column are a consistent size by splitting
                    // the grid evenly based on the number of rows/columns.
                    gridTemplateRows: `repeat(${this.numRows}, 1fr)`,
                    gridTemplateColumns: `repeat(${this.numColumns}, 1fr)`,
                };
            }
            return {
                ...style,
                // Gives a min/max height for every slot
                gridTemplateRows: `repeat(${this.numRows}, minmax(13rem, 13rem))`,
            };
        },
        gridAreaNames() {
            return uniq(flattenDeep(this.layout.map(layoutStr => layoutStr.split(' '))));
        },
        numRows() {
            return this.layout.length;
        },
        numColumns() {
            return this.layout[0].split(' ').length;
        },
        layoutStr() {
            return this.layout.reduce((accumulator, next) => `${accumulator} '${next}'`, '');
        },
        initialAreaClass() {
            return this.selected ? 'area-saved' : 'area-simple';
        },

        computedAreasCategoryPromotion() {
            return this.getAreasCategoryPromotion({
                resourceDefinitionPage: this.selectedResourceDefinitionPage,
                areaNames: this.gridAreaNames,
            });
        },

        isCurrentTemplateSelected() {
            return (
                this.selected &&
                this.selectedResourceDefinitionPage &&
                (this.selectedResourceDefinitionPage.layoutTemplate === this.layoutKey ||
                    // It is possible for a pagesResource to be rendered in the background and for this function to be called.
                    // Therefore we need to combine isSlotsResource with the fact a slotsResource should never have a layoutKey
                    (this.isSlotsResource && !this.layoutKey))
            );
        },
    },
    watch: {
        selected(newValue) {
            if (newValue) {
                this.saveAssignments();
            }
        },
    },
    mounted() {
        this.areasCategoryPromotion = this.computedAreasCategoryPromotion;
        this.saveAssignments();
        this.setAreaForCategoryAllocationSelected(this.selectedAreas.length !== 0);
    },
    methods: {
        ...mapMutations('subCampaigns', [
            'setSelectedAreaForPromotionAllocation',
            'setAreaForCategoryAllocationSelected',
        ]),
        toggleAreaSelected(area) {
            if (this.isPromotionsSection) {
                const selectedArea = this.selectedAreaForPromotionAllocation === area ? null : area;
                this.setSelectedAreaForPromotionAllocation(selectedArea);
                this.globalEmit('update-selected-area', selectedArea);
            } else {
                const index = this.selectedAreas.findIndex(item => item === area);
                if (index > -1) {
                    this.selectedAreas.splice(index, 1);
                } else {
                    this.selectedAreas.push(area);
                }
                this.setAreaForCategoryAllocationSelected(this.selectedAreas.length !== 0);
            }
        },
        /**
         * Create an object that can be used to update assignments
         */
        getAreasCategoryPromotion({ resourceDefinitionPage, areaNames }) {
            const result = {};
            if (!resourceDefinitionPage) {
                return result;
            }
            // Use previous assignment if it exists and if the area is still valid.
            resourceDefinitionPage.assignment.forEach(item => {
                if (areaNames.includes(item.area)) {
                    result[item.area] = {
                        categoryKey: item.categoryKey,
                        promotionId: item.promotionId,
                        products: item.products,
                    };
                }
            });
            // Create an empty assignment for an area if there isn't already one.
            areaNames.forEach(item => {
                if (!result[item]) {
                    result[item] = {
                        categoryKey: null,
                        promotionId: null,
                        products: [],
                    };
                }
            });
            return result;
        },
        removeItem(area) {
            const updatedCategoriesPromotionsObj = {
                ...this.areasCategoryPromotion[area],
                categoryKey: !this.isCategoriesSection
                    ? this.areasCategoryPromotion[area].categoryKey
                    : null,
                promotionId: null,
                products: [],
            };

            // ex: {'a': {categoryKey: '1111', promotionId: '01010', products:[25,91]}}
            this.areasCategoryPromotion = {
                ...this.areasCategoryPromotion,
                [area]: updatedCategoriesPromotionsObj,
            };
            // emitting in each method instead of doing it once in saveAssignments
            // because submitting a form saveAssignments is called once more
            this.saveAssignments();
            // autosave when removing an item
            this.$emit('auto-save');
            this.globalEmit('update-selected-area', this.selectedAreaForPromotionAllocation);
        },

        saveAssignments({ cleanSelectedAreas = false } = {}) {
            if (this.isCurrentTemplateSelected) {
                const newAssignments = [];
                Object.keys(this.areasCategoryPromotion).forEach(key => {
                    if (this.areasCategoryPromotion[key]) {
                        const {
                            promotionId = null,
                            categoryKey = null,
                            products = [],
                        } = this.areasCategoryPromotion[key];
                        // ex: [{area: a, categoryKey: '1111', promotionId: '01010', products:[25,91]}]
                        newAssignments.push({
                            area: key,
                            categoryKey,
                            promotionId,
                            products,
                        });
                    }
                });
                const selectedResourceDefinitionIndex = this.model.findIndex(
                    resource => resource.key === this.selectedResourceDefinitionKey
                );
                const pageIndex = this.model[selectedResourceDefinitionIndex].pages.findIndex(
                    page => page.pageNumber === this.selectedResourceDefinitionPageNumber
                );

                this.$set(
                    this.model[selectedResourceDefinitionIndex].pages[pageIndex],
                    'assignment',
                    newAssignments
                );
            }
            if (cleanSelectedAreas) {
                this.selectedAreas = [];
                this.setAreaForCategoryAllocationSelected(false);
            }
        },

        getFieldValue(area, field) {
            return this.areasCategoryPromotion[area]
                ? this.areasCategoryPromotion[area][field]
                : null;
        },

        getAreaProducts(area) {
            return this.areasCategoryPromotion[area]
                ? [...this.areasCategoryPromotion[area].products]
                : [];
        },
    },
    events: {
        onPageGridUpdated() {
            if (this.isSlotsResource) return;

            // Reset the page grid.
            this.areasCategoryPromotion = this.computedAreasCategoryPromotion;
            this.saveAssignments({ cleanSelectedAreas: true });
            this.$emit('auto-save');
            this.setSelectedAreaForPromotionAllocation(null);
        },
        onSlotsUpdated({ slots }) {
            if (!this.isSlotsResource) return;
            // Ensure we have an assignment for each slot and remove any assignments that are no longer a slot
            this.areasCategoryPromotion = this.getAreasCategoryPromotion({
                resourceDefinitionPage: this.selectedResourceDefinitionPage,
                areaNames: slots,
            });
            this.saveAssignments();

            this.$emit('auto-save');
            this.setSelectedAreaForPromotionAllocation(null);
        },
        onSetCategory(category) {
            if (this.isCurrentTemplateSelected && this.areaForCategoryAllocationSelected) {
                const newAreasCategoryPromotion = { ...this.areasCategoryPromotion };
                this.selectedAreas.forEach(area => {
                    newAreasCategoryPromotion[area] = {
                        categoryKey: category,
                        promotionId: null,
                        products: [],
                    };
                    newAreasCategoryPromotion[area].categoryKey = category;
                });
                this.areasCategoryPromotion = newAreasCategoryPromotion;
                this.saveAssignments({ cleanSelectedAreas: true });
                this.$emit('auto-save');
            }
        },

        onSetPromotion(promotionId) {
            if (this.isCurrentTemplateSelected && this.selectedAreaForPromotionAllocation) {
                const newAreasCategoryPromotion = { ...this.areasCategoryPromotion };
                newAreasCategoryPromotion[
                    this.selectedAreaForPromotionAllocation
                ].promotionId = promotionId;

                newAreasCategoryPromotion[
                    this.selectedAreaForPromotionAllocation
                ].products = this.getPromotionRankedProducts({ promotionId });

                this.areasCategoryPromotion = newAreasCategoryPromotion;
                this.saveAssignments();
                this.$emit('auto-save');
                this.globalEmit('update-selected-area', this.selectedAreaForPromotionAllocation);
            }
        },

        onSetPromotionProduct({ productKey, isSelected }) {
            if (this.isCurrentTemplateSelected && this.selectedAreaForPromotionAllocation) {
                const newAreasCategoryPromotion = { ...this.areasCategoryPromotion };
                const products =
                    newAreasCategoryPromotion[this.selectedAreaForPromotionAllocation].products;

                if (isSelected && !includes(products, productKey)) {
                    products.push(productKey);
                }
                if (!isSelected) {
                    remove(products, key => key === productKey);
                }

                this.areasCategoryPromotion = newAreasCategoryPromotion;
                this.saveAssignments();
                this.$emit('auto-save');
                this.globalEmit('update-selected-area', this.selectedAreaForPromotionAllocation);
            }
        },

        onActiveDocumentSectionUpdated() {
            this.setSelectedAreaForPromotionAllocation(null);
        },
        onChangeTemplate() {
            this.areasCategoryPromotion = this.computedAreasCategoryPromotion;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.layout-container {
    height: 100%;

    display: grid;
    gap: 0.5rem;
}
</style>
