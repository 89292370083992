'use strict';

/**
 * Enum definition file for offer-mechanic-templates
 *
 * @module data/enums/offer-mechanic-templates
 */
module.exports = {
    custom: 'custom',
    noDiscount: 'noDiscount',
    buyXPercentage: 'buyXPercentage',
    buyXMinusMoney: 'buyXMinusMoney',
    buyXMinusMoneyPerItem: 'buyXMinusMoneyPerItem',
    buyXForMoney: 'buyXForMoney',
    buyXGetXPercentage: 'buyXGetXPercentage',
    buyXGetYPercentage: 'buyXGetYPercentage',
    buyLinkedOfferForMoney: 'buyLinkedOfferForMoney',
    tiered: 'tiered',

    // new templates
    payValueWhenBuyXWhenBuyY: 'payValueWhenBuyXWhenBuyY',
    savePercentageOnNthUnit: 'savePercentageOnNthUnit',
    buyXSavePercentageOnY: 'buyXSavePercentageOnY',
    payValueWhenBuyN: 'payValueWhenBuyN',
    savePercentageWhenBuyN: 'savePercentageWhenBuyN',
    saveValueWhenBuyN: 'saveValueWhenBuyN',
    saveValuePerItemWhenBuyN: 'saveValuePerItemWhenBuyN',
    newPricePerItem: 'newPricePerItem',
};
