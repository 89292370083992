import { isNil, map } from 'lodash';
import { mapGetters, mapState } from 'vuex';

const mixin = {
    computed: {
        ...mapGetters('context', ['userStoreGroups', 'userStoreGroupsMap']),
        ...mapState('storeGroups', ['storeGroups']),
        storeGroupsOptions() {
            return map(this.storeGroups, storeGroup => {
                return {
                    ...storeGroup,
                    disabled: isNil(this.userStoreGroupsMap[storeGroup.key]),
                };
            });
        },
    },
};

export default mixin;
