import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "area",
      class: [_vm.computedAreaClass],
      style: { gridArea: _vm.area },
    },
    [
      _vm.isTemplatesSection
        ? _c("div", { staticClass: "area__inner" }, [_vm._v(_vm._s(_vm.area))])
        : _c(
            "div",
            {
              staticClass: "area__inner--category",
              class: { "min-height": !_vm.isSlotsResource },
              on: { click: _vm.toggleSelection },
            },
            [
              _vm.isAssignmentArea
                ? _c(
                    "div",
                    {
                      staticClass: "area__inner--category-active",
                      class: {
                        "non-allocated":
                          _vm.categoryKey ===
                          _vm.categoryAllocationsTypes.nonAllocated,
                        "area-highlighted":
                          _vm.isHighlighted && !_vm.isDisabled,
                        "area__inner--category-active-in-promotions-tab":
                          _vm.isAreaSelectedForPromotionAllocation &&
                          !_vm.isDisabled,
                        "min-height": !_vm.isSlotsResource,
                      },
                    },
                    [
                      _vm.categoryKey ===
                      _vm.categoryAllocationsTypes.nonAllocated
                        ? _c("div", { staticClass: "assignments-container" }, [
                            _c("span", { staticClass: "category-label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(
                                      "preparation.allocation.nonAllocated"
                                    )
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.categoryKey !==
                        _vm.categoryAllocationsTypes.nonAllocated ||
                      _vm.autoAssignedCategory
                        ? _c("div", { staticClass: "assignments-container" }, [
                            _vm.autoAssignedCategory
                              ? _c(
                                  "span",
                                  { staticClass: "category-label" },
                                  [
                                    _vm.isCategoryWidePromotion
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: {
                                              "z-index": "300",
                                              top: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          _vm._b(
                                                            {},
                                                            "span",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                                " +
                                                              _vm._s(
                                                                _vm.autoAssignedCategory
                                                              ) +
                                                              "\n                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              33148813
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.categoriesForCategoryWideText
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.autoAssignedCategory) +
                                              " "
                                          ),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPagesResource && !_vm.autoAssignedCategory
                              ? _c("span", { staticClass: "category-label" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.categoryName) +
                                      "\n                    "
                                  ),
                                  _vm.categoryMismatch
                                    ? _c(
                                        "span",
                                        { staticClass: "category-warning" },
                                        [
                                          _c("icon", {
                                            staticClass:
                                              "category-warning--icon",
                                            attrs: { "icon-name": "warning" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "category-warning--text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm._f("toSentenceCase")(
                                                      _vm.$t(
                                                        "preparation.allocation.categoryMismatch"
                                                      )
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPromotionsSection && _vm.promotion
                              ? _c(
                                  "span",
                                  { staticClass: "promotion" },
                                  [
                                    _c("promotion-rag-colour", {
                                      staticClass: "promotion__rag",
                                      attrs: {
                                        colour:
                                          _vm.promotion.effectivenessRating,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.isStoreWidePromotion
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "promotion__products",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "preparation.allocation.storeWide"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isCategoryWidePromotion
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "promotion__products",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "preparation.allocation.categoryWide"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "promotion__products",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$tc(
                                                    "preparation.allocation.products",
                                                    _vm.promotion.products
                                                      ? _vm.promotion.products
                                                          .length
                                                      : 0
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "promotion__name" },
                                      [_vm._v(_vm._s(_vm.promotion.name))]
                                    ),
                                    _vm._v(" "),
                                    _vm.promotion.offerMechanic &&
                                    _vm.promotion.offerMechanic.description
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "promotion__offer-mechanic",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.promotion.offerMechanic
                                                    .description
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.products && _vm.products.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "promotion__assigned-products",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$tc(
                                            "preparation.allocation.products",
                                            _vm.products.length
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "feature-toggle",
                        { attrs: { toggle: _vm.canEditChannel } },
                        [
                          _c(
                            VBtn,
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRemoveButtonVisible,
                                  expression: "isRemoveButtonVisible",
                                },
                              ],
                              staticClass: "ml-2 category-btn",
                              attrs: {
                                "x-small": "",
                                primary: "",
                                depressed: "",
                                "max-width": "2rem",
                                "min-width": "2rem",
                                disabled: _vm.isRemoveButtonDisabled,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeItem.apply(null, arguments)
                                },
                              },
                            },
                            [_c("revert")],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }