<template>
    <div :class="['scenario-viewer', selectedFadedClass]">
        <div
            v-for="(item, colIndex) in fields"
            :key="'field::' + scenario._id + '::' + colIndex"
            :ref="`scenario_${scenario._id}`"
            class="scenario-viewer__grid-cell scenario-viewer__grid-cell--main-content"
            :class="[item.cellClasses, item.contentClasses]"
            @click="onScenarioClick(item)"
        >
            <div class="scenario-viewer__data" :class="item.dataClasses">
                <div v-if="item.icon">
                    <icon
                        v-if="scenario[item.field] || getPropByPath(item.propPath)"
                        :icon-name="item.icon"
                        v-bind="constructVBindObj({ ...item })"
                    />
                </div>
                <div v-else-if="item.functionName">
                    <span>{{ callAction(item.functionName, scenario[item.functionParam]) }}</span>
                </div>
                <div v-else-if="item.component">
                    <component
                        :is="item.component.name"
                        v-if="
                            (!item.onlyShowWhenSelected || isSelected) &&
                                isShowOnlyInFavorite(item.onlyFavoriteScenariosComponent)
                        "
                        v-bind="
                            constructVBindObj({
                                context: item.propPath ? getPropByPath(item.propPath) : scenario,
                                field: item.field,
                                ...item.component,
                            })
                        "
                        v-on="
                            constructVBindEventObj({
                                events: getAttribute(item.component, 'events'),
                                context: scenario,
                            })
                        "
                    />
                </div>
                <span v-else>{{ getAttribute(scenario, item.field) }}</span>
            </div>
        </div>
        <div
            v-if="isSelected"
            :key="scenario._id + ':: promotions-viewer'"
            class="scenario-viewer__promotions-viewer"
        >
            <promotions-viewer />
        </div>
        <confirm-dialog
            ref="cannotReassignDialog"
            class="confirm-dialog"
            :has-activator="false"
            :question-text="$t(questionTextKey)"
            :action-text="$t('actions.ok')"
        />

        <v-overlay
            v-if="scenario.applyingNotifications"
            class="scenario-viewer__overlay"
            :absolute="true"
            :value="true"
            :opacity="0.3"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { get, intersection, isEmpty, isNil, uniqBy, includes } from 'lodash';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { assignedPromotionsIds } from '@sharedModules/promotion-utils';
import notificationTypes from '@enums/notification-types';
import fields from '../config/scenarios-viewer-fields';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import { suffixWithColon } from '@/js/utils/string-utils';
import activeComponentMixin from '@/js/mixins/active-component';
import navigation from '@/js/navigation';

export default {
    mixins: [configDrivenGridComponentMixin, activeComponentMixin('scenario')],
    props: {
        scenario: {
            required: true,
            type: Object,
        },
        // subCampaign object that contains only current scenario categories
        subCampaignContext: {
            required: true,
            type: Object,
        },
        isLocked: {
            required: true,
            type: Boolean,
        },
        isPrivate: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            fields: Object.freeze(fields),
            questionTextKey: '',
            dialogToggle: true,
            submitBtnDisabled: false,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'generalConfig']),
        ...mapGetters('scenarios', [
            'selectedScenarioId',
            'selectedScenario',
            'scenariosInCategoryGroup',
            'selectedScenarioApplyAllNotificationsReport',
            'getScenarioById',
        ]),
        ...mapGetters('promotions', ['getPromotionsByScenarioId']),
        ...mapGetters('notifications', ['getNotificationsForScenario']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapState('subCampaigns', ['selectedSubCampaignId']),
        ...mapState('promotions', ['selectedPromotionId', 'unsavedPromotion']),
        isCurrentPromotionUnsaved() {
            return (
                Object.values(get(this.unsavedPromotion, this.selectedPromotionId, {})).filter(
                    isUnsaved => isUnsaved
                ).length > 0
            );
        },
        isDisabled() {
            return this.submitBtnDisabled || this.isCurrentPromotionUnsaved;
        },
        isSelected() {
            return this.scenario._id === this.selectedScenarioId;
        },

        parentChildNotifications() {
            return this.getNotificationsForScenario(this.scenario._id).filter(
                n => n.notificationKey === notificationTypes.parentPromotionUpdated
            );
        },

        promotions() {
            return this.getPromotionsByScenarioId(this.scenario._id);
        },

        promotionIds() {
            return this.promotions.map(promotion => promotion._id);
        },

        promotionIdsForExecution() {
            return this.promotions
                .filter(promotion => !promotion.splitPromotion)
                .map(promotion => promotion._id);
        },

        scenarioEditingState() {
            return createFeatureAwareFactory(this.toggleLogic).isScenarioEditingDisabled(
                {
                    scenario: this.getScenarioById({
                        _id: this.scenario._id,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            );
        },

        scenarioDisabled() {
            return this.scenario.isFavourite || this.scenarioEditingState.disabled;
        },

        scenarioDisabledReason() {
            return this.scenarioEditingState.reason;
        },

        isChild() {
            return !isNil(this.subCampaignContext.parentId);
        },

        hasNotifications() {
            return !isEmpty(this.parentChildNotifications);
        },

        isExecutingPromotionsWithPendingNotifications() {
            if (!this.hasNotifications) return false;

            // Get all promotions with pending notifications for the current scenario
            const promotionsWithPendingNotifications = uniqBy(
                this.parentChildNotifications,
                notification => get(notification, 'details.entityIds.promotionId')
            ).map(notification => get(notification, 'details.entityIds.promotionId'));

            // Check if any of the promotions with pending notifications are also being executed
            // This should prevent users from applying all notifications if one of the promos affected is being submitted to execution,
            // so we avoid concurrency issues.
            return this.promotions.some(
                promotion =>
                    promotion.execution &&
                    promotion.execution.executionId &&
                    includes(promotionsWithPendingNotifications, promotion._id)
            );
        },
    },
    methods: {
        ...mapActions('scenarios', ['toggleSelectedScenario', 'deleteScenario', 'setFavourite']),

        getProductCount(productCount) {
            return this.$tc('planning.scenariosViewer.product', productCount);
        },
        getPromotionCount(promotionCount) {
            return suffixWithColon(this.$tc('planning.scenariosViewer.promotion', promotionCount));
        },

        getPropByPath(path) {
            return get(this, path);
        },

        onScenarioClick(fieldConfig) {
            if (fieldConfig.field === 'isFavourite' && !this.isReadOnly) {
                this.onSetFavourite();
            } else {
                if (this.isSelected) {
                    navigation.toSubCampaignView({
                        subCampaignId: this.selectedSubCampaignId,
                    });
                } else {
                    navigation.toScenarioView({ scenarioId: this.scenario._id });
                }

                return this.toggleSelectedScenario({ scenarioId: this.scenario._id });
            }
        },

        onSetFavourite() {
            if (this.generalConfig.oneScenarioForAllCategories) {
                // do not allow to change favourite in 'oneScenarioForAllCategories' mode
                return;
            }

            if (this.scenario.isFavourite && this.scenario.categories.length === 1) {
                // There always needs to be a favourite scenario within a category group,
                // therefore it’s not possible to de-select a favourite scenario which has single category
                return;
            }

            // cannot unfavourite a scenario that has a readonly promotion inside of it.
            const currentFavourite = this.scenario.isFavourite
                ? this.scenario
                : this.scenariosInCategoryGroup(
                      this.scenario.subCampaignId,
                      this.scenario.categories
                  ).find(s => s.isFavourite);

            if (this.isPrivate) {
                // cannot set private scenario as favorite
                this.questionTextKey =
                    'dialogs.cannotReassignFavouriteIfNewFavoriteIsPrivate.description';
                this.$refs.cannotReassignDialog.open();
            } else if (!currentFavourite) {
                this.setFavourite({
                    scenarioId: this.scenario._id,
                    subCampaignId: this.scenario.subCampaignId,
                });
            } else {
                // get current favourite scenario's promotion ids
                const promotionsIds = this.getPromotionsByScenarioId(currentFavourite._id).map(
                    promotion => promotion._id
                );
                const assignedIds = assignedPromotionsIds(this.selectedSubCampaign);
                const hasReadonlyPromotions = !isEmpty(intersection(assignedIds, promotionsIds));

                if (hasReadonlyPromotions) {
                    this.questionTextKey =
                        'dialogs.cannotReassignFavouriteScenarioWithLockedPromotions.description';
                    this.$refs.cannotReassignDialog.open();
                } else {
                    this.setFavourite({
                        scenarioId: this.scenario._id,
                        subCampaignId: this.scenario.subCampaignId,
                        // allow to unfavourite a scenario that has multiple categories
                        isFavourite: !this.scenario.isFavourite,
                    });
                }
            }
        },

        scenarioFormClosed() {
            // Update the dialog toggle. The actual value of this is not important,
            // changing it is just used to force the component to be recreated by referencing
            // the toggle in the key for the dialog.
            this.dialogToggle = !this.dialogToggle;
        },

        getScenarioFormKey() {
            return `scenarioDialog::${this.dialogToggle}`;
        },
        isShowOnlyInFavorite(onlyFavoriteScenariosComponent) {
            if (onlyFavoriteScenariosComponent) {
                return this.scenario.isFavourite;
            }

            return true;
        },
        onSubmitClick() {
            this.submitBtnDisabled = true;
        },
        onActionCompleted() {
            this.submitBtnDisabled = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$grid-padding: 2rem;

.scenario-viewer {
    display: grid;
    grid-template-columns:
        4rem
        20rem
        6rem
        auto
        10rem
        min-content
        15rem
        15rem
        20rem
        22.5rem
        2.6rem
        1rem
        5rem
        $health-sidebar-width;

    position: relative;

    &__data {
        @include flex-column;
        justify-content: center;
        white-space: nowrap;
        padding: 0 1rem;
        min-height: 3.4rem;
        background-color: $promo-white;
        border-top: $promo-grey-dark 0.2rem solid;
        border-bottom: $border-shadow 0.1rem solid;
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 1.1rem;
        color: $text-colour;
        letter-spacing: 0;

        &--first-item {
            border-left: $promo-grey-dark 0.5rem solid;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__grid-cell {
        grid-column: span 1;

        &--heading {
            margin-top: 1rem;
            font-size: 1.4rem;
            font-weight: 700;
        }
        &--main-content {
            padding-top: 1.2rem;
            background-color: $promo-grey-3;
        }

        &--sidebar {
            z-index: $promo-details-z-index;
            background-color: $promo-white;

            .scenario-viewer__data {
                border-bottom: none;
            }
        }
    }

    &__promotions-viewer {
        @include span-full-row;
    }

    &__overlay {
        z-index: 99 !important;
        grid-column-start: 1;
        grid-column-end: -1;
    }
}

.scenario {
    &--faded {
        .scenario-viewer__data {
            opacity: 0.65;
        }
    }
}
</style>
