import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VCheckbox, {
    staticClass: "rtls-checkbox padded",
    attrs: { disabled: "" },
    model: {
      value: _vm.params.value,
      callback: function ($$v) {
        _vm.$set(_vm.params, "value", $$v)
      },
      expression: "params.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }