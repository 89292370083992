<template>
    <div class="create-promotion-container">
        <main-dialog
            ref="dialog"
            class="promotion-dialog"
            heading="parkingLot.promotion.createDialogHeading"
            btn-confirm-text="actions.save"
            btn-cancel-text="actions.cancel"
            :has-activator="hasActivator"
            :show-footer="false"
            @close="closeDialog"
        >
            <template v-slot:default>
                <div class="promotion-dialog-container">
                    <promotion-viewer
                        ref="promotionViewer"
                        :promotion="currentPromotion"
                        :scenario="scenario"
                        :sub-campaign="subCampaign"
                        :split-promotion="splitPromotion"
                        :promotion-name="promotionName"
                        :is-parking-lot="isParkingLot"
                        :is-past-promotions="isPastPromotions"
                        :is-sub-campaign-allocation="isSubCampaignAllocation"
                        is-in-dialog
                        @split-requested="closeDialog"
                        @save-button-clicked="handleSaveButtonClick($event)"
                    />
                </div>
            </template>
        </main-dialog>
    </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapMutations } from 'vuex';
import uxEvents from '@enums/ux-events';

export default {
    props: {
        promotion: {
            type: Object,
            default: () => {
                return {};
            },
        },
        scenario: {
            type: Object,
            default: () => {
                return {};
            },
        },
        subCampaign: {
            required: false,
            type: Object,
            default: () => {
                return {};
            },
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        isSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
        isParkingLot: {
            type: Boolean,
            default: true,
        },
        hasActivator: {
            type: Boolean,
            default: false,
        },
        splitPromotion: {
            type: Boolean,
            default: false,
        },
        promotionName: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        currentPromotion: {},
    }),

    computed: {
        isNewPromotion() {
            return isEmpty(this.promotion);
        },
    },
    methods: {
        ...mapMutations('promotions', ['setSelectedPromotionId']),

        openDialog() {
            this.currentPromotion = this.promotion;
            this.$refs.dialog.open();
        },
        closeDialog() {
            if (this.$refs.dialog) {
                this.$refs.dialog.close();
            }
            this.$emit('close');
        },

        async handleSaveButtonClick(result) {
            if (this.isNewPromotion) {
                this.currentPromotion = result;
                this.globalEmit(uxEvents.promotionUpdatedForForecasting, {
                    namespace: this.currentPromotion._id,
                });
                this.setSelectedPromotionId(this.currentPromotion._id);
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.create-promotion-container {
    &__create-btn {
        position: absolute;
        bottom: 1.5rem;
        left: 2rem;
        font-size: 1.2rem;
    }
}

.promotion-dialog-container {
    width: 95vw;

    &::v-deep .promotion-viewer {
        display: grid;
        grid-template-columns:
            $promotion-grid-template-columns
            repeat(2, calc(#{$health-sidebar-width / 2}));
        position: relative;

        &__grid-cell--main-content {
            padding-top: 0;
        }

        &--first-item {
            margin-left: 0;
        }
    }
}
</style>
