var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "12px",
        height: "14px",
        viewBox: "0 0 12 14",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Import blu")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Buttons-–-updated",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group-11",
                transform: "translate(-354.000000, -25.000000)",
                fill: "#2F477C",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group-10",
                    transform: "translate(282.000000, 24.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Icon-/-Import-blu",
                        transform: "translate(70.000000, 0.000000)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Combined-Shape",
                          d: "M3.63013533,10.8609402 L3.63013533,12.8282345 C3.63013533,13.4031542 3.93516382,13.6744646 4.49217236,13.6744646 L11.5078276,13.6744646 C12.0648362,13.6744646 12.3698647,13.4031542 12.3698647,12.8282345 L12.3698647,10.8609402 L13.7292308,10.8609402 L13.7292308,12.9638897 C13.7292308,14.2946028 12.9998148,14.9987179 11.6205556,14.9987179 L4.3860755,14.9987179 C3.00681624,14.9987179 2.27076923,14.2946028 2.27076923,12.9638897 L2.27076923,10.8609402 L3.63013533,10.8609402 Z M8.00663105,0.99991453 C8.17240741,0.99991453 8.33155271,1.05159271 8.49069801,1.21308702 L8.49069801,1.21308702 L11.2338325,3.81360342 C11.3465604,3.91695978 11.3993436,4.03405783 11.3993436,4.19555214 C11.3993436,4.49916143 11.1569123,4.71754872 10.8518838,4.71754872 C10.6993695,4.71754872 10.537837,4.65558188 10.4317402,4.53930598 L10.4317402,4.53930598 L8.95487179,3.0347428 L8.58353276,2.5696392 L8.6365812,3.47400732 L8.6365812,9.52681395 C8.6365812,9.84980257 8.35807692,10.1275728 8.00663105,10.1275728 C7.66181624,10.1275728 7.38331197,9.84980257 7.38331197,9.52681395 L7.38331197,9.52681395 L7.38331197,3.47400732 L7.4363604,2.57609897 L7.06502137,3.0347428 L5.58099145,4.53930598 C5.47489459,4.65558188 5.33272479,4.71754872 5.17357949,4.71754872 C4.87518205,4.71754872 4.61338803,4.49916143 4.61338803,4.19555214 C4.61338803,4.03405783 4.67890285,3.91695978 4.79163077,3.81360342 L4.79163077,3.81360342 L7.52919516,1.21308702 C7.68834046,1.05159271 7.8408547,0.99991453 8.00663105,0.99991453 Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }