<template>
    <confirm-dialog
        ref="dialog"
        :question-text="
            $t(`dialogs.childPromotionGhost.header${isGhost ? 'Reinstatiate' : 'Decline'}`)
        "
        :action-text="$t('actions.ok')"
        :has-activator="true"
        @confirm="confirm"
    >
        <template v-slot:actions="{ activator }">
            <icon v-if="isGhost" icon-name="reinstatiate" small v-on="activator" @click.stop />
            <icon v-else icon-name="remove" small v-on="activator" @click.stop />
        </template>
    </confirm-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        promotionId: {
            type: String,
            required: true,
        },
        isGhost: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
    },

    methods: {
        ...mapActions('promotions', ['setChildPromotionIsGhost']),
        ...mapActions('execution', ['deleteSinglePromotion']),

        async confirm() {
            await this.setChildPromotionIsGhost({
                promotionId: this.promotionId,
                isGhost: !this.isGhost,
            });
        },

        openDialog() {
            this.$refs.dialog.open();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
</style>
