var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "offer-tab",
    _vm._b(
      { attrs: { "form-ref": _vm.formRef, "cache-dom": _vm.cacheDom } },
      "offer-tab",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }