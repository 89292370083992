<template>
    <div class="workflow-resources" :style="gridStyle">
        <div
            v-for="(item, index) in workflowResources"
            :key="item.icon"
            class="workflow-resources__loop-wrapper"
        >
            <div
                class="workflow-resources__icon"
                :class="{
                    'workflow-resources__icon--last': index === workflowResources.length - 1,
                }"
            >
                <v-tooltip z-index="300" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <icon :icon-name="item.icon" :disabled="!item.nominated" />
                        </span>
                    </template>
                    <span>
                        {{ $t(`preparation.promoResources.${item.icon}`) | toSentenceCase }}
                    </span>
                </v-tooltip>
            </div>
            <div class="workflow-resources__details">
                <span v-if="item.workflowStateIcon">
                    <icon :icon-name="item.workflowStateIcon" :disabled="!item.nominated" />
                </span>
                <span v-else-if="item.submissionDate">
                    {{ item.submissionDate | localDayMonth }}
                </span>
                <span v-else>{{ $t('placeholders.noText') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { find, has, size, maxBy, isNil, intersection, minBy } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import workflowTasks from '@enums/workflow-tasks';
import workflowEntities from '@enums/workflow-entities';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import namespaces from '@enums/namespaces';
import { mapWorkflowStateIcons } from '@/js/utils/workflow-utils';

export default {
    props: {
        // The resources nominated on the promotion.
        nominatedResources: {
            type: Array,
            default: () => [],
        },
        subCampaignId: {
            type: String,
            default: null,
        },
        promotionId: {
            type: String,
        },
    },

    computed: {
        ...mapGetters('clientConfig', ['getPromoResources']),
        ...mapGetters('subCampaigns', ['getSubCampaignById', 'promoResourceWorkflowStateById']),
        ...mapGetters('promotions', ['getPromotionById', 'isPromotionPlannedOnResourceInstance']),
        ...mapState('clientConfig', ['toggleLogic']),

        subCampaign() {
            if (isNil(this.subCampaignId)) return null;

            return this.getSubCampaignById({
                _id: this.subCampaignId,
                usePluralResourceName: true,
            });
        },

        promotion() {
            if (isNil(this.promotionId) || this.promotionId === namespaces.default) {
                return {};
            }
            return this.getPromotionById(this.promotionId);
        },

        currentWorkflow() {
            // The entire workflow object is always stored on the sub-campaign of the current entity.
            return this.subCampaign ? this.subCampaign.workflow : {};
        },

        workflowResources() {
            const defaultEntities = [];

            // We should always show the workflow status for the promotion. This is depicted using
            // the "shelf" icon
            defaultEntities.push({
                icon: 'shelf',
                nominated: true,
                submissionDate: this.getSubmitStep({
                    entity: workflowEntities.promotion,
                    categories: this.promotion.categories,
                }).deadlineDateTime,
                workflowStateIcon: this.workflowStateIcon,
            });

            const categories = !this.subCampaignId
                ? this.promotion.categories
                : this.subCampaign.categories;

            // Then display each of the promo resources available in the Tool by iterating through
            // the available promo resources from the client config.
            const promoResourceEntities = this.getPromoResources.map(promoResource => {
                // Find the nominated resource from the promotion, matching the promoResource
                // currently being processed. Will be undefined if the resource has not
                // been nominated on this promotion.
                const matchingNominatedResource = find(
                    this.nominatedResources,
                    resource => resource.type === promoResource.key
                );

                // Add the promo resource to the the detailed promo resource list.
                // Set the nominated flag if we have a matching resource and at least one instance.
                const resourceSelected =
                    matchingNominatedResource && size(matchingNominatedResource.instances);

                return {
                    icon: promoResource.key,
                    nominated: !!resourceSelected,
                    submissionDate: this.getSubmitStep({
                        entity: promoResource.key,
                        categories,
                    }).deadlineDateTime,
                    workflowStateIcon: this.getResourceWorkflowStateIcon({
                        entity: promoResource.key,
                    }),
                };
            });

            return [...defaultEntities, ...promoResourceEntities];
        },
        gridStyle() {
            return {
                'grid-template-columns': `repeat(${this.workflowResources.length}, 1fr) `,
            };
        },
        workflowStateIcon() {
            const workflowStateIconsMap = createFeatureAwareFactory(
                this.toggleLogic
            ).getPromotionStateIconsMap();

            return mapWorkflowStateIcons({
                workflowState: this.promotion.workflowState,
                ...workflowStateIconsMap,
            }).icon;
        },
    },
    methods: {
        getSubmitStep({ entity, categories }) {
            // Find the Submit workflow step for the given workflow entity.
            const submitSteps =
                (has(this.currentWorkflow, 'steps') &&
                    this.currentWorkflow.steps.filter(step => {
                        return (
                            step.task === workflowTasks.submit &&
                            step.entity === entity &&
                            (!step.categories || intersection(step.categories, categories).length)
                        );
                    })) ||
                [];

            // Return if there is no submit step
            if (!submitSteps.length) return {};

            // If there are multiple matching steps, return the one with the earliest deadline
            return minBy(submitSteps, 'deadlineDateTime');
        },

        getResourceWorkflowStateIcon({ entity }) {
            // Check workflow includes steps relating to this entity.
            // If it does not, then return null as no icon will be required.
            if (
                !this.currentWorkflow ||
                !this.currentWorkflow.steps ||
                !this.currentWorkflow.steps.some(step => step.entity === entity) ||
                !has(this.promotion, 'resources')
            ) {
                return null;
            }

            // Get the workflow state for all instances of that resource type that the promotion
            // has been nominated for.
            const resources = this.promotion.resources.find(resource => resource.type === entity);

            // If the promotion has not been nominated for the resource type, simply return.
            if (!resources || !resources.instances.length) return;

            const resourceInstanceStates = resources.instances.map(instance => {
                // Get promo resource workflow state for this instance.
                const state = this.promoResourceWorkflowStateById({
                    id: instance.key,
                    subCampaignId: this.subCampaignId,
                    getChildEntities: false,
                });

                // Check if the promotion has been planned on the resource instance
                const isPromotionPlanned = this.isPromotionPlannedOnResourceInstance({
                    promotionId: this.promotion._id,
                    subCampaignId: this.subCampaignId,
                    resourceKey: instance.key,
                });

                state.push({
                    entity,
                    state: 'planned',
                    value: isPromotionPlanned,
                });

                return state;
            });

            const workflowStateIconsMap = createFeatureAwareFactory(
                this.toggleLogic
            ).getResourceStateIconsMap();

            const icons = resourceInstanceStates.map(state => {
                return mapWorkflowStateIcons({
                    workflowState: state,
                    resourceType: entity,
                    ...workflowStateIconsMap,
                });
            });

            // Return the highest priority state icon.
            return maxBy(icons, 'priority').icon;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.workflow-resources {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 2rem auto;

    &__icon {
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;
        border-left: $component-border-divider;

        &--last {
            border-right: $component-border-divider;
        }
    }
    &__details {
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 1rem;
    }

    &__loop-wrapper {
        display: contents;
    }
}
</style>
