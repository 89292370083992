var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "funded-promotions-list" },
    _vm._l(_vm.fields, function (item, colIndex) {
      return _c(
        "div",
        {
          key: `${_vm.promotion._id}::${colIndex}`,
          staticClass: "funded-promotions-list__grid-cell",
          class: item.cellClasses,
        },
        [
          _c(
            "div",
            {
              staticClass: "funded-promotions-list__data",
              class: _vm.getColourClass(_vm.promotion),
            },
            [
              item.functionName
                ? _c("div", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.callAction(item.functionName, _vm.promotion))
                      ),
                    ]),
                  ])
                : item.component
                ? _c(
                    "div",
                    [
                      _c(
                        item.component.name,
                        _vm._b(
                          { tag: "component" },
                          "component",
                          _vm.constructVBindObj({
                            context: _vm.promotion,
                            field: item.field,
                            ...item.component,
                          }),
                          false
                        ),
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t(item.component.text)
                              )
                            ) + "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c("span", { staticClass: "truncate-text" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getAttribute(_vm.promotion, item.field)) +
                        "\n            "
                    ),
                  ]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }