<template functional>
    <div :class="`alert-count-badge alert-count-badge--${props.priority.toLowerCase()}`">
        {{ props.alertCount }}
    </div>
</template>

<script>
export default {
    props: {
        priority: {
            type: String,
            required: true,
        },
        alertCount: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.alert-count-badge {
    width: 2.3rem;
    border-radius: 2rem;
    margin-bottom: 0.2rem;
    font-weight: normal;
    font-size: 1.2rem;
    text-align: center;
    color: $promo-white;
    @include alerts-colours(background);
}
</style>
