import { merge } from 'lodash';
import Vue from 'vue';
import namespaces from '@enums/namespaces';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    rateCards: [],
    selectedRateCardId: null,
    selectedRateCardIdForSplitting: null,
    lumpSumTotal: null,
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getRateCardById
     */
    getters: {
        getAllRateCards(state) {
            return state.rateCards;
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setRateCards
     * - deleteRateCards
     * - updateRateCards
     * - addRateCards
     * - resetState
     */
    mutations: {
        setSelectedRateCardId(state, rateCardId) {
            Vue.set(state, 'selectedRateCardId', rateCardId);
        },

        setSelectedRateCardIdForSplitting(state, rateCardId) {
            Vue.set(state, 'selectedRateCardIdForSplitting', rateCardId);
        },

        setLumpSumTotal(state, total) {
            Vue.set(state, 'lumpSumTotal', total);
        },
    },

    /**
     * Default actions available:
     * - fetchRateCards
     * - createRateCards
     * - deleteRateCards
     * - updateRateCards
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        setSelectedRateCardId({ commit }, rateCardId) {
            commit('setSelectedRateCardId', rateCardId);
        },
        async submitRateCard({ dispatch }, { namespace }) {
            if (namespace === namespaces.default) return;
            const { error } = await dispatch('submitForm', {
                namespace,
                editMode: true,
                submitAction: 'updateRateCard',
            });
            // handle any error
            if (error) return { error };
        },
        async fetchRateCardsByStoreGroupsAndDates(
            { dispatch, rootGetters },
            { promotion, namespace }
        ) {
            const { storeGroups, startDate, endDate } = namespace
                ? rootGetters['promotions/getStagingAreaPromotionById'](namespace)
                : promotion;
            await dispatch('fetchRateCards', {
                params: {
                    where: {
                        storeGroups: {
                            $elemMatch: {
                                key: {
                                    $in: (storeGroups || []).map(storeGroup => storeGroup.key),
                                },
                            },
                        },
                        effectiveFrom: {
                            $lte: startDate,
                        },
                        effectiveTo: {
                            $gte: endDate,
                        },
                    },
                },
            });
        },
    },
};

const mixinParams = {
    resource: 'rate-card',
    getInitialState,
    useForm: true,
    useFilters: true,
};

export default merge({}, storeMixin(mixinParams), store);
