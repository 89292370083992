<template>
    <v-autocomplete
        v-model="value"
        :items="options"
        :menu-props="{ auto: true }"
        dense
        allow-overflow
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            options: [],
            value: '',
        };
    },
    created() {
        this.options = this.params.options;
        const { field } = this.params.colDef;
        this.value = this.params.data[field];
    },
    methods: {
        getValue() {
            return this.value;
        },
        isPopup() {
            return false;
        },
    },
});
</script>
