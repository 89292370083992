/* eslint-disable import/prefer-default-export */

const { isEmpty, isNil, omit } = require('lodash');

/**
 *
 * Updates the supplier case information array of a promotion product based on
 * the most up-to-date list of sci in the products collection
 *
 * @param {Array} promotionProductSCI - Supplier case information for the promotion product
 * @param {Array} productSCI - Supplier case information for the current product
 *
 * @returns {Array} promotionProductSCI - updated product supplier case information list
 */
const refreshPromotionProductSCI = ({ promotionProductSCI = [], currentProductSCI }) => {
    promotionProductSCI = promotionProductSCI.reduce((result, sci) => {
        // We only need the suppliers still present in the new list
        const validSupplierCaseInformation = currentProductSCI.find(
            ppSci => ppSci.supplierCaseInformationKey === sci.supplierCaseInformationKey
        );
        if (validSupplierCaseInformation) {
            result.push({ ...sci, ...validSupplierCaseInformation });
        }
        return result;
    }, []);

    const supplierCaseInfoKeysOnPromotionProduct = promotionProductSCI.map(
        ({ supplierCaseInformationKey }) => supplierCaseInformationKey
    );
    
    currentProductSCI.forEach(caseInfo => {
        if (!supplierCaseInfoKeysOnPromotionProduct.includes(caseInfo.supplierCaseInformationKey)) {
            promotionProductSCI.push({
                ...caseInfo,
                volumeSplit: 0,
                buyingPrice: null,
                isSelected: false,
            });
        }
    });

    /*
     * Case 1: If a single caseSize is selected, set it to 100%
     * Case 2: If selected caseSize was removed, auto-select default caseSize
     * Case 3: If no caseSize was selected, auto-select default caseSize
     * Case 4: If there is a single caseSize, auto-select it
     * Case 5: If an unchanged caseSize is already selected, do nothing
     */
    if (promotionProductSCI) {
        if (promotionProductSCI.length === 1) {
            promotionProductSCI[0].isSelected = true;
            promotionProductSCI[0].volumeSplit = 100;
        } else {
            const selectedCaseSizeIndexes = promotionProductSCI
                .map((sci, i) => {
                    return sci.isSelected ? i : null;
                })
                .filter(sci => !isNil(sci));
            if (selectedCaseSizeIndexes.length === 1) {
                promotionProductSCI[selectedCaseSizeIndexes[0]].volumeSplit = 100;
            } else if (isEmpty(selectedCaseSizeIndexes)) {
                let selectedDefaultCaseSize = false;
                promotionProductSCI = promotionProductSCI.map(sci => {
                    // Make sure we only select one case size if there's multiple default case sizes
                    if (sci.isDefaultSupplierCase && !selectedDefaultCaseSize) {
                        selectedDefaultCaseSize = true;
                        return { ...sci, isSelected: true, volumeSplit: 100 };
                    }

                    return sci;
                });
            }
        }
    }

    return promotionProductSCI;
};

/**
 *
 * Works out the main supplier from the available suppliers list
 * and assigns all available suppliers to the new supplier case size information array
 *
 * @param {Array} availableSuppliers - List of available suppliers for a product
 * @param {String} currentProductSupplierKey - Current product supplier key for main supplier
 *
 * @returns {Object} mainSupplier and newSupplierCaseInformation list
 */
const identifyMainSupplierAndSCI = ({ availableSuppliers, currentProductSupplierKey }) => {
    let mainSupplier = {
        supplierKey: -1,
        supplierName: 'unknown',
        clientSupplierKey: null,
    };
    const newSupplierCaseInformation = [];
    if (!isEmpty(availableSuppliers)) {
        let hasMainSupplier = false;
        const mainSuppliers = availableSuppliers.filter(s => s.isMainSupplier);

        // Reference of the current main supplier in case it is present in the available ones
        const currentMainSupplier = availableSuppliers.find(
            s => s.supplierKey === currentProductSupplierKey
        );

        // If no main supplier, we check if the previous main supplier is available,
        // if not available, we assume the first one is the main one
        if (isEmpty(mainSuppliers)) {
            mainSupplier = currentMainSupplier || availableSuppliers[0];
            hasMainSupplier = true;
        }

        // If the previous main supplier is still set as main, then we prioritise it as the new main
        if (currentMainSupplier && currentMainSupplier.isMainSupplier) {
            mainSupplier = currentMainSupplier;
            hasMainSupplier = true;
        }

        availableSuppliers.forEach(supplier => {
            // If multiple main suppliers are present excluding the previous main,
            // then we consider the first one as the main for this product
            if (supplier.isMainSupplier && !hasMainSupplier) {
                mainSupplier = supplier;
                hasMainSupplier = true;
            }

            // Assign all available suppliers to the supplier case information list
            newSupplierCaseInformation.push(omit(supplier, ['_id', 'createdOn', 'lastUpdatedOn']));
        });
    }

    return { mainSupplier, newSupplierCaseInformation };
};

/**
 * This function compares two sets of SCIs and returns true if SCI not the same
 * supplierCaseInformationKey - is using like uniq key for SCI
 * 
 * @param {Array} promotionProductSCI - existing SCI for product
 * @param {Array} currentProductSCI - SCI after suppliers updates
 * @returns {Boolean}
 */
const checkChangesInSCI = ({promotionProductSCI = [], currentProductSCI = []}) => {
    if (promotionProductSCI.length !== currentProductSCI.length) {
        return true;
    }

    const sciKeysSet = new Set(promotionProductSCI.map(sci => String(sci.supplierCaseInformationKey)));
    return currentProductSCI.some(cpSCI => !sciKeysSet.has(String(cpSCI.supplierCaseInformationKey)));
};

module.exports = {
    refreshPromotionProductSCI,
    identifyMainSupplierAndSCI,
    checkChangesInSCI,
}; 
