import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      staticClass: "error-dialog",
      attrs: { persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.hasActivator
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _vm._t(
                      "actions",
                      function () {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  small: "",
                                  depressed: "",
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$t(_vm.buttonText)
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                      { activator: on }
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "error-dialog__container" },
        [
          _c(
            "div",
            { staticClass: "error-dialog__header" },
            [
              _c("span", [_vm._v(_vm._s(_vm.headerText))]),
              _vm._v(" "),
              _c(
                VBtn,
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [_c("icon", { attrs: { "icon-name": "cancel", small: "" } })],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider, { staticClass: "error-dialog__header-divider" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.size(_vm.formattingErrors)
                ? _c(
                    "div",
                    { staticClass: "error-dialog__formatting-errors" },
                    [
                      _c("icon", { attrs: { "icon-name": "warning" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "error-dialog__formatting-errors--upload-failed",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$t(
                                  "planning.promotionsMaintenance.funding.errors.uploadFailed"
                                )
                              ) +
                              ":\n                "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "planning.promotionsMaintenance.funding.errors.formattingErrors"
                            )
                          ) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _c(VTreeview, {
                    attrs: { items: _vm.errorTree || _vm.treeViewErrors },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "error-dialog__close-button" },
            [
              _c(
                VBtn,
                {
                  attrs: { primary: "", depressed: "" },
                  on: { click: _vm.closeDialog },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(_vm.$t("actions.close"))
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }