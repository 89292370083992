import { forEach } from 'lodash';

const install = (Vue, { utils }) => {
    forEach(utils, (value, key) => {
        Vue.prototype[`$${key}`] = value;
    });
};

export default {
    install,
};
