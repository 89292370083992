'use strict';

/**
 * Enum definition file for product lozenge option types.
 *
 * @module data/enums/product-lozenge-option-types
 */
module.exports = {
    field: 'field',
    text: 'text',
    attribute: 'attribute',
};
