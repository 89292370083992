<script>
import { mapActions } from 'vuex';
import commonDeleteDialogComponent from '../common-delete-dialog';

export default {
    extends: commonDeleteDialogComponent,

    computed: {
        btnDeleteId() {
            return 'deleteLoyaltyPoint';
        },

        labels() {
            return {
                deleteBtnText: 'actions.delete',
                deleteHeading: 'dialogs.deleteLoyaltyPoints.header',
            };
        },
    },

    methods: {
        ...mapActions('loyaltyPoints', ['deleteLoyaltyPoint']),

        async deleteSelectedItem() {
            await this.deleteLoyaltyPoint({ id: this.resourceId });
            this.closeAll();
        },
    },
};
</script>
