var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promo-planner" }, [
    _c(
      "aside",
      { staticClass: "promo-planner__sidebar elevation-2" },
      [_c("health-sidebar")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex-column flex-column--full" },
      [
        _c(
          "div",
          {
            staticClass: "promo-planner__weekly-planner",
            style: { height: _vm.weeklyPlannerHeight },
          },
          [
            _vm.shouldDisplayWeeklyView
              ? _c("campaigns-weekly-view", {
                  class: _vm.campaignsListCss,
                  attrs: { "make-read-only": _vm.preparationView },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.selectedSubCampaignId
          ? [
              _vm.preparationView
                ? _c(
                    "div",
                    {
                      ref: "details-component",
                      staticClass: "promo-planner__details-component",
                    },
                    [_vm._t("details-component")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("feature-toggle", { attrs: { toggle: _vm.canReadScenario } }, [
                !_vm.preparationView && _vm.isCampaignSelected
                  ? _c(
                      "div",
                      {
                        ref: "scenarios-component",
                        staticClass: "promo-planner__scenarios-viewer",
                      },
                      [_vm._t("scenarios-component")],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }