<template>
    <div v-if="isVisible" :class="{ 'override-container': true, disabled: readOnly }">
        <div class="ag-override-input">
            <div v-show="!readOnly" class="button-container">
                <icon-button
                    v-if="isRevertVisible"
                    class="button"
                    icon="new-revert"
                    @click="revert()"
                />
            </div>
            <div class="input-container">
                <input-text-field
                    class="input"
                    :class="{
                        'input--bold': isBold,
                        'input--blue': isShowForecastedValue,
                        disabled: readOnly,
                    }"
                    :getter="() => overrideValue"
                    :setter="value => setValue(value)"
                    :validations="params.colDef.validationRules || []"
                    :field-type="params.fieldType"
                    :placeholder="initialValue"
                    :disabled="readOnly"
                    format="numbers.default.overwrittenVolumeEntry"
                    positive-only
                    filled
                />
            </div>
            <div
                v-if="isShowForecastedValue"
                :class="{ 'initial-value': true, 'input--bold': isBold }"
            >
                {{ readOnlyValue }}
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { get, isNil, has } from 'lodash';

export default Vue.extend({
    computed: {
        isRevertVisible() {
            return !isNil(this.overrideValue);
        },

        // This computed is used on the supplier funding page where we use `visible`
        // field to hide it depending on the ag-grid renderer params.
        // 'visible` param is  optional and if not provided this method will just return true and component will be visible
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },

        readOnly() {
            return (
                this.isReadOnly ||
                (has(this.params, 'readOnly') ? this.params.readOnly(this.params) : false)
            );
        },

        initialValue() {
            const initialValue = get(this.params.value, 'defaultValue');
            return this.$n('numbers.default.overwrittenVolumeEntry', initialValue, {
                usePlaceholder: true,
            });
        },

        overrideValue() {
            return get(this.params.value, 'isOverridden', false)
                ? get(this.params.value, 'overrideValue')
                : null;
        },

        isShowForecastedValue() {
            return !isNil(this.overrideValue);
        },

        isBold() {
            return get(this.params, 'data.isSupplier', false);
        },

        readOnlyValue() {
            return `(${this.initialValue})`;
        },
    },

    methods: {
        setValue(value) {
            const params = {
                oldValue: isNil(this.overrideValue) ? this.initialValue : this.overrideValue,
                newValue: value,
                data: this.params.data,
                colDef: {
                    fieldName: this.params.colDef.overrideFieldName,
                    fieldPath: this.params.colDef.overrideFieldPath,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },

        revert() {
            this.setValue(null);
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.override-container {
    display: flex;
    flex-direction: column;

    &:hover {
        .button-container {
            opacity: 1 !important;
        }
    }

    &.disabled {
        .ag-override-input .input-container {
            &::v-deep {
                .rtls-text-field {
                    background-color: transparent !important;
                }
            }
        }
    }

    .ag-override-input {
        display: flex;
        width: 18rem;

        .button-container {
            display: flex;
            opacity: 0;
            width: 2rem;
        }

        .input-container {
            width: 8rem;
            height: 2.5rem;

            .input {
                &--bold {
                    font-weight: bold;
                }
                &--blue {
                    color: #60a1e1;
                }
            }

            &::v-deep {
                .rtls-text-field {
                    width: 8rem;
                    text-align: right;
                    padding-right: 0.4rem;
                    background-color: $promo-table-white-bg-colour;
                }
            }
        }

        .initial-value {
            display: flex;
            width: 6rem;
            align-items: center;
            justify-content: end;
            font-size: 1.1rem;
        }

        .input--bold {
            font-weight: bold;
        }
    }
}
</style>
