import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuex-currency-input", class: _vm.cssClass },
    [
      _c(
        "div",
        {
          staticClass: "vuex-currency-input__currency-symbol",
          class: _vm.isDisabled
            ? "vuex-currency-input__currency-symbol--disabled"
            : "",
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.numeralLocaleConfig.currency.symbol) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(VTextField, {
        staticClass: "rtls-text-field rtls-text-field--white",
        attrs: {
          disabled: _vm.isDisabled,
          reverse: "",
          rules: _vm.rules,
          "validate-on-blur": _vm.validationOnBlur,
          type: "text",
          placeholder: "—",
        },
        on: { keypress: _vm.validateValue, change: _vm.updateModel },
        model: {
          value: _vm.currency,
          callback: function ($$v) {
            _vm.currency = $$v
          },
          expression: "currency",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }