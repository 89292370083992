import { get, isUndefined } from 'lodash';
import { mapActions, mapMutations } from 'vuex';
import { sourceTypes } from '@enums/vuex-form';

const mixin = {
    props: {
        placeholder: String,
        disabled: Boolean,
        hidden: Boolean,
        label: String,
        context: null,
        // can be passed, if component is not controlled by vuex-form mixin
        // we don't want to specify the type here as will be different for each component
        defaultValue: null,
        // TODO: add validator
        dynamicDefaultValue: Object,
        vuexModule: String,
        fieldName: String,
        /**
         * fieldPath is used to support a fieldName that can be a path. e.g. offerMechanic.templateDescription
         * below in the model setter Vue $set is used for reactivity purposes and it can’t handle field that is a path.
         * lodash set can't be used instead because of the reactivity issues.
         * fieldPath is optional if you have a simple field
         *
         * example:
         * fieldPath="offerMechanic"
         * fieldName="templateDescription"
         */
        fieldPath: String,
        namespace: String,
        parsedField: [Object, Array],
        getter: Function,
        setter: Function,
        identifier: String,
        validations: Array,
        resetValueOn: String,
        editMode: {
            type: Boolean,
            default: true,
        },
        needPreValidationOnSet: {
            type: Boolean,
            default: false,
        },
        // if true, undefined values will be transformed to null before setting to model
        updateUndefinedToNull: {
            type: Boolean,
            default: false,
        },
        formRef: {
            type: Object,
            required: false,
        },
        tab: {
            type: String,
            default: null,
        },
    },
    computed: {
        initialValue() {
            if (this.dynamicDefaultValue) {
                if (this.dynamicDefaultValue.source === sourceTypes.context) {
                    return get(this.context, this.dynamicDefaultValue.path);
                }
            }
            return this.defaultValue;
        },

        model: {
            get() {
                return this.getter
                    ? this.getter()
                    : get(
                          this.$store.state[this.vuexModule].stagingArea[this.namespace],
                          this.fieldPath ? `${this.fieldPath}.${this.fieldName}` : this.fieldName
                      );
            },
            set(value) {
                // For some vuex components like vuex-select
                // we need run form validation before changing of staging area
                // because they change validation state in next tick and we got wrong
                // validation state in autosave process
                // ToDo: Possible need to remove this as part of PROWEB-1355
                const formValidator =
                    (this.$parent && this.$parent.validate) ||
                    (this.formRef && this.formRef.validate);
                if (this.needPreValidationOnSet && formValidator) {
                    formValidator();
                }
                const valueToSet = this.updateUndefinedToNull && isUndefined(value) ? null : value;
                if (this.setter) {
                    return this.setter(valueToSet);
                }
                this.setStagingAreaField({
                    namespace: this.namespace,
                    fieldPath: this.fieldPath,
                    fieldName: this.fieldName,
                    value: valueToSet,
                });
                if (this.tab) {
                    this.setUnsavedPromotion({
                        namespace: this.namespace,
                        tab: this.tab,
                        value: true,
                    });
                }
            },
        },

        rules() {
            return (
                this.validations &&
                this.validations.map(validation => value =>
                    validation.validator(value, validation.message, ...validation.params)
                )
            );
        },
    },

    methods: {
        ...mapActions({
            setStagingAreaField(dispatch, payload) {
                return dispatch(`${this.vuexModule}/setStagingAreaField`, payload);
            },
            setStagingAreaFields(dispatch, payload) {
                return dispatch(`${this.vuexModule}/setStagingAreaFields`, payload);
            },
        }),
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        setInitialValue() {
            if (!isUndefined(this.initialValue)) {
                this.model = this.initialValue;
            }
        },
    },

    created() {
        if (this.resetValueOn) {
            this.resetValueOnFn = (resetValue = null) => {
                this.model = resetValue;
            };

            this.$root.$on(this.resetValueOn, this.resetValueOnFn);
        }

        if (!this.editMode) {
            this.setInitialValue();
        }
    },

    beforeDestroy() {
        if (this.resetValueOn) this.$root.$off(this.resetValueOn, this.resetValueOnFn);
    },
};

export default mixin;
