<template>
    <div>
        <div v-if="label" class="label">{{ label }}</div>
        <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
                <span
                    v-if="params.enableSorting"
                    class="sort-icon ag-theme-custom ag-icon"
                    :class="currentSortIconClass"
                    @click="updateSorting($event)"
                />
                <v-btn
                    :disabled="isDisabled"
                    class="select-all-btn"
                    color="primary"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon small color="white">mdi-dots-horizontal</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-tooltip z-index="400" top :max-width="500" :disabled="!isSelectAllDisabled">
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <v-list-item
                                :disabled="isSelectAllDisabled"
                                @click="selectUnselectAll({ isSelected: true })"
                            >
                                {{ $t('actions.selectAll') | toSentenceCase }}
                            </v-list-item>
                        </div>
                    </template>
                    <span>{{ params.selectAllDisabledTooltip }}</span>
                </v-tooltip>
                <v-list-item @click="selectUnselectAll({ isSelected: false })">
                    {{ $t('actions.unselectAll') | toSentenceCase }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import Vue from 'vue';
import { get, isFunction } from 'lodash';

export default Vue.extend({
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentSortIndex: 0,
            currentSortIconClass: 'ag-icon-none',
        };
    },
    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
        isSelectAllDisabled() {
            return isFunction(this.params.isSelectAllDisabled)
                ? this.params.isSelectAllDisabled()
                : false;
        },
        label() {
            return this.params.label;
        },
    },
    mounted() {
        // This will fire any time the sorting of this column is changed. It will also fire
        // when this column is sorted, and another column is selected to be sorted
        // (this will reset the sorting of this column and thus trigger this event once).
        this.params.column.addEventListener('sortChanged', this.onSortChanged);
    },
    methods: {
        updateSorting(event) {
            const sortDirectionOptions = ['none', 'asc', 'desc'];

            if (this.currentSortIndex === sortDirectionOptions.length - 1) {
                this.currentSortIndex = 0;
            } else {
                this.currentSortIndex += 1;
            }

            // event.shiftKey means that when the shift key is held down during the click,
            // multiple columns can be sorted at once.
            this.params.setSort(sortDirectionOptions[this.currentSortIndex], event.shiftKey);
        },
        selectUnselectAll({ isSelected }) {
            const colId = this.params.column.colId;
            const updates = [];
            this.params.api.forEachNodeAfterFilter(rowNode => {
                const data = rowNode.data;

                if (isSelected !== !!data[colId]) {
                    data[colId] = isSelected;
                    updates.push(data);
                }
            });

            // Invoke onSelectUnSelectAll if it exists on the column, for the
            // use case where other actions need to be invoked specific to the
            // parent component.
            if (get(this.params, 'column.colDef.cellRendererParams.onSelectUnSelectAll')) {
                this.params.column.colDef.cellRendererParams.onSelectUnSelectAll({
                    updates,
                    isSelected,
                });
            }

            // Use transaction for prevent trigger update event in multiple cells
            this.params.api.applyTransaction({ update: updates });
        },
        onSortChanged() {
            if (this.params.column.isSortAscending()) {
                this.currentSortIconClass = 'ag-icon-asc';
            } else if (this.params.column.isSortDescending()) {
                this.currentSortIconClass = 'ag-icon-desc';
            } else {
                // Setting the sort index here is important because when a different column
                // is sorted, the sorting of the current column is reset which will
                // cause this function to run and this else block to be entered. When this happens
                // we need to reset the internal sorting state of this component.
                this.currentSortIndex = 0;
                this.currentSortIconClass = 'ag-icon-none';
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';

.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small {
    height: 1.5rem;
    min-width: 3.3rem;
}
.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small:hover {
    background-color: $rtls-secondary-button-hover-colour !important;
}
.select-all-btn.v-btn:not(.v-btn--round).v-size--x-small:disabled {
    background-color: $promo-grey-7 !important;
}
.v-list-item {
    font-size: 1.2rem;
    min-height: 3.5rem;
}
.sort-icon {
    padding-left: 0.5rem;
    font-family: 'agGridAlpine' !important;
}
.label {
    font-weight: normal;
    font-size: 1.2rem;
    white-space: normal;
    margin-bottom: 0.25rem;
}
</style>
