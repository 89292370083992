var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOnSupplyTab
    ? _c(
        "div",
        { staticClass: "supply-tab" },
        [
          _c("supply-grid", {
            attrs: {
              "vuex-module": _vm.vuexModule,
              "field-name": "products",
              namespace: _vm.computedNamespace,
              "form-ref": _vm.formRef,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }