import { render, staticRenderFns } from "./orange-warning-circle.vue?vue&type=template&id=561b18cd&functional=true"
import script from "./orange-warning-circle.vue?vue&type=script&lang=js"
export * from "./orange-warning-circle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports