import axios from 'axios';
import to from 'await-to-js';
import numeral from 'numeral';
import {
    flattenDeep,
    filter,
    includes,
    keyBy,
    uniq,
    map,
    find,
    orderBy,
    findLastIndex,
} from 'lodash';
import promotionAvailability from '@enums/promotion-availability';
import offerMechanicTemplates from '@enums/offer-mechanic-templates';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import i18n from '../../vue-i18n';
import storeMixin from '../mixins/vuex-store';
import { toSentenceCase } from '@/js/utils/string-utils';

const getInitialState = () => ({
    toggleLogic: {},
    i18nConfig: {},
    i18nTranslations: {},
    dateFormats: {},
    numberFormats: {},
    promoResources: [],
    customerRestrictions: [],
    productLozengeConfig: {},
    generalConfig: {},
    detailedProvisions: [],
    offerCommercialFields: [],
    validations: {},
    hierarchyConfig: {},
    additionalDetailsDefaultSelection: {},
    rewardsDefaults: {},
    promoResourceTemplates: {},
    resourceTemplateLayouts: {},
    workflowTemplates: {},
    clientStates: {},
    executionApiConfig: {},
    secondaryPlacements: [],
    notificationsConfig: {},
    mainNavbarRoutes: {},
    promotionTabs: {},
    entityDetailsViewerConfig: {},
    clientStateRoles: {},
    overviewPageConfig: {},
    promoFundingConfig: {},
    productDetailsGridConfig: {},
    productDraggableArea: {},
    highchartsConfig: {},
    mechanicsMatrixConfig: {},
    offerDefaults: {},
    offerMechanicPresetTemplates: {},
    releaseFlags: {},
    redisBullAdminConfig: {},
});

const params = {
    resource: 'config',
    readOnly: true,
};

const {
    mutations: { resetState },
    actions: { handleResponseNotifications, resetState: resetStateAction },
} = storeMixin(params);

const store = {
    namespaced: true,

    state: getInitialState(),

    getters: {
        currencySymbol: state => numeral.localeData(state.defaultNumericLocale).currency.symbol,

        // Promo resources getters
        getPromoResources: state => {
            return state.promoResources.map(resource => {
                return {
                    key: resource.key,
                    clientKey: resource.clientKey,
                    description: i18n.t(`preparation.promoResources.${resource.key}`),
                };
            });
        },
        getPromoResourcesWithSubTypes: state => {
            return state.promoResources.map(resource => {
                return {
                    key: resource.key,
                    clientKey: resource.clientKey,
                    description: i18n.t(`preparation.promoResources.${resource.key}`),
                    subTypes: (resource.subTypes || []).map(subType => {
                        return {
                            ...subType,
                            text: toSentenceCase(
                                i18n.t(`preparation.promoResources.subTypes.${subType.key}`)
                            ),
                        };
                    }),
                };
            });
        },

        getPromoResourceKeys: state => state.promoResources.map(resource => resource.key),

        getFilteredPromoResources: (state, getters) => ({ promoResources }) => {
            return getters.getPromoResources.filter(resource =>
                promoResources.includes(resource.key)
            );
        },

        // Customer Restrictions getters
        getCustomerRestrictions: state => {
            return state.customerRestrictions.map(restriction => {
                return {
                    key: restriction.key,
                    description: i18n.t(`planning.customerRestrictionsOptions.${restriction.key}`),
                };
            });
        },
        getCustomerRestrictionKeys: state =>
            state.customerRestrictions.map(restriction => restriction.key),

        getFilteredCustomerRestrictions: (state, getters) => ({ customerRestrictions }) => {
            return getters.getCustomerRestrictions.filter(restriction =>
                customerRestrictions.includes(restriction.key)
            );
        },

        getNumberOfEffectivenessRatingColours: state => {
            return createFeatureAwareFactory(
                state.toggleLogic
            ).getNumberOfEffectivenessRatingColours();
        },

        getHierarchyConfig: state => {
            return state.hierarchyConfig;
        },

        sortedResourceTemplateLayouts: state => {
            const layoutsWithGridNames = state.resourceTemplateLayouts.map(template => ({
                ...template,
                areaNames: uniq(
                    flattenDeep(template.layout.map(layoutStr => layoutStr.split(' ')))
                ),
            }));
            return layoutsWithGridNames.sort((a, b) => a.areaNames.length - b.areaNames.length);
        },

        filteredResourceTemplateLayouts: (state, getters, rooState, rootGetters) => ({
            allowedTemplates,
        }) => {
            return filter(getters.sortedResourceTemplateLayouts, resourceTemplateLayout => {
                const isAllowedFromArray = allowedTemplates.length
                    ? includes(allowedTemplates, resourceTemplateLayout.key)
                    : true;
                const isAllowedForCover = rootGetters['subCampaigns/isCover']
                    ? resourceTemplateLayout.canBeCover
                    : true;
                return isAllowedFromArray && isAllowedForCover;
            });
        },

        templateLayoutsByKey: (state, getters) => {
            return keyBy(getters.sortedResourceTemplateLayouts, 'key');
        },

        getWorkflowTemplateByKey: state => key => {
            return state.workflowTemplates.find(wt => wt.key === key);
        },

        getWorkflowTemplateOptions: state => () =>
            state.workflowTemplates.map(template => {
                return {
                    value: template.key,
                    text: template.name,
                };
            }),

        getWorkflowClientStatesMap: state => resourceType => {
            return state.clientStates[resourceType];
        },

        getPromotionClientStatesMapForPlanningPageFilter: state => {
            return state.clientStates.promotion.filter(clientState => {
                return clientState.includeInPlanningPageFilter;
            });
        },

        getExecutionApiConfig: state => {
            return state.executionApiConfig;
        },
        getSecondaryPlacements: state => () => {
            const secondaryPlacements = map(state.secondaryPlacements, placement => {
                return {
                    key: placement.key,
                    description: toSentenceCase(
                        i18n.t(`planning.secondaryPlacementsOptions.${placement.key}`)
                    ),
                    isFavourite: placement.isFavourite,
                };
            });

            const orderedSecondaryPlacements = orderBy(
                secondaryPlacements,
                ['isFavourite', 'description'],
                ['desc', 'asc']
            );
            const lastFavouriteIndex = findLastIndex(orderedSecondaryPlacements, {
                isFavourite: true,
            });
            orderedSecondaryPlacements.splice(lastFavouriteIndex + 1, 0, { divider: true });

            return orderedSecondaryPlacements;
        },

        getSecondaryPlacementByKey: state => placementKey => {
            return find(state.secondaryPlacements, { key: placementKey });
        },
        notificationsConfigByKey: state => {
            return keyBy(state.notificationsConfig, 'notificationKey');
        },
    },

    mutations: {
        setToggleLogic(state, toggleLogic) {
            state.toggleLogic = toggleLogic;
        },

        setI18nConfig(state, i18nConfig) {
            state.i18nConfig = i18nConfig;
        },

        setI18nTranslations(state, i18nTranslations) {
            state.i18nTranslations = i18nTranslations;
        },

        setDateFormats(state, dateFormats) {
            state.dateFormats = dateFormats;
        },

        setNumberFormats(state, numberFormats) {
            state.numberFormats = numberFormats;
        },

        setPromoResources(state, promoResources) {
            state.promoResources = promoResources;
        },

        setProductLozengeConfig(state, productLozengeConfig) {
            state.productLozengeConfig = productLozengeConfig;
        },

        setCustomerRestrictions(state, customerRestrictions) {
            state.customerRestrictions = customerRestrictions;
        },

        setGeneralConfig(state, generalConfig) {
            state.generalConfig = generalConfig;
        },

        setDetailedProvisions(state, detailedProvisions) {
            state.detailedProvisions = detailedProvisions;
        },

        setOfferCommercialFields(state, offerCommercialFields) {
            state.offerCommercialFields = offerCommercialFields;
        },

        setValidations(state, validations) {
            state.validations = validations;
        },

        setHierarchy(state, hierarchy) {
            state.hierarchyConfig = hierarchy;
        },

        setAdditionalDetailsDefaultSelection(state, additionalDetailsDefaultSelection) {
            state.additionalDetailsDefaultSelection = additionalDetailsDefaultSelection;
        },

        setRewardsDefaults(state, rewardsDefaults) {
            state.rewardsDefaults = rewardsDefaults;
        },

        setPromoResourceTemplates(state, promoResourceTemplates) {
            state.promoResourceTemplates = promoResourceTemplates;
        },

        setResourceTemplateLayouts(state, resourceTemplateLayouts) {
            state.resourceTemplateLayouts = resourceTemplateLayouts;
        },

        setWorkflowTemplates(state, workflowTemplates) {
            state.workflowTemplates = workflowTemplates;
        },

        setClientStates(state, clientStates) {
            state.clientStates = clientStates;
        },

        setExecutionApiConfig(state, executionApiConfig) {
            state.executionApiConfig = executionApiConfig;
        },
        setSecondaryPlacements(state, secondaryPlacements) {
            state.secondaryPlacements = secondaryPlacements;
        },
        setNotificationsConfig(state, notificationsConfig) {
            state.notificationsConfig = notificationsConfig;
        },
        setPromotionTabs(state, promotionTabs) {
            state.promotionTabs = promotionTabs;
        },
        setEntityDetailsViewer(state, entityDetailsViewer) {
            state.entityDetailsViewerConfig = entityDetailsViewer;
        },
        setMainNavbarRoute(state, mainNavbarRoutes) {
            state.mainNavbarRoutes = mainNavbarRoutes;
        },
        setClientStateRoles(state, clientStateRoles) {
            state.clientStateRoles = clientStateRoles;
        },
        setOverviewPageConfig(state, overviewPageConfig) {
            state.overviewPageConfig = overviewPageConfig;
        },
        setPromoFundingConfig(state, promoFundingConfig) {
            state.promoFundingConfig = promoFundingConfig;
        },
        setProductDetailsGridConfig(state, productDetailsGridConfig) {
            state.productDetailsGridConfig = productDetailsGridConfig;
        },
        setProductDraggableArea(state, productDraggableArea) {
            state.productDraggableArea = productDraggableArea;
        },
        setHighchartsConfig(state, highchartsConfig) {
            state.highchartsConfig = highchartsConfig;
        },
        setMechanicsMatrixConfig(state, mechanicsMatrixConfig) {
            state.mechanicsMatrixConfig = mechanicsMatrixConfig;
        },
        setOfferDefaults(state, offerDefaultsOverrides) {
            const defaultOffer = {
                description: toSentenceCase(
                    i18n.t(
                        'planning.promotionsMaintenance.promotionMechanic.offerDescriptions.noProducts'
                    )
                ),
                tiers: [
                    {
                        globalRewards: [],
                        globalRequirements: [],
                        productOfferGroups: [
                            {
                                _id: 1,
                                description: toSentenceCase(
                                    i18n.t(
                                        'planning.promotionsMaintenance.offer.offerMechanic.productOfferGroup.default'
                                    )
                                ),
                                isOptionalOfferGroup: false,
                                rewards: [],
                                requirements: [],
                            },
                        ],
                        freeGifts: [],
                        uiFields: {
                            isIndividualGroups: null,
                        },
                    },
                ],
                uiFields: {
                    offerTemplate: offerMechanicTemplates.noDiscount,
                    templateDescription: toSentenceCase(
                        i18n.t(
                            'planning.promotionsMaintenance.promotionMechanic.offerDescriptions.noProducts'
                        )
                    ),
                },
                maximumPerPurchase: null,
                maximumPerCustomer: null,
                customerAvailability: null,
                promotionAvailability: promotionAvailability.onPurchase,
                couponBarcode: null,
                isSingleTripPromo: true,
            };

            state.offerDefaults = { ...defaultOffer, ...offerDefaultsOverrides };
        },
        setNotUsingLocalDatabase(state, notUsingLocalDatabase) {
            state.notUsingLocalDatabase = notUsingLocalDatabase;
        },
        setToggleLogicValue(state, { toggle, value }) {
            state.toggleLogic[toggle] = value;
        },
        setReleaseFlagsValue(state, { toggle, value }) {
            state.releaseFlags.releaseFlags[toggle] = value;
        },
        setReleaseFlags(state, releaseFlags) {
            state.releaseFlags = releaseFlags;
        },
        setRedisBullAdminConfig(state, redisBullAdminConfig) {
            state.redisBullAdminConfig = redisBullAdminConfig;
        },
        resetState,
    },

    actions: {
        async loadVueConfig({ commit, dispatch }) {
            const [err, response] = await to(axios.get('/api/client-config/vue-config'));
            if (err) {
                throw new Error(err.message);
            }

            await dispatch('setI18nConfig', response.data.i18nConfig);
            commit('setDateFormats', response.data.dateFormats);
            commit('setNumberFormats', response.data.numberFormats);
            commit('setGeneralConfig', response.data.generalConfig);

            return response.data;
        },
        async loadClientConfig({ commit, dispatch }) {
            const [error, response] = await to(axios.get('/api/client-config/'));
            if (error) {
                await dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.t('entities.config'),
                    }),
                });
            }
            // Set numeral to use the most recently chosen locale
            // This may need to go somewhere else if the user gets the power to choose numeric locale
            numeral.locale(response.data.i18nConfig.defaultNumericLocale);
            // Set a custom output when formatting numerals with a value of null
            numeral.nullFormat(response.data.generalConfig.nullFormatting);
            await dispatch('setI18nConfig', response.data.i18nConfig);
            await dispatch('setI18nTranslations', response.data.i18nTranslations);
            commit('setToggleLogic', response.data.toggleLogic);
            commit('setPromoResources', response.data.promoResources);
            commit('setProductLozengeConfig', response.data.productLozengeConfig);
            commit('setCustomerRestrictions', response.data.customerRestrictions);
            commit('setGeneralConfig', response.data.generalConfig);
            commit('setDetailedProvisions', response.data.detailedProvisions);
            commit('setValidations', response.data.validations);
            commit('setHierarchy', response.data.hierarchy);
            commit(
                'setAdditionalDetailsDefaultSelection',
                response.data.additionalDetailsDefaultSelection
            );
            commit('setRewardsDefaults', response.data.rewardsDefaults);
            commit('setPromoResourceTemplates', response.data.promoResourceTemplates);
            commit('setResourceTemplateLayouts', response.data.resourceTemplateLayouts);
            commit('setWorkflowTemplates', response.data.workflowTemplates);
            commit('setClientStates', response.data.clientStates);
            commit('setExecutionApiConfig', response.data.executionApiConfig);
            commit('setSecondaryPlacements', response.data.secondaryPlacements);
            commit('setNotificationsConfig', response.data.notificationsConfig);
            commit('setPromotionTabs', response.data.promotionTabs);
            commit('setEntityDetailsViewer', response.data.entityDetailsViewerConfig);
            commit('setMainNavbarRoute', response.data.mainNavbarRoutes);
            commit('setClientStateRoles', response.data.clientStateRoles);
            commit('setOverviewPageConfig', response.data.overviewPage);
            commit('setPromoFundingConfig', response.data.promoFunding);
            commit('setProductDetailsGridConfig', response.data.productDetailsGrid);
            commit('setProductDraggableArea', response.data.productDraggableArea);
            commit('setHighchartsConfig', response.data.highcharts);
            commit('setMechanicsMatrixConfig', response.data.mechanicsMatrix);
            commit('setOfferDefaults', response.data.offerDefaults);
            commit('setNotUsingLocalDatabase', response.data.notUsingLocalDatabase);
            commit('setOfferCommercialFields', response.data.offerCommercialFields);
            commit('setReleaseFlags', response.data.releaseFlags);
            commit('setRedisBullAdminConfig', response.data.redisBullAdminConfig);
        },
        setI18nConfig({ commit }, i18nConfig) {
            i18n.locale = i18nConfig.defaultLocale;
            i18n.fallbackLocale = i18nConfig.fallbackLocale;
            commit('setI18nConfig', i18nConfig);
        },
        setI18nTranslations({ commit }, i18nTranslations) {
            map(i18nTranslations, (messages, lang) => {
                i18n.setLocaleMessage(lang, messages);
            });
            commit('setI18nTranslations', i18nTranslations);
        },
        handleResponseNotifications,
        resetState: resetStateAction,
        setToggleLogicValue({ commit, dispatch }, { toggle, value }) {
            commit('setToggleLogicValue', { toggle, value });
            dispatch('handleResponseNotifications', {
                error: null,
                response: null,
                successMessage: i18n.t('featureToggler.setToggleLogic'),
            });
        },
        setReleaseFlagsValue({ commit, dispatch }, { toggle, value }) {
            commit('setReleaseFlagsValue', { toggle, value });
            dispatch('handleResponseNotifications', {
                error: null,
                response: null,
                successMessage: i18n.t('featureToggler.setReleaseFlag'),
            });
        },
    },
};

export default store;
