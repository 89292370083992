import { mapState } from 'vuex';
import { defaultRoute } from '@enums/feature-flags';
import { get } from 'lodash';

const mixin = {
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        defaultRoute() {
            return get(this.toggleLogic, defaultRoute, '/');
        },

        currentReturnPathRoute() {
            const returnPath = this.$route.query.returnPath;
            return !returnPath || returnPath.includes('/error/') ? this.defaultRoute : returnPath;
        },
    },
};

export default mixin;
