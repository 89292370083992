<template>
    <div>
        Please use <a :href="loginSamlUrl">the Login link</a> if you aren't forwarded to login page.
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import queryString from 'querystring';
import defaultRouteMixin from '../mixins/default-route-mixin';

export default {
    mixins: [defaultRouteMixin],
    computed: {
        ...mapState('context', ['loginRedirectUrl']),
        returnPath() {
            let returnPath =
                this.defaultRoute !== '/'
                    ? this.defaultRoute
                    : this.currentReturnPathRoute || this.defaultRoute;
            if (returnPath === '/auth-saml/login') returnPath = this.defaultRoute;
            return returnPath;
        },
        loginSamlUrl() {
            this.setCurrentLoginMethod('auth-saml');
            const query = queryString.stringify({ returnPath: this.returnPath });
            return `${this.loginRedirectUrl}/api/auth-saml/login?${query}`;
        },
        currentContextEntries() {
            return Object.entries(this.$store.state.context);
        },
    },

    created() {
        window.location.href = this.loginSamlUrl;
    },

    methods: {
        ...mapActions('context', ['setCurrentLoginMethod']),
    },
};
</script>
