import Vue from 'vue';

/**
 * Utility functions for calculating the first week in the weekly calendar view.
 *
 * @module client/js/utils/weekly-calendar-view-restriction-functions/first-week-calendar-view-restriction-functions
 */

/**
 * Get the week closest to the 1st of January of the current year.
 */
function weekClosestToStartOfYear({ getWeekByDate }) {
    const currentYear = Vue.moment().year();
    return getWeekByDate(`${currentYear}-01-01`);
}

/**
 * Get the week which is X weeks previous to the current week, where X is defined
 * by the limitationPeriod config.
 */
function limitationPeriod({ getWeekByDate, limitationPeriodConfig }) {
    const offset = limitationPeriodConfig;
    const leftPeriodBorder = Vue.moment().subtract(offset * 7, 'days');
    return getWeekByDate(leftPeriodBorder);
}

export default {
    limitationPeriod,
    weekClosestToStartOfYear,
};
