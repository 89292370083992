<template>
    <div ref="weeklyPlannerContainer" class="weekly-planner-container">
        <div class="weekly-planner-wrapper">
            <div class="planner-title">
                <span>{{ planner }}: {{ viewLabel }}</span>
                <span class="planner-title__info">
                    <!--Was commented out according PROWEB-1359-->
                    <!--<icon icon-name="info" small />-->
                </span>
                <feature-toggle :toggle="canEditCampaign">
                    <campaign-form-dialog
                        v-if="!isReadOnly && !isCampaignSelected"
                        :context="newCampaign"
                        add-btn
                    />
                </feature-toggle>
                <v-btn
                    v-if="isCampaignSelected"
                    color="primary"
                    depressed
                    @click="viewAllCampaigns"
                >
                    <span class="font-weight-bold white--text">
                        {{ $tkey('allCampaigns') | toSentenceCase }}
                    </span>
                </v-btn>
                <div class="planner-title__refresh-weekly-metrics">
                    <v-btn
                        color="primary"
                        :disabled="loadingWeeklyMetrics"
                        depressed
                        x-small
                        @click="fetchWeeklyMetrics"
                    >
                        <span class="font-weight-bold white--text">
                            {{ $tkey('refreshWeeklyMetrics') }}
                        </span>
                        <spinner-dynamic v-if="loadingWeeklyMetrics" class="spinner ml-2" />
                        <v-icon v-if="!loadingWeeklyMetrics" small class="ml-1">mdi-refresh</v-icon>
                    </v-btn>
                </div>
                <span>
                    <span class="planner-title__delta">∆:</span>
                    <v-btn-toggle
                        :value="comparableWeek"
                        mandatory
                        dense
                        @change="setComparableWeek"
                    >
                        <v-btn secondary>
                            {{ $t('general.dates.weekFirstLetter') | toSentenceCase }}
                        </v-btn>
                        <v-btn secondary>
                            {{ $t('general.dates.comparableWeek') | toAllUppercaseCase }}
                        </v-btn>
                    </v-btn-toggle>
                </span>
            </div>

            <!--weekly-planner header-->
            <div class="weekly-planner weekly-planner--header" :style="weeklyPlannerStyles">
                <campaigns-weekly-view-header
                    v-for="(weekObj, index) in weeks"
                    :key="'weekHeader' + weekObj.weekOfYear + weekObj.year"
                    v-observe-visibility="{
                        callback: visibilityChanged(weekObj),
                    }"
                    :week-obj="weekObj"
                    :current-week="currentWeek"
                    :current-year="currentYear"
                    :style="weekGridCss(index)"
                    :display-summary-kpis="displaySummaryKpis"
                />
            </div>

            <!--weekly-planner background-->
            <div class="weekly-planner weekly-planner--background" :style="weeklyPlannerStyles">
                <div
                    v-for="(weekObj, index) in weeks"
                    :key="'week' + weekObj.weekOfYear + weekObj.year"
                    class="week"
                    :style="weekGridCss(index)"
                />
            </div>

            <!--weekly-planner campaigns/sub-campaigns grid-->
            <div class="weekly-planner weekly-planner--campaigns" :style="weeklyPlannerStyles">
                <div :style="emptyRowCss">&nbsp;</div>
                <promo-planner-container
                    v-for="campaign in campaignsInView"
                    :key="campaign._id"
                    :campaign="campaign"
                    :planner-period-years-included-next-year="plannerPeriodYearsIncludedNextYear"
                    :days-shift-per-year="daysShiftPerYear"
                    :first-planner-day-numeric="firstPlannerDay"
                    :last-planner-day-numeric="lastPlannerDay"
                    :first-planner-day-date="firstWeekObj.startDate"
                    :last-planner-day-date="lastWeekObj.endDate"
                    :campaign-read-only="campaignEditingState(campaign._id)"
                    :sub-campaigns-read-only-by-id="subCampaignsEditingStateById(campaign._id)"
                    @campaign-opened="campaignOpened"
                    @campaign-duplicated="campaignDuplicated"
                    @sub-campaign-opened="subCampaignOpened"
                />
            </div>
            <campaign-form-dialog
                ref="campaign_form_dialog"
                :key="`campaignDialog::${selectedCampaign._id}::${campaignToggle}`"
                :edit-mode="true"
                :has-activator="false"
                :context="newCampaign"
                :edit-context="selectedCampaign"
                :make-read-only="selectedCampaignEditingState.disabled"
                :read-only-reason="selectedCampaignEditingState.reason"
                :preparation-view="isPreparationView"
            />
            <sub-campaign-form-dialog
                ref="subcampaign_form_dialog"
                :key="`subCampaignDialog::${selectedSubCampaign._id}::${subCampaignToggle}`"
                :edit-mode="true"
                :has-activator="false"
                :context="selectedCampaign"
                :edit-context="selectedSubCampaign"
                :make-read-only="selectedSubCampaignEditingState.disabled"
                :read-only-reason="selectedSubCampaignEditingState.reason"
                :preparation-view="isPreparationView"
                @sub-campaign-selected="subCampaignSelected"
            />
            <campaign-copy-dialog
                ref="campaign_copy_form_dialog"
                :key="`copyCampaignDialog::${selectedCampaign._id}`"
                :has-activator="false"
                :campaign-id="selectedCampaign._id"
                :start-date="newCampaign.startDate"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { orderBy, throttle, forEach, flatten, uniq } from 'lodash';

import tabNames from '@enums/tab-names';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { canEditCampaign } from '@enums/feature-flags';
import navigation from '@/js/navigation';
import constants from '@/js/constants';

export default {
    localizationKey: 'planning.plannerView',
    data() {
        return {
            weeksInView: new Set(),
            displaySummaryKpis: false,
            canEditCampaign,
            currentYear: this.$moment().year(),
            planner: this.$tkey('planner'),
            icon: 'info',

            weekElementWidth: null,

            selectedCampaign: { _id: '' },
            selectedSubCampaign: { _id: '' },
            campaignToggle: true,
            subCampaignToggle: true,
            currentWeek: null,
        };
    },
    computed: {
        ...mapState('campaigns', ['campaignsInView']),
        ...mapState('subCampaigns', ['subCampaigns', 'selectedSubCampaignId']),
        ...mapGetters('weeks', [
            'getWeeksBetweenDates',
            'getWeekByDate',
            'firstWeekObj',
            'lastWeekObj',
        ]),
        ...mapState('selectedDates', [
            'selectedWeek',
            'selectedYear',
            'visibleWeeks',
            'plannerFirstDay',
            'plannerLastDay',
        ]),
        ...mapState('weeks', ['comparableWeek']),
        ...mapGetters('campaigns', [
            'isCampaignSelected',
            'getFilteredCampaigns',
            'getFilteredCampaignsKeyedById',
            'getCampaignById',
        ]),
        ...mapGetters('subCampaigns', ['getSubCampaignById', 'getSubCampaignsByCampaignId']),
        ...mapGetters('selectedDates', ['getCurrentYearWeek']),
        ...mapState('clientConfig', ['generalConfig', 'validations', 'toggleLogic']),
        ...mapState('promotions', ['loadingWeeklyMetrics']),

        newCampaign() {
            const granularityToISO = grn => {
                // days -> isoDay, weeks -> isoWeek
                const unit = this.$options.filters.toSentenceCase(grn.slice(0, -1));
                return `iso${unit}`;
            };
            const {
                duration,
                granularity,
            } = this.validations.campaignSufficientFutureStartDate.params;
            return {
                startDate: this.$moment
                    .utc()
                    .add(duration, granularity)
                    .startOf(granularityToISO(granularity)),
            };
        },
        scrollableArea() {
            return this.$refs.weeklyPlannerContainer;
        },

        viewLabel() {
            return this.isCampaignSelected ? this.$tkey('campaign') : this.$tkey('allCampaigns');
        },
        // ex: plannerPeriodYearsIncludedNextYear = [2019, 2020, 2021] or [2020, 2021, 2022], etc.
        plannerPeriodYearsIncludedNextYear() {
            const plannerPeriodYearsIncludedNextYear = [];
            const firstPlannerWeek = this.weeks[0];
            const firstPlannerWeekStartYear = this.$moment(firstPlannerWeek.startDate).year();

            // include 1 extra year at the end to handle situation
            //  when last period week includes days of the next year
            for (let year = this.firstWeekObj.year; year <= this.lastWeekObj.year + 1; year += 1) {
                plannerPeriodYearsIncludedNextYear.push(year);
            }

            // If week 1 starts on the previous year, add the previous year
            if (!plannerPeriodYearsIncludedNextYear.includes(firstPlannerWeekStartYear)) {
                plannerPeriodYearsIncludedNextYear.unshift(firstPlannerWeekStartYear);
            }

            return plannerPeriodYearsIncludedNextYear;
        },

        weeks() {
            const earliestWeeklyViewDate = this.firstWeekObj.startDate;
            const latestWeeklyViewDate = this.lastWeekObj.endDate;

            // Get the weeks between the earliest and latest dates (inclusive).
            const filteredWeeks = this.getWeeksBetweenDates({
                startDate: earliestWeeklyViewDate,
                endDate: latestWeeklyViewDate,
            });

            return orderBy(filteredWeeks, ['year', 'weekOfYear']);
        },
        campaignsByWeek() {
            const weeksForCampaigns = this.getFilteredCampaigns.map(cmp => ({
                _id: cmp._id,
                startDateWeek: this.getWeekByDate(cmp.startDate),
                endDateWeek: this.getWeekByDate(cmp.endDate),
            }));
            return this.$dateUtils.campaignIdsByMongoWeeks({ resources: weeksForCampaigns });
        },

        firstPlannerDay() {
            const firstPlannerWeek = this.weeks[0];
            const firstPlannerWeekStartDate = this.$moment(firstPlannerWeek.startDate);
            const firstPlannerWeekEndDate = this.$moment(firstPlannerWeek.endDate);

            // Contains the integer for the day of the year (a number between 1 and 366).
            const firstPlannerDayOfYear = firstPlannerWeekStartDate.dayOfYear();

            // check that whole week is a part of single year
            if (firstPlannerWeekStartDate.year() === firstPlannerWeekEndDate.year()) {
                return firstPlannerDayOfYear;
            }

            return (
                firstPlannerDayOfYear -
                this.$dateUtils.getDaysInYear(firstPlannerWeekStartDate.year())
            );
        },

        lastPlannerDay() {
            return this.weeks.length * 7;
        },

        // ex: { 2019: 0, 2020: 10, 2021: 376 (376=10+366) }
        daysShiftPerYear() {
            const daysShiftPerYear = {};
            const firstPlannerWeek = this.weeks[0];
            const firstPlannerWeekYear = this.$moment(firstPlannerWeek.startDate).year();

            this.plannerPeriodYearsIncludedNextYear.forEach((year, index) => {
                if (index === 0) {
                    daysShiftPerYear[year] = 0;
                    return;
                }
                if (index === 1) {
                    if (this.firstPlannerDay < 0 && year === firstPlannerWeekYear + 1) {
                        // weeks starting on yearIndex 0 and ending on yearIndex 1 should have a negative firstPlannerDay
                        // we should then only shift the number of days displayed from the previous year
                        daysShiftPerYear[year] = this.firstPlannerDay * -1;
                    } else {
                        daysShiftPerYear[year] =
                            this.$dateUtils.getDaysInYear(year - 1) - this.firstPlannerDay;
                    }
                    return;
                }

                daysShiftPerYear[year] =
                    daysShiftPerYear[year - 1] + this.$dateUtils.getDaysInYear(year - 1);
            });
            return daysShiftPerYear;
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },
        selectedCampaignEditingState() {
            return this.featureAwareFactory.isCampaignEditingDisabled(
                {
                    campaign: this.getCampaignById({
                        _id: this.selectedCampaign._id,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            ).disabled;
        },
        selectedSubCampaignEditingState() {
            return this.featureAwareFactory.isSubCampaignEditingDisabled(
                {
                    subCampaign: this.getSubCampaignById({
                        _id: this.selectedSubCampaign._id,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            );
        },
        weeklyPlannerStyles() {
            const daysInWeek = 7;
            return {
                'grid-template-columns': `repeat(${this.weeks.length}, ${constants.oneDayWidth *
                    daysInWeek}rem)`,
            };
        },
        // for header
        emptyRowCss() {
            return {
                'grid-column': `1 / span ${this.weeks.length}`,
                'grid-row': '1',
            };
        },
        isPreparationView() {
            return this.isReadOnly;
        },
    },
    watch: {
        getCurrentYearWeek() {
            // Scroll the weekly view when the current year/week selected changes.
            // according https://owlabs.atlassian.net/browse/PROWEB-652
            // all times current or selected week should be in the center
            this.scroll({ centreInScreen: true });
        },
        // The campaigns visible are controlled by the user's scroll position using the `v-observe-visibility` directive.
        // This means that the user's scroll action triggers updateCampaignsInView to change what can be shown, without
        // relying on using a computed. This means the campaigns in view are not reactive, and so will not react to the user
        // applying filters. This needs to be done manually.
        getFilteredCampaigns() {
            this.updateCampaignsInView();
        },
    },
    created() {
        this.currentWeek = this.getWeekByDate().weekOfYear;
    },
    mounted() {
        // if limitationPeriod is not 0/null/undefined, planner first/last days are defined
        // otherwise we need to wait for campaigns to get the last campaign endDate.
        if (this.generalConfig.limitationPeriod) {
            this.setPlannerFirstDay(this.firstWeekObj.startDate);
            this.setPlannerLastDay(this.lastWeekObj.endDate);

            // Add an event listener to ensure the week numbers visible in the weekly
            // view are updated every time the user stops scrolling.
            this.scrollableArea.addEventListener(
                'scroll',
                throttle(this.getWeekNumbersVisibleOnWeeklyView, 200)
            );
        }

        if (!this.getCurrentYearWeek) {
            const currentWeek = this.getWeekByDate(this.$moment());
            this.setSelectedYearWeek({
                year: currentWeek ? currentWeek.year : this.currentYear,
                weekNumber: currentWeek ? currentWeek.weekOfYear : this.$moment().isoWeek(),
            });
        } else {
            this.scroll({ centreInScreen: true });
        }

        this.weekElementWidth = this.scrollableArea.getElementsByClassName('week')[0].offsetWidth;
    },

    methods: {
        ...mapActions('campaigns', ['fetchCampaigns', 'clearSelectedCampaign']),
        ...mapActions('subCampaigns', [
            'fetchSubCampaigns',
            'fetchSubCampaignWithDependencies',
            'clearSelectedSubCampaignId',
        ]),
        ...mapActions('selectedDates', [
            'setSelectedYearWeek',
            'setVisibleWeeks',
            'setPlannerFirstDay',
            'setPlannerLastDay',
        ]),
        ...mapMutations('campaigns', ['setCampaignsInView']),
        ...mapMutations('weeks', ['setComparableWeek']),
        ...mapActions('promotions', ['fetchWeeklyMetrics']),

        visibilityChanged(weekObj) {
            const token = this.$dateUtils.getYearWeekMongoToken(weekObj);

            return isVisible => {
                // When the user scrolls, this callback is called for every week, not just those weeks which have become
                // visible or not. We should quit early if nothing needs to happen for this week.
                // I.e. isVisible is `true` and the week is already visible, or isVisible is `false` and the week is
                // already not visible. This helps prevent an infinite loop.
                const weekIsAlreadyInView = this.weeksInView.has(token);
                if (isVisible === weekIsAlreadyInView) return;

                // There is nothing to be done if the week has no campaigns.
                const campaignIds = this.campaignsByWeek[token];
                if (!campaignIds) return;

                if (isVisible) {
                    this.weeksInView.add(token);
                } else {
                    this.weeksInView.delete(token);
                }

                this.updateCampaignsInView();
            };
        },

        updateCampaignsInView() {
            const campaignsByWeek = Array.from(this.weeksInView).map(weekToken => {
                const campaignIds = this.campaignsByWeek[weekToken] || [];

                return Array.from(campaignIds).map(campaignId => {
                    return this.getFilteredCampaignsKeyedById[campaignId];
                });
            });

            // campaignsByWeek is an array of arrays - an array of campaigns for every week in view.
            // Flatten all campaign arrays together and get all unique values (campaigns can span multiple weeks).
            const allCampaignsInView = uniq(flatten(campaignsByWeek));

            this.setCampaignsInView({ campaigns: allCampaignsInView });
        },

        scroll({ centreInScreen }) {
            const weekElementId = `week${this.selectedWeek}${this.selectedYear}`;
            this.$scrollTo(`#${weekElementId}`, 0, {
                container: '.weekly-planner-container',
                offset: centreInScreen ? this.getScreenCentreWithOffset(weekElementId) : 0,
                x: true,
                y: false,
            });
        },
        weekGridCss(weekIndex) {
            const weekStart = weekIndex + 1;
            const weekEnd = weekStart + 1;
            return {
                'grid-column': `${weekStart} / ${weekEnd}`,
                'grid-row': '1 / span 1',
            };
        },

        getScreenCentreWithOffset(elementIdToOffsetBy) {
            const screenWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;

            const screenCentre = screenWidth / 2;

            // Retrieve the offset element width and default it to the width of a week.
            const offsetElement = document.getElementById(elementIdToOffsetBy);
            const offsetElementWidth = offsetElement
                ? offsetElement.offsetWidth
                : this.weekElementWidth;

            return -(screenCentre - offsetElementWidth);
        },

        viewAllCampaigns() {
            this.clearSelectedCampaign();
            this.clearSelectedSubCampaignId();

            navigation.toAllCampaignsView({
                tabName: this.isReadOnly ? tabNames.preparation : tabNames.planning,
            });
        },

        getWeekNumbersVisibleOnWeeklyView() {
            if (!this.scrollableArea) return;

            // The width of the scrollable area that's visible to the user (e.g. 1600px)
            // This is different to the total scrollable area which will be larger.
            const visibleScrollableAreaWidth = this.scrollableArea.offsetWidth;

            // The left X position of where the scrollable area has been scrolled to.
            // I.e. if the total area is 11000px and the user has scrolled half way, this would be 5500.
            const scrollableAreaLeftXPosition = this.scrollableArea.scrollLeft;

            // The right X position of where the scrollable area has been scrolled to.
            // In this example, this would yield 7100 (5500 + 1600). This suggests weeks between
            // pixels 5500 and 7100 are visible to the user.
            const scrollableAreaRightXPosition =
                scrollableAreaLeftXPosition + visibleScrollableAreaWidth;

            const weekElements = this.scrollableArea.querySelectorAll('.week');

            const visibleWeeks = [];
            const year = this.selectedYear;

            // Identify elements which are within the visible scrollable area
            // and store their week number within the array to be returned.
            // Only weeks in the year being rendered are considered.
            forEach(weekElements, function(element) {
                if (Number(element.getAttribute('data-year')) === year) {
                    if (
                        element.offsetLeft >= scrollableAreaLeftXPosition &&
                        element.offsetLeft + element.offsetWidth <= scrollableAreaRightXPosition
                    ) {
                        visibleWeeks.push({
                            year: Number(element.getAttribute('data-year')),
                            weekOfYear: Number(element.getAttribute('data-week')),
                        });
                    }
                }
            });

            // Update the store.
            this.setVisibleWeeks({ visibleWeeks });
        },
        async campaignOpened({ campaign }) {
            // Update the campaign toggle. The actual value of this is not important,
            // changing it is just used to force the component to be recreated by referencing
            // the toggle in the key for the dialog.
            this.campaignToggle = !this.campaignToggle;
            await Promise.all([
                this.fetchCampaigns({
                    params: {
                        where: {
                            _id: campaign._id,
                        },
                    },
                    patchState: true,
                }),
                this.fetchSubCampaigns({
                    params: {
                        where: {
                            campaignId: campaign._id,
                        },
                    },
                    patchState: true,
                }),
            ]);

            const selectedCampaign = this.getCampaignById({
                _id: campaign._id,
                usePluralResourceName: true,
            });
            this.selectedCampaign = selectedCampaign;

            this.$nextTick(() => {
                this.$refs.campaign_form_dialog.openDialog();
            });
        },
        campaignDuplicated({ context: campaign }) {
            // The copy campaign dialogue only needs the campaign ID which is available on the context
            // without needing to do a fetch as performed in campaignOpened.
            this.selectedCampaign = campaign;

            this.$nextTick(() => {
                this.$refs.campaign_copy_form_dialog.openDialog();
            });
        },
        async subCampaignOpened({ subCampaign }) {
            this.viewAllCampaigns();
            // Update the sub-campaign toggle. The actual value of this is not important,
            // changing it is just used to force the component to be recreated by referencing
            // the toggle in the key for the dialog.
            this.subCampaignToggle = !this.subCampaignToggle;

            // Load in the promotion data which could restrict the sub-campaign options.
            const promotionFields = [
                '_id',
                'storeGroups',
                'resources',
                'execution',
                'tags',
                'scenarioId',
                'offerMechanic.customerAvailability',
                'categories',
            ];
            await this.fetchSubCampaignWithDependencies({ subCampaign, promotionFields });

            const selectedCampaign = this.getCampaignById({
                _id: subCampaign.campaignId,
                usePluralResourceName: true,
            });

            const selectedSubCampaign = this.getSubCampaignById({
                _id: subCampaign._id,
                usePluralResourceName: true,
            });

            this.selectedCampaign = selectedCampaign;
            this.selectedSubCampaign = selectedSubCampaign;

            this.$nextTick(() => {
                this.$refs.subcampaign_form_dialog.openDialog();
            });
        },
        subCampaignSelected(subCampaign) {
            if (!this.selectedSubCampaignId || this.selectedSubCampaignId !== subCampaign._id) {
                navigation.toFavouriteScenario({ subCampaign });
            }
        },
        /**
         * @param {string} campaignId
         * @returns {boolean} true if this campaign editing is disabled
         */
        campaignEditingState(campaignId) {
            return this.featureAwareFactory.isCampaignEditingDisabled(
                {
                    campaign: this.getCampaignById({
                        _id: campaignId,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            ).disabled;
        },
        /**
         * @param {string} campaignId
         * @returns {Object.<string, boolean>} editing state for each campaign's sub-campaign
         * where string key is subCampaignId and boolean is true if this sub-campaign editing is disabled
         */
        subCampaignsEditingStateById(campaignId) {
            const subCampaigns = this.getSubCampaignsByCampaignId(campaignId);
            return Object.fromEntries(
                subCampaigns.map(subCampaign => [
                    subCampaign._id,
                    this.featureAwareFactory.isSubCampaignEditingDisabled(
                        {
                            subCampaign: this.getSubCampaignById({
                                _id: subCampaign._id,
                                usePluralResourceName: true,
                            }),
                        },
                        this.$moment
                    ).disabled,
                ])
            );
        },
    },
    events: {
        onSummaryKpisToggled() {
            this.displaySummaryKpis = !this.displaySummaryKpis;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

::v-deep {
    .spinner div {
        border-color: white transparent transparent transparent;
    }
}

.weekly-planner-container {
    .weekly-planner-wrapper {
        min-height: 100%;
        position: relative;
        display: inline-block;
        background-color: $promo-white-2;

        .planner-title {
            display: flex;

            padding: 0.7rem 1.5rem;
            width: calc(100vw - 30.4rem);
            height: $planner-title-height;

            background-color: $promo-white-2;
            font-weight: 700;
            font-size: 1.4rem;

            @include position-sticky;
            top: 0;
            left: 0;
            z-index: $planner-z-index;

            &__info {
                margin: 0 1.8rem 0 0.7rem;
            }

            &__refresh-weekly-metrics {
                margin-left: auto;
                margin-right: 2rem;
                align-self: flex-end;
            }

            &__delta {
                padding-right: 0.5rem;
            }
        }

        .weekly-planner {
            height: 100%;
            width: 100%;
            display: grid;

            grid-auto-flow: column;

            &--header {
                @include position-sticky;
                z-index: $planner-z-index;
                top: $planner-title-height;
            }

            &--campaigns {
                //shows the height of grid row in campaign-weekly-view and set the margin from promo-planner-container
                // and also takes 1/4 of the grid row height in promo-planner-container

                grid-auto-rows: 1rem;
            }

            &--background {
                position: absolute;
                top: $planner-title-height;
                left: 0;
                height: calc(100% - #{$planner-title-height});
            }

            .week {
                height: 100%;
                width: 100%;

                border-right: 1px solid $promo-grey;
                background-color: $promo-white;

                &:nth-child(odd) {
                    background-color: $promo-grey-3;
                }
            }
        }
    }
}
</style>
