<template functional>
    <v-chip
        x-small
        label
        :class="
            $options.methods.getBadgeInfo(props.type, props.isProposed, props.proxyProductKey)
                .background
        "
        style="padding-right: 0.6rem; padding-left: 0.6rem;"
    >
        <span
            class="font-weight-bold"
            :class="
                $options.methods.getBadgeInfo(props.type, props.isProposed, props.proxyProductKey)
                    .textColor
            "
        >
            {{
                parent.$t(
                    `general.badge.${
                        $options.methods.getBadgeInfo(
                            props.type,
                            props.isProposed,
                            props.proxyProductKey
                        ).type
                    }`
                )
            }}
        </span>
    </v-chip>
</template>

<script>
import Vue from 'vue';
import { includes } from 'lodash';
import { current, proposed, New, productGroup } from '@enums/product-badge-type';

const badgeTypes = {
    productBadge: {
        background: 'product-badge',
        textColor: 'black--text',
    },
    newProductBadge: {
        background: 'new-product-badge',
        textColor: 'white--text',
    },
    proposedProductBadge: {
        background: 'proposed-product-badge',
        textColor: 'white--text',
    },
};

export default Vue.extend({
    props: {
        type: {
            required: false,
            type: String,
        },
        isProposed: {
            required: false,
            type: Boolean,
        },
        proxyProductKey: {
            required: false,
            type: Number,
        },
    },

    methods: {
        getBadgeInfo(type, isProposed, proxyProductKey) {
            const badgeType =
                type ||
                (() => {
                    if (isProposed) return proposed;
                    if (proxyProductKey) return New;

                    return current;
                })();

            if (includes([current, productGroup], badgeType)) {
                return {
                    type: badgeType,
                    ...badgeTypes.productBadge,
                };
            }
            if (badgeType === proposed) {
                return {
                    type: badgeType,
                    ...badgeTypes.proposedProductBadge,
                };
            }
            return {
                type: badgeType,
                ...badgeTypes.newProductBadge,
            };
        },
    },
});
</script>
