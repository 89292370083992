<template>
    <div>
        <div
            v-for="(additionalReward, index) in model.tiers[tierIndex].additionalRewards"
            :key="index + additionalReward.type"
        >
            <requirement-and-rewards-details
                :vuex-module="vuexModule"
                :field-path="`offerMechanic.tiers[${tierIndex}].additionalRewards`"
                :field-name="`[${index}]`"
                :entity-index="index"
                :namespace="namespace"
                :tier-index="tierIndex"
                :options="additionalRewardOptions"
                :clearable="clearable"
                :amount-validators="amountValidators"
                :disabled="disabled"
                @delete="onDeleteAdditionalReward"
                @change="onChangeAdditionalReward"
            />
            <v-divider class="horizontal-divider" />
        </div>
        <create-new-button
            class="add-additional-reward-btn"
            background
            :disabled="disabled || isAdditionalRewardsEmpty"
            @createNew="onAddAdditionalReward"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isNil, get } from 'lodash';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import validators from '@/js/validators';

export default {
    mixins: [vuexComponentMixin],
    localizationKey: 'planning.promotionsMaintenance.promotionMechanic.additionalRewards',

    props: {
        tierIndex: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        groupDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        formRef: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            isGroupType: false,
            inputValidators: [
                {
                    validator: validators.minValue,
                    params: [1],
                },
                {
                    validator: validators.required,
                },
            ],
        };
    },

    computed: {
        ...mapState('clientConfig', ['generalConfig']),
        ...mapGetters('loyaltyPoints', ['getAwardLoyaltyPointsOptions']),

        additionalRewardOptions() {
            return this.getAwardLoyaltyPointsOptions;
        },

        clearable() {
            return get(this.getTiers(), 'additionalRewards', []).length === 1;
        },

        isAdditionalRewardsEmpty() {
            if (!get(this.getTiers(), 'additionalRewards', []).length) return true;
            return (
                get(this.getTiers(), 'additionalRewards', []).length === 1 &&
                get(this.getTiers(), 'additionalRewards', [])[0].type === null
            );
        },

        amountValidators() {
            if (this.isAdditionalRewardsEmpty) {
                return [];
            }

            return this.inputValidators;
        },
    },

    methods: {
        getTiers(index) {
            return get(this.model, `tiers[${index || this.tierIndex}]`, {});
        },
        onAddAdditionalReward() {
            const defaultAdditionalReward = {
                type: this.additionalRewardOptions[0].value,
                amount: 1,
            };

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldPath: `offerMechanic.tiers[${this.tierIndex}]`,
                fieldName: 'additionalRewards',
                value: [...get(this.getTiers(), 'additionalRewards', []), defaultAdditionalReward],
            });
        },

        onDeleteAdditionalReward({ deleteIndex }) {
            const data = [...get(this.getTiers(), 'additionalRewards', [])];

            data.splice(deleteIndex, 1);

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldPath: `offerMechanic.tiers[${this.tierIndex}]`,
                fieldName: 'additionalRewards',
                value: data,
            });

            // Revalidate form after removing reward.
            this.$nextTick(() => {
                // Revalidates rules for changed inputs
                if (this.formRef) {
                    this.formRef.validate();
                }
            });
        },

        onChangeAdditionalReward({ value: additionalRewardType, index: additionalRewardIndex }) {
            const stagingAreaUpdates = [];

            const additionalRewardFieldPath = `offerMechanic.tiers[${
                this.tierIndex
            }].additionalRewards.[${additionalRewardIndex}]`;

            // initialize tier requirement amount if needed
            if (isNil(this.getTiers(0).additionalRewards[additionalRewardIndex].amount)) {
                stagingAreaUpdates.push({
                    fieldPath: additionalRewardFieldPath,
                    fieldName: 'amount',
                    value: 1,
                });
            }

            // clear additional reward amount when type was cleared
            if (isNil(additionalRewardType)) {
                stagingAreaUpdates.push({
                    fieldPath: additionalRewardFieldPath,
                    fieldName: 'amount',
                    value: null,
                });
            }

            stagingAreaUpdates.push({
                fieldPath: additionalRewardFieldPath,
                fieldName: 'type',
                value: additionalRewardType || null,
            });

            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate: stagingAreaUpdates,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.main__input {
    padding-bottom: 0.7rem;
}

.add-additional-reward-btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.horizontal-divider {
    border-color: $promo-divider-accent-colour;
}
</style>
