// Libraries
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import VueMoment from 'vue-moment';
import vuePlugins from '@/js/utils/vue-plugins/index';
import draggable from 'vuedraggable';
import vueScrollto from 'vue-scrollto';
import VJsoneditor from 'v-jsoneditor';
import VueHighcharts from 'vue-highcharts';
import { get, merge, isEmpty } from 'lodash';
import { ObserveVisibility } from 'vue-observe-visibility';
// eslint-disable-next-line import/no-duplicates
import '@ag-grid-enterprise/core';
// eslint-disable-next-line import/no-duplicates
import { LicenseManager } from '@ag-grid-enterprise/core';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// Importing UI Components from RTLS UI Components
import rtlsUiComponents from '@rtls/rtls-ui-components';
// Importing Icon Components (must be lowercase)
import icons from '@rtls/rtls-ui-components/src/icons';
import momentFactory from '@sharedModules/moment-factory';
import dateUtilsFactory from '@sharedModules/date-utils-factory';

// Importing chart components
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

// Importing Promo Icon Components
import promoIcons from '@/js/icons';

import i18n from '@/js/vue-i18n';
import App from '@/app';
import store from '@/js/store';
import Axios from '@/js/axios';
import promoUtilsPlugin from '@/js/plugins/promo-utils';
import router, { addRouterRoutes } from '@/js/router';
import eventsMixin from '@/js/mixins/events';
import readOnlyMixin from '@/js/mixins/readonly';

// Styles
// Importing vuetify styles
import 'vuetify/dist/vuetify.css';
// Importing Stylesheet from RTLS UI Components Library
import '@rtls/rtls-ui-components/dist/rtls-ui-components.css';
// Import Local Styles
import '@style/main.scss';

// Import numeralJS locales
import '@/lang/numeral';

// register components
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('draggable', draggable);

// Extend Vue with libraries
Vue.use(Vuetify);
Vue.use(rtlsUiComponents);
Vue.use(vuePlugins);
Vue.use(VueHighcharts);
Vue.use(VueI18n);
Vue.use(vueScrollto);
Vue.use(VJsoneditor);
Vue.directive('observe-visibility', ObserveVisibility);

// initiliase extra highcharts modules to enable waterfall chart
highchartsMore(Highcharts);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                // Setting primary app colours for material theme
                primary: '#2f477c',
                accent: '#2f477c',
            },
        },
    },
    icons: merge({ ...icons }, { ...promoIcons }),
});

// Import all the available client components/filters/directives from this project
Vue.registerComponents(require.context('./src/js/components/', true, /(\.html|\.js|\.vue)$/));
Vue.registerComponents(require.context('./src/js/pages/', true, /(\.html|\.js|\.vue)$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/directives/', true, /\.js$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/filters/', true, /\.js$/));

Vue.use(Axios, { router, store, i18n });
Vue.use(VueRouter);

// Global mixins
Vue.mixin(eventsMixin);
Vue.mixin(readOnlyMixin);

// Set ag-grid license
LicenseManager.setLicenseKey(
    'CompanyName=Insight_on_behalf_of_Oliver Wyman Inc,LicensedApplication=RetailElements,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=0,AssetReference=AG-010075,ExpiryDate=27_August_2021_[v2]_MTYzMDAxODgwMDAwMA==8a126685191e85d58a501a22b676e75a'
);

const createMoment = async () => {
    // check if client config is already loaded
    // we only want to dispatch 'clientConfig/loadVueConfig' for unauthorized users
    let i18nConfig;
    let generalConfig;

    if (isEmpty(store.state.clientConfig.i18nConfig)) {
        const vueConfig = await store.dispatch('clientConfig/loadVueConfig');
        i18nConfig = vueConfig.i18nConfig;
        generalConfig = vueConfig.generalConfig;
    } else {
        i18nConfig = store.state.clientConfig.i18nConfig;
        generalConfig = store.state.clientConfig.generalConfig;
    }
    const momentConfig = { ...i18nConfig, ...generalConfig };

    // pass the the configurations to the moment factory
    // the moment registered can be used in the client side using this.$moment.
    return momentFactory(momentConfig);
};

const applyPlugins = moment => {
    Vue.use(VueMoment, {
        moment,
    });

    Vue.use(promoUtilsPlugin, {
        utils: {
            dateUtils: dateUtilsFactory(moment),
        },
    });
};

const initialiseDatadog = context => {
    // Initialise Datadog RUM if not disabled for environment
    if (!get(context.datadog[context.env], 'disabled')) {
        datadogRum.init({
            applicationId: context.datadog.applicationId,
            clientToken: context.datadog.clientToken,
            site: 'datadoghq.eu',
            service: 'promo-webtool',
            env: context.env,
            version: context.versionTag,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackInteractions: true,
            trackFrustrations: true,
            trackLongTasks: true,
            trackResources: true,
            enableExperimentalFeatures: ['clickmap'],
            allowedTracingUrls: [context.clientUrl],
        });
        datadogLogs.init({
            applicationId: context.datadog.applicationId,
            clientToken: context.datadog.clientToken,
            site: 'datadoghq.eu',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        });
    }
};

Promise.resolve(store.dispatch('context/loadContext'))
    // Try to load current profile (get 401 otherwise and we navigate to the login page)
    .then(
        Promise.resolve(store.dispatch('context/loadUserContext')).finally(async () => {
            const moment = await createMoment();
            applyPlugins(moment);

            const app = new Vue({
                vuetify,
                i18n,
                router,
                store,
                render: h => h(App),
            });

            // to access Vue instance inside Vuex store, ref: https://github.com/vuejs/vuex/issues/1399
            store.$app = app;

            initialiseDatadog(store.state.context);

            if (!get(store, 'state.clientConfig.toggleLogic.reportingOnly', false)) {
                store.dispatch('initialiseStateBackgroundPostAppCreation', null, { root: true });
            }

            // It's important that the routes are added after $app is set on the store.
            // If the routes are added first, the routing kicks in and you can encounter issues
            // in the router guard logic where actions run which try to access $app and it's undefined.
            addRouterRoutes();

            // Start the Vue application
            app.$mount('#vueholder');

            return app;
        })
    );
