var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Loyalty")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-106.000000, -190.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "loyalty",
                    transform: "translate(105.999980, 190.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Colour",
                      d: "M7.99633789,14.4013672 C8.15014648,14.4013672 8.38452148,14.2988281 8.55297852,14.1889648 C12.4421387,11.6914062 14.9250488,8.78369141 14.9250488,5.83203125 C14.9250488,3.34912109 13.2111816,1.59863281 10.9553223,1.59863281 C9.65893555,1.59863281 8.6262207,2.20654297 7.99633789,3.13671875 C7.38110352,2.21386719 6.33374023,1.59863281 5.03735352,1.59863281 C2.78149414,1.59863281 1.07495117,3.34912109 1.07495117,5.83203125 C1.07495117,8.78369141 3.55053711,11.6914062 7.43969727,14.1889648 C7.61547852,14.2988281 7.8425293,14.4013672 7.99633789,14.4013672 Z M7.99633789,13.1196289 C7.97436523,13.1196289 7.9230957,13.090332 7.85717773,13.0463867 C4.94946289,11.112793 2.29077148,8.35888672 2.29077148,5.82470703 C2.29077148,4.015625 3.4699707,2.80712891 5.05932617,2.80712891 C6.34106445,2.80712891 7.07348633,3.59814453 7.51293945,4.27929688 C7.70336914,4.55761719 7.82788086,4.64550781 7.99633789,4.64550781 C8.17211914,4.64550781 8.2746582,4.55029297 8.47973633,4.27929688 C8.95581055,3.61279297 9.65893555,2.80712891 10.9406738,2.80712891 C12.5300293,2.80712891 13.7019043,4.015625 13.7019043,5.82470703 C13.7019043,8.35888672 11.0432129,11.112793 8.13549805,13.0463867 C8.06958008,13.090332 8.02563477,13.1196289 7.99633789,13.1196289 Z",
                      fill: "#2F477C",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }