import { object } from '@enums/value-types';
import store from '@/js/store';
import i18n from '@/js/vue-i18n';
import FunctionProp from '@/js/utils/component-utils';
import {
    getPeriod,
    getRemainingLumpFunding,
    getSupplierKeyName,
    getCategoryNames,
} from '@/js/utils/supplier-commitment-utils';

export default [
    {
        header: 'supplierName',
        contextFunction: getSupplierKeyName,
    },
    {
        header: 'hierarchyLevel',
        cellClasses: ['category'],
        contextFunction: getCategoryNames,
    },
    {
        header: 'scope',
        name: 'scope',
    },
    {
        header: 'fundingValue',
        name: 'fundingValue',
        headerClasses: ['bold'],
        cellClasses: ['border-right', 'bold'],
        component: {
            name: 'vuex-currency-input',
            functionProps: [
                new FunctionProp('getter', ['fundingValue'], ({ fundingValue }) => () =>
                    fundingValue
                ),
            ],
        },
    },
    {
        header: 'executed',
        name: 'executed',
        headerClasses: ['bold'],
        cellClasses: ['border-right', 'bold'],
        contextFunction: ({ executed }) => i18n.n('numbers.default.currency', executed),
    },
    {
        header: 'planned',
        name: 'planned',
        cellClasses: 'border-right',
        contextFunction: ({ planned }) => i18n.n('numbers.default.currency', planned),
    },
    {
        header: 'remaining',
        cellClasses: 'align-right',
        contextFunction: ({ fundingValue, executed, planned }) =>
            i18n.n(
                'numbers.default.currency',
                getRemainingLumpFunding({ fundingValue, executed, planned })
            ),
    },
    {
        header: 'period',
        contextFunction: ({ startDate, endDate }) => getPeriod({ startDate, endDate }),
    },
    {
        component: {
            name: 'notes',
            contextualProps: {
                notes: 'notes',
            },
            props: {
                ignoreParentReadOnly: true,
                openLeft: true,
            },
            events: {
                post: {
                    name: 'post',
                    shouldEmitEvent: true,
                },
                save: {
                    name: 'save',
                    shouldEmitEvent: true,
                },
                delete: {
                    name: 'delete',
                    shouldEmitEvent: true,
                },
            },
        },
    },
    {
        hidePromotionSupplierFundingView: true,
        component: {
            name: 'icon-button',
            props: {
                icon: 'trash',
            },
        },
    },
    {
        showPromotionSupplierFundingView: true,
        cellClasses: 'allocation-checkbox',
        component: {
            name: 'allocation-checkbox',
            fullContextAsPropName: 'supplierCommitment',
            computedProps: [
                {
                    name: 'promotionNamespace',
                    executor: 'promotionNamespace',
                },
            ],
        },
    },
    {
        hidePromotionSupplierFundingView: true,
        component: {
            name: 'main-expand-button',
            props: {
                isExpanded: false,
            },
        },
    },
    {
        showPromotionSupplierFundingView: true,
        cellClasses: 'store-groups',
        component: {
            name: 'vuex-select',
            contextualProps: {
                options: 'storeGroups',
                model: 'storeGroups',
            },
            props: {
                disabled: true,
                multiple: true,
                chips: true,
                autocomplete: true,
                finiteList: true,
                itemText: 'description',
                itemValue: 'key',
                setter: () => null,
                label: 'supplierCommitments.headers.storeGroups',
            },
            functionProps: [
                new FunctionProp('getter', ['storeGroups'], ({ storeGroups }) => {
                    return () => storeGroups;
                }),
            ],
        },
    },
    {
        showPromotionSupplierFundingView: true,
        cellClasses: 'promo-resources',
        component: {
            name: 'vuex-icon-checkboxes-list',
            props: {
                itemText: 'description',
                itemValue: 'key',
                valueType: object,
            },
            functionProps: [
                new FunctionProp('options', ['promoResources'], () =>
                    store.getters['supplierCommitments/getCheckboxListOptions']({
                        resource: 'clientConfig',
                        getOptionsFunction: 'getPromoResources',
                    })
                ),
                new FunctionProp('getter', ['promoResources'], ({ promoResources }) => {
                    return () => promoResources;
                }),
                new FunctionProp('model', ['promoResources'], ({ promoResources }) => {
                    const options = store.getters['supplierCommitments/getCheckboxListOptions']({
                        resource: 'clientConfig',
                        getOptionsFunction: 'getPromoResources',
                    });

                    return (options || []).filter(option =>
                        (promoResources || []).find(
                            promoResource => promoResource.key === option.reference.key
                        )
                    );
                }),
            ],
        },
    },
];
