import uxEvents from '@enums/ux-events';

export default {
    promotion: [
        {
            event: uxEvents.promotionPricesUpdated,
            regexes: [/^products\[\d+\]\.promoPrices/],
        },
        {
            event: uxEvents.updatePromotionMechanicDescription,
            regexes: [/^products$/, /^offerMechanic(?!\.uiFields)(?!\.description)/],
        },
        {
            event: uxEvents.promotionUpdatedForUpdatingPrices,
            emitInCreateMode: true,
            emitIfInvalid: true,
            regexes: [/^endDate/, /^storeGroups/],
        },
        {
            event: uxEvents.promotionUpdatedForPromoPriceRecalculation,
            emitInCreateMode: true,
            regexes: [
                /^offerMechanic(?!\.uiFields)(?!\.description)/,
                /^productOfferGroups\[\d+\]\.products/, // Any products moving between product offer groups.
            ],
        },
        {
            event: uxEvents.updateMaintenanceAreaValidation,
            emitIfInvalid: true,
            emitInCreateMode: true,
            regexes: [
                /^endDate/,
                /^storeGroups/,
                /^products/,
                /^offerMechanic\.tiers/,
                /^rateCards/,
            ],
        },
    ],
    'supplier-commitment': [
        {
            event: uxEvents.supplierCommitmentUpdated,
            regexes: [
                /^storeGroupResources/,
                /^supplierName/,
                /^hierarchy/,
                /^scope/,
                /^measurement/,
                /^startDate/,
                /^endDate/,
                /^storeGroups/,
                /^promoResources/,
                /^fundingValue/,
                /^supplier/,
            ],
        },
    ],
    'rate-card': [
        {
            event: uxEvents.rateCardUpdated,
            regexes: [/^storeGroups/, /^description/, /^effectiveTo/, /^amount/, /^identifier/],
        },
    ],
    'variable-funding-agreement': [
        {
            event: uxEvents.variableFundingUpdated,
            regexes: [
                /^storeGroups/,
                /^supplier/,
                /^hierarchy/,
                /^mechanic/,
                /^agreementType/,
                /^applicableTo/,
                /^scope/,
                /^sellInPeriod/,
                /^startDate/,
                /^endDate/,
            ],
        },
    ],
};
