import StatusPage from './status';

const routes = [
    {
        path: '/status',
        name: 'status',
        id: 'status',
        component: StatusPage,
        omitFromNav: true,
    },
];

export default routes;
