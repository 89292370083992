import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "summary-header" }, [
    _c("div", { staticClass: "summary-header__label" }, [
      _vm._v(_vm._s(_vm.params.displayName)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "summary-header__toggle-container" },
      [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("planning.promotionsMaintenance.funding.show")
              )
            ) + ":"
          ),
        ]),
        _vm._v(" "),
        _c(
          VBtnToggle,
          {
            attrs: { mandatory: "" },
            on: { change: _vm.onToggle },
            model: {
              value: _vm.toggleMode,
              callback: function ($$v) {
                _vm.toggleMode = $$v
              },
              expression: "toggleMode",
            },
          },
          [
            _c(
              VBtn,
              { attrs: { large: "", value: _vm.fundingToggleStates.all } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("planning.promotionsMaintenance.funding.all")
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                attrs: {
                  large: "",
                  value: _vm.fundingToggleStates.volumeFunding,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("planning.promotionsMaintenance.funding.volume")
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                attrs: {
                  large: "",
                  value: _vm.fundingToggleStates.lumpFunding,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("planning.promotionsMaintenance.funding.lump")
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                attrs: {
                  large: "",
                  value: _vm.fundingToggleStates.variableFunding,
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t(
                          "planning.promotionsMaintenance.funding.variable"
                        )
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }