'use strict';

// salesUOMTypes are mapped to the keys here in product-types.yaml
const productTypes = {
    unit: 'unit',
    weight: 'weight',
};

module.exports = {
    ...productTypes,
    all: Object.values(productTypes),
};
