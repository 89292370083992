import { get } from 'lodash';
import { sourceTypes } from '@enums/vuex-form';

const mixin = {
    props: ['minDate', 'maxDate'],

    computed: {
        min() {
            // this means it won't have a min date
            if (!this.minDate) return undefined;

            if (this.minDate.source === sourceTypes.context) {
                const minDate = get(this.context, this.minDate.path);
                return this.$options.filters.datepickerFormat(minDate);
            }

            if (this.minDate.source === sourceTypes.contextFunction) {
                const minDate = this.minDate.contextFunction({ context: this.context });
                return this.$options.filters.datepickerFormat(minDate);
            }

            return this.minDate;
        },
        max() {
            // this means it won't have a max date
            if (!this.maxDate) return undefined;

            if (this.maxDate.source === sourceTypes.context) {
                const maxDate = get(this.context, this.maxDate.path);
                return this.$options.filters.datepickerFormat(maxDate);
            }

            return this.maxDate;
        },
    },
};

export default mixin;
