<template>
    <div v-if="isExpanded" class="summary-header variable-funding__header">
        <div class="summary-header__title">
            <label class="summary-header__label">
                {{ params.displayName }}
            </label>
        </div>
        <div class="summary-header__info">
            <v-row no-gutters>
                <v-col cols="3">
                    <div class="line1">
                        {{ formattedValue }}
                    </div>
                </v-col>
                <v-col class="download-container">
                    <v-row no-gutters>
                        <v-col cols="4">
                            <div class="download-label">
                                {{
                                    $t('planning.promotionsMaintenance.funding.enterOrTemplate')
                                        | toSentenceCase
                                }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <div
                            :class="{
                                disabledRow: disableExport || exportBtnDisabled || isFundingUnsaved,
                            }"
                        >
                            <v-btn
                                class="download-button"
                                color="primary"
                                depressed
                                outlined
                                :disabled="disableExport || exportBtnDisabled || isFundingUnsaved"
                                @click.stop="exportExcel"
                            >
                                <b>{{ $t('actions.downloadTemplate') | toSentenceCase }}</b>
                                <icon icon-name="download" right small />
                            </v-btn>
                        </div>
                        <div class="btn-separator">|</div>
                        <file-upload
                            upload-id="bulk-upload-supplier-funding"
                            :allowed-extensions="['.xlsx']"
                            :disabled="disableUpload || isFundingUnsaved"
                            vuex-module="promotions"
                            vuex-action="bulkUploadSupplierFunding"
                        />
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { get, sumBy } from 'lodash';
import promoTabsEnum from '@enums/promotion-tabs';

export default Vue.extend({
    data() {
        return {
            exportBtnDisabled: false,
            isExpanded: false,
        };
    },
    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapState('clientConfig', ['releaseFlags']),
        ...mapState('promotions', ['unsavedPromotion']),

        isFundingUnsaved() {
            return get(
                this.unsavedPromotion[this.params.promotionId],
                promoTabsEnum.funding,
                false
            );
        },
        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.params.promotionId);
        },
        totalVariableFunding() {
            const { products } = this.selectedPromotion;
            return sumBy(products, product => {
                const volume = get(product, 'volumes.totalVolume', 0);
                const unitFundingValue = get(
                    product,
                    'funding.variableFunding.unitFundingValue',
                    0
                );
                return volume * unitFundingValue;
            });
        },
        formattedValue() {
            return this.$options.filters.totalMargin(this.totalVariableFunding);
        },
        disableExport() {
            // disable export if promotion is not in db yet
            return this.params.promotionId === 'default';
        },
        disableUpload() {
            return this.params.promotionId === 'default' || this.params.isPastPromotions;
        },
    },
    events: {
        onVariableFundingCollapsed(state) {
            if (state === this.isExpanded) return;
            this.toggleCollapse();
        },
    },
    methods: {
        ...mapActions('promotions', ['downloadProductSupplierFundingExcel']),
        async exportExcel() {
            this.exportBtnDisabled = true;
            await this.downloadProductSupplierFundingExcel({
                promotionId: this.params.promotionId,
            });
            this.exportBtnDisabled = false;
        },
        toggleCollapse() {
            this.isExpanded = !this.params.columnGroup.isExpanded();
            this.params.setExpanded(this.isExpanded);
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.summary-header {
    flex-grow: 1;
    height: 100%;
    display: flex;
    padding: 0 !important;

    flex-direction: column;
    border-left: $promo-table-border;

    &__title {
        padding: 0 1rem 0 1rem;
        display: flex;
        justify-content: space-between;
    }

    &__info {
        padding: 0 1rem;
        margin: 0 0.5rem 0.5rem 0;
    }

    &__label {
        white-space: normal;
        min-height: 3.4rem;
    }

    .line1 {
        width: 13rem;
        background-color: $promo-table-blue-bg-colour-3;
        padding: 5px;
        margin-bottom: 5px;
    }

    .download-container {
        margin-left: 9rem;
    }

    .download-label {
        font-weight: 400;
        margin-bottom: 0.5rem;
        white-space: initial;
    }

    .btn-separator {
        margin: 0 1rem;
        color: $promo-grey;
    }

    .disabledRow {
        cursor: no-drop;
    }
}
</style>
