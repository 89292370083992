var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alerts-container", style: _vm.gridColumns },
    [
      _vm._l(
        _vm.alertsGroupedByConfiguredCategories,
        function (alertsGroup, index) {
          return _c(
            "div",
            {
              key: alertsGroup.key[0],
              staticClass: "alerts-container__alerts-list",
            },
            [
              !_vm.hasAlerts && index === 0
                ? _c("h2", { staticClass: "alerts-list__label" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("toSentenceCase")(_vm.$tkey("noAlerts"))
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(alertsGroup.alerts, function (alert) {
                return _c(
                  "workflow-task-alert",
                  _vm._b(
                    {
                      key: `${alert.subCampaignId}::${alert.task}::${alert.entity}}`,
                      attrs: { compact: false },
                    },
                    "workflow-task-alert",
                    alert,
                    false
                  )
                )
              }),
            ],
            2
          )
        }
      ),
      _vm._v(" "),
      _c("div", { staticClass: "alerts-container__alerts-list" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }