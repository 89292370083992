import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier-commitments-form" },
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "supplier-commitments-form__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
          model: {
            value: _vm.validation.isValid,
            callback: function ($$v) {
              _vm.$set(_vm.validation, "isValid", $$v)
            },
            expression: "validation.isValid",
          },
        },
        [
          _vm._l(_vm.fields, function (field, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !field.hidden,
                    expression: "!field.hidden",
                  },
                ],
                key: index,
                staticClass: "supplier-commitments-form__grid-cell",
                attrs: {
                  id: `sc_${
                    _vm.editContext ? _vm.editContext._id : "new"
                  }_field_${index}`,
                },
              },
              [
                field.value
                  ? _c("div", [
                      _vm._v(
                        "\n                " +
                          _vm._s(field.value) +
                          "\n            "
                      ),
                    ])
                  : field.type && !field.expandedAreaComponent
                  ? _c(
                      _vm.getVuexComponent(field.type),
                      _vm._g(
                        _vm._b(
                          {
                            tag: "component",
                            attrs: {
                              "vuex-module": _vm.vuexModule,
                              namespace: _vm.computedNamespace,
                              context: _vm.context,
                              disabled: _vm.isDisabledField({
                                editable: _vm.isFieldEnabled(field),
                              }),
                            },
                          },
                          "component",
                          field,
                          false
                        ),
                        _vm.constructVBindEventObj({
                          events: _vm.getAttribute(field, "events"),
                          context: _vm.editContext,
                        })
                      )
                    )
                  : field.functionName
                  ? _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.callAction(field.functionName, _vm.editContext)
                          )
                        ),
                      ]),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.getAttribute(_vm.editContext, field.name))
                      ),
                    ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "supplier-commitments-form__grid-cell" },
            [
              _c("notes", {
                attrs: {
                  notes: _vm.notes,
                  disabled: !_vm.editContext,
                  "open-left": "",
                },
                on: {
                  delete: _vm.removeNote,
                  post: _vm.postNote,
                  save: _vm.saveNote,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "supplier-commitments-form__grid-cell" },
            [
              _c("main-expand-button", {
                attrs: { "is-expanded": true },
                on: { expand: _vm.toggleExpandedArea },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isPromotionSupplierFundingView
            ? _c(
                "div",
                { staticClass: "supplier-commitments-form__grid-cell" },
                [
                  _c("allocation-checkbox", {
                    attrs: {
                      "supplier-commitment": _vm.editContext,
                      "promotion-namespace": _vm.promotionNamespace,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "supplier-commitments-form__expanded-area" },
            _vm._l(_vm.expandedAreaFields, function (field) {
              return _c(
                "div",
                {
                  key: field.fieldName,
                  staticClass: "supplier-commitments-form__expanded-component",
                },
                [
                  _c(
                    _vm.getVuexComponent(field.type),
                    _vm._g(
                      _vm._b(
                        {
                          ref: field.ref,
                          refInFor: true,
                          tag: "component",
                          attrs: {
                            "vuex-module": _vm.vuexModule,
                            namespace: _vm.computedNamespace,
                            context: _vm.context,
                          },
                        },
                        "component",
                        field,
                        false
                      ),
                      _vm.constructVBindEventObj({
                        events: _vm.getAttribute(field, "events"),
                        context: _vm.editContext,
                      })
                    )
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }