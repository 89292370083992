import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variable-funding-viewer" },
    [
      _c("vuex-form-viewer", {
        ref: "formViewer",
        attrs: {
          context: _vm.variableFunding,
          fields: _vm.fields,
          "make-read-only": !_vm.isVariableFundingSelected,
          form: _vm.form,
        },
        on: { click: _vm.onEditClick },
      }),
      _vm._v(" "),
      _vm.isNewVariableFunding
        ? _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                VBtn,
                {
                  staticClass: "cancel-button",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "simple-button",
                {
                  staticClass: "save-button",
                  attrs: { disabled: _vm.isSaveNewVariableFundingDisabled },
                  on: { onClick: _vm.saveNewVariableFunding },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.save"))) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : [
            _vm.isExpanded
              ? _c(
                  "div",
                  {
                    staticClass: "variable-funding-viewer__icons",
                    style: _vm.getStyle(_vm.index, 12),
                  },
                  [
                    _c("main-expand-button", {
                      attrs: { "is-expanded": _vm.isExpanded },
                      on: { expand: _vm.onEditClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isExpanded
              ? _c(
                  "div",
                  {
                    staticClass: "variable-funding-viewer__icons",
                    style: _vm.getStyle(_vm.index, 13),
                  },
                  [
                    _c("common-delete-dialog", {
                      attrs: {
                        resource: _vm.variableFundingAgreementEnum,
                        "child-dependencies-warning": false,
                      },
                      on: {
                        delete: function ($event) {
                          return _vm.deleteVariableFundingAgreement({
                            id: _vm.variableFunding._id,
                          })
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }