var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "funding-tab",
    _vm._b(
      {
        attrs: {
          "form-ref": _vm.formRef,
          "is-parking-lot": _vm.isParkingLot,
          "is-past-promotions": _vm.isPastPromotions,
          "cache-dom": _vm.cacheDom,
        },
      },
      "funding-tab",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }