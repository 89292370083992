var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promo-metric" }, [
    _c("div", { staticClass: "promo-metric__title" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.title))) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "promo-metric__value" }, [
      _vm._v(
        "\n        " + _vm._s(_vm._f("totalSales")(_vm.kpi.value)) + "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "h4",
      { staticClass: "promo-metric__efficiency", class: _vm.efficiencyClass },
      [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.$n("numbers.default.percentageEvolution", _vm.kpi.efficiency)
            ) +
            "\n        "
        ),
        _c("i", { class: _vm.arrowIcon }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }