import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuex-number-input", class: _vm.cssClass },
    [
      _c(VTextField, {
        ref: "textField",
        class: `rtls-text-field rtls-text-field--${_vm.theme}`,
        attrs: {
          disabled: _vm.isDisabled,
          reverse: "",
          placeholder: _vm.placeholder,
          "hide-details": _vm.hideDetails,
          rules: _vm.rules,
          height: _vm.height,
          "validate-on-blur": "",
        },
        on: {
          keypress: (params) =>
            _vm.positiveOnly
              ? _vm.validatePositiveIntegerInput(params)
              : _vm.validateIntegerInput(params),
          paste: _vm.validateNumberOnPaste,
          change: _vm.updateModel,
        },
        model: {
          value: _vm.formattedNumber,
          callback: function ($$v) {
            _vm.formattedNumber = $$v
          },
          expression: "formattedNumber",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }