var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "campaign",
      class: _vm.cssClasses,
      style: _vm.gridCss,
      on: { click: _vm.onClick },
    },
    [
      _c("span", { staticClass: "campaign-name" }, [
        _vm._v(
          _vm._s(_vm.context.name) +
            " " +
            _vm._s(_vm.context.briefing ? "/" : "") +
            " " +
            _vm._s(_vm.context.briefing)
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("parent-child-chips", { attrs: { context: _vm.context || {} } }),
          _vm._v(" "),
          _vm.isLocked
            ? _c(
                "div",
                { staticClass: "lock-icon" },
                [_c("icon", { attrs: { "icon-name": "locked", small: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isPrivate
            ? _c(
                "div",
                { staticClass: "eye-icon" },
                [
                  _c("icon", {
                    attrs: { "icon-name": "eye-closed", small: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isReadOnly
            ? _c(
                "div",
                { staticClass: "icons" },
                [
                  _vm._l(_vm.icons, function (icon) {
                    return [
                      _c(
                        "feature-toggle",
                        {
                          key: icon.name,
                          attrs: { toggle: icon.featureToggle },
                        },
                        [
                          (
                            icon.component.showPropName
                              ? _vm.getComputed(icon.component.showPropName)
                              : true
                          )
                            ? _c(
                                icon.component.name,
                                _vm._g(
                                  _vm._b(
                                    { tag: "component" },
                                    "component",
                                    _vm.constructVBindObj({
                                      context: _vm.context,
                                      field: icon.field,
                                      ...icon.component,
                                    }),
                                    false
                                  ),
                                  _vm.constructVBindEventObj({
                                    events: _vm.getAttribute(
                                      icon.component,
                                      "events"
                                    ),
                                    context: _vm.context,
                                  })
                                )
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }