var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "losenge-monetary-display",
      class: _vm.$options.methods.getFlexClass(_vm.props.compact),
    },
    [
      _c(
        "div",
        {
          staticClass: "incremental-amount",
          class: _vm.$options.methods.getCompactIncrementalAmountClass(
            _vm.props.compact
          ),
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("totalSales")(_vm.props.incrementalAmount)) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.props.compact
        ? _c("div", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.parent.$t("planning.promotionsViewer.efficiency")
                  )
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.$options.methods.getCompactEfficiencyClass(
            _vm.props.compact
          ),
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.parent.$n(
                  "numbers.default.efficiencyRatio",
                  _vm.props.efficiency,
                  {
                    usePlaceholder: true,
                  }
                )
              ) +
              "\n    "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }