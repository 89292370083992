import { canEditChannel } from '@enums/feature-flags';
import FunctionProp from '@/js/utils/component-utils';

export default [
    {
        field: 'effectivenessRating',
        component: {
            name: 'promotion-rag-colour',
            contextualProps: {
                colour: 'effectivenessRating',
            },
        },
    },
    {
        field: 'date',
        component: {
            name: 'losenge-date-display',
            defaultProp: null,
            contextualProps: ['startDate', 'endDate'],
            props: {
                compact: true,
            },
        },
    },
    {
        field: 'name',
        contentClasses: [
            'truncate-text',
            'promotion-allocation-area__grid-cell--truncated-content',
        ],
        component: {
            name: 'truncate-text-tooltip',
            contextualProps: {
                text: 'name',
            },
        },
    },
    {
        field: 'offerMechanic.description',
        contentClasses: [
            'truncate-text',
            'promotion-allocation-area__grid-cell--truncated-content',
        ],
        component: {
            name: 'truncate-text-tooltip',
            contextualProps: {
                text: 'offerMechanic.description',
            },
        },
    },
    {
        functionName: 'getSlotSize',
    },
    {
        featureToggle: canEditChannel,
        contentClasses: ['promotion-allocation-area__grid-cell--button'],
        component: {
            name: 'simple-button',
            text: 'preparation.buttons.select',
            functionProps: [new FunctionProp('disabled', ['_id'], 'isPromotionDisabled')],
            events: {
                onClick: {
                    method: 'setPromotion',
                    params: ['_id'],
                },
            },
        },
    },
    {
        cellClasses: ['last-column'],
        component: {
            name: 'main-expand-button',
            props: {
                isExpanded: false,
            },
            contextualProps: {
                promotionId: '_id',
            },
            functionProps: [new FunctionProp('isExpanded', ['_id'], 'isSelected')],
        },
    },
    {
        field: 'incrementalSales',
        contentClasses: ['promotion-allocation-area__grid-cell--sidebar'],
        component: {
            name: 'losenge-monetary-display',
            defaultProp: null,
            props: {
                compact: true,
            },
            contextualProps: {
                incrementalAmount: 'forecastingAggregations.promotion.incrementalSalesExcTax',
                efficiency: 'forecastingAggregations.promotion.salesEfficiency',
            },
        },
    },
    {
        field: 'incrementalMargin',
        contentClasses: ['promotion-allocation-area__grid-cell--sidebar'],
        component: {
            name: 'losenge-monetary-display',
            defaultProp: null,
            props: {
                compact: true,
            },
            contextualProps: {
                incrementalAmount: 'forecastingAggregations.promotion.incrementalMargin',
                efficiency: 'forecastingAggregations.promotion.marginEfficiency',
            },
        },
    },
];
