<template>
    <v-btn primary @click="onSelectClicked()">
        {{ $t('actions.select') | toSentenceCase }}
    </v-btn>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    methods: {
        async onSelectClicked() {
            await this.params.onSelectClicked(this.params.data._id);
        },
    },
});
</script>
