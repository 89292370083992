import featureFlags from '@enums/feature-flags';
import store from '@/js/store';
import Reporting from './reporting';

const routes = [
    {
        path: '/reporting',
        name: 'reporting',
        id: 'reporting',
        featureToggl: 'reporting',
        component: Reporting,
        linkText: 'navBar.reporting',
        index: 4,
        meta: { canAccess: featureFlags.canAccessReporting, isSideBarCalendarExpanded: false },
        async beforeEnter(to, from, next) {
            await store.dispatch('bookmarks/fetchBookmarks', {
                params: {
                    sortBy: 'lastUpdatedOn',
                    sortDirection: 'ascending',
                },
            });

            next();
        },
        children: [
            {
                name: 'bookmark',
                path: ':bookmarkId',
            },
        ],
    },
];

export default routes;
