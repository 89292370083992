'use strict';

const { find, sumBy, map, includes } = require('lodash');

/**
 * Aggregates lump funding for executed and planned promotions.
 *
 * @param {Array} allocatedPromotions - A list of allocated promotions.
 * @param {Object} selectedSupplierCommitment - Supplier commitment.
 * @param {String} categoryLevel - category level.
 * @param {Object} moment - instatiated moment lib
 */
const getExecutedAndPlannedFunding = function({
    allocatedPromotions,
    selectedSupplierCommitment,
    categoryLevel,
    moment,
}) {
    const today = moment().utc();
    const { supplier, hierarchy } = selectedSupplierCommitment;
    const supplierCommitmentCategoryKeys = map(hierarchy, 'levelEntryKey');

    return allocatedPromotions.reduce(
        (acc, promotion) => {
            const products = promotion.products.filter(product => {
                const productCategoryKey = find(product.hierarchy, {
                    level: categoryLevel,
                }).levelEntryKey;
                return (
                    product.supplierKey === supplier.supplierKey &&
                    includes(supplierCommitmentCategoryKeys, productCategoryKey)
                );
            });

            /* Only sum the lump funding for products where there is a match on supplier and
            category with the supplier commitment. */
            const lumpFunding = sumBy(products, product =>
                sumBy(product.funding.lumpFunding, 'rateCardAmount')
            );

            if (
                moment(promotion.startDate)
                    .utc()
                    .isAfter(today)
            ) {
                acc.planned += lumpFunding;
            } else {
                acc.executed += lumpFunding;
            }
            return acc;
        },
        { executed: 0, planned: 0 }
    );
};

module.exports = {
    getExecutedAndPlannedFunding,
};
