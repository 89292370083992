import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotions-viewer" },
    [
      _c("div", { staticClass: "promotions-viewer__actions" }, [
        _c(
          "div",
          {
            staticClass:
              "promotions-viewer__filter-promotion promotions-viewer__filter-promotion--top promotions-viewer__create-promotion--left",
          },
          [
            _c("label", [_vm._v("Filter: ")]),
            _vm._v(" "),
            _c("vuex-text-field", {
              class: ["filter-promotion--promotion-name"],
              attrs: {
                getter: () => _vm.filter.name,
                setter: (value) => (_vm.filter.name = value),
                placeholder: _vm._f("toSentenceCase")(
                  _vm.$t("planning.promotionsViewer.promotionName")
                ),
                white: "",
                "save-whitespace": "",
                "ignore-parent-read-only": "",
                "update-on-input": "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.applyFilter()
                },
              },
            }),
            _vm._v(" "),
            _c(
              VIcon,
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.filter.name,
                    expression: "filter.name",
                  },
                ],
                attrs: { color: "primary" },
                on: { click: _vm.resetFilter },
              },
              [_vm._v("\n                mdi-close\n            ")]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                staticClass: "filter-btn",
                attrs: { depressed: "", outlined: "" },
                on: { click: _vm.applyFilter },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.apply"))) +
                    "\n            "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("pagination", {
              attrs: {
                length: _vm.paginationLength,
                value: _vm.paginationState.activePage + 1,
              },
              on: { input: _vm.paginationHandler },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "promotions-viewer__create-promotion promotions-viewer__create-promotion--top promotions-viewer__create-promotion--right",
          },
          [
            _c("create-promotion", {
              on: {
                promotionCreated: function ($event) {
                  return _vm.scrollToSelectedPromotion({ promotionId: $event })
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.numberPromotionsInView > 0
        ? _c(
            "div",
            { staticClass: "promotions-viewer__grid" },
            [
              _vm._l(_vm.fields, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "header" + index,
                    staticClass:
                      "promotions-viewer__grid-cell promotions-viewer__grid-cell--heading",
                    class: [item.cellClasses, item.headingClasses],
                  },
                  [
                    item.header.component
                      ? [
                          _c(
                            item.header.component,
                            _vm._b(
                              { tag: "component", class: item.header.classes },
                              "component",
                              item.header.props,
                              false
                            )
                          ),
                          _vm._v(" "),
                          _c("span", { class: item.header.textClasses }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(item.header.title)
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]
                      : item.sortable
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "promotions-viewer__grid-cell--sort",
                              class: _vm.getSortingOrder(item.sortableField)
                                ? "promotions-viewer__grid-cell--heading-sorted"
                                : "",
                              on: {
                                click: function ($event) {
                                  return _vm.sortPromotions(item.sortableField)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "promotions-viewer__grid-cell--heading__sortable",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t(item.header)
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("promotion-sorting", {
                                attrs: {
                                  order: _vm.getSortingOrder(
                                    item.sortableField
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "span",
                            {
                              staticClass:
                                "promotions-viewer__grid-cell--heading__unsorted",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toSentenceCase")(_vm.$t(item.header))
                                )
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm._l(
                _vm.promotionsInView,
                function (promotion, promotionIndex) {
                  return [
                    _c("promotion-viewer", {
                      key: "promotion::" + promotion._id,
                      ref: `promotion_${promotion._id}`,
                      refInFor: true,
                      attrs: {
                        promotion: _vm.getPromotionById(promotion._id),
                        index: promotionIndex,
                        "has-opened-promotions-above":
                          _vm.hasOpenedPromotionsAbove({
                            promotions: _vm.promotionsInView,
                            index: promotionIndex,
                          }),
                        scenario: _vm.getScenarioById({
                          _id: promotion.scenarioId,
                          usePluralResourceName: true,
                        }),
                      },
                      on: { "split-requested": _vm.closePromotion },
                    }),
                  ]
                }
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "promotions-viewer__sidebar-overlay elevation-2",
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promotions-viewer__actions" },
        [
          _c(
            "div",
            {
              staticClass:
                "promotions-viewer__create-promotion promotions-viewer__create-promotion--bottom",
            },
            [
              _vm.numberOfPromotionsForSelectedScenario >= 6
                ? _c("create-promotion")
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "feature-toggle",
            {
              attrs: { toggle: _vm.enableDownloadCentre },
              scopedSlots: _vm._u([
                {
                  key: "alternative",
                  fn: function () {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "export-btn",
                          attrs: {
                            disabled: _vm.exportBtnDisabled,
                            depressed: "",
                            outlined: "",
                          },
                          on: { click: _vm.onExport },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("actions.export")
                                )
                              ) +
                              "\n                    "
                          ),
                          _c("icon", {
                            attrs: {
                              "icon-name": "download",
                              right: "",
                              small: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("download-centre", {
                attrs: { "ignore-parent-read-only": true },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.ghostPromotions.length > 0
        ? [
            _c(VDivider, {
              staticClass: "promotions-viewer__ghost-divider",
            }),
            _vm._v(" "),
            _c("div", { staticClass: "promotions-viewer__ghost-header" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t(
                        "planning.promotionsViewer.excludedChildPromotions"
                      )
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pagination",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _c("pagination", {
                  attrs: {
                    length: _vm.ghostPaginationLength,
                    value: _vm.paginationState.activeGhostPage + 1,
                  },
                  on: { input: _vm.ghostPaginationHandler },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "promotions-viewer__grid" },
              [
                _vm._l(
                  _vm.ghostPromotionsInView,
                  function (promotion, promotionIndex) {
                    return [
                      _c("promotion-viewer", {
                        key: "ghost-promotion::" + promotion._id,
                        ref: `ghost-promotion_${promotion._id}`,
                        refInFor: true,
                        attrs: {
                          promotion: _vm.getPromotionById(promotion._id),
                          index: promotionIndex,
                          "has-opened-promotions-above":
                            _vm.hasOpenedPromotionsAbove({
                              promotions: _vm.ghostPromotions,
                              index: promotionIndex,
                            }),
                          scenario: _vm.getScenarioById({
                            _id: promotion.scenarioId,
                            usePluralResourceName: true,
                          }),
                        },
                      }),
                    ]
                  }
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "promotions-viewer__sidebar-overlay elevation-2",
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "promotions-viewer__actions promotions-viewer__actions__bottom",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.filter.isApplied,
                  expression: "filter.isApplied",
                },
              ],
              staticClass: "clear-filter",
            },
            [
              _c("strong", [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("planning.promotionsViewer.filterApplied")
                      )
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "clear-filter-btn",
                  on: { click: _vm.resetFilter },
                },
                [
                  _c(VIcon, { attrs: { color: "primary" } }, [
                    _vm._v("\n                    mdi-close\n                "),
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("actions.clearFilter")
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }