import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bookmarks-viewer" }, [
    _c(
      "div",
      { staticClass: "bookmarks-viewer__top bookmarks-grid" },
      _vm._l(_vm.bookmarkFields, function (field, index) {
        return _c(
          "div",
          {
            key: `bookmarkHeader::${index}`,
            class: ["bookmarks-viewer__header", field.headerClasses],
          },
          [
            field.header
              ? _c("div", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t(`bookmarks.headers.${field.header}`)
                        )
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bookmarks-viewer__bottom bookmarks-grid" },
      [
        _vm._l(_vm.bookmarks, function (bookmark) {
          return [
            _c(
              "vuex-form-viewer",
              {
                key: bookmark._id,
                attrs: {
                  context: bookmark,
                  fields: _vm.bookmarkFields,
                  form: _vm.form,
                },
              },
              [
                [
                  _c("div", { staticClass: "pl-3" }, [
                    _vm._v(_vm._s(bookmark.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pl-3" }, [
                    _vm._v(_vm._s(bookmark.description)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pl-3" }, [
                    _vm._v(_vm._s(_vm._f("dateShorthand")(bookmark.createdOn))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pl-3 author-container" },
                    [
                      _c(
                        VAvatar,
                        {
                          staticClass: "bookmarks-viewer__author-avatar",
                          attrs: { size: "21" },
                        },
                        [
                          _c("span", { staticClass: "white--text" }, [
                            _vm._v(_vm._s(_vm.userToInitials(bookmark.user))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pl-3" },
                    [
                      _c(VCheckbox, {
                        staticClass: "rtls-checkbox ma-0",
                        attrs: {
                          "hide-details": "",
                          "input-value": _vm.isBookmarkLoaded(bookmark._id),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.toggleBookmark(bookmark._id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pl-3" },
                    [
                      _c("copy-button", {
                        on: {
                          copy: function ($event) {
                            return _vm.copyBookmarkUrl(bookmark._id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      key: `${bookmark._id}_delete`,
                      staticClass: "bookmark-cards-viewer__icons pl-3",
                    },
                    [
                      _c("common-delete-dialog", {
                        attrs: {
                          resource: _vm.bookmarksEnum,
                          "child-dependencies-warning": false,
                          "ignore-parent-read-only": false,
                        },
                        on: {
                          delete: function ($event) {
                            return _vm.handleDelete({ id: bookmark._id })
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { key: `${bookmark._id}_filler`, staticClass: "filler" }),
          ]
        }),
        _vm._v(" "),
        _vm.isNewBookmark
          ? _c(
              "vuex-form-viewer",
              {
                ref: "newFormViewer",
                attrs: { context: {}, fields: [], form: _vm.getFullForm() },
              },
              [
                _c("div", { staticClass: "bookmarks-viewer__icons" }),
                _vm._v(" "),
                _c("div", { staticClass: "bookmarks-viewer__icons" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bookmarks-viewer__buttons" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel-button",
                        attrs: { text: "" },
                        on: { click: _vm.cancelNewBookmark },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "simple-button",
                      {
                        attrs: { disabled: _vm.isSaveNewBookmarkDisabled },
                        on: {
                          onClick: function ($event) {
                            return _vm.saveBookmark()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.save"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "bookmarks-viewer__new-bookmark" },
      [
        _c("create-new-button", {
          attrs: {
            "btn-text": _vm._f("toSentenceCase")(
              _vm.$tkey("newBookmarkButton")
            ),
            disabled: _vm.isNewBookmark,
          },
          on: { createNew: _vm.addNewBookmark, click: _vm.addNewBookmark },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }