// Utility function to load all .js routes files inside a folder and return an array combining all those routes
// that can be passed as the routes argument to VueRouter
// Example after vue-plugins is installed:
//    const routes = Vue.loadRoutes(require.context("routes/", true, /\.js$/));
//    const router = new VueRouter({
//      routes: routes,
//      linkActiveClass: 'active'
//    });

export default function loadRoutes(Vue, req) {
    let routes = [];

    // req is the webpack require function
    // so require each of these files and include their default export into the routes array
    // (each file returns an array with the routes they add)
    const routeFiles = req.keys();
    routeFiles.forEach(jsFile => {
        let thisFileRoutes = req(jsFile);
        if (thisFileRoutes.default) thisFileRoutes = thisFileRoutes.default; // handle ES6 syntax in view models that use export default { ...viewModeldeclaration... }

        routes = routes.concat(thisFileRoutes);
    });

    return routes;
}
