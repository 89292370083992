import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(
        _vm.model.tiers[_vm.tierIndex].additionalRewards,
        function (additionalReward, index) {
          return _c(
            "div",
            { key: index + additionalReward.type },
            [
              _c("requirement-and-rewards-details", {
                attrs: {
                  "vuex-module": _vm.vuexModule,
                  "field-path": `offerMechanic.tiers[${_vm.tierIndex}].additionalRewards`,
                  "field-name": `[${index}]`,
                  "entity-index": index,
                  namespace: _vm.namespace,
                  "tier-index": _vm.tierIndex,
                  options: _vm.additionalRewardOptions,
                  clearable: _vm.clearable,
                  "amount-validators": _vm.amountValidators,
                  disabled: _vm.disabled,
                },
                on: {
                  delete: _vm.onDeleteAdditionalReward,
                  change: _vm.onChangeAdditionalReward,
                },
              }),
              _vm._v(" "),
              _c(VDivider, { staticClass: "horizontal-divider" }),
            ],
            1
          )
        }
      ),
      _vm._v(" "),
      _c("create-new-button", {
        staticClass: "add-additional-reward-btn",
        attrs: {
          background: "",
          disabled: _vm.disabled || _vm.isAdditionalRewardsEmpty,
        },
        on: { createNew: _vm.onAddAdditionalReward },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }