<template functional>
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Draft</title>
        <g id="Icons-/-Circle-half" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Draft">
                <circle id="Combined-Shape" stroke="#8BA4B7" cx="9" cy="9" r="8.5" />
                <path
                    id="Combined-Shape"
                    d="M9,0 L9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 L9,0 Z"
                    fill="#8BA4B7"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CircleHalf',
};
</script>
