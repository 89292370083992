<template>
    <v-dialog v-model="dialog" :persistent="persistent" :hide-overlay="hideOverlay">
        <template v-if="hasActivator" v-slot:activator="{ on }">
            <slot :activator="on" name="actions">
                <v-btn
                    primary
                    dark
                    small
                    depressed
                    :class="{ 'disabled-activator': disabledActivator }"
                    v-on="on"
                    @click="$emit('open')"
                >
                    {{ $t(buttonText) | toSentenceCase }}
                </v-btn>
            </slot>
        </template>
        <v-card
            class="dialog-card"
            tile
            :class="{
                'dialog-card--error': isErrorDialog,
                'dialog-card--warning': !isErrorDialog && isWarningDialog,
            }"
        >
            <header class="dialog-card__header">
                <slot name="inheritance-badges" />
                <h1 class="dialog-card__title">
                    {{ dialogHeader }}
                </h1>
                <v-btn icon @click="closeDialog">
                    <icon icon-name="cancel" small icon-colour-type="primary-path" />
                </v-btn>
            </header>
            <alert-banner
                v-if="isReadOnly && propagatedReadOnlyReason"
                :reason="propagatedReadOnlyReason"
                class="ml-0"
            />
            <slot :validation="validation" />

            <feature-toggle :toggle="canSaveChangesToggle">
                <footer v-if="showFooter" class="dialog-card__footer">
                    <div class="buttons__right">
                        <span v-if="hasMandatoryFields" class="dialog-card__mandatory">
                            <span class="asterisk">*</span>
                            {{ $t('planning.mandatory') | toSentenceCase }}
                        </span>
                        <v-btn
                            v-if="showNegativeActionButton"
                            text
                            secondary
                            :data-id-e2e="btnCancelId"
                            @click="closeDialog"
                        >
                            {{
                                (showPositiveActionButton ? $t(btnCancelText) : $t(btnCloseText))
                                    | toSentenceCase
                            }}
                        </v-btn>
                        <slot name="positiveActionButton">
                            <positive-action-button
                                v-if="showPositiveActionButton"
                                :disabled="positiveActionBtnDisabled"
                                :ignore-parent-read-only="overrideSaveBtnReadOnly"
                                :data-id-e2e="btnSubmitId"
                                @onClick="$emit('confirm')"
                            >
                                {{ $t(btnConfirmText) | toSentenceCase }}
                            </positive-action-button>
                        </slot>
                    </div>
                </footer>
                <template v-slot:alternative>
                    <footer class="dialog-card__footer">
                        <v-btn
                            v-if="showNegativeActionButton"
                            text
                            secondary
                            :data-id-e2e="btnCancelId"
                            @click="closeDialog"
                        >
                            {{ $t(btnCloseText) | toSentenceCase }}
                        </v-btn>
                    </footer>
                </template>
            </feature-toggle>
        </v-card>
    </v-dialog>
</template>

<script>
import featureFlags from '@enums/feature-flags';
import { toSentenceCase } from '../utils/string-utils';

export default {
    props: {
        btnSubmitId: {
            type: String,
            default: 'btnSubmitId',
        },
        btnCancelId: {
            type: String,
            default: 'btnCancelId',
        },
        preventDefault: Boolean,
        buttonText: String,
        heading: String,
        btnConfirmText: {
            type: String,
            default: 'actions.save',
        },
        btnCloseText: {
            type: String,
            default: 'actions.close',
        },
        btnCancelText: {
            type: String,
            default: 'actions.cancel',
        },
        hasMandatoryFields: {
            type: Boolean,
            default: false,
        },
        canSaveChangesToggle: {
            type: String,
            default: featureFlags.noRestriction,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        confirmBtnDisabled: {
            type: Boolean,
            default: false,
        },
        hasActivator: {
            type: Boolean,
            default: true,
        },
        showPositiveActionButton: {
            type: Boolean,
            default: true,
        },
        showNegativeActionButton: {
            type: Boolean,
            default: true,
        },
        translatedHeader: String,
        persistent: {
            type: Boolean,
            default: true,
        },
        isErrorDialog: {
            type: Boolean,
            default: false,
        },
        isWarningDialog: {
            type: Boolean,
            default: false,
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },
        disabledActivator: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: Object,
            default: () => ({
                isValid: true,
                formId: Symbol('formId'),
            }),
        },
        overrideSaveBtnReadOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        dialog: false,
    }),

    computed: {
        dialogHeader() {
            const translatedHeader = this.translatedHeader || this.$t(this.heading);
            return toSentenceCase(translatedHeader);
        },
        positiveActionBtnDisabled() {
            return !this.validation.isValid || this.confirmBtnDisabled;
        },
    },

    watch: {
        // reset form state and validation on open
        dialog(value) {
            if (value) {
                this.globalEmit('reset-dialog-form', this.validation.formId);
            }
        },
    },

    methods: {
        close() {
            this.dialog = false;
        },
        open() {
            this.dialog = true;
            this.$emit('open');
        },
        closeDialog() {
            this.$emit('close');

            if (!this.preventDefault) {
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.disabled-activator {
    opacity: 0.1;
    pointer-events: none;
}

.dialog-card {
    border-left: 0.4rem solid $promo-primary-colour;
    border-radius: 0;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    min-width: 40rem;

    overflow: hidden;

    height: 100%;
    display: flex;
    flex-direction: column;

    &--error {
        border-left: 0.4rem solid $promo-error;
    }

    &--warning {
        border-left: 0.4rem solid $promo-orange-dark;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 4rem;
        min-height: 4rem;
        border-bottom: 1px solid $promo-divider-colour;
    }

    &__title {
        font-family: 'Source Sans Pro';
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 600;
        letter-spacing: 0;
        color: $promo-text-colour;
    }

    &__icon {
        color: $promo-primary-colour !important;
        fill: currentColor;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-height: 80vh;
        overflow: auto;
    }

    &__footer {
        padding: 1rem;
        align-items: center;
        height: 5.5rem;
        min-height: 5.5rem;
        border-top: 1px solid $promo-divider-colour;

        // apply margin to grandchildren to account for wrapper div
        & > * {
            & > * {
                margin-left: 1rem;
            }
        }
    }

    &__mandatory {
        margin-right: auto;
        font-size: 1.2rem;
        line-height: 1.6rem;

        .asterisk {
            margin-right: 0.3rem;
            font-size: 1.8rem;
            line-height: 2rem;
        }
    }

    .buttons {
        width: 100%;
        text-align: right;
        padding: 2rem;
        &__right {
            float: right;
        }
    }
}
</style>
