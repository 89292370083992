var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-permissions-grid-container" },
    [
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 100%;",
          "dom-layout": "normal",
        },
      }),
      _vm._v(" "),
      _c("checkbox-list-dialog", {
        ref: "hierarchy-dialog",
        attrs: { user: _vm.selectedUser },
      }),
      _vm._v(" "),
      _c("store-group-attributes-dialog", {
        ref: "store-group-dialog",
        attrs: { user: _vm.selectedUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }