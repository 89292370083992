<template functional>
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <title>Icon / Add</title>
        <desc>Created with Sketch.</desc>
        <g id="Icon-/-Add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect id="Colour" fill="#2F477C" x="0" y="0" width="16" height="16" rx="2.7" />
            <path
                id="Combined-Shape"
                d="M8.88888889,3.55555556 L8.88855556,7.11055556 L12.4444444,7.11111111 L12.4444444,8.88888889 L8.88855556,8.88855556 L8.88888889,12.4444444 L7.11111111,12.4444444 L7.11055556,8.88855556 L3.55555556,8.88888889 L3.55555556,7.11111111 L7.11055556,7.11055556 L7.11111111,3.55555556 L8.88888889,3.55555556 Z"
                fill="#FFFFFF"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Add',
};
</script>
