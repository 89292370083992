import { render, staticRenderFns } from "./parent-child-chips.vue?vue&type=template&id=ab971ed0&scoped=true"
import script from "./parent-child-chips.vue?vue&type=script&lang=js"
export * from "./parent-child-chips.vue?vue&type=script&lang=js"
import style0 from "./parent-child-chips.vue?vue&type=style&index=0&id=ab971ed0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab971ed0",
  null
  
)

export default component.exports