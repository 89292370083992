<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { hiddenParkingLotColumns, canDeleteParkingLotPromotion } from '@enums/feature-flags';
import promoResourcesEnum from '@enums/promo-resources';
import { ascending } from '@enums/sort-direction';
import { parkingLot } from '@enums/resources';
import UXEvents from '@enums/ux-events';
import {
    isCategoryOrStoreWidePromotion,
    isStoreWidePromotion,
} from '@sharedModules/promotion-utils';
import { uniq, get, values, uniqWith, isEqual, flatten, map } from 'lodash';
import promoAgIconHeader from '@/js/components/promo-ag-grid/ag-icon-header';
import promoAgHeaderWithSpinner from '@/js/components/promo-ag-grid/ag-header-with-spinner';
import { toSentenceCase } from '@/js/utils/string-utils';
import editIconRenderer from '../components/edit-icon-renderer';
import promotionRagСolourRenderer from '../components/promotion-rag-colour-renderer';
import deleteIconRenderer from '../components/delete-icon-renderer';
import parkingLotStatusRenderer from '../components/parking-lot-status-renderer';
import agGridUtils from '@/js/utils/ag-grid-utils';
import AgTextWithTooltip from '@/js/components/promo-ag-grid/ag-text-with-tooltip';

export default {
    localizationKey: 'parkingLot',
    data() {
        return {
            // The actual value of promotionToggle is not important,
            // changing it on dialog close is just used to force the component
            // to be recreated by referencing the toggle in the key for the dialog.
            promotionToggle: true,
            selectedPromotion: {},
            selectedPromotionIds: [],
            rowData: [],
            promotionsInGrid: new Set(),
            defaultColDef: {
                editable: false, // read only for now, make-read-only on component too
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                flex: 1,
                resizable: true,
                filter: false,
                minWidth: 80,
                headerComponentParams: {},
                menuTabs: [],
            },
            gridOptions: {
                onSelectionChanged: this.onSelectionChanged,
                rowHeight: 35, // Specified in pixels.
                frameworkComponents: {
                    promoAgIconHeader,
                    editIconRenderer,
                    deleteIconRenderer,
                    parkingLotStatusRenderer,
                    promoAgHeaderWithSpinner,
                    promotionRagСolourRenderer,
                },
                columnTypes: {
                    numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
                    filterEnabled: {
                        filter: true,
                        menuTabs: ['filterMenuTab'],
                    },
                },
                rowSelection: 'multiple',
                suppressRowClickSelection: true,
                statusBar: {
                    statusPanels: [
                        { statusPanel: 'agSelectedRowCountComponent', align: 'right' },
                        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'center' },
                    ],
                },
                getRowNodeId: data => data._id,
                overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${toSentenceCase(
                    this.$t('actions.loading')
                )}</span>`,
                overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">${toSentenceCase(
                    this.$tkey('grid.noRows')
                )}</span>`,
            },
            identifierColumns: [],
            detailColumns: [],
            limit: 0, // 0 means no limit.
            rowCount: 0,
            editIsDisabled: false,
            splitPromotion: false,
            promotionName: '',
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'hierarchyConfig', 'releaseFlags']),
        ...mapState('promotions', ['promotions', 'loading']),
        ...mapGetters('weeks', ['getWeekByDate']),
        ...mapGetters('promotions', ['getPromotionById']),

        // we have an issue with horizontal scroll for ag-grid
        // looks like it is bug in ag-grid implementation as scroll appears
        // without changes in width of container after any manipulating with components
        // resolve it helps api.refreshCells() but we can see scroll blinking
        // for Windows overflow-x: hidden also helps but it doesn't work on mac
        // for this reason this method checks OS and provide needed class
        overflowClass() {
            if (navigator.userAgent && navigator.userAgent.indexOf('Win') !== -1) {
                return 'overflow-win';
            }

            return 'overflow-default';
        },
        fieldsToPick() {
            const identifierColumns = this.identifierColumns.flatMap(column => column.children);
            const detailColumns = this.detailColumns.flatMap(column => column.children);

            const fieldsToPick = uniq(
                flatten([
                    ...identifierColumns.map(
                        column => column.sourceFields || column.sourceField || column.field
                    ),
                    ...detailColumns.map(column => column.field),
                    'startDate',
                    'endDate',
                    'isInParkingLot',
                    'tags',
                    'productOfferGroups',
                    'notification',
                    'splitPromotion',
                    'splitInProgress',
                ])
            );

            return fieldsToPick.map(field => `{"${field}": 1}`);
        },
    },

    mounted() {
        this.refetchPromotions();
        this.$root.$on(UXEvents.parkingLotSaved, this.onParkingLotSaved);
    },

    beforeDestroy() {
        this.$root.$off(UXEvents.parkingLotSaved, this.onParkingLotSaved);
    },

    methods: {
        ...mapActions('promotions', [
            'fetchPromotionById',
            'fetchPromotions',
            'fetchPromotionsStreamed',
            'resetPromotions',
        ]),
        ...mapMutations('promotions', ['setLoading', 'setSelectedPromotionId']),

        transformRowData(promotions = []) {
            return (promotions || []).map(p => {
                p.category = this.getHierarchyDescriptions(p, 'category');
                p.supplier = this.getSupplier(p);
                p.brand = this.getBrand(p);
                p.storeGroupDescriptions = (p.storeGroups || []).map(sg => sg.description);
                p.week = { startDate: p.startDate, endDate: p.endDate };
                return p;
            });
        },

        onParkingLotSaved(promotion) {
            // do not insert created/updated promotions on the past promotions tab
            if (this.isPastPromotions) {
                return;
            }
            // This event is fired when a promotion is saved in the parking lot. It'll occur for new promotions or
            // after updating an existing promotion. We need to identify which kind this is so that we can pass
            // the correct changeset to ag-grid.
            const changes = {};
            const transformedPromotions = this.transformRowData([promotion]);

            // If promotion already exists in the grid, update it, otherwise add it as a new item
            if (this.promotionsInGrid.has(promotion._id)) {
                changes.updatedItems = transformedPromotions;
            } else {
                changes.newItems = transformedPromotions;
            }
            this.upsertGridData(changes);
        },

        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.showLoadingOverlay();
            this.columnApi = params.columnApi;
        },

        upsertGridData({ newItems = [], removedItems = [], updatedItems = [], callbackFunction }) {
            if (this.gridApi) {
                // Using applyTransactionAsync allows the grid to be used while the transactions are being applied.
                // This means users can select other checkboxes while products updates are being applied to the grid
                // which can take a long time.
                this.gridApi.applyTransactionAsync(
                    {
                        add: newItems,
                        remove: removedItems,
                        update: updatedItems,
                    },
                    callbackFunction
                );

                newItems.forEach(({ _id }) => this.promotionsInGrid.add(_id));
                removedItems.forEach(({ _id }) => this.promotionsInGrid.delete(_id));
                this.rowCount = this.promotionsInGrid.size;
            }
        },
        getCellFormatter(format = 'number') {
            return params =>
                this.$n(`numbers.default.${format}`, params.value, {
                    usePlaceholder: true,
                });
        },
        getHierarchyDescriptions(promotion, level) {
            const levelEntries = [];
            (promotion.products || []).forEach(product => {
                const levelEntry = product.hierarchy.find(
                    h => h.level === this.hierarchyConfig[`${level}Level`]
                );
                // ignore product that doesn't have any hierarchy level
                if (levelEntry) {
                    levelEntries.push(levelEntry.levelEntryDescription);
                }
            });
            // unique descriptions
            const uniqCategories = uniq(levelEntries);
            return uniqCategories;
        },

        getSupplier(promotion) {
            return this.getProductAttributes({
                promotion,
                key: 'supplierKey',
                value: 'supplierName',
            });
        },

        getBrand(promotion) {
            return this.getProductAttributes({
                promotion,
                key: 'brandKey',
                value: 'brandDescription',
            });
        },

        getProductAttributes({ promotion, key, value }) {
            const attributes = [];
            (promotion.products || []).forEach(product => {
                attributes.push({
                    key: product[key],
                    value: product[value],
                });
            });
            const uniqAttributes = uniqWith(attributes, isEqual);
            return map(uniqAttributes, 'value');
        },
        async openDialog(event, promotion = {}) {
            this.editIsDisabled = true;
            this.selectedPromotion = promotion;
            const id = this.selectedPromotion._id || null;
            if (id) {
                await this.fetchPromotionById({ id });
                this.selectedPromotion = this.getPromotionById(id);
            }
            this.setSelectedPromotionId(id);
            this.$nextTick(() => {
                // Need to do use nextTick because selectedPromotion is updated
                // and dialog key depends on selectedPromotion._id
                this.$refs['promotion-viewer-dialog'].openDialog();
                this.editIsDisabled = false;
            });
        },

        async refetchPromotions() {
            if (this.loading) return;

            this.setLoading(true);
            this.resetPromotions();

            if (this.gridApi) {
                this.gridApi.setRowData([]);
                this.promotionsInGrid = new Set();
                this.gridApi.showLoadingOverlay();
                this.rowCount = 0;
            }
            let baseFilter = {};
            if (this.isPastPromotions) {
                baseFilter = {
                    $or: [{ actuals: { $ne: null } }, { clientState: 'approved' }],
                };
            }

            this.fetchPromotionsStreamed({
                params: {
                    where: JSON.stringify({ ...this.where, ...baseFilter } || baseFilter),
                    pick: JSON.stringify(this.fieldsToPick),
                    sortBy: 'startDate',
                    sortDirection: ascending,
                    limit: this.limit,
                },
                callbackFunction: this.promotionStreamedCallback,
            });
        },

        removePromotion(promotionId) {
            this.upsertGridData({ removedItems: [{ _id: promotionId }] });
        },

        promotionStreamedCallback(newPromotions) {
            this.upsertGridData({ newItems: this.transformRowData(newPromotions) });
        },

        onSelectionChanged() {
            this.selectedPromotionIds = this.gridOptions.api
                .getSelectedRows()
                .map(promotion => promotion._id);
        },

        openAllocationDialog() {
            this.$refs['promotion-allocation-dialog'].openDialog();
        },

        getIdentifierColumns() {
            return [
                {
                    headerGroupComponent: 'promoAgHeaderWithSpinner',
                    headerGroupComponentParams: {
                        headerTitle: toSentenceCase(this.$tkey('gridHeadings.promotions')),
                        loadingMessage: toSentenceCase(this.$t('actions.loading')),
                        vuexModule: 'promotions',
                        dataLimitReachedMessage: toSentenceCase(this.$tkey('dataLimitReached')),
                        isDataLimitReached: this.isDataLimitReached,
                    },
                    children: [
                        // effectivenessRating span
                        {
                            maxWidth: 30,
                            headerName: '',
                            cellRenderer: 'promotionRagСolourRenderer',
                            field: 'effectivenessRating',
                        },
                        // week span
                        {
                            maxWidth: 85,
                            headerName: toSentenceCase(this.$tkey('gridHeadings.weeks')),
                            field: 'week',
                            cellRendererFramework: AgTextWithTooltip,
                            valueGetter: ({ data }) => data.week,
                            valueFormatter: ({ value }) => {
                                const yearLabel = this.getYearLabel(value.startDate, value.endDate);
                                const weekLabel = this.getWeekLabel(value.startDate, value.endDate);
                                return `${yearLabel}-${weekLabel}`;
                            },
                            comparator: (valueA, valueB) => {
                                let dateA = valueA.startDate;
                                let dateB = valueB.startDate;

                                // if the start dates are the same, we need to compare end dates instead.
                                if (dateA === dateB) {
                                    dateA = valueA.endDate;
                                    dateB = valueB.endDate;

                                    // // if the one of the dates is missing, then the promotion
                                    // // only spans a single week, so it goes first.
                                    if (!dateA && dateB) return 1;
                                    if (dateA && !dateB) return -1;

                                    // If the new dates both match, then return 0 as the promotions,
                                    // run for the same time.
                                    if (dateA === dateB) return 0;
                                }

                                return dateA > dateB ? 1 : -1;
                            },
                        },
                        // Category (using the config / hierarchy level)
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.category')),
                            field: 'category',
                            sourceField: 'products.hierarchy',
                            type: 'filterEnabled',
                            valueFormatter: ({ value, data }) => {
                                if (value.length === 1) {
                                    return value[0];
                                }
                                return isStoreWidePromotion(data)
                                    ? this.$tkey('all')
                                    : this.$tkey('multiple');
                            },
                        },
                        // Supplier
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.supplier')),
                            field: 'supplier',
                            sourceFields: ['products.supplierKey', 'products.supplierName'],
                            type: 'filterEnabled',
                            valueFormatter: ({ value, data }) => {
                                if (value.length === 1) {
                                    return value[0];
                                }
                                return isStoreWidePromotion(data)
                                    ? this.$tkey('all')
                                    : this.$tkey('multiple');
                            },
                        },
                        // Brand
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.brand')),
                            field: 'brand',
                            sourceFields: ['products.brandKey', 'products.brandDescription'],
                            type: 'filterEnabled',
                            valueFormatter: ({ value, data }) => {
                                if (value.length === 1) {
                                    return value[0];
                                }
                                return isStoreWidePromotion(data)
                                    ? this.$tkey('all')
                                    : this.$tkey('multiple');
                            },
                        },

                        // Promo Name
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.name')),
                            field: 'name',
                            minWidth: 150,
                            type: 'filterEnabled',
                        },

                        // Mechanic
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.mechanic')),
                            field: 'offerMechanic.description',
                            minWidth: 150,
                            type: 'filterEnabled',
                        },

                        // Number of SKUs in the promo
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.SKUs')),
                            field: 'products.length',
                            valueFormatter: ({ value, data }) => {
                                if (value === 0 && isCategoryOrStoreWidePromotion(data)) {
                                    return '';
                                }
                            },
                            maxWidth: 80,
                            type: ['numericColumnCustom', 'filterEnabled'],
                            filter: 'agNumberColumnFilter',
                        },

                        // Store groups - comma separated list of store groups
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.storeGroups')),
                            field: 'storeGroupDescriptions',
                            sourceField: 'storeGroups',
                            type: 'filterEnabled',
                            valueFormatter: ({ value }) =>
                                value.length === 1 ? value[0] : this.$tkey('multiple'),
                        },
                    ].filter(
                        coldef =>
                            !get(this.toggleLogic, hiddenParkingLotColumns, []).includes(
                                coldef.field
                            )
                    ),
                },

                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.channels')),
                    headerClass: 'ag-left-border-light',
                    children: [
                        // Channels (config driven) with a check mark in the box of the channel where the promotion applies.
                        ...values(promoResourcesEnum).map(resource => {
                            return {
                                minWidth: 50,
                                maxWidth: 54,
                                field: 'resources',
                                headerComponent: 'promoAgIconHeader',
                                headerComponentParams: { iconComponent: resource },
                                sorting: false,
                                headerClass: 'ag-left-border-light',
                                cellClass: ['ag-left-border-light', 'ag-checkbox-center'],
                                cellRenderer: params => {
                                    const checked = (params.value || []).some(
                                        r => r.type === promoResourcesEnum[resource]
                                    );
                                    return `<input type="checkbox" disabled ${
                                        checked ? 'checked' : ''
                                    } />`;
                                },
                            };
                        }),
                    ].filter(
                        coldef =>
                            !get(this.toggleLogic, hiddenParkingLotColumns, []).includes(
                                coldef.field
                            )
                    ),
                },
            ];
        },

        getActionColumns({ isPastPromotions = false }) {
            return [
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.actions')),
                    headerClass: 'ag-left-border-dark',
                    children: [
                        {
                            minWidth: 44,
                            maxWidth: 60,
                            sorting: false,
                            headerComponent: 'promoAgIconHeader',
                            headerComponentParams: {
                                iconComponent: isPastPromotions ? 'eye-open' : 'edit',
                                unsortable: true,
                            },
                            headerClass: 'ag-left-border-dark',
                            cellClass: ['ag-left-border-dark', 'center-cell-items'],
                            cellRenderer: 'editIconRenderer',
                            cellRendererParams: {
                                icon: isPastPromotions ? 'eye-open' : 'edit',
                                onClick: params => {
                                    this.openDialog(null, params);
                                },
                                isDisabled: data =>
                                    (!isPastPromotions && !data.isInParkingLot) ||
                                    this.editIsDisabled,
                            },
                        },
                        ...(!isPastPromotions
                            ? [
                                  {
                                      minWidth: 44,
                                      maxWidth: 60,
                                      sorting: false,
                                      headerComponent: 'promoAgIconHeader',
                                      headerComponentParams: {
                                          iconComponent: 'trash',
                                          unsortable: true,
                                      },
                                      cellClass: ['center-cell-items'],
                                      cellRenderer: 'deleteIconRenderer',
                                      cellRendererParams: {
                                          isDisabled: data =>
                                              !data.isInParkingLot ||
                                              !this.toggleLogic[canDeleteParkingLotPromotion],
                                          resource: parkingLot,
                                          resourceId: '_id',
                                          successCallback: this.removePromotion,
                                      },
                                  },
                              ]
                            : []),
                    ],
                },
            ];
        },

        getDetailsColumns({ columnGroups = [], useDefinedFieldSource = true }) {
            return columnGroups.map(group => {
                return {
                    headerName: group.header
                        ? toSentenceCase(this.$tkey(`gridHeadings.${group.header}`))
                        : undefined,
                    headerClass: group.header ? 'ag-left-border-dark' : '',
                    children:
                        // Forecast columns:
                        // Promotional sales, promotional margin rate, Incremental sales and margin
                        group.children
                            .filter(field =>
                                get(this.releaseFlags, ['releaseFlags', field.releaseFlag], true)
                            )
                            .map((field, i) => {
                                // Apply the formatter based on the config. If a format is not specified,
                                // default to formatting as a number.
                                const valueFormatter = this.getCellFormatter(field.format);

                                return {
                                    headerName: toSentenceCase(
                                        this.$tkey(
                                            `gridHeadings.${field.header || field.fieldName}`
                                        )
                                    ),
                                    field: useDefinedFieldSource
                                        ? `${field.source}.promotion.${field.fieldName}`
                                        : field.header || field.fieldName,
                                    type: 'numericColumnCustom',
                                    aggFunc: field.aggFunc,
                                    valueFormatter,
                                    sortable: true,
                                    hide: field.hide,
                                    headerClass: i === 0 ? 'ag-left-border-dark' : '',
                                    minWidth: 100,
                                    maxWidth: 100,
                                    cellClass: params => {
                                        // Apply left border to first column
                                        const borderClass = i === 0 ? 'ag-left-border-dark' : '';

                                        if (!field.overrideField) return borderClass;

                                        // If override field is specified, grey out the current,
                                        // field if the override has a value.
                                        // Need to also check aggData for group rows.
                                        const isOverwritten =
                                            get(params.data, field.overrideField) ||
                                            get(params.node.aggData, field.overrideField);

                                        return isOverwritten
                                            ? `${borderClass} disabled-number`
                                            : borderClass;
                                    },
                                };
                            }),
                };
            });
        },

        getWeekLabel(startDate, endDate) {
            const { weekOfYear: startWeek } = this.getWeekByDate(startDate);
            const { weekOfYear: endWeek } = this.getWeekByDate(endDate);
            return startWeek === endWeek
                ? `${this.$t('general.dates.weekFirstLetter')}${startWeek}`
                : `${this.$t('general.dates.weekFirstLetter')}${startWeek}-${this.$t(
                      'general.dates.weekFirstLetter'
                  )}${endWeek}`;
        },

        getYearLabel(startDate, endDate) {
            const startYear = this.$moment(startDate).year();
            const endYear = this.$moment(endDate).year();
            return startYear === endYear ? startYear : `${startYear}-${endYear}`;
        },

        isDataLimitReached() {
            return this.limit && this.rowCount >= this.limit;
        },

        setSplitPromotion(value) {
            this.splitPromotion = value;
        },

        setPromotionName(value) {
            this.promotionName = value;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.parking-lot-header {
    width: 100%;
    background-color: $promo-white;
    padding: 2rem 0;
}

.parkinglot-grid-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &__download-btn {
        position: absolute;
        left: 46rem;
        font-size: 1.2rem;
    }
}

::v-deep .promo-tag {
    border-radius: 12px;
    padding: 4px 8px;
    align-items: center;
    background-color: lightgrey;
}

::v-deep .last-header .ag-header-cell-text {
    padding-left: 1rem !important;
}

::v-deep .ag-column-drop-wrapper {
    margin-bottom: 1rem;
}

.allocation-row {
    background-color: $promo-white;
    margin-left: 0px !important;
    width: 100%;
}

.overflow-win {
    ::v-deep {
        .ag-center-cols-viewport {
            overflow-x: hidden;
        }
    }
}

.overflow-default {
    ::v-deep {
        .ag-center-cols-viewport {
            overflow-x: auto;
        }
    }
}

::v-deep {
    .center-cell-items {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
    }

    .ag-status-bar-right {
        margin-right: 20rem;
    }
    .status-bar__action {
        &--right {
            position: absolute;
            bottom: 1rem;
            border-top: none;
            right: 2rem;
        }

        &--left {
            height: 2rem;
            position: absolute;
            bottom: 1rem;
            border-top: none;
            left: 2rem;
        }
    }

    .promotion-rag-colour {
        margin-top: 1rem !important;
    }
}
</style>
