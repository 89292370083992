import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VBtn,
    {
      attrs: { primary: "" },
      on: {
        click: function ($event) {
          return _vm.onSelectClicked()
        },
      },
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.select"))) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }