var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "20px",
        height: "19px",
        viewBox: "0 0 20 19",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Empty star")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Planner",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icon-/-Empty-star",
                transform: "translate(0.000000, -1.000000)",
                fill: "#2F477C",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "Star",
                  d: "M10,1.25 L12.9389263,7.20491503 L19.5105652,8.15983006 L14.7552826,12.795085 L15.8778525,19.3401699 L10,16.25 L4.12214748,19.3401699 L5.24471742,12.795085 L0.489434837,8.15983006 L7.06107374,7.20491503 L10,1.25 Z M12.1088444,8.34742474 L10,4.07375 L7.89115563,8.34742474 L3.175,9.0325 L6.58781813,12.3586851 L5.7825,17.055 L10,14.8377803 L14.21625,17.055 L13.4121819,12.3586851 L16.82375,9.0325 L12.1088444,8.34742474 Z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }