import eventMap from './vuex-staging-area-mutation-event-map';

export default (resource, { fieldPath, fieldName, fullEventPath }) => {
    const fullPath = fullEventPath || (fieldPath ? `${fieldPath}.${fieldName}` : fieldName);
    const resourceEventMap = eventMap[resource];

    if (!resourceEventMap) return;

    // Check if there is a matching event for the field which has changed using regex pattern matching.
    return resourceEventMap.filter(map => {
        return map.regexes.find(regex => regex.test(fullPath));
    });
};
