import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "summary-kpis-row" }, [
    _c(
      "span",
      {
        class: [
          _vm.isHeaderBold
            ? "summary-kpis-row__header--bold"
            : "summary-kpis-row__header",
        ],
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(!_vm.isHeaderRow ? _vm.header : "")
            ) +
            "\n    "
        ),
      ]
    ),
    _vm._v(" "),
    _c("span", { staticClass: "summary-kpis-row__data" }, [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm._f("toSentenceCase")(
              !_vm.isHeaderRow ? _vm.KPIMetric : _vm.header
            )
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "summary-kpis-row__data" },
      [
        _vm._v(
          "\n        " +
            _vm._s(!_vm.isHeaderRow ? _vm.deltaKPIMetric : "") +
            "\n        "
        ),
        _vm.isHeaderRow
          ? _c(VIcon, { attrs: { size: "15" } }, [_vm._v("change_history")])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }