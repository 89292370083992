<template>
    <!-- eslint-disable vue/no-useless-template-attributes -->
    <div class="promotion-maintenance">
        <div class="promotion-maintenance__content" :class="isParkingLot ? '' : '.non-parkinglot'">
            <v-form ref="promotion-maintenance-form" v-model="validation.isValid" @submit.prevent>
                <v-tabs
                    :value="selectedTab"
                    height="2.5rem"
                    background-color="transparent"
                    class="promo-tabs"
                    active-class="promo-tabs__tab--active"
                    hide-slider
                    @change="navigateToTab"
                >
                    <template v-for="tab in tabs" :value="tab">
                        <v-tab
                            :key="'tab' + tab.tabName"
                            :disabled="tab.disabled"
                            class="promo-tabs__tab"
                        >
                            <v-tooltip v-if="tab.disabled" z-index="400" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ $t(tab.label) | toSentenceCase }}
                                    </span>
                                </template>
                                <span v-if="disabledTabTooltip">{{
                                    $t(`planning.promotionsViewer.${disabledTabTooltip}`)
                                }}</span>
                            </v-tooltip>

                            <template v-else>
                                {{ $t(tab.label) | toSentenceCase }}
                                <div v-if="getTabState(tab) !== tabStates.invalid">
                                    <icon
                                        v-if="
                                            getTabState(tab) === tabStates.notStarted ||
                                                getTabState(tab) === tabStates.inProgress
                                        "
                                        icon-name="empty-success-circle"
                                        small
                                    />
                                    <icon
                                        v-else-if="getTabState(tab) === tabStates.completed"
                                        icon-name="success-circle"
                                        small
                                    />
                                </div>
                            </template>
                        </v-tab>
                        <!-- Used to fill and style the space between tabs. -->
                        <div :key="'tab-spacer' + tab.tabName" class="promo-tabs__spacer" />
                    </template>
                    <div class="promo-tabs__tab-button-spacer" />
                    <div class="promo-tabs__tab-button">
                        <split-promotion
                            v-if="promotionForForecasting.splitPromotion"
                            :promotion-id="selectedPromotionId"
                            :product-count="promotionForForecasting.products.length"
                            :disabled="isSplitButtonDisabled"
                            @split-requested="$emit('split-requested')"
                        />
                    </div>
                    <div class="promo-tabs__tab-button-spacer" />
                    <div class="promo-tabs__tab-button">
                        <task-button
                            v-if="!isParkingLotOrSubCampaignTab && scenario && scenario.isFavourite"
                            :task="workflowTasks.submit"
                            :entity-type="workflowEntities.promotion"
                            :entity-ids="[selectedPromotionId]"
                            :sub-campaign-id="scenario.subCampaignId"
                            :show-action-date-time="true"
                            :categories="promotion.userSelectedCategories || []"
                            :is-store-wide-promotion="isStoreWidePromotion"
                            :disabled="isTaskButtonsDisabled"
                            :disabled-reason="actionsDisabledReason"
                            short-text
                            @click="
                                () => {
                                    setIsActionDisabled(true);
                                }
                            "
                            @action-completed="
                                () => {
                                    setIsActionDisabled(false);
                                }
                            "
                        />
                    </div>
                    <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
                    <div class="promo-tabs__filler" />
                    <task-buttons-dropdown
                        v-if="!isParkingLotOrSubCampaignTab && scenario && scenario.isFavourite"
                        :tasks="workflowTasksList"
                        :entity-type="workflowEntities.promotion"
                        :entity-ids="[selectedPromotionId]"
                        :sub-campaign-id="scenario.subCampaignId"
                        :entity-instance="promotion"
                        :categories="promotion.userSelectedCategories || []"
                        :is-store-wide-promotion="isStoreWidePromotion"
                        :is-disabled="isTaskButtonsDisabled"
                        :disabled-reason="actionsDisabledReason"
                        @click="
                            () => {
                                setIsActionDisabled(true);
                            }
                        "
                        @action-completed="
                            () => {
                                setIsActionDisabled(false);
                            }
                        "
                    />
                    <div
                        v-if="generalConfig.showWorkflowDebugIcon"
                        class="promo-tabs__notifications-icon"
                    >
                        <workflow-state
                            v-if="!isParkingLotOrSubCampaignTab"
                            :entity-id="selectedPromotionId"
                            :workflow-entity="workflowEntities.promotion"
                        />
                    </div>

                    <div class="promo-tabs__notifications-icon">
                        <notes
                            :notes="notes"
                            :disabled="!editContext"
                            open-left
                            @delete="removeNote"
                            @post="postNote"
                            @save="saveNote"
                        />
                    </div>
                </v-tabs>

                <v-tabs-items v-model="selectedTab">
                    <v-tab-item
                        v-for="(tab, ix) in tabs"
                        :key="'tab-item-' + ix"
                        :transition="false"
                        :reverse-transition="false"
                    >
                        <alert-banner
                            v-if="isReadOnly && !pendingChangesToReview"
                            :reason="propagatedReadOnlyReason"
                            class="ml-4"
                        />
                        <review-changes-dialog
                            v-if="pendingChangesToReview"
                            :promotion-id="namespace"
                            :ignore-parent-read-only="ignoreReadonlyForReviewChanges"
                        />
                        <div v-if="tab.component">
                            <component
                                :is="tab.component"
                                :context="customContext"
                                :form-ref="formRef"
                                :edit-mode="editMode"
                                :namespace="namespace"
                                :is-new-promotion="isNewPromotion"
                                :is-sub-campaign-allocation="isSubCampaignAllocation"
                                :promotion-editing-state="promotionEditingState"
                                :is-parking-lot="isParkingLot"
                                :is-past-promotions="isPastPromotions"
                                :cache-dom="tab.cacheDOM"
                            />
                        </div>
                        <h1 v-else>{{ tab.content }}</h1>
                    </v-tab-item>
                </v-tabs-items>
            </v-form>
            <v-divider />
            <div class="footer">
                <div class="footer__execution">
                    <feature-toggle :toggle="canManuallyValidatePromotion">
                        <v-btn
                            :ignore-parent-read-only="canValidate"
                            :disabled="isTaskButtonsDisabled"
                            primary
                            depressed
                            class="ma-1"
                            @click="validatePromotion"
                        >
                            {{ $t('execution.actions.validatePromotion') | toSentenceCase }}
                        </v-btn>
                    </feature-toggle>
                    <feature-toggle :toggle="canManuallyExecutePromotion">
                        <v-btn
                            v-if="!isParkingLot && scenario && scenario.isFavourite"
                            :disabled="isTaskButtonsDisabled"
                            primary
                            depressed
                            class="ma-1"
                            @click="executePromotion"
                        >
                            {{ $t('execution.actions.executePromotion') | toSentenceCase }}
                        </v-btn>
                    </feature-toggle>
                </div>
                <div class="footer__forecast-save-container">
                    <unsaved-banner
                        small
                        class="footer__unsaved"
                        :promotion-id="selectedPromotionId"
                    />
                    <div
                        v-if="isPromotionSaveInProgress"
                        class="footer__forecast-save-container__spinner"
                    >
                        <spinner-dynamic />
                    </div>
                    <simple-button
                        v-if="isPromotionUnsaved"
                        light-theme
                        class="mr-2"
                        @onClick="cancelChanges"
                    >
                        {{ $t('actions.cancel') | toSentenceCase }}
                    </simple-button>
                    <positive-action-button
                        :disabled="isSavePromotionDisabled"
                        @onClick="forecastAndSavePromotion"
                    >
                        {{ $t('planning.buttons.forecastAndSavePromotion') | toSentenceCase }}
                    </positive-action-button>
                </div>
            </div>
        </div>
        <div class="promotion-maintenance__sidebar">
            <promotion-forecasting
                :promotion="promotionForForecasting"
                :is-past-promotions="isPastPromotions"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { sortBy, findIndex, get, isEmpty } from 'lodash';
import NotesFieldKeys from '@enums/notes-field-keys';
import workflowTasks from '@enums/workflow-tasks';
import workflowEntities from '@enums/workflow-entities';
import lockedReasons from '@enums/locked-reasons';
import UXEvents from '@enums/ux-events';
import TabStates from '@enums/tab-states';
import tabsEnum from '@enums/promotion-tabs';
import {
    enablePromotionPermalink,
    promotionNameIsRequired,
    canEditPromotion,
    canManuallyExecutePromotion,
    canManuallyValidatePromotion,
    canCreateSplitPromotion,
} from '@enums/feature-flags';
import namespaces from '@enums/namespaces';
import navigation from '@/js/navigation';
import vuexFormMixin from '@/js/mixins/vuex-form';

export default {
    mixins: [vuexFormMixin],
    props: {
        promotion: {
            required: true,
            type: Object,
        },
        scenario: {
            required: true,
            type: Object,
        },
        isNewPromotion: {
            type: Boolean,
            default: false,
        },
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tabStates: TabStates,
            workflowTasks,
            workflowEntities,
            workflowTasksList: [
                {
                    task: workflowTasks.approve,
                    isNegativeAction: false,
                },
                {
                    task: workflowTasks.approve,
                    isNegativeAction: true,
                },
            ],
            formRef: null,
            selectedTab: null,
            canManuallyExecutePromotion,
            canManuallyValidatePromotion,
            isManualSaveInProgress: false,
            isActionsDisabled: false,
            isExecutionInProgress: false,
            showNewFundingViewer: true,
        };
    },
    events: {
        // when promotions are updated in store we need to update staging area
        onPromotionsUpdated() {
            this.populateFieldsInStagingArea();
        },
        toggleFundingView() {
            this.showNewFundingViewer = !this.showNewFundingViewer;
        },
        onPromotionForecastedAndSaved({ forecastResult }) {
            this.postSaveActions(forecastResult);
        },
        onNavigateToPlannerTab({ tab }) {
            this.navigateToTab(tab);
        },
        onForecastAndSavePromotion() {
            this.forecastAndSavePromotion();
        },
    },
    computed: {
        ...mapState('clientConfig', ['generalConfig', 'toggleLogic', 'promotionTabs']),
        ...mapGetters('promotions', [
            'getStagingAreaPromotionById',
            'pendingChanges',
            'getPromotionNotifications',
            'isSelectedPromotionStoreWide',
            'isSelectedPromotionCategoryWide',
            'isSelectedPromotionCategoryOrStoreWide',
        ]),
        ...mapState('promotions', [
            'tabValidationState',
            'unsavedPromotion',
            'saveInProgress',
            'addProductInProgress',
        ]),
        tabs() {
            const isParkingLotOrSubCampaign = this.isParkingLotOrSubCampaignTab;
            const promoTabs = [
                {
                    label: this.promotionTabs.date.name,
                    component: 'promotion-dates-form-wrapper',
                    tabName: tabsEnum.date,
                    position: this.promotionTabs.date.position,
                    isDefault: this.promotionTabs.date.isDefault,
                    cacheDOM: this.promotionTabs.date.cacheDOM,
                    disabled: this.isPromotionUnsaved,
                },
                {
                    label: this.promotionTabs.channels.name,
                    component: 'channels-stores-form-wrapper',
                    tabName: tabsEnum.channels,
                    position: this.promotionTabs.channels.position,
                    isDefault: this.promotionTabs.channels.isDefault,
                    cacheDOM: this.promotionTabs.channels.cacheDOM,
                    disabled: this.isPromotionUnsaved,
                },
                {
                    label: this.promotionTabs.offer.name,
                    component: 'offer-form-wrapper',
                    tabName: tabsEnum.offer,
                    position: this.promotionTabs.offer.position,
                    isDefault: this.promotionTabs.offer.isDefault,
                    cacheDOM: this.promotionTabs.offer.cacheDOM,
                    disabled: this.isPromotionUnsaved,
                },
                {
                    label: this.promotionTabs.funding.name,
                    component: 'funding-form-wrapper',
                    tabName: tabsEnum.funding,
                    position: this.promotionTabs.funding.position,
                    isDefault: this.promotionTabs.funding.isDefault,
                    cacheDOM: this.promotionTabs.funding.cacheDOM,
                    disabled:
                        this.isSelectedPromotionCategoryOrStoreWide || this.isPromotionUnsaved,
                },
                {
                    label: this.promotionTabs.supply.name,
                    component: 'supply-form-wrapper',
                    tabName: tabsEnum.supply,
                    position: this.promotionTabs.supply.position,
                    isDefault: this.promotionTabs.supply.isDefault,
                    cacheDOM: this.promotionTabs.supply.cacheDOM,
                    disabled:
                        this.isSelectedPromotionCategoryOrStoreWide ||
                        this.isEmptyDates ||
                        this.isPromotionUnsaved,
                },
            ];
            const sortedTabs = sortBy(promoTabs, 'position');
            return sortedTabs.map(tab => ({
                ...tab,
                navigateTo: () => {
                    // routing navigation should be disabled for parking lot
                    if (!isParkingLotOrSubCampaign) {
                        navigation.toPromotionTabView({
                            tabName: tab.tabName,
                            promotionId: this.selectedPromotionId,
                        });
                    }
                },
            }));
        },

        disabledTabTooltip() {
            if (this.isStoreWidePromotion) {
                return 'disabledForStoreWide';
            }
            if (this.isCategoryWidePromotion) {
                return 'disabledForCategoryWide';
            }
            if (this.isEmptyDates) {
                return 'emptyDates';
            }

            if (this.isPromotionUnsaved) {
                return 'unsavedPromotion';
            }

            return null;
        },

        isParkingLotOrSubCampaignTab() {
            return this.isParkingLot || this.isSubCampaignAllocation;
        },

        isPromotionUnsaved() {
            const tabValues = Object.values(this.unsavedPromotion[this.selectedPromotionId] || {});
            return tabValues.filter(v => v).length > 0;
        },

        isEmptyDates() {
            return !this.promotionForForecasting.startDate || !this.promotionForForecasting.endDate;
        },

        promotionForForecasting() {
            // copying promotion in stagingArea and retain other fields
            return {
                ...this.promotion,
                ...this.getStagingAreaPromotionById(this.selectedPromotionId || 'default'),
            };
        },

        customContext() {
            return {
                selectedScenario: this.scenario,
                selectedPromotion: this.promotion,
            };
        },

        notes() {
            return sortBy(this.promotion.notes, 'createdOn');
        },

        selectedPromotionId() {
            return this.computedNamespace;
        },

        canValidate() {
            return this.isParkingLot && this.toggleLogic[canEditPromotion];
        },

        isSavePromotionDisabled() {
            const stagingAreaPromotion = this.getStagingAreaPromotionById(
                this.selectedPromotionId || 'default'
            );
            const { channels } = tabsEnum;
            const tabsInvalid = this.getTabState({ tabName: channels }) !== TabStates.completed;
            const categories = get(stagingAreaPromotion, 'userSelectedCategories', []);
            const hasCategoriesOrIsStoreWidePromo =
                !!categories.length || this.isStoreWidePromotion;
            return (
                !this.validation.isValid ||
                tabsInvalid ||
                !this.toggleLogic[canEditPromotion] ||
                (this.toggleLogic[promotionNameIsRequired] && !this.promotionForForecasting.name) ||
                // validation for each tab happens only after tab open
                // we don't have defaults values for dates tab, this condition cover dates tab
                (!stagingAreaPromotion.startDate && !stagingAreaPromotion.endDate) ||
                (!stagingAreaPromotion._id && !hasCategoriesOrIsStoreWidePromo) ||
                this.isManualSaveInProgress ||
                this.addProductInProgress ||
                (this.showNewFundingViewer && !this.isNewPromotion && !this.isPromotionUnsaved)
            );
        },

        pendingChangesToReview() {
            // Check for pending notifications in promotion without a changeset, which means this is post-redesign
            const hasChangesToReview = this.pendingChanges({
                promotionId: this.namespace,
            });

            return !(this.changeset || !hasChangesToReview);
        },

        isStoreWidePromotion() {
            return this.isSelectedPromotionStoreWide;
        },

        isCategoryWidePromotion() {
            return this.isSelectedPromotionCategoryWide;
        },

        ignoreReadonlyForReviewChanges() {
            return this.propagatedReadOnlyReason === lockedReasons.promotionNotificationExist;
        },

        actionsDisabledReason() {
            if (this.isPromotionUnsaved) {
                return this.$t('planning.promotionsViewer.unsavedPromotion');
            }

            if (this.promotion.splitPromotion) {
                return this.$t('workflow.taskButton.disabledReason.splitPromotion');
            }

            return null;
        },

        isPromotionSaveInProgress() {
            return this.isManualSaveInProgress || this.saveInProgress;
        },

        isSplitButtonDisabled() {
            return (
                this.isActionsDisabled ||
                this.isExecutionInProgress ||
                this.isPromotionUnsaved ||
                !this.promotion._id ||
                this.promotion.splitInProgress ||
                isEmpty(this.promotion.products) ||
                this.promotion.products.length < this.generalConfig.minProductsPerSplitPromotion ||
                !this.toggleLogic[canCreateSplitPromotion]
            );
        },

        isTaskButtonsDisabled() {
            return (
                this.isActionsDisabled ||
                this.isExecutionInProgress ||
                this.isPromotionUnsaved ||
                this.promotion.splitPromotion
            );
        },
    },
    watch: {
        $route() {
            this.setSelectedTab();
        },
    },
    created() {
        this.setSelectedTab();
    },
    async mounted() {
        // Hold a reference to the promotion maintenance form. This can be passed to child components to facilitate
        // running actions on the form from nested components, e.g. validation.
        this.formRef = this.$refs['promotion-maintenance-form'];

        // planning promotion call this action on expand event
        await this.updateDataForPromotion(this.promotionForForecasting);
    },
    methods: {
        ...mapActions('promotions', [
            'addNote',
            'deleteNote',
            'updateNote',
            'updateDataForPromotion',
            'setSelectedPromotion',
        ]),
        ...mapMutations('promotions', [
            'setSelectedPromotionMaintenanceTab',
            'setUnsavedPromotion',
        ]),
        ...mapActions('execution', ['validateSinglePromotionById', 'executeSinglePromotionById']),
        ...mapActions('forecasting', ['forecastSinglePromotion']),

        async forecastAndSavePromotion() {
            if (this.isReadOnly) return;
            this.isManualSaveInProgress = true;

            // Newly created promos should be saved before forecasting
            if (
                this.computedNamespace === namespaces.default ||
                this.isSelectedPromotionCategoryOrStoreWide
            ) {
                const saveResult = await this.submit();
                this.postSaveActions(saveResult);
                return saveResult;
            }
            return this.forecastSinglePromotion({
                promotion: this.promotionForForecasting,
                callbackEvent: UXEvents.promotionForecastedAndSaved,
            });
        },

        async cancelChanges() {
            this.populateFieldsInStagingArea();
            const promotionId = this.selectedPromotionId;
            const currentTab = this.selectedTab;
            await this.setSelectedPromotion({ promotionId });
            this.setUnsavedPromotion({ namespace: promotionId, tab: 'all', value: false });
            this.globalEmit(UXEvents.resetPromotionViewerDialog, { currentTab, promotionId });
        },

        postSaveActions({ error, result }) {
            if (!error) {
                let globalEvent;
                if (this.computedNamespace === namespaces.default) {
                    // if new entity is created
                    globalEvent = this.isParkingLot
                        ? UXEvents.parkingLotCreated
                        : UXEvents.promotionCreated;
                } else {
                    // if entity is updated
                    globalEvent = this.isParkingLot
                        ? UXEvents.parkingLotSaved
                        : UXEvents.promotionSaved;
                }

                if (this.isParkingLotOrSubCampaignTab) {
                    // namespace required for some global event handlers e.g. updateOfferMechanicDescription
                    result.namespace = this.computedNamespace;
                }

                this.globalEmit(globalEvent, result);
                // if promotion is viewed via dialog we handle it's closure
                if (this.isParkingLotOrSubCampaignTab) {
                    this.$emit('save-button-clicked', result);
                }

                this.setUnsavedPromotion({
                    namespace: this.selectedPromotionId,
                    tab: 'all',
                    value: false,
                });
            } else if (this.isParkingLotOrSubCampaignTab) {
                this.globalEmit(UXEvents.parkingLotPromoSaveFailed);
            }

            this.isManualSaveInProgress = false;
        },

        validatePromotion() {
            this.validateSinglePromotionById({ namespace: this.selectedPromotionId });
        },

        async executePromotion() {
            this.isExecutionInProgress = true;
            await this.executeSinglePromotionById({ namespace: this.selectedPromotionId });
            this.isExecutionInProgress = false;
        },

        postNote(note) {
            this.addNote({
                note,
                id: this.selectedPromotionId,
                fieldKey: NotesFieldKeys.root,
            });
        },
        removeNote(noteId) {
            this.deleteNote({
                noteId,
                id: this.selectedPromotionId,
                fieldKey: NotesFieldKeys.root,
            });
        },
        saveNote(note) {
            this.updateNote({
                note,
                id: this.selectedPromotionId,
                fieldKey: NotesFieldKeys.root,
            });
        },
        getTabState(tab) {
            return this.tabValidationState[tab.tabName];
        },

        navigateToTab(index) {
            if (this.tabs[index].disabled) {
                return;
            }

            this.selectedTab = index;
            this.setSelectedPromotionMaintenanceTab(this.tabs[index].tabName);
            if (this.toggleLogic[enablePromotionPermalink]) {
                this.tabs[index].navigateTo();
            }
        },

        setSelectedTab() {
            if (this.toggleLogic[enablePromotionPermalink]) {
                const tabName = this.$route.params.tabName;
                const tabToOpen = findIndex(this.tabs, { tabName });
                if (tabName && tabToOpen !== -1) {
                    this.selectedTab = tabToOpen;
                    this.setSelectedPromotionMaintenanceTab(tabName);
                } else {
                    const defaultTab = this.tabs.find(tab => tab.isDefault);
                    this.selectedTab = defaultTab ? defaultTab.position : 0;
                    this.setSelectedPromotionMaintenanceTab(defaultTab.tabName);
                }
            }
        },
        setIsActionDisabled(value) {
            this.isActionsDisabled = value;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.promotion-maintenance {
    // Currently we need here explicit height
    // in some cases we have not enough height of content and after tab changing page is jumping
    // more details in https://owlabs.atlassian.net/browse/PROWEB-873
    min-height: 81rem;
    width: 100%;
    @include flex-row;

    background-color: $promo-white;

    &__content {
        flex-grow: 1;
    }

    &__sidebar {
        flex: 0 0 $health-sidebar-width;
        z-index: $health-sidebar-z-index;
    }

    .promo-tabs {
        &__tab {
            display: flex;
            justify-content: space-between;
            // allow pointer events to show tooltip for disabled tabs
            pointer-events: auto !important;
        }
    }

    .non-parkinglot {
        border-bottom: $border-shadow 0.1rem solid;
    }
}

.footer {
    display: flex;
    align-items: center;
    padding: 2rem;
    &__execution {
        flex: 50%;
        float: left;
        text-align: left;
    }
    &__unsaved {
        text-align: center;
    }
    &__forecast-save-container {
        display: flex;
        align-items: center;
        &__spinner {
            margin: 0 1rem;
        }
    }
}
</style>
