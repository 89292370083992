var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "client-specific-fields-changes-viewer" }, [
    _c(
      "div",
      { staticClass: "client-specific-fields-changes-viewer__grid" },
      [
        _c("promo-ag-grid", {
          ref: "agGrid",
          attrs: {
            "row-data": _vm.rowData,
            "column-defs": _vm.columnDefs,
            "grid-options": _vm.gridOptions,
            "default-col-def": _vm.defaultColDef,
            "grid-style": _vm.gridStyle,
            "grid-class": _vm.gridClass,
            "dom-layout": "autoHeight",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "client-specific-fields-changes-viewer__actions",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }