import { merge, filter, sortBy, lowerCase } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    freeGifts: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getFreeGiftsById
     */
    getters: {
        getActiveFreeGifts: state =>
            sortBy(filter(state.freeGifts, { isActive: true }), [i => lowerCase(i.text)]),
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setFreeGifts
     * - deleteFreeGifts
     * - updateFreeGifts
     * - addFreeGifts
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchFreeGifts
     * - createFreeGifts
     * - deleteFreeGifts
     * - updateFreeGifts
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'free-gift',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
