<template>
    <div v-if="isVisible" :class="{ 'text-container': true, disabled: isDisabled }">
        <div class="ag-text-input">
            <div class="input-container">
                <input-text-field
                    :disabled="isDisabled"
                    :getter="() => value"
                    :setter="setValue"
                    :validations="params.colDef.validationRules || []"
                    :field-type="params.fieldType"
                    :format="params.formatter"
                    :placeholder="placeholderValue"
                    :prefix="params.prefix"
                    :suffix="params.suffix"
                    :size="params.size"
                    :class="{ 'input--bold': isBold }"
                    positive-only
                    filled
                />
            </div>
        </div>
    </div>
    <div v-else-if="placeholderClass" :class="placeholderClass" />
</template>

<script>
import Vue from 'vue';
import { get, isNil, has } from 'lodash';

export default Vue.extend({
    computed: {
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },
        isDisabled() {
            return (
                this.isReadOnly ||
                (has(this.params, 'isDisabled') ? this.params.isDisabled(this.params) : false)
            );
        },

        placeholderValue() {
            if (this.params.data.isSupplier && !isNil(get(this.params.value, 'placeholder'))) {
                return get(this.params.value, 'placeholder');
            }
            return this.params.cellPlaceholder;
        },

        placeholderClass() {
            return get(this.params, 'placeholderClass', null);
        },

        value() {
            if (has(this.params.value, 'supplierValue')) {
                return get(this.params.value, 'supplierValue', null);
            }
            return this.params.value;
        },
        isBold() {
            return !!this.params.data.isSupplier;
        },
    },

    methods: {
        setValue(value) {
            const oldValue = this.params.data.isSupplier
                ? get(this.params.value, 'supplierValue', null)
                : this.params.value;

            const params = {
                oldValue,
                newValue: value,
                data: this.params.data,
                colDef: {
                    fieldName: this.params.colDef.fieldName,
                    fieldPath: this.params.colDef.fieldPath,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.5rem;

    background-color: $promo-table-white-bg-colour;

    &.disabled {
        background-color: transparent !important;
    }

    .input--bold {
        font-weight: bold;
    }

    .ag-text-input {
        display: flex;
        width: 100%;

        .input-container {
            width: 'fit-content';
        }
    }
}
</style>
