var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOnFundingTab
    ? _c(
        "div",
        [
          _c("funding-viewer", {
            attrs: {
              "vuex-module": _vm.vuexModule,
              "field-name": _vm.fieldName,
              namespace: _vm.computedNamespace,
              getter: _vm.productsGetter,
              "form-ref": _vm.formRef,
              "is-parking-lot": _vm.isParkingLot,
              "is-past-promotions": _vm.isPastPromotions,
            },
          }),
          _vm._v(" "),
          _c("tree-view-dialog", {
            ref: "bulk-upload-errors-dialog",
            attrs: { errors: _vm.bulkUploadSupplierFundingErrors || {} },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }