<template>
    <rtls-toggle
        :value="model"
        :left-toggle="leftToggle"
        :right-toggle="rightToggle"
        @input="onToggleChange"
    />
</template>

<script>
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        leftToggle: {
            required: true,
            type: Object,
        },
        rightToggle: {
            required: true,
            type: Object,
        },
    },

    methods: {
        onToggleChange(value) {
            this.model = value;
        },
    },
};
</script>
