import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    [
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Material+Icons",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      !_vm.hideNavbar ? _c("main-navbar") : _vm._e(),
      _vm._v(" "),
      !_vm.hideNavbar && !_vm.hideSidebar
        ? _c("main-sidebar", {
            attrs: { "calendar-options": _vm.sidebarCalendarOptions },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(VContent, { style: _vm.contentStyle }, [
        _c(
          "div",
          { attrs: { id: "app-container" } },
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [_c("router-view", { staticClass: "main-page" })],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        VOverlay,
        { attrs: { value: _vm.isNavigating, "z-index": 1000 } },
        [_c("spinner-dynamic")],
        1
      ),
      _vm._v(" "),
      _c("notifications", { attrs: { "popup-timeout": _vm.popupTimeout } }),
      _vm._v(" "),
      _c("events-handler"),
      _vm._v(" "),
      _c("main-footer"),
      _vm._v(" "),
      _c("div", { attrs: { id: "consent_blackbar" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }