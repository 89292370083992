import { VMessages } from 'vuetify/lib/components/VMessages';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supply-grid" },
    [
      _c("div", { staticClass: "supply-grid__title" }, [
        _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.$tkey("title")))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "supply-grid__errors" },
        [
          _vm.formRef
            ? _c(VMessages, {
                attrs: { color: "error", value: _vm.formValidationError },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("promo-ag-grid", {
        ref: "agGrid",
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 95%;",
          "grid-class": "ag-theme-custom__supply-grid",
          "dom-layout": "normal",
        },
        on: {
          "grid-ready": _vm.onReady,
          "model-updated": _vm.refreshStyles,
          "cell-value-changed": _vm.onCellValueChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }