var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-price-grid" },
    [
      _c("div", { staticClass: "list-price-grid__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("listPrice"))) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 35rem;",
          "grid-class": "ag-theme-custom__candidates-grid",
          "dom-layout": "normal",
          "single-click-edit": true,
          "make-read-only": _vm.promotionEditingState.disabled,
          "read-only-reason": _vm.promotionEditingState.reason,
        },
        on: {
          "cell-value-changed": _vm.onCellValueChanged,
          "grid-ready": _vm.onGridReady,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }