<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import UXEvents from '@enums/ux-events';
import commonDeleteDialogComponent from './common-delete-dialog';
import navigation from '@/js/navigation';

export default {
    extends: commonDeleteDialogComponent,

    computed: {
        ...mapGetters('scenarios', ['scenariosInCategoryGroup']),
        ...mapState('clientConfig', ['generalConfig']),

        isSingleScenarioInCategoryGroup() {
            const scenariosInGroup = this.scenariosInCategoryGroup(
                this.editContext.subCampaignId,
                this.editContext.categories
            );
            return scenariosInGroup.length === 1;
        },

        btnDeleteId() {
            return 'deleteScenarioButton';
        },

        deleteHeading() {
            return this.editContext &&
                this.editContext.isFavourite &&
                // allow to delete favourite scenario if it has multiple categories or
                // favourite scenario that is the only one in category combination
                // just display favourite heading warning
                (this.editContext.categories.length > 1 || this.isSingleScenarioInCategoryGroup)
                ? 'dialogs.deleteFavouriteScenario.header'
                : 'dialogs.deleteScenario.header';
        },

        // We should not be able to delete a favourite scenario
        // that only has one category and is not the only one in that category
        // Also disable deleting favourite (default) scenario with all categories in 'oneScenarioForAllCategories' mode
        isDeleteDisabled() {
            return (
                (this.editContext &&
                    this.editContext.isFavourite &&
                    this.editContext.categories.length === 1 &&
                    !this.isSingleScenarioInCategoryGroup) ||
                (this.generalConfig.oneScenarioForAllCategories && this.editContext.isFavourite)
            );
        },

        labels() {
            return {
                deleteBtnText: this.isDeleteDisabled ? 'actions.ok' : 'actions.delete',
                deleteHeading: this.isDeleteDisabled
                    ? 'dialogs.cannotDeleteFavouriteScenario.header'
                    : this.deleteHeading,
                deleteDescription: 'dialogs.cannotDeleteFavouriteScenario.description',
            };
        },
    },

    methods: {
        ...mapActions('scenarios', ['deleteScenario']),

        async deleteSelectedItem() {
            if (this.isDeleteDisabled) {
                return;
            }
            const { error } = await this.deleteScenario({ id: this.resourceId });

            // redirect to sub-campaign view if the currently selected scenario has been deleted.
            if (this.selectedSubCampaign && this.selectedScenarioId === this.resourceId && !error) {
                navigation.toSubCampaignView({
                    subCampaignId: this.selectedSubCampaign._id,
                });
            }

            this.globalEmit(UXEvents.scenarioDeleted);

            this.closeAll();
        },
    },
};
</script>
