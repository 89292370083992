<template>
    <v-pagination
        v-model="pagination.page"
        :length="length"
        :total-visible="totalVisible"
        class="main-pagination"
        :class="cssClasses"
        :disabled="disabled"
    />
</template>

<script>
export default {
    props: {
        pagination: {
            pagination: Object,
            required: true,
        },
        totalVisible: {
            type: Number,
            required: true,
        },
        length: {
            type: Number,
            required: true,
        },
        cssClasses: Array,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.main-pagination.v-pagination {
    justify-content: flex-end;

    &::v-deep {
        .v-pagination__navigation,
        .v-pagination__item {
            height: 2rem;
            width: 2rem;
            box-shadow: none;
        }

        .v-pagination__item {
            display: flex;
            justify-content: center;
            padding: 0;
            align-items: center;
            min-width: 2rem;
            color: $promo-primary-colour;
            border: 0.1rem solid $promo-primary-colour;
            line-height: 1rem;

            &--active {
                color: $promo-white;
            }
        }

        .v-pagination__navigation {
            .v-icon {
                color: $promo-primary-colour;
            }
        }
    }
}
</style>
