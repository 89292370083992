/* eslint-disable import/prefer-default-export */

const { isNil, set, merge, get, isEmpty, round } = require('lodash');
const invoiceCalculationTypes = require('./data/enums/invoice-calculation-types');
const { promoPriceCalculator } = require('./promo-price-calculator');

function isSupplierRow(params) {
    return params.data.isSupplier;
}

function isTotalRow(params) {
    return params.data.overallTotalRow;
}

// Defines the attributes which apply to all columns defined in columnDefs.
const columnDefinition = {
    suppressMovable: true, // Stop users from being able to rearrange columns.
    lockPinned: true, // Stop users from being able to pin columns.
    sortable: true, // All columns default to being sortable.
    unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
    filter: false,
    menuTabs: [],
    minWidth: 100,
};

const defaultGridOptions = {
    enableGroupEdit: true,
    treeData: true,
    groupSuppressAutoColumn: true, // Remove the default "expand group" column.
    groupHeaderHeight: 200,
    applyColumnDefOrder: true,
    postSort: rowNodes => {
        // keep supplier total rows always at the end despite of the product sorting order
        let nextInsertPos = 0;
        for (let i = 0; i < rowNodes.length; i += 1) {
            if (!isTotalRow(rowNodes[i])) {
                rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
                nextInsertPos += 1;
            }
        }
    },
    getRowNodeId: data => {
        // Generates a unique ID for each row. This increases the performance of data updates.
        return `${data.supplierKey}::${data.categoryKey}::${data.productKey}`;
    },
    getRowClass: params => {
        if (isSupplierRow(params)) {
            // Add specific styling for supplier rows.
            return 'grid__supplier-row';
        }
        if (isTotalRow(params)) {
            // Add specific styling for total rows.
            return 'grid__total-row';
        }
    },
    getRowHeight: () => 32,
};

const apportionMappings = {
    uplift: {
        getWeight: product =>
            product.volumes.forecasted.calcUplift +
            (isNil(product.volumes.baseline)
                ? product.volumes.forecasted.calcBaseline
                : product.volumes.baseline),
        path: 'volumes',
    },
    baseline: {
        getWeight: product =>
            product.volumes.forecasted.calcBaseline +
            (isNil(product.volumes.uplift)
                ? product.volumes.forecasted.calcUplift
                : product.volumes.uplift),
        path: 'volumes',
    },
    tmpLumpFunding: {
        getWeight: product => product.volumes.totalVolume,
        path: undefined,
        staging: {
            path: 'funding',
            fieldName: 'lumpFunding',
        },
    },
    totalVolume: {
        getWeight: product =>
            (isNil(product.volumes.uplift)
                ? product.volumes.forecasted.calcUplift
                : product.volumes.uplift) +
            (isNil(product.volumes.baseline)
                ? product.volumes.forecasted.calcBaseline
                : product.volumes.baseline),
        path: 'volumes',
    },
};

const getAllFundingFunctions = featureAwareFactory => {
    // Return all enabled funding functions
    const fundingFunctions = featureAwareFactory.getAllCalculateFundingFunctions();
    return Object.keys(fundingFunctions).filter(ff => fundingFunctions[ff].enable);
};

const calculateFunding = ({
    product,
    percentage,
    fundingFunction = invoiceCalculationTypes.guaranteedMargin,
    featureAwareFactory,
}) => {
    if (!fundingFunction) return null;

    const calculateFundingFunction = featureAwareFactory.getCalculateFundingFunctions(
        fundingFunction,
        'valueFormula'
    );

    return calculateFundingFunction({
        product,
        percentage,
    });
};

const calculateMargin = ({
    product,
    fundingFunction = invoiceCalculationTypes.guaranteedMargin,
    featureAwareFactory,
    showOnlyMinimumPrices = false,
}) => {
    if (!fundingFunction) return null;

    const calculateMarginFunction = featureAwareFactory.getCalculateFundingFunctions(
        fundingFunction,
        'marginFormula'
    );

    if (isNil(calculateMarginFunction)) return null;

    return calculateMarginFunction({
        product,
        prices: product.nonPromoPrices,
        useMinPrice: showOnlyMinimumPrices,
        excludeFunding: true,
    });
};

const getAggregatedFunding = ({ buyingPrice, avgCost, supplierCompensation, standardWeight }) => {
    const bpUnitFunding = !isNil(buyingPrice) ? (avgCost - buyingPrice) * (standardWeight || 1) : 0;
    return !isNil(supplierCompensation) ? bpUnitFunding + supplierCompensation : bpUnitFunding;
};

/**
 * Calculates variable funding for a product.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 * @param {Object} featureAwareFactory - Feature factory to fetch calculation functions from.
 *
 * @returns {Object} - The product's new variable funding.
 */
const calculateProductVariableFunding = ({
    product,
    featureAwareFactory,
    showOnlyMinimumPrices,
}) => {
    const onInvoiceCosts = get(product, 'onInvoiceCosts');
    const avgCost = get(onInvoiceCosts, 'avgCost');
    const currentUiFields = get(product, 'funding.variableFunding.uiFields');

    // Clear fields if no cost and price data
    if (isNil(avgCost) || isEmpty(product.promoPrices)) {
        return {
            uiFields: currentUiFields,
            supplierCompensation: null,
            buyingPrice: null,
            unitFunding: 0,
        };
    }

    const currentBuyingPrice = get(product, 'funding.variableFunding.buyingPrice');
    const currentSupplierCompensation = get(
        product,
        'funding.variableFunding.supplierCompensation'
    );
    const onInvoiceType = get(currentUiFields, 'onInvoiceType');
    const offInvoiceType = get(currentUiFields, 'offInvoiceType');

    // Recalculate invoice value margins if there is a formula for it, otherwise keep current value
    const onInvoiceValue =
        calculateMargin({
            product,
            fundingFunction: onInvoiceType,
            featureAwareFactory,
            showOnlyMinimumPrices,
        }) || get(currentUiFields, 'onInvoiceValue');
    const offInvoiceValue =
        calculateMargin({
            product,
            fundingFunction: offInvoiceType,
            featureAwareFactory,
            showOnlyMinimumPrices,
        }) || get(currentUiFields, 'offInvoiceValue');

    // If both function values are null, we keep the same values
    if (isNil(onInvoiceValue) && isNil(offInvoiceValue)) {
        return {
            uiFields: { ...currentUiFields, onInvoiceValue, offInvoiceValue },
            supplierCompensation: currentSupplierCompensation,
            buyingPrice: currentBuyingPrice,
            unitFunding: getAggregatedFunding({
                avgCost,
                buyingPrice: currentBuyingPrice,
                supplierCompensation: currentSupplierCompensation,
                standardWeight: product.standardWeight,
            }),
        };
    }

    let buyingPrice = currentBuyingPrice;
    let supplierCompensation = currentSupplierCompensation;

    // If onInvoiceValue exists, recalculate buyingPrice
    if (!isNil(onInvoiceValue)) {
        const { newBuyingPrice } = calculateFunding({
            product,
            percentage: onInvoiceValue,
            fundingFunction: onInvoiceType,
            featureAwareFactory,
        });

        buyingPrice = newBuyingPrice || null;
    }

    // If offInvoiceValue exists, recalculate supplierCompensation, which equals the calculated funding value
    if (!isNil(offInvoiceValue)) {
        const { funding } = calculateFunding({
            product,
            percentage: offInvoiceValue,
            fundingFunction: offInvoiceType,
            featureAwareFactory,
        });

        supplierCompensation = funding || null;
    }

    return {
        uiFields: {
            ...currentUiFields,
            onInvoiceValue,
            offInvoiceValue,
        },
        supplierCompensation,
        buyingPrice,
        unitFunding:
            getAggregatedFunding({
                avgCost,
                buyingPrice,
                supplierCompensation,
                standardWeight: product.standardWeight,
            }) || 0,
    };
};

const calculatePromoPricesAndVariableFunding = ({
    products,
    offerMechanic,
    productOfferGroups,
    precision,
    featureAwareFactory,
    showOnlyMinimumPrices,
    bulkAdd = false,
    bulkAddNewProductKeys = null,
    forPublishing,
}) => {
    const productsWithUpdatedPromoPrices = promoPriceCalculator({
        offerMechanic,
        products,
        productOfferGroups,
        precision,
    });
    const updatedProducts = productsWithUpdatedPromoPrices.map(({ product, promoPrices }) => {
        product.promoPrices = promoPrices;

        if (forPublishing) {
            return product;
        }

        const {
            buyingPrice,
            supplierCompensation,
            unitFunding,
            uiFields,
        } = calculateProductVariableFunding({
            product,
            featureAwareFactory,
            showOnlyMinimumPrices,
        });

        const recalculatedVariableFunding = {
            uiFields,
            buyingPrice,
            supplierCompensation,
            unitFundingValue: unitFunding,
        };

        const { funding } = product;
        const originalVariableFunding = get(funding, 'variableFunding', {});

        const updatedVariableFunding = merge(
            {},
            originalVariableFunding,
            recalculatedVariableFunding
        );

        set(product, 'funding.variableFunding', updatedVariableFunding);

        if (bulkAdd && bulkAddNewProductKeys.includes(product.productKey)) {
            product.funding = {
                ...product.funding,
                lumpFunding: [],
                lumpFundingTotal: 0,
                rebate: 0,
                rebateMultiplier: 0,
            };
            product.funding.variableFunding = {
                ...product.funding.variableFunding,
                sellInPeriod: { daysBefore: null, daysAfter: null },
                unitFundingValue: 0,
                buyingPrice: null,
                supplierCompensation: null,
                uiFields: {
                    onInvoiceType: null,
                    onInvoiceValue: null,
                    offInvoiceType: null,
                    offInvoiceValue: null,
                },
            };
        }

        return product;
    });
    return updatedProducts;
};

/**
 * Recalculates variable funding for a product based on manual supplier compensation changes.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const onSupplierCompensationChange = ({ product }) => {
    const currentFunding = product.funding.variableFunding;
    const currentUiFields = get(currentFunding, 'uiFields');
    const currentSupplierCompensation = get(currentFunding, 'supplierCompensation');
    const currentBuyingPrice = get(currentFunding, 'buyingPrice');
    // Clear offInvoiceType and offInvoiceValue
    const uiFields = {
        ...currentUiFields,
        offInvoiceType: null,
        offInvoiceValue: null,
    };

    return {
        uiFields,
        supplierCompensation: currentSupplierCompensation,
        unitFundingValue:
            getAggregatedFunding({
                buyingPrice: currentBuyingPrice,
                avgCost: product.onInvoiceCosts.avgCost,
                supplierCompensation: currentSupplierCompensation,
                standardWeight: product.standardWeight,
            }) || 0,
    };
};

/**
 * Recalculates variable funding for a product based on manual buying price changes.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const onBuyingPriceChange = ({ product }) => {
    const currentFunding = product.funding.variableFunding;
    const currentUiFields = get(currentFunding, 'uiFields');
    const currentBuyingPrice = get(currentFunding, 'buyingPrice');
    const currentSupplierCompensation = get(currentFunding, 'supplierCompensation');

    // Clear onInvoiceType and onInvoiceValue
    const uiFields = {
        ...currentUiFields,
        onInvoiceType: null,
        onInvoiceValue: null,
    };

    return {
        uiFields,
        buyingPrice: currentBuyingPrice,
        unitFundingValue:
            getAggregatedFunding({
                buyingPrice: currentBuyingPrice,
                avgCost: product.onInvoiceCosts.avgCost,
                supplierCompensation: currentSupplierCompensation,
                standardWeight: product.standardWeight,
            }) || 0,
    };
};

/**
 * Recalculates variable funding for a product based on BP function value changes.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const onInvoiceValueChange = ({ product, featureAwareFactory, showOnlyMinimumPrices }) => {
    const { buyingPrice, unitFunding } = calculateProductVariableFunding({
        product,
        featureAwareFactory,
        showOnlyMinimumPrices,
    });

    return {
        buyingPrice,
        unitFundingValue: unitFunding,
    };
};

/**
 * Recalculates variable funding for a product based on SC function value changes.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const offInvoiceValueChange = ({ product, featureAwareFactory, showOnlyMinimumPrices }) => {
    const { supplierCompensation, unitFunding } = calculateProductVariableFunding({
        product,
        featureAwareFactory,
        showOnlyMinimumPrices,
    });

    return {
        supplierCompensation,
        unitFundingValue: unitFunding,
    };
};

/**
 *
 * Recalculates variable funding for a product based on BP function change.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const onInvoiceTypeChange = ({ product, featureAwareFactory, showOnlyMinimumPrices }) => {
    const currentFunding = product.funding.variableFunding;
    const currentUiFields = get(currentFunding, 'uiFields');
    const currentSupplierCompensation = get(currentFunding, 'supplierCompensation');
    let newUiFields = {};
    let supplierCompensation = currentSupplierCompensation;

    // Clear offInvoiceType, offInvoiceValue and SC if offInvoiceType exists
    if (currentUiFields.offInvoiceType) {
        newUiFields = { offInvoiceType: null, offInvoiceValue: null };
        supplierCompensation = null;
    }
    // Clear onInvoiceValue and BP
    let buyingPrice = null;
    const uiFields = {
        ...currentUiFields,
        ...newUiFields,
        onInvoiceValue: calculateMargin({
            product,
            fundingFunction: get(currentUiFields, 'onInvoiceType'),
            featureAwareFactory,
            showOnlyMinimumPrices,
        }),
    };

    if (uiFields.onInvoiceValue !== get(currentUiFields, 'onInvoiceValue')) {
        buyingPrice = onInvoiceValueChange({
            product: {
                ...product,
                funding: {
                    variableFunding: {
                        ...currentFunding,
                        buyingPrice,
                        supplierCompensation,
                        uiFields,
                    },
                },
            },
            featureAwareFactory,
            showOnlyMinimumPrices,
        }).buyingPrice;
    }

    return {
        uiFields,
        buyingPrice,
        supplierCompensation,
        unitFundingValue:
            getAggregatedFunding({
                buyingPrice,
                avgCost: product.onInvoiceCosts.avgCost,
                supplierCompensation,
                standardWeight: product.standardWeight,
            }) || 0,
    };
};

/**
 * Recalculates variable funding for a product based on SC function change.
 *
 * @param {Object} product - The product to calculate the variable funding for.
 *
 * @returns {Object} - The product's new variable funding.
 */
const offInvoiceTypeChange = ({ product, featureAwareFactory, showOnlyMinimumPrices }) => {
    const currentFunding = product.funding.variableFunding;
    const currentUiFields = get(currentFunding, 'uiFields');
    const currentBuyingPrice = get(currentFunding, 'buyingPrice');
    let newUiFields = {};
    let buyingPrice = currentBuyingPrice;

    // Clear onInvoiceType, onInvoiceValue and BP if onInvoiceType exists
    if (currentUiFields.onInvoiceType) {
        newUiFields = { onInvoiceType: null, onInvoiceValue: null };
        buyingPrice = null;
    }
    // Clear offInvoiceValue and SC
    let supplierCompensation = null;
    const uiFields = {
        ...currentUiFields,
        ...newUiFields,
        offInvoiceValue: calculateMargin({
            product,
            fundingFunction: get(currentUiFields, 'offInvoiceType'),
            featureAwareFactory,
            showOnlyMinimumPrices,
        }),
    };

    if (uiFields.offInvoiceValue !== get(currentUiFields, 'offInvoiceValue')) {
        supplierCompensation = offInvoiceValueChange({
            product: {
                ...product,
                funding: {
                    variableFunding: {
                        ...currentFunding,
                        buyingPrice,
                        supplierCompensation,
                        uiFields,
                    },
                },
            },
            featureAwareFactory,
            showOnlyMinimumPrices,
        }).supplierCompensation;
    }

    return {
        uiFields,
        buyingPrice,
        supplierCompensation,
        unitFundingValue:
            getAggregatedFunding({
                buyingPrice,
                avgCost: product.onInvoiceCosts.avgCost,
                supplierCompensation,
                standardWeight: product.standardWeight,
            }) || 0,
    };
};

const variableFundingCalculations = {
    supplierCompensation: {
        onChange: onSupplierCompensationChange,
    },
    buyingPrice: {
        onChange: onBuyingPriceChange,
    },
    onInvoiceType: {
        onChange: onInvoiceTypeChange,
    },
    offInvoiceType: {
        onChange: offInvoiceTypeChange,
    },
    onInvoiceValue: {
        onChange: onInvoiceValueChange,
    },
    offInvoiceValue: {
        onChange: offInvoiceValueChange,
    },
};

// returns the value of a number rounded to the nearest integer (20.5) // 21
// for negative value returns nearest integer - 1 (-20.5); // -21
const roundToPrecisionWithSign = (number, precision) => {
    if (number < 0) {
        return -round(-number, precision);
    }
    return round(number, precision);
};

/**
 * Rounds the funding values for each product in a promotion.
 * @param {Object} promotion - The promotion object.
 * @param {Function} roundFunc - The rounding function to use.
 * @returns {Object} - The updated promotion object with rounded funding values.
 */
const roundPromotionFundings = (promotion, roundFunc) => {
    const { products } = promotion;
    if (!Array.isArray(products)) {
        return promotion;
    }
    const newProducts = products.map(product => {
        const { supplierCompensation, unitFundingValue, buyingPrice } = get(
            product,
            'funding.variableFunding',
            {}
        );
        return {
            ...product,
            funding: {
                ...product.funding,
                variableFunding: {
                    ...product.funding.variableFunding,
                    supplierCompensation: supplierCompensation && roundFunc(supplierCompensation),
                    unitFundingValue: unitFundingValue && roundFunc(unitFundingValue),
                    buyingPrice: buyingPrice && roundFunc(buyingPrice),
                },
            },
        };
    });

    promotion.products = newProducts;
    return {
        ...promotion,
        products: newProducts,
    };
};

module.exports = {
    apportionMappings,
    defaultGridOptions,
    columnDefinition,
    variableFundingCalculations,
    calculatePromoPricesAndVariableFunding,
    calculateProductVariableFunding,
    getAllFundingFunctions,
    isTotalRow,
    isSupplierRow,
    roundPromotionFundings,
    roundToPrecisionWithSign,
};
