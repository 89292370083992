'use  strict';

const mixin = {
    props: {
        context: Object,
        editMode: {
            type: Boolean,
            default: false,
        },
        addBtn: {
            type: Boolean,
            default: false,
        },
        editContext: Object,
    },

    methods: {
        close() {
            this.$refs.dialog.close();
        },

        async save() {
            const { error } = await this.$refs.form.submit();
            if (!error) {
                this.close();
                return true;
            }
        },
    },
};

export default mixin;
