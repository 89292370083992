<template>
    <div class="page">
        <div class="page__filter-bar">
            <div class="page__filter-bar--placeholder" />
            <!--Was commented out according PROWEB-1359-->
            <!--<filter-bar />-->
        </div>
        <aside class="page__details-sidebar elevation-2">
            <div class="sidebar-wrapper">
                <h2 class="sidebar-wrapper__heading">
                    {{ $t('landing.detailsSidebarTitle') | toSentenceCase }}
                </h2>
            </div>
        </aside>
        <div class="page__content">
            <div class="top">
                <div class="top__alerts">
                    <div class="alerts-wrapper">
                        <h2 class="alerts-wrapper__heading">
                            {{ $t('landing.alertsTitle') | toSentenceCase }}
                        </h2>
                        <landing-page-alerts class="alerts-wrapper__content" />
                    </div>
                </div>
                <div class="top__notifications">
                    <div class="notifications-wrapper">
                        <h2 class="notifications-wrapper__heading">
                            {{ $t('landing.notifications.notifications') | toSentenceCase }}
                        </h2>
                        <notifications-list class="notifications-wrapper__content" />
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom__last-viewed" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {},
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.page {
    height: calc(100vh - #{$main-navbar-height} - #{$main-footer-height});

    $filter-bar-height: 7rem;

    &__filter-bar {
        padding: 1rem;
        height: $filter-bar-height;

        &--placeholder {
            @include promo-component-border($top: true);
            width: 100%;
            background-color: $promo-grey-blue-tint;
            height: 5.3rem;
        }
    }
    &__details-sidebar {
        position: relative;
        width: $details-sidebar-width;
        height: calc(100% - #{$filter-bar-height});
        float: right;
        z-index: $details-sidebar-z-index;
        background-color: $promo-white;

        .sidebar-wrapper {
            padding: 1rem;
            &__heading {
                color: $promo-text-colour;
            }
        }
    }
    &__content {
        $content-margin: 1rem;
        margin-left: $content-margin;
        height: calc(100% - #{$filter-bar-height});
        width: calc(100% - #{$details-sidebar-width} - #{$content-margin});
        background-color: $promo-white;
        display: flex;
        flex-wrap: wrap;

        .top {
            flex: 0 1 auto;
            min-height: 50%;
            max-height: 65%;
            width: 100%;
            border-bottom: 0.1rem solid $promo-grey-4;

            &__alerts {
                width: 75%;
                height: 100%;
                float: left;

                .alerts-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    &__heading {
                        padding: 1rem;
                        color: $promo-text-colour;
                    }
                    &__content {
                        flex: 0 1 auto;
                        border-top: 0.1rem solid $promo-grey-4;
                        overflow-y: auto;
                    }
                }
            }
            &__notifications {
                height: 100%;
                width: 25%;
                float: right;
                border-left: 0.1rem solid $promo-grey-4;
                overflow-y: auto;

                .notifications-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    &__heading {
                        padding: 1rem;
                        color: $promo-text-colour;
                    }
                    &__content {
                        border-top: 0.1rem solid $promo-grey-4;
                        overflow-y: auto;
                    }
                }
            }
        }
        .bottom {
            flex: 0 1 auto;
            min-height: 35%;
            max-height: 50%;
            width: 100%;

            &__last-viewed {
                height: 100%;
            }
        }
    }
}
</style>
