<template>
    <div class="page">
        <tabs :tabs="tabs" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import routeNames from '@enums/route-names';
import guardsMap from './supplier-guards-map';
import navigation from '@/js/navigation';

export default {
    beforeRouteUpdate(to, from, next) {
        if (to.name === from.name) {
            // navigating to the same route only params has changed
            // manualy fire beforeEntered hook because it is not called automatically
            guardsMap[to.name](to, from, next);
        } else {
            next();
        }
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        tabs() {
            const tabs = [
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('supplierTabs.supplierCommitments')
                    ),
                    disabled: false,
                    component: 'supplier-commitments-viewer',
                    navigateTo: navigation.toSupplierCommitmentsView,
                    routeNames: [
                        routeNames.supplierCommitmentsView,
                        routeNames.supplierCommitmentView,
                    ],
                },
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('supplierTabs.variableFunding')
                    ),
                    disabled: false,
                    component: 'variable-funding',
                    navigateTo: navigation.toVariableFundingView,
                    routeNames: [routeNames.variableFundingView],
                },
            ];

            return tabs;
        },
    },
};
</script>

<style scoped lang="scss">
.page {
    height: 100%;
    margin-top: 2rem;
    padding: 0 1rem;
}
</style>
