<template>
    <div class="message-title py-2">
        <div>
            <div class="warning-text">{{ $t('planning.warnings.warning') | toSentenceCase }}!</div>
            <div>
                {{
                    $t(translationPath, {
                        entity: $tc(`entities.${resource}`, 1),
                        number: numberOfChildren,
                        entityPlural: $tc(`entities.${resource}`, numberOfChildren),
                    }) | toSentenceCase
                }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        resource: String,
        numberOfChildren: Number,
        hasBeenApproved: Boolean,
    },
    computed: {
        translationPath() {
            return !this.hasBeenApproved
                ? 'planning.warnings.deletingDirectParentWarning'
                : 'planning.warnings.deletingDirectParentOrphanWarning';
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.warning-text {
    color: $negative-action;
}
.information-text {
    color: $positive-action;
}
.message-title {
    font-size: 1.4rem;
}
</style>
