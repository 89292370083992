var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scenario-sidebar flex-row" }, [
    _c("div", { staticClass: "scenario-sidebar__metrics flex-grow flex-row" }, [
      _c(
        "div",
        { staticClass: "flex-grow" },
        [
          _c("losenge-monetary-display", {
            staticClass: "margin-right flex-grow",
            attrs: {
              "incremental-amount": _vm.metrics.incrementalSalesExcTax,
              efficiency: _vm.metrics.salesEfficiency,
              compact: _vm.compact,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-grow" },
        [
          _c("losenge-monetary-display", {
            staticClass: "flex-grow",
            attrs: {
              "incremental-amount": _vm.metrics.incrementalMargin,
              efficiency: _vm.metrics.marginEfficiency,
              compact: _vm.compact,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "scenario-sidebar__icons flex-shrink flex-row" },
      [
        _vm.generalConfig.showWorkflowDebugIcon
          ? _c("workflow-state", {
              attrs: {
                "entity-id": _vm.scenario._id,
                "workflow-entity": _vm.workflowEntities.scenario,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("promotion-copy-dialog", {
          key: `copyPromotionDialog::${_vm.scenario._id}`,
          ref: "campaign_copy_form_dialog",
          attrs: { promotions: _vm.promotions },
        }),
        _vm._v(" "),
        _c(
          "feature-toggle",
          { attrs: { toggle: _vm.canDeleteScenario } },
          [
            !(
              _vm.generalConfig.oneScenarioForAllCategories &&
              _vm.scenario.isFavourite
            )
              ? _c("scenario-delete-dialog", {
                  attrs: {
                    resource: _vm.scenariosEnum,
                    "resource-id": _vm.scenario._id,
                    "edit-context": _vm.scenario,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("main-expand-button", {
          staticClass: "scenario-sidebar__expand-btn",
          attrs: { "is-expanded": _vm.isExpanded },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }