import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "weeklyPlannerContainer", staticClass: "weekly-planner-container" },
    [
      _c(
        "div",
        { staticClass: "weekly-planner-wrapper" },
        [
          _c(
            "div",
            { staticClass: "planner-title" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.planner) + ": " + _vm._s(_vm.viewLabel)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "planner-title__info" }),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.canEditCampaign } },
                [
                  !_vm.isReadOnly && !_vm.isCampaignSelected
                    ? _c("campaign-form-dialog", {
                        attrs: { context: _vm.newCampaign, "add-btn": "" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isCampaignSelected
                ? _c(
                    VBtn,
                    {
                      attrs: { color: "primary", depressed: "" },
                      on: { click: _vm.viewAllCampaigns },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold white--text" },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$tkey("allCampaigns")
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "planner-title__refresh-weekly-metrics" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: _vm.loadingWeeklyMetrics,
                        depressed: "",
                        "x-small": "",
                      },
                      on: { click: _vm.fetchWeeklyMetrics },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold white--text" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$tkey("refreshWeeklyMetrics")) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.loadingWeeklyMetrics
                        ? _c("spinner-dynamic", { staticClass: "spinner ml-2" })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loadingWeeklyMetrics
                        ? _c(
                            VIcon,
                            { staticClass: "ml-1", attrs: { small: "" } },
                            [_vm._v("mdi-refresh")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("span", { staticClass: "planner-title__delta" }, [
                    _vm._v("∆:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    VBtnToggle,
                    {
                      attrs: {
                        value: _vm.comparableWeek,
                        mandatory: "",
                        dense: "",
                      },
                      on: { change: _vm.setComparableWeek },
                    },
                    [
                      _c(VBtn, { attrs: { secondary: "" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t("general.dates.weekFirstLetter")
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(VBtn, { attrs: { secondary: "" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toAllUppercaseCase")(
                                _vm.$t("general.dates.comparableWeek")
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "weekly-planner weekly-planner--header",
              style: _vm.weeklyPlannerStyles,
            },
            _vm._l(_vm.weeks, function (weekObj, index) {
              return _c("campaigns-weekly-view-header", {
                directives: [
                  {
                    name: "observe-visibility",
                    rawName: "v-observe-visibility",
                    value: {
                      callback: _vm.visibilityChanged(weekObj),
                    },
                    expression:
                      "{\n                    callback: visibilityChanged(weekObj),\n                }",
                  },
                ],
                key: "weekHeader" + weekObj.weekOfYear + weekObj.year,
                style: _vm.weekGridCss(index),
                attrs: {
                  "week-obj": weekObj,
                  "current-week": _vm.currentWeek,
                  "current-year": _vm.currentYear,
                  "display-summary-kpis": _vm.displaySummaryKpis,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "weekly-planner weekly-planner--background",
              style: _vm.weeklyPlannerStyles,
            },
            _vm._l(_vm.weeks, function (weekObj, index) {
              return _c("div", {
                key: "week" + weekObj.weekOfYear + weekObj.year,
                staticClass: "week",
                style: _vm.weekGridCss(index),
              })
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "weekly-planner weekly-planner--campaigns",
              style: _vm.weeklyPlannerStyles,
            },
            [
              _c("div", { style: _vm.emptyRowCss }, [_vm._v(" ")]),
              _vm._v(" "),
              _vm._l(_vm.campaignsInView, function (campaign) {
                return _c("promo-planner-container", {
                  key: campaign._id,
                  attrs: {
                    campaign: campaign,
                    "planner-period-years-included-next-year":
                      _vm.plannerPeriodYearsIncludedNextYear,
                    "days-shift-per-year": _vm.daysShiftPerYear,
                    "first-planner-day-numeric": _vm.firstPlannerDay,
                    "last-planner-day-numeric": _vm.lastPlannerDay,
                    "first-planner-day-date": _vm.firstWeekObj.startDate,
                    "last-planner-day-date": _vm.lastWeekObj.endDate,
                    "campaign-read-only": _vm.campaignEditingState(
                      campaign._id
                    ),
                    "sub-campaigns-read-only-by-id":
                      _vm.subCampaignsEditingStateById(campaign._id),
                  },
                  on: {
                    "campaign-opened": _vm.campaignOpened,
                    "campaign-duplicated": _vm.campaignDuplicated,
                    "sub-campaign-opened": _vm.subCampaignOpened,
                  },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("campaign-form-dialog", {
            key: `campaignDialog::${_vm.selectedCampaign._id}::${_vm.campaignToggle}`,
            ref: "campaign_form_dialog",
            attrs: {
              "edit-mode": true,
              "has-activator": false,
              context: _vm.newCampaign,
              "edit-context": _vm.selectedCampaign,
              "make-read-only": _vm.selectedCampaignEditingState.disabled,
              "read-only-reason": _vm.selectedCampaignEditingState.reason,
              "preparation-view": _vm.isPreparationView,
            },
          }),
          _vm._v(" "),
          _c("sub-campaign-form-dialog", {
            key: `subCampaignDialog::${_vm.selectedSubCampaign._id}::${_vm.subCampaignToggle}`,
            ref: "subcampaign_form_dialog",
            attrs: {
              "edit-mode": true,
              "has-activator": false,
              context: _vm.selectedCampaign,
              "edit-context": _vm.selectedSubCampaign,
              "make-read-only": _vm.selectedSubCampaignEditingState.disabled,
              "read-only-reason": _vm.selectedSubCampaignEditingState.reason,
              "preparation-view": _vm.isPreparationView,
            },
            on: { "sub-campaign-selected": _vm.subCampaignSelected },
          }),
          _vm._v(" "),
          _c("campaign-copy-dialog", {
            key: `copyCampaignDialog::${_vm.selectedCampaign._id}`,
            ref: "campaign_copy_form_dialog",
            attrs: {
              "has-activator": false,
              "campaign-id": _vm.selectedCampaign._id,
              "start-date": _vm.newCampaign.startDate,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }