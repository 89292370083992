import Vue from 'vue';
import axios from 'axios';
import to from 'await-to-js';
import { fieldTypes } from '@enums/vuex-form';
import {
    camelCase,
    get,
    merge,
    some,
    find,
    includes,
    range,
    isNil,
    size,
    has,
    forEach,
    set,
    filter,
} from 'lodash';
import i18n from '../../vue-i18n';
import storeMixin from '@/js/store/mixins/vuex-store';

function isNormalPromotionCandidate(product) {
    return !product.isProposed && !product.proxyProductKey;
}

const getInitialState = () => ({
    promotionCandidates: [],
    promotionCandidatesCached: {},
    selectedCategory: null,
    // filterTypes - parent product(s) / PGs filters, e.g. filterTypes: ["suppliers"]
    // should always display 1 parent filter by default
    filterTypes: [null],
    userFilterDefinitions: [
        {
            filterText: i18n.t('filters.candidates.supplier'),
            filterKey: 'supplier',
            filterType: fieldTypes.select,
            filterValueOptionsFunction(candidate) {
                return candidate.products
                    ? candidate.products.map(product => ({
                          value: product.supplierKey,
                          text: product.supplierName,
                      }))
                    : {
                          value: candidate.supplierKey,
                          text: candidate.supplierName,
                      };
            },
            customFilterFunction(candidate, supplierKeys) {
                if (candidate.products) {
                    // If a products property exists, then the candidate is a product group.
                    // Return true if any of the products in the group belongs to one of the
                    // filtered suppliers.
                    return some(candidate.products, function(product) {
                        return supplierKeys.some(
                            supplierKey => supplierKey === product.supplierKey
                        );
                    });
                }
                // Otherwise, the candidate is a product, so return true if it belongs to
                // one of the filtered suppliers.
                return supplierKeys.some(supplierKey => supplierKey === candidate.supplierKey);
            },
        },
        {
            filterText: i18n.t('filters.candidates.name'),
            filterKey: 'name',
            filterType: fieldTypes.text,
            customFilterFunction(candidate, filterText) {
                const productMatchesFilterText = product => {
                    return product.name
                        .toLocaleLowerCase()
                        .includes(filterText.toLocaleLowerCase());
                };

                // if PG then return true if any satisfy the filter
                if (candidate.products) {
                    return some(candidate.products, productMatchesFilterText);
                }
                return productMatchesFilterText(candidate);
            },
        },
        {
            filterText: i18n.t('filters.candidates.productKey'),
            filterKey: 'clientProductKey',
            filterType: fieldTypes.text,
            customFilterFunction(candidate, filterText) {
                const productMatchesFilterText = product => {
                    if (!has(product, 'clientProductKey')) {
                        return false;
                    }
                    return product.clientProductKey
                        .toLocaleLowerCase()
                        .includes(filterText.toLocaleLowerCase());
                };

                // if PG then return true if any satisfy the filter
                if (candidate.products) {
                    return some(candidate.products, productMatchesFilterText);
                }
                return productMatchesFilterText(candidate);
            },
        },
        // construct the hierarchy filters
        // TODO: replace with map of hierarchy data once we've figured out what products _should_ have
        ...range(1, 5).map(level => ({
            filterText: i18n.tc(`hierarchy.hierarchyLevels.${level}`, 1),
            filterKey: `hierarchy.level${level}`,
            filterType: fieldTypes.select,
            filterValueOptionsFunction(candidate) {
                const getProductHierarchyOption = product => {
                    const hierarchyLevelDetails = find(product.hierarchy, { level }) || {};
                    return {
                        value: hierarchyLevelDetails.levelEntryKey,
                        text: hierarchyLevelDetails.levelEntryDescription,
                    };
                };
                return (candidate.products ? candidate.products : [candidate])
                    .map(product => getProductHierarchyOption(product))
                    .filter(({ text, value }) => !isNil(text) && !isNil(value));
            },
            customFilterFunction(candidate, selectedLevelOptions) {
                if (candidate.products) {
                    // If a products property exists, then the candidate is a product group.
                    // Return true if any of the products in the group belongs to one of the hierarchy level
                    return candidate.products.some(product => {
                        const hierarchyLevelDetails = find(product.hierarchy, {
                            level,
                        });
                        if (!product.hierarchy || !hierarchyLevelDetails) {
                            return false;
                        }
                        return selectedLevelOptions.some(
                            item => item === hierarchyLevelDetails.levelEntryKey
                        );
                    });
                }

                const hierarchyLevelDetails = find(candidate.hierarchy, {
                    level,
                });
                if (!candidate.hierarchy || !hierarchyLevelDetails) {
                    return false;
                }
                return selectedLevelOptions.some(
                    item => item === hierarchyLevelDetails.levelEntryKey
                );
            },
        })),
    ],

    // This is used to keep track of what product groups are currently open.
    // It follows a similar patter to how the stagingArea works where whenever
    // a candidates-list component is rendered it creates a namespace in this object
    // for that specific area of the tool.
    productGroupOpenState: {},
    initialLoading: false,
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter - actual filter, e.g. filter: {suppliers: [1, 2], name: 'testName'}
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getPromotionCandidateById
     */
    getters: {
        getSelectedCategory(state) {
            return state.selectedCategory;
        },
        promotionCandidateProductsMap: state => {
            const promotionCandidateProductsMap = {};
            forEach(state.promotionCandidates, candidate => {
                if (candidate.productKey) {
                    promotionCandidateProductsMap[candidate.productKey] = candidate;
                }
            });
            return promotionCandidateProductsMap;
        },
        filteredUserFilterDefinitions(state, getters, rootState) {
            return state.userFilterDefinitions.filter(userFilterDefinition => {
                const excludedFilters = get(
                    rootState,
                    'clientConfig.toggleLogic.productFilterExcludedFields'
                );
                return !(excludedFilters || []).includes(userFilterDefinition.filterKey);
            });
        },
        // override common getter from vuex-filter-store-add-ons.js
        getFilteredPromotionCandidates: (state, getters) => {
            const candidates = [];
            const isProductPromotable = product => {
                if (product.isPromotable) {
                    if (product.proxyProductKey) {
                        const proxyProduct =
                            getters.promotionCandidateProductsMap[product.proxyProductKey];
                        // products => show just promotable products with promotable proxy
                        return proxyProduct && proxyProduct.isPromotable;
                    }
                    // products => show just promotable products without proxy
                    return true;
                }
                return false;
            };

            // filter promotionCandidates by isPromotable field
            forEach(state.promotionCandidates, candidate => {
                if (isNil(candidate.products)) {
                    // products => show just promotable products
                    if (isProductPromotable(candidate)) {
                        candidates.push(candidate);
                    }
                    return;
                }
                // product groups => show just promotable products in PG or
                // hide PG if it contains just one unavailable product
                const promotableProductGroupProducts = filter(candidate.products, product =>
                    isProductPromotable(product)
                );
                if (promotableProductGroupProducts.length) {
                    const updatedProductGroup = {
                        ...candidate,
                        products: promotableProductGroupProducts,
                    };
                    candidates.push(updatedProductGroup);
                }
            });
            return candidates;
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setSelectedFilter
     * - resetFilter
     * - updatePromotionCandidate
     */
    mutations: {
        resetState(state, { excludedFields = [] } = {}) {
            // save state which we no need to reset
            const savedState = excludedFields.map(excludedField => ({
                field: excludedField,
                value: get(state, excludedField, null),
            }));
            // Resetting the state but the cached candidates
            const initialState = {
                ...getInitialState(),
                promotionCandidatesCached: state.promotionCandidatesCached,
            };
            // restore saved state
            savedState.forEach(savedItem => {
                if (savedItem.value) {
                    set(initialState, savedItem.field, savedItem.value);
                }
            });

            Object.assign(state, initialState);
        },
        setFilterTypes(state, value) {
            state.filterTypes = value;
        },
        updateProductGroupOpenState(state, { namespace, pgState }) {
            state.productGroupOpenState = {
                ...state.productGroupOpenState,
                [namespace]: {
                    ...state.productGroupOpenState[namespace],
                    ...pgState,
                },
            };
        },
        toggleProductGroup(state, { namespace, productGroupId, open }) {
            // Can only toggle if the namespace has been created.
            if (state.productGroupOpenState[namespace]) {
                Vue.set(state.productGroupOpenState[namespace], productGroupId, open);
            }
        },
        addUserFilterDefinitions(state, newFilterDefinitions) {
            state.userFilterDefinitions = [...state.userFilterDefinitions, ...newFilterDefinitions];
        },

        addPromotionCandidates(state, candidates) {
            state.promotionCandidates = [...state.promotionCandidates, ...candidates];
        },

        addPromotionCandidatesToCache(state, candidates) {
            const existingCandidates =
                state.promotionCandidatesCached[state.selectedCategory] || [];
            state.promotionCandidatesCached[state.selectedCategory] = [
                ...existingCandidates,
                ...candidates.filter(p => isNormalPromotionCandidate(p)),
            ];
        },
        resetCandidatesAndCache(state) {
            state.promotionCandidates = [];
            state.promotionCandidatesCached = {};
        },
        setCandidatesFromCache(state) {
            state.promotionCandidates = [
                ...state.promotionCandidatesCached[state.selectedCategory],
            ];
        },
        setPromotionCandidates(state, data) {
            state.promotionCandidates = data;
            state.promotionCandidatesCached[state.selectedCategory] = data.filter(p =>
                isNormalPromotionCandidate(p)
            );
        },
        setPromotionCandidatesToCache(state) {
            state.promotionCandidatesCached[
                state.selectedCategory
            ] = state.promotionCandidates.filter(p => isNormalPromotionCandidate(p));
        },
        setSelectedCategory(state, categoryId) {
            state.selectedCategory = categoryId;
        },
        setInitialLoading(state, initialLoading) {
            state.initialLoading = initialLoading;
        },
    },

    /**
     * Default actions available:
     * - fetchPromotionCandidates
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     */
    actions: {
        resetState({ commit, dispatch }, params) {
            const excludedFields = params ? get(params, 'excludedFields', []) : [];

            commit('resetState', { excludedFields });
            dispatch('createAttributeMetadataFilters');
        },
        async resetCandidatesAndCache({ commit }) {
            await commit('resetCandidatesAndCache');
        },
        setFilterTypes({ commit }, value) {
            commit('setFilterTypes', value);
        },
        async fetchPromotionCandidatesByScenarioCategory(
            { commit, dispatch, rootGetters },
            { categoryIds }
        ) {
            const isUnitProduct = !get(
                rootGetters['promotions/selectedPromotion'],
                'isWeightPromotion',
                false
            );
            const params = { where: { categoryIds, isUnitProduct } };

            commit('setLoading', true);
            const [error, response] = await to(
                axios.get('/api/products/promotion-candidates-by-scenario-category', { params })
            );
            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: 'promotion-candidates',
                    }),
                });
            } else commit(camelCase(`setPromotionCandidates`), response.data);
            commit('setLoading', false);
        },
        async fetchPromotionCandidatesByScenarioCategoryStreamed({
            commit,
            dispatch,
            state,
            rootGetters,
        }) {
            commit('setLoading', true);
            commit('setInitialLoading', true);

            let whereClause;
            const actionToCall = 'addPromotionCandidates';
            const categoryParam = JSON.stringify([state.selectedCategory]);
            const cachedCategory = !!state.promotionCandidatesCached[state.selectedCategory];
            const selectedPromotion =
                rootGetters['promotions/selectedPromotion'] ||
                rootGetters['promotions/getStagingAreaPromotionById']('default');
            const isUnitProduct = !get(selectedPromotion, 'isWeightPromotion', false);

            // If category is already in cache, we just fetch uncached products and merge the lists together.
            // Otherwise, we fetch every product for the selected category.
            // Uncached products = proposed and proxy products.
            if (!cachedCategory) {
                whereClause = { categoryIds: categoryParam, isUnitProduct };
            } else {
                commit('setCandidatesFromCache');
                whereClause = { categoryIds: categoryParam, onlyUncached: true, isUnitProduct };
            }

            // We have 2 loading flags
            // 1. For the first 50
            // 2. For the rest
            const params = new URLSearchParams(whereClause);

            const source = new EventSource(
                `/api/products/promotion-candidates-by-scenario-category-streamed?where=${params.toString()}`,
                {
                    withCredentials: true,
                }
            );

            source.addEventListener(
                'message',
                e => {
                    dispatch(actionToCall, JSON.parse(e.data));
                    dispatch('setInitialLoading', false);
                },
                false
            );

            source.addEventListener(
                'error',
                e => {
                    if (e.eventPhase === EventSource.CLOSED) {
                        source.close();
                        dispatch('setLoading', false);
                        dispatch('setInitialLoading', false);
                    }
                },
                false
            );
        },
        createAttributeMetadataFilters({ commit, rootState }) {
            const attributeMetadataFilterDefinitions = rootState.attributeMetadata.attributeMetadata.map(
                ({ attributeName, attributeSource }) => ({
                    filterText: `${attributeName}`,
                    filterKey: `attributes.${attributeName}.${attributeSource}`,
                    filterType: fieldTypes.select,
                    filterValueOptionsFunction(candidate) {
                        const options = [];
                        const products = candidate.products || [candidate];
                        products.forEach(product => {
                            const attr = find(product.attributes, {
                                attributeName,
                                attributeSource,
                            });

                            if (attr) {
                                const { attributeValue } = attr;
                                options.push({ text: attributeValue, value: attributeValue });
                            }
                        });

                        return options;
                    },
                    customFilterFunction(candidate, selectedAttributeValues) {
                        const productHasMatchingAttr = product => {
                            if (!product || size(product.attributes) < 1) return false;

                            const attribute = find(product.attributes, {
                                attributeName,
                                attributeSource,
                            });

                            return (
                                !!attribute &&
                                includes(selectedAttributeValues, attribute.attributeValue)
                            );
                        };

                        // if PG then return true if any satisfy the filter
                        if (candidate.products) {
                            return some(candidate.products, productHasMatchingAttr);
                        }
                        return productHasMatchingAttr(candidate);
                    },
                })
            );
            commit('addUserFilterDefinitions', attributeMetadataFilterDefinitions);
        },
        setSelectedCategory({ commit }, categoryId) {
            commit('setSelectedCategory', categoryId);
        },
        addPromotionCandidates({ commit }, candidates) {
            commit('addPromotionCandidates', candidates);
            commit('addPromotionCandidatesToCache', candidates);
        },
        setCandidatesFromCache({ commit }) {
            commit('setCandidatesFromCache');
        },
        updatePromotionCandidate({ commit }, { id, updates }) {
            commit('updatePromotionCandidate', { id, updates });
            commit('setPromotionCandidatesToCache');
        },
        setLoading({ commit }, loading) {
            commit('setLoading', loading);
        },
        setInitialLoading({ commit }, initialLoading) {
            commit('setInitialLoading', initialLoading);
        },
    },
};

const mixinParams = {
    resource: 'promotion-candidate',
    alternativeApiPath: 'products/promotion-candidates',
    useForm: true,
    useFilters: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
