var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-title py-2" }, [
    _c("div", [
      _c("div", { staticClass: "warning-text" }, [
        _vm._v(
          _vm._s(
            _vm._f("toSentenceCase")(_vm.$t("planning.warnings.warning"))
          ) + "!"
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n            " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t(_vm.translationPath, {
                  entity: _vm.$tc(`entities.${_vm.resource}`, 1),
                  number: _vm.numberOfChildren,
                  entityPlural: _vm.$tc(
                    `entities.${_vm.resource}`,
                    _vm.numberOfChildren
                  ),
                })
              )
            ) +
            "\n        "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }