var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "total-spend-viewer-target__grid" }, [
    _c("div", { staticClass: "total-year-target" }, [
      _c("div", { staticClass: "primary-target" }, [
        _c("span", { staticClass: "bold-text" }, [
          _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.$tkey("fundingTarget")))),
        ]),
        _vm._v(
          "\n            " + _vm._s(_vm.$tkey("selectedYear")) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "primary-target bold-text" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.getCurrencyValue(_vm.targetsData.yearTarget)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "bold-text" }, [
          _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.$tkey("totals")))),
        ]),
        _vm._v("\n            " + _vm._s(_vm.$tkey("toDate")) + "\n        "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "number-cell bold-text" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.getCurrencyValue(_vm.targetsData.commitmentTarget)) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "number-cell bold-text" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.getCurrencyValue(_vm.targetsData.executedTarget)) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "number-cell" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.getCurrencyValue(_vm.targetsData.plannedTarget)) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }