<template>
    <v-text-field hide-details class="rtls-search rtls-search--white" :placeholder="placeholder">
        <v-icon slot="append" small color="primary">search</v-icon>
    </v-text-field>
</template>

<script>
import vuexComponentMixin from '@/js/mixins/vuex-component';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    mixins: [vuexComponentMixin],
    props: {
        placeholder: {
            type: String,
            // i18n library is needed because `this.$t(..)` cannot be used as a prop default.
            default: toSentenceCase(i18n.t(`placeholders.search`)),
        },
    },
    methods: {
        change(value) {
            this.$emit('change', value);
        },
    },
};
</script>
