<template>
    <vuex-select
        :placeholder="$tkey('placeholder.selectProvision') | toSentenceCase"
        :options="params.options"
        :item-text="params.itemText"
        :item-value="params.itemValue"
        :getter="() => params.value"
        :sort-results="false"
        :setter="newValue => setValue(newValue)"
        :validate-on-blur="false"
        :clearable="params.clearable"
        css-class="ag-autocomplete"
        filled
        @change="onChange()"
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    localizationKey: 'planning.nominations.detailedProvision',
    computed: {},
    methods: {
        setValue(newValue) {
            // set up agGrid column value
            const colId = this.params.column.colId;
            this.params.node.setDataValue(colId, newValue);
        },

        onChange() {
            const { productKey } = this.params.node.data;
            this.params.onChange({
                productKey,
                model: this.params.value,
            });

            // Ensure any data changes made as part of onChange
            // are reflected in the actual cell values.
            this.params.api.refreshCells();
        },
    },
});
</script>

<style scoped lang="scss">
.ag-autocomplete {
    &::v-deep {
        .v-input__control .v-select__slot input {
            padding-left: 0.5rem;
        }
        .v-input__append-inner {
            padding-left: 0;
        }
    }
}
</style>
