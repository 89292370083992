export default [
    {
        key: 'templates',
        deps: [],
    },
    {
        key: 'categories',
        deps: ['templates'],
    },
    {
        key: 'promotions',
        deps: ['templates'],
    },
    {
        key: 'products',
        deps: ['templates', 'promotions'],
    },
];
