var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Channels / Shelf")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icon-/-Channels-/--Shelf",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Colour",
              d: "M7.86491093,0.101041515 C7.94242091,0.0357854364 8.04047757,0 8.14178387,0 L14.898976,0 C15.0786692,0 15.2393975,0.111773587 15.3019489,0.280229185 C15.364483,0.448682719 15.3156524,0.638267523 15.1794941,0.755536616 L10.6668933,4.64234577 L14.898976,4.64234577 C15.0786692,4.64234577 15.2393975,4.75412314 15.3019489,4.92257152 C15.364483,5.09103709 15.3156524,5.28061673 15.1794941,5.39787895 L10.6668933,9.28469154 L14.898976,9.28469154 C15.0786692,9.28469154 15.2393975,9.39646891 15.3019489,9.56491729 C15.364483,9.73338286 15.3156524,9.92296251 15.1794941,10.0402247 L8.42230191,15.8603508 C8.34429331,15.9275445 8.24474078,15.9645113 8.14178387,15.9645113 L1.22984683,15.9645113 C1.04927505,15.9645113 0.887954743,15.8516507 0.826049578,15.6820332 C0.807861572,15.6321881 0.7993946,15.5805721 0.7999784,15.5295751 C0.7999784,15.5284059 0.7999784,15.5272367 0.7999784,15.5260675 L0.7999784,10.8962217 C0.7999784,10.893677 0.7999784,10.8911323 0.7999784,10.8885876 L0.7999784,6.25856986 C0.7999784,6.25743506 0.7999784,6.25628307 0.7999784,6.25514828 C0.798513332,6.12897963 0.852716415,6.0055964 0.952979564,5.92117448 L7.86491093,0.101041515 Z M1.6596885,6.67981975 L1.6596885,9.9684403 L5.56523038,6.67981975 L1.6596885,6.67981975 Z M1.6596885,11.3221655 L1.6596885,14.6107861 L5.56523038,11.3221655 L1.6596885,11.3221655 Z M8.14178387,6.67981975 L6.89993918,6.67981975 L2.40768217,10.4624719 L7.98219034,10.4624719 L13.741261,5.50203943 L9.66878894,5.50203943 L8.42230191,6.57565927 C8.34429331,6.64285292 8.24474078,6.67981975 8.14178387,6.67981975 Z M8.29866077,0.859693662 L2.40768217,5.82012609 L7.98219034,5.82012609 L13.741261,0.859693662 L8.29866077,0.859693662 Z M6.89993918,11.3221655 L8.14178387,11.3221655 C8.24474078,11.3221655 8.34429331,11.2851987 8.42230191,11.218005 L9.66878894,10.1443852 L13.741261,10.1443852 L7.98219034,15.1048176 L2.40768217,15.1048176 L6.89993918,11.3221655 Z",
              fill: "#2F477C",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }