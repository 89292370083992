import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.supplierCommitmentTabOptions.length > 1
    ? _c(
        VBtnToggle,
        {
          attrs: { mandatory: "", dense: "" },
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        _vm._l(_vm.supplierCommitmentTabOptions, function (tabOption) {
          return _c(
            VBtn,
            { key: tabOption, attrs: { value: tabOption, secondary: "" } },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t(`supplierCommitments.toggles.${tabOption}`)
                    )
                  ) +
                  "\n    "
              ),
            ]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }