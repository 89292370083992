import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "rtls-textarea-container", class: _vm.cssClass },
    [
      _vm.label
        ? _c("p", { staticClass: "rtls-textarea-container__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(VTextarea, {
        staticClass: "rtls-textarea",
        attrs: {
          "data-id-e2e": _vm.dataIdE2E,
          rows: _vm.rows,
          outlined: true,
          "auto-grow": _vm.autoGrow,
          placeholder: _vm._f("toSentenceCase")(
            _vm.$t(_vm.placeholder) || _vm.$t("placeholders.enterHere")
          ),
          disabled: _vm.isDisabled,
          rules: _vm.rules,
          "no-resize": _vm.noResize,
          "validate-on-blur": "",
        },
        on: { blur: _vm.updateStagingArea },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }