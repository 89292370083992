import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "feature-toggler" }, [
    _c(
      "div",
      { staticClass: "feature-toggler__toggles" },
      _vm._l(
        { ..._vm.releaseFlags.releaseFlags, ..._vm.toggleLogic },
        function (value, key) {
          return _c(
            "div",
            { key: key, staticClass: "feature-toggler__toggles--toggle" },
            [
              _c("b", [_vm._v(_vm._s(key) + ":")]),
              _vm._v(" "),
              _vm.isBoolean(value)
                ? _c(VSwitch, {
                    attrs: { "input-value": value },
                    on: {
                      change: function ($event) {
                        return _vm.debouncedUpdateToggle({ key, value: $event })
                      },
                    },
                  })
                : _vm.isString(value)
                ? _c(VTextField, {
                    attrs: { value: String(value) },
                    on: {
                      input: function ($event) {
                        return _vm.debouncedUpdateToggle({ key, value: $event })
                      },
                    },
                  })
                : _vm.isObject(value) || _vm.isNumber(value)
                ? _c(VTextField, {
                    attrs: { value: JSON.stringify(value) },
                    on: {
                      input: function ($event) {
                        return _vm.debouncedUpdateToggle({
                          key,
                          value: $event,
                          parse: true,
                        })
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(value))]),
            ],
            1
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }