import tabStates from '@enums/tab-states';
import { every, some, groupBy, find } from 'lodash';

/**
 * Checks that the promo price at every tier for a product is less than its original non-promo price.
 * @param {Object} params
 * @param {Object} params.nonPromoPrices - The product's original non-promo prices.
 * @param {Object} params.promoPrices - The product promo prices at every tier.
 */
const productDiscountApplied = ({ nonPromoPrices = {}, promoPrices: tieredPromoPrices = [] }) => {
    // Check that a discount has been applied for this product at every tier.
    // minPrice has been arbitrarily chosen as the source of the comparison, but avg or max could
    // equally have been chosen, as these prices are always re-calculated when running the offer mechanic
    // calculator.
    // E.g for 'Save 50%': min: 1 -> 0.5, avg: 2 -> 1, max: 3 -> 1.5
    return every(
        tieredPromoPrices,
        tieredPromoPrice => tieredPromoPrice.minPrice < nonPromoPrices.minPrice
    );
};

/**
 * Validates the mechanic tab by checking that products have been discounted.
 * @param {Object} params
 * @param {Object} params.promotion - The promotion being validated.
 */
function validateMechanicTab({ promotion }) {
    const { products, offerMechanic } = promotion;

    if (!products || products.length === 0 || !offerMechanic) return tabStates.invalid;

    // If there is only one POG, then check at least one product has an offer applied.
    if (offerMechanic.tiers[0].productOfferGroups.length === 1) {
        return some(products, productDiscountApplied) ? tabStates.completed : tabStates.invalid;
    }

    // If we have multiple POGs, and one of them is a reward group type, then only check prices of reward group products.
    const rewardOfferGroup = find(
        offerMechanic.tiers[0].productOfferGroups,
        pog => pog.isRewardGroup
    );
    if (rewardOfferGroup) {
        const rewardProducts = products.filter(
            product => product.productOfferGroupId === rewardOfferGroup._id
        );

        return some(rewardProducts, productDiscountApplied)
            ? tabStates.completed
            : tabStates.invalid;
    }

    // Otherwise it is a grouped promotion, so check at least one product across every group is discounted.
    const groupedProducts = groupBy(products, 'productOfferGroupId');
    const allGroupsDiscounted = every(
        Object.keys(groupedProducts).map(pogId => {
            return some(groupedProducts[pogId], productDiscountApplied);
        }),
        Boolean
    );
    return allGroupsDiscounted ? tabStates.completed : tabStates.invalid;
}

export default {
    validateMechanicTab,
};
