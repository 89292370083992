<template>
    <div class="bookmarks-viewer">
        <!--Header section start-->
        <div class="bookmarks-viewer__top bookmarks-grid">
            <div
                v-for="(field, index) in bookmarkFields"
                :key="`bookmarkHeader::${index}`"
                :class="['bookmarks-viewer__header', field.headerClasses]"
            >
                <div v-if="field.header">
                    {{ $t(`bookmarks.headers.${field.header}`) | toSentenceCase }}
                </div>
            </div>
        </div>
        <!--Header section end-->
        <div class="bookmarks-viewer__bottom bookmarks-grid">
            <!-- bookmarks section start-->
            <template v-for="bookmark in bookmarks">
                <vuex-form-viewer
                    :key="bookmark._id"
                    :context="bookmark"
                    :fields="bookmarkFields"
                    :form="form"
                >
                    <!-- eslint-disable-next-line vue/no-lone-template -->
                    <template>
                        <div class="pl-3">{{ bookmark.name }}</div>
                        <div class="pl-3">{{ bookmark.description }}</div>
                        <div class="pl-3">{{ bookmark.createdOn | dateShorthand }}</div>
                        <div class="pl-3 author-container">
                            <v-avatar class="bookmarks-viewer__author-avatar" size="21">
                                <span class="white--text">{{ userToInitials(bookmark.user) }}</span>
                            </v-avatar>
                        </div>
                        <div class="pl-3">
                            <v-checkbox
                                class="rtls-checkbox ma-0"
                                hide-details
                                :input-value="isBookmarkLoaded(bookmark._id)"
                                @change="toggleBookmark(bookmark._id)"
                            />
                        </div>
                        <div class="pl-3">
                            <copy-button @copy="copyBookmarkUrl(bookmark._id)" />
                        </div>
                        <div
                            :key="`${bookmark._id}_delete`"
                            class="bookmark-cards-viewer__icons pl-3"
                        >
                            <common-delete-dialog
                                :resource="bookmarksEnum"
                                :child-dependencies-warning="false"
                                :ignore-parent-read-only="false"
                                @delete="handleDelete({ id: bookmark._id })"
                            />
                        </div>
                    </template>
                </vuex-form-viewer>
                <div :key="`${bookmark._id}_filler`" class="filler" />
            </template>
            <!-- bookmarks section end-->
            <!--New bookmark section start-->
            <vuex-form-viewer
                v-if="isNewBookmark"
                ref="newFormViewer"
                :context="{}"
                :fields="[]"
                :form="getFullForm()"
            >
                <div class="bookmarks-viewer__icons" />
                <div class="bookmarks-viewer__icons" />
                <div class="bookmarks-viewer__buttons">
                    <v-btn text class="cancel-button" @click="cancelNewBookmark">
                        {{ $t('actions.cancel') | toSentenceCase }}
                    </v-btn>
                    <simple-button :disabled="isSaveNewBookmarkDisabled" @onClick="saveBookmark()">
                        {{ $t('actions.save') | toSentenceCase }}
                    </simple-button>
                </div>
            </vuex-form-viewer>
            <!--New bookmark section End-->
        </div>
        <!--Create button-->
        <div class="bookmarks-viewer__new-bookmark">
            <create-new-button
                :btn-text="$tkey('newBookmarkButton') | toSentenceCase"
                :disabled="isNewBookmark"
                @createNew="addNewBookmark"
                @click="addNewBookmark"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { cloneDeep, get } from 'lodash';
import { fieldTypes } from '@enums/vuex-form';
import { bookmarks as bookmarksEnum } from '@enums/resources';
import bookmarkFields from './bookmark-fields';
import validators from '@/js/validators';
import navigation from '@/js/navigation';

export default {
    localizationKey: 'bookmarks',
    data() {
        return {
            bookmarksEnum,
            bookmarkFields: Object.freeze(bookmarkFields),
            isNewBookmark: false,
        };
    },
    computed: {
        ...mapGetters('bookmarks', ['getCurrentReportState']),
        ...mapState('bookmarks', ['bookmarks', 'validationStates']),
        isSaveNewBookmarkDisabled() {
            const namespace = 'default';
            return (
                !this.validationStates[namespace] ||
                (this.validationStates[namespace] && !this.validationStates[namespace].isValid)
            );
        },
        form() {
            return {
                vuexModule: 'bookmarks',
                editable: true,
                addEvent: 'saveBookmark',
                isStagingAreaResetNeeded: false,
                fields: [
                    {
                        fieldName: 'name',
                    },
                    {
                        fieldName: 'description',
                    },
                    {
                        fieldName: 'state',
                        editable: false,
                    },
                ],
            };
        },
    },
    methods: {
        ...mapActions('bookmarks', ['createBookmark', 'deleteBookmark']),
        ...mapActions('notifications', ['addNotification']),
        cancelNewBookmark() {
            this.isNewBookmark = false;
        },
        addNewBookmark() {
            this.isNewBookmark = true;
        },
        // https://github.com/euvl/v-clipboard/issues/18#issuecomment-733642508
        copyToClipboard(str) {
            const el = document.createElement('textarea');
            el.addEventListener('focusin', e => e.stopPropagation());
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        },
        copyBookmarkUrl(bookmarkId) {
            const route = this.$router.resolve({
                name: 'bookmark',
                params: { bookmarkId },
            });
            const url = `${window.location.origin}${route.href}`;
            try {
                this.copyToClipboard(url);
                this.addNotification({ message: this.$tkey('notifications.copied') });
            } catch (e) {
                this.addNotification({ message: this.$tkey('notifications.copyFailed') });
            }
        },
        userToInitials(user) {
            if (user) {
                const { firstname, surname } = user;
                return this.$options.filters.toSentenceCase(`${firstname[0]}${surname[0]}`);
            }

            return '';
        },
        async saveBookmark() {
            if (this.$refs.newFormViewer) {
                const { error } = await this.$refs.newFormViewer.submit();
                if (!error) {
                    this.isNewBookmark = false;
                }
            }
        },
        reset() {
            navigation.toReporting();
            this.$emit('reset');
        },
        toggleBookmark(bookmarkId) {
            // if unchecking box return to /reporting and reload the page
            // if checking box go to /reporting/bookmarkID
            return this.isBookmarkLoaded(bookmarkId) ? this.reset() : this.loadBookmark(bookmarkId);
        },
        isBookmarkLoaded(bookmarkId) {
            return bookmarkId === get(this.$route, 'params.bookmarkId');
        },
        loadBookmark(bookmarkId) {
            navigation.toBookmark({ bookmarkId });
        },
        handleDelete({ id }) {
            this.deleteBookmark({ id });
            if (this.isBookmarkLoaded(id)) this.reset();
        },
        getFullForm() {
            const form = cloneDeep(this.form);
            form.fields = [
                {
                    fieldName: 'name',
                    editable: false,
                    type: fieldTypes.text,
                    validations: [
                        {
                            validator: validators.maxLength,
                            params: [250],
                        },
                        {
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'description',
                    editable: false,
                    type: fieldTypes.text,
                },
                {
                    fieldName: 'state',
                    editable: false,
                    dynamicDefaultValue: this.getCurrentReportState,
                },
            ];

            return form;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.bookmarks-viewer {
    padding-left: 0.5rem;
    .bookmarks-grid {
        display: grid;
        grid-template-columns:
            20rem
            20rem
            20rem
            10rem
            5rem
            5rem
            5rem;
    }
    &__top {
        margin-bottom: 1.5rem;
    }

    &__header {
        padding-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $promo-text-colour;
        background-color: $promo-white;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
        border-bottom: $promo-divider-colour 0.1rem solid;
        &.last {
            grid-column-end: span 3;
            padding-right: 1.5rem;
        }
    }

    &__icons {
        @include flex-column;
        padding: 1.5rem 1rem;
        background-color: $promo-white;
        height: 100%;
    }

    &__new-bookmark {
        grid-column: 1 / span 6;
        border-top: $promo-divider-colour 0.1rem solid;
        padding: 1rem 0;
    }

    .filler {
        @include span-full-row;
        margin-bottom: 1.5rem;
    }

    &__buttons {
        @include span-full-row;
        background: $promo-white;
        padding-bottom: 1rem;
        text-align: right;
        padding-right: 2rem;

        .cancel-button {
            color: $promo-primary-colour;
            font-weight: 600;
        }
    }

    &__author-avatar {
        background-color: $promo-text-colour;
        font-size: 1rem;
        font-weight: 400;
    }

    ::v-deep {
        .vuex-form-viewer__grid-cell {
            @include flex-column;
            padding: 0.6rem 1rem;
            background-color: $promo-white;
            font-weight: 400;
            line-height: 3rem;
            font-size: 1.2rem;
            color: $promo-text-colour;
            letter-spacing: 0;
            height: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        .text-bold {
            font-weight: 600;
        }
        .v-input--is-disabled {
            .v-input__control > .v-input__slot:before {
                border-image: none !important;
                border: none !important;
            }
            .v-input__append-inner {
                display: none;
            }
        }

        .vuex-form-viewer__edit {
            form > div {
                margin: 0 !important;
            }
        }

        .vuex-from-grid {
            & > div {
                background: #fff;
                padding: 1rem 1rem 0.7rem;
                height: 100%;
            }
            .rtls-select-container {
                padding-top: 0.6rem;
            }
        }
    }
}
</style>
