import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ag-header-with-spinner" }, [
    _vm._v("\n    " + _vm._s(_vm.params.headerTitle) + "\n    "),
    _vm.loading
      ? _c(
          "span",
          { staticClass: "ag-header-with-spinner__spinner" },
          [
            _vm._v("\n        -\n        "),
            _c(VIcon, { staticClass: "fa-spin" }, [_vm._v("autorenew")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.params.loadingMessage))]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.params.isDataLimitReached()
      ? _c("span", { staticClass: "alert-banner" }, [
          _c(
            "span",
            { staticClass: "alert-banner__lock-icon" },
            [_c("icon", { attrs: { "icon-name": "warning" } })],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "alert-banner__locked-msg" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t(_vm.params.dataLimitReachedMessage)
                  )
                ) +
                "\n        "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }