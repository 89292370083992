var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("confirm-dialog", {
    ref: "dialog",
    attrs: {
      "question-text": _vm.$t(
        `dialogs.childPromotionGhost.header${
          _vm.isGhost ? "Reinstatiate" : "Decline"
        }`
      ),
      "action-text": _vm.$t("actions.ok"),
      "has-activator": true,
    },
    on: { confirm: _vm.confirm },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ activator }) {
          return [
            _vm.isGhost
              ? _c(
                  "icon",
                  _vm._g(
                    {
                      attrs: { "icon-name": "reinstatiate", small: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    activator
                  )
                )
              : _c(
                  "icon",
                  _vm._g(
                    {
                      attrs: { "icon-name": "remove", small: "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    activator
                  )
                ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }