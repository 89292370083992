var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vuex-currency-input", {
    class: _vm.params.cellClass,
    attrs: {
      getter: () => _vm.params.value,
      setter: (value) => _vm.setValue(value),
      filled: "",
      disabled: _vm.isDisabled,
      validations: _vm.validationRules,
      format: _vm.generalConfig.displaySupplierPrices3dp
        ? "numbers.default.currencyNoLabelPadded3dp"
        : "numbers.default.currencyNoLabelPadded",
      "is-validate-number": "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }