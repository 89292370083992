var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ag-editable-price-input" }, [
    _vm.isEditable
      ? _c(
          "div",
          { staticClass: "input-container" },
          [
            _c("vuex-number-input", {
              ref: "numberInput",
              staticClass: "input",
              attrs: {
                getter: () => _vm.value,
                setter: _vm.setValue,
                format: _vm.format,
                validations: _vm.validationRules,
                "positive-only": "",
              },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "span-container" }, [
          _c("span", [_vm._v(_vm._s(_vm.formattedValue))]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }