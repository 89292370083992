'use strict';

const { each, reduce } = require('lodash');

const dateUtilsFactory = function(moment) {
    /**
     * Gets the year and week for the supplied date.
     *
     * @param {Date} - The date to get the year and week from.
     * @returns {Object} - An object containing the year and week of the input date.
     */
    const getYearWeek = function(date) {
        const d = moment(date);

        return {
            year: d.format('YYYY'),
            week: d.format('ww'),
        };
    };

    /**
     * Number token representing a given week in a year. Can be used to easily compare weeks as is a number.
     * @returns Number - of the form <year><week number to 2 places>
     */
    const getYearWeekToken = function(date) {
        const { year, week } = getYearWeek(date);

        return Number(`${year}${week}`);
    };

    const getYearWeekMongoToken = function(week) {
        let { weekOfYear } = week;
        if (String(weekOfYear).length === 1) {
            weekOfYear = `0${weekOfYear}`;
        }

        return Number(`${week.year}${weekOfYear}`);
    };

    /**
     * Gets the year-week tokens inside a given year-week range.
     *
     * @param {Number} startWeekToken - The year-week token to get the weeks from.
     * @param {Number} endWeekToken - The year-week token to get the weeks until.
     * @param {Object} options - Configuration options.
     * @param {Number} options.padding - The number of weeks of padding to apply to the range.
     */
    const getWeeksBetweenTokens = function(startWeekToken, endWeekToken, { padding = 0 } = {}) {
        const startToken = startWeekToken - padding;
        const endToken = endWeekToken + padding;

        const tokens = [startToken];
        let nextToken = startToken;
        while (nextToken < endToken) {
            nextToken += 1;
            tokens.push(nextToken);
        }

        return tokens;
    };

    /**
     * Gets the year-week tokens inside a given year-week range.
     *
     * @param {Number} startDate - The start date to get year-week tokens from.
     * @param {Number} endDate - The end date to get year-week tokens until.
     * @param {Object} options - Configuration options.
     * @param {Number} options.padding - The number of weeks of padding to apply to the range.
     */
    const getWeeks = function(startDate, endDate, { padding = 0 } = {}) {
        const startToken = getYearWeekToken(startDate);
        const endToken = getYearWeekToken(endDate);

        return getWeeksBetweenTokens(startToken, endToken, { padding });
    };

    /**
     * Returns the number of days for a given year.
     * @param {Number} year - The year to determine the number of days from.
     */
    const getDaysInYear = function(year) {
        return moment()
            .year(year)
            .isLeapYear()
            ? 366
            : 365;
    };

    /**
     * Returns resource ids by week.
     * The function takes in resources and map it to weeks it belongs to.
     * Example:
     *      for promotions, the function will return an object of yearWeekKey-promotions pairs
     *      promotionIdsByWeek = {
     *          '202011': Set { '6049edf7d76006e6052ba898', '6049edf7d76006b7e82ba89b' },
     *          '202144': Set { '6049edf7d76006fcc42ba7f9', '6049edf7d76006ed562ba7f0' }
     *      }
     *
     * @param { resources } - the resource to map on (eg. campaigns, promotions)
     */
    const resourceIdsByWeek = function({ resources }) {
        return reduce(
            resources,
            (acc, resource) => {
                const { startDate, endDate, _id: resourceId } = resource;
                const weeks = getWeeks(startDate, endDate);
                each(weeks, week => {
                    acc[week] = (acc[week] || new Set()).add(resourceId);
                });
                return acc;
            },
            {}
        );
    };

    const campaignIdsByMongoWeeks = function({ resources }) {
        return reduce(
            resources,
            (acc, resource) => {
                const { startDateWeek, endDateWeek, _id } = resource;

                const startToken = getYearWeekMongoToken(startDateWeek);
                const endToken = getYearWeekMongoToken(endDateWeek);
                const padding = 0;

                const weeks = getWeeksBetweenTokens(startToken, endToken, { padding });
                each(weeks, week => {
                    acc[week] = (acc[week] || new Set()).add(_id);
                });
                return acc;
            },
            {}
        );
    };

    return {
        getYearWeek,
        getYearWeekToken,
        getWeeksBetweenTokens,
        getWeeks,
        getDaysInYear,
        resourceIdsByWeek,
        getYearWeekMongoToken,
        campaignIdsByMongoWeeks,
    };
};

module.exports = dateUtilsFactory;
