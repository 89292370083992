import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "main-sidebar elevation-2",
      class: `main-sidebar--${_vm.isExpanded ? "expanded" : "collapsed"}`,
    },
    [
      _c(
        "div",
        { staticClass: "main-sidebar__nav" },
        [
          _c(
            "div",
            { staticClass: "alert-list-counts" },
            _vm._l(
              _vm.alertsGroupedByConfiguredCategories,
              function (alertsGroup) {
                return _c("alert-count-badge", {
                  key: alertsGroup.key[0],
                  attrs: {
                    priority: alertsGroup.key[0],
                    "alert-count": alertsGroup.alerts.length,
                  },
                })
              }
            ),
            1
          ),
          _vm._v(" "),
          _c("main-expand-button", {
            attrs: {
              "is-expanded": _vm.isExpanded,
              "expanded-icon": "chevron_left",
              "collapsed-icon": "chevron_right",
              background: "",
            },
            on: { expand: _vm.toggleSidebar },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded",
            },
          ],
          staticClass: "main-sidebar__expanded-content",
        },
        [
          _c("promotion-sidebar-year-dates", {
            attrs: {
              "current-year": _vm.selectedYear,
              options: _vm.calendarOptions,
            },
          }),
          _vm._v(" "),
          _vm.hasActiveAlerts
            ? _c("workflow-task-alert-list", {
                staticClass: "main-sidebar__workflow-task-alert-list",
                attrs: { alerts: _vm.activeAlerts },
                on: { "sidebar-closed": _vm.hideSidebar },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(VDivider, { attrs: { horizontal: "" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-sidebar__notifications-list-header" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("landing.notifications.notifications")
                    )
                  ) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("notifications-list", {
            staticClass: "main-sidebar__notifications-list",
            attrs: { sidebar: "" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }