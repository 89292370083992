<template>
    <v-container class="icon-checkbox-container">
        <v-tooltip z-index="400" bottom :max-width="200" :disable="hideTooltip">
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <v-checkbox
                        v-model="model"
                        class="rtls-checkbox"
                        :value="value"
                        :label="label"
                        :disabled="isDisabled"
                        :on-icon="'$' + icon"
                        :off-icon="'$' + icon"
                        :rules="rules"
                        :hide-details="hideDetails"
                    />
                </span>
            </template>
            <span>{{ tooltipLabel | toSentenceCase }}</span>
        </v-tooltip>
    </v-container>
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        hideDetails: {
            type: Boolean,
            default: true,
        },
        hideTooltip: {
            type: Boolean,
            default: false,
        },
        icon: String,
        tooltipLabel: String,
        value: {
            String,
            default: null,
        },
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.icon-checkbox-container {
    &::v-deep .v-input--checkbox {
        &.rtls-checkbox {
            .v-input--selection-controls__input {
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid $promo-primary-colour;
                @include icon-border-radius;

                svg {
                    height: 1.4rem;
                    width: 1.4rem;
                    bottom: 0.4rem;
                    left: 0.4rem;
                    position: absolute;
                }

                input {
                    z-index: 1;

                    &[role='checkbox'] {
                        width: 2.5rem !important;
                        height: 2.5rem !important;
                    }
                }
            }
            &.v-input--is-disabled {
                .v-input--selection-controls__input {
                    border-color: $promo-grey-7;
                    background-color: $promo-white;

                    svg path,
                    svg text {
                        fill: $promo-grey-7;
                    }
                }
            }

            &.error--text {
                .v-input--selection-controls__input {
                    border-color: $vuetify-error-red;

                    svg path,
                    svg text {
                        fill: $vuetify-error-red;
                    }
                }
            }
        }

        &.v-input--is-label-active {
            .v-input--selection-controls__input {
                background-color: $promo-primary-colour;

                svg path,
                svg text {
                    fill: $promo-white;
                }
            }

            &.v-input--is-disabled {
                .v-input--selection-controls__input {
                    background-color: $promo-grey-7;

                    svg path,
                    svg text {
                        fill: $promo-white;
                    }
                }
            }
        }
    }
}
</style>
