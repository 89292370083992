var render, staticRenderFns
import script from "./suppliers-filter-bar-mixin.vue?vue&type=script&lang=js"
export * from "./suppliers-filter-bar-mixin.vue?vue&type=script&lang=js"
import style0 from "./suppliers-filter-bar-mixin.vue?vue&type=style&index=0&id=596d2c34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596d2c34",
  null
  
)

export default component.exports