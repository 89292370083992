'use strict';

/**
 * Enum definition for tab states in promotion-maintenance-area
 *
 * @type {{inProgress: string, notStarted: string, completed: string, invalid: string }}
 */
module.exports = {
    notStarted: 'notStarted',
    inProgress: 'inProgress',
    completed: 'completed',
    invalid: 'invalid',
};
