<template>
    <div
        :id="'week' + weekObj.weekOfYear + weekObj.year"
        :class="['week', selectedFadedClass]"
        :data-week="weekObj.weekOfYear"
        :data-year="weekObj.year"
    >
        <div
            class="week__heading"
            :class="{ active: weekObj.weekOfYear === currentWeek && weekObj.year === currentYear }"
        >
            <div>
                <span class="week__number">
                    <b>{{ currentWeekOfYear | weekNumberClient }}</b>
                    {{ weekObj.year }}
                </span>
                <span>
                    {{ weekObj.startDate | datesInYear }} -
                    {{ weekObj.endDate | datesInYear }}
                </span>
            </div>
            <promotion-rag-colours
                :vertical="true"
                :promotion-rag-colour-counts="effectivenessRatings"
                :display-total="true"
            />
            <div v-if="displaySummaryKpis" class="summary-kpis">
                <span>
                    {{ $t('general.dates.comparedAgainst') | toSentenceCase }}
                    <b>
                        {{ $t('general.dates.weekFirstLetter')
                        }}{{ isComparableWeekCW ? weekObj.mappedWeek : weekObj.weekOfYear }}
                    </b>
                    {{ isComparableWeekCW ? weekObj.mappedYear : weekObj.year - 1 }}
                </span>
                <campaigns-weekly-view-header-kpis
                    :header="$tkey('kpiSummary.header')"
                    is-header-row
                />
                <v-divider />
                <campaigns-weekly-view-header-kpis
                    :header="$tkey('kpiSummary.salesHeader')"
                    metric="actualSalesExcTax"
                    :week-obj="weekObj"
                    is-header-bold
                />
                <campaigns-weekly-view-header-kpis
                    :header="$tkey('kpiSummary.salesIncrementalHeader')"
                    metric="incrementalSalesExcTax"
                    :week-obj="weekObj"
                />
                <v-divider />
                <campaigns-weekly-view-header-kpis
                    :header="$tkey('kpiSummary.marginHeader')"
                    metric="promoMarginRate"
                    :week-obj="weekObj"
                    is-header-bold
                />
                <campaigns-weekly-view-header-kpis
                    :header="$tkey('kpiSummary.marginIncrementalHeader')"
                    metric="incrementalMargin"
                    :week-obj="weekObj"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { get, range } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';
import activeComponentMixin from '@/js/mixins/active-component';

export default {
    mixins: [activeComponentMixin('campaign', 'week')],
    localizationKey: 'planning.plannerView',
    props: {
        weekObj: {
            type: Object,
            required: true,
        },
        currentWeek: {
            type: Number,
            required: false,
        },
        currentYear: {
            type: Number,
            required: false,
        },
        displaySummaryKpis: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('campaigns', ['selectedCampaign']),
        ...mapGetters('clientConfig', ['getNumberOfEffectivenessRatingColours']),
        ...mapGetters('weeks', ['isComparableWeekCW']),
        ...mapState('promotions', ['weeklyMetrics']),

        selectedCampaignId() {
            return this.selectedCampaign ? this.selectedCampaign._id : null;
        },

        // override mixin computed
        isActive() {
            const weekStartDate = this.$moment(this.weekObj.startDate).utc();
            const weekEndDate = this.$moment(this.weekObj.endDate).utc();

            return (
                weekStartDate.isBetween(
                    this.$moment(this.selectedCampaign.startDate).utc(),
                    this.$moment(this.selectedCampaign.endDate).utc(),
                    undefined,
                    '[]'
                ) ||
                weekEndDate.isBetween(
                    this.$moment(this.selectedCampaign.startDate).utc(),
                    this.$moment(this.selectedCampaign.endDate).utc(),
                    undefined,
                    '[]'
                )
            );
        },

        effectivenessRatings() {
            const yearWeekKey = `${this.weekObj.year}${this.weekObj.weekOfYear}`;
            const numberOfEffectivenessRatings = this.getNumberOfEffectivenessRatingColours;

            if (!numberOfEffectivenessRatings) {
                throw new Error(
                    toSentenceCase(i18n.t('general.errors.missingEffectivenessRatingConfig'))
                );
            }

            // Generate the default effectiveness count object based upon the config. This will look something like:
            // { 1: 0, 2: 0, 3: 0 }
            // With the object keys as the effectiveness rating and the count for that rating defaulted to zero.
            // The second range parameter has 1 added to it because it generates numbers up to but not including the second parameter value.
            const defaultEffectivenessCounts = range(1, numberOfEffectivenessRatings + 1).reduce(
                (acc, value) => {
                    acc[value] = 0;
                    return acc;
                },
                {}
            );
            return get(
                this.weeklyMetrics[yearWeekKey],
                'effectivenessRatings',
                defaultEffectivenessCounts
            );
        },
        currentWeekOfYear() {
            return this.weekObj.weekOfYear;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.week {
    border-right: 0.1rem solid $promo-grey;

    &__heading {
        display: flex;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.8rem;
        color: $promo-text-colour;
        background-color: $promo-white-2;
        border-bottom: 0.1rem solid $promo-grey;
        padding: 0.5rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.active {
            border-bottom: 0.4rem solid $btn-ok-color;
        }
    }
    &__number {
        font-size: 1.4em;
    }
    .summary-kpis {
        width: 100%;
        padding-top: 1rem;
        text-align: start;
    }

    &.week--faded {
        .week__heading {
            opacity: 0.65;
        }
    }
}
</style>
