<template>
    <div v-if="isOnFundingTab">
        <funding-viewer
            :vuex-module="vuexModule"
            :field-name="fieldName"
            :namespace="computedNamespace"
            :getter="productsGetter"
            :form-ref="formRef"
            :is-parking-lot="isParkingLot"
            :is-past-promotions="isPastPromotions"
        />
        <tree-view-dialog
            ref="bulk-upload-errors-dialog"
            :errors="bulkUploadSupplierFundingErrors || {}"
        />
    </div>
</template>

<script>
import { get, values, isEmpty } from 'lodash';
import temporaryIds from '@enums/temporary-ids-for-new-resources';
import { funding } from '@enums/promotion-tabs';
import { mapActions, mapGetters, mapState } from 'vuex';
import vuexFormMixin from '@/js/mixins/vuex-form';

export default {
    events: {
        onSupplierFundingUploaded() {
            if (this.hasBulkUploadErrors) {
                this.$refs['bulk-upload-errors-dialog'].open();
            }
        },
    },
    mixins: [vuexFormMixin],
    props: {
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        cacheDom: {
            type: Boolean,
            default: false,
        },
        formRef: {
            type: Object,
            required: false,
        },
    },
    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapState('promotions', [
            'bulkUploadSupplierFundingErrors',
            'selectedPromotionMaintenanceTab',
        ]),

        isOnFundingTab() {
            return this.cacheDom || this.selectedPromotionMaintenanceTab === funding;
        },

        fieldName() {
            return this.fields[0].fieldName;
        },

        hasBulkUploadErrors() {
            return values(this.bulkUploadSupplierFundingErrors).some(errArr => !isEmpty(errArr));
        },
    },
    mounted() {
        const selectedPromotion = this.getStagingAreaPromotionById(this.computedNamespace);
        this.fetchSupplierCommitmentsForPromotion(selectedPromotion);
    },
    methods: {
        ...mapActions('supplierCommitments', ['fetchSupplierCommitmentsForPromotion']),
        productsGetter() {
            // Override the default getter of the vuex component mixin as we
            // need to filter out products that have been assigned to a product group
            // that is currently being created.
            return get(
                this.$store.state[this.vuexModule].stagingArea[this.computedNamespace],
                this.fieldName,
                []
            ).filter(product => product.productGroup !== temporaryIds.newProductGroupId);
        },
    },
};
</script>
