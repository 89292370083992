import routeNames from '@enums/route-names';
import tabNames from '@enums/tab-names';
import { maxBy, find } from 'lodash';
import router from './router';
import store from '@/js/store';

export default {
    toAllCampaignsView({ tabName = tabNames.planning }) {
        router.push({ name: routeNames[`${tabName}AllCampaignsView`] });
    },
    toCampaignView({ tabName = tabNames.planning, campaignId }) {
        router.push({ name: routeNames[`${tabName}SingleCampaignView`], params: { campaignId } });
    },
    toSubCampaignView({ tabName = tabNames.planning, subCampaignId }) {
        router.push({
            name: routeNames[`${tabName}SingleSubCampaignView`],
            params: { subCampaignId },
        });
    },
    async toFavouriteScenario({ subCampaign }) {
        let favScenario;
        const subCampaignId = subCampaign._id;
        await store.dispatch('scenarios/fetchScenariosForSubCampaign', subCampaign, {
            root: true,
        });
        const scenarios = store.getters['scenarios/getScenariosBySubCampaignId'](subCampaignId);
        if (store.state.clientConfig.generalConfig.oneScenarioForAllCategories) {
            favScenario = find(scenarios, 'isFavourite');
        } else {
            const favScenarios = scenarios.filter(i => i.isFavourite);
            favScenario = maxBy(favScenarios, scenario => scenario.categories.length);
        }

        const scenarioId = favScenario._id;
        router.push({ name: routeNames.scenarioView, params: { subCampaignId, scenarioId } });
    },
    toScenarioView({ scenarioId }) {
        router.push({ name: routeNames.scenarioView, params: { scenarioId } });
    },
    toPromotionsViewerView() {
        router.push({ name: routeNames.promotionsViewer });
    },
    toSupplierCommitmentsView({ category, supplier } = {}) {
        router.push({ name: routeNames.supplierCommitmentsView, query: { category, supplier } });
    },
    toSupplierCommitmentView({ supplierCommitmentId }) {
        router.push({ name: routeNames.supplierCommitmentView, params: { supplierCommitmentId } });
    },
    toVariableFundingView() {
        router.push({ name: routeNames.variableFundingView });
    },
    toRateCardView() {
        router.push({ name: routeNames.rateCardView });
    },
    toPromotionView({ promotionId }) {
        router.push({ name: routeNames.promotionView, params: { promotionId } });
    },
    toPromotionTabView({ tabName, promotionId }) {
        router.push({ name: routeNames.promotionTabView, params: { tabName, promotionId } });
    },
    // Admin area
    toFreeGiftsView() {
        router.push({ name: routeNames.freeGiftsView });
    },
    // admin loyalty points
    toLoyaltyPointsView() {
        router.push({ name: routeNames.loyaltyPointsView });
    },
    // Parking Lot area
    toParkingLotView() {
        router.push({ name: routeNames.parkingLotView });
    },
    toPastPromotionsView() {
        router.push({ name: routeNames.pastPromotionsView });
    },
    toSubCampaignAllocationView() {
        router.push({ name: routeNames.subCampaignAllocationView });
    },

    // Products area
    toProxyProductsView() {
        router.push({ name: routeNames.proxyProductsView });
    },
    toProductGroupsView() {
        router.push({ name: routeNames.productGroupsView });
    },

    // reporting
    toReporting() {
        router.push({ name: routeNames.reporting });
    },
    toBookmark({ bookmarkId }) {
        router.push({ name: routeNames.bookmark, params: { bookmarkId } });
    },
    // user permissions
    toUserPermissions() {
        router.push({ name: routeNames.userPermissions });
    },
    toAdminPage() {
        router.push({ name: routeNames.admin });
    },
    toClientSystemsTesting() {
        router.push({ name: routeNames.clientSystemsTesting });
    },
    toRedisBull() {
        router.push({ name: routeNames.redisBull });
    },
    toFeatureToggler() {
        router.push({ name: routeNames.featureToggler });
    },
};
