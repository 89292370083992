// Commented out according PROWEB-1359
// import FunctionProp from '@/js/utils/component-utils';

export default [
    {
        field: 'effectivenessRating',
        component: {
            name: 'promotion-rag-colour',
            contextualProps: {
                colour: 'effectivenessRating',
            },
        },
    },
    {
        field: 'date',
        component: {
            name: 'losenge-date-display',
            defaultProp: null,
            contextualProps: ['startDate', 'endDate'],
            props: {
                compact: true,
            },
        },
    },
    {
        functionName: 'getProductCount',
    },
    {
        field: 'offerMechanic.description',
    },
    // Commented out according PROWEB-1359
    // {
    //     cellClasses: ['last-column'],
    //     component: {
    //         name: 'main-expand-button',
    //         props: {
    //             isExpanded: false,
    //         },
    //         contextualProps: {
    //             promotionId: '_id',
    //         },
    //         functionProps: [new FunctionProp('isExpanded', ['_id'], 'isPromotionSelected')],
    //     },
    // },
];
