<script>
import { mapState, mapActions } from 'vuex';
import UXEvents from '@enums/ux-events';
import tabNames from '@enums/tab-names';
import navigation from '@/js/navigation';
import commonDeleteDialogComponent from './common-delete-dialog';

export default {
    extends: commonDeleteDialogComponent,

    computed: {
        ...mapState('subCampaigns', ['selectedSubCampaignId', 'selectedSubCampaignParentId']),

        labels() {
            return {
                deleteBtnText: 'actions.delete',
                deleteHeading: 'dialogs.deleteSubCampaign.header',
            };
        },

        btnDeleteId() {
            return 'deleteSubCampaignButton';
        },
    },

    methods: {
        ...mapActions('subCampaigns', ['deleteSubCampaignById']),

        async deleteSelectedItem() {
            await this.deleteSubCampaignById({ id: this.resourceId });

            // redirect to sub-campaign's parent if selected sub-campaign was deleted
            if (this.resourceId === this.selectedSubCampaignId) {
                navigation.toCampaignView({
                    tabName: tabNames.planning,
                    campaignId: this.selectedSubCampaignParentId,
                });
            }
            this.globalEmit(UXEvents.subCampaignDeleted);
            this.closeAll();
        },
    },
};
</script>
