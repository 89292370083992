import featureFlagEnums from '@enums/feature-flags';
import routeNames from '@enums/route-names';
import store from '@/js/store';
import SupplierPage from './supplier';
import guardsMap from './supplier-guards-map';

const defaultBeforeEnter = (to, from, next) => {
    guardsMap[to.name](to, from, next);
};
const routes = [
    {
        path: '/supplier',
        id: 'supplier',
        featureToggle: 'canAccessSupplierPage',
        component: SupplierPage,
        linkText: 'navBar.supplier',
        index: 2,
        meta: {
            canAccess: featureFlagEnums.canAccessSupplierPage,
            isSideBarExpanded: false,
        },
        async beforeEnter(to, from, next) {
            await store.dispatch('supplierCommitments/resetSupplierCommitmentsState');
            await Promise.all([
                store.dispatch('hierarchy/fetchHierarchy'),
                store.dispatch('storeGroups/fetchStoreGroups'),
            ]);

            next();
        },
        children: [
            // Default route to ensure that all '/supplier' requests are redirected to the '/supplier/commitments'.
            {
                path: '',
                name: 'supplier',
                redirect: { name: routeNames.supplierCommitmentsView },
            },
            {
                path: 'commitments',
                name: routeNames.supplierCommitmentsView,
                beforeEnter: defaultBeforeEnter,
                children: [
                    {
                        path: ':supplierCommitmentId',
                        name: routeNames.supplierCommitmentView,
                        beforeEnter: defaultBeforeEnter,
                    },
                ],
            },
            {
                path: 'variable-funding',
                name: routeNames.variableFundingView,
                beforeEnter: defaultBeforeEnter,
            },
        ],
    },
];

export default routes;
