<template>
    <main-expansion-panel :label="$t(`supplierCommitments.viewPromotions`)" :is-open-state="null">
        <div v-for="(promotionGroup, index) in promotions" :key="index" class="sp-promotions">
            <h3 v-if="promotionGroup.data.length > 0" class="sp-promotions__title">
                {{ $t(promotionGroup.label) | toSentenceCase }}
            </h3>
            <promotions-list :promotions="promotionGroup.data" :fields="fields" />
        </div>
    </main-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import fields from '../../../config/supplier-commitment-promotion-fields';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin],

    props: {
        supplierCommitment: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            fields,
        };
    },

    computed: {
        ...mapGetters('promotions', ['getSupplierCommitmentPromotions']),

        promotions() {
            return [
                {
                    data: this.getSupplierCommitmentPromotions.planned,
                    label: 'supplierCommitments.plannedPromotions',
                },
                {
                    data: this.getSupplierCommitmentPromotions.executed,
                    label: 'supplierCommitments.executedPromotions',
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
    .rtls-expansion-panel .rtls-expansion-panel-header .rtls-expansion-panel-header__label {
        font-size: 1.3rem;
        color: $promo-primary-colour;
    }
}
.sp-promotions {
    display: flex;
    padding-bottom: 2rem;

    &__title {
        width: 17rem;
        text-align: right;
        font-size: 1.3rem;
        padding-right: 1rem;
    }
}
</style>
