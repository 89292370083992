import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotion-forecasting" },
    [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(
          _vm._s(
            _vm._f("toSentenceCase")(
              _vm.$tkey(`header.${_vm.hasActuals ? "actual" : "forecast"}`)
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promotion-forecasting--column-divider" },
        [
          _c("span", { staticClass: "forecast-date" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(_vm.$tkey("header.lastForecastDate"))
                ) +
                ":\n            "
            ),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.lastForecastTime) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          !_vm.hasActuals
            ? _c("forecast-promotion-button", {
                attrs: {
                  promotion: _vm.promotion,
                  disabled: _vm.isDisabled || _vm.isPastPromotions,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "metrics" },
        [
          _vm._l(_vm.metricFields, function (metric, ix) {
            return [
              _vm.get(
                _vm.releaseFlags,
                ["releaseFlags", metric.releaseFlag],
                true
              )
                ? [
                    metric.isDivider
                      ? _c(VDivider, {
                          key: `metric--${ix}--divider`,
                          staticClass: "metrics__cell metrics__divider",
                        })
                      : [
                          _c(
                            "div",
                            {
                              key: `metric--${metric.nameKey}--type`,
                              staticClass: "metrics__cell metrics__type",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$tkey(`metrics.${metric.nameKey}`)
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              key: `metric--${metric.nameKey}--value`,
                              staticClass:
                                "metrics__cell metrics__value font-weight-bold",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.formattedValue(
                                      metric.numberFormatter.type,
                                      _vm.aggregations[metric.field]
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                  ]
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("forecast-details-dialog", {
        ref: "forecast-details-dialog",
        attrs: { getter: () => _vm.promotion.products },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }