var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "store-provision-grid-container" },
    [
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.rowData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 35rem;",
          "grid-class": "ag-theme-custom__candidates-grid",
          "dom-layout": "normal",
          "single-click-edit": true,
          "make-read-only": _vm.promotionEditingState.disabled,
          "read-only-reason": _vm.promotionEditingState.reason,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }