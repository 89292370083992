<template>
    <div class="flex promotions-orders">
        <div
            class="promotions-orders__caret promotions-orders__caret--up"
            :class="{ 'active-up': order === 'desc' }"
        />
        <div
            class="promotions-orders__caret promotions-orders__caret--down"
            :class="{ 'active-down': order === 'asc' }"
        />
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$caret-aside-border-width: 4px;
$caret-main-border-width: 5px;
.promotions-orders {
    &__caret {
        width: 0;
        height: 0;
        border-left: $caret-aside-border-width solid transparent;
        border-right: $caret-aside-border-width solid transparent;
        margin-left: 4px;
        cursor: pointer;

        &--up {
            border-bottom: $caret-main-border-width solid $promo-primary-colour;
            margin-bottom: 3px;
        }

        &--down {
            border-top: $caret-main-border-width solid $promo-primary-colour;
        }
    }

    .active-down {
        border-top-color: $active-sorting-color;
    }

    .active-up {
        border-bottom-color: $active-sorting-color;
    }
}
</style>
