var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "product",
      class: [
        _vm.data.staticClass,
        { "product--compact": _vm.props.layout.compact },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "full-flex-column-top" },
        [
          _c("badge", {
            attrs: {
              type: _vm.$options.methods.getBadgeType(_vm.props.product),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.props.layout.compact
        ? _c(
            "div",
            {
              staticClass: "product__slot full-flex-column-top",
              class: _vm.$options.methods.getFormattedLayout(
                _vm.props.layout,
                _vm.props.product
              ).prependSlot.cssClass,
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$options.methods.getFormattedLayout(
                      _vm.props.layout,
                      _vm.props.product
                    ).prependSlot.value
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "product__inner-grid" }, [
        _c(
          "div",
          {
            staticClass: "product__slot",
            class: _vm.$options.methods.getFormattedLayout(
              _vm.props.layout,
              _vm.props.product
            ).slotUpperLeft.cssClass,
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$options.methods.getFormattedLayout(
                    _vm.props.layout,
                    _vm.props.product
                  ).slotUpperLeft.value
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product__slot product__slot--right",
            class: _vm.$options.methods.getFormattedLayout(
              _vm.props.layout,
              _vm.props.product
            ).slotUpperRight.cssClass,
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$options.methods.getFormattedLayout(
                    _vm.props.layout,
                    _vm.props.product
                  ).slotUpperRight.value
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product__slot",
            class: _vm.$options.methods.getFormattedLayout(
              _vm.props.layout,
              _vm.props.product
            ).slotLowerLeft.cssClass,
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$options.methods.getFormattedLayout(
                    _vm.props.layout,
                    _vm.props.product
                  ).slotLowerLeft.value
                ) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product__slot truncate-text product__slot--right",
            class: _vm.$options.methods.getFormattedLayout(
              _vm.props.layout,
              _vm.props.product
            ).slotLowerRight.cssClass,
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.$options.methods.getFormattedLayout(
                    _vm.props.layout,
                    _vm.props.product
                  ).slotLowerRight.value
                ) +
                "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.props.draggable && !_vm.parent.isReadOnly
        ? _c(
            "div",
            { staticClass: "product__draggable full-flex-column-center" },
            [_c("icon", { attrs: { "icon-name": "cross-move", small: "" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.props.selectable
        ? _c(
            "div",
            { staticClass: "product__draggable full-flex-column-center" },
            [
              _c("vuex-checkbox", {
                attrs: {
                  getter: () => _vm.props.selected,
                  setter: (value) =>
                    _vm.$options.methods.productSelectorSetter(
                      value,
                      _vm.props.product,
                      _vm.listeners
                    ),
                  disabled: _vm.props.disabled,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }