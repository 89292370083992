<template>
    <v-tabs
        v-model="selectedTab"
        height="2.5rem"
        background-color="transparent"
        class="promo-tabs"
        active-class="promo-tabs__tab--active"
        hide-slider
        @change="onTabSwitch"
    >
        <template v-for="tab in tabs">
            <!-- Used to fill and style the space between tabs. -->
            <div :key="`spacer-${tab.label}`" class="promo-tabs__spacer" />
            <v-tab :key="`tab-${tab.label}`" class="promo-tabs__tab">
                {{ tab.label | toSentenceCase }}
            </v-tab>
        </template>

        <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
        <div class="promo-tabs__filler" />

        <v-tab-item
            v-for="tab in tabs"
            :key="`tab-item-${tab.label}`"
            :transition="false"
            :reverse-transition="false"
        >
            <div v-if="tab.component" class="nomination-tab">
                <component
                    :is="tab.component"
                    v-if="tab.rerender"
                    :key="rerender"
                    v-bind="tab.props"
                />
                <component :is="tab.component" v-else v-bind="tab.props" />
            </div>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import vuexComponentMixin from '@/js/mixins/vuex-component';

const localizationKey = 'planning.nominations';

export default {
    mixins: [vuexComponentMixin],

    props: {
        parentContextType: String,
        storeGroups: Object,
        resources: Object,
        defaultStoreGroups: Object,
        defaultResources: Object,
        onResourceChange: Function,
        createMode: Boolean,
    },

    data() {
        return {
            selectedTab: 0, // Set the first tab to be the default.
            rerender: 0, // Used to rerender a tab
        };
    },

    computed: {
        tabs() {
            return [
                {
                    label: this.$t(`${localizationKey}.provisionHeading`),
                    component: 'nomination-matrix',
                    props: this.$props,
                },
                {
                    label: this.$t(`${localizationKey}.defaultHeading`),
                    component: 'nomination-matrix',
                    rerender: true,
                    props: {
                        ...this.$props,
                        storeGroups: this.defaultStoreGroups,
                        resources: this.defaultResources,
                        onResourceChange: () => {}, // avoid both matrixes affecting workflow
                        createMode: false, // no nomination-templates for defaults
                        showExcludedStores: true,
                        showDetailedProvisions: false,
                        isDefaultMatrix: true,
                    },
                },
            ];
        },
    },
    methods: {
        onTabSwitch(tabNumber) {
            // Rerender defaults tab when we switch back to it.
            // It is needed to make sure default's tab is always up to date with staging area,
            // as changes affecting it can also occur on provision tab
            if (tabNumber === 1) this.rerender += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.nomination-tab {
    margin: 1.3rem 0;
}
</style>
