import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { class: _vm.cssClass },
    [
      _vm.isSelectAllAvailable
        ? _c(VCheckbox, {
            staticClass: "rtls-checkbox select-all",
            attrs: {
              label: _vm._f("toSentenceCase")(_vm.$t(_vm.label)),
              disabled: _vm.isSelectAllDisabled,
            },
            model: {
              value: _vm.selectAll,
              callback: function ($$v) {
                _vm.selectAll = $$v
              },
              expression: "selectAll",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        VContainer,
        { staticClass: "checkboxes-list-container", attrs: { fluid: "" } },
        [
          !_vm.isSelectAllAvailable
            ? _c("p", { staticClass: "checkboxes-list__label" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                    "\n            "
                ),
                _vm.isRequired
                  ? _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            VContainer,
            { staticClass: "checkboxes-list-container__control" },
            _vm._l(_vm.getOptions, function (option, index) {
              return _c(VCheckbox, {
                key: _vm.getKey(option, index),
                staticClass: "rtls-checkbox",
                attrs: {
                  multiple: true,
                  label: _vm._f("toSentenceCase")(
                    option.reference[_vm.itemText]
                  ),
                  value: _vm.getValue(option),
                  disabled: _vm.isDisabled({ option }),
                  rules: _vm.rules,
                  "hide-details": index < _vm.getOptions.length - 1,
                },
                on: { change: _vm.change },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }