<template>
    <div class="year-dates">
        <div class="year-dates__nav">
            <main-expand-button
                :is-expanded="isExpanded"
                expanded-icon="expand_less"
                collapsed-icon="expand_more"
                @expand="toggleYearDates"
            />
        </div>
        <div class="year-dates__divider" />
        <div v-show="isExpanded">
            <div class="year-dates__carousel">
                <v-carousel
                    ref="carousel"
                    v-model="carouselIndex"
                    height="5rem"
                    hide-delimiters
                    :show-arrows="false"
                    :continuous="false"
                >
                    <v-carousel-item
                        v-for="carouselYear in getAllYearsInWeeklyView"
                        :key="carouselYear"
                    >
                        <div class="year-dates__carousel-item">
                            {{
                                $t('general.dates.yearWeeks', {
                                    year: carouselYear,
                                })
                            }}
                        </div>
                    </v-carousel-item>
                </v-carousel>
                <icon-button
                    class="year-dates__carousel-button year-dates__carousel-button--prev"
                    icon="navigate_before"
                    :disabled="isCarouselPrevButtonDisabled"
                    @click="onCarouselButtonClick({ isPrev: true })"
                />
                <icon-button
                    class="year-dates__carousel-button year-dates__carousel-button--next"
                    icon="navigate_next"
                    :disabled="isCarouselNextButtonDisabled"
                    @click="onCarouselButtonClick()"
                />
            </div>
            <div v-for="monthAndWeeks in monthsAndWeeks" :key="monthAndWeeks.key">
                <div class="year-dates__month">{{ monthAndWeeks.monthName }}</div>
                <div class="year-dates__divider" />
                <ol>
                    <li
                        v-for="weekNumberObj in monthAndWeeks.weekNumbers"
                        :key="weekNumberObj.weekNumber"
                        class="year-dates__week"
                        :class="{
                            highlighted: isWeekHighlighted(weekNumberObj.weekNumber),
                            roundedLeft: isLeftBoundaryWeek(
                                weekNumberObj.weekNumber,
                                monthAndWeeks.weekNumbers
                            ),
                            roundedRight: isRightBoundaryWeek(
                                weekNumberObj.weekNumber,
                                monthAndWeeks.weekNumbers
                            ),
                            disabled: !weekNumberObj.isWeekInScopeOfYear,
                        }"
                        @click="navigateToWeek(weekNumberObj)"
                    >
                        <v-tooltip right content-class="rtls-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">{{ weekNumberObj.weekNumber }}</div>
                            </template>
                            <b>week {{ weekNumberObj.weekNumber }}</b>
                            <span>
                                {{ weekNumberObj.startDate | localShortDate }}
                                -
                                {{ weekNumberObj.endDate | localShortDate }}
                            </span>
                        </v-tooltip>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { forEach, range, includes } from 'lodash';

export default {
    props: {
        currentYear: Number,
        options: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            isExpanded: true,
            carouselIndex: 0,
            year: this.currentYear,
            currentPath: null,
        };
    },
    computed: {
        ...mapState('selectedDates', ['visibleWeeks']),
        ...mapGetters('weeks', [
            'getNumberOfWeeksInYear',
            'getYearWeek',
            'getAllYearsInWeeklyView',
            'getWeeksInScopeOfYearAndWeeklyView',
        ]),
        ...mapGetters('weeks', ['firstWeekObj', 'lastWeekObj']),

        highlightedWeeks() {
            return this.visibleWeeks;
        },
        monthsAndWeeks() {
            const lastWeek = this.getNumberOfWeeksInYear(this.year);

            if (!lastWeek) return [];
            // Generate all integers between the first and last week of the year (either 1-52 or 1-53).
            // Add 1 because range goes up to but doesn't include the lastWeek value.
            const weekNumbersInYear = range(1, lastWeek + 1);
            const monthsAndWeeks = {};
            const monthsAndWeeksOrder = [];
            const weeksInScopeOfYear = this.getWeeksInScopeOfYearAndWeeklyView(this.year);

            forEach(weekNumbersInYear, weekNumber => {
                const week = this.getYearWeek({ year: this.year, weekNumber });
                const startDateMoment = this.$moment(week.startDate);
                const monthNumber = startDateMoment.month(); // month() returns a value between 0 and 11.
                // ex: if client first week start in December we can have 2 December
                // for handle this will use year
                const weekYear = startDateMoment.year();
                const monthName = startDateMoment.format('MMMM');
                // Client year can start not from January for support this cases:
                // generate the next structure:
                //  { month-11-year-2019: { monthName: "December", weekNumbers: [1], key: month-11-year-2019 },
                //  month-0-year-2020: { monthName: "January", weekNumbers: [1,2,3,4], key: month-0-year-2020 },...
                //  month-11-year-2020: { monthName: "December", weekNumbers: [50, 51, 52], key: month-11-year-2020 }
                //  etc.
                // For saving order will use an array for keys
                // key will be concat of month and year
                const key = `month-${monthNumber}-year-${weekYear}`;
                if (!monthsAndWeeksOrder.includes(key)) {
                    monthsAndWeeksOrder.push(key);
                }

                const weekNumberObj = {
                    weekNumber,
                    isWeekInScopeOfYear: includes(weeksInScopeOfYear, weekNumber),
                    startDate: week.startDate,
                    endDate: week.endDate,
                };

                if (monthsAndWeeks[key]) {
                    monthsAndWeeks[key].weekNumbers.push(weekNumberObj);
                } else {
                    monthsAndWeeks[key] = {
                        monthName,
                        weekNumbers: [weekNumberObj],
                        key,
                    };
                }
            });

            return monthsAndWeeksOrder.map(item => monthsAndWeeks[item]);
        },
        isCarouselPrevButtonDisabled() {
            return this.carouselIndex === 0;
        },
        isCarouselNextButtonDisabled() {
            return this.carouselIndex === this.getAllYearsInWeeklyView.length - 1;
        },
    },
    watch: {
        options(value) {
            const { showSidebarCalendar, path } = value;
            if (this.currentPath === path) return;
            this.currentPath = path;
            this.isExpanded = showSidebarCalendar;
        },
    },
    async created() {
        this.year = this.currentYear;
        this.carouselIndex = this.getAllYearsInWeeklyView
            ? this.getAllYearsInWeeklyView.indexOf(this.year)
            : 0;
    },
    methods: {
        ...mapActions('selectedDates', ['setSelectedYearWeek']),

        toggleYearDates() {
            this.isExpanded = !this.isExpanded;
        },
        isWeekHighlighted(weekNumber) {
            return this.highlightedWeeks.some(week => {
                return week.weekOfYear === weekNumber && week.year === this.year;
            });
        },
        isLeftBoundaryWeek(weekNumber, weeksOfMonth) {
            const weeks = weeksOfMonth.map(week => week.weekNumber);
            return (
                (this.highlightedWeeks[0] && this.highlightedWeeks[0].weekOfYear) === weekNumber ||
                weeks[0] === weekNumber
            );
        },
        isRightBoundaryWeek(weekNumber, weeksOfMonth) {
            const weeks = weeksOfMonth.map(week => week.weekNumber);
            return (
                (this.highlightedWeeks[this.highlightedWeeks.length - 1] &&
                    this.highlightedWeeks[this.highlightedWeeks.length - 1].weekOfYear ===
                        weekNumber) ||
                weeks[weeks.length - 1] === weekNumber
            );
        },
        navigateToWeek(weekNumberObj) {
            // Only navigate to a selected year-week if it is within scope.
            if (weekNumberObj.isWeekInScopeOfYear) {
                this.setSelectedYearWeek({ year: this.year, weekNumber: weekNumberObj.weekNumber });
            }
        },
        onCarouselButtonClick({ isPrev } = {}) {
            if (isPrev) {
                this.$refs.carousel.prev();
            } else {
                this.$refs.carousel.next();
            }
            this.setNewYear();
        },
        setNewYear() {
            this.year = this.getAllYearsInWeeklyView[this.carouselIndex];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.year-dates {
    ol {
        padding: 0;
    }

    &__nav {
        @include flex-row;
        align-items: right;
        height: 3rem;
    }

    &__month {
        color: $promo-text-colour;
        font-family: 'Source Sans Pro';
        font-size: 1.2rem;
        letter-spacing: 0;
        margin-top: 0.75rem;
    }

    &__week {
        list-style: none;
        display: inline-block;
        color: $promo-primary-colour;
        font-family: 'Source Sans Pro';
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0;
        width: 17.5%;
        text-align: center;
        &:hover {
            color: $promo-white;
            border-radius: 0.75rem;
            background-color: $promo-primary-colour;
            cursor: pointer;
        }
        &.highlighted {
            background-color: $promo-light-blue-4;
            &.roundedLeft {
                border-top-left-radius: 0.75rem;
                border-bottom-left-radius: 0.75rem;
                &:hover {
                    border-top-left-radius: 0.75rem;
                    border-bottom-left-radius: 0.75rem;
                }
            }
            &.roundedRight {
                border-top-right-radius: 0.75rem;
                border-bottom-right-radius: 0.75rem;
                &:hover {
                    border-top-right-radius: 0.75rem;
                    border-bottom-right-radius: 0.75rem;
                }
            }
            &:hover {
                background-color: $promo-primary-colour;
                border-radius: 0;
            }
        }
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    &__divider {
        box-sizing: border-box;
        height: 0.2rem;
        width: 17rem;
        border: 0.1rem solid $promo-divider-colour;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    &__carousel {
        position: relative;
        width: 100%;

        &-item {
            @include flex-center;
            height: 100%;
            font-size: 1.4rem;
            font-weight: bold;
        }
        &-button {
            position: absolute;
            top: calc(50% - 1rem);
            z-index: 2;

            &--next {
                right: 0;
            }

            &--prev {
                left: 0;
            }
        }
    }
}
</style>
