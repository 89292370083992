<template>
    <div class="grouping-renderer">
        <div v-if="params.data.isSupplier" class="supplier">
            <div class="supplier__expansion">
                <main-expand-button :is-expanded="isExpanded" @expand="toggleSupplier" />
                ({{ params.data.productCount }})
            </div>
            <div class="supplier__details" @click="toggleSupplier">
                <v-tooltip :disabled="hideTooltip" top>
                    <template v-slot:activator="{ on: criteria, attrs }">
                        <div
                            ref="truncated"
                            v-bind="attrs"
                            class="truncate-text"
                            v-on="criteria"
                            @mouseover="checkTooltipRequired"
                        >
                            {{ supplierDescription }}
                        </div>
                    </template>
                    {{ supplierDescription }}
                </v-tooltip>
            </div>

            <div class="supplier__actions">
                <main-dialog
                    ref="supplierCommitmentsDialog"
                    :heading="$tkey('supplierCommitments')"
                    :show-positive-action-button="false"
                    has-activator
                >
                    <template v-slot:actions="{ activator }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <button
                                        class="actions__supplier-commitments"
                                        :disabled="viewSupplierCommitmentsDisabled"
                                        v-on="activator"
                                        @click="fetchCommitments"
                                    >
                                        {{ $tkey('viewSupplierCommitmentsShort') | toSentenceCase }}
                                    </button>
                                </div>
                            </template>
                            {{ supplierCommitmentsTooltip | toSentenceCase }}
                        </v-tooltip>
                    </template>
                    <template v-slot:default>
                        <funding-commitments-viewer
                            :supplier-key="params.data.supplierKey"
                            :category="params.data.categoryKey"
                            :promotion-namespace="params.promotionNamespace"
                        />
                        <div class="supplier-commitments__view-all">
                            <simple-button
                                :light-theme="true"
                                @onClick="viewAllSupplierCommitments"
                            >
                                {{ $t('actions.viewAll') | toSentenceCase }}
                            </simple-button>
                        </div>
                    </template>
                </main-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <div v-on="on">
                            <button class="actions__agreement-selector" @click="selectAgreement">
                                {{ $tkey('addAgreementShort') | toSentenceCase }}
                            </button>
                        </div>
                    </template>
                    {{ $tkey('addAgreement') | toSentenceCase }}
                </v-tooltip>
                <agreement-selector-modal
                    ref="agreement-selector-modal"
                    :key="`agreementSelectorModal::${params.selectedPromotion._id}`"
                    :has-activator="false"
                    :supplier-key="params.data.supplierKey"
                    :category-key="params.data.categoryKey"
                    :promotion="params.selectedPromotion"
                    :products="params.data.products"
                    :namespace="params.promotionNamespace"
                />
            </div>
        </div>
        <div v-else>
            <div v-if="isVisible(params)" class="product">
                <v-tooltip :disabled="hideTooltip" top>
                    <template v-slot:activator="{ on: criteria, attrs }">
                        <div
                            ref="truncated"
                            v-bind="attrs"
                            class="truncate-text"
                            v-on="criteria"
                            @mouseover="checkTooltipRequired"
                        >
                            {{ productDescription }}
                        </div>
                    </template>
                    {{ productDescription }}
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { has, includes } from 'lodash';
import namespaces from '@enums/namespaces';
import { autoOpenSupplierCommitmentDialogInFunding } from '@enums/feature-flags';
import navigation from '@/js/navigation';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.funding',
    data() {
        return {
            isExpanded: false,
            hideTooltip: null,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('promotions', ['unsavedPromotion']),
        ...mapGetters('supplierCommitments', ['getSupplierCommitmentsBySupplierKey']),
        displayCommitmentDialog() {
            // Auto-display of a dialog is feature flagged.
            // Don't display dialog in parking lot create mode
            if (
                this.toggleLogic[autoOpenSupplierCommitmentDialogInFunding] &&
                this.params.promotionNamespace !== namespaces.default
            ) {
                const matchingCommitments = this.getSupplierCommitmentsBySupplierKey({
                    supplierKey: this.params.data.supplierKey,
                    category: this.params.data.categoryKey,
                    promotionNamespace: this.params.promotionNamespace,
                    useActiveSupplierCommitmentsSection: false,
                });

                // dialog is displayed if there are matching commitments and none are selected
                return (
                    matchingCommitments.length !== 0 &&
                    !matchingCommitments.some(commitment =>
                        includes(commitment.allocatedPromotionIds, this.params.promotionNamespace)
                    )
                );
            }
            return false;
        },
        supplierDescription() {
            const clientSupplierKey =
                this.params.data.clientSupplierKey || this.$t('general.defaults.unknown');
            return `${clientSupplierKey} – ${this.params.data.name} / ${this.params.data.category}`;
        },
        productDescription() {
            return this.params.data.name;
        },
        isPromotionUnsaved() {
            const tabValues = Object.values(
                this.unsavedPromotion[this.params.promotionNamespace] || {}
            );
            return tabValues.filter(v => v).length > 0;
        },
        viewSupplierCommitmentsDisabled() {
            return this.isPromotionUnsaved || this.params.promotionNamespace === 'default';
        },
        supplierCommitmentsTooltip() {
            if (this.viewSupplierCommitmentsDisabled) {
                return this.$tkey('viewSupplierCommitmentsDisabled');
            }
            return this.$tkey('viewSupplierCommitments');
        },
    },
    methods: {
        ...mapActions('supplierCommitments', ['fetchSupplierCommitmentsForPromotion']),
        isVisible(params) {
            return has(this.params, 'visible') ? this.params.visible(params) : true;
        },
        toggleSupplier() {
            this.params.node.setExpanded(!this.params.node.expanded);
            this.isExpanded = this.params.node.expanded;
        },
        viewAllSupplierCommitments() {
            navigation.toSupplierCommitmentsView({
                category: this.params.data.categoryKey,
                supplier: this.params.data.supplierKey,
            });
        },
        selectAgreement() {
            this.$refs['agreement-selector-modal'].openDialog();
        },
        checkTooltipRequired(event) {
            // scrollWidth includes content not visible due to overflow, whilst offsetWidth includes only visible content.
            // If these differ, then a tooltip is required to display the full text.
            const { offsetWidth, scrollWidth } = event.target;
            this.hideTooltip = offsetWidth === scrollWidth;
        },
        async fetchCommitments() {
            await this.fetchSupplierCommitmentsForPromotion();
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.grouping-renderer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100%;

    .supplier {
        height: 100%;
        display: flex;

        flex-direction: row;
        justify-content: space-between;

        &__expansion {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            color: $promo-black;
        }

        &__details {
            width: 24rem;
            display: flex;
            align-items: center;
            font-size: 1.3rem;

            cursor: pointer;
        }

        &__actions {
            z-index: 100;
            margin-left: 1.5rem;
            font-size: 1.2rem !important;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            color: $promo-primary-colour;

            .actions {
                &__supplier-commitments {
                    text-decoration: underline;
                    margin: 0 3rem;
                }

                &__agreement-selector {
                    text-decoration: underline;
                    margin: 0 3rem;
                }
            }
        }
    }

    .product {
        margin-left: 3.5rem;
        max-width: 22rem;

        > .truncate-text {
            cursor: default !important;
        }
    }
}
.supplier-commitments__view-all {
    padding: 1rem;
}
</style>
