<template>
    <div>
        <main-dialog
            ref="dialog"
            heading="userPermissions.selectUserRoles"
            btn-confirm-text="actions.save"
            btn-cancel-text="actions.cancel"
            :has-activator="hasActivator"
            @confirm="save"
            @close="closeDialog"
        >
            <template v-slot:default>
                <div class="checkbox-dialog-container">
                    <categories-select :tree="tree" :update="updateUserCategories" />
                </div>
            </template>
        </main-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get, omit, cloneDeep } from 'lodash';

export default {
    props: {
        user: {
            type: Object,
            default: () => {
                return {};
            },
        },

        hasActivator: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        currentUser: {},
    }),

    computed: {
        ...mapGetters('hierarchy', ['getCategoriesTree']),
        tree() {
            const selectedCategories = get(this.currentUser, 'access.categories', []);
            return this.getCategoriesTree({
                allCategories: true,
                isEditable: true,
                selectedCategories,
            });
        },
    },

    methods: {
        ...mapActions('userProfiles', ['updateUserProfile']),

        openDialog() {
            this.currentUser = {};
            this.currentUser = cloneDeep(this.user);
            this.$refs.dialog.open();
        },
        closeDialog() {
            if (this.$refs.dialog) {
                this.$refs.dialog.close();
            }
            // reset incase we cancel
            this.currentUser = cloneDeep(this.user);
            this.$emit('close');
        },

        updateUserCategories(selectedCategories) {
            this.currentUser.access.categories = selectedCategories;
        },

        async save() {
            await this.updateUserProfile({
                _id: this.currentUser._id,
                updatedItem: omit(this.currentUser, ['isAdmin', 'rolesString']),
            });
            this.$refs.dialog.close();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.checkbox-dialog-container {
    width: 40rem;
    display: flex;
    padding-top: 1rem;

    ::v-deep .container.units,
    ::v-deep .container.categories {
        padding: 0;

        .select-all {
            padding: 0 0 0.3rem 0.2rem;
        }

        .checkboxes-list-container {
            border: 0.1rem solid $promo-grey;
            padding: 0.75rem 0;
            height: 11rem;
            overflow: auto;

            .rtls-checkbox {
                padding: 0 0.75rem;

                .v-input__control {
                    width: 100%;

                    .v-input__slot {
                        flex-direction: row-reverse;
                        justify-content: space-between;

                        .v-input--selection-controls__input {
                            margin-left: 0.8rem;
                            margin-right: 0;
                        }
                    }
                }

                &:hover {
                    background: $promo-campaign-color-5;
                }
            }

            .checkboxes-list__label {
                padding-left: 0.75rem;
            }
        }
    }
}
</style>
