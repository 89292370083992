<template>
    <div class="supply-percentage-container">
        <div class="ag-percentage-input">
            <div class="input-container">
                <vuex-percentage-input
                    :disabled="isDisabled"
                    :getter="() => params.value"
                    :setter="value => setValue(value)"
                    :validations="validationRules"
                    :dynamic-placeholder="params.cellPlaceholder"
                    :plain="params.plainValue"
                    :class="params.cellClass"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { isNil } from 'lodash';

export default Vue.extend({
    computed: {
        isDisabled() {
            if (isNil(this.params.isDisabled)) {
                return false;
            }
            const data = this.params.data;
            return this.params.isDisabled(data);
        },
        validationRules() {
            return this.params.getValidationRules(this.params);
        },
    },

    methods: {
        setValue(value) {
            const colId = this.params.column.colId;
            this.params.node.setDataValue(colId, value);
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.supply-percentage-container {
    display: flex;
    flex-direction: column;

    .ag-percentage-input {
        display: flex;
        width: 100%;

        .input-container {
            width: 6.5rem;

            &::v-deep .v-input__slot {
                height: 1.8rem;
            }
        }

        .preceding-container {
            padding-right: 0.6rem;
            font-size: 1.6rem;
        }
    }
}
</style>
