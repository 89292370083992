<!--
Wrapper component to handle hiding/displaying other HTML components
Prop toggle: a string from feature-flags.js enums, matching the keys of toggle-logic.js
-->
<template functional>
    <span v-if="$options.methods.toggleDisplay(props.toggle)" :class="props.slotClass">
        <slot />
    </span>
    <span v-else :class="props.slotClass">
        <slot name="alternative" />
    </span>
</template>

<script>
import featureFlagEnums from '@enums/feature-flags';
import { isNil } from 'lodash';
import store from '@/js/store';

export default {
    props: {
        // key of the toggle feature in toggleLogic
        toggle: String,
        slotClass: String,
    },

    methods: {
        toggleDisplay(toggle) {
            // ensure we are passing a valid toggle
            console.assert(
                featureFlagEnums[toggle],
                `Specified toggle '${toggle}' has not been defined as an enum`
            );

            const decision = store.state.clientConfig.toggleLogic[toggle];
            console.assert(
                !isNil(decision),
                `Specified toggle '${toggle}' does not have a corresponding entry in toggle-logic.js`
            );

            /**
             * return the (boolean cast) value of a feature decision.
             */
            return !!decision;
        },
    },
};
</script>
