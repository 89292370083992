import { filter, map, intersection } from 'lodash';
import moment from 'moment';
import supplierCommitmentTypes from '@enums/supplier-commitment-types';

/**
 * Filters supplier commitments by supplier key, category and start/end dates
 * @param {Object} RORO - The RORO wrapper
 * @param {Array} RORO.supplierCommitments - supplier commitments for filtering.
 * @param {Number} RORO.supplierKey - supplier key to filter by.
 * @param {Array.<Number>} RORO.productsCategories - product categories to check if supplier commitment category included in
 * @param {String} RORO.startDate - promotion start date
 * @param {String} RORO.endDate - promotion end date
 */
function supplierCommitmentFilterBySupplierKeyAndCategoryAndDates({
    supplierCommitments,
    supplierKey,
    productsCategories,
    startDate,
    endDate,
}) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    return filter(supplierCommitments, supplierCommitment => {
        // Currently there are 2 different ways to store supplier commitments,
        // each of which has a slightly different supplier schema.
        // The following code ensures that both types of supplier commitments
        // are filtered appropriately.  Note that this will be aligned in
        // PROWEB-1483.
        const hasMatchingSupplierKey =
            supplierCommitment.type === supplierCommitmentTypes.specifiedAllocations
                ? supplierCommitment.supplierKey === supplierKey
                : supplierCommitment.supplier.supplierKey === supplierKey;

        return (
            hasMatchingSupplierKey &&
            intersection(productsCategories, map(supplierCommitment.hierarchy, 'levelEntryKey'))
                .length &&
            (startDate.isBetween(supplierCommitment.startDate, supplierCommitment.endDate, 'day') ||
                endDate.isBetween(supplierCommitment.startDate, supplierCommitment.endDate, 'day'))
        );
    });
}

export default {
    supplierCommitmentFilterBySupplierKeyAndCategoryAndDates,
};
