<template>
    <div class="proxy-products">
        Proxy products
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.proxy-products {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>
