<template>
    <icon-button
        v-if="!textBtn"
        :disabled="isDisabled"
        :btn-text="btnText"
        icon="get_app"
        @click="downloadResource"
    />
    <v-btn
        v-else-if="isSecondaryBtn"
        :disabled="isDisabled"
        secondary
        depressed
        outlined
        class="secondary-btn"
        @click="downloadResource"
    >
        {{ btnText | toSentenceCase }}
        <icon icon-name="download" right small />
    </v-btn>
    <v-btn v-else primary depressed outlined class="primary-btn" @click="downloadResource">
        {{ btnText | toSentenceCase }}
        <icon icon-name="download" right small />
    </v-btn>
</template>

<script>
import { camelCase, get } from 'lodash';

export default {
    props: {
        resource: String,
        where: {
            type: Object,
            default: () => {},
        },
        format: String,
        label: {
            type: String,
            default: null,
        },
        extraParams: Object,
        textBtn: {
            type: Boolean,
            default: false,
        },
        downloadAction: {
            type: String,
            default: 'download',
        },
        exportSchema: String,
        isSecondaryBtn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isValidDownloadAction: false,
            isDisabled: false,
        };
    },

    computed: {
        btnText() {
            const format = this.$t(`general.exportFormats.${this.format}`);
            if (this.label) {
                return `${this.label}`;
            }
            return `${this.$t('general.labels.download')} ${this.resource} ${format}`;
        },
        computedAction() {
            return `${camelCase(this.resource)}/${this.downloadAction}`;
        },
    },
    created() {
        this.isValidDownloadAction = !!get(this.$store, `_actions.${this.computedAction}`);
        if (!this.isValidDownloadAction) {
            throw new Error(
                this.$t('validation.failureMessages.downloadResourceAction', {
                    downloadResourceAction: this.computedAction,
                })
            );
        }
    },
    methods: {
        async downloadResource() {
            this.isDisabled = true;
            await this.$store.dispatch(this.computedAction, {
                params: {
                    resource: this.resource,
                    export: true,
                    where: this.where,
                    exportFormat: this.format,
                    exportSchema: this.exportSchema,
                    ...this.extraParams,
                },
            });
            this.isDisabled = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.primary-btn {
    ::v-deep #download-icon {
        fill: $promo-white;
    }
}
.secondary-btn {
    ::v-deep #download-icon {
        fill: $promo-primary-colour;
    }
}
</style>
