import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      "translated-header": _vm.$tkey("header"),
      "btn-confirm-text": _vm.btnConfirmText,
      "btn-cancel-text": "actions.cancel",
      "has-activator": false,
    },
    on: {
      confirm: function ($event) {
        return _vm.$emit("confirm")
      },
      close: _vm.close,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _vm.renderGrid
              ? _c(
                  "div",
                  { staticClass: "confirm-dialog" },
                  [
                    _c("p", { staticClass: "confirm-dialog__description" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$tkey("actionableText")
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("promo-ag-grid", {
                      ref: "agGrid",
                      attrs: {
                        "row-data": _vm.actionableEntities,
                        "column-defs": _vm.columnDefs,
                        "grid-options": _vm.gridOptions,
                        "default-col-def": _vm.defaultColDef,
                        "grid-style": "width: 65rem; height: 20rem;",
                      },
                    }),
                    _vm._v(" "),
                    _c(VDivider),
                    _vm._v(" "),
                    _vm.excludedEntities.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "p",
                              { staticClass: "confirm-dialog__description" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$tkey("notActionableText")
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("promo-ag-grid", {
                              ref: "agGrid",
                              attrs: {
                                "row-data": _vm.excludedEntities,
                                "column-defs": _vm.columnDefs,
                                "grid-options": _vm.gridOptions,
                                "default-col-def": _vm.defaultColDef,
                                "grid-style": "width: 65rem; height: 20rem;",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }