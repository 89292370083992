<template>
    <div class="flex-row">
        <div class="alternative-mechanics__select-container">
            <vuex-checkbox :getter="() => isSelected()" :setter="() => selectionHandler()" />
        </div>
        <div class="alternative-mechanics__name">{{ params.data.title | toSentenceCase }}</div>
    </div>
</template>

<script>
import Vue from 'vue';
import { toSentenceCase } from '@/js/utils/string-utils';

export default Vue.extend({
    methods: {
        toSentenceCase,
        isSelected() {
            const { selectedOption, idx } = this.params.data;
            return selectedOption === idx;
        },
        selectionHandler() {
            const { data, colDef } = this.params;
            colDef.changeSelected(data.idx);
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.flex-row {
    display: flex;
    flex-direction: row !important;
}
.alternative-mechanics__select-container {
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    margin-right: 0.5rem;

    ::v-deep {
        .mdi {
            &::before {
                font-size: 2.4rem;
            }
            &.mdi-checkbox-marked {
                &::before {
                    content: '\F43E';
                }
            }

            &.mdi-checkbox-blank-outline {
                transition: none !important;
                &::before {
                    content: '\F43D';
                }
            }
        }
    }
}
.alternative-mechanics__name {
    margin-left: 1rem;
}
</style>
