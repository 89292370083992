<template functional>
    <div class="promotion-rag-colours">
        <span
            v-for="(count, colour) in props.promotionRagColourCounts"
            :key="`${colour}`"
            class="promotion-rag-colours__colour"
        >
            <promotion-rag-colour
                :vertical="props.vertical"
                :colour="Number(colour)"
                :count="count"
                :big-circle="props.bigCircle"
            />
        </span>
        <span v-if="props.displayTotal" class="promotion-rag-colours__total">
            {{
                $options.methods.getRagColourTotal({ ragColours: props.promotionRagColourCounts })
                    | countNumber
            }}
        </span>
    </div>
</template>

<script>
import { sum, values } from 'lodash';

export default {
    props: {
        // Should be an object containing key/value pairs like:
        // { 1: 0, 2: 5, 3: 1 }
        // Where the key is the colour number and the value is the count (the number of promotions with that colour).
        promotionRagColourCounts: {
            type: Object,
            required: true,
        },
        displayTotal: {
            type: Boolean,
            default: true,
        },
        vertical: {
            type: Boolean,
            default: false,
            required: false,
        },
        bigCircle: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getRagColourTotal({ ragColours }) {
            return sum(values(ragColours));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.promotion-rag-colours {
    display: flex;
    &__colour {
        padding-right: 1rem;
    }
    &__total {
        font-size: 1.2rem;
        font-weight: 600;
    }
}
</style>
