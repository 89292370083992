import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-bar" },
    [
      _c(
        "div",
        { staticClass: "filter-bar__grouping" },
        [
          _c("label", { staticClass: "filter-bar__label" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(_vm.$t("general.labels.filter"))
                ) +
                ":\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filters, function (filter, index) {
            return _c(
              "div",
              { key: filter.filterId, staticClass: "filter-group__filters" },
              [
                _c(
                  "div",
                  { staticClass: "flex-row" },
                  [
                    _c("vuex-select", {
                      staticClass: "filter-bar__parent-filter",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t("placeholders.selectFilter")
                        ),
                        options: _vm.getFilterOptions(index),
                        "item-value": "filterKey",
                        "item-text": "filterText",
                        getter: () =>
                          _vm.filterGetter({ index, path: "filterKey" }),
                        setter: (key) => _vm.filterKeySetter({ index, key }),
                      },
                    }),
                    _vm._v(" "),
                    filter.filterKey
                      ? _c(_vm.componentMap[filter.filterType], {
                          tag: "component",
                          attrs: {
                            placeholder: _vm._f("toSentenceCase")(
                              _vm.$t(
                                `placeholders.selectOption.${filter.filterKey}`
                              )
                            ),
                            options: _vm.getFilterValueOptionsByFilterId({
                              filterKey: filter.filterKey,
                            }),
                            "item-value": "value",
                            "item-text": "text",
                            getter: () =>
                              _vm.filterGetter({ index, path: "filterValue" }),
                            setter: (value) =>
                              _vm.filterValueSetter({
                                index,
                                value,
                                filterId: filter.filterId,
                                filterKey: filter.filterKey,
                              }),
                            multiple: _vm.isComponentMultiple({
                              filterKey: filter.filterKey,
                            }),
                            chips: _vm.isComponentMultiple({
                              filterKey: filter.filterKey,
                            }),
                            horizontal: "",
                            "deletable-chips": "",
                            autocomplete: "",
                            "invalid-selection-text":
                              "general.info.invalidFilterSelection",
                            "finite-list": _vm.finiteList,
                            "max-items-in-list": _vm.maxItemsInList,
                            "sort-results": _vm.shouldSortResults({
                              filterKey: filter.filterKey,
                            }),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onFilterValueChange({
                                filterKey: filter.filterKey,
                                event: $event,
                              })
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showRemoveFilterBtn
                      ? _c(
                          VBtn,
                          {
                            staticClass: "filter-bar__cancel-button",
                            attrs: {
                              icon: "",
                              disabled: _vm.isDeleteDisabled(index),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeFilter(index)
                              },
                            },
                          },
                          [
                            _c("icon", {
                              attrs: {
                                "icon-name": "cancel",
                                small: "",
                                "icon-colour-type": "primary-path",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-bar__add-button" },
            [
              _c("create-new-button", {
                attrs: { background: "", disabled: !_vm.canAddFilter },
                on: { createNew: _vm.addFilter },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showSearchField
        ? _c("vuex-search-field", { staticClass: "filter-bar__search-field" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }