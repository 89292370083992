import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VTooltip,
    {
      attrs: { disabled: _vm.hideTooltip, top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: criteria, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      ref: "truncated",
                      staticClass: "truncate-text",
                      on: { mouseover: _vm.checkTooltipRequired },
                    },
                    "div",
                    attrs,
                    false
                  ),
                  criteria
                ),
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.params.valueFormatted || _vm.params.value) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(
        "\n    " + _vm._s(_vm.params.valueFormatted || _vm.params.value) + "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }