<template functional>
    <svg width="21" height="21" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.57173 7.14437V10.8723L5.04277 10.8723L5.04277 7.14437L9.57173 7.14437ZM7.30725 9.94815C7.82448 9.94815 8.24709 9.52553 8.24709 9.0083C8.24709 8.49108 7.82448 8.06846 7.30725 8.06846C6.79002 8.06846 6.36741 8.49108 6.36741 9.0083C6.36741 9.52553 6.79002 9.94815 7.30725 9.94815Z"
            fill="#59811C"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.90401 6.06577L8.37011 6.06577L8.37011 5.47855C8.37011 4.29643 9.32944 3.33709 10.5116 3.33709C11.6937 3.33709 12.653 4.29643 12.653 5.47855L12.653 5.61731H11.5744L11.5744 5.47855C11.5744 4.89029 11.0998 4.41569 10.5116 4.41569C9.92331 4.41569 9.44871 4.89029 9.44871 5.47855L9.44871 6.06577H9.71049C10.2277 6.06577 10.6503 6.48838 10.6503 7.00561V11.011C10.6503 11.5282 10.2277 11.9509 9.71049 11.9509L4.90401 11.9509C4.38678 11.9509 3.96417 11.5282 3.96417 11.011L3.96417 7.00561C3.96417 6.48838 4.38678 6.06577 4.90401 6.06577Z"
            fill="#59811C"
        />
    </svg>
</template>

<script>
export default {
    name: 'UnlockRequest',
};
</script>
