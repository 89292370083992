var render = function render(_c, _vm) {
  return _c(
    "button",
    {
      staticClass: "simple-btn v-btn",
      class: [
        _vm.props.lightTheme ? "simple-btn-light" : "simple-btn-dark",
        _vm.data.staticClass || "",
      ],
      attrs: { disabled: _vm.props.disabled },
      on: { click: _vm.listeners.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }