<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UXEvents from '@enums/ux-events';
import tabNames from '@enums/tab-names';
import navigation from '@/js/navigation';
import commonDeleteDialogComponent from './common-delete-dialog';

export default {
    extends: commonDeleteDialogComponent,

    computed: {
        ...mapState('subCampaigns', ['selectedSubCampaignParentId']),
        ...mapGetters('campaigns', ['selectedCampaignId']),

        labels() {
            return {
                deleteBtnText: 'actions.delete',
                deleteHeading: 'dialogs.deleteCampaign.header',
            };
        },

        btnDeleteId() {
            return 'deleteCampaignButton';
        },
    },

    methods: {
        ...mapActions('campaigns', ['deleteCampaign']),

        async deleteSelectedItem() {
            await this.deleteCampaign({ id: this.resourceId });

            // redirect to toAllCampaignsView if selected campaign or selected sub-campaign's parent was deleted
            if (this.resourceId === this.selectedCampaignId) {
                navigation.toAllCampaignsView({ tabName: tabNames.planning });
            } else if (this.resourceId === this.selectedSubCampaignParentId) {
                navigation.toAllCampaignsView({ tabName: tabNames.planning });
            }

            this.globalEmit(UXEvents.campaignDeleted);
            this.closeAll();
        },
    },
};
</script>
