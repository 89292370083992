var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "create-resource-btn",
    _vm._b(
      {
        ref: "createResourceBtn",
        attrs: {
          context: _vm.selectedScenario,
          "btn-text": _vm.btnText,
          "before-create-function": _vm.beforeCreateFunction,
        },
        on: {
          resourceCreationFailed: function ($event) {
            return _vm.$emit("promotionCreationFailed", $event)
          },
          resourceCreated: function ($event) {
            return _vm.$emit("promotionCreated", $event)
          },
        },
      },
      "create-resource-btn",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }