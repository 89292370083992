<template>
    <div class="variable-funding-viewer">
        <vuex-form-viewer
            ref="formViewer"
            :context="variableFunding"
            :fields="fields"
            :make-read-only="!isVariableFundingSelected"
            :form="form"
            @click="onEditClick"
        />

        <!--Cancel and save buttons -->
        <div v-if="isNewVariableFunding" class="buttons">
            <v-btn text class="cancel-button" @click="$emit('close')">
                {{ $t('actions.cancel') | toSentenceCase }}
            </v-btn>
            <simple-button
                class="save-button"
                :disabled="isSaveNewVariableFundingDisabled"
                @onClick="saveNewVariableFunding"
            >
                {{ $t('actions.save') | toSentenceCase }}
            </simple-button>
        </div>
        <!--Edit and delete buttons -->

        <template v-else>
            <div
                v-if="isExpanded"
                class="variable-funding-viewer__icons"
                :style="getStyle(index, 12)"
            >
                <main-expand-button :is-expanded="isExpanded" @expand="onEditClick" />
            </div>
            <div
                v-if="isExpanded"
                class="variable-funding-viewer__icons"
                :style="getStyle(index, 13)"
            >
                <common-delete-dialog
                    :resource="variableFundingAgreementEnum"
                    :child-dependencies-warning="false"
                    @delete="deleteVariableFundingAgreement({ id: variableFunding._id })"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { get, map, isEmpty, keyBy, filter, intersection, includes } from 'lodash';
import { fieldTypes } from '@enums/vuex-form';
import agreementTypes from '@enums/agreement-types';
import agreementDisplayTypes from '@enums/agreement-display-types';
import invoiceCalculationTypes from '@enums/invoice-calculation-types';
import applicableToOptions from '@enums/agreements-applicable';
import { componentTypes } from '@enums/custom-components';
import { variableFundingAgreements as variableFundingAgreementEnum } from '@enums/resources';
import namespaces from '@enums/namespaces';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { getAllFundingFunctions } from '@sharedModules/funding-utils';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import storeGroupsOptionsMixin from '@/js/pages/mixins/store-groups-options-mixin';
import suppliersCategoriesOptionsMixin from '../mixins/suppliers-categories-options-mixin';

const allInvoiceOptions = Object.values(invoiceCalculationTypes);

export default {
    mixins: [storeGroupsOptionsMixin, suppliersCategoriesOptionsMixin],
    props: {
        fields: {
            required: true,
            type: Array,
        },
        variableFunding: {
            type: Object,
            default: () => {
                return {};
            },
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            variableFundingAgreementEnum,
            productsOptions: [],
        };
    },
    computed: {
        ...mapGetters('products', ['getProductByKey', 'getProductsBySupplierAndCategory']),
        ...mapState('variableFundingAgreements', ['validationStates', 'selectedVariableFundingId']),
        ...mapState('suppliers', ['suppliers']),
        ...mapState('clientConfig', ['hierarchyConfig', 'toggleLogic']),
        ...mapGetters('variableFundingAgreements', ['getStagingAreaField']),

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        isExpanded() {
            return this.variableFunding._id === this.selectedVariableFundingId;
        },

        minDateForVariableFunding() {
            // if the variable funding is new or has not started,
            // we should not let the user set an effectiveFrom date in the past.
            const startDate = this.$moment(get(this.variableFunding, 'startDate'));
            const variableFundingStarted = startDate.isBefore(this.$moment(), 'day');

            if (this.isNewVariableFunding || !variableFundingStarted) {
                return this.$d('dates.default.shortDate', this.$moment());
            }
            // if the variable funding has started, no restriction is applied. user can choose any date.
            return null;
        },

        form() {
            return {
                editContext: this.isNewVariableFunding ? null : this.variableFunding,
                context: this.isNewVariableFunding ? null : this.variableFunding,
                editMode: !this.isNewVariableFunding,
                validation: this.validationStates[this.namespace],
                vuexModule: 'variableFundingAgreements',
                editable: true,
                addEvent: 'createVariableFundingAgreement',
                editEvent: 'updateVariableFundingAgreement',
                isStagingAreaResetNeeded: false,
                fields: [
                    {
                        fieldName: 'supplier',
                        placeholder: i18n.t(
                            'validation.supplierCommitment.placeholder.selectSupplier'
                        ),
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.select,
                        options: this.suppliers,
                        defaultValue: this.defaultSupplier,
                        filled: true,
                        itemText: 'name',
                        itemValue: 'supplierKey',
                        returnObject: true,
                        validateOnBlur: false, // need validation if field is cleared when category is changed
                        setter: supplier =>
                            this.onVariableFundingSupplierCategoryChange({ supplier }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },

                    {
                        fieldName: 'hierarchy',
                        placeholder: i18n.t(
                            'validation.supplierCommitment.placeholder.selectCategory'
                        ),
                        cssClass: 'vuex-form-viewer__grid-cell category',
                        editable: true,
                        restricted: true,
                        type: fieldTypes.select,
                        filled: true,
                        options: this.userCategories,
                        defaultValue: [this.categoryFromFilter],
                        itemText: 'levelEntryDescription',
                        itemValue: 'levelEntryKey',
                        returnObject: true,
                        multiple: true,
                        chips: true,
                        deletableChips: true,
                        autocomplete: true,
                        finiteList: true,
                        setter: categories =>
                            this.onVariableFundingSupplierCategoryChange({ categories }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'mechanic',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.text,
                        validations: [
                            {
                                validator: validators.maxLength,
                                params: [250],
                            },
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'agreementType',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.select,
                        options: this.getAgreementTypeOptions(),
                        itemText: 'text',
                        itemValue: 'key',
                        filled: true,
                        onChange: value => {
                            if (
                                !includes(
                                    [agreementTypes.onInvoiceValue, agreementTypes.offInvoiceValue],
                                    value
                                )
                            ) {
                                this.setStagingAreaField({
                                    namespace: this.namespace,
                                    fieldName: 'agreementFunction',
                                    value: null,
                                });
                            }
                        },
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'agreementFunction',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.select,
                        options: this.getAgreementFunctionOptions(),
                        itemText: 'text',
                        itemValue: 'key',
                        filled: true,
                        checkDisabled: () => {
                            const selectedType = this.getStagingAreaField({
                                namespace: this.namespace,
                                fieldName: 'agreementType',
                            });
                            return !includes(
                                [agreementTypes.onInvoiceValue, agreementTypes.offInvoiceValue],
                                selectedType
                            );
                        },
                    },
                    {
                        fieldName: 'applicableTo',
                        placeholder: i18n.t('variableFundingAgreements.placeholder.applicableTo'),
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.select,
                        options: this.getApplicableToOptions(),
                        itemText: 'text',
                        itemValue: 'key',
                        filled: true,
                        onChange: value => this.applicableToChange(value),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'scope',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: componentTypes.scope,
                        needPreValidationOnSet: !this.isNewVariableFunding,
                        valueComponent:
                            agreementDisplayTypes[this.agreementDisplayType].displayComponent,
                        productsOptions: this.productsOptions,
                        categoryKeys: this.categoryKeys,
                        supplierKey: this.supplierKey,
                        numberFormatter:
                            agreementDisplayTypes[this.agreementDisplayType].numberFormatter.type,
                    },
                    {
                        type: fieldTypes.dateRange,
                        cssClass: 'vuex-form__dates vuex-form-viewer__grid-cell',
                        editable: true,
                        from: {
                            fieldName: 'startDate',
                        },
                        to: {
                            fieldName: 'endDate',
                        },
                        minDate: this.minDateForVariableFunding,
                        validations: [
                            {
                                message: i18n.t('validation.supplierCommitment.datesRequired'),
                                validator: validators.required,
                            },
                            {
                                validator: validators.dateRangeRequired,
                            },
                        ],
                    },
                    {
                        fieldPath: 'sellInPeriod',
                        fieldName: 'daysBefore',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.number,
                        validations: [
                            {
                                validator: validators.minValue,
                                params: [0],
                            },
                            {
                                validator: validators.required,
                            },
                        ],
                        defaultValue: 0,
                    },
                    {
                        fieldPath: 'sellInPeriod',
                        fieldName: 'daysAfter',
                        cssClass: 'vuex-form-viewer__grid-cell',
                        editable: true,
                        type: fieldTypes.number,
                        validations: [
                            {
                                validator: validators.minValue,
                                params: [0],
                            },
                            {
                                validator: validators.required,
                            },
                        ],
                        defaultValue: 0,
                    },
                    {
                        fieldName: 'storeGroups',
                        cssClass: 'vuex-form-viewer__grid-cell store-groups',
                        placeholder: i18n.t('variableFundingAgreements.placeholder.storeGroups'),
                        editable: true,
                        type: fieldTypes.select,
                        options: this.storeGroupsOptions,
                        defaultValue: this.userStoreGroups,
                        needPreValidationOnSet: !this.isNewVariableFunding,
                        validateOnBlur: false,
                        itemText: 'description',
                        itemValue: 'key',
                        label: 'variableFundingAgreements.headers.storeGroups',
                        multiple: true,
                        chips: true,
                        deletableChips: true,
                        returnObject: true,
                        autocomplete: true,
                        finiteList: true,
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                ],
            };
        },
        getScope() {
            const { scope, agreementType } = this.variableFunding;
            const numberFormatter = agreementDisplayTypes[agreementType].numberFormatter.type;
            let products;
            if (scope.products) {
                products = map(scope.products, product => {
                    const fullProductObj = this.getProductByKey(product.productKey);
                    const name = fullProductObj ? fullProductObj.name : '';
                    return { name, ...product };
                });
            }
            return { ...scope, products, numberFormatter };
        },

        isVariableFundingSelected() {
            return (
                this.selectedVariableFundingId === this.variableFunding._id ||
                this.isNewVariableFunding
            );
        },

        isNewVariableFunding() {
            return isEmpty(this.variableFunding);
        },

        isSaveNewVariableFundingDisabled() {
            const namespace = namespaces.default;
            return (
                !this.validationStates[namespace] ||
                (this.validationStates[namespace] && !this.validationStates[namespace].isValid)
            );
        },

        namespace() {
            return this.isNewVariableFunding ? namespaces.default : this.variableFunding._id;
        },

        agreementDisplayType() {
            const agreementType = this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'agreementType',
            });
            const defaultType = agreementTypes.onInvoiceValue;
            return agreementType || defaultType;
        },

        productsOptionsMap() {
            return keyBy(this.productsOptions, 'productKey');
        },
    },
    methods: {
        ...mapActions('variableFundingAgreements', [
            'setSelectedVariableFundingId',
            'deleteVariableFundingAgreement',
            'setStagingAreaField',
            'setStagingAreaFields',
        ]),
        ...mapActions('products', ['fetchProductsBySupplierAndCategory']),

        async onEditClick() {
            if (!this.isVariableFundingSelected) {
                const { supplier, hierarchy } = this.variableFunding;
                const { supplierKey } = supplier;
                const categoryKeys = map(hierarchy, 'levelEntryKey');
                this.fetchSuppliersByCategories(categoryKeys);
                await this.fetchProductsBySupplierAndCategory({
                    supplier: supplierKey,
                    categories: categoryKeys,
                });
                this.productsOptions = this.getProductsBySupplierAndCategory({
                    supplierKey,
                    categoryKeys,
                });
            }
            this.setSelectedVariableFundingId(
                this.isVariableFundingSelected ? null : this.variableFunding._id
            );
        },
        getStyle(index, targetGridColumn) {
            // Calculate the cell position based on the current index
            // and the target grid column.

            const numberOfGridRowsPerVariableFundingRow = 2;
            if (this.isNewVariableFunding) {
                return {
                    'grid-row': index * numberOfGridRowsPerVariableFundingRow + 4,
                    'grid-column': targetGridColumn,
                };
            }

            return {
                'grid-row': (index + 1) * numberOfGridRowsPerVariableFundingRow + 1,
                'grid-column': targetGridColumn,
            };
        },

        async saveNewVariableFunding(event) {
            if (this.$refs.formViewer) {
                event.target.disabled = true;
                const { error } = await this.$refs.formViewer.submit();
                if (!error) {
                    this.$emit('close');
                } else {
                    event.target.disabled = false;
                }
            }
        },

        applicableToChange(value) {
            const scope = this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'scope',
            });
            const newScope = { ...scope };

            if (value === applicableToOptions.groupOfProducts) {
                delete newScope.products;
            } else {
                delete newScope.value;
                newScope.products = [{ productKey: null, value: null }];
            }

            this.updateScope(newScope);
        },

        updateScope(newScope) {
            this.setStagingAreaField({
                namespace: this.namespace,
                fieldName: 'scope',
                value: newScope,
            });
        },

        async onVariableFundingSupplierCategoryChange({ supplier = null, categories = null }) {
            const updateScope = async ({ supplierKey, categoryKeys }) => {
                const fieldsToUpdate = [];
                // fetch products for updated supplier+category
                await this.fetchProductsBySupplierAndCategory({
                    supplier: supplierKey,
                    categories: categoryKeys,
                });

                // update product options
                this.productsOptions = this.getProductsBySupplierAndCategory({
                    supplierKey,
                    categoryKeys,
                });

                // leave just available products for updated supplier+category
                const scope = this.getStagingAreaField({
                    namespace: this.namespace,
                    fieldName: 'scope',
                });
                const newScope = { ...scope };
                if (scope && scope.products) {
                    const updatedProducts = filter(
                        newScope.products,
                        product => !!this.productsOptionsMap[product.productKey]
                    );

                    newScope.products = isEmpty(updatedProducts)
                        ? [{ productKey: null, value: null }]
                        : updatedProducts;

                    // add scope to the list to send for update
                    fieldsToUpdate.push({ fieldName: 'scope', value: newScope });
                }
                return fieldsToUpdate;
            };

            // supplier, categories and scope (products) depend on each other,
            // they need to be updated together
            // if form is invalid, they won't be sent to mongo
            this.onSupplierCategoryChange({
                supplier,
                categories,
                getAdditionalFieldsToUpdate: updateScope,
            });
        },

        getAgreementTypeOptions() {
            return [
                {
                    key: agreementTypes.guaranteedMargin,
                    text: toSentenceCase(
                        i18n.t(
                            `variableFundingAgreements.agreementTypes.${
                                agreementTypes.guaranteedMargin
                            }`
                        )
                    ),
                },

                {
                    key: agreementTypes.onInvoiceValue,
                    text: toSentenceCase(
                        i18n.t(
                            `variableFundingAgreements.agreementTypes.${
                                agreementTypes.onInvoiceValue
                            }`
                        )
                    ),
                },
                {
                    key: agreementTypes.offInvoiceValue,
                    text: toSentenceCase(
                        i18n.t(
                            `variableFundingAgreements.agreementTypes.${
                                agreementTypes.offInvoiceValue
                            }`
                        )
                    ),
                },
                {
                    key: agreementTypes.supplierCompensation,
                    text: toSentenceCase(
                        i18n.t(
                            `variableFundingAgreements.agreementTypes.${
                                agreementTypes.supplierCompensation
                            }`
                        )
                    ),
                },
                {
                    key: agreementTypes.buyingPrice,
                    text: toSentenceCase(
                        i18n.t(
                            `variableFundingAgreements.agreementTypes.${agreementTypes.buyingPrice}`
                        )
                    ),
                },
            ];
        },

        getAgreementFunctionOptions() {
            return intersection(
                allInvoiceOptions,
                getAllFundingFunctions(this.featureAwareFactory)
            ).map(option => {
                return {
                    key: option,
                    text: toSentenceCase(
                        i18n.t(`variableFundingAgreements.agreementFunctions.${option}`)
                    ),
                };
            });
        },

        getApplicableToOptions() {
            return map(applicableToOptions, option => {
                return {
                    key: option,
                    text: toSentenceCase(
                        i18n.t(`variableFundingAgreements.applicableTo.${option}`)
                    ),
                };
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.variable-funding-viewer {
    display: contents;
    &__icons {
        padding-top: 1rem;
        padding-left: 1rem;
    }
    &::v-deep {
        .vuex-form-viewer {
            &__grid-cell {
                font-size: 1.2rem;
                font-weight: 400;
                color: $promo-text-colour;
                padding: 1rem 0 0.5rem 1rem;
                margin: 0;

                &.category {
                    .v-select.v-select--chips.v-select--chips--small {
                        .v-chip {
                            margin: 0.2rem 0.4rem;
                        }
                        .v-select__selections {
                            min-height: 2.8rem;
                        }
                    }
                }

                &.scope {
                    grid-column: span 2;
                }
                &.value {
                    display: none;
                }
                &.sellInPeriod {
                    margin-left: 2rem;
                }

                &.agreement {
                    font-weight: 600;
                }
                &:first-child {
                    grid-column-start: 1;
                    padding-left: 1.5rem;
                }
                &.store-groups {
                    padding-left: 1.5rem;
                    padding-bottom: 1rem;
                    padding-top: 1.5rem;
                    grid-column: 1 / span 9;
                    display: flex;
                }
                .rtls-select-container {
                    display: flex;

                    &__label {
                        padding: 0;
                        font-weight: 400;
                        line-height: 3rem;
                    }

                    .v-input--is-disabled {
                        .v-input__control > .v-input__slot:before {
                            border-image: none !important;
                            border: none !important;
                        }

                        .v-input__append-inner {
                            display: none;
                        }
                    }
                }
            }
        }

        .v-messages.error--text {
            z-index: $planner-z-index;
            background-color: $promo-white;
            margin-top: 0.4rem;
        }
    }

    &__edit-btn {
        padding-left: 1rem;
    }

    .buttons {
        @include span-full-row;
        background: $promo-white;
        padding-bottom: 1rem;
        text-align: right;
        padding-right: 2rem;
    }

    .cancel-button {
        color: $promo-primary-colour;
        font-weight: 600;
    }
}
</style>
