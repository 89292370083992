import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-layout" }, [
    _c(
      "div",
      { staticClass: "page-layout__labels" },
      [
        _c("label", { staticClass: "page-layout__labels-label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.isPagesResource
                    ? _vm.$t("preparation.templates.pageLayout")
                    : _vm.$t("preparation.templates.slots")
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "feature-toggle",
          { attrs: { toggle: _vm.canEditChannel } },
          [
            _vm.isTemplatesSection
              ? _c("confirm-dialog", {
                  ref: "changeTemplateModal",
                  staticClass: "confirm-dialog",
                  attrs: {
                    "question-text": _vm.$t(
                      "preparation.templates.changeTemplateModal.heading"
                    ),
                    "warning-text": _vm.$t(
                      "preparation.templates.changeTemplateModal.message"
                    ),
                    "action-text": _vm.$t(
                      "preparation.templates.changeTemplateModal.confirm"
                    ),
                  },
                  on: {
                    confirm: function ($event) {
                      return _vm.confirmChangeTemplate()
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                staticClass:
                                  "page-layout__labels-label nav-link",
                                attrs: {
                                  text: "",
                                  depressed: "",
                                  disabled: _vm.changeTemplateButtonDisabled,
                                },
                                on: { click: _vm.openChangeTemplateModal },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t(
                                          "preparation.templates.changeTemplate"
                                        )
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3163555223
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "page-layout-wrapper" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPagesLayout,
              expression: "showPagesLayout",
            },
          ],
          staticClass: "page-layout-wrapper__selector",
        },
        [
          _c(
            VCarousel,
            {
              ref: "carousel",
              attrs: {
                height: "100%",
                "hide-delimiters": "",
                continuous: false,
                "show-arrows": false,
                disabled: _vm.isDisabled,
              },
              model: {
                value: _vm.carouselModel,
                callback: function ($$v) {
                  _vm.carouselModel = $$v
                },
                expression: "carouselModel",
              },
            },
            _vm._l(_vm.allowedTemplates, function (template, index) {
              return _c(
                VCarouselItem,
                { key: _vm.createCarouselItemKey(template, index) },
                [
                  _c("page-layout-grid", {
                    key:
                      template._id + _vm.selectedResourceDefinitionPageNumber,
                    attrs: {
                      layout: template.layout,
                      "layout-key": template.key,
                      selected: _vm.isTemplateSelected,
                      model: _vm.model,
                    },
                    on: {
                      "auto-save": function ($event) {
                        return _vm.$emit("auto-save")
                      },
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.changeTemplateEnabled
            ? [
                _c("navigate-button", {
                  staticClass:
                    "page-layout-wrapper__carousel-button page-layout-wrapper__carousel-button--prev",
                  attrs: { left: true, disabled: _vm.isPrevButtonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.onCarouselButtonClick(true)
                    },
                  },
                }),
                _vm._v(" "),
                _c("navigate-button", {
                  staticClass:
                    "page-layout-wrapper__carousel-button page-layout-wrapper__carousel-button--next",
                  attrs: { right: true, disabled: _vm.isNextButtonDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.onCarouselButtonClick(false)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-layout-wrapper__panel" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "page-layout-wrapper__panel--select-template",
                      },
                      [
                        _c("vuex-select", {
                          staticClass: "mt-3 mb-3",
                          attrs: {
                            options: _vm.templateOptions,
                            "sort-results": false,
                            "return-object": "",
                            filled: "",
                            getter: () => _vm.currentLayout.key,
                            setter: (value) =>
                              (_vm.carouselModel = value.index),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "page-layout-wrapper__panel--description",
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "preparation.templates.selectTemplateDescription.template"
                              )
                            ) +
                              "\n                            " +
                              _vm._s(_vm.carouselModel + 1)
                          ),
                        ]),
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "preparation.templates.selectTemplateDescription.of"
                              )
                            ) +
                            "\n                        " +
                            _vm._s(_vm.templateOptions.length) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "feature-toggle",
                      { attrs: { toggle: _vm.canEditChannel } },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass:
                              "page-layout-wrapper__panel--select-button mt-3",
                            attrs: { outlined: "", disabled: _vm.isDisabled },
                            on: {
                              click: function ($event) {
                                return _vm.setSelectedTemplate(
                                  _vm.currentLayout.key
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t("actions.select")
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showSlotsPageLayout
        ? _c(
            "div",
            { staticClass: "page-layout-wrapper__selector" },
            [
              _c("page-layout-grid", {
                attrs: {
                  layout: _vm.selectedResourceDefinition.pages[0].slots || [],
                  "layout-key": null,
                  model: _vm.model,
                  selected: true,
                },
                on: {
                  "auto-save": function ($event) {
                    return _vm.$emit("auto-save")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }