<template functional>
    <div class="product" :class="[data.staticClass, { 'product--compact': props.layout.compact }]">
        <div class="full-flex-column-top">
            <badge :type="$options.methods.getBadgeType(props.product)" />
        </div>
        <!-- Prepend slot - only available when not in compact mode-->
        <div
            v-if="!props.layout.compact"
            :class="
                $options.methods.getFormattedLayout(props.layout, props.product).prependSlot
                    .cssClass
            "
            class="product__slot full-flex-column-top"
        >
            {{ $options.methods.getFormattedLayout(props.layout, props.product).prependSlot.value }}
        </div>
        <div class="product__inner-grid">
            <!-- Upper left slot -->
            <div
                :class="
                    $options.methods.getFormattedLayout(props.layout, props.product).slotUpperLeft
                        .cssClass
                "
                class="product__slot"
            >
                {{
                    $options.methods.getFormattedLayout(props.layout, props.product).slotUpperLeft
                        .value
                }}
            </div>
            <!-- Upper right slot -->
            <div
                :class="
                    $options.methods.getFormattedLayout(props.layout, props.product).slotUpperRight
                        .cssClass
                "
                class="product__slot product__slot--right"
            >
                {{
                    $options.methods.getFormattedLayout(props.layout, props.product).slotUpperRight
                        .value
                }}
            </div>
            <!-- Lower left slot-->
            <div
                :class="
                    $options.methods.getFormattedLayout(props.layout, props.product).slotLowerLeft
                        .cssClass
                "
                class="product__slot"
            >
                {{
                    $options.methods.getFormattedLayout(props.layout, props.product).slotLowerLeft
                        .value
                }}
            </div>
            <!-- Lower right slot -->
            <div
                :class="
                    $options.methods.getFormattedLayout(props.layout, props.product).slotLowerRight
                        .cssClass
                "
                class="product__slot truncate-text product__slot--right"
            >
                {{
                    $options.methods.getFormattedLayout(props.layout, props.product).slotLowerRight
                        .value
                }}
            </div>
        </div>
        <div
            v-if="props.draggable && !parent.isReadOnly"
            class="product__draggable full-flex-column-center"
        >
            <icon icon-name="cross-move" small />
        </div>
        <div v-if="props.selectable" class="product__draggable full-flex-column-center">
            <vuex-checkbox
                :getter="() => props.selected"
                :setter="
                    value => $options.methods.productSelectorSetter(value, props.product, listeners)
                "
                :disabled="props.disabled"
            />
        </div>
    </div>
</template>

<script>
import { get, set } from 'lodash';
import {
    prependSlot,
    slotUpperLeft,
    slotLowerLeft,
    slotUpperRight,
    slotLowerRight,
} from '@enums/product-lozenge-slots';
import { getBadgeType } from '@/js/utils/products-utils';
import SlotFormatters from './product-slot-formatters';

// Used to keep track of each products layout to reduce the amount of
// times getFormattedLayout is calculated. Will contain one entry per
// product-location combination.
const productFormattedLayout = {};

export default {
    props: {
        product: {
            required: true,
            type: Object,
        },
        layout: {
            required: true,
            type: Object,
        },
        draggable: {
            required: false,
            default: true,
            type: Boolean,
        },
        selectable: {
            required: false,
            default: false,
            type: Boolean,
        },
        selected: {
            required: false,
            type: Boolean,
            default: false,
        },
        disabled: {
            required: false,
            default: true,
            type: Boolean,
        },
    },

    methods: {
        getBadgeType,
        getFormattedLayout(layout, product) {
            // Check the cache for the current product and location
            const cachedLayout = get(productFormattedLayout, [
                product.productKey,
                layout.location || 'default',
            ]);
            if (cachedLayout) return cachedLayout;

            const slots = [
                prependSlot,
                slotUpperLeft,
                slotLowerLeft,
                slotUpperRight,
                slotLowerRight,
            ].reduce((acc, slot) => {
                if (slot === prependSlot && layout.compact) {
                    return acc;
                }

                const currentSlotConfig = layout[slot];
                if (currentSlotConfig.formatter) {
                    acc[slot] = {
                        ...currentSlotConfig,
                        value: SlotFormatters[currentSlotConfig.formatter.key](
                            product,
                            currentSlotConfig.formatter.options
                        ),
                    };
                } else {
                    acc[slot] = {
                        ...currentSlotConfig,
                        value: get(product, currentSlotConfig.field),
                    };
                }

                return acc;
            }, {});

            if (product.productKey) {
                // Cache the current product and layout combination for future use.
                set(
                    productFormattedLayout,
                    [product.productKey, layout.location || 'default'],
                    slots
                );
            }

            return slots;
        },

        productSelectorSetter(value, product, listeners) {
            if (listeners['product-selector-update']) {
                listeners['product-selector-update']({
                    productKey: product.productKey,
                    isSelected: value,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$product-background: #eff5f6;

// candidates-list is a class that is passed into the product component
// from candidates-list.vue.
.candidates-list {
    &__product {
        margin: 0 1.2rem;
        height: $product-height;
    }
}

.product {
    display: grid;
    grid-template-columns: 4rem 6rem auto 4rem;

    // Compact means removing the prepend slot which is 6rem in the template-columns above
    // and the drag icon slot which is the last 4rem
    &--compact {
        grid-template-columns: 4rem auto;
    }

    &__inner-grid {
        display: grid;
        grid-template-columns: auto auto;
    }

    padding: 0.5rem;

    background-color: $product-background;
    border-radius: 0.3rem;

    &__slot {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: inline-block;

        &--right {
            text-align: right;
        }
    }

    &__draggable {
        @include grid-span(2, 1);
    }
}
</style>
