import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.allowApplyAllNotificationsDialog } },
    [
      _vm.isChild
        ? _c("main-dialog", {
            ref: "dialog",
            attrs: {
              "translated-header": _vm.$tkey("header"),
              "btn-cancel-text": "actions.close",
              "button-text": _vm.$tkey("header"),
              "disabled-activator": !_vm.reportExists,
              "show-positive-action-button": false,
              "has-activator": true,
            },
            on: { close: _vm.close, open: _vm.open },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _vm.renderGrid
                        ? _c(
                            "div",
                            { staticClass: "notifications-report-dialog" },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "notifications-report-dialog__description",
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm._f("toSentenceCase")(
                                              _vm.$tkey(
                                                "promotionsWithNotificationsApplied"
                                              )
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("promo-ag-grid", {
                                    ref: "agGrid",
                                    attrs: {
                                      "row-data":
                                        _vm.reportWithDetails
                                          .promotionsWithNotificationsApplied,
                                      "column-defs": _vm.columnDefs,
                                      "grid-options": _vm.gridOptions,
                                      "default-col-def": _vm.defaultColDef,
                                      "grid-style":
                                        "width: 65rem; height: 30rem;",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(VDivider),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isEmpty(
                                _vm.reportWithDetails
                                  .promotionsWhichFailedToUpdate
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "notifications-report-dialog__description",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm._f("toSentenceCase")(
                                                  _vm.$tkey(
                                                    "promotionsWhichFailedToUpdate"
                                                  )
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("promo-ag-grid", {
                                        ref: "agGrid",
                                        attrs: {
                                          "row-data":
                                            _vm.reportWithDetails
                                              .promotionsWhichFailedToUpdate,
                                          "column-defs": _vm.columnDefs,
                                          "grid-options": _vm.gridOptions,
                                          "default-col-def": _vm.defaultColDef,
                                          "grid-style":
                                            "width: 65rem; height: 30rem;",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(VDivider),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isEmpty(
                                _vm.reportWithDetails
                                  .promotionsWithNoNotifications
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "notifications-report-dialog__description",
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm._f("toSentenceCase")(
                                                  _vm.$tkey(
                                                    "promotionsWithNoNotifications"
                                                  )
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("promo-ag-grid", {
                                        ref: "agGrid",
                                        attrs: {
                                          "row-data":
                                            _vm.reportWithDetails
                                              .promotionsWithNoNotifications,
                                          "column-defs": _vm.columnDefs,
                                          "grid-options": _vm.gridOptions,
                                          "default-col-def": _vm.defaultColDef,
                                          "grid-style":
                                            "width: 65rem; height: 30rem;",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1552245295
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }