import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "dialogs.promotionErrors.header",
      "has-activator": _vm.hasActivator,
      "show-positive-action-button": false,
      "is-error-dialog": _vm.hasExecutionErrors,
      "btn-close-text": "actions.close",
    },
    on: { close: _vm.closeDialog },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    VTooltip,
                    {
                      attrs: { "z-index": "300", bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(_vm._b({}, "span", attrs, false), on),
                                  [
                                    _vm.errors.length
                                      ? _c(
                                          "icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "icon-name":
                                                  "red-error-triangle",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            !_vm.disabled && activator
                                          )
                                        )
                                      : _vm.warnings.length
                                      ? _c(
                                          "icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "icon-name":
                                                  "orange-warning-circle",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            !_vm.disabled && activator
                                          )
                                        )
                                      : _vm.infos.length
                                      ? _c(
                                          "icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                "icon-name":
                                                  "grey-warning-square",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                            },
                                            !_vm.disabled && activator
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.toolTipMessage)
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return _vm._l(
              Object.keys(_vm.allErrorsMap),
              function (errorSeverity) {
                return _c(
                  "div",
                  { key: errorSeverity, staticClass: "error-list-container" },
                  [
                    _vm.allErrorsMap[errorSeverity] &&
                    _vm.allErrorsMap[errorSeverity].length
                      ? _c("div", { staticClass: "error-list" }, [
                          _c(
                            "div",
                            { class: `error-list__${errorSeverity}` },
                            [
                              errorSeverity === "error"
                                ? _c("icon", {
                                    attrs: {
                                      "icon-name": "red-error-triangle",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              errorSeverity === "warning"
                                ? _c("icon", {
                                    attrs: {
                                      "icon-name": "orange-warning-circle",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              errorSeverity === "info"
                                ? _c("icon", {
                                    attrs: {
                                      "icon-name": "grey-warning-square",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { class: `error-list__${errorSeverity}--text` },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$tkey(`${errorSeverity}Description`)
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "error-list__table" },
                            [
                              _c("span", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$tkey("table.code")
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$tkey("table.source")
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _c("b", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$tkey("table.description")
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.allErrorsMap[errorSeverity],
                                function (error, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: error.key,
                                      staticClass:
                                        "error-list__table--contents",
                                      class: {
                                        "error-list__table--striped":
                                          index % 2 === 0,
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toSentenceCase")(error.key)
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toSentenceCase")(
                                              _vm.$tkey(
                                                `sources.${error.source}`
                                              )
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toSentenceCase")(
                                              error.message
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }
            )
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }