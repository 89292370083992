var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "14px",
        height: "14px",
        viewBox: "0 0 14 14",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Trash")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "parent-child_v1-5",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Parent-Child---03-02",
                transform: "translate(-1392.000000, -572.000000)",
                fill: "#2F477C",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group-23",
                    transform: "translate(1222.000000, 551.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Icon-/-Trash",
                        transform: "translate(169.000000, 20.000000)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Combined-Shape",
                          d: "M12.5,1.5 C13.6045695,1.5 14.5,2.3954305 14.5,3.5 L14.5,12.5 C14.5,13.6045695 13.6045695,14.5 12.5,14.5 L3.5,14.5 C2.3954305,14.5 1.5,13.6045695 1.5,12.5 L1.5,3.5 C1.5,2.3954305 2.3954305,1.5 3.5,1.5 L12.5,1.5 Z M12.5,2.5 L3.5,2.5 C2.98716416,2.5 2.56449284,2.88604019 2.50672773,3.38337887 L2.5,3.5 L2.5,12.5 C2.5,13.0128358 2.88604019,13.4355072 3.38337887,13.4932723 L3.5,13.5 L12.5,13.5 C13.0128358,13.5 13.4355072,13.1139598 13.4932723,12.6166211 L13.5,12.5 L13.5,3.5 C13.5,2.94771525 13.0522847,2.5 12.5,2.5 Z M10.5,7.4 C10.7761424,7.4 11,7.62385763 11,7.9 L11,8.1 C11,8.37614237 10.7761424,8.6 10.5,8.6 L5.5,8.6 C5.22385763,8.6 5,8.37614237 5,8.1 L5,7.9 C5,7.62385763 5.22385763,7.4 5.5,7.4 L10.5,7.4 Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }