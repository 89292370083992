<template>
    <div>
        <div class="ag-cell-label-container" :class="`ag-sort-${sort}`">
            <span
                v-if="!params.unsortable"
                aria-hidden="true"
                class="ag-header-icon ag-header-label-icon"
                :class="`ag-sort-${sort}-icon`"
                @click="toggleSort"
            >
                <span role="presentation" :class="`ag-icon ag-icon-${sortShort}`" />
            </span>
            <component :is="params.iconComponent" v-if="params.iconComponent" />
        </div>
    </div>
</template>

<script>
// https://www.ag-grid.com/vue-grid/component-header/
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            sort: 'none',
            sortOptions: ['none', 'ascending', 'descending'],
        };
    },
    computed: {
        sortShort() {
            return this.sort.replace('ending', '');
        },
    },
    mounted() {
        this.params.column.addEventListener('sortChanged', this.onSortChanged);
        this.onSortChanged();
    },
    methods: {
        onMenuClicked() {
            this.params.showColumnMenu(this.$refs.menuButton);
        },

        onSortChanged() {
            if (this.params.column.isSortAscending()) {
                this.sort = 'ascending';
            } else if (this.params.column.isSortDescending()) {
                this.sort = 'descending';
            } else {
                this.sort = 'none';
            }
        },

        toggleSort(event) {
            const currentSortIndex = this.sortOptions.indexOf(this.sort);
            const newSort = this.sortOptions[(currentSortIndex + 1) % this.sortOptions.length];
            this.sort = newSort;
            this.params.setSort(this.sortShort, event.shiftKey);
        },
    },
});
</script>
