<template>
    <div :class="['scope', cssClass]">
        <div v-if="isGroupOfProducts" class="scope__group-of-products">
            <vuex-text-field
                :getter="() => modelGetter('description')"
                :setter="value => modelSetter('description', value)"
                class="scope__product-description"
                filled
                :validations="descriptionValidations"
            />
            <component
                :is="valueComponent"
                :getter="() => modelGetter('value')"
                :setter="value => modelSetter('value', value)"
                class="scope__value"
                filled
                :format="numberFormatter"
                :need-pre-validation-on-set="needPreValidationOnSet"
                :validations="valueValidations"
            />
        </div>

        <div v-else class="scope__individual-products">
            <vuex-text-field
                :getter="() => modelGetter('description')"
                :setter="value => modelSetter('description', value)"
                class="scope__product-description"
                filled
                :validations="descriptionValidations"
            />
            <ul class="scope__products">
                <li
                    v-for="(product, index) in model.products"
                    :key="`editable::${product.productKey}::${index}`"
                >
                    <vuex-select
                        class="scope__product"
                        :placeholder="$t('placeholders.selectProduct') | toSentenceCase"
                        item-value="_id"
                        item-text="displayLabel"
                        :options="getFilteredProductsOptions(product.productKey)"
                        :getter="() => productGetter('productKey', product.productKey)"
                        :setter="value => productSetter(index, value)"
                        :validations="productValidations"
                        filled
                    />
                    <component
                        :is="valueComponent"
                        :getter="() => product.value"
                        :setter="value => productValueSetter(index, value)"
                        class="scope__value"
                        filled
                        :format="numberFormatter"
                        :need-pre-validation-on-set="needPreValidationOnSet"
                        :validations="valueValidations"
                    />
                    <delete-button
                        class="scope__delete-btn"
                        background
                        @delete="deleteProduct(index)"
                    />
                </li>
            </ul>
            <div class="scope__add-btn-container">
                <create-new-button
                    class="scope__add-btn"
                    :disabled="isCreateButtonDisabled"
                    background
                    @createNew="addNewProduct"
                />
                <div v-if="isCreateButtonDisabled" class="error--text">
                    {{ $t('variableFundingAgreements.noSupplierAndCategory') | toSentenceCase }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { find, some, filter, pull, isNil, isEmpty } from 'lodash';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import validators from '@/js/validators';

export default {
    mixins: [vuexComponentMixin],
    props: {
        cssClass: String,
        valueComponent: String,
        categoryKeys: {
            type: Array,
            required: true,
        },
        supplierKey: {
            type: Number,
            default: null,
        },
        productsOptions: {
            required: true,
            type: Array,
        },
        numberFormatter: String,
    },
    computed: {
        ...mapGetters('variableFundingAgreements', ['getStagingAreaField']),
        descriptionValidations() {
            return [
                {
                    validator: validators.required,
                },
                {
                    validator: validators.maxLength,
                    params: [250],
                },
            ];
        },

        valueValidations() {
            return [
                {
                    validator: validators.required,
                },
                {
                    validator: validators.minValue,
                    params: [0],
                },
                ...(this.valueComponent === 'vuex-percentage-input'
                    ? [
                          {
                              validator: validators.maxValue,
                              params: [100],
                          },
                      ]
                    : []),
            ];
        },

        productValidations() {
            return [
                {
                    validator: validators.required,
                },
            ];
        },

        isCreateButtonDisabled() {
            return isNil(this.supplierKey) || isEmpty(this.categoryKeys);
        },

        isGroupOfProducts() {
            return isNil(this.model) || (this.model && isNil(this.model.products));
        },
    },
    methods: {
        modelGetter(field) {
            return this.model ? this.model[field] : null;
        },
        modelSetter(field, value) {
            this.model = { ...this.model, [field]: value };
        },

        productGetter(field, value) {
            return find(this.productsOptions, productOption => productOption[field] === value);
        },

        productSetter(index, productId) {
            const oldProduct = this.model.products[index];
            const newProduct = this.productGetter('_id', productId);
            this.model.products[index] = {
                productKey: newProduct.productKey,
                value: oldProduct.value,
            };
            this.model = { ...this.model, products: [...this.model.products] };
        },

        productValueSetter(index, value) {
            const oldProduct = this.model.products[index];
            this.model.products[index] = { ...oldProduct, value };
            this.model = { ...this.model, products: [...this.model.products] };
        },

        getFilteredProductsOptions(productKey) {
            // Filtering the dropdown to only products that have not yet been selected
            return filter(this.productsOptions, productOption => {
                const isProductSelected = some(
                    this.model.products,
                    product => product.productKey === productOption.productKey
                );
                // exclude selected products, include current product
                return !isProductSelected || productOption.productKey === productKey;
            });
        },

        addNewProduct() {
            const newProduct = { productKey: null, value: null };
            this.model = { ...this.model, products: [...this.model.products, newProduct] };
        },

        deleteProduct(index) {
            pull(this.model.products, this.model.products[index]);
            this.model = {
                ...this.model,
                products:
                    this.model.products.length === 0
                        ? [{ productKey: null, value: null }]
                        : [...this.model.products],
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.scope {
    &__group-of-products {
        display: flex;
    }

    &__value {
        padding-left: 1rem;
        width: 8rem;
    }
    &__individual-products {
        @include flex-column;
    }
    &__products {
        @include flex-column;
        padding: 0;

        li {
            @include flex-row;
            padding-top: 0.3rem;
            height: 3.1rem;

            &:first-child {
                border-top: 0.1rem solid $promo-grey;
                padding-top: 0.5rem;
                margin-top: 0.5rem;
                height: 3.4rem;
            }

            &::v-deep .rtls-select {
                input {
                    padding-left: 0.5rem;
                }
            }
        }
    }
    &__product {
        width: 100%;
    }
    &__delete-btn {
        height: 2.8rem;
    }

    &__add-btn-container {
        display: flex;
        padding-top: 1rem;
        .error--text {
            padding-left: 1rem;
        }
    }
}
</style>
