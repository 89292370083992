const mixin = {
    props: {
        makeReadOnly: {
            type: Boolean,
            default: false,
        },
        readOnlyReason: {
            type: String,
            default: '',
        },
        ignoreParentReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            overrideReadOnly: false,
        };
    },
    computed: {
        isReadOnly() {
            return (
                !this.overrideReadOnly &&
                (this.makeReadOnly ||
                    (this.propagatedIgnoreParentReadOnly
                        ? false
                        : this.$parent && this.$parent.isReadOnly))
            );
        },
        propagatedIgnoreParentReadOnly() {
            return (
                this.ignoreParentReadOnly ||
                (this.$parent && this.$parent.propagatedIgnoreParentReadOnly)
            );
        },
        propagatedReadOnlyReason() {
            return this.readOnlyReason || (this.$parent && this.$parent.propagatedReadOnlyReason);
        },
    },
};

export default mixin;
