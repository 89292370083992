<template>
    <v-autocomplete
        ref="autocomplete"
        v-model="value"
        :items="options"
        :no-data-text="
            $t('planning.promotionsMaintenance.products.newProductSetupModal.noMatchingProducts')
                | toSentenceCase
        "
        :menu-props="{ auto: true }"
        dense
        allow-overflow
    />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            options: [],
            value: '',
        };
    },
    created() {
        // Format the product data for display.
        this.options = this.params.options;

        // Set initial value of autocomplete to be the existing value.
        // Look up the product to ensure the correct option is set.
        const selectedProxyProduct = this.options.find(
            proxyProduct => proxyProduct.value.productKey === this.params.data.proxyProductKey
        );

        this.value = selectedProxyProduct ? selectedProxyProduct.value : null;
    },
    mounted() {
        // Focus the input and show the menu by default to ensure the user
        // doesn't have to click multiple times within the grid cell.
        this.$nextTick(() => {
            this.$refs.autocomplete.focus();
            this.$refs.autocomplete.isMenuActive = true;
        });
    },
    methods: {
        getValue() {
            return this.value;
        },
        isPopup() {
            return false;
        },
    },
});
</script>
