var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addButton
    ? _c("create-new-button", {
        attrs: {
          "btn-text": _vm.buttonText,
          events: _vm.activator,
          "has-border": _vm.hasBorder,
          disabled: _vm.disabled,
          background: "",
        },
        on: {
          createNew: function ($event) {
            return _vm.$emit("click")
          },
        },
      })
    : _vm.editButton
    ? _c("icon-button", {
        attrs: {
          events: _vm.activator,
          "ignore-parent-read-only": _vm.ignoreParentReadonly,
          disabled: _vm.disabled,
          icon: "edit",
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("click")
          },
        },
      })
    : _vm.deleteButton
    ? _c("delete-button", {
        attrs: {
          events: _vm.activator,
          "data-id-e2e": _vm.deleteButtonId,
          "ignore-parent-read-only": _vm.ignoreParentReadonly,
          disabled: _vm.disabled,
          background: "",
        },
        on: { delete: _vm.fetchChildDependencies },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }