<template functional>
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Enrichment</title>
        <g id="Icons-/-Circle-full" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <circle id="Enrichment" fill="#8BA4B7" cx="9" cy="9" r="9" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CircleFull',
};
</script>
