<template functional>
    <div :class="$options.methods.getFlexClass(props.vertical)" class="promotion-rag-colour">
        <div
            class="promotion-rag-colour__colour"
            :class="[$options.methods.getColourClass(props.colour, props.count, props.bigCircle)]"
        />
        <div
            v-if="props.displayCount"
            class="promotion-rag-colour__count"
            :class="$options.methods.getRagColourCountClass(props.vertical)"
        >
            {{ props.count | countNumber }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        colour: {
            type: Number,
            default: 0,
        },
        count: {
            type: Number,
            default: null,
            required: false,
        },
        vertical: {
            type: Boolean,
            default: false,
            required: false,
        },
        displayCount: {
            type: Boolean,
            default: true,
        },
        bigCircle: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getColourClass(colour, count, bigCircle) {
            // if colour is null we should show blue/grey circle using default styles
            // in other cases we have to show circle with border
            const bigCircleClass = bigCircle ? 'big-circle' : '';
            if (count === 0 && colour !== null) {
                return `promotion-rag-colour__border promotion-rag-colour__border--${colour} ${bigCircleClass}`;
            }
            return `promotion-rag-colour__colour--${colour} ${bigCircleClass}`;
        },
        getFlexClass(vertical) {
            return vertical ? 'flex-column' : 'flex-row';
        },
        getRagColourCountClass(vertical) {
            return vertical
                ? 'promotion-rag-colour__count--vertical'
                : 'promotion-rag-colour__count--horizontal';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.promotion-rag-colour {
    &__colour {
        height: 1.3rem;
        width: 1.3rem;
        border-radius: 5rem;
        margin-top: 0.3rem;
        background: $promo-product-badge;
        @include promo-rag-colours(background);
    }
    &__border {
        background: #fff;
        border: 0.2rem solid;
        @include promo-rag-colours(border-color);
    }
    &__count {
        &--vertical {
            margin-top: 0.2rem;
            text-align: center;
        }
        &--horizontal {
            margin-top: 0.2rem;
            margin-left: 0.5rem;
        }
    }
    .big-circle {
        height: 1.8rem;
        width: 1.8rem;
        margin-top: 0;
    }
}
</style>
