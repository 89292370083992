import { VPagination } from 'vuetify/lib/components/VPagination';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VPagination, {
    staticClass: "main-pagination",
    class: _vm.cssClasses,
    attrs: {
      length: _vm.length,
      "total-visible": _vm.totalVisible,
      disabled: _vm.disabled,
    },
    model: {
      value: _vm.pagination.page,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "page", $$v)
      },
      expression: "pagination.page",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }