const mixin = {
    data() {
        return {
            listHeight: '200',
            window: {
                height: 0,
            },
        };
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.height = window.innerHeight;
            const heightOfProduct = 60;
            const maxHeight = 800;

            // we are deducting the height of footer and header here + the space we need for the green buttons below (its just an estimate)
            let spaceToWorkWith = this.window.height - 420;
            if (spaceToWorkWith > maxHeight) spaceToWorkWith = maxHeight;

            let howManyProductsCanBeShown = Math.floor(spaceToWorkWith / heightOfProduct);
            if (howManyProductsCanBeShown < 1) howManyProductsCanBeShown = 1;

            this.listHeight = spaceToWorkWith;
            this.pagination.perPage = howManyProductsCanBeShown;
        },
    },
};

export default mixin;
