var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.renderIcon
    ? _c("icon", { attrs: { "icon-name": _vm.icon, disabled: _vm.isDisabled } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }