<template>
    <div class="ranking-tab">
        <div v-if="isRankAvailable" class="ranking-tab__products-count">
            <label>{{ $tkey(`rankSelect`) | toSentenceCase }}</label>
            <vuex-select
                :options="rankCountOptions"
                vuex-module="promotions"
                :namespace="namespace"
                :sort-results="false"
                field-name="rankedProductsCount"
                placeholder=""
                filled
            />
            <div class="ranking-tab__products-count--warning">{{ warningMessage }}</div>
        </div>
        <featured-products-grid
            vuex-module="promotions"
            :namespace="namespace"
            :getter="promoGetter"
            :promotion-editing-state="promotionEditingState"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { range } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    localizationKey: 'planning.promotionsMaintenance.resources.featuredProducts',

    props: {
        namespace: {
            required: true,
            type: String,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState('clientConfig', ['generalConfig', 'toggleLogic']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        isRankAvailable() {
            return (this.toggleLogic.rankTabColumnsConfig || []).find(item => item === 'rank');
        },
        rankCountOptions() {
            // _.range method returns sorted array by default
            return range(
                this.generalConfig.minRankedProductsCount,
                this.generalConfig.maxRankedProductsCount + 1
            ).map(value => ({ text: value, value }));
        },

        warningMessage() {
            const selectedRanks = this.selectedPromotion.products
                .filter(p => !!p.rank)
                .map(i => i.rank);

            const isIncomplete = range(1, this.selectedPromotion.rankedProductsCount + 1).some(
                value => !selectedRanks.includes(value)
            );

            if (isIncomplete) {
                return toSentenceCase(this.$tkey('rankIsNotComplete'));
            }

            const isInvalid = selectedRanks.some(
                value => value > this.selectedPromotion.rankedProductsCount
            );

            return isInvalid ? toSentenceCase(this.$tkey('rankIsInvalid')) : '';
        },

        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },
    },

    methods: {
        // Overrides the default getter to get the entire promotion rather than a specific field
        promoGetter() {
            return this.$store.state.promotions.stagingArea[this.namespace];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.ranking-tab {
    &__products-count {
        display: flex;
        align-items: center;
        padding: 1rem 1rem 0 1rem;

        label {
            padding-right: 1rem;
        }

        .rtls-select-container {
            width: 6rem;
        }

        &--warning {
            padding-left: 1rem;
            color: $negative-action;
        }
    }
}
</style>
