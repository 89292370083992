'use strict';

module.exports = {
    averageDiscount: 'averageDiscount',
    effectivenessRating: 'effectivenessRating',
    incrementalMargin: "incrementalMargin",
    incrementalNetSales: "incrementalNetSales",
    incrementalNetSalesRatio: "incrementalNetSalesRatio",
    incrementalTraffic: 'incrementalTraffic',
    mechanics: 'mechanics',
    mixedMargin: 'mixedMargin',
    profit: 'profit',
    promoSales: 'promoSales',
    promoMargin: 'promoMargin',
    totalSales: 'totalSales',
    volume: 'volume',
};