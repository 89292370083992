import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reward-requirement-option" },
    [
      _c("div", { staticClass: "reward-requirement-option__label" }, [
        _vm._v(_vm._s(_vm.prefixLabel)),
      ]),
      _vm._v(" "),
      _vm.rewardRequirementConfig.showNumberInput
        ? _c("vuex-number-input", {
            staticClass: "reward-requirement-option__number-input",
            attrs: {
              theme: "white",
              height: "21",
              format: `numbers.default.${_vm.rewardRequirementConfig.format}`,
              getter: () =>
                _vm.rewardRequirement[
                  _vm.rewardRequirementConfig.attributeToMapToNumberInput
                ],
              setter: (value) => _vm.updateValue(value),
              validations: _vm.rewardRequirementConfig.validations,
              disabled: !_vm.isRewardRequirementEditable,
              "hide-details": true,
              "data-id-e2e": "reward-requirement-number-input",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.rewardRequirementConfig.showDropDown
        ? _c("vuex-select", {
            staticClass: "reward-requirement-option__additional-reward",
            attrs: {
              getter: () => _vm.rewardRequirement.identifier,
              setter: (value) => _vm.updateAdditionalReward(value),
              options: _vm.additionalRewardDropDownOptions,
              disabled: !_vm.isRewardRequirementEditable,
              placeholder: _vm._f("toSentenceCase")(
                _vm.$tkey(
                  `placeholders.${_vm.rewardRequirementConfig.dropDownPlaceholder}`
                )
              ),
              "data-id-e2e": "reward-requirement-additional-reward",
              "item-text": "text",
              "item-value": "value",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "reward-requirement-option__label" }, [
        _vm._v(_vm._s(_vm.suffixLabel)),
      ]),
      _vm._v(" "),
      _vm.isRewardRequirementTypeEditable
        ? _c(
            VMenu,
            {
              attrs: { bottom: "", "offset-y": "", disabled: _vm.isReadOnly },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "icon-container" },
                          [
                            _c(
                              VIcon,
                              _vm._g(
                                _vm._b(
                                  {
                                    class: { disabled: _vm.isReadOnly },
                                    attrs: { small: "" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  "\n                    mdi-dots-vertical\n                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1967413414
              ),
            },
            [
              _vm._v(" "),
              _c(
                VList,
                [
                  _vm._l(_vm.changeOptionToDropDownOptions, function (option) {
                    return _c(
                      VListItem,
                      {
                        key: option,
                        on: {
                          click: function ($event) {
                            return _vm.updateRewardRequirement(option)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$tkey(
                                  `rewardRequirementOptions.${option}`,
                                  { currencySymbol: _vm.currencySymbol }
                                )
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c(
                    VListItem,
                    {
                      staticClass:
                        "reward-requirement-option__remove-option-item",
                      on: { click: _vm.removeRewardRequirement },
                    },
                    [
                      _c("icon", { attrs: { "icon-name": "cancel" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "reward-requirement-option__remove-option-label",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$tkey("labels.remove")
                                )
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }