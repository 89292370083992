<template>
    <div class="summary-header">
        <div v-if="isExpanded" class="summary-header__actions">
            <label class="summary-header__label">
                {{ params.displayName }}
            </label>
            <main-dialog
                ref="dialog"
                heading="planning.promotionsMaintenance.offer.offerGroup.attributeSelector.header"
                btn-confirm-text="actions.save"
                has-activator
                @confirm="updateSelectedAttributes"
                @close="resetSelectedAttributes"
            >
                <template v-slot:actions="{ activator }">
                    <v-btn color="primary" depressed outlined v-on="activator">
                        <b>{{ $t('actions.select') | toSentenceCase }}</b>
                    </v-btn>
                </template>
                <template v-slot:default>
                    <div class="attribute-dialog">
                        <vuex-select
                            class="attribute-dialog__select"
                            item-text="attributeName"
                            item-value="attributeKey"
                            value-type="object"
                            autocomplete
                            multiple
                            chips
                            deletable-chips
                            :has-select-all="false"
                            :options="getAvailableAttributes"
                            :getter="() => selectedAttributes"
                            :setter="value => (selectedAttributes = value)"
                        />
                    </div>
                </template>
            </main-dialog>
        </div>

        <main-expand-button
            class="expand-btn"
            :is-expanded="isExpanded"
            horizontal
            background
            @expand="toggleCollapse"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { xor } from 'lodash';
import { mapGetters } from 'vuex';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.offer.offerGroup.attributeSelector',

    data() {
        return {
            isExpanded: true,
            selectedAttributes: [],
        };
    },
    computed: {
        ...mapGetters('promotions', ['getCheckboxListOptions']),
        ...mapGetters('attributeMetadata', ['getAvailableAttributes']),
        attributeOptions() {
            const attributeOptions = this.getCheckboxListOptions({
                attributeName: 'attributeName',
                attributeKey: 'attributeKey',
                resource: 'attributeMetadata',
                getOptionsFunction: 'getAvailableAttributes',
                useSelectedContext: false,
            });

            return attributeOptions;
        },
    },
    created() {
        this.selectedAttributes = this.params.selectedAttributes;
    },
    methods: {
        closeDialog() {
            if (this.$refs.dialog) this.$refs.dialog.close();
        },
        resetSelectedAttributes() {
            this.selectedAttributes = this.params.selectedAttributes;
            this.closeDialog();
        },
        toggleCollapse() {
            this.isExpanded = !this.params.columnGroup.isExpanded();
            this.params.setExpanded(this.isExpanded);
        },

        updateSelectedAttributes() {
            // Check there has been a change to the selected attributes.
            // If not, then we do not need to do anything.
            // xor will take items in one array but not the other. I.e. new items, or removed items.
            // If this is array is empty, we know no changes have been made.
            const hasChanges = xor(this.selectedAttributes, this.params.selectedAttributes).length;
            if (hasChanges) this.params.onSelectedAttributeChange(this.selectedAttributes);
            this.closeDialog();
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.summary-header {
    flex-grow: 1;
    height: 100%;
    display: flex;
    margin: 0 0 0 0.6rem;
    align-items: center;
    justify-content: space-between;

    &__actions {
        display: flex;
    }

    &__label {
        align-self: center;
        white-space: nowrap;
        padding-right: 1rem;
    }

    .expand-btn {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5rem;
    }
}

.attribute-dialog {
    width: 40rem;

    &__select {
        width: 100%;
    }
}
</style>
