<template>
    <div class="supplier-commitments-form">
        <v-form
            ref="form"
            v-model="validation.isValid"
            class="supplier-commitments-form__form"
            @submit.prevent
        >
            <div
                v-for="(field, index) in fields"
                v-show="!field.hidden"
                :id="`sc_${editContext ? editContext._id : 'new'}_field_${index}`"
                :key="index"
                class="supplier-commitments-form__grid-cell"
            >
                <div v-if="field.value">
                    {{ field.value }}
                </div>
                <component
                    :is="getVuexComponent(field.type)"
                    v-else-if="field.type && !field.expandedAreaComponent"
                    v-bind="field"
                    :vuex-module="vuexModule"
                    :namespace="computedNamespace"
                    :context="context"
                    :disabled="isDisabledField({ editable: isFieldEnabled(field) })"
                    v-on="
                        constructVBindEventObj({
                            events: getAttribute(field, 'events'),
                            context: editContext,
                        })
                    "
                />
                <div v-else-if="field.functionName">
                    <span>{{ callAction(field.functionName, editContext) }}</span>
                </div>
                <span v-else>{{ getAttribute(editContext, field.name) }}</span>
            </div>
            <div class="supplier-commitments-form__grid-cell">
                <notes
                    :notes="notes"
                    :disabled="!editContext"
                    open-left
                    @delete="removeNote"
                    @post="postNote"
                    @save="saveNote"
                />
            </div>
            <div class="supplier-commitments-form__grid-cell">
                <main-expand-button :is-expanded="true" @expand="toggleExpandedArea" />
            </div>
            <div v-if="isPromotionSupplierFundingView" class="supplier-commitments-form__grid-cell">
                <allocation-checkbox
                    :supplier-commitment="editContext"
                    :promotion-namespace="promotionNamespace"
                />
            </div>
            <div class="supplier-commitments-form__expanded-area">
                <div
                    v-for="field in expandedAreaFields"
                    :key="field.fieldName"
                    class="supplier-commitments-form__expanded-component"
                >
                    <component
                        :is="getVuexComponent(field.type)"
                        :ref="field.ref"
                        v-bind="field"
                        :vuex-module="vuexModule"
                        :namespace="computedNamespace"
                        :context="context"
                        v-on="
                            constructVBindEventObj({
                                events: getAttribute(field, 'events'),
                                context: editContext,
                            })
                        "
                    />
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NotesFieldKeys from '@enums/notes-field-keys';
import { sortBy, isEmpty, get } from 'lodash';
import measurements from '@enums/measurements';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import vuexFormMixin from '@/js/mixins/vuex-form';

export default {
    mixins: [vuexFormMixin, configDrivenGridComponentMixin],
    props: {
        expandedAreaFields: {
            type: Array,
            default: () => [],
        },
        isPromotionSupplierFundingView: {
            type: Boolean,
            default: false,
        },
        promotionNamespace: String,
    },
    computed: {
        ...mapGetters('suppliers', ['categoryFromFilter']),

        notes() {
            return this.editContext ? sortBy(this.editContext.notes, 'createdOn') : [];
        },
        allocatedPromotionIds() {
            return this.editContext ? this.editContext.allocatedPromotionIds : [];
        },
    },
    watch: {
        allocatedPromotionIds() {
            this.$refs.commitmentMatrix[0].updateMeasurementCounts();
        },
    },
    methods: {
        ...mapActions('supplierCommitments', [
            'setStagingAreaField',
            'addNote',
            'deleteNote',
            'updateNote',
        ]),
        isFieldEnabled(field) {
            // make the restricted field disabled if the supplier commitment have promotions allocated to it.
            return isEmpty(get(this.editContext, 'allocatedPromotionIds', [])) || !field.restricted;
        },
        setField(fieldName, value) {
            this.setStagingAreaField({
                namespace: this.computedNamespace,
                fieldName,
                value,
            });
        },
        toggleExpandedArea() {
            this.$emit('toggle');
        },

        getTotalCurrentAllocations({
            total = 0,
            productCurrent = 0,
            promotionCurrent = 0,
            measurement = measurements.promotion,
        }) {
            return `${
                measurement === measurements.promotion ? promotionCurrent : productCurrent
            } / ${total}`;
        },

        getPeriod({ startDate, endDate }) {
            const formattedStartDate = this.$options.filters.dateShorthand(startDate);
            const formattedEndDate = this.$options.filters.dateShorthand(endDate);
            return `${formattedStartDate} - ${formattedEndDate}`;
        },

        getValue({ value }) {
            return this.$n('numbers.default.currencyRounded', value);
        },

        totalValueHandler() {
            const { storeGroupResources } = this.stagingArea[this.computedNamespace];
            let value = 0;
            if (storeGroupResources) {
                value = storeGroupResources.reduce((acc, item) => acc + item.value, 0);
            }
            this.setField('value', value);
            return this.$n('numbers.default.currencyRounded', value);
        },
        totalCurrentAllocationsHandler(commitment) {
            const total = this.totalAllocationsHandler();
            this.setField('total', total);
            return this.getTotalCurrentAllocations({ ...commitment, total });
        },

        totalAllocationsHandler() {
            const { storeGroupResources } = this.stagingArea[this.computedNamespace];
            let total = 0;
            if (storeGroupResources) {
                total = storeGroupResources.reduce(
                    (totalAcc, storeGroupResource) =>
                        totalAcc +
                        storeGroupResource.resources.reduce((acc, resource) => {
                            return (
                                acc +
                                resource.instances.reduce(
                                    (noOfOccurrencesSum, instance) =>
                                        noOfOccurrencesSum + instance.noOfOccurrences,
                                    0
                                )
                            );
                        }, 0),
                    0
                );
            }
            return total;
        },

        supplierNameChange(supplierCommitment, newName) {
            this.$emit('supplierNameChange', newName);
        },

        measurementChange() {
            this.$refs.commitmentMatrix[0].updateMeasurementCounts();
        },

        matrixUpdated(supplierCommitment, newWidth) {
            this.$emit('matrix-updated', newWidth);
        },

        postNote(note) {
            this.addNote({
                note,
                id: this.editContext._id,
                fieldKey: NotesFieldKeys.root,
            });
        },
        removeNote(noteId) {
            this.deleteNote({
                noteId,
                id: this.editContext._id,
                fieldKey: NotesFieldKeys.root,
            });
        },
        saveNote(note) {
            this.updateNote({
                note,
                id: this.editContext._id,
                fieldKey: NotesFieldKeys.root,
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.supplier-commitments-form {
    display: contents;
    &__form {
        display: contents;
    }
    &__grid-cell {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.6rem 1rem;
        background-color: $promo-white;
        border-top: $promo-grey 0.1rem solid;
        font-weight: 400;
        line-height: 3rem;
        font-size: 1.2rem;
        color: $promo-text-colour;
        letter-spacing: 0;

        &::v-deep {
            .theme--light.v-label {
                color: $promo-text-colour;
            }

            .error--text {
                font-weight: 400;
            }
        }
    }

    & &__grid-cell:first-child {
        font-weight: 600;
    }

    &__radio-group {
        &::v-deep {
            .v-input--selection-controls {
                margin: 0;
                padding: 0;

                &__input {
                    margin-right: 0;
                    width: 1.8rem;

                    .v-icon.v-icon {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    &__expanded-area {
        padding: 0.6rem 1rem;
        background-color: $promo-white;
        grid-column: 1 / last-column;
    }

    &__expanded-component {
        display: contents;
    }
}
</style>
