<script>
import { mapActions, mapState } from 'vuex';
import { categories } from '@enums/filters';
import filterBar from '@/js/components/filter-bar';
import filterBarMixin from '@/js/pages/supplier/components/mixins/suppliers-filter-bar-mixin';

export default {
    extends: filterBar,
    mixins: [filterBarMixin],

    computed: {
        ...mapState('variableFundingAgreements', ['variableFundingAgreements']),
    },

    methods: {
        ...mapActions(['resetSuppliersFilters']),
        ...mapActions('variableFundingAgreements', [
            'fetchVariableFundingAgreementsSuppliersAndProductByCategory',
            'resetVariableFundingAgreementsState',
            'setSelectedCategory',
        ]),
        ...mapActions('supplierCommitments', [
            'resetSupplierCommitmentsState',
            'clearSelectedSupplierCommitment',
        ]),

        async onFilterValueChange({ filterKey, event }) {
            if (filterKey === categories) {
                // remove old supplier filter for updated category
                this.removeSupplierFilter();

                this.$emit('change-categories-filter');

                // fetch variableFundingAgreements, suppliers and products by category
                await this.setSelectedCategory({ categoryKey: event });

                return this.fetchVariableFundingAgreementsSuppliersAndProductByCategory(event);
            }
        },

        resetFilters({ filterId }) {
            // if category filter is not selected, we show empty list of
            // variable-funding-agreements and supplier-commitments
            this.resetVariableFundingAgreementsState();
            this.resetSupplierCommitmentsState();
            this.clearSelectedSupplierCommitment();
            this.resetSuppliersFilters({ filterId });
        },
    },
};
</script>
