import Vue from 'vue';
import tabStates from '@enums/tab-states';
import { isNil } from 'lodash';

function validateDateTab({ scenario, promotion }) {
    if (isNil(promotion.startDate) || isNil(promotion.endDate)) {
        return tabStates.invalid;
    }
    const promoStartDate = Vue.moment(promotion.startDate, 'YYYY-MM-DD').utc();
    const promoEndDate = Vue.moment(promotion.endDate, 'YYYY-MM-DD').utc();

    if (isNil(scenario)) {
        return promoStartDate && promoEndDate ? tabStates.completed : tabStates.invalid;
    }

    const scenarioStartDate = Vue.moment(scenario.startDate, 'YYYY-MM-DD').utc();
    const scenarioEndDate = Vue.moment(scenario.endDate, 'YYYY-MM-DD').utc();

    if (promoStartDate.isBefore(scenarioStartDate) || promoEndDate.isAfter(scenarioEndDate)) {
        return tabStates.invalid;
    }
    return scenarioStartDate.isSame(promoStartDate) && scenarioEndDate.isSame(promoEndDate)
        ? tabStates.notStarted
        : tabStates.completed;
}

export default {
    validateDateTab,
};
