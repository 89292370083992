<template>
    <div class="free-gifts-viewer">
        <promo-ag-grid
            :row-data="freeGiftsData"
            :column-defs="columnDefs"
            :grid-options="gridOptions"
            :grid-style="gridStyle"
            :default-col-def="defaultColDef"
            @cell-value-changed="onCellValueChanged"
        />
        <div class="grid-controls">
            <create-new-button
                :disabled="isAddingFreeGift || isCreateInProgress"
                :btn-text="buttonText"
                background
                @createNew="addNewFreeGift"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isNil } from 'lodash';

import FreeGiftsActions from '@/js/components/promo-ag-grid/free-gifts-actions';
import AgCheckbox from '@/js/components/promo-ag-grid/ag-checkbox';
import { toSentenceCase } from '@/js/utils/string-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
    localizationKey: 'admin.freeGifts',
    data() {
        return {
            buttonText: toSentenceCase(this.$tkey('table.controls.addFreeGift')),
            isAddingFreeGift: false,
            isCreateInProgress: false,
            gridOptions: {
                context: {
                    componentParent: this,
                },
                rowHeight: 35,
                paginationAutoPageSize: true,
                pagination: true,
                columnTypes: {
                    requiredField: agGridUtils.columnTypes.requiredField,
                },
            },
            gridStyle: 'width: 100%; height: 100%;',
            defaultColDef: {
                editable: true,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                flex: 1,
                resizable: true,
                filter: true,
                minWidth: 150,
            },
        };
    },
    computed: {
        ...mapState('freeGifts', ['freeGifts']),
        freeGiftsData() {
            return this.freeGifts;
        },
        columnDefs() {
            return [
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.identifier'))} *`,
                    field: 'identifier',
                    type: 'requiredField',
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.description'))} *`,
                    field: 'description',
                    type: 'requiredField',
                },
                {
                    headerName: toSentenceCase(this.$tkey('table.headers.isActive')),
                    field: 'isActive',
                    cellRendererFramework: AgCheckbox,
                    editable: false,
                    cellClass: 'flex-cell-start',
                    maxWidth: 100,
                },
                {
                    headerName: '',
                    cellRendererFramework: FreeGiftsActions,
                    editable: false,
                    sortable: false,
                    filter: false,
                    resizable: false,
                    minWidth: 30,
                    width: 30,
                    cellClass: 'flex-cell-center',
                },
            ];
        },
    },
    methods: {
        ...mapActions('freeGifts', ['createFreeGift', 'updateFreeGift']),
        async onCellValueChanged({ data }) {
            const { identifier, description, isActive, _id } = data;

            // Check if the row is ready for saving (i.e. all fields have a value).
            const isIdentifierInvalid = isNil(identifier) || identifier.trim() === '';
            const isDescriptionInvalid = isNil(description) || description.trim() === '';
            const isIsActiveValid = isNil(isActive);
            if (isIdentifierInvalid || isDescriptionInvalid || isIsActiveValid) {
                return;
            }

            // The row is ready to be saved. Determine whether it is a CREATE or UPDATE.
            const isCreate = isNil(_id);
            const freeGiftForSaving = {
                identifier,
                description,
                isActive,
            };

            if (isCreate) {
                if (!this.isCreateInProgress) {
                    this.isCreateInProgress = true;
                    await this.createFreeGift({ document: freeGiftForSaving });
                    this.isCreateInProgress = false;

                    this.enableGridControls();
                }
            } else {
                await this.updateFreeGift({
                    id: _id,
                    updates: freeGiftForSaving,
                });
            }
        },
        addNewFreeGift() {
            const newFreeGift = [
                {
                    identifier: null,
                    description: null,
                    isActive: true,
                },
            ];
            this.addFreeGiftToGrid({ freeGift: newFreeGift, editColumnKey: 'identifier' });
        },
        addFreeGiftToGrid({ freeGift, editColumnKey }) {
            const newRow = this.gridOptions.api.applyTransaction({ add: freeGift });

            // New free gifts are placed at the end of the grid, so navigate to the last page so the new row is visible.
            this.gridOptions.api.paginationGoToLastPage();
            this.gridOptions.api.ensureIndexVisible(newRow.add[0].rowIndex);

            // Start editing as soon as the row is added.
            this.gridOptions.api.startEditingCell({
                rowIndex: newRow.add[0].rowIndex,
                colKey: editColumnKey,
            });
            this.addedRowIndex = newRow.add[0].rowIndex;

            // Stop users from adding multiple items to the grid at once.
            this.disableGridControls();
        },
        disableGridControls() {
            this.isAddingFreeGift = true;
        },
        enableGridControls() {
            this.isAddingFreeGift = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.free-gifts-viewer {
    @include flex-column;
    height: 100%;

    padding-bottom: 3rem;
    background-color: $promo-white;

    .grid-controls {
        font-size: 1.4rem;
        padding-left: 3rem;
    }
}

.free-gifts-viewer::v-deep {
    .ag-theme-custom {
        width: 50% !important;
    }
    .flex-cell-start {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .flex-cell-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ag-center-cols-viewport {
        overflow-x: hidden;
    }
}
</style>
