import { isEmpty, sortBy, merge, lowerCase } from 'lodash';

import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    workPackages: [],
    selectedWorkPackage: {},
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getWorkpackageById
     * - getFilteredWorkPackages
     */
    getters: {
        activeWorkPackages: state =>
            sortBy(
                state.workPackages.filter(
                    ({ releaseDate, type }) => type === 'master' || releaseDate !== null
                ),
                [i => lowerCase(i.description)]
            ),

        inActiveWorkPackages: state =>
            state.workPackages.filter(({ releaseDate }) => releaseDate !== null),

        getSelectedWorkPackage: state => state.selectedWorkPackage,

        hasSelectedWorkPackage: state => !isEmpty(state.selectedWorkPackage),
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setWorkpackages
     * - deleteWorkpackage
     * - updateWorkpackage
     * - addWorkpackage
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        setSelectedWorkPackage(state, workPackage) {
            state.selectedWorkPackage = workPackage;
        },
    },

    /**
     * Default actions available:
     * - fetchWorkpackages
     * - deleteWorkpackage
     * - updateWorkpackage
     * - createWorkpackage
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        setWorkPackages({ commit }, workPackages) {
            commit('setWorkPackages', workPackages);
        },

        setUserSelectionWorkPackage({ commit, dispatch }, { params = {} } = {}) {
            commit('setSelectedWorkPackage', params);
            dispatch('filters/resetAllFilters', {}, { root: true }); // clears filter selections
            dispatch('gridView/resetAllFilters', {}, { root: true });
            dispatch('gridView/resetExpandedHierarchyLevels', {}, { root: true });
        },
    },
};

const mixinParams = {
    resource: 'workpackage',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
