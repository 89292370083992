var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "file-upload" }, [
    _c("form", { attrs: { enctype: "multipart/form-data" } }, [
      _c("input", {
        staticClass: "file-upload__input",
        attrs: {
          id: _vm.uploadId,
          type: "file",
          name: "file",
          accept: _vm.allowedExtensionsForInput,
          disabled: _vm.disabled || _vm.uploadInProgress,
        },
        on: {
          change: function ($event) {
            return _vm.fileSelected($event.target.name, $event.target.files)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "file-upload__label",
          class: { disabled: _vm.disabled },
          attrs: { for: _vm.uploadId },
        },
        [
          _c(
            "div",
            { staticClass: "file-upload__label-contents" },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.upload")))
                ),
              ]),
              _vm._v(" "),
              _c("icon", {
                attrs: { "icon-name": "upload", right: "", small: "" },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }