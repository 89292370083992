import { isArray, isEmpty } from 'lodash';

export function createOfferMechanicProductFromPromotionProduct({
    promotionProduct,
    productGroupId,
    productGroupName,
    productPrices,
}) {
    const offerMechanicProduct = {
        ...promotionProduct,
        avgNonPromoPrice: productPrices.avgNonPromoPrice,
        avgPromoPrice: 0,
        minNonPromoPrice: productPrices.minNonPromoPrice,
        minPromoPrice: 0,
        maxNonPromoPrice: productPrices.maxNonPromoPrice,
        maxPromoPrice: 0,
        avgNonPromoPriceExcTax: 0,
        avgPromoPriceExcTax: 0,
        // Only add product group related fields if data is provided for them.
        ...(productGroupId && { productGroupId }),
        ...(productGroupName && { productGroupName }),
    };

    return offerMechanicProduct;
}

export function createOfferMechanicProductGroupFromPromotionProductGroup({
    promotionProductGroup,
    productPrices,
}) {
    const offerMechanicProducts = [];
    const productGroupId = promotionProductGroup._id;
    const productGroupName = promotionProductGroup.name;

    promotionProductGroup.products.forEach(promotionProduct => {
        offerMechanicProducts.push(
            createOfferMechanicProductFromPromotionProduct({
                promotionProduct,
                productGroupId,
                productGroupName,
                productPrices: productPrices[promotionProduct.productKey],
            })
        );
    });

    return {
        _id: productGroupId,
        name: productGroupName,
        products: offerMechanicProducts,
    };
}

export function isPopulatedArray(array) {
    return isArray(array) && !isEmpty(array);
}
