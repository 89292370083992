var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ranking-tab" },
    [
      _vm.isRankAvailable
        ? _c(
            "div",
            { staticClass: "ranking-tab__products-count" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm._f("toSentenceCase")(_vm.$tkey(`rankSelect`)))
                ),
              ]),
              _vm._v(" "),
              _c("vuex-select", {
                attrs: {
                  options: _vm.rankCountOptions,
                  "vuex-module": "promotions",
                  tab: _vm.channels,
                  namespace: _vm.namespace,
                  "sort-results": false,
                  "field-name": "rankedProductsCount",
                  placeholder: "",
                  filled: "",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ranking-tab__products-count--warning" },
                [_vm._v(_vm._s(_vm.warningMessage))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ranking-and-placement-grid", {
        attrs: {
          "vuex-module": "promotions",
          namespace: _vm.namespace,
          getter: _vm.promoGetter,
          "promotion-editing-state": _vm.promotionEditingState,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }