var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "19px",
        height: "16px",
        viewBox: "0 0 19 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("text", { attrs: { x: "0", y: "11", fill: "#2F477C" } }, [
        _vm._v("Any"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }