<template>
    <div class="alerts-container" :style="gridColumns">
        <div
            v-for="(alertsGroup, index) in alertsGroupedByConfiguredCategories"
            :key="alertsGroup.key[0]"
            class="alerts-container__alerts-list"
        >
            <h2 v-if="!hasAlerts && index === 0" class="alerts-list__label">
                {{ $tkey('noAlerts') | toSentenceCase }}
            </h2>
            <workflow-task-alert
                v-for="alert in alertsGroup.alerts"
                :key="`${alert.subCampaignId}::${alert.task}::${alert.entity}}`"
                v-bind="alert"
                :compact="false"
            />
        </div>
        <div class="alerts-container__alerts-list" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { workflowAlertGrouping } from '@enums/feature-flags';

export default {
    localizationKey: 'landing.alerts',
    computed: {
        ...mapGetters('subCampaigns', ['alertsGroupedByConfiguredCategories']),
        ...mapState('clientConfig', ['toggleLogic']),

        gridColumns() {
            // Dynamically generate the number of columns which should be displayed
            // based on the client config.
            return {
                'grid-template-columns': `repeat(${
                    this.toggleLogic[workflowAlertGrouping].length
                }, 1fr) 3rem`,
            };
        },

        hasAlerts() {
            // Return true if at least one of the alert groups contains at least one alert.
            return this.alertsGroupedByConfiguredCategories.some(group => group.alerts.length);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.alerts-container {
    height: 100%;
    width: 100%;
    display: grid;

    &__alerts-list {
        height: 100%;
        width: 100%;
        padding: 2rem;
        border-right: 0.1rem solid $promo-grey-4;
        background-color: $promo-white;

        &:nth-last-child(odd) {
            background-color: $promo-grey-3;
        }

        &:last-child {
            padding: 0;
            border-right: 0;
        }
    }
}

.alerts-list {
    &__label {
        color: $promo-text-colour;
        font-weight: normal;
    }
}
</style>
