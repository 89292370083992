import routeNames from '@enums/route-names';
import ExportFormats from '@enums/export-formats';
import store from '@/js/store';

const guardsMap = {
    [routeNames.freeGiftsView](to, from, next) {
        next();
    },
    [routeNames.rateCardView](to, from, next) {
        next();
    },
    [routeNames.loyaltyPointsView](to, from, next) {
        next();
    },
    [routeNames.userPermissions](to, from, next) {
        next();
    },
    [routeNames.clientSystemsTesting](to, from, next) {
        next();
    },
    [routeNames.redisBull](to, from, next) {
        next();
    },
    [routeNames.featureToggler](to, from, next) {
        next();
    },
    async [routeNames.executionErrors](to, from, next) {
        const startDate = to.query.startDate || null;
        const endDate = to.query.endDate || null;
        await store.dispatch('promotions/downloadPromotionsWithExecutionErrors', {
            params: {
                export: true,
                exportFormat: ExportFormats.excel,
                startDate,
                endDate,
            },
        });
        next();
    },
};

export default guardsMap;
