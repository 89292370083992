var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vuex-select", {
    attrs: {
      placeholder: _vm._f("toSentenceCase")(
        _vm.$tkey("placeholder.selectProvision")
      ),
      options: _vm.params.options,
      "item-text": _vm.params.itemText,
      "item-value": _vm.params.itemValue,
      getter: () => _vm.params.value,
      "sort-results": false,
      setter: (newValue) => _vm.setValue(newValue),
      "validate-on-blur": false,
      clearable: _vm.params.clearable,
      "css-class": "ag-autocomplete",
      filled: "",
    },
    on: {
      change: function ($event) {
        return _vm.onChange()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }