var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Info")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icon-/-Info",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Icon-colour",
              d: "M8,16 C12.3891675,16 16,12.3891675 16,8 C16,3.6108325 12.3891675,0 8,0 C3.6108325,0 0,3.6108325 0,8 C0,12.3891675 3.6108325,16 8,16 Z M8,14.0421264 C4.65396189,14.0421264 1.95787362,11.3460381 1.95787362,8 C1.95787362,4.64593781 4.64593781,1.94984955 8,1.94984955 C11.3460381,1.94984955 14.0421264,4.64593781 14.0501505,8 C14.0501505,11.3460381 11.3540622,14.0421264 8,14.0421264 Z M7.99197593,5.60882648 C8.60180542,5.60882648 9.09127382,5.111334 9.09127382,4.50952859 C9.09127382,3.88365095 8.60180542,3.39418255 7.99197593,3.39418255 C7.38214644,3.39418255 6.88465396,3.88365095 6.88465396,4.50952859 C6.88465396,5.111334 7.38214644,5.60882648 7.99197593,5.60882648 Z M9.70912738,12.0521565 C10.0862588,12.0521565 10.3911735,11.7713139 10.3911735,11.3781344 C10.3911735,11.0090271 10.0862588,10.7201605 9.70912738,10.7201605 L9.00300903,10.7201605 L9.00300903,7.52657974 C9.00300903,7.00501505 8.74623872,6.66800401 8.25677031,6.66800401 L6.78034102,6.66800401 C6.39518556,6.66800401 6.09829488,6.95687061 6.09829488,7.32597793 C6.09829488,7.72718154 6.39518556,8 6.78034102,8 L7.49448345,8 L7.49448345,10.7201605 L6.6439318,10.7201605 C6.25877633,10.7201605 5.95386158,11.0090271 5.95386158,11.3781344 C5.95386158,11.7713139 6.25877633,12.0521565 6.6439318,12.0521565 L9.70912738,12.0521565 Z",
              fill: "#2F477C",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }