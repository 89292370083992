import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasParent
    ? _c(
        "div",
        {
          staticClass: "parent-permalink",
          on: { click: _vm.navigateToParentCampaign },
        },
        [
          _c(
            VChip,
            {
              staticClass: "parent-badge font-weight-bold",
              attrs: { small: "", label: "" },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("general.badge.parent")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "link" }, [
            _c("p", [_vm._v(_vm._s(_vm.parentSubCampaign.name))]),
            _vm._v(" "),
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("chevron_right"),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }