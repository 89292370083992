<template>
    <icon v-if="renderIcon" :icon-name="icon" :disabled="isDisabled" />
</template>

<script>
import Vue from 'vue';
import { isFunction } from 'lodash';

export default Vue.extend({
    computed: {
        renderIcon() {
            // conditional icon render based on row data
            if (this.params.renderFunction) {
                const expanded = this.params.node.expanded;
                return this.params.renderFunction(this.params, expanded);
            }
            return true;
        },

        icon() {
            return this.params.icon;
        },

        isDisabled() {
            const data = this.params.data;

            if (isFunction(this.params.isDisabled)) {
                return this.params.isDisabled(data);
            }

            return false;
        },
    },
    methods: {
        onClick() {
            if (!this.params.onClick) return;
            this.params.onClick(this.params.data);
        },
    },
});
</script>

<style lang="scss" scoped></style>
