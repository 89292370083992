<template>
    <div class="page">
        <tabs :tabs="tabs" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import routeNames from '@enums/route-names';
import navigation from '@/js/navigation';

export default {
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        tabs() {
            const tabs = [
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('parkingLotTabs.pastPromotions')
                    ),
                    disabled: false,
                    component: 'past-promotions',
                    navigateTo: navigation.toPastPromotionsView,
                    routeNames: [routeNames.pastPromotionsView],
                    class: 'past-promotions-tab',
                },
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('parkingLotTabs.parkingLot')
                    ),
                    disabled: false,
                    component: 'parking-lot',
                    navigateTo: navigation.toParkingLotView,
                    routeNames: [routeNames.parkingLotView],
                    class: 'parking-lot-tab',
                },
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('parkingLotTabs.subCampaignAllocation')
                    ),
                    disabled: false,
                    component: 'sub-campaign-allocation',
                    navigateTo: navigation.toSubCampaignAllocationView,
                    routeNames: [routeNames.subCampaignAllocationView],
                    class: 'sub-campaign-allocation-tab',
                },
            ];

            return tabs;
        },
    },
};
</script>

<style scoped lang="scss">
$past-promotions-tab: #aecadb;
$rtls-parking-lot-tab-color: #4ca183;
$rtls-sub-campaign-allocation-tab-color: #94ab28;

.page {
    height: 100%;
    padding: 2rem 1rem;
}

// Vuetify overrides needed for making the tabs fill the available vertical space.
::v-deep {
    .v-tabs.promo-tabs.theme--light {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .v-window.v-item-group.theme--light.v-tabs-items {
        flex: 1 0 0;
    }

    .v-window__container {
        height: 100%;
    }

    .v-window-item.tab {
        height: 100%;
        // margin: 0 0.5rem;

        &.past-promotions-tab {
            border-left: 0.3rem solid $past-promotions-tab;
        }

        &.parking-lot-tab {
            border-left: 0.3rem solid $rtls-parking-lot-tab-color;
        }

        &.sub-campaign-allocation-tab {
            border-left: 0.3rem solid $rtls-sub-campaign-allocation-tab-color;
        }
    }

    .promo-tabs .promo-tabs__tab {
        &.parking-lot-tab {
            border-left-color: $rtls-parking-lot-tab-color;
        }

        &.sub-campaign-allocation-tab {
            border-left-color: $rtls-sub-campaign-allocation-tab-color;
        }
    }

    .ag-checkbox-center {
        display: flex;
        align-content: center;
        justify-content: center;
    }
}
</style>
