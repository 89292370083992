import axios from 'axios';
import { to } from 'await-to-js';
import { get, omit } from 'lodash';
import Vue from 'vue';
import storeMixin from '../mixins/vuex-store';
import i18n from '../../vue-i18n';

const getInitialState = () => ({
    timeouts: {},
    jsonPromotionResponse: {},
    jsonPromotionError: {},
    transformedPromotion: {},
});

const params = {
    resource: 'execution',
    readOnly: true,
};

const {
    mutations: { resetState },
    actions: { handleResponseNotifications, resetState: resetStateAction },
} = storeMixin(params);

const store = {
    namespaced: true,

    state: getInitialState(),

    getters: {},

    mutations: {
        resetState,
        setTimeout(state, { promotionId, timeout }) {
            Vue.set(state.timeouts, promotionId, timeout);
        },
        setJsonPromotionResponse(state, res) {
            state.jsonPromotionResponse = res;
        },
        setJsonPromotionError(state, err) {
            state.jsonPromotionError = err;
        },
        setTransformedPromotion(state, transformedPromotion) {
            state.transformedPromotion = transformedPromotion;
        },
    },

    actions: {
        async queuePromotionForExecution({ dispatch }, { promotion }) {
            const promotionId = promotion._id || promotion.promotionId;
            const scenarioId = promotion.scenarioId;
            const isGhost = promotion.isGhost || false;
            const [error, result] = await to(
                axios.post('/api/execution-api/queuePromotionForExecution', {
                    promotionId,
                    scenarioId,
                    isGhost,
                })
            );

            const errorTranslationKey = get(
                error,
                'response.data.errorTranslationKey',
                'execution.notifications.executeError'
            );

            dispatch('handleResponseNotifications', {
                error,
                response: result,
                successMessage: i18n.t('execution.notifications.executeQueueSuccess'),
                errorMessage: i18n.t(errorTranslationKey),
            });
        },

        async queuePromotionsForExecution({ dispatch }, { promotionIds, scenarioId }) {
            const [error, result] = await to(
                axios.post('/api/execution-api/queuePromotionsForExecution', {
                    promotionIds,
                    scenarioId,
                })
            );

            const errorTranslationKey = get(
                error,
                'response.data.errorTranslationKey',
                'execution.notifications.executeQueueErrors'
            );

            dispatch('handleResponseNotifications', {
                error,
                response: result,
                successMessage: i18n.t('execution.notifications.executeQueueSuccesses'),
                errorMessage: i18n.t(errorTranslationKey),
            });
        },

        async executeSinglePromotion({ dispatch }, { promotion }) {
            const promotionId = promotion._id || promotion.promotionId;
            const [error, result] = await to(
                axios.post('/api/execution-api/executeSinglePromotion', {
                    promotionId,
                })
            );

            if (!error) {
                const executionId = get(result, 'data.executionId', null);

                dispatch(
                    'promotions/setPromotionExecution',
                    { promotionId: promotion._id, executionId },
                    { root: true }
                );
            }

            const errorTranslationKey = get(
                error,
                'response.data.errorTranslationKey',
                'execution.notifications.executeError'
            );

            dispatch('handleResponseNotifications', {
                error,
                response: result,
                successMessage: i18n.t('execution.notifications.executeSuccess'),
                errorMessage: i18n.t(errorTranslationKey),
            });
        },

        async deleteSinglePromotion({ dispatch }, { promotionId }) {
            const [error, result] = await to(
                axios.post('/api/execution-api/deleteSinglePromotion', {
                    promotionId,
                })
            );

            dispatch('handleResponseNotifications', {
                error,
                response: result,
                successMessage: i18n.t('execution.notifications.deleteSuccess'),
                errorMessage: i18n.t('execution.notifications.deleteError'),
            });

            return { error };
        },

        async validateSinglePromotion({ dispatch }, { promotion }) {
            const [error, result] = await to(
                axios.post('/api/execution-api/validateSinglePromotion', {
                    promotionId: promotion._id,
                })
            );

            dispatch('handleResponseNotifications', {
                error,
                response: result,
                successMessage: i18n.t('execution.notifications.validateSuccess'),
                errorMessage: i18n.t('execution.notifications.validateError'),
            });
        },

        executeSinglePromotionById({ dispatch, rootGetters }, { namespace: promotionId }) {
            const promotionForExecution = rootGetters['promotions/getPromotionForForecasting']({
                promotionId,
            });
            const { scenarioId } = promotionForExecution;
            const { isFavourite } = rootGetters['scenarios/getScenarioById']({
                _id: scenarioId,
                usePluralResourceName: true,
            });

            if (isFavourite) {
                return dispatch('queuePromotionForExecution', {
                    promotion: promotionForExecution,
                });
            }
        },

        executePromotionsById({ dispatch, rootGetters }, { entityIds: promotionIds }) {
            // get scenario for promotions
            const promotionForExecution = rootGetters['promotions/getPromotionForForecasting']({
                promotionId: promotionIds[0],
            });
            const { scenarioId } = promotionForExecution;
            const { isFavourite } = rootGetters['scenarios/getScenarioById']({
                _id: scenarioId,
                usePluralResourceName: true,
            });

            if (isFavourite) {
                return dispatch('queuePromotionsForExecution', {
                    promotionIds,
                    scenarioId,
                });
            }
        },

        validateSinglePromotionById({ dispatch, rootGetters }, { namespace: promotionId }) {
            const promotionForValidation = rootGetters['promotions/getPromotionForForecasting']({
                promotionId,
            });

            return dispatch('validateSinglePromotion', {
                promotion: promotionForValidation,
            });
        },

        setPromotionExecutionTimeout({ state, rootGetters, commit }, { promotionId }) {
            const { callbackTimeout } = rootGetters['clientConfig/getExecutionApiConfig'];
            const timeout = setTimeout(async () => {
                await axios.post('/api/execution-api/timeout', { promotionId });
            }, callbackTimeout * 1000);

            // clear previous timeout if any
            if (state.timeouts[promotionId]) {
                clearTimeout(state.timeouts[promotionId]);
            }

            commit('setTimeout', { promotionId, timeout });
        },

        clearPromotionExecutionTimeout({ state, commit }, { promotionId }) {
            if (state.timeouts[promotionId]) {
                clearTimeout(state.timeouts[promotionId]);
            }
            commit('setTimeout', { promotionId, timeout: null });
        },

        async submitJson({ commit, dispatch }, { json }) {
            const [error, response] = await to(
                axios.post('/api/execution-api/executeTransformedPromotion', {
                    json,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`execution.notifications.successJson`),
                errorMessage: i18n.t(`execution.notifications.errorJson`),
            });
            commit('setJsonPromotionResponse', omit(response, ['request']) || {});
            if (error) {
                commit(
                    'setJsonPromotionError',
                    {
                        ...omit(error, ['request']),
                        response: omit(error.response, ['request']),
                    } || {}
                );
                throw error;
            } else {
                commit('setJsonPromotionError', {});
            }
        },

        async transformPromotion({ commit, dispatch }, { json }) {
            const [error, response] = await to(
                axios.post('/api/execution-api/transformPromotion', {
                    json,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`execution.notifications.successTransform`),
                errorMessage: i18n.t(`execution.notifications.errorTransform`),
            });
            commit('setTransformedPromotion', get(response, 'data', {}) || {});
            commit('setJsonPromotionResponse', omit(response, ['request']) || {});
            if (error) {
                commit(
                    'setJsonPromotionError',
                    {
                        ...omit(error, ['request']),
                        response: omit(error.response, ['request']),
                    } || {}
                );
                throw error;
            } else {
                commit('setJsonPromotionError', {});
            }

            return get(response, 'data');
        },

        clearTestingValues({ commit }) {
            commit('setJsonPromotionError', {});
            commit('setJsonPromotionResponse', {});
            commit('setTransformedPromotion', {});
        },

        handleResponseNotifications,
        resetState: resetStateAction,
    },
};

export default store;
