import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "health-sidebar" },
    [
      _c("h1", { staticClass: "health-sidebar__title font-weight-bold" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(_vm.$t("planning.healthSidebar.title"))
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "health-sidebar__title-row" }, [
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("planning.healthSidebar.incrementalSales")
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("h4", [
          _vm._v(
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("planning.healthSidebar.incrementalMargin")
              )
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "health-sidebar__message" }, [
        _vm._v(_vm._s(_vm.$t("planning.healthSidebar.message"))),
      ]),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c("div", { staticClass: "summary-container" }, [
        _c(
          "div",
          { staticClass: "summary-container__promo-metric-item" },
          [
            _c("health-sidebar-kpi-item", {
              attrs: {
                kpi: _vm.getYearToDateAggregatedMetrics.incrementalSales,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "summary-container__promo-metric-item" },
          [
            _c("health-sidebar-kpi-item", {
              attrs: {
                kpi: _vm.getYearToDateAggregatedMetrics.incrementalMargin,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "summary-container__drop-down-item health-sidebar__expand-kpis promo-metric__drop-down",
          },
          [
            _c("main-expand-button", {
              attrs: { "is-expanded": _vm.isExpanded, background: "" },
              on: { expand: _vm.toggleKpis },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.isExpanded
        ? _c(
            "div",
            { staticClass: "health-sidebar__details" },
            [
              _c(VDivider),
              _vm._v(" "),
              _c("div", { staticClass: "health-sidebar__details-row" }, [
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-right" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("totalSales")(
                            _vm.getYearToDateAggregatedMetrics.incrementalSales
                              .delta
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-left" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("totalMargin")(
                            _vm.getYearToDateAggregatedMetrics.incrementalMargin
                              .delta
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(VIcon, { attrs: { size: "15" } }, [
                      _vm._v("change_history"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(VDivider),
              _vm._v(" "),
              _c("div", { staticClass: "health-sidebar__details-row" }, [
                _c(
                  "div",
                  { staticClass: "health-sidebar__details-column text-right" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.healthSidebar.sales")
                          )
                        ) +
                        ":\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "health-sidebar__details-column text-left" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.healthSidebar.margin")
                          )
                        ) +
                        ":\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "health-sidebar__details-row" }, [
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-right" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("totalSales")(
                            _vm.getYearToDateAggregatedMetrics.promoSales.value
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-left" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("totalMargin")(
                            _vm.getYearToDateAggregatedMetrics.promoMargin.value
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "health-sidebar__details-row" }, [
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-right" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$n(
                            "numbers.default.percentageEvolution",
                            _vm.getYearToDateAggregatedMetrics.promoSales
                              .efficiency
                          )
                        ) +
                        "\n                "
                    ),
                    _c("i", {
                      class: _vm.getEfficiencyArrowClass(
                        _vm.getYearToDateAggregatedMetrics.promoSales.efficiency
                      ),
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h4",
                  { staticClass: "health-sidebar__details-column text-left" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$n(
                            "numbers.default.percentageEvolution",
                            _vm.getYearToDateAggregatedMetrics.promoMargin
                              .efficiency
                          )
                        ) +
                        "\n                "
                    ),
                    _c("i", {
                      class: _vm.getEfficiencyArrowClass(
                        _vm.getYearToDateAggregatedMetrics.promoMargin
                          .efficiency
                      ),
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(VDivider),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "health-sidebar__details-row" },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "health-sidebar__details-column text-right",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("totalSales")(
                              _vm.getYearToDateAggregatedMetrics.promoSales
                                .delta
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h4",
                    { staticClass: "health-sidebar__details-column text-left" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("totalMargin")(
                              _vm.getYearToDateAggregatedMetrics.promoMargin
                                .delta
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(VIcon, { attrs: { size: "15" } }, [
                    _vm._v("change_history"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(VDivider),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }