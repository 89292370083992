import { VChip } from 'vuetify/lib/components/VChip';

var render = function render(_c, _vm) {
  return _c(
    VChip,
    {
      class: _vm.$options.methods.getBadgeInfo(
        _vm.props.type,
        _vm.props.isProposed,
        _vm.props.proxyProductKey
      ).background,
      staticStyle: { "padding-right": "0.6rem", "padding-left": "0.6rem" },
      attrs: { "x-small": "", label: "" },
    },
    [
      _c(
        "span",
        {
          staticClass: "font-weight-bold",
          class: _vm.$options.methods.getBadgeInfo(
            _vm.props.type,
            _vm.props.isProposed,
            _vm.props.proxyProductKey
          ).textColor,
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.parent.$t(
                  `general.badge.${
                    _vm.$options.methods.getBadgeInfo(
                      _vm.props.type,
                      _vm.props.isProposed,
                      _vm.props.proxyProductKey
                    ).type
                  }`
                )
              ) +
              "\n    "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }