import { render, staticRenderFns } from "./workflow-open-cross.vue?vue&type=template&id=6ae363f4&functional=true"
import script from "./workflow-open-cross.vue?vue&type=script&lang=js"
export * from "./workflow-open-cross.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports