var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "17px",
        height: "17px",
        viewBox: "0 0 17 17",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Empty Done")]),
      _vm._v(" "),
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-3",
            points: "7 0.75 3 4.75 1 2.75 0 3.75 3 6.75 8 1.75",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Success-/-Tick",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: { id: "Tick", transform: "translate(5.000000, 5.000000)" },
            },
            [
              _c("mask", { attrs: { id: "mask-4", fill: "white" } }, [
                _c("use", { attrs: { "xlink:href": "#path-3" } }),
              ]),
              _vm._v(" "),
              _c("use", {
                attrs: { fill: "#3D8113", "xlink:href": "#path-3" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("circle", {
            attrs: {
              id: "Combined-Shape",
              stroke: "#3D8113",
              cx: "8.5",
              cy: "8.5",
              r: "8",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }