<template>
    <v-form ref="nominationForm" v-model="formValidationState.isValid">
        <div class="nomination-matrix" :style="templateStyle">
            <div
                class="nomination-matrix__header"
                :style="{ 'grid-column-end': `span ${channelColumnsCount}` }"
            >
                <span class="nomination-matrix__label">{{
                    $tkey('resources') | toSentenceCase
                }}</span>
                <nomination-templates
                    v-if="shouldShowNominationTemplates"
                    ref="nominationTemplatesComponent"
                    :vuex-module="vuexModule"
                    :namespace="namespace"
                    :nomination-model="model"
                    :store-group-options="storeGroupOptions"
                    :resource-options="resourceOptions"
                    :make-read-only="isTemplateSelectDisabled"
                    @change="onNominationTemplateChange"
                />
            </div>
            <div class="nomination-matrix__sidebar--header">
                <span class="nomination-matrix__label">{{
                    $tkey('storeGroups') | toSentenceCase
                }}</span>
                <span v-if="isPromotion" class="nomination-matrix__label label-stores">
                    {{ $t('planning.nominations.stores') | toSentenceCase }}
                </span>
            </div>
            <template v-for="storeGroup in storeGroupOptions">
                <div
                    :key="storeGroup.reference.description"
                    class="nomination-matrix__sidebar-wrapper"
                >
                    <vuex-checkbox
                        class="nomination-matrix__sidebar"
                        :getter="() => getStoreGroupIds"
                        :setter="storeGroups => storeGroupsSetter(storeGroups)"
                        :value="storeGroup.reference._id"
                        :disabled="isOptionDisabled(storeGroup)"
                        :validations="storeGroupsValidations()"
                        :label="storeGroup.reference.description"
                    />
                    <div class="nomination-matrix__stores">
                        <exclude-stores-dialog
                            v-if="showExcludedStores"
                            :store-group="storeGroup.reference"
                            :excluded-stores="excludeStoresMap[storeGroup.reference.key]"
                            :disabled="
                                storeGroup.disabled ||
                                    !excludeStoresMap[storeGroup.reference.key] ||
                                    isReadOnly
                            "
                            @save="saveExcludedStores($event, storeGroup.reference.key)"
                        />
                    </div>
                </div>
            </template>

            <div
                v-if="createMode || showDetailedProvisions"
                class="nomination-matrix__sidebar--footer"
            />
            <div
                v-for="resource in model.resources"
                :key="resource.type"
                class="nomination-matrix--loop-wrapper"
            >
                <vuex-icon-checkbox
                    :key="resource.type + dialogOpen"
                    class="nomination-matrix__resource-header"
                    :class="{
                        'nominations-matrix__resource-header--unselected': !resource.enabled,
                    }"
                    :getter="() => resource.enabled"
                    :setter="value => toggleResource({ resource, value })"
                    :icon="resource.type"
                    :tooltip-label="getResourceLabel(resource.type)"
                    :style="generateHeaderWidth(resource)"
                    :disabled="isResourceDisabled(resource)"
                    :validations="resourceValidations(resource)"
                />
                <template v-if="resource.enabled">
                    <div
                        v-for="(instance, index) in resource.instances"
                        :key="index"
                        class="nomination-matrix--loop-wrapper"
                    >
                        <div class="nomination-matrix__state-header">
                            <v-tooltip v-if="instance.subType" z-index="400" top :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        color="primary"
                                        size="20"
                                        v-on="on"
                                        @click="
                                            canEditSubType &&
                                                createMode &&
                                                setInstanceSubType({
                                                    resource,
                                                    instance,
                                                    value: true,
                                                })
                                        "
                                    >
                                        mdi-information-outline
                                    </v-icon>
                                </template>
                                <span>{{
                                    $t(
                                        `preparation.promoResources.subTypes.${
                                            instance.subType.key
                                        }`
                                    ) | toSentenceCase
                                }}</span>
                            </v-tooltip>
                            <resource-state
                                v-if="showResourceState && instance.storeGroups.length"
                                class="nomination-matrix__icon"
                                :instance-key="instance.key"
                                :promotion-id="namespace"
                            />
                        </div>
                        <nomination-matrix-select-subtype
                            v-if="createMode && instance.updateSubType"
                            class="nomination-matrix__subType"
                            :options="resource.subTypes"
                            :initial-value="instance.subType"
                            @setValue="
                                value =>
                                    setInstanceSubType({
                                        resource,
                                        instance,
                                        subType: value,
                                    })
                            "
                            @cancel="setInstanceSubType({ resource, instance, value: false })"
                        />
                        <div
                            v-for="storeGroup in storeGroupOptions"
                            v-else
                            :key="index + storeGroup.reference.description"
                            class="nomination-matrix__cell"
                            :class="{
                                'nomination-matrix__cell--highlighted':
                                    highlightedInstanceKey === instance.key && isPromotion,
                            }"
                            @mouseover="setHighlightedInstanceKey({ instanceKey: instance.key })"
                            @mouseleave="setHighlightedInstanceKey({ instanceKey: null })"
                        >
                            <vuex-checkbox
                                v-if="displayCheckbox(instance, storeGroup.reference.key)"
                                :key="index + storeGroup.reference.description + dialogOpen"
                                :getter="() => instance.storeGroups"
                                :setter="
                                    storeGroups => instanceSetter(storeGroups, instance, resource)
                                "
                                :value="storeGroup.reference"
                                :disabled="
                                    isInstanceStoreGroupDisabled(instance, storeGroup.reference.key)
                                "
                                :validations="instanceValidations(resource, instance)"
                            />
                        </div>
                        <div
                            v-if="showDetailedProvisions"
                            class="nomination-matrix__add-detailed-provision"
                        >
                            <v-icon
                                v-if="isAddDetailedProvisionButtonVisibleForResource({ resource })"
                                small
                                :disabled="
                                    isAddDetailedProvisionButtonDisabledForInstance({
                                        resource,
                                        instance,
                                    })
                                "
                                class="nomination-matrix__icon"
                                @click="addDetailedProvision({ resource, instance })"
                                >add_box</v-icon
                            >
                        </div>

                        <div v-if="createMode" class="nomination-matrix__delete-resource">
                            <v-icon
                                small
                                :disabled="
                                    isDisabled ||
                                        instance.disabled ||
                                        resource.instances.length <= 1 ||
                                        resourcesUneditable
                                "
                                class="nomination-matrix__icon"
                                @click="deleteInstance({ resource, index })"
                            >
                                cancel
                            </v-icon>
                        </div>
                    </div>
                    <template v-if="createMode">
                        <div class="nomination-matrix__add-resource">
                            <template v-if="resource.subTypes && resource.subTypes.length">
                                <v-icon
                                    small
                                    :disabled="
                                        isDisabled ||
                                            resourcesUneditable ||
                                            instanceCreationInProgress[resource.type]
                                    "
                                    class="nomination-matrix__icon"
                                    @click="$set(instanceCreationInProgress, resource.type, true)"
                                    >add_box</v-icon
                                >
                            </template>
                            <v-icon
                                v-else
                                small
                                :disabled="isDisabled || resourcesUneditable"
                                class="nomination-matrix__icon"
                                @click="addResourceInstance({ resource })"
                                >add_box</v-icon
                            >
                        </div>
                        <nomination-matrix-select-subtype
                            v-if="instanceCreationInProgress[resource.type]"
                            class="nomination-matrix__subType nomination-matrix__subType--add-resource"
                            :options="resource.subTypes"
                            @setValue="
                                value =>
                                    addResourceInstance({
                                        resource,
                                        subType: value,
                                    })
                            "
                            @cancel="cancelCreateInstance({ resource })"
                        />
                        <div v-else class="nomination-matrix__add-resource--filler" />
                        <div class="nomination-matrix__add-resource--footer" />
                    </template>
                </template>
                <template v-else>
                    <div class="nomination-matrix__emptyCell--header" />
                    <div class="nomination-matrix__disabledResource" />
                </template>
            </div>
        </div>
        <v-messages :color="'error'" :value="formValidationError" />
        <confirm-dialog
            ref="instanceUpdateDialog"
            class="confirm-dialog"
            :question-text="$t('dialogs.removeResource.header')"
            :warning-text="instanceConfirmWarning"
            :action-text="$t('dialogs.removeResource.confirm')"
            :has-activator="false"
            @confirm="updateBasedOnPaths()"
            @close="cancelDialog()"
        />
    </v-form>
</template>

<script>
import {
    differenceBy,
    find,
    has,
    map,
    size,
    sortBy,
    sumBy,
    cloneDeep,
    forEachRight,
    filter,
    includes,
    findIndex,
    get,
    some,
    intersectionBy,
    intersectionWith,
    isEmpty,
    pick,
    lowerCase,
    uniq,
} from 'lodash';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { subCampaigns, promotions } from '@enums/resources';
import { canEditSubCampaign, canEditChannel } from '@enums/feature-flags';
import inputTypes from '@enums/input-types';
import uuid from 'uuid/v4';
import { removePromotionsFromInstances } from '@sharedModules/promotion-utils';
import { generateDefaultDetailedProvisionForResourceType } from '@/js/utils/nomination-matrix-utils';
import { toSentenceCase } from '@/js/utils/string-utils';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import i18n from '@/js/vue-i18n';

export default {
    mixins: [vuexComponentMixin],
    localizationKey: 'planning.nominations',
    props: {
        storeGroups: Object,
        resources: Object,
        createMode: {
            type: Boolean,
            default: false,
        },
        showDetailedProvisions: {
            type: Boolean,
            default: false,
        },
        showResourceState: {
            type: Boolean,
            default: false,
        },
        showExcludedStores: {
            type: Boolean,
            default: false,
        },
        parentContextType: String,
        onResourceChange: {
            type: Function,
            required: false,
        },
        isDefaultMatrix: {
            type: Boolean,
            default: false,
        },
        // two props below are used only to update default matrix in sub-campaign
        defaultStoreGroups: Object,
        defaultResources: Object,
        tab: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            inputTypes,
            localStoreGroups: [],
            localResources: [],
            isMounted: false,
            instanceCreationInProgress: {},
            newInstanceSubType: {},
            instancePaths: [],
            instanceObjectToUpdate: null,
            resourceToToggle: null,
            dialogOpen: false,
        };
    },

    events: {
        // when promotions are updated in store we need to update storeGroups and resources,
        // as they can be changed by sub-campaign or scenario
        onPromotionsUpdated() {
            this.resetLocalData();
        },
    },

    computed: {
        ...mapState('clientConfig', ['detailedProvisions', 'toggleLogic']),
        ...mapState('promotions', ['highlightedInstanceKey', 'selectedPromotionId']),
        ...mapGetters('clientConfig', ['getPromoResources']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),

        canEditSubType() {
            return this.toggleLogic[canEditSubCampaign] || this.toggleLogic[canEditChannel];
        },

        shouldShowNominationTemplates() {
            return (
                (this.toggleLogic[canEditSubCampaign] || this.toggleLogic[canEditChannel]) &&
                this.createMode
            );
        },

        formValidationState() {
            return this.$store.state[this.vuexModule].validationStates[this.namespace];
        },

        formValidationError() {
            // We need to wait form to be mounted before we can access it's inputs by reference.
            if (this.isMounted) {
                const inputs = this.$refs.nominationForm.inputs;
                const errorMessages = inputs.flatMap(input => input.errorBucket);
                // Just return the first error if there is one so the user knows what to address first.
                return errorMessages.slice(0, 1);
            }
            return [];
        },

        instanceConfirmWarning() {
            if (!this.instanceObjectToUpdate) {
                return `${this.$t('planning.promotionsViewer.effectResources')} ${uniq(
                    this.instancePaths.map(path => path.resource)
                ).join(', ')}`;
            }
            return `${this.$t(
                'planning.promotionsViewer.effectSubTypeResources'
            )} ${this.instancePaths.map(path => `${path.resource} - ${path.subType}`).join(', ')}`;
        },

        model() {
            const storeGroups = this.localStoreGroups;
            const storedResources = this.localResources;
            const resources = map(this.resourceOptions, option => {
                const storedResource =
                    find(storedResources, storeResource => storeResource.type === option.key) || {};

                const instances = [];

                if (this.isSubCampaign && !this.isDefaultMatrix) {
                    // as user can add sub-campaign instances using plus icon
                    // add items to instances from storedResource
                    (storedResource.instances || []).forEach(storedInstance => {
                        const optionInstance =
                            find(option.instances, { key: storedInstance.key }) || {};

                        instances.push({
                            key: storedInstance.key,
                            options: map(map(this.storeGroupOptions, 'reference'), 'key') || [],
                            storeGroups: storedInstance.storeGroups || [],
                            detailedProvision: storedInstance.detailedProvision || [],
                            disabled: optionInstance.disabled,
                            disabledStoreGroups: optionInstance.disabledStoreGroups || [],
                            subType: storedInstance.subType,
                            updateSubType: storedInstance.updateSubType,
                        });
                    });
                } else {
                    (option.instances || []).forEach(optionInstance => {
                        const storedInstance =
                            find(storedResource.instances, { key: optionInstance.key }) || {};

                        instances.push({
                            key: optionInstance.key,
                            options: map(optionInstance.storeGroups, 'key') || [],
                            storeGroups: storedInstance.storeGroups || [],
                            detailedProvision: storedInstance.detailedProvision || [],
                            disabled: optionInstance.disabled,
                            disabledStoreGroups: optionInstance.disabledStoreGroups || [],
                            subType: optionInstance.subType,
                        });
                    });
                }

                return {
                    type: option.key,
                    clientKey: option.clientKey,
                    enabled: !!storedResource.instances,
                    subTypes: option.subTypes,
                    instances,
                };
            });

            // sort resources in the same order as in client config
            // the options order can change, if the user selects/unselects channel in parent entity
            resources.sort((a, b) => {
                return (
                    findIndex(this.getPromoResources, { key: a.type }) -
                    findIndex(this.getPromoResources, { key: b.type })
                );
            });

            return { storeGroups, resources };
        },
        // return storeGroups without excludedStores
        // excludedStores field is available only for promotions
        // and is not required for other resources.
        getStoreGroupValue() {
            return map(this.model.storeGroups, storeGroup =>
                pick(storeGroup, ['_id', 'key', 'description', 'clientStoreGroupKey', 'attributes'])
            );
        },
        getStoreGroupIds() {
            return map(this.model.storeGroups, '_id');
        },
        channelColumnsCount() {
            // We have one column containing the store group names, then one column
            // per instance within each resource.
            return sumBy(this.model.resources, resource => {
                return resource.instances.length + this.createMode;
            });
        },
        storeGroupOptions() {
            const storedGroupOptions = find(
                this.parsedField,
                parsed => parsed.fieldName === this.storeGroups.fieldName
            ).options;
            return sortBy(storedGroupOptions, [i => lowerCase(i.reference.description)]);
        },
        resourceOptions() {
            return find(this.parsedField, parsed => parsed.fieldName === this.resources.fieldName)
                .options;
        },

        mainGridRows() {
            // Adjust the number of rows and columns based on the data.
            // 3 header rows, 1 row per store group and 1 row for delete icons (if in createMode)
            return 3 + this.storeGroupOptions.length + this.createMode;
        },

        enabledDetailedProvisions() {
            return this.detailedProvisions.filter(config => {
                const resource = this.model.resources.find(r => r.type === config.type);
                return resource && resource.enabled;
            });
        },

        detailedProvisionsGridRows() {
            // + 1 for detailed provision label row
            return this.showDetailedProvisions ? 1 + this.enabledDetailedProvisions.length : 0;
        },

        templateStyle() {
            const columns = this.model.resources.map(resource => {
                const subTypeSelectionVisible = this.instanceCreationInProgress[resource.type];
                let gridColumnTemplate = '';

                // Add one column to the grid for each resource instance
                resource.instances.forEach(instance => {
                    gridColumnTemplate += instance.updateSubType ? ` 15rem` : ` 3rem`;
                });

                if (this.createMode) {
                    // if in create mode add a column for the "add instance" button.
                    gridColumnTemplate += subTypeSelectionVisible ? ` 15rem` : ` 3rem`;
                }

                return gridColumnTemplate;
            });

            return {
                'grid-template-rows': `repeat(${this.mainGridRows +
                    this.detailedProvisionsGridRows}, max-content)`,
                'grid-template-columns': `max-content ${columns.join(' ')}`,
            };
        },
        isDisabled() {
            return this.isReadOnly;
        },

        resourcesUneditable() {
            return !this.resources.editable;
        },

        storeGroupsUneditable() {
            return !this.storeGroups.editable;
        },

        isSubCampaign() {
            return this.parentContextType === subCampaigns;
        },

        isPromotion() {
            return this.parentContextType === promotions;
        },

        isTemplateSelectDisabled() {
            // Prevent template from being changed if any of the resources or instances are disabled
            // or if user doesn't have permission to edit sub-campaign
            return (
                this.resourceOptions.some(
                    resource =>
                        resource.disabled || resource.instances.some(instance => instance.disabled)
                ) || this.resourcesUneditable
            );
        },
        excludeStoresMap() {
            return this.model.storeGroups.reduce((acc, sg) => {
                acc[sg.key] = sg.excludedStores;
                return acc;
            }, {});
        },
    },

    created() {
        this.resetLocalData();
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        ...mapActions('promotions', [
            'updateDetailedProvision',
            'addNewDetailedProvision',
            'setHighlightedInstanceKey',
        ]),
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        ...mapActions('subCampaigns', ['setUpdatedResourceDefinitions']),

        cancelDialog() {
            this.dialogOpen = false;
            this.instanceObjectToUpdate = null;
            this.resourceToToggle = null;
            this.instancePaths = [];
        },

        isAddDetailedProvisionButtonVisibleForResource({ resource }) {
            // Hide the button for adding detailed provisions if the resource type
            // doesn't have any possible provisions configured for it.
            return this.detailedProvisions.some(dp => dp.type === resource.type);
        },
        isAddDetailedProvisionButtonDisabledForInstance({ resource, instance }) {
            // Stop users from adding detailed provisions when the instance is not selected.
            if (!instance || isEmpty(instance.storeGroups)) {
                return true;
            }

            const emptyDetailedProvisionAlreadyExists = instance.detailedProvision.some(dp =>
                isEmpty(dp)
            );

            const allPossibleProvisionsAlreadyExist = this.detailedProvisions
                .filter(dp => dp.type === resource.type)
                .every(dp => {
                    return instance.detailedProvision.some(idp => {
                        return idp.name === dp.name;
                    });
                });
            return (
                this.isDisabled ||
                emptyDetailedProvisionAlreadyExists ||
                allPossibleProvisionsAlreadyExist
            );
        },
        addDetailedProvision({ resource, instance }) {
            const defaultDetailedProvision = generateDefaultDetailedProvisionForResourceType({
                resourceType: resource.type,
                detailedProvisionsConfig: this.detailedProvisions,
            });
            // open additional resource details expansion panel when detailed provision is added
            this.globalEmit('detailed-provision-added');
            // if there are no default detailed provisions,
            // just add empty object to show empty dropdown without updating stagingArea
            if (isEmpty(defaultDetailedProvision)) {
                return this.addNewDetailedProvision({
                    namespace: this.namespace,
                    instanceKey: instance.key,
                    newDetailedProvision: {},
                });
            }
            // as all the detailed provisions are shown in one dropdown,
            // clicking '+' icon should generate the list of default detailed provisions,
            // if there are any, and update stagingArea
            return this.updateDetailedProvision({
                namespace: this.namespace,
                resourceType: resource.type,
                instanceKey: instance.key,
                newValue: defaultDetailedProvision,
            });
        },
        resetLocalData() {
            this.localStoreGroups = this.getStagingAreaStoreGroups();
            this.localResources = this.getStagingAreaResources();
        },
        clearDefaultValues() {
            let defaultStoreGroups = cloneDeep(this.getStagingAreaDefaultStoreGroups());
            let defaultResources = cloneDeep(this.getStagingAreaDefaultResources());
            const fieldsToUpdate = [];
            if (defaultStoreGroups) {
                defaultStoreGroups = defaultStoreGroups.filter(dsg =>
                    this.localStoreGroups.find(lsg => lsg.key === dsg.key)
                );
                fieldsToUpdate.push({
                    fieldName: this.defaultStoreGroups.fieldName,
                    value: defaultStoreGroups,
                });
            }

            if (defaultResources) {
                defaultResources = defaultResources.filter(dr =>
                    this.localResources.find(lr => lr.type === dr.type)
                );
                fieldsToUpdate.push({
                    fieldName: this.defaultResources.fieldName,
                    value: defaultResources,
                });
            }

            if (defaultStoreGroups || defaultResources) {
                this.updateStagingArea(fieldsToUpdate);
            }
        },
        onNominationTemplateChange() {
            // Ensure the latest values are retrieved from the staging area
            // when the nomination matrix template changes.
            this.resetLocalData();
            this.clearDefaultValues();
        },
        getStagingAreaStoreGroups() {
            return get(
                this.$store.state[this.vuexModule].stagingArea[this.namespace],
                this.storeGroups.fieldName,
                []
            );
        },
        getStagingAreaResources() {
            return get(
                this.$store.state[this.vuexModule].stagingArea[this.namespace],
                this.resources.fieldName,
                []
            );
        },
        // This method is only used when updating default matrix in sub-campaign
        getStagingAreaDefaultStoreGroups() {
            return get(
                this.$store.state[this.vuexModule].stagingArea[this.namespace],
                this.defaultStoreGroups.fieldName,
                []
            );
        },
        // This method is only used when updating default matrix in sub-campaign
        getStagingAreaDefaultResources() {
            return get(
                this.$store.state[this.vuexModule].stagingArea[this.namespace],
                this.defaultResources.fieldName,
                []
            );
        },
        setInstanceSubType({ resource, instance, value, subType }) {
            const resources = this.localResources;

            const resourceToUpdate = resources.find(r => r.type === resource.type);
            const instanceToUpdate = resourceToUpdate.instances.find(i => i.key === instance.key);

            this.$set(instanceToUpdate, 'updateSubType', value);
            if (subType) this.$set(instanceToUpdate, 'subType', subType);
            this.setResources(resources);
        },

        removeStoreGroupFieldsToUpdate({
            storeGroups,
            resources,
            storeGroup,
            removeEmptyInstances,
            resourcesFieldName,
            storeGroupsFieldName,
        }) {
            const updatedStoreGroups = storeGroups.filter(s => s.key !== storeGroup.key);
            // remove storeGroup from resource instances
            resources.forEach(resource => {
                forEachRight(resource.instances, (instance, index) => {
                    if (!instance.storeGroups) {
                        return;
                    }
                    instance.storeGroups = instance.storeGroups.filter(
                        s => s.key !== storeGroup.key
                    );
                    // If this removes the last store group in a resource instance, then instance
                    // should be removed entirely.
                    if (removeEmptyInstances && !instance.storeGroups.length) {
                        resource.instances.splice(index, 1);
                    }
                });
            });

            // updating stores and resources
            return [
                {
                    fieldName: resourcesFieldName,
                    value: resources,
                },
                {
                    fieldName: storeGroupsFieldName,
                    value: updatedStoreGroups,
                },
            ];
        },

        removeStoreGroup({ storeGroup, removeEmptyInstances }) {
            // removing storeGroup
            const fieldsToUpdate = this.removeStoreGroupFieldsToUpdate({
                storeGroups: this.localStoreGroups,
                resources: this.localResources,
                storeGroup,
                removeEmptyInstances,
                resourcesFieldName: this.resources.fieldName,
                storeGroupsFieldName: this.storeGroups.fieldName,
            });

            // removing storeGroup from default matrix in sub-campaign
            if ((this.isSubCampaign || this.isScenario) && !this.isDefaultMatrix) {
                fieldsToUpdate.push(
                    ...this.removeStoreGroupFieldsToUpdate({
                        storeGroups: this.getStagingAreaDefaultStoreGroups(),
                        resources: this.getStagingAreaDefaultResources(),
                        storeGroup,
                        removeEmptyInstances,
                        resourcesFieldName: this.defaultResources.fieldName,
                        storeGroupsFieldName: this.defaultStoreGroups.fieldName,
                    })
                );
            }

            // applying changes
            this.updateStagingArea(fieldsToUpdate);
        },

        toggleInstance({ resource, instance, storeGroups, value }) {
            const resourceToUpdate = find(this.localResources, r => r.type === resource.type);
            const index = findIndex(resourceToUpdate.instances, {
                key: instance.key,
            });
            if (!value) {
                return this.deleteInstance({
                    resource: resourceToUpdate,
                    index,
                });
            }

            if (index !== -1) {
                // update existing instance with storeGroups
                // if createMode=false (for scenario, promotion/parkingLot) instance can exist just in case of
                // empty storeGroups by default
                return this.setInstanceStoreGroups({ resource, instance, storeGroups });
            }

            // if no instance to update, create a new one
            const newResourceInstance = {
                resource,
                key: instance.key,
                storeGroups: filter(storeGroups, storeGroup => {
                    return includes(instance.options, storeGroup.key);
                }),
                subType: instance.subType,
            };

            if (this.showDetailedProvisions) {
                newResourceInstance.detailedProvision = [];
            }

            this.addResourceInstance(newResourceInstance);
        },

        updateBasedOnPaths() {
            // if we are toggling a full resource
            if (!this.instanceObjectToUpdate) {
                this.removeResource();
                this.resourceToToggle = null;
                // if we are toggling an instance of a resource
            } else {
                this.toggleInstance(this.instanceObjectToUpdate);
                this.instanceObjectToUpdate = null;
            }
            this.instancePaths = [];
            this.diaglogOpen = false;
        },

        setResources(resources) {
            this.updateStagingArea([
                {
                    fieldName: this.resources.fieldName,
                    value: resources,
                },
            ]);
        },
        setStoreGroups(storeGroups) {
            this.updateStagingArea([
                {
                    fieldName: this.storeGroups.fieldName,
                    value: storeGroups,
                },
            ]);
        },
        updateStagingArea(fieldsToUpdate) {
            // Validation should be run before updating the staging area.
            // NextTick is required as the validation is run against the form, which is
            // not updated until the next DOM refresh.
            this.$nextTick(() => {
                this.$refs.nominationForm.validate();
                this.setStagingAreaFields({
                    namespace: this.namespace,
                    fieldsToUpdate,
                });
                if (this.tab) {
                    this.setUnsavedPromotion({
                        namespace: this.namespace,
                        tab: this.tab,
                        value: true,
                    });
                }
                this.resetLocalData();
            });
        },
        addStoreGroup(storeGroup) {
            const storeGroups = this.localStoreGroups;
            if (this.showExcludedStores) {
                storeGroups.push({ excludedStores: [], ...storeGroup });
            } else {
                storeGroups.push(storeGroup);
            }
            this.setStoreGroups(storeGroups);
        },
        addResourceInstance({ resource, key, storeGroups, detailedProvision, subType }) {
            const resources = this.localResources;
            const resourceToUpdate = find(resources, r => r.type === resource.type);

            // If store groups are not supplied, default to selecting all store groups.
            const newResourceInstance = {
                key: key || uuid(),
                storeGroups: storeGroups || [...this.localStoreGroups],
                subType,
            };

            if (detailedProvision) {
                newResourceInstance.detailedProvision = detailedProvision;
            }

            resourceToUpdate.instances.push(newResourceInstance);

            this.$set(this.instanceCreationInProgress, resource.type, false);
            this.$set(this.newInstanceSubType, resource.type, false);

            this.setResources(resources);
        },
        setInstanceStoreGroups({ resource, instance, storeGroups, callSetResources = true }) {
            const resources = this.localResources;
            const resourceToUpdate = find(resources, {
                type: resource.type,
            });
            let instanceToUpdate = find(resourceToUpdate.instances, { key: instance.key });

            // if no instance to update, create it
            if (!instanceToUpdate) {
                instanceToUpdate = { key: instance.key };
                resourceToUpdate.instances.push(instanceToUpdate);
            }

            const fieldsToUpdate = [];

            // if storeGroups were deleted - ensure they are deleted from defaults
            if (this.isSubCampaign && !this.isDefaultMatrix) {
                const storeGroupsToRemove = differenceBy(
                    instanceToUpdate.storeGroups,
                    storeGroups,
                    'key'
                );
                if (!isEmpty(storeGroupsToRemove)) {
                    const defaultResources = this.getStagingAreaDefaultResources();
                    const defaultResourceToUpdate = find(defaultResources, {
                        type: resource.type,
                    });
                    if (defaultResourceToUpdate) {
                        const defaultInstanceToUpdate = find(defaultResourceToUpdate.instances, {
                            key: instance.key,
                        });
                        if (defaultInstanceToUpdate && defaultInstanceToUpdate.storeGroups) {
                            // make sure removed storeGroups are not included in instance
                            const defaultInstanceStoregroups = defaultInstanceToUpdate.storeGroups.filter(
                                a => storeGroupsToRemove.some(b => a.key !== b.key)
                            );
                            this.$set(
                                defaultInstanceToUpdate,
                                'storeGroups',
                                defaultInstanceStoregroups
                            );
                            fieldsToUpdate.push({
                                fieldName: this.defaultResources.fieldName,
                                value: defaultResources,
                            });
                        }
                    }
                }
            }

            // update the storeGroups of this instance to update
            this.$set(instanceToUpdate, 'storeGroups', storeGroups);
            fieldsToUpdate.push({
                fieldName: this.resources.fieldName,
                value: resources,
            });

            if (callSetResources) {
                this.updateStagingArea(fieldsToUpdate);
            }
        },
        deleteInstance({ resource, index }) {
            const resources = this.localResources;
            const resourceToUpdate = find(resources, r => r.type === resource.type);
            const resourceInstanceKey = resourceToUpdate.instances[index].key;
            resourceToUpdate.instances.splice(index, 1);

            // deleting instance
            const fieldsToUpdate = [
                {
                    fieldName: this.resources.fieldName,
                    value: resources,
                },
            ];

            // deleting instance from default matrix
            if (this.isSubCampaign && !this.isDefaultMatrix) {
                const defaultResources = this.getStagingAreaDefaultResources();
                // ensuring default matrix has default resources
                if (defaultResources.length !== 0) {
                    const defaultResourceToUpdate = find(
                        defaultResources,
                        r => r.type === resource.type
                    );
                    // ensuring default matrix has this resource
                    if (
                        !isEmpty(defaultResourceToUpdate) ||
                        !isEmpty(defaultResourceToUpdate.instances)
                    ) {
                        const indexInDefaultResources = defaultResourceToUpdate.instances.findIndex(
                            instance => instance.key === resourceInstanceKey
                        );
                        // ensuring default matrix resource has this instance
                        if (indexInDefaultResources !== -1) {
                            defaultResourceToUpdate.instances.splice(indexInDefaultResources, 1);

                            fieldsToUpdate.push({
                                fieldName: this.defaultResources.fieldName,
                                value: defaultResources,
                            });
                        }
                    }
                }
            }

            this.updateStagingArea(fieldsToUpdate);
        },
        removeResource() {
            const resources = this.localResources;
            // removing a resource
            const fieldsToUpdate = [
                {
                    fieldName: this.resources.fieldName,
                    value: resources.filter(r => r.type !== this.resourceToToggle.type),
                },
            ];

            // removing a resource from default matrix
            if (this.isSubCampaign && !this.isDefaultMatrix) {
                const defaultResources = this.getStagingAreaDefaultResources();
                fieldsToUpdate.push({
                    fieldName: this.defaultResources.fieldName,
                    value: defaultResources.filter(r => r.type !== this.resourceToToggle.type),
                });
            }

            this.updateStagingArea(fieldsToUpdate);
            this.instanceCreationInProgress[this.resourceToToggle.type] = false;

            if (this.onResourceChange) {
                this.onResourceChange();
            }
        },
        toggleResource({ resource, value }) {
            const resources = this.localResources;

            if (value) {
                // get resource from options with the same type
                const resourceOption =
                    find(this.resourceOptions, option => option.key === resource.type) || {};
                const resourceOptionInstances = cloneDeep(resourceOption.instances);

                // by default get instances from resource option or if they are not provided
                // create new array using storeGroups from stagingArea
                let instances = [];

                if (this.createMode && resource.subTypes && resource.subTypes.length) {
                    // Do not add a new instance, instead prompt the user to select a sub-type.
                    this.$set(this.instanceCreationInProgress, resource.type, true);
                } else if (
                    !this.createMode &&
                    resourceOptionInstances &&
                    resourceOptionInstances.length
                ) {
                    resourceOptionInstances.forEach(instance => {
                        // Check the overlap between store groups for the instance being added
                        // and the selected store groups.
                        const storeGroups = intersectionBy(
                            instance.storeGroups,
                            this.localStoreGroups,
                            'key'
                        );

                        // Add the instance if any of the instance store groups are selected.
                        if (storeGroups.length) {
                            instances.push({
                                ...instance,
                                storeGroups,
                            });
                        }
                    });
                } else {
                    // Add default instance
                    instances = [{ key: uuid(), storeGroups: [...this.localStoreGroups] }];
                }

                // Add empty detailed provision array to each instance.
                if (this.showDetailedProvisions) {
                    instances.forEach(instance => {
                        instance.detailedProvision = [];
                    });
                }

                // add resource
                resources.push({
                    ...resource,
                    enabled: value,
                    instances,
                });
                this.setResources(resources);
                if (this.onResourceChange) {
                    this.onResourceChange();
                }
            } else {
                // remove resource
                // removing a resource so make sure this promotion is removed from
                // any leaflets etc at the subcampaign level
                const {
                    pathsToUpdate,
                    resourceDefinitions: updatedResourceDefinitions,
                } = removePromotionsFromInstances(
                    cloneDeep(this.selectedSubCampaign),
                    this.selectedPromotionId,
                    resource.type
                );
                this.instancePaths = pathsToUpdate;
                this.resourceToToggle = resource;
                if (!isEmpty(pathsToUpdate)) {
                    // if this will effect the subcampaign, show the modal and the warning
                    this.setUpdatedResourceDefinitions(updatedResourceDefinitions);
                    this.$refs.instanceUpdateDialog.open();
                    this.dialogOpen = true;
                } else {
                    this.removeResource();
                }
            }
        },

        cancelCreateInstance({ resource }) {
            this.$set(this.instanceCreationInProgress, resource.type, false);
            this.$set(this.newInstanceSubType, resource.type, false);

            if (!resource.instances.length) this.toggleResource({ resource, value: false });
        },

        generateInstance() {
            return { storeGroups: [] };
        },
        generateHeaderWidth(resource) {
            let numberOfColumns = 1;

            if (resource.enabled) {
                numberOfColumns = resource.instances.length + this.createMode;
            }

            return `grid-column-end: span ${numberOfColumns}`;
        },
        storeGroupsSetter(selectedStoreGroupIds) {
            // Map store group id's to the full store group options.
            const storeGroupReferenceOptions = map(this.storeGroupOptions, 'reference');
            const storeGroups = intersectionWith(
                storeGroupReferenceOptions,
                selectedStoreGroupIds,
                (option, selectedStoreGroupId) => option._id === selectedStoreGroupId
            );

            const storeGroupsToAdd = differenceBy(storeGroups, this.model.storeGroups, 'key');
            const storeGroupsToRemove = differenceBy(this.model.storeGroups, storeGroups, 'key');

            if (size(storeGroupsToAdd)) {
                storeGroupsToAdd.forEach(storeGroup => {
                    // Store groups have been added. If not in create mode, ensure
                    // it is enabled in any selected instances where appropriate.
                    this.addStoreGroup(storeGroup);

                    if (!this.createMode) {
                        this.model.resources.forEach(resource => {
                            resource.instances.forEach(instance => {
                                // Update the store groups for any instances which already have
                                // selected values to include the selected options.
                                if (
                                    size(instance.storeGroups) &&
                                    instance.options.includes(storeGroup.key)
                                ) {
                                    this.setInstanceStoreGroups({
                                        resource,
                                        instance,
                                        storeGroups: storeGroups.filter(sg =>
                                            instance.options.includes(sg.key)
                                        ),
                                        callSetResources: false,
                                    });
                                }
                            });
                        });
                        // call setResources and update state only once when
                        // we finish updating resources and instances
                        this.setResources(this.localResources);
                    }
                });
            } else {
                storeGroupsToRemove.forEach(storeGroup =>
                    // Store groups have been removed. Ensure they are removed from any
                    // instances containing it.
                    this.removeStoreGroup({
                        storeGroup,
                        removeEmptyInstances: !this.createMode,
                    })
                );
            }
        },
        instanceGetter(instance) {
            return instance.storeGroups;
        },
        instanceSetter(storeGroups, instance, resource) {
            if (this.createMode) {
                this.setInstanceStoreGroups({
                    resource,
                    instance,
                    storeGroups,
                });
            } else {
                const hasStoreGroupBeenRemoved = instance.storeGroups.length < storeGroups.length;
                // toggling off
                if (!hasStoreGroupBeenRemoved) {
                    // if we are toggling off an instance, make sure we capture any updates
                    // required for the subcampaign
                    const instanceIndex = resource.instances.findIndex(i => i.key === instance.key);
                    const {
                        pathsToUpdate,
                        resourceDefinitions: updatedResourceDefinitions,
                    } = removePromotionsFromInstances(
                        cloneDeep(this.selectedSubCampaign),
                        this.selectedPromotionId,
                        resource.type,
                        get(instance, 'subType.key'),
                        instanceIndex
                    );
                    this.instancePaths = pathsToUpdate;
                    if (!isEmpty(pathsToUpdate)) {
                        // open the dialog and show the warning if this will effect the
                        // the subcampaign
                        this.setUpdatedResourceDefinitions(updatedResourceDefinitions);
                        this.$refs.instanceUpdateDialog.open();
                        this.dialogOpen = true;
                        this.instanceObjectToUpdate = {
                            resource,
                            instance,
                            storeGroups,
                            value: hasStoreGroupBeenRemoved,
                        };
                    } else {
                        this.toggleInstance({
                            resource,
                            instance,
                            storeGroups: this.getStoreGroupValue,
                            value: hasStoreGroupBeenRemoved,
                        });
                    }
                } else {
                    this.toggleInstance({
                        resource,
                        instance,
                        storeGroups: this.getStoreGroupValue,
                        value: hasStoreGroupBeenRemoved,
                    });
                }
            }
        },
        isInstanceStoreGroupDisabled(instance, storeGroup) {
            // Disable the store group check box within the instance if either:
            //  * The instance is disabled entirely - occurs if its being used at a lower level
            //  * The store group has not been selected.
            //  * The user doesn't have permission to edit resource
            return (
                (instance.disabled && instance.disabledStoreGroups.includes(storeGroup)) ||
                !some(this.model.storeGroups, { key: storeGroup }) ||
                this.resourcesUneditable
            );
        },
        displayCheckbox(instance, storeGroup) {
            return instance.options.includes(storeGroup);
        },
        isOptionDisabled(option) {
            // Disable option checkbox if either:
            //  * Option has disabled prop === true or it doesn't have a prop but the whole nomination matrix is disabled
            //  * The user doesn't have permission to edit resource
            return (
                (has(option, 'disabled') ? option.disabled : this.disabled) ||
                this.storeGroupsUneditable
            );
        },
        isResourceDisabled(resource) {
            const resourceOption = find(
                this.resourceOptions,
                option => option.key === resource.type
            );
            const toReturn = has(resourceOption, 'disabled')
                ? resourceOption.disabled
                : this.disabled;
            // Disable option checkbox if either:
            //  * Option has disabled prop === true or it doesn't have a prop but the whole nomination matrix is disabled
            //  * The user doen't have permission to edit resource
            return toReturn || this.resourcesUneditable;
        },
        storeGroupsValidations() {
            if (this.isDefaultMatrix) return [];
            return [
                {
                    validator: () =>
                        // Ensure that at least one store group has been selected.
                        !!this.model.storeGroups.length ||
                        toSentenceCase(
                            i18n.t('planning.nominations.validation.storeGroupRequired')
                        ),
                },
            ];
        },
        instanceValidations(resource, instance) {
            return [
                {
                    validator: () =>
                        // When in create mode, ensure that each instance must have at least
                        // one store group selected.
                        !this.createMode ||
                        !!instance.storeGroups.length ||
                        toSentenceCase(
                            i18n.t('planning.nominations.validation.instanceMustHaveStoreGroup')
                        ),
                },
                {
                    validator: () =>
                        // When not in create mode, ensure each enabled resource has at least
                        // one instance selected. A selected instance, will have a non-empty
                        // list of store groups.
                        this.createMode ||
                        !resource.enabled ||
                        !!sumBy(resource.instances, i => i.storeGroups.length) ||
                        toSentenceCase(
                            i18n.t('planning.nominations.validation.resourceMustHaveInstance')
                        ),
                },
            ];
        },
        resourceValidations(resource) {
            return [
                {
                    validator: () =>
                        // When in create mode, ensure each enabled resource has at least
                        // one instance selected.
                        !this.createMode ||
                        !resource.enabled ||
                        !!resource.instances.length ||
                        !this.instanceCreationInProgress[resource.type] ||
                        toSentenceCase(
                            i18n.t('planning.nominations.validation.resourceMustHaveInstance')
                        ),
                },
            ];
        },

        convertOptions(options) {
            return options.map(option => {
                return {
                    value: option,
                };
            });
        },
        saveExcludedStores(excludedStores, key) {
            const storeGroups = this.localStoreGroups;
            const storeGroup = storeGroups.find(storeGroupItem => storeGroupItem.key === key);

            storeGroup.excludedStores = excludedStores;
            this.setStoreGroups(storeGroups);
        },

        getResourceLabel(resourceKey) {
            const resource = this.getPromoResources.find(r => r.key === resourceKey);
            return resource ? resource.description : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$light-border: 1px solid $promo-grey-4;
$dark-border: 1px solid $promo-grey-dark;

.nomination-matrix {
    display: grid;
    grid-auto-flow: column;

    &__label {
        font-size: 1.2rem;
        font-weight: 600;
        align-self: flex-end;
        padding: 0.6rem 0 1.2rem;
        min-width: 10rem;
    }

    .label-stores {
        text-align: right;
        padding-right: 1rem;
    }

    &__header {
        padding-left: 0.25rem;
        grid-column-start: 2;
        width: 25rem;

        @include flex-row;
        align-items: center;
    }
    &__sidebar-wrapper {
        grid-column: 1;
        @include grid-span;
        @include flex-row;
        justify-content: space-between;
    }
    &__stores {
        border-right: $dark-border;
        padding: 0.3rem 0.3rem 0.3rem 0;
    }
    &__sidebar {
        padding: 0.3rem 3rem 0.3rem 0;
        @include flex-center;

        &--header {
            @include flex-row;
            @include grid-span(3, 1);
            justify-content: space-between;
            border-right: $dark-border;
            border-bottom: $light-border;
        }
        &--footer {
            border-top: $light-border;
            border-right: $dark-border;
        }

        &--dp-header {
            padding: 1rem 1rem 1rem 4.5rem;
            border-bottom: $light-border;
            border-top: $light-border;
            border-right: $dark-border;
        }
        &--dp {
            margin-left: 4.5rem;
            padding: 0.5rem 1rem 0.5rem 0;
            border-bottom: $light-border;
            border-right: $dark-border;

            & div {
                padding: 0.5rem 0;
            }
        }

        .v-input--checkbox {
            width: 1rem;
        }
    }

    &__emptyCell {
        @include grid-span;
        min-height: 3rem;
        border-right: $light-border;
        border-bottom: $light-border;

        &--header {
            @extend .nomination-matrix__emptyCell;
            grid-row-start: 3;
        }

        &--red {
            @extend .nomination-matrix__emptyCell;
            background-color: red;
        }

        &--top-bordered {
            @extend .nomination-matrix__emptyCell;
            border-top: $light-border;
        }
    }

    &--loop-wrapper {
        display: contents;
    }

    &__subType {
        @include grid-span;
        grid-row-start: 4;
        grid-row-end: -2;
        border-right: $light-border;
        padding: 1rem;

        &--add-resource {
            background: $promo-grey-3;
        }
    }

    &__add-resource {
        grid-row: 3;

        @include flex-center;
        align-items: center;

        padding: 0.7rem 0;

        border-right: $light-border;
        border-bottom: $light-border;

        background: $promo-grey-3;

        &--cell {
            @include grid-span;
            background: $promo-grey-3;
            border-right: $light-border;
        }

        &--filler {
            @include grid-span;
            grid-row-start: 4;
            grid-row-end: -2;
            background: $promo-grey-3;
            border-right: $light-border;
            padding: 1rem;

            .subtype {
                &__button {
                    background: $promo-grey-3;
                    color: $promo-primary-colour;
                    margin-left: 0.5rem;
                }
            }
        }

        &--footer {
            @include grid-span;
            grid-row-start: -2;
            background: $promo-grey-3;
            border-right: $light-border;
            border-top: $light-border;
        }
    }

    &__add-detailed-provision {
        @include grid-span;
        @include flex-center;
        padding: 0.7rem 0;
        border-right: $light-border;
        border-top: $light-border;
        padding-left: 0.25rem;
    }

    &__delete-resource {
        @include grid-span;
        @include flex-center;
        padding: 0.7rem 0;
        border-right: $light-border;
        border-top: $light-border;
        padding-left: 0.25rem;
    }

    &__resource-header {
        grid-row: 2;
        @include flex-row;
        padding: 0.4rem 0.25rem;
        border-right: $light-border;
        align-items: center;

        &--unselected {
            background-color: $promo-grey-3;
        }
    }

    &__state-header {
        grid-row: 3;
        @include flex-center;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 3rem;
        padding: 0.4rem 0.25rem;
        border-right: $light-border;
        border-bottom: $light-border;
    }

    &__icon {
        @include icon-border-radius;
        align-self: center;
        justify-self: center;
        color: $promo-primary-colour;
        margin-bottom: 0.25rem;
    }

    &__cell {
        @include grid-span;
        font-size: 1rem;
        border-right: $light-border;
        @include flex-center;

        &--highlighted {
            background: $promo-highlight-detailed-provision;
        }

        .v-input--checkbox {
            width: 1rem;
        }
    }

    &__disabledResource {
        grid-column: span 1;
        grid-row-start: 4;
        grid-row-end: -1;
        border-right: $light-border;
        background-color: $promo-grey-3;
        @include flex-row;
    }

    &__detailed-provisions {
        @include grid-span;
        @include flex-center;
        border-right: $light-border;
        border-bottom: $light-border;
        padding-left: 0.25rem;

        .v-input {
            margin: 0;
            padding: 0;
        }
    }
}
</style>
