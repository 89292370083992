<template>
    <main-dialog
        ref="dialog"
        heading="planning.copyCampaign.heading"
        btn-confirm-text="actions.copy"
        :has-activator="hasActivator"
        @confirm="copyCampaignDialogConfirm"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <icon icon-name="duplicate" small v-on="activator" @click.stop />
        </template>
        <template v-slot:default>
            <div class="copy-campaign">
                <p class="copy-campaign__description">
                    {{ $t('planning.copyCampaign.description') | toSentenceCase }}
                </p>
                <p class="copy-campaign__form-item border">
                    <span class="copy-campaign__form-item--label">
                        {{ $tc('entities.campaigns', 1) | toSentenceCase }}
                    </span>
                    <vuex-checkbox
                        disabled
                        :getter="() => modelsGetter({ model: copyCampaignModels.campaignModel })"
                        :setter="
                            value =>
                                modelsSetter({ model: copyCampaignModels.campaignModel, value })
                        "
                    />
                </p>
                <p class="copy-campaign__form-item">
                    <span class="copy-campaign__form-item--label">
                        {{ $t('planning.copyCampaign.startDateField') | toSentenceCase }}
                    </span>
                    <vuex-date-picker
                        class="copy-campaign__form-item--picker"
                        :getter="() => modelsGetter({ model: copyCampaignModels.startDateModel })"
                        :setter="
                            value =>
                                modelsSetter({ model: copyCampaignModels.startDateModel, value })
                        "
                        :min-date="minDate"
                    />
                </p>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import CopyCampaignModels from '@enums/copy-campaign-models';

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        campaignId: {
            type: String,
            required: true,
        },
        startDate: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            models: {
                [CopyCampaignModels.campaignModel]: true,
                [CopyCampaignModels.startDateModel]: null,
            },
            copyCampaignModels: CopyCampaignModels,
        };
    },
    computed: {
        minDate() {
            return this.$options.filters.datepickerFormat(this.startDate);
        },
    },
    mounted() {
        this.models[CopyCampaignModels.startDateModel] = this.minDate;
    },
    methods: {
        ...mapActions('campaigns', ['copyCampaign']),
        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.$refs.dialog.close();
            this.resetModels();
        },
        modelsGetter({ model }) {
            return this.models[model];
        },
        modelsSetter({ model, value }) {
            this.models[model] = value;
        },
        async copyCampaignDialogConfirm() {
            const error = await this.copyCampaign({
                id: this.campaignId,
                body: {
                    startDate: this.models[CopyCampaignModels.startDateModel],
                },
            });

            if (!error) {
                this.closeDialog();
                this.resetModels();
            }
        },
        resetModels() {
            this.modelsSetter({
                model: CopyCampaignModels.startDateModel,
                value: this.minDate,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.copy-campaign {
    font-size: 1.2rem;

    &__description {
        padding: 1rem 0;
    }

    &__form-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem 2rem;

        &--label {
            margin-right: 1rem;
            font-weight: bold;
        }

        &--picker {
            width: 13rem;
        }
    }

    .border {
        border-bottom: 0.1rem solid $promo-divider-colour;
    }
}
</style>
