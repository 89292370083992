<template>
    <v-expansion-panels v-model="isOpenState" :readonly="isExpanded" accordian class="px-1">
        <v-expansion-panel class="product-group">
            <v-expansion-panel-header
                class="pa-1 expansion-header"
                hide-actions
                @click="pgClicked(isOpenState)"
            >
                <template v-slot:default="{ open }">
                    <v-row no-gutters>
                        <v-col cols="1">
                            <v-row no-gutters class="justify-center">
                                <badge :type="badgeType" class="mt-1" />
                            </v-row>
                            <v-row no-gutters class="justify-center mt-1">
                                <main-expand-button :is-expanded="open" class="text-center" />
                            </v-row>
                        </v-col>
                        <v-col cols="10">
                            <v-row no-gutters>
                                <v-chip
                                    v-if="!editable"
                                    small
                                    label
                                    color="transparent"
                                    class="font-weight-bold"
                                >
                                    {{ name }}
                                </v-chip>
                                <vuex-text-field
                                    v-else
                                    v-bind="nameField"
                                    class="pg-name"
                                    white
                                    @change="onChangePromotionProductGroup"
                                    @click.native.stop="openExpansionPanel"
                                    @keypress.enter.native.prevent="blurPGName"
                                />
                            </v-row>
                            <v-row no-gutters>
                                <v-chip
                                    small
                                    label
                                    color="transparent"
                                    class="font-weight-bold indigo--text"
                                >
                                    {{
                                        $tc(
                                            'planning.promotionsMaintenance.products.productItem.count',
                                            isNew ? editableCount : products.length
                                        ) | toSentenceCase
                                    }}
                                </v-chip>
                            </v-row>
                        </v-col>
                        <v-col v-if="isDraggable" cols="1">
                            <icon icon-name="cross-move" small class="product-group__move_icon" />
                        </v-col>
                    </v-row>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <product-group-draggable-area
                    v-if="editable"
                    v-bind="productsField"
                    :product-lozenge-layout="productLozengeLayout"
                    @draggableChange="onDraggableChange"
                />
                <div v-else>
                    <template v-for="product in pgProducts">
                        <product
                            v-if="!product.hideProduct"
                            :key="`productgroup-${_id}-product-${product.productKey}`"
                            :product="product"
                            :draggable="false"
                            :selectable="selectable"
                            :layout="productLozengeLayout"
                            :disabled="disabled"
                            :selected="isProductSelected(product)"
                            class="mx-2 product-group-product"
                            @product-selector-update="productSelectorUpdate"
                        />
                    </template>
                </div>
                <div v-if="editable" class="product-group__delete-container">
                    <common-delete-dialog
                        :resource="entityType"
                        :child-dependencies-warning="false"
                        @delete="$emit('delete')"
                    />
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { findIndex, camelCase, has } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { productGroup } from '@enums/product-badge-type';
import UXEvents from '@enums/ux-events';
import { promotionProductGroups } from '@enums/resources';
import vuexForm from '@/js/mixins/vuex-form';

export default {
    mixins: [vuexForm],
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        _id: String,
        name: String,
        products: {
            type: Array,
            default: () => [],
        },
        productLozengeLayout: {
            required: true,
            type: Object,
        },
        isOpen: {
            type: Boolean,
            default: () => false,
        },
        draggable: {
            required: false,
            default: true,
            type: Boolean,
        },
        selectable: {
            required: false,
            default: false,
            type: Boolean,
        },
        disabled: {
            required: false,
            default: true,
            type: Boolean,
        },
        selectedProducts: {
            type: Array,
            required: false,
            default: () => [],
        },
        editable: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNew: {
            type: Boolean,
            required: false,
            default: false,
        },
        promotionNamespace: String,
    },
    data() {
        return {
            badgeType: productGroup,
            editableCount: 0,
            nameField: {
                namespace: this.namespace,
                vuexModule: this.vuexModule,
                ...this.fields[0],
            },
            productsField: {
                namespace: this.namespace,
                vuexModule: this.vuexModule,
                ...this.fields[1],
            },
            isOpenState: this.isOpen ? 0 : undefined,
            entityType: camelCase(promotionProductGroups),
        };
    },
    events: {
        [UXEvents.openProductGroup]({ productGroup: pg }) {
            if (this._id === pg._id) {
                this.isOpenState = 0;
            }
        },
    },
    computed: {
        ...mapState('clientConfig', ['hierarchyConfig', 'productDraggableArea']),
        ...mapGetters('context', ['userCategoryKeys']),
        pgProducts() {
            return this.products.map(product => {
                const pgProduct = {
                    productKey: product.productKey,
                    clientProductKey: product.clientProductKey,
                    name: product.name,
                    brandDescription: product.brandDescription,
                    packSize: product.packSize,
                    packUnit: product.packUnit,
                    supplierName: product.supplierName,
                    nonPromoPrices: product.nonPromoPrices,
                    isProposed: product.isProposed,
                    attributes: product.attributes,
                };
                // attaching prop 'hideProduct' to each product if we hide products
                if (!this.productDraggableArea.hideProductsWithCategoriesUserHasNoAccessTo) {
                    return pgProduct;
                }
                const { levelEntryKey: categoryKey } = product.hierarchy.find(
                    h => h.level === this.hierarchyConfig.categoryLevel
                );
                // Products are hidden if user has no access to product's category and flag is set
                const accessToCategory = this.userCategoryKeys.some(key => categoryKey === key);
                pgProduct.hideProduct = !accessToCategory;
                return pgProduct;
            });
        },
        isExpanded() {
            return this.isNew;
        },
        isDraggable() {
            return this.draggable && !this.isReadOnly;
        },
    },
    methods: {
        ...mapActions('promotionProductGroups', ['setStagingAreaField']),
        ...mapMutations('promotions', ['setPpgName']),
        ...mapMutations('promotionCandidates', ['updatePromotionCandidate']),
        ...mapActions('subCampaigns', ['setUpdatedResourceDefinitions']),
        openExpansionPanel() {
            // When we click on a text field and the PG is currently closed, we will open it
            if (!this.isOpen) {
                // Vuetify expansion panels uses index to know which panel is open (even though we only have one panel)
                this.isOpenState = 0;
                this.$emit('opened');
            }
        },
        pgClicked() {
            // Vuetify expansion panels uses index to know which panel is open (even though we only have one panel)
            if (this.isOpenState === 0) {
                this.$emit('closed');
            } else {
                this.$emit('opened');
            }
        },
        productSelectorUpdate(payload) {
            this.$emit('product-selector-update', payload);
        },
        isProductSelected({ productKey }) {
            return findIndex(this.selectedProducts, key => key === productKey) !== -1;
        },
        onDraggableChange(event) {
            if (has(event, 'event.added')) {
                // if we add to a PG block the other component marking the item
                // as having left the selected candidates which could effect the
                // the subcampaign leaflet assignment
                this.setUpdatedResourceDefinitions(null);
            }
            this.editableCount = event.model.length;
            this.$emit('draggable-change', event);
            if (this.editableCount) {
                this.onChangePromotionProductGroup();
            }
        },
        async changePpgName({ id, name }) {
            await this.setPpgName({ namespace: this.promotionNamespace, id, name });
            await this.updatePromotionCandidate({
                id,
                updates: { name },
            });
        },
        // We don't have save button, saving is after drop or blur events
        // Now we save ppg each time on this events
        onChangePromotionProductGroup(name) {
            const productGroupInStagingArea = this.$store.state[this.vuexModule].stagingArea[
                this.namespace
            ];
            const productGroupName = name || productGroupInStagingArea.name;
            const productGroupProducts = productGroupInStagingArea.products;
            if (name) {
                this.setStagingAreaField({
                    namespace: this.namespace,
                    fieldName: 'name',
                    value: name,
                });
            }
            // The product group should only be saved when we have a name, and at least 1 product.
            if (
                productGroupName &&
                productGroupName.trim() &&
                productGroupProducts &&
                productGroupProducts.length
            ) {
                this.savePromotionProductGroup(productGroupName);
            }
        },
        async savePromotionProductGroup(name) {
            const { result: createdPG } = await this.submitForm({
                vuexModule: this.vuexModule,
                editMode: !this.isNew,
                namespace: this.namespace,
                postSubmitEvent: this.isNew
                    ? 'save-new-promotion-product-group'
                    : 'update-promotion-product-group',
            });
            // When there has been an error or server-side validation failure, the createdPG will be undefined.
            if (createdPG) {
                if (this.isNew) {
                    // Reset the staging area for the temporary pg that was created
                    this.$set(this.$store.state[this.vuexModule].stagingArea, this.namespace, null);
                }
                this.changePpgName({ name, id: createdPG._id });
                this.$emit('pg-created', createdPG);
            }
        },
        // product group name input looses focus on enter press
        blurPGName(e) {
            e.target.blur();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';
.product-group {
    border: $promo-primary-colour solid 1px;
    &__delete-container {
        position: absolute;
        bottom: 1rem;
        right: 0.5rem;
    }
}
.expansion-header {
    min-height: $product-group-height;
    max-height: $product-group-height;
}
</style>

<style lang="scss">
.product-group {
    &__move_icon {
        height: 1.8rem !important;
        width: 100%;
        margin-top: 1.1rem;
    }
    .v-text-field.rtls-text-field .v-input__slot {
        height: 20px;
        padding-bottom: 5px;
    }
    .v-input.v-text-field.rtls-text-field .v-text-field__slot input {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .v-text-field.rtls-text-field .v-text-field__slot {
        height: 20px;
    }
    .v-chip.v-size--small {
        height: 20px;
    }
}
</style>
