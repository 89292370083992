var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { class: { "text-container": true, disabled: _vm.isDisabled } },
        [
          _c("div", { staticClass: "ag-text-input" }, [
            _c(
              "div",
              { staticClass: "input-container" },
              [
                _c("input-text-field", {
                  class: { "input--bold": _vm.isBold },
                  attrs: {
                    disabled: _vm.isDisabled,
                    getter: () => _vm.value,
                    setter: _vm.setValue,
                    validations: _vm.params.colDef.validationRules || [],
                    "field-type": _vm.params.fieldType,
                    format: _vm.params.formatter,
                    placeholder: _vm.placeholderValue,
                    prefix: _vm.params.prefix,
                    suffix: _vm.params.suffix,
                    size: _vm.params.size,
                    "positive-only": "",
                    filled: "",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm.placeholderClass
    ? _c("div", { class: _vm.placeholderClass })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }