'use strict';

/**
 * Enum definition for workflow states.
 * These values map directly to the fields set on the workflowState object on Mongo entities.
 *
 * @module data/enums/workflow-states
 */

module.exports = {
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    rejected: 'REJECTED',
    draftSubmitted: 'DRAFT_SUBMITTED',
    requestUnlock: 'REQUEST_UNLOCK',
    // This state is used to indicate the entity has been approved at some point.
    // It may have since been unlocked, resulting the approved state being false.
    hasBeenApproved: 'HAS_BEEN_APPROVED',
};
