var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Channels / Cover")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icon-/-Channels-/--Cover",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Colour",
              d: "M5.4,1.2099502 C5.491656,1.1791756 5.631804,1.1651068 5.76,1.2099502 L5.76,1.2099502 L10.62,2.9480752 L15.48,1.2099502 C15.805116,1.0950526 16.2018,1.37701 16.2000061,1.7218252 L16.2000061,1.7218252 L16.2000061,12.8818216 C16.198812,13.1024296 16.04799,13.3145236 15.84,13.3880716 L15.84,13.3880716 L10.8,15.1880716 C10.684656,15.2288416 10.555344,15.2288416 10.44,15.1880716 L10.44,15.1880716 L5.58,13.4499556 L0.720000018,15.1880716 C0.396648018,15.3023536 0.001800018,15.0247576 -6.11269635e-06,14.6818216 L-6.11269635e-06,14.6818216 L-6.11269635e-06,3.5218252 C-0.001115982,3.299338 0.150246018,3.0841264 0.360000018,3.0099502 L0.360000018,3.0099502 Z M6.12,2.4812002 L6.12,12.4993216 L10.08,13.9168216 L10.08,3.8987002 L6.12,2.4812002 Z M15.12,2.4868162 L11.16,3.8986912 L11.16,13.9168216 L15.12,12.4993216 L15.12,2.4868162 Z",
              fill: "#2F477C",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }