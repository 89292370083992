<template>
    <div class="categories-select" :class="cssClass">
        <div v-if="isSelectAllAvailable" class="select-all">
            <vuex-checkbox
                :getter="() => allUnits"
                :setter="allUnitsSetter"
                class="rtls-checkbox"
                :label="$t('hierarchy.units') | toSentenceCase"
                :disabled="isAllUnitsDisabled"
                @click="onCLick"
            />

            <vuex-checkbox
                :getter="() => allCategories"
                :setter="allCategoriesSetter"
                class="rtls-checkbox"
                :label="$t('hierarchy.categories') | toSentenceCase"
                :disabled="isAllCategoriesDisabled"
                @click="onCLick"
            />
        </div>
        <Finder
            ref="treeRef"
            :key="forceRerender"
            :tree="tree"
            :selectable="true"
            auto-select-descendants
            auto-deselect-descendants
            :default-expanded="defaultExpanded"
            :class="{
                required: isInvalid,
                'hide-unit-checkboxes': hideUnitCheckboxes,
                'disabled-reason': !!disabledReason,
            }"
            @select="onSelect"
            @expand="onExpand"
        />
        <div v-if="isInvalid" class="red--text">
            {{ $t(validationMessage) | toSentenceCase }}
        </div>
        <div v-if="disabledReason && isShowDisabledReason" class="disabled-reason-container">
            {{ disabledReason | toSentenceCase }}
        </div>
    </div>
</template>

<script>
import { Finder } from '@jledentu/vue-finder';
import { last, forEach, includes, every, difference, get, find, map, some } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    components: {
        Finder,
    },

    props: {
        tree: {
            type: Object,
            required: true,
        },
        cssClass: {
            type: String,
            default: () => '',
        },
        isRequired: {
            type: Boolean,
            default: () => false,
        },
        validationAfterChanges: {
            type: Boolean,
            default: () => false,
        },
        update: {
            type: Function,
            default: () => {},
        },
        isSelectAllAvailable: {
            type: Boolean,
            default: true,
        },
        hideUnitCheckboxes: {
            type: Boolean,
            default: false,
        },
        disabledReason: {
            type: String,
        },
        isUserCategoriesRequired: {
            type: Boolean,
            default: false,
        },
        validationMessage: {
            type: String,
            default: 'validation.common.required',
        },
    },

    data() {
        return {
            forceRerender: 1,
            defaultExpanded: this.tree.children[0] ? this.tree.children[0].id : 'root',
            allUnits: false,
            allCategories: false,
            expandedUnit: this.tree.children[0] ? this.tree.children[0].id : 'root',
            isShowDisabledReason: false,
            isTouched: false,
        };
    },

    computed: {
        ...mapGetters('context', ['userCategoryKeys']),
        isAllUnitsDisabled() {
            return some(this.tree.children, { selectable: false });
        },

        isAllCategoriesDisabled() {
            // If at least one category from the list is disabled then we should disable all categories checkbox
            return some(get(find(this.tree.children, { id: this.expandedUnit }), 'children'), {
                selectable: false,
            });
        },
        isInvalid() {
            const validation = this.isRequired && this.isValidationMessageVisible();
            return this.validationAfterChanges ? this.isTouched && validation : validation;
        },
    },

    watch: {
        tree() {
            this.rerenderComponent();
        },
    },

    mounted() {
        this.updateAllCheckBoxes();
        this.updateCategories();
        this.rerenderComponent();
    },

    methods: {
        onCLick() {
            this.isTouched = true;
        },
        onSelect({ selected }) {
            this.isTouched = true;
            forEach(this.tree.children, unit => {
                unit.selected = every(unit.children, category => includes(selected, category.id));
                // Lib doesn't provide possibility for partial select checkbox that is why we addd a class in case if at least 1 category is selected from the unit
                unit.cssClass =
                    !unit.selected &&
                    some(unit.children, category => includes(selected, category.id))
                        ? 'partially-selected'
                        : '';

                forEach(unit.children, category => {
                    category.selected = includes(selected, category.id);
                });
            });

            this.updateCategories();
            this.rerenderComponent();
            this.$emit('change');
        },

        isValidationMessageVisible() {
            const selectedCategories = [];

            forEach(this.tree.children, unit => {
                forEach(unit.children, category => {
                    if (category.selected) selectedCategories.push(category.id);
                });
            });

            if (this.isUserCategoriesRequired) {
                return !this.userCategoryKeys.some(categoryKey =>
                    selectedCategories.includes(String(categoryKey))
                );
            }

            return selectedCategories.length === 0;
        },

        updateCategories() {
            const selectedCategories = this.getSelectedCategories();
            this.update(selectedCategories);
        },

        onExpand(expandedItems) {
            if (this.disabledReason) {
                this.setDisableReasonVisibility(expandedItems);
            }
            this.expandedUnit = this.$refs.treeRef
                ? this.$refs.treeRef.treeModel.expanded[1]
                : 'root';
            this.updateAllCheckBoxes();
        },
        setDisableReasonVisibility({ expanded }) {
            // Click was done on unit
            if (expanded.length !== 3) {
                this.isShowDisabledReason = false;
                return;
            }

            const unitId = expanded[1];
            const categoryId = expanded[2];

            const unit = this.tree.children.find(unitChild => unitChild.id === unitId);
            const category = unit.children.find(categoryChild => categoryChild.id === categoryId);

            this.isShowDisabledReason = !category.selectable;
        },
        allUnitsSetter(value) {
            this.allUnits = value;
            this.allCategories = value;

            // If user clicks "Select all units" we should select all units and categories
            forEach(this.tree.children, unit => {
                unit.selected = value;
                unit.cssClass = '';

                forEach(unit.children, category => {
                    category.selected = value;
                });
            });

            this.updateCategories();
            this.rerenderComponent();
            this.$emit('change');
        },

        allCategoriesSetter(value) {
            this.allCategories = value;

            // If user clicks "Select all categories", we should select all categories and set unit as selected
            if (this.$refs.treeRef) {
                forEach(this.tree.children, unit => {
                    if (unit.id === this.expandedUnit) {
                        unit.selected = value;

                        forEach(unit.children, category => {
                            category.selected = value;
                        });
                    }
                });

                this.updateCategories();
                this.rerenderComponent();
                this.$emit('change');
            }
        },

        updateAllCheckBoxes() {
            if (this.$refs.treeRef) {
                const unitCategories = map(
                    get(find(this.tree.children, { id: this.expandedUnit }), 'children'),
                    category => category.id
                );
                const selectedCategories = this.getSelectedCategories();
                this.allUnits = every(this.tree.children, { selected: true });
                this.allCategories = difference(unitCategories, selectedCategories).length === 0;
            }
        },

        getSelectedCategories() {
            const selectedCategories = [];

            forEach(this.tree.children, unit => {
                let isUnitEmpty = true;
                forEach(unit.children, category => {
                    if (category.selected) {
                        selectedCategories.push(category.id);
                        isUnitEmpty = false;
                    }
                });
                if (isUnitEmpty) {
                    unit.selected = false;
                }
            });

            return selectedCategories;
        },

        /**
         * We pass prop tree to Finder component
         * Inside lib it's create a new object treeModel and then it works with that treeModel
         * Lib doesn't handle changes in tree property
         * As the result changing in tree will not affect component because it's already working with their own object treeModel
         * For fixing that bug we should rerender component after each change in tree property
         */
        rerenderComponent() {
            this.defaultExpanded = last(this.$refs.treeRef.treeModel.expanded);
            this.forceRerender += 1;
            this.updateAllCheckBoxes();
        },
    },
};
</script>

<style src="@jledentu/vue-finder/dist/vue-finder.css" />

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep.categories-select {
    font-size: 1.2rem;

    .select-all {
        display: flex;
        margin: 0 0 0.5rem 1rem;

        .rtls-checkbox {
            min-width: 20rem;
        }
    }

    .tree-container {
        max-height: 25rem;
        &.required .list-container .item {
            input[type='checkbox']:before {
                border-color: $vuetify-error-red;
            }

            .inner-item {
                color: $vuetify-error-red;
            }
        }

        .list-container {
            .list {
                border: 0.1rem solid $promo-grey;
                min-width: 20rem;
            }

            .list-container .list {
                border-left: none;
            }

            .item {
                flex-direction: row-reverse;
                padding: 0 0.5rem;

                .arrow {
                    display: none;
                }

                &.expanded {
                    background-color: $promo-campaign-color-5;
                    color: inherit;
                }

                .inner-item {
                    padding: 0;
                }

                &.partially-selected input[type='checkbox'] {
                    &:before {
                        background-color: $rtls-primary-colour;
                    }

                    &:after {
                        content: '';
                        width: 1rem;
                        height: 0.9rem;
                        position: absolute;
                        right: 0.8rem;
                        border-bottom: 0.2rem solid white;
                    }
                }

                &.partially-selected input[type='checkbox'][disabled] {
                    &:before {
                        background-color: inherit;
                    }

                    &:after {
                        border-color: #bdbdbd;
                    }
                }

                input[type='checkbox'] {
                    width: 1.6rem;
                    height: 1.6rem;

                    &:before {
                        content: '';
                        width: 1.6rem;
                        height: 1.6rem;
                        border: 0.2rem solid $rtls-primary-colour;
                        display: inline-block;
                        border-radius: 0.2rem;
                    }

                    &:checked {
                        &:before {
                            background-color: $rtls-primary-colour;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            right: 1rem;
                            transform: rotate(45deg);
                            height: 1.2rem;
                            width: 0.6rem;
                            border-bottom: 0.2rem solid white;
                            border-right: 0.2rem solid white;
                        }
                    }
                }

                input[type='checkbox'][disabled] {
                    &:before {
                        border-color: #bdbdbd;
                    }

                    &:checked:before {
                        background-color: #bdbdbd;
                    }
                }
            }
        }
    }

    .hide-unit-checkboxes > .list-container > .list {
        input[type='checkbox'] {
            display: none;
        }
    }

    .disabled-reason {
        .list-container {
            .list-container {
                .item {
                    &.expanded {
                        input[type='checkbox'][disabled] {
                            &:before {
                                border-color: $vuetify-error-red;
                            }

                            &:checked:before {
                                background-color: $vuetify-error-red;
                            }
                        }
                    }
                }
            }
        }
    }

    .disabled-reason-container {
        width: 40rem;
        color: $vuetify-error-red;
        margin: 0.5rem 0;
    }
}
</style>
