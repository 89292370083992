var render = function render(_c, _vm) {
  return _c("div", [
    _vm.props.isFavourite
      ? _c(
          "div",
          [
            _c("icon", {
              attrs: {
                "icon-name": "filled-star",
                small: "",
                disabled: _vm.parent.disabled || _vm.parent.isReadOnly,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("icon", {
              attrs: {
                "icon-name": "empty-star",
                small: "",
                disabled: _vm.parent.disabled || _vm.parent.isReadOnly,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }