<template>
    <div class="error-page py-4 text-center">
        <h1 class="title">
            {{ $tkey(`${status}.title`) }}
        </h1>
        <h2 class="my-5">
            <span class="error-code">
                {{ status }}
            </span>
        </h2>
        <p class="description">{{ $tkey(`${status}.description`) }}</p>
    </div>
</template>

<script>
const localizationKey = 'errorPage';

export default {
    localizationKey,

    data() {
        return {
            status: this.$route.params.status,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.error-page {
    margin-top: 10rem;
    .title {
        font-size: 3rem !important;
    }
    .error-code {
        vertical-align: middle;
        font-size: 4rem;
        width: 20rem;
        height: 20rem;
        line-height: 20rem;
        display: inline-block;
        border: 0.5rem solid $rtls-border-colour;
        border-radius: 50%;
    }
    .description {
        font-size: 2rem;
    }
}
</style>
