import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "copy-btn",
      class: [_vm.createNewBtnClassObject, _vm.verticalAlignmentClass],
      attrs: { disabled: _vm.isDisabled },
      on: {
        click: function ($event) {
          return _vm.onClick($event)
        },
      },
    },
    [
      _c(VIcon, { attrs: { color: "primary", small: "" } }, [
        _vm._v("mdi-content-copy"),
      ]),
      _vm._v(" "),
      _vm.btnText
        ? _c("span", { staticClass: "copy-btn__text font-weight-bold" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.btnText))) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }