'use strict';

/**
 * Enum definition file for offer mechanic requirement types.
 * Used in a toggle where quantity is # and money is €
 *
 * @module data/enums/requirement-types
 */
module.exports = {
    quantity: 'QUANTITY',
    money: 'MONEY',
    loyaltyPoint: 'LOYALTY_POINT',
    onNthItem: 'ON_NTH_ITEM',
    redeemLoyaltyPoints: 'REDEEM_LOYALTY_POINTS',
    // TODO: These have replaced quantity and money but unsure if the values can be
    // removed as they are used in a migration
    spendMoney: 'SPEND_MONEY',
    buyNumber: 'BUY_NUMBER',
};
