var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Cog")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Navigation-/-Header",
                transform: "translate(-1169.000000, -18.000000)",
                fill: "#2F477C",
              },
            },
            [
              _c("g", { attrs: { id: "Group-5" } }, [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Week",
                      transform: "translate(1072.000000, 18.000000)",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "Cog",
                        d: "M111.112565,8.784 C111.145463,8.528 111.170136,8.272 111.170136,8 C111.170136,7.728 111.145463,7.472 111.112565,7.216 L112.847919,5.896 C113.004183,5.776 113.045305,5.56 112.946612,5.384 L111.301727,2.616 C111.203034,2.44 110.980974,2.376 110.800037,2.44 L108.752155,3.24 C108.324484,2.92 107.863917,2.656 107.362227,2.456 L107.049698,0.336 C107.025025,0.144 106.852312,0 106.646702,0 L103.356931,0 C103.151321,0 102.978608,0.144 102.953934,0.336 L102.641406,2.456 C102.139716,2.656 101.679148,2.928 101.251478,3.24 L99.2035964,2.44 C99.0144346,2.368 98.8005995,2.44 98.7019064,2.616 L97.0570213,5.384 C96.9501037,5.56 96.9994503,5.776 97.1557144,5.896 L98.8910682,7.216 C98.8581705,7.472 98.8334972,7.736 98.8334972,8 C98.8334972,8.264 98.8581705,8.528 98.8910682,8.784 L97.1557144,10.104 C96.9994503,10.224 96.9583281,10.44 97.0570213,10.616 L98.7019064,13.384 C98.8005995,13.56 99.022659,13.624 99.2035964,13.56 L101.251478,12.76 C101.679148,13.08 102.139716,13.344 102.641406,13.544 L102.953934,15.664 C102.978608,15.856 103.151321,16 103.356931,16 L106.646702,16 C106.852312,16 107.025025,15.856 107.049698,15.664 L107.362227,13.544 C107.863917,13.344 108.324484,13.072 108.752155,12.76 L110.800037,13.56 C110.989198,13.632 111.203034,13.56 111.301727,13.384 L112.946612,10.616 C113.045305,10.44 113.004183,10.224 112.847919,10.104 L111.112565,8.784 Z M105.001816,10.8 C103.414502,10.8 102.123267,9.544 102.123267,8 C102.123267,6.456 103.414502,5.2 105.001816,5.2 C106.589131,5.2 107.880365,6.456 107.880365,8 C107.880365,9.544 106.589131,10.8 105.001816,10.8 Z",
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }