<template>
    <div class="supplier-commitment-form-wrapper">
        <supplier-commitment-form
            v-if="isNewCommitment || editContext"
            ref="supplierCommitmentForm"
            v-bind="form"
            :is-promotion-supplier-funding-view="isPromotionSupplierFundingView"
            :promotion-namespace="promotionNamespace"
            @toggle="formToggle"
            @supplierNameChange="setFormSupplierKey"
            @matrix-updated="setButtonsContainerWidth"
        />
        <div v-if="displayPromotions" class="supplier-commitment-form-wrapper__view-promotions">
            <supplier-commitment-promotions :supplier-commitment="editContext" />
        </div>
        <div
            v-if="!isReadOnly"
            class="supplier-commitment-form-wrapper__buttons"
            :style="{ width: buttonsContainerWidth }"
        >
            <div>
                <create-new-button
                    v-if="!editContext"
                    :btn-text="$t('supplierCommitments.buttons.newCommitment') | toSentenceCase"
                    :disabled="isCreateButtonDisabled"
                    @createNew="addNewCommitment"
                />
            </div>
            <div class="supplier-commitment-form-wrapper__buttons--save">
                <template v-if="isNewCommitment || editContext">
                    <confirm-dialog
                        v-if="isNewCommitment || editContext"
                        :question-text="$t('dialogs.deleteSupplierCommitment.header')"
                        :action-text="$t('actions.delete')"
                        @confirm="deleteCurrentSupplierCommitment"
                    >
                        <template v-slot:actions="{ activator }">
                            <v-btn text secondary small v-on="activator">
                                {{ $t('actions.delete') | toSentenceCase }}
                            </v-btn>
                        </template>
                    </confirm-dialog>

                    <v-btn primary small :disabled="isSaveDisabled" class="save-btn" @click="save">
                        {{ $t('actions.save') | toSentenceCase }}
                    </v-btn>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { componentTypes } from '@enums/custom-components';
import { mapActions, mapState } from 'vuex';
import { isEmpty, isNil } from 'lodash';
import namespaces from '@enums/namespaces';
import navigation from '@/js/navigation';
import suppliersCategoriesOptionsMixin from '@/js/pages/supplier/components/mixins/suppliers-categories-options-mixin';

export default {
    mixins: [suppliersCategoriesOptionsMixin],
    props: {
        editContext: {
            type: Object,
            default: null,
        },
        formFields: {
            required: true,
            type: Array,
        },
        isPromotionSupplierFundingView: {
            type: Boolean,
            default: false,
        },
        promotionNamespace: String,
    },
    data() {
        return {
            isNewCommitment: false,
            validation: {
                isValid: true,
                formId: Symbol('supplierCommitmentForm'),
            },
            matrixWidth: 0,
        };
    },
    computed: {
        ...mapState('supplierCommitments', ['validationStates']),
        ...mapState('suppliers', ['suppliers']),

        form() {
            const expandedAreaFields = [
                {
                    fieldName: 'storeGroupResources',
                    editable: true,
                    type: componentTypes.commitmentMatrix,
                    expandedAreaComponent: true,
                    hidden: true,
                    ref: 'commitmentMatrix',
                    events: {
                        'matrix-updated': {
                            method: 'matrixUpdated',
                            params: [],
                        },
                    },
                },
            ];

            return {
                vuexModule: 'supplierCommitments',
                editContext: this.editContext,
                editable: true,
                editMode: !!this.editContext,
                addEvent: 'createSupplierCommitment',
                editEvent: 'updateSupplierCommitment',
                isStagingAreaResetNeeded: false,
                validation: this.validationStates[this.namespace],
                expandedAreaFields,
                resetStagingAreaNamespaceOnCreate: !this.editContext, // reset in create mode
                fields: [
                    ...this.formFields,
                    // Staging area is filling from fields array, so need to add fields from expanded section
                    ...expandedAreaFields,
                ],
            };
        },

        displayPromotions() {
            return (
                !this.isPromotionSupplierFundingView &&
                this.editContext &&
                !isEmpty(this.editContext.allocatedPromotionIds)
            );
        },
        namespace() {
            // 'default' used as namespace when we create new commitment
            return !this.editContext ? namespaces.default : this.editContext._id;
        },
        isSaveDisabled() {
            const validationState = this.validationStates[this.namespace];
            if (validationState) {
                return !this.validationStates[this.namespace].isValid;
            }

            return true;
        },

        buttonsContainerWidth() {
            // check if current form is for creating new commitment and it's closed
            return isEmpty(this.editContext) && this.isNewCommitment === false
                ? '100%'
                : `${this.matrixWidth}px`;
        },

        isCreateButtonDisabled() {
            return this.isNewCommitment || isNil(this.categoryFromFilter);
        },
    },
    methods: {
        ...mapActions('supplierCommitments', [
            'setSelectedSupplierCommitmentId',
            'deleteSupplierCommitment',
        ]),
        ...mapActions('suppliers', ['fetchSuppliersByFilterCategories']),
        addNewCommitment() {
            this.setSelectedSupplierCommitmentId({ supplierCommitmentId: null });
            this.isNewCommitment = true;
            this.fetchSuppliersByFilterCategories();
            this.$nextTick(() => {
                // supplierCommitmentForm should be created to set supplierKey
                if (this.defaultSupplier) {
                    this.setFormSupplierKey(this.defaultSupplier.name);
                }
            });
        },
        async deleteCurrentSupplierCommitment() {
            if (this.isNewCommitment) {
                this.isNewCommitment = false;
            } else if (this.editContext) {
                await this.deleteSupplierCommitment({ id: this.editContext._id });
                navigation.toSupplierCommitmentsView();
            }
        },
        async save() {
            if (this.$refs.supplierCommitmentForm) {
                this.$refs.supplierCommitmentForm.setField('allocatedPromotionIds', []);
                this.$refs.supplierCommitmentForm.setField('notes', []);
                const { error } = await this.$refs.supplierCommitmentForm.submit();
                if (!error) {
                    this.isNewCommitment = false;
                }
            }
        },

        setFormSupplierKey(supplierName) {
            if (this.$refs.supplierCommitmentForm) {
                const { supplierKey, clientSupplierKey } = this.suppliers.find(
                    supplier => supplier.name === supplierName
                );

                this.$refs.supplierCommitmentForm.setField('supplierName', supplierName);
                this.$refs.supplierCommitmentForm.setField('supplierKey', supplierKey);
                this.$refs.supplierCommitmentForm.setField('clientSupplierKey', clientSupplierKey);
            }
        },
        formToggle() {
            this.isNewCommitment = false;
            // setSelectedSupplierCommitmentId to null to close the expanded area
            this.setSelectedSupplierCommitmentId({ supplierCommitmentId: null });
            // only navigate to the supplier-commitments view if
            // this.isPromotionSupplierFundingView is false
            if (!this.isPromotionSupplierFundingView) navigation.toSupplierCommitmentsView();
        },

        setButtonsContainerWidth(newWidth) {
            this.matrixWidth = newWidth;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.supplier-commitment-form-wrapper {
    display: contents;

    &__view-promotions {
        grid-column: 1 / last-column;
        padding: 0 1rem;
        background: $promo-white;
    }

    &__buttons {
        background: $promo-white;
        display: grid;
        grid-template-columns: 25% auto;
        grid-column: 1 / last-column;
        border-top: $promo-grey 0.1rem solid;
        padding: 1.5rem 0 1.5rem 1rem;
        font-size: 1.2rem;
        font-weight: 600;

        &--save {
            text-align: right;
            .save-btn {
                padding: 0 1.2rem;
                height: 2rem;
                margin-left: 1rem;
            }
        }
    }
}
</style>
