<template functional>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 3.71313L16.8455 15.5404H3.15455L10 3.71313ZM10 0.0858612L0 17.3586H20L10 0.0858612ZM10.9091 12.8131H9.09091V14.6313H10.9091V12.8131ZM10.9091 7.35859H9.09091V10.995H10.9091V7.35859Z"
            fill="#C11512"
        />
    </svg>
</template>

<script>
export default {
    name: 'RedErrorTriangle',
};
</script>
