<template>
    <div>
        <v-snackbar
            v-for="(notification, index) in getNotifications"
            :key="index + notification.message"
            :value="true"
            :timeout="notification.popupTimeout || popupTimeout"
            :color="notification.severity || 'success'"
            bottom
        >
            {{ notification.message }}
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        popupTimeout: Number,
    },

    events: {
        onSuccessfulSubmit(options) {
            const entityName = this.$options.filters.toSentenceCase(
                this.$t(`entities.${options.entityName}`)
            );
            this.addNotification({
                message: this.$t('notifications.saveSuccess', { entityName }),
            });
        },
    },

    computed: {
        ...mapGetters('notifications', ['getNotifications']),
    },

    methods: {
        ...mapActions('notifications', ['addNotification']),
    },
};
</script>
