var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "18px",
        height: "18px",
        viewBox: "0 0 18 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("arrows-alt")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1x1",
            points:
              "12.3821049 14.625 9 18 5.62039123 14.625 7.875 14.625 7.875 10.125 3.375 10.125 3.375 12.3796961 0 9.00002197 3.375 5.625 3.375 7.875 7.875 7.875 7.875 3.375 5.62039123 3.375 9 0 12.3821049 3.375 10.125 3.375 10.125 7.875 14.65327 7.875 14.65327 5.61799237 18 9.00002197 14.65327 12.3796961 14.65327 10.125 10.125 10.125 10.125 14.625",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("g", { attrs: { id: "Icon-/-Move-/-Blue" } }, [
            _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
              _c("use", { attrs: { "xlink:href": "#path-1x1" } }),
            ]),
            _vm._v(" "),
            _c("use", {
              attrs: {
                id: "arrows-alt",
                fill: "#2F477C",
                "fill-rule": "nonzero",
                "xlink:href": "#path-1x1",
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }