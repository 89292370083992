<template>
    <notes
        v-if="renderNotes"
        :notes="notes"
        :disabled="isDisabled"
        open-right
        @delete="removeNote"
        @post="postNote"
        @save="saveNote"
    />
</template>

<script>
import Vue from 'vue';
import { get, isFunction, sortBy } from 'lodash';
import NotesFieldKeys from '@enums/notes-field-keys';
import { mapActions } from 'vuex';

export default Vue.extend({
    computed: {
        renderNotes() {
            // conditional icon render based on row data
            if (this.params.conditionalRender) {
                return get(this.params.data, this.params.conditionPath, false);
            }
            return true;
        },

        notes() {
            return sortBy(get(this.params.data, 'notes', []), 'createdOn');
        },

        isDisabled() {
            const data = this.params.data;

            if (isFunction(this.params.isDisabled)) {
                return this.params.isDisabled(data);
            }

            return false;
        },
    },
    methods: {
        ...mapActions('promotions', ['addNote', 'deleteNote', 'updateNote']),
        postNote(note) {
            this.addNote({
                note,
                id: get(this.params.data, 'promotionId'),
                fieldKey: NotesFieldKeys.root,
            });
        },
        removeNote(noteId) {
            this.deleteNote({
                noteId,
                id: get(this.params.data, 'promotionId'),
                fieldKey: NotesFieldKeys.root,
            });
        },

        saveNote(note) {
            this.updateNote({
                note,
                id: get(this.params.data, 'promotionId'),
                fieldKey: NotesFieldKeys.root,
            });
        },
    },
});
</script>

<style lang="scss" scoped></style>
