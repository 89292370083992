<template>
    <div class="workflow-task-alert-list">
        <div class="workflow-task-alert-list__header">
            {{ $t('workflow.taskAlertList.header') | toSentenceCase }}

            <div class="alert-count-badges">
                <div
                    v-for="alertsGroup in alertsGroupedByConfiguredCategories"
                    :key="alertsGroup.key[0]"
                >
                    <alert-count-badge
                        :priority="alertsGroup.key[0]"
                        :alert-count="alertsGroup.alerts.length"
                    />
                </div>
            </div>
        </div>
        <div
            v-for="alert in alerts"
            :key="
                `${alert.subCampaignId}::${alert.task}::${alert.entity}}::${alert.hierarchyGroup}`
            "
            class="workflow-task-alert-list__item"
        >
            <workflow-task-alert v-bind="alert" @sidebar-closed="$emit('sidebar-closed')" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        alerts: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters('subCampaigns', ['alertsGroupedByConfiguredCategories']),
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.workflow-task-alert-list {
    width: 90%;

    &__header {
        color: $promo-text-colour;
        font-size: 1.2rem;
        font-weight: 600;
        padding-top: 2rem;
        padding-bottom: 1rem;
        display: inline-block;
        width: 100%;

        .alert-count-badges {
            float: right;
            display: flex;
            flex-direction: row;
            > * {
                margin-right: 0.2rem;
            }
        }
    }
}
</style>
