import { reward, requirement } from '@enums/offer-mechanic-option-type';
import rewardTypes from '@enums/reward-types';
import requirementTypes from '@enums/requirement-types';
import validators from '@/js/validators';
import { toSentenceCase } from '@/js/utils/string-utils';

const basePath = 'planning.promotionsMaintenance.offer.offerMechanic';

export default (i18n, currencySymbol) => ({
    noDiscount: {
        type: reward,
        mappedType: rewardTypes.noDiscount,
    },
    savePercent: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'save',
        suffixLabel: 'percent',
        mappedType: rewardTypes.savePercent,
        showNumberInput: true,
        format: 'numberWithDecimals',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.maxValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.max`, {
                        max: 100,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.savePercent`),
                    })
                ),
            },
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.savePercent`),
                    })
                ),
            },
        ],
    },
    payMonetary: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'pay',
        suffixLabel: 'currencySymbol',
        mappedType: rewardTypes.setMoney,
        showNumberInput: true,
        format: 'currencyNoLabelPadded',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.payMonetary`, {
                            currencySymbol,
                        }),
                    })
                ),
            },
        ],
    },
    onNthItem: {
        type: requirement,
        prefixLabel: 'onNthItem',
        suffixLabel: '',
        mappedType: requirementTypes.onNthItem,
        showNumberInput: true,
        format: 'number',
        defaultNumberInputValue: 1,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.onNthItem`),
                    })
                ),
            },
        ],
    },
    whenYouSpend: {
        type: requirement,
        prefixLabel: 'whenYouSpend',
        suffixLabel: 'currencySymbol',
        mappedType: requirementTypes.spendMoney,
        showNumberInput: true,
        format: 'currencyNoLabelPadded',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.whenYouSpend`),
                    })
                ),
            },
        ],
    },
    whenYouBuy: {
        type: requirement,
        prefixLabel: 'whenYouBuy',
        suffixLabel: 'items',
        mappedType: requirementTypes.buyNumber,
        showNumberInput: true,
        format: 'number',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.whenYouBuy`),
                    })
                ),
            },
        ],
    },
    getNFree: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'getNFree',
        suffixLabel: '',
        mappedType: rewardTypes.getNFree,
        showNumberInput: true,
        format: 'number',
        attributeToMapToNumberInput: 'amount',
        defaultNumberInputValue: 1,
        isGlobalDisabled: true,
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.getNFree`),
                    })
                ),
            },
        ],
    },
    saveMonetary: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'save',
        suffixLabel: 'currencySymbol',
        mappedType: rewardTypes.saveMoney,
        showNumberInput: true,
        format: 'currencyNoLabelPadded',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.saveMonetary`, {
                            currencySymbol,
                        }),
                    })
                ),
            },
        ],
    },
    saveMonetaryPerItem: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'save',
        suffixLabel: 'moneyPerItem',
        mappedType: rewardTypes.saveMoneyPerItem,
        showNumberInput: true,
        format: 'currencyNoLabelPadded',
        defaultNumberInputValue: 0,
        attributeToMapToNumberInput: 'amount',
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(
                            `${basePath}.rewardRequirementOptions.saveMonetaryPerItem`,
                            {
                                currencySymbol,
                            }
                        ),
                    })
                ),
            },
        ],
    },
    // New price per item will be set in the products table.
    newPricePerItem: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'newPricePerItem',
        suffixLabel: '',
        mappedType: rewardTypes.newPricePerItem,
        isGlobalDisabled: true,
    },
    newShelfPricePerItem: {
        type: reward,
        rewardType: 'primary',
        prefixLabel: 'newShelfPricePerItem',
        suffixLabel: '',
        mappedType: rewardTypes.newShelfPricePerItem,
        isGlobalDisabled: true,
    },
    redeemPoints: {
        type: requirement,
        prefixLabel: 'redeem',
        suffixLabel: '',
        mappedType: requirementTypes.redeemLoyaltyPoints,
        showDropDown: true,
        dropDownPlaceholder: 'selectLoyaltyPoints',
        showNumberInput: true,
        format: 'number',
        attributeToMapToNumberInput: 'amount',
        isGlobalOnly: true,
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.redeemPoints`),
                    })
                ),
            },
        ],
    },
    getPoints: {
        type: reward,
        prefixLabel: 'receive',
        suffixLabel: '',
        mappedType: rewardTypes.getLoyaltyPoints,
        showDropDown: true,
        dropDownPlaceholder: 'selectLoyaltyPoints',
        showNumberInput: true,
        format: 'number',
        attributeToMapToNumberInput: 'amount',
        isGlobalOnly: true,
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.getPoints`),
                    })
                ),
            },
        ],
    },
    getFreeGift: {
        type: reward,
        prefixLabel: 'receive',
        suffixLabel: '',
        mappedType: rewardTypes.getFreeGift,
        showDropDown: true,
        dropDownPlaceholder: 'selectFreeGift',
        showNumberInput: true,
        format: 'number',
        // For free gifts we need to store the number input to the `quantity`
        // attribute, which is different to the other reward/requirements where
        // we have an `amount` attribute. This is to ensure we are aligned with
        // the client specification.
        attributeToMapToNumberInput: 'quantity',
        isGlobalOnly: true,
        validations: [
            {
                validator: validators.minValue,
                message: toSentenceCase(
                    i18n.t(`${basePath}.validations.minInclusive`, {
                        min: 0,
                        fieldName: i18n.t(`${basePath}.rewardRequirementOptions.getFreeGift`),
                    })
                ),
            },
        ],
    },
});
