<template>
    <div class="client-systems-testing">
        <div class="client-systems-testing__switch">
            <div class="client-systems-testing__switch--test">
                {{ $t('admin.clientSystemsTesting.systemToTest') | toSentenceCase }}:
            </div>
            <v-btn-toggle v-model="clientSystem" mandatory>
                <v-btn :value="clientSystems.forecasting">
                    {{ $t('admin.clientSystemsTesting.forecastingSystem') | toSentenceCase }}
                </v-btn>
                <v-btn :value="clientSystems.execution">
                    {{ $t('admin.clientSystemsTesting.executionSystem') | toSentenceCase }}
                </v-btn>
            </v-btn-toggle>
        </div>
        <div class="client-systems-testing__headers">
            <div class="client-systems-testing__headers--input">
                {{ $t('admin.clientSystemsTesting.jsonInput') }}
            </div>
            <div class="client-systems-testing__headers--input">
                {{ $t('admin.clientSystemsTesting.jsonResponse') }}
            </div>
        </div>
        <div class="client-systems-testing__editors">
            <v-jsoneditor
                ref="editor"
                v-model="json[clientSystem]"
                :options="options"
                :plus="false"
                @error="onError"
            />
            <v-jsoneditor
                ref="response-editor"
                :value="!isEmpty(errorJson) ? errorJson : responseJson"
                :plus="false"
                class="client-systems-testing__editors--response"
                :options="responseOptions"
            />
        </div>
        <div class="client-systems-testing__buttons">
            <v-btn primary depressed @click="transformPromo">
                {{ $t('actions.transform') | toSentenceCase }}
            </v-btn>
            <v-divider vertical class="client-systems-testing__buttons--divider" />
            <v-btn
                primary
                depressed
                :disabled="disabled || isEmpty(transformedPromotion)"
                @click="submit"
            >
                {{ $t('actions.submit') | toSentenceCase }}
            </v-btn>
            <v-btn
                primary
                depressed
                class="client-systems-testing__buttons--clear"
                :disabled="disabled"
                @click="clear"
            >
                {{ $t('actions.clear') | toSentenceCase }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import to from 'await-to-js';
import clientSystems from '@enums/client-systems';
import { isEmpty } from 'lodash';

export default {
    data() {
        return {
            clientSystems,
            clientSystem: clientSystems.forecasting,
            json: {
                [clientSystems.forecasting]: {
                    promotionId: '',
                },
                [clientSystems.execution]: {
                    promotionId: '',
                },
            },
            options: {
                mode: 'code',
                name: 'input',
                enableTransform: false,
            },

            responseOptions: {
                mode: 'view',
                name: 'response',
            },
            disabled: false,
        };
    },
    computed: {
        responseJson() {
            return this.$store.state[this.clientSystem].jsonPromotionResponse;
        },
        errorJson() {
            return this.$store.state[this.clientSystem].jsonPromotionError;
        },
        transformedPromotion() {
            return this.$store.state[this.clientSystem].transformedPromotion;
        },
    },

    watch: {
        // We only come in here if the json is valid
        // There seems to be some weirdness with the jsoneditor component
        json: {
            handler() {
                this.disabled = false;
            },
            deep: true,
        },
    },
    methods: {
        isEmpty,
        onError() {
            this.disabled = true;
        },
        async submit() {
            await this.$store.dispatch(`${this.clientSystem}/submitJson`, {
                json: this.json[this.clientSystem],
            });
        },

        async transformPromo() {
            const [err, transformedPromotion] = await to(
                this.$store.dispatch(`${this.clientSystem}/transformPromotion`, {
                    json: this.json[this.clientSystem],
                })
            );
            if (!err && transformedPromotion) {
                this.json[this.clientSystem] = transformedPromotion;
            }
        },

        clear() {
            this.json[this.clientSystem] = {
                promotionId: '',
            };
            this.$store.dispatch(`${this.clientSystem}/clearTestingValues`);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.client-systems-testing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &__switch,
    &__buttons,
    &__headers {
        padding: 0.5rem;
        display: flex;

        flex-direction: row;
        font-size: 1.4rem;
        font-weight: 600;
        background-color: white;

        &--test {
            padding-right: 1rem;
        }
    }

    &__headers {
        > * {
            width: 50%;
        }
    }

    &__buttons {
        border-top: 1px $promo-grey-2 solid;

        &--divider {
            margin-left: 0.2rem;
            margin-right: 0.2rem;
        }

        &--clear {
            margin-left: auto;
        }
    }

    &__editors {
        display: flex;
        height: 100%;
    }

    ::v-deep {
        .jsoneditor-menu {
            background-color: $promo-primary-colour;
            border: unset;
        }
        .jsoneditor {
            border: unset;
            height: 100%;
            background-color: white;
        }
        .jsoneditor-mode-view {
            border-left: 2px $promo-primary-colour solid;
            height: 100%;
            background-color: white;
        }
        .jsoneditor-navigation-bar,
        .jsoneditor-poweredBy,
        .jsoneditor-repair,
        .jsoneditor-sort,
        .jsoneditor-statusbar {
            display: none;
        }
        .max-btn {
            display: unset;
        }
        .jsoneditor-value,
        .jsoneditor-field,
        .jsoneditor-separator {
            line-height: 2rem;
        }
    }
}
</style>
