var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "btn-checkbox-wrapper" },
    [
      _c("vuex-checkbox", {
        attrs: {
          getter: () => _vm.isSelected(),
          setter: (value) => _vm.selectionHandler(value),
          indeterminate: _vm.indeterminate && !_vm.allSelectedInHierarchy,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }