<template>
    <div>
        <loyalty-points-delete-dialog
            v-if="isEditMode"
            :resource="loyaltyPointsResource"
            :resource-id="resourceId"
            :child-dependencies-warning="false"
        />
        <delete-button v-else background @delete="cancelCreation" />
    </div>
</template>

<script>
import Vue from 'vue';
import { loyaltyPoints as loyaltyPointsResource } from '@enums/resources';

export default Vue.extend({
    data() {
        return {
            loyaltyPointsResource,
        };
    },
    computed: {
        resourceId() {
            return this.params.data._id;
        },
        isEditMode() {
            return this.resourceId;
        },
    },
    methods: {
        cancelCreation() {
            const selectedNode = this.params.node;
            const selectedData = selectedNode.data;
            this.params.api.applyTransaction({ remove: [selectedData] });
            this.params.context.componentParent.enableGridControls();
        },
    },
});
</script>
