<template>
    <v-tooltip z-index="400" top :max-width="500" :disabled="!maxProductsTooltip">
        <template v-slot:activator="{ on }">
            <div
                class="ag-checkbox-wrapper"
                :class="{ 'ag-checkbox-wrapper__centered': isCenteredHorizontally }"
                v-on="on"
            >
                <vuex-checkbox
                    :class="{ 'ag-checkbox': isCenteredHorizontally }"
                    :getter="
                        () => (params.pathToData ? get(params, params.pathToData) : params.value)
                    "
                    :setter="value => setValue(value)"
                    :disabled="isDisabled"
                    :validations="params.validations && params.validations(params)"
                />
            </div>
        </template>
        <span>{{ maxProductsTooltip }}</span>
    </v-tooltip>
</template>

<script>
import Vue from 'vue';
import { isFunction, get } from 'lodash';

export default Vue.extend({
    data() {
        return {
            isChecked: false,
        };
    },
    computed: {
        // Controls whether classes for centering the checkbox horizontally are applied
        isCenteredHorizontally() {
            return this.params.isCenteredHorizontally;
        },
        isDisabled() {
            return (
                (isFunction(this.params.isDisabled)
                    ? this.params.isDisabled(this.params)
                    : this.params.isDisabled) || this.isReadOnly
            );
        },

        maxProductsTooltip() {
            return isFunction(this.params.maxProductsTooltip)
                ? this.params.maxProductsTooltip(this.params)
                : false;
        },
    },
    created() {
        // Ensure the internal state is set correctly when the component is first created.
        if (this.params.pathToData) {
            this.isChecked = get(this.params, this.params.pathToData);
        } else {
            this.isChecked = this.params.value;
        }
    },
    methods: {
        get,
        setValue(newValue) {
            //  set up local state
            this.isChecked = newValue;

            // set up agGrid column value
            const colId = this.params.column.colId;
            this.params.node.setDataValue(colId, newValue);
        },
    },
});
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

// centers the checkbox within it's parent
.ag-checkbox-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &__centered {
        justify-content: center;
    }

    // Removes the default 8px margin that comes in v-checkbox
    .ag-checkbox .v-input--selection-controls__input {
        margin: 0;
    }
}

.simple-checkbox {
    .v-icon {
        color: $promo-primary-colour;
        font-size: 2rem;
    }
    .v-input--selection-controls__ripple {
        height: 3rem;
        width: 3rem;
        top: calc(50% - 2.2rem);
    }
}
</style>
