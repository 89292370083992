<template>
    <div class="wrapper" :class="{ 'wrapper-scrollable': isScrollable }">
        <div v-if="isScrollable" class="document-navigator__arrow">
            <navigate-button
                left
                :is-basic="isBasicNavigateButton"
                :disabled="leftButtonDisabled"
                @click="onLeftScrollerClick"
            />
        </div>
        <ul
            class="v-pagination"
            :class="{
                'v-pagination--disabled': disabled,
                'v-pagination--scrollable': isScrollable,
            }"
        >
            <li v-if="firstPageVisible" class="v-pagination__first-page">
                <v-btn
                    depressed
                    class="v-pagination__item"
                    :disabled="isPageDisabled(firstPageValue)"
                    :class="{
                        'v-pagination__item--active': isActiveClass(firstPageValue),
                        primary: isActiveClass(firstPageValue),
                        'v-pagination__item--colored': coloredPages.includes(firstPageValue),
                        'v-pagination__item--highlighted': highlightedPages.includes(
                            firstPageValue
                        ),
                    }"
                    @click.prevent="pageClickHandler(1)"
                >
                    {{ firstPageValue }}
                </v-btn>
            </li>
            <li
                v-if="isPaginationEllipsisVisible({ isStart: true })"
                class="v-pagination__ellipsis--start"
            >
                ...
            </li>
            <li
                v-for="(page, index) in computedLength"
                :key="'page' + page"
                :class="{ 'no-border': isBorderNeed(index) }"
            >
                <v-btn
                    depressed
                    class="v-pagination__item"
                    :disabled="isPageDisabled(getPageValue(page))"
                    :class="{
                        'v-pagination__item--active': isActiveClass(getPageValue(page)),
                        primary: isActiveClass(getPageValue(page)),
                        'v-pagination__item--colored': coloredPages.includes(getPageValue(page)),
                        'v-pagination__item--highlighted': highlightedPages.includes(
                            getPageValue(page)
                        ),
                    }"
                    @click.prevent="pageClickHandler(getPageValue(page))"
                >
                    {{ getPageValue(page) }}
                </v-btn>
            </li>
            <li
                v-if="isPaginationEllipsisVisible({ isStart: false })"
                class="v-pagination__ellipsis--end"
            >
                ...
            </li>
            <li v-if="lastPageVisible" class="v-pagination__last-page">
                <v-btn
                    depressed
                    class="v-pagination__item"
                    :disabled="isPageDisabled(length)"
                    :class="{
                        'v-pagination__item--active': isActiveClass(length),
                        primary: isActiveClass(length),
                        'v-pagination__item--colored': coloredPages.includes(length),
                        'v-pagination__item--highlighted': highlightedPages.includes(length),
                    }"
                    @click.prevent="pageClickHandler(length)"
                >
                    {{ length }}
                </v-btn>
            </li>
        </ul>
        <div v-if="isScrollable" class="document-navigator__arrow">
            <navigate-button
                right
                :is-basic="isBasicNavigateButton"
                :disabled="rightButtonDisabled"
                @click="onRightScrollerClick"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Number,
            default: null,
        },
        coloredPages: {
            type: Array,
            default: () => [],
        },
        disabledPages: {
            type: Array,
            default: () => [],
        },
        highlightedPages: {
            type: Array,
            default: () => [],
        },
        startFromSecond: {
            type: Boolean,
            default: false,
        },
        isMultiSelect: {
            type: Boolean,
            default: false,
        },
        multiSelectValues: {
            type: Array,
            default: () => [],
        },
        itemsInScreen: {
            type: Number,
            default: 10,
        },
        isBasicNavigateButton: {
            type: Boolean,
            default: false,
        },
        useArrowAsPagination: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            offset: 0,
        };
    },
    computed: {
        isScrollable() {
            return this.startFromSecond
                ? this.itemsInScreen < this.length - 1
                : this.itemsInScreen < this.length;
        },
        computedLength() {
            if (!this.isScrollable && this.startFromSecond) {
                return this.length - 1;
            }
            return this.isScrollable ? this.itemsInScreen : this.length;
        },
        maxOffset() {
            if (this.length < this.itemsInScreen) {
                return 0;
            }
            if (this.startFromSecond) {
                return this.length - this.itemsInScreen - 1;
            }
            return this.length - this.itemsInScreen;
        },
        rightButtonDisabled() {
            return this.offset === this.maxOffset;
        },
        leftButtonDisabled() {
            if (this.useArrowAsPagination) {
                return this.value === 1;
            }
            return this.offset === 0;
        },
        firstPageVisible() {
            return this.isScrollable && this.offset > 0;
        },
        lastPageVisible() {
            return this.isScrollable && this.offset < this.maxOffset;
        },
        firstPageValue() {
            return this.startFromSecond ? 2 : 1;
        },
    },
    watch: {
        length() {
            this.offset = 0;
        },
    },
    methods: {
        pageClickHandler(page) {
            if (page === 1) {
                this.offset = 0;
            }
            if (page === this.length) {
                this.offset = this.maxOffset;
            }
            this.$emit('input', page);
        },
        isPageDisabled(page) {
            return this.disabledPages.includes(page);
        },
        onLeftScrollerClick() {
            if (this.offset > 0) {
                this.offset -= 1;
            }

            if (this.useArrowAsPagination) {
                this.$emit('input', this.value - 1);
            }
        },
        onRightScrollerClick() {
            if (this.useArrowAsPagination) {
                this.$emit('input', this.value + 1);

                if (this.value >= this.itemsInScreen) {
                    this.offset += 1;
                }
            } else if (this.offset < this.maxOffset) {
                this.offset += 1;
            }
        },
        // in case of copy resource we need to skip first page, prop startFromSecond is using for this
        // for this reason this method was added
        // ex: we need render pages from 2 to 8
        // in prop we have length === 8 for rendering needed pages we decrease length for 1
        // and add 1 for each page
        // also we can use offset for supporting scrolling
        getPageValue(page) {
            return this.startFromSecond ? page + 1 + this.offset : page + this.offset;
        },

        isActiveClass(page) {
            if (!this.isMultiSelect) {
                return this.value === page;
            }

            return this.multiSelectValues.includes(page);
        },
        isBorderNeed(index) {
            return (this.startFromSecond && index === 0) || this.offset > 0;
        },
        isPaginationEllipsisVisible({ isStart }) {
            if (isStart) {
                return this.offset > 0;
            }
            return this.isScrollable && this.offset < this.maxOffset;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.wrapper-scrollable {
    display: grid;
    grid-template-columns: 2.7rem auto 2.7rem;
    align-items: center;
}

.wrapper-pagination {
    width: 18.3rem;
    overflow: hidden;
    position: relative;
    margin-left: 1rem;
}

.v-pagination {
    width: auto;

    li.no-border {
        border: none;
        padding: 0;
    }

    li.no-border + li {
        padding: 0;
    }

    .v-pagination {
        &__item {
            min-width: 2rem;
            width: 2rem;
            height: 2rem !important;
            padding: 0;
            border-radius: 0.2rem;
            border: $component-border;
            color: $promo-primary-colour;

            &.v-pagination__item--active {
                color: $promo-white;
            }

            &--colored {
                color: $positive-action;
                border-color: $positive-action;

                &.v-pagination__item--active {
                    background-color: $positive-action !important;
                    color: $promo-white;
                }
            }

            &--highlighted {
                background-color: $highlighted-category-bg-colour !important;
            }

            &:focus {
                outline: none;
            }
        }

        &__ellipsis {
            &--start,
            &--end {
                height: 2.6rem;
                letter-spacing: 0.2rem;
                padding: 0 0.4rem !important;
                font-weight: bold;
            }

            &--start {
                border-left: $component-border-divider;
            }

            &--end {
                border-right: $component-border-divider;
            }
        }

        &__first-page {
            padding-right: 0.4rem;
        }

        &__last-page {
            padding-left: 0.4rem;
        }
    }
    &.v-pagination--scrollable {
        margin-left: 0.5rem;
        padding-left: 0.4rem;
    }
}
</style>
