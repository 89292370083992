<template>
    <div class="promotion-products">
        <candidates-list
            :items="currentPromotionProductsAndGroups"
            :disabled="isProductsDisabled"
            :selected-products="assignedProducts"
            :list-namespace="ProductNamespaces.preparationPromotionProducts"
            :product-selection-enabled="canSelectProducts"
            expand-product-groups-by-default
        >
            <div
                v-for="(item, index) in currentPromotionProductsAndGroups"
                :key="getSidebarKey(item)"
                class="promotion-products__sidebar"
                :style="{ 'grid-row': `${index + 1}` }"
            >
                <promotion-products-sidebar-column
                    :candidate="item"
                    :is-disabled-color="isProductsDisabled"
                    :list-namespace="ProductNamespaces.preparationPromotionProducts"
                    field="sales"
                />
                <promotion-products-sidebar-column
                    :candidate="item"
                    :is-disabled-color="isProductsDisabled"
                    :list-namespace="ProductNamespaces.preparationPromotionProducts"
                    field="margin"
                />
            </div>
        </candidates-list>
        <div
            v-if="!currentPromotionProductsAndGroups.length"
            class="promotion-products__no_products"
        >
            {{ $t('preparation.allocation.noProducts') | toSentenceCase }}
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ProductNamespaces from '@enums/product-namespaces';
import { canEditChannel } from '@enums/feature-flags';

export default {
    props: {
        promotionId: {
            required: true,
            type: String,
        },
        isProductsDisabled: {
            type: Boolean,
            default: true,
        },
        assignedProducts: {
            type: Array,
        },
    },
    data() {
        return {
            pgOpenState: {},
            ProductNamespaces,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['promotionProductsAndGroups']),

        currentPromotionProductsAndGroups() {
            return this.promotionProductsAndGroups(this.promotionId);
        },

        canSelectProducts() {
            return this.toggleLogic[canEditChannel];
        },
    },
    methods: {
        getSidebarKey(item) {
            return `candidate-sidebar-${
                item.products ? `product-group-${item._id}` : `product-${item.productKey}`
            }`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.sidebar {
    z-index: $health-sidebar-z-index;
}

::v-deep .candidates-list {
    &__candidate {
        grid-column: 1 / span 1;
        padding: 0.2rem 5.5rem 0.2rem 2.5rem;
    }

    &__product {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }
}

.promotion-products {
    grid-column: span 9;
    display: grid;
    padding-top: 1rem;

    &__sidebar {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        border-top: 1px solid $promo-campaign-border-3;
        z-index: 99;
        grid-column: 2;
    }

    &__no_products {
        padding: 1rem 5.5rem;
    }
}
</style>
