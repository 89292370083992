import tabStates from '@enums/tab-states';
import scopes from '@enums/product-offer-group-scope';
import { isEmpty } from 'lodash';

function validateOfferTab({ promotion }) {
    const firstTier = promotion.offerMechanic.tiers[0];

    const hasComponent =
        firstTier &&
        (!isEmpty(firstTier.globalRewards) ||
            !isEmpty(firstTier.globalRequirements) ||
            !isEmpty(firstTier.freeGifts) ||
            !isEmpty(
                !isEmpty(firstTier.productOfferGroups) && firstTier.productOfferGroups[0].rewards
            ));
    const hasProductsSelected =
        !isEmpty(promotion.productOfferGroups) &&
        promotion.productOfferGroups.some(
            pog =>
                !isEmpty(pog.products) ||
                [scopes.categoryWide, scopes.storeWide].includes(pog.scope)
        );

    if (!hasComponent) {
        return hasProductsSelected ? tabStates.inProgress : tabStates.invalid;
    }
    return hasProductsSelected ? tabStates.completed : tabStates.inProgress;
}

export default {
    validateOfferTab,
};
