import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter" }, [
    _c(
      "div",
      { staticClass: "filter__grouping" },
      [
        _c("label", { staticClass: "filter__label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(_vm.$t("general.labels.filter"))
              ) +
              ":\n        "
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.filters, function (filter, index) {
          return _c(
            "div",
            { key: filter.filterId, staticClass: "filter-group__filters" },
            [
              _c(
                "div",
                { staticClass: "flex-row" },
                [
                  _c("vuex-select", {
                    staticClass: "filter__parent-filter",
                    attrs: {
                      placeholder: _vm._f("toSentenceCase")(
                        _vm.$t("parkingLot.filters.selectFilter")
                      ),
                      options: _vm.getFilterOptions(filter),
                      "item-value": "key",
                      "item-text": "text",
                      getter: () => filter.filterKey,
                      setter: (key) => _vm.filterSetter(filter, key),
                    },
                  }),
                  _vm._v(" "),
                  filter.filterKey
                    ? _c(
                        _vm.componentMap[
                          (_vm.filterDefinitions[filter.filterKey] || {}).type
                        ],
                        {
                          tag: "component",
                          attrs: {
                            placeholder: _vm._f("toSentenceCase")(
                              _vm.$t(
                                `parkingLot.filters.select.${filter.filterKey}`
                              )
                            ),
                            options: _vm.getFilterValueOptions({
                              filterKey: filter.filterKey,
                            }),
                            "item-value": "value",
                            "item-text": "text",
                            getter: () => filter.value,
                            setter: (value) =>
                              _vm.filterValueSetter({
                                filter,
                                value,
                              }),
                            multiple: "",
                            chips: "",
                            horizontal: "",
                            "deletable-chips": "",
                            autocomplete: "",
                            "invalid-selection-text":
                              "general.info.invalidFilterSelection",
                            "finite-list": _vm.finiteList,
                            "max-items-in-list": _vm.maxItemsInList,
                            "has-select-all": false,
                            "sort-results": "",
                          },
                          on: { change: _vm.change },
                        }
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showRemoveFilterBtn
                    ? _c(
                        VBtn,
                        {
                          staticClass: "filter__cancel-button",
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFilter(index)
                            },
                          },
                        },
                        [
                          _c("icon", {
                            attrs: {
                              "icon-name": "cancel",
                              small: "",
                              "icon-colour-type": "primary-path",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-bar__add-button" },
          [
            _c("create-new-button", {
              attrs: { background: "", disabled: !_vm.canAddFilter },
              on: { createNew: _vm.addEmptyFilter },
            }),
          ],
          1
        ),
      ],
      2
    ),
    _vm._v(" "),
    _vm.includeDateFilter
      ? _c(
          "div",
          { staticClass: "date-pair" },
          [
            _c("vuex-date-picker", {
              ref: "fromDate",
              staticClass:
                "date-pair__date-picker date-pair__date-picker--from-date",
              attrs: {
                model: _vm.fromValue,
                getter: () => _vm.fromValue,
                setter: (val) => _vm.setFromValue(val),
                placeholder: _vm._f("toSentenceCase")(
                  _vm.$t("general.labels.from")
                ),
                "max-date": _vm.yesterday,
              },
            }),
            _vm._v(" "),
            _c("vuex-date-picker", {
              ref: "toDate",
              staticClass:
                "date-pair__date-picker date-pair__date-picker--to-date",
              attrs: {
                model: _vm.toValue,
                getter: () => _vm.toValue,
                setter: (val) => _vm.setToValue(val),
                placeholder: _vm._f("toSentenceCase")(
                  _vm.$t("general.labels.to")
                ),
                "min-date": _vm.fromValue,
                "max-date": _vm.yesterday,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }