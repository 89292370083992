import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-navigator" }, [
    _c(
      "div",
      { class: _vm.flexClass },
      [
        _c(
          "feature-toggle",
          { attrs: { toggle: _vm.canEditChannel } },
          [
            _c("vuex-select", {
              class: _vm.dropDownCss,
              attrs: {
                placeholder: _vm._f("toSentenceCase")(
                  _vm.$t("actions.selectTemplate")
                ),
                options: _vm.filteredPromoResourceTemplates,
                "item-value": "templateName",
                "item-text": "translatedTemplateName",
                getter: _vm.getTemplateName,
                setter: (templateName) =>
                  _vm.setSelectedPromoResourceTemplateName(templateName),
                filled: "",
              },
            }),
            _vm._v(" "),
            _c("confirm-dialog", {
              ref: "change-template-confirmation-dialog",
              attrs: {
                "question-text": _vm.$t(
                  "dialogs.changeResourceDefinitionTemplate.heading"
                ),
                "action-text": _vm.$t(
                  "dialogs.changeResourceDefinitionTemplate.confirm"
                ),
                "warning-text": _vm.$t(
                  "dialogs.changeResourceDefinitionTemplate.message"
                ),
                "has-activator": false,
              },
              on: {
                confirm: _vm.updatePromoResourceTemplate,
                close: _vm.resetPromoResourceTemplateDropDown,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row" },
          [
            _vm.isPagesResource
              ? [
                  _c(
                    VBtnToggle,
                    {
                      staticClass: "page-navigator__section-toggle",
                      attrs: {
                        value: _vm.activeDocumentSection,
                        mandatory: "",
                      },
                      on: { change: _vm.changeActiveSection },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            secondary: "",
                            disabled: _vm.isTemplatesDisabled,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("preparation.templates.templates")
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            secondary: "",
                            disabled: _vm.isCategoriesDisabled,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("preparation.templates.categories")
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            secondary: "",
                            disabled: _vm.isPromotionsDisabled,
                          },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t("preparation.templates.promotions")
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("page-navigator", {
                    attrs: {
                      "vuex-module": _vm.vuexModule,
                      namespace: _vm.namespace,
                      "field-name": _vm.fieldName,
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isSlotsResource
              ? _c("slots-selector", {
                  attrs: {
                    "vuex-module": _vm.vuexModule,
                    namespace: _vm.namespace,
                    "field-name": _vm.fieldName,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-navigator__notifications-icon" },
              [
                _c("notes", {
                  attrs: {
                    notes: _vm.notes,
                    disabled: _vm.isNotesDisabled,
                    "open-right": "",
                  },
                  on: {
                    delete: _vm.removeNote,
                    post: _vm.postNote,
                    save: _vm.saveNote,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "feature-toggle",
              { attrs: { toggle: _vm.canEditChannel } },
              [
                _vm.isLeafletResource
                  ? [
                      _c(
                        "div",
                        { staticClass: "page-navigator__buttons" },
                        [
                          _c("task-button", {
                            attrs: {
                              task: _vm.workflowTasks.submit,
                              "entity-type": _vm.workflowEntities.leaflet,
                              "entity-ids": [_vm.selectedResourceDefinitionKey],
                              "owning-entity-id": _vm.selectedSubCampaignId,
                              "sub-campaign-id": _vm.selectedSubCampaignId,
                              categories: _vm.selectedSubCampaign.categories,
                              "short-text": "",
                            },
                          }),
                          _vm._v(" "),
                          _vm.generalConfig.showWorkflowDebugIcon
                            ? _c("workflow-state", {
                                attrs: {
                                  "workflow-entity":
                                    _vm.workflowEntities.leaflet,
                                  "entity-id":
                                    _vm.selectedResourceDefinitionKey,
                                  "owning-entity-id": _vm.selectedSubCampaignId,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }