<template>
    <div class="product-changes-viewer">
        <div class="product-changes-viewer__grid">
            <promo-ag-grid
                ref="agGrid"
                :row-data="rowData"
                :column-defs="columnDefs"
                :grid-options="gridOptions"
                :default-col-def="defaultColDef"
                :grid-style="gridStyle"
                :grid-class="gridClass"
                dom-layout="autoHeight"
            />
        </div>
        <div class="product-changes-viewer__actions">
            <v-btn
                depressed
                outlined
                class="product-changes-viewer__actions--button"
                @click="acceptAll('parent')"
            >
                {{ $tkey('acceptAllParent') | toSentenceCase }}
            </v-btn>
            <v-btn
                depressed
                outlined
                class="product-changes-viewer__actions--button"
                @click="acceptAll('child')"
            >
                {{ $tkey('acceptAllChild') | toSentenceCase }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty, concat, find } from 'lodash';
import parentChildTypes from '@enums/parent-child';
import { toSentenceCase } from '@/js/utils/string-utils';
import AgTextWithTooltip from '@/js/components/promo-ag-grid/ag-text-with-tooltip';
import ButtonField from '@/js/components/promo-ag-grid/ag-button-field';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';

export default {
    localizationKey: 'planning.parentChanges',
    props: {
        // Child products list
        child: {
            type: Array,
            default: null,
        },
        // Parent products changeset
        parent: {
            type: Object,
            default: null,
        },
        selectAllChild: {
            type: Boolean,
            default: false,
        },
        selectAllParent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: true,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter
                rowHeight: 35, // Specified in pixels.
                headerHeight: 31, // Specified in pixels.
                groupHeaderHeight: 30, // Specified in pixels.
                columnTypes: {
                    parentChildField: {
                        cellClass: ['parent-child'],
                        valueFormatter: params => {
                            return params.value || '-';
                        },
                        maxWidth: 80,
                    },
                    parentChildButton: {
                        headerName: '',
                        headerHeight: 0,
                        filter: false,
                        maxWidth: 70,
                        cellRendererFramework: ButtonField,
                        cellRendererParams: {
                            onClick: params => {
                                params.data.selectedChangeset = params.value;
                                this.selectChanges(params.value, params.data);
                            },
                        },
                    },
                },
            },
            gridStyle: 'width: 144rem; height: 100%;',
            gridClass: 'ag-theme-custom__parent-child-changes',
            headerColumns: [
                parentChildTypes.child,
                parentChildTypes.parent,
                parentChildTypes.accept,
            ],
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },
        rowData() {
            if (!this.parent || isEmpty(this.parent)) {
                return [];
            }

            let data = [];
            const products = this.parent;

            if (!isEmpty(products.added)) {
                data = products.added.map(p => {
                    p.isAdded = true;
                    return p;
                });
            }

            if (!isEmpty(products.removed)) {
                data = concat(
                    data,
                    products.removed.map(p => {
                        p.isRemoved = true;
                        return p;
                    })
                );
            }

            const rows = [];
            data.forEach(product => {
                const row = {
                    child:
                        find(
                            this.child,
                            childProduct => childProduct.productKey === product.productKey
                        ) || {},
                    parent: product,
                    isAdded: product.isAdded,
                    selectedChangeset: parentChildTypes.parent,
                };

                rows.push(row);
            });

            return rows;
        },
        columnDefs() {
            return [
                ...this.headerColumns.map(header => {
                    return {
                        headerName: toSentenceCase(this.$tkey(`gridHeadings.${header}`)),
                        headerClass: `border-left__${header}`,
                        minWidth: 400,
                        autoHeight: true,
                        children: [
                            ...(header !== parentChildTypes.accept
                                ? [
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.productKey`)
                                          ),
                                          type: 'parentChildField',
                                          field: `${header}.clientProductKey`,
                                          headerClass: [`border-left__${header}`],
                                          cellClass: ['parent-child', `border-left__${header}`],
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.productName`)
                                          ),
                                          type: 'parentChildField',
                                          field: `${header}.name`,
                                          maxWidth: 260,
                                          cellRendererFramework: AgTextWithTooltip,
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.brandName`)
                                          ),
                                          type: 'parentChildField',
                                          field: `${header}.brandDescription`,
                                          maxWidth: 100,
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.supplierName`)
                                          ),
                                          type: 'parentChildField',
                                          field: `${header}.supplierName`,
                                          maxWidth: 100,
                                          cellRendererFramework: AgTextWithTooltip,
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.sizeAndUnits`)
                                          ),
                                          type: 'parentChildField',
                                          valueGetter: params =>
                                              this.getFormattedProductSizeAndUnit(params, header),
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                      {
                                          headerName: toSentenceCase(
                                              this.$tkey(`gridHeadings.margin`)
                                          ),
                                          type: 'parentChildField',
                                          field: `${header}.margin`,
                                          valueGetter: params => this.getMargin(params, header),
                                          headerClass: 'font-weight-bold',
                                          cellClass: ['parent-child', 'font-weight-bold'],
                                          cellClassRules: {
                                              'parent-child--selected': params =>
                                                  params.data.selectedChangeset === header,
                                              'parent-child--not-selected': params =>
                                                  params.data.selectedChangeset !== header,
                                              'parent-child--removed': params =>
                                                  parentChildTypes.parent === header &&
                                                  !params.data.isAdded,
                                          },
                                      },
                                  ]
                                : [
                                      {
                                          fieldName: 'childButton',
                                          type: 'parentChildButton',
                                          cellClass: [
                                              'parent-child',
                                              'parent-child__button',
                                              'parent-child__button--child',
                                          ],
                                          cellClassRules: {
                                              'parent-child__button--child-selected': params =>
                                                  params.data.selectedChangeset ===
                                                  params.colDef.cellRendererParams.value,
                                          },
                                          cellRendererParams: {
                                              text: toSentenceCase(
                                                  this.$tkey(
                                                      `gridHeadings.${parentChildTypes.child}`
                                                  )
                                              ),
                                              value: parentChildTypes.child,
                                          },
                                      },
                                      {
                                          fieldName: 'parentButton',
                                          type: 'parentChildButton',
                                          cellClass: [
                                              'parent-child',
                                              'parent-child__button',
                                              'parent-child__button--parent',
                                          ],
                                          cellClassRules: {
                                              'parent-child__button--parent-selected': params =>
                                                  params.data.selectedChangeset ===
                                                  params.colDef.cellRendererParams.value,
                                          },
                                          cellRendererParams: {
                                              text: toSentenceCase(
                                                  this.$tkey(
                                                      `gridHeadings.${parentChildTypes.parent}`
                                                  )
                                              ),
                                              value: parentChildTypes.parent,
                                          },
                                      },
                                  ]),
                        ],
                    };
                }),
            ];
        },
    },
    watch: {
        selectAllChild() {
            this.acceptAll(parentChildTypes.child);
        },
        selectAllParent() {
            this.acceptAll(parentChildTypes.parent);
        },
    },
    methods: {
        getMargin(params, header) {
            if (
                !params.data[`${header}`].promoPrices ||
                isEmpty(params.data[`${header}`].promoPrices)
            ) {
                return null;
            }
            const calculateMarginFunction = this.featureAwareFactory.getCalculateMarginFunction();
            const margin = calculateMarginFunction({
                product: params.data[`${header}`],
                prices: params.data[`${header}`].promoPrices[0],
            });
            return this.$n('numbers.default.percentageWith1Decimal', margin);
        },

        getFormattedProductSizeAndUnit(params, header) {
            const data = params.data[`${header}`];
            if (data.packSize !== undefined && data.packUnit) {
                return `${data.packSize}${data.packUnit}`;
            }
        },

        selectChanges(value, data) {
            const product = data.isAdded
                ? find(this.parent.added, p => p.clientProductKey === data.parent.clientProductKey)
                : find(
                      this.parent.removed,
                      p => p.clientProductKey === data.child.clientProductKey
                  );
            if (value === parentChildTypes.parent) {
                this.$emit('select-product', {
                    value: product,
                    isAdded: data.isAdded,
                    productKey: product.clientProductKey,
                });
            } else {
                this.$emit('select-product', {
                    value: null,
                    isAdded: data.isAdded,
                    productKey: product.clientProductKey,
                });
            }
        },

        acceptAll(selectedChangeset) {
            const data = this.rowData.map(row => {
                this.selectChanges(parentChildTypes[`${selectedChangeset}`], row);
                return { ...row, selectedChangeset: parentChildTypes[`${selectedChangeset}`] };
            });

            this.gridOptions.api.setRowData(data);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.product-changes-viewer {
    @include flex-column;
    margin-bottom: 2rem;

    &__actions {
        width: 100%;
        background-color: $promo-white;

        &--button {
            float: right;
            margin: 0.5rem;
            color: $promo-primary-colour;
        }
    }
}

.product-changes-viewer::v-deep {
    .ag-header-row-column-group {
        top: 0.1rem !important;
    }

    .ag-header {
        border-bottom: none !important;
    }

    .ag-header-row {
        border-bottom: solid 0.3rem $promo-table-blue-bg-colour !important;
    }
    .parent-child {
        &--removed {
            text-decoration: line-through;
        }
    }
}
</style>
