<template>
    <div class="losenge-date-display" :class="flexClass">
        <div :class="compactClass">
            <span v-if="isWeeksEqual"> {{ startDateWeek | weekNumberClient }} </span>
            <span v-else-if="hasDates">
                {{ startDateWeek | weekNumberClient }} -
                {{ endDateWeek | weekNumberClient }}
            </span>
            <span v-if="isDatesEqual" class="date_spacer">
                {{ startDate | datesInYear }}
            </span>
            <span v-else-if="hasDates" class="date_spacer"
                >{{ startDate | datesInYear }} — {{ endDate | datesInYear }}</span
            >
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
    props: {
        startDate: String,
        endDate: String,
        compact: {
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters('weeks', ['getWeekByDate']),

        hasDates() {
            return this.startDate || this.endDate;
        },
        flexClass() {
            return this.compact ? 'flex-row' : 'flex-column';
        },
        compactClass() {
            return this.compact ? 'compact' : '';
        },
        endDateWeek() {
            const parsedDate = Date.parse(this.endDate);
            return get(this.getWeekByDate(parsedDate), 'weekOfYear');
        },
        startDateWeek() {
            const parsedDate = Date.parse(this.startDate);
            return get(this.getWeekByDate(parsedDate), 'weekOfYear');
        },
        isDatesEqual() {
            const startDate = this.$moment(this.startDate).date();
            const endDate = this.$moment(this.endDate).date();
            return startDate === endDate;
        },
        isWeeksEqual() {
            return this.startDateWeek && this.startDateWeek === this.endDateWeek;
        },
    },
};
</script>

<style lang="scss" scoped>
.losenge-date-display {
    font-size: 1.1rem;
}

.compact {
    padding-right: 1rem;
    font-size: 1.2rem;
}

.date_spacer {
    padding-left: 1.5rem;
}
</style>
