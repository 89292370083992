'use strict';

/**
 * This enum contains a number of sections of the app where we display product groups.
 * These values are used as namespaces similar to how we use namespaces for the stagingArea.
 * They are used in the promotion-candidates vuex module to keep track of which product groups are
 * open in which area of the app.
 * This is needed as other components outside of the PGs need to know what is open so they can display
 * the correct values.
 */
module.exports = {
    potentialCandidates: 'potentialCandidates',
    selectedCandidates: 'selectedCandidates',
    offerMechanic: 'offerMechanic',
    preparationPromotionProducts: 'preparationPromotionProducts',
    supplierFunding: 'supplierFunding',
};
