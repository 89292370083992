<template functional>
    <svg
        width="17px"
        height="16px"
        viewBox="0 0 17 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>TV</title>
        <g id="------Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icons" transform="translate(-128.000000, -190.000000)">
                <g id="tv" transform="translate(128.666640, 190.000000)">
                    <rect id="Rectangle" x="0" y="0" width="16" height="16" />
                    <path
                        id="Colour"
                        d="M13.7480469,11.7148438 C14.84375,11.7148438 15.4414062,11.1289062 15.4414062,10.0214844 L15.4414062,3.88671875 C15.4414062,2.7734375 14.84375,2.1875 13.7480469,2.1875 L2.25195312,2.1875 C1.15625,2.1875 0.55859375,2.7734375 0.55859375,3.88671875 L0.55859375,10.0214844 C0.55859375,11.1289062 1.15625,11.7148438 2.25195312,11.7148438 L13.7480469,11.7148438 Z M13.6074219,10.3847656 L2.39257812,10.3847656 C2.046875,10.3847656 1.88867188,10.2441406 1.88867188,9.88085938 L1.88867188,4.02148438 C1.88867188,3.65820312 2.046875,3.51757812 2.39257812,3.51757812 L13.6074219,3.51757812 C13.953125,3.51757812 14.1113281,3.65820312 14.1113281,4.02148438 L14.1113281,9.88085938 C14.1113281,10.2441406 13.953125,10.3847656 13.6074219,10.3847656 Z M11.4277344,13.8125 C11.7558594,13.8125 12.0195312,13.5371094 12.0195312,13.2207031 C12.0195312,12.9042969 11.7558594,12.6347656 11.4277344,12.6347656 L4.56054688,12.6347656 C4.23828125,12.6347656 3.97460938,12.9042969 3.97460938,13.2207031 C3.97460938,13.5371094 4.23828125,13.8125 4.56054688,13.8125 L11.4277344,13.8125 Z"
                        fill="#2F477C"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'TV',
};
</script>
