<template>
    <span>
        <span v-observe-visibility="visibilityChanged" />
        <v-tooltip v-if="tooltipRequired" z-index="300" bottom :max-width="maxWidth">
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                    {{ truncatedText }}
                </span>
            </template>

            <span>{{ text }}</span>
        </v-tooltip>
        <span v-else> {{ truncatedText }} </span>
    </span>
</template>

<script>
import { truncate } from 'lodash';

export default {
    props: {
        text: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: Number,
            default: 300,
        },
        length: {
            type: Number,
            default: 60,
        },
    },
    data() {
        return { isVisible: false };
    },
    computed: {
        truncatedText() {
            return truncate(this.text, {
                length: this.length,
                omission: '...',
            });
        },
        tooltipRequired() {
            return this.isVisible && this.truncatedText !== this.text;
        },
    },
    methods: {
        visibilityChanged(isVisible) {
            this.isVisible = isVisible;
        },
    },
};
</script>
