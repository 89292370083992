import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promotion-categories" }, [
    _c(
      "div",
      { staticClass: "promotion-categories__edit" },
      [
        _c("main-dialog", {
          ref: "dialog",
          attrs: {
            heading:
              "planning.promotionsMaintenance.products.promotionCategories.editCategoriesListHeader",
            "btn-confirm-text": "actions.select",
            "confirm-btn-disabled": _vm.confirmBtnDisabled,
          },
          on: { confirm: _vm.saveCategories, close: _vm.rejectChanges },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    VForm,
                    {
                      ref: "categoriesForm",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.isValid,
                        callback: function ($$v) {
                          _vm.isValid = $$v
                        },
                        expression: "isValid",
                      },
                    },
                    [
                      _c(
                        "vuex-select",
                        _vm._g(
                          {
                            ref: "categoriesSelectDropdown",
                            attrs: {
                              options: _vm.allCategoryOptions,
                              getter: () => _vm.selectedCategoriesList,
                              "deletable-chips": false,
                              validations: _vm.validationRules,
                              "validate-on-create": "",
                              "validate-on-blur": "",
                              "append-icon": "edit",
                              chips: "",
                              multiple: "",
                              "finite-list": "",
                              "item-text": "levelEntryDescription",
                              "item-value": "levelEntryKey",
                              "hide-dropdown": "",
                            },
                            on: { clicked: _vm.openDialog },
                          },
                          activator
                        ),
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "icon-button",
                                _vm._g(
                                  {
                                    attrs: { icon: "edit" },
                                    on: { click: _vm.openDialog },
                                  },
                                  activator
                                )
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c("div", { staticClass: "labels-container" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("units")))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(_vm.$tkey("categories"))
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.isOpen
                    ? _c("categories-select", {
                        ref: "categoriesSelect",
                        attrs: { tree: _vm.tree, update: _vm.updateCategories },
                        on: { change: _vm.onChange },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }