import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-tab" },
    [
      _c("offer-mechanic", {
        attrs: {
          "vuex-module": _vm.vuexModule,
          namespace: _vm.computedNamespace,
          context: _vm.context,
          "form-ref": _vm.formRef,
          "is-past-promotion": _vm.isPastPromotion,
        },
        on: {
          "create-pog": _vm.newOfferProductGroup,
          "delete-pog": _vm.deletePOG,
        },
      }),
      _vm._v(" "),
      _vm.isOnOfferTab
        ? _c(
            VExpansionPanels,
            {
              attrs: { flat: "", accordion: "" },
              model: {
                value: _vm.panelIndex,
                callback: function ($$v) {
                  _vm.panelIndex = $$v
                },
                expression: "panelIndex",
              },
            },
            _vm._l(_vm.productOfferGroups, function (offerGroup, ix) {
              return _c("offer-group", {
                key: `${_vm.promotion._id}-${offerGroup._id}`,
                attrs: {
                  index: ix,
                  "vuex-module": _vm.vuexModule,
                  namespace: _vm.computedNamespace,
                  "product-offer-group": offerGroup,
                  expanded: _vm.panelIndex === ix,
                  context: _vm.context,
                },
                on: {
                  delete: _vm.deletePOG,
                  removed: _vm.handleProductRemoval,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("create-new-button", {
        staticClass: "offer-tab__new-group",
        attrs: {
          "btn-text": _vm.$tkey("newPOG"),
          disabled: _vm.splitPromotion,
        },
        on: { createNew: _vm.newOfferProductGroup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }