<template functional>
    <div>
        <div v-if="props.isFavourite">
            <icon icon-name="filled-star" small :disabled="parent.disabled || parent.isReadOnly" />
        </div>
        <div v-else>
            <icon icon-name="empty-star" small :disabled="parent.disabled || parent.isReadOnly" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isFavourite: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
};
</script>
