import { get, mapValues, partial, isEmpty } from 'lodash';
import { constructVBindObj } from '@/js/utils/component-utils';

const mixin = {
    methods: {
        constructVBindObj({
            context,
            field,
            props,
            contextualProps,
            functionProps,
            computedProps,
            defaultProp,
            fullContextAsPropName,
            componentRef,
        }) {
            return constructVBindObj({
                context,
                field,
                props,
                contextualProps,
                functionProps,
                computedProps,
                defaultProp,
                fullContextAsPropName,
                componentRef: componentRef || this,
            });
        },

        constructVBindEventObj({ events, context }) {
            if (!isEmpty(events)) {
                const values = mapValues(events, event => {
                    const contextualParams = (event.params || []).reduce((acc, param) => {
                        acc[param] = context[param];
                        return acc;
                    }, {});

                    // Finally, add the whole context to the params object which can be optionally used by the event
                    // method if required.
                    contextualParams[event.contextParamName || 'context'] = context;

                    // Using partial so that 'event' is dynamically passed to the callAction function.
                    if (event.shouldEmitEvent) {
                        return partial(this.emitEvent, event.name, contextualParams);
                    }

                    return partial(this.callAction, event.method, contextualParams);
                });

                return values;
            }
        },

        callAction(action, params, event) {
            return this[action](params, event);
        },

        emitEvent(name, params, event) {
            this.$emit(name, { params, event });
        },

        callContextFunction({ contextFunction, context, params }) {
            return contextFunction({ ...context, ...params });
        },

        getComputed(action) {
            return this[action];
        },

        getAttribute(object, path) {
            return get(object, path);
        },

        getSpanCss(columns) {
            return {
                'grid-row': `span 1`,
                'grid-column': `span ${columns}`,
            };
        },
    },
};

export default mixin;
