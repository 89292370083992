'use strict';

const promoResources = require('./promo-resources');

module.exports = {
    subCampaign: 'sub-campaign',
    scenario: 'scenario',
    promotion: 'promotion',
    leaflet: promoResources.leaflet,
};
