<template>
    <div
        class="slots-selector-container"
        :class="{ 'slots-selector-container__disabled': !selectedResourceDefinition }"
    >
        <span class="slots-selector-container__label">
            {{ $t('preparation.templates.numberOfSlots') | toSentenceCase }}
        </span>
        <vuex-select
            v-if="hasAccessToSlotCountDropDown"
            class="slots-selector-container__select"
            :options="slotsOptions"
            :getter="slotsGetter"
            :setter="slotsSetter"
            :disabled="!selectedResourceDefinition"
            :placeholder="'-'"
        />
        <div v-else class="slots-selector-container__no-of-slots">
            {{ slotsGetter() }}
        </div>
        <confirm-dialog
            ref="dialog_confirm"
            class="confirm-dialog"
            :question-text="$t('preparation.templates.changeSlotsModal.heading')"
            :warning-text="$t('preparation.templates.changeSlotsModal.message')"
            :action-text="$t('preparation.templates.changeSlotsModal.confirm')"
            :has-activator="false"
            @confirm="updateNumberOfSlots"
            @close="cancelUpdateNumberOfSlots"
        />
    </div>
</template>

<script>
import { get, map, range } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import uuid from 'uuid/v4';
import { canEditChannel } from '@enums/feature-flags';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    data() {
        return {
            previousSlotCount: 0,
            newSlotsCount: 0,
        };
    },
    computed: {
        ...mapState('subCampaigns', ['selectedResourceDefinitionKey']),
        ...mapGetters('subCampaigns', ['selectedResourceDefinition']),
        ...mapState('clientConfig', ['generalConfig', 'toggleLogic']),

        hasAccessToSlotCountDropDown() {
            return this.toggleLogic[canEditChannel];
        },

        slotsOptions() {
            return map(range(1, this.generalConfig.maxSlotsForPromoResource + 1), page =>
                page.toString()
            );
        },

        selectedResourceDefinitionIndex() {
            return this.model.findIndex(
                resource => resource.key === this.selectedResourceDefinitionKey
            );
        },
        shouldShowWarning() {
            const page = get(this.selectedResourceDefinition, 'pages[0]');
            if (!page) return false;
            const slotIdsToBeRemoved = page.slots.slice(this.newSlotsCount);
            return page.assignment.some(
                assignment => assignment.promotionId && slotIdsToBeRemoved.includes(assignment.area)
            );
        },
    },
    methods: {
        slotsGetter() {
            if (this.selectedResourceDefinition) {
                return `${this.selectedResourceDefinition.pages[0].slots.length}`;
            }
            return '0';
        },
        slotsSetter(value) {
            this.previousSlotsCount = this.selectedResourceDefinition.pages[0].slots.length;
            this.newSlotsCount = value;
            if (this.previousSlotsCount <= value || !this.shouldShowWarning) {
                this.updateNumberOfSlots();
            } else {
                // show modal for confirmation whether user want
                // to remove the slots and lose their data
                this.$refs.dialog_confirm.open();
            }
        },

        updateNumberOfSlots() {
            const currentSlots = get(this.selectedResourceDefinition, 'pages[0].slots');
            let newSlots;
            if (currentSlots.length <= this.newSlotsCount) {
                newSlots = [
                    ...currentSlots,
                    ...range(this.newSlotsCount - currentSlots.length).map(() => uuid()),
                ];
            } else {
                newSlots = currentSlots.slice(0, this.newSlotsCount);
            }

            this.$set(this.model[this.selectedResourceDefinitionIndex].pages[0], 'slots', newSlots);

            this.$refs.dialog_confirm.close();
            this.globalEmit('slots-updated', { slots: newSlots });
        },

        cancelUpdateNumberOfSlots() {
            // as model wasn't updated in setter, it contains old value,
            // but dropdown shows updated one
            // as input and change event of v-select emits after model setter
            // need to change model to another value and then in $nextTick
            // update it to previousPageCount
            const currentSlots = get(this.selectedResourceDefinition, 'pages[0].slots');
            this.$set(this.model[this.selectedResourceDefinitionIndex].pages[0], 'slots', []);
            this.$nextTick(() =>
                this.$set(
                    this.model[this.selectedResourceDefinitionIndex].pages[0],
                    'slots',
                    currentSlots
                )
            );
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.slots-selector-container {
    @include flex-row;

    &__select {
        &::v-deep {
            .v-select {
                &__slot {
                    background-color: $promo-background;

                    .v-label {
                        padding-left: 1rem;
                        padding-right: 0.6rem;
                    }
                }

                &__selections {
                    line-height: 1.6rem;

                    input {
                        width: 0;
                    }
                }

                &__selection.v-select__selection--comma {
                    padding-left: 1rem !important;
                }
            }
            .v-select__slot,
            .v-input__slot {
                height: 2.2rem !important;
            }
        }
    }

    &__no-of-slots {
        font-size: 1.2rem;
        line-height: 2.4rem;
        padding: 0 1rem 0 1rem;
    }

    &__label {
        font-size: 1.2rem;
        line-height: 2.4rem;
        padding: 0 1rem 0 1rem;
    }

    &__disabled {
        .slots-selector-container {
            &__label {
                color: $promo-grey;
            }
        }
    }
}
</style>
