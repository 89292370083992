'use strict';

/**
 * Enum definition file for notification statuses.
 *
 * @module data/enums/notification-statuses
 */
module.exports = {
    open: 'OPEN',
    closed: 'CLOSED',
    automaticallyClosed: 'AUTOMATICALLY_CLOSED',
    adminClosed: 'ADMIN_CLOSED',
};
