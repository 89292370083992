var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "number-input", class: _vm.cssClass }, [
    _vm.prefix
      ? _c("span", { staticClass: "number-input--prefix" }, [
          _vm._v(" " + _vm._s(_vm.prefix) + " "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formattedNumber,
          expression: "formattedNumber",
        },
      ],
      ref: "input",
      class: `${_vm.defaultCss} ${_vm.validationErrorCss}`,
      attrs: {
        name: "input-text-field",
        disabled: _vm.isDisabled,
        reverse: "",
        title: _vm.validationError,
        placeholder: _vm.placeholder,
      },
      domProps: { value: _vm.formattedNumber },
      on: {
        keypress: (params) =>
          _vm.positiveOnly
            ? _vm.validatePositiveIntegerInput(params)
            : _vm.validateIntegerInput(params),
        paste: _vm.validateNumberOnPaste,
        change: _vm.updateModel,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.formattedNumber = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _vm.suffix
      ? _c("span", { staticClass: "number-input--suffix" }, [
          _vm._v(" " + _vm._s(_vm.suffix) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }