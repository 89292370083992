<template>
    <v-list class="notifications-list">
        <h2
            v-if="!landingPageNotifications.length"
            class="notifications-list__no-notifications-label"
        >
            {{ $tkey('noNotifications') | toSentenceCase }}
        </h2>
        <v-list-item
            v-for="notification in landingPageNotifications.slice(0, 20)"
            :key="notification._id"
            class="notification"
            @click="handleNotificationClick(notification)"
        >
            <v-list-item-content>
                <v-list-item-subtitle
                    v-for="row in notification.entityDisplayConfig"
                    :key="row.entity"
                    class="notification__title"
                >
                    <span>
                        <b>{{ $tc(`${row.prefix}`) | toSentenceCase | suffixWithColon }}</b>
                        <span>
                            <truncate-text-tooltip
                                :text="getDetails({ notification, rowDetails: row })"
                                :length="(sidebar ? 33 : 51) - row.prefix.length"
                            />
                        </span>
                    </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="notification__content">
                    {{ $t(`landingPageNotifications.${notification.messageKey}`) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="notification__content">
                    {{ notification.lastUpdatedOn | dateTime }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="notification__icon">
                <v-icon size="33" color="primary">
                    navigate_next
                </v-icon>
            </v-list-item-icon>
        </v-list-item>
    </v-list>
</template>

<script>
import { pick, get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import resources from '@enums/resources';

export default {
    localizationKey: 'landing.notifications',
    props: {
        sidebar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            resourceToResourceIdMap: {
                [resources.promotions]: 'promotionId',
            },
        };
    },
    computed: {
        ...mapState('clientConfig', ['notificationsConfig']),
        ...mapGetters('notifications', ['landingPageNotifications']),
    },
    methods: {
        ...mapActions('notifications', ['closeNotification']),
        async handleNotificationClick(notification) {
            const notificationConfig = this.notificationsConfig.find(
                c => c.notificationKey === notification.notificationKey
            );
            const closeOnClick = notificationConfig.closeOnClick;
            if (closeOnClick) {
                const resource = notificationConfig.resource;
                const resourceIdField = this.resourceToResourceIdMap[resource];
                const resourceId = get(notification, `details.entityIds.${resourceIdField}`);
                this.closeNotification({
                    notificationKey: notification.notificationKey,
                    resource,
                    resourceId,
                });
            }
            const routeEntityIds = pick(
                notification.details.entityIds,
                notificationConfig.routeEntityIds
            );
            this.$router.push({ name: notificationConfig.routeName, params: routeEntityIds });
        },
        getDetails({ notification, rowDetails }) {
            return get(notification, rowDetails.path);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.notifications-list {
    padding-top: 0;
    .notification {
        border-bottom: 0.1rem solid $promo-divider-colour;
        &__title {
            display: flex;
            font-size: 1.3rem;
        }
        &__content {
            white-space: initial;
            font-size: 1.2rem;
        }
        &__icon {
            align-self: auto;
        }
    }
    &__no-notifications-label {
        color: $promo-text-colour;
        font-weight: normal;
        padding: 2rem;
    }
}
</style>
