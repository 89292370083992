<template>
    <div class="nomination-templates">
        <div class="nomination-templates__column">
            <vuex-select
                :placeholder="
                    $t('planning.nominations.nominationTemplates.selectPreset') | toSentenceCase
                "
                :options="filteredNominationTemplates"
                :item-text="'name'"
                :item-value="'_id'"
                :getter="() => modelGetter({ model: 'selectModel' })"
                :setter="value => modelSetter({ value, model: 'selectModel' })"
                @change="nominationTemplatesChange"
            />
        </div>
        <div class="nomination-templates__column nomination-templates__column-name">
            <span class="nomination-templates__column--label">
                {{ $t('planning.nominations.nominationTemplates.newPreset') | toSentenceCase }}
            </span>
            <vuex-text-field
                class="nomination-templates__column--input"
                :getter="() => modelGetter({ model: 'nameModel' })"
                :setter="value => modelSetter({ value, model: 'nameModel' })"
                :ignore-parent-read-only="true"
            />
        </div>
        <div class="nomination-templates__column">
            <v-btn
                class="nomination-templates__column--save"
                primary
                depressed
                :disabled="isSaveDisabled"
                :ignore-parent-read-only="true"
                @click="saveNominationTemplate"
            >
                {{ $t('actions.save') | toSentenceCase }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { cloneDeep, size } from 'lodash';
import { mapActions, mapState } from 'vuex';
import uuid from 'uuid/v4';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    props: {
        nominationModel: {
            type: Object,
            default: null,
        },
        storeGroupOptions: {
            type: Array,
            default: () => [],
        },
        resourceOptions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectModel: null,
            nameModel: '',
        };
    },
    computed: {
        ...mapState('nominationTemplates', ['nominationTemplates']),
        isSaveDisabled() {
            return !this.nameModel.trim() || this.isNominationModelEmpty;
        },
        filteredNominationTemplates() {
            const storeGroupOptionsKeys = this.storeGroupOptions.map(
                option => option.reference.key
            );
            const resourceOptionsKeys = this.resourceOptions.map(option => option.key);

            return this.nominationTemplates.filter(nominationTemplate => {
                const isStoreGroupsApplied = nominationTemplate.storeGroups.every(storeGroup =>
                    storeGroupOptionsKeys.includes(storeGroup.key)
                );
                const isResourcesApplied = nominationTemplate.resources.every(resource =>
                    resourceOptionsKeys.includes(resource.type)
                );
                return isStoreGroupsApplied && isResourcesApplied;
            });
        },
        isNominationModelEmpty() {
            const { resources, storeGroups } = this.nominationModel;

            if (!storeGroups || (storeGroups && !storeGroups.length)) {
                return true;
            }

            if (resources && resources.length) {
                return !resources.every(resource => {
                    if (!resource.enabled) {
                        return true;
                    }
                    return resource.instances.every(instance => size(instance.storeGroups));
                });
            }
            return true;
        },
    },
    async mounted() {
        await this.fetchNominationTemplates();
    },
    methods: {
        ...mapActions('nominationTemplates', [
            'fetchNominationTemplates',
            'createNominationTemplate',
        ]),
        ...mapActions('subCampaigns', ['setStagingAreaField']),
        modelGetter({ model }) {
            return this[model];
        },
        modelSetter({ value, model }) {
            this[model] = value;
        },
        async saveNominationTemplate() {
            const nominationTemplate = {
                name: this.nameModel,
                storeGroups: this.nominationModel.storeGroups,
                resources: this.nominationModel.resources.filter(
                    // need to save resource only in case if it doesn't has enabled field
                    // or this field exist and has true value
                    resource => resource.enabled === undefined || resource.enabled
                ),
            };
            await this.createNominationTemplate({ document: nominationTemplate });
            this.nameModel = '';
        },
        nominationTemplatesChange(value) {
            const template = {
                ...this.filteredNominationTemplates.find(item => item._id === value),
            };
            const { resources, storeGroups } = template;
            resources.forEach(resource => {
                resource.instances.forEach(instance => {
                    instance.key = uuid();
                });
            });
            this.setStagingAreaField({
                namespace: this.namespace,
                fieldName: 'storeGroups',
                value: cloneDeep(storeGroups),
            });

            this.setStagingAreaField({
                namespace: this.namespace,
                fieldName: 'resources',
                value: cloneDeep(resources),
            });

            this.$emit('change');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.nomination-templates {
    display: grid;
    grid-auto-flow: column;
    padding-bottom: 0.6rem;

    &__column {
        display: flex;
        min-width: 16rem;
        margin-right: 4rem;

        &--label {
            width: 10rem;
            line-height: 3rem;
            font-size: 1.1rem;
            font-weight: bold;
        }

        &--input {
            min-width: 16rem;
        }

        &--save {
            margin-top: 0.2rem;
        }
    }

    &__column-name {
        min-width: 26rem;
        margin-right: 2rem;
    }
}
</style>
