<template functional>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 0.705L7.355 3.35L9 5L5 5L5 1L6.645 2.645L9.295 0L10 0.705ZM0.705 10L3.35 7.355L5 9L5 5L1 5L2.645 6.645L0 9.295L0.705 10Z"
            fill="#39477B"
        />
    </svg>
</template>

<script>
export default {
    name: 'CollapseAll',
};
</script>
