import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(_c, _vm) {
  return _c(
    VRadioGroup,
    {
      staticClass: "measurement",
      attrs: { row: "", mandatory: "", value: _vm.props.selectedMeasurement },
    },
    _vm._l(_vm.$options.staticData.measurements, function (measurement) {
      return _c(VRadio, {
        key: measurement,
        attrs: {
          value: measurement,
          disabled:
            _vm.props.disabled || _vm.parent.disabled || _vm.parent.isReadOnly,
          label: _vm._f("toSentenceCase")(
            _vm.parent.$t(
              `${_vm.$options.staticData.translationPath}.${measurement}`
            )
          ),
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }