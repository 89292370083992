<template>
    <div class="ag-header-with-spinner">
        {{ params.headerTitle }}
        <span v-if="loading" class="ag-header-with-spinner__spinner">
            -
            <v-icon class="fa-spin">autorenew</v-icon>
            <span>{{ params.loadingMessage }}</span>
        </span>
        <span v-if="params.isDataLimitReached()" class="alert-banner">
            <span class="alert-banner__lock-icon">
                <icon icon-name="warning" />
            </span>

            <span class="alert-banner__locked-msg">
                {{ $t(params.dataLimitReachedMessage) | toSentenceCase }}
            </span>
        </span>
    </div>
</template>

<script>
// https://www.ag-grid.com/vue-grid/component-header/
import Vue from 'vue';

export default Vue.extend({
    computed: {
        loading() {
            return this.$store.state[this.params.vuexModule].loading;
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.ag-header-with-spinner {
    padding-left: 0.7rem;

    &__spinner {
        font-size: 1.2rem;
    }

    .alert-banner {
        margin: 0;
        padding: 0;
        line-height: 2.5rem;

        &__locked {
            font-size: 1.2rem;
            font-weight: 700;
            color: $alert-banner;
        }

        &__locked-msg {
            font-size: 1.2rem;
            color: $alert-banner;
        }

        &__lock-icon {
            font-size: 2.8rem;
            svg path,
            svg text {
                fill: $alert-banner;
            }
        }

        &::v-deep {
            svg {
                height: 2.6rem;
                width: 2.6rem;
            }
        }
    }
}
</style>
