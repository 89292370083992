<template>
    <main-dialog
        ref="dialog"
        heading="parkingLot.allocatePromotion.heading"
        btn-confirm-text="actions.allocate"
        :has-activator="hasActivator"
        :confirm-btn-disabled="allocateInProgress"
        @confirm="allocatePromotionsDialogConfirm"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <icon icon-name="duplicate" small v-on="activator" @click.stop />
        </template>
        <template v-slot:default>
            <div class="allocate-promotion">
                <p class="allocate-promotion__description">
                    {{ $t('parkingLot.allocatePromotion.description') | toSentenceCase }}
                </p>
                <v-text-field
                    class="rtls-text-field rtls-text-field--grey pb-2"
                    disabled
                    :label="modalLabel"
                />
                <v-text-field
                    class="rtls-text-field rtls-text-field--grey pb-2"
                    disabled
                    :label="campaign.name"
                />
                <v-text-field
                    class="rtls-text-field rtls-text-field--grey pb-2"
                    disabled
                    :label="subCampaign.name"
                />
                <v-text-field
                    class="rtls-text-field rtls-text-field--grey pb-2"
                    disabled
                    :label="scenario.name"
                />
            </div>

            <div v-if="requestInProgress" class="in-progress--message">
                <label class="in-progress__message">{{
                    $t('parkingLot.allocatePromotion.inProgressMessage') | toSentenceCase
                }}</label>
                <spinner-dynamic />
            </div>
        </template>
    </main-dialog>
</template>

<script>
import to from 'await-to-js';
import { mapActions, mapGetters, mapState } from 'vuex';
import { get } from 'lodash';

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        campaign: {
            type: Object,
            required: true,
        },
        subCampaign: {
            type: Object,
            required: true,
        },
        scenario: {
            type: Object,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: true,
        },
        promotionIds: {
            type: Array,
            required: true,
        },
        successCallback: {
            type: Function,
            required: false,
        },
    },
    data() {
        return {
            allocateInProgress: false,
        };
    },
    computed: {
        ...mapState('promotions', ['loading']),
        ...mapGetters('weeks', ['getWeekByDate']),
        requestInProgress() {
            return this.loading;
        },
        modalLabel() {
            const startWeek = this.$options.filters.weekNumberShorthandClient(
                get(this.getWeekByDate(this.scenario.startDate), 'weekOfYear')
            );
            const lastWeek = this.$options.filters.weekNumberShorthandClient(
                get(this.getWeekByDate(this.scenario.endDate), 'weekOfYear')
            );
            const startDate = this.$options.filters.dateShorthand(this.scenario.startDate);
            const endDate = this.$options.filters.dateShorthand(this.scenario.endDate);
            return `${startWeek} - ${lastWeek} | ${startDate} - ${endDate}`;
        },
    },
    methods: {
        ...mapActions('promotions', ['movePromotionsToScenario']),
        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.$refs.dialog.close();
        },
        async allocatePromotionsDialogConfirm() {
            this.allocateInProgress = true;
            const [error, results] = await to(
                this.movePromotionsToScenario({
                    scenario: this.scenario,
                    promotionIds: this.promotionIds,
                })
            );

            if (!error) {
                this.closeDialog();
                if (this.successCallback) this.successCallback(results);
            }
            this.allocateInProgress = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.allocate-promotion {
    font-size: 1.2rem;

    &__description {
        padding: 1rem 0;
    }
}

.in-progress__message {
    font-size: 1.3rem;
    color: $promo-text-colour;
    margin: 1rem;

    .spinner {
        width: 1.4rem;
        height: 1.4rem;
    }
}

::v-deep {
    .v-text-field__slot {
        label {
            padding-left: 1rem;
        }
    }
}
</style>
