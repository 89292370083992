<template>
    <div class="btn-checkbox-wrapper">
        <v-btn
            v-if="!params.data.promotionId && !isCategoryOrStoreWidePromotion(params.data.id)"
            x-small
            depressed
            @click="btnClickedHandler()"
        >
            {{ $t('subCampaignAllocation.forecastsModal.view') | toSentenceCase }}
        </v-btn>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import pogScope from '@enums/product-offer-group-scope';

export default Vue.extend({
    computed: {
        ...mapState('clientConfig', ['hierarchyConfig']),
    },
    methods: {
        btnClickedHandler() {
            const isUnit = this.params.data.hierarchy.length <= this.hierarchyConfig.categoryLevel;
            this.params.clicked(this.params.data.id, isUnit);
        },
        isCategoryOrStoreWidePromotion(scope) {
            return scope === pogScope.storeWide || scope === pogScope.categoryWide;
        },
    },
});
</script>

<style lang="scss" scoped>
.btn-checkbox-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
