'use strict';

module.exports = {
    categories: 'categories',
    tags: 'tags',
    suppliers: 'suppliers',
    clientState: 'clientState',
    storeGroups: 'storeGroups',
    year: 'year',
    brands: 'brands',
};
