import workflowEntities from '@enums/workflow-entities';
import workflowTasks from '@enums/workflow-tasks';
import FunctionProp from '@/js/utils/component-utils';

export default [
    {
        dataClasses: ['scenario-viewer__data--first-item'],
        field: 'isFavourite',
        component: {
            name: 'favourite-icon',
            contextualProps: {
                isFavourite: 'isFavourite',
            },
        },
    },
    {
        field: 'date',
        component: {
            name: 'losenge-date-display',
            defaultProp: null,
            contextualProps: ['startDate', 'endDate'],
            props: {
                compact: true,
            },
        },
    },
    {
        field: 'order',
    },
    {
        field: 'name',
    },
    {
        field: 'promotionCount',
        functionName: 'getPromotionCount',
        functionParam: 'promotionCount',
    },
    {
        field: 'effectivenessRatings',
        component: {
            name: 'promotion-rag-colours',
            props: {
                displayTotal: false,
                vertical: false,
            },
            contextualProps: {
                promotionRagColourCounts: 'effectivenessRatings',
            },
        },
    },
    {
        field: 'productCount',
        functionName: 'getProductCount',
        functionParam: 'productCount',
    },
    {
        field: 'acceptAllNotifications',
        onlyShowWhenSelected: true,
        onlyFavoriteScenariosComponent: true,
        component: {
            name: 'accept-all-notifications',
            props: {
                entity: 'scenarios',
            },
            contextualProps: {
                entityId: '_id',
                applyingNotifications: 'applyingNotifications',
            },
            computedProps: [
                {
                    name: 'isChild',
                    executor: 'isChild',
                },
                {
                    name: 'hasNotifications',
                    executor: 'hasNotifications',
                },
                {
                    name: 'isExecutingPromotionsWithPendingNotifications',
                    executor: 'isExecutingPromotionsWithPendingNotifications',
                },
                {
                    name: 'disabled',
                    executor: 'isDisabled',
                },
            ],
        },
    },
    {
        field: 'acceptAllNotificationsReportDialog',
        onlyShowWhenSelected: true,
        onlyFavoriteScenariosComponent: true,
        component: {
            name: 'accept-all-notifications-report-dialog',
            computedProps: [
                {
                    name: 'isChild',
                    executor: 'isChild',
                },
            ],
        },
    },
    {
        field: 'submitPromotions',
        onlyShowWhenSelected: true,
        onlyFavoriteScenariosComponent: true,
        component: {
            name: 'task-button',
            props: {
                task: workflowTasks.draftSubmit,
                entityType: workflowEntities.promotion,
                multipleEntities: true,
            },
            contextualProps: {
                subCampaignId: 'subCampaignId',
                categories: 'categories',
            },
            computedProps: [
                {
                    name: 'entityIds',
                    executor: 'promotionIdsForExecution',
                },
                {
                    name: 'disabled',
                    executor: 'isDisabled',
                },
            ],
            events: {
                click: {
                    method: 'onSubmitClick',
                    params: [],
                },
                'action-completed': {
                    method: 'onActionCompleted',
                    params: [],
                },
            },
        },
    },
    {
        icon: 'locked',
        propPath: 'isLocked',
        props: {
            size: '1.6rem',
        },
    },
    {
        icon: 'eye-closed',
        propPath: 'isPrivate',
        props: {
            size: '1.6rem',
        },
    },
    {
        field: 'editScenario',
        propPath: 'subCampaignContext',
        component: {
            name: 'scenario-form-dialog',
            props: {
                editMode: true,
            },
            fullContextAsPropName: 'context',
            computedProps: [
                {
                    name: 'editContext',
                    executor: 'scenario',
                },
                {
                    name: 'makeReadOnly',
                    executor: 'scenarioDisabled',
                },
                {
                    name: 'readOnlyReason',
                    executor: 'scenarioDisabledReason',
                },
            ],
            functionProps: [new FunctionProp('key', [], 'getScenarioFormKey')],
            events: {
                close: {
                    method: 'scenarioFormClosed',
                    params: [],
                },
            },
        },
    },
    {
        field: 'sideBarIcons',
        contentClasses: ['scenario-viewer__grid-cell--sidebar'],
        component: {
            name: 'scenario-sidebar-icons',
            props: {
                compact: false,
            },
            contextualProps: {
                incrementalSales: 'incrementalSales',
                salesEfficiency: 'salesEfficiency',
                incrementalMargin: 'incrementalMargin',
                marginEfficiency: 'marginEfficiency',
            },
            fullContextAsPropName: 'scenario',
            computedProps: [
                {
                    name: 'isExpanded',
                    executor: 'isSelected',
                },
            ],
        },
    },
];
