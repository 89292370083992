<template>
    <div class="filter-bar">
        <div class="filter-bar__grouping">
            <!-- Note: the select already has a label so that's why not specifying a for attr on this label -->
            <label class="filter-bar__label">
                {{ $t('general.labels.filter') | toSentenceCase }}:
            </label>
            <div
                v-for="(filter, index) in filters"
                :key="filter.filterId"
                class="filter-group__filters"
            >
                <div class="flex-row">
                    <vuex-select
                        class="filter-bar__parent-filter"
                        :placeholder="$t('placeholders.selectFilter') | toSentenceCase"
                        :options="getFilterOptions(index)"
                        item-value="filterKey"
                        item-text="filterText"
                        :getter="() => filterGetter({ index, path: 'filterKey' })"
                        :setter="key => filterKeySetter({ index, key })"
                    />

                    <component
                        :is="componentMap[filter.filterType]"
                        v-if="filter.filterKey"
                        :placeholder="
                            $t(`placeholders.selectOption.${filter.filterKey}`) | toSentenceCase
                        "
                        :options="getFilterValueOptionsByFilterId({ filterKey: filter.filterKey })"
                        item-value="value"
                        item-text="text"
                        :getter="() => filterGetter({ index, path: 'filterValue' })"
                        :setter="
                            value =>
                                filterValueSetter({
                                    index,
                                    value,
                                    filterId: filter.filterId,
                                    filterKey: filter.filterKey,
                                })
                        "
                        :multiple="isComponentMultiple({ filterKey: filter.filterKey })"
                        :chips="isComponentMultiple({ filterKey: filter.filterKey })"
                        horizontal
                        deletable-chips
                        autocomplete
                        invalid-selection-text="general.info.invalidFilterSelection"
                        :finite-list="finiteList"
                        :max-items-in-list="maxItemsInList"
                        :sort-results="shouldSortResults({ filterKey: filter.filterKey })"
                        @change="
                            onFilterValueChange({ filterKey: filter.filterKey, event: $event })
                        "
                    />

                    <v-btn
                        v-if="showRemoveFilterBtn"
                        class="filter-bar__cancel-button"
                        icon
                        :disabled="isDeleteDisabled(index)"
                        @click="removeFilter(index)"
                    >
                        <icon icon-name="cancel" small icon-colour-type="primary-path" />
                    </v-btn>
                </div>
            </div>
            <div class="filter-bar__add-button">
                <create-new-button background :disabled="!canAddFilter" @createNew="addFilter" />
            </div>
        </div>
        <vuex-search-field v-if="showSearchField" class="filter-bar__search-field" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { includes, some, has, get, find, last, isNil, isEmpty } from 'lodash';
import { fieldTypes } from '@enums/vuex-form';
import filterEnums from '@enums/filters';

export default {
    props: {
        showSearchField: {
            type: Boolean,
            default: false,
        },
        finiteList: {
            type: Boolean,
            default: true,
        },
        maxItemsInList: {
            type: Number,
            default: 1,
        },
        maxFilterCount: {
            type: Number,
            default: 2,
        },
    },
    data() {
        return {
            componentMap: {
                [fieldTypes.select]: 'vuex-select',
            },
        };
    },
    computed: {
        ...mapState(['globalFilterDefinitions', 'globalFilters']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('context', ['userCategories']),
        filters() {
            return this.globalFilters;
        },

        filterDefinitions() {
            return this.globalFilterDefinitions;
        },

        canAddFilter() {
            return (
                this.filters.length < this.maxFilterCount &&
                !isNil(get(last(this.filters), 'filterValue'))
            );
        },

        showRemoveFilterBtn() {
            return this.filters.length > 1 || this.filters[0].filterKey !== null;
        },

        filterKeysInUse() {
            return this.filters.map(filter => filter.filterKey);
        },
    },

    methods: {
        ...mapActions('scenarios', ['setSelectedScenarioId']),
        ...mapActions('promotions', [
            'fetchWeeklyMetrics',
            'fetchSelectedSubcampaignPromotionAggregations',
        ]),
        ...mapActions('subCampaigns', ['setSelectedSubCampaignId']),
        ...mapActions([
            'resetGlobalFilters',
            'removeGlobalFilter',
            'addGlobalFilter',
            'globalFilterKeySetter',
            'globalFilterValueSetter',
        ]),

        filterGetter({ index, filterId, path }) {
            const filter = filterId ? find(this.filters, { filterId }) : this.filters[index];
            return get(filter, path, null);
        },

        getFilterValueOptionsByFilterId({ filterKey }) {
            const filterDefinition = find(this.filterDefinitions, { filterKey });
            return this.$store.getters[filterDefinition.optionsRootGetter].map(option => ({
                text: option[filterDefinition.filterValueMapping.text],
                value: option[filterDefinition.filterValueMapping.value],
            }));
        },
        isComponentMultiple({ filterKey }) {
            return !find(this.filterDefinitions, { filterKey }).isSingle;
        },

        getFilterOptions(index) {
            const filterKeyAtIndex = get(this.filters, [index, 'filterKey'], null);
            if (!filterKeyAtIndex) {
                return this.filterDefinitions.filter(
                    def => !this.filterKeysInUse.includes(def.filterKey)
                );
            }
            return this.filterDefinitions;
        },

        addFilter() {
            return this.addGlobalFilter();
        },

        filterKeySetter({ index, filterId, key }) {
            this.globalFilterKeySetter({ index, filterId, key });
        },

        removeFilter(index) {
            const filterId = get(this.filters, [index, 'filterId']);
            const filterKey = get(this.filters, [index, 'filterKey']);

            if (this.filters.length === 1) {
                this.resetGlobalFilters({ filterId });
            } else {
                this.removeGlobalFilter({ filterId, index });
            }

            this.filterChange({ filterKey });
        },

        async filterValueSetter({ index, filterId, value, filterKey }) {
            await this.setFilterValue({ index, value, filterId });
            this.filterChange({ filterKey });
        },

        filterChange({ filterKey }) {
            // if filter for categories or tags is set, check is selected subCampaign contains any filtered category or tag,
            const filterDefinition = find(this.filterDefinitions, { filterKey });
            if (!filterDefinition) return;
            const updatedFilter = this.$store.getters[`${filterDefinition.module}/specifiedFilter`];
            const objectKey = get(filterDefinition, 'objectKey', '');
            if (
                this.selectedSubCampaign &&
                this.selectedSubCampaign[filterKey] !== undefined &&
                has(updatedFilter, filterKey) &&
                !some(this.selectedSubCampaign[filterKey], item =>
                    includes(updatedFilter[filterKey], get(item, objectKey, item))
                )
            ) {
                // if sub-campaign is hidden, set selectedSubCampaignId to null
                this.setSelectedSubCampaignId(null);
                this.setSelectedScenarioId(null);
            }

            this.fetchWeeklyMetrics();
            if (!isEmpty(this.selectedSubCampaign)) {
                this.fetchSelectedSubcampaignPromotionAggregations();
            }
        },

        async setFilterValue({ index, filterId, value }) {
            await this.globalFilterValueSetter({ index, filterId, value });
        },

        // an empty method to redefine it in child component
        onFilterValueChange() {},

        isDeleteDisabled(index) {
            return (
                this.userCategories.length === 1 &&
                get(this.filters, [index, 'filterKey']) === filterEnums.categories
            );
        },

        shouldSortResults({ filterKey }) {
            const filterDefinition = find(this.filterDefinitions, { filterKey });
            return isNil(filterDefinition.sortResults) || filterDefinition.sortResults;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.filter-bar {
    @include promo-component-border($top: true);

    width: 100%;
    padding: 1rem;
    display: flex;
    background-color: $promo-grey-blue-tint;

    &__grouping {
        display: flex;
        flex: 1;
        align-items: baseline;

        & > * {
            margin-right: 1rem;
        }
    }

    &__parent-filter {
        margin-right: 1rem;
    }

    &__label {
        color: $filter-bar-text-colour;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 300;
    }

    &__main-select {
        flex: 0 1 20rem;
    }

    &__search-field {
        flex: 0 1 24rem;
        margin-left: auto;
    }

    &__cancel-button {
        padding-top: 0.8rem;
        margin-left: 0.8rem;
    }

    &__add-button {
        line-height: 2.8rem;
    }
}
</style>
