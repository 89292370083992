<template>
    <div class="vuex-percentage-input" :class="cssClass">
        <v-text-field
            v-model="percentage"
            :disabled="isDisabled"
            reverse
            class="rtls-text-field rtls-text-field--white"
            :rules="rules"
            validate-on-blur
            type="text"
            :placeholder="placeholderValue"
            @keypress="validateNumber"
            @paste="validateNumberOnPaste"
            @change="updateModel"
        />
        <div
            class="vuex-percentage-input__percentage-symbol"
            :class="isDisabled ? 'vuex-percentage-input__percentage-symbol--disabled' : ''"
        >
            %
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';
import { isNil } from 'lodash';
import vuexComponentMixin from '../../mixins/vuex-component';
import validateNumberInputMixin from '../../mixins/validate-number-input';

export default {
    mixins: [vuexComponentMixin, validateNumberInputMixin],
    props: {
        disabled: Boolean,
        cssClass: String,
        plain: Boolean,
        dynamicPlaceholder: String,
    },
    data() {
        return {
            percentageInput: null,
            placeholderValue: this.$t('placeholders.noText'),
        };
    },
    computed: {
        percentage: {
            get() {
                if (isNil(this.model)) return null;

                // null/undefined shouldn't be formatted to 0
                return this.$n(
                    'numbers.default.numberWithDecimals',
                    this.plain ? this.model : this.model * 100
                );
            },
            set(newValue) {
                if (isNil(newValue) || newValue === '') {
                    this.percentageInput = null;
                } else {
                    this.percentageInput = this.plain
                        ? // eslint-disable-next-line no-underscore-dangle
                          numeral(newValue)._value
                        : // eslint-disable-next-line no-underscore-dangle
                          numeral(newValue)._value / 100;
                }
            },
        },

        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },
    watch: {
        dynamicPlaceholder: {
            immediate: true,
            handler() {
                this.placeholderValue = this.dynamicPlaceholder || this.$t('placeholders.noText');
            },
        },
    },
    created() {
        this.percentageInput = this.model;
    },
    methods: {
        updateModel() {
            this.model = this.percentageInput;
            this.$emit('change', this.percentageInput);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.vuex-percentage-input {
    @include flex-row;

    &__percentage-symbol {
        font-weight: normal;
        @include flex-center;
        height: 2.9rem;
        padding: 0 0.5rem;

        color: $promo-white;
        background-color: $promo-primary-colour;

        &--disabled {
            background-color: $promo-grey-dark;
            font-weight: normal;
        }
    }

    .error--text {
        font-weight: normal;
    }
}
</style>
