import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '../utils/string-utils';

const numericString = (value, message) => {
    const regex = /^\d+$/;
    return (
        regex.test(value) ||
        message ||
        toSentenceCase(i18n.t('validation.common.mustContainNumbers'))
    );
};

export default numericString;
