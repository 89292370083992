<template functional>
    <svg
        width="13px"
        height="12px"
        viewBox="0 0 13 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Warning</title>
        <g id="------Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icons" transform="translate(-606.000000, -192.000000)" fill="#ca7c00">
                <g id="warning" transform="translate(604.666498, 190.000000)">
                    <path
                        id="Icon-/-warning-Copy"
                        d="M2.78950314,13.5 L13.2104969,13.5 C13.8188788,13.5 14.191758,12.8280888 13.8973797,12.2783434 L8.67707021,2.91230912 C8.38269186,2.36256363 7.61730814,2.36256363 7.32292979,2.91230912 L2.10262031,12.2783434 C1.80824196,12.8077279 2.1811212,13.5 2.78950314,13.5 Z M8,10.8448276 C8.41129032,10.8448276 8.75,11.1874305 8.75,11.6034483 C8.75,12.0194661 8.41129032,12.362069 8,12.362069 C7.58870968,12.362069 7.25,12.0194661 7.25,11.6034483 C7.25,11.2119021 7.58870968,10.8448276 8,10.8448276 Z M7.99936986,5.53448276 C8.42401278,5.53448276 8.77371872,5.78735632 8.74873972,6.09441708 L8.62384474,9.25533662 C8.62384474,9.50821018 8.3240968,9.70689655 7.99936986,9.70689655 C7.64966393,9.70689655 7.37489498,9.50821018 7.37489498,9.25533662 L7.25,6.09441708 C7.25,5.78735632 7.57472694,5.53448276 7.99936986,5.53448276 Z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Warning',
};
</script>
