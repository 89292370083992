import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VCombobox, {
    ref: "combobox",
    staticClass: "rtls-combobox",
    attrs: {
      items: _vm.options,
      "menu-props": { bottom: true, offsetY: true },
      "search-input": _vm.searchInput,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      dense: "",
      "allow-overflow": "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchInput = $event
      },
      "update:search-input": function ($event) {
        _vm.searchInput = $event
      },
      change: function ($event) {
        return _vm.onChange()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              VListItem,
              [
                _c(
                  VListItemContent,
                  [
                    _c(VListItemTitle, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("general.combobox.noResultsPlaceholder", {
                                searchInput: _vm.searchInput,
                              })
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }