import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.allowAlternativeMechanics } },
        [
          _vm.isAlternativeMechanicsVisible
            ? _c("alternative-mechanics", {
                attrs: {
                  namespace: _vm.namespace,
                  disabled: _vm.isReadOnly || _vm.splitPromotion,
                  "disabled-generation": _vm.isPromotionInPast,
                },
                on: {
                  "set-offer-mechanics": _vm.setGeneratedOfferMechanics,
                  "get-alternative-mechanics": _vm.getAlternativeMechanics,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "offer-mechanic-preset" }, [
        _c("div", { staticClass: "offer-mechanic-preset__offer-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("labels.offer"))) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "offer-mechanic-preset__preset-selector" },
          [
            _c(
              "div",
              { staticClass: "offer-mechanic-preset-selector__preset--text" },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(_vm.$tkey("labels.preset"))
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("vuex-select", {
              staticClass:
                "offer-mechanic-preset__preset-selector--select-preset",
              attrs: {
                getter: () => _vm.getOfferMechanicTemplate(),
                setter: (value) => _vm.setOfferMechanicToPreset(value),
                options: _vm.offerMechanicPresetTemplateOptions,
                placeholder: _vm._f("toSentenceCase")(
                  _vm.$tkey("placeholders.selectPreset")
                ),
                disabled: _vm.isReadOnly || _vm.splitPromotion,
                "item-text": "text",
                "item-value": "value",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "offer-mechanic", attrs: { id: "offer-mechanic" } },
        [
          _c("div", { staticClass: "offer-mechanic__offer" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    !_vm.isPresetTemplate ? _vm.$tkey("labels.custom") : ""
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offer-mechanic__tiers" },
            _vm._l(_vm.offerMechanic.tiers, function (tier, tierIndex) {
              return _c(
                "div",
                { key: tierIndex, staticClass: "offer-mechanic__tier" },
                [
                  _vm.isTierExpanded(tierIndex)
                    ? _c(
                        "div",
                        [
                          _vm.conditions[tierIndex].length
                            ? _c(
                                "div",
                                { staticClass: "offer-mechanic__conditions" },
                                _vm._l(
                                  _vm.conditions[tierIndex],
                                  function (rewardRequirement, conditionIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: conditionIndex,
                                        staticClass:
                                          "offer-mechanic__conditions--inner-container",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "offer-mechanic__reward-requirement",
                                          },
                                          _vm._l(
                                            rewardRequirement.options,
                                            function (option, optionIndex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: optionIndex,
                                                  staticClass: "flex-row",
                                                },
                                                [
                                                  _c(
                                                    "reward-requirement-option",
                                                    {
                                                      attrs: {
                                                        "reward-requirement":
                                                          option,
                                                        "tier-index": tierIndex,
                                                        "condition-index":
                                                          conditionIndex,
                                                        "change-option-to-drop-down-options":
                                                          _vm.changeOptionToDropDownOptions(
                                                            tierIndex,
                                                            conditionIndex,
                                                            optionIndex
                                                          ),
                                                        "additional-reward-drop-down-options":
                                                          _vm.additionalRewardDropDownOptions(
                                                            option.type
                                                          ),
                                                        "should-prefix-with-and":
                                                          _vm.shouldPrefixWithAnd(
                                                            tierIndex,
                                                            conditionIndex,
                                                            optionIndex
                                                          ),
                                                        "is-reward-requirement-editable":
                                                          _vm.isConditionEditable(
                                                            tierIndex,
                                                            conditionIndex
                                                          ),
                                                        "is-reward-requirement-type-editable":
                                                          _vm.isConditionTypeEditable(
                                                            tierIndex,
                                                            conditionIndex
                                                          ),
                                                      },
                                                      on: {
                                                        remove:
                                                          _vm.removeOption,
                                                        update:
                                                          _vm.updateOption,
                                                        updateAdditionalReward:
                                                          _vm.updateAdditionalReward,
                                                        save: function (
                                                          $event
                                                        ) {
                                                          return _vm.mapConditionsToOfferMechanic(
                                                            { tierIndex }
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  optionIndex ===
                                                  rewardRequirement.options
                                                    .length -
                                                    1
                                                    ? _c(
                                                        VMenu,
                                                        {
                                                          attrs: {
                                                            bottom: "",
                                                            "offset-y": "",
                                                            disabled:
                                                              _vm.isReadOnly ||
                                                              _vm.splitPromotion,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      VBtn,
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "offer-mechanic__drop-down offer-mechanic__drop-down--no-label",
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "offer-mechanic-icon-wrapper",
                                                                            class:
                                                                              {
                                                                                disabled:
                                                                                  _vm.isReadOnly ||
                                                                                  _vm.splitPromotion,
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-dots-vertical"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c(
                                                            VList,
                                                            [
                                                              _vm.isConditionTypeEditable(
                                                                tierIndex,
                                                                conditionIndex
                                                              )
                                                                ? _vm._l(
                                                                    _vm.addToClearDropDownOptions(
                                                                      tierIndex,
                                                                      conditionIndex
                                                                    ),
                                                                    function (
                                                                      dropDownOption
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: dropDownOption,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.addOption(
                                                                                  tierIndex,
                                                                                  conditionIndex,
                                                                                  dropDownOption
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "toSentenceCase"
                                                                                )(
                                                                                  _vm.$tkey(
                                                                                    `rewardRequirementOptions.${dropDownOption}`
                                                                                  )
                                                                                )
                                                                              ) +
                                                                              "\n                                                "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.canAddTier()
                                                                ? _c(
                                                                    VListItem,
                                                                    {
                                                                      staticClass:
                                                                        "offer-mechanic__dropdown-icon-item",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addTier(
                                                                              tierIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-name":
                                                                                "add",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "offer-mechanic__dropdown-icon-label",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "toSentenceCase"
                                                                                )(
                                                                                  _vm.$tkey(
                                                                                    "buttons.addTier"
                                                                                  )
                                                                                )
                                                                              ) +
                                                                              "\n                                                "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              tierIndex !== 0
                                                                ? _c(
                                                                    VListItem,
                                                                    {
                                                                      staticClass:
                                                                        "offer-mechanic__dropdown-icon-item",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeTier(
                                                                              tierIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-name":
                                                                                "cancel",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "offer-mechanic__dropdown-icon-label",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "toSentenceCase"
                                                                                )(
                                                                                  _vm.$tkey(
                                                                                    "buttons.removeTier"
                                                                                  )
                                                                                )
                                                                              ) +
                                                                              "\n                                                "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.isConditionTypeEditable(
                                                                tierIndex,
                                                                conditionIndex
                                                              )
                                                                ? [
                                                                    _c(
                                                                      VDivider
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      VListItem,
                                                                      {
                                                                        staticClass:
                                                                          "offer-mechanic__dropdown-icon-item",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeCondition(
                                                                                tierIndex,
                                                                                conditionIndex
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "icon-name":
                                                                                  "cancel",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "offer-mechanic__dropdown-icon-label",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                        " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "toSentenceCase"
                                                                                  )(
                                                                                    _vm.$tkey(
                                                                                      "buttons.removeCondition"
                                                                                    )
                                                                                  )
                                                                                ) +
                                                                                "\n                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "offer-mechanic__product-offer-group-inputs",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "flex-row" },
                                              [
                                                _vm.shouldShowProductOfferGroupDropDown(
                                                  rewardRequirement
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "offer-mechanic__product-offer-group-drop-down",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "offer-mechanic__on-label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                            " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "toSentenceCase"
                                                                  )(
                                                                    _vm.$tkey(
                                                                      "labels.on"
                                                                    )
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("vuex-select", {
                                                          staticClass:
                                                            "offer-mechanic__select",
                                                          attrs: {
                                                            getter: () =>
                                                              rewardRequirement.productOfferGroup,
                                                            setter: (value) =>
                                                              _vm.setProductOfferGroupOnCondition(
                                                                value,
                                                                conditionIndex,
                                                                tierIndex
                                                              ),
                                                            options:
                                                              _vm.getProductOfferGroupOptions(
                                                                tierIndex,
                                                                rewardRequirement.productOfferGroup,
                                                                conditionIndex
                                                              ),
                                                            placeholder: _vm._f(
                                                              "toSentenceCase"
                                                            )(
                                                              _vm.$tkey(
                                                                "placeholders.selectProductOfferGroup"
                                                              )
                                                            ),
                                                            disabled:
                                                              !_vm.isConditionTypeEditable(
                                                                tierIndex,
                                                                conditionIndex
                                                              ),
                                                            "item-text": "text",
                                                            "item-value":
                                                              "value",
                                                            clearable: "",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.shouldShowProductOfferGroupOptionalCheckBox(
                                                  {
                                                    conditionIndex,
                                                    tierIndex,
                                                  }
                                                )
                                                  ? _c("vuex-checkbox", {
                                                      staticClass:
                                                        "offer-mechanic__optional-check-box",
                                                      attrs: {
                                                        getter: () =>
                                                          rewardRequirement.isOptionalOfferGroup,
                                                        setter: (value) =>
                                                          _vm.setIsOptionalOfferGroup(
                                                            value,
                                                            conditionIndex,
                                                            tierIndex
                                                          ),
                                                        disabled:
                                                          _vm.shouldDisableIsOptionalOfferGroupCheckBox(
                                                            {
                                                              tierIndex,
                                                              conditionIndex,
                                                            }
                                                          ),
                                                        label: _vm._f(
                                                          "toSentenceCase"
                                                        )(
                                                          _vm.$tkey(
                                                            "labels.optional"
                                                          )
                                                        ),
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "offer-mechanic__errors",
                                              },
                                              [
                                                _vm.getValidationErrors(
                                                  tierIndex,
                                                  conditionIndex
                                                ).length
                                                  ? _c(
                                                      VTooltip,
                                                      {
                                                        attrs: {
                                                          "z-index": "400",
                                                          top: "",
                                                          "max-width": 500,
                                                          "content-class":
                                                            "error-tooltip",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "icon-name":
                                                                                "warning",
                                                                            },
                                                                        },
                                                                        "icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.getValidationErrors(
                                                            tierIndex,
                                                            conditionIndex
                                                          ),
                                                          function (
                                                            error,
                                                            errorIndex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: errorIndex,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                            " +
                                                                    _vm._s(
                                                                      error
                                                                    ) +
                                                                    "\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            VMenu,
                            {
                              attrs: { bottom: "", "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "offer-mechanic__drop-down offer-mechanic__drop-down--add-condition",
                                                class: {
                                                  "offer-mechanic__drop-down--with-conditions":
                                                    _vm.conditions[tierIndex]
                                                      .length,
                                                },
                                                attrs: {
                                                  disabled:
                                                    _vm.shouldDisableAddConditionDropDown(
                                                      { tierIndex }
                                                    ),
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "offer-mechanic-icon-wrapper",
                                              },
                                              [
                                                !_vm.conditions[tierIndex]
                                                  .length
                                                  ? _c(
                                                      VIcon,
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        mdi-dots-vertical\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      VIcon,
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        add\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            !_vm.conditions[tierIndex].length
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "toSentenceCase"
                                                        )(
                                                          _vm.$tkey(
                                                            "buttons.addCondition"
                                                          )
                                                        )
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                VList,
                                _vm._l(
                                  _vm.getConditionOptions(tierIndex),
                                  function (option) {
                                    return _c(
                                      VListItem,
                                      {
                                        key: option.key,
                                        on: {
                                          click: function ($event) {
                                            return _vm.addCondition(
                                              tierIndex,
                                              option.key
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(option.value) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          tierIndex === 0
                            ? _c(
                                "div",
                                { staticClass: "offer-mechanic__restrictions" },
                                [
                                  _c(VDivider),
                                  _vm._v(" "),
                                  _c("offer-restrictions", {
                                    attrs: {
                                      "vuex-module": _vm.vuexModule,
                                      namespace: _vm.namespace,
                                      context: _vm.context,
                                      "form-ref": _vm.formRef,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.offerMechanic.tiers.length > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "offer-mechanic__expand",
                          class: {
                            "offer-mechanic__expand--collapsed":
                              !_vm.isTierExpanded(tierIndex),
                          },
                        },
                        [
                          _c("main-expand-button", {
                            attrs: {
                              "is-expanded": _vm.isTierExpanded(tierIndex),
                              horizontal: "",
                              background: "",
                            },
                            on: {
                              expand: function ($event) {
                                return _vm.toggleTierCollapse(tierIndex)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(tierIndex + 1))]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }