<template>
    <div
        class="pagination-container"
        :class="{ 'pagination-container__disabled': !selectedResourceDefinition }"
    >
        <span class="pagination-container__label">
            {{ $t('preparation.templates.numberOfPages') | toSentenceCase }}
        </span>
        <vuex-select
            v-if="hasAccesToPageCountDropDown"
            class="pagination-container__select"
            :options="pages"
            :getter="pagesGetter"
            :setter="pagesSetter"
            :disabled="!selectedResourceDefinition"
            :placeholder="'-'"
        />
        <div v-else class="pagination-container__no-of-pages">
            {{ selectedResourceDefinition.pageCount }}
        </div>
        <span class="pagination-container__label">
            {{ $t('preparation.templates.page') | toSentenceCase }}
        </span>
        <pagination
            v-model="selectedPage"
            class="pagination-container__pagination"
            :length="pageCount"
            :disabled="isPaginationDisabled"
            :colored-pages="completedPages"
            :disabled-pages="disabledPages"
            :highlighted-pages="highlightedPages"
            is-basic-navigate-button
            use-arrow-as-pagination
        />

        <confirm-dialog
            ref="dialog_confirm"
            class="confirm-dialog"
            :has-activator="false"
            :question-text="$t('preparation.templates.confirmModal.heading')"
            :warning-text="$t('preparation.templates.confirmModal.message')"
            :action-text="$t('preparation.templates.confirmModal.confirm')"
            :auto-close-on-confirm="false"
            @confirm="updatePageCount"
            @close="cancelUpdatePageCount"
        />
    </div>
</template>

<script>
import { range, map, toNumber } from 'lodash';
import { mapMutations, mapState, mapGetters } from 'vuex';
import categoryAllocationsTypes from '@enums/category-allocations-types';
import { canEditChannel } from '@enums/feature-flags';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    data() {
        return {
            btnConfirmDialogCancel: 'changePagesConfirmDialogCancelId',
            previousPageCount: 0,
            newPageCount: 0,
        };
    },
    computed: {
        ...mapState('subCampaigns', [
            'selectedResourceDefinitionKey',
            'selectedResourceDefinitionPageNumber',
            'highlightedCategory',
        ]),
        ...mapState('clientConfig', ['generalConfig', 'toggleLogic']),
        ...mapGetters('subCampaigns', [
            'selectedResourceDefinition',
            'isTemplatesSection',
            'isCategoriesSection',
            'isPromotionsSection',
        ]),
        ...mapGetters('clientConfig', ['templateLayoutsByKey']),

        hasAccesToPageCountDropDown() {
            return this.toggleLogic[canEditChannel];
        },

        pages() {
            return map(range(1, this.generalConfig.maxPagesForPromoResource + 1), page =>
                page.toString()
            );
        },
        selectedResourceDefinition() {
            return this.model.find(rd => rd.key === this.selectedResourceDefinitionKey);
        },
        pageCount() {
            // show 2 pages by default
            if (this.selectedResourceDefinition) {
                return toNumber(this.selectedResourceDefinition.pageCount) || 2;
            }
            return 2;
        },

        isPaginationDisabled() {
            return (
                !this.selectedResourceDefinition ||
                !(this.selectedResourceDefinition && !!this.selectedResourceDefinition.pageCount)
            );
        },
        highlightedPages() {
            const result = [];
            if (this.selectedResourceDefinition && this.highlightedCategory) {
                this.selectedResourceDefinition.pages.forEach(page => {
                    if (
                        page.assignment.find(item => item.categoryKey === this.highlightedCategory)
                    ) {
                        result.push(page.pageNumber);
                    }
                });
            }

            return result;
        },

        selectedPage: {
            get() {
                return this.selectedResourceDefinitionPageNumber;
            },
            set(value) {
                this.setSelectedResourceDefinitionPageNumber(value);
                this.globalEmit('change-selected-page', value);
            },
        },

        completedPages() {
            if (this.selectedResourceDefinition && this.selectedResourceDefinition.pageCount) {
                // create array of page numbers for pages with layoutTemplate
                return this.selectedResourceDefinition.pages.reduce((completedPages, page) => {
                    if (this.isTemplatesSection && page.layoutTemplate) {
                        completedPages.push(page.pageNumber);
                    } else if (
                        this.isCategoriesSection &&
                        this.isAssignmentComplete(page, 'categoryKey')
                    ) {
                        completedPages.push(page.pageNumber);
                    } else if (
                        this.isPromotionsSection &&
                        this.isAssignmentComplete(page, 'promotionId')
                    ) {
                        completedPages.push(page.pageNumber);
                    }
                    return completedPages;
                }, []);
            }
            return [];
        },
        disabledPages() {
            if (
                this.selectedResourceDefinition &&
                (this.isCategoriesSection || this.isPromotionsSection)
            ) {
                return this.selectedResourceDefinition.pages
                    .filter(page => !page.layoutTemplate)
                    .map(page => page.pageNumber);
            }

            return [];
        },
    },
    methods: {
        ...mapMutations('subCampaigns', ['setSelectedResourceDefinitionPageNumber']),
        pagesGetter() {
            if (this.selectedResourceDefinition) {
                return `${this.selectedResourceDefinition.pageCount}`;
            }
            return '0';
        },
        pagesSetter(value) {
            this.previousPageCount = this.selectedResourceDefinition.pageCount || 0;
            this.newPageCount = value;

            if (this.previousPageCount <= value) {
                this.updatePageCount();
            } else {
                // show modal for confirmation whether user want
                // to remove the pages and lose the data or not
                this.$refs.dialog_confirm.open();
            }
        },

        updatePageCount() {
            this.$set(this.selectedResourceDefinition, 'pageCount', `${this.newPageCount}`);
            this.$set(this.selectedResourceDefinition, 'templateName', 'custom');

            this.globalEmit('update-page-count', this.newPageCount);

            // reset selectedPage, if pageCount is less than selectedPage
            if (this.selectedPage > this.newPageCount) {
                this.selectedPage = null;
            }
            this.$refs.dialog_confirm.close();
        },

        cancelUpdatePageCount() {
            // as model wasn't updated in setter, in contains old value,
            // but dropdown shows updated one
            // as input and change event of v-select emits after model setter
            // need to change model to another value and then in $nextTick
            // update it to previousPageCount
            this.$set(this.selectedResourceDefinition, 'pageCount', null);
            this.$nextTick(() =>
                this.$set(this.selectedResourceDefinition, 'pageCount', this.previousPageCount)
            );
        },
        isAssignmentComplete(page, field) {
            if (!page.layoutTemplate) return false;
            const gridNamesForTemplate = this.templateLayoutsByKey[page.layoutTemplate].areaNames;
            const completedAreas = page.assignment.filter(assignment => {
                // space marked as marketing can't have promotion assigned
                if (
                    field === 'promotionId' &&
                    assignment.categoryKey === categoryAllocationsTypes.nonAllocated
                ) {
                    return true;
                }
                return assignment[field];
            });
            return gridNamesForTemplate.length === completedAreas.length;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.pagination-container {
    @include flex-row;

    &__select {
        &::v-deep {
            .v-select {
                &__slot {
                    background-color: $promo-background;

                    .v-label {
                        padding-left: 1rem;
                        padding-right: 0.6rem;
                    }
                }

                &__selections {
                    line-height: 1.6rem;

                    input {
                        width: 0;
                    }
                }

                &__selection.v-select__selection--comma {
                    padding-left: 1rem !important;
                }
            }
            .v-select__slot,
            .v-input__slot {
                height: 2.2rem !important;
            }
        }
    }

    &__no-of-pages {
        font-size: 1.2rem;
        line-height: 2.4rem;
        padding: 0 1rem 0 1rem;
    }

    &__label {
        font-size: 1.2rem;
        line-height: 2.4rem;
        padding: 0 1rem 0 3rem;
    }

    &__disabled {
        .pagination-container {
            &__label {
                color: $promo-grey;
            }
        }
    }
}
</style>
