/* eslint-disable import/prefer-default-export */

import { find, includes } from 'lodash';
import { current, New, proposed } from '@enums/product-badge-type';

/**
 * Function checks if product has the same supplier and category
 * @param {Object} product - The product
 * @param {Number} supplierKey - The key of the supplier
 * @param {Array} categoryKeys - The array of the category keys
 * @param {Number} categoryLevel - The category level in the hierarchy.
 */
export function isProductMatchSupplierAndCategory({
    product,
    supplierKey,
    categoryKeys,
    categoryLevel,
}) {
    const productCategoryKey = find(product.hierarchy, {
        level: categoryLevel,
    }).levelEntryKey;
    return includes(categoryKeys, productCategoryKey) && product.supplierKey === supplierKey;
}

/**
 * Get badge for the product
 *
 * @param product
 * @returns {string}
 */
export function getBadgeType(product) {
    if (product.isProposed) return proposed;
    if (product.proxyProductKey) return New;

    return current;
}
