import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["promotion-viewer", _vm.selectedFadedClass, _vm.ghostFadedClass],
    },
    [
      _vm._l(_vm.fields, function (item, colIndex) {
        return _c(
          "div",
          {
            key: `field::${_vm.promotionNamespace}::${colIndex}`,
            ref: `promotion_${_vm.promotionNamespace}`,
            refInFor: true,
            staticClass:
              "promotion-viewer__grid-cell promotion-viewer__grid-cell--main-content",
            class: [item.cellClasses, item.contentClasses],
            on: {
              click: function ($event) {
                return _vm.onPromotionClick(_vm.promotionNamespace)
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "promotion-viewer__data",
                class: [
                  item.dataClasses,
                  { "promotion-viewer__data--expanded": _vm.isSelected },
                  _vm.getColourClass,
                ],
              },
              [
                item.component
                  ? _c(
                      "div",
                      [
                        _c(
                          item.component.name,
                          _vm._b(
                            {
                              tag: "component",
                              attrs: {
                                "make-read-only":
                                  _vm.promotionEditingState.disabled,
                                "read-only-reason":
                                  _vm.promotionEditingState.reason,
                              },
                            },
                            "component",
                            _vm.constructVBindObj({
                              context: _vm.currentPromotion,
                              field: item.field,
                              ...item.component,
                            }),
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _c("div", { class: [item.fieldClasses] }, [
                      item.isCurrency
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("totalMargin")(
                                    _vm.getPromotionField(
                                      _vm.currentPromotion,
                                      item.field
                                    )
                                  )
                                ) +
                                "\n                "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getPromotionField(
                                    _vm.currentPromotion,
                                    item.field
                                  )
                                ) +
                                "\n                "
                            ),
                          ]),
                    ]),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.isAlwaysExpanded || _vm.isSelected
        ? _c(
            "div",
            {
              staticClass:
                "promotion-viewer--first-item promotion-viewer__extra-options extra-options__tag-selector",
              class: [
                {
                  "expanded-promotion": _vm.isAlwaysExpanded || _vm.isSelected,
                },
              ],
            },
            [
              _c("promotion-viewer-tag-selector-wrapper", {
                attrs: {
                  "promotion-id": _vm.promotionNamespace,
                  "selected-promotion-tags": _vm.promotion.tags,
                  "sub-campaign-tags": _vm.tagsOptions,
                  disabled: _vm.promotionEditingState.disabled,
                },
              }),
              _vm._v(" "),
              _c("unsaved-banner", {
                staticStyle: { "flex-grow": "9" },
                attrs: { "promotion-id": _vm.promotionNamespace },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAlwaysExpanded || _vm.isSelected
        ? _c(
            "div",
            {
              key: "promotion-maintenance-" + _vm.promotionNamespace,
              staticClass:
                "promotion-viewer__maintenance-pane promotion-viewer--first-item",
            },
            [
              _vm.showPromoViewer
                ? _c("promotion-maintenance-form-wrapper", {
                    ref: "promotionMaintenanceWrapper",
                    attrs: {
                      promotion: _vm.updatedPromotion,
                      scenario: _vm.scenario,
                      "sub-campaign": _vm.subCampaign,
                      "is-parking-lot": _vm.isParkingLot,
                      "is-sub-campaign-allocation": _vm.isSubCampaignAllocation,
                      "is-past-promotions": _vm.isPastPromotions,
                      "make-read-only": _vm.promotionEditingState.disabled,
                      "read-only-reason": _vm.promotionEditingState.reason,
                      "promotion-editing-state": _vm.promotionEditingState,
                      "split-promotion": _vm.splitPromotion,
                      "promotion-name": _vm.promotionName,
                    },
                    on: {
                      "split-requested": function ($event) {
                        return _vm.$emit("split-requested")
                      },
                      "save-button-clicked": function ($event) {
                        return _vm.$emit("save-button-clicked", $event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPromotionOverlay
        ? _c(VOverlay, {
            staticClass: "promotion-viewer__overlay",
            style: _vm.gridOverlayStyle(_vm.index),
            attrs: { absolute: true, value: true, opacity: 0.3 },
            nativeOn: {
              click: function ($event) {
                return _vm.onPromotionClick(_vm.promotionNamespace)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }