'use strict';

const moment = require('moment');
const { get } = require('lodash');

require('moment/locale/en-gb');
require('moment/locale/nl');

const momentFactory = function(config) {
    moment.locale(config.defaultLocale);
    const localeUpdates = {
        week: {
            dow: get(config, 'weekStartsWith', 1), // First day of week
        },
        ...get(config, 'customRules', {}),
    };
    [config.defaultLocale, config.fallbackLocale].forEach(locale =>
        moment.updateLocale(locale, localeUpdates)
    );
    return moment;
};

module.exports = momentFactory;
