import axios from 'axios';
import { camelCase } from 'lodash';
import to from 'await-to-js';

import i18n from '../../vue-i18n';

const createNotesVuexOptions = ({ resource, apiPath }) => {
    return {
        actions: {
            async addNote(
                { commit, dispatch },
                { id, note, fieldKey, parentId = null, updateFieldNotesCopy = false }
            ) {
                commit('setLoading', true);

                const url = parentId
                    ? `${apiPath}/${id}/note/field/${fieldKey}/${parentId}`
                    : `${apiPath}/${id}/note/field/${fieldKey}`;

                const [error, response] = await to(axios.post(url, note));
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notes.notifications.postSuccess`),
                    errorMessage: i18n.t(`notes.notifications.postError`),
                });
                if (error) return { error };
                commit(camelCase(`update-${resource}`), { id, updates: response.data });
                if (updateFieldNotesCopy) {
                    commit(camelCase(`update-${fieldKey}-notes-copy`), {
                        parentId,
                        updates: response.data,
                    });
                }
                commit('setLoading', false);
                return { result: response.data };
            },
            async deleteNote(
                { commit, dispatch },
                { id, noteId, fieldKey, parentId = null, updateFieldNotesCopy = false }
            ) {
                commit('setLoading', true);

                const [error, response] = await to(
                    axios.delete(`${apiPath}/${id}/note/${noteId}/field/${fieldKey}`)
                );
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notes.notifications.deleteSuccess`),
                    errorMessage: i18n.t(`notes.notifications.deleteError`),
                });
                if (error) return { error };
                commit(camelCase(`update-${resource}`), { id, updates: response.data });
                if (updateFieldNotesCopy) {
                    commit(camelCase(`update-${fieldKey}-notes-copy`), {
                        parentId,
                        updates: response.data,
                    });
                }
                commit('setLoading', false);
                return { result: response.data };
            },
            async updateNote(
                { commit, dispatch },
                { id, note, fieldKey, parentId = null, updateFieldNotesCopy = false }
            ) {
                commit('setLoading', true);

                const [error, response] = await to(
                    axios.patch(`${apiPath}/${id}/note/${note._id}/field/${fieldKey}`, note)
                );
                dispatch('handleResponseNotifications', {
                    error,
                    response,
                    successMessage: i18n.t(`notes.notifications.updateSuccess`),
                    errorMessage: i18n.t(`notes.notifications.updateError`),
                });
                if (error) return { error };
                commit(camelCase(`update-${resource}`), { id, updates: response.data });
                if (updateFieldNotesCopy) {
                    commit(camelCase(`update-${fieldKey}-notes-copy`), {
                        parentId,
                        updates: response.data,
                    });
                }
                commit('setLoading', false);
                return { result: response.data };
            },
        },
    };
};

export default createNotesVuexOptions;
