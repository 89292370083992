<template>
    <feature-toggle :toggle="allowApplyAllNotificationsDialog">
        <main-dialog
            v-if="isChild"
            ref="dialog"
            :translated-header="$tkey('header')"
            btn-cancel-text="actions.close"
            :button-text="$tkey('header')"
            :disabled-activator="!reportExists"
            :show-positive-action-button="false"
            :has-activator="true"
            @close="close"
            @open="open"
        >
            <template v-slot:default>
                <div v-if="renderGrid" class="notifications-report-dialog">
                    <div>
                        <p class="notifications-report-dialog__description">
                            {{ $tkey('promotionsWithNotificationsApplied') | toSentenceCase }}
                        </p>
                        <promo-ag-grid
                            ref="agGrid"
                            :row-data="reportWithDetails.promotionsWithNotificationsApplied"
                            :column-defs="columnDefs"
                            :grid-options="gridOptions"
                            :default-col-def="defaultColDef"
                            grid-style="width: 65rem; height: 30rem;"
                        />
                        <v-divider />
                    </div>
                    <div v-if="!isEmpty(reportWithDetails.promotionsWhichFailedToUpdate)">
                        <p class="notifications-report-dialog__description">
                            {{ $tkey('promotionsWhichFailedToUpdate') | toSentenceCase }}
                        </p>
                        <promo-ag-grid
                            ref="agGrid"
                            :row-data="reportWithDetails.promotionsWhichFailedToUpdate"
                            :column-defs="columnDefs"
                            :grid-options="gridOptions"
                            :default-col-def="defaultColDef"
                            grid-style="width: 65rem; height: 30rem;"
                        />
                        <v-divider />
                    </div>
                    <div v-if="!isEmpty(reportWithDetails.promotionsWithNoNotifications)">
                        <p class="notifications-report-dialog__description">
                            {{ $tkey('promotionsWithNoNotifications') | toSentenceCase }}
                        </p>
                        <promo-ag-grid
                            ref="agGrid"
                            :row-data="reportWithDetails.promotionsWithNoNotifications"
                            :column-defs="columnDefs"
                            :grid-options="gridOptions"
                            :default-col-def="defaultColDef"
                            grid-style="width: 65rem; height: 30rem;"
                        />
                    </div>
                </div>
            </template>
        </main-dialog>
    </feature-toggle>
</template>

<script>
import { isEmpty, get } from 'lodash';
import { allowApplyAllNotificationsDialog } from '@enums/feature-flags';
import { mapState, mapGetters } from 'vuex';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    localizationKey: 'acceptAllNotifications.dialog',

    props: {
        isChild: {
            type: Boolean,
            default: false,
        },
        entityId: {
            type: String,
        },
    },

    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: false,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter
                statusBar: {
                    statusPanels: [{ statusPanel: 'agTotalRowCountComponent', align: 'right' }],
                },
                rowCount: 0,
            },
            columnDefs: [
                { headerName: toSentenceCase(this.$tkey('name')), field: 'name' },
                {
                    headerName: toSentenceCase(this.$tkey('offerDescription')),
                    field: 'offerMechanic.description',
                },
            ],
            renderGrid: false,
            allowApplyAllNotificationsDialog,
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('promotions', ['promotions']),
        ...mapGetters('scenarios', [
            'selectedScenarioApplyAllNotificationsReport',
            'selectedScenarioId',
        ]),

        reportExists() {
            return !isEmpty(this.report);
        },
        report() {
            return this.selectedScenarioApplyAllNotificationsReport(
                this.entityId || this.selectedScenarioId
            );
        },
        reportWithDetails() {
            return this.promotions.reduce(
                (acc, p) => {
                    const promoDetails = {
                        name: p.name,
                        offerMechanic: p.offerMechanic,
                    };
                    if (get(this.report, 'promotionsWithNoNotifications', []).includes(p._id)) {
                        acc.promotionsWithNoNotifications.push(promoDetails);
                    } else if (
                        get(this.report, 'promotionsWhichFailedToUpdate', []).includes(p._id)
                    ) {
                        acc.promotionsWhichFailedToUpdate.push(promoDetails);
                    } else if (
                        get(this.report, 'promotionsWithNotificationsApplied', []).includes(p._id)
                    ) {
                        acc.promotionsWithNotificationsApplied.push(promoDetails);
                    }
                    return acc;
                },
                {
                    promotionsWithNoNotifications: [],
                    promotionsWhichFailedToUpdate: [],
                    promotionsWithNotificationsApplied: [],
                }
            );
        },
    },

    methods: {
        isEmpty,
        open() {
            this.renderGrid = true;
        },

        close() {
            this.renderGrid = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications-report-dialog {
    font-size: 1.2rem;

    &__description {
        padding: 1rem 0;
    }
}
</style>
