/**
 * Utility functions for forecasting calculations.
 *
 * @module client/js/utils/forecasting/calculation-utils
 */

/**
 * Calculates sales efficiency.
 *
 * @param {Object} params - The params object.
 * @param {Number} params.incrementalSalesExcTax Incremental sales excluding tax value to be used in the calculation.
 * @param {Number} params.salesDiscountExcTax Sales discount excluding tax value to be used in the calculation.
 */
export function calculateSalesEfficiency({ incrementalSalesExcTax = 0, salesDiscountExcTax = 0 }) {
    if (salesDiscountExcTax === 0) return 0;

    return -1 * (incrementalSalesExcTax / salesDiscountExcTax);
}

/**
 * Calculates margin efficiency.
 *
 * @param {Object} params - The params object.
 * @param {Number} params.incrementalMarginBeforeFunding Incremental margin excluding funding to be used in the calculation.
 * @param {Number} params.funding Funding to be used in the calculation.
 * @param {Number} params.salesDiscountExcTax Sales discount excluding tax value to be used in the calculation.
 * @param {Number} params.incrementalMarginAfterFunding Incremental margin where funding has already been applied.
 * @param {Number} params.fundingApplied A flag to track if funding has already been applied to the incremental margin.
 */
export function calculateMarginEfficiency({
    incrementalMarginBeforeFunding = 0,
    funding = 0,
    salesDiscountExcTax = 0,
    incrementalMarginAfterFunding = 0,
    fundingApplied = false,
}) {
    if (salesDiscountExcTax === 0) return 0;

    const incrementalMargin = fundingApplied
        ? incrementalMarginAfterFunding
        : incrementalMarginBeforeFunding + funding;

    return -1 * (incrementalMargin / salesDiscountExcTax);
}
