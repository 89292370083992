<template>
    <div class="filter">
        <div class="filter__grouping">
            <!-- Note: the select already has a label so that's why not specifying a for attr on this label -->
            <label class="filter__label">
                {{ $t('general.labels.filter') | toSentenceCase }}:
            </label>
            <div
                v-for="(filter, index) in filters"
                :key="filter.filterId"
                class="filter-group__filters"
            >
                <div class="flex-row">
                    <vuex-select
                        :placeholder="$t('parkingLot.filters.selectFilter') | toSentenceCase"
                        class="filter__parent-filter"
                        :options="getFilterOptions(filter)"
                        item-value="key"
                        item-text="text"
                        :getter="() => filter.filterKey"
                        :setter="key => filterSetter(filter, key)"
                    />

                    <component
                        :is="componentMap[(filterDefinitions[filter.filterKey] || {}).type]"
                        v-if="filter.filterKey"
                        :placeholder="
                            $t(`parkingLot.filters.select.${filter.filterKey}`) | toSentenceCase
                        "
                        :options="getFilterValueOptions({ filterKey: filter.filterKey })"
                        item-value="value"
                        item-text="text"
                        :getter="() => filter.value"
                        :setter="
                            value =>
                                filterValueSetter({
                                    filter,
                                    value,
                                })
                        "
                        multiple
                        chips
                        horizontal
                        deletable-chips
                        autocomplete
                        invalid-selection-text="general.info.invalidFilterSelection"
                        :finite-list="finiteList"
                        :max-items-in-list="maxItemsInList"
                        :has-select-all="false"
                        sort-results
                        @change="change"
                    />

                    <v-btn
                        v-if="showRemoveFilterBtn"
                        class="filter__cancel-button"
                        icon
                        @click="removeFilter(index)"
                    >
                        <icon icon-name="cancel" small icon-colour-type="primary-path" />
                    </v-btn>
                </div>
            </div>
            <div class="filter-bar__add-button">
                <create-new-button
                    background
                    :disabled="!canAddFilter"
                    @createNew="addEmptyFilter"
                />
            </div>
        </div>
        <div v-if="includeDateFilter" class="date-pair">
            <vuex-date-picker
                ref="fromDate"
                class="date-pair__date-picker date-pair__date-picker--from-date"
                :model="fromValue"
                :getter="() => fromValue"
                :setter="val => setFromValue(val)"
                :placeholder="$t('general.labels.from') | toSentenceCase"
                :max-date="yesterday"
            />
            <vuex-date-picker
                ref="toDate"
                class="date-pair__date-picker date-pair__date-picker--to-date"
                :model="toValue"
                :getter="() => toValue"
                :setter="val => setToValue(val)"
                :placeholder="$t('general.labels.to') | toSentenceCase"
                :min-date="fromValue"
                :max-date="yesterday"
            />
        </div>
    </div>
</template>

<script>
import { fieldTypes } from '@enums/vuex-form';
import filterEnums from '@enums/filters';
import { find, get, isNil, isArray, last } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    localizationKey: 'parkingLot.filters',
    props: {
        finiteList: {
            type: Boolean,
            default: true,
        },
        maxItemsInList: {
            type: Number,
            default: 2,
        },
        includeDateFilter: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // Taking a substring here to align with the examples detailed
            // here: https://vuetifyjs.com/en/components/date-pickers/#usage
            // This ensures the correct date is highlighted in the date picker
            yesterday: new Date(new Date().setDate(new Date().getDate() - 1))
                .toISOString()
                .substr(0, 10),
            toValue: new Date(new Date().setDate(new Date().getDate() - 1))
                .toISOString()
                .substr(0, 10),
            // 1 month ago from today
            fromValue: new Date(new Date().setMonth(new Date().getMonth() - 1))
                .toISOString()
                .substr(0, 10),
            filterDefinitions: {
                // Object keyed on filterKey for easier lookup.
                [filterEnums.brands]: {
                    text: toSentenceCase(this.$tkey('brand')),
                    key: filterEnums.brands,
                    query: 'products.brandKey',
                    optionsGetter: 'products/getBrands',
                    type: fieldTypes.select,
                    filterValueMapping: {
                        text: 'brandDescription',
                        value: 'brandKey',
                    },
                },
                [filterEnums.suppliers]: {
                    text: toSentenceCase(this.$tkey('supplier')),
                    key: filterEnums.suppliers,
                    query: 'products.supplierKey',
                    optionsGetter: 'products/getSuppliers',
                    type: fieldTypes.select,
                    filterValueMapping: {
                        text: 'supplierName',
                        value: 'supplierKey',
                    },
                },
            },
            filters: [],
            componentMap: {
                [fieldTypes.select]: 'vuex-select',
            },
        };
    },
    computed: {
        filterKeysInUse() {
            return this.filters.map(filter => filter.filterKey);
        },

        showRemoveFilterBtn() {
            return this.filters.length > 1 || this.filters[0].filterKey !== null;
        },
        canAddFilter() {
            return this.filters.length < 2 && !isNil(get(last(this.filters), 'filterKey'));
        },
    },
    created() {
        // Emitting change event to inform parent about default 'from' and 'to' values
        if (this.includeDateFilter) this.change();
        this.addEmptyFilter();
    },
    methods: {
        addEmptyFilter() {
            this.filters.push({ filterKey: null, value: null });
        },
        getFilterOptions({ filterKey }) {
            return Object.values(this.filterDefinitions).filter(
                def => !this.filterKeysInUse.includes(def.key) || def.key === filterKey
            );
        },
        getFilterValueOptions({ filterKey }) {
            const filterDefinition = this.filterDefinitions[filterKey];
            return this.$store.getters[filterDefinition.optionsGetter].map(option => ({
                text: option[filterDefinition.filterValueMapping.text],
                value: option[filterDefinition.filterValueMapping.value],
            }));
        },
        filterGetter({ index, filterId, path }) {
            const filter = filterId ? find(this.filters, { filterId }) : this.filters[index];
            return get(filter, path, null);
        },
        filterSetter(filter, filterKey) {
            const clearCurrentValue = !!filter.filterKey;
            this.$set(filter, 'filterKey', filterKey);
            if (clearCurrentValue) {
                this.filterValueSetter({ filter, value: null });
                this.change();
            }
        },
        filterValueSetter({ filter, value }) {
            if (isArray(value) && value.length === 0) value = null;
            this.$set(filter, 'value', value);
        },
        removeFilter(index) {
            this.$delete(this.filters, index);
            if (this.filters.length === 0) this.addEmptyFilter();
            this.change();
        },
        setFromValue(value) {
            this.fromValue = value;
            // If the toValue is now before the fromValue, reset it to the from date.
            if (this.toValue < this.fromValue) this.toValue = this.fromValue;

            this.change();
        },
        setToValue(value) {
            this.toValue = value;
            this.change();
        },
        change() {
            const where = this.includeDateFilter
                ? {
                      startDate: { $gte: this.fromValue },
                      endDate: { $lte: this.toValue },
                  }
                : {};

            this.filters.forEach(({ filterKey, value }) => {
                if (filterKey && value) {
                    const filterDefinition = this.filterDefinitions[filterKey];
                    where[filterDefinition.query] = isArray(value) ? { $in: value } : value;
                }
            });

            this.$emit('change', { where });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.filter {
    @include promo-component-border($top: true);

    width: 100%;
    padding: 1rem;
    display: flex;
    background-color: $promo-grey-blue-tint;

    &__label {
        color: $filter-bar-text-colour;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 300;
        margin-right: 1rem;
    }

    .date-pair {
        display: flex;
        &__date-picker {
            margin-right: 2rem;
        }
    }

    &__grouping {
        display: flex;
        flex: 1;
        align-items: baseline;

        & > * {
            margin-right: 1rem;
        }
    }

    &__parent-filter {
        margin-right: 1rem;
    }

    &__label {
        color: $filter-bar-text-colour;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 300;
    }

    &__cancel-button {
        padding-top: 0.8rem;
        margin-left: 0.8rem;
    }

    &__add-button {
        line-height: 2.8rem;
    }
}
</style>
