import { isEmpty } from 'lodash';

const mixin = {
    props: {
        context: Object,
        editMode: {
            type: Boolean,
            default: false,
        },
        namespace: {
            type: String,
            default: 'default',
        },
        isNewPromotion: {
            type: Boolean,
            default: false,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
        isSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
        cacheDom: {
            type: Boolean,
            default: false,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasScenario() {
            return !isEmpty(this.context.selectedScenario);
        },

        newPromotionInSubCampaignAllocation() {
            // selected scenario will be empty when editing promotion in sub-campaign allocation
            return (
                this.isSubCampaignAllocation &&
                !isEmpty(this.context.selectedScenario) &&
                isEmpty(this.context.selectedPromotion)
            );
        },

        hasSubCampaign() {
            return !!(this.hasScenario && this.context.selectedScenario.subCampaignId);
        },
    },
};

export default mixin;
