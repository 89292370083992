import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      "translated-header": _vm.$tkey(`${_vm.localizationKey}.title`),
      "has-activator": _vm.hasActivator,
      "show-negative-action-button": "",
      "show-positive-action-button": false,
    },
    on: { close: _vm.closeDialog },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    "div",
                    { staticClass: "details-btn" },
                    [
                      _c(
                        VBtn,
                        _vm._g(
                          {
                            attrs: {
                              disabled:
                                _vm.isSelectedPromotionCategoryOrStoreWide,
                              secondary: "",
                              depressed: "",
                              outlined: "",
                            },
                            on: { click: _vm.openDialog },
                          },
                          activator
                        ),
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$tkey(
                                    `${_vm.localizationKey}.activatorButton`
                                  )
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "forecast-details-container" },
                [
                  _c(
                    "div",
                    { staticClass: "forecast-details-container__filters" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "forecast-details-container__filters--label",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$tkey(
                                    `${_vm.localizationKey}.filters.storeGroupsLabel`
                                  )
                                )
                              ) +
                              ":\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("vuex-select", {
                        staticClass:
                          "forecast-details-container__filters--select",
                        attrs: {
                          getter: () => _vm.filter.storeGroups,
                          setter: (value) => _vm.setStoreGroupsFilter(value),
                          options: _vm.storeGroupsFilterOptions,
                          placeholder: _vm._f("toSentenceCase")(
                            _vm.$tkey(
                              `${_vm.localizationKey}.filters.storeGroupsPlaceholder`
                            )
                          ),
                          "item-text": "name",
                          "item-value": "key",
                          multiple: "",
                          chips: "",
                          "deletable-chips": "",
                          clearable: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "waterfall-charts" },
                    _vm._l(
                      _vm.getChartsConfig({ type: "waterfall" }),
                      function (chart) {
                        return _c(
                          "div",
                          {
                            key: "forecasting-highchart-" + chart.name,
                            staticClass: "chart-container",
                          },
                          [
                            _c("waterfall-chart", {
                              attrs: {
                                "chart-config": chart,
                                "localization-key": _vm.localizationKey,
                                getter: () => _vm.totals,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _vm._v(" "),
                  _c("promo-ag-grid", {
                    attrs: {
                      "row-data": _vm.rowData,
                      "column-defs": _vm.columnDefs,
                      "default-col-def": _vm.defaultColDef,
                      "grid-options": _vm.gridOptions,
                      "grid-style": "width: 150rem; height: 44rem",
                      "grid-class": "ag-theme-custom__product-details",
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }