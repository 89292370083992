import tabStates from '@enums/tab-states';
import { get, flatMap, size } from 'lodash';

function validateSupplierTab({ promotion }) {
    // Check the promotion has some funding
    const hasFunding = get(promotion, 'forecastingAggregations.promotion.promoFunding', 0) > 0;

    // Exit early if there is no funding
    if (!hasFunding) return tabStates.invalid;

    // Verify each assigned rate card's funding matches the required amount.
    const products = promotion.products || [];
    const productRateCards = flatMap(products, product => get(product, 'funding.lumpFunding', []));
    // Get the total rate card amounts assigned across the products.
    const rateCardTotals = productRateCards.reduce((result, value) => {
        result[value.rateCardId] = (result[value.rateCardId] || 0) + value.rateCardAmount;
        return result;
    }, {});

    const allAssignedRateCardsMatchExpected = promotion.rateCards.every(rateCard => {
        // return true if the rate card hasn't been assigned yet, or if the amount assigned matches
        // the expected value.
        return !rateCardTotals[rateCard._id] || rateCardTotals[rateCard._id] === rateCard.amount;
    });

    // Supplier tab is valid if there is some funding and all assigned rate cards have the expected
    // amount assigned to them.
    return hasFunding && products.length && allAssignedRateCardsMatchExpected
        ? tabStates.completed
        : tabStates.invalid;
}

function validateFundingTabIconState({ promotion, rateCards }) {
    // If there are no rate cards to allocate - return inProgress state.
    if (!size(rateCards)) {
        return tabStates.inProgress;
    }

    // Check the promotion has some funding.
    const hasFunding = get(promotion, 'forecastingAggregations.promotion.promoFunding', 0) > 0;
    // Verify each assigned rate card's funding matches the required amount.
    const products = promotion.products || [];
    const productRateCards = flatMap(products, product => get(product, 'funding.lumpFunding', []));

    // Check if promotion has rate card but they are not allocated.
    if (!size(productRateCards)) {
        return tabStates.invalid;
    }

    // Get the total rate card amounts assigned across the products.
    const rateCardTotals = productRateCards.reduce((result, value) => {
        result[value.rateCardId] = (result[value.rateCardId] || 0) + value.rateCardAmount;
        return result;
    }, {});

    // Compare a number of the rate cards that are allocated to each product with the number of rate cards
    // that have been added to the promotion.
    const allAppliedRateCardsAllocated = size(rateCardTotals) === size(promotion.rateCards);

    const allAssignedRateCardsMatchExpected = promotion.rateCards.every(rateCard => {
        // return false if at least one of the rate cards hasn't been assigned yet, or if the assigned
        // amount does not match the expected value.
        return !rateCardTotals[rateCard._id] || rateCardTotals[rateCard._id] === rateCard.amount;
    });

    // Supplier tab is valid if there is some funding and all assigned rate cards have the expected
    // amount assigned to them.
    return hasFunding &&
        products.length &&
        allAssignedRateCardsMatchExpected &&
        allAppliedRateCardsAllocated
        ? tabStates.completed
        : tabStates.inProgress;
}

export default {
    validateSupplierTab,
    validateFundingTabIconState,
};
