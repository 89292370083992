var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier-commitments" },
    [
      _c("supplier-commitment-type-toggle", {
        attrs: { "selected-supplier-commitment-type": _vm.totalSpendType },
      }),
      _vm._v(" "),
      _vm.isSpecifiedAllocationsSection
        ? _c(
            "div",
            { staticClass: "supplier-commitments-grid" },
            [
              _vm._l(_vm.fields, function (field, index) {
                return _c(
                  "div",
                  {
                    key: `supplierCommitmentHeader::${index}`,
                    staticClass: "supplier-commitments-grid__header",
                  },
                  [
                    field.header
                      ? _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    `supplierCommitments.headers.${field.header}`
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "supplier-commitments-grid__header" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("supplierCommitments.headers.select")
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.supplierCommitments, function (supplierCommitment) {
                return [
                  _c("supplier-commitment-viewer", {
                    key: `supplierCommitment::${supplierCommitment._id}`,
                    attrs: {
                      "supplier-commitment": supplierCommitment,
                      fields: _vm.fields,
                      "form-fields": _vm.formFields,
                      "promotion-namespace": _vm.promotionNamespace,
                      "is-promotion-supplier-funding-view": "",
                      "make-read-only": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onCommitmentClick({
                          id: supplierCommitment._id,
                        })
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm.isTotalSpendSection
        ? _c("total-spend-viewer", {
            attrs: {
              "is-promotion-supplier-funding-view": "",
              "promotion-supplier-key": _vm.supplierKey,
              "promotion-category": _vm.category,
              "promotion-namespace": _vm.promotionNamespace,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }