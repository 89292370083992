'use strict';

const tasks = require('../data/enums/workflow-tasks');
const actions = require('../data/enums/workflow-actions');
const userRoles = require('../data/enums/user-roles');
const promoResources = require('../data/enums/promo-resources');
const workflowEntities = require('../data/enums/workflow-entities');
const states = require('../data/enums/workflow-states');

const submitPromotion = {
    type: tasks.submit,
    entity: workflowEntities.promotion,
    buttonText: 'submitPromotion',
    positiveAction: actions.submitPromotion,
    negativeAction: null,
    userRoles: [
        userRoles.subCampaignPromoCreator,
        userRoles.subCampaignPromoEditor,
        userRoles.buyer,
        userRoles.buyingManager,
        userRoles.admin,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [
        {
            entity: workflowEntities.promotion,
            state: states.draftSubmitted,
        },
    ],
    promoResource: [],
    completionIndicator: states.submitted,
};

const approvePromotion = {
    type: tasks.approve,
    entity: workflowEntities.promotion,
    buttonText: 'approvePromotion',
    negativeButtonText: 'rejectPromotion',
    positiveAction: actions.approvePromotion,
    negativeAction: actions.rejectPromotion,
    userRoles: [
        userRoles.buyingManager,
        userRoles.promoApprover,
        userRoles.admin,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [
        {
            entity: workflowEntities.promotion,
            state: states.submitted,
        },
    ],
    promoResource: [],
    completionIndicator: states.approved,
    positiveIcon: 'empty-success-circle',
    negativeIcon: 'workflow-open-cross',
};

const requestUnlockPromotion = {
    type: tasks.requestUnlock,
    entity: workflowEntities.promotion,
    buttonText: 'requestUnlockPromotion',
    positiveAction: actions.requestUnlockPromotion,
    userRoles: [
        userRoles.admin,
        userRoles.subCampaignPromoEditor,
        userRoles.buyer,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [
        {
            entity: workflowEntities.promotion,
            state: states.approved,
        },
    ],
    promoResource: [],
    completionIndicator: states.requestUnlock,
    positiveIcon: 'empty-success-circle',
    negativeIcon: 'workflow-open-cross',
};

const unlockPromotion = {
    type: tasks.unlock,
    entity: workflowEntities.promotion,
    buttonText: 'unlockPromotion',
    negativeButtonText: 'rejectRequestUnlockPromotion',
    positiveAction: actions.unlockPromotion,
    negativeAction: actions.rejectRequestUnlockPromotion,
    userRoles: [
        userRoles.admin,
        userRoles.promoApprover,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [
        {
            entity: workflowEntities.promotion,
            state: states.approved,
        },
    ],
    promoResource: [],
    completionIndicator: null,
    positiveIcon: 'empty-success-circle',
    negativeIcon: 'workflow-open-cross',
};

const submitLeaflet = {
    type: tasks.submit,
    entity: workflowEntities.leaflet,
    buttonText: 'submitLeaflet',
    positiveAction: actions.submitLeaflet,
    negativeAction: null,
    userRoles: [
        userRoles.marketing,
        userRoles.marketingManager,
        userRoles.admin,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [],
    promoResource: [promoResources.leaflet],
    completionIndicator: states.submitted,
};

const approveLeaflet = {
    type: tasks.approve,
    entity: workflowEntities.leaflet,
    buttonText: 'approveLeaflet',
    negativeButtonText: 'rejectLeaflet',
    positiveAction: actions.approveLeaflet,
    negativeAction: actions.rejectLeaflet,
    userRoles: [
        userRoles.marketingManager,
        userRoles.admin,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [
        {
            entity: workflowEntities.leaflet,
            state: states.submitted,
        },
    ],
    promoResource: [promoResources.leaflet],
    completionIndicator: states.approved,
    positiveIcon: 'empty-success-circle',
    negativeIcon: 'workflow-open-cross',
};

const submitDraftPromotion = {
    type: tasks.draftSubmit,
    entity: workflowEntities.promotion,
    buttonText: 'submitDraftPromotion',
    positiveAction: actions.submitDraftPromotion,
    negativeAction: null,
    userRoles: [
        userRoles.subCampaignPromoCreator,
        userRoles.subCampaignPromoEditor,
        userRoles.buyer,
        userRoles.buyingManager,
        userRoles.admin,
        userRoles.promoSpecialistAdmin,
        userRoles.promoSpecialist,
    ],
    requirements: [],
    parentRequirements: [],
    promoResource: [],
    completionIndicator: states.draftSubmitted,
};

module.exports = {
    [workflowEntities.promotion]: {
        [tasks.submit]: submitPromotion,
        [tasks.approve]: approvePromotion,
        [tasks.requestUnlock]: requestUnlockPromotion,
        [tasks.unlock]: unlockPromotion,
        [tasks.draftSubmit]: submitDraftPromotion,
    },

    [workflowEntities.leaflet]: {
        [tasks.submit]: submitLeaflet,
        [tasks.approve]: approveLeaflet,
    },
};
