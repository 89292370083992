var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c("badge", {
        attrs: {
          type: _vm.type,
          "is-proposed": _vm.params.data.isProposed,
          "proxy-product-key": _vm.params.data.proxyProductKey,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }