import axios from 'axios';
import { get, merge, find, filter } from 'lodash';
import { to } from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '../../vue-i18n';

const getInitialState = () => ({
    tagMetadata: [],
});

const resource = 'tag-metadata';

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getTagMetadataById
     */
    getters: {
        getTagOptions: state => {
            return state.tagMetadata.map(tag => {
                return {
                    id: tag._id,
                    tagKey: tag.tagKey,
                    tagName: tag.tagName,
                    canBeDeleted: true,
                };
            });
        },

        getSelectedSubCampaignTagsInFilter: (state, getters, rootState, rootGetters) =>
            get(rootGetters['subCampaigns/selectedSubCampaign'], 'tags', []).filter(tag =>
                get(getters.specifiedFilter, 'tags', []).includes(tag.tagKey)
            ),

        getTagsInFilter: (state, getters) =>
            getters.getTagOptions.filter(tag =>
                get(getters.specifiedFilter, 'tags', []).includes(tag.tagKey)
            ),
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setTagMetadata
     * - deleteTagMetadata
     * - updateTagMetadata
     * - addTagMetadata
     * - resetState
     */
    mutations: {
        setChildDependencies(state, dependencies) {
            state.childDependencies = dependencies;
        },

        updateTag(state, tag) {
            const tagToUpdate = find(state.tagMetadata, item => item.tagKey === tag.tagKey);
            tagToUpdate.tagName = tag.tagName;
            tagToUpdate.tagKey = tag.tagKey;
        },

        deleteTag(state, tag) {
            state.tagMetadata = filter(state.tagMetadata, item => item.tagKey !== tag.tagKey);
        },
    },

    /**
     * Default actions available:
     * - fetchTagMetadata
     * - createTagMetadata
     * - deleteTagMetadata
     * - updateTagMetadata
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        async createTag({ commit, dispatch }, { tagName }) {
            const [error, response] = await to(
                axios.post('/api/tag-metadata/getOrCreate', {
                    tagName,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.saveSuccess`, { resource }),
                errorMessage: i18n.t(`notifications.saveError`, { resource }),
            });
            if (error) return { error }; // Return error so components can choose whether to perform another action

            commit('addTagMetadata', response.data);

            return { result: response.data };
        },

        async updateTagName({ commit, dispatch, rootGetters }, { tag, namespace }) {
            const [error, response] = await to(
                axios.patch(`/api/tag-metadata/updateTagName/${tag.id}`, {
                    tagName: tag.tagName,
                    tagKey: tag.tagKey,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.saveSuccess`, { resource }),
                errorMessage: i18n.t(`notifications.saveError`, { resource }),
            });
            if (error) return { error }; // Return error so components can choose whether to perform another action

            commit('updateTag', tag);
            // refetch promotions and sub-campaign in dialog
            // we need to handle 3 cases here
            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];
            if (
                selectedSubCampaign === null ||
                selectedSubCampaign === undefined ||
                selectedSubCampaign._id === namespace
            ) {
                // case 1 - no sub-campaign was selected
                // case 2 - tags were updated using a dialog for selected sub-campaign
                const subCampaign = rootGetters['subCampaigns/getSubCampaignById']({
                    _id: namespace,
                    usePluralResourceName: true,
                });
                await dispatch(
                    'subCampaigns/fetchSubCampaignWithDependencies',
                    { subCampaign },
                    { root: true }
                );
            } else {
                // case 3 - selectedSubCampaign is not null and tags were updated in a dialog for a different sub-campaign
                // fetchSubCampaignWithDependencies also fetches all sub-campaign's siblings,
                // so it will refetch the one with _id===namespace too
                await dispatch(
                    'subCampaigns/fetchSubCampaignWithDependencies',
                    { subCampaign: selectedSubCampaign },
                    { root: true }
                );
            }

            this.$app.globalEmit('tags-updated');
            return { result: response.data };
        },

        async mergeTags({ commit, dispatch, rootGetters }, { tag, tagDuplicate, namespace }) {
            const [error, response] = await to(
                axios.patch(`/api/tag-metadata/merge-tags/${tag.id}/${tagDuplicate._id}`, {
                    tagName: tag.tagName,
                    tagKey: tag.tagKey,
                })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.saveSuccess`, { resource }),
                errorMessage: i18n.t(`notifications.saveError`, { resource }),
            });
            if (error) return { error }; // Return error so components can choose whether to perform another action

            commit('updateTag', tag);
            commit('deleteTag', tagDuplicate);

            // refetch promotions and sub-campaign in dialog
            // we need to handle 3 cases here
            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];
            if (selectedSubCampaign === null || selectedSubCampaign._id === namespace) {
                // case 1 - no sub-campaign was selected
                // case 2 - tags were updated using a dialog for selected sub-campaign
                const subCampaign = rootGetters['subCampaigns/getSubCampaignById']({
                    _id: namespace,
                    usePluralResourceName: true,
                });
                await dispatch(
                    'subCampaigns/fetchSubCampaignWithDependencies',
                    { subCampaign },
                    { root: true }
                );
            } else {
                // case 3 - selectedSubCampaign is not null and tags were updated in a dialog for a different sub-campaign
                // fetchSubCampaignWithDependencies also fetches all sub-campaign's siblings,
                // so it will refetch the one with _id===namespace too
                await dispatch(
                    'subCampaigns/fetchSubCampaignWithDependencies',
                    { subCampaign: selectedSubCampaign },
                    { root: true }
                );
            }

            this.$app.globalEmit('tags-updated');
            return { result: response.data };
        },

        async fetchChildDependencies({ commit }, { id }) {
            const { data: dependencies } = await axios.get(`/api/tag-metadata/dependencies/${id}`);

            commit('setChildDependencies', dependencies);

            return dependencies;
        },

        async fetchDuplicateChildDependencies({ commit }, { id, duplicateId }) {
            const { data: dependencies } = await axios.get(
                `/api/tag-metadata/dependencies/${id}/${duplicateId}`
            );

            commit('setChildDependencies', dependencies);

            return dependencies;
        },
    },
};

const mixinParams = {
    useFilters: true,
    resource,
    isPlural: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
