<template functional>
    <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Duplicate</title>
        <g id="Planner" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-/-Duplicate" transform="translate(-1.000000, -1.000000)" fill="#2F477C">
                <path
                    id="copy-copy-6"
                    d="M2.95412396,11.912892 C1.65592972,11.912892 1,11.2508711 1,9.94076655 L1,2.97212544 C1,1.66202091 1.65592972,1 2.95412396,1 L9.87554905,1 C11.1737433,1 11.829673,1.66898955 11.829673,2.97212544 L11.829673,4.03832753 L13.0527086,4.03832753 C14.3440703,4.03832753 15,4.70731707 15,6.00348432 L15,13.0348432 C15,14.3379791 14.3440703,15 13.0527086,15 L6.12445095,15 C4.82625671,15 4.17032699,14.3379791 4.17032699,13.0278746 L4.17032699,11.912892 L2.95412396,11.912892 Z M2.96778917,11 L4.17032699,11 L4.17032699,6.00348432 C4.17032699,4.70034843 4.82625671,4.03832753 6.12445095,4.03832753 L10.9346022,4.03832753 L10.9346022,3.02090592 C10.9346022,2.28919861 10.545144,1.91289199 9.86188385,1.91289199 L2.96778917,1.91289199 C2.27086384,1.91289199 1.89507077,2.28919861 1.89507077,3.02090592 L1.89507077,9.8989547 C1.89507077,10.6236934 2.27086384,11 2.96778917,11 Z M6.13811615,14.087108 L13.0322108,14.087108 C13.715471,14.087108 14.1117618,13.71777 14.1117618,12.9860627 L14.1117618,6.05226481 C14.1117618,5.32752613 13.715471,4.95121951 13.0322108,4.95121951 L6.13811615,4.95121951 C5.44802343,4.95121951 5.06539776,5.32752613 5.06539776,6.05226481 L5.06539776,12.9790941 C5.06539776,13.7108014 5.44119082,14.087108 6.13811615,14.087108 Z M9.5954124,12.261324 C9.36310395,12.261324 9.17862372,12.0731707 9.17862372,11.815331 L9.17862372,9.96167247 L7.35431918,9.96167247 C7.12201074,9.96167247 6.9102001,9.7456446 6.9102001,9.5087108 C6.9102001,9.27874564 7.12201074,9.06968641 7.35431918,9.06968641 L9.17862372,9.06968641 L9.17862372,7.21602787 C9.17862372,6.95818815 9.36310395,6.77003484 9.5954124,6.77003484 C9.83455344,6.77003484 10.0190337,6.95818815 10.0190337,7.21602787 L10.0190337,9.06968641 L11.79551,9.06968641 C12.0551489,9.06968641 12.2601269,9.271777 12.2601269,9.5087108 C12.2601269,9.75261324 12.0551489,9.96167247 11.79551,9.96167247 L10.0190337,9.96167247 L10.0190337,11.815331 C10.0190337,12.0731707 9.83455344,12.261324 9.5954124,12.261324 Z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Duplicate',
};
</script>
