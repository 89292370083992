var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "17px",
        height: "17px",
        viewBox: "0 0 17 17",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Online video")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-128.000000, -214.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Online-video",
                    transform: "translate(128.666640, 214.800510)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group-28",
                        transform: "translate(0.000000, 2.500000)",
                        fill: "#2F477C",
                        "fill-rule": "nonzero",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Combined-Shape",
                          d: "M15,0 C15.5522847,0 16,0.44771525 16,1 L16,10 C16,10.5522847 15.5522847,11 15,11 L1,11 C0.44771525,11 0,10.5522847 0,10 L0,1 C0,0.44771525 0.44771525,0 1,0 L15,0 Z M15,1 L1,1 L1,10 L15,10 L15,1 Z M5.5,2.5 L11.5,5.5 L5.5,8.5 L5.5,2.5 Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }