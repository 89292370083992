<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { bind, get } from 'lodash';
import { scenarios } from '@enums/resources';
import { canEditScenario, canPublishScenarios } from '@enums/feature-flags';
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { componentTypes } from '@enums/custom-components';
import { object } from '@enums/value-types';
import commonFormDialogComponent from './common-form-dialog';
import i18n from '../vue-i18n';
import validators from '../validators';
import { getDefaultValuePropName, getDefaultValuePropValue } from '@/js/utils/component-utils';

const createForm = function({
    isEditable,
    isPrivateDefault,
    isDetailsOpened,
    isNominationsOpened,
    onDetailsPanelStateChange,
    onNominationsPanelStateChange,
    scenarioDefaults,
    categoriesTree,
    updateCategoriesInStagingArea,
}) {
    return {
        vuexModule: 'scenarios',
        editable: isEditable,
        addEvent: 'createScenario',
        editEvent: 'updateScenario',
        fields: [
            {
                fieldName: 'name',
                editable: isEditable,
                type: fieldTypes.text,
                label: 'planning.createScenario.title',
                placeholder: 'planning.createScenario.titlePlaceholder',
                cssClass: 'vuex-form__name',
                isRequired: true,
                validations: [
                    {
                        message: i18n.t('validation.scenario.nameRequired'),

                        validator: validators.required,
                    },
                ],
            },
            {
                fieldName: 'briefing',
                editable: isEditable,
                type: fieldTypes.textarea,
                label: 'planning.createScenario.briefing',
                placeholder: 'planning.createScenario.briefingPlaceholder',
                cssClass: 'vuex-form__briefing',
            },
            {
                type: fieldTypes.dateRange,
                editable: isEditable,
                label: 'planning.createScenario.dates',
                cssClass: 'vuex-form__dates',
                isRequired: true,
                minDate: {
                    source: sourceTypes.context,
                    path: 'startDate',
                },
                maxDate: {
                    source: sourceTypes.context,
                    path: 'endDate',
                },
                from: {
                    fieldName: 'startDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'startDate',
                    },
                },
                to: {
                    fieldName: 'endDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'endDate',
                    },
                },
                entity: 'scenario',
                ignoreParentReadOnly: true,
                validations: [
                    {
                        message: i18n.t('validation.scenario.datesRequired'),

                        validator: validators.required,
                    },
                    {
                        validator: validators.dateRangeRequired,
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createScenario.categories',
                cssClass: 'vuex-form__details',
                expansionPanelClass: 'details__wrapper',
                isOpenState: isDetailsOpened,
                change: onDetailsPanelStateChange,
                children: [
                    {
                        fieldName: 'subCampaignId',
                        dynamicDefaultValue: {
                            source: sourceTypes.context,
                            path: '_id',
                        },
                    },
                    {
                        fieldName: 'categories',
                        type: componentTypes.categoriesSelect,
                        tree: categoriesTree,
                        cssClass: 'vuex-form__categories',
                        editable: isEditable,
                        isRequired: true,
                        update: updateCategoriesInStagingArea,
                        [getDefaultValuePropName(
                            scenarioDefaults.categories
                        )]: getDefaultValuePropValue(scenarioDefaults.categories, {
                            source: sourceTypes.context,
                            path: 'categories',
                        }),
                    },
                    {
                        fieldName: 'customerGroups',
                        editable: isEditable,
                        type: fieldTypes.checkboxesList,
                        cssClass: 'vuex-form__customer-restrictions',
                        isRequired: true,
                        label: 'planning.customerRestrictions',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'scenarios',
                            params: {
                                attributeName: 'customerGroups',
                                attributeKey: 'key',
                                resource: 'clientConfig',
                                getOptionsFunction: 'getCustomerRestrictions',
                                attributePathOnChildEntity: 'offerMechanic.customerAvailability',
                                scenarioId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                        itemText: 'description',
                        itemValue: 'key',
                        valueType: object,
                        [getDefaultValuePropName(
                            scenarioDefaults.customerRestrictions
                        )]: getDefaultValuePropValue(scenarioDefaults.customerRestrictions, {
                            source: sourceTypes.context,
                            path: 'customerGroups',
                        }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createScenario.resources',
                cssClass: 'vuex-form__nominations',
                isOpenState: isNominationsOpened,
                change: onNominationsPanelStateChange,
                children: [
                    {
                        type: componentTypes.nominationTabs,
                        editable: isEditable,
                        parentContextType: scenarios,
                        storeGroups: {
                            fieldName: 'storeGroups',
                            editable: isEditable,
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getCheckboxListOptions',
                                module: 'scenarios',
                                params: {
                                    attributeName: 'storeGroups',
                                    attributeKey: 'key',
                                    resource: 'storeGroups',
                                    getOptionsFunction: 'getStoreGroupsOptions',
                                    attributePathOnChildEntity: 'storeGroups',
                                    getUserAccessOptionsMap: 'userStoreGroupsMap',
                                    scenarioId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                            itemText: 'description',
                            itemValue: 'key',
                            valueType: object,
                            [getDefaultValuePropName(
                                scenarioDefaults.storeGroups
                            )]: getDefaultValuePropValue(scenarioDefaults.storeGroups, {
                                source: sourceTypes.context,
                                path: 'storeGroups',
                            }),
                        },
                        resources: {
                            isRequired: true,
                            fieldName: 'resources',
                            editable: isEditable,
                            itemText: 'description',
                            itemValue: 'key',
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getResourceOptions',
                                module: 'scenarios',
                                params: {
                                    parentModule: 'subCampaigns',
                                    parentGetter: 'selectedSubCampaign',
                                    contextId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                    childModule: 'promotions',
                                    childGetter: 'getPromotionsByScenarioId',
                                },
                            },
                            [getDefaultValuePropName(
                                scenarioDefaults.promoResources
                            )]: getDefaultValuePropValue(scenarioDefaults.promoResources, {
                                source: sourceTypes.getter,
                                identifier: 'getDefaultResources',
                                module: 'scenarios',
                                params: {
                                    resources: {
                                        source: sourceTypes.context,
                                        path: 'resources',
                                    },
                                },
                            }),
                        },
                        defaultStoreGroups: {
                            fieldName: 'defaultStoreGroups',
                            editable: isEditable,
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getSelectedScenarioStoreGroupOptions',
                                module: 'scenarios',
                                params: {
                                    scenarioId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                            itemText: 'description',
                            itemValue: 'key',
                            valueType: object,
                        },
                        defaultResources: {
                            isRequired: true,
                            fieldName: 'defaultResources',
                            editable: isEditable,
                            itemText: 'description',
                            itemValue: 'key',
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getSelectedScenarioResourceOptions',
                                module: 'scenarios',
                                params: {
                                    scenarioId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            {
                fieldName: 'isPrivate',
                defaultValue: isPrivateDefault,
                editable: isEditable,
            },
            {
                fieldName: 'isFavourite',
                defaultValue: false,
            },
            {
                fieldName: 'workflowState',
                defaultValue: [],
            },
        ],
    };
};

export default {
    extends: commonFormDialogComponent,

    data: () => ({
        resource: scenarios,
        btnCancelId: 'scenarioCancelId',
        btnConfirmDialogCancel: 'scenarioConfirmDialogCancelId',
        btnConfirmId: 'scenarioBtnConfirmId',
        isDetailsOpened: null,
        isNominationsOpened: null,
        hasConfirmationDialog: false,
    }),

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'additionalDetailsDefaultSelection']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('hierarchy', ['getCategoriesTree']),
        ...mapGetters('scenarios', ['getStagingAreaField', 'getPromotionCategoriesByScenarioId']),

        form() {
            return createForm({
                isEditable: !!this.toggleLogic[canEditScenario],
                isPrivateDefault: !!this.toggleLogic[canPublishScenarios],
                isDetailsOpened: this.isDetailsOpened,
                isNominationsOpened: this.isNominationsOpened,
                onDetailsPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isDetailsOpened'
                ),
                onNominationsPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isNominationsOpened'
                ),
                scenarioDefaults: this.additionalDetailsDefaultSelection.scenario,
                updateCategories: this.updateCategories,
                updateUnits: this.updateUnits,
                categoriesTree: this.getCategoriesTree({
                    parentCategories: this.selectedSubCampaign.categories,
                    childCategories: this.getPromotionCategoriesByScenarioId({
                        scenarioId: get(this.editContext, '_id'),
                    }),
                    selectedCategories: this.getStagingAreaField({
                        namespace: this.editContext ? this.editContext._id : 'default',
                        fieldName: 'categories',
                    }),
                    isEditable: !!this.toggleLogic[canEditScenario] && !this.isReadOnly,
                }),
                updateCategoriesInStagingArea: this.updateCategoriesInStagingArea,
            });
        },

        labels() {
            return {
                btnText: 'planning.buttons.createScenario',
                heading: this.editMode
                    ? 'planning.editScenario.heading'
                    : 'planning.createScenario.heading',
            };
        },
    },
    methods: {
        ...mapActions('scenarios', ['setStagingAreaField']),

        setModalExpansionPanelsState() {
            this.setDetailsPanelState();
            this.setNominationsPanelState();
        },

        setDetailsPanelState() {
            this.setExpansionPanelState('isDetailsOpened', ['customerGroups', 'categories']);
        },

        setNominationsPanelState() {
            this.setExpansionPanelState('isNominationsOpened', ['storeGroups', 'resources']);
        },

        updateCategoriesInStagingArea(selectedCategories) {
            const namespace = this.editContext ? this.editContext._id : 'default';

            this.setStagingAreaField({
                namespace,
                fieldName: 'categories',
                value: selectedCategories,
            });
        },
    },
};
</script>
