import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCheckbox, {
    staticClass: "rtls-checkbox",
    attrs: {
      label: _vm.label,
      value: _vm.value,
      disabled: _vm.isDisabled,
      rules: _vm.rules,
      "hide-details": _vm.hideDetails,
      indeterminate: _vm.indeterminate,
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }