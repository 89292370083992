import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      staticClass: "navigate-button ml-2",
      class: { basic: _vm.isBasic },
      attrs: {
        disabled: _vm.disabled,
        "x-small": "",
        primary: !_vm.isBasic,
        text: _vm.isBasic,
        depressed: "",
        "max-width": "2rem",
        "min-width": "2rem",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.left
        ? _c(VIcon, [_vm._v("\n        navigate_before\n    ")])
        : _vm.right
        ? _c(VIcon, [_vm._v("\n        navigate_next\n    ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }