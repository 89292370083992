export default [
    {
        field: 'effectivenessRating',
        component: {
            name: 'promotion-rag-colour',
            contextualProps: {
                colour: 'effectivenessRating',
            },
        },
    },
    {
        field: 'date',
        component: {
            name: 'losenge-date-display',
            defaultProp: null,
            contextualProps: ['startDate', 'endDate'],
            props: {
                compact: true,
            },
        },
    },
    {
        functionName: 'getProductCount',
    },
    {
        cellClasses: 'funded-promotions-list__last-column',
        field: 'offerMechanic.description',
    },
];
