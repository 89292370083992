<template>
    <div class="page">
        <div class="page__filter-bar">
            <filter-bar :max-filter-count="3" />
        </div>
        <promo-planner>
            <template v-slot:scenarios-component>
                <template v-if="shouldShowScenariosViewer">
                    <scenarios-viewer :key="selectedScenarioId" />
                </template>
            </template>
        </promo-planner>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { last, get } from 'lodash';
import { canViewScenariosFrontEnd } from '@enums/feature-flags';
import guardsMap from './planning-guard-map';

export default {
    beforeRouteUpdate(to, from, next) {
        if (to.name === from.name) {
            // navigating to the same route only params has changed
            // manually fire beforeEntered hook because it is not called automatically
            guardsMap[to.name](to, from, next);
            const toScenarioId = get(to, 'params.scenarioId', null);
            const fromScenarioId = get(from, 'params.scenarioId', null);
            if (toScenarioId && fromScenarioId && toScenarioId !== fromScenarioId) {
                this.globalEmit('change-scenario-in-route');
            }
        } else {
            // while navigating from child route to parent, beforeEntered hook is not called, need to do it manually
            // matched array contains route records for all nested path segments of the current route
            // the last object is mostly matched route
            // ex: fromRoute = { path: '/planning/singleSubCampaignView/:subCampaignId/promotion/:promotionId/products,
            //                   parent: {path: '/planning/singleSubCampaignView/:subCampaignId/promotion/:promotionId,
            //                   ...}, ...}
            // ex: toRoute = {path: '/planning/singleSubCampaignView/:subCampaignId,...}
            // if one of the fromRoute deep parent is toRoute, it means that fromRoute is a child of toRoute
            let fromRoute = last(from.matched);
            const toRoute = last(to.matched);
            // the first (/planning) route doesn't have parent,
            // infinite loop is impossible here
            while (fromRoute.parent) {
                if (fromRoute.parent === toRoute && guardsMap[to.name]) {
                    // navigating from child route to parent
                    // manually fire beforeEntered hook because it is not called automatically
                    guardsMap[to.name](to, from, next);
                    return;
                }
                // go to higher level
                fromRoute = fromRoute.parent;
            }

            next();
        }
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('scenarios', ['selectedScenarioId']),

        shouldShowScenariosViewer() {
            return this.toggleLogic[canViewScenariosFrontEnd] && this.selectedSubCampaign;
        },
    },
};
</script>

<style scoped lang="scss">
.page {
    height: 100%;

    &__filter-bar {
        padding: 1rem;
    }
}
</style>
