import { camelCase } from 'lodash';

const mixin = (resource, component = resource) => {
    // promotion -> selectedPromotionId
    // scenario -> selectedScenarioId
    // weekly-view-header -> selectedCampaignId
    const selectedResourceId = camelCase(`selected-${resource}-id`);
    return {
        props: {},
        data() {
            return {};
        },
        computed: {
            selectedFadedClass() {
                if (this[selectedResourceId]) {
                    return this.isActive ? `${component}--selected` : `${component}--faded`;
                }

                return '';
            },

            isActive() {
                return this[resource]._id === this[selectedResourceId];
            },
        },
    };
};

export default mixin;
