<template>
    <div :class="cssClass">
        <v-radio-group v-model="model" :row="row" class="rtls-radio-group" @change="change">
            <template v-slot:label>
                <p v-if="label" class="checkboxes-list__label">
                    {{ $t(label) | toSentenceCase }}
                    <span v-if="isRequired" class="asterisk">*</span>
                </p>
            </template>
            <v-radio
                v-for="(option, index) in options"
                :key="option[itemValue]"
                :label="option[itemText]"
                :value="option[itemValue]"
                :disabled="isDisabled"
                :rules="rules"
                :hide-details="index < options.length - 1"
                class="rtls-radio"
            />
        </v-radio-group>
    </div>
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        options: Array,
        itemValue: {
            type: String,
            default: 'value',
        },
        itemText: {
            type: String,
            default: 'text',
        },
        cssClass: String,
        isRequired: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    methods: {
        change(value) {
            this.$emit('change', value);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-radio {
    margin-right: 0;
}
</style>
