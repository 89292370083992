var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "spinner", class: _vm.data.staticClass || "" },
    [
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }