<template>
    <div class="promo-metric">
        <div class="promo-metric__title">
            {{ $t(title) | toSentenceCase }}
        </div>
        <div class="promo-metric__value">
            {{ kpi.value | totalSales }}
        </div>
        <h4 class="promo-metric__efficiency" :class="efficiencyClass">
            {{ $n('numbers.default.percentageEvolution', kpi.efficiency) }}
            <i :class="arrowIcon" />
        </h4>
    </div>
</template>

<script>
import { getArrowClass } from '../../utils/health-sidebar-utils';

export default {
    props: ['title', 'kpi', 'efficiency'],

    computed: {
        arrowIcon() {
            return getArrowClass({ kpi: this.kpi.efficiency });
        },

        efficiencyClass() {
            if (!this.kpi.efficiency) return '';
            return this.kpi.efficiency > 0
                ? 'promo-metric__efficiency--positive'
                : 'promo-metric__efficiency--negative';
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.promo-metric {
    &__title {
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__value {
        font-size: 1.5rem;
        font-weight: 500;
        text-align: right;
    }

    &__efficiency {
        border-width: 0.1rem;
        border-style: solid;
        border-radius: 1rem;
        text-align: center;
        border-color: $promo-grey-dark;

        &--negative {
            border-color: $rtls-negative-action-colour;

            i {
                color: $rtls-negative-action-colour;
            }
        }

        &--positive {
            border-color: $rtls-postive-action-colour;

            i {
                color: $rtls-postive-action-colour;
            }
        }
    }
}
</style>
