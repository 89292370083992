<template>
    <main-dialog
        ref="dialog"
        heading="parkingLot.allocatePromotion.heading"
        btn-confirm-text="actions.copy"
        :has-activator="hasActivator"
        :confirm-btn-disabled="disabled"
        @confirm="allocatePromotionsDialogConfirm"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <icon icon-name="duplicate" small v-on="activator" @click.stop />
        </template>
        <template v-slot:default>
            <div class="allocate-description">
                {{ $t('parkingLot.allocatePromotion.pastPromotionsDescription') }}
            </div>
            <div class="allocate-destination">
                {{ $t('parkingLot.allocatePromotion.destination') }}:
            </div>
            <div class="allocate-past-promotion">
                <v-radio-group v-model="selectedType" class="allocate-past-promotion__radio">
                    <v-radio
                        :label="toSentenceCase($t('parkingLot.allocatePromotion.parkingLot'))"
                        :value="parkingLot"
                        @click="selectedType = parkingLot"
                    />
                </v-radio-group>
                <vuex-date-range
                    :key="dateRange.length"
                    :from="{ fieldName: 'from' }"
                    :to="{ fieldName: 'to' }"
                    :use-vuex-staging-area="false"
                    :getter="() => dateRange"
                    :min-date="minDateRangeDate"
                    class="allocate-past-promotion__date-range"
                    @change="dateRangeSelected"
                />
            </div>
            <div v-if="requestInProgress" class="in-progress--message">
                <label class="in-progress__message">{{
                    $t('parkingLot.allocatePromotion.inProgressMessage') | toSentenceCase
                }}</label>
                <spinner-dynamic />
            </div>
            <v-overlay
                v-if="requestInProgress"
                class="allocate-past-promotion__overlay"
                :absolute="true"
                :value="true"
                :opacity="0.3"
            />
        </template>
    </main-dialog>
</template>

<script>
import to from 'await-to-js';
import { parkingLot } from '@enums/resources';
import { mapActions, mapState } from 'vuex';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        promotionIds: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedType: parkingLot,
            selectedStartDate: null,
            selectedEndDate: null,
            parkingLot,
            dateRange: [],
        };
    },
    computed: {
        ...mapState('promotions', ['loading']),
        minDateRangeDate() {
            return this.$options.filters.datepickerFormat(this.$moment());
        },
        disabled() {
            return this.selectedStartDate === null || this.selectedEndDate === null;
        },
        requestInProgress() {
            return this.loading;
        },
    },
    methods: {
        toSentenceCase,
        ...mapActions('promotions', ['movePromotionsToParkingLot']),

        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.resetDateRange();
            this.$refs.dialog.close();
        },
        async allocatePromotionsDialogConfirm() {
            const [error] = await to(
                this.movePromotionsToParkingLot({
                    promotionIds: this.promotionIds,
                    startDate: this.selectedStartDate,
                    endDate: this.selectedEndDate,
                    copyPromotions: true,
                    sendUpdatedDates: true,
                    refetchDocs: false,
                    updateForecastReference: true,
                    removeNonPromotableProducts: true,
                })
            );

            if (!error) {
                this.closeDialog();
                this.$emit('confirm');
            }
        },
        dateRangeSelected({ from, to: toValue }) {
            this.selectedStartDate = from;
            this.selectedEndDate = toValue;
            this.dateRange = [this.selectedStartDate, this.selectedEndDate];
        },
        resetDateRange() {
            this.selectedStartDate = null;
            this.selectedEndDate = null;
            this.dateRange.splice(0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.allocate-destination {
    font-size: 1.2rem;
    font-weight: 600;
}

.allocate-description {
    font-size: 1.2rem;
}

.allocate-past-promotion {
    display: flex;

    &__radio {
        width: 40%;
    }
    &__date-range {
        padding-top: 1.5rem;
    }
    &__overlay {
        z-index: 99 !important;
    }
}

.in-progress__message {
    font-size: 1.3rem;
    color: $promo-text-colour;
    margin: 1rem;

    .spinner {
        width: 1.4rem;
        height: 1.4rem;
    }
}

::v-deep {
    .v-text-field__slot {
        label {
            padding-left: 1rem;
        }
    }
    .v-input--selection-controls {
        margin-top: 1.3rem;
        margin-bottom: 0.5rem;
    }
}
</style>
