'use strict';

const promotionRoles = {
    parkingLotPromoCreator: 'PARKING_LOT_PROMO_CREATOR',
    parkingLotPromoEditor: 'PARKING_LOT_PROMO_EDITOR',
    parkingLotPromoViewer: 'PARKING_LOT_PROMO_VIEWER',
    subCampaignPromoCreator: 'SUB_CAMPAIGN_PROMO_CREATOR',
    subCampaignPromoEditor: 'SUB_CAMPAIGN_PROMO_EDITOR',
    subCampaignPromoViewer: 'SUB_CAMPAIGN_PROMO_VIEWER',
    promoApprover: 'PROMO_APPROVER',
    approvedPromoEditor: 'APPROVED_PROMO_EDITOR',
};

const campaignRoles = {
    campaignViewer: 'CAMPAIGN_VIEWER',
    campaignCreator: 'CAMPAIGN_CREATOR',
};

const channelRoles = {
    channelViewer: 'CHANNEL_VIEWER',
    channelCreator: 'CHANNEL_CREATOR',
};

const reportingRoles = {
    reportingUser: 'REPORTING_USER',
    adminReportingUser: 'ADMIN_REPORTING_USER',
};

const supplierRoles = {
    supplierCommitmentViewer: 'SUPPLIER_COMMITMENT_VIEWER',
    supplierCommitmentCreator: 'SUPPLIER_COMMITMENT_CREATOR',
    variableFundingAgreementViewer: 'VARIABLE_FUNDING_AGREEMENT_VIEWER',
    variableFundingAgreementCreator: 'VARIABLE_FUNDING_AGREEMENT_CREATOR',
};

module.exports = {
    admin: 'ADMIN',
    promoSpecialistAdmin: 'PROMO_SPECIALIST_ADMIN',
    categoryAdmin: 'CATEGORY_ADMIN',
    promoSpecialist: 'PROMO_SPECIALIST',
    buyingManager: 'BUYING_MANAGER',
    tradingManager: 'TRADING_MANAGER',
    buyer: 'BUYER',
    marketing: 'MARKETING',
    marketingManager: 'MARKETING_MANAGER',
    ...promotionRoles,
    ...campaignRoles,
    ...channelRoles,
    ...reportingRoles,
    ...supplierRoles,
};
