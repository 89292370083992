import { isBoolean, isString } from 'lodash';

const isValid = (value, validations) => {
    if (!validations) {
        return true;
    }

    return validations.every(validation => {
        const result = validation.validator(value, validation.message, ...validation.params);
        // if validation fails error message string is returned
        return isBoolean(result) && result;
    });
};

const getErrorMessages = (value, validations) => {
    if (!validations) {
        return [];
    }

    return validations
        .map(validation => {
            const result = validation.validator(value, validation.message, ...validation.params);
            // if validation fails error message string is returned
            return isString(result) ? result : null;
        })
        .filter(error => !!error);
};

export default {
    isValid,
    getErrorMessages,
};
