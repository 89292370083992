var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-title py-2" }, [
    _c("div", { staticClass: "warning-text" }, [
      _vm._v(
        _vm._s(_vm._f("toSentenceCase")(_vm.$t("planning.warnings.warning"))) +
          "!"
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm._f("toSentenceCase")(
              _vm.$t("planning.warnings.deletingChildSubCampaignWarning")
            )
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.children, function (child, index) {
        return _c("li", { key: index }, [_vm._v(_vm._s(child))])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }