import UXEvents from '@enums/ux-events';

export default [
    {
        key: UXEvents.subCampaignDeleted,
        actions: ['subCampaigns/setSelectedSubCampaignId', 'notifications/fetchOpenNotifications'],
    },
    {
        key: UXEvents.subCampaignUpdated,
        actions: ['scenarios/fetchScenariosForSubCampaign'],
    },
];
