<script>
import { canEditCampaign, enablePublicPrivateEntities } from '@enums/feature-flags';
import { mapState } from 'vuex';
import promoPlannerContainerItem from './promo-planner-container-item';

const icons = [
    {
        field: 'duplicate',
        featureToggle: 'canEditCampaign',
        component: {
            name: 'icon-button',
            featureToggle: canEditCampaign,
            props: {
                icon: 'duplicate',
            },
            events: {
                click: {
                    method: 'duplicateClicked',
                    params: [],
                },
            },
        },
    },
];

export default {
    extends: promoPlannerContainerItem,
    props: ['campaign', 'parentStartDate', 'parentEndDate', 'isLocked'],
    data() {
        return {
            icons,
            canEditCampaign,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),

        context() {
            return this.campaign;
        },

        isPrivate() {
            return (
                this.toggleLogic[enablePublicPrivateEntities] &&
                this.campaign &&
                this.campaign.isPrivate
            );
        },
    },
    methods: {
        onClick() {
            this.$emit('opened', { campaign: this.campaign });
        },
        duplicateClicked() {
            this.$emit('duplicate-campaign-clicked', { context: this.context });
        },
    },
};
</script>

<style lang="scss" scoped></style>
