import storeMixin from '../mixins/vuex-store';

const getInitialState = () => ({
    selectedWeek: null,
    selectedYear: null,
    visibleWeeks: [],
    plannerFirstDay: null,
    plannerLastDay: null,
});

const params = {
    resource: 'selected-dates',
    readOnly: true,
};

const {
    mutations: { resetState },
    actions: { resetState: resetStateAction },
} = storeMixin(params);

const store = {
    namespaced: true,

    /**
     * No default state available.
     */
    state: getInitialState(),

    /**
     * No default getters available:
     */
    getters: {
        getCurrentYearWeek(state) {
            // 2020 becomes 202000. 202000 + 52 = 202052.
            // This also ensures a falsy value is returned when either variable isn't set.
            return state.selectedYear * 100 + state.selectedWeek;
        },
    },

    /**
     * default mutations available:
     * - resetState
     */
    mutations: {
        setSelectedWeek(state, weekNumber) {
            state.selectedWeek = weekNumber;
        },
        setSelectedYear(state, year) {
            state.selectedYear = year;
        },
        setVisibleWeeks(state, visibleWeeks) {
            state.visibleWeeks = visibleWeeks;
        },
        setPlannerFirstDay(state, plannerFirstDay) {
            state.plannerFirstDay = plannerFirstDay;
        },
        setPlannerLastDay(state, plannerLastDay) {
            state.plannerLastDay = plannerLastDay;
        },
        resetState,
    },

    /**
     * default actions available:
     * - resetState
     */
    actions: {
        setSelectedYearWeek({ commit }, { year, weekNumber }) {
            commit('setSelectedYear', year);
            commit('setSelectedWeek', weekNumber);
        },
        setVisibleWeeks({ commit }, { visibleWeeks }) {
            commit('setVisibleWeeks', visibleWeeks);
        },
        setPlannerFirstDay({ commit }, plannerFirstDay) {
            commit('setPlannerFirstDay', plannerFirstDay);
        },
        setPlannerLastDay({ commit }, plannerLastDay) {
            commit('setPlannerLastDay', plannerLastDay);
        },
        resetState: resetStateAction,
        setSelectedYearWeekFromEntity({ rootGetters, dispatch }, { startDate }) {
            const { weekOfYear, year } = rootGetters['weeks/getWeekByDate'](startDate);
            dispatch('setSelectedYearWeek', { year: Number(year), weekNumber: Number(weekOfYear) });
        },
    },
};

export default store;
