<template>
    <v-container class="rtls-text-field-container" :class="cssClass">
        <div class="rtls-text-field-container__label-wrapper">
            <p v-if="label" class="rtls-text-field-container__label">
                {{ $t(label) | toSentenceCase }}
            </p>
            <span v-if="isRequired" class="asterisk">*</span>
        </div>
        <div class="rtls-text-field-container__control">
            <v-text-field
                v-model="text"
                :data-id-e2e="dataIdE2E"
                :class="white ? 'rtls-text-field--white' : 'rtls-text-field--grey'"
                class="rtls-text-field"
                :placeholder="$t(placeholder) | toSentenceCase"
                :disabled="isDisabled"
                :rules="rules"
                validate-on-blur
                @keydown.enter="handleEnter"
                @blur="onBlur"
                @input="handleInput"
                @change="change"
                @click="click"
            />
        </div>
    </v-container>
</template>

<script>
import { trim, debounce, isEmpty } from 'lodash';
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        label: String,
        cssClass: String,
        isRequired: {
            type: Boolean,
            default: false,
        },
        dataIdE2E: String,
        updateOnInput: {
            type: Boolean,
            default: false,
        },
        saveWhitespace: {
            type: Boolean,
            default: false,
        },
        preventDefault: Boolean,
        white: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    data() {
        return {
            textField: null,
        };
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
        text: {
            get() {
                return this.model;
            },
            set(newValue) {
                this.textField = this.saveWhitespace ? newValue : trim(newValue);
            },
        },
    },

    created() {
        this.textField = this.model;
    },

    methods: {
        updateStagingArea() {
            this.model = this.textField;
        },

        onInput() {
            this.updateStagingArea();
            this.$emit('input');
        },

        debouncedOnInput: debounce(
            function() {
                this.onInput();
            },
            400,
            { leading: true }
        ),

        handleInput() {
            if (!this.updateOnInput) return;

            if (isEmpty(this.textField)) {
                this.onInput();
            } else {
                this.debouncedOnInput();
            }
        },

        onBlur() {
            this.textField = trim(this.textField);
            this.updateStagingArea();

            if (this.updateOnInput) return;

            this.$emit('blur');
        },

        change(value) {
            this.$emit('change', value);
        },

        click(event) {
            this.$emit('click', event);
        },
        handleEnter(event) {
            this.updateStagingArea();
            if (this.preventDefault) {
                event.preventDefault();
            } else {
                // nextTick is needed to allow model setter to be called and staging area to be updated.
                // without nextTick model can be outdated if the user presses enter button very quickly after text entering
                this.$nextTick(() => {
                    this.$emit('keydown', event);
                });
            }
        },
    },
};
</script>
