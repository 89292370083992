var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("rtls-toggle", {
    attrs: {
      value: _vm.model,
      "left-toggle": _vm.leftToggle,
      "right-toggle": _vm.rightToggle,
    },
    on: { input: _vm.onToggleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }