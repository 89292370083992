<template>
    <v-container class="rtls-textarea-container" :class="cssClass">
        <p v-if="label" class="rtls-textarea-container__label">
            {{ $t(label) | toSentenceCase }}
        </p>
        <v-textarea
            v-model="text"
            :data-id-e2e="dataIdE2E"
            class="rtls-textarea"
            :rows="rows"
            :outlined="true"
            :auto-grow="autoGrow"
            :placeholder="$t(placeholder) || $t('placeholders.enterHere') | toSentenceCase"
            :disabled="isDisabled"
            :rules="rules"
            :no-resize="noResize"
            validate-on-blur
            @blur="updateStagingArea"
        />
    </v-container>
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        label: String,
        cssClass: String,
        autoGrow: {
            type: Boolean,
            default: true,
        },
        rows: {
            type: Number,
            default: 3,
        },
        dataIdE2E: String,
        noResize: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            textField: null,
        };
    },

    computed: {
        text: {
            get() {
                return this.model;
            },
            set(newValue) {
                // using null for empty strings
                this.textField = newValue === '' ? null : newValue;
            },
        },
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    created() {
        this.textField = this.model;
    },

    methods: {
        updateStagingArea() {
            this.model = this.textField;
            this.$emit('blur');
        },
    },
};
</script>
