<template functional>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 4.44444V0L5.55556 0L7.38333 1.82778L1.82778 7.38333L0 5.55556L0 10H4.44444L2.61667 8.17222L8.17222 2.61667L10 4.44444Z"
            fill="#39477B"
        />
    </svg>
</template>

<script>
export default {
    name: 'ExpandAll',
};
</script>
