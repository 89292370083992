import Vue from 'vue';
import { get, uniq, flatten } from 'lodash';
import namespaces from '@enums/namespaces';
import getEventToEmitOnMutation from './vuex-staging-area-mutation';

export const getInitialFormState = () => ({
    stagingArea: {},
    validationStates: {},
});

export const storeFormMixin = resource => ({
    state: getInitialFormState(),

    getters: {
        getStagingAreaField: state => ({ namespace, fieldPath, fieldName }) => {
            return get(
                state.stagingArea[namespace],
                fieldPath ? `${fieldPath}.${fieldName}` : fieldName
            );
        },
    },

    mutations: {
        /**
         * only use this mutation if discussed with a tech/epic lead
         *
         * this mutation allows any field to be updated from any part of the application
         * which goes against the established form framework approach
         * should be used carefully
         */
        setStagingAreaField(state, { namespace, fieldPath, fieldName, value }) {
            let stateToUpdate = state.stagingArea[namespace];
            if (fieldPath) {
                stateToUpdate = get(stateToUpdate, fieldPath);
            }

            Vue.set(stateToUpdate, fieldName, value);
        },

        setStagingAreaFields(state, { namespace, fieldsToUpdate }) {
            (fieldsToUpdate || []).forEach(field => {
                const { fieldPath, fieldName, value } = field;
                let stateToUpdate = state.stagingArea[namespace];

                if (fieldPath) {
                    stateToUpdate = get(stateToUpdate, fieldPath);
                }
                Vue.set(stateToUpdate, fieldName, value);
            });
        },

        resetStagingArea(state, namespace) {
            if (namespace) {
                Vue.set(state.stagingArea, namespace, {});
                Vue.set(state.validationStates, namespace, { isValid: true });
            } else {
                state.stagingArea = {};
                state.validationStates = {};
            }
        },
    },

    actions: {
        /**
         * only use this action if discussed with a tech/epic lead
         *
         * this action allows any field to be updated from any part of the application
         * which goes against the established form framework approach
         * should be used carefully
         */
        async setStagingAreaField(
            { commit, dispatch },
            {
                namespace,
                fieldPath,
                fieldName,
                value,
                fullEventPath,
                emitEvents = true,
                skipResetForecastingForInvalid = false,
                requiredEvents = [],
            }
        ) {
            // TODO: https://owlabs.atlassian.net/browse/PROWEB-1355
            // Remove `await` and instead ensure staging area model validation happens before the next call to check
            // validation state and emit events.
            await commit('setStagingAreaField', { namespace, fieldPath, fieldName, value });
            await dispatch('emitEventsOnMutation', {
                namespace,
                fieldsToUpdate: [{ namespace, fieldPath, fieldName, value, fullEventPath }],
                skipResetForecastingForInvalid,
                requiredEvents,
                emitEvents,
            });
        },

        // Use this action if you want to update multiple attributes in the
        // staging area at once, where each attribute may fire the same events.
        async setStagingAreaFields(
            { commit, dispatch },
            { namespace, fieldsToUpdate, emitEvents = true }
        ) {
            // TODO: https://owlabs.atlassian.net/browse/PROWEB-1355
            // Remove `await` and instead ensure staging area model validation happens before the next call to check
            // validation state and emit events.
            await commit('setStagingAreaFields', {
                namespace,
                fieldsToUpdate,
            });

            await dispatch('emitEventsOnMutation', { namespace, fieldsToUpdate, emitEvents });
        },

        emitEventsOnMutation(
            { state },
            {
                namespace,
                fieldsToUpdate,
                skipResetForecastingForInvalid = false,
                requiredEvents = [],
                emitEvents = true,
            }
        ) {
            const validationState = state.validationStates[namespace];
            const events = uniq(
                flatten(
                    fieldsToUpdate.map(option => {
                        return getEventToEmitOnMutation(resource, option);
                    })
                )
            );
            const eventsToEmit = validationState.isValid
                ? [...events, ...requiredEvents]
                : [
                      ...events.filter(event => event && event.emitIfInvalid),
                      ...(skipResetForecastingForInvalid
                          ? []
                          : [{ event: `${resource}-is-invalid` }]),
                  ];
            eventsToEmit.forEach(eventToEmit => {
                if (eventToEmit) {
                    // in create mode we can emit just events with flag emitInCreateMode
                    // in edit mode emit all events
                    if (!emitEvents && !eventToEmit.forceEmit) {
                        return;
                    }
                    if (!eventToEmit.emitInCreateMode && namespace === namespaces.default) {
                        return;
                    }
                    this.$app.globalEmit(eventToEmit.event, {
                        namespace,
                    });
                }
            });
        },

        async resetStagingArea({ commit }, { namespace }) {
            commit('resetStagingArea', namespace);
        },

        async createStagingAreaNamespace(
            { state, commit },
            { namespace = namespaces.default, reset = false } = {}
        ) {
            // if the namespace already exists we do nothing, unless reset is true
            if (reset || !state.stagingArea[namespace]) commit('resetStagingArea', namespace);
        },
    },
});
