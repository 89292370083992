var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value",
      },
    ],
    ref: "input",
    staticClass: "ag-numeric",
    domProps: { value: _vm.value },
    on: {
      keydown: function ($event) {
        return _vm.onKeyDown($event)
      },
      input: function ($event) {
        if ($event.target.composing) return
        _vm.value = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }