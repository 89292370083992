'use strict';

import path from 'path';

// Register vue component by loading all appropriate files inside the components folder
// Inspired by: http://stackoverflow.com/questions/29421409/how-to-load-all-files-in-a-subdirectories-using-webpack-without-require-statemen

const htmlRegex = /\.html$/;
const vueRegex = /\.vue$/;

export default function registerComponents(Vue, req) {
    // r is the webpack require function
    const allFiles = req.keys();

    // First register all single-file components
    const vueFiles = allFiles.filter(file => {
        return file.match(vueRegex);
    });
    vueFiles.forEach(file => {
        const componentName = path.basename(file, '.vue');
        const componentFile = req(file);
        // Handle module syntax or commonJS
        const component = Object.prototype.hasOwnProperty.call(componentFile, 'default')
            ? componentFile.default
            : componentFile;

        Vue.component(componentName, component);
    });

    // Next, find any split file components and register them as well
    const htmlFiles = allFiles.filter(file => {
        return file.match(htmlRegex);
    });

    // For now let's assume that all components have an html file, but that's not necessarily true going forward
    // We need to individually register every component, some might have a viewModel as a separated js file
    htmlFiles.forEach(htmlFile => {
        const componentName = path.basename(htmlFile, '.html');
        const jsFile = htmlFile.replace('.html', '.js');

        const template = req(htmlFile);
        let vm = allFiles.includes(jsFile) ? req(jsFile) : {};
        if (vm.default) vm = vm.default; // handle ES6 syntax in view models that use export default { ...viewModeldeclaration... }

        Vue.component(componentName, Object.assign(vm, { template }));
    });
}
