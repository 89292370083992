var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "losenge-date-display", class: _vm.flexClass },
    [
      _c("div", { class: _vm.compactClass }, [
        _vm.isWeeksEqual
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm._f("weekNumberClient")(_vm.startDateWeek)) +
                  " "
              ),
            ])
          : _vm.hasDates
          ? _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("weekNumberClient")(_vm.startDateWeek)) +
                  " -\n            " +
                  _vm._s(_vm._f("weekNumberClient")(_vm.endDateWeek)) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isDatesEqual
          ? _c("span", { staticClass: "date_spacer" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("datesInYear")(_vm.startDate)) +
                  "\n        "
              ),
            ])
          : _vm.hasDates
          ? _c("span", { staticClass: "date_spacer" }, [
              _vm._v(
                _vm._s(_vm._f("datesInYear")(_vm.startDate)) +
                  " — " +
                  _vm._s(_vm._f("datesInYear")(_vm.endDate))
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }