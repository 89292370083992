import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      staticClass: "positive-action-button btn",
      attrs: {
        depressed: "",
        disabled: _vm.isDisabled,
        "data-id-e2e": _vm.dataIdE2e,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }