<template>
    <div class="summary-header">
        <div class="summary-header__label">{{ params.displayName }}</div>
        <div class="summary-header__toggle-container">
            <span>{{ $t('planning.promotionsMaintenance.funding.show') | toSentenceCase }}:</span>
            <v-btn-toggle v-model="toggleMode" mandatory @change="onToggle">
                <v-btn large :value="fundingToggleStates.all">
                    {{ $t('planning.promotionsMaintenance.funding.all') | toSentenceCase }}
                </v-btn>
                <v-btn large :value="fundingToggleStates.volumeFunding">
                    {{ $t('planning.promotionsMaintenance.funding.volume') | toSentenceCase }}
                </v-btn>
                <v-btn large :value="fundingToggleStates.lumpFunding">
                    {{ $t('planning.promotionsMaintenance.funding.lump') | toSentenceCase }}
                </v-btn>
                <v-btn large :value="fundingToggleStates.variableFunding">
                    {{ $t('planning.promotionsMaintenance.funding.variable') | toSentenceCase }}
                </v-btn>
            </v-btn-toggle>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { get, has } from 'lodash';
import fundingToggleStatesEnum from '@enums/funding-toggle-states';
import promoTabsEnum from '@enums/promotion-tabs';

export default Vue.extend({
    data() {
        return {
            toggleMode: fundingToggleStatesEnum.volumeFunding,
            fundingToggleStates: fundingToggleStatesEnum,
            promoTabsEnum,
        };
    },
    computed: {
        ...mapState('promotions', ['unsavedPromotion']),
        showUnsaveMessage() {
            return get(
                this.unsavedPromotion[this.params.promotionId],
                promoTabsEnum.funding,
                false
            );
        },
    },
    mounted() {
        this.toggleMode = has(this.params, 'toggleMode') ? this.params.toggleMode() : false;
    },
    methods: {
        onToggle(value) {
            this.params.setToggleMode({ value, params: this.params });
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.summary-header {
    flex-grow: 1;
    height: 100%;
    display: flex;

    flex-direction: column;
    justify-content: space-between;

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__toggle-container {
        display: flex;
        margin-top: 2rem;
        span {
            padding-top: 0.2rem;
            margin-right: 1rem;
        }
    }

    &__banner {
        height: 3rem;
        margin: 1rem;
        padding: 0.5rem 1rem;
        background-color: $promo-rag-2;
        border: 0.1rem solid $promo-orange-dark;
        border-radius: 0.5rem;
        font-weight: normal;
    }
}
</style>
