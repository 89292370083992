import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["scenario-viewer", _vm.selectedFadedClass] },
    [
      _vm._l(_vm.fields, function (item, colIndex) {
        return _c(
          "div",
          {
            key: "field::" + _vm.scenario._id + "::" + colIndex,
            ref: `scenario_${_vm.scenario._id}`,
            refInFor: true,
            staticClass:
              "scenario-viewer__grid-cell scenario-viewer__grid-cell--main-content",
            class: [item.cellClasses, item.contentClasses],
            on: {
              click: function ($event) {
                return _vm.onScenarioClick(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "scenario-viewer__data", class: item.dataClasses },
              [
                item.icon
                  ? _c(
                      "div",
                      [
                        _vm.scenario[item.field] ||
                        _vm.getPropByPath(item.propPath)
                          ? _c(
                              "icon",
                              _vm._b(
                                { attrs: { "icon-name": item.icon } },
                                "icon",
                                _vm.constructVBindObj({ ...item }),
                                false
                              )
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : item.functionName
                  ? _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.callAction(
                              item.functionName,
                              _vm.scenario[item.functionParam]
                            )
                          )
                        ),
                      ]),
                    ])
                  : item.component
                  ? _c(
                      "div",
                      [
                        (!item.onlyShowWhenSelected || _vm.isSelected) &&
                        _vm.isShowOnlyInFavorite(
                          item.onlyFavoriteScenariosComponent
                        )
                          ? _c(
                              item.component.name,
                              _vm._g(
                                _vm._b(
                                  { tag: "component" },
                                  "component",
                                  _vm.constructVBindObj({
                                    context: item.propPath
                                      ? _vm.getPropByPath(item.propPath)
                                      : _vm.scenario,
                                    field: item.field,
                                    ...item.component,
                                  }),
                                  false
                                ),
                                _vm.constructVBindEventObj({
                                  events: _vm.getAttribute(
                                    item.component,
                                    "events"
                                  ),
                                  context: _vm.scenario,
                                })
                              )
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.getAttribute(_vm.scenario, item.field))
                      ),
                    ]),
              ]
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.isSelected
        ? _c(
            "div",
            {
              key: _vm.scenario._id + ":: promotions-viewer",
              staticClass: "scenario-viewer__promotions-viewer",
            },
            [_c("promotions-viewer")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "cannotReassignDialog",
        staticClass: "confirm-dialog",
        attrs: {
          "has-activator": false,
          "question-text": _vm.$t(_vm.questionTextKey),
          "action-text": _vm.$t("actions.ok"),
        },
      }),
      _vm._v(" "),
      _vm.scenario.applyingNotifications
        ? _c(VOverlay, {
            staticClass: "scenario-viewer__overlay",
            attrs: { absolute: true, value: true, opacity: 0.3 },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }