import { merge, filter, map, sortBy, find, lowerCase } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    loyaltyPoints: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getLoyaltyPointsById
     */
    getters: {
        getLoyaltyPointsOptionsByFilter: state => filters => {
            const options = sortBy(
                map(filter(state.loyaltyPoints, filters), loyaltyPoint => {
                    return {
                        value: loyaltyPoint.identifier,
                        text: loyaltyPoint.description,
                        isLoyaltyPoint: true,
                    };
                }),
                [i => lowerCase(i.text)]
            );

            return options;
        },

        getRedeemLoyaltyPointsOptions: (state, getters) => {
            return getters.getLoyaltyPointsOptionsByFilter({ isRedeem: true, isActive: true });
        },

        getAwardLoyaltyPointsOptions: (state, getters) => {
            return getters.getLoyaltyPointsOptionsByFilter({ isAward: true, isActive: true });
        },

        getLoyaltyPointByIdentifier: state => ({ identifier }) => {
            return find(state.loyaltyPoints, { identifier });
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setLoyaltyPoints
     * - deleteLoyaltyPoints
     * - updateLoyaltyPoints
     * - addLoyaltyPoints
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchLoyaltyPoints
     * - createLoyaltyPoints
     * - deleteLoyaltyPoints
     * - updateLoyaltyPoints
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'loyalty-point',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
