<template>
    <div class="funded-promotions-list">
        <div
            v-for="(item, colIndex) in fields"
            :key="`${promotion._id}::${colIndex}`"
            class="funded-promotions-list__grid-cell"
            :class="item.cellClasses"
        >
            <div class="funded-promotions-list__data" :class="getColourClass(promotion)">
                <div v-if="item.functionName">
                    <span>{{ callAction(item.functionName, promotion) }}</span>
                </div>

                <div v-else-if="item.component">
                    <component
                        :is="item.component.name"
                        v-bind="
                            constructVBindObj({
                                context: promotion,
                                field: item.field,
                                ...item.component,
                            })
                        "
                        >{{ $t(item.component.text) | toSentenceCase }}
                    </component>
                </div>

                <span v-else class="truncate-text">
                    {{ getAttribute(promotion, item.field) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { size } from 'lodash';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin],

    props: {
        promotion: {
            type: Object,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
    },

    methods: {
        getProductCount(params) {
            const numberOfProducts = size(params.products) || 0;
            return this.$tc(`supplierCommitments.products`, numberOfProducts);
        },

        getColourClass({ effectivenessRating }) {
            if (effectivenessRating) {
                return `funded-promotions-list__border-colour--${effectivenessRating}`;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.funded-promotions-list {
    @include flex-row;
    width: 100%;

    &__data {
        @include flex-column;
        padding-top: 0.75rem;
        padding-left: 3rem;
        padding-right: 3rem;
        min-height: 3.5rem;
        background-color: $promo-white;
        border-top: $promo-divider-accent-colour 0.2rem solid;
        border-bottom: $border-shadow 0.1rem solid;
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 1.2rem;
        color: $text-colour;
        letter-spacing: 0;
        box-shadow: 0 0.4rem 0.4rem 0 $box-shadow-color, 0 0.2rem 0 0 $box-shadow-color;

        &:hover {
            cursor: pointer;
        }
    }

    &__border-colour {
        @include promo-rag-colours(border-top-color);
    }

    &__grid-cell {
        padding-top: 0.5rem;
        display: contents;
    }

    &__last-column {
        flex: 1 0 0;
    }
}
</style>
