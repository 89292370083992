// It doesn't make sense to export this class as default from this module, even though it's the only export currently.
// Ignore this rule until we export something else from here.
/* eslint import/prefer-default-export: 1 */

import { pick, get, isArray, reduce, isFunction } from 'lodash';

/**
 * The structure to describe a function, which exists on a parent component,
 * which can be called and used as the prop value for the child component.
 */
export default class FunctionProp {
    /**
     * Constructs the object.
     *
     * @param {String} name - The name of the prop to be assigned to.
     * @param {Array<String>} contextualParameters - The parameters to pass into the function which exist on the context.
     * @param {Function} executor - The function which exists on the parent component to be executed.
     * @param {Object} staticProps - Object with parameters to pass into the executor function.
     */
    constructor(name, contextualParameters, executor, staticProps) {
        this.name = name;
        this.contextualParameters = contextualParameters;
        this.executor = executor;
        this.staticProps = staticProps;
    }
}

/**
 * Function returns default value property name depending on additional details config object
 * @param {Object} [{all: true}] additional details configuration object
 */
export function getDefaultValuePropName({ all }) {
    return all ? 'dynamicDefaultValue' : 'defaultValue';
}

/**
 * Function returns default value  depending on additional details config object
 * @param {Object} [{all: false, items: []}] additional details configuration object
 * @param {Object} object that allow us to specify a computed default value
 */
export function getDefaultValuePropValue({ all, items = [] }, dynamicDefaultValue) {
    return all ? dynamicDefaultValue : items;
}

/**
 * Dynamically constructs the binding object for a component based on config.
 */
export function constructVBindObj({
    context,
    field,
    props,
    contextualProps,
    functionProps,
    computedProps,
    defaultProp,
    // fullContextAsPropName allows for an entire object to be passed in as a prop.
    // For example, when rendering the Edit Scenario form, it requires the entire
    // Scenario context as a prop.
    fullContextAsPropName,
    componentRef,
}) {
    let propsFromContext = {};
    let functionPropsFromContext = {};
    let computedPropsFromContext = {};

    if (context) {
        propsFromContext = isArray(contextualProps)
            ? pick(context, contextualProps)
            : // allow us to rename the props via before they get bound to the component
              reduce(
                  contextualProps,
                  (propObj, contextKey, desiredKey) => ({
                      ...propObj,
                      [desiredKey]: get(context, contextKey, null),
                  }),
                  {}
              );

        // Generate the props which use a function to generate their value.
        functionPropsFromContext = (functionProps || []).reduce((acc, prop) => {
            const params = (prop.contextualParameters || []).reduce(
                (paramsAcc, contextualParam) => {
                    paramsAcc[contextualParam] = context[contextualParam];

                    return paramsAcc;
                },
                { ...prop.staticProps }
            );

            if (isFunction(prop.executor)) {
                acc[prop.name] = prop.executor(params);
            } else {
                acc[prop.name] = componentRef[prop.executor](params);
            }

            return acc;
        }, {});

        // Generate the props which use a computed prop to get their value.
        computedPropsFromContext = (computedProps || []).reduce((acc, prop) => {
            acc[prop.name] = componentRef[prop.executor];

            return acc;
        }, {});
    }

    const contextAsProp = fullContextAsPropName ? { [fullContextAsPropName]: context } : {};

    return {
        ...props,
        ...propsFromContext,
        ...functionPropsFromContext,
        ...computedPropsFromContext,
        ...contextAsProp,
        [defaultProp]: context ? context[field] : {},
    };
}
