<template functional>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.35527 10.4H8.95527V12H7.35527V10.4ZM7.35527 4H8.95527V8.8H7.35527V4ZM8.14727 0C3.73127 0 0.155273 3.584 0.155273 8C0.155273 12.416 3.73127 16 8.14727 16C12.5713 16 16.1553 12.416 16.1553 8C16.1553 3.584 12.5713 0 8.14727 0ZM8.15527 14.4C4.61927 14.4 1.75527 11.536 1.75527 8C1.75527 4.464 4.61927 1.6 8.15527 1.6C11.6913 1.6 14.5553 4.464 14.5553 8C14.5553 11.536 11.6913 14.4 8.15527 14.4Z"
            fill="#EB995E"
        />
    </svg>
</template>

<script>
export default {
    name: 'OrangeWarningCircle',
};
</script>
