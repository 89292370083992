<template>
    <div class="promotion-categories">
        <div class="promotion-categories__edit">
            <main-dialog
                ref="dialog"
                heading="planning.promotionsMaintenance.products.promotionCategories.editCategoriesListHeader"
                btn-confirm-text="actions.select"
                :confirm-btn-disabled="confirmBtnDisabled"
                @confirm="saveCategories"
                @close="rejectChanges"
            >
                <template v-slot:actions="{ activator }">
                    <v-form ref="categoriesForm" v-model="isValid" @submit.prevent>
                        <vuex-select
                            ref="categoriesSelectDropdown"
                            :options="allCategoryOptions"
                            :getter="() => selectedCategoriesList"
                            :deletable-chips="false"
                            :validations="validationRules"
                            validate-on-create
                            validate-on-blur
                            append-icon="edit"
                            chips
                            multiple
                            finite-list
                            item-text="levelEntryDescription"
                            item-value="levelEntryKey"
                            hide-dropdown
                            v-on="activator"
                            @clicked="openDialog"
                        >
                            <template slot="append">
                                <icon-button icon="edit" v-on="activator" @click="openDialog" />
                            </template>
                        </vuex-select>
                    </v-form>
                </template>
                <template v-slot:default>
                    <div class="labels-container">
                        <div class="label">{{ $tkey('units') | toSentenceCase }}</div>
                        <div class="label">{{ $tkey('categories') | toSentenceCase }}</div>
                    </div>
                    <categories-select
                        v-if="isOpen"
                        ref="categoriesSelect"
                        :tree="tree"
                        :update="updateCategories"
                        @change="onChange"
                    />
                </template>
            </main-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { cloneDeep, uniqBy, isEmpty, sortBy, get, uniq } from 'lodash';
import { isStoreWidePromotion, isCategoryWidePromotion } from '@sharedModules/promotion-utils';
import pogScope from '@enums/product-offer-group-scope';
import { offer } from '@enums/promotion-tabs';

export default {
    localizationKey: 'planning.promotionsMaintenance.products.promotionCategories',
    props: {
        namespace: {
            type: String,
            default: 'default',
        },
        pogIndex: {
            type: Number,
            default: null,
        },
        isCategoryWidePromotion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            categoriesCache: [],
            isOpen: false,
            confirmBtnDisabled: false,
            chipsToDisplay: 3,
            isValid: true,
        };
    },
    computed: {
        ...mapGetters('hierarchy', ['getCategoriesTree', 'getAllCategories']),
        ...mapGetters('scenarios', ['selectedScenario', 'getScenarioById']),
        ...mapGetters('context', ['userCategoryKeys']),
        ...mapState('promotions', ['stagingArea']),
        ...mapState('clientConfig', ['hierarchyConfig']),
        stagingAreaForNamespace() {
            return this.stagingArea[this.namespace];
        },
        validationRules() {
            return [
                {
                    validator: () =>
                        // Ensure that at least one store group has been selected.
                        !!this.selectedCategoriesList.length ||
                        this.$tkey('validationMessageShort'),
                },
            ];
        },
        promotionScenario() {
            let promotionScenario = this.selectedScenario;
            // allocated promotion was opened in parking lot
            if (!promotionScenario && this.stagingAreaForNamespace.scenarioId) {
                promotionScenario = this.getScenarioById({
                    _id: this.stagingAreaForNamespace.scenarioId,
                    usePluralResourceName: true,
                });
            }
            return promotionScenario;
        },
        tree() {
            const selectedCategories = this.selectedCategoriesList.map(
                category => category.levelEntryKey
            );
            const parentCategories = this.promotionScenario
                ? this.promotionScenario.categories
                : [];

            return this.getCategoriesTree({
                allCategories: true,
                parentCategories,
                isEditable: true,
                selectedCategories,
                lockedCategories: this.lockedCategories,
            });
        },
        allCategoryOptions() {
            // for new created promos on parkinglot without scenario
            if (!this.promotionScenario) {
                return this.getAllCategories;
            }
            // disabled: true use for prevent remove categories using backspace and delete buttons
            // for editing categories we are using modal
            return this.getAllCategories
                .filter(({ levelEntryKey }) =>
                    this.promotionScenario.categories.includes(levelEntryKey)
                )
                .map(cat => ({ ...cat, disabled: true }));
        },
        lockedCategories() {
            const promotion = this.stagingAreaForNamespace;
            if (
                isCategoryWidePromotion(promotion) &&
                this.productOfferGroup.scope === pogScope.categoryWide
            ) {
                return this.productOfferGroup.userSelectedCategories.filter(
                    c => !this.userCategoryKeys.includes(c.levelEntryKey)
                );
            }
            return uniqBy(
                promotion.products
                    .filter(p => this.productOfferGroup.products.includes(p.productKey))
                    .map(product =>
                        product.hierarchy.find(
                            item => item.level === this.hierarchyConfig.categoryLevel
                        )
                    ),
                'levelEntryKey'
            );
        },
        productOfferGroup() {
            return this.stagingAreaForNamespace.productOfferGroups[this.pogIndex] || {};
        },
        selectedCategoriesList() {
            return (
                uniqBy(
                    sortBy(
                        get(this.productOfferGroup, 'userSelectedCategories', []),
                        'levelEntryDescription'
                    ),
                    'levelEntryKey'
                ) || []
            );
        },

        promotionUserSelectedCategories() {
            return this.stagingAreaForNamespace.userSelectedCategories;
        },
        promotionProducts() {
            return this.stagingAreaForNamespace.products;
        },
    },
    mounted() {
        this.$refs.categoriesForm.validate();
    },
    methods: {
        ...mapMutations('promotions', [
            'setStagingAreaField',
            'setStagingAreaFields',
            'setUnsavedPromotion',
        ]),
        ...mapActions('promotions', ['submitForm', 'getPromotionUserSelectedCategories']),
        async saveCategories() {
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
            this.$emit('change');
            this.closeDialog();
            if (this.$refs.categoriesForm) {
                this.$refs.categoriesForm.validate();
            }
        },

        isEmpty,

        rejectChanges() {
            const pogIndex = this.stagingAreaForNamespace.productOfferGroups.findIndex(
                pog => pog._id === this.productOfferGroup._id
            );
            const promotion = this.stagingAreaForNamespace;
            const fieldsToUpdate = [
                {
                    fieldName: 'userSelectedCategories',
                    value: isStoreWidePromotion(promotion)
                        ? []
                        : uniqBy(
                              [...this.promotionUserSelectedCategories, ...this.categoriesCache],
                              'levelEntryKey'
                          ),
                },
                {
                    fieldPath: `productOfferGroups[${pogIndex}]`,
                    fieldName: 'userSelectedCategories',
                    value: this.categoriesCache,
                },
            ];
            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate,
            });
            this.closeDialog();
        },

        closeDialog() {
            if (this.$refs.dialog) {
                this.isOpen = false;
                this.confirmBtnDisabled = false;
                this.$refs.dialog.close();
            }
        },

        async updateCategories(categories) {
            const filteredCategories = this.getAllCategories.filter(category =>
                categories.includes(category.levelEntryKey)
            );

            const pogIndex = this.stagingAreaForNamespace.productOfferGroups.findIndex(
                pog => pog._id === this.productOfferGroup._id
            );

            const currentPogScope = this.stagingAreaForNamespace.productOfferGroups[pogIndex].scope;
            const otherPogs = this.stagingAreaForNamespace.productOfferGroups.filter(
                pog => pog._id !== this.productOfferGroup._id
            );
            const otherPogUserSelectedCategories = uniqBy(
                otherPogs.reduce((acc, item) => [...acc, ...item.userSelectedCategories], []),
                'levelEntryKey'
            );

            let productCategories = [];
            if (currentPogScope === pogScope.categoryWide) {
                productCategories = this.stagingAreaForNamespace.products.map(p => p.category);
            }
            const promotion = this.stagingAreaForNamespace;
            const fieldsToUpdate = [
                {
                    fieldName: 'userSelectedCategories',
                    value: isStoreWidePromotion(promotion)
                        ? []
                        : uniqBy(
                              [...otherPogUserSelectedCategories, ...filteredCategories],
                              'levelEntryKey'
                          ),
                },
                {
                    fieldPath: `productOfferGroups[${pogIndex}]`,
                    fieldName: 'userSelectedCategories',
                    value: filteredCategories,
                },
                ...(currentPogScope === pogScope.categoryWide
                    ? [
                          {
                              fieldName: 'categories',
                              value: uniq([
                                  ...productCategories,
                                  ...filteredCategories.map(c => c.levelEntryKey),
                              ]),
                          },
                      ]
                    : []),
            ];

            await this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate,
            });
        },

        openDialog() {
            this.isOpen = true;
            this.$refs.dialog.open();
            this.categoriesCache = cloneDeep(this.selectedCategoriesList);
        },
        onChange() {
            this.$nextTick(() => {
                this.confirmBtnDisabled = this.$refs.categoriesSelect.isInvalid;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';
.promotion-categories {
    display: flex;
    column-gap: 1rem;

    &__header {
        @include flex-row;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }

    &__list {
        margin-bottom: 1rem;

        .category {
            font-weight: bold;
            padding: 0.5rem 0;
            border-bottom: 0.1rem solid $promo-divider-colour;
        }
    }

    &__divider {
        border-color: $promo-campaign-border-4;
    }

    ::v-deep {
        .v-chip {
            height: 2rem;
        }
    }
}

.labels-container {
    @include flex-row;
    margin-top: 0.5rem;
    .label {
        width: 50%;
        font-size: 1.2rem;
    }
}
</style>
