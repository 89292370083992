import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "subtype" },
    [
      _c("vuex-select", {
        staticClass: "subtype__select",
        attrs: {
          placeholder: _vm._f("toSentenceCase")(
            _vm.$t("planning.nominations.selectSubType")
          ),
          options: _vm.options,
          "item-value": "key",
          "return-object": "",
          getter: () => _vm.selectedValue,
          setter: (value) => (_vm.selectedValue = value),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "span",
            {
              staticClass: "subtype__button font-weight-bold",
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.cancel"))) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "subtype__button",
              attrs: { text: "", secondary: "", disabled: !_vm.selectedValue },
              on: {
                click: function ($event) {
                  return _vm.emitSet({ selectedValue: _vm.selectedValue })
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("set"))) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }