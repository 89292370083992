<template functional>
    <div
        class="workflow-task-alert clickable"
        :class="[
            props.compact ? 'workflow-task-alert--one-column' : 'workflow-task-alert--two-column',
            `workflow-task-alert--${props.priority.toLowerCase()}`,
        ]"
        @click="$options.methods.alertClicked({ subCampaignId: props.subCampaignId, listeners })"
    >
        <div class="workflow-task-alert__body">
            <div class="workflow-task-alert__header">
                <div class="workflow-task-alert__header--name">{{ props.subCampaignName }}</div>
                <div
                    class="workflow-task-alert__header--tag"
                    :class="`workflow-task-alert__header--${props.priority.toLowerCase()}`"
                >
                    {{
                        $options.methods.getTag({
                            deadlineDateTime: props.deadlineDateTime,
                            dueDateTime: props.dueDateTime,
                        })
                    }}
                </div>
            </div>
            <div class="workflow-task-alert__hierarchyGroup">
                {{ $options.methods.getHierarchyGroup(props.hierarchyGroup) }}
            </div>
            <div class="workflow-task-alert__priority">{{ props.priority }}</div>
            <div
                class="workflow-task-alert__message"
                :class="`workflow-task-alert__message--${props.priority.toLowerCase()}`"
            >
                {{
                    $options.methods.getAlertMessage({
                        alertMessageKey: props.alertMessageKey,
                        entity: props.entity,
                        task: props.task,
                        deadlineDateTime: props.deadlineDateTime,
                    })
                }}
            </div>
        </div>
        <div
            v-if="!props.compact"
            class="workflow-task-alert__arrow"
            :class="`workflow-task-alert__arrow--${props.priority.toLowerCase()}`"
        >
            <icon icon-name="navigate_next" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';
import navigation from '@/js/navigation';

export default {
    props: {
        subCampaignName: {
            type: String,
            required: true,
        },
        subCampaignId: {
            type: String,
            required: true,
        },
        alertMessageKey: {
            type: String,
            required: true,
        },
        priority: {
            type: String,
            required: true,
        },
        hierarchyGroup: {
            type: String,
        },
        task: {
            type: String,
            required: true,
        },
        entity: {
            type: String,
            required: true,
        },
        deadlineDateTime: {
            type: String,
            required: true,
        },
        dueDateTime: {
            type: String,
            required: true,
        },
        compact: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        getAlertMessage({ alertMessageKey, entity, task, deadlineDateTime }) {
            const today = Vue.moment();
            const relativeDueDate = today.to(Vue.moment(deadlineDateTime));
            const absoluteDate = Vue.moment(deadlineDateTime).format('L');

            const alertType = i18n.t(`workflow.taskAlertList.alertTypes.${entity}.${task}`);

            return toSentenceCase(
                i18n.t(`workflow.taskAlertList.messages.${alertMessageKey}`, {
                    alertType,
                    relativeDueDate,
                    absoluteDate,
                })
            );
        },
        getHierarchyGroup(hierarchyGroup) {
            if (hierarchyGroup) {
                return toSentenceCase(i18n.t(`workflow.hierarchyGroup.${hierarchyGroup}`));
            }
        },
        alertClicked({ subCampaignId, listeners }) {
            navigation.toSubCampaignView({ subCampaignId });

            if (listeners['sidebar-closed']) {
                listeners['sidebar-closed']();
            }
        },
        getTag({ deadlineDateTime, dueDateTime }) {
            const today = Vue.moment();
            let tag = null;
            if (today.isAfter(dueDateTime)) {
                tag = i18n.t(`workflow.taskAlertList.due`);
            }

            if (today.isAfter(deadlineDateTime)) {
                tag = i18n.t(`workflow.taskAlertList.overdue`);
            }

            return tag;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.workflow-task-alert {
    border: 0.1rem solid;
    box-sizing: border-box;
    border-radius: 0.3rem;
    margin-bottom: 0.5rem;
    background-color: $promo-white;
    display: grid;
    grid-auto-flow: column;
    @include alerts-colours(border-color);

    &--one-column {
        grid-template-columns: auto;
    }

    &--two-column {
        grid-template-columns: auto 2.5rem;
    }

    &__body {
        display: grid;
        padding: 0.5rem;
    }

    &__arrow {
        display: flex;
        align-items: center;
        color: $promo-white;
        @include alerts-colours(background-color);
    }

    &__header {
        display: grid;
        grid-template-columns: auto 4.5rem;
        font-size: 1.2rem;
        font-weight: bold;

        &--tag {
            text-align: center;
        }
        @include alerts-colours(color);
    }
    &__message {
        font-size: 1.2rem;
        @include alerts-colours(color);
    }
}
</style>
