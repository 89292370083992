var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ma-1 mr-3" },
        [
          _c("main-dialog", {
            ref: "bookmark-modal",
            attrs: {
              heading: "bookmarks.heading",
              "show-positive-action-button": false,
              persistent: false,
              "has-activator": false,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("bookmarks", {
                      on: {
                        reset: function ($event) {
                          return _vm.$router.go()
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "powerbi-container" } }),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "clicked-promotion-dialog",
        attrs: {
          "has-activator": false,
          "question-text": `You clicked on promotion: ${_vm.promotionToView}`,
          "action-text": "ok",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }