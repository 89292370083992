<template>
    <icon-button
        :data-id-e2e="dataIdE2e"
        :class="['delete-btn']"
        :disabled="isDisabled"
        :events="events"
        icon="trash"
        @click.stop="onClick"
    />
</template>

<script>
export default {
    props: {
        events: Object,
        preventDefault: Boolean,
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        dataIdE2e: String,
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    methods: {
        onClick() {
            if (this.isDisabled) return;
            if (!this.disabled && !this.preventDefault) this.$emit('delete');
        },
    },
};
</script>
