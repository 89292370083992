<script>
import { mapState } from 'vuex';
import { get, isNil, sumBy, some } from 'lodash';
import { isTotalRow } from '@sharedModules/funding-utils';
import { toSentenceCase } from '@/js/utils/string-utils';
import inputTypes from '@enums/input-types';
import { recalculateTotalFundingValues } from '@/js/utils/promotion-products-utils';
import InputTextOverrideField from '@/js/pages/planning/components/funding-tab/input-text-override-field';
import TextField from '@/js/pages/planning/components/funding-tab/input-text-field-simple';

const volumeFieldSort = (valueA, valueB) => {
    const valueAToCompare = isNil(valueA.overrideValue)
        ? valueA.defaultValue
        : valueA.overrideValue;
    const valueBToCompare = isNil(valueB.overrideValue)
        ? valueB.defaultValue
        : valueB.overrideValue;
    if (valueAToCompare < valueBToCompare) {
        return -1;
    }
    if (valueAToCompare > valueBToCompare) {
        return 1;
    }
    return 0;
};

export default {
    localizationKey: 'planning.promotionsMaintenance.funding',
    data() {
        return {
            supplierCategories: [],
            gridOptions: {
                columnTypes: {
                    overrideableField: {
                        columnGroupShow: 'open',
                        cellRendererFramework: InputTextOverrideField,
                        cellRendererParams: {
                            visible: params => {
                                return !isTotalRow(params);
                            },
                        },
                        valueGetter: params => {
                            // No need to handle supplier rows as this is calculated by the aggFunc
                            if (params.data.isSupplier || params.data.isSupplierTotal) return;

                            const defaultValue = get(
                                params.data,
                                `${params.colDef.defaultFieldPath}.${
                                    params.colDef.defaultFieldName
                                }`
                            );
                            const overrideValue = get(
                                params.data,
                                `${params.colDef.overrideFieldPath}.${
                                    params.colDef.overrideFieldName
                                }`
                            );
                            const isOverridden = !isNil(overrideValue);
                            return {
                                defaultValue,
                                overrideValue,
                                isOverridden,
                            };
                        },
                        aggFunc: params => {
                            const seed = {
                                defaultValue: 0,
                                overrideValue: 0,
                                isOverridden: false,
                            };
                            let hasOverride = false;

                            const aggregation = params.values.reduce((acc, value) => {
                                if (!value) return acc;

                                acc.defaultValue += value.defaultValue;

                                // Record if one of the values has an override.
                                if (!isNil(value.overrideValue)) {
                                    hasOverride = true;
                                }

                                acc.overrideValue += isNil(value.overrideValue)
                                    ? value.defaultValue
                                    : value.overrideValue;
                                // if any value is overridden, the whole acc should be overridden
                                acc.isOverridden = acc.isOverridden || value.isOverridden;

                                return acc;
                            }, seed);

                            if (!hasOverride) {
                                // If there are no individual overrides, remove the
                                // override from the aggregation.
                                aggregation.overrideValue = undefined;
                            }

                            return aggregation;
                        },
                    },
                },
            },
        };
    },

    computed: {
        ...mapState('clientConfig', ['promoFundingConfig']),
    },

    methods: {
        getVolumeFundingColumns() {
            return [
                {
                    headerName: toSentenceCase(this.$tkey('headers.totalFunding')),
                    headerGroupComponent: 'volumeFundingHeader',
                    headerGroupComponentParams: {
                        promotionId: this.namespace,
                    },
                    groupId: 'volumeFunding',
                    openByDefault: true,
                    children: [
                        // this column need to expand functionality is working on ag-greed
                        // ag-grid required columns for both state open and close in other case column group not expand
                        {
                            columnGroupShow: 'close',
                            maxWidth: 0,
                            sortable: false,
                        },
                        ...this.getTotalFundingColumns(),
                        {
                            headerName: toSentenceCase(this.$tkey('headers.familyTotalVolume')),
                            // not stored in db. generated in created hook when loading the page.
                            field: 'familyTotalVolume',
                            cellClass: ['funding-information', 'volume-funding__cell'],
                            width: 84,
                            columnGroupShow: 'open',
                            aggFunc: 'sum',
                            valueGetter: ({ data: product }) => {
                                const childVolumes = get(product, 'childVolumes', 0);
                                let volume = get(product, 'volumes.totalVolume', 0);
                                if (isNil(volume)) {
                                    // if totalVolume is reverted, we need to calculate it as calcBaseline+calcUplift
                                    // until updated totalVolume comes from BE,
                                    volume =
                                        get(product, 'volumes.forecasted.calcBaseline', 0) +
                                        get(product, 'volumes.forecasted.calcUplift', 0);
                                }
                                return volume + childVolumes;
                            },
                            valueFormatter: this.getCellFormatter('overwrittenVolumeEntry'),
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('headers.percentageVolumeBuffer')
                            ),
                            columnGroupShow: 'open',
                            cellRendererFramework: TextField,
                            cellRendererParams: {
                                suffix: '%',
                                fieldType: inputTypes.plainPercentage,
                                onInputChange: params => {
                                    const defaultValue = 0;
                                    this.setNestedField(params, defaultValue);
                                    this.onCellValueChangedNoApportion({
                                        params,
                                        defaultValue: 0,
                                    });
                                },
                            },
                            cellClass: ['funding-information', 'volume-funding__cell'],
                            width: 90,
                            field: 'volumes.percentageVolumeBuffer',
                            fieldPath: 'volumes',
                            fieldName: 'percentageVolumeBuffer',
                            aggFunc: params => {
                                return this.dynamicSupplierAggFunc({
                                    supplierKey: get(params.data, 'supplierKey'),
                                    categoryKey: get(params.data, 'category'),
                                    colDef: params.colDef,
                                    productValues: params.values,
                                    defaultPlaceholder: params.cellPlaceholder,
                                });
                            },
                        },
                    ],
                },
            ];
        },

        getTotalFundingColumns() {
            const columnsToRender = [
                {
                    headerName: toSentenceCase(this.$tkey('headers.baseline')),
                    field: 'baseline',
                    headerClass: !this.baselineColumnHidden()
                        ? ['funding-information--first-column__left-border', 'volume-funding__cell']
                        : [],
                    cellClass: ['funding-information', 'volume-funding__cell'],
                    cellClassRules: {
                        'funding-information--first-column__left-border': () =>
                            !this.baselineColumnHidden(),
                    },
                    width: 200,
                    type: 'overrideableField',
                    defaultFieldPath: 'volumes.forecasted',
                    defaultFieldName: 'calcBaseline',
                    overrideFieldPath: 'volumes',
                    overrideFieldName: 'baseline',
                    comparator: volumeFieldSort,
                },
                {
                    headerName: toSentenceCase(this.$tkey('headers.uplift')),
                    field: 'uplift',
                    headerClass:
                        this.baselineColumnHidden() && !this.upliftColumnHidden()
                            ? [
                                  'funding-information--first-column__left-border',
                                  'volume-funding__cell',
                              ]
                            : [],
                    cellClass: ['funding-information', 'volume-funding__cell'],
                    cellClassRules: {
                        'funding-information--first-column__left-border': () =>
                            this.baselineColumnHidden() && !this.upliftColumnHidden(),
                    },
                    width: 200,
                    type: 'overrideableField',
                    defaultFieldPath: 'volumes.forecasted',
                    defaultFieldName: 'calcUplift',
                    overrideFieldPath: 'volumes',
                    overrideFieldName: 'uplift',
                    comparator: volumeFieldSort,
                },
                {
                    headerName: toSentenceCase(this.$tkey('headers.totalVolume')),
                    field: 'totalVolume',
                    headerClass:
                        this.baselineColumnHidden() && this.upliftColumnHidden()
                            ? [
                                  'funding-information--first-column__left-border',
                                  'volume-funding__cell',
                              ]
                            : [],
                    cellClass: ['funding-information', 'volume-funding__cell'],
                    cellClassRules: {
                        'funding-information--first-column__left-border': () =>
                            this.baselineColumnHidden() && this.upliftColumnHidden(),
                    },
                    width: 200,
                    type: 'overrideableField',
                    defaultFieldPath: 'volumes.forecasted',
                    defaultSumFieldNames: ['calcUplift', 'calcBaseline'],
                    overrideFieldPath: 'volumes',
                    overrideFieldName: 'totalVolume',
                    overrideSumFieldNames: ['uplift', 'baseline'],
                    comparator: volumeFieldSort,
                    colSpan: params => {
                        if (isTotalRow(params)) {
                            return 3;
                        }
                        return 1;
                    },
                    valueGetter: params => {
                        // No need to handle supplier rows as this is calculated by the aggFunc
                        if (params.data.isSupplier || params.data.isSupplierTotal) return;

                        // defaultValue is a sum of values for fields from defaultSumFieldNames array
                        const defaultValue = sumBy(
                            params.colDef.defaultSumFieldNames,
                            defaultFieldName =>
                                get(
                                    params.data,
                                    `${params.colDef.defaultFieldPath}.${defaultFieldName}`
                                )
                        );
                        const overrideValue = get(
                            params.data,
                            `${params.colDef.overrideFieldPath}.${params.colDef.overrideFieldName}`
                        );
                        // if any of the overrideSumFieldNames values is overridden (not null),
                        // totalVolume field is also overridden
                        const isOverridden = some(
                            params.colDef.overrideSumFieldNames,
                            overrideFieldName =>
                                !isNil(
                                    get(
                                        params.data,
                                        `${params.colDef.overrideFieldPath}.${overrideFieldName}`
                                    )
                                )
                        );
                        return {
                            defaultValue,
                            overrideValue,
                            isOverridden,
                        };
                    },
                },
            ];
            columnsToRender.forEach(column => {
                column.cellRendererParams = {
                    ...column.cellRendererParams,
                    onInputChange: params => {
                        this.setNestedField(params);
                        this.onCellValueChanged(params, 2);
                        const data = params.data;
                        const products = data.isSupplier ? data.products : [data];
                        recalculateTotalFundingValues({
                            ...params.colDef,
                            products,
                            updateProductValues: this.updateProductValues,
                        });
                    },
                    readOnly: () => {
                        return this.getColumnConfigDrivenProp({
                            colName: column.field,
                            prop: 'readOnly',
                            defaultValue: false,
                        });
                    },
                };
                column.hide = !this.getColumnConfigDrivenProp({
                    colName: column.field,
                    prop: 'visible',
                    defaultValue: true,
                });
            });

            return columnsToRender;
        },

        getColumnConfigDrivenProp({ colName, prop, defaultValue }) {
            const column = this.promoFundingConfig.volumes[colName];
            return get(column, prop, defaultValue);
        },

        baselineColumnHidden() {
            return !this.getColumnConfigDrivenProp({
                colName: 'baseline',
                prop: 'visible',
                defaultValue: true,
            });
        },
        upliftColumnHidden() {
            return !this.getColumnConfigDrivenProp({
                colName: 'uplift',
                prop: 'visible',
                defaultValue: true,
            });
        },
    },
};
</script>
