import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VTooltip,
    {
      attrs: { "z-index": "200", bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "span",
                _vm._g(_vm._b({}, "span", attrs, false), on),
                [
                  _c("icon", {
                    attrs: { "icon-name": "timeline", small: _vm.small },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "simple-list" },
        _vm._l(_vm.workflowState, function (ws) {
          return _c(
            "li",
            { key: ws.name, staticClass: "simple-list__item" },
            [
              _c("icon", {
                attrs: { "icon-name": ws.value ? "done" : "close", small: "" },
              }),
              _vm._v(" " + _vm._s(ws.name) + "\n        "),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }