'use strict';

/**
 * Enum definition for supplier-commitment types.
 *
 * @module data/enums/supplier-commitment-types
 */
module.exports = {
    specifiedAllocations: 'specifiedAllocations',
    totalSpend: 'totalSpend',
};
