<template>
    <div class="scenarios-viewer">
        <div>
            <template v-for="(category, categoryIndex) in categories">
                <div
                    :key="category.name + ':heading:' + categoryIndex"
                    class=" scenarios-viewer__heading"
                >
                    <truncate-text-tooltip :text="category.name" :max-width="800" />
                </div>

                <template v-for="(scenario, rowIndex) in category.scenarios">
                    <scenario-viewer
                        :key="'scenario::' + scenario._id + '::' + rowIndex"
                        :ref="`scenario_${scenario._id}`"
                        :scenario="scenario"
                        :sub-campaign-context="subCampaignContext(category)"
                        :make-read-only="
                            subCampaignEditingState.disabled &&
                                !toggleLogic[canEditInFlightPromotion]
                        "
                        :read-only-reason="subCampaignEditingState.reason"
                        :is-locked="isScenarioEditingDisabled(scenario._id)"
                        :is-private="toggleLogic[enablePublicPrivateEntities] && scenario.isPrivate"
                    />
                </template>

                <div :key="category.name + ':btn:' + categoryIndex">
                    <feature-toggle :toggle="canCreateScenario">
                        <scenario-form-dialog
                            :context="subCampaignContext(category)"
                            :make-read-only="subCampaignEditingState.disabled"
                            :read-only-reason="subCampaignEditingState.reason"
                            add-btn
                        />
                    </feature-toggle>
                </div>
            </template>
            <div v-if="categories.length === 0">
                <feature-toggle :toggle="canCreateScenario">
                    <scenario-form-dialog
                        :context="subCampaignContext()"
                        :make-read-only="subCampaignEditingState.disabled"
                        :read-only-reason="subCampaignEditingState.reason"
                        add-btn
                    />
                </feature-toggle>
            </div>
        </div>
    </div>
</template>

<script>
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { find, map, sortBy, cloneDeep, range, truncate } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
    canCreateScenario,
    canEditInFlightPromotion,
    enablePublicPrivateEntities,
} from '@enums/feature-flags';
import UXEvents from '@enums/ux-events';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';
import featureToggle from '@/js/components/feature-toggle';

export default {
    components: { featureToggle },
    data() {
        return {
            canCreateScenario,
            canEditInFlightPromotion,
            enablePublicPrivateEntities,
        };
    },
    events: {
        // get updated sub-campaign promotions, when sub-campaign is updated
        async onSubCampaignUpdated(subCampaign) {
            await this.fetchPromotionsForSubCampaign({ subCampaign });
            this.globalEmit(UXEvents.promotionsUpdated);
        },

        // get updated scenario promotions, when scenario is updated
        async onUpdateScenario(scenario) {
            await this.fetchPromotionsForScenario({ scenario });
            this.globalEmit(UXEvents.promotionsUpdated);
        },
    },
    computed: {
        ...mapGetters('scenarios', ['scenariosGroupedByCategory', 'getScenarioById']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign', 'getSubCampaignById']),
        ...mapGetters('promotions', ['promotionsAggregatedByScenario']),
        ...mapGetters('clientConfig', ['getNumberOfEffectivenessRatingColours']),
        ...mapState('clientConfig', ['toggleLogic']),

        categories() {
            if (!this.selectedSubCampaign) {
                return [];
            }
            const numberOfEffectivenessRatings = this.getNumberOfEffectivenessRatingColours;

            if (!numberOfEffectivenessRatings) {
                throw new Error(
                    toSentenceCase(i18n.t('general.errors.missingEffectivenessRatingConfig'))
                );
            }

            // Generate an array containing the valid effectiveness rating values based upon the config.
            // The second range parameter has 1 added to it because it generates numbers up to but not including the second parameter value.
            const validEffectivenessRatings = range(1, numberOfEffectivenessRatings + 1);

            const groupedScenarios = this.scenariosGroupedByCategory(this.selectedSubCampaign._id);

            // Traverse through each of the scenario groups.
            return map(Object.keys(groupedScenarios), categoryGroup => {
                // Sort the scenarios within the scenario group so an order can be determined on
                // when the scenario was created.
                let sortedGroupedScenarios = sortBy(groupedScenarios[categoryGroup], 'createdOn');

                // For each scenario, apply the aggregated promotion metrics.
                sortedGroupedScenarios = sortedGroupedScenarios.map((scenario, index) => {
                    const aggregatedMetrics = find(this.promotionsAggregatedByScenario, {
                        _id: scenario._id,
                    });

                    const getAggregatedMetric = field => {
                        return aggregatedMetrics ? aggregatedMetrics[field] : 0;
                    };

                    scenario.index = index;
                    scenario.order = `${index + 1} / ${groupedScenarios[categoryGroup].length}`;
                    scenario.promotionCount = getAggregatedMetric('promotionCount');

                    scenario.productCount = getAggregatedMetric('productCount');
                    scenario.incrementalSales = getAggregatedMetric('incrementalSales');
                    scenario.salesEfficiency = getAggregatedMetric('salesEfficiency');
                    scenario.incrementalMargin = getAggregatedMetric('incrementalMargin');
                    scenario.marginEfficiency = getAggregatedMetric('marginEfficiency');

                    // Add the effectiveness colour related properties.
                    // This must be done dynamically because the number of effectiveness colours is config driven.
                    const effectivenessRatings = validEffectivenessRatings.reduce((acc, value) => {
                        const metricName = `effectivenessColour${value}Count`;
                        acc[value] = getAggregatedMetric(metricName);
                        return acc;
                    }, {});

                    scenario.effectivenessRatings = effectivenessRatings;

                    return { ...scenario };
                });

                return {
                    name: categoryGroup,
                    scenarios: sortBy(sortedGroupedScenarios, 'index'),
                };
            });
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        subCampaignEditingState() {
            return this.featureAwareFactory.isSubCampaignEditingDisabled(
                {
                    subCampaign: this.getSubCampaignById({
                        _id: this.selectedSubCampaign._id,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            );
        },
    },
    methods: {
        ...mapActions('promotions', [
            'fetchPromotionsForSubCampaign',
            'fetchPromotionsForScenario',
        ]),
        subCampaignContext(category) {
            // Take a copy of the selected sub-campaign so that we can alter the
            // default categories to be the same as the group where the scenario
            // is created from. There should always be at least 1 scenario for a
            // given scenario group, each of which will have the same data in the
            // 'categories' attribute.
            const selectedSubCampaign = cloneDeep(this.selectedSubCampaign);
            if (category) {
                selectedSubCampaign.categories = category.scenarios[0].categories;
            }
            return selectedSubCampaign;
        },
        isScenarioEditingDisabled(scenarioId) {
            return this.featureAwareFactory.isScenarioEditingDisabled(
                {
                    scenario: this.getScenarioById({
                        _id: scenarioId,
                        usePluralResourceName: true,
                    }),
                },
                this.$moment
            ).disabled;
        },

        getTruncatedText(text) {
            return truncate(text, {
                length: 60,
                omission: '...',
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$grid-padding: 2rem;

.scenarios-viewer {
    padding-bottom: $grid-padding;

    background-color: $promo-grey-3;
    font-size: 1.2rem;
    padding-left: 1.5rem;

    &__heading {
        padding-top: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        color: $text-colour;
    }
}
</style>
