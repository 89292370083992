<template>
    <div>
        <entity-details
            :entity="parkingLotFilters.selections.subCampaign || {}"
            :details-config="detailsConfig"
            custom-first-row
        >
            <div slot="firstRowLabel">
                {{ $tkey('targetSubCampaign') | toSentenceCase | suffixWithColon }}
            </div>
            <div slot="firstRowContent">
                <div class="selectors">
                    <vuex-date-range
                        :from="{ fieldName: 'from' }"
                        :to="{ fieldName: 'to' }"
                        :use-vuex-staging-area="false"
                        :getter="() => parkingLotFilters.selections.dateRange"
                        class="selectors__select"
                        @change="dateRangeSelected"
                    />
                    <vuex-select
                        :placeholder="$tkey('selectCampaign') | toSentenceCase"
                        :options="parkingLotFilters.options.campaignOptions"
                        :getter="() => parkingLotFilters.selections.campaign"
                        :setter="value => setParkingLotFiltersFields('campaign', value)"
                        :disabled="!parkingLotFilters.selections.dateRange"
                        clearable
                        filled
                        return-object
                        item-text="name"
                        class="selectors__select"
                        @change="campaignSelected"
                    />
                    <vuex-select
                        :placeholder="$tkey('selectSubCampaign') | toSentenceCase"
                        :options="parkingLotFilters.options.subCampaignOptions"
                        :getter="() => parkingLotFilters.selections.subCampaign"
                        :setter="value => setParkingLotFiltersFields('subCampaign', value)"
                        :disabled="!parkingLotFilters.selections.campaign"
                        clearable
                        filled
                        return-object
                        item-text="name"
                        class="selectors__select"
                        @change="subCampaignSelected"
                    />
                    <vuex-select
                        :placeholder="$tkey('selectScenario') | toSentenceCase"
                        :options="parkingLotFilters.options.scenarioOptions"
                        :getter="() => parkingLotFilters.selections.scenario"
                        :setter="value => setParkingLotFiltersFields('scenario', value)"
                        :disabled="!parkingLotFilters.selections.subCampaign"
                        clearable
                        filled
                        return-object
                        item-text="name"
                        class="selectors__select"
                        @change="scenarioSelected"
                    />
                    <v-btn icon class="pt-2" @click="onReset">
                        <icon icon-name="cancel" small icon-colour-type="primary-path" />
                    </v-btn>
                </div>
            </div>
        </entity-details>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';

export default {
    localizationKey: 'parkingLot.subCampaignSelector',
    data() {
        return {};
    },

    computed: {
        ...mapState(['parkingLotFilters']),
        ...mapState('clientConfig', ['entityDetailsViewerConfig', 'toggleLogic']),
        detailsConfig() {
            return this.entityDetailsViewerConfig.parkingLotSubCampaignSelector;
        },
    },

    methods: {
        ...mapMutations([
            'setParkingLotFilters',
            'setParkingLotFiltersField',
            'setParkingLotOptionsField',
            'setParkingLotOptions',
        ]),
        ...mapActions('campaigns', ['fetchCampaigns']),
        ...mapActions('subCampaigns', ['fetchSubCampaigns']),
        ...mapActions('scenarios', ['fetchScenarios']),

        onReset() {
            this.setParkingLotOptions({
                campaignOptions: [],
                subCampaignOptions: [],
                scenarioOptions: [],
            });

            this.setParkingLotFilters({
                dateRange: null,
                campaign: null,
                subCampaign: null,
                scenario: null,
            });

            this.emitChange();
        },
        setParkingLotFiltersFields(field, value) {
            this.setParkingLotFiltersField({ field, value });
            if (value) {
                const { startDate, endDate } = value;
                this.setParkingLotFiltersField({
                    field: 'dateRange',
                    value: [
                        this.$options.filters.datepickerFormat(startDate),
                        this.$options.filters.datepickerFormat(endDate),
                    ],
                });
            }
        },
        async dateRangeSelected({ from, to }) {
            const today = this.$moment();
            let startDate = from;
            if (this.$moment(to).diff(today, 'days') < 0) {
                startDate = this.$options.filters.datepickerFormat(today);
            }

            const params = {
                where: {
                    startDate: { $gte: startDate },
                    endDate: { $lte: to },
                },
            };
            this.setParkingLotFiltersField({ field: 'dateRange', value: [from, to] });
            const campaignOptions = await this.fetchCampaigns({ params, returnDocuments: true });
            this.setParkingLotOptionsField({ field: 'campaignOptions', value: campaignOptions });
            this.campaignSelected(null);
        },

        async campaignSelected(campaign) {
            // deselection
            if (!campaign) {
                this.setParkingLotOptionsField({
                    field: 'subCampaignOptions',
                    value: [],
                });
                this.setParkingLotFiltersField({ field: 'campaign', value: null });
                return this.subCampaignSelected(null);
            }
            // selection
            await this.subCampaignSelected(null);
            const params = {
                where: {
                    campaignId: campaign._id,
                },
            };
            const subCampaignOptions = await this.fetchSubCampaigns({
                params,
                returnDocuments: true,
            });
            this.setParkingLotOptionsField({
                field: 'subCampaignOptions',
                value: subCampaignOptions,
            });
        },
        async subCampaignSelected(subCampaign) {
            // deselection
            if (!subCampaign) {
                this.setParkingLotOptionsField({ field: 'scenarioOptions', value: [] });
                this.setParkingLotFiltersField({ field: 'subCampaign', value: null });
                this.scenarioSelected(null);
                return;
            }
            // selection
            this.scenarioSelected(null);
            const params = {
                where: {
                    subCampaignId: subCampaign._id,
                },
            };
            const scenarioOptions = await this.fetchScenarios({
                params,
                returnDocuments: true,
            });
            this.setParkingLotOptionsField({ field: 'scenarioOptions', value: scenarioOptions });
            if (this.parkingLotFilters.options.scenarioOptions.length === 1) {
                this.scenarioSelected(this.parkingLotFilters.options.scenarioOptions[0]);
            }
        },
        scenarioSelected(scenario) {
            this.setParkingLotFiltersField({ field: 'scenario', value: scenario });
            this.emitChange();
        },
        emitChange() {
            const eventDetails = {
                campaign: this.parkingLotFilters.selections.campaign,
                subCampaign: this.parkingLotFilters.selections.subCampaign,
                scenario: this.parkingLotFilters.selections.scenario,
            };
            if (this.parkingLotFilters.selections.dateRange) {
                eventDetails.dateRange = {
                    from: this.parkingLotFilters.selections.dateRange[0],
                    to: this.parkingLotFilters.selections.dateRange[1],
                };
            }
            this.$emit('change', eventDetails);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.selectors {
    display: flex;
    flex: 1;
    align-items: baseline;

    & > * {
        margin-right: 1rem;
    }

    &__select {
        width: 20rem;
        max-width: 20rem;
        align-self: center;

        &::v-deep {
            .v-select__slot,
            .v-input__slot {
                padding-left: 0.5rem;
            }
        }
    }
}
.details {
    width: 75%;
    display: flex;
    align-items: baseline;
    margin-left: 2rem;
    margin-top: 1rem;

    &__label {
        font-size: 1.2rem;
        text-align: right;
        padding: 0;
    }

    &__content {
        margin-left: 1rem;
        font-size: 1.2rem;
        padding: 0;
    }
}
</style>
