<template>
    <div class="promotion-name-date">
        <vuex-text-field
            ref="promotionName"
            class="promotion-name-date__text"
            :disabled="editNameDisabled"
            :getter="() => getPromotionNameModel()"
            :setter="value => setPromotionNameModel(value)"
            :white="true"
            :validations="promotionNameValidation"
            :placeholder="$t('planning.promotionsViewer.promotionName') | toSentenceCase"
            @click.stop
            @blur="saveName"
        />
        <losenge-date-display :start-date="startDate" :end-date="endDate" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { isNil, isEmpty, get } from 'lodash';
import { promotionNameIsRequired } from '@enums/feature-flags';
import validators from '@/js/validators';

export default {
    props: {
        startDate: String,
        endDate: String,
        promotionId: String,
        promotionName: String,
    },
    data() {
        return {
            promotionNameModel: this.promotionName || '',
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById', 'selectedPromotion']),
        editNameDisabled() {
            if (this.promotionId === 'default') {
                return false;
            }
            return get(this.selectedPromotion, '_id') !== this.promotionId;
        },
        promotionNameValidation() {
            const promotionNameValidators = [];

            if (this.toggleLogic[promotionNameIsRequired]) {
                promotionNameValidators.push({
                    validator: validators.required,
                });
            }

            return promotionNameValidators;
        },
    },
    watch: {
        promotionName() {
            this.promotionNameModel = this.promotionName || '';
        },
    },
    mounted() {
        // Focus on promotion name field
        this.$nextTick(() => {
            if (this.$refs.promotionName && isEmpty(this.promotionNameModel)) {
                this.$refs.promotionName.$children[0].focus();
            }
        });
    },
    methods: {
        ...mapActions('promotions', [
            'updatePromotion',
            'setStagingAreaField',
            'createStagingAreaNamespace',
        ]),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        getPromotionNameModel() {
            return this.promotionNameModel;
        },
        setPromotionNameModel(value) {
            this.promotionNameModel = value.trim();
        },
        async saveName() {
            const name = this.promotionNameModel;

            if (this.isPromotionNameWasChanged({ name })) {
                if (!this.getStagingAreaPromotionById(this.promotionId)) {
                    await this.createStagingAreaNamespace({
                        namespace: this.promotionId,
                    });
                }
                if (!this.toggleLogic[promotionNameIsRequired] || !isNil(name)) {
                    await this.setStagingAreaField({
                        namespace: this.promotionId,
                        fieldName: 'name',
                        value: name,
                    });
                    this.setUnsavedPromotion({
                        namespace: this.promotionId,
                        tab: 'root',
                        value: true,
                    });
                }
            }
        },
        isPromotionNameWasChanged({ name }) {
            // check for null was done for new promotion
            return !isNil(this.promotionName) && this.promotionName !== name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.promotion-name-date {
    &__text {
        font-size: 1rem;
        font-weight: 500;
        padding-bottom: 1rem;

        &::v-deep {
            .theme--light.v-text-field.v-input--is-disabled {
                .v-input__control {
                    .v-input__slot {
                        &:before {
                            border-image: none;
                            border-color: inherit;
                        }
                    }
                }
            }
            .v-input__slot {
                height: 1.9rem;
            }

            .v-input--is-disabled input {
                color: $text-colour;
                // need to use !important because need to redeclare padding which also has important
                padding-left: 0 !important;
            }
            .v-text-field__slot input {
                padding-left: 0 !important;
            }
        }
    }
}
</style>
