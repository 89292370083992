<template>
    <feature-toggle :toggle="canWriteParkingLotPromotion">
        <div class="create-promotion">
            <div class="create-promotion--prefix create-promotion--first-item">
                <div>{{ $t(getPromotionTypeText()) | toSentenceCase }}</div>
                <v-menu
                    v-if="splitPromotionsEnabled"
                    v-model="isMenuOpen"
                    :close-on-content-click="true"
                    content-class="create-promotion--menu"
                    offset-y
                    top
                    left
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            class="create-promotion--menu__container"
                            @mouseover="toggleCreateNewMenu(!isDisabled)"
                        >
                            <icon-button
                                v-bind="attrs"
                                icon="expand_more"
                                class="create-promotion--menu__icon"
                                :disabled="isDisabled"
                                icon-on-right
                                v-on="on"
                            />
                        </div>
                    </template>
                    <v-list class="create-promotion--menu__list">
                        <template v-for="isSplitPromotion in [false, true]">
                            <v-list-item
                                v-if="isSplitPromotion !== splitPromotion"
                                :key="'promotion-type-split-' + isSplitPromotion"
                                @click="splitPromotionSetter(isSplitPromotion)"
                            >
                                <div>
                                    {{
                                        $t(getPromotionTypeText(isSplitPromotion)) | toSentenceCase
                                    }}
                                </div>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </div>
            <vuex-text-field
                v-if="isPromotionNameFieldVisible"
                :getter="() => promotionNameGetter()"
                :setter="value => promotionNameSetter(value)"
                :placeholder="$t('planning.promotionsViewer.promotionName') | toSentenceCase"
                :make-read-only="isDisabled"
                white
                save-whitespace
                update-on-input
                :class="['create-promotion--promotion-name']"
                @click.stop
                @keydown.enter="!isDisabled && $emit('create-promotion')"
            />
            <create-new-button
                class="create-promotion--create-btn"
                :btn-text="'actions.create'"
                background
                :disabled="isDisabled || !promotionName"
                @createNew="$emit('create-promotion')"
            />
        </div>
    </feature-toggle>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
    canWriteParkingLotPromotion,
    promotionNameIsRequired,
    enableSplitPromotions,
    canCreateSplitPromotion,
} from '@enums/feature-flags';
import UXEvents from '@enums/ux-events';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [configDrivenGridComponentMixin],
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMenuOpen: false,
            canWriteParkingLotPromotion,
            promotionName: '',
            splitPromotion: false,
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('scenarios', ['selectedScenarioId', 'getScenarioById']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign', 'getSubCampaignById']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        splitPromotionsEnabled() {
            return (
                this.toggleLogic[enableSplitPromotions] && this.toggleLogic[canCreateSplitPromotion]
            );
        },

        isPromotionNameFieldVisible() {
            return this.toggleLogic[promotionNameIsRequired];
        },
    },
    created() {
        this.addEventListener();
    },
    beforeDestroy() {
        this.$root.$off(UXEvents.parkingLotCreated, this.resetFields);
        this.$root.$off(UXEvents.promotionCreated, this.resetFields);
    },
    methods: {
        ...mapActions('promotions', ['setStagingAreaField']),

        toggleCreateNewMenu(value) {
            this.isMenuOpen = value;
        },

        getPromotionTypeText(splitPromotion = this.splitPromotion) {
            return splitPromotion
                ? 'planning.promotionsViewer.createNewSplitPromotion'
                : 'planning.promotionsViewer.createNewPromotion';
        },

        splitPromotionSetter(value) {
            this.splitPromotion = value;
            this.$emit('set-split-promotion', value);
        },

        promotionNameGetter() {
            return this.promotionName;
        },

        promotionNameSetter(value) {
            this.promotionName = value;
            this.$emit('set-promotion-name', value);
        },

        resetFields() {
            this.splitPromotionSetter(false);
            this.promotionNameSetter('');
        },

        addEventListener() {
            this.$root.$on(UXEvents.parkingLotCreated, this.resetFields);
            this.$root.$on(UXEvents.promotionCreated, this.resetFields);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.create-promotion {
    background-color: $promo-white;
    margin-left: 0px !important;
    width: 100%;
    position: relative;
    display: inline-flex;
    bottom: 0.5rem;

    &--first-item {
        padding-left: 0.6rem;
    }

    &--promotion-name {
        width: 20rem;
    }

    &--menu {
        &__list {
            background: $buttons-menu-list-bg;
            padding: 0;
        }
        &__icon {
            padding: 0 0.5rem;
        }
    }

    &--prefix {
        display: flex;
        align-items: center;
        div {
            justify-content: flex-start;
            color: $buttons-menu-text-color;
            font-weight: 700;
            font-size: 1.2rem;
        }
    }

    &--create-btn {
        font-size: 1.2rem;
        position: relative;
        left: 2rem;
    }
}

.v-list-item {
    padding: 0rem;
    min-height: 3.2rem;
    min-width: 14rem;
    border: none;
    text-align: center;

    &:hover {
        background: $buttons-menu-hover;
    }

    div,
    ::v-deep div {
        width: 100%;
        height: 100%;

        justify-content: flex-start;
        color: $buttons-menu-text-color;
        font-weight: 700;
        font-size: 1.2rem;

        &:hover {
            background: none;
            &:before {
                opacity: 0;
            }
        }
    }
}

.v-menu__content {
    z-index: $menu-z-index !important;
    margin-top: 0.5rem;
}
</style>
