var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Workflow open cross")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "simple-UPDATE",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Workflow-4-1-A-01",
                transform: "translate(-1297.000000, -551.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Success-/-Tick",
                    transform: "translate(1297.000000, 551.000000)",
                  },
                },
                [
                  _c("circle", {
                    attrs: {
                      id: "Combined-Shape",
                      stroke: "#C13939",
                      cx: "8",
                      cy: "8",
                      r: "7.5",
                    },
                  }),
                  _vm._v(" "),
                  _c("polygon", {
                    attrs: {
                      id: "Path",
                      fill: "#C13939",
                      points:
                        "11.5 10.513 10.513 11.5 8 8.987 5.487 11.5 4.5 10.513 7.013 8 4.5 5.487 5.487 4.5 8 7.013 10.513 4.5 11.5 5.487 8.987 8",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }