<template>
    <div :style="gridCss" class="campaign-container">
        <campaign
            :key="campaign._id"
            :campaign="campaign"
            :parent-start-date="containerStartDate"
            :parent-end-date="containerEndDate"
            :is-locked="campaignReadOnly"
            @opened="campaignOpened"
            @duplicate-campaign-clicked="campaignDuplicated"
        />
        <sub-campaign
            v-for="subCampaign in subCampaigns"
            :key="subCampaign._id"
            :campaign="campaign"
            :sub-campaign="subCampaign"
            :parent-start-date="containerStartDate"
            :parent-end-date="containerEndDate"
            :is-locked="subCampaignsReadOnlyById[subCampaign._id]"
            @opened="subCampaignOpened"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { orderBy } from 'lodash';
import { subCampaignOrdering } from '@enums/feature-flags';
import constants from '@/js/constants';

export default {
    props: [
        'campaign',
        'plannerPeriodYearsIncludedNextYear',
        'daysShiftPerYear',
        'firstPlannerDayNumeric',
        'lastPlannerDayNumeric',
        'firstPlannerDayDate',
        'lastPlannerDayDate',
        'campaignReadOnly',
        'subCampaignsReadOnlyById',
    ],
    computed: {
        ...mapGetters('subCampaigns', ['getFilteredSubCampaignsByCampaignId']),
        ...mapState('clientConfig', ['toggleLogic']),

        subCampaigns() {
            return orderBy(
                this.getFilteredSubCampaignsByCampaignId(this.campaign._id),
                this.toggleLogic[subCampaignOrdering].fields,
                this.toggleLogic[subCampaignOrdering].directions
            );
        },
        numRows() {
            // shows quantity of grid rows for subCampaigns + campaign
            return this.subCampaigns.length * 4 + 4;
        },

        containerStartDate() {
            const campaignStartDate = this.$moment(this.campaign.startDate);
            return campaignStartDate.isSameOrAfter(this.firstPlannerDayDate)
                ? campaignStartDate
                : this.firstPlannerDayDate;
        },

        containerEndDate() {
            const campaignEndDate = this.$moment(this.campaign.endDate);
            return campaignEndDate.isSameOrBefore(this.lastPlannerDayDate)
                ? campaignEndDate
                : this.lastPlannerDayDate;
        },

        startDay() {
            const startDate = this.$moment(this.campaign.startDate).dayOfYear();
            const startDateYear = this.$moment(this.campaign.startDate).year();

            const yearIndex = this.plannerPeriodYearsIncludedNextYear.indexOf(startDateYear);

            if (yearIndex === -1) {
                return startDateYear < this.plannerPeriodYearsIncludedNextYear[0]
                    ? 1
                    : this.daysShiftPerYear[startDateYear] + startDate;
            }

            let startDay;
            if (yearIndex === 0) {
                // a negative firstPlannerDay means that the first week in the planner
                // starts on the previous year, so we position the campaigns subtracting
                // their dayOfYear from the spaces needed for the next 2 years
                if (this.firstPlannerDayNumeric < 0) {
                    startDay =
                        startDate +
                        this.daysShiftPerYear[startDateYear + 1] -
                        this.daysShiftPerYear[startDateYear + 2];
                } else {
                    startDay = startDate - this.firstPlannerDayNumeric + 1;
                }
            } else {
                startDay = this.daysShiftPerYear[startDateYear] + startDate + 1;
            }

            return startDay > 0 ? startDay : 1;
        },

        endDay() {
            const endDate = this.$moment(this.campaign.endDate).dayOfYear();
            const endDateYear = this.$moment(this.campaign.endDate).year();

            const yearIndex = this.plannerPeriodYearsIncludedNextYear.indexOf(endDateYear);

            if (yearIndex === -1) {
                return this.lastPlannerDayNumeric;
            }

            let endDay;
            if (yearIndex === 0) {
                endDay = endDate - this.firstPlannerDayNumeric + 1;
            } else {
                endDay = this.daysShiftPerYear[endDateYear] + endDate + 1;
            }

            return endDay < this.lastPlannerDayNumeric ? endDay : this.lastPlannerDayNumeric;
        },

        // grid inside grid
        // For resolving issue with grid limitation in chrome
        // one column for campaigns grid include 7 days 2.4 * 7
        // in this case one column will represent 1 week and we can render 1000 weeks almost 20 years
        // position of each campaign need to be tweak using margin
        gridCss() {
            const daysInWeek = 7;
            // this will give us the start column number for the week in the planner
            let gridColumnStart = Math.floor(this.startDay / daysInWeek);
            // as we have week in one column each campaign starts from the first day in planner
            // need to count how many days between first day of week and campaign startDay
            // this number will be used for margin
            let offset = this.startDay - gridColumnStart * daysInWeek - 1;
            if (offset < 0) {
                gridColumnStart -= 1;
                offset += daysInWeek;
            }
            // this will give us the end column number for the week in the planner
            const gridColumnEnd = Math.ceil(this.endDay / daysInWeek);
            return {
                'grid-column-start': `${gridColumnStart + 1}`,
                'grid-column-end': `${gridColumnEnd + 1} `,
                'grid-row-end': `span ${this.numRows + 1}`,
                'margin-left': `${offset * constants.oneDayWidth}rem`,
                height: `${(100 / (this.numRows + 1)) * this.numRows}%`,
                'grid-template-columns': `repeat(${this.endDay - this.startDay + 1}, ${
                    constants.oneDayWidth
                }rem)`,

                // The size of grid-template-row here takes 4 rows for one grid row in campaign-weekly-view.

                'grid-template-rows': `repeat(${this.numRows}, 4rem)`,
            };
        },
    },
    methods: {
        campaignOpened(params) {
            this.$emit('campaign-opened', params);
        },
        campaignDuplicated(params) {
            this.$emit('campaign-duplicated', params);
        },
        subCampaignOpened(params) {
            this.$emit('sub-campaign-opened', params);
        },
    },
};
</script>

<style lang="scss" scoped>
.campaign-container {
    display: grid;
    grid-auto-flow: column;
}
</style>
