var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "18px",
        height: "18px",
        viewBox: "0 0 18 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Draft")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icons-/-Circle-half",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("g", { attrs: { id: "Draft" } }, [
            _c("circle", {
              attrs: {
                id: "Combined-Shape",
                stroke: "#8BA4B7",
                cx: "9",
                cy: "9",
                r: "8.5",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                id: "Combined-Shape",
                d: "M9,0 L9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 L9,0 Z",
                fill: "#8BA4B7",
              },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }