'use strict';

/**
 * This enum contains the different levels at which a supplier commitment can be
 * allocated against.
 *
 * @module data/enums/measurements
 */
module.exports = {
    promotion: 'promotion',
    product: 'product',
};
