import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { persistent: _vm.persistent, "hide-overlay": _vm.hideOverlay },
      scopedSlots: _vm._u(
        [
          _vm.hasActivator
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _vm._t(
                      "actions",
                      function () {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                class: {
                                  "disabled-activator": _vm.disabledActivator,
                                },
                                attrs: {
                                  primary: "",
                                  dark: "",
                                  small: "",
                                  depressed: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("open")
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$t(_vm.buttonText)
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                      { activator: on }
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        {
          staticClass: "dialog-card",
          class: {
            "dialog-card--error": _vm.isErrorDialog,
            "dialog-card--warning": !_vm.isErrorDialog && _vm.isWarningDialog,
          },
          attrs: { tile: "" },
        },
        [
          _c(
            "header",
            { staticClass: "dialog-card__header" },
            [
              _vm._t("inheritance-badges"),
              _vm._v(" "),
              _c("h1", { staticClass: "dialog-card__title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.dialogHeader) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                VBtn,
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("icon", {
                    attrs: {
                      "icon-name": "cancel",
                      small: "",
                      "icon-colour-type": "primary-path",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isReadOnly && _vm.propagatedReadOnlyReason
            ? _c("alert-banner", {
                staticClass: "ml-0",
                attrs: { reason: _vm.propagatedReadOnlyReason },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default", null, { validation: _vm.validation }),
          _vm._v(" "),
          _c(
            "feature-toggle",
            {
              attrs: { toggle: _vm.canSaveChangesToggle },
              scopedSlots: _vm._u([
                {
                  key: "alternative",
                  fn: function () {
                    return [
                      _c(
                        "footer",
                        { staticClass: "dialog-card__footer" },
                        [
                          _vm.showNegativeActionButton
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    text: "",
                                    secondary: "",
                                    "data-id-e2e": _vm.btnCancelId,
                                  },
                                  on: { click: _vm.closeDialog },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t(_vm.btnCloseText)
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.showFooter
                ? _c("footer", { staticClass: "dialog-card__footer" }, [
                    _c(
                      "div",
                      { staticClass: "buttons__right" },
                      [
                        _vm.hasMandatoryFields
                          ? _c(
                              "span",
                              { staticClass: "dialog-card__mandatory" },
                              [
                                _c("span", { staticClass: "asterisk" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t("planning.mandatory")
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showNegativeActionButton
                          ? _c(
                              VBtn,
                              {
                                attrs: {
                                  text: "",
                                  secondary: "",
                                  "data-id-e2e": _vm.btnCancelId,
                                },
                                on: { click: _vm.closeDialog },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.showPositiveActionButton
                                          ? _vm.$t(_vm.btnCancelText)
                                          : _vm.$t(_vm.btnCloseText)
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t("positiveActionButton", function () {
                          return [
                            _vm.showPositiveActionButton
                              ? _c(
                                  "positive-action-button",
                                  {
                                    attrs: {
                                      disabled: _vm.positiveActionBtnDisabled,
                                      "ignore-parent-read-only":
                                        _vm.overrideSaveBtnReadOnly,
                                      "data-id-e2e": _vm.btnSubmitId,
                                    },
                                    on: {
                                      onClick: function ($event) {
                                        return _vm.$emit("confirm")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$t(_vm.btnConfirmText)
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }