import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workflow-resources", style: _vm.gridStyle },
    _vm._l(_vm.workflowResources, function (item, index) {
      return _c(
        "div",
        { key: item.icon, staticClass: "workflow-resources__loop-wrapper" },
        [
          _c(
            "div",
            {
              staticClass: "workflow-resources__icon",
              class: {
                "workflow-resources__icon--last":
                  index === _vm.workflowResources.length - 1,
              },
            },
            [
              _c(
                VTooltip,
                {
                  attrs: { "z-index": "300", bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "span",
                              _vm._g(_vm._b({}, "span", attrs, false), on),
                              [
                                _c("icon", {
                                  attrs: {
                                    "icon-name": item.icon,
                                    disabled: !item.nominated,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t(`preparation.promoResources.${item.icon}`)
                          )
                        ) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "workflow-resources__details" }, [
            item.workflowStateIcon
              ? _c(
                  "span",
                  [
                    _c("icon", {
                      attrs: {
                        "icon-name": item.workflowStateIcon,
                        disabled: !item.nominated,
                      },
                    }),
                  ],
                  1
                )
              : item.submissionDate
              ? _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("localDayMonth")(item.submissionDate)) +
                      "\n            "
                  ),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.$t("placeholders.noText")))]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }