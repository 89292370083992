<template>
    <div class="detailed-provisions-grid">
        <div class="detailed-provisions-grid__headers">
            <span
                >{{
                    $t(
                        'planning.promotionsMaintenance.resources.additionalResourceDetails.resource'
                    ) | toSentenceCase
                }}
            </span>
            <span
                >{{
                    $t(
                        'planning.promotionsMaintenance.resources.additionalResourceDetails.provision'
                    ) | toSentenceCase
                }}
            </span>
        </div>
        <div class="detailed-provisions-grid__rows">
            <div
                v-for="promotionDetailedProvision in promotionDetailedProvisionsDisplay"
                :key="
                    `${promotionDetailedProvision.resourceType}::${
                        promotionDetailedProvision.instanceKey
                    }::${promotionDetailedProvision.selectedProvisionOption}`
                "
                class="row"
                :class="{
                    'row--highlighted':
                        highlightedInstanceKey === promotionDetailedProvision.instanceKey &&
                        highlightedInstanceKey != null,
                }"
                @mouseover="
                    setHighlightedInstanceKey({
                        instanceKey: promotionDetailedProvision.instanceKey,
                    })
                "
                @mouseleave="setHighlightedInstanceKey({ instanceKey: null })"
            >
                <span class="row__channel-cell">{{
                    promotionDetailedProvision.resourceType
                        ? $t(
                              `preparation.promoResources.${
                                  promotionDetailedProvision.resourceType
                              }`
                          )
                        : '' | toSentenceCase
                }}</span>
                <span class="row__provisions-cell">
                    <vuex-select
                        :placeholder="$tkey('placeholder.selectProvision') | toSentenceCase"
                        :multiple="false"
                        :edit-mode="false"
                        :options="promotionDetailedProvision.detailedProvisionOptions"
                        :getter="() => promotionDetailedProvision.selectedProvisionOption"
                        :setter="
                            value =>
                                setSelectedDetailedProvision({
                                    resourceType: promotionDetailedProvision.resourceType,
                                    instanceKey: promotionDetailedProvision.instanceKey,
                                    provisionIndex: promotionDetailedProvision.provisionIndex,
                                    newValue: value,
                                })
                        "
                        :disabled="isDisabled"
                        filled
                /></span>
                <span class="row__delete-cell">
                    <common-delete-dialog
                        :resource="detailedProvisionsResource"
                        :child-dependencies-warning="false"
                        :make-read-only="isDisabled"
                        @delete="
                            onDelete({
                                resourceType: promotionDetailedProvision.resourceType,
                                instanceKey: promotionDetailedProvision.instanceKey,
                                provisionIndex: promotionDetailedProvision.provisionIndex,
                            })
                        "
                    />
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { orderBy, isEmpty, groupBy, mapValues, map, flatten } from 'lodash';
import { detailedProvisions as detailedProvisionsResource } from '@enums/resources';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    localizationKey: 'planning.nominations.detailedProvision',
    props: {
        namespace: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            detailedProvisionsResource,
        };
    },
    computed: {
        ...mapState('clientConfig', ['detailedProvisions']),
        ...mapState('promotions', ['highlightedInstanceKey']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        promotionDetailedProvisionsDisplay() {
            // Display a dummy provision with no values when there are no detailed provisions on the promotion.
            if (isEmpty(this.promotionDetailedProvisions)) {
                return [
                    {
                        resourceType: null,
                        instanceKey: null,
                        detailedProvisionOptions: [],
                        selectedProvisionOption: null,
                        provisionIndex: null,
                    },
                ];
            }

            // For each of the detailed provisions on the promotion, create
            // an object representing a row in the grid.
            const unorderedDetailedProvisionRows = this.promotionDetailedProvisions.map(pdp => {
                // Generate the options available in the dropdown - this varies for each
                // provision being iterated over,
                // get array of arrays of objects, the example of structure is above allDetailedProvisionOptions definition
                const resourceTypeDetailedProvisionOptions = this.allDetailedProvisionOptions[
                    pdp.resourceType
                ];

                // all details provision options need to be combined between each other
                // the result is array of arrays with object combinations
                // ex: [[ {provisionName: "location", provisionValue: { key: "cover", clientKey: "1" }},
                //        {provisionName: "slotSize", provisionValue: { key: "unspecified", clientKey: "3"}} ],
                //      [ {provisionName: "location", provisionValue: { key: "cover", clientKey: "1" }},
                //        {provisionName: "slotSize", provisionValue: { key: "1/2", clientKey: "4"}} ],
                // ...]
                const detailedProvisionOptionsCombination = [];
                const getDetailedProvisionOptionsCombination = function(
                    elements = [],
                    deepIndex = 0
                ) {
                    resourceTypeDetailedProvisionOptions[deepIndex].forEach(levelItem => {
                        const processArray = [...elements];
                        // processArray should contains one option from each item (array) of resourceTypeDetailedProvisionOptions
                        // if there are 2 detailed provision types (ex: location and slotSize),
                        // processArray contain 1 location option and 1 slotSize option
                        processArray.push(levelItem);
                        // deepIndex is the current level processing resourceTypeDetailedProvisionOptions array
                        if (deepIndex < resourceTypeDetailedProvisionOptions.length - 1) {
                            // go to the next item(array) of resourceTypeDetailedProvisionOptions
                            getDetailedProvisionOptionsCombination(processArray, deepIndex + 1);
                        } else {
                            // if we processed all the items (arrays) of resourceTypeDetailedProvisionOptions,
                            // we can save processArray and return
                            detailedProvisionOptionsCombination.push(processArray);
                        }
                    });
                };

                getDetailedProvisionOptionsCombination();

                // each element of the array above is formatted to the object
                // ex: [{value: "leaflet-location-inside-2-slotSize-unspecified-3", text: "Location - Inside, Slot size - Unspecified"}, ...]
                const formattedDetailedProvisionOptions = detailedProvisionOptionsCombination.map(
                    optionsCombination =>
                        this.formatDetailedProvisionOptionForDisplay({
                            detailedProvisions: optionsCombination,
                            resourceType: pdp.resourceType,
                        })
                );

                // Null values represent a new detailed provision for which the user hasn't
                // yet selected a value
                const isNewProvision = pdp.detailedProvisions.some(
                    dp => dp.provisionName === null && dp.provisionValue === null
                );

                return {
                    resourceType: pdp.resourceType,
                    instanceKey: pdp.instanceKey,
                    detailedProvisionOptions: formattedDetailedProvisionOptions,
                    selectedProvisionOption: isNewProvision
                        ? null
                        : this.getDetailedProvisionOptionKey(pdp),
                    provisionIndex: pdp.provisionIndex,
                };
            });

            return orderBy(unorderedDetailedProvisionRows, [
                'resourceType',
                'instanceKey',
                'provisionIndex',
            ]);
        },
        promotionDetailedProvisions() {
            return this.getStagingAreaPromotionById(this.namespace).resources.flatMap(resource => {
                // filter instances with not empty detailedProvision array
                const instancesWithDetailedProvisions = resource.instances.filter(
                    instance => !isEmpty(instance.detailedProvision)
                );

                // Generate an array of objects for each instance
                // Ex: [ { resourceType: "leaflet", instanceKey: "GUID-key",
                //         detailedProvisions: [
                //           {provisionName: "location", provisionValue: {key: "cover", clientKey: "1"}, provisionIndex: 0},
                //           {provisionName: "slotSize", provisionValue: {key: "1/2", clientKey: "4"}, provisionIndex: 0} ]
                //     } ]
                return instancesWithDetailedProvisions.map(instance => ({
                    instanceKey: instance.key,
                    resourceType: resource.type,
                    detailedProvisions: instance.detailedProvision.map((dp, index) => {
                        const isNewProvision = isEmpty(dp);

                        return {
                            provisionName: isNewProvision ? null : dp.name,
                            provisionValue: isNewProvision
                                ? null
                                : { key: dp.value, clientKey: dp.clientKey },
                            provisionIndex: index,
                        };
                    }),
                }));
            });
        },

        allDetailedProvisionOptions() {
            // Generates  map of arrays of arrays of objects as values looking something like:
            // {leaflet: [
            // [{provisionName: 'location', provisionValue: { key: 'cover', clientKey: '1' } },
            //  {provisionName: 'location', provisionValue: { key: 'inside', clientKey: '2'} }],
            // [{provisionName: "slotSize",provisionValue: { key: 'unspecified', clientKey: '3'} },
            //  {provisionName: "slotSize",provisionValue: { key: '1/2' , clientKey: '4'} }, ...]
            // ]}
            const groupedDetailedProvisions = groupBy(this.detailedProvisions, 'type');
            return mapValues(groupedDetailedProvisions, dps => {
                return map(dps, dp =>
                    dp.options.flatMap(option => {
                        return {
                            provisionName: dp.name,
                            provisionValue: option,
                        };
                    })
                );
            });
        },

        isDisabled() {
            return this.isReadOnly || isEmpty(this.promotionDetailedProvisions);
        },
    },
    methods: {
        ...mapActions('promotions', [
            'updateDetailedProvision',
            'deleteDetailedProvision',
            'setHighlightedInstanceKey',
        ]),

        // created for PROWEB-1412
        // can be used for filtering DP in dropdown, if there are multiple dropdowns for one resource instance
        filterDetailedProvisionOptions({
            currentResourceType,
            currentProvisionInstanceKey,
            currentProvisionName,
        }) {
            // Store all of the detailed provisions for the current resource/instance which aren't empty.
            const existingDetailedProvisions = this.promotionDetailedProvisions.filter(
                pdp =>
                    pdp.resourceType === currentResourceType &&
                    pdp.instanceKey === currentProvisionInstanceKey &&
                    pdp.detailedProvisions.some(dp => dp.provisionName !== null)
            );

            // Filter the set of all detailed provisions to only include:
            // 1. Options available for the current resource type.
            // 2. Alternative options for the currently selected provision (or all options if there are none selected).
            // 3. Options for provisions which haven't already been selected for the current resource/instance.
            const filteredProvisionOptions = flatten(
                this.allDetailedProvisionOptions[currentResourceType]
            ).filter(option => {
                // See point 2. above
                const isOptionForCurrentProvisionName =
                    option.provisionName === currentProvisionName;
                const isCurrentProvisionEmpty = currentProvisionName === null;

                // See point 3. above.
                const isOptionAlreadyPresentOnInstance = existingDetailedProvisions.some(edp =>
                    edp.detailedProvisions.some(
                        dp =>
                            dp.provisionName === option.provisionName &&
                            option.provisionName !== currentProvisionName
                    )
                );

                return (
                    (isCurrentProvisionEmpty || isOptionForCurrentProvisionName) &&
                    !isOptionAlreadyPresentOnInstance
                );
            });

            return filteredProvisionOptions;
        },

        setSelectedDetailedProvision({ resourceType, instanceKey, provisionIndex, newValue }) {
            const provision = this.getDetailedProvisionValueFromKey({ key: newValue });

            this.updateDetailedProvision({
                namespace: this.namespace,
                resourceType,
                instanceKey,
                provisionIndex,
                newValue: provision,
            });
        },
        onDelete({ resourceType, instanceKey, provisionIndex }) {
            this.deleteDetailedProvision({
                namespace: this.namespace,
                resourceType,
                instanceKey,
                provisionIndex,
            });
        },
        getDetailedProvisionValueFromKey({ key }) {
            const detailedProvisionParts = key.split('-');
            const detailProvisionsNumber = (detailedProvisionParts.length - 1) / 3;
            const detailedProvisions = [];
            // ex: leaflet-location-cover-1-slotSize-unspecified-4 -> [leaflet,location,cover,1,slotSize,unspecified,4]
            for (let i = 1; i <= detailProvisionsNumber; i += 1) {
                const provisionClientKey = i * 3; // 3 for i=1, 6 for i=2
                const provisionValueIndex = provisionClientKey - 1; // 2 for i=1, 5 for i=2
                const provisionNameIndex = provisionValueIndex - 1; // 1 for i=1, 4 for i=2
                const detailedProvisionName = detailedProvisionParts[provisionNameIndex];
                const detailedProvisionValue = detailedProvisionParts[provisionValueIndex];
                const detailedProvisionClientKey = detailedProvisionParts[provisionClientKey];
                detailedProvisions.push({
                    name: detailedProvisionName,
                    value: detailedProvisionValue,
                    clientKey: String(detailedProvisionClientKey),
                });
            }
            // result: [{name: location, value: cover, clientKey: 1}, {name: slotSize, value: unspecified, clientKey: 4}]
            return detailedProvisions;
        },
        getDetailedProvisionOptionKey({ detailedProvisions, resourceType }) {
            // The combination of provision type, provision name and option name will be unique.
            // Generate a string from this to be used as a key - e.g. 'leaflet-location-cover-1-slotSize-unspecified-4'.
            const detailedProvisionStringCombination = detailedProvisions.reduce(
                (acc, { provisionName, provisionValue }) =>
                    `${acc}-${provisionName}-${provisionValue.key}-${provisionValue.clientKey}`,
                ''
            );
            return `${resourceType}${detailedProvisionStringCombination}`;
        },
        getDetailedProvisionOptionText(detailedProvisions) {
            // Gets the dropdown text to be used for the detailed provision option.
            if (detailedProvisions.length === 1) {
                return toSentenceCase(this.$tkey(detailedProvisions[0].provisionValue.key));
            }
            return detailedProvisions.reduce(
                (acc, { provisionName, provisionValue }) =>
                    `${acc + (isEmpty(acc) ? '' : this.$tkey('separator'))}${toSentenceCase(
                        this.$tkey(provisionName)
                    )} - ${toSentenceCase(this.$tkey(provisionValue.key))}`,
                ''
            );
        },
        formatDetailedProvisionOptionForDisplay({ detailedProvisions, resourceType }) {
            return {
                value: this.getDetailedProvisionOptionKey({ detailedProvisions, resourceType }),
                text: this.getDetailedProvisionOptionText(detailedProvisions),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.detailed-provisions-grid {
    width: 87rem; // 87 because we have 2rem of padding for the rows.
    &__headers {
        display: grid;
        grid-template-columns: 10rem 75rem;
        border-bottom: 0.1rem solid $promo-divider-colour;
        padding-bottom: 0.5rem;
        padding-left: 2rem;
    }

    &__rows {
        .row {
            display: grid;
            grid-template-columns: 10rem 55rem 20rem;
            margin: 0;
            padding-bottom: 0.5rem;
            padding-left: 2rem;
            padding-top: 0.5rem;
            &--highlighted {
                background: $promo-highlight-detailed-provision;
            }
            &__channel-cell {
                display: flex;
                align-self: center;
            }
            &__delete-cell {
                display: flex;
                align-self: center;
                justify-content: flex-end;
                padding-right: 1rem;
            }
            &__provisions-cell::v-deep {
                .v-autocomplete .v-select__slot > input {
                    padding-left: 0.5rem;
                }
            }
        }
    }
}
</style>
