import { VDatePicker } from 'vuetify/lib/components/VDatePicker';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VDatePicker, {
    ref: "picker",
    attrs: {
      "no-title": "",
      scrollable: "",
      range: _vm.range,
      min: _vm.min,
      max: _vm.max,
      "first-day-of-week": _vm.firstDayOfWeek,
      "show-week": "",
    },
    on: { input: _vm.complete, "click:month": _vm.setMonth },
    model: {
      value: _vm.computedDates,
      callback: function ($$v) {
        _vm.computedDates = $$v
      },
      expression: "computedDates",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }