import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VFooter,
    {
      staticClass: "footer",
      attrs: { app: "", height: "40px", elevation: "3" },
    },
    [
      _c(
        VBtn,
        {
          staticClass: "nav-link pa-0 mr-6 cookie-manager-btn text-none",
          attrs: { text: "", color: "white", href: _vm.termsOfUse },
        },
        [
          _c(VFlex, { staticClass: "subheading" }, [
            _vm._v(_vm._s(_vm.$t("footer.termsOfUse"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "nav-link pa-0 mr-6 cookie-manager-btn text-none",
          attrs: { text: "", color: "white", href: _vm.privacyNote },
        },
        [
          _c(VFlex, { staticClass: "subheading" }, [
            _vm._v(_vm._s(_vm.$t("footer.privacyNote"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isManageCookiesButtonVisible
        ? [
            _c(
              VBtn,
              {
                staticClass: "nav-link pa-0 mr-6 cookie-manager-btn text-none",
                attrs: { text: "", color: "white", href: _vm.cookieNotice },
              },
              [
                _c(VFlex, { staticClass: "subheading" }, [
                  _vm._v(_vm._s(_vm.$t("footer.cookieNotice"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                staticClass: "nav-link pa-0 mr-6 cookie-manager-btn text-none",
                attrs: { text: "", color: "white" },
                on: { click: _vm.onManageCookiesClick },
              },
              [
                _c(VFlex, { staticClass: "subheading" }, [
                  _vm._v(_vm._s(_vm.$t("footer.manageCookiesButtonTitle"))),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(VFlex, { staticClass: "subheading" }, [
        _vm._v("\n        " + _vm._s(_vm.copyrightOwText) + "\n    "),
      ]),
      _vm._v(" "),
      _c(VSpacer),
      _vm._v(" "),
      _c(
        VBtn,
        { staticClass: "text-none ml-5", attrs: { text: "", href: _vm.link } },
        [
          _c(VFlex, { staticClass: "subheading" }, [
            _vm._v(_vm._s(_vm.$t("footer.solutionFrom")) + ":"),
          ]),
          _vm._v(" "),
          _c(
            VFlex,
            { staticClass: "ow-logo", attrs: { "offset-md1": "", xs12: "" } },
            [
              _c("img", {
                attrs: { src: _vm.logo, height: "12px", width: "103px" },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }