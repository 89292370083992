var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "promotion-rag-colour",
      class: _vm.$options.methods.getFlexClass(_vm.props.vertical),
    },
    [
      _c("div", {
        staticClass: "promotion-rag-colour__colour",
        class: [
          _vm.$options.methods.getColourClass(
            _vm.props.colour,
            _vm.props.count,
            _vm.props.bigCircle
          ),
        ],
      }),
      _vm._v(" "),
      _vm.props.displayCount
        ? _c(
            "div",
            {
              staticClass: "promotion-rag-colour__count",
              class: _vm.$options.methods.getRagColourCountClass(
                _vm.props.vertical
              ),
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("countNumber")(_vm.props.count)) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }