import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: { "wrapper-scrollable": _vm.isScrollable },
    },
    [
      _vm.isScrollable
        ? _c(
            "div",
            { staticClass: "document-navigator__arrow" },
            [
              _c("navigate-button", {
                attrs: {
                  left: "",
                  "is-basic": _vm.isBasicNavigateButton,
                  disabled: _vm.leftButtonDisabled,
                },
                on: { click: _vm.onLeftScrollerClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "v-pagination",
          class: {
            "v-pagination--disabled": _vm.disabled,
            "v-pagination--scrollable": _vm.isScrollable,
          },
        },
        [
          _vm.firstPageVisible
            ? _c(
                "li",
                { staticClass: "v-pagination__first-page" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "v-pagination__item",
                      class: {
                        "v-pagination__item--active": _vm.isActiveClass(
                          _vm.firstPageValue
                        ),
                        primary: _vm.isActiveClass(_vm.firstPageValue),
                        "v-pagination__item--colored":
                          _vm.coloredPages.includes(_vm.firstPageValue),
                        "v-pagination__item--highlighted":
                          _vm.highlightedPages.includes(_vm.firstPageValue),
                      },
                      attrs: {
                        depressed: "",
                        disabled: _vm.isPageDisabled(_vm.firstPageValue),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.pageClickHandler(1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.firstPageValue) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isPaginationEllipsisVisible({ isStart: true })
            ? _c("li", { staticClass: "v-pagination__ellipsis--start" }, [
                _vm._v("\n            ...\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.computedLength, function (page, index) {
            return _c(
              "li",
              {
                key: "page" + page,
                class: { "no-border": _vm.isBorderNeed(index) },
              },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "v-pagination__item",
                    class: {
                      "v-pagination__item--active": _vm.isActiveClass(
                        _vm.getPageValue(page)
                      ),
                      primary: _vm.isActiveClass(_vm.getPageValue(page)),
                      "v-pagination__item--colored": _vm.coloredPages.includes(
                        _vm.getPageValue(page)
                      ),
                      "v-pagination__item--highlighted":
                        _vm.highlightedPages.includes(_vm.getPageValue(page)),
                    },
                    attrs: {
                      depressed: "",
                      disabled: _vm.isPageDisabled(_vm.getPageValue(page)),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.pageClickHandler(_vm.getPageValue(page))
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getPageValue(page)) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.isPaginationEllipsisVisible({ isStart: false })
            ? _c("li", { staticClass: "v-pagination__ellipsis--end" }, [
                _vm._v("\n            ...\n        "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.lastPageVisible
            ? _c(
                "li",
                { staticClass: "v-pagination__last-page" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "v-pagination__item",
                      class: {
                        "v-pagination__item--active": _vm.isActiveClass(
                          _vm.length
                        ),
                        primary: _vm.isActiveClass(_vm.length),
                        "v-pagination__item--colored":
                          _vm.coloredPages.includes(_vm.length),
                        "v-pagination__item--highlighted":
                          _vm.highlightedPages.includes(_vm.length),
                      },
                      attrs: {
                        depressed: "",
                        disabled: _vm.isPageDisabled(_vm.length),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.pageClickHandler(_vm.length)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.length) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isScrollable
        ? _c(
            "div",
            { staticClass: "document-navigator__arrow" },
            [
              _c("navigate-button", {
                attrs: {
                  right: "",
                  "is-basic": _vm.isBasicNavigateButton,
                  disabled: _vm.rightButtonDisabled,
                },
                on: { click: _vm.onRightScrollerClick },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }