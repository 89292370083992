<template functional>
    <button
        class="simple-btn v-btn"
        :class="[props.lightTheme ? 'simple-btn-light' : 'simple-btn-dark', data.staticClass || '']"
        :disabled="props.disabled"
        @click="listeners.onClick"
    >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        lightTheme: Boolean,
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.simple-btn {
    padding: 0 1.2rem !important;
    height: 2rem !important;

    &-light {
        background-color: $promo-white;
        border: 0.1rem solid $promo-primary-colour;
        color: $promo-primary-colour;
    }

    &-dark {
        background-color: $promo-primary-colour;
        color: $promo-white;
        &:not([disabled='disabled']):hover {
            background-color: $rtls-secondary-button-hover-colour !important;
        }
    }
    &[disabled='disabled'] {
        background-color: $promo-grey-7;
    }
}
</style>
