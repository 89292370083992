import Vue from 'vue';
import i18n from '@/js/vue-i18n';

/**
 * Function that creates a blob with the passed in data and downloads a JSON file
 * @param {Object[]} data
 * @param {String} resource - Name of the resource we are downloading
 */
function downloadJSONData(data, resource) {
    const jsonData = JSON.stringify(data, null, 2);

    // Create a blob
    const blob = new Blob([jsonData], {
        type: 'text/plain',
    });

    // Create a temporary link to download the data and remove when done
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${resource}-${i18n.d('dates.default.shortDate', Vue.moment())}.json`;
    link.click();
    link.remove();
}

export default downloadJSONData;
