<template>
    <div class="date-pair">
        <vuex-date-picker
            ref="fromDate"
            class="date-pair__date-picker date-pair__date-picker--from-date"
            v-bind="from"
            :vuex-module="vuexModule"
            :namespace="namespace"
            :placeholder="$t('general.labels.from') | toSentenceCase"
            :max-date="toValue"
            :context="context"
            :disabled="disabled"
        />
        <vuex-date-picker
            ref="toDate"
            class="date-pair__date-picker date-pair__date-picker--to-date"
            v-bind="to"
            :vuex-module="vuexModule"
            :namespace="namespace"
            :placeholder="$t('general.labels.to') | toSentenceCase"
            :min-date="fromValue"
            :context="context"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import mountedHackMixin from '../../mixins/mounted-hack';

export default {
    mixins: [mountedHackMixin],

    props: {
        context: Object,
        vuexModule: {
            type: String,
            required: true,
        },
        namespace: {
            type: String,
            required: true,
        },
        from: {
            type: Object, // TODO: custom types
            required: true,
        },
        to: {
            type: Object, // TODO: custom types
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        fromValue() {
            return this.mounted && this.$refs.fromDate.model;
        },
        toValue() {
            return this.mounted && this.$refs.toDate.model;
        },
    },
};
</script>

<style lang="scss" scoped>
.date-pair {
    display: flex;

    &__date-picker:first-child {
        margin-right: 2rem;
    }
}
</style>
