var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      "translated-header": _vm.translatedHeader,
      "has-activator": _vm.hasActivator,
      "show-negative-action-button": "",
      "show-positive-action-button": false,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "promotion-forecasts" }, [
              _c(
                "div",
                {
                  staticClass: "promotion-forecasts__forecasts-block",
                  style: {
                    gridTemplate: _vm.gridLayout,
                    gridTemplateColumns: _vm.gridTemplateColumns,
                  },
                },
                [
                  _vm._l(_vm.headers, function (header) {
                    return _c(
                      "div",
                      {
                        key: header.fieldName,
                        staticClass:
                          "promotion-forecasts__forecasts-block--header",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cell",
                            style: { "grid-area": `header${header.fieldName}` },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(header.header) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.unitData, function (item, key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        staticClass:
                          "promotion-forecasts__forecasts-block--wrapper",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "promotion-forecasts__forecasts-block--name",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "border-top-blue",
                                style: { gridArea: `${item.templateKey}name` },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "selected-hierarchy":
                                        _vm.isSelectedHierarchy(
                                          key,
                                          _vm.selectedHierarchyId
                                        ),
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "promotion-forecasts__forecasts-block--target",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "border-right border-top-blue",
                                style: {
                                  gridArea: `${item.templateKey}target`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$tkey("forecastsModal.target")) +
                                    "\n                            " +
                                    _vm._s(_vm.dateLabel) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.headers, function (header) {
                          return _c(
                            "div",
                            {
                              key: `${key}:target:${header.fieldName}`,
                              staticClass:
                                "promotion-forecasts__forecasts-block--forecasts",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-top-blue cell highlighted",
                                  style: {
                                    "grid-area": `${item.templateKey}forecast${header.fieldName}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.cellTypeToFormatter(
                                          _vm.get(
                                            item.target,
                                            [header.fieldName],
                                            0
                                          ),
                                          header.type
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-top-grey border-bottom-grey cell",
                                  style: {
                                    "grid-area": `${item.templateKey}currentForecasts${header.fieldName}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.cellTypeToFormatter(
                                          _vm.get(
                                            item.current,
                                            [header.fieldName],
                                            0
                                          ),
                                          header.type
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "cell",
                                  class:
                                    _vm.deltaValue(
                                      _vm.get(
                                        item.product,
                                        [header.fieldName],
                                        0
                                      ),
                                      _vm.get(
                                        item.target,
                                        [header.fieldName],
                                        0
                                      )
                                    ) > 0
                                      ? "positive"
                                      : "negative",
                                  style: {
                                    "grid-area": `${item.templateKey}deltaForecasts${header.fieldName}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.cellTypeToFormatter(
                                          _vm.deltaValue(
                                            _vm.get(
                                              item.product,
                                              [header.fieldName],
                                              0
                                            ),
                                            _vm.get(
                                              item.target,
                                              [header.fieldName],
                                              0
                                            )
                                          ),
                                          header.type
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "background-blue cell",
                                  style: {
                                    "grid-area": `${item.templateKey}productForecasts${header.fieldName}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.cellTypeToFormatter(
                                          _vm.get(
                                            item.product,
                                            [header.fieldName],
                                            0
                                          ),
                                          header.type
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "promotion-forecasts__forecasts-block--productTarget",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "border-right",
                                style: {
                                  "grid-area": `${item.templateKey}productTarget`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.dateLabel) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "promotion-forecasts__forecasts-block--delta",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "border-right",
                                style: {
                                  "grid-area": `${item.templateKey}delta`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$tkey("forecastsModal.delta")) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "promotion-forecasts__forecasts-block--current",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "border-right border-top-grey",
                                style: {
                                  "grid-area": `${item.templateKey}current`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$tkey("forecastsModal.current")
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    )
                  }),
                ],
                2
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }