<template>
    <div>
        <div v-if="sortedItems.length === 0">{{ $t(emptyText) | toSentenceCase }}</div>
        <ul v-else class="simple-list">
            <li
                v-for="(item, visibleIndex) in visibleItems"
                :key="'item' + visibleIndex"
                class="simple-list__item"
            >
                <span v-for="(field, fieldIndex) in displayFields" :key="`field-${fieldIndex}`">
                    {{ get(item, field) || $t(`general.defaults.${nullValueKey}`) }}
                </span>
            </li>
            <li v-if="showTooltip && hiddenItems.length" class="simple-list__more-items">
                <v-tooltip z-index="300" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                            $tc(additionalItemsText, hiddenItems.length)
                        }}</span>
                    </template>

                    <ul class="simple-list">
                        <li
                            v-for="(item, hiddenIndex) in hiddenItems"
                            :key="'h-item' + hiddenIndex"
                            class="simple-list__item"
                        >
                            <span
                                v-for="(field, hiddenChildIndex) in displayFields"
                                :key="'h-field' + hiddenChildIndex"
                            >
                                {{ get(item, field) }}
                            </span>
                        </li>
                    </ul>
                </v-tooltip>
            </li>
        </ul>
    </div>
</template>

<script>
import { get, sortBy, lowerCase, uniqBy } from 'lodash';

export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        displayFields: Array,
        sort: {
            type: Boolean,
            required: false,
            default: false,
        },
        limit: Number,
        nullValueKey: String,
        additionalItemsText: {
            type: String,
            default: 'general.components.additionalItemsText',
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
        uniqByKey: String,
        emptyText: {
            type: String,
            default: '',
        },
    },

    computed: {
        uniqueItems() {
            return this.uniqByKey ? uniqBy(this.items, this.uniqByKey) : this.items;
        },

        sortedItems() {
            return this.sort
                ? sortBy(this.uniqueItems, [
                      i => (this.displayFields ? lowerCase(i.description) : this.displayFields),
                  ])
                : this.uniqueItems;
        },

        visibleItems() {
            return this.sortedItems.slice(0, this.limit || this.sortedItems.length);
        },

        hiddenItems() {
            return this.sortedItems.slice(this.limit || this.sortedItems.length);
        },
    },

    methods: {
        get,
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.simple-list {
    list-style-type: none;
    padding: 0;

    &__item {
        span {
            &:not(:last-child):after {
                content: '\2014'; // em dash
                margin: 0 0.3rem;
            }
        }
    }

    &__more-items {
        color: $promo-primary-colour;
    }
}
</style>
