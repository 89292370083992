var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "slots-selector-container",
      class: {
        "slots-selector-container__disabled": !_vm.selectedResourceDefinition,
      },
    },
    [
      _c("span", { staticClass: "slots-selector-container__label" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("preparation.templates.numberOfSlots")
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.hasAccessToSlotCountDropDown
        ? _c("vuex-select", {
            staticClass: "slots-selector-container__select",
            attrs: {
              options: _vm.slotsOptions,
              getter: _vm.slotsGetter,
              setter: _vm.slotsSetter,
              disabled: !_vm.selectedResourceDefinition,
              placeholder: "-",
            },
          })
        : _c("div", { staticClass: "slots-selector-container__no-of-slots" }, [
            _vm._v("\n        " + _vm._s(_vm.slotsGetter()) + "\n    "),
          ]),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "dialog_confirm",
        staticClass: "confirm-dialog",
        attrs: {
          "question-text": _vm.$t(
            "preparation.templates.changeSlotsModal.heading"
          ),
          "warning-text": _vm.$t(
            "preparation.templates.changeSlotsModal.message"
          ),
          "action-text": _vm.$t(
            "preparation.templates.changeSlotsModal.confirm"
          ),
          "has-activator": false,
        },
        on: {
          confirm: _vm.updateNumberOfSlots,
          close: _vm.cancelUpdateNumberOfSlots,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }