<template>
    <promotion-maintenance
        ref="promotionMaintenance"
        v-bind="form"
        :promotion="promotion"
        :scenario="scenario"
        :is-new-promotion="isNewPromotion"
        :is-parking-lot="isParkingLot"
        :is-sub-campaign-allocation="isSubCampaignAllocation"
        :is-past-promotions="isPastPromotions"
        :promotion-editing-state="promotionEditingState"
        @split-requested="$emit('split-requested')"
        @save-button-clicked="$emit('save-button-clicked', $event)"
    />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { isEmpty, get, cloneDeep } from 'lodash';
import namespaces from '@enums/namespaces';
import { defaultParkingLotState, defaultPlanningState } from '@enums/promotion-default-statuses';
import pogScope from '@enums/product-offer-group-scope';
import { getDefaultResourcesForPromotion } from '@/js/utils/nomination-matrix-utils';
import { getActualCustomerAvailabilityForPromotion } from '@/js/utils/promotion-products-utils';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    props: {
        promotion: {
            required: true,
            type: Object,
        },
        scenario: {
            required: true,
            type: Object,
        },
        subCampaign: {
            required: false,
            type: Object,
        },
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isSubCampaignAllocation: {
            type: Boolean,
            default: false,
        },
        wasInParkingLot: {
            type: Boolean,
            default: false,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        splitPromotion: {
            type: Boolean,
            default: false,
        },
        promotionName: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState('promotions', ['validationStates']),
        ...mapGetters('scenarios', ['getScenarioById']),
        ...mapGetters('storeGroups', ['getStoreGroupsOptions']),
        ...mapGetters('context', ['userCategories']),
        ...mapState('clientConfig', [
            'detailedProvisions',
            'generalConfig',
            'rewardsDefaults',
            'clientStates',
            'offerDefaults',
        ]),
        ...mapGetters('clientConfig', ['getPromoResources', 'getCustomerRestrictions']),
        ...mapGetters('promotions', ['getDefaultResources']),

        form() {
            // Select category by default when creating a promo for the user with access to one category
            const userSelectedCategories =
                this.isNewPromotion && this.userCategories.length === 1 ? this.userCategories : [];

            const form = {
                namespace: this.namespace,
                editContext: this.isNewPromotion ? null : this.promotion,
                vuexModule: 'promotions',
                editMode: !this.isNewPromotion,
                editable: true,
                addEvent: 'createPromotion',
                submitAction: this.isNewPromotion ? null : 'prepareAndUpdatePromotion',
                validation: this.validationStates[this.namespace],
                fields: [
                    {
                        fieldName: '_id',
                        defaultValue: this.namespace,
                    },
                    {
                        fieldName: 'name',
                        defaultValue: this.promotionName,
                    },
                    {
                        fieldName: 'tags',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'scenarioId',
                        defaultValue: this.isSubCampaignAllocation ? this.scenario._id : null,
                    },
                    {
                        type: fieldTypes.dateRange,
                        from: {
                            fieldName: 'startDate',
                        },
                        to: {
                            fieldName: 'endDate',
                        },
                        editable: true,
                    },
                    {
                        fieldName: 'products',
                        defaultValue: [],
                        editable: true,
                    },
                    {
                        fieldName: 'productGroups',
                        defaultValue: [],
                        editable: true,
                    },
                    {
                        fieldName: 'categories',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'offerMechanic',
                        defaultValue: this.getOfferMechanic,
                        editable: true,
                    },
                    {
                        fieldName: 'productOfferGroups',
                        defaultValue: [
                            {
                                _id: 1,
                                description: toSentenceCase(
                                    i18n.t(
                                        'planning.promotionsMaintenance.offer.offerMechanic.productOfferGroup.default'
                                    )
                                ),
                                products: [],
                                userSelectedCategories,
                                scope: pogScope.selectedProducts,
                            },
                        ],
                        editable: true,
                    },
                    {
                        fieldName: 'supplierNotes',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'forecastingAggregations',
                        editable: true,
                    },
                    {
                        fieldName: 'effectivenessRating',
                        editable: true,
                    },
                    {
                        fieldName: 'rankedProductsCount',
                        defaultValue: this.generalConfig.defaultRankedProductsCount,
                        editable: true,
                    },
                    {
                        fieldName: 'rateCards',
                        defaultValue: [],
                        editable: true,
                    },
                    {
                        fieldName: 'additionalClientSpecificFields',
                        defaultValue: {
                            allVariantsFlag: null,
                            offerCommercialField: null,
                        },
                    },
                    {
                        fieldName: 'execution',
                        defaultValue: {},
                    },
                    {
                        fieldName: 'isInParkingLot',
                        defaultValue: this.isParkingLot && !this.isSubCampaignAllocation,
                        editable: false,
                    },
                    {
                        fieldName: 'wasInParkingLot',
                        defaultValue: this.isParkingLot,
                        editable: false,
                    },
                    {
                        fieldName: 'userSelectedCategories',
                        defaultValue: userSelectedCategories,
                    },
                    {
                        fieldName: 'splitPromotion',
                        defaultValue: this.splitPromotion,
                    },
                ],
            };

            // different context for existing promotions, new proposed promotions and new promotions with parents
            // isNewPromotion is always false in planning's promotion-viewer as promotions are created before opening the form
            if (this.isNewPromotion) {
                form.context = this.isSubCampaignAllocation ? this.scenario : null;

                form.fields.push(
                    // Set both workflowState and clientState only for new promotions
                    { fieldName: 'workflowState', defaultValue: [] },
                    {
                        fieldName: 'clientState',
                        defaultValue: this.determineClientState,
                    },
                    {
                        fieldName: 'notes',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'tags',
                        defaultValue: [],
                    }
                );
            } else {
                form.context = this.promotion;
            }

            if (this.isNewPromotion && this.isParkingLot) {
                form.fields.push({ fieldName: 'createdInParkingLot', defaultValue: true });
            }

            // is promotion is created in sub-campaign allocation
            if (this.isSubCampaignAllocation) {
                form.fields.push(
                    {
                        fieldName: 'storeGroups',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                const { defaultStoreGroups, storeGroups } = context;
                                const groups = isEmpty(defaultStoreGroups)
                                    ? storeGroups
                                    : defaultStoreGroups;
                                return groups.map(sg => ({
                                    ...sg,
                                    excludedStores: get(sg, 'excludedStores', []),
                                }));
                            },
                        },
                        editable: true,
                    },
                    {
                        fieldName: 'resources',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                const scenario = this.getScenarioById({
                                    _id: context._id,
                                    usePluralResourceName: true,
                                });
                                const scenarioResources = get(scenario, 'defaultResources', []);
                                return getDefaultResourcesForPromotion({
                                    scenarioResources,
                                });
                            },
                            params: {
                                detailedProvisions: this.detailedProvisions,
                            },
                        },
                        editable: true,
                    },
                    {
                        fieldName: 'startDate',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                const today = this.$moment();
                                const contextStartDate = this.$moment(context.startDate);
                                const contextEndDate = this.$moment(context.endDate);
                                if (
                                    contextStartDate.isBefore(today) &&
                                    contextEndDate.isSameOrAfter(today)
                                ) {
                                    return this.$options.filters.datepickerFormat(today);
                                }
                                return this.$options.filters.datepickerFormat(contextStartDate);
                            },
                        },
                        editable: true,
                    },
                    {
                        fieldName: 'endDate',
                        dynamicDefaultValue: {
                            source: sourceTypes.context,
                            path: 'endDate',
                        },
                        editable: true,
                    }
                );
            } else {
                form.fields.push(
                    {
                        fieldName: 'storeGroups',
                        defaultValue: [],
                        editable: true,
                    },
                    {
                        fieldName: 'resources',
                        defaultValue: [],
                        editable: true,
                    }
                );
            }

            return form;
        },

        isNewPromotion() {
            return isEmpty(this.promotion);
        },

        determineClientState() {
            const defaultState =
                this.isParkingLot && !this.isSubCampaignAllocation
                    ? defaultParkingLotState
                    : defaultPlanningState;
            return this.clientStates.promotion.find(state => state[defaultState]).state;
        },

        namespace() {
            return this.isNewPromotion ? namespaces.default : this.promotion._id;
        },

        isSavePromotionDisabled() {
            return this.$refs.promotionMaintenance.isSavePromotionDisabled;
        },
        getOfferMechanic() {
            const offerMechanic = cloneDeep(this.offerDefaults);
            if (this.isNewPromotion && this.isSubCampaignAllocation) {
                const defaultCustomerAvailability = offerMechanic.customerAvailability;
                offerMechanic.customerAvailability = getActualCustomerAvailabilityForPromotion({
                    defaultCustomerAvailability,
                    scenario: this.scenario,
                });
            }
            return offerMechanic;
        },
    },

    beforeDestroy() {
        this.setUnsavedPromotion({
            namespace: this.namespace,
            tab: 'all',
            value: false,
        });
        this.setAddProductsInProgress(false);
    },

    methods: {
        ...mapMutations('promotions', ['setUnsavedPromotion', 'setAddProductsInProgress']),
        savePromotion() {
            return this.$refs.promotionMaintenance.savePromotion();
        },
    },
};
</script>
