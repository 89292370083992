'use strict';

module.exports = {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
    joi: 'joi-error',
    joiCustom: 'joi-custom-error',
};
