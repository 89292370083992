<template>
    <aside
        class="main-sidebar elevation-2"
        :class="`main-sidebar--${isExpanded ? 'expanded' : 'collapsed'}`"
    >
        <div class="main-sidebar__nav">
            <div class="alert-list-counts">
                <alert-count-badge
                    v-for="alertsGroup in alertsGroupedByConfiguredCategories"
                    :key="alertsGroup.key[0]"
                    :priority="alertsGroup.key[0]"
                    :alert-count="alertsGroup.alerts.length"
                />
            </div>
            <main-expand-button
                :is-expanded="isExpanded"
                expanded-icon="chevron_left"
                collapsed-icon="chevron_right"
                background
                @expand="toggleSidebar"
            />
        </div>
        <div v-show="isExpanded" class="main-sidebar__expanded-content">
            <promotion-sidebar-year-dates :current-year="selectedYear" :options="calendarOptions" />
            <workflow-task-alert-list
                v-if="hasActiveAlerts"
                :alerts="activeAlerts"
                class="main-sidebar__workflow-task-alert-list"
                @sidebar-closed="hideSidebar"
            />
            <v-divider horizontal />
            <div class="main-sidebar__notifications-list-header">
                {{ $t('landing.notifications.notifications') | toSentenceCase }}
            </div>
            <notifications-list sidebar class="main-sidebar__notifications-list" />
        </div>
    </aside>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    props: {
        calendarOptions: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        ...mapState('subCampaigns', ['activeAlerts']),
        ...mapGetters('weeks', ['getWeekByDate']),
        ...mapGetters('subCampaigns', ['alertsGroupedByConfiguredCategories']),

        hasActiveAlerts() {
            return this.activeAlerts && this.activeAlerts.length > 0;
        },
        selectedYear() {
            const week = this.getWeekByDate(this.$moment());
            return week ? week.year : this.$moment().year();
        },
    },
    mounted() {
        this.fetchActiveAlerts();
    },
    methods: {
        ...mapActions('subCampaigns', ['fetchActiveAlerts']),

        toggleSidebar() {
            this.isExpanded = !this.isExpanded;
            // Update the active alerts data each time the sidebar opens.
            if (this.isExpanded) {
                this.fetchActiveAlerts();
            }
        },
        hideSidebar() {
            this.isExpanded = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.main-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $promo-white;
    z-index: $main-sidebar-z-index;
    padding: ($main-navbar-height + 1.2rem) 0 ($main-footer-height) + 1.2rem 0;
    transition: all 0.5s;

    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    overflow-y: auto;

    &--collapsed {
        width: $main-sidebar-width;
    }

    &--expanded {
        width: 25rem;
    }

    &__nav {
        @include flex-column;
        align-items: center;
        width: $main-sidebar-width;

        .alert-list-counts {
            text-align: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
        }
    }

    &__expanded-content {
        @include flex-column;
        flex-grow: 1;
        width: 100%;
        padding-left: 1.4rem;
        border-right: 1px solid $promo-grey-2;
    }

    &__workflow-task-alert-list {
        padding-bottom: 1rem;
    }

    &__notifications-list-header {
        color: $promo-text-colour;
        font-size: 1.2rem;
        font-weight: 600;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    &__notifications-list {
        padding-bottom: $main-footer-height;
        ::v-deep .notification {
            width: 20rem;
            margin: 0 !important;
            padding: 0;
        }
    }
}
</style>
