// Filter translations by client and fallback languages
import { isNil } from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numeral from 'numeral';
import i18nconfig from '../../config/static-i18n-config';

Vue.use(VueI18n);
Vue.mixin({
    methods: {
        /*
         * This new helper method can be used to simplify translation calls.
         * Instead of calling multiple times in Vue component for translations as:
         * this.$t('averylongnameformysection.subsection.title'), it can be simplified as this.$tkey('subsection.title')
         * as long as localizationKey is passed as an option to the Vue component. See furniture-editor.vue for more examples.
         * */
        $tkey(key, ...args) {
            if (!this.$options.localizationKey) return this.$t(key, ...args);
            return this.$t(`${this.$options.localizationKey}.${key}`, ...args);
        },
        $tckey(key, ...args) {
            if (!this.$options.localizationKey) return this.$t(key, ...args);
            return this.$tc(`${this.$options.localizationKey}.${key}`, ...args);
        },
    },
});
// Filter translations by client and fallback languages
const i18n = new VueI18n({
    locale: i18nconfig.defaultLocale, // client-specific. Overwritten on context load
    fallbackLocale: i18nconfig.fallbackLocale, // fallback language
    messages: {}, // no translations loaded until the user logs in.
    numberFormats: {}, // no number formats loaded until the user logs in.
    silentTranslationWarn: true,
    dateTimeFormats: {}, // no date formats loaded until the user logs in.
});

// Default tranlsations available before the users logs in
i18n.setLocaleMessage('en', {
    footer: {
        manageCookiesButtonTitle: 'Manage Cookies',
        termsOfUse: 'Terms of Use',
        privacyNote: 'Privacy Notice',
        cookieNotice: 'Cookie Notice',
        solutionFrom: 'A solution from',
    },
});

i18n.n = function(
    numberFormatKey,
    value,
    { usePlaceholder = false, placeholder = '-', useAbsoluteValue = false } = {}
) {
    if (isNil(value) && usePlaceholder) return placeholder;

    // Use translations to get the format
    let format = i18n.t(numberFormatKey);

    // It is possible for the returned value to point to a key in our defaults
    // As we may want some clients to still have the default value whenever that changes
    // For docs on how this works see: https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1428127889/Number+Localisation
    if (i18n.te(`numbers.default.${format}`)) {
        format = i18n.t(`numbers.default.${format}`);
    }

    // Use numeralJS to convert value using locale and format and return it
    return numeral(useAbsoluteValue ? Math.abs(value) : value).format(format);
};

// i18n.te(key) function checks whether the key exists, but it only checks out a default locale,
// while i18n.t function checks both default and fallback locales. So the tanslation may exist on
// fallback locale but construction if(te(key)) t(key) would behave as if the key does not exist.
// i18n.translationExists applies i18n.te function on both default and fallback locales and checks
// if Translation Exists on Any of the Locales.
i18n.translationExists = function(key) {
    return i18n.te(key, i18n.locale) || i18n.te(key, i18n.fallbackLocale);
};

i18n.d = function(dateFormatKey, date, { usePlaceholder = false, placeholder = '-' } = {}) {
    if (isNil(date) && usePlaceholder) return placeholder;
    return Vue.moment(date).format(i18n.t(dateFormatKey));
};

// Converts to UTC before formatting as local , inserting '2021-01-17' gives '2021-01-16' (23:00h) if your local time is positive eg. UTC+1
i18n.dUTC = function(dateFormatKey, date, { usePlaceholder = false, placeholder = '-' } = {}) {
    if (isNil(date) && usePlaceholder) return placeholder;
    return Vue.moment(date)
        .utc()
        .format(i18n.t(dateFormatKey));
};

// Parse date as UTC explicitly before formatting, inserting '2021-01-17' gives '2021-01-17' (0:00h)
i18n.eUTC = function(dateFormatKey, date, { usePlaceholder = false, placeholder = '-' } = {}) {
    if (isNil(date) && usePlaceholder) return placeholder;
    return Vue.moment.utc(date).format(i18n.t(dateFormatKey));
};

export default i18n;
