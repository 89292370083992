import Radio from './components/component-icons/channels/radio';
import TV from './components/component-icons/channels/tv';
import Leaflet from './components/component-icons/channels/leaflet';
import Any from './components/component-icons/channels/any';
import DigitalMedia from './components/component-icons/channels/digital-media';
import OnlineBanner from './components/component-icons/channels/online-banner';
import Loyalty from './components/component-icons/channels/loyalty';
import OnlineVideo from './components/component-icons/channels/online-video';
import Social from './components/component-icons/channels/social';
import Store from './components/component-icons/channels/store';
import Newspaper from './components/component-icons/channels/newspaper';

export default {
    values: {
        radio: {
            component: Radio,
        },
        tv: {
            component: TV,
        },
        leaflet: {
            component: Leaflet,
        },
        'digital-media': {
            component: DigitalMedia,
        },
        'online-banner': {
            component: OnlineBanner,
        },
        'online-video': {
            component: OnlineVideo,
        },
        loyalty: {
            component: Loyalty,
        },
        store: {
            component: Store,
        },
        social: {
            component: Social,
        },
        newspaper: {
            component: Newspaper,
        },
        any: {
            component: Any,
        },
    },
};
