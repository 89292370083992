<script>
import { mapState } from 'vuex';
import { isNil, isUndefined, get, forEach, replace, unset } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import AgBadgeWrapper from '@/js/components/ag-badge-wrapper';
import AgEditablePriceField from '@/js/pages/planning/components/offer-tab/ag-editable-price-field';

const formatField = (field, params) => {
    let result = field;
    forEach(params, (paramValue, paramKey) => {
        result = replace(result, `{${paramKey}}`, paramValue);
    });

    return result;
};

export default {
    localizationKey: 'planning.productDetails',
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                filter: true,
                menuTabs: [],
                columnDefs: null,
            },
            gridOptions: {
                frameworkComponents: {},
                headerHeight: 40,
                rowHeight: 26,
                paginationPageSize: 500,
                pagination: true,
                columnTypes: {
                    productField: {
                        cellClass: ['product-information'],
                        cellRendererParams: {
                            visible: params => {
                                return !isNil(params.data);
                            },
                        },
                    },
                },
            },
        };
    },

    computed: {
        ...mapState('clientConfig', ['productDetailsGridConfig']),

        // Places that implement this mixin should send in a getter to the products list
        products() {
            return this.model;
        },
    },

    methods: {
        getGridConfig({ context }) {
            // Configurable columns in product-details-grid.yaml are based on the modal context
            return get(this.productDetailsGridConfig, `${context}`);
        },

        getFormattedProductSizeAndUnit(params) {
            if (!params.data) return null;
            if (!isUndefined(params.data.packSize) && params.data.packUnit) {
                return `${params.data.packSize}${params.data.packUnit}`;
            }
        },

        getCellFormatter(format = 'numberShorthand') {
            return params => {
                if (isNil(params.value)) {
                    return null;
                }
                return get(this.$options.filters, format)(params.value);
            };
        },

        getCellRendererFramework(componentName) {
            if (!componentName) return null;

            const componentMap = {
                AgBadgeWrapper,
                AgEditablePriceField,
            };

            return componentMap[componentName];
        },

        mapColumnGroups(columnGroups, params) {
            return columnGroups.map(columnGroup => {
                const columnDef = {
                    ...columnGroup,
                    headerName: columnGroup.headerName
                        ? toSentenceCase(this.$tkey(`headers.${columnGroup.headerName}`, params))
                        : undefined,
                    ...(columnGroup.headerGroupComponentParamsInputFunc
                        ? {
                              headerGroupComponentParams: {
                                  inputFunc: this[columnGroup.headerGroupComponentParamsInputFunc],
                              },
                          }
                        : {}),
                    ...(columnGroup.cellRendererParams
                        ? {
                              cellRendererParams: columnGroup.cellRendererParams.reduce(
                                  (acc, param) => {
                                      acc[param] = this[param];
                                      return acc;
                                  },
                                  {}
                              ),
                          }
                        : {}),
                    children: columnGroup.children.map(field => {
                        return {
                            ...field,
                            ...(field.format
                                ? {
                                      valueFormatter: this.getCellFormatter(field.format),
                                  }
                                : {}),
                            ...(field.valueGetter
                                ? {
                                      valueGetter: ({ data }) =>
                                          this[field.valueGetter](data, params),
                                  }
                                : {}),
                            ...(field.getByPath
                                ? {
                                      valueGetter: ({ data }) =>
                                          get(data, formatField(field.getByPath, params)),
                                      // field is required to uniquely identify the column
                                      field: formatField(field.getByPath, params),
                                  }
                                : {}),
                            ...(field.cellRendererFramework
                                ? {
                                      cellRendererFramework: this.getCellRendererFramework(
                                          field.cellRendererFramework
                                      ),
                                      cellRendererParams: params,
                                  }
                                : {}),
                            ...(field.onCellValueChanged
                                ? {
                                      onCellValueChanged: this[field.onCellValueChanged],
                                  }
                                : {}),
                            headerName: field.headerName
                                ? toSentenceCase(this.$tkey(`headers.${field.headerName}`, params))
                                : undefined,
                            colId: field.headerName,
                        };
                    }),
                };

                // Unassign processed properties
                unset(columnDef, 'headerGroupComponentParamsInputFunc');

                return columnDef;
            });
        },
    },
};
</script>
