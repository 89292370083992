import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.canCreatePromotion } },
    [
      _c(
        "div",
        {
          class: [
            "create-promotion--prefix",
            "create-promotion--first-item",
            !_vm.splitPromotionsEnabled
              ? "create-promotion--prefix__margin"
              : "",
          ],
        },
        [
          _c("div", [
            _vm._v(
              _vm._s(
                _vm._f("toSentenceCase")(_vm.$tkey(_vm.getPromotionTypeText()))
              )
            ),
          ]),
          _vm._v(" "),
          _vm.splitPromotionsEnabled
            ? _c(
                VMenu,
                {
                  attrs: {
                    "close-on-content-click": true,
                    "offset-y": "",
                    bottom: "",
                    left: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.toggleCreateNewMenu(
                                      !_vm.isCreatePromotionMenuDisabled
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "icon-button",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "create-promotion--menu",
                                        attrs: {
                                          disabled:
                                            _vm.isCreatePromotionMenuDisabled,
                                          icon: "expand_more",
                                          "icon-on-right": "",
                                        },
                                      },
                                      "icon-button",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2121057442
                  ),
                  model: {
                    value: _vm.isMenuOpen,
                    callback: function ($$v) {
                      _vm.isMenuOpen = $$v
                    },
                    expression: "isMenuOpen",
                  },
                },
                [
                  _vm._v(" "),
                  _c(
                    VList,
                    { staticClass: "create-promotion--menu__list" },
                    [
                      _vm._l([false, true], function (isSplitPromotion) {
                        return [
                          isSplitPromotion !== _vm.splitPromotion
                            ? _c(
                                VListItem,
                                {
                                  key:
                                    "promotion-type-split-" + isSplitPromotion,
                                  on: {
                                    click: function ($event) {
                                      return _vm.setSplitPromotion(
                                        isSplitPromotion
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.$tkey(
                                              _vm.getPromotionTypeText(
                                                isSplitPromotion
                                              )
                                            )
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPromotionNameFieldVisible
        ? _c("vuex-text-field", {
            class: ["create-promotion--promotion-name"],
            attrs: {
              getter: () => _vm.promotionNameGetter(),
              setter: (value) => _vm.promotionNameSetter(value),
              placeholder: _vm._f("toSentenceCase")(_vm.$tkey("promotionName")),
              "make-read-only": _vm.isCreateProcess,
              white: "",
              "save-whitespace": "",
              "update-on-input": "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                !_vm.isCreatePromotionDisabled &&
                  _vm.$refs.createPromotionBtn.$refs.createResourceBtn.createNewResource()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "create-promotion-btn",
        _vm._b(
          {
            ref: "createPromotionBtn",
            class: ["ml-1", "create-promotion--first-item", "font-weight-bold"],
            attrs: {
              "btn-text": "actions.create",
              "make-read-only": _vm.isCreatePromotionDisabled,
              "read-only-reason": _vm.subCampaignEditingState.reason,
              "promotion-name": _vm.promotionNameGetter(),
              "split-promotion": _vm.splitPromotion,
              "before-create-function": _vm.beforeCreateProcess,
            },
            on: {
              promotionCreationFailed: function ($event) {
                return _vm.afterCreateProcess()
              },
              promotionCreated: function ($event) {
                return _vm.afterCreateProcess($event)
              },
            },
          },
          "create-promotion-btn",
          { background: true },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }