<template>
    <div class="rtls-combobox-container" :class="cssClass">
        <p v-if="label" class="rtls-combobox-container__label">
            {{ $t(label) | toSentenceCase }}
        </p>
        <v-combobox
            v-model="selectedItems"
            class="rtls-combobox"
            :class="{
                'rtls-select--auto-height': chips || multiple,
                'rtls-select--white': !filled,
            }"
            :items="getOptions"
            :placeholder="$t(placeholder) | toSentenceCase"
            :menu-props="{ bottom: true, offsetY: true }"
            :disabled="isDisabled"
            :multiple="multiple"
            :item-text="itemText"
            :item-value="itemValue"
            :small-chips="chips"
            :deletable-chips="deletableChips"
            single-line
            :rules="rules"
            :validate-on-blur="validateOnBlur"
            :clearable="clearable"
            :return-object="returnObject"
            :search-input.sync="searchInput"
            @change="updateStagingArea"
            @keyup.enter="ensureEnterButtonEventOccurs()"
        >
            <template slot="append">
                <v-icon size="28">expand_more</v-icon>
            </template>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t(noResultsPlaceholder, { searchInput }) | toSentenceCase }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item #default="{ active }" dense v-bind="attrs" :disabled="false" v-on="on">
                    <v-list-item-action v-if="multiple">
                        <v-checkbox :input-value="active" value :disabled="attrs.disabled" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-text-field
                            v-if="editing[itemValue] === item[itemValue]"
                            v-model="editing[itemText]"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            @keyup.enter="edit(item)"
                            @click.stop
                        />
                        <v-list-item-title v-else>
                            <v-row no-gutters>
                                <span>{{ item[itemText] }}</span>
                                <v-spacer />
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action @click.stop>
                        <icon-button
                            :icon="editing[itemValue] !== item[itemValue] ? 'edit' : 'check'"
                            @click="edit(item)"
                        />
                    </v-list-item-action>
                    <v-list-item-action v-if="item.canBeDeleted" @click.stop>
                        <delete-button background @delete="deleteItem(item)" />
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import { find, isString, pullAt, sortBy, uniqBy, last, clone, isEmpty, lowerCase } from 'lodash';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';

const sortByItemText = function(options) {
    return sortBy(options, [i => lowerCase(i[this.itemText])]);
};

export default {
    mixins: [vuexComponentMixin],

    props: {
        multiple: Boolean,
        itemValue: {
            type: String,
            default: 'value',
        },
        itemText: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: toSentenceCase(i18n.t(`placeholders.select`)),
        },
        noResultsPlaceholder: {
            type: String,
            default: 'general.combobox.noResultsPlaceholder',
        },
        label: String,
        cssClass: String,
        chips: Boolean,
        deletableChips: Boolean,
        filled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        sortFunction: {
            type: Function,
            default: sortByItemText,
        },
        sortResults: {
            type: Boolean,
            default: true,
        },
        invalidSelectionText: {
            type: String,
            default: 'general.info.invalidSelection',
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        validateOnBlur: {
            type: Boolean,
            default: true,
        },
        createAction: {
            type: String,
            required: true,
        },
        deleteAction: {
            type: Function,
            required: true,
        },
        updateAction: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            selectedItems: [],
            options: [],
            searchInput: null,
            editing: {},
        };
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },

        getOptions() {
            const options = this.parsedField
                ? this.parsedField.options.map(option => {
                      return {
                          ...option.reference,
                          disabled: option.disabled,
                      };
                  })
                : this.options;
            return this.sortResults ? this.sortFunction(options) : options;
        },
    },

    watch: {
        model() {
            this.initSelectedItems();
        },
    },

    created() {
        this.initSelectedItems();
    },

    methods: {
        initSelectedItems() {
            // For each item in the model, check if that item is disabled based
            // on the available options disabled flag.
            const result = this.model.map(item => {
                const matchingOption = find(
                    this.getOptions,
                    option => option[this.itemValue] === item[this.itemValue]
                );

                return { ...item, disabled: matchingOption.disabled };
            });

            this.selectedItems = result;
        },
        async ensureEnterButtonEventOccurs() {
            // There seems to be a bug surrounding the v-combobox that causes the input
            // to stop accepting the enter button clicks after we delete some chips.
            // This function is always fired on the enter button but really only comes
            // into play when the input stops working. It just makes sure that the model
            // selectedItems is updated with the searchInput and then we call the
            // updateStagingArea function to ensure any new tags are created.
            if (!!this.searchInput && !this.selectedItems.includes(this.searchInput)) {
                this.selectedItems.push(this.searchInput);
                await this.updateStagingArea();
            }
        },

        async updateStagingArea() {
            // Reset the search input when updating the model. This is a workaround for a bug
            // in the v-combobox where the search term remains after selecting an item.
            // https://github.com/vuetifyjs/vuetify/issues/8842
            this.searchInput = null;

            // If the last item in selectedItems is a string then we need to create
            // a new tag
            const lastItem = last(this.selectedItems);
            const lastItemIndex = this.selectedItems.length - 1;
            if (isString(lastItem)) {
                // The item added will either be a string or an object.
                // If the new item is an object, it will have been selected from the select box,
                // and so must be an existing option. If the value is a string, it will have been typed
                // by the user, so we need to check if it is an existing option or a new one which needs
                // to be created.
                const existingOption = find(this.getOptions, option => {
                    return option[this.itemText].toLowerCase() === lastItem.toLowerCase();
                });

                if (existingOption) {
                    // check that item isn't being added twice.
                    this.selectedItems[lastItemIndex] = existingOption;
                } else {
                    // Create the option adding it to the store.
                    const { error } = await this.$store.dispatch(this.createAction, {
                        [this.itemText]: lastItem,
                    });

                    if (error) {
                        // If an error occurs when creating the tag, remove from the selected items.
                        pullAt(this.selectedItems, lastItemIndex);
                    } else {
                        // Replace the string value in the array with the new item object.
                        this.selectedItems[lastItemIndex] = find(
                            this.getOptions,
                            option => option[this.itemText].toLowerCase() === lastItem.toLowerCase()
                        );
                    }
                }
            }

            // Ensure there are no duplicate entries and update the staging area via the model.
            this.selectedItems = uniqBy(this.selectedItems, item => item[this.itemValue]);
            this.model = this.selectedItems;
            this.$emit('change', this.selectedItems);
        },

        async deleteItem(item) {
            // Delete the option.
            await this.deleteAction(item);
            await this.updateStagingArea();
        },

        async edit(item) {
            if (isEmpty(this.editing)) {
                this.editing = clone(item);
            } else {
                if (this.editing[this.itemText] !== item[this.itemText]) {
                    await this.updateAction(this.editing);
                    await this.updateStagingArea();
                }
                this.editing = {};
            }
        },
    },
};
</script>
