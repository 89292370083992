import { merge } from 'lodash';
import axios from 'axios';
import to from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({});

const store = {
    namespaced: true,

    state: getInitialState(),

    getters: {},

    mutations: {},

    actions: {
        async createPromotionVersion(
            state,
            { promotionId, reason, isLatestVersionChangesetApplied = false }
        ) {
            const [error, response] = await to(
                axios.post(`/api/promotion-versions/${promotionId}`, {
                    reason,
                    isLatestVersionChangesetApplied,
                })
            );

            if (error) return { error }; // Return error so components can choose whether to perform another action

            return { result: response.data };
        },
    },
};

const mixinParams = {
    resource: 'promotion-versions',
    readonly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
