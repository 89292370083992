/* eslint-disable import/prefer-default-export */

/**
 * Utility functions for forecasting aggregations.
 *
 * @module client/js/utils/forecasting/aggregation-utils
 */

import { calculateSalesEfficiency, calculateMarginEfficiency } from './calculation-utils';

/**
 * Aggregates forecast metrics together.
 *
 * @param {Object} params - The params object.
 * @param {Number} params.forecastMetrics The array of forecast metrics to aggregate.
 */
function aggregateForecastMetrics({ forecastMetrics = [] }) {
    const defaultMetrics = {
        incrementalSalesExcTax: 0,
        incrementalMargin: 0,
        salesDiscountExcTax: 0,
        promoFunding: 0,
        incrementalMarginBeforeFunding: 0,
    };

    const aggregatedMetrics = forecastMetrics.reduce(
        (metricsAcc, metrics) => {
            if (!metrics) metrics = {};

            const metricsWithDefaults = {
                ...defaultMetrics,
                ...metrics,
            };

            metricsAcc.incrementalSalesExcTax += metricsWithDefaults.incrementalSalesExcTax;
            metricsAcc.incrementalMargin += metricsWithDefaults.incrementalMargin;
            metricsAcc.salesDiscountExcTax += metricsWithDefaults.salesDiscountExcTax;
            metricsAcc.promoFunding += metricsWithDefaults.promoFunding;
            metricsAcc.incrementalMarginBeforeFunding +=
                metricsWithDefaults.incrementalMarginBeforeFunding;

            return metricsAcc;
        },
        { ...defaultMetrics }
    );

    const calculatedEfficiencies = {
        salesEfficiency: calculateSalesEfficiency({
            incrementalSalesExcTax: aggregatedMetrics.incrementalSalesExcTax,
            salesDiscountExcTax: aggregatedMetrics.salesDiscountExcTax,
        }),
        marginEfficiency: calculateMarginEfficiency({
            incrementalMarginBeforeFunding: aggregatedMetrics.incrementalMarginBeforeFunding,
            funding: aggregatedMetrics.promoFunding,
            salesDiscountExcTax: aggregatedMetrics.salesDiscountExcTax,
        }),
    };

    return {
        ...aggregatedMetrics,
        ...calculatedEfficiencies,
    };
}

export default {
    aggregateForecastMetrics,
};
