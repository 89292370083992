import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.sortedItems.length === 0
      ? _c("div", [
          _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.emptyText)))),
        ])
      : _c(
          "ul",
          { staticClass: "simple-list" },
          [
            _vm._l(_vm.visibleItems, function (item, visibleIndex) {
              return _c(
                "li",
                {
                  key: "item" + visibleIndex,
                  staticClass: "simple-list__item",
                },
                _vm._l(_vm.displayFields, function (field, fieldIndex) {
                  return _c("span", { key: `field-${fieldIndex}` }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.get(item, field) ||
                            _vm.$t(`general.defaults.${_vm.nullValueKey}`)
                        ) +
                        "\n            "
                    ),
                  ])
                }),
                0
              )
            }),
            _vm._v(" "),
            _vm.showTooltip && _vm.hiddenItems.length
              ? _c(
                  "li",
                  { staticClass: "simple-list__more-items" },
                  [
                    _c(
                      VTooltip,
                      {
                        attrs: { "z-index": "300", bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b({}, "span", attrs, false),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tc(
                                            _vm.additionalItemsText,
                                            _vm.hiddenItems.length
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2080893712
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "simple-list" },
                          _vm._l(_vm.hiddenItems, function (item, hiddenIndex) {
                            return _c(
                              "li",
                              {
                                key: "h-item" + hiddenIndex,
                                staticClass: "simple-list__item",
                              },
                              _vm._l(
                                _vm.displayFields,
                                function (field, hiddenChildIndex) {
                                  return _c(
                                    "span",
                                    { key: "h-field" + hiddenChildIndex },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.get(item, field)) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }