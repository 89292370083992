<template>
    <div class="promotions-viewer">
        <div class="promotions-viewer__actions">
            <div
                class="promotions-viewer__filter-promotion promotions-viewer__filter-promotion--top promotions-viewer__create-promotion--left"
            >
                <label>Filter: </label>
                <vuex-text-field
                    :getter="() => filter.name"
                    :setter="value => (filter.name = value)"
                    :placeholder="$t('planning.promotionsViewer.promotionName') | toSentenceCase"
                    white
                    save-whitespace
                    ignore-parent-read-only
                    update-on-input
                    :class="['filter-promotion--promotion-name']"
                    @click.stop
                    @keydown.enter="applyFilter()"
                />
                <v-icon v-show="filter.name" color="primary" @click="resetFilter">
                    mdi-close
                </v-icon>
                <v-btn depressed outlined class="filter-btn" @click="applyFilter">
                    {{ $t('actions.apply') | toSentenceCase }}
                </v-btn>
            </div>
            <div class="pagination">
                <pagination
                    :length="paginationLength"
                    :value="paginationState.activePage + 1"
                    @input="paginationHandler"
                />
            </div>
            <div
                class="promotions-viewer__create-promotion promotions-viewer__create-promotion--top promotions-viewer__create-promotion--right"
            >
                <create-promotion
                    @promotionCreated="scrollToSelectedPromotion({ promotionId: $event })"
                />
            </div>
        </div>
        <div v-if="numberPromotionsInView > 0" class="promotions-viewer__grid">
            <div
                v-for="(item, index) in fields"
                :key="'header' + index"
                class="promotions-viewer__grid-cell promotions-viewer__grid-cell--heading"
                :class="[item.cellClasses, item.headingClasses]"
            >
                <template v-if="item.header.component">
                    <component
                        :is="item.header.component"
                        v-bind="item.header.props"
                        :class="item.header.classes"
                    />
                    <span :class="item.header.textClasses">
                        {{ $t(item.header.title) | toSentenceCase }}
                    </span>
                </template>
                <template v-else-if="item.sortable">
                    <div
                        class="promotions-viewer__grid-cell--sort"
                        :class="
                            getSortingOrder(item.sortableField)
                                ? 'promotions-viewer__grid-cell--heading-sorted'
                                : ''
                        "
                        @click="sortPromotions(item.sortableField)"
                    >
                        <div class="promotions-viewer__grid-cell--heading__sortable">
                            {{ $t(item.header) | toSentenceCase }}
                        </div>
                        <promotion-sorting :order="getSortingOrder(item.sortableField)" />
                    </div>
                </template>
                <template v-else>
                    <span class="promotions-viewer__grid-cell--heading__unsorted">{{
                        $t(item.header) | toSentenceCase
                    }}</span>
                </template>
            </div>

            <!-- Loop through all of the promotion rows -->
            <template v-for="(promotion, promotionIndex) in promotionsInView">
                <promotion-viewer
                    :key="'promotion::' + promotion._id"
                    :ref="`promotion_${promotion._id}`"
                    :promotion="getPromotionById(promotion._id)"
                    :index="promotionIndex"
                    :has-opened-promotions-above="
                        hasOpenedPromotionsAbove({
                            promotions: promotionsInView,
                            index: promotionIndex,
                        })
                    "
                    :scenario="
                        getScenarioById({
                            _id: promotion.scenarioId,
                            usePluralResourceName: true,
                        })
                    "
                    @split-requested="closePromotion"
                />
            </template>

            <!-- Convenience element for adding the sidebar elevation styles. -->
            <!-- Style is provided by the generic utilities class. -->
            <div class="promotions-viewer__sidebar-overlay elevation-2" />
        </div>
        <div class="promotions-viewer__actions">
            <div
                class="promotions-viewer__create-promotion promotions-viewer__create-promotion--bottom"
            >
                <create-promotion v-if="numberOfPromotionsForSelectedScenario >= 6" />
            </div>

            <feature-toggle :toggle="enableDownloadCentre">
                <download-centre :ignore-parent-read-only="true" />
                <template v-slot:alternative>
                    <v-btn
                        :disabled="exportBtnDisabled"
                        depressed
                        outlined
                        class="export-btn"
                        @click="onExport"
                    >
                        {{ $t('actions.export') | toSentenceCase }}
                        <icon icon-name="download" right small />
                    </v-btn>
                </template>
            </feature-toggle>
        </div>
        <template v-if="ghostPromotions.length > 0">
            <v-divider class="promotions-viewer__ghost-divider" />
            <div class="promotions-viewer__ghost-header">
                {{ $t('planning.promotionsViewer.excludedChildPromotions') | toSentenceCase }}
            </div>
            <div class="pagination" style="display: flex; justify-content: center;">
                <pagination
                    :length="ghostPaginationLength"
                    :value="paginationState.activeGhostPage + 1"
                    @input="ghostPaginationHandler"
                />
            </div>
            <div class="promotions-viewer__grid">
                <!-- Loop through all of the ghost promotion rows -->
                <template v-for="(promotion, promotionIndex) in ghostPromotionsInView">
                    <promotion-viewer
                        :key="'ghost-promotion::' + promotion._id"
                        :ref="`ghost-promotion_${promotion._id}`"
                        :promotion="getPromotionById(promotion._id)"
                        :index="promotionIndex"
                        :has-opened-promotions-above="
                            hasOpenedPromotionsAbove({
                                promotions: ghostPromotions,
                                index: promotionIndex,
                            })
                        "
                        :scenario="
                            getScenarioById({
                                _id: promotion.scenarioId,
                                usePluralResourceName: true,
                            })
                        "
                    />
                </template>

                <!-- Convenience element for adding the sidebar elevation styles. -->
                <!-- Style is provided by the generic utilities class. -->
                <div class="promotions-viewer__sidebar-overlay elevation-2" />
            </div>
        </template>
        <div class="promotions-viewer__actions promotions-viewer__actions__bottom">
            <div v-show="filter.isApplied" class="clear-filter">
                <strong>
                    {{ $t('planning.promotionsViewer.filterApplied') | toSentenceCase }}
                </strong>
                <button class="clear-filter-btn" @click="resetFilter">
                    <v-icon color="primary">
                        mdi-close
                    </v-icon>
                    <label>
                        {{ $t('actions.clearFilter') | toSentenceCase }}
                    </label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { get, isNil, some, includes, toLower } from 'lodash';
import UXEvents from '@enums/ux-events';
import ResourcesEnum from '@enums/resources';
import ExportFormats from '@enums/export-formats';
import { scroller } from 'vue-scrollto/src/scrollTo';
import { enablePromotionPermalink, enableDownloadCentre } from '@enums/feature-flags';
import getPromotionFields from '../config/promotions-viewer-fields';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import navigation from '@/js/navigation';

export default {
    mixins: [configDrivenGridComponentMixin],
    data() {
        return {
            visiblePromos: 0,
            fields: getPromotionFields(),
            ResourcesEnum,
            ExportFormats,
            orderedPromotions: [],
            ghostPromotions: [],
            numberPromotionsInView: 10,
            numberGhostPromotionsInView: 5,
            enableDownloadCentre,
            exportBtnDisabled: false,
            filter: { name: '', isApplied: false },
            paginationState: {
                promotionsPerPage: 10,
                activePage: 0,
                ghostPtomotionsPerPage: 5,
                activeGhostPage: 0,
            },
        };
    },
    events: {
        async onCreatePromotion(promotion) {
            if (this.toggleLogic[enablePromotionPermalink]) {
                // new promotion will be expanded, need to go to promotion view
                navigation.toPromotionView({ promotionId: promotion._id });
            } else {
                await this.toggleSelectedPromotion({ promotionId: promotion._id });
            }

            this.globalEmit(UXEvents.promotionCreated);

            // PROWEB-4839 - Commented out as new promos are created at the bottom of the list and scrolling to it
            // is currently not good for performance
            // this.scrollToSelectedPromotion({ promotionId: promotion._id });
        },
        // when promotions are updated in store,  we need to update them on UI
        onPromotionsUpdated() {
            this.reorderPromotions();
        },
        onChangeScenarioInRoute() {
            // clean memory before we change route
            this.orderedPromotions = [];
        },
    },
    computed: {
        ...mapState('promotions', [
            'sorting',
            'selectedPromotionId',
            'promotions',
            'numberOfPromosToRender',
        ]),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('scenarios', ['selectedScenarioId', 'getScenarioById']),
        ...mapGetters('promotions', ['getFilteredPromotions', 'getPromotionById']),

        numberOfPromotionsForSelectedScenario() {
            return this.getFilteredPromotions.length;
        },
        promotionsInView() {
            if (this.orderedPromotions.length <= this.numberPromotionsInView) {
                return this.orderedPromotions;
            }
            const startPosition =
                this.paginationState.promotionsPerPage * this.paginationState.activePage;
            let endPosition = startPosition + this.paginationState.promotionsPerPage;
            if (this.orderedPromotions.length - 1 < endPosition) {
                endPosition = this.orderedPromotions.length;
            }
            return this.orderedPromotions.slice(startPosition, endPosition);
        },
        ghostPromotionsInView() {
            if (this.ghostPromotions.length <= this.numberGhostPromotionsInView) {
                return this.ghostPromotions;
            }
            const startPosition =
                this.paginationState.ghostPtomotionsPerPage * this.paginationState.activeGhostPage;
            let endPosition = startPosition + this.paginationState.ghostPtomotionsPerPage;
            if (this.ghostPromotions.length - 1 < endPosition) {
                endPosition = this.ghostPromotions.length;
            }
            return this.ghostPromotions.slice(startPosition, endPosition);
        },
        numberOfGhostPromotions() {
            return this.getFilteredPromotions.filter(p => p.isGhost).length;
        },
        paginationLength() {
            return Math.ceil(
                this.orderedPromotions.length / this.paginationState.promotionsPerPage
            );
        },
        ghostPaginationLength() {
            return Math.ceil(
                this.ghostPromotions.length / this.paginationState.ghostPtomotionsPerPage
            );
        },
    },
    watch: {
        // The promotions displayed in the promotions viewer are no longer re-ordered whenever any of the promotions
        // change. The promotions are now only re-ordered when the selected promotion changes, or if any of the sorting
        // fields change. Creating and deleting promotions are covered by a change to the number of promotions for the
        // selected scenario.
        // This prevents the selected promotion from jumping up and down in the viewer when it is being edited.
        selectedPromotionId: 'reorderPromotions',
        sorting: 'reorderPromotions',
        numberOfPromotionsForSelectedScenario: 'reorderPromotions',
        numberOfGhostPromotions: 'reorderPromotions',
    },
    created() {
        // Ensure the promotions are ordered on load of the promotions viewer.
        const promotionsCount = get(this.toggleLogic, 'pagination.countPerPage.promotions', 10);
        const ghostsCount = get(this.toggleLogic, 'pagination.countPerPage.ghost', 5);
        this.numberPromotionsInView = promotionsCount;
        this.numberGhostPromotionsInView = ghostsCount;
        this.paginationState.promotionsPerPage = promotionsCount;
        this.paginationState.ghostPtomotionsPerPage = ghostsCount;

        this.reorderPromotions();
        this.scrollToSelectedPromotion({ promotionId: this.selectedPromotionId });
    },
    methods: {
        ...mapActions('promotions', [
            'toggleSelectedPromotion',
            'setSortingAction',
            'downloadPromotionsForScenario',
        ]),
        // Setup active pagination page after clicking on pagination component
        paginationHandler(value) {
            this.paginationState.activePage = value - 1;
        },

        // Setup ghost active pagination page after clicking on pagination component
        ghostPaginationHandler(value) {
            this.paginationState.activeGhostPage = value - 1;
        },
        applyFilter() {
            this.filter.isApplied = !!this.filter.name;
            this.reorderPromotions();
            this.paginationState.activePage = 0;
            this.paginationState.activeGhostPage = 0;
        },

        resetFilter() {
            if (this.filter.isApplied) {
                this.filter = { name: '', isApplied: false };
                this.reorderPromotions();
            } else {
                this.filter.name = '';
            }
            this.paginationState.activePage = 0;
            this.paginationState.activeGhostPage = 0;
        },

        sortPromotions(sortableField) {
            this.setSortingAction({ field: sortableField, isSingleFieldSorting: true });
            this.paginationState.activePage = 0;
            this.paginationState.activeGhostPage = 0;
        },

        getSortingConfig() {
            const fieldsArr = [];
            const orderingsArr = [];
            this.sorting.forEach(item => {
                fieldsArr.push(item.field);
                orderingsArr.push(item.order);
            });

            return { fieldsArr, orderingsArr };
        },
        getSortingOrder(field) {
            const sortingField = this.sorting.find(item => item.field === field) || {};
            return sortingField.order || null;
        },
        reorderPromotions() {
            const { fieldsArr, orderingsArr } = this.getSortingConfig();

            const promotions = this.getFilteredPromotions.filter(promotion => {
                let matchFilter = true;
                if (this.filter.isApplied && this.filter.name) {
                    matchFilter = includes(
                        toLower(promotion.name.replace(/ /g, '')),
                        toLower(this.filter.name.replace(/ /g, ''))
                    );
                }

                return !promotion.isGhost && matchFilter;
            });
            const ghostPromotions = this.getFilteredPromotions.filter(promotion => {
                let matchFilter = true;
                if (this.filter.isApplied && this.filter.name) {
                    matchFilter = includes(
                        toLower(promotion.name.replace(/ /g, '')),
                        toLower(this.filter.name.replace(/ /g, ''))
                    );
                }
                return promotion.isGhost && matchFilter;
            });

            this.orderedPromotions =
                fieldsArr.length === 0
                    ? promotions
                    : this.customSort([...promotions], fieldsArr, orderingsArr);

            this.ghostPromotions =
                fieldsArr.length === 0
                    ? ghostPromotions
                    : this.customSort([...ghostPromotions], fieldsArr, orderingsArr);
        },
        /**
         * Some fields are not required, in case if we don't have field the undefined value go to the sort
         * orderBy provide unpredictable result for this case.
         * This function explicit handle this case.
         * For supporting multi headers sorting, recursion was used
         */
        sortFunction(a, b, arrFields, arrOrder) {
            const aField = get(a, arrFields[0]);
            const bField = get(b, arrFields[0]);
            if (aField === undefined) {
                return 1;
            }
            if (bField === undefined) {
                return -1;
            }
            if (aField === bField) {
                if (arrFields.length === 1) {
                    return 0;
                }
                return this.sortFunction(a, b, arrFields.slice(1), arrOrder.slice(1));
            }

            if (arrOrder[0] === 'asc') {
                return aField < bField ? -1 : 1;
            }
            return aField < bField ? 1 : -1;
        },
        customSort(arr, arrFields, arrOrder) {
            return arr.sort((a, b) => this.sortFunction(a, b, arrFields, arrOrder));
        },

        hasOpenedPromotionsAbove({ promotions, index }) {
            return some(promotions, (promotion, promotionIndex) => {
                return promotionIndex < index && promotion._id === this.selectedPromotionId;
            });
        },

        scrollToSelectedPromotion({ promotionId }) {
            if (promotionId) {
                const promoIndex = this.orderedPromotions.findIndex(
                    orderedPromotion => orderedPromotion._id === promotionId
                );
                if (promoIndex > -1) {
                    const pageInPagination = Math.floor(
                        promoIndex / this.paginationState.promotionsPerPage
                    );
                    if (pageInPagination !== this.paginationState.activePage) {
                        this.paginationState.activePage = pageInPagination;
                    }
                } else {
                    const ghostPromoIndex = this.ghostPromotions.findIndex(
                        ghostPromotion => ghostPromotion._id === promotionId
                    );
                    if (ghostPromoIndex > -1) {
                        const pageInPagination = Math.floor(
                            ghostPromoIndex / this.paginationState.ghostPtomotionsPerPage
                        );
                        if (pageInPagination !== this.paginationState.activeGhostPage) {
                            this.paginationState.activeGhostPage = pageInPagination;
                        }
                    }
                }
                // nextTick is needed for Vue to actually collapse previously opened promotion before scrolling to new one
                // otherwise page with already opened promotion will be scrolled to incorrect place when creating new promotion
                this.$nextTick(() => {
                    // Using the default scrollTo methods allows for only one scroll action at a time for performance reasons.
                    // Imported the scroller factory directly and created instance for allowing simultaneous scrolling.
                    const scrollToPromotion = scroller();
                    // scroll to the first children of the promotion element,
                    // because element itself is not in the DOM (has display: contents; style)
                    const el = get(this.$refs, `promotion_${promotionId}.0.$children.0.$el`);
                    if (isNil(el)) return;

                    // default vue-scrollto library duration
                    const duration = 500;
                    // negative offset for the new promotion to be visible below fixed header
                    const offset = -75;

                    scrollToPromotion(el, duration, {
                        offset,
                    });
                });
            }
        },

        async onExport() {
            this.exportBtnDisabled = true;
            const result = await this.downloadPromotionsForScenario({
                params: {
                    export: true,
                    exportFormat: ExportFormats.excel,
                    exportSchema: 'promotions-for-scenario-flat',
                    fieldToUnwind: 'productOfferGroups',
                },
            });
            this.exportBtnDisabled = false;
            return result;
        },

        closePromotion() {
            navigation.toScenarioView({
                scenarioId: this.selectedScenarioId,
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$grid-padding: 2rem;

.promotions-viewer {
    background-color: $promo-grey-3;
    font-size: 1.2rem;

    &__grid {
        display: grid;

        // This number of columns here must match the number of data columns to be shown in the grid.
        // Each column can be adjusted in width within the row.
        // The sidebar columns are calculated based on the width of the sidebar element.
        grid-template-columns:
            $promotion-grid-template-columns
            repeat(3, calc(#{$health-sidebar-width / 3}));
        position: relative;
    }

    &__grid-cell {
        grid-column: span 1;

        &--sort {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0.5rem 0.5rem 0.5rem 0.8rem;
            cursor: pointer;

            &:hover {
                background: $hover-active-heading-color;
            }
        }

        &--heading {
            display: flex;
            align-items: center;

            background-color: $promo-table-blue-bg-colour-3;
            @include promo-component-border($top: true);

            &__unsorted {
                text-indent: 1rem;
            }
        }

        &--heading-sorted {
            background-color: $hover-active-heading-color;
        }

        &--main-content {
            padding-top: $grid-padding;

            background-color: $promo-grey-3;
        }

        &--sidebar-heading {
            z-index: $promo-details-z-index;
        }
    }

    .filter-promotion {
        &--promotion-name {
            width: 20rem;
            padding-left: 1.5rem;
        }
    }

    &__sidebar-overlay {
        @include promo-planner-health-sidebar;
    }

    &__create-new-promotion {
        margin-left: 1.5rem;
        color: $promo-primary-colour;
    }

    &--first-item {
        padding-left: 1.5rem;

        .promotions-viewer__grid-cell--sort {
            padding-left: 0.5rem;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;

        &__bottom {
            padding-top: $grid-padding;

            .clear-filter {
                margin: auto;

                &-btn {
                    box-shadow: none;
                    margin-left: 1.5rem;
                    padding: 0;

                    .mdi-close {
                        font-size: 1.8rem;
                    }

                    label {
                        cursor: pointer;
                        color: $promo-primary-colour;
                        text-decoration-line: underline;
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .export-btn {
            margin-right: 24rem;
            margin-top: 2rem;

            color: $promo-primary-colour;
        }
    }

    &__create-promotion,
    &__filter-promotion {
        display: flex;
        span {
            display: flex;
        }

        label {
            align-self: center;
        }

        &--top {
            padding-bottom: 2rem;
        }

        &--bottom {
            padding-top: 2rem;
        }

        &--right {
            margin-right: 30rem;
            float: right;
        }

        &--left {
            margin-left: 2rem;
            float: left;
        }

        .v-icon {
            color: $promo-primary-colour;
        }

        .filter-btn {
            margin-left: 1rem;
            align-self: center;

            color: $promo-primary-colour;
        }

        .mdi-close {
            position: absolute;
            left: 22rem;
            align-self: center;
        }
    }

    &__virtual-container {
        display: flex;
        flex-direction: column;
        padding-top: 5rem;
        position: absolute;
        div {
            height: 5.8rem;
            display: flex;
            position: relative;
        }
    }

    &__ghost-divider {
        margin: 2rem 1.5rem;
    }

    &__ghost-header {
        margin-left: 1.5rem;
    }
}
</style>
