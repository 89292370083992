var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { class: { "select-container": true, disabled: _vm.isDisabled } },
        [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value",
                },
              ],
              class: { "ag-select-input": true, disabled: _vm.isDisabled },
              attrs: {
                name: `select-field::${_vm.params.colDef.fieldName}`,
                disabled: _vm.isDisabled,
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.value = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c(
                "option",
                { attrs: { selected: "" }, domProps: { value: null } },
                [_vm._v(_vm._s(_vm.valuePlaceholder))]
              ),
              _vm._v(" "),
              _vm._l(_vm.options, function (item) {
                return _c(
                  "option",
                  { key: item.value, domProps: { value: item.value } },
                  [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("icon", {
            staticClass: "select-arrow",
            attrs: { "icon-name": "expand_more" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }