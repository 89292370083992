import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "icon-checkbox-container" },
    [
      _c(
        VTooltip,
        {
          attrs: {
            "z-index": "400",
            bottom: "",
            "max-width": 200,
            disable: _vm.hideTooltip,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "span",
                    _vm._g({}, on),
                    [
                      _c(VCheckbox, {
                        staticClass: "rtls-checkbox",
                        attrs: {
                          value: _vm.value,
                          label: _vm.label,
                          disabled: _vm.isDisabled,
                          "on-icon": "$" + _vm.icon,
                          "off-icon": "$" + _vm.icon,
                          rules: _vm.rules,
                          "hide-details": _vm.hideDetails,
                        },
                        model: {
                          value: _vm.model,
                          callback: function ($$v) {
                            _vm.model = $$v
                          },
                          expression: "model",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.tooltipLabel))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }