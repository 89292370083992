<template>
    <icon-button v-if="renderIcon" :icon="icon" :disabled="isDisabled" @click.stop="onClick" />
</template>

<script>
import Vue from 'vue';
import { get, isFunction } from 'lodash';

export default Vue.extend({
    computed: {
        renderIcon() {
            // conditional icon render based on row data
            if (this.params.conditionalRender) {
                return get(this.params.data, this.params.conditionPath, false);
            }
            return true;
        },

        icon() {
            return this.params.icon || 'edit';
        },

        isDisabled() {
            const data = this.params.data;

            if (isFunction(this.params.isDisabled)) {
                return this.params.isDisabled(data);
            }

            return false;
        },
    },
    methods: {
        onClick() {
            this.params.onClick(this.params.data);
        },
    },
});
</script>

<style lang="scss" scoped></style>
