<template>
    <div class="vuex-currency-input" :class="cssClass">
        <div
            class="vuex-currency-input__currency-symbol"
            :class="isDisabled ? 'vuex-currency-input__currency-symbol--disabled' : ''"
        >
            {{ numeralLocaleConfig.currency.symbol }}
        </div>
        <v-text-field
            v-model="currency"
            :disabled="isDisabled"
            reverse
            class="rtls-text-field rtls-text-field--white"
            :rules="rules"
            :validate-on-blur="validationOnBlur"
            type="text"
            placeholder="—"
            @keypress="validateValue"
            @change="updateModel"
        />
    </div>
</template>

<script>
import numeral from 'numeral';
import { isNil } from 'lodash';
import vuexComponentMixin from '../../mixins/vuex-component';
import validateNumberInputMixin from '../../mixins/validate-number-input';

export default {
    mixins: [vuexComponentMixin, validateNumberInputMixin],
    props: {
        disabled: Boolean,
        cssClass: String,
        format: {
            type: String,
            default: 'numbers.default.currencyNoLabelPadded',
        },
        validationOnBlur: {
            type: Boolean,
            default: () => true,
        },
        isValidateNumber: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            currencyInput: null,
        };
    },
    computed: {
        currency: {
            get() {
                // null/undefined shouldn't be formatted to 0
                return isNil(this.model) ? null : this.formattedValue(this.format, this.model);
            },
            set(newValue) {
                // eslint-disable-next-line no-underscore-dangle
                this.currencyInput = numeral(newValue)._value;
            },
        },

        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    created() {
        this.currencyInput = this.model;
    },
    methods: {
        updateModel() {
            this.model = this.currencyInput;
            this.$emit('change', this.currencyInput);
        },
        validateValue(event) {
            if (this.isValidateNumber) {
                return this.validateNumber(event);
            }
            return this.validateIntegerInput(event);
        },
        formattedValue(filterName, data) {
            const filter = this.$options.filters[filterName];
            if (typeof filter === 'function') {
                return filter(data);
            }
            return this.$n(filterName, data);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.vuex-currency-input {
    @include flex-row;

    &__currency-symbol {
        @include flex-center;
        font-weight: normal;
        height: 2.9rem;
        padding: 0 0.5rem;

        color: $promo-white;
        background-color: $promo-primary-colour;

        &--disabled {
            background-color: $promo-grey-dark;
            font-weight: normal;
        }
    }

    .error--text {
        font-weight: normal;
    }
}
</style>
