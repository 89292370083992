import { render, staticRenderFns } from "./losenge-monetary-display.vue?vue&type=template&id=4250a090&scoped=true&functional=true"
import script from "./losenge-monetary-display.vue?vue&type=script&lang=js"
export * from "./losenge-monetary-display.vue?vue&type=script&lang=js"
import style0 from "./losenge-monetary-display.vue?vue&type=style&index=0&id=4250a090&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4250a090",
  null
  
)

export default component.exports