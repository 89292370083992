<template functional>
    <svg
        width="19px"
        height="16px"
        viewBox="0 0 19 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <text x="0" y="11" fill="#2F477C">Any</text>
    </svg>
</template>

<script>
export default {
    name: 'Any',
};
</script>
