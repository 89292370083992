<template>
    <icon :icon-name="workflowStateIcon" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { mapWorkflowStateIcons } from '@/js/utils/workflow-utils';

export default {
    props: {
        promotionId: {
            type: String,
            required: true,
        },
        instanceKey: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapGetters('promotions', [
            'isPromotionPlannedOnResourceInstance',
            'getSubCampaignIdForEntity',
        ]),
        ...mapState('clientConfig', ['toggleLogic']),

        subCampaign() {
            const subCampaignId = this.getSubCampaignIdForEntity({ entityId: this.promotionId });
            return this.getSubCampaignById({
                _id: subCampaignId,
                usePluralResourceName: true,
            });
        },

        currentWorkflow() {
            // The entire workflow object is always stored on the sub-campaign of the current entity.
            return this.subCampaign.workflow;
        },

        workflowStateIcon() {
            // If the subCampaign does not have a workflow, then return null as no icon will be required.
            if (!this.currentWorkflow || !this.currentWorkflow.steps) return null;

            // Get workflow state for all instances of that resource type from the subCampaign
            const matchingResourceDefinition = this.subCampaign.resourceDefinitions.find(
                rd => rd.key === this.instanceKey
            );

            // If resource key cannot be found, return null.
            if (!matchingResourceDefinition) return null;

            // Check workflow includes steps relating to this entity.
            // If it does not, then return null as no icon will be required.
            if (
                !this.currentWorkflow.steps.some(
                    step => step.entity === matchingResourceDefinition.resourceKey
                )
            ) {
                return null;
            }

            const state = [...(matchingResourceDefinition.workflowState || [])];

            const isPromotionPlanned = this.isPromotionPlannedOnResourceInstance({
                promotionId: this.promotionId,
                subCampaignId: this.subCampaign._id,
                resourceKey: this.instanceKey,
            });

            state.push({
                entity: matchingResourceDefinition.resourceKey,
                state: 'planned',
                value: isPromotionPlanned,
            });

            const workflowStateIconsMap = createFeatureAwareFactory(
                this.toggleLogic
            ).getResourceStateIconsMap();

            return mapWorkflowStateIcons({
                workflowState: state,
                resourceType: matchingResourceDefinition.resourceKey,
                ...workflowStateIconsMap,
            }).icon;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.workflow-resources {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 2rem auto;

    &__icon {
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;
        border-left: $component-border-divider;

        &--last {
            border-right: $component-border-divider;
        }
    }
    &__details {
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 1rem;
    }

    &__loop-wrapper {
        display: contents;
    }
}
</style>
