/**
 * To see how this events framework works visit https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1716781333/Events+and+follow+on+actions
 */

// Import events for specific areas of the tool
import applicationEvents from './application-events';
import campaignEvents from './campaign-events';
import promotionEvents from './promotion-events';
import scenarioEvents from './scenario-events';
import subCampaignEvents from './sub-campaign-events';
import supplierCommitmentEvents from './supplier-commitment-events';
import rateCardEvents from './rate-card-events';
import variableFundingEvents from './variable-funding-events';

// Export all events as one array
export default [
    ...applicationEvents,
    ...campaignEvents,
    ...promotionEvents,
    ...scenarioEvents,
    ...subCampaignEvents,
    ...supplierCommitmentEvents,
    ...rateCardEvents,
    ...variableFundingEvents,
];
