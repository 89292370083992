import { flattenDeep, map, merge, uniq, isNil } from 'lodash';
import Vue from 'vue';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    variableFundingAgreements: [],
    selectedVariableFundingId: null,
    selectedCategory: null,
});

const mixinParams = {
    resource: 'variable-funding-agreement',
    useFilters: true,
    useForm: true,
    getInitialState,
};

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getVariableFundingAgreementsById
     */
    getters: {},

    /**
     * Default mutations available:
     *
     * - setLoading
     * - setVariableFundingAgreements
     * - deleteVariableFundingAgreement
     * - updateVariableFundingAgreement
     * - addVariableFundingAgreement
     * - resetState
     */
    mutations: {
        setSelectedVariableFundingId(state, variableFundingId) {
            Vue.set(state, 'selectedVariableFundingId', variableFundingId);
        },
        clearSelectedCategory(state) {
            state.selectedCategory = null;
        },
        setSelectedCategory(state, category) {
            state.selectedCategory = category;
        },
    },

    /**
     * Default actions available:
     *
     * - fetchVariableFundingAgreements
     * - createVariableFundingAgreement
     * - deleteVariableFundingAgreement
     * - updateVariableFundingAgreement
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        setSelectedVariableFundingId({ commit }, variableFundingId) {
            commit('setSelectedVariableFundingId', variableFundingId);
        },
        async submitVariableFunding({ dispatch }, { namespace }) {
            if (namespace === 'default') return;
            const { error } = await dispatch('submitForm', {
                namespace,
                editMode: true,
                submitAction: 'updateVariableFundingAgreement',
            });
            // handle any error
            if (error) return { error };
        },

        async fetchVariableFundingAgreementsSuppliersAndProductByCategory(
            { state, dispatch, rootGetters },
            category
        ) {
            const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];
            dispatch('suppliers/fetchSuppliersByCategories', category, { root: true });
            await dispatch('fetchVariableFundingAgreements', {
                params: {
                    where: {
                        'hierarchy.level': categoryLevel,
                        'hierarchy.levelEntryKey': category,
                    },
                },
            });
            const productKeys = uniq(
                flattenDeep(
                    map(state.variableFundingAgreements, variableFunding =>
                        map(variableFunding.scope.products, product => product.productKey)
                    )
                )
            );
            if (productKeys.length) {
                dispatch(
                    'products/fetchProducts',
                    {
                        params: { where: { productKey: { $in: productKeys } } },
                    },
                    { root: true }
                );
            }
        },

        refreshVariableFundingAgreements({ dispatch, state }) {
            if (isNil(state.selectedCategory)) return;

            return dispatch(
                'fetchVariableFundingAgreementsSuppliersAndProductByCategory',
                state.selectedCategory
            );
        },

        setSelectedCategory({ commit }, { categoryKey }) {
            commit('setSelectedCategory', categoryKey);
        },

        resetVariableFundingAgreementsState({ commit }) {
            commit('setVariableFundingAgreements', []);
            commit('clearSelectedCategory');
        },
    },
};

export default merge({}, storeMixin(mixinParams), store);
