'use strict';

/**
 * Enum definition file for export notifications.
 *
 * @module data/enums/export-notifications
 */
module.exports = {
    exportLimitReached: 'export-limit-reached',
};
