var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "parking-lot-status" },
    [
      _vm.params.data.isInParkingLot
        ? _c("vuex-checkbox", {
            attrs: {
              getter: () => _vm.params.node.selected,
              setter: _vm.setSelected,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.params.value === _vm.parkingLotStates.planned
        ? _c("vuex-checkbox", { attrs: { getter: () => true, disabled: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.params.value === _vm.parkingLotStates.executed
        ? _c("icon", { attrs: { "icon-name": "locked", small: "" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }