<template>
    <!--v-form reloads the page when user press any <button/> inside, fixed by @submit.prevent-->
    <v-form
        ref="form"
        v-model="validation.isValid"
        class="vuex-form vuex-form--single-column"
        @submit.prevent
    >
        <!-- FIXME: remove parsedField prop -->
        <template v-for="(field, index) in fields">
            <div
                v-if="field.contextFunction"
                :key="`contextFunction::${index}`"
                :class="field.cssClass"
            >
                {{
                    resolveValue({
                        source: 'contextFunction',
                        contextFunction: field.contextFunction,
                        params: field.params,
                    })
                }}
            </div>
            <component
                :is="getVuexComponent(field.type)"
                v-else-if="!field.omitField"
                v-show="!field.hidden"
                :key="index"
                v-bind="field"
                :vuex-module="vuexModule"
                :namespace="computedNamespace"
                :context="context"
                :parsed-field="parseFieldDefinition(field)"
                :disabled="isDisabledField({ editable: resolveValue(field.editable) })"
                :edit-mode="editMode"
                @change="field.onChange ? field.onChange($event) : () => ({})"
            >
                <template v-if="field.isContainer">
                    <component
                        :is="getVuexComponent(childField.type)"
                        v-for="(childField, childIndex) in field.children"
                        v-show="!childField.hidden"
                        :key="'child' + childIndex"
                        v-bind="childField"
                        :vuex-module="vuexModule"
                        :namespace="computedNamespace"
                        :context="context"
                        :parsed-field="parseFieldDefinition(childField)"
                        :disabled="isDisabledField({ editable: resolveValue(childField.editable) })"
                        :edit-mode="editMode"
                    />
                </template>
            </component>
        </template>
        <slot />
    </v-form>
</template>

<script>
import vuexFormMixin from '../../mixins/vuex-form';

export default {
    mixins: [vuexFormMixin],
    events: {
        onResetDialogForm(formId) {
            if (this.validation.formId === formId) {
                this.$refs.form.resetValidation();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vuex-form {
    & > * {
        margin: 1.3rem 0;
    }
}
</style>
