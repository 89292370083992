/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';
import workflowEntities from '@enums/workflow-entities';

/**
 * This function provide needed icon for resource depends on workflow status
 * in workflow we have true/false flags
 * ex: { isPromotionSubmitted: true, isPromotionApproved: false, isPromotionRejected: true }
 * we can represent it like 0 or 1 for each flag and join them in binary key => 101 for above case
 * in this way we can configure any icon for any combination of states for different clients in feature-flags.yaml
 * all what we need to know which state represent each position in the key
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.resource - it can be promotion, sub-campaign etc.
 * @param {String} RORO.resourceType
 * @param {Array} RORO.iconsStateMapOrdering - ordering which for positions in key
 * @param {Object} RORO.iconsStateMap - map for icons
 */
export function mapWorkflowStateIcons({
    workflowState,
    resourceType = workflowEntities.promotion,
    iconsStateMap = {},
    iconsStateMapOrdering = [],
    iconsStateMapToReleaseFlag = {},
    releaseFlags = {},
} = {}) {
    let workflowStateIcon = {};

    if (!workflowState) {
        return workflowStateIcon;
    }

    // Here we are counting key which need for getting proper icon from the 'iconsStateMap'
    // we go through 'iconsStateMapOrdering' and if state is true we concat '1' to the accumulator in other case we concat '0'
    // so each register will be represent state
    const stateIconsMapKey = iconsStateMapOrdering.reduce((acc, item) => {
        const state = workflowState.find(
            x => x.entity === resourceType && x.state === item && x.value
        );

        const isReleased = state
            ? get(releaseFlags, iconsStateMapToReleaseFlag[state.state], true)
            : true;

        return state && isReleased ? `${acc}1` : `${acc}0`;
    }, '$');

    workflowStateIcon = iconsStateMap[stateIconsMapKey] || {};

    return workflowStateIcon;
}
