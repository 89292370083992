var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "funded-promotions-list-wrapper__funded-promotions" },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("supplierCommitments.fundedPromotions")
                )
              ) +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.hasPromotionsToDisplay
        ? [
            _vm._l(_vm.promotions, function (promotionGroup, pgIndex) {
              return [
                _vm._l(promotionGroup.data, function (promotion, pIndex) {
                  return [
                    pIndex === 0
                      ? _c(
                          "h3",
                          {
                            key: `label::${pIndex}::${pgIndex}::${promotion._id}`,
                            staticClass:
                              "funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__promotion-group-title",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(promotionGroup.label)
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _c("div", {
                          key: `noLabel::${pIndex}::${pgIndex}::${promotion._id}`,
                          staticClass:
                            "funded-promotions-list-wrapper__single-column",
                        }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: `fundedPromotionsList::${pIndex}::${pgIndex}::${promotion._id}`,
                        staticClass:
                          "funded-promotions-list-wrapper__funded-promotions-list",
                      },
                      [
                        _c("funded-promotions-list", {
                          attrs: { promotion: promotion, fields: _vm.fields },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      key: `committedValueFiller::${pIndex}::${pgIndex}::${promotion._id}`,
                      staticClass:
                        "funded-promotions-list-wrapper__single-column",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: `executedFunding::${pIndex}::${pgIndex}::${promotion._id}`,
                        staticClass:
                          "funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__funding-value",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$n(
                                "numbers.default.currency",
                                promotion.executedFunding,
                                {
                                  usePlaceholder: true,
                                }
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        key: `plannedFunding::${pIndex}::${pgIndex}::${promotion._id}`,
                        staticClass:
                          "funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__funding-value total-spend-viewer__funding-value--right-border",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$n(
                                "numbers.default.currency",
                                promotion.plannedFunding,
                                {
                                  usePlaceholder: true,
                                }
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      key: `remainingRowFiller::${pIndex}::${pgIndex}::${promotion._id}`,
                      staticClass:
                        "funded-promotions-list-wrapper__remaining-row-filler",
                    }),
                  ]
                }),
                _vm._v(" "),
                _c("div", {
                  key: `productGroupDivider::${pgIndex}::${promotionGroup.label}`,
                  staticClass: "funded-promotions-list-wrapper__row-divider",
                }),
              ]
            }),
          ]
        : _c(
            "div",
            {
              staticClass:
                "funded-promotions-list-wrapper__funded-promotions funded-promotions-list-wrapper__funded-promotions--no-allocated-promotions",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("supplierCommitments.noAllocatedPromotions")
                    )
                  ) +
                  "\n    "
              ),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }