<template>
    <div v-if="hasParent" class="parent-permalink" @click="navigateToParentCampaign">
        <v-chip small label class="parent-badge font-weight-bold">
            {{ $t('general.badge.parent') }}
        </v-chip>
        <div class="link">
            <p>{{ parentSubCampaign.name }}</p>
            <i class="material-icons">chevron_right</i>
        </div>
    </div>
</template>

<script>
import { get, isNil } from 'lodash';
import { mapState } from 'vuex';
import navigation from '@/js/navigation';

export default {
    props: {
        subCampaignId: {
            type: String,
            required: true,
        },
        dialogCloseFunction: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapState('subCampaigns', ['subCampaigns']),
        currentSubCampaign() {
            if (!this.subCampaignId) return;
            return this.subCampaigns.find(s => s._id === this.subCampaignId);
        },
        parentSubCampaign() {
            return this.subCampaigns.find(s => s._id === this.currentSubCampaign.parentId);
        },
        hasParent() {
            return !isNil(get(this.currentSubCampaign, 'parentId'));
        },
    },
    methods: {
        navigateToParentCampaign() {
            navigation.toFavouriteScenario({
                subCampaign: this.parentSubCampaign,
            });
            this.dialogCloseFunction();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.parent-permalink {
    float: left;
    text-transform: uppercase;
    .parent-badge {
        background: $parent-badge !important;
        color: $promo-white;
        transform: scale(0.8);
        float: left;
        margin-left: -0.5rem;
    }

    .link {
        float: left;
        font-weight: bold;
        text-transform: none;
        cursor: pointer;
        color: $promo-primary-colour;
        font-size: 1.1rem;
        padding-top: 0.4rem;
        width: 19rem;
        p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 17rem;
            float: left;
        }
        i {
            font-size: 1.5rem;
        }
    }
    .link:hover {
        text-decoration: underline;
    }
}
</style>
