<template functional>
    <svg
        width="17px"
        height="17px"
        viewBox="0 0 17 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Empty Done</title>
        <defs>
            <polygon id="path-3" points="7 0.75 3 4.75 1 2.75 0 3.75 3 6.75 8 1.75" />
        </defs>
        <g id="Success-/-Tick" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Tick" transform="translate(5.000000, 5.000000)">
                <mask id="mask-4" fill="white">
                    <use xlink:href="#path-3" />
                </mask>
                <use fill="#3D8113" xlink:href="#path-3" />
            </g>
            <circle id="Combined-Shape" stroke="#3D8113" cx="8.5" cy="8.5" r="8" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'EmptySuccessCircle',
};
</script>
