import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "rtls-calendar-picker", class: _vm.cssClass },
    [
      _c("div", { staticClass: "rtls-calendar-picker__label-wrapper" }, [
        _vm.label
          ? _c("p", { staticClass: "rtls-calendar-picker__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isRequired
          ? _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rtls-calendar-picker__control" },
        [
          _c(
            VMenu,
            {
              attrs: {
                "close-on-content-click": false,
                transition: "scale-transition",
                "nudge-bottom": "25",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        VTextField,
                        _vm._g(
                          {
                            class: `rtls-text-field rtls-text-field--${_vm.theme} rtls-text-field--reverse-order`,
                            attrs: {
                              "data-id-e2e": _vm.dataIdE2E,
                              value: _vm.dateText,
                              placeholder: _vm._f("toSentenceCase")(
                                _vm.$t("general.dates.startEnd")
                              ),
                              rules: _vm.rules,
                              readonly: "",
                              disabled: _vm.isDisabled,
                            },
                          },
                          on
                        ),
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                VIcon,
                                {
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menu = true
                                    },
                                  },
                                },
                                [_vm._v("$date-picker")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _vm._v(" "),
              _c("date-picker", {
                attrs: {
                  dates: _vm.dateRange,
                  "fixed-start-date": _vm.computedFixedStartDate,
                  "make-read-only": _vm.isDisabled,
                  min: _vm.computedMin,
                  max: _vm.max,
                  range: "",
                },
                on: { complete: _vm.closeWhenComplete },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }