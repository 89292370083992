<template>
    <div>
        <main-dialog
            ref="dialog"
            class="dialog"
            heading="planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.header"
            btn-confirm-text="planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.selectProducts"
            :confirm-btn-disabled="!noOfPastedProductKeys || loading"
            :has-activator="hasActivator"
            :show-negative-action-button="false"
            @confirm="addBulkProductsToPromo"
            @close="closeDialog"
        >
            <template v-if="hasActivator" v-slot:actions="{ activator }">
                <icon icon-name="duplicate" small v-on="activator" @click.stop />
            </template>
            <template v-slot:default>
                <div class="bulk-upload">
                    <div>
                        {{
                            $t(
                                'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.information'
                            ) | toSentenceCase
                        }}
                    </div>
                    <div>
                        <b>
                            {{
                                $t(
                                    'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.instruction'
                                ) | toSentenceCase
                            }}
                        </b>
                        <b>
                            {{
                                $t(
                                    'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.warning'
                                ) | toSentenceCase
                            }}
                        </b>
                    </div>
                    <div class="product-key-area--input">
                        <v-textarea
                            v-model="productKeysText"
                            class="rtls-text-field--grey pb-1 pt-2"
                            hide-details
                            no-resize
                        />
                    </div>
                    <div class="product-key-area--report">
                        <div>
                            <div
                                v-if="noOfPastedProductKeys"
                                :class="{
                                    'product-key-area--report--warning': allProductKeysNotAdded,
                                }"
                            >
                                {{
                                    $t(
                                        'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.totalPastedKeys',
                                        { noOfPastedProductKeys }
                                    ) | toSentenceCase
                                }}
                            </div>
                            <div
                                v-if="!requestInProgress && !isNil(noOfCopiedKeys)"
                                :class="{
                                    'product-key-area--report--warning': allProductKeysNotAdded,
                                }"
                            >
                                {{
                                    $t(
                                        'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.totalCopiedKeys',
                                        { noOfCopiedKeys }
                                    ) | toSentenceCase
                                }}
                            </div>
                            <div v-if="requestInProgress" class="in-progress--message">
                                {{
                                    $t(
                                        'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.productsAreBeingAddedToThePromotion'
                                    ) | toSentenceCase
                                }}
                                <spinner-dynamic class="spinner" />
                            </div>
                        </div>
                        <div>
                            <v-btn
                                v-if="!isNil(noOfCopiedKeys) && showMoreDetails"
                                color="primary"
                                depressed
                                @click="openUploadReportDialog"
                            >
                                {{
                                    $t(
                                        'planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.uploadReport'
                                    ) | toSentenceCase
                                }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </main-dialog>
        <tree-view-dialog
            ref="bulk-upload-errors-dialog"
            :error-tree="uploadReportTree"
            header="planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.uploadReportKeys.header"
            @close="closeDialog"
        />
    </div>
</template>

<script>
import { size, isNil } from 'lodash';
import { mapActions, mapState } from 'vuex';

const SUPPORTED_DELIMETERS = [',', '\n', ':', ';'];

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        promotionId: {
            required: true,
        },
        productOfferGroupId: {
            required: true,
        },
        showMoreDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            noOfCopiedKeys: null,
            productKeysText: '',
            loading: false,
        };
    },
    computed: {
        ...mapState('promotions', ['uploadReportDetails']),
        requestInProgress() {
            return this.loading;
        },
        allProductKeysNotAdded() {
            return (
                !isNil(this.noOfCopiedKeys) && this.noOfPastedProductKeys !== this.noOfCopiedKeys
            );
        },
        chosenDelimter() {
            return SUPPORTED_DELIMETERS.find(del => this.productKeysText.includes(del)) || ',';
        },
        splitProductKeys() {
            return this.productKeysText.split(this.chosenDelimter).filter(key => !!key);
        },
        noOfPastedProductKeys() {
            return size(this.splitProductKeys);
        },

        uploadReportTree() {
            return Object.keys(this.uploadReportDetails).reduce((acc, key, index) => {
                acc.push({
                    id: index,
                    name: this.$options.filters.toSentenceCase(
                        `${this.$t(
                            `planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.uploadReportKeys.${key}`
                        )}: ${size(this.uploadReportDetails[key])}`
                    ),
                    children: this.uploadReportDetails[key].map((pKey, ix) => {
                        return {
                            id: ix,
                            name: pKey,
                        };
                    }),
                });
                return acc;
            }, []);
        },
    },
    methods: {
        ...mapActions('promotions', ['addBulkProductsToPromotion']),
        isNil,
        openDialog() {
            this.noOfCopiedKeys = null;
            this.productKeysText = '';
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.$refs.dialog.close();
        },
        async addBulkProductsToPromo() {
            this.loading = true;
            const productKeys = this.splitProductKeys;

            const { productKeysAdded = [] } = await this.addBulkProductsToPromotion({
                promotionId: this.promotionId,
                productOfferGroupId: this.productOfferGroupId,
                productKeys,
            });

            this.noOfCopiedKeys = size(productKeysAdded);
            this.openUploadReportDialog();
            this.loading = false;
            this.$emit('confirm');
        },

        openUploadReportDialog() {
            if (!isNil(this.noOfCopiedKeys)) {
                this.$refs['bulk-upload-errors-dialog'].open();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.bulk-upload {
    font-size: 1.3rem;
}

::v-deep {
    .v-text-area__slot {
        label {
            padding-left: 1rem;
        }
    }
    .spinner {
        width: 1.5rem !important;
        height: 1.5rem !important;
    }
}

.product-key-area {
    &--input {
        height: 15rem;
        ::v-deep {
            .v-textarea {
                border-radius: 0;
                box-shadow: 0;
            }

            .v-label {
                top: 0.5rem !important;
            }

            .v-input__slot {
                .v-text-field__slot {
                    textarea {
                        margin: 0;
                        margin-top: 0.5rem;
                        height: 12rem;
                        overflow-y: auto;
                        padding-left: 1rem;
                    }
                }
            }
        }
    }

    &--report {
        display: flex;
        justify-content: space-between;
        height: 6rem;
        &--warning {
            color: red;
        }

        .in-progress__message {
            font-size: 1.3rem;
            color: $promo-text-colour;
        }
    }
}
</style>
