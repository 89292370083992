<template>
    <div class="candidates-list" :style="getWrapperStyles">
        <!-- Only candidates with class 'allowed-to-drag' are draggable -->
        <draggable
            v-model="candidatesPage"
            v-bind="draggableAreaOptions"
            draggable=".allowed-to-drag"
            :sort="false"
            :disabled="isDisabled"
            class="candidates-list__drop-area"
            :style="draggableStyles"
            @change="$emit('draggable-change', $event)"
        >
            <div v-if="renderPlaceholder" id="noCandidatesPlaceholder" class="text-center">
                {{
                    $t('planning.promotionsMaintenance.products.candidates.noCandidatesPlaceholder')
                }}
            </div>
            <div
                v-for="(candidate, ix) in candidatesPage"
                v-else
                :key="`candidate-${candidate.name}-${candidate._id}`"
                :class="[
                    candidate.preventDrag ? 'disabled' : 'allowed-to-drag',
                    { 'candidates-list__candidate-hover': isDraggable && !isDisabled },
                ]"
                class="candidates-list__candidate"
                :style="getCandidatesStyles(ix)"
            >
                <product-group
                    v-if="candidate.products"
                    v-bind="createVBindCandidate(candidate)"
                    :draggable="isDraggable && !candidate.preventDrag"
                    :disabled="isDisabled || !!candidate.preventDrag"
                    :selected-products="selectedProducts"
                    :selectable="productSelectionEnabled && !candidate.preventDrag"
                    :is-open="isProductGroupExpandedByDefault({ productGroup: candidate })"
                    :editable="isProductGroupsEditable && !candidate.preventDrag"
                    :is-new="isNewPg(candidate)"
                    :product-lozenge-layout="getProductLozengeLayout()"
                    :list-namespace="listNamespace"
                    :product-groups-opened-state="productGroupOpenState[listNamespace]"
                    :promotion-namespace="promotionNamespace"
                    class="candidates-list__product-group"
                    @closed="toggleProductGroup({ productGroup: candidate, isOpened: false })"
                    @opened="toggleProductGroup({ productGroup: candidate, isOpened: true })"
                    @pg-created="handleProductGroupCreated"
                    @delete="$emit('delete-product-group', candidate)"
                    @product-selector-update="productSelectorUpdate"
                    @draggable-change="
                        $emit('product-group-draggable-change', {
                            changes: $event,
                            productGroup: candidate,
                        })
                    "
                />
                <product
                    v-else-if="!candidate.hideProduct"
                    :product="candidate"
                    :selected="isProductSelected(candidate)"
                    :draggable="isDraggable && !candidate.preventDrag"
                    :selectable="productSelectionEnabled && !candidate.preventDrag"
                    :disabled="isDisabled || !!candidate.preventDrag"
                    :layout="getProductLozengeLayout()"
                    class="candidates-list__product"
                    @product-selector-update="productSelectorUpdate"
                />
            </div>
        </draggable>
        <slot />
    </div>
</template>

<script>
import { findIndex, difference, differenceWith, get, cloneDeep } from 'lodash';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import UXEvents from '@enums/ux-events';
import { removeProductFromSubCampaignHeroProducts } from '@sharedModules/promotion-utils';
import temporaryIds from '@enums/temporary-ids-for-new-resources';
import { fieldTypes } from '@enums/vuex-form';

export default {
    props: {
        // listNamespace is used similarly to how the namespace is used in our stagingAreas
        // It is used to keep track of which product groups are open in different areas of the app
        listNamespace: {
            required: true,
            type: String,
        },
        items: {
            required: true,
            type: Array,
        },
        draggableAreaOptions: {
            required: false,
            type: Object,
            default: () => {},
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        candidatesSetter: {
            required: false,
            type: Function,
            default: () => null,
        },
        isDraggable: {
            required: false,
            type: Boolean,
            default: false,
        },
        productSelectionEnabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        selectedProducts: {
            required: false,
            type: Array,
            default: () => [],
        },
        expandProductGroupsByDefault: {
            required: false,
            type: Boolean,
            default: false,
        },
        isProductGroupsEditable: {
            type: Boolean,
            required: false,
            default: false,
        },
        secondColumnWidth: {
            type: String,
            required: false,
            default: '22rem',
        },
        pagination: {
            type: Object,
            required: false,
        },
        isSelectedCandidates: {
            type: Boolean,
            default: false,
        },
        isSelectedList: {
            type: Boolean,
            default: false,
        },
        promotionNamespace: String,
        showPlaceholder: {
            type: Boolean,
            default: false,
        },
        enforceCategoryDragPermissions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                vuexModule: 'promotionProductGroups',
                fields: [
                    {
                        fieldName: 'name',
                        type: fieldTypes.text,
                        placeholder:
                            'planning.promotionsMaintenance.products.selectedProducts.namePlaceholder',
                        editable: true,
                    },
                    {
                        fieldName: 'products',
                        placeholder:
                            'planning.promotionsMaintenance.products.selectedProducts.dragAreaPlaceholder',
                        editable: true,
                        defaultValue: [],
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState('clientConfig', [
            'productLozengeConfig',
            'hierarchyConfig',
            'productDraggableArea',
        ]),
        ...mapState('promotionCandidates', ['productGroupOpenState']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('context', ['userCategoryKeys']),
        ...mapGetters('products', ['getProductsCategories']),

        candidates: {
            get() {
                return this.attachPermissionProps(this.items);
            },
            set(candidates) {
                return this.candidatesSetter(candidates);
            },
        },

        candidatesPage: {
            get() {
                if (!this.pagination) {
                    return this.candidates;
                }
                const firstElemIx = (this.pagination.page - 1) * this.pagination.perPage;
                const lastElemIx = firstElemIx + this.pagination.perPage;
                return this.candidates.slice(firstElemIx, lastElemIx);
            },
            set(candidates) {
                if (this.isSelectedCandidates) {
                    // We need to be more careful about how we handle updates when we are
                    // in the products tab as an item can be added or removed
                    const isBeingRemoved = candidates.length < this.candidatesPage.length;
                    const candidateComparator = (a, b) => {
                        // return true if a and b are both product groups, with the same id,
                        // or they are both products with the same productKey.
                        return (
                            (a.products && b.products && a._id === b._id) ||
                            (!a.products && !b.products && a.productKey === b.productKey)
                        );
                    };
                    const itemsBeingMoved = isBeingRemoved
                        ? differenceWith(this.candidatesPage, candidates, candidateComparator)
                        : differenceWith(candidates, this.candidatesPage, candidateComparator);
                    let updatedCandidates;
                    if (isBeingRemoved && itemsBeingMoved.length) {
                        const productKeys = [];
                        itemsBeingMoved.forEach(itemBeingMoved => {
                            const isProductGroup = !!itemBeingMoved.products;

                            updatedCandidates = this.candidates.filter(
                                candidate => !candidateComparator(candidate, itemBeingMoved)
                            );
                            if (this.isSelectedList) {
                                // If we are removing from the selected candidates list
                                // make sure we remove this product if it is assigned to the hero
                                // products of the sub-campaign in a leaflet
                                productKeys.push(
                                    ...(isProductGroup
                                        ? get(itemBeingMoved, 'products', []).map(
                                              product => product.productKey
                                          )
                                        : [get(itemBeingMoved, 'productKey')])
                                );
                            }
                        });

                        if (productKeys.length) {
                            const {
                                hasUpdates,
                                resourceDefinitions: updatedResourceDefinitions,
                            } = removeProductFromSubCampaignHeroProducts(
                                cloneDeep(this.selectedSubCampaign),
                                productKeys
                            );
                            if (hasUpdates) {
                                this.setUpdatedResourceDefinitions(updatedResourceDefinitions);
                            }
                        }
                    } else {
                        // Item is being added
                        updatedCandidates = this.candidates.concat(itemsBeingMoved);
                    }
                    return this.candidatesSetter(updatedCandidates);
                }
                return this.candidatesSetter(candidates);
            },
        },

        renderPlaceholder() {
            return this.showPlaceholder && this.candidatesPage.length === 0;
        },
        isDisabled() {
            return this.disabled || Boolean(this.isReadOnly);
        },

        isTwoColumnsTemplate() {
            return !!this.$slots.default;
        },
        draggableStyles() {
            if (this.isTwoColumnsTemplate) {
                return { display: 'contents' };
            }

            return {};
        },
        getWrapperStyles() {
            if (this.isTwoColumnsTemplate) {
                return {
                    'grid-template-columns': `auto ${this.secondColumnWidth}`,
                    'grid-template-rows': `repeat(${this.candidates.length}, auto)`,
                };
            }
            return {
                'grid-template-columns': `auto`,
            };
        },
        userCategoriesSet() {
            return new Set(this.userCategoryKeys);
        },
    },
    created() {
        // Create the initial state of which PGs are open
        const initialProductGroupsOpenState = this.items.reduce((acc, item) => {
            if (item.products) {
                acc[item._id] = this.expandProductGroupsByDefault;
            }
            return acc;
        }, {});

        // Set the state in the store so that other components know what PGs are open
        this.updateProductGroupOpenState({
            namespace: this.listNamespace,
            pgState: initialProductGroupsOpenState,
        });
    },
    methods: {
        ...mapMutations('promotionCandidates', ['updateProductGroupOpenState']),
        ...mapActions('subCampaigns', ['setUpdatedResourceDefinitions']),
        getProductLozengeLayout() {
            return this.productLozengeConfig[this.listNamespace];
        },
        productSelectorUpdate(payload) {
            this.globalEmit(UXEvents.setPromotionProduct, payload);
        },
        isProductSelected({ productKey }) {
            return findIndex(this.selectedProducts, key => key === productKey) !== -1;
        },
        handleProductGroupCreated(productGroup) {
            this.toggleProductGroup({ productGroup, isOpened: true });
        },
        toggleProductGroup({ productGroup, isOpened }) {
            this.updateProductGroupOpenState({
                namespace: this.listNamespace,
                pgState: { [productGroup._id]: isOpened },
            });
        },
        createVBindCandidate(candidate) {
            // Combine the candidate with the form so that it can be used with the vuex-form framework
            return {
                ...candidate,
                ...this.form,
                editContext: candidate,
                namespace: candidate._id,
                editMode: true,
                isStagingAreaResetNeeded: false,
            };
        },
        isNewPg(candidate) {
            return candidate._id === temporaryIds.newProductGroupId;
        },
        isProductGroupExpandedByDefault({ productGroup }) {
            return this.expandProductGroupsByDefault || this.isNewPg(productGroup);
        },
        getCandidatesStyles(index) {
            if (this.isTwoColumnsTemplate) {
                return { 'grid-column': '1', 'grid-row': `${index + 1}` };
            }
            return {};
        },
        attachPermissionProps(items) {
            // This is a generic component used in a few places.
            // Enforcing category permissions is not needed everywhere and the logic can be quite intense
            // Therefore we can speed things up in components that don't need to do it.
            if (!this.enforceCategoryDragPermissions) {
                return items;
            }
            // If config doesn't care about users categories then we can just return items.
            if (
                !this.productDraggableArea.hideProductsWithCategoriesUserHasNoAccessTo &&
                this.productDraggableArea.allowToDragProductsWithCategoriesUserHasNoAccessTo
            ) {
                return items;
            }
            return items.map(item => {
                // applying different logics for product groupds and products
                if (item.products) {
                    const { preventDrag } = this.productGroupPermissionProps(item);
                    item.preventDrag = preventDrag;
                    return item;
                }
                const { hideProduct, preventDrag } = this.productPermissionProps(item);
                item.hideProduct = hideProduct;
                item.preventDrag = preventDrag;
                return item;
            });
        },
        productPermissionProps(candidate) {
            // Getting category
            const { levelEntryKey: categoryKey } = candidate.hierarchy.find(
                h => h.level === this.hierarchyConfig.categoryLevel
            );
            // Products can be dragged if user has access to product's category or flag is set
            const accessToCategory = this.userCategoriesSet.has(categoryKey);
            const preventDrag =
                !accessToCategory &&
                !this.productDraggableArea.allowToDragProductsWithCategoriesUserHasNoAccessTo;
            // Products are hidden if user has no access to product's category and flag is set
            let hideProduct = false;
            if (this.productDraggableArea.hideProductsWithCategoriesUserHasNoAccessTo) {
                hideProduct = !accessToCategory;
            }
            return { preventDrag, hideProduct };
        },
        productGroupPermissionProps(candidate) {
            // User is not allowed to drag or edit product group if he doesn't have access to all categories or flag is not set
            const accessToCategories =
                (
                    difference(
                        this.getProductsCategories(candidate.products),
                        this.userCategoryKeys
                    ) || []
                ).length === 0;
            const preventDrag =
                !accessToCategories &&
                !this.productDraggableArea.allowToDragProductsWithCategoriesUserHasNoAccessTo;
            return { preventDrag };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.candidates-list {
    display: grid;
    height: 100%;

    &__drop-area {
        height: 100%;
    }

    &__candidate-hover {
        &:hover {
            cursor: pointer;
        }
    }

    #noCandidatesPlaceholder {
        color: $promo-grey-dark;
    }
}
</style>
