import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier-commitment-form-wrapper" },
    [
      _vm.isNewCommitment || _vm.editContext
        ? _c(
            "supplier-commitment-form",
            _vm._b(
              {
                ref: "supplierCommitmentForm",
                attrs: {
                  "is-promotion-supplier-funding-view":
                    _vm.isPromotionSupplierFundingView,
                  "promotion-namespace": _vm.promotionNamespace,
                },
                on: {
                  toggle: _vm.formToggle,
                  supplierNameChange: _vm.setFormSupplierKey,
                  "matrix-updated": _vm.setButtonsContainerWidth,
                },
              },
              "supplier-commitment-form",
              _vm.form,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayPromotions
        ? _c(
            "div",
            {
              staticClass: "supplier-commitment-form-wrapper__view-promotions",
            },
            [
              _c("supplier-commitment-promotions", {
                attrs: { "supplier-commitment": _vm.editContext },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isReadOnly
        ? _c(
            "div",
            {
              staticClass: "supplier-commitment-form-wrapper__buttons",
              style: { width: _vm.buttonsContainerWidth },
            },
            [
              _c(
                "div",
                [
                  !_vm.editContext
                    ? _c("create-new-button", {
                        attrs: {
                          "btn-text": _vm._f("toSentenceCase")(
                            _vm.$t("supplierCommitments.buttons.newCommitment")
                          ),
                          disabled: _vm.isCreateButtonDisabled,
                        },
                        on: { createNew: _vm.addNewCommitment },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "supplier-commitment-form-wrapper__buttons--save",
                },
                [
                  _vm.isNewCommitment || _vm.editContext
                    ? [
                        _vm.isNewCommitment || _vm.editContext
                          ? _c("confirm-dialog", {
                              attrs: {
                                "question-text": _vm.$t(
                                  "dialogs.deleteSupplierCommitment.header"
                                ),
                                "action-text": _vm.$t("actions.delete"),
                              },
                              on: {
                                confirm: _vm.deleteCurrentSupplierCommitment,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function ({ activator }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              attrs: {
                                                text: "",
                                                secondary: "",
                                                small: "",
                                              },
                                            },
                                            activator
                                          ),
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm._f("toSentenceCase")(
                                                    _vm.$t("actions.delete")
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2583629150
                              ),
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "save-btn",
                            attrs: {
                              primary: "",
                              small: "",
                              disabled: _vm.isSaveDisabled,
                            },
                            on: { click: _vm.save },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t("actions.save")
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }