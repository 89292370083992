<template>
    <div class="inheritance-chips" :class="{ 'parent-and-child': isParent && isChild }">
        <v-chip v-if="isParent" small label class="parent-badge font-weight-bold">
            {{ $t('general.badge.parent') }}
        </v-chip>
        <v-chip v-if="isChild" small label class="child-badge font-weight-bold">
            {{ $t('general.badge.child') }}
        </v-chip>
    </div>
</template>

<script>
import { get, isNil } from 'lodash';

export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isParent() {
            const children = get(this.context, 'children', []);
            return children.length > 0;
        },
        isChild() {
            return !isNil(get(this.context, 'parentId'));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.inheritance-chips {
    float: left;
    text-transform: uppercase;

    .parent-badge {
        background: $parent-badge !important;
        color: $promo-white;
    }

    .child-badge {
        background: $child-badge !important;
    }
}
.parent-and-child {
    .child-badge {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        ::v-deep {
            .v-chip__content {
                width: 1.7rem !important;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
    .parent-badge {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        ::v-deep {
            .v-chip__content {
                width: 1.9rem !important;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
</style>
