import { find, get, merge, isEmpty, includes, filter } from 'lodash';

import axios from 'axios';
import to from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '../../vue-i18n';

const getInitialState = () => ({
    suppliers: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getSupplierById
     * - getFilteredSuppliers
     */
    getters: {
        getSuppliersByCategories: state => state.suppliers,
        categoryFromFilter: (state, getters, rootState, rootGetters) => {
            const selectedCategoryKey = get(state, 'filter.categories', []);
            if (selectedCategoryKey) {
                return find(rootGetters['context/userCategories'], {
                    levelEntryKey: selectedCategoryKey,
                });
            }
            return null;
        },

        suppliersFromFilter: (state, getters) => {
            const selectedSupplierKeys = get(state, 'filter.suppliers', []);
            if (!isEmpty(selectedSupplierKeys)) {
                return filter(getters.getSuppliersByCategories, supplier =>
                    includes(selectedSupplierKeys, supplier.supplierKey)
                );
            }
            return [];
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setSuppliers
     * - deleteSupplier
     * - updateSupplier
     * - addSupplier
     * - setSelectedFilter
     * - resetFilter
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchSuppliers
     * - deleteSupplier
     * - updateSupplier
     * - createSupplier
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     */
    actions: {
        async fetchSuppliersByCategories({ commit }, categoryKeys = []) {
            commit('setLoading', true);

            const suppliersByCategory = await axios.get('/api/suppliers/getSuppliersByCategories', {
                params: {
                    categoryKeys,
                },
            });

            commit('setSuppliers', suppliersByCategory.data.suppliers);

            commit('setLoading', false);
        },

        fetchSuppliersByFilterCategories({ dispatch, getters }) {
            const categoryKeys = getters.categoryFromFilter
                ? [getters.categoryFromFilter.levelEntryKey]
                : [];
            return dispatch('fetchSuppliersByCategories', categoryKeys);
        },

        async fetchSCIByProductKeys({ dispatch }, { params }) {
            if (isEmpty(params.productKeys)) {
                return [];
            }

            const [error, response] = await to(
                axios.post('/api/suppliers/getSuppliersByProductKeys', params)
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: 'suppliers',
                    }),
                });
            }

            return response.data.suppliers;
        },
        async updatePromotionProductSuppliers({ dispatch }, { promotion }) {
            const [error, response] = await to(
                axios.post('/api/suppliers/updatePromotionProductSuppliers', {
                    promoObject: promotion,
                })
            );

            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: 'suppliers',
                    }),
                });
            }
            return response.data;
        },
    },
};

const mixinParams = {
    resource: 'supplier',
    useFilters: true,
    readOnly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
