import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "vuex-form vuex-form--single-column",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.validation.isValid,
        callback: function ($$v) {
          _vm.$set(_vm.validation, "isValid", $$v)
        },
        expression: "validation.isValid",
      },
    },
    [
      _vm._l(_vm.fields, function (field, index) {
        return [
          field.contextFunction
            ? _c(
                "div",
                { key: `contextFunction::${index}`, class: field.cssClass },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.resolveValue({
                          source: "contextFunction",
                          contextFunction: field.contextFunction,
                          params: field.params,
                        })
                      ) +
                      "\n        "
                  ),
                ]
              )
            : !field.omitField
            ? _c(
                _vm.getVuexComponent(field.type),
                _vm._b(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !field.hidden,
                        expression: "!field.hidden",
                      },
                    ],
                    key: index,
                    tag: "component",
                    attrs: {
                      "vuex-module": _vm.vuexModule,
                      namespace: _vm.computedNamespace,
                      context: _vm.context,
                      "parsed-field": _vm.parseFieldDefinition(field),
                      disabled: _vm.isDisabledField({
                        editable: _vm.resolveValue(field.editable),
                      }),
                      "edit-mode": _vm.editMode,
                    },
                    on: {
                      change: function ($event) {
                        field.onChange ? field.onChange($event) : () => ({})
                      },
                    },
                  },
                  "component",
                  field,
                  false
                ),
                [
                  field.isContainer
                    ? _vm._l(field.children, function (childField, childIndex) {
                        return _c(
                          _vm.getVuexComponent(childField.type),
                          _vm._b(
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !childField.hidden,
                                  expression: "!childField.hidden",
                                },
                              ],
                              key: "child" + childIndex,
                              tag: "component",
                              attrs: {
                                "vuex-module": _vm.vuexModule,
                                namespace: _vm.computedNamespace,
                                context: _vm.context,
                                "parsed-field":
                                  _vm.parseFieldDefinition(childField),
                                disabled: _vm.isDisabledField({
                                  editable: _vm.resolveValue(
                                    childField.editable
                                  ),
                                }),
                                "edit-mode": _vm.editMode,
                              },
                            },
                            "component",
                            childField,
                            false
                          )
                        )
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }