var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c("div", { staticClass: "revert-container" }, [
        !_vm.readOnly
          ? _c("div", { staticClass: "ag-revert-input" }, [
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _vm.isRevertVisible
                    ? _c("icon-button", {
                        staticClass: "button",
                        attrs: { icon: "revert" },
                        on: {
                          click: function ($event) {
                            return _vm.revert()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "input-container" },
                [
                  _c("vuex-number-input", {
                    staticClass: "input",
                    attrs: {
                      getter: () => _vm.overrideValue,
                      setter: (value) => _vm.setValue(value),
                      placeholder: _vm.initialValue,
                      filled: "",
                      "positive-only": "",
                      format: "numbers.default.overwrittenVolumeEntry",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "initial-value" }, [
          _vm._v("\n        " + _vm._s(_vm.readOnlyValue) + "\n    "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }