<template>
    <div class="list-price-grid">
        <div class="list-price-grid__header">
            {{ $tkey('listPrice') | toSentenceCase }}
        </div>
        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 35rem;"
            grid-class="ag-theme-custom__candidates-grid"
            dom-layout="normal"
            :single-click-edit="true"
            :make-read-only="promotionEditingState.disabled"
            :read-only-reason="promotionEditingState.reason"
            @cell-value-changed="onCellValueChanged"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { isEmpty, get } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import { channels } from '@enums/promotion-tabs';
import AgSelectAllHeader from '@/js/components/promo-ag-grid/ag-select-all-header';
import AgCheckBox from '@/js/components/promo-ag-grid/ag-checkbox';
import AgCurrencyInput from '@/js/components/promo-ag-grid/ag-currency-input';

export default {
    localizationKey: 'planning.promotionsMaintenance.resources.featuredProducts.gridHeadings',

    props: {
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
        namespace: {
            required: true,
            type: String,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            gridApi: null,
            rowData: [],
            // Defines the attributes which apply to all columns defined in columnDefs.
            defaultColDef: {
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
            },
            gridOptions: {
                rowHeight: 35, // Specified in pixels.
                applyColumnDefOrder: true, // apply default order of columns when colDef is updated
                suppressHorizontalScroll: true, // Stops an issue with two horizontal scrollbars appearing.
                frameworkComponents: { AgSelectAllHeader },
                headerHeight: 120,
                getRowNodeId: data => data.productKey,
            },
        };
    },

    events: {
        onCandidateRemovedFromPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
        onCandidateAddedToPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
    },

    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        dataForGrid() {
            return this.selectedPromotion.products;
        },

        columnDefs() {
            const columns = [
                {
                    headerName: toSentenceCase(this.$tkey('productKey')),
                    field: 'clientProductKey',
                    cellClass: ['product-information'],
                    maxWidth: 80,
                    minWidth: 80,
                    pinned: 'left',
                },
                {
                    headerName: toSentenceCase(this.$tkey('productName')),
                    field: 'name',
                    cellClass: ['product-information', 'product-information__name'],
                    maxWidth: 200,
                    minWidth: 170,
                    pinned: 'left',
                },
                {
                    headerName: toSentenceCase(this.$tkey('brandName')),
                    field: 'brandDescription',
                    cellClass: ['product-information'],
                    maxWidth: 90,
                    minWidth: 90,
                },
                {
                    headerName: toSentenceCase(this.$tkey('supplierName')),
                    field: 'supplierName',
                    cellClass: ['product-information'],
                    maxWidth: 120,
                    minWidth: 120,
                },
                {
                    headerName: toSentenceCase(this.$tkey('sizeAndUnits')),
                    valueGetter: this.getFormattedProductSizeAndUnit,
                    cellClass: ['product-information', 'product-information--last-column'],
                    maxWidth: 80,
                    minWidth: 80,
                },
                {
                    headerName: toSentenceCase(this.$tkey('regularPrice')),
                    field: 'nonPromoPrices.avgPrice',
                    valueFormatter: params =>
                        this.formatCellValueAsCurrency(params, 'currencyPadded'),
                    cellClass: ['product-information__right-border'],
                    maxWidth: 100,
                    minWidth: 80,
                },
                {
                    field: 'nonPromoListPriceSameAsSystem',
                    headerComponent: 'AgSelectAllHeader',
                    headerComponentParams: {
                        label: toSentenceCase(this.$tkey('listPriceSameAsRegular')),
                    },
                    cellRendererFramework: AgCheckBox,
                    cellRendererParams: {
                        onSelectUnSelectAll: ({ updates, isSelected }) => {
                            const productUpdates = [];
                            updates.forEach((product, ix) => {
                                if (isSelected) {
                                    // Reset nonPromoListPrice when nonPromoListPriceSameAsSystem
                                    // has been selected.
                                    product.nonPromoListPrice = null;
                                }

                                productUpdates.push({
                                    fieldPath: `products[${ix}]`,
                                    fieldName: 'nonPromoListPrice',
                                    value: product.nonPromoListPrice,
                                });

                                productUpdates.push({
                                    fieldPath: `products[${ix}]`,
                                    fieldName: 'nonPromoListPriceSameAsSystem',
                                    value: product.nonPromoListPriceSameAsSystem,
                                });
                            });

                            this.setStagingAreaFields({
                                namespace: this.namespace,
                                fieldsToUpdate: productUpdates,
                            });
                            this.setUnsavedPromotion({
                                namespace: this.namespace,
                                tab: channels,
                                value: true,
                            });
                        },
                    },
                    maxWidth: 45,
                    minWidth: 45,
                    sortable: false,
                    unSortIcon: false,
                },
                {
                    headerName: toSentenceCase(this.$tkey('manualListPrice')),
                    field: 'nonPromoListPrice',
                    cellRendererFramework: AgCurrencyInput,
                    cellRendererParams: {
                        onInputChange: params => {
                            this.onCellValueChanged(params);
                        },
                        isDisabled: data => {
                            return get(data, 'nonPromoListPriceSameAsSystem', false);
                        },
                    },
                    maxWidth: 100,
                    minWidth: 90,
                },
                {
                    headerName: toSentenceCase(this.$tkey('incrementalSales')),
                    field: 'forecastingAggregations.product.incrementalSalesExcTax',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__incremental'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                {
                    headerName: toSentenceCase(this.$tkey('salesEfficiency')),
                    field: 'forecastingAggregations.product.salesEfficiency',
                    valueFormatter: this.formatCellValueAsDecimal,
                    cellClass: ['metrics', 'metrics__margin'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                {
                    headerName: toSentenceCase(this.$tkey('incrementalMargin')),
                    field: 'forecastingAggregations.product.incrementalMargin',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__incremental'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                {
                    headerName: toSentenceCase(this.$tkey('marginEfficiency')),
                    field: 'forecastingAggregations.product.marginEfficiency',
                    valueFormatter: this.formatCellValueAsDecimal,
                    cellClass: ['metrics', 'metrics__margin'],
                    maxWidth: 95,
                    minWidth: 95,
                },
            ];

            if (isEmpty(this.hiddenColumns)) {
                return columns;
            }

            return columns.filter(column => !this.hiddenColumns.includes(column.field));
        },

        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },
    },

    methods: {
        ...mapActions('promotions', ['setPromotionProductListPrice', 'setStagingAreaFields']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.setRowData(this.dataForGrid);
        },
        formatCellValueAsCurrency(params, format) {
            const currencyFormat = format || 'currencyShorthand';
            return this.$n(`numbers.default.${currencyFormat}`, params.value, {
                usePlaceholder: true,
            });
        },

        formatCellValueAsDecimal(params) {
            return this.$n('numbers.default.numberWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },

        getFormattedProductSizeAndUnit(params) {
            if (params.data.packSize !== undefined && params.data.packUnit) {
                return `${params.data.packSize}${params.data.packUnit}`;
            }
        },

        onCellValueChanged({ data, newValue, oldValue, colDef }) {
            if (newValue !== oldValue) {
                if (colDef.field === 'nonPromoListPriceSameAsSystem') {
                    this.setPromotionProductListPrice({
                        productKey: data.productKey,
                        // Reset nonPromoListPrice when nonPromoListPriceSameAsSystem
                        // has been selected.
                        nonPromoListPrice: newValue ? null : data.nonPromoListPrice,
                        nonPromoListPriceSameAsSystem: newValue,
                        namespace: this.namespace,
                    });
                } else if (colDef.field === 'nonPromoListPrice') {
                    this.setPromotionProductListPrice({
                        productKey: data.productKey,
                        nonPromoListPrice: newValue,
                        nonPromoListPriceSameAsSystem: data.nonPromoListPriceSameAsSystem,
                        namespace: this.namespace,
                    });
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.list-price-grid {
    border-bottom: solid;
    border-bottom-width: 0.75rem !important;
    border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;

    &__header {
        margin-top: 1.5rem;
    }
}
</style>
