<template functional>
    <svg
        width="16px"
        height="13px"
        viewBox="0 0 16 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Trash</title>
        <g id="parent-child_v1-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Parent-Child---08-02"
                transform="translate(-1391.000000, -1043.000000)"
                fill="#2F477C"
            >
                <g id="Icon-/-Trash" transform="translate(1391.000000, 1042.000000)">
                    <path
                        id="Combined-Shape"
                        d="M8,5.0875 L4,2.5875 L8,0.0875 L8,1.9125 C11.5898509,1.9125 14.5,4.82264913 14.5,8.4125 C14.5,12.0023509 11.5898509,14.9125 8,14.9125 C4.41014913,14.9125 1.5,12.0023509 1.5,8.4125 L2.75,8.4125 C2.75,11.3119949 5.10050506,13.6625 8,13.6625 C10.8994949,13.6625 13.25,11.3119949 13.25,8.4125 C13.25,5.51300506 10.8994949,3.1625 8,3.1625 L8,5.0875 Z"
                        transform="translate(8.000000, 7.500000) rotate(-90.000000) translate(-8.000000, -7.500000) "
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Reinstatiate',
};
</script>
