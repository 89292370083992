'use strict';

const { sum, sumBy, isNil, round } = require('lodash');

/**
 * Apportion the value based on a products percentage weighting.
 * Applying any remainder the the first product in the array.
 * @param {Object} RORO - The RORO wrapper
 * @param {Array} RORO.products - The products to apportion across.
 * @param {Number} RORO.valueToApportion - The value being apportioned.
 * @param {Function} RORO.getWeight - Function to return the weighting for each product.
 * @param {Number} RORO.decimalPlaces - The number of places to round the apportioned value should be rounded to
 */
function remainderToFirst({ products, valueToApportion, getWeight, decimalPlaces = 0 }) {
    // calculate all totalVolume across all products based on the predicted total volumes
    const totalVolume = sumBy(products, product => getWeight(product));
    const numberOfProducts = products.length;

    // create array of the apportioned value based on individual product values
    const updatedProductsFields = products.map(product => {
        if (!isNil(valueToApportion)) {
            if (!totalVolume) {
                // If total volume is zero, then split valueToApportion evenly
                return round(valueToApportion / numberOfProducts, decimalPlaces);
            }
            return round((valueToApportion * getWeight(product)) / totalVolume, decimalPlaces);
        }
        return null;
    });

    // find difference between the value to apportion the apportioned values so far
    // ex: 1000 - sum([336, 224, 439]) = 1
    // add this difference to the first non-zero product
    // ex: result [336+1, 224, 439]
    if (!isNil(valueToApportion)) {
        const unapportionedValue = valueToApportion - sum(updatedProductsFields);
        for (let productIndex = 0; productIndex < updatedProductsFields.length; productIndex += 1) {
            if (updatedProductsFields[productIndex]) {
                updatedProductsFields[productIndex] += unapportionedValue;
                break;
            }
        }
    }

    return updatedProductsFields;
}

module.exports = {
    remainderToFirst,
};
