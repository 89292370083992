var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "document-scroller" }, [
    _c(
      "div",
      { staticClass: "document-navigator", style: _vm.gridCss },
      [
        _c(
          "div",
          { staticClass: "document-navigator__arrow" },
          [
            _c("navigate-button", {
              attrs: { disabled: !_vm.canScrollLeft, left: "" },
              on: { click: _vm.navigateLeft },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.documentsToShow, function (doc, docIndex) {
          return _c(
            "div",
            {
              key: doc.key,
              staticClass: "document clickable",
              class: {
                "selected-document": doc.key === _vm.selectedDocumentKey,
                "document__border--right":
                  docIndex === _vm.numDocumentsToShow - 1,
              },
              on: {
                click: function ($event) {
                  return _vm.selectDocument(doc.key)
                },
              },
            },
            [
              _c("div", { staticClass: "ml-2" }, [
                _c("h3", [_vm._v(_vm._s(_vm._f("toSentenceCase")(doc.title)))]),
                _vm._v(" "),
                _c("h4", [
                  _vm._v(_vm._s(_vm._f("toSentenceCase")(doc.subType))),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  { staticClass: "truncate-text" },
                  [
                    _c("truncate-text-tooltip", {
                      attrs: { text: doc.subtitle, "max-width": 800 },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _vm._l(_vm.placeholderDocuments, function (doc, placeholderIndex) {
          return _c("div", {
            key: doc,
            staticClass: "document",
            class: {
              "document__border--right":
                placeholderIndex === _vm.placeholderDocuments.length - 1,
            },
          })
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "document-navigator__arrow" },
          [
            _c("navigate-button", {
              attrs: { disabled: !_vm.canScrollRight, right: "" },
              on: { click: _vm.navigateRight },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }