import routeNames from '@enums/route-names';
import store from '@/js/store';

// to be sure that nothing left selected from planning/preparation tabs
async function clearSelectedEntities() {
    await Promise.all([
        store.dispatch('campaigns/clearSelectedCampaign'),
        store.dispatch('subCampaigns/clearSelectedSubCampaignId'),
        store.dispatch('scenarios/clearSelectedScenario'),
        store.dispatch('promotions/clearSelectedPromotion'),
    ]);
}

const guardsMap = {
    async [routeNames.pastPromotionsView](to, from, next) {
        await Promise.all([
            clearSelectedEntities(),
            store.dispatch('products/fetchProductBrands'),
            store.dispatch('products/fetchProductSuppliers'),
        ]);
        next();
    },

    async [routeNames.parkingLotView](to, from, next) {
        await Promise.all([
            clearSelectedEntities(),
            store.dispatch('products/fetchProductBrands'),
            store.dispatch('products/fetchProductSuppliers'),
        ]);
        next();
    },

    async [routeNames.subCampaignAllocationView](to, from, next) {
        await clearSelectedEntities();
        next();
    },
};

export default guardsMap;
