'use strict';

const { uniq, uniqBy, intersection, map, groupBy } = require('lodash');

/**
 * Filters the list of store groups to those matching the set of attribute restrictions.
 *
 * @param {Array} storeGroupAttributeRestrictions - The list of attribute restrictions to filter on.
 * @param {Array} storeGroups - All store groups available.
 */
const getAccessibleStoreGroups = ({ storeGroupAttributeRestrictions = [], storeGroups }) => {
    // Need to map the attributes to the list of store groups.
    // This logic should live in a utils file which can be run on login.
    const accessibleStoreGroups = [];

    // Store group attribute restrictions is an array of distinct restrictions. The user
    // will have access to any store group satisfied by at least one of these restrictions.
    storeGroupAttributeRestrictions.forEach(attributeRestrictions => {
        // For each set of restrictions filter down the store groups
        // to return only the store groups keys which satisfy all restrictions.
        const filteredStoreGroups = storeGroups.filter(storeGroup => {
            return attributeRestrictions.every(attributeRestriction => {
                // A store group satisfies each restriction if it has
                // an attribute with the corresponding attribute name and a value
                // given in the attribute restriction.
                return storeGroup.attributes.some(
                    attribute =>
                        attribute.attributeName === attributeRestriction.attributeName &&
                        attributeRestriction.values.includes(attribute.value)
                );
            });
        });
        const filteredStoreGroupKeys = map(filteredStoreGroups, 'key');

        accessibleStoreGroups.push(...filteredStoreGroupKeys);
    });

    const uniqueStoreGroups = uniq(accessibleStoreGroups);
    return uniqueStoreGroups;
};

/**
 * Filters the store group attribute restrictions to remove any attributes or values which
 * are no longer valid options.
 *
 * @param {Array} storeGroupAttributeRestrictions - The list of attribute restrictions to filter.
 * @param {Array} storeGroupAttributeOptions - The list of store groups attribute options to filter with.
 */
const filterInactiveAttributes = ({
    storeGroupAttributeRestrictions = [],
    storeGroupAttributeOptions = [],
}) => {
    const filteredRestrictions = [];

    storeGroupAttributeRestrictions.forEach(restrictions => {
        const filteredRestriction = [];
        restrictions.forEach(restriction => {
            const attributeOptions = storeGroupAttributeOptions.find(
                options => options.attributeName === restriction.attributeName
            );

            // Restriction is for a valid attribute.
            // Now remove any options which are no longer valid.
            if (attributeOptions) {
                const filteredValues = intersection(
                    restriction.values,
                    map(attributeOptions.options, 'value')
                );

                if (filteredValues.length) {
                    filteredRestriction.push({
                        attributeName: restriction.attributeName,
                        values: filteredValues,
                    });
                }
            }
        });
        if (filteredRestriction.length) filteredRestrictions.push(filteredRestriction);
    });

    return filteredRestrictions;
};

/**
 * Generate a list of all attribute options for the supplied storeGroups.
 *
 * @param {Object} storeGroups - The list of store groups to retrieve the attributes from.
 */
const getStoreGroupAttributeOption = ({ storeGroups = [] }) => {
    // Identify all store group options available
    const storeGroupAttributes = storeGroups.flatMap(sg => sg.attributes);

    // Reduce this to a unique list of options based on the attribute name and value.
    const uniqueAttributes = uniqBy(
        storeGroupAttributes,
        attr => `${attr.attributeName}::${attr.value}`
    );

    // Group these based on their attribute
    const groupedAttributes = groupBy(uniqueAttributes, 'attributeName');

    // Finally return an array of objects containing each attributeName and their options.
    return Object.entries(groupedAttributes).map(([key, value]) => {
        return {
            attributeName: key,
            options: value,
        };
    });
};

module.exports = {
    getAccessibleStoreGroups,
    filterInactiveAttributes,
    getStoreGroupAttributeOption,
};
