var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotions__grid" },
    [
      _vm._l(_vm.promotions, function (promotion, rowIndex) {
        return _vm._l(_vm.fields, function (item, colIndex) {
          return _c(
            "div",
            {
              key: promotion._id + "::" + rowIndex + "::" + colIndex,
              ref: `promotion_${promotion._id}`,
              refInFor: true,
              staticClass: "promotions__grid-cell",
              class: [item.cellClasses, item.contentClasses],
            },
            [
              _c(
                "div",
                {
                  staticClass: "promotions__data",
                  class: _vm.getColourClass(promotion),
                },
                [
                  item.functionName
                    ? _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.callAction(item.functionName, promotion))
                          ),
                        ]),
                      ])
                    : item.component
                    ? _c(
                        "div",
                        [
                          _c(
                            item.component.name,
                            _vm._g(
                              _vm._b(
                                { tag: "component" },
                                "component",
                                _vm.constructVBindObj({
                                  context: promotion,
                                  field: item.field,
                                  ...item.component,
                                }),
                                false
                              ),
                              _vm.constructVBindEventObj({
                                events: _vm.getAttribute(
                                  item.component,
                                  "events"
                                ),
                                context: promotion,
                              })
                            ),
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(item.component.text)
                                  )
                                ) + "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("span", { staticClass: "truncate-text" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getAttribute(promotion, item.field)) +
                            "\n                "
                        ),
                      ]),
                ]
              ),
            ]
          )
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }