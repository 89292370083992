import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.enableSplitPromotions } },
    [
      _c(
        VBtn,
        {
          attrs: { primary: "", depressed: "", disabled: _vm.disabled },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$refs.dialog.open()
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.$tkey(`splitPromotion`)) + "\n    ")]
      ),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "dialog",
        attrs: {
          "question-text": _vm.$tkey(`confirmationQuestion`, {
            productCount: _vm.productCount,
          }),
          "action-text": _vm.$t("actions.confirm"),
          "has-activator": false,
        },
        on: { confirm: _vm.confirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }