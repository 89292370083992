<template>
    <div class="parkinglot-grid-container" :class="overflowClass">
        <parking-lot-sub-campaign-selector
            class="parking-lot-header"
            @change="handleSelectorChange"
        />

        <overview-filter @change="updateFilter" />

        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 100%;"
            grid-class="ag-theme-custom__parkinglot-grid"
            dom-layout="normal"
            @grid-ready="onGridReady"
        />

        <div class="status-bar__action--left">
            <create-promotion-overview
                @create-promotion="openDialog()"
                @set-split-promotion="setSplitPromotion"
                @set-promotion-name="setPromotionName"
            />
            <download-resource-button
                class="parkinglot-grid-container__download-btn"
                :is-secondary-btn="true"
                :resource="resourcesEnum.promotions"
                :format="excel"
                :text-btn="true"
                :label="$t('parkingLot.buttons.export')"
                :download-action="downloadAction"
                :where="where"
                :export-schema="exportSchema"
            />
        </div>

        <v-btn
            class="status-bar__action--right"
            primary
            :disabled="allocationDialogDisabled"
            @click="openAllocationDialog"
        >
            {{ $t('parkingLot.allocateSelectedPromotions') | toSentenceCase }}
        </v-btn>

        <promotion-viewer-dialog
            ref="promotion-viewer-dialog"
            :key="`promotion-viewer::${selectedPromotion._id}::${promotionToggle}`"
            :promotion="selectedPromotion"
            :split-promotion="splitPromotion"
            :promotion-name="promotionName"
            @close="closePromoViewerDialog"
        />
        <allocate-promotion-dialog
            ref="promotion-allocation-dialog"
            :campaign="parkingLotFilters.selections.campaign || {}"
            :sub-campaign="parkingLotFilters.selections.subCampaign || {}"
            :scenario="parkingLotFilters.selections.scenario || {}"
            :start-date="selectedDateRange[0]"
            :end-date="selectedDateRange[1]"
            :promotion-ids="selectedPromotionIds"
            :has-activator="false"
            :success-callback="allocatePromotions"
        />
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { get, isEmpty } from 'lodash';
import parkingLotStates from '@enums/parking-lot-states';
import { excel } from '@enums/export-formats';
import resourcesEnum from '@enums/resources';
import { getPromotionStatus } from '@sharedModules/promotion-utils';
import parkingLotGridMixin from '../mixins/parking-lot-grids-mixin';
import { toSentenceCase } from '@/js/utils/string-utils';

export default {
    mixins: [parkingLotGridMixin],
    data() {
        return {
            // The actual value of promotionToggle is not important,
            // changing it on dialog close is just used to force the component
            // to be recreated by referencing the toggle in the key for the dialog.
            promotionToggle: true,
            gridOptions: {
                rowClassRules: {
                    'grid__disabled-row': params => !params.data.isInParkingLot,
                },
                // only use checkboxes, can't select row by clicking
                suppressRowClickSelection: true,
            },

            where: {},
            filterWhere: {},
            excel,
            resourcesEnum,
            downloadAction: 'downloadParkingLot',
            exportSchema: 'parking-lot-flat',
        };
    },
    computed: {
        ...mapState(['parkingLotFilters']),
        ...mapState('clientConfig', ['overviewPageConfig', 'toggleLogic']),

        allocationDialogDisabled() {
            return (
                isEmpty(this.selectedPromotionIds) ||
                isEmpty(this.parkingLotFilters.selections.scenario)
            );
        },
        selectedDateRange() {
            if (!this.parkingLotFilters.selections.dateRange) {
                return ['', ''];
            }
            return this.parkingLotFilters.selections.dateRange;
        },
    },
    created() {
        this.buildWhereClause();
        this.identifierColumns = this.getIdentifierColumns();
        this.detailColumns = this.getDetailsColumns({
            columnGroups: this.overviewPageConfig.parkingLot.detailColumns,
        });

        this.columnDefs = [
            ...this.identifierColumns,
            ...this.getActionColumns({ isPastPromotions: false }),
            ...this.detailColumns,
            {
                minWidth: 55,
                width: 55,
                sortable: false,
                flex: 1,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerClass: 'last-header',
                cellRenderer: 'parkingLotStatusRenderer',
                valueGetter: params => {
                    return getPromotionStatus({
                        moment: this.$moment,
                        startDate: params.data.startDate,
                        isInParkingLot: params.data.isInParkingLot,
                    });
                },
                filterParams: {
                    values: Object.keys(parkingLotStates),
                    valueFormatter: params =>
                        toSentenceCase(this.$tkey(`statusColumn.${params.value}`)),
                    suppressSorting: true,
                },
                filter: 'agSetColumnFilter',
                menuTabs: ['filterMenuTab'],
            },
        ];

        // fetching all parking lot promotions
        this.refetchPromotions();
    },
    events: {
        onAllocatePromotions(promotions) {
            const changes = {
                updatedItems: [],
                newItems: [],
            };
            const transformedPromotions = this.transformRowData(promotions);
            transformedPromotions.forEach(promotion => {
                promotion.isInParkingLot = true;
                if (this.promotionsInGrid.has(promotion._id)) {
                    changes.updatedItems.push(promotion);
                } else {
                    changes.newItems.push(promotion);
                }
            });
            this.upsertGridData(changes);

            // we need to update styles and params for the cells
            // flushAsyncTransactions help to get fresh data before refresh
            this.gridApi.flushAsyncTransactions();
            this.gridApi.refreshCells({
                force: true,
            });
        },
    },
    methods: {
        ...mapMutations('promotions', ['setUnsavedPromotion', 'setAddProductsInProgress']),
        buildPromotionFilter() {
            const filter = {};

            if (this.parkingLotFilters.selections.dateRange) {
                filter.startDate = { $gte: this.parkingLotFilters.selections.dateRange[0] };
                filter.endDate = { $lte: this.parkingLotFilters.selections.dateRange[1] };
            }

            // try to get scenario categories, fallback to subCampaign, then campaign if unavailable.
            const categories =
                get(this.parkingLotFilters.selections.scenario, 'categories') ||
                get(this.parkingLotFilters.selections.subCampaign, 'categories') ||
                get(this.parkingLotFilters.selections.campaign, 'categories');
            if (categories) {
                // Users can see promotions which currently have no categories
                filter.$or = [{ categories: { $in: categories } }, { categories: [] }];
            }

            return filter;
        },

        buildWhereClause() {
            this.where = {
                $and: [
                    { $or: [{ isInParkingLot: true }, { wasInParkingLot: true }] },
                    this.buildPromotionFilter(),
                    this.filterWhere,
                ],
            };
        },

        updateFilter({ where }) {
            this.filterWhere = where;
            this.handleSelectorChange();
        },

        /**
         * Filter the parking lot promotions based on user selections in the filter
         */
        handleSelectorChange() {
            this.gridOptions.api.deselectAll();
            this.buildWhereClause();
            this.refetchPromotions();
        },

        allocatePromotions(promotions) {
            if (!this.gridApi) this.refetchPromotions();
            else {
                this.gridApi.deselectAll();

                const callbackFunction = () => {
                    const rowNodes = [];
                    promotions.forEach(({ _id }) => {
                        rowNodes.push(this.gridApi.getRowNode(_id));
                    });
                    this.gridApi.redrawRows({ rowNodes });
                };
                this.upsertGridData({
                    updatedItems: this.transformRowData(promotions),
                    callbackFunction,
                });
            }
        },
        closePromoViewerDialog() {
            this.setUnsavedPromotion({
                namespace: this.selectedPromotion._id,
                tab: 'all',
                value: false,
            });
            this.setAddProductsInProgress(false);
            this.promotionToggle = !this.promotionToggle;
        },
    },
};
</script>
