import { isEmpty, intersection, keys } from 'lodash';
import { useClientStateFilter } from '@enums/feature-flags';
import userRolesEnum from '@enums/user-roles';

/**
 * Generate a client state filter based on user roles
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.userRoles - The user's roles
 * @param {Object} RORO.toggleLogic - The toggleLogic
 */
const generateClientStateFilter = ({
    clientStateRoles,
    userRoles,
    toggleLogic,
    returnArray = false,
}) => {
    const isAdmin =
        intersection(userRoles, [userRolesEnum.admin, userRolesEnum.promoSpecialistAdmin]).length >
        0;
    if (!toggleLogic[useClientStateFilter] || isAdmin) return returnArray ? [] : {};
    if (isEmpty(clientStateRoles)) return returnArray ? [] : {};
    const relevantStates = [];
    keys(clientStateRoles).forEach(clientState => {
        if (!isEmpty(intersection(userRoles, clientStateRoles[clientState]))) {
            relevantStates.push(clientState);
        }
    });
    // Ensures other users can see all promotions
    if (isEmpty(relevantStates)) return returnArray ? keys(clientStateRoles) : {};
    return returnArray
        ? relevantStates
        : {
              clientState: { $in: relevantStates },
          };
};

export default { generateClientStateFilter };
