'use strict';

module.exports = {
    componentTypes: {
        expansionPanel: 'expansionPanel',
        nominationTabs: 'nominationTabs',
        nominationMatrix: 'nominationMatrix',
        commitmentMatrix: 'commitmentMatrix',
        scope: 'scope',
        notes: 'notes',
        featuredProducts: 'featuredProducts',
        detailedProvisions: 'detailedProvisions',
        parentPermalink: 'parentPermalink',
        categoriesSelect: 'categoriesSelect',
    },
};
