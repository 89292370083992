<template>
    <v-switch v-model="model" :label="label" :disabled="disabled" />
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
};
</script>
