export default function getPromotionFields(isParkingLot = false) {
    const fields = [
        {
            header: '',
            contentClasses: ['promotion-viewer--first-item'],
            headingClasses: ['promotions-viewer--first-item'],
            field: 'effectivenessRating',
            sortable: true,
            sortableField: 'effectivenessRating',
            component: {
                name: 'promotion-rag-colour-icon-wrapper',
                contextualProps: {
                    colour: 'effectivenessRating',
                    execution: 'execution',
                    forecasting: 'forecastingAggregations',
                    notification: 'notification',
                },
                props: {
                    iconName: 'locked',
                    bigCircle: true,
                },
                computedProps: [
                    {
                        name: 'promotionId',
                        executor: 'promotionNamespace',
                    },
                    {
                        name: 'subCampaign',
                        executor: 'subCampaign',
                    },
                ],
            },
        },
        {
            header: 'planning.promotionsViewer.headings.date',
            field: 'date',
            sortable: true,
            sortableField: 'startDate',
            component: {
                name: 'promotion-viewer-name-date-wrapper',
                defaultProp: null,
                contextualProps: {
                    startDate: 'startDate',
                    endDate: 'endDate',
                    promotionName: 'name',
                },
                computedProps: [
                    {
                        name: 'promotionId',
                        executor: 'promotionNamespace',
                    },
                ],
            },
        },
        {
            header: 'planning.promotionsViewer.headings.products',
            field: 'products',
            component: {
                name: 'simple-object-list',
                props: {
                    displayFields: ['clientProductKey', 'name'],
                    limit: 2,
                    additionalItemsText: 'planning.promotionsViewer.additionalProductsText',
                },
                computedProps: [
                    {
                        name: 'emptyText',
                        executor: 'getEmptyProductsMessage',
                    },
                ],
                defaultProp: 'items',
            },
        },
        {
            header: 'planning.promotionsViewer.headings.supplier',
            field: 'products',
            component: {
                name: 'simple-object-list',
                props: {
                    displayFields: ['clientSupplierKey', 'supplierName'],
                    nullValueKey: 'unknown',
                    limit: 2,
                    showTooltip: true,
                    additionalItemsText: 'planning.promotionsViewer.additionalSuppliersText',
                    uniqByKey: 'clientSupplierKey',
                },
                computedProps: [
                    {
                        name: 'emptyText',
                        executor: 'getEmptySuppliersMessage',
                    },
                ],
                defaultProp: 'items',
            },
        },
        {
            header: 'planning.promotionsViewer.headings.promotionMechanic',
            field: 'offerMechanic.description',
            contentClasses: ['truncate-text'],
            component: {
                name: 'truncate-text-tooltip',
                contextualProps: {
                    text: 'offerMechanic.description',
                },
            },
        },
        {
            header: 'planning.promotionsViewer.headings.funding',
            field: 'forecastingAggregations.promotion.promoFunding',
            isCurrency: true,
            sortable: true,
            sortableField: 'forecastingAggregations.promotion.promoFunding',
        },
        {
            header: 'planning.promotionsViewer.headings.storeGroups',
            field: 'storeGroups',
            component: {
                name: 'simple-object-list',
                props: {
                    displayFields: ['description'],
                    limit: 2,
                    additionalItemsText: 'planning.promotionsViewer.additionalStoreGroupText',
                    // When sort is true, it sorts by the displayFields
                    sort: true,
                },
                defaultProp: 'items',
            },
        },
        {
            header: 'planning.promotionsViewer.headings.resources',
            field: 'resources',
            component: {
                name: 'workflow-resources-list',
                computedProps: [
                    {
                        name: 'subCampaignId',
                        executor: 'subCampaignIdGetter',
                    },
                ],
                contextualProps: {
                    promotionId: '_id',
                },
                defaultProp: 'nominatedResources',
            },
        },
        {
            header: '',
            field: 'icons',
        },
        {
            header: 'planning.promotionsViewer.headings.incrementalSales',
            field: 'incrementalSales',
            contentClasses: ['promotion-viewer__grid-cell--sidebar'],
            headingClasses: ['promotions-viewer__grid-cell--sidebar-heading'],
            sortable: true,
            sortableField: 'forecastingAggregations.promotion.incrementalSalesExcTax',
            component: {
                name: 'losenge-monetary-display',
                defaultProp: null,
                contextualProps: {
                    incrementalAmount: 'forecastingAggregations.promotion.incrementalSalesExcTax',
                    efficiency: 'forecastingAggregations.promotion.salesEfficiency',
                },
            },
        },
        {
            header: 'planning.promotionsViewer.headings.incrementalMargin',
            field: 'incrementalMargin',
            contentClasses: ['promotion-viewer__grid-cell--sidebar'],
            headingClasses: ['promotions-viewer__grid-cell--sidebar-heading'],
            sortable: true,
            sortableField: 'forecastingAggregations.promotion.incrementalMargin',
            component: {
                name: 'losenge-monetary-display',
                defaultProp: null,
                contextualProps: {
                    incrementalAmount: 'forecastingAggregations.promotion.incrementalMargin',
                    efficiency: 'forecastingAggregations.promotion.marginEfficiency',
                },
            },
        },
    ];
    if (!isParkingLot) {
        fields.push({
            header: '',
            field: 'sideBarIcons',
            contentClasses: ['promotion-viewer__grid-cell--sidebar'],
            headingClasses: ['promotions-viewer__grid-cell--sidebar-heading'],
            dataClasses: ['full-flex-column-center'],
            component: {
                name: 'promotion-sidebar-icons',
                fullContextAsPropName: 'promotion',
                computedProps: [
                    {
                        name: 'isExpanded',
                        executor: 'isSelected',
                    },
                ],
            },
        });
    }
    return fields;
}
