'use strict';

/**
 * Enum definition file for reward types.
 *
 * @module data/enums/reward-types
 */
const newPriceRewardTypes = {
    newPricePerItem: 'NEW_PRICE_PER_ITEM',
    newShelfPricePerItem: 'NEW_SHELF_PRICE_PER_ITEM',
};

module.exports = {
    noDiscount: 'NO_DISCOUNT',
    savePercent: 'SAVE_PERCENT',
    saveMoney: 'SAVE_MONEY',
    saveMoneyPerItem: 'SAVE_MONEY_PER_ITEM',
    setMoney: 'SET_MONEY',
    getLoyaltyPoints: 'GET_LOYALTY_POINTS',
    newPricePerItem: newPriceRewardTypes.newPricePerItem,
    newShelfPricePerItem: newPriceRewardTypes.newShelfPricePerItem,
    getNFree: 'GET_FREE_NON_STANDARD_ITEM',
    getFreeGift: 'GET_FREE_GIFT',
    newPriceRewardTypes: [
        newPriceRewardTypes.newPricePerItem,
        newPriceRewardTypes.newShelfPricePerItem,
    ],
};
