<template>
    <div class="icon-renderer">
        <v-tooltip v-if="params.value.tooltipText" z-index="300" bottom>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                    <icon class="icon-renderer__icon" :icon-name="params.value.icon" />
                    <span v-if="params.value.text" class="icon-renderer__text">
                        {{ params.value.text | toSentenceCase }}
                    </span>
                </span>
            </template>
            <span>{{ params.value.tooltipText }}</span>
        </v-tooltip>
        <span v-else>
            <icon class="icon-renderer__icon" :icon-name="params.value.icon" />
            <span v-if="params.value.text" class="icon-renderer__text">
                {{ params.value.text | toSentenceCase }}
            </span>
        </span>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>

<style lang="scss" scoped>
.icon-renderer {
    &__text {
        padding-left: 1rem;
    }
}
</style>
