import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VExpansionPanels,
    {
      staticClass: "rtls-expansion-panels",
      class: _vm.cssClass,
      attrs: { value: _vm.isOpenState, readonly: _vm.isAlwaysExpanded },
      on: { change: _vm.change },
    },
    [
      _c(
        VExpansionPanel,
        { staticClass: "rtls-expansion-panel" },
        [
          _vm.label
            ? _c(VExpansionPanelHeader, {
                staticClass: "rtls-expansion-panel-header",
                attrs: { "hide-actions": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ open }) {
                        return [
                          _c(
                            "p",
                            {
                              staticClass: "rtls-expansion-panel-header__label",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(_vm.$t(_vm.label))
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isStoreGroups
                            ? _c("span", { staticClass: "asterisk" }, [
                                _vm._v("*"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("main-expand-button", {
                            attrs: { "is-expanded": open },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1699576673
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            VExpansionPanelContent,
            {
              staticClass: "rtls-expansion-panel-content",
              class: _vm.expansionPanelClass,
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }