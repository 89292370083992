import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sub-campaign-allocation-grid-container" },
    [
      _c("parking-lot-sub-campaign-selector", {
        staticClass: "sub-campaign-allocation-header",
        on: { change: _vm.handleSelectorChange },
      }),
      _vm._v(" "),
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.gridData,
          "column-defs": _vm.columnDefs,
          "default-col-def": _vm.defaultColDef,
          "grid-options": _vm.gridOptions,
          "grid-style": "width: 100%; height: 100%;",
          "grid-class": "ag-theme-custom__sub-campaign-allocation-grid",
          "dom-layout": "normal",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "allocation-row" },
        [
          _c(
            VCol,
            { attrs: { cols: "4" } },
            [
              _c("create-promotion-overview", {
                attrs: { "is-disabled": _vm.shouldDisableButton },
                on: {
                  "create-promotion": function ($event) {
                    return _vm.openDialog()
                  },
                  "set-split-promotion": _vm.setSplitPromotion,
                  "set-promotion-name": _vm.setPromotionName,
                },
              }),
              _vm._v(" "),
              _c(
                "feature-toggle",
                {
                  attrs: { toggle: _vm.enableDownloadCentre },
                  scopedSlots: _vm._u([
                    {
                      key: "alternative",
                      fn: function () {
                        return [
                          _c(
                            VBtn,
                            {
                              staticClass: "allocation-row__download-btn",
                              attrs: {
                                secondary: "",
                                depressed: "",
                                outlined: "",
                                disabled: _vm.shouldDisableButton,
                              },
                              on: { click: _vm.onExport },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$t("actions.export")
                                    )
                                  ) +
                                  "\n                        "
                              ),
                              _c("icon", {
                                attrs: {
                                  "icon-name": "download",
                                  right: "",
                                  small: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "allocation-row__download-btn" },
                    [
                      _c("download-centre", {
                        attrs: {
                          "ignore-parent-read-only": true,
                          disabled: _vm.shouldDisableButton,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCol, { attrs: { cols: "4" } }),
          _vm._v(" "),
          _c(
            VCol,
            { attrs: { cols: "2" } },
            [
              _c("accept-all-notifications", {
                attrs: {
                  entity: "scenarios",
                  "entity-id": _vm.get(
                    _vm.parkingLotFilters,
                    "selections.scenario._id",
                    ""
                  ),
                  "applying-notifications": _vm.get(
                    _vm.parkingLotFilters,
                    "selections.scenario.applyingNotifications",
                    false
                  ),
                  "is-child": !!_vm.get(
                    _vm.parkingLotFilters,
                    "selections.subCampaign.parentId",
                    null
                  ),
                  "has-notifications": _vm.hasNotifications,
                  "is-executing-promotions-with-pending-notifications":
                    _vm.isExecutingPromotionsWithPendingNotifications,
                  disabled: _vm.isActionsDisabled,
                },
              }),
              _vm._v(" "),
              _c("accept-all-notifications-report-dialog", {
                attrs: {
                  "entity-id": _vm.get(
                    _vm.parkingLotFilters,
                    "selections.scenario._id",
                    ""
                  ),
                  "is-child": !!_vm.get(
                    _vm.parkingLotFilters,
                    "selections.subCampaign.parentId",
                    null
                  ),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { attrs: { cols: "2" } },
            [
              _c(
                VMenu,
                {
                  attrs: {
                    "content-class": "actions-menu",
                    "close-on-content-click": false,
                    "offset-y": "",
                    top: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "icon-button",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "actions-menu__icon",
                                  attrs: {
                                    disabled:
                                      _vm.allocationMenuDisabled ||
                                      _vm.applyingNotifications,
                                    "btn-text": _vm.$tkey("selectedItems"),
                                    icon: "expand_more",
                                    "icon-on-right": "",
                                  },
                                },
                                "icon-button",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.isActionsMenuOpen,
                    callback: function ($$v) {
                      _vm.isActionsMenuOpen = $$v
                    },
                    expression: "isActionsMenuOpen",
                  },
                },
                [
                  _vm._v(" "),
                  _c(
                    VList,
                    { staticClass: "actions-menu__list" },
                    [
                      _vm._l(_vm.workflowTasksForSubCampaign, function (task) {
                        return _c(
                          VListItem,
                          { key: `${task.task}::${task.isNegativeAction}` },
                          [
                            _vm.isActionsDisabled
                              ? _c("spinner-dynamic")
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "task-button",
                              _vm._b(
                                {
                                  attrs: {
                                    "entity-type":
                                      _vm.workflowEntities.promotion,
                                    "entity-ids":
                                      _vm.selectedForAllocationPromotionIds,
                                    "split-promotions": _vm.splitPromotionsById,
                                    "sub-campaign-id":
                                      _vm.selectedSubCampaign._id,
                                    categories:
                                      _vm.selectedSubCampaign.categories || [],
                                    "multiple-entities": true,
                                    "disabled-function": task.disabledFunction,
                                    disabled:
                                      _vm.isActionsDisabled ||
                                      _vm.applyingNotifications,
                                    text: "",
                                    "hide-tooltip": "",
                                    "always-show": "",
                                    "confirm-first": "",
                                  },
                                  on: {
                                    click: _vm.onActionButtonClick,
                                    "action-completed": _vm.onActionCompleted,
                                    "toggle-action-buttons":
                                      _vm.toggleActionButtons,
                                  },
                                },
                                "task-button",
                                task,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        VListItem,
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                text: "",
                                disabled:
                                  _vm.allocationDialogDisabled ||
                                  _vm.isActionsDisabled ||
                                  _vm.applyingNotifications,
                              },
                              on: { click: _vm.openAllocationDialog },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$tkey("removeSelectedPromotions")
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("forecasts-dialog", {
        ref: "forecasts-dialog",
        attrs: {
          "sub-campaign": _vm.parkingLotFilters.selections.subCampaign || {},
          "top-level-products-data": _vm.allProductData,
          "top-level-targets-data": _vm.allTargetsData,
          "unit-data": _vm.isUnit ? _vm.unitsData : _vm.categoriesData,
          "selected-hierarchy-id": String(_vm.selectedHierarchyId),
          "is-unit": _vm.isUnit,
        },
      }),
      _vm._v(" "),
      _c("allocate-promotion-to-parking-lot-dialog", {
        ref: "promotion-allocation-dialog",
        attrs: {
          campaign: _vm.parkingLotFilters.selections.campaign || {},
          "sub-campaign": _vm.parkingLotFilters.selections.subCampaign || {},
          scenario: _vm.parkingLotFilters.selections.scenario || {},
          "start-date": _vm.selectedDateRange[0],
          "end-date": _vm.selectedDateRange[1],
          "promotion-ids": _vm.filteringSelectedForAllocationPromotionIds,
          "has-activator": false,
        },
        on: { confirm: _vm.afterAllocation },
      }),
      _vm._v(" "),
      _c("promotion-viewer-dialog", {
        key: `promotion-viewer::${_vm.editPromotionId}::${_vm.promotionToggle}`,
        ref: "promotion-viewer-dialog",
        attrs: {
          "is-sub-campaign-allocation": "",
          "is-parking-lot": false,
          promotion: _vm.dialogPromotionGetter(),
          scenario: _vm.parkingLotFilters.selections.scenario || {},
          "sub-campaign": _vm.parkingLotFilters.selections.subCampaign || {},
          "split-promotion": _vm.splitPromotion,
          "promotion-name": _vm.promotionName,
        },
        on: { close: _vm.closePromotionViewerDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }