import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "ag-button-container" },
        [
          _c(
            VBtn,
            {
              staticClass: "ag-btn",
              attrs: { depressed: "", outlined: "" },
              on: { click: _vm.onClick },
            },
            [_vm._v("\n        " + _vm._s(_vm.params.text) + "\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }