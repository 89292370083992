import Vue from 'vue';
import {
    camelCase,
    get,
    find,
    each,
    reduce,
    flatten,
    some,
    isArray,
    isNil,
    every,
    uniqueId,
    keyBy,
    uniqBy,
    isEmpty,
} from 'lodash';
import filtersMap from './vuex-filters-map';

const createEmptyUserFilter = () => ({ filterKey: null, filterValue: null, filterId: uniqueId() });

export const getInitialFilterState = pluralResourceName => ({
    filter: {},

    /**
     * This is where we store filters that the user has explicitly set via the UI
     */
    userFilters: [createEmptyUserFilter()],

    // this will be the result of any user filtering
    [camelCase(`filtered-${pluralResourceName}`)]: [],
});

/**
 * See {@link https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1750466613/Vuex+Filter+Mixin|Confluence}
 * to learn how this works.
 */
export const createFiltersStoreAddOns = ({ pluralResourceName, pluralResourceNameCamelCase }) => {
    return {
        state: getInitialFilterState(pluralResourceName),

        getters: {
            userFilterKeyGetter: state => ({ index, filterId }) => {
                const filter = filterId
                    ? find(state.userFilters, { filterId })
                    : state.userFilters[index];
                return get(filter, 'filterKey', null);
            },

            userFilterValueGetter: state => ({ index, filterId }) => {
                const filter = filterId
                    ? find(state.userFilters, { filterId })
                    : state.userFilters[index];
                return get(filter, 'filterValue', null);
            },

            getFilterValueOptionsByFilterId: state => ({ filterId }) =>
                find(state.userFilters, { filterId }, { filterValueOptions: [] })
                    .filterValueOptions,

            [camelCase(`get-filtered-${pluralResourceName}`)]: (state, getters) => {
                // filter resources by each property in filtersMap
                const resourceList = state[pluralResourceNameCamelCase];
                return getters[camelCase(`get-filtered-${pluralResourceName}-from-resource-list`)](
                    resourceList
                );
            },

            [camelCase(`get-filtered-${pluralResourceName}-from-resource-list`)]: (
                state,
                getters,
                rootState
            ) => resourceList => {
                const predefinedFilters = filtersMap[pluralResourceNameCamelCase];

                // filter resources by each property in filtersMap
                return resourceList.filter(item => {
                    // first we filter by the predefined filters
                    return every(predefinedFilters, (filterDefinition, key) => {
                        const moduleFilter = rootState[filterDefinition.module].filter;

                        // filter not set; return true
                        if (isNil(moduleFilter[key])) {
                            return true;
                        }

                        // by default filter key name is the same as field key
                        // can be set explicitly with fieldKey in filtersMap config
                        const fieldKey = filterDefinition.fieldKey || key;

                        // Use the custom filter if one has been supplied for the filter key.
                        const customFilter = filterDefinition.customFilter;
                        if (customFilter) {
                            return customFilter(item, moduleFilter[key]);
                        }

                        // Filter based on field type and filter type
                        if (filterDefinition.isFieldArray) {
                            /**
                             * Example
                             * campaign.categories: ['2', '7']
                             * hierarchy.filter.categories: ['7', '8', '9']
                             * this will check that some of campaign.categories are set in the filter categories
                             */
                            /**
                             * Example using objectKey
                             * subCampaign.tags: [{tagName: 'tag 1', tagKey: 'abc'}, {tagName: 'tag 2', tagKey: 'xyz'}]
                             * tagMetdata.filter.tags: ['abc']
                             * this will check that some of subCampaign.tags are set in the filter tags by using the object key
                             * to extract out the chosen field from the list of objects in subCampaign.tags (tagKey)
                             */
                            const objectKey = get(filterDefinition, 'objectKey', '');
                            return item[fieldKey].some(i =>
                                moduleFilter[key].includes(get(i, objectKey, i))
                            );
                        }
                        /**
                         * if filter is array then item[fieldKey] value should be in the filter array.
                         * else it is default equals check
                         *
                         * Example
                         * product.supplierKey: 2
                         * promotionCandidates.filter.suppliers: [2, 7, 8, 9]
                         * this will check that product.supplierKey is set in the filter suppliers
                         */
                        return isArray(moduleFilter[key])
                            ? moduleFilter[key].some(i => i === item[fieldKey])
                            : item[fieldKey] === moduleFilter[key];
                    });
                });
            },

            userFilterDefinitionsByFilterKey: state =>
                keyBy(state.userFilterDefinitions, 'filterKey'),

            specifiedFilter: state => state.filter,
        },
        mutations: {
            setSelectedFilter(state, filterValue) {
                state.filter = filterValue;
            },
            resetFilter(state) {
                state.filter = {};
            },
            resetUserFilters(state) {
                state.userFilters = [createEmptyUserFilter()];
            },
            resetFilteredResource(state, resourceList) {
                state[camelCase(`filtered-${pluralResourceName}`)] = resourceList;
            },
            removeUserFilter(state, index) {
                Vue.delete(state.userFilters, index);
            },
            addUserFilter(state) {
                // push new empty filter to userFilters
                Vue.set(state.userFilters, state.userFilters.length, createEmptyUserFilter());
            },
            userFilterKeySetter(state, { index, filterId, key }) {
                const filter = filterId
                    ? find(state.userFilters, { filterId })
                    : state.userFilters[index];
                Vue.set(filter, 'filterKey', key);
                Vue.set(filter, 'filterValue', null);
            },
            userFilterValueSetter(state, { index, filterId, value }) {
                const filter = filterId
                    ? find(state.userFilters, { filterId })
                    : state.userFilters[index];
                Vue.set(filter, 'filterValue', value);
            },
            setFilteredResource(
                state,
                { resourceFilteredByPredefinedFilters, userFilterDefinitionsByFilterKey }
            ) {
                // object of `filterId: options`
                const filterValueOptionsObj = reduce(
                    state.userFilters,
                    (obj, { filterId, filterType }) => {
                        obj[filterId] = { options: [], type: filterType };
                        return obj;
                    },
                    {}
                );

                state[
                    camelCase(`filtered-${pluralResourceName}`)
                ] = resourceFilteredByPredefinedFilters.filter(item => {
                    return every(state.userFilters, ({ filterKey, filterValue, filterId }) => {
                        if (!filterKey) return true; // empty filter pair

                        const filterDefinition = userFilterDefinitionsByFilterKey[filterKey];
                        if (!filterDefinition) {
                            throw new Error('Invalid filterKey set. No matching filter definition');
                        }

                        if (filterDefinition.filterValueOptionsFunction) {
                            // get the options list for this filter
                            const itemFilterValues = flatten([
                                filterDefinition.filterValueOptionsFunction(item),
                            ]);
                            filterValueOptionsObj[filterId].options.push(...itemFilterValues);
                        }

                        filterValueOptionsObj[filterId].type = filterDefinition.filterType;

                        if (isEmpty(filterValue)) return true;

                        return filterDefinition.customFilterFunction(item, filterValue);
                    });
                });

                each(state.userFilters, userFilter => {
                    const { filterId, filterValue } = userFilter;
                    const filterValueOptions = uniqBy(
                        filterValueOptionsObj[filterId].options,
                        'text'
                    );
                    // cache the old options in case we need to find the label of an invalid option
                    // later on
                    const previousFilterValueOptions = userFilter.filterValueOptions || [];
                    // set the new filter value options
                    userFilter.filterValueOptions = filterValueOptions;

                    // Set the filter component type.
                    userFilter.filterType = filterValueOptionsObj[filterId].type;

                    if (isEmpty(filterValue)) return true;
                    // if filter value is set, we need to make sure it's still a valid selection
                    // if a preceding filter has been changed this may not still be the case
                    // filterValue may be an array or a single value
                    const filterValueArray = isArray(filterValue) ? filterValue : [filterValue];
                    // find if any of the set values are no longer valid options
                    each(filterValueArray, value => {
                        if (some(filterValueOptions, { value })) return;
                        // else this is now an invalid option and we need to flag it as such
                        const invalidOption = find(previousFilterValueOptions, { value });
                        Vue.set(
                            userFilter.filterValueOptions,
                            userFilter.filterValueOptions.length,
                            { ...invalidOption, invalid: true }
                        );
                    });
                });
            },
        },
        actions: {
            setSelectedFilter({ commit }, filterValue) {
                commit('setSelectedFilter', filterValue);
            },

            resetFilter({ commit }) {
                commit('resetFilter');
            },

            resetUserFilters({ commit, getters }) {
                commit('resetUserFilters');
                commit(
                    'resetFilteredResource',
                    getters[camelCase(`get-filtered-${pluralResourceName}`)]
                );
            },

            removeUserFilter({ commit, dispatch }, index) {
                commit('removeUserFilter', index);
                dispatch('setFilteredResource');
            },

            addUserFilter({ commit }) {
                commit('addUserFilter');
            },

            userFilterKeySetter({ commit, dispatch }, { index, filterId, key }) {
                commit('userFilterKeySetter', { index, filterId, key });
                dispatch('setFilteredResource');
            },

            userFilterValueSetter({ commit, dispatch }, { index, filterId, value }) {
                commit('userFilterValueSetter', { index, filterId, value });
                dispatch('setFilteredResource');
            },

            setFilteredResource({ commit, getters }) {
                commit('setFilteredResource', {
                    resourceFilteredByPredefinedFilters:
                        getters[camelCase(`get-filtered-${pluralResourceName}`)],
                    userFilterDefinitionsByFilterKey: getters.userFilterDefinitionsByFilterKey,
                });
            },
        },
    };
};
