var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotion-name-date" },
    [
      _c("vuex-text-field", {
        ref: "promotionName",
        staticClass: "promotion-name-date__text",
        attrs: {
          disabled: _vm.editNameDisabled,
          getter: () => _vm.getPromotionNameModel(),
          setter: (value) => _vm.setPromotionNameModel(value),
          white: true,
          validations: _vm.promotionNameValidation,
          placeholder: _vm._f("toSentenceCase")(
            _vm.$t("planning.promotionsViewer.promotionName")
          ),
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
          blur: _vm.saveName,
        },
      }),
      _vm._v(" "),
      _c("losenge-date-display", {
        attrs: { "start-date": _vm.startDate, "end-date": _vm.endDate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }