<template>
    <v-checkbox v-model="params.value" class="rtls-checkbox padded" disabled />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    computed: {},
    methods: {},
});
</script>

<style lang="scss" scoped>
.padded {
    padding-top: 1rem !important;
}
</style>
