var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-dialog", {
        ref: "dialog",
        attrs: {
          heading: "userPermissions.selectUserRoles",
          "btn-confirm-text": "actions.save",
          "btn-cancel-text": "actions.cancel",
          "has-activator": _vm.hasActivator,
        },
        on: { confirm: _vm.save, close: _vm.closeDialog },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "checkbox-dialog-container" },
                  [
                    _c("categories-select", {
                      attrs: {
                        tree: _vm.tree,
                        update: _vm.updateUserCategories,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }