import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VTooltip,
    {
      attrs: {
        "z-index": "400",
        top: "",
        "max-width": 500,
        disabled: !_vm.maxProductsTooltip,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "ag-checkbox-wrapper",
                    class: {
                      "ag-checkbox-wrapper__centered":
                        _vm.isCenteredHorizontally,
                    },
                  },
                  on
                ),
                [
                  _c("vuex-checkbox", {
                    class: { "ag-checkbox": _vm.isCenteredHorizontally },
                    attrs: {
                      getter: () =>
                        _vm.params.pathToData
                          ? _vm.get(_vm.params, _vm.params.pathToData)
                          : _vm.params.value,
                      setter: (value) => _vm.setValue(value),
                      disabled: _vm.isDisabled,
                      validations:
                        _vm.params.validations &&
                        _vm.params.validations(_vm.params),
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.maxProductsTooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }