import numeral from 'numeral';
import { isNil } from 'lodash';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '../utils/string-utils';

const greaterThanZero = (value, message) => {
    if (isNil(value)) {
        return true;
    }

    value = numeral(value).value();

    const comparisonResult = value > 0;
    const defaultErrorMessage = 'validation.common.greaterThanZero';

    return comparisonResult || (message || toSentenceCase(i18n.t(defaultErrorMessage)));
};

export default greaterThanZero;
