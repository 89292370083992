import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    {
      staticClass: "icon-checkboxes-list-container",
      class: _vm.cssClass,
      attrs: { fluid: "" },
    },
    [
      _vm.label
        ? _c("p", { staticClass: "checkboxes-list__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                "\n        "
            ),
            _vm.isRequired
              ? _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        VContainer,
        { staticClass: "icon-checkboxes-list-container__control" },
        [
          _vm._l(_vm.getOptions, function (option, index) {
            return [
              _c(
                VTooltip,
                {
                  key: `tooltip::${_vm.getKey(option, index)}`,
                  attrs: {
                    "z-index": "400",
                    bottom: "",
                    "max-width": 200,
                    disable: _vm.hideTooltip,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "span",
                              _vm._g({}, on),
                              [
                                _c(VCheckbox, {
                                  key: `checkbox::${_vm.getKey(option, index)}`,
                                  staticClass: "rtls-checkbox",
                                  attrs: {
                                    multiple: true,
                                    value: _vm.getValue(option),
                                    disabled: _vm.isDisabled({ option }),
                                    "on-icon":
                                      "$" + option.reference[_vm.itemValue],
                                    "off-icon":
                                      "$" + option.reference[_vm.itemValue],
                                    rules: _vm.rules,
                                    "hide-details": index !== 0,
                                  },
                                  model: {
                                    value: _vm.model,
                                    callback: function ($$v) {
                                      _vm.model = $$v
                                    },
                                    expression: "model",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("toSentenceCase")(option.reference[_vm.itemText])
                      )
                    ),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }