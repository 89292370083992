<template>
    <div class="parking-lot-status">
        <vuex-checkbox
            v-if="params.data.isInParkingLot"
            :getter="() => params.node.selected"
            :setter="setSelected"
        />
        <vuex-checkbox
            v-if="params.value === parkingLotStates.planned"
            :getter="() => true"
            disabled
        />
        <icon v-if="params.value === parkingLotStates.executed" icon-name="locked" small />
    </div>
</template>

<script>
import Vue from 'vue';
import parkingLotStates from '@enums/parking-lot-states';

export default Vue.extend({
    data() {
        return { parkingLotStates };
    },
    methods: {
        setSelected(value) {
            this.params.node.setSelected(value);
        },
    },
});
</script>

<style lang="scss" scoped>
.parking-lot-status {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    // Removes the default margin-right: 8px on v-checkbox
    ::v-deep .rtls-checkbox * {
        margin-right: 0px;
    }
}
</style>
