<template functional>
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <title>Icon / Done</title>
        <desc>Created with Sketch.</desc>
        <g id="Icon-/-Done" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                id="Combined-Shape"
                d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M11,5 L7,9 L5,7 L4,8 L7,11 L12,6 L11,5 Z"
                fill="#3D8113"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SuccessCircle',
};
</script>
