<template>
    <div class="loyalty-points-viewer">
        <promo-ag-grid
            :row-data="loyaltyPointsData"
            :column-defs="columnDefs"
            :grid-options="gridOptions"
            :grid-style="gridStyle"
            :default-col-def="defaultColDef"
            @cell-value-changed="onCellValueChanged"
        />
        <div class="grid-controls">
            <create-new-button
                :disabled="isAddingLoyaltyPoint || isCreateInProgress"
                :btn-text="buttonText"
                background
                @createNew="addNewLoyaltyPoint"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isNil } from 'lodash';

import LoyaltyPointsActions from '@/js/components/promo-ag-grid/loyalty-points-actions';
import AgCheckbox from '@/js/components/promo-ag-grid/ag-checkbox';
import { toSentenceCase } from '@/js/utils/string-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
    localizationKey: 'admin.loyaltyPoints',
    data() {
        return {
            buttonText: toSentenceCase(this.$tkey('table.controls.addLoyaltyPoints')),
            isAddingLoyaltyPoint: false,
            isCreateInProgress: false,
            gridOptions: {
                context: {
                    componentParent: this,
                },
                rowHeight: 35,
                paginationAutoPageSize: true,
                pagination: true,
                columnTypes: {
                    requiredField: agGridUtils.columnTypes.requiredField,
                },
            },
            gridStyle: 'width: 100%; height: 100%;',
            defaultColDef: {
                editable: true,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                flex: 1,
                resizable: true,
                filter: true,
                minWidth: 150,
            },
        };
    },
    computed: {
        ...mapState('loyaltyPoints', ['loyaltyPoints']),
        loyaltyPointsData() {
            return this.loyaltyPoints;
        },
        columnDefs() {
            return [
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.identifier'))} *`,
                    field: 'identifier',
                    type: 'requiredField',
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.description'))} *`,
                    field: 'description',
                    type: 'requiredField',
                },
                {
                    headerName: toSentenceCase(this.$tkey('table.headers.isAward')),
                    field: 'isAward',
                    cellRendererFramework: AgCheckbox,
                    editable: false,
                    cellClass: 'flex-cell-start',
                    maxWidth: 120,
                    cellClassRules: {
                        'invalid-background': this.validateRequiredSelection,
                    },
                },
                {
                    headerName: toSentenceCase(this.$tkey('table.headers.isRedeem')),
                    field: 'isRedeem',
                    cellRendererFramework: AgCheckbox,
                    editable: false,
                    cellClass: 'flex-cell-start',
                    maxWidth: 120,
                    cellClassRules: {
                        'invalid-background': this.validateRequiredSelection,
                    },
                },
                {
                    headerName: toSentenceCase(this.$tkey('table.headers.isActive')),
                    field: 'isActive',
                    cellRendererFramework: AgCheckbox,
                    editable: false,
                    cellClass: 'flex-cell-start',
                    maxWidth: 100,
                },
                {
                    headerName: '',
                    cellRendererFramework: LoyaltyPointsActions,
                    editable: false,
                    sortable: false,
                    filter: false,
                    resizable: false,
                    minWidth: 30,
                    width: 30,
                    cellClass: 'flex-cell-center',
                },
            ];
        },
    },
    methods: {
        ...mapActions('loyaltyPoints', ['createLoyaltyPoint', 'updateLoyaltyPoint']),
        async onCellValueChanged({ data }) {
            const { identifier, description, isAward, isRedeem, isActive, _id } = data;

            // Check if the row is ready for saving (i.e. all fields have a value).
            const isIdentifierInvalid = isNil(identifier) || identifier.trim() === '';
            const isDescriptionInvalid = isNil(description) || description.trim() === '';
            if (isIdentifierInvalid || isDescriptionInvalid) {
                return;
            }

            // The row is ready to be saved. Determine whether it is a CREATE or UPDATE.
            const isCreate = isNil(_id);
            const loyaltyPointsSaving = {
                identifier,
                description,
                isAward,
                isRedeem,
                isActive,
            };

            if (isCreate) {
                if (!this.isCreateInProgress) {
                    this.isCreateInProgress = true;
                    await this.createLoyaltyPoint({ document: loyaltyPointsSaving });

                    this.isCreateInProgress = false;

                    this.enableGridControls();
                }
            } else {
                await this.updateLoyaltyPoint({
                    id: _id,
                    updates: loyaltyPointsSaving,
                });
            }
        },
        addNewLoyaltyPoint() {
            const newLoyaltyPointRecord = [
                {
                    identifier: null,
                    description: null,
                    isAward: true,
                    isRedeem: true,
                    isActive: true,
                },
            ];
            this.addLoyaltyPointToGrid({
                loyaltyPoint: newLoyaltyPointRecord,
                editColumnKey: 'identifier',
            });
        },
        addLoyaltyPointToGrid({ loyaltyPoint, editColumnKey }) {
            const newRow = this.gridOptions.api.applyTransaction({ add: loyaltyPoint });

            // New loyalty points are placed at the end of the grid, so navigate to the last page so the new row is visible.
            this.gridOptions.api.paginationGoToLastPage();
            this.gridOptions.api.ensureIndexVisible(newRow.add[0].rowIndex);

            // Start editing as soon as the row is added.
            this.gridOptions.api.startEditingCell({
                rowIndex: newRow.add[0].rowIndex,
                colKey: editColumnKey,
            });
            this.addedRowIndex = newRow.add[0].rowIndex;

            // Stop users from adding multiple items to the grid at once.
            this.disableGridControls();
        },
        disableGridControls() {
            this.isAddingLoyaltyPoint = true;
        },
        enableGridControls() {
            this.isAddingLoyaltyPoint = false;
        },
        validateRequiredSelection(params) {
            const { isAward, isRedeem } = params.data;
            return !isAward && !isRedeem;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.loyalty-points-viewer {
    @include flex-column;
    height: 100%;

    padding-bottom: 3rem;
    background-color: $promo-white;

    .grid-controls {
        font-size: 1.4rem;
        padding-left: 3rem;
    }
}

.loyalty-points-viewer::v-deep {
    .ag-theme-custom {
        max-width: 50% !important;
    }
    .flex-cell-start {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .flex-cell-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ag-center-cols-viewport {
        overflow-x: hidden;
    }

    .invalid-background {
        background: $rtls-negative-action-background;
    }
}
</style>
