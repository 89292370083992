<script>
import { mapActions, mapState } from 'vuex';
import { totalSpend } from '@enums/supplier-commitment-types';
import { categories, year, storeGroups } from '@enums/filters';
import { findIndex, uniqueId } from 'lodash';
import { supplierCommitmentsView } from '@enums/route-names';
import filterBar from '@/js/components/filter-bar';
import filterBarMixin from '../../mixins/suppliers-filter-bar-mixin';
import navigation from '@/js/navigation';

export default {
    extends: filterBar,
    mixins: [filterBarMixin],

    events: {
        onSupplierCommitmentTabChanged() {
            this.updateFilters({ isSupplierCommitmentPage: true });
        },
    },

    computed: {
        ...mapState([
            'totalSpendSupplierCommitmentsFilterDefinitions',
            'defaultSuppliersFilterDefinitions',
        ]),
        ...mapState('supplierCommitments', ['activeSupplierCommitmentsSection']),

        isTotalSpendTab() {
            return this.activeSupplierCommitmentsSection === totalSpend;
        },
    },

    watch: {
        $route(to) {
            const isSupplierCommitmentPage = to.name === supplierCommitmentsView;
            this.updateFilters({ isSupplierCommitmentPage });
        },
    },

    created() {
        this.updateFilters({ isSupplierCommitmentPage: true });
    },

    methods: {
        ...mapActions([
            'resetSuppliersFilters',
            'suppliersFilterDefinitionsSetter',
            'suppliersFiltersSetter',
        ]),
        ...mapActions('supplierCommitments', [
            'fetchSupplierCommitmentsAndSuppliersByCategory',
            'clearSelectedSupplierCommitment',
            'resetSupplierCommitmentsState',
            'fetchTargetsData',
        ]),
        ...mapActions('variableFundingAgreements', ['resetVariableFundingAgreementsState']),

        onFilterValueChange({ filterKey }) {
            if (filterKey === categories || filterKey === year) {
                // remove old supplier filter for updated category
                this.removeSupplierFilter();
                // set selected supplier-commitment to null
                this.clearSelectedSupplierCommitment();

                this.$emit('change-categories-filter');

                // fetch supplierCommitments and suppliers by category
                this.fetchSupplierCommitmentsAndSuppliersByCategory(this.filters);
            }

            // fetch targetsData for totalSpend tab only
            if (
                this.isTotalSpendTab &&
                (filterKey === categories || filterKey === year || filterKey === storeGroups)
            ) {
                this.fetchTargetsData(this.filters);
            }
        },

        resetFilters({ filterId }) {
            // if category filter is not selected, we show empty list of
            // variable-funding-agreements and supplier-commitments
            this.resetSupplierCommitmentsState();
            this.resetVariableFundingAgreementsState();
            this.clearSelectedSupplierCommitment();
            navigation.toSupplierCommitmentsView();
            return this.resetSuppliersFilters({ filterId });
        },

        updateFilters({ isSupplierCommitmentPage }) {
            const storeGroupFilterIndex = findIndex(this.filters, { filterKey: storeGroups });
            const yearFilterIndex = findIndex(this.filters, { filterKey: year });

            if (storeGroupFilterIndex !== -1) {
                this.removeFilter(storeGroupFilterIndex);
            }

            if (yearFilterIndex !== -1) {
                this.removeFilter(yearFilterIndex);
            }

            if (isSupplierCommitmentPage && this.activeSupplierCommitmentsSection === totalSpend) {
                this.suppliersFilterDefinitionsSetter({
                    filterDefinitions: this.totalSpendSupplierCommitmentsFilterDefinitions,
                });
                this.suppliersFiltersSetter({
                    filters: [
                        {
                            filterKey: year,
                            filterValue: new Date().getFullYear().toString(),
                            filterId: uniqueId(),
                            filterType: 'select',
                        },
                        ...this.filters,
                    ],
                });

                this.fetchTargetsData(this.filters);
            } else {
                this.suppliersFilterDefinitionsSetter({
                    filterDefinitions: this.defaultSuppliersFilterDefinitions,
                });
            }
        },
    },
};
</script>
