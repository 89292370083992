import { defaultRoute } from '@enums/feature-flags';
import { get } from 'lodash';
import store from '@/js/store';

const routes = [
    {
        path: '*',
        redirect: () => get(store.state.clientConfig.toggleLogic, [defaultRoute], '/'),
        omitFromNav: true,
    },
];

export default routes;
