<template>
    <div class="rag-color-and-icon">
        <div class="rag-color">
            <promotion-rag-colour
                :vertical="true"
                :colour="colour"
                :make-read-only="makeReadOnly"
                :read-only-reason="readOnlyReason"
                :display-count="false"
                :big-circle="bigCircle"
            />
            <icon v-if="showParentChildIcon" icon-name="parent-child-icon-red" />
        </div>
        <div class="icon">
            <promotion-unlock-dialog
                :icon-name="iconName"
                :promotion-id="promotionId"
                :sub-campaign="subCampaign"
                class="unlock-dialog-wrapper"
            />
            <spinner-dynamic v-if="execution.executionId" />
            <execution-errors-dialog
                v-if="hasErrors"
                :execution-errors="executionErrors"
                :forecasting-errors="forecastingErrors"
                :in-tool-validations="inToolValidations"
            />
        </div>
    </div>
</template>

<script>
import { isEmpty, isNil, get, groupBy } from 'lodash';
import { mapGetters } from 'vuex';
import notificationTypes from '@enums/notification-types';

export default {
    props: {
        colour: {
            type: Number,
            required: false,
        },
        iconName: {
            type: String,
            required: true,
        },
        execution: {
            type: Object,
            required: false,
        },
        forecasting: {
            type: Object,
            required: false,
        },
        promotionId: {
            type: String,
            required: true,
        },
        subCampaign: {
            type: Object,
            required: false,
        },
        notification: {
            type: Object,
            required: false,
        },
        bigCircle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('promotions', ['pendingChanges', 'getPromotionNotifications']),
        hasErrors() {
            return (
                !isEmpty(this.executionErrors) ||
                !isEmpty(this.forecastingErrors) ||
                !isEmpty(this.inToolValidations)
            );
        },
        forecastingErrors() {
            return isNil(this.forecasting) ? [] : this.forecasting.errors;
        },
        executionErrors() {
            const executionErrors = [];
            if (!isNil(this.execution)) {
                if (this.execution.errors) {
                    executionErrors.push(...this.execution.errors);
                }
                if (this.execution.deleteError) {
                    executionErrors.push(this.execution.deleteError);
                }
            }
            return executionErrors;
        },
        showParentChildIcon() {
            return (
                this.pendingChanges({ promotionId: this.promotionId }) ||
                (this.notification && this.notification.pendingChanges)
            );
        },
        inToolValidations() {
            const notificationKey = notificationTypes.ruleValidationError;
            const notification = this.getPromotionNotifications({
                promotionId: this.promotionId,
                notificationKey,
            });
            if (notification && notification.length) {
                const ruleValidationTypes = get(notification[0], 'details.ruleValidationTypes', []);
                return groupBy(ruleValidationTypes, 'severity');
            }
            return null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.rag-color-and-icon {
    flex-direction: column;
}

.rag-color {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    margin-top: 0.85rem;
    display: flex;
    justify-content: space-between;
    align-items: end;
    ::v-deep {
        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}
</style>
