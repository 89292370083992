import { get } from 'lodash';
import store from '../store/index';
import i18n from '../vue-i18n';

const getFormattedDate = (translationKey, date) => {
    const separator = get(store.state, 'clientConfig.i18nConfig.defaultDateSeparator');
    const dateFormat = i18n.t(translationKey, { separator });
    return i18n.d(dateFormat, date);
};

const getFormattedWeekNumber = (prefix, week) => {
    return i18n.t(prefix).concat(String(week).padStart(2, '0'));
};

const getWeekNumberClient = (translationKey, week) => {
    return getFormattedWeekNumber('general.dates.weekFirstLetter', week);
};

const getWeekNumberShorthandClient = (translationKey, week) => {
    return getFormattedWeekNumber('general.dates.weekShorthand', week);
};

const filters = [
    { name: 'weekNumberShorthand', formatter: i18n.eUTC },
    { name: 'weekNumberShorthandClient', formatter: getWeekNumberShorthandClient },
    { name: 'weekNumber', formatter: i18n.eUTC },
    { name: 'weekNumberClient', formatter: getWeekNumberClient },
    { name: 'dateShorthand', formatter: i18n.eUTC },
    { name: 'dateShorthandNoYear', formatter: i18n.eUTC },
    { name: 'dateTime', formatter: i18n.d },
    { name: 'localShortDate', formatter: i18n.d },
    { name: 'localDayMonth', formatter: getFormattedDate },
    { name: 'fullDateText', formatter: i18n.d },
    { name: 'datepickerFormat', formatter: i18n.eUTC },
    { name: 'datesInYear', formatter: i18n.eUTC },
    { name: 'timeStamp', formatter: i18n.d },
];

export default Vue => {
    filters.forEach(filter => {
        Vue.filter(filter.name, date => filter.formatter(`dates.default.${filter.name}`, date));
    });
};
