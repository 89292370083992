var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "17px",
        height: "16px",
        viewBox: "0 0 17 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Store")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-536.000000, -190.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "arrow-down",
                    transform: "translate(536.666518, 190.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "15.9916235",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M6.53304145,13.4066365 C7.2488041,13.4066365 7.82904437,13.9871807 7.82904437,14.7033182 C7.82904437,15.4194558 7.2488041,16 6.53304145,16 C5.81727881,16 5.23703854,15.4194558 5.23703854,14.7033182 C5.23703854,13.9871807 5.81727881,13.4066365 6.53304145,13.4066365 Z M12.1828803,13.4066365 C12.8986429,13.4066365 13.4788832,13.9871807 13.4788832,14.7033182 C13.4788832,15.4194558 12.8986429,16 12.1828803,16 C11.4671176,16 10.8868774,15.4194558 10.8868774,14.7033182 C10.8868774,13.9871807 11.4671176,13.4066365 12.1828803,13.4066365 Z M2.99762619,2 C3.23890144,2 3.45068646,2.14988818 3.5355631,2.36926934 L3.56388488,2.46731012 L3.836,3.857 L15.4208136,3.85761665 C15.7571649,3.85761665 16.0150534,4.15880885 15.9993165,4.50259869 L15.9861915,4.60675498 L14.9645367,9.54086341 C14.9133502,9.78806992 14.7269077,9.9730096 14.4990662,10.0153819 L14.3991588,10.0245788 L5.048,10.024 L5.30015406,11.2878189 L13.7162944,11.2882338 C13.9996235,11.2882338 14.2352677,11.4931464 14.2841352,11.7633693 L14.2934336,11.8674916 C14.2934336,12.1518607 14.0892705,12.3883698 13.8200359,12.4374167 L13.7162944,12.4467493 L4.82835041,12.4467493 C4.58772402,12.4467493 4.37634262,12.2976477 4.29103531,12.0790755 L4.26247616,11.9813806 L3.81750064,9.75417638 L2.52296013,3.15851551 L0.577139221,3.15851551 C0.293810163,3.15851551 0.0581659718,2.9536029 0.00929848554,2.68338007 L0,2.57925776 C0,2.29488867 0.204163185,2.05837948 0.473397716,2.00933262 L0.577139221,2 L2.99762619,2 Z M14.6995625,5.08933967 L4.078,5.09 L4.804,8.791 L13.9330268,8.79159925 L14.6995625,5.08933967 Z",
                      fill: "#2F477C",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }