<template>
    <v-alert class="alert-banner">
        <span class="alert-banner__lock-icon">
            <icon icon-name="locked" />
        </span>

        <span class="alert-banner__locked">
            {{ $t('general.alerts.locked') | toSentenceCase }}
        </span>

        <span class="alert-banner__locked-msg">
            {{ $t(reason) | toSentenceCase }}
        </span>
    </v-alert>
</template>

<script>
export default {
    props: {
        reason: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.alert-banner {
    margin: 0;
    padding: 0;
    line-height: 2.5rem;

    &__locked {
        font-size: 1.2rem;
        font-weight: 700;
        color: $alert-banner;
    }

    &__locked-msg {
        font-size: 1.2rem;
        color: $alert-banner;
    }

    &__lock-icon {
        font-size: 2.8rem;
        svg path,
        svg text {
            fill: $alert-banner;
        }
    }

    &::v-deep {
        svg {
            height: 2.6rem;
            width: 2.6rem;
        }
    }
}
</style>
