import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.cssClass },
    [
      _c(
        VRadioGroup,
        {
          staticClass: "rtls-radio-group",
          attrs: { row: _vm.row },
          on: { change: _vm.change },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _vm.label
                    ? _c("p", { staticClass: "checkboxes-list__label" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t(_vm.label))
                            ) +
                            "\n                "
                        ),
                        _vm.isRequired
                          ? _c("span", { staticClass: "asterisk" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        [
          _vm._v(" "),
          _vm._l(_vm.options, function (option, index) {
            return _c(VRadio, {
              key: option[_vm.itemValue],
              staticClass: "rtls-radio",
              attrs: {
                label: option[_vm.itemText],
                value: option[_vm.itemValue],
                disabled: _vm.isDisabled,
                rules: _vm.rules,
                "hide-details": index < _vm.options.length - 1,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }