<template>
    <div class="funding-viewer-grid-container">
        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            :resize-to-fit="false"
            grid-style="width: 100%; height: 64rem;"
            grid-class="ag-theme-custom__funding-viewer-grid"
            dom-layout="normal"
        />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import {
    find,
    differenceBy,
    get,
    intersectionBy,
    size,
    every,
    findIndex,
    forEach,
    set,
    fill,
    isNil,
    includes,
} from 'lodash';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import vuexComponentMixin from '@/js/mixins/vuex-component';

import fundingProvisionsHeader from './funding-provisions-header';
import groupingRenderer from './funding-viewer-grouping-renderer';
import volumeFundingHeader from './volume-funding-viewer-summary-header';
import variableFundingHeader from './variable-funding-viewer-summary-header';
import lumpFundingFooter from './lump-funding-footer';
import lumpFundingHeader from './lump-funding-viewer-summary-header';
import volumesFundingMixin from './volume-funding-mixin';
import lumpFundingMixin from './lump-funding-mixin';
import variableFundingMixin from './variable-funding-mixin';
import promoTabsEnum from '@enums/promotion-tabs';

import {
    isTotalRow,
    apportionMappings,
    columnDefinition,
    defaultGridOptions,
} from '@sharedModules/funding-utils';
import { toSentenceCase } from '@/js/utils/string-utils';
import {
    getFieldPathByProductKey,
    getProductsGroupedBySupplierCategory,
    getFormattedProductSizeAndUnit,
} from '@/js/utils/promotion-products-utils';
import fundingToggleStatesEnum from '@enums/funding-toggle-states';

const toggleConfig = {
    [fundingToggleStatesEnum.all]: {
        volumeFunding: true,
        lumpFunding: true,
        variableFunding: true,
    },
    [fundingToggleStatesEnum.volumeFunding]: {
        volumeFunding: true,
        lumpFunding: false,
        variableFunding: false,
    },
    [fundingToggleStatesEnum.lumpFunding]: {
        volumeFunding: false,
        lumpFunding: true,
        variableFunding: false,
    },
    [fundingToggleStatesEnum.variableFunding]: {
        volumeFunding: false,
        lumpFunding: false,
        variableFunding: true,
    },
};

export default {
    localizationKey: 'planning.promotionsMaintenance.funding',
    mixins: [
        vuexComponentMixin,
        configDrivenGridComponentMixin,
        volumesFundingMixin,
        lumpFundingMixin,
        variableFundingMixin,
    ],

    props: {
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        formRef: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            columnDefs: [],
            rowData: [],
            supplierCategories: [],
            supplierTotals: [],
            separator: '--',
            defaultColDef: {
                ...columnDefinition,
            },
            gridOptions: {
                ...defaultGridOptions,
                frameworkComponents: {
                    groupingRenderer,
                    volumeFundingHeader,
                    variableFundingHeader,
                    lumpFundingHeader,
                    lumpFundingFooter,
                    fundingProvisionsHeader,
                },
                getDataPath: data => {
                    // Define the path used in grouping the tree data.
                    if (data.isSupplier) {
                        const root = `${data.supplierKey}::${data.categoryKey}`;

                        return [root];
                    }

                    if (data.isSupplierTotal) {
                        const root = `${data.supplierKey}::${data.categoryKey}`;

                        return [root, data.productKey];
                    }

                    const category = find(data.hierarchy, {
                        level: this.hierarchyConfig.categoryLevel,
                    });
                    const root = `${data.supplierKey}::${category.levelEntryKey}`;

                    return [root, data.productKey];
                },
            },
            toggleMode: fundingToggleStatesEnum.volumeFunding,
        };
    },

    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapState('rateCards', ['selectedRateCardIdForSplitting']),
        ...mapState('clientConfig', ['hierarchyConfig', 'toggleLogic', 'promotionTabs']),

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        promotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },

        products() {
            if (!this.selectedRateCardIdForSplitting) {
                return this.model.map(product => {
                    product.lumpFundingAllocation = this.calculateLumpFundingAllocation(product);
                    return product;
                });
            }
            return this.model;
        },
    },

    watch: {
        // We need to update the grid data through the grid API as connecting straight to the computed
        // causes the full grid data to refresh when forecasting is run. We use the watch to track when
        // the products have changed, either as a result of forecasting or products being added/removed.
        products: {
            handler(newValue, oldValue) {
                // The grid api requires inserts, updates and deletions to be passed in separately, so compare
                // the previous and new values to determine which products fall into each category.
                const newItems = differenceBy(newValue, oldValue, 'productKey');
                const removedItems = differenceBy(oldValue, newValue, 'productKey');
                const updatedItems = intersectionBy(oldValue, newValue, 'productKey');

                if (newItems.length || removedItems.length) {
                    // If products have been added or removed, suppliers may also have been added or removed,
                    // so recompute them and compare with current suppliers.
                    const supplierCategories = this.mapSuppliersByCategory();

                    const getUniqueSupplierCategory = supplier =>
                        `${supplier.supplierKey}::${supplier.categoryKey}`;

                    const newSuppliers = differenceBy(
                        supplierCategories,
                        this.supplierCategories,
                        getUniqueSupplierCategory
                    );
                    const removedSuppliers = differenceBy(
                        this.supplierCategories,
                        supplierCategories,
                        getUniqueSupplierCategory
                    );
                    const updatedSuppliers = intersectionBy(
                        supplierCategories,
                        this.supplierCategories,
                        getUniqueSupplierCategory
                    );

                    newItems.push(...newSuppliers);
                    removedItems.push(...removedSuppliers);
                    updatedItems.push(...updatedSuppliers);

                    this.supplierCategories = supplierCategories;
                } else {
                    // If no products have been added or removed we need just to find product that were updated
                    // and update them in suppliers array, to be sure that all the changes from BE are applied to supplier products
                    forEach(updatedItems, updatedItem => {
                        every(this.supplierCategories, supplier => {
                            const productIndex = findIndex(
                                supplier.products,
                                product => product.productKey === updatedItem.productKey
                            );
                            if (productIndex !== -1) {
                                this.$set(supplier.products, productIndex, updatedItem);
                            }
                            // if productIndex is found return false and break the loop
                            return productIndex === -1;
                        });
                    });
                }
                this.upsertGridData({ newItems, removedItems, updatedItems });
                this.gridOptions.api.refreshCells();
            },
            deep: true,
        },
    },

    mounted() {
        this.columnDefs = this.getColumnDefs();
        this.supplierCategories = this.mapSuppliersByCategory();

        if (!this.isParkingLot) {
            this.generateChildTotalVolumeAggregations({
                promotionId: this.namespace,
            });
        }
        this.upsertGridData({ newItems: this.supplierCategories });
        this.upsertGridData({ newItems: this.products });
        this.resetRateCardSelection();
        this.setGroupHeaderHeight(this.promotionRateCards);
        this.setUnsavedPromotion({
            namespace: this.namespace,
            tab: promoTabsEnum.funding,
            value: false,
        });
    },

    beforeDestroy() {
        if (!this.promotionTabs.funding.cacheDOM && this.gridOptions.api) {
            // force-clear any references pointing to the grid when it gets removed from the DOM
            this.gridOptions.api.cleanDownReferencesToAvoidMemoryLeakInCaseApplicationIsKeepingReferenceToDestroyedGrid();
        }
    },

    methods: {
        ...mapActions('promotions', [
            'setStagingAreaField',
            'setStagingAreaFields',
            'generateChildTotalVolumeAggregations',
        ]),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        getColumnDefs() {
            return [
                {
                    headerName: toSentenceCase(this.$tkey('headers.fundingProvisions')),
                    headerGroupComponent: 'fundingProvisionsHeader',
                    groupId: 'fundingProvisions',
                    headerGroupComponentParams: {
                        promotionId: this.namespace,
                        toggleMode: () => this.toggleMode,
                        setToggleMode: ({ value }) => {
                            this.toggleMode = value;
                            this.globalEmit(
                                'volume-funding-collapsed',
                                toggleConfig[this.toggleMode].volumeFunding
                            );
                            this.globalEmit(
                                'lump-funding-collapsed',
                                toggleConfig[this.toggleMode].lumpFunding
                            );
                            this.globalEmit(
                                'variable-funding-collapsed',
                                toggleConfig[this.toggleMode].variableFunding
                            );
                        },
                    },
                    children: [
                        {
                            headerName: toSentenceCase(this.$tkey('headers.supplierCategory')),
                            cellClass: ['grouping-cell', 'funding-information'],
                            field: 'group',
                            pinned: 'left',
                            cellRenderer: 'groupingRenderer',
                            cellRendererParams: {
                                visible: params => {
                                    return !isTotalRow(params);
                                },
                                promotionNamespace: this.namespace,
                                selectedPromotion: this.promotion,
                                newFundingGrid: true,
                            },
                            valueGetter: params => {
                                return params.data.productCount;
                            },
                            width: 320,
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('headers.productKey')),
                            cellClass: 'funding-information',
                            field: 'productKey',
                            pinned: 'left',
                            filter: true,
                            menuTabs: ['filterMenuTab'],
                            valueGetter: params => {
                                if (isTotalRow(params)) {
                                    return '';
                                }
                                return params.data.productKey;
                            },
                            width: 80,
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('headers.brandName')),
                            cellClass: 'funding-information',
                            field: 'brandDescription',
                            pinned: 'left',
                            filter: true,
                            menuTabs: ['filterMenuTab'],
                            width: 100,
                        },
                        {
                            headerName: toSentenceCase(this.$tkey(`headers.sizeAndUnits`)),
                            cellClass: 'funding-information',
                            pinned: 'left',
                            filter: true,
                            menuTabs: ['filterMenuTab'],
                            valueGetter: params => getFormattedProductSizeAndUnit(params),
                            maxWidth: 80,
                        },
                    ],
                },
                ...this.getVolumeFundingColumns(),
                ...this.getLumpFundingColumns(),
                ...this.getVariableFundingColumns(),
            ];
        },

        mapSuppliersByCategory() {
            // The suppliers need to be built up from the promotion's products, grouping by supplier and category, and then
            // mapping to a new array of supplier objects which has a products child property.
            const allProducts = this.model;
            // Group using a separator which allows the grouping to be performed on two parts - supplier key and name.
            const supplierProductsGrouped = getProductsGroupedBySupplierCategory(
                allProducts,
                this.hierarchyConfig.categoryLevel,
                this.separator
            );
            const suppliers = Object.keys(supplierProductsGrouped).map(supplierProductKey => {
                const [
                    supplierKey,
                    clientSupplierKey,
                    category,
                    categoryKey,
                ] = supplierProductKey.split(this.separator);
                const supplierProducts = supplierProductsGrouped[supplierProductKey].filter(
                    product => !get(product, 'isSupplierTotal', false)
                );

                // initial allocation of rate cards (left column supplier totals)
                const lumpFundingAllocation = supplierProducts.reduce(
                    (s, product) => s + this.calculateLumpFundingAllocation(product),
                    0
                );
                const supplierName = get(
                    allProducts.find(prod => prod.supplierKey === Number(supplierKey)),
                    'supplierName'
                );

                const supplier = {
                    isSupplier: true,
                    supplierKey: Number(supplierKey),
                    clientSupplierKey,
                    name: supplierName,
                    category,
                    categoryKey,
                    productCount: size(supplierProducts),
                    products: supplierProducts,
                    lumpFundingAllocation,
                };
                return supplier;
            });

            return suppliers;
        },
        upsertGridData({ newItems = [], removedItems = [], updatedItems = [] }) {
            // Using applyTransactionAsync allows the grid to be used while the transactions are being applied.
            this.gridOptions.api.applyTransactionAsync({
                add: newItems,
                remove: removedItems,
                update: updatedItems,
            });
        },
        setNestedField(params, defaultValue) {
            const { fieldPath, fieldName } = params.colDef;

            // If a default value is available and the newValue is falsey,
            // then use the default.
            const newValue =
                !isNil(defaultValue) && !params.newValue ? defaultValue : params.newValue;

            set(params.data, `${fieldPath}.${fieldName}`, newValue);
        },
        onCellValueChanged(params, decimalPlaces = 0) {
            // Update the staging area when grid data changes.
            const { newValue, data, oldValue, colDef } = params;

            const fieldName = colDef.fieldName;

            if (data.isSupplier) {
                // If supplier field, apportion based on field updated.
                const fieldPath = colDef.fieldPath;
                this.supplierValueChanged({ data, fieldPath, fieldName, decimalPlaces });
            } else {
                if (newValue === oldValue) return;
                // If product field, call setStagingAreaField
                const fieldPath = getFieldPathByProductKey({
                    productKey: data.productKey,
                    path: colDef.fieldPath,
                    promotion: this.getStagingAreaPromotionById(this.namespace),
                });

                this.setStagingAreaField({
                    namespace: this.namespace,
                    fieldPath,
                    fieldName,
                    value: newValue,
                });
            }
            if (fieldName === 'tmpLumpFunding') {
                this.updateOverallTotalSupplierFunding();
                // when we update tmpLumpFunding we don't see changes in product watcher
                // we already have updated values but cells not refreshed and we don't see new values
                // for fix it call this method
                this.gridOptions.api.refreshCells();
            }
        },
        onCellValueChangedNoApportion({
            params,
            defaultValue = null,
            recalculateVariableFunding = false,
        }) {
            // Update the staging area when grid data changes.
            const { newValue, data } = params;
            const { fieldPath, fieldName } = params.colDef;

            if (data.isSupplier) {
                // If supplier field, copy supplier-level value to the products.
                this.supplierValueChanged({ data, fieldPath, fieldName, apportion: false });
            } else {
                const fieldValue = !isNil(newValue) ? newValue : defaultValue;
                // If product field, update it in stagingArea
                this.updateProductValues({
                    products: [data],
                    path: fieldPath,
                    fieldName,
                    fieldValues: [fieldValue],
                });
                this.upsertGridData({ updatedItems: [data] });
            }

            if (recalculateVariableFunding) {
                let products = [data];
                if (data.isSupplier) {
                    products = find(
                        this.supplierCategories,
                        s =>
                            s.supplierKey === data.supplierKey && s.categoryKey === data.categoryKey
                    ).products;
                }
                this.recalculateVariableFunding({
                    products,
                    updatedField: fieldName,
                });
            }
        },
        supplierValueChanged({ data, fieldPath, fieldName, apportion = true, decimalPlaces = 0 }) {
            const supplierValue = fieldPath
                ? get(data, `${fieldPath}.${fieldName}`, null)
                : data[fieldName];

            const { products } = data;

            if (apportion) {
                const { getWeight, path } = apportionMappings[fieldName];

                const apportionedValues = this.apportionValue({
                    products,
                    valueToApportion: supplierValue,
                    getWeight,
                    decimalPlaces,
                });

                return this.updateProductValues({
                    products,
                    path,
                    fieldName,
                    fieldValues: apportionedValues,
                });
            }

            const copiedValues = fill(new Array(products.length), supplierValue);
            return this.updateProductValues({
                products,
                path: fieldPath,
                fieldName,
                fieldValues: copiedValues,
            });
        },
        getProductsBySupplier({ supplier }) {
            const { supplierKey, categoryKey } = supplier;
            const supplierProducts = this.products.filter(p => {
                const { levelEntryKey } = this.getCategoryLevel(p.hierarchy);
                return (
                    p.supplierKey === supplierKey && levelEntryKey === categoryKey && !p.isSupplier
                );
            });
            return supplierProducts;
        },
        updateProductValues({ products, path, fieldName, fieldValues }) {
            // Generate an array of product updates which can be applied to the staging area
            // at the same time.
            const productUpdates = products.map(({ productKey, supplierKey, category }, ix) => {
                this.updateSupplierValues({
                    supplierKey,
                    categoryKey: category,
                    productKey,
                    path,
                    fieldName,
                    fieldValue: fieldValues[ix],
                });

                const productFieldPathInPromo = getFieldPathByProductKey({
                    productKey,
                    path,
                    promotion: this.getStagingAreaPromotionById(this.namespace),
                });

                return {
                    fieldPath: productFieldPathInPromo,
                    fieldName,
                    value: fieldValues[ix],
                };
            });
            this.setUnsavedPromotion({
                namespace: this.namespace,
                tab: promoTabsEnum.funding,
                value: true,
            });
            return this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate: productUpdates,
            });
        },
        updateSupplierValues({
            supplierKey,
            categoryKey,
            productKey,
            path,
            fieldName,
            fieldValue,
        }) {
            const supplierIndex = this.supplierCategories.findIndex(
                s => s.supplierKey === supplierKey && s.categoryKey === categoryKey
            );
            const productFieldPathInSupplier = getFieldPathByProductKey({
                productKey,
                path,
                promotion: this.supplierCategories[supplierIndex],
            });

            set(
                this.supplierCategories[supplierIndex],
                `${productFieldPathInSupplier}.${fieldName}`,
                fieldValue
            );
        },
        apportionValue({
            products,
            valueToApportion,
            decimalPlaces = 0,
            getWeight = product => product.volumes.totalVolume,
        }) {
            const apportionFunction = this.featureAwareFactory.getApportionFunction();

            return apportionFunction({
                products,
                valueToApportion,
                getWeight,
                decimalPlaces,
            });
        },
        getCategoryLevel(hierarchy) {
            return find(hierarchy, {
                level: this.hierarchyConfig.categoryLevel,
            });
        },

        // Dynamic Aggregate Function to handle non-apportioned supplier cells
        dynamicSupplierAggFunc({
            supplierKey,
            categoryKey,
            colDef,
            productValues,
            defaultPlaceholder = null,
        }) {
            const { fieldName, fieldPath } = colDef;
            const placeholder = this.$tkey(`symbols.overriddenSupplierPlaceholders.${fieldName}`);
            // Total row contains a hidden cell with an undefined value that needs to be ignored in this validation
            if (includes(productValues, undefined)) {
                productValues.splice(productValues.findIndex(value => value === undefined), 1);
            }
            if (!productValues.length || isNil(supplierKey)) return null;

            const { allValuesMatch, supplierValue } = this.checkMatchingProductValues(
                productValues
            );

            // Update supplier field
            const supplierIndex = findIndex(
                this.supplierCategories,
                s => s.supplierKey === supplierKey && s.categoryKey === categoryKey
            );
            set(this.supplierCategories[supplierIndex], `${fieldPath}.${fieldName}`, supplierValue);

            return allValuesMatch
                ? {
                      supplierValue,
                      placeholder: defaultPlaceholder,
                  }
                : { supplierValue, placeholder };
        },

        checkMatchingProductValues(values) {
            /**
             * Supplier-level value should be updated according to the following scenarios:
             * 1 - If user manually sets a supplier value, the input should be shown and copied to the product fields.
             * 2 - If user overrides a product-level value causing all products to match, supplier should show the matching value.
             * 3 - If user overrides a product-level value and they no longer match, supplier should display a placeholder according to the field.
             * 4 - If product values are all empty / zeroes, supplier value should display zero or empty.
             */
            const matchingValue = values[0];
            const allValuesMatch = every(values, v => v === matchingValue);
            const allValuesFilled = every(values, v => !isNil(v));
            // case 2 and 4 - if matchingValue === true then supplierValue = matchingValue
            // case 3 - if matchingValue === false then supplierValue = null and placeholder is displayed
            const supplierValue = allValuesMatch ? matchingValue : null;

            return { allValuesMatch, allValuesFilled, supplierValue };
        },

        getCellFormatter(format = 'number') {
            return params =>
                this.$n(`numbers.default.${format}`, params.value, {
                    usePlaceholder: true,
                });
        },
    },

    events: {
        onSupplierFundingUploaded({ products }) {
            this.upsertGridData({ updatedItems: products });
        },
        onRateCardDeleted() {
            // rate card was deleted - update supplier funding totals in grid
            this.supplierCategories = this.mapSuppliersByCategory();
            this.upsertGridData({ updatedItems: this.supplierCategories });
        },
        onRateCardChange() {
            if (this.isSingleSupplierCategory) {
                this.rateCardChanged();
                this.addLumpFundingTotal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

$product-background: #eff5f6;

.funding-viewer-grid-container {
    width: 100%;
    max-height: 75rem;
    padding-top: 1.5rem;
    border-bottom: solid;
    border-bottom-width: 0.75rem !important;
    border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;

    overflow-y: auto;
}

::v-deep {
    .no-background {
        background: transparent !important;
    }

    .summary-header {
        &.volume-funding__header {
            border-left: $funding-border-purple;
        }
        &.lump-funding__header {
            border-left: $funding-border-green;
        }
        &.variable-funding__header {
            border-left: $funding-border-orange;
        }
    }

    // lump funding
    .lump-funding-valid-row {
        background: $rtls-positive-action-background !important;
        border-top: $rtls-postive-action-colour 0.2rem solid;
        border-radius: 0 0 0 0 !important;
    }
    .lump-funding-invalid-row {
        background: $rtls-negative-action-background !important;
        border-top: $rtls-negative-action-colour 0.2rem solid;
        border-radius: 0 0 0 0 !important;
    }
    .ag-floating-bottom {
        .ag-cell {
            &.total-cell {
                background-color: $promo-table-blue-bg-colour-3 !important;
            }
        }
    }

    // variable funding
    .negative-unit-funding {
        color: $promo-error !important;
    }
    .funding-information {
        &--calculated {
            &__buying-price-greater-than-cost,
            &__supplier-compensation-greater-than-cost,
            &__negative-funding {
                .rtls-text-field {
                    color: $promo-error !important;
                }
            }
        }
    }
}
</style>
