import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.canWriteParkingLotPromotion } },
    [
      _c(
        "div",
        { staticClass: "create-promotion" },
        [
          _c(
            "div",
            {
              staticClass:
                "create-promotion--prefix create-promotion--first-item",
            },
            [
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm._f("toSentenceCase")(_vm.$t(_vm.getPromotionTypeText()))
                  )
                ),
              ]),
              _vm._v(" "),
              _vm.splitPromotionsEnabled
                ? _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": true,
                        "content-class": "create-promotion--menu",
                        "offset-y": "",
                        top: "",
                        left: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "create-promotion--menu__container",
                                    on: {
                                      mouseover: function ($event) {
                                        return _vm.toggleCreateNewMenu(
                                          !_vm.isDisabled
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "icon-button",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "create-promotion--menu__icon",
                                            attrs: {
                                              icon: "expand_more",
                                              disabled: _vm.isDisabled,
                                              "icon-on-right": "",
                                            },
                                          },
                                          "icon-button",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1390700327
                      ),
                      model: {
                        value: _vm.isMenuOpen,
                        callback: function ($$v) {
                          _vm.isMenuOpen = $$v
                        },
                        expression: "isMenuOpen",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        VList,
                        { staticClass: "create-promotion--menu__list" },
                        [
                          _vm._l([false, true], function (isSplitPromotion) {
                            return [
                              isSplitPromotion !== _vm.splitPromotion
                                ? _c(
                                    VListItem,
                                    {
                                      key:
                                        "promotion-type-split-" +
                                        isSplitPromotion,
                                      on: {
                                        click: function ($event) {
                                          return _vm.splitPromotionSetter(
                                            isSplitPromotion
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("toSentenceCase")(
                                                _vm.$t(
                                                  _vm.getPromotionTypeText(
                                                    isSplitPromotion
                                                  )
                                                )
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isPromotionNameFieldVisible
            ? _c("vuex-text-field", {
                class: ["create-promotion--promotion-name"],
                attrs: {
                  getter: () => _vm.promotionNameGetter(),
                  setter: (value) => _vm.promotionNameSetter(value),
                  placeholder: _vm._f("toSentenceCase")(
                    _vm.$t("planning.promotionsViewer.promotionName")
                  ),
                  "make-read-only": _vm.isDisabled,
                  white: "",
                  "save-whitespace": "",
                  "update-on-input": "",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    !_vm.isDisabled && _vm.$emit("create-promotion")
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("create-new-button", {
            staticClass: "create-promotion--create-btn",
            attrs: {
              "btn-text": "actions.create",
              background: "",
              disabled: _vm.isDisabled || !_vm.promotionName,
            },
            on: {
              createNew: function ($event) {
                return _vm.$emit("create-promotion")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }