'use strict';

/**
 * Enum definition file for the existing client systems
 *
 * @module data/enums/client-systems
 */
module.exports = {
    forecasting: 'forecasting',
    execution: 'execution',
};
