<template>
    <div>
        <div v-if="selectedResourceDefinition" class="allocation-area-grid">
            <div class="allocation-area-grid__cell" :style="getSpanCss(2)">
                <label class="font-weight-bold">
                    {{ $t('preparation.allocation.categoryAllocation') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell row-right-align">
                <label class="font-weight-bold"
                    >{{ $t('preparation.allocation.thisPage') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell row-right-align">
                <label class="font-weight-bold"
                    >{{ $t('preparation.allocation.total') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell row-right-align">
                <label class="font-weight-bold"
                    >{{ $t('preparation.allocation.percentSymbol') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell row-right-align">
                <v-tooltip z-index="400" top :max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" color="primary" size="20" v-on="on">
                            mdi-information-outline
                        </v-icon>
                    </template>
                    <span>{{ $t('preparation.allocation.explanation') | toSentenceCase }}</span>
                </v-tooltip>
            </div>
            <div class="allocation-area-grid__cell" :style="getSpanCss(6)">
                <div class="search-bar" :class="{ 'no-search-field': !showSearchField }">
                    <vuex-search-field
                        v-if="showSearchField"
                        class="search-bar__search-field"
                        :placeholder="$t('preparation.allocation.subCategory') | toSentenceCase"
                    />
                </div>
            </div>
            <div class="allocation-area-grid__cell" :style="getSpanCss(6)">
                <label class="font-weight-bold">
                    {{ $t('preparation.allocation.category') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell" :style="getSpanCss(6)">
                <v-divider />
            </div>
            <template v-for="(category, categoryIx) in categories">
                <div
                    v-for="(field, fieldIx) in allocationFields"
                    :key="`category-${fieldIx}-${categoryIx}`"
                    class="allocation-area-grid__cell"
                    :class="getCellClasses(field.cellClasses, categoryIx, category.categoryKey)"
                    :style="getSpanCss(field.span)"
                >
                    <div v-if="field.text">
                        {{
                            $n(
                                field.numberFormat,
                                getCategoryCount(category.categoryKey, field.countFunction)
                            )
                        }}
                    </div>
                    <div v-else-if="field.field">
                        <span>
                            {{ category[field.field] }}
                        </span>
                    </div>
                    <div v-else-if="field.component">
                        <feature-toggle v-if="field.featureToggle" :toggle="field.featureToggle">
                            <component
                                :is="field.component.name"
                                v-bind="
                                    constructVBindObj({
                                        context: category,
                                        field: field.field,
                                        ...field.component,
                                    })
                                "
                                v-on="
                                    constructVBindEventObj({
                                        events: getAttribute(field.component, 'events'),
                                        context: category,
                                    })
                                "
                                >{{ $t(field.component.text) | toSentenceCase }}
                            </component>
                        </feature-toggle>
                        <component
                            :is="field.component.name"
                            v-else
                            v-bind="
                                constructVBindObj({
                                    context: category,
                                    field: field.field,
                                    ...field.component,
                                })
                            "
                            v-on="
                                constructVBindEventObj({
                                    events: getAttribute(field.component, 'events'),
                                    context: category,
                                })
                            "
                            >{{ $t(field.component.text) | toSentenceCase }}
                        </component>
                    </div>
                </div>
            </template>
            <div class="allocation-area-grid__cell" :style="getSpanCss(6)">
                <v-divider />
            </div>
            <div class="allocation-area-grid__cell" :style="getSpanCss(1)">
                <label class="font-weight-bold">
                    {{ $t('preparation.allocation.nonAllocated') | toSentenceCase }}
                </label>
            </div>
            <div class="allocation-area-grid__cell" :style="getSpanCss(1)">
                <feature-toggle :toggle="canEditChannel">
                    <simple-button
                        :disabled="isCategoryDisabled()"
                        @onClick="
                            setCategory({ categoryKey: categoryAllocationsTypes.nonAllocated })
                        "
                    >
                        {{ $t('preparation.buttons.select') | toSentenceCase }}
                    </simple-button>
                </feature-toggle>
            </div>
        </div>
    </div>
</template>

<script>
import categoryAllocationsTypes from '@enums/category-allocations-types';
import { canEditChannel } from '@enums/feature-flags';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { filter, map, includes, uniq, flattenDeep, get } from 'lodash';
import allocationFields from './categories-allocation-fields';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin],
    props: {
        showSearchField: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            allocationFields,
            categoryAllocationsTypes,
            canEditChannel,
        };
    },

    computed: {
        ...mapGetters('subCampaigns', [
            'selectedSubCampaign',
            'selectedResourceDefinition',
            'selectedResourceDefinitionPage',
        ]),
        ...mapGetters('context', ['userCategories']),
        ...mapGetters('promotions', ['getPromotionById']),
        ...mapGetters('scenarios', ['getScenarioById']),
        ...mapGetters('products', ['getProductsCategories']),
        ...mapState('clientConfig', ['resourceTemplateLayouts']),
        ...mapState('subCampaigns', [
            'selectedResourceDefinitionPageNumber',
            'highlightedCategory',
            'areaForCategoryAllocationSelected',
        ]),
        categories() {
            // Get categories that have been planned on the sub-campaign, of which
            // the user has permissions to see.
            const subCampaignCategories = filter(this.userCategories, category => {
                if (includes(this.selectedSubCampaign.categories, category.levelEntryKey)) {
                    return category;
                }
            });

            return map(subCampaignCategories, subCampaignCategory => {
                return {
                    categoryKey: subCampaignCategory.levelEntryKey,
                    categoryName: subCampaignCategory.levelEntryDescription,
                };
            });
        },
        // We need to count selected leaflet capacity
        // Each page of leaflet should have the template for - layoutTemplate
        // layout is an array like this:
        // [ 'a a b', 'a a c', 'a a d'] for this array capacity is 4 - a, b, c, d
        currentLeafletSpaces() {
            let result = 0;
            if (this.selectedResourceDefinition) {
                result = this.selectedResourceDefinition.pages.reduce((acc, page) => {
                    const { layoutTemplate } = page;
                    if (layoutTemplate) {
                        const { layout } = this.resourceTemplateLayouts.find(
                            layoutItem => layoutItem.key === layoutTemplate
                        );
                        acc += uniq(flattenDeep(layout.map(layoutStr => layoutStr.split(' '))))
                            .length;
                    }
                    return acc;
                }, 0);
            }
            return result;
        },
        // For each category counting how many times this
        // category placed on each page and in full leaflet
        categoryCounts() {
            const result = {};
            if (this.selectedResourceDefinition) {
                this.selectedResourceDefinition.pages.forEach(page => {
                    page.assignment.forEach(assignmentItem => {
                        let productCategories;
                        const assignmentItemCopy = { ...assignmentItem };

                        if (!assignmentItemCopy.categoryKey && assignmentItemCopy.promotionId) {
                            // If a categoryKey has not been allocated, then retrieve
                            // the categories of the products that are associated with
                            // the allocated promotion.
                            const allocatedPromotion = this.getPromotionById(
                                assignmentItemCopy.promotionId
                            );
                            const products = get(allocatedPromotion, 'products', []);
                            productCategories = this.getProductsCategories(products);
                        }

                        const fallbackProductCategories = productCategories
                            ? [...productCategories]
                            : [];

                        // If a category has been explicitly allocated, then use it, otherwise
                        // fall-back to the promotions product categories.
                        const categories = assignmentItemCopy.categoryKey
                            ? [assignmentItemCopy.categoryKey]
                            : fallbackProductCategories;

                        const categoryCountProportion = categories.length
                            ? 1 / categories.length
                            : 0;

                        categories.forEach(categoryKey => {
                            if (!result[categoryKey]) {
                                result[categoryKey] = {
                                    totalCount: categoryCountProportion,
                                    pageCounts: {
                                        [page.pageNumber]: categoryCountProportion,
                                    },
                                };
                            } else {
                                result[categoryKey].totalCount += categoryCountProportion;
                                if (result[categoryKey].pageCounts[page.pageNumber]) {
                                    result[categoryKey].pageCounts[
                                        page.pageNumber
                                    ] += categoryCountProportion;
                                } else {
                                    result[categoryKey].pageCounts[
                                        page.pageNumber
                                    ] = categoryCountProportion;
                                }
                            }
                        });
                    });
                });
            }

            return result;
        },
    },
    methods: {
        ...mapMutations('subCampaigns', ['setHighlightedCategory']),

        isCategoryDisabled() {
            return this.isReadOnly || !this.areaForCategoryAllocationSelected;
        },
        getCellClasses(cellClasses, index, categoryKey) {
            if (this.highlightedCategory === categoryKey) {
                return [...cellClasses, 'allocation-area-grid__cell--highlighted'];
            }
            const alternateRowClass = index % 2 ? 'allocation-area-grid__cell--alternate' : '';
            return cellClasses ? cellClasses.concat([alternateRowClass]) : alternateRowClass;
        },
        setCategory({ categoryKey }) {
            this.globalEmit('set-category', categoryKey);
        },
        countPage(category) {
            if (this.selectedResourceDefinitionPageNumber && this.categoryCounts[category]) {
                return this.categoryCounts[category].pageCounts[
                    this.selectedResourceDefinitionPageNumber
                ];
            }
            return 0;
        },
        countAllLeaflet(category) {
            if (this.categoryCounts[category]) {
                return this.categoryCounts[category].totalCount;
            }
            return 0;
        },
        countAllPercent(category) {
            return this.countAllLeaflet(category) / this.currentLeafletSpaces;
        },
        getCategoryCount(category, countFunction) {
            return this[countFunction](category);
        },

        setHighlightedCategoryAction({ categoryKey }) {
            if (this.highlightedCategory && this.highlightedCategory === categoryKey) {
                this.setHighlightedCategory(null);
            } else {
                this.setHighlightedCategory(categoryKey);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.allocation-area-grid {
    display: grid;
    grid-template-columns: auto 10rem 8rem 8rem 8rem 8rem;

    font-size: 1.2rem;
    overflow-y: auto;

    &__cell {
        @include grid-span;
        @include flex-row;

        align-items: center;
        padding: 0.4rem 0.8rem;

        &--alternate {
            background-color: $rtls-table-alternate-row;
        }
        &--highlighted {
            background-color: $highlighted-category-bg-colour;
        }
    }
}

.search-bar {
    @include promo-component-border($top: true);

    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: baseline;
    background-color: $promo-grey-blue-tint;

    &.no-search-field {
        padding-bottom: 2rem;
    }

    &__search-field {
        flex: 0 1 24rem;
    }
}
.row-right-align {
    justify-content: flex-end;
}
</style>
