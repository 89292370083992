<template>
    <v-date-picker
        ref="picker"
        v-model="computedDates"
        no-title
        scrollable
        :range="range"
        :min="min"
        :max="max"
        :first-day-of-week="firstDayOfWeek"
        show-week
        @input="complete"
        @click:month="setMonth"
    />
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    props: {
        dates: {
            type: [Array, String],
            required: true,
        },
        range: {
            type: Boolean,
            default: false,
        },
        min: String,
        max: String,
        fixedStartDate: {
            type: String,
            default: '',
            required: false,
        },
    },

    data() {
        return {
            modelDates: [],
        };
    },

    computed: {
        ...mapGetters('weeks', ['getWeekByDate']),
        ...mapState('clientConfig', ['generalConfig']),

        firstDayOfWeek() {
            return this.generalConfig.weekStartsWith;
        },

        computedDates: {
            get() {
                return this.modelDates;
            },
            set(dates) {
                // handle case where we only set end date of range. e.g. when promotion has already started
                if (this.fixedStartDate && this.range) {
                    this.modelDates = [this.fixedStartDate, dates[0]];
                } else {
                    this.modelDates = dates;
                }
            },
        },
    },

    created() {
        this.modelDates = this.dates;
    },

    mounted() {
        // update week number generator when children are mounted,
        // week generator function is inside nested child v-date-picker-date-table
        this.updateWeekNumberGeneration();
    },

    methods: {
        complete() {
            this.$emit('complete', this.modelDates);
        },

        setMonth() {
            this.$nextTick(() => {
                this.updateWeekNumberGeneration();
            });
        },

        updateWeekNumberGeneration() {
            const datePickerDateTable = this.$refs.picker.$children[0].$children[1];

            // redefine function that returns the week number to make it check correct day
            datePickerDateTable.getWeekNumber = day => {
                // generate month in ISO format 01,02,03...12
                const displayedMonth = `0${datePickerDateTable.displayedMonth + 1}`.slice(-2);
                let dateToCheck;
                // handle the case if day is bigger than 28 by switching to the next month if needed
                // dates are provided in UTC Z so that Vue.moment won't transform dates based on user's timezone
                if (day > 28) {
                    const d = new Date(
                        `${datePickerDateTable.displayedYear}-${displayedMonth}-${day -
                            7}T00:00:00.000Z`
                    );
                    dateToCheck = new Date(d.setDate(d.getDate() + 7)).toISOString();
                } else {
                    const formattedDay = day > 9 ? day : `0${day}`;
                    dateToCheck = `${
                        datePickerDateTable.displayedYear
                    }-${displayedMonth}-${formattedDay}T00:00:00.000Z`;
                }

                // get weekOfYear from store
                return this.getWeekByDate(dateToCheck).weekOfYear;
            };
            // copy of pad function from vuetify utils
            const pad = (string, targetLength = 2, padString = '0') => {
                string = String(string);
                padString = String(padString);
                if (string.length > targetLength) {
                    return String(string);
                }

                targetLength -= string.length;
                if (targetLength > padString.length) {
                    padString += padString.repeat(targetLength / padString.length);
                }
                return padString.slice(0, targetLength) + String(string);
            };
            // Redefine function that generates calendar to make it check each week number
            // NOTE: this only works for vuetify lower than 2.4. If we update up to 2.4 or higher - genTBody would need to be changed.
            datePickerDateTable.genTBody = () => {
                const children = [];
                const daysInMonth = new Date(
                    datePickerDateTable.displayedYear,
                    datePickerDateTable.displayedMonth + 1,
                    0
                ).getDate();
                let rows = [];
                let day = datePickerDateTable.weekDaysBeforeFirstDayOfTheMonth();

                // Getting first week
                if (datePickerDateTable.showWeek) {
                    rows.push(
                        datePickerDateTable.genWeekNumber(datePickerDateTable.getWeekNumber(1))
                    );
                }

                // Empty space claimed by the days from the previous month.
                for (let i = 0; i < day; i += 1) {
                    rows.push(datePickerDateTable.$createElement('td'));
                }

                for (day = 1; day <= daysInMonth; day += 1) {
                    const date = `${datePickerDateTable.displayedYear}-${pad(
                        datePickerDateTable.displayedMonth + 1
                    )}-${pad(day)}`;

                    rows.push(
                        datePickerDateTable.$createElement('td', [
                            datePickerDateTable.genButton(
                                date,
                                true,
                                'date',
                                datePickerDateTable.formatter
                            ),
                        ])
                    );

                    if (rows.length % (datePickerDateTable.showWeek ? 8 : 7) === 0) {
                        children.push(datePickerDateTable.genTR(rows));
                        rows = [];
                        if (day < daysInMonth && datePickerDateTable.showWeek) {
                            rows.push(
                                datePickerDateTable.genWeekNumber(
                                    datePickerDateTable.getWeekNumber(day + 7)
                                )
                            );
                        }
                    }
                }

                if (rows.length) {
                    children.push(datePickerDateTable.genTR(rows));
                }

                return datePickerDateTable.$createElement('tbody', children);
            };

            // re-render the first month, as has been already rendered.
            datePickerDateTable.$forceUpdate();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-date-picker-table--date__week {
        font-size: 1.2rem;
    }
}
</style>
