<template>
    <div class="filter-group">
        <h4 class="filter-group__header">
            {{ $tkey('title') | toSentenceCase }}
        </h4>

        <v-divider class="mb-2" />
        <vuex-radio-group
            :label="$tkey('soldBy')"
            :options="promotionTypeOptions"
            :getter="() => promotionType"
            :disabled="promotionHasProducts"
            :vuex-module="vuexModule"
            :namespace="namespace"
            row
            class="mb-2"
            @change="onProductTypeChange"
        />
        <required-category-filter @change="onCategoryFilterChange" />
        <v-divider class="mt-2 mb-2" />

        <div class="filter-group__content flex-column flex-column--guttered">
            <div
                v-for="(filter, index) in userFilters"
                :key="filter.filterId"
                class="filter-group__filters"
            >
                <div class="filter-group__wrapper">
                    <vuex-select
                        v-if="canUpdateFilters"
                        class="filter-group__parent-filter"
                        :placeholder="$tkey('placeholder.products') | toSentenceCase"
                        :options="supportedUserFilters"
                        :disabled="isProductSelectDisabled"
                        item-value="filterKey"
                        item-text="filterText"
                        :getter="() => userFilterKeyGetter({ index })"
                        :setter="key => userFilterKeySetter({ index, key })"
                    />

                    <component
                        :is="componentMap[filter.filterType]"
                        v-if="filter.filterKey"
                        :prevent-default="setPreventDefault"
                        :options="getFilterValueOptionsByFilterId({ filterId: filter.filterId })"
                        item-value="value"
                        item-text="text"
                        :getter="() => userFilterValueGetter({ index })"
                        :setter="value => userFilterValueSetter({ index, value })"
                        :finite-list="finiteList"
                        :max-items-in-list="maxItemsInList"
                        multiple
                        chips
                        deletable-chips
                        invalid-selection-text="general.info.invalidFilterSelection"
                        @change="$forceUpdate()"
                    />
                </div>

                <v-btn v-if="showRemoveFilterBtn" icon @click="removeFilter(index)">
                    <icon icon-name="cancel" small icon-colour-type="primary-path" />
                </v-btn>
            </div>

            <create-new-button background :disabled="!canAddFilter" @createNew="addFilter" />

            <v-divider />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { get, isEmpty } from 'lodash';
import { fieldTypes } from '@enums/vuex-form';
import productTypes from '@enums/product-types';

const maxFilterCount = 5;

export default {
    localizationKey: 'planning.promotionsMaintenance.products.filterPanel',
    props: {
        finiteList: {
            type: Boolean,
            default: true,
        },
        maxItemsInList: {
            type: Number,
            default: 3,
        },
        requiredFilters: {
            type: Array,
            default: () => [],
        },
        vuexModule: {
            required: true,
            type: String,
        },
        namespace: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            hasRequiredFilters: false,
            componentMap: {
                [fieldTypes.text]: 'vuex-text-field',
                [fieldTypes.select]: 'vuex-select',
            },
            promotionTypeOptions: [
                {
                    text: this.$tkey(productTypes.unit),
                    value: productTypes.unit,
                },
                {
                    text: this.$tkey(productTypes.weight),
                    value: productTypes.weight,
                },
            ],
        };
    },

    computed: {
        ...mapState('promotionCandidates', [
            'userFilters',
            'userFilterDefinitions',
            'promotionCandidates',
            'selectedCategory',
        ]),
        ...mapGetters('promotionCandidates', [
            'userFilterKeyGetter',
            'userFilterValueGetter',
            'getFilterValueOptionsByFilterId',
            'filteredUserFilterDefinitions',
        ]),
        ...mapGetters('promotions', ['selectedPromotion', 'getStagingAreaPromotionById']),

        promotion() {
            if (!isEmpty(this.selectedPromotion)) return this.selectedPromotion;

            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            return this.getStagingAreaPromotionById('default');
        },

        promotionType() {
            // ensure unit is the default e.g. in parkinglot creation mode
            return this.promotion.isWeightPromotion ? productTypes.weight : productTypes.unit;
        },

        promotionHasProducts() {
            return get(this.promotion, 'products.length', 0) > 0;
        },

        canUpdateFilters() {
            if (!this.requiredFilters.length) return true;
            return this.hasRequiredFilters;
        },
        isProductSelectDisabled() {
            return !this.selectedCategory;
        },
        canAddFilter() {
            return (
                this.canUpdateFilters &&
                !this.isReadOnly &&
                this.userFilters.length < maxFilterCount
            );
        },

        showRemoveFilterBtn() {
            return this.userFilters.length > 1 || this.userFilters[0].filterKey !== null;
        },

        supportedUserFilters() {
            return this.filteredUserFilterDefinitions.filter(
                f => !this.requiredFilters.includes(f.filterKey)
            );
        },
        setPreventDefault() {
            return this.userFilters[0].filterType === 'text';
        },
        firstCandidate() {
            if (isEmpty(this.promotionCandidates)) return {};
            // first candidate may be group so try to get first nested product, otherwise first candidate is product
            return get(this.promotionCandidates, '0.products.0', this.promotionCandidates[0]);
        },
    },

    watch: {
        promotionCandidates: {
            immediate: true,
            handler() {
                this.setFilteredResource();
            },
        },
        selectedCategory: {
            immediate: true,
            handler() {
                this.hasRequiredFilters = true;
            },
        },
    },

    async created() {
        // if wrong type of product is cached, refetch
        if (this.firstCandidate.isUnitProduct === this.promotion.isWeightPromotion) {
            // same as onProductTypeChange but avoid setPromotionType which updates promotion
            await this.resetCandidatesAndCache();
            await this.fetchPromotionCandidatesByScenarioCategoryStreamed();
            await this.fetchProductBrandsByCategory();
        }
    },

    methods: {
        ...mapActions('promotionCandidates', [
            'resetState',
            'resetCandidatesAndCache',
            'resetUserFilters',
            'removeUserFilter',
            'addUserFilter',
            'userFilterKeySetter',
            'userFilterValueSetter',
            'setFilteredResource',
            'setSelectedCategory',
            'fetchPromotionCandidatesByScenarioCategoryStreamed',
        ]),
        ...mapActions('promotions', ['updatePromotion', 'setStagingAreaField']),
        ...mapActions('products', ['fetchProductBrandsByCategory']),

        async onCategoryFilterChange({ selectedCategoryId }) {
            // when changing category, don't reset cache, just state
            await this.resetState({ excludedFields: ['productGroupOpenState.offerMechanic'] });
            this.setSelectedCategory(selectedCategoryId);
            // only fetch if there are no candidate products, or they are same type as promotion to avoid double fetching.
            // case where products are not the same as promotion should be handled in created hook.
            if (
                isEmpty(this.firstCandidate) ||
                this.firstCandidate.isUnitProduct !== this.promotion.isWeightPromotion
            ) {
                await this.resetCandidatesAndCache();
                await this.fetchPromotionCandidatesByScenarioCategoryStreamed();
                await this.fetchProductBrandsByCategory();
            }
        },

        async onProductTypeChange(productType) {
            // need to clear out cache and products since product type has changed
            // must run in sequence
            await this.resetCandidatesAndCache();
            await this.setPromotionType(productType);
            await this.fetchPromotionCandidatesByScenarioCategoryStreamed();
        },

        async setPromotionType(value) {
            const isWeightPromotion = value === productTypes.weight;
            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            if (this.promotion._id === 'default') {
                this.setStagingAreaField({
                    namespace: 'default',
                    fieldName: 'isWeightPromotion',
                    value: isWeightPromotion,
                });
            } else {
                await this.updatePromotion({
                    id: this.promotion._id,
                    updates: { isWeightPromotion },
                });
            }
        },

        addFilter() {
            this.addUserFilter();
        },

        removeFilter(index) {
            if (this.userFilters.length === 1) {
                // if only 1 filter available - reset its value
                this.resetUserFilters();
            } else {
                // otherwise remove filter
                this.removeUserFilter(index);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.filter-group {
    &__header {
        padding-bottom: 0.7rem;
    }

    &__content {
        margin-top: 1.2rem;
        width: 100%;
    }

    &__filters {
        @include flex-row;
        align-items: flex-end;
    }

    &__wrapper {
        min-width: 92%;
    }

    &__parent-filter {
        margin-bottom: 0.4rem;
    }

    &::v-deep {
        .v-chip--select {
            max-width: 12.5rem;
        }
        .v-input--selection-controls.v-input {
            margin-top: 0;
        }
    }
}
</style>
