<template functional>
    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="29" fill="#39477B" />
        <path
            d="M12.0001 9.7V6.5L8.0001 10.5L12.0001 14.5V11.3C14.6481 11.3 16.8001 13.452 16.8001 16.1C16.8001 18.748 14.6481 20.9 12.0001 20.9C9.3521 20.9 7.2001 18.748 7.2001 16.1H5.6001C5.6001 19.636 8.4641 22.5 12.0001 22.5C15.5361 22.5 18.4001 19.636 18.4001 16.1C18.4001 12.564 15.5361 9.7 12.0001 9.7Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: 'NewRevert',
};
</script>
