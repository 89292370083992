<template>
    <v-form ref="priceWeightForm" v-model="isValid" hide-details @submit.prevent>
        <div class="price-weight">
            <div class="price-weight__container">
                <vuex-number-input
                    :getter="() => getValue('weight')"
                    :setter="newValue => setValue(newValue, productTypes.weight)"
                    class="price-weight__container--input"
                    :disabled="isReadOnly"
                    hide-details
                    positive-only
                    @change="onChange(false)"
                />
            </div>
            <div class="price-weight__container">
                <vuex-select
                    :options="unitOptions"
                    :getter="() => getValue(productTypes.unit)"
                    :setter="newValue => setValue(newValue, productTypes.unit)"
                    filled
                    class="price-weight__container--select"
                    :validate-on-blur="false"
                    :disabled="isReadOnly"
                    @change="onChange(true)"
                />
            </div>
        </div>
        <v-messages
            v-if="formValidationError.length"
            :color="'error'"
            :value="formValidationError"
        />
    </v-form>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import productTypes from '@enums/product-types';
import mountedHackMixin from '@/js/mixins/mounted-hack';

export default Vue.extend({
    mixins: [mountedHackMixin],
    data() {
        return {
            isValid: true,
            model: {},
            productTypes,
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        unitOptions() {
            return [
                ...this.toggleLogic.weightOptions.map(option => ({
                    text: this.$t(`general.weightOptions.${option}`),
                    value: option,
                })),
            ];
        },
        formValidationError() {
            if (this.mounted) {
                const inputs = this.$refs.priceWeightForm.inputs;
                const errorMessages = inputs.flatMap(input => input.errorBucket);
                return errorMessages.slice(0, 1);
            }
            return [];
        },
    },
    created() {
        // Ensure the internal state is set correctly when the component is first created.
        this.model = this.params.value || { unit: 'g' };
    },
    methods: {
        getValue(field) {
            if (!this.model) {
                return null;
            }
            return this.model[field];
        },
        setValue(value, field) {
            this.model = {
                ...(this.model || {}),
                [field]: value,
            };
        },
        onChange(isUnit) {
            // no need save if unit was changed and weight not set
            if (isUnit && !this.model.weight) {
                return;
            }
            // calling validate manually as looks like validators set changing in computed
            // after form validated automatically
            if (this.$refs.priceWeightForm) {
                this.$refs.priceWeightForm.validate();
            }
            // need to use nextTick here as isValid is not actual
            this.$nextTick(() => {
                if (this.isValid) {
                    const { productKey } = this.params.node.data;
                    this.params.saveModel({
                        productKey,
                        model: !this.model.weight ? null : this.model,
                    });
                }
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.price-weight {
    display: flex;

    &__container {
        display: flex;
        align-items: center;

        &--input {
            width: 4rem;
            margin: 0 0.2rem;
        }

        &--select {
            width: 5.3rem;
        }

        &::v-deep {
            .v-text-field__details {
                display: none;
            }
        }
    }
}
</style>
