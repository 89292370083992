import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c("span", {
        directives: [
          {
            name: "observe-visibility",
            rawName: "v-observe-visibility",
            value: _vm.visibilityChanged,
            expression: "visibilityChanged",
          },
        ],
      }),
      _vm._v(" "),
      _vm.tooltipRequired
        ? _c(
            VTooltip,
            {
              attrs: {
                "z-index": "300",
                bottom: "",
                "max-width": _vm.maxWidth,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "span",
                          _vm._g(_vm._b({}, "span", attrs, false), on),
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.truncatedText) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3953781477
              ),
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.text))])]
          )
        : _c("span", [_vm._v(" " + _vm._s(_vm.truncatedText) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }