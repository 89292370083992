var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-title py-2" }, [
    _c("div", { staticClass: "warning-text" }, [
      _vm._v(
        _vm._s(_vm._f("toSentenceCase")(_vm.$t("planning.warnings.warning"))) +
          "!"
      ),
    ]),
    _vm._v(" "),
    _vm.mergeTags
      ? _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t(_vm.descriptionText, {
                    tagName: _vm.tagName,
                    duplicateTagName: _vm.duplicateTagName,
                  })
                )
              ) +
              "\n    "
          ),
        ])
      : _c("div", [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.descriptionText))) +
              "\n    "
          ),
        ]),
    _vm._v(" "),
    !_vm.isEmpty(_vm.childDependencies)
      ? _c("div", [
          _vm.deleteTag
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t(_vm.warningText, { tagName: _vm.tagName })
                      )
                    ) +
                    "\n        "
                ),
              ])
            : _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.warningText))) +
                    "\n        "
                ),
              ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.childDependencies, function (count, entityName) {
              return _c("li", { key: entityName }, [
                count
                  ? _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t(`pluralEntities.${entityName}`)
                            )
                          ) +
                          ": " +
                          _vm._s(count) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }