var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "promo-resource-tab",
    _vm._b(
      {
        key: _vm.selectedSubCampaignId,
        attrs: {
          "edit-context": _vm.selectedSubCampaign,
          "promo-resource": _vm.promoResource,
        },
      },
      "promo-resource-tab",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }