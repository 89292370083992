var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isOnDatesTab
      ? _c(
          "div",
          { staticClass: "promotion-period" },
          [
            _c("div", { staticClass: "promotion-period__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("planning.promotionsMaintenance.dates.period")
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "promotion-period__start-end" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("planning.promotionsMaintenance.dates.startEnd")
                    )
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                _vm.getVuexComponent(field.type),
                _vm._b(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !field.hidden,
                        expression: "!field.hidden",
                      },
                    ],
                    key: "child" + index,
                    tag: "component",
                    attrs: {
                      "vuex-module": _vm.vuexModule,
                      namespace: _vm.computedNamespace,
                      context: _vm.context,
                      "parsed-field": _vm.parseFieldDefinition(field),
                      disabled: _vm.isDisabledField({
                        editable: _vm.resolveValue(field.editable),
                      }),
                      "edit-mode": _vm.editMode,
                    },
                    on: { change: _vm.onDateChange },
                  },
                  "component",
                  field,
                  false
                )
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }