'use strict';

// Register files by loading all js files inside the given folder
// Inspired by: http://stackoverflow.com/questions/29421409/how-to-load-all-files-in-a-subdirectories-using-webpack-without-require-statemen
export default function registerDirectivesAndFilters(Vue, req) {
    // As the directive and filter files register themselves within Vue, we just require them and they will auto-register
    const allFiles = req.keys();

    allFiles.forEach(jsFile => {
        let functionFile = req(jsFile);
        if (functionFile.default) functionFile = functionFile.default; // handle ES6 syntax in view models that use export default { ...viewModeldeclaration... }

        functionFile(Vue);
    });
}
