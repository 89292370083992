<template>
    <v-container :class="cssClass">
        <v-checkbox
            v-if="isSelectAllAvailable"
            v-model="selectAll"
            class="rtls-checkbox select-all"
            :label="$t(label) | toSentenceCase"
            :disabled="isSelectAllDisabled"
        />
        <v-container class="checkboxes-list-container" fluid>
            <p v-if="!isSelectAllAvailable" class="checkboxes-list__label">
                {{ $t(label) | toSentenceCase }}
                <span v-if="isRequired" class="asterisk">*</span>
            </p>
            <v-container class="checkboxes-list-container__control">
                <v-checkbox
                    v-for="(option, index) in getOptions"
                    :key="getKey(option, index)"
                    v-model="model"
                    class="rtls-checkbox"
                    :multiple="true"
                    :label="option.reference[itemText] | toSentenceCase"
                    :value="getValue(option)"
                    :disabled="isDisabled({ option })"
                    :rules="rules"
                    :hide-details="index < getOptions.length - 1"
                    @change="change"
                />
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import { map, some } from 'lodash';
import vuexComponentMixin from '../../mixins/vuex-component';
import vuexCheckboxList from '@/js/mixins/vuex-checkbox-list';

export default {
    mixins: [vuexComponentMixin, vuexCheckboxList],

    props: {
        isSelectAllAvailable: {
            type: Boolean,
            default: false,
        },
        change: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        selectAll: {
            get() {
                return (
                    this.model && this.getOptions && this.model.length === this.getOptions.length
                );
            },
            set(value) {
                this.model = value ? map(this.getOptions, `reference.${this.itemValue}`) : [];
                this.change(this.model);
            },
        },
        isSelectAllDisabled() {
            return some(this.getOptions, option => this.isDisabled({ option }));
        },
    },

    methods: {
        isDisabled({ option }) {
            return this.disabled || this.isOptionDisabled(option) || this.isReadOnly;
        },
    },
};
</script>

<style lang="scss" scoped>
.checkboxes-list-container {
    .checkboxes-list__label {
        font-size: 1.2rem;
        font-weight: 600;
        padding-bottom: 0.8rem;
    }

    &__control {
        padding: 0;
    }
}
</style>
