import {
    find,
    merge,
    sortBy,
    filter,
    get,
    groupBy,
    transform,
    map,
    forIn,
    difference,
    some,
    includes,
    lowerCase,
} from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    hierarchy: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     */
    getters: {
        getHierarchyByKey: state => levelEntryKey => find(state.hierarchy, { levelEntryKey }),
        getHierarchyByKeys: state => ({ levelEntryKeys }) =>
            sortBy(state.hierarchy.filter(item => levelEntryKeys.includes(item.levelEntryKey)), [
                i => lowerCase(i.levelEntryDescription),
            ]),
        getAllUnits: (state, getters, rootState) =>
            filter(
                state.hierarchy,
                hierarchy => hierarchy.level === rootState.clientConfig.hierarchyConfig.unitLevel
            ),
        getAllCategories: (state, getters, rootState) =>
            filter(
                state.hierarchy,
                hierarchy =>
                    hierarchy.level === rootState.clientConfig.hierarchyConfig.categoryLevel
            ),
        getCategoriesKeyedByLevelEntryKey: (state, getters) =>
            getters.getAllCategories.reduce((acc, cat) => {
                acc[cat.levelEntryKey] = cat;
                return acc;
            }, {}),
        getCategoriesInFilter: state => {
            return get(state, 'filter.categories', []);
        },
        getHierarchiesByParentKey: state => ({ parentLevel, parentLevelEntryKey }) =>
            // Add 1 to the parent level to give the correct level of the hierarchies we want to retrieve
            filter(state.hierarchy, { level: parentLevel + 1, parentId: parentLevelEntryKey }),

        getCategoryKeysForHierarchyNode: (state, getters, rootState) => ({
            level,
            levelEntryKey,
        }) => {
            const categoryLevel = rootState.clientConfig.hierarchyConfig.categoryLevel;
            if (level === categoryLevel) return levelEntryKey;

            if (level > categoryLevel) {
                const hierarchy = getters.getHierarchyByKey(levelEntryKey);
                // Hierarchy node is lower than category level, traverse through parents to
                // find the category it belongs to.
                const parentHierarchy = getters.getHierarchyByKey({
                    levelEntryKey: hierarchy.parentId,
                });
                return getters.getCategoryKeysForHierarchyNode(parentHierarchy);
            }

            // Level < categoryLevel
            // Hierarchy node is higher than category level, so return the categories for
            // each of it's children.
            const childHierarchies = getters.getHierarchiesByParentKey({
                parentLevel: level,
                parentLevelEntryKey: levelEntryKey,
            });

            return childHierarchies.flatMap(childHierarchy =>
                getters.getCategoryKeysForHierarchyNode(childHierarchy)
            );
        },

        /**
         * As the result of getUnitToCategoriesMap function we expect to have a map:
         * { unitId1: [categoryId1, categoryId2], unitId2: [categoryId3, categoryId4, categoryId5] }

         * At the beginning category option is
         * Array<{level, levelDescription, levelEntryDescription, levelEntryKey, parentId - this is category unit, productCategoryKey, _id}>

         * We group this array by groupBy(categoryOptions, 'parentId'), to the next object:
         * { unitId1: [category1, category2], unitId2: [category3, category4, category5] }

         * Then by transform, we change object values from array of objects to array of ids.
         */
        getUnitToCategoriesMap: (state, getters, rootState, rootGetters) => ({
            categoriesList = [],
            allCategories = false,
        }) => {
            let categoryOptions = allCategories
                ? getters.getAllCategories
                : rootGetters['context/userCategories'];

            if (categoriesList.length !== 0) {
                categoryOptions = rootGetters['context/userCategories'].filter(option => {
                    return categoriesList.includes(option.levelEntryKey);
                });
            }

            return transform(
                groupBy(categoryOptions, 'parentId'),
                (result, value, key) => {
                    result[key] = map(value, item => item.levelEntryKey);
                },
                {}
            );
        },
        getUnitByLevelEntryKey: (state, getters) => ({ unitLevelEntryKey }) => {
            return find(getters.getAllUnits, { levelEntryKey: unitLevelEntryKey });
        },
        /**
         * As the result of getCategoriesTree function we expect to have a map:
         * {id: 'root', children: Array<{id, label, selectable, selected, cssclass, children: Array<{id, label, selectable, selected, cssclass}>}>}
         * selectable - if false, then checkbox is disabled
         * selected - if true, checkbox is checked
         * cssclass - cSS classes to each item Finder component
         */
        getCategoriesTree: (state, getters, rootState, rootGetters) => ({
            allCategories = false,
            selectedCategories = [],
            parentCategories = [],
            childCategories = [],
            lockedCategories = [],
            isEditable = false,
        }) => {
            const categoryOptions = allCategories
                ? getters.getAllCategories
                : rootGetters['context/userCategories'];
            const tree = [];
            const unitToCategoriesMap = getters.getUnitToCategoriesMap({
                allCategories,
                categoriesList: parentCategories,
            });

            forIn(groupBy(categoryOptions, 'parentId'), (unitCategories, unitLevelEntryKey) => {
                const unit = getters.getUnitByLevelEntryKey({ unitLevelEntryKey });
                // If it's sub-campaign/scenario we should filter only categories that were selected on campaign/sub-campaign level
                const categories = unitCategories
                    .filter(
                        category =>
                            parentCategories.length === 0 ||
                            includes(parentCategories, category.levelEntryKey)
                    )
                    .map(category => {
                        let selectable;
                        // send locked categories explicitly
                        if (lockedCategories && lockedCategories.length) {
                            selectable = !lockedCategories.find(
                                lockedCategory =>
                                    lockedCategory.levelEntryKey === category.levelEntryKey
                            );
                        } else {
                            // Default
                            // If category is selected on sub-campaign/scenario level, we should disable select for them on campaing/sub-campaign level
                            selectable =
                                isEditable && !includes(childCategories, category.levelEntryKey);
                        }
                        return {
                            id: category.levelEntryKey.toString(),
                            label: category.levelEntryDescription,
                            selected: includes(selectedCategories, category.levelEntryKey),
                            selectable,
                        };
                    });

                // If on campaign/sub-campaign level we don't select any category from unit, then we don't need this unit in sub-campaign/scenario level
                if (categories.length !== 0) {
                    const isUnitSelected =
                        difference(unitToCategoriesMap[unit.levelEntryKey], selectedCategories)
                            .length === 0;
                    const unitClass =
                        !isUnitSelected &&
                        some(unitToCategoriesMap[unit.levelEntryKey], item =>
                            includes(selectedCategories, item)
                        )
                            ? 'partially-selected'
                            : '';
                    tree.push({
                        id: unit.levelEntryKey.toString(),
                        label: unit.levelEntryDescription,
                        children: sortBy(categories, [category => lowerCase(category.label)]),
                        // If at least 1 category from unit is disabled, we should disable unit checkbox
                        selectable: isEditable && !some(categories, { selectable: false }),
                        selected: isUnitSelected,
                        cssClass: unitClass,
                    });
                }
            });

            return {
                id: 'root',
                children: sortBy(tree, [item => lowerCase(item.label)]),
            };
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setHierarchy
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchHierarchy
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'hierarchy',
    isPlural: true,
    useFilters: true,
    readOnly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
