<template>
    <div class="promotions__grid">
        <template v-for="(promotion, rowIndex) in promotions">
            <div
                v-for="(item, colIndex) in fields"
                :key="promotion._id + '::' + rowIndex + '::' + colIndex"
                :ref="`promotion_${promotion._id}`"
                class="promotions__grid-cell"
                :class="[item.cellClasses, item.contentClasses]"
            >
                <div class="promotions__data" :class="getColourClass(promotion)">
                    <div v-if="item.functionName">
                        <span>{{ callAction(item.functionName, promotion) }}</span>
                    </div>

                    <div v-else-if="item.component">
                        <component
                            :is="item.component.name"
                            v-bind="
                                constructVBindObj({
                                    context: promotion,
                                    field: item.field,
                                    ...item.component,
                                })
                            "
                            v-on="
                                constructVBindEventObj({
                                    events: getAttribute(item.component, 'events'),
                                    context: promotion,
                                })
                            "
                            >{{ $t(item.component.text) | toSentenceCase }}
                        </component>
                    </div>

                    <span v-else class="truncate-text">
                        {{ getAttribute(promotion, item.field) }}
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import vuexComponentMixin from '@/js/mixins/vuex-component';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin],

    props: {
        promotions: {
            type: Array,
            default: () => [],
        },
        fields: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        getProductCount(params) {
            const numberOfProducts = (params.products && params.products.length) || 0;
            return this.$tc(`supplierCommitments.products`, numberOfProducts);
        },

        getColourClass({ effectivenessRating }) {
            if (effectivenessRating) {
                return `promotions__border-colour--${effectivenessRating}`;
            }
        },

        isPromotionSelected() {
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.promotions {
    &__grid {
        display: grid;
        grid-template-columns: 3.4rem 22rem 10rem 42rem;
        position: relative;
    }

    &__data {
        @include flex-column;
        padding-top: 0.75rem;
        padding-left: 1rem;
        min-height: 3.5rem;
        background-color: $promo-white;
        border-top: $promo-divider-accent-colour 0.2rem solid;
        border-bottom: $border-shadow 0.1rem solid;
        font-weight: 600;
        line-height: 1.5rem;
        font-size: 1.2rem;
        color: $text-colour;
        letter-spacing: 0;
        box-shadow: 0 0.4rem 0.4rem 0 $box-shadow-color, 0 0.2rem 0 0 $box-shadow-color;

        &:hover {
            cursor: pointer;
        }
    }

    &__border-colour {
        @include promo-rag-colours(border-top-color);
    }

    &__grid-cell {
        padding-top: 0.5rem;
        display: contents;
    }
}

.last-column {
    padding-right: 0.75rem;

    .promotions__data {
        padding-top: 0;
        justify-content: center;
    }
}
</style>
