/**
 * Retrieves a flattened array of store group attributes.
 *
 * @param {Array} storeGroups - The store groups to get the attributes from.
 */
export function getFlattenedStoreGroupAttributes({ storeGroups }) {
    const storeGroupAttributes = (storeGroups || []).reduce((acc, storeGroup) => {
        storeGroup.attributes.forEach(({ attributeName, value }) => {
            if (acc[attributeName]) {
                if (!acc[attributeName].includes(value)) {
                    acc[attributeName].push(value);
                }
            } else {
                acc[attributeName] = [value];
            }
        });

        return acc;
    }, {});

    return Object.entries(storeGroupAttributes).map(([key, values]) => {
        return {
            attributeName: key,
            attributeValues: values,
        };
    });
}

/**
 * Builds a filter across all store group attributes.
 *
 * @param {Array} storeGroups - The store groups to get the attributes from.
 */
export function getStoreGroupAttributeFilters({ storeGroups }) {
    const storeGroupAttributes = getFlattenedStoreGroupAttributes({ storeGroups });

    // Build up an AND clause to include all the store group attributes
    // that exist on the selected store groups.
    const storeGroupAttributeFilters = storeGroupAttributes.map(
        ({ attributeName, attributeValues }) => {
            return {
                storeGroupAttributes: {
                    $elemMatch: {
                        attributeName,
                        attributeValues: { $all: attributeValues },
                    },
                },
            };
        }
    );

    return { $and: [...storeGroupAttributeFilters] };
}
