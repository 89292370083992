var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "18",
        viewBox: "0 0 19 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.25524 11.7H10.0552V13.5H8.25524V11.7ZM8.25524 4.5H10.0552V9.9H8.25524V4.5Z",
          fill: "#786F6F",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M15.1552 1.50001H3.15515C2.32672 1.50001 1.65515 2.17158 1.65515 3.00001V15C1.65515 15.8284 2.32672 16.5 3.15515 16.5H15.1552C15.9836 16.5 16.6552 15.8284 16.6552 15V3.00001C16.6552 2.17158 15.9836 1.50001 15.1552 1.50001ZM3.15515 1.14441e-05C1.4983 1.14441e-05 0.155151 1.34316 0.155151 3.00001V15C0.155151 16.6569 1.4983 18 3.15515 18H15.1552C16.812 18 18.1552 16.6569 18.1552 15V3.00001C18.1552 1.34316 16.812 1.14441e-05 15.1552 1.14441e-05H3.15515Z",
          fill: "#786F6F",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }