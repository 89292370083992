import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-offer-group" },
    [
      _vm.productOfferGroup
        ? _c(
            VExpansionPanel,
            { key: _vm.index, staticClass: "product-offer-group__panel" },
            [
              _c(
                VExpansionPanelHeader,
                {
                  staticClass: "product-offer-group__panel--header",
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ])
                      )
                        return null
                      $event.preventDefault()
                    },
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "product-offer-group__name" }, [
                      _c(
                        "span",
                        { staticClass: "product-offer-group__name--label" },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("pg")))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "product-offer-group__name--wrapper",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c(VTextField, {
                            staticClass:
                              "rtls-text-field rtls-text-field--white product-offer-group__name--text",
                            attrs: {
                              placeholder: _vm.$tkey("pgName"),
                              value: _vm.productOfferGroup.description || "",
                            },
                            on: {
                              input: _vm.debouncedUpdateDescription,
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-offer-group__delete" },
                      [
                        _c("common-delete-dialog", {
                          attrs: {
                            resource: _vm.productOfferGroupsResource,
                            "child-dependencies-warning": false,
                            disabled: _vm.promotion.splitPromotion,
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.deletePOG()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(VExpansionPanelContent, [
                _c(
                  "div",
                  {
                    staticStyle: { display: "contents" },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "product-offer-group__category-selector",
                        style: _vm.categorySelectorStyle,
                      },
                      [
                        _c(
                          "b",
                          {
                            staticClass:
                              "product-offer-group__category-selector--text",
                          },
                          [_vm._v(_vm._s(_vm.$tkey("include")))]
                        ),
                        _vm._v(" "),
                        _c("vuex-select", {
                          staticClass:
                            "product-offer-group__category-selector--type",
                          attrs: {
                            options: _vm.options,
                            "item-text": "name",
                            "item-value": "id",
                            "vuex-module": _vm.vuexModule,
                            namespace: _vm.namespace,
                            tab: _vm.offer,
                            "field-path": _vm.fieldPath,
                            "field-name": "scope",
                          },
                          on: { change: _vm.onPOGScopeChange },
                        }),
                        _vm._v(" "),
                        _vm.productOfferGroup.scope !== _vm.pogScope.storeWide
                          ? [
                              _c(
                                "b",
                                {
                                  staticClass:
                                    "product-offer-group__category-selector--text",
                                },
                                [_vm._v(_vm._s(_vm.$tkey("from")))]
                              ),
                              _vm._v(" "),
                              _c("product-offer-group-categories", {
                                staticClass:
                                  "product-offer-group__category-selector--categories",
                                attrs: {
                                  namespace: _vm.namespace,
                                  "pog-index": _vm.index,
                                  "is-category-wide-promotion":
                                    _vm.isSelectedPromotionCategoryWide,
                                },
                                on: { change: _vm.onCategoriesChange },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.productOfferGroup.scope ===
                    _vm.pogScope.selectedProducts
                      ? [
                          _c(
                            "div",
                            { staticClass: "product-offer-group__filter" },
                            [
                              _c(
                                VBtnToggle,
                                {
                                  staticClass:
                                    "product-offer-group__filter--toggle",
                                  staticStyle: { "margin-bottom": "1rem" },
                                  attrs: { mandatory: "" },
                                  on: { change: _vm.setViewSelectedOnly },
                                  model: {
                                    value: _vm.viewSelectedOnly,
                                    callback: function ($$v) {
                                      _vm.viewSelectedOnly = $$v
                                    },
                                    expression: "viewSelectedOnly",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        value: false,
                                        disabled: _vm.isReadOnly,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$tkey("all")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        value: true,
                                        disabled: _vm.isReadOnly,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$tkey("selected")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VTooltip,
                                {
                                  attrs: {
                                    top: "",
                                    disabled: !_vm.promotionNotSaved,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g({}, on),
                                              [
                                                _c(
                                                  VBtn,
                                                  {
                                                    staticClass:
                                                      "product-offer-group__add-bulk",
                                                    attrs: {
                                                      color: "primary",
                                                      depressed: "",
                                                      disabled:
                                                        _vm.isReadOnly ||
                                                        !_vm.hasUserSelectedCategories ||
                                                        _vm.promotionNotSaved,
                                                    },
                                                    on: {
                                                      click: _vm.openBulkDialog,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "toSentenceCase"
                                                          )(
                                                            _vm.$t(
                                                              "actions.selectUsingProductKeys"
                                                            )
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2651151004
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$tkey("savePromotionBefore")
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("vuex-radio-group", {
                                attrs: {
                                  label: _vm.$tkey("soldBy"),
                                  options: _vm.promotionTypeOptions,
                                  getter: () => _vm.promotionType,
                                  disabled: _vm.promotionHasProducts,
                                  "vuex-module": _vm.vuexModule,
                                  namespace: _vm.namespace,
                                  row: "",
                                },
                                on: { change: _vm.onProductTypeChange },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.expanded && _vm.hasUserSelectedCategories
                            ? _c("ag-grid-vue", {
                                ref: "grid",
                                staticClass: "ag-theme-custom",
                                staticStyle: { width: "100%", height: "35rem" },
                                attrs: {
                                  "get-row-node-id": _vm.getRowNodeId,
                                  modules: _vm.modules,
                                  "row-data": _vm.rowData,
                                  "column-defs": _vm.columnDefs,
                                  "default-col-def": _vm.defaultColDef,
                                  "status-bar": _vm.statusBar,
                                  "grid-options": _vm.gridOptions,
                                  "suppress-click-edit": true,
                                  "process-cell-from-clipboard":
                                    _vm.processCellFromClipboard,
                                  "process-cell-for-clipboard":
                                    _vm.processCellForClipboard,
                                },
                                on: {
                                  "grid-ready": _vm.onGridReady,
                                  "first-data-rendered": _vm.firstDataRendered,
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("bulk-dialog", {
        ref: "bulk-product-key-dialog",
        attrs: {
          "has-activator": false,
          "promotion-id": _vm.namespace,
          "product-offer-group-id": _vm.productOfferGroup._id,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }