import axios from 'axios';
import { merge } from 'lodash';
import to from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '../../vue-i18n';

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: {
        embedToken: null,
    },

    /**
     * Default getters available:
     */
    getters: {},

    /**
     * Default mutations available:
     */
    mutations: {},

    /**
     * Default actions available:
     *
     * - handleResponseNotifications
     */
    actions: {
        async fetchEmbedToken({ dispatch }) {
            const [error, response] = await to(axios.get('/api/reporting/embedToken'));
            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.t('entities.report'),
                    }),
                });
            }
            return response.data;
        },
    },
};

const mixinParams = {
    resource: 'reporting',
    readOnly: true,
};

export default merge({}, storeMixin(mixinParams), store);
