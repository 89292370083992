var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-container", style: _vm.gridStyle },
    _vm._l(_vm.gridAreaNames, function (area) {
      return _c("page-layout-grid-space", {
        key: `${area}-${_vm.selectedResourceDefinitionKey}`,
        attrs: {
          area: area,
          "initial-area-class": _vm.initialAreaClass,
          "category-key": _vm.getFieldValue(area, "categoryKey"),
          "promotion-id": _vm.getFieldValue(area, "promotionId"),
          products: _vm.getAreaProducts(area, "products"),
          "selected-area-for-promotion-allocation":
            _vm.selectedAreaForPromotionAllocation,
        },
        on: {
          "area-toggled": _vm.toggleAreaSelected,
          "remove-item": _vm.removeItem,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }