<script>
import { mapState, mapMutations } from 'vuex';
import { get, findIndex, set, isNil, cloneDeep, includes, intersection } from 'lodash';
import { enableProductLevelSellInPeriod, showOnlyMinPrices } from '@enums/feature-flags';
import invoiceCalculationTypes from '@enums/invoice-calculation-types';
import inputTypes from '@enums/input-types';
import { toSentenceCase } from '@/js/utils/string-utils';
import {
    isSupplierRow,
    variableFundingCalculations,
    isTotalRow,
    getAllFundingFunctions,
} from '@sharedModules/funding-utils';
import {
    getFieldPathByProductKey,
    formatValueAsCurrency,
} from '@/js/utils/promotion-products-utils';
import validators from '@/js/validators';
import SellInPeriodField from './sell-in-period-field';
import SelectField from './select-field';
import TextField from './input-text-field-simple';
import promoTabsEnum from '@enums/promotion-tabs';

const allInvoiceOptions = Object.values(invoiceCalculationTypes);

export default {
    localizationKey: 'planning.productDetails',
    data() {
        return {
            supplierCategories: [],
            gridOptions: {
                columnTypes: {
                    variableFundingCalculatedField: {
                        sortable: true,
                        cellClass: ['funding-information', 'variable-funding__cell'],
                        cellRendererFramework: TextField,
                        cellRendererParams: {
                            onInputChange: params => {
                                this.setNestedField(params);
                                this.onCellValueChangedNoApportion({
                                    params,
                                    recalculateVariableFunding: true,
                                });
                            },
                            visible: params => {
                                return !isTotalRow(params);
                            },
                            formatter: 'numbers.default.currencyPadded3dp',
                            size: 'large',
                        },
                        validationRules: [
                            {
                                validator: validators.minValue,
                                params: [0],
                            },
                        ],
                        aggFunc: params => {
                            return this.dynamicSupplierAggFunc({
                                supplierKey: get(params.data, 'supplierKey'),
                                categoryKey: get(params.data, 'category'),
                                colDef: params.colDef,
                                productValues: params.values,
                            });
                        },
                    },
                    invoiceValueField: {
                        sortable: true,
                        cellClass: ['funding-information', 'variable-funding__cell'],
                        cellRendererFramework: TextField,
                        cellRendererParams: {
                            onInputChange: params => {
                                this.setNestedField(params);
                                this.onCellValueChangedNoApportion({
                                    params,
                                    recalculateVariableFunding: true,
                                });
                            },
                            visible: params => {
                                return !isTotalRow(params);
                            },
                            fieldType: inputTypes.percentage,
                            suffix: '%',
                        },
                        validationRules: [
                            {
                                validator: validators.minValue,
                                params: [0],
                            },
                            {
                                validator: validators.maxValue,
                                params: [100],
                            },
                        ],
                        aggFunc: params => {
                            return this.dynamicSupplierAggFunc({
                                supplierKey: get(params.data, 'supplierKey'),
                                categoryKey: get(params.data, 'category'),
                                colDef: params.colDef,
                                productValues: params.values,
                            });
                        },
                    },
                    invoiceTypeField: {
                        minWidth: 160,
                        maxWidth: 160,
                        sortable: true,
                        cellClass: ['funding-information', 'variable-funding__cell'],
                        cellRendererFramework: SelectField,
                        cellRendererParams: {
                            onInputChange: params => {
                                this.setNestedField(params);
                                this.onCellValueChangedNoApportion({
                                    params,
                                    recalculateVariableFunding: true,
                                });
                            },
                            visible: params => {
                                return !isTotalRow(params);
                            },
                            getOptions: () => {
                                return [
                                    ...intersection(
                                        allInvoiceOptions,
                                        getAllFundingFunctions(this.featureAwareFactory)
                                    ).map(type => {
                                        return {
                                            name: toSentenceCase(
                                                this.$tkey(`variableFunding.${type}`)
                                            ),
                                            value: type,
                                        };
                                    }),
                                ];
                            },
                            placeholder: toSentenceCase(
                                this.$tkey(`variableFunding.${invoiceCalculationTypes.noInvoice}`)
                            ),
                        },
                        aggFunc: params => {
                            return this.dynamicSupplierAggFunc({
                                supplierKey: get(params.data, 'supplierKey'),
                                categoryKey: get(params.data, 'category'),
                                colDef: params.colDef,
                                productValues: params.values,
                            });
                        },
                    },
                },
            },
        };
    },

    computed: {
        ...mapState('clientConfig', ['generalConfig']),

        fundingFunctionsEditingDisabled() {
            const fundingFunctions = this.featureAwareFactory.getAllCalculateFundingFunctions();
            return Object.keys(fundingFunctions).filter(ff => fundingFunctions[ff].disableEditing);
        },
    },

    methods: {
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        getVariableFundingColumns() {
            const priceFieldFormatter = params => {
                if (isTotalRow(params)) {
                    return '';
                }
                return formatValueAsCurrency(
                    params.value,
                    this.generalConfig.displaySupplierPrices3dp
                );
            };
            return [
                {
                    headerName: toSentenceCase(this.$tkey('headers.variableFunding')),
                    headerGroupComponent: 'variableFundingHeader',
                    headerGroupComponentParams: {
                        promotionId: this.namespace,
                        isPastPromotions: this.isPastPromotions,
                    },
                    groupId: 'variableFunding',
                    openByDefault: false,
                    children: [
                        // this column need to expand functionality is working on ag-greed
                        // ag-grid required columns for both state open and close in other case column group not expand
                        {
                            columnGroupShow: 'close',
                            maxWidth: 0,
                            sortable: false,
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('headers.value')),
                            headerClass: [
                                'funding-information--first-column__left-border variable-funding__cell',
                            ],
                            field: 'funding.variableFunding.unitFundingValue',
                            fieldPath: 'funding.variableFunding',
                            fieldName: 'unitFundingValue',
                            cellClass: [
                                'funding-information',
                                'variable-funding__cell',
                                'funding-information--first-column__left-border',
                            ],
                            cellClassRules: {
                                'negative-unit-funding': this.isNegativeUnitFunding,
                            },
                            minWidth: 150,
                            maxWidth: 150,
                            editable: false,
                            valueFormatter: priceFieldFormatter,
                            valueGetter: params => {
                                // No need to handle supplier rows as this is calculated by the aggFunc.
                                if (isSupplierRow(params)) {
                                    return;
                                }
                                const product = params.data;
                                const volume = get(product, 'volumes.totalVolume', 0);
                                const unitFunding = get(
                                    product,
                                    'funding.variableFunding.unitFundingValue',
                                    0
                                );
                                return unitFunding * volume;
                            },
                            aggFunc: params => {
                                const products = params.values;
                                // Reduce all products down to an object which holds aggregated values, e.g. total variable funding.
                                // Using only one reduce for all aggregations to improve performance.
                                const totalProductsData = products.reduce((acc, productRow) => {
                                    if (!productRow) return acc;
                                    acc += productRow || 0;
                                    return acc;
                                }, 0);

                                return totalProductsData;
                            },
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('variableFunding.originalCost')),
                            field: 'onInvoiceCosts.avgCost',
                            minWidth: 110,
                            maxWidth: 110,
                            cellClass: ['funding-information', 'variable-funding__cell'],
                            valueGetter: params => {
                                if (isSupplierRow(params) || isTotalRow(params)) return '';

                                return priceFieldFormatter({
                                    ...params,
                                    value: get(params, 'data.onInvoiceCosts.avgCost'),
                                });
                            },
                            editable: false,
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('variableFunding.onInvoiceFunction')
                            ),
                            field: 'funding.variableFunding.uiFields.onInvoiceType',
                            fieldPath: 'funding.variableFunding.uiFields',
                            fieldName: 'onInvoiceType',
                            type: 'invoiceTypeField',
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('variableFunding.onInvoiceValue')
                            ),
                            type: 'invoiceValueField',
                            field: 'funding.variableFunding.uiFields.onInvoiceValue',
                            fieldPath: 'funding.variableFunding.uiFields',
                            fieldName: 'onInvoiceValue',
                            cellRendererParams: {
                                isDisabled: params => {
                                    if (isSupplierRow(params)) {
                                        const supplierType = get(
                                            params.node.aggData[
                                                `${params.colDef.fieldPath}.onInvoiceType`
                                            ],
                                            `supplierValue`
                                        );

                                        return (
                                            !supplierType ||
                                            includes(
                                                this.fundingFunctionsEditingDisabled,
                                                supplierType
                                            )
                                        );
                                    }
                                    const productType = get(
                                        params.data,
                                        `${params.colDef.fieldPath}.onInvoiceType`
                                    );

                                    return (
                                        !productType ||
                                        includes(this.fundingFunctionsEditingDisabled, productType)
                                    );
                                },
                            },
                            minWidth: 90,
                            maxWidth: 90,
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('variableFunding.buyingPrice')),
                            type: 'variableFundingCalculatedField',
                            field: 'funding.variableFunding.buyingPrice',
                            fieldPath: 'funding.variableFunding',
                            fieldName: 'buyingPrice',
                            minWidth: 130,
                            maxWidth: 130,
                            valueFormatter: priceFieldFormatter,
                            cellClassRules: this.getCalculatedFieldDynamicClasses({
                                field: 'funding.variableFunding.buyingPrice',
                            }),
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('variableFunding.offInvoiceFunction')
                            ),
                            field: 'funding.variableFunding.uiFields.offInvoiceType',
                            fieldPath: 'funding.variableFunding.uiFields',
                            fieldName: 'offInvoiceType',
                            type: 'invoiceTypeField',
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('variableFunding.offInvoiceValue')
                            ),
                            type: 'invoiceValueField',
                            field: 'funding.variableFunding.uiFields.offInvoiceValue',
                            fieldPath: 'funding.variableFunding.uiFields',
                            fieldName: 'offInvoiceValue',
                            cellRendererParams: {
                                isDisabled: params => {
                                    if (isSupplierRow(params)) {
                                        const supplierType = get(
                                            params.node.aggData[
                                                `${params.colDef.fieldPath}.offInvoiceType`
                                            ],
                                            `supplierValue`
                                        );

                                        return (
                                            !supplierType ||
                                            includes(
                                                this.fundingFunctionsEditingDisabled,
                                                supplierType
                                            )
                                        );
                                    }
                                    const productType = get(
                                        params.data,
                                        `${params.colDef.fieldPath}.offInvoiceType`
                                    );

                                    return (
                                        !productType ||
                                        includes(this.fundingFunctionsEditingDisabled, productType)
                                    );
                                },
                            },
                            minWidth: 90,
                            maxWidth: 90,
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey('variableFunding.supplierCompensation')
                            ),
                            type: 'variableFundingCalculatedField',
                            field: 'funding.variableFunding.supplierCompensation',
                            fieldPath: 'funding.variableFunding',
                            fieldName: 'supplierCompensation',
                            minWidth: 120,
                            maxWidth: 120,
                            valueFormatter: priceFieldFormatter,
                            cellClassRules: this.getCalculatedFieldDynamicClasses({
                                field: 'funding.variableFunding.supplierCompensation',
                            }),
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('variableFunding.sellInPeriod')),
                            field: 'funding.variableFunding.sellInPeriod',
                            fieldPath: 'funding.variableFunding',
                            fieldName: 'sellInPeriod',
                            minWidth: 140,
                            maxWidth: 140,
                            cellClass: params => {
                                if (
                                    !this.toggleLogic[enableProductLevelSellInPeriod] &&
                                    !isSupplierRow(params)
                                ) {
                                    // This column will not render for product cells
                                    return;
                                }

                                return [
                                    'variable-funding__cell',
                                    'funding-information',
                                    'funding-information--last-column',
                                ];
                            },
                            cellRendererFramework: SellInPeriodField,
                            cellRendererParams: {
                                onInputChange: params => {
                                    this.setNestedField(params);
                                    this.onCellValueChangedNoApportion({
                                        params,
                                    });
                                },
                                currencyBackground: () => 'currency-background__primary',
                                visible: params => {
                                    return !isTotalRow(params);
                                },
                            },
                            sortable: false,
                            aggFunc: params => {
                                if (this.toggleLogic[enableProductLevelSellInPeriod]) {
                                    return this.sellInPeriodAggFunc({
                                        supplierKey: get(params.data, 'supplierKey'),
                                        categoryKey: get(params.data, 'category'),
                                        colDef: params.colDef,
                                        productValues: params.values,
                                    });
                                }
                                // Since this cell only exists at supplier level it should display the first product value
                                return params.values[0];
                            },
                            columnGroupShow: 'open',
                        },
                    ],
                },
            ];
        },
        // Dynamic Aggregate Function to handle non-apportioned supplier cells
        sellInPeriodAggFunc({
            supplierKey,
            categoryKey,
            colDef,
            productValues,
            defaultPlaceholder = null,
        }) {
            const { fieldName, fieldPath } = colDef;
            const placeholder = this.$tkey(`symbols.overriddenSupplierPlaceholders.${fieldName}`);
            // Total row contains a hidden cell with an undefined value that needs to be ignored in this validation
            if (includes(productValues, undefined)) {
                productValues.splice(productValues.findIndex(value => value === undefined), 1);
            }

            if (!productValues.length || isNil(supplierKey)) return 0;

            const supplier = productValues[0].supplierValue || {
                daysBefore: null,
                daysAfter: null,
            };
            const placeholders = { daysBefore: null, daysAfter: null };

            ['daysBefore', 'daysAfter'].forEach(value => {
                if (productValues[0][`${value}`] === undefined) return;

                const values = productValues.map(p => p[`${value}`]);
                const { allValuesMatch, supplierValue } = this.checkMatchingProductValues(values);

                supplier[`${value}`] = supplierValue;
                placeholders[`${value}`] = allValuesMatch ? defaultPlaceholder : placeholder;
            });

            // Update supplier field
            const supplierIndex = findIndex(
                this.supplierCategories,
                s => s.supplierKey === supplierKey && s.categoryKey === categoryKey
            );
            set(this.supplierCategories[supplierIndex], `${fieldPath}.${fieldName}`, supplier);

            return {
                supplierValue: supplier,
                placeholders,
            };
        },

        isNegativeUnitFunding(params) {
            return (
                !isNil(get(params.data, 'funding.variableFunding.buyingPrice')) &&
                get(params.data, 'funding.variableFunding.unitFundingValue') < 0
            );
        },
        getCalculatedFieldDynamicClasses({ field }) {
            return {
                'funding-information--calculated__buying-price-greater-than-cost': params => {
                    return (
                        field === 'funding.variableFunding.buyingPrice' &&
                        !isNil(get(params.data, `${field}`)) &&
                        get(params.data, 'onInvoiceCosts.avgCost') - get(params.data, `${field}`) <
                            0
                    );
                },
                'funding-information--calculated__supplier-compensation-greater-than-cost': params => {
                    return (
                        field === 'funding.variableFunding.supplierCompensation' &&
                        !isNil(get(params.data, `${field}`)) &&
                        get(params.data, 'onInvoiceCosts.minCost') - get(params.data, `${field}`) <
                            0
                    );
                },
                'funding-information--calculated__negative-funding': params => {
                    const fieldsAffected =
                        field === 'funding.variableFunding.supplierCompensation' ||
                        field === 'funding.variableFunding.buyingPrice';
                    return (
                        fieldsAffected &&
                        !isNil(get(params.data, `${field}`)) &&
                        get(params.data, `${field}`) < 0
                    );
                },
            };
        },

        // Recalculates variable funding for every product involved on the user action and fires a single update
        // 1) if a product cell gets updated, the funding values for that product will recalculate
        // 2) If a supplier-category cell gets updated, the funding values for all products within that supplier will recalculate
        recalculateVariableFunding({ products, updatedField }) {
            const path = 'funding';
            const fieldName = 'variableFunding';

            const productGridUpdates = [];
            const productFundingUpdates = products.map(data => {
                const {
                    onInvoiceCosts,
                    commercialCosts,
                    funding,
                    standardWeight,
                    promoPrices,
                    nonPromoPrices,
                    productKey,
                    supplierKey,
                    category,
                } = data;
                const newData = cloneDeep(data);

                // Generate new variable funding object based on updated field
                const newVariableFunding = variableFundingCalculations[updatedField].onChange({
                    product: {
                        onInvoiceCosts,
                        commercialCosts,
                        nonPromoPrices,
                        funding,
                        promoPrices,
                        standardWeight,
                    },
                    featureAwareFactory: this.featureAwareFactory,
                    showOnlyMinimumPrices: this.toggleLogic[showOnlyMinPrices],
                });

                set(newData, `${path}.${fieldName}`, {
                    ...get(newData, `${path}.${fieldName}`),
                    ...newVariableFunding,
                });

                this.updateSupplierValues({
                    supplierKey,
                    categoryKey: category,
                    productKey,
                    path,
                    fieldName,
                    fieldValue: newData.funding.variableFunding,
                });
                const productFieldPathInPromo = getFieldPathByProductKey({
                    productKey,
                    path,
                    promotion: this.getStagingAreaPromotionById(this.namespace),
                });
                productGridUpdates.push(newData);

                return {
                    fieldPath: productFieldPathInPromo,
                    fieldName,
                    value: newData.funding.variableFunding,
                };
            });

            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate: productFundingUpdates,
            });
            this.setUnsavedPromotion({
                namespace: this.namespace,
                tab: promoTabsEnum.funding,
                value: true,
            });
            return this.upsertGridData({ updatedItems: productGridUpdates });
        },
    },
};
</script>
