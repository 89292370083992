import { mapActions } from 'vuex';
import {
    flatten,
    get,
    map,
    isNil,
    isObject,
    isFunction,
    has,
    mapValues,
    pick,
    flatMap,
    cloneDeep,
    isEqual,
    isArray,
    isEqualWith,
    differenceWith,
    bind,
} from 'lodash';
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import namespaces from '@enums/namespaces';
import formUtilsMixin from '@/js/mixins/form-utils';
import { componentTypes } from '@enums/custom-components';

// eslint-disable-next-line default-param-last
function equalComparator(ignoringFields = [], value, otherValue) {
    function isEqualWithComparator(v1, v2) {
        // isEqual method returns false, if order of the array elements
        // is not the same. differenceWith ignore the order
        if (isArray(v1) && v1.length > 0) {
            if (v1.length !== v2.length) {
                return false;
            }
            if (isObject(v1[0])) {
                const comparator = bind(equalComparator, null, ignoringFields);
                return differenceWith(v1, v2, comparator).length === 0;
            }
            return differenceWith(v1, v2, isEqual).length === 0;
        }

        if (isObject(v1) || isObject(v2)) {
            const v1WithoutIgnoringFields = { ...v1 };
            const v2WithoutIgnoringFields = { ...v2 };

            const filteredIgnoringFields = ignoringFields.filter(
                ignoringField =>
                    v1WithoutIgnoringFields[ignoringField] || v2WithoutIgnoringFields[ignoringField]
            );

            if (filteredIgnoringFields.length === 0) {
                // if customizer returns undefined, comparisons are handled by isEqual method instead
                return undefined;
            }

            // delete fields that shouldn't be compared
            filteredIgnoringFields.forEach(ignoringField => {
                delete v1WithoutIgnoringFields[ignoringField];
                delete v2WithoutIgnoringFields[ignoringField];
            });
            return isEqualWith(
                v1WithoutIgnoringFields,
                v2WithoutIgnoringFields,
                isEqualWithComparator
            );
        }
        // if customizer returns undefined, comparisons are handled by isEqual method instead
        return undefined;
    }
    return isEqualWith(value, otherValue, isEqualWithComparator);
}

const mixin = {
    mixins: [formUtilsMixin],

    props: {
        context: Object,
        editContext: Object,
        editMode: {
            type: Boolean,
            default: false,
        },
        vuexModule: {
            type: String,
            required: true,
        },
        namespace: {
            type: String,
            default: namespaces.default,
        },
        submitAction: {
            type: String,
        },
        addEvent: {
            type: String,
        },
        editEvent: {
            type: String,
        },
        fields: {
            type: Array,
            required: true,
        },
        isStagingAreaResetNeeded: {
            type: Boolean,
            default: true,
        },
        validation: {
            type: Object,
            default: () => ({}),
        },
        preventDefault: {
            type: Boolean,
            default: false,
        },
        isReactive: {
            type: Boolean,
            default: false,
        },
        resetStagingAreaNamespaceOnCreate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            componentMap: {
                [fieldTypes.text]: 'vuex-text-field',
                [fieldTypes.number]: 'vuex-number-input',
                [fieldTypes.textarea]: 'vuex-textarea',
                [fieldTypes.select]: 'vuex-select',
                [fieldTypes.toggle]: 'vuex-switch',
                [fieldTypes.date]: 'vuex-date-picker',
                [fieldTypes.datePair]: 'vuex-date-pair',
                [fieldTypes.dateRange]: 'vuex-date-range',
                [fieldTypes.checkboxesList]: 'vuex-checkboxes-list',
                [fieldTypes.iconCheckboxesList]: 'vuex-icon-checkboxes-list',
                [fieldTypes.vuexRadioGroup]: 'vuex-radio-group',
                [fieldTypes.comboBox]: 'vuex-combo-box',
                [fieldTypes.currency]: 'vuex-currency-input',
                [componentTypes.expansionPanel]: 'main-expansion-panel',
                [componentTypes.nominationTabs]: 'nomination-tabs',
                [componentTypes.nominationMatrix]: 'nomination-matrix',
                [componentTypes.commitmentMatrix]: 'commitment-matrix',
                [componentTypes.parentPermalink]: 'parent-permalink',
                [componentTypes.scope]: 'scope-editable',
                [componentTypes.featuredProducts]: 'featured-products',
                [componentTypes.detailedProvisions]: 'detailed-provisions',
                [componentTypes.categoriesSelect]: 'categories-select',
            },
        };
    },

    events: {
        onSuccessfulSubmit({ namespace }) {
            if (namespace === this.computedNamespace) {
                this.populateFieldsInStagingArea();
            }
        },
    },

    created() {
        if (this.preventDefault) return;

        // Ensure vuexModule is valid
        if (!this.$store.state[this.vuexModule]) {
            throw new Error(
                `Invalid vuexModule prop specified. No state found at this.$store.state[${
                    this.vuexModule
                }]`
            );
        }

        if (!this.$store.state[this.vuexModule].stagingArea) {
            // FIXME: when we have proper way of error handling on frontend
            throw new Error('The specified vuex module does not have a stagingArea defined');
        }

        // create namespace in staging area
        this.$store.dispatch(`${this.vuexModule}/createStagingAreaNamespace`, {
            namespace: this.computedNamespace,
            reset: this.resetStagingAreaNamespaceOnCreate,
        });

        // when we are in edit mode, we should have the item we're editing as the editContext
        if (this.editMode && !this.editContext) {
            throw new Error('Edit mode is true but no edit context was specified');
        }

        this.populateFieldsInStagingArea();
    },

    computed: {
        computedNamespace() {
            return this.editMode ? this.editContext._id : this.namespace;
        },

        // useful for debugging purposes
        stagingArea() {
            return this.$store.state[this.vuexModule].stagingArea;
        },

        parsedFields() {
            const flattenedFields = flatMap(this.fields, item =>
                item.isContainer ? item.children : item
            );
            return flatten(map(flattenedFields, this.parseFieldDefinition));
        },

        editableFields() {
            return this.parsedFields.filter(field => field.editable).map(field => field.fieldName);
        },
    },

    methods: {
        ...mapActions({
            resetStagingArea(dispatch) {
                return dispatch(`${this.vuexModule}/resetStagingArea`, {
                    namespace: this.computedNamespace,
                });
            },
        }),

        async submit() {
            // If the isReactive flag is set when we are creating a resource it
            // means that data we have sent in the form could be subject to change
            // e.g. when creating promotions we may update the tag filter and
            // want to add the new tag to promotion we are creating
            // If we are creating a resource and this flag is set
            // before submitting the form we repopulate the staging area
            // with the latest data to ensure everything is up to date
            if (this.isReactive && !this.editMode) {
                await this.populateFieldsInStagingArea();
            }
            const { error, result } = await this.submitForm({
                vuexModule: this.vuexModule,
                namespace: this.computedNamespace,
                editMode: this.editMode,
                editableFields: this.editableFields,
                submitAction: this.submitAction,
                postSubmitEvent: this.editMode ? this.editEvent : this.addEvent,
            });
            return { error, result };
        },

        resolveValue(valueDefinition) {
            // check if value is primitive or a hardcoded array/object
            if (!isObject(valueDefinition) || !has(valueDefinition, 'source')) {
                return valueDefinition;
            }

            const supportedSources = {
                // value comes from context
                [sourceTypes.context]: ({ path }) => get(this.context, path),

                // value comes from getter
                [sourceTypes.getter]: ({ identifier, module, params = {} }) => {
                    module = module || this.vuexModule;
                    const getterPath = `${module}/${identifier}`;
                    const getter = this.$store.getters[getterPath];
                    // use isNil in case getter resolves to false
                    if (isNil(getter)) {
                        throw new Error(
                            `Source type 'getter' specified but unable to resolve path to getter: ${getterPath}`
                        );
                    }
                    const getterParams = mapValues(params, this.resolveValue);
                    if (isFunction(getter)) return getter(getterParams);
                    return getter;
                },

                [sourceTypes.contextFunction]: ({ contextFunction, params }) =>
                    contextFunction({ context: this.context, params }),

                [sourceTypes.editContext]: ({ path }) => get(this.editContext, path),
                [sourceTypes.editContextFunction]: ({ editContextFunction, params }) =>
                    editContextFunction({ editContext: this.editContext, params }),
            };

            const { source } = valueDefinition;
            const resolver = supportedSources[source];
            if (!resolver) throw new Error(`Unsupported source specified: ${source}`);
            const resolvedValue = resolver(valueDefinition);
            return resolvedValue;
        },

        /**
         * Not all field definitions are in a flat structure so this function takes a field
         * definition and returns an array of objects with the required fields flattened.
         */
        parseFieldDefinition(fieldDefinition) {
            const requiredFields = [
                'fieldName',
                'fieldPath',
                'editable',
                'defaultValue',
                'dynamicDefaultValue',
            ];

            // map of fieldTypes to parser functions
            const customParsers = {
                [fieldTypes.datePair]: field => [
                    {
                        ...pick(field.from, requiredFields),
                        editable: this.resolveValue(field.editable),
                    },
                    {
                        ...pick(field.to, requiredFields),
                        editable: this.resolveValue(field.editable),
                    },
                ],
                [fieldTypes.dateRange]: field => [
                    {
                        ...pick(field.from, requiredFields),
                        editable: this.resolveValue(field.editable),
                    },
                    {
                        ...pick(field.to, requiredFields),
                        editable: this.resolveValue(field.editable),
                    },
                ],
                [fieldTypes.select]: field => this.parseOptions(field, requiredFields),
                [fieldTypes.comboBox]: field => this.parseOptions(field, requiredFields),
                [fieldTypes.checkboxesList]: field => this.parseOptions(field, requiredFields),
                [fieldTypes.iconCheckboxesList]: field => this.parseOptions(field, requiredFields),
                [componentTypes.nominationMatrix]: field => [
                    this.parseOptions(field.storeGroups, requiredFields),
                    this.parseOptions(field.resources, requiredFields),
                ],
                [componentTypes.nominationTabs]: field => [
                    this.parseOptions(field.storeGroups, requiredFields),
                    this.parseOptions(field.resources, requiredFields),
                    this.parseOptions(field.defaultStoreGroups, requiredFields),
                    this.parseOptions(field.defaultResources, requiredFields),
                ],
            };
            const defaultParser = field => [pick(field, requiredFields)];

            const parser = get(customParsers, fieldDefinition.type, defaultParser);
            return parser(fieldDefinition);
        },

        parseOptions(field, requiredFields) {
            return {
                ...pick(field, ...requiredFields, 'itemValue', 'itemText'),
                editable: this.resolveValue(field.editable),
                options: this.resolveValue(field.options),
            };
        },

        populateFieldsInStagingArea() {
            this.parsedFields.forEach(field => {
                let initialValue = this.getInitialValueForField(field);
                const { fieldName, fieldPath } = field;
                if (fieldPath) {
                    initialValue = {
                        // if we have fieldPath, we need to add existing fields inside this path
                        ...(this.$store.state[this.vuexModule].stagingArea[this.computedNamespace][
                            fieldPath
                        ] || {}),
                        [fieldName]: initialValue,
                    };
                }
                this.$set(
                    this.$store.state[this.vuexModule].stagingArea[this.computedNamespace],
                    fieldPath || fieldName,
                    initialValue
                );
            });
        },

        getInitialValueForField({ fieldName, fieldPath, defaultValue, dynamicDefaultValue }) {
            // when we are in edit mode, we want the initial value to be the current value
            // note this means you can't specify a default value in editMode
            if (this.editMode) {
                if (fieldPath && this.editContext[fieldPath]) {
                    return cloneDeep(this.editContext[fieldPath][fieldName]);
                }
                return cloneDeep(this.editContext[fieldName]);
            }

            // allow us to specify a computed default value
            if (dynamicDefaultValue) {
                return this.resolveValue(dynamicDefaultValue);
            }

            // default to hardcoded defaultValue (or undefined)
            return cloneDeep(defaultValue);
        },

        isDisabledField({ editable }) {
            return this.editMode && !editable;
        },

        getVuexComponent(fieldType) {
            if (!fieldType) {
                return undefined;
            }
            return this.componentMap[fieldType];
        },

        // Function that goes through each of the fields passed in and returns whether those fields
        // in the form still have all the default values or if they now differ.
        checkAllFieldsHaveDefaultValues(fieldNames) {
            // for a new item all fields should be set as default
            if (!this.editMode) {
                return true;
            }

            return this.parsedFields.every(({ fieldName, defaultValue, dynamicDefaultValue }) => {
                if (fieldNames.indexOf(fieldName) !== -1) {
                    const fieldDefaultValue = dynamicDefaultValue
                        ? this.resolveValue(dynamicDefaultValue)
                        : defaultValue;

                    const fieldValue = this.editContext[fieldName];

                    // To compare current value and default value we need
                    // to ignore 'key' prop in resources
                    const ignoringFields = fieldName === 'resources' ? ['key'] : [];
                    const comparator = bind(equalComparator, null, ignoringFields);

                    // if array with difference is not empty return false
                    // need to inspect both fieldDefaultValue and fieldValue arrays
                    return (
                        differenceWith(fieldDefaultValue, fieldValue, comparator).length === 0 &&
                        differenceWith(fieldValue, fieldDefaultValue, comparator).length === 0
                    );
                }
                return true;
            });
        },
    },

    destroyed() {
        if (this.isStagingAreaResetNeeded) {
            this.resetStagingArea();
        }
    },
};

export default mixin;
