<template functional>
    <svg
        width="16px"
        height="12px"
        viewBox="0 0 16 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Workflow arrow</title>
        <g id="simple-UPDATE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Workflow-4-1-A-01"
                transform="translate(-1297.000000, -532.000000)"
                fill="#C13939"
            >
                <g id="Group" transform="translate(1297.000000, 530.000000)">
                    <g id="Group-31" transform="translate(0.000000, 2.000000)">
                        <polygon
                            id="Triangle"
                            transform="translate(12.000000, 6.000000) rotate(-270.000000) translate(-12.000000, -6.000000) "
                            points="12 2 17.5 10 6.5 10"
                        />
                        <rect id="Rectangle" x="0" y="4.5" width="8" height="3" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'WorkflowArrow',
};
</script>
