import maxValue from './maxValue';
import maxLength from './maxLength';
import minValue from './minValue';
import required from './required';
import dateRangeRequired from './dateRangeRequired';
import numericString from './numericString';
import greaterThanZero from './greaterThanZero';

const validators = {
    maxValue,
    maxLength,
    minValue,
    required,
    dateRangeRequired,
    numericString,
    greaterThanZero,
};

export default validators;
