<template functional>
    <svg
        width="17px"
        height="16px"
        viewBox="0 0 17 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Leaflet</title>
        <g id="------Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icons" transform="translate(-355.000000, -190.000000)">
                <g id="leaflet" transform="translate(355.333239, 190.000000)">
                    <rect id="Rectangle" x="0" y="0" width="16" height="16" />
                    <path
                        id="Colour"
                        d="M5.72501065,1.88370588 L1.31501067,3.45870588 C1.13147592,3.52361005 0.99903417,3.7119202 1.00000531,3.9065965 L1.00000531,13.6715934 C1.00158567,13.9716624 1.34707767,14.2145589 1.63001067,14.1145621 L5.88251065,12.5937106 L10.1350107,14.1145621 C10.2359367,14.1502359 10.3490847,14.1502359 10.4500107,14.1145621 L14.8600107,12.5395621 C15.0420019,12.4752076 15.1739712,12.2896254 15.175016,12.0965934 L15.175016,2.3315965 C15.1765857,2.0298832 14.8294872,1.78317048 14.5450107,1.88370588 L10.2925107,3.40456525 L6.04001065,1.88370588 C5.92783915,1.8444679 5.80520965,1.8567781 5.72501065,1.88370588 Z M5.41001065,2.99604963 L5.41001065,11.7619059 L1.94501065,12.9973044 L1.94501065,4.23636213 L5.41001065,2.99604963 Z M6.35501065,2.99604963 L9.82001065,4.23636213 L9.82001065,13.0022184 L6.35501065,11.7619059 L6.35501065,2.99604963 Z M14.2300107,3.00096363 L14.2300107,11.7619059 L10.7650107,13.0022184 L10.7650107,4.23635425 L14.2300107,3.00096363 Z"
                        fill="#2F477C"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Leaflet',
};
</script>
