<template>
    <div class="health-sidebar">
        <h1 class="health-sidebar__title font-weight-bold">
            {{ $t('planning.healthSidebar.title') | toSentenceCase }}
        </h1>
        <div class="health-sidebar__title-row">
            <h4>{{ $t('planning.healthSidebar.incrementalSales') | toSentenceCase }}</h4>
            <h4>{{ $t('planning.healthSidebar.incrementalMargin') | toSentenceCase }}</h4>
        </div>
        <p class="health-sidebar__message">{{ $t('planning.healthSidebar.message') }}</p>
        <v-divider />

        <div class="summary-container">
            <div class="summary-container__promo-metric-item">
                <health-sidebar-kpi-item :kpi="getYearToDateAggregatedMetrics.incrementalSales" />
            </div>
            <div class="summary-container__promo-metric-item">
                <health-sidebar-kpi-item :kpi="getYearToDateAggregatedMetrics.incrementalMargin" />
            </div>
            <div
                class="summary-container__drop-down-item health-sidebar__expand-kpis promo-metric__drop-down"
            >
                <main-expand-button :is-expanded="isExpanded" background @expand="toggleKpis" />
            </div>
        </div>

        <div v-if="isExpanded" class="health-sidebar__details">
            <v-divider />
            <div class="health-sidebar__details-row">
                <h4 class="health-sidebar__details-column text-right">
                    {{ getYearToDateAggregatedMetrics.incrementalSales.delta | totalSales }}
                </h4>
                <h4 class="health-sidebar__details-column text-left">
                    {{ getYearToDateAggregatedMetrics.incrementalMargin.delta | totalMargin }}
                </h4>
                <div>
                    <v-icon size="15">change_history</v-icon>
                </div>
            </div>
            <v-divider />
            <div class="health-sidebar__details-row">
                <div class="health-sidebar__details-column text-right">
                    {{ $t('planning.healthSidebar.sales') | toSentenceCase }}:
                </div>
                <div class="health-sidebar__details-column text-left">
                    {{ $t('planning.healthSidebar.margin') | toSentenceCase }}:
                </div>
            </div>
            <div class="health-sidebar__details-row">
                <h4 class="health-sidebar__details-column text-right">
                    {{ getYearToDateAggregatedMetrics.promoSales.value | totalSales }}
                </h4>
                <h4 class="health-sidebar__details-column text-left">
                    {{ getYearToDateAggregatedMetrics.promoMargin.value | totalMargin }}
                </h4>
            </div>
            <div class="health-sidebar__details-row">
                <h4 class="health-sidebar__details-column text-right">
                    {{
                        $n(
                            'numbers.default.percentageEvolution',
                            getYearToDateAggregatedMetrics.promoSales.efficiency
                        )
                    }}
                    <i
                        :class="
                            getEfficiencyArrowClass(
                                getYearToDateAggregatedMetrics.promoSales.efficiency
                            )
                        "
                    />
                </h4>
                <h4 class="health-sidebar__details-column text-left">
                    {{
                        $n(
                            'numbers.default.percentageEvolution',
                            getYearToDateAggregatedMetrics.promoMargin.efficiency
                        )
                    }}
                    <i
                        :class="
                            getEfficiencyArrowClass(
                                getYearToDateAggregatedMetrics.promoMargin.efficiency
                            )
                        "
                    />
                </h4>
            </div>

            <v-divider />
            <div class="health-sidebar__details-row">
                <h4 class="health-sidebar__details-column text-right">
                    {{ getYearToDateAggregatedMetrics.promoSales.delta | totalSales }}
                </h4>
                <h4 class="health-sidebar__details-column text-left">
                    {{ getYearToDateAggregatedMetrics.promoMargin.delta | totalMargin }}
                </h4>
                <v-icon size="15">change_history</v-icon>
            </div>
            <v-divider />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getArrowClass } from '../../utils/health-sidebar-utils';

export default {
    localizationKey: 'planning.plannerView',
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        ...mapGetters('promotions', ['getYearToDateAggregatedMetrics']),
    },
    methods: {
        toggleKpis() {
            this.isExpanded = !this.isExpanded;
            this.globalEmit('summary-kpis-toggled');
        },
        getEfficiencyArrowClass(efficiency) {
            return getArrowClass({ kpi: efficiency });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.health-sidebar {
    width: 100%;
    height: 100%;
    padding: 1rem;

    font-size: 1.2rem;
    background-color: $promo-white;

    &__expand-kpis {
        margin-top: 1rem;
        float: right;
    }

    &__title {
        font-size: 1.4rem;

        &-row {
            display: flex;
            margin: 0.5rem 0;

            h4 {
                width: 7rem;

                &:first-child {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    &__message {
        margin-bottom: 0.5rem;
    }

    &__details {
        width: 17rem;
        padding-top: 1rem;
        text-align: center;

        &-row {
            display: flex;
        }

        &-column {
            width: 7rem;
            padding: 0.2rem 1rem;

            .fa-arrow-up {
                color: $rtls-postive-action-colour;
            }

            .fa-arrow-down {
                color: $rtls-negative-action-colour;
            }
        }
    }
}

.summary-container {
    display: flex;

    &__promo-metric-item {
        width: 6.5rem;
        white-space: nowrap;
        margin-right: 1rem;
    }

    &__drop-down-item {
        flex: 1 0 0;
    }
}

.promo-metric {
    &__drop-down {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}
</style>
