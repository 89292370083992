var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main-dialog",
    {
      ref: "dialog",
      attrs: { heading: _vm.labels.heading, "button-text": _vm.labels.btnText },
      on: { confirm: _vm.save, close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function ({ activator }) {
            return [
              _c("resource-actions", {
                attrs: {
                  "add-button": _vm.addBtn,
                  "edit-button": _vm.editMode,
                  "button-text": _vm.labels.btnText,
                  "has-border": _vm.labels.hasBorder,
                  activator: activator,
                },
              }),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "vuex-form",
        _vm._b({ ref: "form" }, "vuex-form", _vm.vBindObj, false)
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }