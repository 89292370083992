<template>
    <v-container class="icon-checkboxes-list-container" :class="cssClass" fluid>
        <p v-if="label" class="checkboxes-list__label">
            {{ $t(label) | toSentenceCase }}
            <span v-if="isRequired" class="asterisk">*</span>
        </p>
        <v-container class="icon-checkboxes-list-container__control">
            <template v-for="(option, index) in getOptions">
                <v-tooltip
                    :key="`tooltip::${getKey(option, index)}`"
                    z-index="400"
                    bottom
                    :max-width="200"
                    :disable="hideTooltip"
                >
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            <v-checkbox
                                :key="`checkbox::${getKey(option, index)}`"
                                v-model="model"
                                class="rtls-checkbox"
                                :multiple="true"
                                :value="getValue(option)"
                                :disabled="isDisabled({ option })"
                                :on-icon="'$' + option.reference[itemValue]"
                                :off-icon="'$' + option.reference[itemValue]"
                                :rules="rules"
                                :hide-details="index !== 0"
                            />
                        </span>
                    </template>
                    <span>{{ option.reference[itemText] | toSentenceCase }}</span>
                </v-tooltip>
            </template>
        </v-container>
    </v-container>
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';
import vuexCheckboxList from '../../mixins/vuex-checkbox-list';

export default {
    mixins: [vuexComponentMixin, vuexCheckboxList],

    props: {
        hideTooltip: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        getOptions() {
            return this.parsedField ? this.parsedField.options : this.options;
        },
    },

    methods: {
        isDisabled({ option }) {
            return this.disabled || this.isOptionDisabled(option) || this.isReadOnly;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.icon-checkboxes-list-container {
    .checkboxes-list__label {
        font-size: 1.2rem;
        font-weight: 600;
        padding-bottom: 0.8rem;
    }

    &::v-deep .v-input--checkbox {
        &.rtls-checkbox {
            .v-input--selection-controls__input {
                width: 2.4rem;
                height: 2.4rem;
                border: 0.1rem solid $promo-primary-colour;
                @include icon-border-radius;

                svg {
                    height: 1.4rem;
                    width: 1.4rem;
                    bottom: 0.4rem;
                    left: 0.4rem;
                    position: absolute;
                }

                input {
                    bottom: 0.1rem;
                    z-index: 1;
                    height: 2.5rem !important;
                    width: 2.5rem !important;
                }
            }
            &.v-input--is-disabled {
                .v-input--selection-controls__input {
                    border-color: $promo-grey-7;
                    background-color: $promo-white;

                    svg path {
                        fill: $promo-grey-7;
                    }
                }
            }

            &.error--text {
                .v-input--selection-controls__input {
                    border-color: $vuetify-error-red;

                    svg path {
                        fill: $vuetify-error-red;
                    }
                }
            }
        }

        &.v-input--is-label-active {
            .v-input--selection-controls__input {
                background-color: $promo-primary-colour;

                svg path {
                    fill: $promo-white;
                }
            }

            &.v-input--is-disabled {
                .v-input--selection-controls__input {
                    background-color: $promo-grey-7;

                    svg path {
                        fill: $promo-white;
                    }
                }
            }
        }
    }
}
</style>
