import featureFlagEnums from '@enums/feature-flags';
import routeNames from '@enums/route-names';
import PreparationPage from './preparation';
import guardsMap from '@/js/pages/preparation/preparation-guard-map';

const defaultBeforeEnter = async (to, from, next) => {
    await guardsMap[to.name](to, from, next);
};

const routes = [
    {
        path: '/preparation',
        id: 'preparation',
        featureToggle: 'canAccessPreparation',
        component: PreparationPage,
        linkText: 'navBar.preparation',
        index: 1,
        meta: { canAccess: featureFlagEnums.canAccessPreparation, isSideBarCalendarExpanded: true },
        beforeEnter(to, from, next) {
            next();
        },
        children: [
            // Default route to ensure that all '/preparation' requests are redirected to the preparationAllCampaignsView.
            {
                path: '',
                name: 'preparation',
                redirect: { name: routeNames.preparationAllCampaignsView },
            },
            {
                path: 'allCampaignsView',
                name: routeNames.preparationAllCampaignsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'singleCampaignView/:campaignId',
                name: routeNames.preparationSingleCampaignView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'singleSubCampaignView/:subCampaignId',
                name: routeNames.preparationSingleSubCampaignView,
                beforeEnter: defaultBeforeEnter,
            },
        ],
    },
];

export default routes;
