<template>
    <div class="scenario-sidebar flex-row">
        <div class="scenario-sidebar__metrics flex-grow flex-row">
            <div class="flex-grow">
                <losenge-monetary-display
                    :incremental-amount="metrics.incrementalSalesExcTax"
                    :efficiency="metrics.salesEfficiency"
                    :compact="compact"
                    class="margin-right flex-grow"
                />
            </div>
            <div class="flex-grow">
                <losenge-monetary-display
                    :incremental-amount="metrics.incrementalMargin"
                    :efficiency="metrics.marginEfficiency"
                    :compact="compact"
                    class="flex-grow"
                />
            </div>
        </div>
        <div class="scenario-sidebar__icons flex-shrink flex-row">
            <workflow-state
                v-if="generalConfig.showWorkflowDebugIcon"
                :entity-id="scenario._id"
                :workflow-entity="workflowEntities.scenario"
            />

            <promotion-copy-dialog
                ref="campaign_copy_form_dialog"
                :key="`copyPromotionDialog::${scenario._id}`"
                :promotions="promotions"
            />

            <feature-toggle :toggle="canDeleteScenario">
                <scenario-delete-dialog
                    v-if="!(generalConfig.oneScenarioForAllCategories && scenario.isFavourite)"
                    :resource="scenariosEnum"
                    :resource-id="scenario._id"
                    :edit-context="scenario"
                />
            </feature-toggle>

            <main-expand-button class="scenario-sidebar__expand-btn" :is-expanded="isExpanded" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import workflowEntities from '@enums/workflow-entities';
import { scenarios } from '@enums/resources';
import { canDeleteScenario } from '@enums/feature-flags';
import scenarioDeleteDialog from '@/js/components/scenario-delete-dialog';
import aggregationUtils from '@/js/utils/forecasting/aggregation-utils';

export default {
    components: { scenarioDeleteDialog },
    props: {
        isExpanded: {
            required: true,
            type: Boolean,
        },
        compact: {
            required: false,
            default: false,
        },
        scenario: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            workflowEntities,
            canDeleteScenario,
            scenariosEnum: scenarios,
        };
    },
    computed: {
        ...mapState('clientConfig', ['generalConfig']),
        ...mapGetters('promotions', ['getPromotionsByScenarioId']),
        ...mapGetters('scenarios', ['scenariosInCategoryGroup']),

        promotions() {
            return this.getPromotionsByScenarioId(this.scenario._id);
        },

        metrics() {
            const promotionForecasts = this.promotions.map(promotion => {
                const forecastingAggregations = promotion.forecastingAggregations || {};
                const promotionAggregations = forecastingAggregations.promotion || {};

                return promotionAggregations;
            });

            const scenarioAggregations = aggregationUtils.aggregateForecastMetrics({
                forecastMetrics: promotionForecasts,
            });

            return scenarioAggregations;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';

.margin-right {
    margin-right: 0.7rem;
}

.flex-grow {
    @include flex-grow;
}

.flex-shrink {
    @include flex-shrink;
}

.scenario-sidebar {
    &__metrics {
        line-height: 1.8rem;
    }

    &__icons {
        align-items: flex-start;

        ::v-deep > * {
            padding: 0 0.2rem;
            height: 1.8rem;
        }
    }

    &__expand-btn::v-deep {
        .expand-btn__icon {
            height: 1.8rem;
        }
    }
}
</style>
