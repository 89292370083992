<template>
    <div class="area" :class="[computedAreaClass]" :style="{ gridArea: area }">
        <div v-if="isTemplatesSection" class="area__inner">{{ area }}</div>
        <div
            v-else
            class="area__inner--category"
            :class="{ 'min-height': !isSlotsResource }"
            @click="toggleSelection"
        >
            <div
                v-if="isAssignmentArea"
                class="area__inner--category-active"
                :class="{
                    'non-allocated': categoryKey === categoryAllocationsTypes.nonAllocated,
                    'area-highlighted': isHighlighted && !isDisabled,
                    'area__inner--category-active-in-promotions-tab':
                        isAreaSelectedForPromotionAllocation && !isDisabled,
                    'min-height': !isSlotsResource,
                }"
            >
                <div
                    v-if="categoryKey === categoryAllocationsTypes.nonAllocated"
                    class="assignments-container"
                >
                    <span class="category-label">{{
                        $t('preparation.allocation.nonAllocated') | toSentenceCase
                    }}</span>
                </div>
                <div
                    v-if="
                        categoryKey !== categoryAllocationsTypes.nonAllocated ||
                            autoAssignedCategory
                    "
                    class="assignments-container"
                >
                    <span v-if="autoAssignedCategory" class="category-label">
                        <v-tooltip v-if="isCategoryWidePromotion" z-index="300" top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    {{ autoAssignedCategory }}
                                </span>
                            </template>

                            <span>{{ categoriesForCategoryWideText }}</span>
                        </v-tooltip>
                        <span v-else> {{ autoAssignedCategory }} </span>
                    </span>
                    <span v-if="isPagesResource && !autoAssignedCategory" class="category-label">
                        {{ categoryName }}
                        <span v-if="categoryMismatch" class="category-warning">
                            <icon class="category-warning--icon" icon-name="warning" />
                            <span class="category-warning--text">
                                {{ $t('preparation.allocation.categoryMismatch') | toSentenceCase }}
                            </span>
                        </span>
                    </span>
                    <span v-if="isPromotionsSection && promotion" class="promotion">
                        <promotion-rag-colour
                            class="promotion__rag"
                            :colour="promotion.effectivenessRating"
                        />
                        <span v-if="isStoreWidePromotion" class="promotion__products">
                            {{ $t('preparation.allocation.storeWide') }}
                        </span>
                        <span v-if="isCategoryWidePromotion" class="promotion__products">
                            {{ $t('preparation.allocation.categoryWide') }}
                        </span>
                        <span v-else class="promotion__products">
                            {{
                                $tc(
                                    'preparation.allocation.products',
                                    promotion.products ? promotion.products.length : 0
                                )
                            }}
                        </span>
                        <!--added promotion name and offer mechanic description-->
                        <span class="promotion__name">{{ promotion.name }}</span>
                        <span
                            v-if="promotion.offerMechanic && promotion.offerMechanic.description"
                            class="promotion__offer-mechanic"
                        >
                            {{ promotion.offerMechanic.description }}
                        </span>
                    </span>
                    <div v-if="products && products.length" class="promotion__assigned-products">
                        {{ $tc('preparation.allocation.products', products.length) }}
                    </div>
                </div>

                <feature-toggle :toggle="canEditChannel">
                    <v-btn
                        v-show="isRemoveButtonVisible"
                        x-small
                        primary
                        depressed
                        max-width="2rem"
                        min-width="2rem"
                        :disabled="isRemoveButtonDisabled"
                        class="ml-2 category-btn"
                        @click.stop="removeItem"
                    >
                        <revert />
                    </v-btn>
                </feature-toggle>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import categoryAllocationsTypes from '@enums/category-allocations-types';
import { canEditChannel } from '@enums/feature-flags';
import pogScope from '@enums/product-offer-group-scope';
import {
    isStoreWidePromotion,
    isCategoryOrStoreWidePromotion,
    isCategoryWidePromotion,
} from '@sharedModules/promotion-utils';
import { isNil, includes, get, size } from 'lodash';
import { toSentenceCase } from '../../../../utils/string-utils';

export default {
    props: {
        area: {
            type: String,
            required: true,
        },
        initialAreaClass: {
            type: String,
            default: 'area-simple',
        },
        categoryKey: {
            type: [Number, String],
            default: null,
        },
        promotionId: {
            type: String,
            default: null,
        },
        products: {
            type: Array,
        },
        selectedAreaForPromotionAllocation: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            selected: false,
            categoryAllocationsTypes,
            canEditChannel,
        };
    },
    computed: {
        ...mapState('subCampaigns', ['highlightedCategory']),
        ...mapGetters('subCampaigns', [
            'isTemplatesSection',
            'isCategoriesSection',
            'isPromotionsSection',
            'isSlotsResource',
            'isPagesResource',
        ]),
        ...mapGetters('context', ['userCategoriesKeyMap']),
        ...mapGetters('promotions', ['getPromotionById']),
        ...mapGetters('products', ['getProductsCategories']),
        computedAreaClass() {
            if (this.selected && this.isCategoriesSection && !this.isReadOnly) {
                return 'area-selected';
            }
            return this.initialAreaClass;
        },
        isHighlighted() {
            return this.isAutoAssignedCategory
                ? includes(this.autoAssignedCategoryKeys, this.highlightedCategory)
                : this.highlightedCategory === this.categoryKey;
        },
        isAreaSelectedForPromotionAllocation() {
            return (
                this.selectedAreaForPromotionAllocation === this.area && this.isPromotionsSection
            );
        },

        categoriesForCategoryWideText() {
            return this.categoryWidePog.userSelectedCategories
                .map(c => c.levelEntryDescription)
                .join(', ');
        },

        categoryName() {
            if (this.isPromotionsSection && this.categoryKey === null) {
                return toSentenceCase(this.$t('preparation.allocation.notAssigned'));
            }
            return this.getUserCategoryName({ categoryKey: this.categoryKey });
        },

        promotion() {
            return this.getPromotionById(this.promotionId);
        },

        isRemoveButtonVisible() {
            // firstly check user permissions to current area
            return (
                this.hasAccessToArea(this.categoryKey) &&
                ((this.isCategoriesSection && this.isPagesResource) ||
                    (this.isPromotionsSection && this.promotion))
            );
        },

        isAssignmentArea() {
            return (
                (this.isCategoriesSection && (this.categoryKey || this.autoAssignedCategory)) ||
                this.isPromotionsSection ||
                this.isSlotsResource
            );
        },
        isAutoAssignedCategory() {
            return this.isPagesResource && this.promotionId && !this.categoryKey;
        },
        autoAssignedCategoryKeys() {
            if (this.isAutoAssignedCategory) {
                const productCategories = this.getProductsCategories(
                    get(this.promotion, 'products', [])
                );
                return productCategories && productCategories.length ? productCategories : null;
            }

            return null;
        },
        autoAssignedCategory() {
            if (this.isAutoAssignedCategory) {
                if (this.autoAssignedCategoryKeys && this.autoAssignedCategoryKeys.length > 1) {
                    return toSentenceCase(this.$t('preparation.allocation.multipleCategories'));
                }

                const categoryKey =
                    this.autoAssignedCategoryKeys && this.autoAssignedCategoryKeys.length
                        ? this.autoAssignedCategoryKeys[0]
                        : null;

                return this.getUserCategoryName({ categoryKey });
            }

            return null;
        },
        categoryMismatch() {
            const productCategories = this.getProductsCategories(
                get(this.promotion, 'products', [])
            );
            const autoAssignedCategoryKeys =
                productCategories && productCategories.length ? productCategories : null;
            if (autoAssignedCategoryKeys) {
                return !autoAssignedCategoryKeys.includes(this.categoryKey);
            }
            return false;
        },
        isRemoveButtonDisabled() {
            return this.isReadOnly || (this.isCategoriesSection && !!this.isAutoAssignedCategory);
        },
        isDisabled() {
            return this.isReadOnly;
        },

        isStoreWidePromotion() {
            return isStoreWidePromotion(this.promotion);
        },

        isCategoryWidePromotion() {
            return isCategoryWidePromotion(this.promotion);
        },

        isCategoryOrStoreWidePromotion() {
            return isCategoryOrStoreWidePromotion(this.promotion);
        },

        categoryWidePog() {
            return this.promotion.productOfferGroups.find(
                pog => pog.scope === pogScope.categoryWide
            );
        },
    },
    methods: {
        ...mapMutations('subCampaigns', ['setHighlightedCategory']),
        toggleSelection() {
            if (this.isCategoriesSection) {
                this.setHighlightedCategory(null);
                if (
                    this.categoryKey ||
                    (this.autoAssignedCategoryKeys && this.autoAssignedCategoryKeys.length)
                ) {
                    return false;
                }
            }

            const hasPermissionsToSpace =
                isNil(this.categoryKey) ||
                (this.userCategoriesKeyMap && this.userCategoriesKeyMap[this.categoryKey]);
            // A user should only be able to toggle areas where a category has been assigned,
            // that they have permission to see
            if (this.isCategoriesSection || (this.isPromotionsSection && hasPermissionsToSpace)) {
                this.selected = !this.selected;
                this.$emit('area-toggled', this.area);
            }
        },
        hasAccessToArea(categoryKey) {
            return (
                categoryKey === null ||
                categoryKey === categoryAllocationsTypes.nonAllocated ||
                (this.userCategoriesKeyMap && this.userCategoriesKeyMap[categoryKey])
            );
        },
        removeItem() {
            this.$emit('remove-item', this.area);
        },

        categoryWideMessage() {
            const numberOfOtherUserSelectedCategories =
                size(this.categoryWidePog.userSelectedCategories) - 1;
            return `${this.categoryWidePog.userSelectedCategories[0].levelEntryDescription} ${
                numberOfOtherUserSelectedCategories
                    ? this.$t('preparation.allocation.execessCategories', {
                          number: numberOfOtherUserSelectedCategories,
                      })
                    : ''
            }`;
        },

        getUserCategoryName({ categoryKey }) {
            if (isStoreWidePromotion(this.promotion)) {
                return toSentenceCase(this.$t('preparation.allocation.storeWide'));
            }
            if (isCategoryWidePromotion(this.promotion)) {
                return this.categoryWideMessage();
            }
            return this.userCategoriesKeyMap && this.userCategoriesKeyMap[categoryKey]
                ? this.userCategoriesKeyMap[categoryKey].levelEntryDescription
                : toSentenceCase(this.$t('preparation.allocation.restrictedAllocatedPromo'));
        },
    },
    events: {
        onSetCategory() {
            this.selected = false;
        },
        onActiveDocumentSectionUpdated() {
            this.selected = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.area {
    color: $promo-white;
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__inner {
        &--category {
            width: 100%;
            height: 100%;
        }

        &--category-active {
            width: 100%;
            height: 100%;
            background: $promo-white;
            border: 0.4rem solid $promo-campaign-border-3;
            position: relative;
            overflow: hidden;

            .assignments-container {
                position: absolute;
                top: 1rem;
                left: 1rem;
                width: 90%;
                height: 80%;
                color: $promo-text-colour;
                font-size: 1.2rem;

                .category-label {
                    font-weight: 600;
                }
                .category-warning {
                    color: $promo-error;
                    padding-left: 1rem;

                    ::v-deep .v-icon {
                        padding-bottom: 0.3rem;
                    }

                    &--text {
                        font-weight: 600;
                        padding-left: 0.2rem;
                    }
                }
                .promotion {
                    display: flex;
                    flex-wrap: wrap;

                    &__products {
                        padding: 0 1rem;
                    }

                    &__name {
                        font-weight: 600;
                    }

                    &__offer-mechanic {
                        font-weight: 600;
                        width: 100%;
                    }

                    &__assigned-products {
                        font-weight: 600;
                        text-align: center;
                        margin-top: 1.5rem;
                    }
                }
            }

            .category-btn {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
            }
        }

        &--category-active-in-promotions-tab {
            border-color: $promo-light-blue;
        }
    }
}

.non-allocated {
    background: $non-allocated-bg-colour;
    border: none;
}

.area-simple {
    background-color: $paramsFormBackgrounColor;
}

.area-saved {
    background-color: $promo-grey-blue-tint;
}

.area-selected {
    background-color: $promo-product-badge;
}

.area-highlighted {
    background-color: $highlighted-category-bg-colour;
}
</style>
