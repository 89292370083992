import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.chipsToDisplay, function (chip) {
        return _c(
          VChip,
          {
            key: _vm.field ? chip[_vm.field] : chip,
            staticClass: "pt-0 mt-0 mr-1",
            attrs: { small: "" },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.field ? chip[_vm.field] : chip) +
                "\n    "
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.hiddenItems.length
        ? _c(
            VTooltip,
            {
              attrs: { "z-index": "200", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          VChip,
                          _vm._g(
                            _vm._b(
                              { attrs: { small: "" } },
                              "v-chip",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("general.labels.plusXMore", {
                                    count: _vm.hiddenItems.length,
                                  })
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1470213731
              ),
            },
            [
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "simple-list" },
                _vm._l(_vm.hiddenItems, function (item, hiddenIndex) {
                  return _c(
                    "li",
                    {
                      key: "h-item" + hiddenIndex,
                      staticClass: "simple-list__item",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.field ? item[_vm.field] : item) +
                            "\n                "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }