<template>
    <div v-if="isOnSupplyTab" class="supply-tab">
        <supply-grid
            :vuex-module="vuexModule"
            field-name="products"
            :namespace="computedNamespace"
            :form-ref="formRef"
        />
    </div>
</template>

<script>
import vuexFormMixin from '@/js/mixins/vuex-form';
import { supply } from '@enums/promotion-tabs';
import { mapState } from 'vuex';

export default {
    mixins: [vuexFormMixin],
    props: {
        formRef: {
            type: Object,
            required: false,
        },
        cacheDom: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('promotions', ['selectedPromotionMaintenanceTab']),
        isOnSupplyTab() {
            return this.cacheDom || this.selectedPromotionMaintenanceTab === supply;
        },
    },
};
</script>

<style lang="scss" scoped></style>
