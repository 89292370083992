export default [
    {
        nameKey: 'volumeUplift',
        field: 'volumeUplift',
        numberFormatter: {
            type: 'forecastedVolume',
        },
    },
    {
        nameKey: 'totalVolume',
        field: 'totalVolume',
        numberFormatter: {
            type: 'forecastedVolume',
        },
    },
    {
        nameKey: 'promotionalVolume',
        field: 'promotionalVolume',
        numberFormatter: {
            type: 'forecastedVolume',
        },
        releaseFlag: 'mixedMarginRefactor',
    },
    {
        isDivider: true,
    },
    {
        nameKey: 'actualSalesExcTax',
        field: 'actualSalesExcTax',
        numberFormatter: {
            type: 'forecastedSales',
        },
    },
    {
        nameKey: 'promotionalSales',
        field: 'discountedSalesExcTax',
        numberFormatter: {
            type: 'forecastedSales',
        },
        releaseFlag: 'mixedMarginRefactor',
    },
    {
        isDivider: true,
    },
    {
        nameKey: 'discountRate',
        field: 'discountRate',
        numberFormatter: {
            type: 'efficiencyPercentage',
        },
    },
    {
        nameKey: 'salesDiscountExcTax',
        field: 'salesDiscountExcTax',
        numberFormatter: {
            type: 'forecastedSales',
            options: {
                useAbsoluteValue: true,
            },
        },
    },
    {
        nameKey: 'discountExcTaxFundedRate',
        field: 'discountExcTaxFundedRate',
        numberFormatter: {
            type: 'efficiencyPercentage',
        },
    },
    {
        nameKey: 'promoFunding',
        field: 'promoFunding',
        numberFormatter: {
            type: 'forecastedMargin',
        },
    },
    {
        isDivider: true,
    },
    {
        nameKey: 'nonPromoMarginRate',
        field: 'nonPromoMarginRate',
        numberFormatter: {
            type: 'marginPercentage',
        },
    },
    {
        nameKey: 'mixedMarginRate',
        field: 'promoMarginRate',
        numberFormatter: {
            type: 'marginPercentage',
        },
    },
    {
        nameKey: 'promoMarginRateExclMixedMarginRate',
        field: 'promoMarginRateExclMixedMarginRate',
        numberFormatter: {
            type: 'marginPercentage',
        },
        releaseFlag: 'mixedMarginRefactor',
    },
];
