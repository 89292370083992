<template>
    <div class="product-offer-group">
        <v-expansion-panel v-if="productOfferGroup" :key="index" class="product-offer-group__panel">
            <v-expansion-panel-header
                class="product-offer-group__panel--header"
                @keyup.space.prevent
                @keypress.enter.prevent
            >
                <div>
                    <div class="product-offer-group__name">
                        <span class="product-offer-group__name--label">
                            <b>{{ $tkey('pg') }}</b>
                        </span>
                        <div class="product-offer-group__name--wrapper" @click.prevent.stop>
                            <v-text-field
                                class="rtls-text-field rtls-text-field--white product-offer-group__name--text"
                                :placeholder="$tkey('pgName')"
                                :value="productOfferGroup.description || ''"
                                @input="debouncedUpdateDescription"
                                @click.stop=""
                            />
                        </div>
                    </div>
                    <div class="product-offer-group__delete">
                        <common-delete-dialog
                            :resource="productOfferGroupsResource"
                            :child-dependencies-warning="false"
                            :disabled="promotion.splitPromotion"
                            @delete="deletePOG()"
                        />
                    </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <!--Need for intercept keydown event from inside ag-grid components, not works directly from ag-grid-vue-->
                <!--without this common-delete-dialog catch keydown from filter components and open modal-->
                <div style="display: contents" @keydown.enter.prevent.stop>
                    <div
                        class="product-offer-group__category-selector"
                        :style="categorySelectorStyle"
                    >
                        <b class="product-offer-group__category-selector--text">{{
                            $tkey('include')
                        }}</b>
                        <vuex-select
                            class="product-offer-group__category-selector--type"
                            :options="options"
                            item-text="name"
                            item-value="id"
                            :vuex-module="vuexModule"
                            :namespace="namespace"
                            :tab="offer"
                            :field-path="fieldPath"
                            field-name="scope"
                            @change="onPOGScopeChange"
                        />
                        <template v-if="productOfferGroup.scope !== pogScope.storeWide">
                            <b class="product-offer-group__category-selector--text">{{
                                $tkey('from')
                            }}</b>
                            <product-offer-group-categories
                                :namespace="namespace"
                                :pog-index="index"
                                :is-category-wide-promotion="isSelectedPromotionCategoryWide"
                                class="product-offer-group__category-selector--categories"
                                @change="onCategoriesChange"
                            />
                        </template>
                    </div>
                    <template v-if="productOfferGroup.scope === pogScope.selectedProducts">
                        <div class="product-offer-group__filter">
                            <v-btn-toggle
                                v-model="viewSelectedOnly"
                                class="product-offer-group__filter--toggle"
                                style="margin-bottom: 1rem"
                                mandatory
                                @change="setViewSelectedOnly"
                            >
                                <v-btn :value="false" :disabled="isReadOnly">
                                    {{ $tkey('all') }}
                                </v-btn>
                                <v-btn :value="true" :disabled="isReadOnly">
                                    {{ $tkey('selected') }}
                                </v-btn>
                            </v-btn-toggle>
                            <v-tooltip top :disabled="!promotionNotSaved">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <v-btn
                                            class="product-offer-group__add-bulk"
                                            color="primary"
                                            depressed
                                            :disabled="
                                                isReadOnly ||
                                                    !hasUserSelectedCategories ||
                                                    promotionNotSaved
                                            "
                                            @click="openBulkDialog"
                                        >
                                            {{
                                                $t('actions.selectUsingProductKeys')
                                                    | toSentenceCase
                                            }}
                                        </v-btn>
                                    </div>
                                </template>
                                <span>{{ $tkey('savePromotionBefore') | toSentenceCase }}</span>
                            </v-tooltip>
                            <vuex-radio-group
                                :label="$tkey('soldBy')"
                                :options="promotionTypeOptions"
                                :getter="() => promotionType"
                                :disabled="promotionHasProducts"
                                :vuex-module="vuexModule"
                                :namespace="namespace"
                                row
                                @change="onProductTypeChange"
                            />
                        </div>
                        <ag-grid-vue
                            v-if="expanded && hasUserSelectedCategories"
                            ref="grid"
                            class="ag-theme-custom"
                            :get-row-node-id="getRowNodeId"
                            :modules="modules"
                            :row-data="rowData"
                            :column-defs="columnDefs"
                            :default-col-def="defaultColDef"
                            :status-bar="statusBar"
                            :grid-options="gridOptions"
                            :suppress-click-edit="true"
                            :process-cell-from-clipboard="processCellFromClipboard"
                            :process-cell-for-clipboard="processCellForClipboard"
                            style="width: 100%; height: 35rem"
                            @grid-ready="onGridReady"
                            @first-data-rendered="firstDataRendered"
                        />
                    </template>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <bulk-dialog
            ref="bulk-product-key-dialog"
            :has-activator="false"
            :promotion-id="namespace"
            :product-offer-group-id="productOfferGroup._id"
        />
    </div>
</template>

<script>
import { AgGridVue } from '@ag-grid-community/vue';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import {
    debounce,
    filter,
    unionBy,
    cloneDeep,
    size,
    map,
    isEmpty,
    get,
    sumBy,
    differenceBy,
    find,
    set,
    isNil,
} from 'lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { productOfferGroups as productOfferGroupsResource } from '@enums/resources';
import {
    canCreateStoreWidePromotion,
    canCreateCategoryWidePromotion,
    allowStoreWidePromotions,
    allowCategoryWidePromotions,
    showOnlyMinPrices,
} from '@enums/feature-flags';
import productTypes from '@enums/product-types';
import pogScope from '@enums/product-offer-group-scope';
import { offer } from '@enums/promotion-tabs';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import promoAgSelectAllHeader from '@/js/components/promo-ag-grid/ag-select-all-header';
import attributeSelectionHeader from './product-attribute-selector-header';
import AgCheckbox from '@/js/components/promo-ag-grid/ag-checkbox';
import { toSentenceCase } from '@/js/utils/string-utils';
import promoAgFilterAllRows from './ag-filter-all-rows';
import agGridUtils from '@/js/utils/ag-grid-utils';
import productDetailsMixin from '@/js/components/product-details/product-details-mixin';

const ATTRIBUTE_GROUP_WIDTH = 220;

export default {
    localizationKey: 'planning.promotionsMaintenance.offer.offerGroup',

    components: {
        'ag-grid-vue': AgGridVue,
    },

    mixins: [productDetailsMixin],

    props: {
        productOfferGroup: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: 0,
        },
        expanded: {
            type: Boolean,
            default: () => false,
        },
        namespace: {
            required: true,
            type: String,
        },
        vuexModule: {
            type: String,
            required: true,
        },
        context: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            productsForPromotionProductOfferGroup: [],
            productOfferGroupsResource,
            viewSelectedOnly: false,
            rowData: [],
            modules: [
                ClientSideRowModelModule,
                RichSelectModule,
                LicenseManager,
                StatusBarModule,
                SetFilterModule,
                RangeSelectionModule,
                ClipboardModule,
            ],
            productsInProgress: {},
            gridOptions: {
                frameworkComponents: { promoAgFilterAllRows, attributeSelectionHeader },
                rowSelection: 'multiple',
                pagination: false,
                rowBuffer: 75,
                rowHeight: 35, // Specified in pixels.
                headerHeight: 30, // Specified in pixels.
                groupHeaderHeight: 45, // Specified in pixels.
                // only use checkboxes, can't select row by clicking
                suppressRowClickSelection: true,
                columnTypes: {
                    numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
                    numericColumnCustomHighlightNegative:
                        agGridUtils.columnTypes.numericColumnCustomHighlightNegative,
                },
                getRowNodeId: data => data.productKey,
                tooltipShowDelay: 0,
                enableBrowserTooltips: true,
                overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${toSentenceCase(
                    this.$t('actions.loading')
                )}</span>`,
                overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">${toSentenceCase(
                    this.$tkey('grid.noRows')
                )}</span>`,
                applyColumnDefOrder: true,
                // need to override navigation methods to prevent focusing next cell when user presses
                // arrow keys in order for navigation to work inside inputs
                navigateToNextCell: () => {
                    return null;
                },
                ensureDomOrder: true,
                enableRangeSelection: true,
            },
            defaultColDef: {
                flex: -1,
                sortable: true,
                filter: true,
                resizable: true,
                filterParams: {
                    newRowsAction: 'keep',
                },
                suppressKeyboardEvent: params => {
                    const { keyCode } = params.event;

                    // allow only ctrl, v, c to allow ctrl + c, ctrl + v
                    // also left and right command keys for mac users
                    return ![17, 86, 67, 91, 93].includes(keyCode);
                },
            },
            defaultSortModel: [
                {
                    colId: 'description',
                    sort: 'asc',
                },
            ],
            gridApi: null,
            columnApi: null,
            promotionTypeOptions: [
                {
                    text: this.$tkey(productTypes.unit),
                    value: productTypes.unit,
                },
                {
                    text: this.$tkey(productTypes.weight),
                    value: productTypes.weight,
                },
            ],
            columnDefs: [],
            selectedProductAttributes: [],
            addedProductAccumulator: [],
            removedProductAccumulator: [],
            pogScope,
            offer,
            isWeightPromotion: false,
        };
    },

    events: {
        async onPromotionProductsUpdatedForGridRefresh({
            products,
            added,
            fetchProductsAndAttributesForPromotionProductOfferGroup = false,
        }) {
            // Only one product offer group will ever be expanded at one time
            if (this.expanded) {
                if (fetchProductsAndAttributesForPromotionProductOfferGroup) {
                    const productsForPromotion = await this.fetchProductsAndAttributesForPromotionProductOfferGroup(
                        {
                            productOfferGroup: this.productOfferGroup,
                            attributes: this.selectedProductAttributes,
                            returnDocuments: true,
                        }
                    );

                    this.productsForPromotionProductOfferGroup = cloneDeep(productsForPromotion);
                }

                if (added) {
                    this.upsertGridData({
                        updatedItems: products
                            .filter(p => p.productOfferGroupId === this.productOfferGroup._id)
                            .map(p => ({
                                ...p,
                                onCurrentProductGroup: true,
                            })),
                    });
                } else {
                    const removedProductKeys = new Set(products);
                    const productsToUpdateWithMinimalData = this.productsForPromotionProductOfferGroup
                        .filter(
                            p =>
                                removedProductKeys.has(p.productKey) &&
                                this.filterByProductType(p.isUnitProduct)
                        )
                        .map(p => ({
                            ...p,
                            onCurrentProductGroup: false,
                        }));
                    this.upsertGridData({
                        updatedItems: productsToUpdateWithMinimalData,
                    });
                }

                // after applying parent changes dispaly only selected products
                if (fetchProductsAndAttributesForPromotionProductOfferGroup) {
                    this.setViewSelectedOnly(true);
                }
            }
        },
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'generalConfig', 'promotionTabs']),
        ...mapState('attributeMetadata', ['attributeMetadata']),
        ...mapState('promotions', ['unsavedPromotion']),
        ...mapGetters('promotions', [
            'selectedPromotion',
            'getStagingAreaPromotionById',
            'isSelectedPromotionCategoryWide',
            'isSelectedPromotionStoreWide',
            'isSelectedPromotionCategoryOrStoreWide',
        ]),
        ...mapGetters('subCampaigns', ['hasAccessToAllSubCampaignCategories']),
        ...mapGetters('context', ['userCategories']),
        ...mapGetters('clientConfig', ['currencySymbol']),
        ...mapGetters('attributeMetadata', ['getDefaultAttributes']),

        fieldPath() {
            return `productOfferGroups[${this.index}]`;
        },

        hasAccessToAllCategoriesOnPromotion() {
            return isEmpty(
                differenceBy(
                    this.promotion.userSelectedCategories,
                    this.userCategories,
                    'levelEntryKey'
                )
            );
        },

        options() {
            return [
                {
                    id: pogScope.selectedProducts,
                    name: this.$tkey('selectedProducts'),
                    disabled:
                        !this.isSelectedPromotionCategoryOrStoreWide ||
                        (this.isSelectedPromotionCategoryWide &&
                            !this.hasAccessToAllCategoriesOnPromotion),
                },
                ...(this.toggleLogic[allowStoreWidePromotions]
                    ? [
                          {
                              id: pogScope.storeWide,
                              name: this.$tkey('storeWide'),
                              disabled:
                                  !this.toggleLogic[canCreateStoreWidePromotion] ||
                                  this.isSelectedPromotionStoreWide ||
                                  !this.hasAccessToAllSubCampaignCategories,
                          },
                      ]
                    : []),
                ...(this.toggleLogic[allowCategoryWidePromotions]
                    ? [
                          {
                              id: pogScope.categoryWide,
                              name: this.$tkey('categoryWide'),
                              disabled:
                                  !this.toggleLogic[canCreateCategoryWidePromotion] ||
                                  this.isSelectedPromotionCategoryWide,
                          },
                      ]
                    : []),
            ];
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },

        showOnlyMinimumPrices() {
            return this.toggleLogic[showOnlyMinPrices];
        },

        categorySelectorStyle() {
            if (!this.productOfferGroup.userSelectedCategories.length) {
                return {
                    height: '5rem',
                };
            }
            return {};
        },

        hasUserSelectedCategories() {
            return this.productOfferGroup.userSelectedCategories.length > 0;
        },

        statusBar() {
            return {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left',
                    },
                ],
            };
        },

        promotion() {
            if (!isEmpty(this.selectedPromotion)) return this.selectedPromotion;

            // if creating a new promotion in the parkinglot, promotion will be in staging area and _id is default
            return this.getStagingAreaPromotionById('default');
        },

        stagingAreaPromotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },

        productKeysOnPromotion() {
            return new Set(get(this.stagingAreaPromotion, 'products', []).map(p => p.productKey));
        },

        productsOnProductOfferGroup() {
            const products = filter(this.stagingAreaPromotion.products, product =>
                this.productKeysOnProductOfferGroup.has(product.productKey)
            );

            return products.map(product => {
                return {
                    ...product,
                    onCurrentProductGroup: true,
                };
            });
        },

        productsOnOtherProductOfferGroups() {
            const products = filter(
                this.productsForPromotionProductOfferGroup,
                product =>
                    this.filterByProductType(product.isUnitProduct) &&
                    this.productKeysOnPromotion.has(product.productKey) &&
                    !this.productKeysOnProductOfferGroup.has(product.productKey)
            );

            return products.map(product => {
                return {
                    ...product,
                    onCurrentProductGroup: false,
                    onAnotherProductGroup: true,
                };
            });
        },

        accessibleCategories() {
            return new Set(
                [
                    ...this.userCategories,
                    ...get(this.productOfferGroup, 'userSelectedCategories', []),
                ].map(cat => cat.levelEntryKey)
            );
        },

        userCategoriesKeys() {
            return new Set(this.userCategories.map(cat => cat.levelEntryKey));
        },

        productKeysOnProductOfferGroup() {
            return new Set(this.productOfferGroup.products);
        },

        numberOfTiers() {
            return size(this.stagingAreaPromotion.offerMechanic.tiers);
        },

        dataForGrid() {
            const productsNotOnPromotion =
                // cloneDeep needed here otherwise deselection of products does not work properly
                // and checkboxes remain selected

                cloneDeep(
                    this.productsForPromotionProductOfferGroup.filter(product =>
                        this.isWeightPromotion ? !product.isUnitProduct : product.isUnitProduct
                    )
                ) || [];

            const data = unionBy(
                cloneDeep(this.productsOnProductOfferGroup),
                cloneDeep(this.productsOnOtherProductOfferGroups),
                productsNotOnPromotion,
                'productKey'
            );
            return data;
        },

        promotionType() {
            // ensure unit is the default e.g. in parkinglot creation mode
            return this.promotion.isWeightPromotion ? productTypes.weight : productTypes.unit;
        },

        promotionHasProducts() {
            return get(this.promotion, 'products.length', 0) > 0;
        },
        debouncedAddProductToPromo() {
            return debounce(this.addProductToPromo, 600);
        },
        debouncedRemoveProductFromPromo() {
            return debounce(this.onRowDeselected, 600);
        },
        promotionNotSaved() {
            return (
                get(this.unsavedPromotion, [this.namespace, offer], false) ||
                this.namespace === 'default'
            );
        },
        maxProductsReachedOnSelectAll() {
            if (!this.gridApi) return this.maxProductsReached;
            const availableItems = [];
            this.gridApi.forEachNodeAfterFilter(rowNode => {
                if (!this.productKeysOnPromotion.has(rowNode.data.productKey)) {
                    availableItems.push(rowNode);
                }
            });
            const productCount =
                this.stagingAreaPromotion.products.length +
                availableItems.length +
                this.addedProductAccumulator.length -
                this.removedProductAccumulator.length;

            if (this.promotion.splitPromotion) {
                return productCount >= this.generalConfig.maxProductsPerSplitPromotion;
            }

            return (
                this.generalConfig.maxProductsPerPromotion !== -1 &&
                productCount >= this.generalConfig.maxProductsPerPromotion
            );
        },
        maxProductsReached() {
            const productCount =
                this.stagingAreaPromotion.products.length +
                this.addedProductAccumulator.length -
                this.removedProductAccumulator.length;
            if (this.promotion.splitPromotion) {
                return productCount >= this.generalConfig.maxProductsPerSplitPromotion;
            }
            return (
                this.generalConfig.maxProductsPerPromotion !== -1 &&
                productCount >= this.generalConfig.maxProductsPerPromotion
            );
        },
    },

    watch: {
        productsForPromotionProductOfferGroup() {
            this.refreshGridData();
        },
        async expanded() {
            if (this.expanded && isEmpty(this.productsForPromotionProductOfferGroup)) {
                const productsForPromotion = await this.fetchProductsAndAttributesForPromotionProductOfferGroup(
                    {
                        productOfferGroup: this.productOfferGroup,
                        attributes: this.selectedProductAttributes,
                        returnDocuments: true,
                    }
                );

                this.productsForPromotionProductOfferGroup = cloneDeep(productsForPromotion);
            } else {
                this.viewSelectedOnly = false;
            }
        },

        numberOfTiers() {
            // Update column definitions to show prices for new tiers (or remove old prices)
            if (this.gridApi) {
                this.regenerateGridColumns();
            }
        },
    },

    async created() {
        this.selectedProductAttributes = map(this.getDefaultAttributes, 'attributeKey');
        this.isWeightPromotion = this.promotion.isWeightPromotion;
        this.setGridColumns();
        this.$set(this.gridOptions, 'getRowClass', ({ data }) => {
            const key = data.productKey;
            if (
                (this.productKeysOnPromotion.has(key) &&
                    !this.productOfferGroup.products.includes(key)) ||
                !this.userCategoriesKeys.has(data.category)
            ) {
                return 'disabled-row';
            }
        });
        if (this.isReadOnly) {
            this.viewSelectedOnly = true;
        } else if (this.expanded) {
            this.productsForPromotionProductOfferGroup = await this.fetchProductsAndAttributesForPromotionProductOfferGroup(
                {
                    productOfferGroup: this.productOfferGroup,
                    attributes: this.selectedProductAttributes,
                    returnDocuments: true,
                }
            );
        }
    },

    beforeDestroy() {
        if (!this.promotionTabs.offer.cacheDOM && this.gridOptions.api) {
            // force-clear any references pointing to the grid when it gets removed from the DOM
            this.gridOptions.api.cleanDownReferencesToAvoidMemoryLeakInCaseApplicationIsKeepingReferenceToDestroyedGrid();
        }
    },

    methods: {
        ...mapActions('promotions', [
            'setStagingAreaField',
            'addProductsToPromotion',
            'updatePromotion',
            'setPromotionStoreWide',
            'setPromotionCategoryWide',
        ]),
        ...mapActions('products', [
            'fetchProducts',
            'fetchProductAttributesForPromotion',
            'fetchProductsAndAttributesForPromotionProductOfferGroup',
        ]),
        ...mapMutations('promotions', ['setUnsavedPromotion', 'setAddProductsInProgress']),
        async onCategoriesChange() {
            if (!this.isSelectedPromotionCategoryWide) {
                // on first category selection gridApi is not available
                if (this.gridApi) this.gridApi.showLoadingOverlay();
                const productsForPromotion = await this.fetchProductsAndAttributesForPromotionProductOfferGroup(
                    {
                        productOfferGroup: this.productOfferGroup,
                        attributes: this.selectedProductAttributes,
                        returnDocuments: true,
                    }
                );

                this.productsForPromotionProductOfferGroup = cloneDeep(productsForPromotion);
            }
        },

        setGridColumns() {
            this.columnDefs = this.getOfferGroupColumns();
        },

        regenerateGridColumns() {
            this.columnDefs = this.getOfferGroupColumns();
            this.gridApi.setColumnDefs(this.columnDefs);
            this.$nextTick(() => {
                this.autoSizeAllColumns();
            });
        },

        openBulkDialog() {
            this.$refs['bulk-product-key-dialog'].openDialog();
        },

        autoSizeAllColumns() {
            this.columnApi.autoSizeAllColumns();

            // Manually adjust size of the attribute columns if they are visible.
            // This is required to ensure the full group header is visible as the columns
            // within it are dynamic, we cannot rely on their individual sizes.
            if (
                this.columnApi.getColumnGroup('attributes') &&
                this.columnApi.getColumnGroup('attributes').isExpanded()
            ) {
                const attributeColumns = this.columnApi.getColumnGroup('attributes')
                    .displayedChildren;

                const currentWidth = sumBy(attributeColumns, 'actualWidth');
                if (currentWidth < ATTRIBUTE_GROUP_WIDTH) {
                    const columnWidth = ATTRIBUTE_GROUP_WIDTH / attributeColumns.length;

                    attributeColumns.forEach(column =>
                        this.columnApi.setColumnWidth(column, columnWidth)
                    );
                }
            }
        },

        debouncedFilterChange: debounce(function(newFilter) {
            this.gridApi.setQuickFilter(newFilter);
        }, 800),

        debouncedUpdateDescription: debounce(function(description) {
            this.setStagingAreaField({
                namespace: this.namespace,
                fieldPath: this.fieldPath,
                fieldName: 'description',
                value: description,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
        }, 800),
        onGridReady(params) {
            if (!this.expanded) {
                return;
            }
            this.gridApi = params.api;
            this.gridApi.showLoadingOverlay();
            this.columnApi = params.columnApi;
            this.autoSizeAllColumns();
            // We've noticed the ag-grid is quite slow at adding lot's of data,
            // therefore we are rendering with 500 first to show the table quicker
            // and then when this data is rendered we load the rest.
            // It takes around 200ms to see these initial 500 rows and
            // around 3/4 seconds to see the rest. The grid is not usable until
            // all the data is loaded.
            const gridData = this.dataForGrid.slice(0, 500);
            this.gridApi.setRowData(gridData);
            // Checks if any products are already selected as part of this promotion
            // If so, applies a "selected" filter on the grid
            if (gridData.some(elem => elem.onCurrentProductGroup)) {
                this.setViewSelectedOnly(true);
                this.viewSelectedOnly = true;
            }
            this.columnApi.applyColumnState({ state: this.defaultSortModel });

            // Need to show the overlay again as setRowData function removes it once complete
            this.gridApi.showLoadingOverlay();
        },
        firstDataRendered(params) {
            // Once the initial set of rows are rendered we then set the remaining data
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
            this.gridApi.setRowData(this.dataForGrid);
            // Checks if any products are already selected as part of this promotion
            // If so, applies a "selected" filter on the grid
            if (this.dataForGrid.some(elem => elem.onCurrentProductGroup)) {
                this.setViewSelectedOnly(true);
                this.viewSelectedOnly = true;
            }
            this.columnApi.applyColumnState({ state: this.defaultSortModel });
        },

        getOfferGroupColumns() {
            const detailColumnGroups = this.getGridConfig({ context: 'offerGroup' }).details || [];
            const nonPromoPriceColumnGroups =
                this.getGridConfig({ context: 'offerGroup' }).nonPromoPrice || [];
            const promoPriceColumnGroups =
                this.getGridConfig({ context: 'offerGroup' }).promoPrice || [];

            const columnDefs = [
                {
                    headerName: toSentenceCase(this.$tkey('headers.include')),
                    children: [
                        {
                            headerName: '',
                            sortable: false,
                            field: 'onCurrentProductGroup',
                            headerComponentFramework: promoAgSelectAllHeader,
                            headerComponentParams: {
                                isSelectAllDisabled: () => {
                                    return this.maxProductsReachedOnSelectAll;
                                },
                                selectAllDisabledTooltip: this.$t(
                                    'validation.failureMessages.promotionMaxProducts',
                                    {
                                        maxProductsPerPromotion: this.promotion.splitPromotion
                                            ? this.generalConfig.maxProductsPerSplitPromotion
                                            : this.generalConfig.maxProductsPerPromotion,
                                    }
                                ),
                            },
                            onCellValueChanged: params => this.updateRowSelected(params),
                            cellRendererFramework: AgCheckbox,
                            cellRendererParams: {
                                onSelectUnSelectAll: async ({ updates, isSelected }) => {
                                    this.addedProductAccumulator = [];
                                    this.removedProductAccumulator = [];
                                    if (isSelected) {
                                        // filter out products which are already selected in other POGs
                                        const availableProducts = [];
                                        const productData = await this.fetchProducts({
                                            params: {
                                                where: {
                                                    productKey: {
                                                        $in: updates.map(u => u.productKey),
                                                    },
                                                },
                                            },
                                            returnDocuments: true,
                                            storeDocuments: false,
                                        });
                                        productData.forEach(p => {
                                            const key = p.productKey;
                                            if (
                                                (this.productKeysOnPromotion.has(key) &&
                                                    !this.productOfferGroup.products.includes(
                                                        key
                                                    )) ||
                                                !this.userCategoriesKeys.has(p.category)
                                            ) {
                                                // needed for checkbox to still be unselected
                                                p.onCurrentProductGroup = false;
                                            } else {
                                                // actual array of products being saved
                                                availableProducts.push({
                                                    ...p,
                                                    productOfferGroupId: this.productOfferGroup._id,
                                                });
                                            }
                                        });

                                        await this.addProductsToPromotion({
                                            promotionId: this.namespace,
                                            newProducts: availableProducts,
                                            productOfferGroupId: this.productOfferGroup._id,
                                        });
                                    } else {
                                        await this.onRowDeselected(map(updates, 'productKey'));
                                    }
                                },
                                isDisabled: ({ data }) => {
                                    if (
                                        data.onAnotherProductGroup ||
                                        !this.userCategoriesKeys.has(data.category) ||
                                        this.productsInProgress[data.productKey] ||
                                        (this.maxProductsReached &&
                                            !this.productKeysOnPromotion.has(data.productKey))
                                    ) {
                                        return true;
                                    }
                                },
                                maxProductsTooltip: ({ data }) => {
                                    return this.maxProductsReached &&
                                        !this.productKeysOnPromotion.has(data.productKey)
                                        ? this.$t(
                                              'validation.failureMessages.promotionMaxProducts',
                                              {
                                                  maxProductsPerPromotion: this.promotion
                                                      .splitPromotion
                                                      ? this.generalConfig
                                                            .maxProductsPerSplitPromotion
                                                      : this.generalConfig.maxProductsPerPromotion,
                                              }
                                          )
                                        : '';
                                },
                                isCenteredHorizontally: true,
                            },
                            suppressFilter: true,
                            // We need to explicitly set the filter values. This means that both options are
                            // always available even if no or all products have been selected.
                            filterParams: {
                                values: [true, false],
                            },
                            suppressMenu: true,
                            tooltipValueGetter: ({ data }) => {
                                const key = data.productKey;
                                if (
                                    this.productKeysOnPromotion.has(key) &&
                                    !this.productOfferGroup.products.includes(key)
                                ) {
                                    return toSentenceCase(this.$tkey('onlyOnePOG'));
                                }
                                if (!this.userCategoriesKeys.has(data.category)) {
                                    return toSentenceCase(this.$tkey('noPermission'));
                                }
                            },
                        },
                    ],
                },
                ...this.mapColumnGroups(detailColumnGroups),
                ...this.generateAttributeColumns(),
                ...this.mapColumnGroups(nonPromoPriceColumnGroups, {
                    currencySymbol: this.currencySymbol,
                }),
            ];

            for (let i = 0; i < this.numberOfTiers; i += 1) {
                // Get latest reward on promotion from staging area
                const stagingAreaOfferMechanic = get(this.stagingAreaPromotion, 'offerMechanic');
                const reward = get(
                    find(
                        stagingAreaOfferMechanic.tiers[i].productOfferGroups,
                        pog => pog._id === this.productOfferGroup._id
                    ),
                    'rewards.0.type'
                );
                columnDefs.push(
                    ...this.mapColumnGroups(promoPriceColumnGroups, {
                        tierIndex: i,
                        tier: i + 1,
                        reward,
                        currencySymbol: this.currencySymbol,
                        namespace: this.namespace,
                        productOfferGroupId: this.productOfferGroup._id,
                        fixAvgPromoPrice: this.fixAvgPromoPrice,
                    })
                );
            }
            return columnDefs;
        },

        generateAttributeColumns() {
            const columnGroup = [
                {
                    headerName: toSentenceCase(this.$tkey('headers.additionalAttributes')),
                    groupId: 'attributes',
                    headerGroupComponent: 'attributeSelectionHeader',
                    headerGroupComponentParams: {
                        selectedAttributes: this.selectedProductAttributes,
                        onSelectedAttributeChange: this.onSelectedAttributeChange,
                    },
                    openByDefault: true,
                    children: [
                        ...(this.selectedProductAttributes.length
                            ? this.selectedProductAttributes.map((attr, index) => {
                                  const selectedAttribute = this.attributeMetadata.find(
                                      availableAttribute => {
                                          return availableAttribute.attributeKey === attr;
                                      }
                                  );
                                  return {
                                      colId: `attribute-${index}`,
                                      headerName: selectedAttribute.attributeName,
                                      sortable: true,
                                      valueGetter: ({ data }) => {
                                          if (!data.attributes) {
                                              return;
                                          }
                                          const attribute =
                                              data.attributes.find(a => {
                                                  return a.attributeKey === attr;
                                              }) || {};

                                          return attribute.attributeValue;
                                      },
                                      columnGroupShow: 'open',
                                  };
                              })
                            : [
                                  {
                                      colId: `attribute-placeholder`,
                                      headerName: '',
                                      sortable: false,
                                      filter: false,
                                      columnGroupShow: 'open',
                                      width: ATTRIBUTE_GROUP_WIDTH,
                                  },
                              ]),
                        {
                            colId: `attribute-collapsed`,
                            headerName: '',
                            sortable: false,
                            filter: false,
                            columnGroupShow: 'closed',
                            minWidth: 30,
                        },
                    ],
                },
            ];

            return columnGroup;
        },

        async onSelectedAttributeChange(value) {
            this.selectedProductAttributes = value;

            this.gridApi.showLoadingOverlay();
            this.regenerateGridColumns();
            const productsForPromotion = await this.fetchProductAttributesForPromotion({
                attributes: this.selectedProductAttributes,
                productsForPromotion: this.productsForPromotionProductOfferGroup,
                returnDocuments: true,
            });

            this.productsForPromotionProductOfferGroup = cloneDeep(productsForPromotion);
        },

        getRowNodeId(data) {
            return data.productKey;
        },

        getSelectedProducts() {
            return this.gridApi.getSelectedNodes();
        },

        deletePOG() {
            this.$emit('delete', { productOfferGroupId: this.productOfferGroup._id });
        },

        upsertGridData({ newItems = [], removedItems = [], updatedItems = [] }) {
            if (this.gridApi) {
                // Using applyTransactionAsync allows the grid to be used while the transactions are being applied.
                // This means users can select other checkboxes while products updates are being applied to the grid
                // which can take a long time.
                this.gridApi.applyTransactionAsync({
                    add: newItems,
                    remove: removedItems,
                    update: updatedItems,
                });
            }
        },
        cleanProductsInAccumulator(productKey, path = 'addedProductAccumulator') {
            const index = this[path].findIndex(product => product.productKey === productKey);
            if (index > -1) {
                this[path].splice(index, 1);
            }
        },

        async updateRowSelected({ newValue, data }) {
            this.$set(this.productsInProgress, data.productKey, true);
            if (newValue) {
                this.setAddProductsInProgress(true);
                this.cleanProductsInAccumulator(data.productKey, 'removedProductAccumulator');
                // Adding product - need to load all product info
                const productData = await this.fetchProducts({
                    params: {
                        where: { productKey: data.productKey },
                    },
                    returnDocuments: true,
                    storeDocuments: false,
                });
                const newProducts = productData.map(p => ({
                    ...p,
                    productOfferGroupId: this.productOfferGroup._id,
                }));
                this.addedProductAccumulator = [...this.addedProductAccumulator, ...newProducts];
                this.debouncedAddProductToPromo();
            } else {
                this.cleanProductsInAccumulator(data.productKey);
                this.removedProductAccumulator.push(data.productKey);
                this.debouncedRemoveProductFromPromo();
            }
        },

        async addProductToPromo() {
            if (!this.addedProductAccumulator.length) {
                this.setAddProductsInProgress(false);
                return;
            }
            const newProducts = [...this.addedProductAccumulator];
            this.addedProductAccumulator = [];

            await this.addProductsToPromotion({
                promotionId: this.namespace,
                newProducts,
                productOfferGroupId: this.productOfferGroup._id,
            });
            newProducts.forEach(newProduct =>
                this.$set(this.productsInProgress, newProduct.productKey, false)
            );
            const isProductsInProgress = Object.keys(this.productsInProgress).some(
                product => this.productsInProgress[product]
            );

            if (!isProductsInProgress) {
                this.setAddProductsInProgress(false);
            }
        },
        async onRowDeselected(products = null) {
            const removedProducts = products ? [...products] : [...this.removedProductAccumulator];
            this.removedProductAccumulator = [];
            await this.$emit('removed', {
                removedProducts,
                productOfferGroupId: this.productOfferGroup._id,
            });
            removedProducts.forEach(removedProduct =>
                this.$delete(this.productsInProgress, removedProduct)
            );
        },

        getPromoMargin(product, params) {
            if (!product.onCurrentProductGroup) {
                return null;
            }

            if (!product.commercialCosts) {
                return 0;
            }

            let productFromStagingArea = null;

            if (this.stagingAreaPromotion) {
                productFromStagingArea = this.stagingAreaPromotion.products.find(
                    sap => sap.productKey === product.productKey
                );
            }

            const calculateMarginFunction = this.featureAwareFactory.getCalculateMarginFunction();

            if (!product.promoPrices || !(params.tierIndex in product.promoPrices)) return 0;
            const promoMargin = calculateMarginFunction({
                product: productFromStagingArea || product,
                prices: (productFromStagingArea || product).promoPrices[params.tierIndex],
                useMinPrice: this.showOnlyMinimumPrices,
                excludeFunding: false,
            });

            return promoMargin;
        },

        getNonPromoMargin(product) {
            if (!product.onCurrentProductGroup) {
                return null;
            }

            if (!product.commercialCosts) {
                return 0;
            }

            const calculateMarginFunction = this.featureAwareFactory.getCalculateMarginFunction();

            const nonPromoMargin = calculateMarginFunction({
                product,
                prices: product.nonPromoPrices,
                useMinPrice: this.showOnlyMinimumPrices,
                excludeFunding: true,
            });

            return nonPromoMargin;
        },

        setViewSelectedOnly(viewSelectedOnly = false) {
            if (!this.gridApi) return;
            const model = this.gridApi.getFilterModel();

            if (viewSelectedOnly) {
                model.onCurrentProductGroup = {
                    filterType: 'set',
                    values: ['true'],
                };
            } else {
                model.onCurrentProductGroup = null;
            }

            this.gridApi.setFilterModel(model);
        },

        async onProductTypeChange(productType) {
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay();
            }
            await this.setPromotionType(productType);
            this.refreshGridData();
        },

        async setPromotionType(value) {
            this.isWeightPromotion = value === productTypes.weight;
            this.setStagingAreaField({
                namespace: this.namespace,
                fieldName: 'isWeightPromotion',
                value: this.isWeightPromotion,
            });
            this.setUnsavedPromotion({ namespace: this.namespace, tab: offer, value: true });
        },

        onPOGScopeChange(value) {
            if (
                value === pogScope.storeWide &&
                (!this.isSelectedPromotionStoreWide || this.namespace === 'default')
            ) {
                this.setPromotionStoreWide({ namespace: this.namespace, pogIndex: this.index });
            }

            if (
                value === pogScope.categoryWide &&
                (!this.isSelectedPromotionCategoryWide || this.namespace === 'default')
            ) {
                this.setPromotionCategoryWide({ namespace: this.namespace, pogIndex: this.index });
            }
        },

        refreshGridData() {
            if (this.gridApi) {
                this.gridApi.setRowData(this.dataForGrid);
                this.columnApi.applyColumnState({ state: this.defaultSortModel });
            }
        },

        filterByProductType(isUnitProduct) {
            return this.isWeightPromotion ? !isUnitProduct : isUnitProduct;
        },
        processCellFromClipboard(params) {
            this.globalEmit('paste', params);
        },

        // intercept ag-grid copy event
        // if we have data in staging area for the cell retun value from stging area
        // if not return value from the ag-grid data
        processCellForClipboard(params) {
            const producKey = get(params, 'node.data.productKey', null);
            const stagingAreaProduct = this.stagingAreaPromotion.products.find(
                pr => String(pr.productKey) === String(producKey)
            );
            if (isNil(stagingAreaProduct)) {
                return params.value;
            }

            // path example: promoPrices[1].avgPrice - this key we can use to get price from stagingArea
            const path = get(params, 'column.colDef.field');

            const avgPrice = get(stagingAreaProduct, path, null);
            if (!isNil(avgPrice)) {
                return avgPrice;
            }

            return params.value;
        },

        fixAvgPromoPrice(params) {
            // Fix avg price into min and max prices in the grid
            set(params.data, `promoPrices.${params.tierIndex}.minPrice`, params.value);
            set(params.data, `promoPrices.${params.tierIndex}.maxPrice`, params.value);
            this.gridOptions.api.refreshCells();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.product-offer-group {
    height: 100%;

    &__name {
        margin-bottom: 1rem;
        display: flex;
        &--label {
            font-size: 1.2rem;
            margin-top: 1.5rem;
            margin-right: 1rem;
        }
        &--wrapper {
            padding: 0.5rem 0 0.5rem 2rem;
        }
        &--text {
            ::v-deep {
                .v-input__slot {
                    width: 20rem;
                    padding-left: 1rem;
                }
            }
        }
    }

    &__category-selector {
        display: flex;
        height: 4rem;
        background-color: $promo-light-blue-4;
        margin-bottom: 1rem;
        padding-left: 3rem;
        padding-top: 0.5rem;
        column-gap: 1rem;

        &--text {
            padding-top: 0.5rem;
        }

        &--type {
            min-width: 10%;
        }

        &--categories {
            margin-top: 0 !important;
            align-self: flex-start;
        }
    }

    &__panel {
        border-bottom: $component-border;

        &--header {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: unset;
            padding-bottom: unset;

            div {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &__filter {
        display: flex;
        align-items: baseline;

        ::v-deep {
            .v-input--selection-controls {
                margin: 0 2rem;
                padding-top: 0;
            }
        }
    }

    &__add-bulk {
        margin-left: 0.5rem;
    }

    &__filter-bar {
        margin-bottom: 1rem !important;
        ::v-deep {
            .v-input__slot {
                width: 105rem;
            }
        }
    }

    ::v-deep {
        .disabled-row {
            opacity: 0.5;
            cursor: not-allowed;
        }
        .v-select__slot {
            height: 2.8rem !important;
            min-width: 25rem;
        }
        // Centers the Select All header button
        .ag-header-cell:first-child {
            display: flex !important;
            justify-content: center !important;
        }

        .ag-cell {
            .ag-cell-wrapper {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
