var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "12px",
        viewBox: "0 0 16 12",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Workflow arrow")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "simple-UPDATE",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Workflow-4-1-A-01",
                transform: "translate(-1297.000000, -532.000000)",
                fill: "#C13939",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group",
                    transform: "translate(1297.000000, 530.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group-31",
                        transform: "translate(0.000000, 2.000000)",
                      },
                    },
                    [
                      _c("polygon", {
                        attrs: {
                          id: "Triangle",
                          transform:
                            "translate(12.000000, 6.000000) rotate(-270.000000) translate(-12.000000, -6.000000) ",
                          points: "12 2 17.5 10 6.5 10",
                        },
                      }),
                      _vm._v(" "),
                      _c("rect", {
                        attrs: {
                          id: "Rectangle",
                          x: "0",
                          y: "4.5",
                          width: "8",
                          height: "3",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }