/**
 * Generates a detailed provision array for the given resource type, including any
 * default values specified in the detailed provisions config.
 * @param {String} resourceType - the type of the resource (e.g. 'leaflet', 'tv' etc.).
 * @param {Object} detailedProvisionsConfig - the detailed provisions configuration.
 */
export const generateDefaultDetailedProvisionForResourceType = ({
    resourceType,
    detailedProvisionsConfig,
}) => {
    const detailedProvisionsForResourceType = detailedProvisionsConfig.filter(
        dp => dp.type === resourceType
    );

    const defaultDetailedProvision = [];
    detailedProvisionsForResourceType.forEach(dp => {
        if (dp.default) {
            const defaultDP = dp.options.find(option => option.key === dp.default);
            if (defaultDP) {
                defaultDetailedProvision.push({
                    name: dp.name,
                    value: defaultDP.key,
                    clientKey: String(defaultDP.clientKey),
                });
            }
        }
    });

    return defaultDetailedProvision;
};

/**
 * Generates a resources array for a promotion based on the resources present on the parent
 * entity (the scenario) and the detailed provisions configuration.
 * @param {Array<Object>} scenarioResources - the resources array present on the scenario (the promotion's parent entity).
 */
export const getDefaultResourcesForPromotion = ({ scenarioResources }) => {
    const promotionResources = scenarioResources.map(resource => {
        const updatedInstances = resource.instances.map(instance => {
            return {
                ...instance,
                detailedProvision: [],
            };
        });

        return {
            ...resource,
            instances: updatedInstances,
        };
    });

    return promotionResources;
};
