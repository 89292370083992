import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "parkingLot.allocatePromotionToParkingLot.heading",
      "btn-confirm-text": "actions.move",
      "has-activator": _vm.hasActivator,
      "confirm-btn-disabled": _vm.isDisabled,
    },
    on: {
      confirm: _vm.allocatePromotionsDialogConfirm,
      close: _vm.closeDialog,
    },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    "icon",
                    _vm._g(
                      {
                        attrs: { "icon-name": "duplicate", small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      activator
                    )
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "allocate-promotion" },
                [
                  _c("p", { staticClass: "allocate-promotion__description" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t(
                              "parkingLot.allocatePromotionToParkingLot.description"
                            )
                          )
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VTextField, {
                    staticClass: "rtls-text-field rtls-text-field--grey pb-2",
                    attrs: { disabled: "", label: _vm.modalLabel },
                  }),
                  _vm._v(" "),
                  _c(VTextField, {
                    staticClass: "rtls-text-field rtls-text-field--grey pb-2",
                    attrs: { disabled: "", label: _vm.campaign.name },
                  }),
                  _vm._v(" "),
                  _c(VTextField, {
                    staticClass: "rtls-text-field rtls-text-field--grey pb-2",
                    attrs: { disabled: "", label: _vm.subCampaign.name },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.requestInProgress
                ? _c(
                    "div",
                    { staticClass: "in-progress--message" },
                    [
                      _c("label", { staticClass: "in-progress__message" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t(
                                "parkingLot.allocatePromotionToParkingLot.inProgressMessage"
                              )
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("spinner-dynamic"),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }