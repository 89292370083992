import { object } from '@enums/value-types';
import { has } from 'lodash';

const mixin = {
    props: {
        label: String,
        options: [Array, Object],
        itemValue: String,
        itemText: String,
        valueType: String,
        cssClass: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getOptions() {
            const options = this.parsedField ? this.parsedField.options : this.options;

            return this.sortResults ? this.sortFunction(options) : options;
        },
    },
    methods: {
        getValue(option) {
            option = option.reference ? option.reference : option;
            return this.valueType === object ? option : option[this.itemValue];
        },

        getKey(option, index) {
            option = option.reference ? option.reference : option;

            return this.valueType === object
                ? option[this.itemText] + index
                : option[this.itemValue];
        },

        isOptionDisabled(option) {
            return has(option, 'disabled') ? option.disabled : this.disabled;
        },
    },
};

export default mixin;
