<template>
    <main-dialog
        ref="dialog"
        :heading="dialogTitle"
        :has-activator="hasActivator"
        ignore-parent-read-only
        show-positive-action-button
        btn-close-text="actions.cancel"
        @close="closeDialog()"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <div style="display: contents">
                <div v-if="showUnlockButton" class="icons-container">
                    <v-avatar
                        v-if="isPromotionUnlockRequested && canUnlockPromotion"
                        class="notification-indicator"
                        size="5"
                    />
                    <icon
                        :icon-name="iconName"
                        :disabled="dialogDisabled"
                        small
                        v-on="activator"
                        @click.stop
                    />
                </div>
            </div>
        </template>
        <template v-slot:default>
            <div v-if="canUnlockPromotion" class="message">
                <div v-if="isPromotionUnlockRequested" class="mb-1">
                    {{ $t('dialogs.promotionUnlock.notesForDetails') | toSentenceCase }}
                </div>
                <div class="mb-1">
                    {{ $t('dialogs.promotionUnlock.unlockMessage') | toSentenceCase }}
                </div>
                <b>{{ $t('dialogs.promotionUnlock.unlockWarning') | toSentenceCase }}</b>
            </div>
            <div v-else class="message">
                <span v-if="isPromotionUnlockRequested">
                    {{ $t('dialogs.promotionUnlock.unlockAlreadyRequested') | toSentenceCase }}
                </span>
                <div v-else>
                    <div class="mb-1">
                        {{ $t('dialogs.promotionUnlock.requestUnlockMessage') | toSentenceCase }}
                    </div>
                    <div class="mb-1">
                        {{ $t('dialogs.promotionUnlock.requestUnlockNote') | toSentenceCase }}
                    </div>
                    <div class="notes__input-container">
                        <div class="notes__input">
                            <v-textarea
                                v-model="noteText"
                                :label="$t('dialogs.promotionUnlock.notePrompt') | toSentenceCase"
                                hide-details
                                no-resize
                                solo
                                autocomplete
                                flat
                                rows="4"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:positiveActionButton>
            <task-button
                v-if="canUnlockPromotion && isPromotionUnlockRequested"
                :is-negative-action="true"
                :task="workflowTasks.unlock"
                :entity-type="workflowEntities.promotion"
                :entity-ids="[promotion._id]"
                :sub-campaign-id="selectedSubCampaignId"
                :show-action-date-time="false"
                :categories="promotion.userSelectedCategories || []"
                :is-store-wide-promotion="isStoreWidePromotion"
                :button-style="{ 'margin-left': '1rem' }"
                :small="false"
                :sub-campaign="subCampaign"
                class="task-button"
                short-text
                @click="closeDialog()"
            />
            <task-button
                v-if="canUnlockPromotion"
                :task="workflowTasks.unlock"
                :entity-type="workflowEntities.promotion"
                :entity-ids="[promotion._id]"
                :sub-campaign-id="selectedSubCampaignId"
                :show-action-date-time="false"
                :categories="promotion.userSelectedCategories || []"
                :is-store-wide-promotion="isStoreWidePromotion"
                :button-style="{ 'margin-left': '1rem' }"
                :small="false"
                :sub-campaign="subCampaign"
                class="task-button"
                short-text
                @click="closeDialog()"
            />
            <task-button
                v-else
                :disabled="!noteText.length"
                :task="workflowTasks.requestUnlock"
                :entity-type="workflowEntities.promotion"
                :entity-ids="[promotion._id]"
                :sub-campaign-id="selectedSubCampaignId"
                :show-action-date-time="false"
                :categories="promotion.userSelectedCategories || []"
                :is-store-wide-promotion="isStoreWidePromotion"
                :button-style="{ 'margin-left': '1rem' }"
                :small="false"
                :sub-campaign="subCampaign"
                class="task-button"
                short-text
                @action-completed="createNote"
                @click="closeDialog({ resetNote: false })"
            />
        </template>
    </main-dialog>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { find } from 'lodash';
import workflowTasks from '@enums/workflow-tasks';
import workflowEntities from '@enums/workflow-entities';
import workflowStates from '@enums/workflow-states';
import featureFlagEnums from '@enums/feature-flags';
import NotesFieldKeys from '@enums/notes-field-keys';
import { isStoreWidePromotion } from '@sharedModules/promotion-utils';

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        iconName: {
            type: String,
            required: true,
        },
        promotionId: {
            type: String,
            required: true,
        },
        subCampaign: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            noteText: '',
            workflowTasks,
            workflowEntities,
        };
    },
    computed: {
        ...mapState('subCampaigns', ['selectedSubCampaignId']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['getPromotionById']),
        ...mapGetters('context', ['hasPermission']),
        promotion() {
            return this.getPromotionById(this.promotionId) || {};
        },
        isPromotionUnlockRequested() {
            if (!this.promotionId) return false;
            const isPromotionUnlockRequested = find(this.promotion.workflowState, {
                entity: workflowEntities.promotion,
                state: workflowStates.requestUnlock,
                value: true,
            });
            return isPromotionUnlockRequested;
        },
        isPromotionApproved() {
            if (!this.promotionId) return false;
            const isPromotionApproved = find(this.promotion.workflowState, {
                entity: workflowEntities.promotion,
                state: workflowStates.approved,
                value: true,
            });
            return isPromotionApproved;
        },
        canUnlockPromotion() {
            if (!this.promotionId) return false;

            return (
                this.isPromotionApproved && this.toggleLogic[featureFlagEnums.canUnlockPromotion]
            );
        },
        showUnlockButton() {
            return this.isReadOnly || this.canUnlockPromotion;
        },
        dialogTitle() {
            if (this.canUnlockPromotion) return 'dialogs.promotionUnlock.unlockTitle';
            return 'dialogs.promotionUnlock.requestUnlockTitle';
        },
        dialogDisabled() {
            // If the promotion cannot be edited based on the start date then disable the dialog
            if (!this.promotionCanBeEditedBasedOnStartDate) return true;

            if (this.isPromotionApproved) {
                return (
                    !this.toggleLogic[featureFlagEnums.canRequestUnlockPromotion] &&
                    !this.canUnlockPromotion
                );
            }
            return true;
        },
        /**
         * A promotion can be edited if it's start date is more than the specified config value number of days away from the current date
         */
        promotionCanBeEditedBasedOnStartDate() {
            // We don't want to take the time portion of the dates into account, as
            // this won't work for a promotion that is due to start the next day.
            // For example, if we consider:
            //    `promotionStartDate`: 2022-01-02 00:00:00
            //    `currentDate`: 2022-01-01 12:00:00
            // Then the date diff in `days` will be 0 as it's not a full day before
            // the start of the promotion.
            const promotionStartDate = this.$moment(this.promotion.startDate).startOf('day');
            const currentDate = this.$moment().startOf('day');
            const diffBetweenCurrentDateAndStartDate = promotionStartDate.diff(currentDate, 'days');

            return (
                diffBetweenCurrentDateAndStartDate >
                this.toggleLogic[featureFlagEnums.disablePromotionEditingDaysBeforeStart]
            );
        },
        isStoreWidePromotion() {
            return isStoreWidePromotion(this.promotion);
        },
    },
    methods: {
        ...mapActions('promotions', ['addNote']),
        async createNote() {
            if (!this.noteText.length) return;
            await this.addNote({
                note: { text: this.noteText },
                id: this.promotion._id,
                fieldKey: NotesFieldKeys.root,
            });
            this.noteText = '';
            this.closeDialog();
        },
        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog({ resetNote } = { resetNote: true }) {
            if (resetNote) {
                this.noteText = '';
            }
            this.$refs.dialog.close();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.message {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 4rem;
    padding-right: 4rem;
    text-align: center;
}

.task-button {
    margin-left: 1rem;
}

.notes {
    &__input-container {
        padding: 1rem 1rem 0.5rem;
        background: $promo-grey-6;
    }

    &__text {
        padding-right: 0.5rem;
        font-size: 1.2rem;
        word-break: break-word;
    }
    &__input {
        ::v-deep {
            .v-textarea {
                border-radius: 0;
                box-shadow: 0;
            }

            .v-label {
                top: 0.5rem !important;
            }

            .v-input__slot {
                .v-text-field__slot {
                    textarea {
                        margin: 0;
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}
.icons-container {
    position: relative;
}
.notification-indicator {
    z-index: 2;
    cursor: pointer;
    position: absolute;
    right: -0.1rem;
    top: -0.2rem;
    background-color: $rtls-negative-action-colour;
    padding: 0.4rem !important;
}
</style>
