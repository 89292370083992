import featureFlags from '@enums/feature-flags';
import routeNames from '@enums/route-names';

import guardsMap from './parking-lot-guards-map';
import ParkingLotPage from './parking-lot-page';

const defaultBeforeEnter = (to, from, next) => {
    guardsMap[to.name](to, from, next);
};
const routes = [
    {
        path: '/parking-lot',
        id: 'parkingLot',
        component: ParkingLotPage,
        featureToggle: 'canAccessParkingLot',
        linkText: 'navBar.parkingLot',
        index: 3,
        meta: { canAccess: featureFlags.canAccessParkingLot, isSideBarCalendarExpanded: false },
        children: [
            {
                path: '',
                name: routeNames.parkingLotView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'past-promotions',
                name: routeNames.pastPromotionsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'sub-campaign-allocation',
                name: routeNames.subCampaignAllocationView,
                beforeEnter: defaultBeforeEnter,
            },
        ],
    },
];

export default routes;
