<template>
    <div class="supplier-commitments">
        <supplier-commitment-type-toggle :selected-supplier-commitment-type="totalSpendType" />

        <div v-if="isSpecifiedAllocationsSection" class="supplier-commitments-grid">
            <div
                v-for="(field, index) in fields"
                :key="`supplierCommitmentHeader::${index}`"
                class="supplier-commitments-grid__header"
            >
                <div v-if="field.header">
                    {{ $t(`supplierCommitments.headers.${field.header}`) | toSentenceCase }}
                </div>
            </div>

            <div class="supplier-commitments-grid__header">
                {{ $t('supplierCommitments.headers.select') | toSentenceCase }}
            </div>

            <template v-for="supplierCommitment in supplierCommitments">
                <supplier-commitment-viewer
                    :key="`supplierCommitment::${supplierCommitment._id}`"
                    :supplier-commitment="supplierCommitment"
                    :fields="fields"
                    :form-fields="formFields"
                    :promotion-namespace="promotionNamespace"
                    is-promotion-supplier-funding-view
                    make-read-only
                    @click="onCommitmentClick({ id: supplierCommitment._id })"
                />
            </template>
        </div>

        <total-spend-viewer
            v-else-if="isTotalSpendSection"
            is-promotion-supplier-funding-view
            :promotion-supplier-key="supplierKey"
            :promotion-category="category"
            :promotion-namespace="promotionNamespace"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { fieldTypes } from '@enums/vuex-form';
import NotesFieldKeys from '@enums/notes-field-keys';
import { totalSpend } from '@enums/supplier-commitment-types';
import fields from './supplier-commitment-fields';
import { getMeasurementsOptions } from '@/js/utils/supplier-commitment-utils';

export default {
    props: {
        supplierKey: {
            required: true,
            type: Number,
        },
        category: {
            required: true,
            type: String,
        },
        promotionNamespace: String,
    },
    data() {
        return {
            fields: Object.freeze(fields),
            totalSpendType: totalSpend,
        };
    },
    computed: {
        ...mapGetters('supplierCommitments', [
            'getSupplierCommitmentsBySupplierKey',
            'isTotalSpendSection',
            'isSpecifiedAllocationsSection',
        ]),

        supplierCommitments() {
            return this.getSupplierCommitmentsBySupplierKey({
                supplierKey: this.supplierKey,
                category: this.category,
                promotionNamespace: this.promotionNamespace,
            });
        },

        formFields() {
            return [
                {
                    header: 'hierarchyLevel',
                    name: 'hierarchy.levelEntryDescription',
                    functionName: 'getCategoryNames',
                },
                {
                    header: 'scope',
                    name: 'scope',
                },
                {
                    fieldName: 'measurement',
                    type: fieldTypes.vuexRadioGroup,
                    options: getMeasurementsOptions(),
                    cssClass: 'supplier-commitments-form__radio-group',
                    row: true,
                },
                {
                    header: 'totalCurrent',
                    functionName: 'getTotalCurrentAllocations',
                },
                {
                    header: 'value',
                    functionName: 'getValue',
                },
                {
                    header: 'period',
                    functionName: 'getPeriod',
                },
            ];
        },
    },
    created() {
        // Reset the supplier commitment id so no commitment is expanded
        // when you first open the commitments viewer.
        this.setSelectedSupplierCommitmentId({ supplierCommitmentId: null });
    },
    methods: {
        ...mapActions('supplierCommitments', [
            'setSelectedSupplierCommitmentId',
            'addNote',
            'deleteNote',
            'updateNote',
        ]),
        ...mapActions('promotions', ['fetchPromotionsForSupplierCommitment']),

        async onCommitmentClick({ id }) {
            await this.fetchPromotionsForSupplierCommitment(id);

            this.setSelectedSupplierCommitmentId({
                supplierCommitmentId: id,
            });
        },

        postNote(params, note) {
            this.addNote({
                note,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },

        removeNote(params, noteId) {
            this.deleteNote({
                noteId,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },

        saveNote(params, note) {
            this.updateNote({
                note,
                id: get(params, 'context._id'),
                fieldKey: NotesFieldKeys.root,
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.supplier-commitments {
    padding: 1rem;
}

.supplier-commitments-grid {
    display: grid;
    grid-template-columns: 20rem auto 30rem 15rem 15rem 30rem 5rem 5rem 6rem [last-column];
    color: $promo-text-colour;

    .supplier-commitments-grid__header {
        padding: 0.6rem 1rem;
    }

    &::v-deep {
        .supplier-commitment-viewer {
            & > div:first-child {
                .supplier-commitment-viewer__grid-cell {
                    font-weight: 400;
                }
            }
        }

        .supplier-commitments-grid__header + .supplier-commitment-viewer-wrapper {
            .supplier-commitment-viewer__grid-cell {
                border-top-color: $promo-grey;
            }
        }
    }
}
</style>
