import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "planning.copyPromotion.heading",
      "btn-confirm-text": "actions.copy",
      "has-activator": _vm.hasActivator,
      "confirm-btn-disabled": !_vm.selectedScenarioId || _vm.copyInProgress,
    },
    on: { confirm: _vm.copyPromotionDialogConfirm, close: _vm.closeDialog },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    "icon",
                    _vm._g(
                      {
                        attrs: { "icon-name": "duplicate", small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      activator
                    )
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _c("div", { staticClass: "copy-promotion" }, [
                _c(
                  "div",
                  { staticClass: "copy-promotion__description" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.copyPromotion.description")
                          )
                        ) +
                        "\n                "
                    ),
                    _c(
                      VTooltip,
                      {
                        attrs: { "z-index": "400", top: "", "max-width": 500 },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  VIcon,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { color: "primary", size: "20" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                            mdi-information-outline\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t("planning.copyPromotion.tooltip")
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "copy-campaign__form-item select-resource" },
                  [
                    _c("label", { staticClass: "select-resource__label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("planning.copyPromotion.campaign")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "select-resource__select",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t(
                            "planning.copyPromotion.placeholder.selectCampaign"
                          )
                        ),
                        options: _vm.editableCampaigns,
                        filled: "",
                        getter: () => _vm.selectedCampaignId,
                        setter: (value) => _vm.setSelectedCampaign(value),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "copy-campaign__form-item select-resource" },
                  [
                    _c("label", { staticClass: "select-resource__label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("planning.copyPromotion.subCampaign")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "select-resource__select",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t(
                            "planning.copyPromotion.placeholder.selectSubcampaign"
                          )
                        ),
                        options: _vm.editableSubCampaigns,
                        disabled: !_vm.selectedCampaignId,
                        filled: "",
                        getter: () => _vm.selectedSubCampaignId,
                        setter: (value) => _vm.setSelectedSubCampaign(value),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "copy-campaign__form-item select-resource" },
                  [
                    _c("label", { staticClass: "select-resource__label" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("planning.copyPromotion.scenario")
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "select-resource__select",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t(
                            "planning.copyPromotion.placeholder.selectScenario"
                          )
                        ),
                        options: _vm.editableScenarios,
                        disabled: !_vm.selectedSubCampaignId,
                        filled: "",
                        getter: () => _vm.selectedScenarioId,
                        setter: (value) => _vm.setSelectedScenario(value),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }