const mixin = {
    data: () => ({
        mounted: false,
    }),

    mounted() {
        this.mounted = true;
    },
};

export default mixin;
