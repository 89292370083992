var render = function render(_c, _vm) {
  return _c("div", { staticClass: "scope" }, [
    _c("div", { staticClass: "scope__group-of-products" }, [
      _c("span", { staticClass: "scope__product-description" }, [
        _vm._v(_vm._s(_vm.props.scope.description)),
      ]),
      _vm._v(" "),
      _vm.props.scope.value
        ? _c("span", { staticClass: "scope__value" }, [
            _vm._v(
              _vm._s(
                _vm.$options.methods.getFormattedValue(
                  _vm.props.scope.value,
                  _vm.props.scope.numberFormatter
                )
              )
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.props.scope.products
      ? _c("div", { staticClass: "scope__individual-product" }, [
          _c(
            "ul",
            { staticClass: "scope__products" },
            _vm._l(_vm.props.scope.products, function (product, index) {
              return _c("li", { key: `${product.productKey}::${index}` }, [
                _c("span", { staticClass: "scope__product" }, [
                  _vm._v(_vm._s(product.name)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "scope__product-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$options.methods.getFormattedValue(
                        product.value,
                        _vm.props.scope.numberFormatter
                      )
                    )
                  ),
                ]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }