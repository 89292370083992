<template>
    <v-combobox
        ref="combobox"
        v-model="value"
        class="rtls-combobox"
        :items="options"
        :menu-props="{ bottom: true, offsetY: true }"
        :search-input.sync="searchInput"
        :item-text="itemText"
        :item-value="itemValue"
        dense
        allow-overflow
        @change="onChange()"
    >
        <template v-slot:no-data>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        {{
                            $t('general.combobox.noResultsPlaceholder', { searchInput })
                                | toSentenceCase
                        }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-combobox>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            options: [],
            searchInput: null,
            itemValue: null,
            itemText: null,
            value: '',
        };
    },
    created() {
        this.options = this.params.options;
        const { field, itemText, itemValue } = this.params.colDef;
        // Set initial value of autocomplete to be the existing value.
        const selectedOption = this.options.find(
            option => option[field] === this.params.data[field]
        );
        this.value = selectedOption || null;
        this.itemValue = itemValue;
        this.itemText = itemText;
    },
    mounted() {
        // Focus the input and show the menu by default to ensure the user
        // doesn't have to click multiple times within the grid cell.
        this.$nextTick(() => {
            this.$refs.combobox.focus();
            this.$refs.combobox.isMenuActive = true;
        });
    },
    methods: {
        onChange() {
            this.$refs.combobox.isMenuActive = false;
        },
        getValue() {
            return this.value;
        },
        isPopup() {
            return false;
        },
    },
});
</script>
