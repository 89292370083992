<template>
    <v-tooltip :disabled="hideTooltip" top>
        <template v-slot:activator="{ on: criteria, attrs }">
            <div
                ref="truncated"
                v-bind="attrs"
                class="truncate-text"
                v-on="criteria"
                @mouseover="checkTooltipRequired"
            >
                {{ params.valueFormatted || params.value }}
            </div>
        </template>
        {{ params.valueFormatted || params.value }}
    </v-tooltip>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            hideTooltip: null,
        };
    },
    methods: {
        checkTooltipRequired(event) {
            // scrollWidth includes content not visible due to overflow, whilst offsetWidth includes only visible content.
            // If these differ, then a tooltip is required to display the full text.
            const { offsetWidth, scrollWidth } = event.target;
            this.hideTooltip = offsetWidth === scrollWidth;
        },
    },
});
</script>
