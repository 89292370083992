import LoginPage from './login';

const routes = [
    {
        path: '/login',
        name: 'login',
        id: 'login',
        component: LoginPage,
        meta: { hideNavbar: true },
        omitFromNav: true,
    },
];

export default routes;
