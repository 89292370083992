import { filter, includes, find } from 'lodash';
import { isCategoryWidePromotion } from '@sharedModules/promotion-utils';

/**
 * Filters promotions to those where their scenario includes the required category
 * @param {Object} RORO - The RORO wrapper
 * @param {Array} RORO.promotions - The promotions to filter
 * @param {Array} RORO.scenarios - The scenarios the promotions belong to
 * @param {Number} RORO.categoryKey - The categoryKey to filter by.
 */
function filterByScenarioCategory({ promotions, scenarios, categoryKey }) {
    // Retrieve the scenario id's where there is a match between the scenario's categories,
    // and the categories of the selected areas.
    const filteredScenarioIds = scenarios
        .filter(scenario => includes(scenario.categories, categoryKey))
        .map(scenario => scenario._id);

    return filter(promotions, promotion => {
        return includes(filteredScenarioIds, promotion.scenarioId);
    });
}

/**
 * Filters promotions to those whose products include the required category
 * @param {Object} RORO - The RORO wrapper
 * @param {Array} RORO.promotions - The promotions to filter
 * @param {Number} RORO.categoryKey - The categoryKey to filter by.
 */
function filterByPromotionProductCategory({ promotions, scenarios, categoryKey, categoryLevel }) {
    // First filter to promotions for scenarios which include the target category.
    // This reduces the number of promotions whose products need to be filtered.
    const promotionsFilteredByScenario = filterByScenarioCategory({
        promotions,
        scenarios,
        categoryKey,
    });

    // Return promotions where at least one of it's product's category key matches the supplied value.
    return filter(promotionsFilteredByScenario, promotion => {
        return (
            isCategoryWidePromotion(promotion) ||
            promotion.products.some(product => {
                const { levelEntryKey } =
                    find(product.hierarchy, {
                        level: categoryLevel,
                    }) || {};
                return levelEntryKey === categoryKey;
            })
        );
    });
}

export default {
    filterByScenarioCategory,
    filterByPromotionProductCategory,
};
