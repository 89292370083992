import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "funding-changes-viewer" }, [
    _c(
      "div",
      { staticClass: "funding-changes-viewer__grid" },
      [
        _c("promo-ag-grid", {
          ref: "agGrid",
          attrs: {
            "row-data": _vm.rowData,
            "column-defs": _vm.columnDefs,
            "grid-options": _vm.gridOptions,
            "default-col-def": _vm.defaultColDef,
            "grid-style": _vm.gridStyle,
            "grid-class": _vm.gridClass,
            "dom-layout": "autoHeight",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "funding-changes-viewer__actions" },
      [
        _c(
          VBtn,
          {
            staticClass: "funding-changes-viewer__actions--button",
            attrs: { depressed: "", outlined: "" },
            on: {
              click: function ($event) {
                return _vm.acceptAll("parent")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("acceptAllParent"))) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          VBtn,
          {
            staticClass: "funding-changes-viewer__actions--button",
            attrs: { depressed: "", outlined: "" },
            on: {
              click: function ($event) {
                return _vm.acceptAll("child")
              },
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("acceptAllChild"))) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }