'use strict';

/**
 * This enum can be used to create temporary ids for when we are creating new resources.
 * For example when we are creating a new product group. We don't save this product group
 * straight away to get a legitimate mongoId.
 * However we still want to be able to assign products to it and change it's name before saving.
 * Having a temporary id until it is saved to mongo works for this use case.
 */
module.exports = {
    newProductGroupId: 'newProductGroupTempId',
};
