<template>
    <div v-if="isVisible" :class="{ 'select-container': true, disabled: isDisabled }">
        <select
            v-model="value"
            :name="`select-field::${params.colDef.fieldName}`"
            :class="{ 'ag-select-input': true, disabled: isDisabled }"
            :disabled="isDisabled"
        >
            <option selected :value="null">{{ valuePlaceholder }}</option>
            <option v-for="item in options" :key="item.value" :value="item.value">
                {{ item.name }}
            </option>
        </select>
        <icon class="select-arrow" icon-name="expand_more" />
    </div>
</template>

<script>
import Vue from 'vue';
import { get, has } from 'lodash';

export default Vue.extend({
    computed: {
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },

        isDisabled() {
            return (
                this.isReadOnly ||
                (has(this.params, 'isDisabled') ? this.params.isDisabled(this.params) : false)
            );
        },

        valuePlaceholder() {
            return get(this.params.value, 'placeholder') || this.params.placeholder;
        },

        options() {
            return has(this.params, 'getOptions') ? this.params.getOptions() : this.params.options;
        },

        value: {
            get() {
                return this.params.data.isSupplier
                    ? get(this.params.value, 'supplierValue', null)
                    : this.params.value;
            },

            set(value) {
                this.setValue(value);
            },
        },
    },

    methods: {
        setValue(value) {
            const oldValue = this.params.data.isSupplier
                ? get(this.params.value, 'supplierValue', null)
                : this.params.value;

            const params = {
                oldValue,
                newValue: value,
                data: this.params.data,
                colDef: {
                    fieldName: this.params.colDef.fieldName,
                    fieldPath: this.params.colDef.fieldPath,
                },
            };
            this.$nextTick(() => {
                this.params.onInputChange(params);
            });
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 2.5rem;

    .ag-select-input,
    .ag-label {
        display: flex;
        width: 100%;
        height: 2.5rem;

        padding-left: 0.5rem;

        &.disabled {
            background-color: transparent !important;
        }
    }

    ::v-deep.select-arrow {
        position: absolute;
        right: 1.5rem;
        padding-top: 0.25rem;
        font-size: 1.8rem;

        color: $promo-primary-colour;

        pointer-events: none;
    }
}
</style>
