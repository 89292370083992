<template functional>
    <svg
        width="11px"
        height="11px"
        viewBox="0 0 11 11"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Small Icon / Publish</title>
        <g id="Small-Icon-/-Publish" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
                id="Fill-46"
                d="M4.27777706,3.39999154 L4.27777706,1 L0,5.19999154 L4.27777706,9.39999577 L4.27777706,6.93999239 C7.33332472,6.93999239 9.47221971,7.90000423 11,10 C10.3888853,7 8.55555412,4 4.27777706,3.39999154 Z"
                fill="#FFFFFF"
                transform="translate(5.500000, 5.500000) scale(-1, 1) translate(-5.500000, -5.500000) "
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Publish',
};
</script>
