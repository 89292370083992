var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detailed-provisions-grid" }, [
    _c("div", { staticClass: "detailed-provisions-grid__headers" }, [
      _c("span", [
        _vm._v(
          _vm._s(
            _vm._f("toSentenceCase")(
              _vm.$t(
                "planning.promotionsMaintenance.resources.additionalResourceDetails.resource"
              )
            )
          ) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm._f("toSentenceCase")(
              _vm.$t(
                "planning.promotionsMaintenance.resources.additionalResourceDetails.provision"
              )
            )
          ) + "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detailed-provisions-grid__rows" },
      _vm._l(
        _vm.promotionDetailedProvisionsDisplay,
        function (promotionDetailedProvision) {
          return _c(
            "div",
            {
              key: `${promotionDetailedProvision.resourceType}::${promotionDetailedProvision.instanceKey}::${promotionDetailedProvision.selectedProvisionOption}`,
              staticClass: "row",
              class: {
                "row--highlighted":
                  _vm.highlightedInstanceKey ===
                    promotionDetailedProvision.instanceKey &&
                  _vm.highlightedInstanceKey != null,
              },
              on: {
                mouseover: function ($event) {
                  return _vm.setHighlightedInstanceKey({
                    instanceKey: promotionDetailedProvision.instanceKey,
                  })
                },
                mouseleave: function ($event) {
                  return _vm.setHighlightedInstanceKey({ instanceKey: null })
                },
              },
            },
            [
              _c("span", { staticClass: "row__channel-cell" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      promotionDetailedProvision.resourceType
                        ? _vm.$t(
                            `preparation.promoResources.${promotionDetailedProvision.resourceType}`
                          )
                        : ""
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "row__provisions-cell" },
                [
                  _c("vuex-select", {
                    attrs: {
                      placeholder: _vm._f("toSentenceCase")(
                        _vm.$tkey("placeholder.selectProvision")
                      ),
                      multiple: false,
                      "edit-mode": false,
                      options:
                        promotionDetailedProvision.detailedProvisionOptions,
                      getter: () =>
                        promotionDetailedProvision.selectedProvisionOption,
                      setter: (value) =>
                        _vm.setSelectedDetailedProvision({
                          resourceType: promotionDetailedProvision.resourceType,
                          instanceKey: promotionDetailedProvision.instanceKey,
                          provisionIndex:
                            promotionDetailedProvision.provisionIndex,
                          newValue: value,
                        }),
                      disabled: _vm.isDisabled,
                      filled: "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "row__delete-cell" },
                [
                  _c("common-delete-dialog", {
                    attrs: {
                      resource: _vm.detailedProvisionsResource,
                      "child-dependencies-warning": false,
                      "make-read-only": _vm.isDisabled,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.onDelete({
                          resourceType: promotionDetailedProvision.resourceType,
                          instanceKey: promotionDetailedProvision.instanceKey,
                          provisionIndex:
                            promotionDetailedProvision.provisionIndex,
                        })
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }