import { merge } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    bookmarks: [],
    currentReportState: null,
    bookmarkToLoad: null,
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getBookmarkById
     */
    getters: {
        getCurrentReportState: state => state.currentReportState,
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setBookmarks
     * - deleteBookmark
     * - updateBookmark
     * - addBookmark
     * - resetState
     */
    mutations: {
        setCurrentReportState(state, currentReportState) {
            state.currentReportState = currentReportState;
        },
    },

    /**
     * Default actions available:
     * - fetchBookmarks
     * - createBookmark
     * - deleteBookmark
     * - updateBookmark
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'bookmark',
    getInitialState,
    useForm: true,
};

export default merge({}, storeMixin(mixinParams), store);
