<template>
    <div class="footer-renderer">
        <div
            class="total"
            :class="{
                valid: isValid && selectedRateCardIdForSplitting,
                invalid: !isValid && selectedRateCardIdForSplitting,
            }"
        >
            <vuex-currency-input
                :getter="() => formattedValue(lumpSumTotal)"
                :disabled="true"
                filled
            />
        </div>
        <simple-button :disabled="isAddTotalDisabled" @onClick="addToTotal()">
            {{ $tkey('buttons.addToTotal') }}
        </simple-button>
    </div>
</template>

<script>
import Vue from 'vue';
import { get, isNil } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { allowZeroRateCardAmount } from '@enums/feature-flags';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.funding',
    computed: {
        ...mapState('rateCards', ['lumpSumTotal', 'selectedRateCardIdForSplitting']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['selectedPromotion']),
        isAddTotalDisabled() {
            if (!this.selectedRateCardIdForSplitting) return true;
            return !this.isValid;
        },
        isValid() {
            const selectedRateCard = this.getRateCardById(this.selectedRateCardIdForSplitting);
            if (!selectedRateCard) return false;
            if (!this.toggleLogic[allowZeroRateCardAmount] && selectedRateCard.amount === 0) {
                return false;
            }
            return this.lumpSumTotal === selectedRateCard.amount;
        },
    },
    methods: {
        ...mapMutations('rateCards', ['setSelectedRateCardIdForSplitting']),
        getRateCardById(rateCardId) {
            // When a new promotion is created in the parking lot it won't yet
            // exist in the promotions state, however it will exist in the
            // staging area.
            const promotion = get(
                this.$store.state,
                `promotions.stagingArea.${this.params.namespace}`
            );
            return get(promotion, 'rateCards', []).find(card => card._id === rateCardId);
        },
        addToTotal() {
            this.params.context.supplierGrid.addLumpFundingTotal();
        },
        formattedValue(value) {
            if (isNil(value)) return null;
            return this.$n('numbers.default.currency', value, {
                usePlaceholder: true,
            });
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.footer-renderer {
    display: flex;
    flex-direction: row;

    .total {
        @include flex-row;
        width: 8rem;
        margin: 0 2rem 0 0;

        &::v-deep {
            .vuex-currency-input__currency-symbol {
                background-color: transparent;
                color: $promo-black;
                height: 2rem;
            }
            .v-input__slot {
                height: 1.8rem;
                background-color: $promo-table-grey-bg-colour;

                input {
                    color: $promo-black;
                }
            }
        }
    }

    .valid {
        border-bottom: $rtls-postive-action-colour 0.2rem solid;
    }

    .invalid {
        border-bottom: $rtls-negative-action-colour 0.2rem solid;
    }
}
</style>
