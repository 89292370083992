import axios from 'axios';
import { to } from 'await-to-js';
import { debounce, get, isEmpty, set, omit } from 'lodash';
import UXEvents from '@enums/ux-events';
import forecastTypes from '@enums/forecast-types';
import {
    getPromotionTotalVolume,
    isCategoryOrStoreWidePromotion,
} from '@sharedModules/promotion-utils';
import storeMixin from '../mixins/vuex-store';
import i18n from '../../vue-i18n';

const getInitialState = () => ({
    forecasting: [],
    jsonPromotionResponse: {},
    jsonPromotionError: {},
    transformedPromotion: {},
});

const params = {
    resource: 'forecasting',
    readOnly: true,
};

const {
    mutations: { resetState },
    actions: { handleResponseNotifications, resetState: resetStateAction },
} = storeMixin(params);

const store = {
    namespaced: true,

    state: getInitialState(),

    getters: {},

    mutations: {
        resetState,
        setJsonPromotionResponse(state, res) {
            state.jsonPromotionResponse = res;
        },
        setJsonPromotionError(state, err) {
            state.jsonPromotionError = err;
        },
        setTransformedPromotion(state, transformedPromotion) {
            state.transformedPromotion = transformedPromotion;
        },
    },

    actions: {
        // forecastSinglePromotion is debounced to prevent multiple simultaneous calls from running.

        async forecastSinglePromotion(
            { dispatch },
            { promotion, callbackEvent, resetForecasting }
        ) {
            return dispatch('debouncedForecastSinglePromotion', {
                // no need to update execution field from FE it should be updated only from the callback
                promotion: omit(promotion, 'execution'),
                callbackEvent,
                resetForecasting,
            });
        },

        debouncedForecastSinglePromotion: debounce(
            async function(
                { commit, dispatch },
                {
                    promotion,
                    callbackEvent = UXEvents.promotionForecasted,
                    resetForecasting = false,
                }
            ) {
                commit('promotions/setSaveInProgress', true, {
                    root: true,
                });

                const maxRetries = 3;
                let retries = 0;
                let result;
                let error;
                // We'll run up to 3 forecasting retries in case there's issues
                // with the nginx ingress or the BE availability
                while (retries < maxRetries) {
                    // eslint-disable-next-line
                    const [forecastingError, forecastingResult] = await to(
                        axios.post('/api/forecasting/forecastSinglePromotion', {
                            promotion,
                            resetForecasting,
                        })
                    );

                    error = forecastingError;
                    result = forecastingResult;

                    if (error && [502, 503, 504].includes(get(error, 'response.status'))) {
                        retries += 1;
                    } else break;
                }

                const errorTranslationKey = get(
                    error,
                    'response.data.errorTranslationKey',
                    'forecasting.notifications.error'
                );
                const messageKey = get(error, 'response.data.messageKey');
                const isForecastingAborted =
                    messageKey === 'general.errors.forecastingRequestOlderThanCache';

                if (!isForecastingAborted) {
                    dispatch('handleResponseNotifications', {
                        error,
                        response: result,
                        successMessage: i18n.t(`forecasting.notifications.success`),
                        errorMessage: i18n.t(errorTranslationKey),
                    });
                }

                let promotionWithUpdatedForecasts;
                if (!error) {
                    promotionWithUpdatedForecasts = result.data;
                    commit(
                        'promotions/setPromotionForecastingResults',
                        promotionWithUpdatedForecasts,
                        {
                            root: true,
                        }
                    );
                } else {
                    const promotions = await dispatch(
                        'promotions/fetchPromotions',
                        {
                            params: {
                                where: {
                                    _id: promotion._id,
                                },
                            },
                            returnDocuments: true,
                        },
                        { root: true }
                    );
                    promotionWithUpdatedForecasts = promotions[0];

                    commit('promotions/setPromotionForecast', promotionWithUpdatedForecasts, {
                        root: true,
                    });
                }
                commit(
                    'promotions/updatePromotion',
                    {
                        id: promotionWithUpdatedForecasts._id,
                        updates: promotionWithUpdatedForecasts,
                    },
                    { root: true }
                );
                if (!error) {
                    const globalEvent = promotionWithUpdatedForecasts.isInParkingLot
                        ? UXEvents.parkingLotSaved
                        : UXEvents.promotionSaved;
                    // namespace required for some global event handlers e.g. updateOfferMechanicDescription
                    this.$app.globalEmit(globalEvent, {
                        ...promotionWithUpdatedForecasts,
                        namespace: promotion._id,
                    });
                }

                commit(
                    'promotions/setUnsavedPromotion',
                    { namespace: promotion._id, tab: 'all', value: false },
                    {
                        root: true,
                    }
                );

                commit('promotions/setSaveInProgress', false, {
                    root: true,
                });

                const forecastResult = { error, result: get(result, 'data', null) };

                this.$app.globalEmit(callbackEvent, {
                    namespace: promotion._id,
                    forecastResult,
                });

                return forecastResult;
            },
            // Forecasting is delayed until there has been at least 300ms since the last forecasting call.
            300,
            {
                // Leading 'false' means that forecastSinglePromotion will wait until the specified debounce time has
                // passed before it runs. This means if there are multiple calls before the debounce time has passed, each of
                // those calls will return undefined. The final call will execute the function and then any subsequent calls
                // made to forecasting, but before the next execution, will use the previously calculated value. Having this
                // as false stops issues with forecasting running twice and subsequently saving twice (which may trigger
                // a large number of API calls on each save).
                leading: false,
                trailing: true,
            }
        ),

        forecastSinglePromotionById(
            { dispatch, rootGetters },
            { namespace: promotionId, resetForecasting = false }
        ) {
            const promotionForForecasting = rootGetters['promotions/getPromotionForForecasting']({
                promotionId,
            });

            if (isCategoryOrStoreWidePromotion(promotionForForecasting)) {
                this.$app.globalEmit(UXEvents.promotionUpdated, {
                    namespace: promotionForForecasting._id,
                });
                return;
            }

            return dispatch('forecastSinglePromotion', {
                promotion: promotionForForecasting,
                resetForecasting,
            });
        },

        resetForecastingForSinglePromotionById({ dispatch }, { namespace: promotionId }) {
            return dispatch('forecastSinglePromotionById', {
                namespace: promotionId,
                resetForecasting: true,
            });
        },

        updateForecastReferenceById({ dispatch, rootGetters }, { namespace: promotionId }) {
            const promotionToUpdate = rootGetters['promotions/getPromotionById'](promotionId);
            if (isCategoryOrStoreWidePromotion(promotionToUpdate)) {
                return;
            }
            const { forecasts, products } = promotionToUpdate;
            // Apply reference forecasts at promotion level
            forecasts.reference = isEmpty(forecasts.override)
                ? forecasts.algorithm
                : forecasts.override;
            // Apply reference forecasts at product level
            products.forEach(p => {
                p.forecasts = {
                    ...p.forecasts,
                    reference: isEmpty(p.forecasts.override)
                        ? p.forecasts.algorithm
                        : p.forecasts.override,
                };
            });
            // Aggregate reference volume forecasts at promotion level
            set(
                forecasts.reference,
                'promotion.totalVolume',
                getPromotionTotalVolume({
                    forecastType: forecastTypes.reference,
                    products,
                })
            );

            // Apply current totalVolume at promotion level
            set(
                forecasts.reference,
                'promotion.totalVolume',
                getPromotionTotalVolume({
                    forecastType: forecastTypes.reference,
                    products,
                })
            );

            return dispatch(
                'promotions/updatePromotion',
                { id: promotionId, updates: { forecasts, products } },
                { root: true }
            );
        },

        handleResponseNotifications,
        resetState: resetStateAction,

        async submitJson({ commit, dispatch }, { json }) {
            const [error, response] = await to(
                axios.post('/api/forecasting/forecastTransformedPromotion', {
                    json,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`forecasting.notifications.successJson`),
                errorMessage: i18n.t(`forecasting.notifications.errorJson`),
            });
            commit('setJsonPromotionResponse', omit(response, ['request']) || {});
            if (error) {
                commit(
                    'setJsonPromotionError',
                    {
                        ...omit(error, ['request']),
                        response: omit(error.response, ['request']),
                    } || {}
                );
            } else {
                commit('setJsonPromotionError', {});
            }
        },

        async transformPromotion({ commit, dispatch }, { json }) {
            const [error, response] = await to(
                axios.post('/api/forecasting/transformPromotion', {
                    json,
                })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`forecasting.notifications.successTransform`),
                errorMessage: i18n.t(`forecasting.notifications.errorTransform`),
            });
            commit('setTransformedPromotion', get(response, 'data', {}) || {});
            commit('setJsonPromotionResponse', omit(response, ['request']) || {});
            if (error) {
                commit(
                    'setJsonPromotionError',
                    {
                        ...omit(error, ['request']),
                        response: omit(error.response, ['request']),
                    } || {}
                );
                throw error;
            } else {
                commit('setJsonPromotionError', {});
            }

            return get(response, 'data');
        },

        clearTestingValues({ commit }) {
            commit('setJsonPromotionError', {});
            commit('setJsonPromotionResponse', {});
            commit('setTransformedPromotion', {});
        },
    },
};

export default store;
