var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("promotion-copy-dialog", {
        key: `copyPromotionDialog::${_vm.promotion._id}`,
        attrs: { promotions: [_vm.promotion] },
      }),
      _vm._v(" "),
      _vm.promotion.parentPromotionId
        ? _c("child-promotion-ghost-dialog", {
            key: `childPromotionDeclineDialog::${_vm.promotion._id}`,
            attrs: {
              "promotion-id": _vm.promotion._id,
              "is-ghost": _vm.promotion.isGhost,
              "ignore-parent-read-only": true,
            },
          })
        : _c("common-delete-dialog", {
            attrs: {
              resource: _vm.promotionsEnum,
              "resource-id": _vm.promotion._id,
              children: _vm.promotion.children,
              "has-been-approved": _vm.hasBeenApproved(_vm.promotion),
              "child-dependencies-warning": false,
              "ignore-parent-read-only": true,
            },
          }),
      _vm._v(" "),
      _c("main-expand-button", {
        attrs: {
          "is-expanded": _vm.isExpanded,
          "expanded-icon": "expand_less",
          "collapsed-icon": "expand_more",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }