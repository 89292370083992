<template>
    <div class="store-provision-grid-container">
        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 35rem;"
            grid-class="ag-theme-custom__candidates-grid"
            dom-layout="normal"
            :single-click-edit="true"
            :make-read-only="promotionEditingState.disabled"
            :read-only-reason="promotionEditingState.reason"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import AgProductAutocomplete from '@/js/components/promo-ag-grid/ag-product-autocomplete';
import i18n from '@/js/vue-i18n';

export default {
    localizationKey: 'planning.promotionsMaintenance.resources.featuredProducts.gridHeadings',
    props: {
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
        namespace: {
            required: true,
            type: String,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            rowData: [],
            gridApi: null,
            // Defines the attributes which apply to all columns defined in columnDefs.
            defaultColDef: {
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
            },
            gridOptions: {
                rowHeight: 41, // Specified in pixels.
                applyColumnDefOrder: true, // apply default order of columns when colDef is updated
                suppressHorizontalScroll: true, // Stops an issue with two horizontal scrollbars appearing.
                rowBuffer: 60,
                getRowNodeId: data => data.productKey,
            },
        };
    },

    events: {
        onCandidateRemovedFromPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
        onCandidateAddedToPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapGetters('clientConfig', ['getSecondaryPlacements', 'getSecondaryPlacementByKey']),

        dataForGrid() {
            // Return all products (including ones contained within product groups) currently in the promotion.
            return this.selectedPromotion.products;
        },

        columnDefs() {
            const columns = [
                {
                    headerName: toSentenceCase(this.$tkey(`productKey`)),
                    field: 'clientProductKey',
                    cellClass: ['product-information'],
                    maxWidth: 90,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`productName`)),
                    field: 'name',
                    cellClass: ['product-information', 'product-information__name'],
                    maxWidth: 200,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`brandName`)),
                    field: 'brandDescription',
                    cellClass: ['product-information'],
                    maxWidth: 105,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`supplierName`)),
                    field: 'supplierName',
                    cellClass: ['product-information'],
                    maxWidth: 120,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`sizeAndUnits`)),
                    valueGetter: this.getFormattedProductSizeAndUnit,
                    cellClass: ['product-information', 'product-information--last-column'],
                    maxWidth: 90,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`provision`)),
                    field: 'secondaryPlacement',
                    cellRendererFramework: AgProductAutocomplete,
                    cellRendererParams: {
                        options: this.getSecondaryPlacements(),
                        itemText: 'description',
                        itemValue: 'key',
                        clearable: true,
                        onChange: ({ productKey, model }) => {
                            this.setProductSecondaryPlacement({
                                productKey,
                                value: this.getSecondaryPlacementByKey(model),
                                namespace: this.namespace,
                            });
                        },
                    },
                    maxWidth: 160,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`incrementalSales`)),
                    field: 'forecastingAggregations.product.incrementalSalesExcTax',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__incremental'],
                    maxWidth: 95,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`salesEfficiency`)),
                    field: 'forecastingAggregations.product.salesEfficiency',
                    valueFormatter: this.formatCellValueAsDecimal,
                    cellClass: ['metrics', 'metrics__margin'],
                    maxWidth: 105,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`incrementalMargin`)),
                    field: 'forecastingAggregations.product.incrementalMargin',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__incremental'],
                    maxWidth: 105,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`marginEfficiency`)),
                    field: 'forecastingAggregations.product.marginEfficiency',
                    valueFormatter: this.formatCellValueAsDecimal,
                    cellClass: ['metrics', 'metrics__margin'],
                    maxWidth: 165,
                },
            ];

            if (isEmpty(this.hiddenColumns)) {
                return columns;
            }

            return columns.filter(column => !this.hiddenColumns.includes(column.field));
        },

        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },

        promotionParentSubCampaign() {
            return this.getSubCampaignById({
                _id: this.selectedPromotion.subCampaignId,
                usePluralResourceName: true,
            });
        },
    },

    methods: {
        ...mapActions('promotions', ['setProductSecondaryPlacement']),

        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.setRowData(this.dataForGrid);
        },

        formatCellValueAsCurrency(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'value' property is the value pulled out by ag-grid for the 'field' defined in the columnDefs.
            return i18n.n('numbers.default.currencyShorthand', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsDecimal(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'value' property is the value pulled out by ag-grid for the 'field' defined in the columnDefs.
            return i18n.n('numbers.default.numberWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },

        getFormattedProductSizeAndUnit(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'data' property represents the object being used to render the row.
            // This reflects a single object within the array returned by the
            // rowData() computed (in our case, that's a promotion product).
            if (params.data.packSize !== undefined && params.data.packUnit) {
                return `${params.data.packSize}${params.data.packUnit}`;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.store-provision-grid-container {
    border-bottom: solid;
    border-bottom-width: 0.75rem !important;
    border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;

    .ag-theme-custom {
        &__candidates-grid {
            &::v-deep .ag-row {
                height: 3.6rem !important;
            }
        }
    }
}
</style>
