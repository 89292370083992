<template>
    <vuex-currency-input
        :getter="() => params.value"
        :setter="value => setValue(value)"
        filled
        :disabled="isDisabled"
        :class="params.cellClass"
        :validations="validationRules"
        :format="
            generalConfig.displaySupplierPrices3dp
                ? 'numbers.default.currencyNoLabelPadded3dp'
                : 'numbers.default.currencyNoLabelPadded'
        "
        is-validate-number
    />
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
    computed: {
        ...mapState('clientConfig', ['generalConfig']),

        isDisabled() {
            const data = this.params.data;
            return this.params.isDisabled(data);
        },
        validationRules() {
            return this.params.getValidationRules(this.params);
        },
    },

    methods: {
        setValue(value) {
            const colId = this.params.column.colId;
            this.$nextTick(() => {
                this.params.node.setDataValue(colId, value);
            });
        },
    },
});
</script>
