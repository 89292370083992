'use strict';

/**
 * Factory for workflow utility functions for working with workflows.
 *
 * @module shared-modules/workflow-utils-factory
 */

const {
    every,
    map,
    size,
    find,
    get,
    forOwn,
    groupBy,
    keyBy,
    intersection,
    filter,
    difference,
    flatMap,
} = require('lodash');

const taskDefinitions = require('./workflow/task-definitions');
const promoResources = require('./data/enums/promo-resources');

const workflowUtilsFactory = function(moment) {
    /**
     * Create a date relative to a supplied start date using config.
     *
     * @param {Date} startDate - The start date to create the relative date from.
     * @param {Object} relativeDateTimeConfig - Config object used to create the relative date.
     */
    const convertRelativeDate = ({ startDate, relativeDateTimeConfig }) => {
        return moment(startDate)
            .subtract(relativeDateTimeConfig.noOfDaysBeforeStart, 'days')
            .hour(relativeDateTimeConfig.hours)
            .minutes(relativeDateTimeConfig.minutes)
            .toISOString();
    };

    /**
     * Aggregates an array of workflow states to a single array.
     * This is done through the logical conjunction of each state object, so that
     * a state is true in the aggregated result if and only if it is true in
     * all individual states.
     *
     * @param {Array<Object>} workflowStates - The workflow states to aggregate.
     */
    const combineStates = childWorkflowStates => {
        // If there are no childWorkflowStates to aggregate, then return an empty array.
        if (size(childWorkflowStates) === 0) return [];

        // Use the first childWorkflowState as a seed to verify if its containing
        // states exist across all childWorkflowStates.
        return map(childWorkflowStates[0], childWorkflowState => {
            return {
                entity: childWorkflowState.entity,
                state: childWorkflowState.state,
                value: every(childWorkflowStates, workflowState => {
                    return find(workflowState, {
                        entity: childWorkflowState.entity,
                        state: childWorkflowState.state,
                        value: true,
                    });
                }),
            };
        });
    };

    /**
     * Checks if the workflow step can be completed based on the current state and
     * the pre-requisites for the step and it's task.
     *
     * @param {Object} RORO.step -The step in the workflow to be checked.
     * @param {Object} RORO.workflowState - The current workflow state.
     * @param {Object} RORO.parentWorkflowState - The parent's current workflow state, may be null
     */
    const canStepBeCompleted = ({ step, workflowState, getParentWorkflowState }) => {
        // Retrieve the task definition associated with the workflow step.
        const task = taskDefinitions[step.entity][step.task];

        // Get the completed state from workflowState if it exists.
        const completedState = find(workflowState, {
            state: task.completionIndicator,
            entity: task.entity,
            value: true,
        });

        // If the task has already been completed then we can return false here
        // as the task should not be actionable again.
        if (completedState) return false;

        // Bring together all the required states from the requirements and pre-requisites.
        const allRequiredStates = [...task.requirements, ...step.prerequisiteStates];

        // The step can be completed if all the required states are satisfied by the workflow state.
        let canTaskBeCompleted = every(allRequiredStates, requiredState => {
            return find(workflowState, {
                entity: requiredState.entity,
                state: requiredState.state,
                value: true,
            });
        });

        // If the task can be completed based on it's pre-requisites, check if the
        // parent pre-requisites are met, if there are any and a parent exists.
        if (canTaskBeCompleted && getParentWorkflowState && size(step.parentPrerequisiteStates)) {
            const parentWorkflowState = getParentWorkflowState();

            // parentWorkflowState will be falsy if the parent does not exist.
            if (parentWorkflowState) {
                canTaskBeCompleted = every(step.parentPrerequisiteStates, requiredState => {
                    return find(parentWorkflowState, {
                        entity: requiredState.entity,
                        state: requiredState.state,
                        value: true,
                    });
                });
            }
        }

        return canTaskBeCompleted;
    };

    /**
     * Checks whether the given workflow template step is applicable for the supplied sub-campaign.
     *
     * @param {Object} RORO.templateStep - The workflow step as defined on the workflow template.
     * @param {Object} RORO.subCampaign - The sub-campaign which the workflow step will potentially be added to.
     */
    const isWorkflowStepApplicable = ({ templateStep, subCampaign }) => {
        // Check whether the entity (e.g. "leaflet", "promotion") is a promo resource.
        if (promoResources[templateStep.entity]) {
            // Check whether there is a resource present on the sub-campaign for the given entity and that
            // this resource has at least one instance.
            const subCampaignHasResource = subCampaign.resources.some(
                resource => resource.type === templateStep.entity && size(resource.instances) > 0
            );

            // When there's no resource for the entity (e.g. no "leaflet" resources), then any workflow steps
            // associated with that resource are not applicable and should not be included in the workflow.
            if (!subCampaignHasResource) {
                return false;
            }
        }

        return true;
    };

    /**
     * Create a workflow instance based on a workflow template.
     *
     * @param {Object} workflowTemplate - The workflow template to create the workflow instance with.
     * @param {Object} subCampaign - The sub campaign the workflow instance is being added to.
     */
    const convertWorkflowTemplateToInstance = ({
        workflowTemplate,
        subCampaign,
        getCategoryKeysForHierarchyNode,
    }) => {
        const categoryGroups = workflowTemplate.hierarchyGroups.map(hierarchyGroup => {
            const categories = flatMap(hierarchyGroup.hierarchyNodes, hierarchyNode =>
                getCategoryKeysForHierarchyNode(hierarchyNode)
            );
            return {
                name: hierarchyGroup.name,
                categories: intersection(categories, subCampaign.categories),
            };
        });

        const startDateTime = moment(subCampaign.startDate);

        const subCampaignCategories = subCampaign.categories;

        return {
            workflowTemplateKey: workflowTemplate.key,
            categoryGroups,
            steps: workflowTemplate.steps
                .filter(step =>
                    isWorkflowStepApplicable({
                        templateStep: step,
                        subCampaign,
                    })
                )
                .map(step => {
                    const deadlineDateTime = convertRelativeDate({
                        startDate: startDateTime,
                        relativeDateTimeConfig: step.deadline,
                    });

                    const dueDateTime = convertRelativeDate({
                        startDate: startDateTime,
                        relativeDateTimeConfig: step.dueDateTime,
                    });

                    let categories;

                    if (step.hierarchyGroup) {
                        categories = (
                            categoryGroups.find(group => group.name === step.hierarchyGroup) || {}
                        ).categories;
                    } else {
                        const matchingSteps = filter(workflowTemplate.steps, {
                            task: step.task,
                            entity: step.entity,
                        });

                        const allocatedCategories = flatMap(matchingSteps, matchingStep => {
                            return (
                                categoryGroups.find(
                                    group => group.name === matchingStep.hierarchyGroup
                                ) || {}
                            ).categories;
                        });

                        categories = difference(subCampaignCategories, allocatedCategories);
                    }

                    return {
                        task: step.task,
                        entity: step.entity,
                        alerts: map(
                            step.alerts,
                            ({ alertMessageKey, triggerPoint, priority, userRoles }) => {
                                return {
                                    alertMessageKey,
                                    alertDateTime: convertRelativeDate({
                                        startDate: deadlineDateTime,
                                        relativeDateTimeConfig: triggerPoint,
                                    }),
                                    priority,
                                    userRoles,
                                };
                            }
                        ),
                        hierarchyGroup: step.hierarchyGroup || undefined,
                        categories,
                        earliestCompletionDate: step.earliestStartPoint
                            ? convertRelativeDate({
                                  startDate: startDateTime,
                                  relativeDateTimeConfig: step.earliestStartPoint,
                              })
                            : null,
                        deadlineDateTime,
                        dueDateTime,
                        prerequisiteStates: step.prerequisiteStates,
                        parentPrerequisiteStates: step.parentPrerequisiteStates,
                    };
                }),
        };
    };

    const getChildCategoryKeysForHierarchyNode = (
        { level, levelEntryKey },
        { hierarchy, categoryLevel }
    ) => {
        if (level === categoryLevel) return levelEntryKey;

        // Level < categoryLevel
        // Hierarchy node is higher than category level, so return the categories for
        // each of it's children.
        const childHierarchies = filter(hierarchy, {
            level: level + 1,
            parentId: levelEntryKey,
        });

        return flatMap(childHierarchies, childHierarchy =>
            getChildCategoryKeysForHierarchyNode(childHierarchy, {
                hierarchy,
                categoryLevel,
            })
        );
    };

    function determineClientState({ entityToCheck, workflowState, clientStateMap }) {
        const groupedWorkflowState = groupBy(workflowState, 'entity');
        const keyedWorkflowState = {};
        forOwn(groupedWorkflowState, (value, key) => {
            keyedWorkflowState[key] = keyBy(value, 'state');
        });

        const clientState = clientStateMap.find(cs => {
            const propertyCheck = every(cs.property, property => {
                // Check property matches value on object being tested
                if (property.operation === 'equal') {
                    return get(entityToCheck, property.path) === property.value;
                }
            });

            if (!propertyCheck) return; // exit early

            const workflowCheck = every(cs.workflowState, ({ entity, state, value }) => {
                const matchingState = get(keyedWorkflowState, [entity, state], {
                    value: false,
                });

                return matchingState.value === value;
            });
            return workflowCheck;
        });
        return clientState ? clientState.state : undefined;
    }

    return {
        combineStates,
        canStepBeCompleted,
        convertWorkflowTemplateToInstance,
        taskDefinitions,
        determineClientState,
        getChildCategoryKeysForHierarchyNode,
    };
};

module.exports = workflowUtilsFactory;
