<template>
    <div class="subtype">
        <vuex-select
            class="subtype__select"
            :placeholder="$t('planning.nominations.selectSubType') | toSentenceCase"
            :options="options"
            :item-value="'key'"
            return-object
            :getter="() => selectedValue"
            :setter="value => (selectedValue = value)"
        />
        <div>
            <span class="subtype__button  font-weight-bold" @click="$emit('cancel')">
                {{ $t('actions.cancel') | toSentenceCase }}
            </span>
            <v-btn
                text
                secondary
                class="subtype__button"
                :disabled="!selectedValue"
                @click="emitSet({ selectedValue })"
            >
                {{ $tkey('set') | toSentenceCase }}
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            selectedValue: undefined,
        };
    },

    created() {
        this.selectedValue = this.initialValue;
    },
    methods: {
        emitSet({ selectedValue }) {
            this.$emit('setValue', selectedValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.subtype {
    padding: 1rem;
    @include flex-row;
    flex-wrap: wrap;
    justify-content: flex-end;

    &__select {
        padding-bottom: 1rem;
        max-width: 13rem;
    }
    &__button {
        color: $promo-primary-colour;
        margin-left: 0.5rem;
        cursor: pointer;
    }
}
</style>
