<template>
    <div class="candidates-grid-container">
        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 35rem;"
            grid-class="ag-theme-custom__candidates-grid"
            dom-layout="normal"
            :single-click-edit="true"
            :make-read-only="promotionEditingState.disabled"
            :read-only-reason="promotionEditingState.reason"
            @cell-value-changed="onCellValueChanged"
            @grid-ready="onGridReady"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { range } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import AgRadioButton from '@/js/components/promo-ag-grid/ag-radio-button';
import AgPriceWeight from '@/js/components/promo-ag-grid/ag-price-weight';
import AgProductAutocomplete from '@/js/components/promo-ag-grid/ag-product-autocomplete';
import i18n from '@/js/vue-i18n';

export default {
    localizationKey: 'planning.promotionsMaintenance.resources.featuredProducts.gridHeadings',
    mixins: [vuexComponentMixin],
    props: {
        namespace: {
            required: true,
            type: String,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            rowData: [],
            // Required to force a refresh
            gridApi: null,
            // Defines the attributes which apply to all columns defined in columnDefs.
            defaultColDef: {
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
            },
            gridOptions: {
                rowHeight: 41, // Specified in pixels.
                applyColumnDefOrder: true, // apply default order of columns when colDef is updated
                suppressHorizontalScroll: true, // Stops an issue with two horizontal scrollbars appearing.
                rowBuffer: 60,
                getRowNodeId: data => data.productKey,
            },
        };
    },

    events: {
        onCandidateRemovedFromPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
        onCandidateAddedToPromotion() {
            this.gridApi.setRowData(this.dataForGrid);
        },
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapGetters('clientConfig', ['getSecondaryPlacements', 'getSecondaryPlacementByKey']),

        dataForGrid() {
            return this.model.products;
        },

        rankOptions() {
            const selectedRanks = this.model.products.filter(p => !!p.rank).map(i => i.rank);
            // always include default empty string option to allow the deselection of the rank
            const options = [''];
            return options.concat(
                range(1, this.model.rankedProductsCount + 1).filter(
                    item => !selectedRanks.includes(item)
                )
            );
        },

        columnDefs() {
            const columnsMap = {
                productKey: {
                    headerName: toSentenceCase(this.$tkey(`productKey`)),
                    field: 'clientProductKey',
                    cellClass: ['product-information'],
                    maxWidth: 90,
                    minWidth: 90,
                    pinned: 'left',
                },
                productName: {
                    headerName: toSentenceCase(this.$tkey(`productName`)),
                    field: 'name',
                    cellClass: ['product-information', 'product-information__name'],
                    maxWidth: 190,
                    minWidth: 190,
                    pinned: 'left',
                },
                brandName: {
                    headerName: toSentenceCase(this.$tkey(`brandName`)),
                    field: 'brandDescription',
                    cellClass: ['product-information'],
                    maxWidth: 105,
                    minWidth: 105,
                },
                supplierName: {
                    headerName: toSentenceCase(this.$tkey(`supplierName`)),
                    field: 'supplierName',
                    cellClass: ['product-information'],
                    maxWidth: 110,
                    minWidth: 110,
                },
                salesPrice: {
                    headerName: toSentenceCase(this.$tkey(`salesPrice`)),
                    valueGetter: ({ data }) => {
                        const priceField = this.toggleLogic.rankTabPriceField || 'minPrice';
                        return data.promoPrices && data.promoPrices.length
                            ? data.promoPrices[0][priceField]
                            : null;
                    },
                    cellClass: ['product-information'],
                    valueFormatter: this.formatCellValueAsCurrencyPadded,
                    maxWidth: 130,
                    minWidth: 90,
                },
                rank: {
                    headerName: toSentenceCase(this.$tkey(`rank`)),
                    field: 'rank',
                    cellClass: ['product-information', 'product-information__rank'],
                    maxWidth: 90,
                    minWidth: 90,
                    editable: !this.isReadOnly,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: () => ({ values: this.rankOptions }),
                    // Sort the ranks such that null/undefined values are always at the bottom of the list
                    comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                        if (valueA === valueB) return 0;
                        if (valueA == null) return isDescending ? -1 : 1;
                        if (valueB == null) return isDescending ? 1 : -1;
                        return valueA > valueB ? 1 : -1;
                    },
                },
                isDefault: {
                    headerName: toSentenceCase(this.$tkey(`defaultSelector`)),
                    field: 'isDefault',
                    cellRendererFramework: AgRadioButton,
                    cellRendererParams: {
                        entityIdFieldName: 'productKey',
                        allowRadioButtonDeselect: true,
                        onRadioButtonChange: async ({ entityId, isSelected }) => {
                            if (isSelected) {
                                const result = await this.setDefaultPromotionProduct({
                                    newDefaultProductKey: entityId,
                                    namespace: this.namespace,
                                });
                                // This is needed as reactivity was not working without watcher
                                const updatedRows = [];
                                if (this.model.products[result.previousIndex]) {
                                    updatedRows.push(this.model.products[result.previousIndex]);
                                }
                                if (this.model.products[result.newIndex]) {
                                    updatedRows.push(this.model.products[result.newIndex]);
                                }

                                this.gridApi.applyTransactionAsync({
                                    update: updatedRows,
                                });
                            }
                        },
                        onRadioButtonDeselect: () => {
                            // Clear the default promotion product if the radio button is already
                            // selected and the user clicks it again.
                            this.clearDefaultPromotionProduct({
                                namespace: this.namespace,
                            });
                        },
                    },
                    cellClass: ['default-product'],
                    headerClass: ['default'],
                    maxWidth: 125,
                    minWidth: 90,
                },
                volume: {
                    headerName: toSentenceCase(this.$tkey(`volume`)),
                    field: 'volumes.totalVolume',
                    valueFormatter: this.formatCellValueAsDecimal,
                    cellClass: ['metrics', 'metrics__margin'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                secondaryPlacement: {
                    headerName: toSentenceCase(this.$tkey(`provision`)),
                    field: 'secondaryPlacement',
                    cellRendererFramework: AgProductAutocomplete,
                    cellRendererParams: {
                        options: this.getSecondaryPlacements(),
                        itemText: 'description',
                        itemValue: 'key',
                        clearable: true,
                        onChange: ({ productKey, model }) => {
                            this.setProductSecondaryPlacement({
                                productKey,
                                value: this.getSecondaryPlacementByKey(model),
                                namespace: this.namespace,
                            });
                        },
                    },
                    maxWidth: 160,
                    minWidth: 160,
                },
                totalSales: {
                    headerName: toSentenceCase(this.$tkey(`totalSales`)),
                    field: 'forecastingAggregations.product.actualSalesExcTax',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__margin', 'metrics__bolder'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                mixedMargin: {
                    headerName: toSentenceCase(this.$tkey(`mixedMargin`)),
                    field: 'forecastingAggregations.product.promoMarginRate',
                    valueFormatter: this.formatCellValueAsPercentage,
                    cellClass: ['metrics', 'metrics__margin', 'metrics__bolder'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                promoMargin: {
                    headerName: toSentenceCase(this.$tkey(`promoMargin`)),
                    field: 'forecastingAggregations.product.promoMarginRateExclMixedMarginRate',
                    valueFormatter: this.formatCellValueAsPercentage,
                    cellClass: ['metrics', 'metrics__margin', 'metrics__bolder'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                incrementalSales: {
                    headerName: toSentenceCase(this.$tkey(`incrementalSales`)),
                    field: 'forecastingAggregations.product.incrementalSalesExcTax',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__margin', 'metrics__bolder'],
                    maxWidth: 95,
                    minWidth: 95,
                },
                incrementalMargin: {
                    headerName: toSentenceCase(this.$tkey(`incrementalMargin`)),
                    field: 'forecastingAggregations.product.incrementalMargin',
                    valueFormatter: this.formatCellValueAsCurrency,
                    cellClass: ['metrics', 'metrics__margin', 'metrics__bolder'],
                    maxWidth: 105,
                    minWidth: 105,
                },
                priceByWeight: {
                    headerName: toSentenceCase(this.$tkey(`priceByWeight`)),
                    field: 'priceByWeight',
                    cellClass: [],
                    sortable: false,
                    unSortIcon: false,
                    maxWidth: 155,
                    minWidth: 155,
                    cellRendererFramework: AgPriceWeight,
                    cellRendererParams: {
                        saveModel: ({ productKey, model }) => {
                            this.setProductPriceByWeight({
                                productKey,
                                value: model,
                                namespace: this.namespace,
                            });
                        },
                    },
                    wrapText: true,
                },
                sizeAndUnits: {
                    headerName: toSentenceCase(this.$tkey(`sizeAndUnits`)),
                    valueGetter: this.getFormattedProductSizeAndUnit,
                    cellClass: ['product-information', 'product-information--last-column'],
                    maxWidth: 90,
                    minWidth: 90,
                },
            };

            return (this.toggleLogic.rankTabColumnsConfig || [])
                .map(item => columnsMap[item] || null)
                .filter(i => Boolean(i));
        },

        promotionParentSubCampaign() {
            return this.getSubCampaignById({
                _id: this.model.subCampaignId,
                usePluralResourceName: true,
            });
        },
    },

    methods: {
        ...mapActions('promotions', [
            'setDefaultPromotionProduct',
            'setPromotionProductRank',
            'setProductPriceByWeight',
            'clearDefaultPromotionProduct',
            'setProductSecondaryPlacement',
        ]),

        onGridReady(params) {
            this.gridApi = params.api;
            this.gridApi.setRowData(this.dataForGrid);
        },
        formatCellValueAsCurrency(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'value' property is the value pulled out by ag-grid for the 'field' defined in the columnDefs.
            return i18n.n('numbers.default.currencyShorthand', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsCurrencyPadded(params) {
            return i18n.n('numbers.default.currencyPadded', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsCurrencyNoLabelPadded(params) {
            return i18n.n('numbers.default.currencyNoLabelPadded', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsPercentage(params) {
            return i18n.n('numbers.default.percentageWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsDecimal(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'value' property is the value pulled out by ag-grid for the 'field' defined in the columnDefs.
            return i18n.n('numbers.default.numberWithDecimals', params.value, {
                usePlaceholder: true,
            });
        },

        getFormattedProductSizeAndUnit(params) {
            // The params are provided by ag-grid's cell renderer.
            // The 'data' property represents the object being used to render the row.
            // This reflects a single object within the array returned by the
            // 'model.products' from mixin (in our case, that's a promotion product).
            if (params.data.packSize !== undefined && params.data.packUnit) {
                return `${params.data.packSize}${params.data.packUnit}`;
            }
        },

        onCellValueChanged({ data, newValue, oldValue, colDef }) {
            if (colDef.field === 'rank' && newValue !== oldValue) {
                this.setPromotionProductRank({
                    productKey: data.productKey,
                    rank: data.rank,
                    namespace: this.namespace,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.candidates-grid-container {
    border-bottom: solid;
    border-bottom-width: 0.75rem !important;
    border-image: linear-gradient(to top, rgba(204, 204, 204, 0.7), rgba(203, 203, 203, 0)) 0 1 100%;

    .ag-theme-custom {
        &__candidates-grid {
            &::v-deep {
                .ag-row {
                    height: 3.6rem !important;
                }

                .ag-header {
                    border-left: none;
                    border-right: none;
                }

                .ag-center-cols-viewport {
                    border-left: none;
                    border-right: none;
                }
            }
        }
    }
}
</style>
