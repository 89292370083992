<template>
    <div class="offer-changes-viewer">
        <div class="offer-changes-viewer__grid">
            <promo-ag-grid
                ref="agGrid"
                :row-data="rowData"
                :column-defs="columnDefs"
                :grid-options="gridOptions"
                :default-col-def="defaultColDef"
                :grid-style="gridStyle"
                :grid-class="gridClass"
                dom-layout="autoHeight"
            />
        </div>
        <div class="offer-changes-viewer__actions" />
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import parentChildTypes from '@enums/parent-child';
import { toSentenceCase } from '@/js/utils/string-utils';
import ButtonField from '@/js/components/promo-ag-grid/ag-button-field';

export default {
    localizationKey: 'planning.parentChanges',
    props: {
        // Child offer mechanic
        child: {
            type: Object,
            default: null,
        },
        // Parent changeset offer mechanic
        parent: {
            type: Object,
            default: null,
        },
        selectAllChild: {
            type: Boolean,
            default: false,
        },
        selectAllParent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: true,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter
                rowHeight: 35, // Specified in pixels.
                columnTypes: {
                    parentChildButton: {
                        headerName: '',
                        headerHeight: 0,
                        filter: false,
                        maxWidth: 70,
                        cellRendererFramework: ButtonField,
                        cellRendererParams: {
                            onClick: params => {
                                this.selectChanges(params.value);
                            },
                            visible: params => params.rowIndex === 0,
                        },
                    },
                },
            },
            gridStyle: 'width: 144rem; height: 100%;',
            gridClass: 'ag-theme-custom__parent-child-changes',
            headerColumns: [
                parentChildTypes.child,
                parentChildTypes.parent,
                parentChildTypes.accept,
            ],
            fieldNames: { description: 'description', restrictions: 'restrictions' },
            selectedChangeset: parentChildTypes.parent,
        };
    },
    computed: {
        rowData() {
            if (!this.parent || isEmpty(this.parent)) {
                return [];
            }
            const rows = [];
            Object.keys(this.fieldNames).forEach(field => {
                const row = {};
                this.headerColumns.forEach(header => {
                    row[`${header}`] = {
                        fieldName: field,
                        fieldValue:
                            field === this.fieldNames.restrictions
                                ? {
                                      maximumPerPurchase: get(this, `${header}.maximumPerPurchase`),
                                      maximumPerCustomer: get(this, `${header}.maximumPerCustomer`),
                                      couponBarcode: get(this, `${header}.couponBarcode`),
                                      customerAvailability: get(
                                          this,
                                          `${header}.customerAvailability`
                                      ),
                                      promotionAvailability: get(
                                          this,
                                          `${header}.promotionAvailability`
                                      ),
                                  }
                                : get(this, `${header}.${field}`),
                    };
                });
                rows.push(row);
            });
            return rows;
        },
        columnDefs() {
            return [
                ...this.headerColumns.map(header => {
                    return {
                        headerName: toSentenceCase(this.$tkey(`gridHeadings.${header}`)),
                        headerClass: `border-left__${header}`,
                        minWidth: 410,
                        autoHeight: true,
                        children: [
                            ...(header !== parentChildTypes.accept
                                ? [
                                      {
                                          headerName: '',
                                          headerHeight: 0,
                                          field: `${header}.fieldName`,
                                          cellClass: ['parent-child', `border-left__${header}`],
                                          maxWidth: 200,
                                          cellClassRules: {
                                              'parent-child--selected': () =>
                                                  this.selectedChangeset === header,
                                              'parent-child--not-selected': () =>
                                                  this.selectedChangeset !== header,
                                          },
                                          cellRenderer: params => {
                                              return `<label>${toSentenceCase(
                                                  this.$tkey(`gridFields.mechanic.${params.value}`)
                                              )}</label>`;
                                          },
                                      },
                                      {
                                          headerName: '',
                                          headerHeight: 0,
                                          field: `${header}.fieldValue`,
                                          cellClass: ['parent-child', `border-left__${header}`],
                                          maxWidth: 450,
                                          autoHeight: true,
                                          cellClassRules: {
                                              'parent-child--selected': () =>
                                                  this.selectedChangeset === header,
                                              'parent-child--not-selected': () =>
                                                  this.selectedChangeset !== header,
                                          },
                                          cellRenderer: params => {
                                              // restrictions will be an object while description is a string
                                              if (typeof params.value === 'object') {
                                                  return `
                                        <div>${toSentenceCase(
                                            this.$tkey(`gridFields.restrictions.maximumPerPurchase`)
                                        )} ${params.value.maximumPerPurchase || '-'}</div>

                                        <div>${toSentenceCase(
                                            this.$tkey(`gridFields.restrictions.maximumPerCustomer`)
                                        )} ${params.value.maximumPerCustomer || '-'}</div>

                                         <div>${toSentenceCase(
                                             this.$tkey(`gridFields.restrictions.availability`)
                                         )} ${toSentenceCase(
                                                      this.$tkey(
                                                          `gridFields.restrictions.customerRestrictions.${
                                                              params.value.customerAvailability
                                                          }`
                                                      )
                                                  )} ${
                                                      params.value.promotionAvailability
                                                          ? `- ${toSentenceCase(
                                                                this.$tkey(
                                                                    `gridFields.restrictions.promotionAvailabilities.${
                                                                        params.value
                                                                            .promotionAvailability
                                                                    }`
                                                                )
                                                            )}`
                                                          : ''
                                                  }</div>

                                        <div>${toSentenceCase(
                                            this.$tkey(`gridFields.restrictions.coupon`)
                                        )} ${params.value.couponBarcode || '-'}</div>`;
                                              }
                                              return `<label title="${params.value}">${
                                                  params.value
                                              }</label>`;
                                          },
                                      },
                                  ]
                                : [
                                      {
                                          fieldName: 'childButton',
                                          type: 'parentChildButton',
                                          cellClass: [
                                              'parent-child',
                                              'parent-child__button',
                                              'parent-child__button--child',
                                          ],
                                          cellClassRules: {
                                              'parent-child__button--child-selected': params =>
                                                  this.selectedChangeset ===
                                                  params.colDef.cellRendererParams.value,
                                          },
                                          cellRendererParams: {
                                              text: toSentenceCase(
                                                  this.$tkey(
                                                      `gridHeadings.${parentChildTypes.child}`
                                                  )
                                              ),
                                              value: parentChildTypes.child,
                                          },
                                      },
                                      {
                                          fieldName: 'parentButton',
                                          type: 'parentChildButton',
                                          cellClass: [
                                              'parent-child',
                                              'parent-child__button',
                                              'parent-child__button--parent',
                                          ],
                                          cellClassRules: {
                                              'parent-child__button--parent-selected': params =>
                                                  this.selectedChangeset ===
                                                  params.colDef.cellRendererParams.value,
                                          },
                                          cellRendererParams: {
                                              text: toSentenceCase(
                                                  this.$tkey(
                                                      `gridHeadings.${parentChildTypes.parent}`
                                                  )
                                              ),
                                              value: parentChildTypes.parent,
                                          },
                                      },
                                  ]),
                        ],
                    };
                }),
            ];
        },
    },
    watch: {
        selectAllChild() {
            this.toggleSelection(parentChildTypes.child);
        },
        selectAllParent() {
            this.toggleSelection(parentChildTypes.parent);
        },
    },
    methods: {
        selectChanges(value) {
            this.selectedChangeset = value;
            this.$emit(
                'select-offer-mechanic',
                this.selectedChangeset === parentChildTypes.parent ? this.parent : null
            );
        },

        toggleSelection(selectedChangeset) {
            this.selectChanges(parentChildTypes[`${selectedChangeset}`]);
            this.gridOptions.api.refreshCells();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.offer-changes-viewer {
    @include flex-column;
    margin-bottom: 2rem;

    &__actions {
        width: 100%;
        height: 2rem;
        background-color: $promo-white;
    }
}

.offer-changes-viewer::v-deep {
    .ag-header-container,
    .ag-header,
    .ag-header-viewport {
        height: 4rem !important;
        min-height: 4rem !important;
    }

    .ag-center-cols-clipper {
        border-top: solid 0.1rem $promo-table-blue-bg-colour !important;
    }
}
</style>
