import routeNames from '@enums/route-names';
import ErrorPage from './error-page';

const routes = [
    {
        path: '/error/:status',
        name: routeNames.errorView,
        component: ErrorPage,
        omitFromNav: true,
    },
];

export default routes;
