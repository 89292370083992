import featureFlags from '@enums/feature-flags';
import routeNames from '@enums/route-names';
import { get } from 'lodash';
import store from '@/js/store';
import AdminPage from './admin';
import guardsMap from './admin-guards-map';

const defaultBeforeEnter = (to, from, next) => {
    guardsMap[to.name](to, from, next);
};

const routes = [
    {
        path: '/admin',
        id: 'admin',
        featureToggle: 'canAccessAdminArea',
        omitFromNav: true,
        component: AdminPage,
        linkText: 'navBar.admin',
        cssClasses: ['admin-toolbar-item'],
        meta: { canAccess: featureFlags.canAccessAdminArea },
        async beforeEnter(to, from, next) {
            await store.dispatch('rateCards/fetchRateCards', {
                params: {
                    sortBy: 'lastUpdatedOn',
                    sortDirection: 'descending',
                },
            });

            await store.dispatch('freeGifts/fetchFreeGifts', {
                params: {
                    sortBy: 'lastUpdatedOn',
                    sortDirection: 'descending',
                },
            });

            await store.dispatch('loyaltyPoints/fetchLoyaltyPoints', {
                params: {
                    sortBy: 'lastUpdatedOn',
                    sortDirection: 'descending',
                },
            });

            await store.dispatch('userProfiles/fetchUserProfiles');

            next();
        },
        children: [
            // Default route to ensure that all '/admin' requests are redirected to the '/admin/freeGifts'.
            {
                path: '',
                name: 'admin',
                redirect: () => {
                    return {
                        name: get(
                            store.state.clientConfig.toggleLogic,
                            featureFlags.defaultAdminTab,
                            routeNames.freeGiftsView
                        ),
                    };
                },
            },
            {
                path: 'free-gifts',
                name: routeNames.freeGiftsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'rate-card',
                meta: { canAccess: featureFlags.canModifyRateCards },
                name: routeNames.rateCardView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'loyalty-points',
                name: routeNames.loyaltyPointsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'userPermissions',
                name: routeNames.userPermissions,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'client-systems-testing',
                meta: { canAccess: featureFlags.canAccessClientSystemsTesting },
                name: routeNames.clientSystemsTesting,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'redis-bull',
                meta: { canAccess: featureFlags.canAccessClientSystemsTesting },
                name: routeNames.redisBull,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'execution-errors',
                meta: { canAccess: featureFlags.canAccessClientSystemsTesting },
                name: routeNames.executionErrors,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'feature-toggler',
                meta: { canAccess: featureFlags.canAccessClientSystemsTesting },
                name: routeNames.featureToggler,
                beforeEnter: defaultBeforeEnter,
            },
        ],
    },
];

export default routes;
