var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Eye closed")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icon-/-Eye-closed",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Colour",
              d: "M12.8046875,12.9550781 C12.9863281,12.7675781 12.9746094,12.4980469 12.8046875,12.3222656 L3.34179688,2.86523438 C3.16601562,2.68945312 2.87890625,2.68945312 2.703125,2.86523438 C2.53320312,3.03515625 2.53320312,3.328125 2.703125,3.49804687 L12.171875,12.9550781 C12.3417969,13.1308594 12.6230469,13.1367187 12.8046875,12.9550781 Z M12.6640625,11.296875 C14.3984375,10.1484375 15.40625,8.65429688 15.40625,8.00390625 C15.40625,6.8671875 12.3476562,3.18164062 7.765625,3.18164062 C6.79882812,3.18164062 5.91992188,3.34570312 5.10546875,3.61523438 L6.08398438,4.59375 C6.6171875,4.44726562 7.16796875,4.359375 7.765625,4.359375 C11.2460938,4.359375 13.9765625,7.36523438 13.9765625,8.00390625 C13.9765625,8.43164062 13.1269531,9.52148438 11.7617188,10.3886719 L12.6640625,11.296875 Z M7.765625,12.8320312 C8.78515625,12.8320312 9.71679688,12.65625 10.5664062,12.3691406 L9.59375,11.390625 C9.01953125,11.5546875 8.41601562,11.6542969 7.765625,11.6542969 C4.28515625,11.6542969 1.5546875,8.77148438 1.5546875,8.00390625 C1.5546875,7.640625 2.45703125,6.48632812 3.8984375,5.58398438 L2.97851562,4.6640625 C1.18554688,5.82421875 0.130859375,7.34765625 0.130859375,8.00390625 C0.130859375,9.140625 3.24804688,12.8320312 7.765625,12.8320312 Z M10.484375,8.99414062 C10.6191406,8.68945312 10.6894531,8.35546875 10.6894531,8.00390625 C10.6894531,6.38085938 9.38867188,5.09179688 7.77148438,5.09179688 C7.4140625,5.09179688 7.0859375,5.16210938 6.78125,5.28515625 L10.484375,8.99414062 Z M7.765625,10.921875 C8.1640625,10.921875 8.53320312,10.828125 8.87304688,10.6757812 L5.08789062,6.89648438 C4.9296875,7.23046875 4.84179688,7.60546875 4.84179688,8.00976562 C4.84765625,9.59179688 6.14257812,10.921875 7.765625,10.921875 Z",
              fill: "#2F477C",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }