import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "grouping-renderer" }, [
    _vm.params.data.isSupplier
      ? _c("div", { staticClass: "supplier" }, [
          _c(
            "div",
            { staticClass: "supplier__expansion" },
            [
              _c("main-expand-button", {
                attrs: { "is-expanded": _vm.isExpanded },
                on: { expand: _vm.toggleSupplier },
              }),
              _vm._v(
                "\n            (" +
                  _vm._s(_vm.params.data.productCount) +
                  ")\n        "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "supplier__details",
              on: { click: _vm.toggleSupplier },
            },
            [
              _c(
                VTooltip,
                {
                  attrs: { disabled: _vm.hideTooltip, top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: criteria, attrs }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    ref: "truncated",
                                    staticClass: "truncate-text",
                                    on: { mouseover: _vm.checkTooltipRequired },
                                  },
                                  "div",
                                  attrs,
                                  false
                                ),
                                criteria
                              ),
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.supplierDescription) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1364526170
                  ),
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.supplierDescription) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "supplier__actions" },
            [
              _c("main-dialog", {
                ref: "supplierCommitmentsDialog",
                attrs: {
                  heading: _vm.$tkey("supplierCommitments"),
                  "show-positive-action-button": false,
                  "has-activator": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function ({ activator }) {
                        return [
                          _c(
                            VTooltip,
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("div", _vm._g({}, on), [
                                          _c(
                                            "button",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "actions__supplier-commitments",
                                                attrs: {
                                                  disabled:
                                                    _vm.viewSupplierCommitmentsDisabled,
                                                },
                                                on: {
                                                  click: _vm.fetchCommitments,
                                                },
                                              },
                                              activator
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("toSentenceCase")(
                                                      _vm.$tkey(
                                                        "viewSupplierCommitmentsShort"
                                                      )
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.supplierCommitmentsTooltip
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("funding-commitments-viewer", {
                            attrs: {
                              "supplier-key": _vm.params.data.supplierKey,
                              category: _vm.params.data.categoryKey,
                              "promotion-namespace":
                                _vm.params.promotionNamespace,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "supplier-commitments__view-all" },
                            [
                              _c(
                                "simple-button",
                                {
                                  attrs: { "light-theme": true },
                                  on: {
                                    onClick: _vm.viewAllSupplierCommitments,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t("actions.viewAll")
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2201809766
                ),
              }),
              _vm._v(" "),
              _c(
                VTooltip,
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c("div", _vm._g({}, on), [
                              _c(
                                "button",
                                {
                                  staticClass: "actions__agreement-selector",
                                  on: { click: _vm.selectAgreement },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$tkey("addAgreementShort")
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2501388755
                  ),
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(_vm.$tkey("addAgreement"))
                      ) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("agreement-selector-modal", {
                key: `agreementSelectorModal::${_vm.params.selectedPromotion._id}`,
                ref: "agreement-selector-modal",
                attrs: {
                  "has-activator": false,
                  "supplier-key": _vm.params.data.supplierKey,
                  "category-key": _vm.params.data.categoryKey,
                  promotion: _vm.params.selectedPromotion,
                  products: _vm.params.data.products,
                  namespace: _vm.params.promotionNamespace,
                },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _vm.isVisible(_vm.params)
            ? _c(
                "div",
                { staticClass: "product" },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { disabled: _vm.hideTooltip, top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: criteria, attrs }) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        ref: "truncated",
                                        staticClass: "truncate-text",
                                        on: {
                                          mouseover: _vm.checkTooltipRequired,
                                        },
                                      },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    criteria
                                  ),
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(_vm.productDescription) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        142973989
                      ),
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.productDescription) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }