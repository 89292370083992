'use strict';

/**
 * Enum definition for workflow action.
 *
 * @module data/enums/workflow-actions
 */
module.exports = {
    submitPromotion: 'SUBMIT_PROMOTION',
    approvePromotion: 'APPROVE_PROMOTION',
    requestUnlockPromotion: 'REQUEST_UNLOCK_PROMOTION',
    unlockPromotion: 'UNLOCK_PROMOTION',
    rejectRequestUnlockPromotion: 'REJECT_REQUEST_UNLOCK_PROMOTION',
    rejectPromotion: 'REJECT_PROMOTION',
    submitDraftPromotion: 'SUBMIT_DRAFT_PROMOTION',
    submitLeaflet: 'SUBMIT_LEAFLET',
    approveLeaflet: 'APPROVE_LEAFLET',
    rejectLeaflet: 'REJECT_LEAFLET',
};
