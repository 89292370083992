<template>
    <div class="message-title py-2">
        <div class="warning-text">{{ $t('planning.warnings.warning') | toSentenceCase }}!</div>
        <div v-if="mergeTags">
            {{ $t(descriptionText, { tagName, duplicateTagName }) | toSentenceCase }}
        </div>
        <div v-else>
            {{ $t(descriptionText) | toSentenceCase }}
        </div>
        <div v-if="!isEmpty(childDependencies)">
            <div v-if="deleteTag">
                {{ $t(warningText, { tagName }) | toSentenceCase }}
            </div>
            <div v-else>
                {{ $t(warningText) | toSentenceCase }}
            </div>
            <ul>
                <li v-for="(count, entityName) in childDependencies" :key="entityName">
                    <span v-if="count">
                        {{ $t(`pluralEntities.${entityName}`) | toSentenceCase }}: {{ count }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
    props: {
        mergeTags: {
            type: Boolean,
            default: false,
        },
        deleteTag: {
            type: Boolean,
            default: false,
        },
        tagName: {
            type: String,
            default: '',
        },
        duplicateTagName: {
            type: String,
            default: '',
        },
        childDependencies: Object,
        descriptionText: String,
        warningText: String,
    },

    data: () => ({
        isEmpty,
    }),
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.warning-text {
    color: $negative-action;
}

.message-title {
    font-size: 1.4rem;
}
</style>
