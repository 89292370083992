import UXEvents from '@enums/ux-events';

export default [
    {
        key: UXEvents.logout,
        actions: [
            'campaigns/resetState',
            'clientConfig/resetState',
            'context/resetState',
            'forecasting/resetState',
            'hierarchy/resetState',
            'notifications/resetState',
            'productPrices/resetState',
            'products/resetState',
            'promotionCandidates/resetState',
            'promotionProductGroups/resetState',
            'promotions/resetState',
            'scenarios/resetState',
            'selectedDates/resetState',
            'storeGroups/resetState',
            'subCampaigns/resetState',
            'weeks/resetState',
            'workPackages/resetState',
            'suppliers/resetState',
        ],
    },
];
