import tabStates from '@enums/tab-states';

function validateChannelsStoresTab(promotion) {
    return promotion.storeGroups && promotion.storeGroups.length
        ? tabStates.completed
        : tabStates.invalid;
}

export default {
    validateChannelsStoresTab,
};
