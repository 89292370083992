<template>
    <div class="product-groups">
        Product groups
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.product-groups {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
</style>
