import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "rtls-text-field-container", class: _vm.cssClass },
    [
      _c("div", { staticClass: "rtls-text-field-container__label-wrapper" }, [
        _vm.label
          ? _c("p", { staticClass: "rtls-text-field-container__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isRequired
          ? _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rtls-text-field-container__control" },
        [
          _c(VTextField, {
            staticClass: "rtls-text-field",
            class: _vm.white
              ? "rtls-text-field--white"
              : "rtls-text-field--grey",
            attrs: {
              "data-id-e2e": _vm.dataIdE2E,
              placeholder: _vm._f("toSentenceCase")(_vm.$t(_vm.placeholder)),
              disabled: _vm.isDisabled,
              rules: _vm.rules,
              "validate-on-blur": "",
            },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleEnter.apply(null, arguments)
              },
              blur: _vm.onBlur,
              input: _vm.handleInput,
              change: _vm.change,
              click: _vm.click,
            },
            model: {
              value: _vm.text,
              callback: function ($$v) {
                _vm.text = $$v
              },
              expression: "text",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }