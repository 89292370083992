<template>
    <button class="expand-btn" :class="expandBtnClassObject" @click="toggleExpand">
        <slot />
        <v-icon class="expand-btn__icon" :disabled="disabled">{{ name }}</v-icon>
    </button>
</template>

<script>
export default {
    props: {
        isExpanded: {
            required: true,
            type: Boolean,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        expandedIcon: {
            required: false,
            type: String,
            default: 'expand_less',
        },
        collapsedIcon: {
            required: false,
            type: String,
            default: 'expand_more',
        },
        background: {
            required: false,
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            expandBtnClassObject: {
                'expand-btn--bg': this.background,
                'expand-btn--disabled': this.disabled,
                'expand-btn--horizontal': this.horizontal,
            },
        };
    },

    computed: {
        name() {
            return this.isExpanded ? this.expandedIcon : this.collapsedIcon;
        },
    },

    methods: {
        toggleExpand() {
            if (!this.disabled) this.$emit('expand');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins';
@import '@style/base/_variables';

.expand-btn {
    outline: none;
    text-align: center;

    &--disabled {
        cursor: not-allowed;
    }

    &--bg {
        background-color: $promo-primary-colour;
        @include icon-border-radius;
        &:hover {
            background-color: $rtls-secondary-button-hover-colour !important;
        }
        .expand-btn__icon {
            color: $promo-white;
        }
    }

    &--horizontal {
        transform: rotate(270deg);
    }

    &__icon {
        vertical-align: middle;
        color: $promo-primary-colour;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
    }
}
</style>
