var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotion-products" },
    [
      _c(
        "candidates-list",
        {
          attrs: {
            items: _vm.currentPromotionProductsAndGroups,
            disabled: _vm.isProductsDisabled,
            "selected-products": _vm.assignedProducts,
            "list-namespace":
              _vm.ProductNamespaces.preparationPromotionProducts,
            "product-selection-enabled": _vm.canSelectProducts,
            "expand-product-groups-by-default": "",
          },
        },
        _vm._l(_vm.currentPromotionProductsAndGroups, function (item, index) {
          return _c(
            "div",
            {
              key: _vm.getSidebarKey(item),
              staticClass: "promotion-products__sidebar",
              style: { "grid-row": `${index + 1}` },
            },
            [
              _c("promotion-products-sidebar-column", {
                attrs: {
                  candidate: item,
                  "is-disabled-color": _vm.isProductsDisabled,
                  "list-namespace":
                    _vm.ProductNamespaces.preparationPromotionProducts,
                  field: "sales",
                },
              }),
              _vm._v(" "),
              _c("promotion-products-sidebar-column", {
                attrs: {
                  candidate: item,
                  "is-disabled-color": _vm.isProductsDisabled,
                  "list-namespace":
                    _vm.ProductNamespaces.preparationPromotionProducts,
                  field: "margin",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      !_vm.currentPromotionProductsAndGroups.length
        ? _c("div", { staticClass: "promotion-products__no_products" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("preparation.allocation.noProducts")
                  )
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }