import { mapState } from 'vuex';
import numeral from 'numeral';

const mixin = {
    computed: {
        ...mapState('clientConfig', ['i18nTranslations']),
        numeralLocaleConfig() {
            return numeral.localeData(this.i18nTranslations.defaultNumericLocale);
        },
    },
    methods: {
        validateNumber(evt) {
            const key = evt.key;

            // This could be , or . depending on the locale
            const decimalDelimeter = this.numeralLocaleConfig.delimiters.decimal;

            const isNumber = key >= '0' && key <= '9';
            // If the user has entered too many decimal delimeters prevent it from accepting
            const validDelimiter =
                key === decimalDelimeter && evt.target.value.indexOf(decimalDelimeter) === -1;

            if (!isNumber && !validDelimiter) {
                evt.preventDefault();
            }
        },
        validateNumberOnPaste(evt) {
            const pastedData = evt.clipboardData.getData('Text');

            // This could be , or . depending on the locale
            const decimalDelimeter = this.numeralLocaleConfig.delimiters.decimal;

            const isValidNumber = pastedData >= '0';
            // If the user has entered too many decimal delimeters prevent it from accepting
            const validDelimiter =
                pastedData === decimalDelimeter &&
                evt.target.value.indexOf(decimalDelimeter) === -1;

            if (!isValidNumber && !validDelimiter) {
                evt.preventDefault();
            }
        },
        validateIntegerInput(evt) {
            const key = evt.key;

            // This could be , or . depending on the locale
            const decimalDelimeter = this.numeralLocaleConfig.delimiters.decimal;

            const isNumber = key >= '0' && key <= '9';

            // If the user has entered too many decimal delimeters prevent it from accepting
            const validDelimiter =
                key === decimalDelimeter && evt.target.value.indexOf(decimalDelimeter) === -1;

            const negativeSymbol = '-';
            const validNegative =
                key === negativeSymbol &&
                evt.target.value.indexOf(negativeSymbol) === -1 &&
                evt.target.selectionStart === 0;

            if (!isNumber && !validDelimiter && !validNegative) {
                evt.preventDefault();
            }
        },

        validatePositiveIntegerInput(evt) {
            const key = evt.key;

            // This could be , or . depending on the locale
            const decimalDelimeter = this.numeralLocaleConfig.delimiters.decimal;

            const isNumber = key >= '0' && key <= '9';

            // If the user has entered too many decimal delimeters prevent it from accepting
            const validDelimiter =
                key === decimalDelimeter && evt.target.value.indexOf(decimalDelimeter) === -1;

            if (!isNumber && !validDelimiter) {
                evt.preventDefault();
            }
        },
    },
};

export default mixin;
