import Vue from 'vue';
import axios from 'axios';
import {
    isEmpty,
    merge,
    map,
    flatten,
    uniq,
    includes,
    isObject,
    unionBy,
    keyBy,
    isNil,
} from 'lodash';
import to from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '@/js/vue-i18n';

const getInitialState = () => ({
    campaigns: [],
    filter: {
        _id: null,
    },
    childDependencies: null,
    campaignsInView: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getCampaignById
     * - getFilteredCampaigns
     */
    getters: {
        isCampaignSelected: state => {
            return !!state.filter._id;
        },
        selectedCampaignId: state => {
            return state.filter._id;
        },
        selectedCampaign: (state, getters) => {
            return getters.getCampaignById({ _id: state.filter._id, usePluralResourceName: true });
        },

        hasNoSubCampaigns: (state, getters, rootState, rootGetters) => ({ campaignId }) =>
            isEmpty(rootGetters['subCampaigns/getSubCampaignsByCampaignId'](campaignId)),

        getCheckboxListOptions: (state, getters, rootState, rootGetters) => ({
            campaignId,
            attributeName,
            attributeKey,
            resourceOptionAttributeKey = attributeKey,
            resource,
            getOptionsFunction,
            getUserAccessOptionsMap,
            useReference = true,
        }) => {
            const subCampaigns = rootGetters['subCampaigns/getSubCampaignsByCampaignId'](
                campaignId
            );

            // get uniq list of resource values or
            // keys, if resource is stored as list of objects
            const selectedValuesKeys = uniq(
                map(flatten(map(subCampaigns, attributeName)), value =>
                    isObject(value) ? value[attributeKey] : value
                )
            );

            const resourceOptions = rootGetters[`${resource}/${getOptionsFunction}`];

            return map(resourceOptions, resourceOption => {
                const optionKey = resourceOption[resourceOptionAttributeKey];
                return {
                    ...(useReference ? { reference: resourceOption } : resourceOption),
                    disabled:
                        // check if user has no access to campaign resource option
                        (getUserAccessOptionsMap &&
                            isNil(rootGetters[`context/${getUserAccessOptionsMap}`][optionKey])) ||
                        // check if campaign resource option is selected in child subCampaigns
                        includes(selectedValuesKeys, optionKey),
                };
            });
        },

        getSubCampaignsCategories: (state, getters, rootState, rootGetters) => ({ campaignId }) => {
            const subCampaigns = rootGetters['subCampaigns/getSubCampaignsByCampaignId'](
                campaignId
            );

            const selectedCategories = uniq(
                map(flatten(map(subCampaigns, 'categories')), value =>
                    isObject(value) ? value.levelEntryKey : value
                )
            );

            return selectedCategories;
        },

        getFilteredCampaignsKeyedById: (state, getters) => {
            return keyBy(getters.getFilteredCampaigns, '_id');
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setCampaigns
     * - deleteCampaign
     * - updateCampaign
     * - addCampaign
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        setChildDependencies(state, dependencies) {
            state.childDependencies = dependencies;
        },
        setSelectedCampaignId(state, { campaignId }) {
            Vue.set(state.filter, '_id', campaignId);
        },
        setCampaignIsPrivateAttribute(state, { id, isPrivate }) {
            if (state.stagingArea[id]) {
                state.stagingArea[id].isPrivate = isPrivate;
            }
        },
        setCampaignsInView(state, { campaigns }) {
            state.campaignsInView = campaigns;
        },
    },

    /**
     * Default actions available:
     * - fetchCampaigns
     * - createCampaign
     * - deleteCampaign
     * - updateCampaign
     * - submitForm
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        async fetchChildDependencies({ commit }, { id, includeGhosts = false }) {
            let url = `/api/hierarchy/child-dependencies/campaigns/${id}`;
            // when we delete sub-campaign we also need to pick up ghost promotions
            if (includeGhosts) {
                url += `/includeGhosts`;
            }
            const { data: dependencies } = await axios.get(url);
            commit('setChildDependencies', dependencies);
        },
        async copyCampaign({ commit, dispatch }, { id, body }) {
            const resource = 'campaign';
            const [error, response] = await to(axios.post(`/api/campaigns/${id}/copy`, body));

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.copySuccess`, { resource }),
                errorMessage: i18n.t(`notifications.copyError`, { resource }),
            });

            if (error) return error;
            commit('addCampaign', response.data);
        },
        async fetchAndUpdateCampaigns({ state, commit, dispatch }, { fetchParams = {} }) {
            const [error, campaigns] = await to(axios.get('/api/campaigns', { fetchParams }));
            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.campaigns', 1),
                    }),
                });
            } else {
                const updatedCampaigns = unionBy(campaigns.data, state.campaigns, '_id');
                commit('setCampaigns', updatedCampaigns);
            }
        },
        setSelectedCampaignId({ commit }, selectedCampaignId) {
            commit('setSelectedCampaignId', { campaignId: selectedCampaignId });
        },
        clearSelectedCampaign({ commit }) {
            commit('setSelectedCampaignId', { campaignId: null });
        },
    },
};

const mixinParams = {
    resource: 'campaign',
    useForm: true,
    useFilters: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
