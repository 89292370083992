<template>
    <div class="commitment-matrix" :style="gridTemplate">
        <div class="commitment-matrix__header">
            {{ $t('supplierCommitments.commitmentMatrix.storeGroups') | toSentenceCase }}
        </div>
        <div
            v-for="resource in computedPromoResources"
            :key="resource.key"
            class="commitment-matrix__resources-wrapper"
        >
            <div
                class="border-left align-top"
                :style="getStyle({ resource: modelResources[resource.key] })"
            >
                <vuex-icon-checkbox
                    class="commitment-matrix__resource-header"
                    :getter="() => enabledModelResources"
                    :setter="resources => resourcesSetter({ key: resource.key, resources })"
                    :icon="resource.key"
                    :tooltip-label="resource.description"
                    :value="resource.key"
                    :validations="resourcesValidations"
                />
            </div>
            <template v-if="shouldRenderDetailedProvisions({ key: resource.key })">
                <div
                    v-for="option in modelResources[resource.key].optionsKeys"
                    :key="option"
                    class="commitment-matrix__resource-options"
                >
                    <div class="commitment-matrix__resource-options--top">
                        {{ $t(`general.detailedProvision.${option}`) | toSentenceCase }}
                        <vuex-checkbox
                            :getter="() => getModelResourcesInstancesNames(resource.key)"
                            :setter="
                                instances =>
                                    instancesValueSetter({
                                        key: resource.key,
                                        type: option,
                                        instances,
                                    })
                            "
                            :value="
                                getInstanceValue({
                                    key: resource.key,
                                    type: option,
                                })
                            "
                            :validations="detailedProvisionValidations(resource.key)"
                        />
                    </div>
                    <div
                        class="commitment-matrix__resource-options--bottom resource-options--bottom-width"
                    >
                        <div class="bottom-no-of-occurrences">
                            <div
                                v-if="modelResources[resource.key].instances[option].value"
                                class="flex-row"
                            >
                                <vuex-number-input
                                    :getter="
                                        () =>
                                            instancesFieldGetter({
                                                key: resource.key,
                                                type: option,
                                                field: 'noOfOccurrences',
                                            })
                                    "
                                    :setter="
                                        value =>
                                            instancesFieldSetter({
                                                key: resource.key,
                                                type: option,
                                                value,
                                                field: 'noOfOccurrences',
                                            })
                                    "
                                    class="commitment-matrix__occurrence"
                                    :validations="valueValidations"
                                />
                                <div
                                    class="commitment-matrix__measurement-counts commitment-matrix__measurement-counts--executed"
                                >
                                    {{
                                        instancesFieldGetter({
                                            key: resource.key,
                                            type: option,
                                            field: 'executed',
                                        })
                                    }}
                                </div>
                                <div
                                    class="commitment-matrix__measurement-counts commitment-matrix__measurement-counts--planned"
                                >
                                    {{
                                        instancesFieldGetter({
                                            key: resource.key,
                                            type: option,
                                            field: 'planned',
                                        })
                                    }}
                                </div>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </div>
                        <div class="bottom-notes">
                            <vuex-textarea
                                v-if="modelResources[resource.key].instances[option].value"
                                :auto-grow="false"
                                :no-resize="true"
                                placeholder="supplierCommitments.commitmentMatrix.addNotes"
                                :getter="
                                    () =>
                                        instancesFieldGetter({
                                            key: resource.key,
                                            type: option,
                                            field: 'note',
                                        })
                                "
                                :setter="
                                    value =>
                                        instancesFieldSetter({
                                            key: resource.key,
                                            type: option,
                                            value,
                                            field: 'note',
                                        })
                                "
                                class="commitment-matrix__note"
                            />
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="commitment-matrix__resource-options">
                    <div class="commitment-matrix__resource-options--top" />
                    <div class="commitment-matrix__resource-options--bottom">
                        <div class="bottom-no-of-occurrences" />
                        <div class="bottom-notes" />
                    </div>
                </div>
            </template>
        </div>
        <div class="commitment-matrix__store-groups">
            <vuex-select
                :options="storeGroupsOptions"
                :getter="() => getStoreGroups()"
                :setter="value => setStoreGroups({ value })"
                :validate-on-blur="false"
                item-text="description"
                item-value="key"
                multiple
                chips
                deletable-chips
                finite-list
                clearable
                :validations="storeGroupsValidations"
                :placeholder="$t('placeholders.selectOption.storeGroups') | toSentenceCase"
            />
        </div>
        <div class="border-left" />
        <div class="commitment-matrix__value border-left">
            {{ $t('supplierCommitments.commitmentMatrix.value') | toSentenceCase }}
        </div>
        <div class="commitment-matrix__value border-left">
            <vuex-currency-input
                :getter="() => getValue()"
                :setter="value => setValue({ value })"
                :validations="valueValidations"
            />
        </div>
        <div ref="lastRow" class="commitment-matrix__key">
            <div class="commitment-matrix__label">
                {{ $t('supplierCommitments.key') | toSentenceCase }}
            </div>
            <div
                class="commitment-matrix__measurement-counts commitment-matrix__measurement-counts--executed"
            />
            <div class="commitment-matrix__label">
                {{ $t('supplierCommitments.executedPromotions') | toSentenceCase }}
            </div>
            <div
                class="commitment-matrix__measurement-counts commitment-matrix__measurement-counts--planned"
            />
            <div class="commitment-matrix__label">
                {{ $t('supplierCommitments.plannedPromotions') | toSentenceCase }}
            </div>
        </div>
    </div>
</template>

<script>
import { values, filter, map, includes } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import leafletPageTypes from '@enums/leaflet-page-types';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import { toSentenceCase } from '@/js/utils/string-utils';
import { getMeasurementCounts } from '@/js/utils/supplier-commitment-utils';
import storeGroupsOptionsMixin from '@/js/pages/mixins/store-groups-options-mixin';

export default {
    mixins: [vuexComponentMixin, storeGroupsOptionsMixin],
    data() {
        return {
            matrixModel: { resources: {}, storeGroups: [] },
        };
    },
    computed: {
        ...mapState('clientConfig', ['detailedProvisions']),
        ...mapGetters('clientConfig', ['getPromoResources', 'getHierarchyConfig']),
        ...mapGetters('promotions', ['getSupplierCommitmentPromotions']),
        ...mapGetters('supplierCommitments', ['getSelectedSupplierCommitment']),

        modelResources() {
            return this.matrixModel.resources;
        },

        enabledModelResources() {
            return map(filter(values(this.modelResources), { enabled: true }), 'key');
        },
        computedPromoResources() {
            return [
                {
                    key: 'any',
                    description: this.$t(`preparation.promoResources.any`),
                },
                ...this.getPromoResources,
            ];
        },
        // Counting how many columns need for grid
        gridTemplate() {
            const gridColumnsCount = this.computedPromoResources.reduce((acc, item) => {
                if (!this.matrixModel.resources[item.key].enabled) {
                    acc += 1;
                } else {
                    acc += this.matrixModel.resources[item.key].optionsKeys.length;
                }

                return acc;
            }, 0);
            return {
                'grid-template-columns': `25rem repeat(${gridColumnsCount}, max-content) 6rem`,
            };
        },
        // Count place in grid for each resource
        // Depends on how many options show for prev resources
        gridResourcesPositions() {
            const result = {};
            let prevOffset = 1;
            this.computedPromoResources.forEach(item => {
                const resource = this.modelResources[item.key];
                const start = prevOffset + 1;
                const span = resource.enabled ? resource.optionsKeys.length : 1;
                prevOffset = span + start - 1;
                result[item.key] = { start, span };
            });
            return result;
        },

        valueValidations() {
            return [
                {
                    message: i18n.t('validation.common.required'),
                    validator: validators.required,
                },
                {
                    validator: validators.minValue,
                    params: [0],
                },
            ];
        },

        storeGroupsValidations() {
            return [
                {
                    validator: () =>
                        // Ensure that at least one store group has been selected.
                        !!this.matrixModel.storeGroups.length ||
                        toSentenceCase(i18n.t('validation.supplierCommitment.storeGroupRequired')),
                },
            ];
        },

        resourcesValidations() {
            return [
                {
                    validator: () => {
                        // Ensure that at least one promo resource has been selected.
                        if (!this.model) return false;
                        return (
                            !!this.model.some(o => o.resources && o.resources.length) ||
                            // need to return any string as an error message,
                            // as vuetify is adding just 'error--text' class to invalid fields.
                            'error'
                        );
                    },
                },
            ];
        },
    },
    created() {
        this.createMatrixModel();
    },
    mounted() {
        // this.$refs.lastRow.offsetWidth can't be computed, as refs are not reactive any more
        this.$emit('matrix-updated', this.$refs.lastRow.offsetWidth + 10);
    },
    updated() {
        this.$emit('matrix-updated', this.$refs.lastRow.offsetWidth + 10);
    },
    methods: {
        createMatrixModel() {
            // Get the promotions associated with the supplier commitment.
            const promotions = this.getSupplierCommitmentPromotions;
            // now we support only one row in matrix
            const row = (this.model || [])[0] || {};
            // Map resources using the model
            const resources = this.computedPromoResources.reduce((acc, item) => {
                let resource = null;
                if (row.resources) {
                    resource = row.resources.find(
                        resourceItem => resourceItem.resource === item.key
                    );
                }

                const enabled = !!resource;
                const detailedProvision = this.detailedProvisions.find(
                    provision => provision.type === item.key
                );
                // get optionsKeys from detailedProvisions and exclude 'unspecified' key
                let optionsKeys = detailedProvision
                    ? filter(
                          map(detailedProvision.options, 'key'),
                          option => option !== leafletPageTypes.unspecified
                      )
                    : ['any'];
                if (detailedProvision) {
                    optionsKeys = ['any', ...optionsKeys];
                }
                const instances = optionsKeys.reduce((instancesAcc, key) => {
                    let instance = null;
                    if (resource) {
                        instance = resource.instances.find(
                            instanceItem => instanceItem.type === key
                        );
                    }

                    if (instance) {
                        const { executed, planned } = getMeasurementCounts({
                            promotions,
                            detailedProvisionKey: key,
                            resourceKey: item.key,
                            supplierCommitment: this.getSelectedSupplierCommitment,
                            categoryLevel: this.getHierarchyConfig.categoryLevel,
                            detailedProvisions: this.detailedProvisions,
                        });

                        instancesAcc[key] = {
                            ...instance,
                            value: true,
                            executed,
                            planned,
                        };
                    } else {
                        instancesAcc[key] = {
                            type: key,
                            note: null,
                            noOfOccurrences: null,
                            value: false,
                            executed: 0,
                            planned: 0,
                        };
                    }

                    return instancesAcc;
                }, {});
                acc[item.key] = {
                    optionsKeys,
                    key: item.key,
                    instances,
                    enabled,
                };

                return acc;
            }, {});
            // If no enabled resources make "any" enabled by default
            const isSomeResourceEnabled = Object.keys(resources).some(
                key => resources[key].enabled
            );
            if (!isSomeResourceEnabled) {
                resources.any.enabled = true;
                resources.any.instances.any.value = true;
            }
            const storeGroups = (row.storeGroups || this.userStoreGroups).map(
                storeGroup => storeGroup.key
            );
            const value = row.value || null;
            this.matrixModel = { storeGroups, resources, value };
        },
        getStyle({ resource }) {
            const { start, span } = this.gridResourcesPositions[resource.key];
            return { 'grid-column': `${start} / span ${span}` };
        },
        setStoreGroups({ value }) {
            this.matrixModel.storeGroups = value;
            this.updateModelAfterValidation();
        },
        getStoreGroups() {
            return this.matrixModel.storeGroups;
        },

        setValue({ value }) {
            this.matrixModel.value = value;
            this.updateModelAfterValidation();
        },
        getValue() {
            return this.matrixModel.value;
        },

        getModelResourcesInstancesNames(resourceKey) {
            return map(
                filter(values(this.modelResources[resourceKey].instances), { value: true }),
                'type'
            );
        },

        getInstanceValue({ key, type }) {
            return this.modelResources[key].instances[type].type;
        },

        instancesValueSetter({ key, type, instances }) {
            const modelInstance = this.modelResources[key].instances[type];

            if (includes(instances, type)) {
                const { executed, planned } = getMeasurementCounts({
                    promotions: this.getSupplierCommitmentPromotions,
                    detailedProvisionKey: type,
                    resourceKey: key,
                    supplierCommitment: this.getSelectedSupplierCommitment,
                    categoryLevel: this.getHierarchyConfig.categoryLevel,
                    detailedProvisions: this.detailedProvisions,
                });

                modelInstance.value = true;
                modelInstance.planned = planned;
                modelInstance.executed = executed;
            } else {
                modelInstance.value = false;
                modelInstance.noOfOccurrences = null;
                modelInstance.note = null;
                modelInstance.planned = 0;
                modelInstance.executed = 0;
            }

            this.updateModelAfterValidation();
        },

        instancesFieldGetter({ key, type, field }) {
            return this.modelResources[key].instances[type][field];
        },

        instancesFieldSetter({ key, type, value, field }) {
            this.modelResources[key].instances[type][field] = value;

            this.updateModelAfterValidation();
        },
        resourcesSetter({ key, resources }) {
            if (includes(resources, key)) {
                this.modelResources[key].enabled = true;
                this.modelResources[key].instances.any.value = true;

                const { executed, planned } = getMeasurementCounts({
                    promotions: this.getSupplierCommitmentPromotions,
                    detailedProvisionKey: 'any',
                    resourceKey: key,
                    supplierCommitment: this.getSelectedSupplierCommitment,
                    categoryLevel: this.getHierarchyConfig.categoryLevel,
                    detailedProvisions: this.detailedProvisions,
                });

                this.modelResources[key].instances.any.executed = executed;
                this.modelResources[key].instances.any.planned = planned;
            } else {
                this.modelResources[key].enabled = false;
                Object.keys(this.modelResources[key].instances).forEach(detailedProvisionKey => {
                    const instance = this.modelResources[key].instances[detailedProvisionKey];

                    instance.note = null;
                    instance.noOfOccurrences = null;
                    instance.value = false;
                    instance.planned = 0;
                    instance.executed = 0;
                });
            }

            this.updateModelAfterValidation();
        },
        shouldRenderDetailedProvisions({ key }) {
            const resource = this.modelResources[key];
            return resource.enabled && resource.optionsKeys && resource.optionsKeys.length;
        },
        mapModelFromMatrix() {
            const { value, storeGroups } = this.matrixModel;
            const resources = Object.keys(this.modelResources).reduce((acc, resourceKey) => {
                const resource = this.modelResources[resourceKey];
                if (!resource.enabled) {
                    return acc;
                }
                const instances = [];

                Object.keys(resource.instances).forEach(instanceKey => {
                    const instance = resource.instances[instanceKey];
                    if (instance.value) {
                        instances.push({
                            noOfOccurrences: instance.noOfOccurrences,
                            note: instance.note,
                            type: instance.type,
                        });
                    }
                });

                acc.push({ resource: resource.key, instances });
                return acc;
            }, []);
            const filteredStoreGroups = this.storeGroups.filter(storeGroupsOption =>
                storeGroups.includes(storeGroupsOption.key)
            );
            return [{ resources, value, storeGroups: filteredStoreGroups }];
        },

        detailedProvisionValidations(resourceKey) {
            return [
                {
                    validator: () => {
                        if (!this.model) return false;
                        // Ensure that at least one detail provision has been selected.
                        return (
                            // Here need to use inner component model
                            // because we call validation before update this.model
                            !!this.modelResources[resourceKey].optionsKeys.some(key => {
                                return this.modelResources[resourceKey].instances[key].value;
                                // need to return any string as an error message,
                                // as vuetify is adding just 'error--text' class to invalid fields.
                            }) || 'error'
                        );
                    },
                },
            ];
        },
        updateModelAfterValidation() {
            // Used nextTick because in other cases validation doesn't change state
            // and we send invalid request during auto-save process
            this.$nextTick(() => {
                this.$parent.validate();
                this.model = this.mapModelFromMatrix();
            });
        },

        // update the measurement counts as new promotions are allocated,
        // and as the user toggles between the promotion/product measurement.
        updateMeasurementCounts() {
            const promotions = this.getSupplierCommitmentPromotions;

            // Traverse through all instances on modelResources to update the
            // executed/planned counts.
            Object.keys(this.modelResources).forEach(resourceKey => {
                Object.keys(this.modelResources[resourceKey].instances).forEach(key => {
                    const instance = this.modelResources[resourceKey].instances[key];

                    const { executed, planned } = getMeasurementCounts({
                        promotions,
                        detailedProvisionKey: key,
                        resourceKey,
                        supplierCommitment: this.getSelectedSupplierCommitment,
                        categoryLevel: this.getHierarchyConfig.categoryLevel,
                        detailedProvisions: this.detailedProvisions,
                    });

                    instance.executed = executed;
                    instance.planned = planned;
                });
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.commitment-matrix {
    display: grid;
    grid-template-rows: 5rem 5rem auto;
    background: $promo-white;

    &__key {
        @include span-full-row;
        @include flex-row;

        justify-content: flex-end;
        padding: 0 1rem;
        background: $promo-white;
        font-size: 1.1rem;
        font-weight: 400;
        margin-top: 1rem;
    }

    &__label {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
        line-height: 1.5rem;
    }

    &__header {
        grid-row: 1 / span 2;
        display: flex;
        padding: 0 0 0.5rem 0.5rem;
        border-bottom: $promo-grey 0.1rem solid;
        font-weight: 600;
        line-height: 3rem;
        font-size: 1.1rem;
        align-items: flex-end;
    }

    &__resources-wrapper {
        display: contents;
    }

    &__resource-header {
        padding: 0.6rem 0 0 1rem;
        margin: 0;
    }

    &__resource-options {
        display: contents;

        &--top {
            padding: 0 0.6rem 0 1.2rem;
            border-left: $promo-grey 0.1rem solid;
            border-bottom: $promo-grey 0.1rem solid;
            color: $promo-text-colour;
            font-weight: 400;
            font-size: 1.2rem;
            grid-row: 2;
        }

        &--bottom {
            border-left: $promo-grey 0.1rem solid;
            border-bottom: $promo-grey 0.1rem solid;
            color: $promo-text-colour;
            font-weight: 400;
            font-size: 1.2rem;
            grid-row: 3;

            .bottom-no-of-occurrences {
                padding: 0.2rem 0.4rem;
                height: 2.2rem;
                border-bottom: $promo-grey 0.1rem solid;
            }

            .bottom-notes {
                height: 9rem;
            }

            &::v-deep {
                .v-text-field.rtls-text-field .v-input__slot {
                    height: 1.5rem;
                }
                .v-textarea {
                    height: 100%;

                    .v-input__slot {
                        height: 100%;
                        padding-left: 0.5rem;
                        padding-top: 0;
                        margin: 0;
                        border: none;

                        textarea {
                            height: 100%;
                        }

                        fieldset {
                            border: none;
                        }
                    }
                    .v-input__control {
                        height: 100%;
                        padding-left: 0;
                    }
                }

                .v-messages.error--text {
                    z-index: $planner-z-index;
                    background-color: $promo-white;
                    margin-top: 0.5rem;
                }
            }
        }
    }

    &__occurrence {
        width: 5rem;
    }
    &__note {
        height: 100%;
    }
    &__store-groups {
        grid-row: 3;
        grid-column: 1;
        border-bottom: $promo-grey 0.1rem solid;
        padding: 1rem;
    }

    &__value {
        padding-left: 0.6rem;
        padding-top: 0.6rem;
        font-weight: 400;
        font-size: 1.2rem;
        border-bottom: $promo-grey 0.1rem solid;
        color: $promo-text-colour;
        &::v-deep {
            .v-text-field.rtls-text-field .v-input__slot {
                height: 2.2rem;
            }
            .vuex-currency-input__currency-symbol {
                height: 2.3rem;
            }
        }
    }

    .align-top {
        align-items: flex-start;
    }

    .border-left {
        border-left: $promo-grey 0.1rem solid;
    }

    .resource-options--bottom-width {
        width: 9rem;
    }

    &__measurement-counts {
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.1rem;
        text-align: center;
        line-height: 1.4rem;
        margin-left: 0.15rem;
        margin-right: 0.15rem;

        &--executed {
            background: $promo-primary-colour;
            color: $promo-white;
        }

        &--planned {
            background: $promo-white;
            color: $promo-primary-colour;
            border-color: $promo-primary-colour;
            border-style: solid;
            border-width: 0.1rem;
            line-height: 1.3rem;
        }
    }
}
</style>
