import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VTabs,
    {
      staticClass: "promo-tabs",
      attrs: {
        height: "2.5rem",
        "background-color": "transparent",
        "active-class": "promo-tabs__tab--active",
        "hide-slider": "",
      },
      model: {
        value: _vm.selectedTab,
        callback: function ($$v) {
          _vm.selectedTab = $$v
        },
        expression: "selectedTab",
      },
    },
    [
      _vm._l(_vm.tabs, function (tab) {
        return [
          _c("div", {
            key: `spacer-${tab.label}`,
            staticClass: "promo-tabs__spacer",
          }),
          _vm._v(" "),
          _c(
            VTab,
            {
              key: `tab-${tab.label}`,
              staticClass: "promo-tabs__tab",
              attrs: { disabled: tab.disabled },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(tab.label)) +
                  "\n        "
              ),
            ]
          ),
        ]
      }),
      _vm._v(" "),
      _c("div", { staticClass: "promo-tabs__filler" }),
      _vm._v(" "),
      _vm._l(_vm.tabs, function (tab) {
        return _c(
          VTabItem,
          {
            key: `tab-item-${tab.label}`,
            attrs: { transition: false, "reverse-transition": false },
          },
          [
            tab.component && _vm.isOnChannelTab
              ? _c(
                  "div",
                  [
                    _c(tab.component, {
                      tag: "component",
                      attrs: {
                        namespace: _vm.namespace,
                        "promotion-editing-state": _vm.promotionEditingState,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }