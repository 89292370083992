<template>
    <header>
        <!-- Standard navbar otherwise -->
        <v-app-bar
            app
            class="promo-toolbar elevation-3"
            :class="{ warning: notUsingLocalDatabase }"
        >
            <v-toolbar-items class="toolbar-items">
                <v-btn exact text :to="defaultRoute" active-class="no-active">
                    <!-- make the (optional) client logo invisible until loaded -->
                    <v-img
                        id="client-logo"
                        src="/organisation-assets/logo.png"
                        style="visiblility: invisible;"
                        @load="logoLoaded"
                    />
                    <v-img :src="logo" width="185px" />
                </v-btn>
            </v-toolbar-items>

            <v-spacer />

            <v-toolbar-items
                v-for="(route, index) in routes"
                :key="index"
                class="toolbar-items"
                :class="route.cssClasses"
            >
                <feature-toggle :toggle="route.meta.canAccess" slot-class="v-toolbar__items">
                    <template v-slot:default>
                        <v-btn
                            :key="index"
                            slot="activator"
                            exact
                            text
                            :to="route.path"
                            class="text-none nav-link"
                            :class="
                                $route.fullPath.indexOf(route.path) === 0
                                    ? 'active-link font-weight-bold'
                                    : ''
                            "
                        >
                            {{ $t(route.linkText) | toSentenceCase }}
                        </v-btn>
                    </template>
                </feature-toggle>
            </v-toolbar-items>

            <v-spacer />

            <v-toolbar-items class="toolbar-items">
                <div v-if="!hideWeeks" class="date">
                    <span class="week-of-year font-weight-bold">{{
                        weekOfYear | weekNumberClient
                    }}</span>
                    <span>{{ date | fullDateText }}</span>
                </div>
                <!--Was commented out according PROWEB-1359-->
                <!--<icon icon-name="cog" class="cog" small />-->
            </v-toolbar-items>

            <v-toolbar-items class="toolbar-items">
                <v-divider class="vertical-divider" vertical />
            </v-toolbar-items>

            <feature-toggle :toggle="canAccessAdminArea">
                <v-toolbar-items class="toolbar-items">
                    <v-btn text exact depressed @click="toAdminPage">
                        <span><v-icon v-once color="primary">mdi-settings</v-icon></span>
                    </v-btn>
                </v-toolbar-items>
            </feature-toggle>

            <v-toolbar-items>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn text class="user-menu" v-on="on" @click="isExpanded = !isExpanded">
                            <v-avatar
                                class="avatar"
                                height="4rem"
                                width="4rem"
                                color="primary"
                                size="36"
                            >
                                <span class="white--text headline">{{ userProfile.initials }}</span>
                            </v-avatar>
                            <span class="name-text">{{ userProfile.fullName }}</span>
                            <main-expand-button :is-expanded="isExpanded" />
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item>
                            <v-list-item-title>
                                <v-btn
                                    id="navbar-logout"
                                    text
                                    class="text-none nav-link"
                                    @click="onLogout"
                                >
                                    {{ $t('actions.logout') | toSentenceCase }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="shouldDisplayUserDocsLink">
                            <v-list-item-title>
                                <v-btn
                                    id="navbar-user-docs"
                                    class="text-none nav-link"
                                    text
                                    href="/api/docs/"
                                    target="_blank"
                                >
                                    {{ $t('actions.viewUserDocs') | toSentenceCase }}
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-app-bar>
    </header>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import UXEvents from '@enums/ux-events';
import { canAccessAdminArea, reportingOnly, canAccessUserDocs } from '@enums/feature-flags';
import navigation from '@/js/navigation';
import defaultRouteMixin from '../pages/mixins/default-route-mixin';

import logo from '../../img/retail-promotions-logo.png';

export default {
    mixins: [defaultRouteMixin],
    data() {
        return {
            logo,
            date: this.$moment(),
            isExpanded: false,
            canAccessAdminArea,
        };
    },
    computed: {
        ...mapGetters('weeks', ['getWeekByDate']),
        ...mapState('context', ['profile']),
        ...mapState('clientConfig', ['toggleLogic', 'mainNavbarRoutes', 'notUsingLocalDatabase']),

        shouldDisplayUserDocsLink() {
            return this.toggleLogic[canAccessUserDocs];
        },

        weekOfYear() {
            return get(this.getWeekByDate(this.date), 'weekOfYear');
        },

        userProfile() {
            if (this.profile) {
                const { firstname, surname } = this.profile;
                return {
                    fullName: `${firstname} ${surname}`,
                    initials: this.$options.filters.toSentenceCase(`${firstname[0]}${surname[0]}`),
                };
            }
            return {
                fullName: '',
                initials: '',
            };
        },

        routes() {
            return this.$router.options.routes
                .filter(route => !route.omitFromNav)
                .map(route => ({ ...route, index: this.mainNavbarRoutes[route.id].position }))
                .sort((a, b) => {
                    if (Number.isFinite(a.index) && Number.isFinite(b.index)) {
                        return a.index - b.index;
                    }
                    if (Number.isFinite(a.index)) return -1;
                    if (Number.isFinite(b.index)) return 1;
                    return a.name > b.name ? -1 : 0;
                });
        },
        hideWeeks() {
            return this.toggleLogic[reportingOnly];
        },
    },
    methods: {
        ...mapActions('context', ['logout']),

        onLogout() {
            return this.logout().then(
                redirectUrl => {
                    this.$router.push(redirectUrl);
                    this.globalEmit(UXEvents.logout);
                },
                () => {
                    // eslint-disable-next-line no-console
                    console.log('Logout error!');
                }
            );
        },

        toAdminPage() {
            navigation.toAdminPage();
        },

        logoLoaded() {
            // if the client logo exists, make it visible and style it
            document.getElementById('client-logo').style.visibility = 'visible';
            document.getElementById('client-logo').style.width = '2.5rem';
            document.getElementById('client-logo').style['margin-right'] = '1rem';
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.promo-toolbar.v-app-bar--fixed {
    background-color: $promo-white;
    z-index: $main-navbar-z-index;
}

.promo-toolbar.v-app-bar--fixed.warning {
    background-color: $promo-orange-dark !important;
}

.vertical-divider {
    height: inherit;
    margin: 0 1.4rem;
}

.toolbar-items {
    align-items: center;
}

.nav-link {
    color: $promo-primary-colour;
    padding-bottom: 0.5rem !important;

    &::v-deep .v-btn__content {
        font-size: 1.4rem;
    }

    // Needed to override the style applied by Vuetify which displays a background colour on the link.
    &::before {
        opacity: 0;
    }
}

.admin-toolbar-item {
    background-color: $promo-grey-blue-tint;
}

.active-link {
    border-bottom: 0.5rem solid $promo-primary-colour;
    padding-bottom: 0 !important;
}

.date {
    color: $text-colour;
    font-size: 1.2rem;

    .week-of-year {
        margin-right: 0.6rem;
    }
}

.avatar {
    margin-right: 1rem;
}

.name-text {
    color: $text-colour;
    font-size: 1.4rem;
}

::v-deep .cog {
    margin-left: 0.8rem;
}

.user-menu {
    box-shadow: none;
}

.v-menu__content--fixed {
    border-radius: 0;
}

.theme--light.v-list {
    border-radius: 0;
}

// This is a workaround to remove the styling applied by the --active class when the logo is clicked.
// See here for more details: https://github.com/vuetifyjs/vuetify/issues/8172
.v-btn--active.no-active::before {
    opacity: 0 !important;
}
</style>
