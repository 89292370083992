import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VRadioGroup,
    {
      staticClass: "ag-radio-group",
      attrs: { disabled: _vm.isDisabled },
      on: { change: _vm.onChange },
      model: {
        value: _vm.model,
        callback: function ($$v) {
          _vm.model = $$v
        },
        expression: "model",
      },
    },
    [
      _c(VRadio, {
        staticClass: "ag-radio",
        attrs: { value: true, ripple: false },
        nativeOn: {
          click: function ($event) {
            return _vm.onClick.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }