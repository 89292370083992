import { render, staticRenderFns } from "./product-group.vue?vue&type=template&id=16374f0a&scoped=true"
import script from "./product-group.vue?vue&type=script&lang=js"
export * from "./product-group.vue?vue&type=script&lang=js"
import style0 from "./product-group.vue?vue&type=style&index=0&id=16374f0a&prod&lang=scss&scoped=true"
import style1 from "./product-group.vue?vue&type=style&index=1&id=16374f0a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16374f0a",
  null
  
)

export default component.exports