<template>
    <div>
        <v-chip
            v-for="chip in chipsToDisplay"
            :key="field ? chip[field] : chip"
            small
            class="pt-0 mt-0 mr-1"
        >
            {{ field ? chip[field] : chip }}
        </v-chip>
        <v-tooltip v-if="hiddenItems.length" z-index="200" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" small v-on="on">
                    {{ $t('general.labels.plusXMore', { count: hiddenItems.length }) }}
                </v-chip>
            </template>
            <ul class="simple-list">
                <li
                    v-for="(item, hiddenIndex) in hiddenItems"
                    :key="'h-item' + hiddenIndex"
                    class="simple-list__item"
                >
                    <span>
                        {{ field ? item[field] : item }}
                    </span>
                </li>
            </ul>
        </v-tooltip>
    </div>
</template>

<script>
import { drop, slice } from 'lodash';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        field: {
            type: String,
            required: false,
        },
        maxNumChipsToDisplay: {
            type: Number,
            required: false,
            default: 2,
        },
    },

    data() {
        return {};
    },

    computed: {
        chipsToDisplay() {
            if (this.items.length > this.maxNumChipsToDisplay) {
                return slice(this.items, 0, this.maxNumChipsToDisplay);
            }
            return this.items;
        },
        hiddenItems() {
            if (this.items.length > this.maxNumChipsToDisplay) {
                return drop(this.items, this.maxNumChipsToDisplay);
            }
            return [];
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.simple-list {
    list-style-type: none;
    padding: 0;

    &__item {
        span {
            &:not(:last-child):after {
                content: '\2014'; // em dash
                margin: 0 0.3rem;
            }
        }
    }
}
</style>
