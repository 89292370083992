var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "promotion-maintenance",
    _vm._b(
      {
        ref: "promotionMaintenance",
        attrs: {
          promotion: _vm.promotion,
          scenario: _vm.scenario,
          "is-new-promotion": _vm.isNewPromotion,
          "is-parking-lot": _vm.isParkingLot,
          "is-sub-campaign-allocation": _vm.isSubCampaignAllocation,
          "is-past-promotions": _vm.isPastPromotions,
          "promotion-editing-state": _vm.promotionEditingState,
        },
        on: {
          "split-requested": function ($event) {
            return _vm.$emit("split-requested")
          },
          "save-button-clicked": function ($event) {
            return _vm.$emit("save-button-clicked", $event)
          },
        },
      },
      "promotion-maintenance",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }