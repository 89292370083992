<template>
    <v-btn-toggle
        v-if="supplierCommitmentTabOptions.length > 1"
        v-model="selectedTab"
        mandatory
        dense
    >
        <v-btn
            v-for="tabOption in supplierCommitmentTabOptions"
            :key="tabOption"
            :value="tabOption"
            secondary
        >
            {{ $t(`supplierCommitments.toggles.${tabOption}`) | toSentenceCase }}
        </v-btn>
    </v-btn-toggle>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { includes } from 'lodash';
import { supplierCommitmentOptions } from '@enums/feature-flags';

export default {
    props: {
        selectedSupplierCommitmentType: {
            type: String,
        },
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('supplierCommitments', ['activeSupplierCommitmentsSection']),

        supplierCommitmentTabOptions() {
            return this.toggleLogic[supplierCommitmentOptions];
        },

        selectedTab: {
            get() {
                return this.activeSupplierCommitmentsSection;
            },
            set(value) {
                this.setActiveSupplierCommitmentsSection({
                    activeSupplierCommitmentsSection: value,
                });
                this.globalEmit('supplier-commitment-tab-changed');
            },
        },
    },

    mounted() {
        const selectedSupplierCommitmentType =
            this.selectedSupplierCommitmentType &&
            includes(this.supplierCommitmentTabOptions, this.selectedSupplierCommitmentType)
                ? this.selectedSupplierCommitmentType
                : this.supplierCommitmentTabOptions[0];
        this.onSupplierCommitmentTypeChange(selectedSupplierCommitmentType);
    },

    methods: {
        ...mapActions('supplierCommitments', ['setActiveSupplierCommitmentsSection']),

        onSupplierCommitmentTypeChange(value) {
            this.setActiveSupplierCommitmentsSection({ activeSupplierCommitmentsSection: value });
            this.globalEmit('supplier-commitment-tab-changed');
        },
    },
};
</script>
