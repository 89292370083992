<template>
    <promo-resource-tab
        :key="selectedSubCampaignId"
        v-bind="form"
        :edit-context="selectedSubCampaign"
        :promo-resource="promoResource"
    />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

const form = {
    vuexModule: 'subCampaigns',
    editMode: true,
    editEvent: 'subCampaignUpdated',
    preventDefault: true,
    fields: [
        {
            fieldName: 'resourceDefinitions',
            editable: true,
        },
    ],
};

export default {
    props: ['promoResource'],
    data: () => ({
        form,
    }),
    computed: {
        ...mapState('subCampaigns', ['selectedSubCampaignId']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
    },
};
</script>
