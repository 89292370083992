import axios from 'axios';
import { merge } from 'lodash';
import to from 'await-to-js';
import { productPrices as productPricesEnum } from '@enums/resources';
import storeMixin from '@/js/store/mixins/vuex-store';
import i18n from '../../vue-i18n';

const getInitialState = () => ({
    productPrices: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getProductPriceById
     * - getFilteredProductPrices
     */
    getters: {},

    /**
     * Default mutations available:
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     *
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        async fetchProductPrices({ dispatch }, { params }) {
            // Call Product Prices API for product.
            const [error, response] = await to(
                axios.post('/api/product-prices/for-promotion', params)
            );
            if (error) {
                return dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: productPricesEnum,
                    }),
                });
            }

            const productPrices = response.data;

            return productPrices;
        },
    },
};

const mixinParams = {
    resource: 'product-price',
    readOnly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
