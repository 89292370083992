<template>
    <div class="document-scroller">
        <div :style="gridCss" class="document-navigator">
            <div class="document-navigator__arrow">
                <navigate-button :disabled="!canScrollLeft" left @click="navigateLeft" />
            </div>
            <div
                v-for="(doc, docIndex) in documentsToShow"
                :key="doc.key"
                :class="{
                    'selected-document': doc.key === selectedDocumentKey,
                    'document__border--right': docIndex === numDocumentsToShow - 1,
                }"
                class="document clickable"
                @click="selectDocument(doc.key)"
            >
                <div class="ml-2">
                    <h3>{{ doc.title | toSentenceCase }}</h3>
                    <h4>{{ doc.subType | toSentenceCase }}</h4>
                    <h4 class="truncate-text">
                        <truncate-text-tooltip :text="doc.subtitle" :max-width="800" />
                    </h4>
                </div>
            </div>
            <div
                v-for="(doc, placeholderIndex) in placeholderDocuments"
                :key="doc"
                :class="{
                    'document__border--right': placeholderIndex === placeholderDocuments.length - 1,
                }"
                class="document"
            />
            <div class="document-navigator__arrow">
                <navigate-button :disabled="!canScrollRight" right @click="navigateRight" />
            </div>
        </div>
    </div>
</template>

<script>
import { range } from 'lodash';
import UXEvents from '@enums/ux-events';

export default {
    props: {
        documents: Array,
        selectedDocumentKey: String,
        numDocumentsToShow: Number,
        selectDocumentOnOpen: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            index: 0,
        };
    },
    computed: {
        canScrollLeft() {
            return this.index > 0;
        },
        canScrollRight() {
            return (
                this.documents.length > this.numDocumentsToShow &&
                this.index < this.documents.length - this.numDocumentsToShow
            );
        },
        documentsToShow() {
            return this.documents.slice(this.index, this.numDocumentsToShow + this.index);
        },
        placeholderDocuments() {
            if (this.documents.length < this.numDocumentsToShow) {
                return range(this.numDocumentsToShow - this.documents.length);
            }
            return [];
        },
        gridCss() {
            // Dynamically setup number of columns based on the number of docs to show
            return {
                'grid-template-columns': `5% repeat(${this.numDocumentsToShow}, ${90 /
                    this.numDocumentsToShow}%) 5%`,
            };
        },
    },
    created() {
        if (this.selectDocumentOnOpen && this.documents.length) {
            this.selectDocument(this.documents[0].key);
        }
    },
    events: {
        [UXEvents.promoResourceDocumentCreated](key) {
            if (this.documents.length > this.numDocumentsToShow - 1) {
                this.index = this.documents.length - this.numDocumentsToShow + 1;
            }
            this.selectDocument(key);
        },
    },
    methods: {
        navigateLeft() {
            this.index -= 1;
        },
        navigateRight() {
            this.index += 1;
        },
        selectDocument(selectedDocumentKey) {
            this.$emit('document-selected', selectedDocumentKey);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.document-scroller {
    display: flex;
    height: 10rem;
    margin-top: 1rem;
}

.document-navigator {
    display: grid;
    width: 100%;
    color: $promo-grey-dark;
    &__arrow {
        align-self: center;
    }
}

.document {
    border-left: 0.1rem solid $promo-divider-colour;
    &__border--right {
        border-right: 0.1rem solid $promo-divider-colour;
    }
}

.document-performance {
    align-self: center;
    text-align: right;
    margin-right: 2rem;
}

.selected-document {
    border-bottom: 3px solid $promo-primary-colour;
    color: $promo-text-colour;
    max-width: 50rem;
}

.truncate-text {
    font-weight: normal;
}
</style>
