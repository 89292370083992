<template functional>
    <svg
        width="16px"
        height="17px"
        viewBox="0 0 16 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Social</title>
        <g id="------Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icons" transform="translate(-38.000000, -214.000000)">
                <g id="Social" transform="translate(38.000000, 214.800510)">
                    <rect id="Rectangle" x="0" y="0" width="16" height="16" />
                    <g id="Group-27" transform="translate(1.636364, 1.000000)" fill="#2F477C">
                        <path
                            id="Fill-44"
                            d="M10.6060606,9.89558872 C10.0686804,9.89558872 9.58787879,10.1064321 9.22019559,10.436747 L4.17878788,7.5200931 C4.21413499,7.35843373 4.24242424,7.19679354 4.24242424,7.02811884 C4.24242424,6.85944414 4.21413499,6.69780394 4.17878788,6.53614458 L9.16363636,3.64759036 C9.54545455,3.99900876 10.0474683,4.21686747 10.6060606,4.21686747 C11.7798044,4.21686747 12.7272727,3.27510679 12.7272727,2.10843373 C12.7272727,0.941779847 11.7798044,0 10.6060606,0 C9.4323168,0 8.48484848,0.941779847 8.48484848,2.10843373 C8.48484848,2.27710843 8.51313774,2.4387678 8.54848485,2.600408 L3.56363636,5.48896221 C3.18181818,5.13756298 2.67980441,4.9196851 2.12121212,4.9196851 C0.94746832,4.9196851 0,5.86144578 0,7.02811884 C0,8.19479189 0.94746832,9.13655257 2.12121212,9.13655257 C2.67980441,9.13655257 3.18181818,8.9186747 3.56363636,8.56727547 L8.59798623,11.4909639 C8.56261983,11.6385542 8.54140771,11.7931791 8.54140771,11.9478039 C8.54140771,13.0793237 9.4676832,14 10.6060606,14 C11.744438,14 12.6707135,13.0793237 12.6707135,11.9478039 C12.6707135,10.8162651 11.744438,9.89558872 10.6060606,9.89558872 Z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Social',
};
</script>
