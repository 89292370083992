'use strict';

const { round, isNil, isEmpty, get } = require('lodash');

// ([price excl. tax] – [commercialCost] + [per unit funding]) / [price incl. tax]
// if there are multiple tiers in promotion offer mechanic use promo prices for the first tier
function incTaxCalculation({ product, prices, useMinPrice = false, excludeFunding = false }) {
    const { commercialCosts, funding } = product;
    const standardWeight = product.standardWeight || 1;
    const { avgPrice, avgPriceExcTax, minPrice } = prices;
    const { avgCost } = commercialCosts;

    // The non-promo margin should not be impacted by the variable funding that is applied for that product.
    // this is converted back to kg as unitFundingValue is normweighted
    const unitFundingValue = excludeFunding
        ? 0
        : funding.variableFunding.unitFundingValue / standardWeight;

    if (avgPrice && !useMinPrice) {
        return round((avgPriceExcTax - avgCost + unitFundingValue) / avgPrice, 4);
    }
    if (avgPrice && minPrice && useMinPrice) {
        const taxMultiplier = avgPriceExcTax / avgPrice;
        const minPriceExcTax = minPrice * taxMultiplier;

        return round((minPriceExcTax - avgCost + unitFundingValue) / minPrice, 4);
    }

    return 0;
}

// ([price excl. tax] – [commercialCost] + [per unit funding]) / [price excl. tax]
// if there are multiple tiers in promotion offer mechanic use promo prices for the first tier
function excTaxCalculation({ product, prices, useMinPrice = false, excludeFunding = false }) {
    const { commercialCosts, funding } = product;
    const standardWeight = product.standardWeight || 1;
    const { avgPrice, avgPriceExcTax, minPrice } = prices;
    const { avgCost } = commercialCosts;

    // The non-promo margin should not be impacted by the variable funding that is applied for that product.
    // this is converted back to kg as unitFundingValue is normweighted
    const unitFundingValue = excludeFunding
        ? 0
        : funding.variableFunding.unitFundingValue / standardWeight;

    if (avgPrice && !useMinPrice) {
        return round((avgPriceExcTax - avgCost + unitFundingValue) / avgPriceExcTax, 4);
    }

    if (avgPrice && minPrice && useMinPrice) {
        const taxMultiplier = avgPriceExcTax / avgPrice;
        const minPriceExcTax = minPrice * taxMultiplier;

        return round((minPriceExcTax - avgCost + unitFundingValue) / minPriceExcTax, 4);
    }

    return 0;
}

// BP: ([promoPriceExcTax] - ([percentageValue] * [avgPrice])) - ([avgCommercialCost] - [avgInvoiceCost])
// SC: [onInvoiceCost] - [buyingPrice]
// if there are multiple tiers in promotion offer mechanic use promo prices for the first tier
function incTaxFundingCalculation({ product, percentage }) {
    const avgInvoiceCost = get(product.onInvoiceCosts, 'avgCost');
    const avgCommercialCost = get(product.commercialCosts, 'avgCost');
    const standardWeight = product.standardWeight || 1;
    if (isNil(avgInvoiceCost) || isNil(avgCommercialCost) || isEmpty(product.promoPrices)) {
        return {
            newBuyingPrice: null,
            funding: 0,
        };
    }

    const promoPrice = product.promoPrices[0].avgPrice;

    const promoPriceExcTax = product.promoPrices[0].avgPriceExcTax;
    const costDelta = avgCommercialCost - avgInvoiceCost;
    const newBuyingPrice = promoPriceExcTax - percentage * promoPrice - costDelta;
    const funding = (avgInvoiceCost - newBuyingPrice) * standardWeight;

    return { newBuyingPrice, funding };
}

// BP: ([promoPriceExcTax] - ([percentageValue] * [avgPriceExcTax])) - ([avgCommercialCost] - [avgInvoiceCost])
// SC: [onInvoiceCost] – [buyingPrice]
// if there are multiple tiers in promotion offer mechanic use promo prices for the first tier
function excTaxFundingCalculation({ product, percentage }) {
    const avgInvoiceCost = get(product.onInvoiceCosts, 'avgCost');
    const avgCommercialCost = get(product.commercialCosts, 'avgCost');
    const standardWeight = product.standardWeight || 1;
    if (isNil(avgInvoiceCost) || isNil(avgCommercialCost) || isEmpty(product.promoPrices)) {
        return {
            newBuyingPrice: null,
            funding: 0,
        };
    }

    const promoPrice = product.promoPrices[0].avgPriceExcTax;

    const promoPriceExcTax = product.promoPrices[0].avgPriceExcTax;
    const costDelta = avgCommercialCost - avgInvoiceCost;
    const newBuyingPrice = promoPriceExcTax - percentage * promoPrice - costDelta;
    const funding = (avgInvoiceCost - newBuyingPrice) * standardWeight;

    return { newBuyingPrice, funding };
}

// BP: [avgCost] * (1 - [percentageValue])
// SC: [avgCost] * [percentageValue]
function discountFundingCalculation({ product, percentage }) {
    const { avgCost } = product.onInvoiceCosts;

    if (isNil(avgCost)) {
        return {
            newBuyingPrice: null,
            funding: 0,
        };
    }

    const newBuyingPrice = avgCost * (1 - percentage);
    const funding = avgCost * percentage;

    return { newBuyingPrice, funding };
}

module.exports = {
    incTaxCalculation,
    excTaxCalculation,
    incTaxFundingCalculation,
    excTaxFundingCalculation,
    discountFundingCalculation,
};
