<template>
    <div class="supplier-commitments-viewer">
        <supplier-commitment-type-toggle class="supplier-commitments-viewer__toggle" />

        <div class="supplier-commitments-viewer__filter">
            <supplier-commitments-filter-bar
                :max-filter-count="maxFilterCount"
                @change-categories-filter="hideNewCommitmentForm()"
            />
        </div>

        <div
            v-if="isSpecifiedAllocationsSection"
            class="supplier-commitments-viewer__specified-allocations-grid"
        >
            <div
                v-for="(field, index) in fields"
                :key="`supplierCommitmentHeader::${index}`"
                class="supplier-commitments-viewer__header"
            >
                <div v-if="field.header">
                    {{ $t(`supplierCommitments.headers.${field.header}`) | toSentenceCase }}
                </div>
            </div>

            <template
                v-for="supplierCommitment in filteredSupplierCommitmentsMap.current
                    .specifiedAllocations"
            >
                <supplier-commitment-viewer
                    :key="`supplierCommitment::${supplierCommitment._id}`"
                    :supplier-commitment="supplierCommitment"
                    :fields="fields"
                    :form-fields="formFields"
                    @click="onCommitmentClick({ id: supplierCommitment._id })"
                />
            </template>

            <!--grid filler when filter is empty-->
            <div
                v-if="isGridFillerVisible"
                :class="['supplier-commitments-viewer__header', 'bold']"
            >
                {{ $t('supplierCommitments.filterNotSelected') | toSentenceCase }}
            </div>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
                <supplier-commitment-form-wrapper
                    ref="addNewCommitmentForm"
                    :form-fields="formFields"
                />
            </template>
            <div class="supplier-commitments-viewer__historical-title">
                {{ $t('supplierCommitments.historicalTitle') | toSentenceCase }}
            </div>
            <template
                v-for="supplierCommitment in filteredSupplierCommitmentsMap.historical
                    .specifiedAllocations"
            >
                <supplier-commitment-viewer
                    :key="`historicalSupplierCommitment::${supplierCommitment._id}`"
                    :supplier-commitment="supplierCommitment"
                    :fields="fields"
                    :form-fields="formFields"
                    make-read-only
                    @click="onCommitmentClick({ id: supplierCommitment._id, isCurrent: false })"
                />
            </template>
        </div>

        <total-spend-viewer v-else-if="isTotalSpendSection" ref="totalSpendViewer" />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { fieldTypes } from '@enums/vuex-form';
import { isEmpty, find, map } from 'lodash';
import measurements from '@enums/measurements';
import supplierCommitmentTypes from '@enums/supplier-commitment-types';
import namespaces from '@enums/namespaces';
import navigation from '@/js/navigation';
import fields from '../../config/supplier-commitment-viewer-fields';
import { getMeasurementsOptions } from '@/js/utils/supplier-commitment-utils';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import suppliersCategoriesOptionsMixin from '../mixins/suppliers-categories-options-mixin';

export default {
    mixins: [suppliersCategoriesOptionsMixin],
    data() {
        return {
            fields: Object.freeze(fields),
        };
    },
    computed: {
        ...mapGetters('supplierCommitments', [
            'filteredSupplierCommitmentsMap',
            'isTotalSpendSection',
            'isSpecifiedAllocationsSection',
            'getStagingAreaField',
            'getSelectedSupplierCommitment',
        ]),
        ...mapState('supplierCommitments', ['selectedSupplierCommitmentId']),
        ...mapState('suppliers', ['suppliers']),
        ...mapGetters('context', ['userCategories']),

        isGridFillerVisible() {
            // If at least one filter field is not fully filled and
            // both filteredSupplierCommitmentsMap.current.totalSpend and .specifiedAllocations are empty we return true.
            const { filterKey, filterType, filterValue } = this.$store.state.suppliersFilters[0];
            return (
                (!filterKey || !filterType || !filterValue) &&
                isEmpty(this.filteredSupplierCommitmentsMap.current.totalSpend) &&
                isEmpty(this.filteredSupplierCommitmentsMap.current.specifiedAllocations)
            );
        },

        maxFilterCount() {
            return this.isTotalSpendSection ? 3 : 2;
        },

        formFields() {
            return [
                {
                    fieldName: 'type',
                    defaultValue: supplierCommitmentTypes.specifiedAllocations,
                    hidden: true,
                },
                {
                    fieldName: 'supplierName',
                    placeholder: 'validation.supplierCommitment.placeholder.selectSupplier',
                    editable: true,
                    restricted: true,
                    type: fieldTypes.select,
                    options: this.suppliersOptions,
                    defaultValue: this.defaultSupplierFromOptions,
                    itemValue: 'name',
                    itemText: 'label',
                    filled: true,
                    events: {
                        change: {
                            method: 'supplierNameChange',
                            params: [],
                        },
                    },
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.supplierRequired'),
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'supplierKey',
                    editable: true,
                    hidden: true,
                },
                {
                    fieldName: 'clientSupplierKey',
                    editable: true,
                    hidden: true,
                },
                {
                    fieldName: 'value',
                    editable: true,
                    hidden: true,
                },
                {
                    fieldName: 'total',
                    editable: true,
                    hidden: true,
                },
                {
                    fieldName: 'current',
                    editable: true,
                    hidden: true,
                },
                {
                    fieldName: 'hierarchy',
                    placeholder: 'validation.supplierCommitment.placeholder.selectCategory',
                    cssClass: 'category',
                    restricted: true,
                    type: fieldTypes.select,
                    options: this.userCategories,
                    defaultValue: [this.categoryFromFilter],
                    itemText: 'levelEntryDescription',
                    itemValue: 'levelEntryKey',
                    returnObject: true,
                    filled: true,
                    multiple: true,
                    chips: true,
                    deletableChips: true,
                    autocomplete: true,
                    finiteList: true,
                    setter: categories =>
                        this.onSupplierCategoryChange({
                            categories,
                            supplierFields: ['supplierName', 'supplierKey'],
                        }),
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.categoryRequired'),
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'scope',
                    editable: true,
                    type: fieldTypes.text,
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.scopeRequired'),
                            validator: validators.required,
                        },
                        {
                            validator: validators.maxLength,
                            params: [250],
                        },
                    ],
                },
                {
                    fieldName: 'measurement',
                    editable: true,
                    defaultValue: measurements.promotion,
                    type: fieldTypes.vuexRadioGroup,
                    options: getMeasurementsOptions(),
                    cssClass: 'supplier-commitments-form__radio-group',
                    row: true,
                    events: {
                        change: {
                            method: 'measurementChange',
                            params: [],
                        },
                    },
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.measurementRequired'),
                            validator: validators.required,
                        },
                    ],
                },
                {
                    functionName: 'totalCurrentAllocationsHandler',
                },
                {
                    functionName: 'totalValueHandler',
                },
                {
                    type: fieldTypes.dateRange,
                    cssClass: 'vuex-form__dates',
                    editable: true,
                    restricted: true,
                    from: {
                        fieldName: 'startDate',
                    },
                    to: {
                        fieldName: 'endDate',
                    },
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.datesRequired'),
                            validator: validators.required,
                        },
                        {
                            validator: validators.dateRangeRequired,
                        },
                    ],
                },
            ];
        },

        suppliersOptions() {
            return this.suppliers.map(supplier => ({
                ...supplier,
                label: `${supplier.clientSupplierKey} - ${supplier.name}`,
            }));
        },

        defaultSupplierFromOptions() {
            if (this.defaultSupplier) {
                return find(this.suppliersOptions, {
                    supplierKey: this.defaultSupplier.supplierKey,
                });
            }
            return null;
        },

        namespace() {
            return this.$refs.addNewCommitmentForm &&
                this.$refs.addNewCommitmentForm.isNewCommitment
                ? namespaces.default
                : this.selectedSupplierCommitmentId;
        },

        supplierKey() {
            return this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'supplierKey',
            });
        },
    },

    mounted() {
        if (this.selectedSupplierCommitmentId) {
            // scroll to the selected supplier commitment
            // default vue-scrollto library duration
            const duration = 500;
            // negative offset for the selected supplier commitment to be visible below fixed header
            const offset = -75;
            this.$scrollTo(`#sc_${this.selectedSupplierCommitmentId}_field_0`, duration, {
                offset,
            });
        }
    },

    methods: {
        ...mapActions('promotions', ['fetchPromotionsForSupplierCommitment']),
        ...mapActions('supplierCommitments', [
            'setSelectedSupplierCommitmentId',
            'setStagingAreaFields',
        ]),

        async onCommitmentClick({ id, isCurrent = true }) {
            await this.fetchPromotionsForSupplierCommitment(id);

            this.setSelectedSupplierCommitmentId({
                supplierCommitmentId: id,
            });
            navigation.toSupplierCommitmentView({ supplierCommitmentId: id });

            if (isCurrent) {
                // get suppliers if sc is current
                const { hierarchy } = this.getSelectedSupplierCommitment;
                const categoryKeys = map(hierarchy, 'levelEntryKey');
                this.fetchSuppliersByCategories(categoryKeys);
            }

            if (this.$refs.addNewCommitmentForm) {
                this.$refs.addNewCommitmentForm.isNewCommitment = false;
            }
        },

        hideNewCommitmentForm() {
            if (this.$refs.addNewCommitmentForm) {
                this.$refs.addNewCommitmentForm.isNewCommitment = false;
            }
            if (this.$refs.totalSpendViewer) {
                this.$refs.totalSpendViewer.isNewSupplierCommitment = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

$grid-padding: 2rem;

.supplier-commitments-viewer {
    background-color: $promo-white;
    padding-bottom: $grid-padding;

    &__toggle {
        width: 100%;
        padding: 1rem 0 1rem 1rem;
    }

    &__specified-allocations-grid {
        display: grid;
        grid-template-columns:
            20rem
            20rem
            auto
            25rem
            15rem
            10rem
            20rem
            5rem
            5rem [last-column];

        &::v-deep {
            .category {
                .v-select.v-select--chips.v-select--chips--small {
                    .v-chip {
                        margin: 0.2rem 0.4rem;
                    }

                    .v-select__selections {
                        min-height: 2.8rem;
                    }
                }
            }
        }
    }

    &__total-spend-grid {
        display: grid;
        grid-template-columns:
            20rem
            20rem
            auto
            10rem
            10rem
            10rem
            10rem
            20rem
            5rem
            5rem [last-column];
    }

    &__header {
        padding-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $promo-text-colour;
        padding-left: 1rem;
        padding-bottom: 0.3rem;

        &.bold {
            font-weight: 600;
        }
    }

    &__historical-title {
        @include span-full-row;

        padding: 2rem 1rem 1rem;
        font-weight: 700;
        font-size: 1.4rem;
    }
}
</style>
