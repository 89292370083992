'use strict';

/**
 * Enum definition for tasks.
 *
 * @module data/enums/tasks
 */
module.exports = {
    submit: 'SUBMIT',
    approve: 'APPROVE',
    unlock: 'UNLOCK',
    requestUnlock: 'REQUEST_UNLOCK',
    draftSubmit: 'DRAFT_SUBMIT',
};
