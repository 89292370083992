import { merge } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    nominationTemplates: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getNominationTemplatesById
     */
    getters: {},

    /**
     * Default mutations available:
     * - setLoading
     * - setNominationTemplates
     * - deleteNominationTemplates
     * - updateNominationTemplates
     * - addNominationTemplates
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchNominationTemplates
     * - createNominationTemplates
     * - deleteNominationTemplates
     * - updateNominationTemplates
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'nomination-template',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
