<template>
    <v-radio-group v-model="model" class="ag-radio-group" :disabled="isDisabled" @change="onChange">
        <v-radio :value="true" class="ag-radio" :ripple="false" @click.native="onClick" />
    </v-radio-group>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            isSelected: false,
        };
    },
    computed: {
        model: {
            get() {
                // Getting the value from the params ensures this component is responsive when
                // the radio button value is changed outside of this component (e.g. by a Vuex value changing).
                return this.params.value;
            },
            set(newValue) {
                // Set the internal state of this component as the value changes from clicking the radio button.
                this.isSelected = newValue;
            },
        },

        isDisabled() {
            return this.isReadOnly;
        },
    },
    created() {
        // Ensure the internal state is set correctly when the component is first created.
        this.isSelected = this.params.value;
    },
    methods: {
        onChange() {
            const entityIdFieldName = this.params.entityIdFieldName;

            if (!entityIdFieldName) {
                return;
            }

            this.params.onRadioButtonChange({
                entityId: this.params.data[entityIdFieldName],
                isSelected: this.isSelected,
            });

            // Ensure any data changes made as part of onRadioButtonChange
            // are reflected in the actual cell values.
            this.params.api.refreshCells();
        },

        onClick() {
            if (!this.params.allowRadioButtonDeselect || !this.model) {
                return;
            }

            this.params.onRadioButtonDeselect();
            this.isSelected = false;

            // Ensure any data changes made as part of onRadioButtonDeselect
            // are reflected in the actual cell values.
            this.params.api.refreshCells();
        },
    },
});
</script>

<style scoped>
.ag-radio {
    margin: 0;
}

.ag-radio-group {
    margin: 0;
}
</style>
