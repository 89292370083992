<template>
    <v-checkbox
        v-model="model"
        class="rtls-checkbox"
        :label="label"
        :value="value"
        :disabled="isDisabled"
        :rules="rules"
        :hide-details="hideDetails"
        :indeterminate="indeterminate"
        @click="$emit('click')"
    />
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],

    props: {
        hideDetails: {
            type: Boolean,
            default: true,
        },
        value: {
            String,
            default: null,
        },
        indeterminate: {
            Boolean,
            default: false,
        },
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },
};
</script>

<style></style>
