<template>
    <div class="ag-editable-price-input">
        <div v-if="isEditable" class="input-container">
            <vuex-number-input
                ref="numberInput"
                class="input"
                :getter="() => value"
                :setter="setValue"
                :format="format"
                :validations="validationRules"
                positive-only
            />
        </div>
        <div v-else class="span-container">
            <span>{{ formattedValue }}</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isNil, get } from 'lodash';
import { roundProductPrices } from '@sharedModules/promo-price-calculator';
import rewardTypes from '@enums/reward-types';
import validators from '@/js/validators';
import { offer } from '@enums/promotion-tabs';

export default Vue.extend({
    data() {
        return {
            value: null,
        };
    },
    computed: {
        ...mapGetters('promotions', ['editablePriceMap']),
        format() {
            return `numbers.default.${this.params.colDef.format}`;
        },
        isEditable() {
            if (!get(this.params, 'data.nonPromoPrices')) {
                return false;
            }
            const { namespace, tierIndex, productOfferGroupId } = this.params;
            return (
                this.editablePriceMap[namespace][tierIndex] &&
                this.editablePriceMap[namespace][tierIndex].productOfferGroups[productOfferGroupId]
            );
        },
        formattedValue() {
            return !isNil(this.value) ? this.$n(this.format, this.value) : '';
        },
        validationRules() {
            return [
                {
                    validator: validators.required,
                },
            ];
        },
        rowIndex() {
            return this.params.rowIndex;
        },
        colId() {
            return this.params.column.colId;
        },
    },
    created() {
        this.value = this.params.value;
    },
    events: {
        onPaste(event) {
            const value = parseFloat(String(event.value).replace(',', '.'));
            if (!this.isEditable || !value) {
                return false;
            }
            const eventIndex = get(event, 'node.rowIndex', null);
            const eventColId = get(event, 'column.colId', null);

            if (eventIndex === this.rowIndex && eventColId === this.colId) {
                this.setValue(value);
                // Run validation after paste, vuetify component run validation on blur
                // programaticly focus and the blur in setTimeout
                // Note: nextTick doesn't work correctly here in case when user past a lot of values
                // only last element validated with nextTick
                setTimeout(() => {
                    const textField = get(this.$refs, 'numberInput.$refs.textField', null);
                    if (textField) {
                        textField.focus();
                        textField.blur();
                    }
                }, 200);
            }
        },
    },
    methods: {
        ...mapActions('promotions', ['setProductPrice']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        setValue(value) {
            this.value = value;
            const params = {
                value: roundProductPrices({ productPrices: { [this.params.colDef.colId]: value } })[
                    this.params.colDef.colId
                ],
                productKey: this.params.data.productKey,
                tierIndex: this.params.tierIndex || 0,
                reward: this.params.reward,
                priceId: this.params.colDef.colId,
                namespace: this.params.namespace,
                data: this.params.data,
            };
            // need next tick for getting proper validation status
            this.$nextTick(() => {
                if (this.params.colDef.colId.indexOf('avgPrice') > -1) {
                    const { nonPromoPrices } = this.params.data;
                    const avgPriceExcTax =
                        nonPromoPrices.avgPrice === 0
                            ? 0
                            : (nonPromoPrices.avgPriceExcTax / nonPromoPrices.avgPrice) * value;
                    const avgPriceExcTaxRounded = roundProductPrices({
                        productPrices: { avgPriceExcTax },
                    }).avgPriceExcTax;
                    this.setProductPrice({
                        ...params,
                        priceId: 'avgPriceExcTax',
                        value: avgPriceExcTaxRounded,
                    });

                    // For new price reward types, fix avgPrice into min and max prices
                    if (rewardTypes.newPriceRewardTypes.includes(params.reward)) {
                        this.setProductPrice({
                            ...params,
                            priceIds: ['avgPrice', 'minPrice', 'maxPrice'],
                        });
                        this.params.fixAvgPromoPrice(params);
                    }
                } else {
                    this.setProductPrice(params);
                }

                this.setUnsavedPromotion({
                    namespace: this.params.namespace,
                    tab: offer,
                    value: true,
                });
            });
        },
    },
});
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.ag-editable-price-input {
    height: 2rem;

    .input-container {
        &::v-deep .v-input__slot {
            margin-top: 0.8rem;
            height: 2rem;
        }
    }

    ::v-deep {
        .v-messages__message {
            display: none;
        }
    }
}
</style>
