var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "candidates-list", style: _vm.getWrapperStyles },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "candidates-list__drop-area",
            style: _vm.draggableStyles,
            attrs: {
              draggable: ".allowed-to-drag",
              sort: false,
              disabled: _vm.isDisabled,
            },
            on: {
              change: function ($event) {
                return _vm.$emit("draggable-change", $event)
              },
            },
            model: {
              value: _vm.candidatesPage,
              callback: function ($$v) {
                _vm.candidatesPage = $$v
              },
              expression: "candidatesPage",
            },
          },
          "draggable",
          _vm.draggableAreaOptions,
          false
        ),
        [
          _vm.renderPlaceholder
            ? _c(
                "div",
                {
                  staticClass: "text-center",
                  attrs: { id: "noCandidatesPlaceholder" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t(
                          "planning.promotionsMaintenance.products.candidates.noCandidatesPlaceholder"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              )
            : _vm._l(_vm.candidatesPage, function (candidate, ix) {
                return _c(
                  "div",
                  {
                    key: `candidate-${candidate.name}-${candidate._id}`,
                    staticClass: "candidates-list__candidate",
                    class: [
                      candidate.preventDrag ? "disabled" : "allowed-to-drag",
                      {
                        "candidates-list__candidate-hover":
                          _vm.isDraggable && !_vm.isDisabled,
                      },
                    ],
                    style: _vm.getCandidatesStyles(ix),
                  },
                  [
                    candidate.products
                      ? _c(
                          "product-group",
                          _vm._b(
                            {
                              staticClass: "candidates-list__product-group",
                              attrs: {
                                draggable:
                                  _vm.isDraggable && !candidate.preventDrag,
                                disabled:
                                  _vm.isDisabled || !!candidate.preventDrag,
                                "selected-products": _vm.selectedProducts,
                                selectable:
                                  _vm.productSelectionEnabled &&
                                  !candidate.preventDrag,
                                "is-open": _vm.isProductGroupExpandedByDefault({
                                  productGroup: candidate,
                                }),
                                editable:
                                  _vm.isProductGroupsEditable &&
                                  !candidate.preventDrag,
                                "is-new": _vm.isNewPg(candidate),
                                "product-lozenge-layout":
                                  _vm.getProductLozengeLayout(),
                                "list-namespace": _vm.listNamespace,
                                "product-groups-opened-state":
                                  _vm.productGroupOpenState[_vm.listNamespace],
                                "promotion-namespace": _vm.promotionNamespace,
                              },
                              on: {
                                closed: function ($event) {
                                  return _vm.toggleProductGroup({
                                    productGroup: candidate,
                                    isOpened: false,
                                  })
                                },
                                opened: function ($event) {
                                  return _vm.toggleProductGroup({
                                    productGroup: candidate,
                                    isOpened: true,
                                  })
                                },
                                "pg-created": _vm.handleProductGroupCreated,
                                delete: function ($event) {
                                  return _vm.$emit(
                                    "delete-product-group",
                                    candidate
                                  )
                                },
                                "product-selector-update":
                                  _vm.productSelectorUpdate,
                                "draggable-change": function ($event) {
                                  return _vm.$emit(
                                    "product-group-draggable-change",
                                    {
                                      changes: $event,
                                      productGroup: candidate,
                                    }
                                  )
                                },
                              },
                            },
                            "product-group",
                            _vm.createVBindCandidate(candidate),
                            false
                          )
                        )
                      : !candidate.hideProduct
                      ? _c("product", {
                          staticClass: "candidates-list__product",
                          attrs: {
                            product: candidate,
                            selected: _vm.isProductSelected(candidate),
                            draggable:
                              _vm.isDraggable && !candidate.preventDrag,
                            selectable:
                              _vm.productSelectionEnabled &&
                              !candidate.preventDrag,
                            disabled: _vm.isDisabled || !!candidate.preventDrag,
                            layout: _vm.getProductLozengeLayout(),
                          },
                          on: {
                            "product-selector-update":
                              _vm.productSelectorUpdate,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }