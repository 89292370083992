import routeNames from '@enums/route-names';
import { isNil, map } from 'lodash';
import filterEnums from '@enums/filters';
import store from '@/js/store';

const guardsMap = {
    async [routeNames.supplierCommitmentsView](to, from, next) {
        await store.dispatch('supplierCommitments/clearSelectedSupplierCommitment');

        let category;

        if (to.query.category) {
            const filter = {};
            // convert data from string type after refreshing the page
            category = String(to.query.category);
            filter[filterEnums.categories] = category;

            if (to.query.supplier) {
                const supplier = Number(to.query.supplier);
                filter[filterEnums.suppliers] = [supplier];
            }
            // save updated filter to the store
            await store.dispatch('suppliers/setSelectedFilter', filter);
            // set default filter definitions to the store
            await store.dispatch('suppliersFilterDefinitionsSetter', {
                filterDefinitions: store.state.defaultSuppliersFilterDefinitions,
            });
            // initialize suppliers filters to show them in filter component
            await store.dispatch('initializeSuppliersFilter');
        } else {
            // get category from filter if user change tabs and backs to the supplier commitments tab
            category = store.state.suppliers.filter.categories;
        }

        // fetch supplier-commitments and suppliers if category is selected in filter
        if (!isNil(category)) {
            await store.dispatch(
                'supplierCommitments/fetchSupplierCommitmentsAndSuppliersByCategory',
                [{ filterKey: 'categories', filterValue: category }]
            );
        }

        next();
    },
    async [routeNames.supplierCommitmentView](to, from, next) {
        const { supplierCommitmentId } = to.params;
        await store.dispatch('supplierCommitments/setSelectedSupplierCommitmentId', {
            supplierCommitmentId,
        });

        let isReloaded = false;
        let sc = store.getters['supplierCommitments/getSelectedSupplierCommitment'];
        const filter = {};
        if (!sc) {
            isReloaded = true;
            // id user reload the page,
            // fetch supplier commitments with the same category as supplierCommitment from URL has
            await store.dispatch(
                'supplierCommitments/getSupplierCommitmentsWithTheSameCategoryById',
                supplierCommitmentId
            );
            sc = store.getters['supplierCommitments/getSelectedSupplierCommitment'];

            // if supplier commitment is not found go to 404 page
            if (!sc) {
                next(`/error/404`);
                return;
            }

            const categories = map(sc.hierarchy, 'levelEntryKey');

            await store.dispatch('suppliers/fetchSuppliersByCategories', categories);

            // set filter with the selected category,
            // filter supports just one category for now
            filter[filterEnums.categories] = categories[0];
        }

        // Fetch promotions associated with the supplier commitment.
        if (sc.allocatedPromotionIds && sc.allocatedPromotionIds.length) {
            await store.dispatch('promotions/fetchPromotionsForSupplierCommitment', sc._id);
        }

        if (isReloaded) {
            // save updated filter to the store
            await store.dispatch('suppliers/setSelectedFilter', filter);
            // set default filter definitions to the store
            await store.dispatch('suppliersFilterDefinitionsSetter', {
                filterDefinitions: store.state.defaultSuppliersFilterDefinitions,
            });
            // initialize suppliers filters to show them in filter component
            await store.dispatch('initializeSuppliersFilter');
        }

        next();
    },
    async [routeNames.variableFundingView](to, from, next) {
        // fetch variableFundingAgreements,suppliers and products if category is selected in filter
        const categoryFromFilter = store.state.suppliers.filter.categories;
        if (!isNil(categoryFromFilter)) {
            await store.dispatch(
                'variableFundingAgreements/fetchVariableFundingAgreementsSuppliersAndProductByCategory',
                categoryFromFilter
            );
        }
        next();
    },
};

export default guardsMap;
