var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex promotions-orders" }, [
    _c("div", {
      staticClass: "promotions-orders__caret promotions-orders__caret--up",
      class: { "active-up": _vm.order === "desc" },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "promotions-orders__caret promotions-orders__caret--down",
      class: { "active-down": _vm.order === "asc" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }