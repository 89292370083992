var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotion-tag-selector" },
    [
      _c("vuex-select", {
        staticClass: "promotion-tag-selector__select",
        attrs: {
          placeholder: _vm._f("toSentenceCase")(
            _vm.$t("planning.createPromotion.selectTags")
          ),
          clearable: "",
          "return-object": "",
          options: _vm.subCampaignTags,
          getter: () => _vm.getPromotionTagsModel(),
          setter: (value) => _vm.setPromotionTagsModel(value),
          "finite-list": "",
          "item-text": "tagName",
          "item-value": "tagKey",
          chips: "",
          "deletable-chips": "",
          multiple: "",
          disabled: _vm.disabled,
        },
        on: { change: _vm.saveTags },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }