import { merge } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    promotionProductGroups: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getPromotionProductGroupById
     * - getFilteredProductGroups
     */
    getters: {},

    /**
     * Default mutations available:
     * - setLoading
     * - setPromotionProductGroups
     * - deletePromotionProductGroup
     * - updatePromotionProductGroups
     * - addPromotionProductGroup
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchPromotionProductGroups
     * - deletePromotionProductGroup
     * - updatePromotionProductGroups
     * - createPromotionProductGroup
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'promotion-product-group',
    useForm: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
