import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMessages } from 'vuetify/lib/components/VMessages';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "nominationForm",
      model: {
        value: _vm.formValidationState.isValid,
        callback: function ($$v) {
          _vm.$set(_vm.formValidationState, "isValid", $$v)
        },
        expression: "formValidationState.isValid",
      },
    },
    [
      _c(
        "div",
        { staticClass: "nomination-matrix", style: _vm.templateStyle },
        [
          _c(
            "div",
            {
              staticClass: "nomination-matrix__header",
              style: { "grid-column-end": `span ${_vm.channelColumnsCount}` },
            },
            [
              _c("span", { staticClass: "nomination-matrix__label" }, [
                _vm._v(
                  _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("resources")))
                ),
              ]),
              _vm._v(" "),
              _vm.shouldShowNominationTemplates
                ? _c("nomination-templates", {
                    ref: "nominationTemplatesComponent",
                    attrs: {
                      "vuex-module": _vm.vuexModule,
                      namespace: _vm.namespace,
                      "nomination-model": _vm.model,
                      "store-group-options": _vm.storeGroupOptions,
                      "resource-options": _vm.resourceOptions,
                      "make-read-only": _vm.isTemplateSelectDisabled,
                    },
                    on: { change: _vm.onNominationTemplateChange },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "nomination-matrix__sidebar--header" }, [
            _c("span", { staticClass: "nomination-matrix__label" }, [
              _vm._v(
                _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("storeGroups")))
              ),
            ]),
            _vm._v(" "),
            _vm.isPromotion
              ? _c(
                  "span",
                  { staticClass: "nomination-matrix__label label-stores" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("planning.nominations.stores")
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._l(_vm.storeGroupOptions, function (storeGroup) {
            return [
              _c(
                "div",
                {
                  key: storeGroup.reference.description,
                  staticClass: "nomination-matrix__sidebar-wrapper",
                },
                [
                  _c("vuex-checkbox", {
                    staticClass: "nomination-matrix__sidebar",
                    attrs: {
                      getter: () => _vm.getStoreGroupIds,
                      setter: (storeGroups) =>
                        _vm.storeGroupsSetter(storeGroups),
                      value: storeGroup.reference._id,
                      disabled: _vm.isOptionDisabled(storeGroup),
                      validations: _vm.storeGroupsValidations(),
                      label: storeGroup.reference.description,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "nomination-matrix__stores" },
                    [
                      _vm.showExcludedStores
                        ? _c("exclude-stores-dialog", {
                            attrs: {
                              "store-group": storeGroup.reference,
                              "excluded-stores":
                                _vm.excludeStoresMap[storeGroup.reference.key],
                              disabled:
                                storeGroup.disabled ||
                                !_vm.excludeStoresMap[
                                  storeGroup.reference.key
                                ] ||
                                _vm.isReadOnly,
                            },
                            on: {
                              save: function ($event) {
                                return _vm.saveExcludedStores(
                                  $event,
                                  storeGroup.reference.key
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _vm.createMode || _vm.showDetailedProvisions
            ? _c("div", { staticClass: "nomination-matrix__sidebar--footer" })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.model.resources, function (resource) {
            return _c(
              "div",
              {
                key: resource.type,
                staticClass: "nomination-matrix--loop-wrapper",
              },
              [
                _c("vuex-icon-checkbox", {
                  key: resource.type + _vm.dialogOpen,
                  staticClass: "nomination-matrix__resource-header",
                  class: {
                    "nominations-matrix__resource-header--unselected":
                      !resource.enabled,
                  },
                  style: _vm.generateHeaderWidth(resource),
                  attrs: {
                    getter: () => resource.enabled,
                    setter: (value) => _vm.toggleResource({ resource, value }),
                    icon: resource.type,
                    "tooltip-label": _vm.getResourceLabel(resource.type),
                    disabled: _vm.isResourceDisabled(resource),
                    validations: _vm.resourceValidations(resource),
                  },
                }),
                _vm._v(" "),
                resource.enabled
                  ? [
                      _vm._l(resource.instances, function (instance, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "nomination-matrix--loop-wrapper",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nomination-matrix__state-header",
                              },
                              [
                                instance.subType
                                  ? _c(
                                      VTooltip,
                                      {
                                        attrs: {
                                          "z-index": "400",
                                          top: "",
                                          "max-width": 500,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    VIcon,
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            size: "20",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.canEditSubType &&
                                                                _vm.createMode &&
                                                                _vm.setInstanceSubType(
                                                                  {
                                                                    resource,
                                                                    instance,
                                                                    value: true,
                                                                  }
                                                                )
                                                            },
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                    mdi-information-outline\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("toSentenceCase")(
                                                _vm.$t(
                                                  `preparation.promoResources.subTypes.${instance.subType.key}`
                                                )
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showResourceState &&
                                instance.storeGroups.length
                                  ? _c("resource-state", {
                                      staticClass: "nomination-matrix__icon",
                                      attrs: {
                                        "instance-key": instance.key,
                                        "promotion-id": _vm.namespace,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.createMode && instance.updateSubType
                              ? _c("nomination-matrix-select-subtype", {
                                  staticClass: "nomination-matrix__subType",
                                  attrs: {
                                    options: resource.subTypes,
                                    "initial-value": instance.subType,
                                  },
                                  on: {
                                    setValue: (value) =>
                                      _vm.setInstanceSubType({
                                        resource,
                                        instance,
                                        subType: value,
                                      }),
                                    cancel: function ($event) {
                                      return _vm.setInstanceSubType({
                                        resource,
                                        instance,
                                        value: false,
                                      })
                                    },
                                  },
                                })
                              : _vm._l(
                                  _vm.storeGroupOptions,
                                  function (storeGroup) {
                                    return _c(
                                      "div",
                                      {
                                        key:
                                          index +
                                          storeGroup.reference.description,
                                        staticClass: "nomination-matrix__cell",
                                        class: {
                                          "nomination-matrix__cell--highlighted":
                                            _vm.highlightedInstanceKey ===
                                              instance.key && _vm.isPromotion,
                                        },
                                        on: {
                                          mouseover: function ($event) {
                                            return _vm.setHighlightedInstanceKey(
                                              { instanceKey: instance.key }
                                            )
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.setHighlightedInstanceKey(
                                              { instanceKey: null }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.displayCheckbox(
                                          instance,
                                          storeGroup.reference.key
                                        )
                                          ? _c("vuex-checkbox", {
                                              key:
                                                index +
                                                storeGroup.reference
                                                  .description +
                                                _vm.dialogOpen,
                                              attrs: {
                                                getter: () =>
                                                  instance.storeGroups,
                                                setter: (storeGroups) =>
                                                  _vm.instanceSetter(
                                                    storeGroups,
                                                    instance,
                                                    resource
                                                  ),
                                                value: storeGroup.reference,
                                                disabled:
                                                  _vm.isInstanceStoreGroupDisabled(
                                                    instance,
                                                    storeGroup.reference.key
                                                  ),
                                                validations:
                                                  _vm.instanceValidations(
                                                    resource,
                                                    instance
                                                  ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                            _vm._v(" "),
                            _vm.showDetailedProvisions
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "nomination-matrix__add-detailed-provision",
                                  },
                                  [
                                    _vm.isAddDetailedProvisionButtonVisibleForResource(
                                      { resource }
                                    )
                                      ? _c(
                                          VIcon,
                                          {
                                            staticClass:
                                              "nomination-matrix__icon",
                                            attrs: {
                                              small: "",
                                              disabled:
                                                _vm.isAddDetailedProvisionButtonDisabledForInstance(
                                                  {
                                                    resource,
                                                    instance,
                                                  }
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addDetailedProvision(
                                                  { resource, instance }
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("add_box")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.createMode
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "nomination-matrix__delete-resource",
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      {
                                        staticClass: "nomination-matrix__icon",
                                        attrs: {
                                          small: "",
                                          disabled:
                                            _vm.isDisabled ||
                                            instance.disabled ||
                                            resource.instances.length <= 1 ||
                                            _vm.resourcesUneditable,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteInstance({
                                              resource,
                                              index,
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            cancel\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _vm.createMode
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "nomination-matrix__add-resource",
                              },
                              [
                                resource.subTypes && resource.subTypes.length
                                  ? [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass:
                                            "nomination-matrix__icon",
                                          attrs: {
                                            small: "",
                                            disabled:
                                              _vm.isDisabled ||
                                              _vm.resourcesUneditable ||
                                              _vm.instanceCreationInProgress[
                                                resource.type
                                              ],
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$set(
                                                _vm.instanceCreationInProgress,
                                                resource.type,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("add_box")]
                                      ),
                                    ]
                                  : _c(
                                      VIcon,
                                      {
                                        staticClass: "nomination-matrix__icon",
                                        attrs: {
                                          small: "",
                                          disabled:
                                            _vm.isDisabled ||
                                            _vm.resourcesUneditable,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addResourceInstance({
                                              resource,
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v("add_box")]
                                    ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.instanceCreationInProgress[resource.type]
                              ? _c("nomination-matrix-select-subtype", {
                                  staticClass:
                                    "nomination-matrix__subType nomination-matrix__subType--add-resource",
                                  attrs: { options: resource.subTypes },
                                  on: {
                                    setValue: (value) =>
                                      _vm.addResourceInstance({
                                        resource,
                                        subType: value,
                                      }),
                                    cancel: function ($event) {
                                      return _vm.cancelCreateInstance({
                                        resource,
                                      })
                                    },
                                  },
                                })
                              : _c("div", {
                                  staticClass:
                                    "nomination-matrix__add-resource--filler",
                                }),
                            _vm._v(" "),
                            _c("div", {
                              staticClass:
                                "nomination-matrix__add-resource--footer",
                            }),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _c("div", {
                        staticClass: "nomination-matrix__emptyCell--header",
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "nomination-matrix__disabledResource",
                      }),
                    ],
              ],
              2
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(VMessages, {
        attrs: { color: "error", value: _vm.formValidationError },
      }),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "instanceUpdateDialog",
        staticClass: "confirm-dialog",
        attrs: {
          "question-text": _vm.$t("dialogs.removeResource.header"),
          "warning-text": _vm.instanceConfirmWarning,
          "action-text": _vm.$t("dialogs.removeResource.confirm"),
          "has-activator": false,
        },
        on: {
          confirm: function ($event) {
            return _vm.updateBasedOnPaths()
          },
          close: function ($event) {
            return _vm.cancelDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }