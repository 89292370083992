import { canEditChannel } from '@enums/feature-flags';
import FunctionProp from '@/js/utils/component-utils';

export default [
    {
        field: 'categoryName',
        span: 1,
    },
    {
        featureToggle: canEditChannel,
        component: {
            name: 'simple-button',
            text: 'preparation.buttons.select',
            functionProps: [new FunctionProp('disabled', [], 'isCategoryDisabled')],
            events: {
                onClick: {
                    method: 'setCategory',
                    params: ['categoryKey'],
                },
            },
        },
        span: 1,
    },
    {
        text: '-',
        countFunction: 'countPage',
        span: 1,
        numberFormat: 'numbers.default.numberWithDecimals',
        cellClasses: ['font-weight-bold', 'row-right-align'],
    },
    {
        text: '-',
        countFunction: 'countAllLeaflet',
        span: 1,
        numberFormat: 'numbers.default.numberWithDecimals',
        cellClasses: ['font-weight-bold', 'row-right-align'],
    },
    {
        text: '-',
        countFunction: 'countAllPercent',
        span: 1,
        numberFormat: 'numbers.default.percentage',
        cellClasses: ['font-weight-bold', 'row-right-align'],
    },
    {
        component: {
            name: 'simple-button',
            text: 'preparation.buttons.view',
            props: {
                lightTheme: true,
            },
            events: {
                onClick: {
                    method: 'setHighlightedCategoryAction',
                    params: ['categoryKey'],
                },
            },
        },
        span: 1,
    },
];
