import Vue from 'vue';
import VueRouter from 'vue-router';
import loadRoutes from './utils/vue-plugins/loadRoutes';
import { forEach, get, isEmpty } from 'lodash';
import store from './store/index';

const publicRoutes = ['/login', '/ow-auth/login', '/auth-saml/login'];

export const navigationGuards = {
    ensureAuthenticated: (to, from, next) => {
        // If not authenticated, navigate to the login page
        const { profile, expireAt } = store.state.context;
        const isTokenExpired = expireAt < Date.now();

        if (profile && isTokenExpired) {
            // if auth token is expired a refresh should be requested
            store.dispatch('context/refreshUserContext');
        }

        if (!profile && !publicRoutes.includes(to.path)) {
            console.log('No valid user session, client forced redirect to /login page.');
            return next({ path: '/login', query: { returnPath: to.path } });
        }

        return next();
    },
    ensureAuthorized: (to, from, next) => {
        // Here we ensure a user has the required permissions to access the "to" page
        // iterate over all matched routes to check canAccess property
        const isAuthorized = to.matched.every(route =>
            route.meta.canAccess ? store.state.clientConfig.toggleLogic[route.meta.canAccess] : true
        );

        const clientConfigExists = !isEmpty(store.state.clientConfig.toggleLogic);

        if (!isAuthorized) {
            console.error('accessing page without required permissions');
            // If clientConfig does not exist then it means we have cleared out vuex and will need to login
            const path = clientConfigExists ? '/error/403' : '/login';
            return next({ path });
        }

        return next();
    },
};

// Import routes and build router
const routes = loadRoutes(Vue, require.context('./pages/', true, /\.router.js$/));
const router = new VueRouter({
    routes: [],
    linkActiveClass: 'active',
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    store.commit('setIsNavigating', true);
    return next();
});

forEach(navigationGuards, guard => router.beforeEach(guard));

router.afterEach(() => {
    store.commit('setIsNavigating', false);
});

export function addRouterRoutes() {
    const filteredRoutes = routes.filter(route => {
        if (!route.featureToggle) return true;
        return get(store.state.clientConfig.toggleLogic, route.featureToggle, true);
    });
    router.addRoutes(filteredRoutes);
    // The options object is only set by VueRouter on creation so the routes there never get updated
    // when calling addRoutes. We must manually update this set of routes to ensure the navbar works.
    router.options.routes = filteredRoutes;
}

export default router;
