import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nomination-templates" }, [
    _c(
      "div",
      { staticClass: "nomination-templates__column" },
      [
        _c("vuex-select", {
          attrs: {
            placeholder: _vm._f("toSentenceCase")(
              _vm.$t("planning.nominations.nominationTemplates.selectPreset")
            ),
            options: _vm.filteredNominationTemplates,
            "item-text": "name",
            "item-value": "_id",
            getter: () => _vm.modelGetter({ model: "selectModel" }),
            setter: (value) => _vm.modelSetter({ value, model: "selectModel" }),
          },
          on: { change: _vm.nominationTemplatesChange },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "nomination-templates__column nomination-templates__column-name",
      },
      [
        _c("span", { staticClass: "nomination-templates__column--label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("planning.nominations.nominationTemplates.newPreset")
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("vuex-text-field", {
          staticClass: "nomination-templates__column--input",
          attrs: {
            getter: () => _vm.modelGetter({ model: "nameModel" }),
            setter: (value) => _vm.modelSetter({ value, model: "nameModel" }),
            "ignore-parent-read-only": true,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "nomination-templates__column" },
      [
        _c(
          VBtn,
          {
            staticClass: "nomination-templates__column--save",
            attrs: {
              primary: "",
              depressed: "",
              disabled: _vm.isSaveDisabled,
              "ignore-parent-read-only": true,
            },
            on: { click: _vm.saveNominationTemplate },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.save"))) +
                "\n        "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }