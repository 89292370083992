import to from 'await-to-js';
import axios from 'axios';
import { merge, get } from 'lodash';
import i18n from '@/js/vue-i18n';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    state: getInitialState(),

    getters: {},

    mutations: {},

    actions: {
        async performOperationOnQueue({ dispatch }, { operationId, queueId }) {
            const [error, response] = await to(
                axios.post(`/api/redis-bull/performOperationOnQueue`, { operationId, queueId })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.queueOperationSuccess'),
                errorMessage: i18n.t('notifications.queueOperationError'),
            });
            if (error) return { error };

            return response.data.numberOfEffectedJobs;
        },

        async bulkAddsForQueue({ dispatch }, { queue, filter, includeGhosts }) {
            const [error, response] = await to(
                axios.post(`/api/redis-bull/bulkAddsForQueue`, { queue, filter, includeGhosts })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobsForQueueSuccess'),
                errorMessage: i18n.t('notifications.batchJobsForQueueError'),
            });
            if (error) return { error };

            return response.data.numberOfAddedPromotions;
        },

        async runBatchJob({ dispatch }, { job, filter }) {
            const [error, response] = await to(
                axios.post(`/api/redis-bull/runBatchJob`, { job, filter })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };

            return response.data.numberOfProcessedPromotions;
        },

        async resyncPromoIds(state, { promotionIds }) {
            const [error, response] = await to(
                axios.post(`/api/promotions/deleteChildPromotionsToResyncWithParent`, {
                    promotionIds,
                })
            );
            if (error) return { error };
            return response.data;
        },
        async fixProductsWithMismatchingProducts(state, { startDate, endDate, includeGhosts }) {
            const [error, response] = await to(
                axios.post(`/api/promotions/fixProductsMismatchOnPromotions`, {
                    startDate,
                    endDate,
                    includeGhosts,
                })
            );
            if (error) return { error };
            return response.data.length;
        },
        async closeDuplicateNotifications({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/notifications/closeDuplicateNotifications`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'closedNotifications', []).length;
        },
        async fixSellInPeriodMismatch({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixSellInPeriodMismatch`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', []).length;
        },
        async copyFundingToChild({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/copyFundingToChild`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', []).length;
        },
        async fixClientState({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixClientState`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', []).length;
        },
        async fixUserSelectedCategories({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixUserSelectedCategories`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', []).length;
        },
        async fixPromosWithWrongFunding({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixPromosWithWrongFunding`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', []).length;
        },

        async fixParkingLotPromosWhereWasInParkingLotBecameFalse({ dispatch }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixParkingLotPromosWhereWasInParkingLotBecameFalse`)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedItems', 0);
        },

        async addMissingFieldToPromos({ dispatch }, { field, value, filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/addMissingFieldToPromos`, { field, value, filter })
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions', 0);
        },

        async fixPromosWithWrongRateCardIdType({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixPromosWithWrongRateCardIdType`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
        async fixingStartEndDatesAction({ dispatch }) {
            const [error, response] = await to(axios.post(`/api/diagnostics/fixingStartEndDates`));
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
        async removeUnusedCategories({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/removeUnusedCategories`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
        async recalculateFunding({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/recalculateFunding`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
        async fixProductsHierachy({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixProductsHierachy`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
        async fixVolumeSplit({ dispatch }, { filter }) {
            const [error, response] = await to(
                axios.post(`/api/diagnostics/fixVolumeSplit`, filter)
            );
            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t('notifications.batchJobSuccess'),
                errorMessage: i18n.t('notifications.batchJobError'),
            });
            if (error) return { error };
            return get(response.data, 'successFixedPromotions.length', 0);
        },
    },
};

const mixinParams = {
    resource: 'redis-bull',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
