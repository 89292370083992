<template>
    <div>
        <promotion-copy-dialog
            :key="`copyPromotionDialog::${promotion._id}`"
            :promotions="[promotion]"
        />

        <child-promotion-ghost-dialog
            v-if="promotion.parentPromotionId"
            :key="`childPromotionDeclineDialog::${promotion._id}`"
            :promotion-id="promotion._id"
            :is-ghost="promotion.isGhost"
            :ignore-parent-read-only="true"
        />

        <common-delete-dialog
            v-else
            :resource="promotionsEnum"
            :resource-id="promotion._id"
            :children="promotion.children"
            :has-been-approved="hasBeenApproved(promotion)"
            :child-dependencies-warning="false"
            :ignore-parent-read-only="true"
        />

        <main-expand-button
            :is-expanded="isExpanded"
            expanded-icon="expand_less"
            collapsed-icon="expand_more"
        />
    </div>
</template>

<script>
import { find, get, isEmpty } from 'lodash';
import { promotions } from '@enums/resources';
import workflowStates from '@enums/workflow-states';
import workflowEntities from '@enums/workflow-entities';

export default {
    props: {
        isExpanded: {
            required: true,
            type: Boolean,
        },
        promotion: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            promotionsEnum: promotions,
        };
    },
    methods: {
        hasBeenApproved(promotion) {
            return !isEmpty(
                find(get(promotion, ['workflowState'], []), {
                    state: workflowStates.hasBeenApproved,
                    entity: workflowEntities.promotion,
                    value: true,
                })
            );
        },
    },
};
</script>
