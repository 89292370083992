import { render, staticRenderFns } from "./campaigns-weekly-view-header-kpis.vue?vue&type=template&id=b7744606&scoped=true"
import script from "./campaigns-weekly-view-header-kpis.vue?vue&type=script&lang=js"
export * from "./campaigns-weekly-view-header-kpis.vue?vue&type=script&lang=js"
import style0 from "./campaigns-weekly-view-header-kpis.vue?vue&type=style&index=0&id=b7744606&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7744606",
  null
  
)

export default component.exports