<template>
    <create-new-button
        v-if="addButton"
        :btn-text="buttonText"
        :events="activator"
        :has-border="hasBorder"
        :disabled="disabled"
        background
        @createNew="$emit('click')"
    />
    <icon-button
        v-else-if="editButton"
        :events="activator"
        :ignore-parent-read-only="ignoreParentReadonly"
        :disabled="disabled"
        icon="edit"
        @click.stop="$emit('click')"
    />
    <delete-button
        v-else-if="deleteButton"
        :events="activator"
        :data-id-e2e="deleteButtonId"
        :ignore-parent-read-only="ignoreParentReadonly"
        :disabled="disabled"
        background
        @delete="fetchChildDependencies"
    />
</template>

<script>
import { camelCase } from 'lodash';
import { campaigns, subCampaigns, scenarios } from '@enums/resources';

export default {
    props: {
        activator: Object,
        buttonText: String,
        hasBorder: Boolean,
        addButton: Boolean,
        editButton: Boolean,
        ignoreParentReadonly: Boolean,
        deleteButton: Boolean,
        resourceId: String,
        resource: String,
        deleteButtonId: {
            type: String,
            default: 'deleteButton',
        },
        disabled: Boolean,
    },

    data: () => ({}),

    methods: {
        async fetchChildDependencies() {
            if ([campaigns, camelCase(subCampaigns), scenarios].includes(this.resource)) {
                await this.$store.dispatch(`${this.resource}/fetchChildDependencies`, {
                    id: this.resourceId,
                    includeGhosts: true,
                });
            }
        },
    },
};
</script>
