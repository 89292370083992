<template>
    <div class="advertisement-fees" :style="gridStyle">
        <div class="advertisement-fees__header">
            {{ $t(`${localizationKey}.rateCard`) | toSentenceCase }}
        </div>
        <div class="advertisement-fees__header">
            {{ $t(`${localizationKey}.price`, { currencySymbol }) | toSentenceCase }}
            <span v-if="hasOverwriteAmount && turnOnRateCardOverwriteReason">
                {{ $t(`${localizationKey}.overWriteHeader`) | toSentenceCase }}
            </span>
        </div>
        <div class="advertisement-fees__expanded-wrapper">
            <div class="advertisement-fees__header" />
            <div class="advertisement-fees__header" />
        </div>

        <div
            v-for="(rateCard, index) in model"
            :key="`rateCard::${rateCard._id}`"
            class="advertisement-fees__item"
        >
            <div class="advertisement-fees__rate-card">
                <v-tooltip
                    z-index="400"
                    top
                    :max-width="500"
                    :disabled="!isRateCardDisabled(index)"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                            <vuex-select
                                item-text="description"
                                item-value="_id"
                                :options="getOptions(index)"
                                :getter="() => rateCardGetter(index)"
                                :setter="value => rateCardSetter(index, value)"
                                filled
                                :validations="validationRules"
                                :disabled="isRateCardDisabled(index)"
                                v-bind="attrs"
                            />
                        </div>
                    </template>
                    <span>{{ model[index].description }} </span>
                </v-tooltip>
            </div>
            <div class="advertisement-fees__amount-area">
                <icon-button
                    v-if="isRevertVisible(rateCard)"
                    class="advertisement-fees__revert-btn"
                    icon="new-revert"
                    :disabled="!isRateCardSelected(rateCard) && isRateCardAssigned(rateCard)"
                    @click="revertAmount(index, rateCard._id)"
                />
                <vuex-number-input
                    class="advertisement-fees__amount"
                    :getter="() => rateCard.amount"
                    :setter="value => amountSetter(index, value)"
                    filled
                    :validations="valueValidationRules"
                    :disabled="!isRateCardSelected(rateCard) && isRateCardAssigned(rateCard)"
                    format="numbers.default.currencyNoLabel"
                />
                <v-tooltip
                    v-if="isRevertVisible(rateCard) && turnOnRateCardOverwriteReason"
                    z-index="400"
                    top
                    :max-width="500"
                    :disabled="!getOverwroteReasonTitle(model[index].overwriteReason)"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                            <vuex-select
                                class="advertisement-fees__reason"
                                item-text="text"
                                :options="overwriteReasonOptions"
                                :getter="() => getOverwriteReason(index)"
                                :setter="value => overwriteReasonSetter(index, value)"
                                filled
                                :validations="validationRules"
                                :validate-on-blur="false"
                                :disabled="
                                    !isRateCardSelected(rateCard) && isRateCardAssigned(rateCard)
                                "
                                v-bind="attrs"
                            />
                        </div>
                    </template>
                    <span>{{ getOverwroteReasonTitle(model[index].overwriteReason) }} </span>
                </v-tooltip>
            </div>
            <div class="advertisement-fees__expanded-wrapper">
                <div
                    v-if="isRateCardAssigned(rateCard) && !isRateCardSelected(rateCard)"
                    class="advertisement-fees__action-btns"
                >
                    <icon-button
                        icon="edit"
                        @click.stop="setSelectedRateCardIdForSplitting(rateCard._id)"
                    />
                    <icon
                        v-if="doesRateCardAmountMatchTotal(rateCard)"
                        icon-name="success-circle"
                    />
                    <icon v-else icon-name="failure-circle" />
                </div>
                <div v-else class="advertisement-fees__apply-btn">
                    <simple-button
                        :disabled="isApplyButtonDisabled(rateCard)"
                        @onClick="setSelectedRateCardIdForSplitting(rateCard._id)"
                    >
                        {{ $t('actions.Apply') | toSentenceCase }}
                    </simple-button>
                </div>
                <div class="advertisement-fees__delete-btn">
                    <common-delete-dialog
                        :resource="promotionRateCardResource"
                        :child-dependencies-warning="false"
                        @delete="deleteRateCard(index)"
                    />
                </div>
            </div>
        </div>
        <div class="advertisement-fees__new-item">
            <create-new-button :disabled="addButtonDisabled" @createNew="addRateCard" />
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { promotionRateCards as promotionRateCardResource } from '@enums/resources';
import { allowZeroRateCardAmount } from '@enums/feature-flags';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import validators from '@/js/validators';
import { funding } from '@enums/promotion-tabs';

export default {
    mixins: [vuexComponentMixin],
    props: {
        namespace: {
            required: true,
            type: String,
        },
        assignedRateCards: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            promotionRateCardResource,
            localizationKey: 'planning.promotionsMaintenance.resources.advertisementFees',
        };
    },
    computed: {
        ...mapGetters('clientConfig', ['currencySymbol']),
        ...mapState('clientConfig', ['releaseFlags', 'toggleLogic']),
        ...mapState('rateCards', ['rateCards', 'selectedRateCardIdForSplitting']),
        ...mapGetters('rateCards', ['getRateCardById']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),

        validationRules() {
            return [
                {
                    validator: validators.required,
                },
            ];
        },
        valueValidationRules() {
            return [
                {
                    validator: validators.minValue,
                    params: [0, this.toggleLogic[allowZeroRateCardAmount]],
                },
                {
                    validator: validators.required,
                },
            ];
        },
        addButtonDisabled() {
            if (this.model) {
                if (this.model.some(rateCard => !rateCard._id)) return true;

                return this.model.length === this.rateCards.length;
            }

            return false;
        },
        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },
        hasOverwriteAmount() {
            return this.model.some(item => item._id && !!this.isRevertVisible(item));
        },
        gridStyle() {
            const secondColumnWidth =
                this.hasOverwriteAmount && this.turnOnRateCardOverwriteReason ? '25rem' : '13rem';
            return {
                'grid-template-columns': `20rem ${secondColumnWidth} 6rem 2rem`,
            };
        },
        overwriteReasonOptions() {
            return this.toggleLogic.rateCardOverwriteReasons;
        },
        turnOnRateCardOverwriteReason() {
            return get(this.releaseFlags, 'releaseFlags.turnOnRateCardOverwriteReason', false);
        },
    },
    methods: {
        ...mapActions('promotions', ['removeRateCardFunding']),
        ...mapMutations('rateCards', ['setSelectedRateCardIdForSplitting']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),

        rateCardGetter(index) {
            return this.model[index]._id;
        },
        rateCardSetter(index, value) {
            const newItem = this.rateCards.find(rateCard => rateCard._id === value);
            if (newItem) {
                this.$set(this.model, index, {
                    _id: newItem._id,
                    identifier: newItem.identifier,
                    description: newItem.description,
                    originalAmount: newItem.amount,
                    amount: newItem.amount,
                    isAssigned: true,
                    overwriteReason: newItem.overwriteReason,
                });
                this.updateModelLink();
            }
        },
        editRateCard(rateCardId) {
            this.setSelectedRateCardIdForSplitting(rateCardId);
            this.closeDialog();
        },
        amountSetter(index, value) {
            const originalRateCard = this.getOriginalRateCard(this.model[index]._id);
            this.$set(this.model[index], 'amount', value);
            if (originalRateCard.amount === value) {
                this.$set(this.model[index], 'overwriteReason', value);
            }
            this.updateModelLink();
            this.globalEmit('rate-card-change');
        },
        overwriteReasonSetter(index, value) {
            this.$set(this.model[index], 'overwriteReason', value);
            this.updateModelLink();
            this.globalEmit('rate-card-change');
            this.setUnsavedPromotion({ namespace: this.namespace, tab: funding, value: true });
        },
        updateModelLink() {
            this.model = [...this.model];
        },
        addRateCard() {
            const newRateCard = {
                _id: null,
                identifier: null,
                description: null,
                originalAmount: null,
                amount: null,
                isAssigned: false,
                overwriteReason: null,
            };
            this.setUnsavedPromotion({ namespace: this.namespace, tab: funding, value: true });

            this.model.push(newRateCard);
        },
        async deleteRateCard(index) {
            const rateCardId = this.model[index]._id;
            if (rateCardId) {
                // if we have a rateCardId - make sure to update the promotion and its products in the
                // same request rather than separate requests
                await this.removeRateCardFunding({
                    rateCardId,
                    namespace: this.namespace,
                });
                this.globalEmit('rate-card-deleted');
            }
            if (!rateCardId) {
                // update model link updates the rateCards array on the promo but is not
                // concerned with the rateCard values on the promos.
                this.model.splice(index, 1);
                this.updateModelLink();
            }
            // if deleted rate card is selected in the supplier tab reset it
            if (rateCardId === this.selectedRateCardIdForSplitting) {
                this.setSelectedRateCardIdForSplitting(null);
            }
            this.setUnsavedPromotion({ namespace: this.namespace, tab: funding, value: true });
        },
        // provide options only in case if model doesn't consist them
        getOptions(index) {
            const value = this.model[index];
            const rateCardOptions = this.rateCards.filter(
                rateCard =>
                    value._id === rateCard._id ||
                    !this.model.find(item => item._id === rateCard._id)
            );

            // If rate card doesn’t exist,
            // add it to the options, to show it's name to the user
            if (value.isAssigned) {
                const originalRateCard = this.getOriginalRateCard(value._id);
                if (!originalRateCard) {
                    return [...rateCardOptions, value];
                }
            }
            return rateCardOptions;
        },

        getOverwriteReason(index) {
            const value = this.model[index];
            if (value) {
                return value.overwriteReason;
            }

            return null;
        },

        revertAmount(index, rateCardId) {
            const originalRateCard = this.getOriginalRateCard(rateCardId);
            // If rate card exists, revert to the current rate card value.
            // If it doesn’t exist, revert to the value for when
            // we added the rate card to the promotion.
            const originalAmount = originalRateCard
                ? originalRateCard.amount
                : this.model[index].originalAmount;
            this.$set(this.model[index], 'amount', originalAmount);
            this.$set(this.model[index], 'overwriteReason', null);

            this.updateModelLink();
            this.globalEmit('rate-card-change');
        },

        isRevertVisible({ _id, isAssigned, amount, originalAmount }) {
            if (isAssigned) {
                const originalRateCard = this.getOriginalRateCard(_id);
                // If rate card exists, compare to the current rate card value.
                if (originalRateCard) {
                    return amount !== originalRateCard.amount;
                }
                return amount !== originalAmount;
            }
            return false;
        },

        getOriginalRateCard(id) {
            return this.getRateCardById({ _id: id, usePluralResourceName: true });
        },

        isRateCardDisabled(index) {
            const id = this.model[index]._id;
            const isFundingAllocated = this.selectedPromotion.products.some(product =>
                get(product, 'funding.lumpFunding', []).some(rateCard => rateCard.rateCardId === id)
            );
            return isFundingAllocated;
        },

        doesRateCardAmountMatchTotal(rateCard) {
            const assignedRateCard = this.assignedRateCards.find(
                arc => arc.rateCardId === rateCard._id
            );
            return assignedRateCard && assignedRateCard.matchesExpected;
        },

        isRateCardAssigned(rateCard) {
            const assignedRateCard = this.assignedRateCards.find(
                arc => arc.rateCardId === rateCard._id
            );
            return assignedRateCard && assignedRateCard.isAssigned;
        },

        isRateCardSelected(rateCard) {
            return (
                this.selectedRateCardIdForSplitting &&
                rateCard._id === this.selectedRateCardIdForSplitting
            );
        },
        isApplyButtonDisabled(rateCard) {
            let overwriteReasonRequiredAndEmpty = false;

            if (this.turnOnRateCardOverwriteReason) {
                overwriteReasonRequiredAndEmpty =
                    this.isRevertVisible(rateCard) && !rateCard.overwriteReason;
            }

            return (
                this.isRateCardSelected(rateCard) ||
                !rateCard._id ||
                overwriteReasonRequiredAndEmpty
            );
        },
        getOverwroteReasonTitle(value) {
            const selectedOption = this.toggleLogic.rateCardOverwriteReasons.find(
                opt => opt.value === value
            );
            if (!selectedOption) {
                return null;
            }

            return selectedOption.text;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.advertisement-fees {
    display: grid;

    &__header {
        border-bottom: 0.1rem solid $promo-grey;
        padding-left: 2rem;

        span {
            margin-left: 3.7rem;
        }
    }

    &__item,
    &__expanded-wrapper {
        display: contents;

        & > div {
            padding: 0.5rem 0.5rem 0 0;

            &::v-deep .rtls-select {
                input {
                    padding-left: 0.5rem;
                }
            }
        }
    }

    &__rate-card {
        grid-column: 1;
    }

    &__new-item {
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem;
    }

    &__total-label {
        display: flex;
        padding: 0.5rem;
        justify-content: flex-end;
    }

    &__action-btns {
        display: flex;
        justify-content: space-around;
    }

    &__apply-btn {
        ::v-deep.simple-btn {
            height: 2.8rem !important;
        }
    }

    &__delete-btn {
        display: flex;
        justify-content: flex-end;
    }

    &__total {
        padding-top: 1.2rem;
        padding-right: 0.5rem;
        margin-left: auto;

        &-label {
            padding-top: 1rem;
            padding-right: 0.5rem;
        }

        &-value {
            border-top: 0.1rem solid $promo-black;
            text-align: right;
            padding-right: 0.5rem;
            width: 8.2rem;
        }
    }

    &__amount {
        width: 8.2rem;
    }

    &__reason {
        margin-left: 1rem;
        width: 15rem;
    }

    &__amount-area {
        display: flex;
    }
    &__revert-btn {
        background-color: $promo-primary-colour;
        height: 2.9rem;
    }
}
</style>
