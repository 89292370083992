<template>
    <div />
</template>

<script>
/**
 * To see how this component works visit https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1716781333/Events+and+follow+on+actions
 */
import { isArray } from 'lodash';
import events from '@/js/events';
import store from '@/js/store';

/**
 * Function that takes an array of store actions and runs them sequentially. It is possible for an array element to
 * in fact be an array of actions that we want to run in parallel which is also addressed in this function.
 * e.g. actions:
 * [
 *    actionA,
 *    actionB,
 *    [
 *        actionC,
 *        actionD,
 *    ],
 *    actionE,
 * ]
 * actionA will run first. When it is finished B will run. When B is finished C and D will run in parallel.
 * When these complete the final action E will run
 */
async function runStoreActions({ actions, params }) {
    actions.reduce(async (previousPromise, action) => {
        await previousPromise;
        if (isArray(action)) {
            const functionsToRunParallel = action.map(a => store.dispatch(a, params));
            return Promise.all(functionsToRunParallel);
        }
        return store.dispatch(action, params);
    }, Promise.resolve());
}

export default {
    data() {
        return {};
    },
    events: {
        ...events.reduce((acc, event) => {
            acc[event.key] = params => {
                // All actions for an event should be store action paths
                runStoreActions({ actions: event.actions, params });
            };
            return acc;
        }, {}),
    },
};
</script>

<style lang="scss" scoped></style>
