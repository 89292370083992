var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-title py-2" }, [
    _vm.childDependencies
      ? _c("div", [
          _c("div", { staticClass: "warning-text" }, [
            _vm._v(
              _vm._s(
                _vm._f("toSentenceCase")(_vm.$t("planning.warnings.warning"))
              ) + "!"
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("planning.warnings.deletingParentWarning", {
                      entity: _vm.$tc(`entities.${_vm.resource}`, 1),
                    })
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.childDependencies, function (entityIds, entity) {
              return _c("li", { key: entity }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$tc(`entities.${entity}`, entityIds.length)
                      )
                    ) +
                    ":\n                " +
                    _vm._s(entityIds.length) +
                    "\n                "
                ),
                _vm.extraMessages[entity]
                  ? _c("span", [
                      _vm._v(
                        "\n                    (" +
                          _vm._s(
                            _vm.$t(_vm.extraMessages[entity], {
                              entity: _vm.$tc(`entities.${entity}`, 2),
                            })
                          ) +
                          ")\n                "
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.promotionsToMoveToParkingLot
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t(
                          "planning.warnings.revertingParkingLotPromotionsWarning",
                          {
                            entity: _vm.$tc(
                              `entities.promotions`,
                              _vm.promotionsToMoveToParkingLot
                            ),
                            count: _vm.promotionsToMoveToParkingLot,
                          }
                        )
                      )
                    ) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ])
      : _c("div", [
          _c("div", { staticClass: "information-text" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("planning.information.information")
                  )
                ) +
                "!\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("planning.information.noAssociatedChildren", {
                      entity: _vm.$tc(`entities.${_vm.resource}`, 1),
                    })
                  )
                ) +
                "\n        "
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }