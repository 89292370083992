var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "19px",
        height: "20px",
        viewBox: "0 0 19 19",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Book")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c("defs", [
        _c("path", {
          attrs: {
            id: "path-1",
            d: "M18.000857,16 C18.4317806,16 18.8235294,15.7593261 18.8235294,15.1817088 L18.8235294,2.377858 C18.4121932,1.27075812 16.3163371,0 13.7993512,0 C11.7622575,0 9.99938789,0.837545126 9.41176471,1.61732852 C8.82414152,0.837545126 7.06127196,0 5.02417825,0 C2.49739854,0 0.401542511,1.27075812 -1.28608235e-12,2.377858 L-1.28608235e-12,15.1817088 C-1.28608235e-12,15.7593261 0.381955071,16 0.812878742,16 C1.16545265,16 1.37112077,15.8941035 1.57678888,15.7304452 C2.04688743,15.2779783 3.20254637,14.5848375 5.02417825,14.5848375 C6.84581012,14.5848375 8.09940626,15.2587244 8.52053621,15.6823105 C8.7164106,15.8459687 8.97104732,16 9.41176471,16 C9.84268838,16 10.0875314,15.8363418 10.2931995,15.6823105 C10.7339169,15.2779783 11.9777193,14.5848375 13.7993512,14.5848375 C15.6111893,14.5848375 16.776642,15.2876053 17.2467405,15.7304452 C17.4426149,15.8941035 17.6580768,16 18.000857,16 Z M1.28297729,14.4981949 L1.28297729,2.75330927 C1.44947053,2.21419976 2.88914733,1.17448857 5.02417825,1.17448857 C7.15920916,1.17448857 8.62826712,2.21419976 8.77517292,2.75330927 L8.77517292,14.4693141 C7.9231193,13.7858002 6.53241109,13.410349 5.02417825,13.410349 C3.5159454,13.410349 2.12523719,13.7858002 1.28297729,14.4981949 Z M17.5405521,14.4981949 C16.6884985,13.7858002 15.307584,13.410349 13.7993512,13.410349 C12.2813246,13.410349 10.9004101,13.7858002 10.0483565,14.4693141 L10.0483565,2.75330927 C10.1952623,2.21419976 11.6643203,1.17448857 13.7993512,1.17448857 C15.9343821,1.17448857 17.3740589,2.21419976 17.5405521,2.75330927 L17.5405521,14.4981949 Z",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Planner",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "0.6-Planner",
                transform: "translate(-1189.000000, -996.000000)",
                fill: "#2F477C",
                "fill-rule": "nonzero",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Promotion-bar",
                    transform: "translate(46.000000, 934.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "tabs" } }, [
                    _c(
                      "g",
                      {
                        attrs: { transform: "translate(0.000000, 57.000000)" },
                      },
                      [
                        _c("g", { attrs: { id: "Group-28" } }, [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Group-19",
                                transform: "translate(1143.000000, 5.000000)",
                              },
                            },
                            [
                              _c("g", { attrs: { id: "Book" } }, [
                                _c("use", {
                                  attrs: { "xlink:href": "#path-1" },
                                }),
                                _vm._v(" "),
                                _c("use", {
                                  attrs: { "xlink:href": "#path-1" },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }