<template functional>
    <v-radio-group row mandatory :value="props.selectedMeasurement" class="measurement">
        <v-radio
            v-for="measurement in $options.staticData.measurements"
            :key="measurement"
            :value="measurement"
            :disabled="props.disabled || parent.disabled || parent.isReadOnly"
            :label="
                parent.$t(`${$options.staticData.translationPath}.${measurement}`) | toSentenceCase
            "
        />
    </v-radio-group>
</template>

<script>
import measurements from '@enums/measurements';

export default {
    props: {
        selectedMeasurement: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    staticData: {
        measurements,
        translationPath: 'supplierCommitments.measurements',
    },
};
</script>

<style lang="scss" scoped>
.measurement {
    margin-top: 0;
    padding-top: 0;
}
</style>
