var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-dialog", {
        ref: "dialog",
        attrs: {
          heading: "userPermissions.storeGroupAccess",
          "btn-confirm-text": "actions.save",
          "btn-cancel-text": "actions.cancel",
          "has-activator": _vm.hasActivator,
        },
        on: { confirm: _vm.save, close: _vm.closeDialog },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "attributes", style: _vm.templateStyle },
                  [
                    _vm._l(_vm.storeGroupAttributes, function (attribute) {
                      return _c(
                        "div",
                        {
                          key: attribute.attributeName,
                          staticClass: "attributes__header",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(attribute.attributeName) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.userStoreGroupAttributeRestrictions,
                      function (row, rowIndex) {
                        return _c(
                          "div",
                          {
                            key: row.name,
                            staticClass: "attributes__loop-wrapper",
                          },
                          [
                            _vm._l(
                              _vm.storeGroupAttributes,
                              function (attribute, columnIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: attribute.attributeName,
                                    staticClass: "attributes__value",
                                    class: {
                                      attributes__firstValue: columnIndex === 0,
                                    },
                                  },
                                  [
                                    _c("vuex-select", {
                                      attrs: {
                                        placeholder: _vm._f("toSentenceCase")(
                                          _vm.$t(
                                            "userPermissions.placeholder.attribute"
                                          )
                                        ),
                                        options: attribute.options,
                                        getter: () =>
                                          _vm.getAttributeValues(
                                            row,
                                            attribute.attributeName
                                          ),
                                        setter: (value) =>
                                          _vm.setAttributeValues(
                                            row,
                                            attribute.attributeName,
                                            value
                                          ),
                                        "item-value": "value",
                                        "item-text": "value",
                                        multiple: "",
                                        chips: "",
                                        "deletable-chips": "",
                                        autocomplete: "",
                                        clearable: "",
                                        "finite-list": true,
                                        "max-items-in-list": 1,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("icon-button", {
                              attrs: {
                                icon: "cancel",
                                disabled: _vm.disableDeleteButton,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => _vm.removeRow(rowIndex)).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            }),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("icon-button", {
                  staticClass: "attributes__add-button",
                  attrs: { icon: "add", disabled: _vm.disableAddButton },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.addRow.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }