<template>
    <badge
        v-if="isVisible"
        :type="type"
        :is-proposed="params.data.isProposed"
        :proxy-product-key="params.data.proxyProductKey"
    />
</template>

<script>
import Vue from 'vue';
import { has } from 'lodash';

export default Vue.extend({
    props: {
        type: {
            required: false,
            type: String,
        },
    },
    computed: {
        isVisible() {
            return has(this.params, 'visible') ? this.params.visible(this.params) : true;
        },
    },
});
</script>
