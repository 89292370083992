import numeral from 'numeral';
import { isNil } from 'lodash';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '../utils/string-utils';

const minValue = (value, message, min = 0, isInclusive = true) => {
    if (isNil(value)) {
        return true;
    }

    value = numeral(value).value();

    const comparisonResult = isInclusive ? value >= min : value > min;
    const defaultErrorMessage = isInclusive
        ? 'validation.common.minInclusive'
        : 'validation.common.minExclusive';

    return comparisonResult || (message || toSentenceCase(i18n.t(defaultErrorMessage, { min })));
};

export default minValue;
