var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isExpanded
    ? _c("div", { staticClass: "summary-header volume-funding__header" }, [
        _c("div", { staticClass: "summary-header__title" }, [
          _c("label", { staticClass: "summary-header__label" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.params.displayName) + "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "summary-header__info" }, [
          _vm._v("\n        " + _vm._s(_vm.formattedValue) + "\n    "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }