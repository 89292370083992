<template>
    <create-new-button
        v-if="isCreateMode"
        :disabled="isCreateDisabled"
        background
        @createNew="createNewProduct"
    />
    <div v-else>
        <v-btn v-if="isEditMode" primary :disabled="isEditDisabled" @click="updateProposedProduct">
            {{ $t('actions.save') | toSentenceCase }}
        </v-btn>

        <proposed-product-delete-dialog
            :resource="productsResource"
            :resource-id="productId"
            :child-dependencies-warning="false"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { find, get, isString } from 'lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { current } from '@enums/product-status';
import { products as productsResource } from '@enums/resources';

const notEmptyColumns = [
    'name',
    'packSize',
    'packUnit',
    'supplierName',
    'brandDescription',
    'category',
    'proxyProductKey',
];

export default Vue.extend({
    data() {
        return {
            productsResource,
            isCreateProcess: false,
        };
    },
    computed: {
        ...mapState('hierarchy', ['hierarchy']),
        ...mapGetters('promotions', ['candidates']),
        ...mapGetters('scenarios', ['selectedScenario']),
        ...mapState('clientConfig', ['hierarchyConfig']),

        productId() {
            return this.params.data._id;
        },
        isCreateMode() {
            return !this.productId;
        },
        isEditMode() {
            return !!this.params.context.componentParent.editedRows[this.productId];
        },
        isCreateDisabled() {
            const validationProduct = this.params.context.componentParent.validationProduct;

            return (
                this.isCreateProcess ||
                notEmptyColumns.some(
                    column => !validationProduct[column] || validationProduct[column].trim() === ''
                )
            );
        },
        isEditDisabled() {
            const data = this.params.context.componentParent.editedRows[this.productId];

            // simple validation as above to check that all required fileds are entered
            return notEmptyColumns.some(
                column => !data[column] || (isString(data[column]) && data[column].trim() === '')
            );
        },
    },
    methods: {
        ...mapActions('products', [
            'createProduct',
            'updateProduct',
            'fetchProductBrandsByCategory',
        ]),
        ...mapActions('promotionCandidates', [
            'fetchPromotionCandidatesByScenarioCategoryStreamed',
            'resetCandidatesAndCache',
        ]),
        ...mapMutations('promotionCandidates', [
            'setPromotionCandidates',
            'updatePromotionCandidate',
        ]),

        async createNewProduct() {
            // disable create button
            this.isCreateProcess = true;
            // the grid isn't updated as it's still in edit stage, so stop editing before adding the product
            this.params.context.componentParent.gridOptions.api.stopEditing();

            // Retrieve the first candidate that has a matching supplier name
            // so that we can set supplierKey and clientSupplierKey.
            const candidate = find(this.candidates, {
                supplierName: this.params.data.supplierName,
            });

            const category = find(this.hierarchy, {
                levelEntryKey: this.params.data.categoryKey,
            });
            const newProduct = {
                name: this.params.data.name,
                proxyProductKey: this.params.data.proxyProductKey,
                proxyClientProductKey: this.params.data.proxyClientProductKey,
                status: current,
                brandDescription: this.params.data.brandDescription,
                brandKey: this.params.data.brandKey,
                supplierName: this.params.data.supplierName,
                packSize: this.params.data.packSize,
                packUnit: this.params.data.packUnit,
                supplierKey: get(candidate, 'supplierKey'),
                clientSupplierKey: get(candidate, 'clientSupplierKey'),
                hierarchy: [category],
                isProposed: true,
                isPromotable: true,
                isUnitProduct: get(candidate, 'isUnitProduct'),
                attributes: [...get(candidate, 'attributes')],
                category: category.levelEntryKey,
            };
            const { result } = await this.createProduct({ document: newProduct });
            if (result) {
                await this.setPromotionCandidates(this.candidates.concat([result]));
                this.params.context.componentParent.clearState();
                this.params.api.sizeColumnsToFit();
            }
            await this.resetCandidatesAndCache();
            await this.fetchPromotionCandidatesByScenarioCategoryStreamed();
            await this.fetchProductBrandsByCategory();
            // enable create button
            this.isCreateProcess = false;
        },

        async updateProposedProduct() {
            // The grid isn't updated until editing is stopped (which normally occurs
            // when the user focuses out of the grid). If the user were to change
            // the proxy selected without us stopping editing, the proxyProductKey value
            // would be its old value (or null if it doesn't have an old value).
            this.params.context.componentParent.gridOptions.api.stopEditing();
            const {
                name,
                packSize,
                packUnit,
                supplierName,
                brandDescription,
                brandKey,
                category,
                proxyProductKey,
                proxyClientProductKey,
                isProposed,
            } = this.params.data;

            const updates = {
                name,
                packSize,
                packUnit,
                supplierName,
                brandDescription,
                category,
                proxyProductKey,
                proxyClientProductKey,
                isProposed,
                brandKey,
            };
            const { result } = await this.updateProduct({
                id: this.productId,
                updates,
            });

            if (result) {
                this.params.context.componentParent.clearState({ productId: this.productId });

                await this.updatePromotionCandidate({
                    id: this.productId,
                    updates,
                });
                this.params.api.sizeColumnsToFit();
            }
            await this.resetCandidatesAndCache();
            await this.fetchPromotionCandidatesByScenarioCategoryStreamed();
            await this.fetchProductBrandsByCategory();
        },

        async refresh(params) {
            this.params = params;
            return true;
        },
    },
});
</script>
