var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "18px",
        height: "18px",
        viewBox: "0 0 18 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Proposed")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icons-/-Circle-empty",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("circle", {
            attrs: {
              id: "Proposed",
              stroke: "#8BA4B7",
              cx: "9",
              cy: "9",
              r: "8.5",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }