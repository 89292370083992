<template>
    <v-tooltip z-index="200" bottom>
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><icon icon-name="timeline" :small="small" /> </span>
        </template>

        <ul class="simple-list">
            <li v-for="ws in workflowState" :key="ws.name" class="simple-list__item">
                <icon :icon-name="ws.value ? 'done' : 'close'" small /> {{ ws.name }}
            </li>
        </ul>
    </v-tooltip>
</template>

<script>
import workflowEntityOperations from './workflow-entity-operations';

export default {
    localizationKey: 'workflow.tasks',
    props: {
        workflowEntity: {
            required: true,
            type: String,
        },
        entityId: {
            required: true,
            type: String,
        },
        owningEntityId: {
            required: false,
            type: String,
        },
        small: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            identifier: {
                entityId: this.entityId,
                owningEntityId: this.owningEntityId,
            },
        };
    },
    computed: {
        workflowState() {
            return workflowEntityOperations[this.workflowEntity]
                .getWorkflowState(this.identifier)
                .map(workflowState => {
                    return {
                        name: `${workflowState.entity} - ${workflowState.state}`,
                        value: workflowState.value,
                    };
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.simple-list {
    list-style-type: none;
    padding: 0;
}
</style>
