<template>
    <div class="vuex-form-viewer">
        <div v-if="isFormReadOnly" class="vuex-form-viewer__read" @click="$emit('click')">
            <div
                v-for="(field, index) in fields"
                :key="`${context._id}::field::${index}`"
                :class="[
                    'vuex-form-viewer__grid-cell',
                    ...(field.cellClasses || []),
                    isExpandable ? 'vuex-form-viewer__grid-cell--expandable' : '',
                ]"
            >
                <div v-if="field.contextFunction">
                    <span>{{
                        callContextFunction({
                            contextFunction: field.contextFunction,
                            context,
                            params: field.params,
                        })
                    }}</span>
                </div>
                <div v-else-if="field.component">
                    <component
                        :is="field.component.name"
                        v-bind="
                            constructVBindObj({
                                context,
                                field: field.name,
                                ...field.component,
                                componentRef: $parent,
                            })
                        "
                        v-on="
                            constructVBindEventObj({
                                events: getAttribute(field.component, 'events'),
                                context,
                            })
                        "
                    />
                </div>
                <span v-else>{{ getAttribute(context, field.name) }}</span>
            </div>
        </div>
        <div v-else class="vuex-form-viewer__edit">
            <vuex-form ref="form" v-bind="form" class="vuex-from-grid" />
            <slot />
        </div>
    </div>
</template>

<script>
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';

export default {
    mixins: [configDrivenGridComponentMixin],
    props: {
        context: {
            required: true,
            type: Object,
        },
        form: {
            type: Object,
            default: null,
        },
        fields: {
            required: true,
            type: Array,
        },
        isExpandable: {
            type: Boolean,
            default: true,
        },
        shouldOverrideFormReadOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isFormReadOnly() {
            return !this.shouldOverrideFormReadOnly && this.isReadOnly;
        },
    },
    created() {
        this.fields = Object.freeze(this.fields);
    },
    methods: {
        async submit() {
            if (this.$refs.form) {
                const { error } = await this.$refs.form.submit();
                return { error };
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';
.vuex-form-viewer {
    display: contents;
    z-index: $menu-z-index;

    &__read {
        display: contents;
        position: relative;
    }

    &__edit {
        display: contents;
    }
}

.vuex-from-grid {
    display: contents;
}

.vuex-form-viewer__grid-cell--expandable:hover {
    cursor: pointer;
}
</style>
