import Vue from 'vue';

export default [
    {
        header: 'name',
        name: 'name',
    },
    {
        header: 'description',
        name: 'description',
    },
    {
        header: 'createdOn',
        name: 'createdOn',
        contextFunction: createdOn => Vue.filter('dateShorthand')(createdOn),
    },
    {
        header: 'createdBy',
        name: 'createdBy',
    },
    {
        header: 'load',
        // load bookmark button
    },
    {
        header: 'copy',
        // copy button
    },
    {
        header: 'delete',
        // delete button
    },
];
