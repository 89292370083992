var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "17px",
        height: "17px",
        viewBox: "0 0 17 17",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Digital media")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-60.000000, -214.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Digital-media",
                    transform: "translate(60.666660, 214.800510)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Group-29",
                        transform: "translate(0.166667, 1.500000)",
                        fill: "#2F477C",
                        "fill-rule": "nonzero",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Combined-Shape",
                          d: "M7.99516908,11 C8.56038647,11 9,11.4368932 9,12 C9,12.5436893 8.54589372,13 7.99516908,13 C7.44927536,13 7,12.5436893 7,12 C7,11.4466019 7.44927536,11 7.99516908,11 Z M11.3357664,11 C11.9002433,11 12.3333333,11.4368932 12.3333333,12 C12.3333333,12.5436893 11.8807786,13 11.3357664,13 C10.7810219,13 10.3333333,12.5436893 10.3333333,12 C10.3333333,11.4466019 10.7810219,11 11.3357664,11 Z M14.6714976,11 C15.2270531,11 15.6666667,11.4368932 15.6666667,12 C15.6666667,12.5436893 15.2125604,13 14.6714976,13 C14.115942,13 13.6666667,12.5436893 13.6666667,12 C13.6666667,11.4466019 14.115942,11 14.6714976,11 Z M10,0 C10.5522847,0 11,0.44771525 11,1 L11,2 L12,2 C12.5522847,2 13,2.44771525 13,3 L13,9 C13,9.55228475 12.5522847,10 12,10 L3,10 C2.44771525,10 2,9.55228475 2,9 L2,8 L1,8 C0.44771525,8 0,7.55228475 0,7 L0,1 C0,0.44771525 0.44771525,0 1,0 L10,0 Z M12,3 L11.012,3 L11.0126245,7.00894475 C11.0126245,7.52178059 10.6265844,7.94445191 10.1292457,8.00221702 L10.0126245,8.00894475 L3,8.008 L3,9 L12,9 L12,3 Z M10,1 L1,1 L1,7 L10,7 L10,1 Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }