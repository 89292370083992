<template>
    <div class="flex-row">
        <div v-if="params.isAllocationPage && showCheckbox" class="checkbox-container">
            <vuex-checkbox
                :getter="() => isSelected()"
                :setter="value => selectionHandler(value)"
                :indeterminate="indeterminate && !allSelectedInHierarchy"
            />
        </div>
        <promotion-rag-colour v-if="hasValue" :colour="colour" :display-count="false" />
        <div class="promotion-name">{{ params.data.description | toSentenceCase }}</div>
    </div>
</template>

<script>
import Vue from 'vue';
import { last } from 'lodash';

export default Vue.extend({
    data() {
        return {
            selectedIds: [],
        };
    },
    computed: {
        colour() {
            return this.params.data.effectivenessRating;
        },
        hasValue() {
            return this.params.data.effectivenessRating >= 0;
        },
        indeterminate() {
            if (this.params.data.promotionId) return false;
            const promotionsForHierarchy = this.getPromotionsForHierarchy;
            return (
                !!this.selectedIds.length &&
                promotionsForHierarchy.some(id => this.selectedIds.includes(id))
            );
        },
        allSelectedInHierarchy() {
            const promotionsForHierarchy = this.getPromotionsForHierarchy;
            return (
                !!this.selectedIds.length &&
                promotionsForHierarchy.every(id => this.selectedIds.includes(id))
            );
        },
        getPromotionsForHierarchy() {
            const relevantId = last(this.params.data.id.split(':'));
            const promotionsForHierarchy = this.params.promotionsForHierarchy(relevantId);
            return promotionsForHierarchy;
        },
        showCheckbox() {
            return this.params.selectAllSCAP || (!this.params.selectAllSCAP && this.hasValue);
        },
    },
    mounted() {
        if (this.params.selectedIds) {
            this.selectedIds = this.params.selectedIds;
        }
    },
    events: {
        onSelectedIdsUpdated(payload) {
            this.selectedIds = payload;
        },
    },
    methods: {
        isSelected() {
            if (this.params.data.promotionId) {
                return this.selectedIds.find(id => id === this.params.data.promotionId);
            }
            return this.allSelectedInHierarchy;
        },
        selectionHandler(value) {
            if (this.params.data.promotionId) {
                this.params.selection(this.params.data.promotionId, value);
            } else this.params.hierarchySelection(last(this.params.data.id.split(':')), value);
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.flex-row {
    display: flex;
    flex-direction: row !important;
}
.checkbox-container {
    display: flex;
    align-items: center;
    padding: 0 0.2rem;
    margin-right: 0.5rem;
}
.v-input--indeterminate {
    ::v-deep {
        .mdi.mdi-minus-box {
            transition: none !important;
            &::before {
                content: ' ';
                width: 1.5rem;
                height: 1.5rem;
                background: $promo-white;
                border-radius: 0.2rem;
                border: 0.4rem solid $promo-primary-colour;
                transition: none;
            }
        }
    }
}

::v-deep {
    .v-input--checkbox.rtls-checkbox .v-input--selection-controls__input .v-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.promotion-name {
    margin-left: 1rem;
}
</style>
