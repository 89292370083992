import { merge } from 'lodash';
import axios from 'axios';
import to from 'await-to-js';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    targets: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getTargetsById
     */
    getters: {},

    /**
     * Default mutations available:
     * - setLoading
     * - setTargets
     * - deleteTarget
     * - updateTarget
     * - addTarget
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchTargets
     * - createTarget
     * - deleteTarget
     * - updateTarget
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        async fetchTargetsAggregations({ commit }, { params }) {
            commit('setLoading', true);
            const [err, res] = await to(axios.get('/api/targets/aggregations', { params }));
            if (err) {
                commit('setLoading', false);
                return;
            }
            commit('setLoading', false);
            const { data: aggregations } = res;
            return aggregations;
        },
    },
};

const mixinParams = {
    resource: 'target',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
