import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "inheritance-chips",
      class: { "parent-and-child": _vm.isParent && _vm.isChild },
    },
    [
      _vm.isParent
        ? _c(
            VChip,
            {
              staticClass: "parent-badge font-weight-bold",
              attrs: { small: "", label: "" },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("general.badge.parent")) + "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isChild
        ? _c(
            VChip,
            {
              staticClass: "child-badge font-weight-bold",
              attrs: { small: "", label: "" },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("general.badge.child")) + "\n    "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }