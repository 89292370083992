import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "summary-header" },
    [
      _vm.isExpanded
        ? _c(
            "div",
            { staticClass: "summary-header__actions" },
            [
              _c("label", { staticClass: "summary-header__label" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.params.displayName) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("main-dialog", {
                ref: "dialog",
                attrs: {
                  heading:
                    "planning.promotionsMaintenance.offer.offerGroup.attributeSelector.header",
                  "btn-confirm-text": "actions.save",
                  "has-activator": "",
                },
                on: {
                  confirm: _vm.updateSelectedAttributes,
                  close: _vm.resetSelectedAttributes,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function ({ activator }) {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                attrs: {
                                  color: "primary",
                                  depressed: "",
                                  outlined: "",
                                },
                              },
                              activator
                            ),
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$t("actions.select")
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "attribute-dialog" },
                            [
                              _c("vuex-select", {
                                staticClass: "attribute-dialog__select",
                                attrs: {
                                  "item-text": "attributeName",
                                  "item-value": "attributeKey",
                                  "value-type": "object",
                                  autocomplete: "",
                                  multiple: "",
                                  chips: "",
                                  "deletable-chips": "",
                                  "has-select-all": false,
                                  options: _vm.getAvailableAttributes,
                                  getter: () => _vm.selectedAttributes,
                                  setter: (value) =>
                                    (_vm.selectedAttributes = value),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1812057317
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("main-expand-button", {
        staticClass: "expand-btn",
        attrs: {
          "is-expanded": _vm.isExpanded,
          horizontal: "",
          background: "",
        },
        on: { expand: _vm.toggleCollapse },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }