<template>
    <v-btn
        class="positive-action-button btn"
        depressed
        :disabled="isDisabled"
        :data-id-e2e="dataIdE2e"
        @click.stop="onClick"
    >
        <slot />
    </v-btn>
</template>

<script>
export default {
    props: {
        dataIdE2e: String,
        preventDefault: Boolean,
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },
    methods: {
        onClick() {
            if (!this.disabled && !this.preventDefault) this.$emit('onClick');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.positive-action-button {
    background-color: $positive-action !important;
    color: $promo-white;
    padding: 0 1.2rem !important;
    height: 2rem !important;
    &:hover {
        &.v-size--default {
            background-color: $rtls-action-button-hover-colour !important;
        }
    }

    &.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $rtls-action-button-disabled-colour !important;
        color: $promo-white !important;
    }
}
</style>
