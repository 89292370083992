var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["scope", _vm.cssClass] }, [
    _vm.isGroupOfProducts
      ? _c(
          "div",
          { staticClass: "scope__group-of-products" },
          [
            _c("vuex-text-field", {
              staticClass: "scope__product-description",
              attrs: {
                getter: () => _vm.modelGetter("description"),
                setter: (value) => _vm.modelSetter("description", value),
                filled: "",
                validations: _vm.descriptionValidations,
              },
            }),
            _vm._v(" "),
            _c(_vm.valueComponent, {
              tag: "component",
              staticClass: "scope__value",
              attrs: {
                getter: () => _vm.modelGetter("value"),
                setter: (value) => _vm.modelSetter("value", value),
                filled: "",
                format: _vm.numberFormatter,
                "need-pre-validation-on-set": _vm.needPreValidationOnSet,
                validations: _vm.valueValidations,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "scope__individual-products" },
          [
            _c("vuex-text-field", {
              staticClass: "scope__product-description",
              attrs: {
                getter: () => _vm.modelGetter("description"),
                setter: (value) => _vm.modelSetter("description", value),
                filled: "",
                validations: _vm.descriptionValidations,
              },
            }),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "scope__products" },
              _vm._l(_vm.model.products, function (product, index) {
                return _c(
                  "li",
                  { key: `editable::${product.productKey}::${index}` },
                  [
                    _c("vuex-select", {
                      staticClass: "scope__product",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t("placeholders.selectProduct")
                        ),
                        "item-value": "_id",
                        "item-text": "displayLabel",
                        options: _vm.getFilteredProductsOptions(
                          product.productKey
                        ),
                        getter: () =>
                          _vm.productGetter("productKey", product.productKey),
                        setter: (value) => _vm.productSetter(index, value),
                        validations: _vm.productValidations,
                        filled: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(_vm.valueComponent, {
                      tag: "component",
                      staticClass: "scope__value",
                      attrs: {
                        getter: () => product.value,
                        setter: (value) => _vm.productValueSetter(index, value),
                        filled: "",
                        format: _vm.numberFormatter,
                        "need-pre-validation-on-set":
                          _vm.needPreValidationOnSet,
                        validations: _vm.valueValidations,
                      },
                    }),
                    _vm._v(" "),
                    _c("delete-button", {
                      staticClass: "scope__delete-btn",
                      attrs: { background: "" },
                      on: {
                        delete: function ($event) {
                          return _vm.deleteProduct(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "scope__add-btn-container" },
              [
                _c("create-new-button", {
                  staticClass: "scope__add-btn",
                  attrs: {
                    disabled: _vm.isCreateButtonDisabled,
                    background: "",
                  },
                  on: { createNew: _vm.addNewProduct },
                }),
                _vm._v(" "),
                _vm.isCreateButtonDisabled
                  ? _c("div", { staticClass: "error--text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t(
                                "variableFundingAgreements.noSupplierAndCategory"
                              )
                            )
                          ) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }