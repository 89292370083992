<template functional>
    <div class="scope">
        <div class="scope__group-of-products">
            <span class="scope__product-description">{{ props.scope.description }}</span>
            <span v-if="props.scope.value" class="scope__value">{{
                $options.methods.getFormattedValue(props.scope.value, props.scope.numberFormatter)
            }}</span>
        </div>
        <div v-if="props.scope.products" class="scope__individual-product">
            <ul class="scope__products">
                <li
                    v-for="(product, index) in props.scope.products"
                    :key="`${product.productKey}::${index}`"
                >
                    <span class="scope__product">{{ product.name }}</span>
                    <span class="scope__product-value">{{
                        $options.methods.getFormattedValue(
                            product.value,
                            props.scope.numberFormatter
                        )
                    }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import i18n from '@/js/vue-i18n';

export default {
    props: {
        scope: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getFormattedValue(value, numberFormatter) {
            return i18n.n(`numbers.default.${numberFormatter}`, value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.scope {
    padding: 0 1rem;

    &__group-of-products {
        display: flex;
    }

    &__product-description {
        padding-bottom: 0.5rem;
    }

    &__products {
        padding: 0;
        li {
            display: flex;

            &:first-child {
                border-top: 0.1rem solid $promo-grey;
            }
            span {
                padding: 0.5rem 0;
            }
        }
    }
    &__value,
    &__product-value {
        margin-left: auto;
        width: 3rem;
        font-weight: 600;
    }
}
</style>
