var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading:
        "planning.promotionsMaintenance.funding.agreementSelector.header",
      "btn-confirm-text": "actions.select",
      "has-activator": _vm.hasActivator,
      "show-negative-action-button": false,
      "confirm-btn-disabled": true,
    },
    on: { close: _vm.closeDialog },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "variable-funding-agreements-container" },
              [
                _c("promo-ag-grid", {
                  attrs: {
                    "row-data": _vm.variableFundingAgreements,
                    "column-defs": _vm.columnDefs,
                    "grid-options": _vm.gridOptions,
                    "grid-style": _vm.gridStyle,
                    "default-col-def": _vm.defaultColDef,
                  },
                  on: { "cell-value-changed": () => null },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }