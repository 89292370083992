var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "categories-select", class: _vm.cssClass },
    [
      _vm.isSelectAllAvailable
        ? _c(
            "div",
            { staticClass: "select-all" },
            [
              _c("vuex-checkbox", {
                staticClass: "rtls-checkbox",
                attrs: {
                  getter: () => _vm.allUnits,
                  setter: _vm.allUnitsSetter,
                  label: _vm._f("toSentenceCase")(_vm.$t("hierarchy.units")),
                  disabled: _vm.isAllUnitsDisabled,
                },
                on: { click: _vm.onCLick },
              }),
              _vm._v(" "),
              _c("vuex-checkbox", {
                staticClass: "rtls-checkbox",
                attrs: {
                  getter: () => _vm.allCategories,
                  setter: _vm.allCategoriesSetter,
                  label: _vm._f("toSentenceCase")(
                    _vm.$t("hierarchy.categories")
                  ),
                  disabled: _vm.isAllCategoriesDisabled,
                },
                on: { click: _vm.onCLick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Finder", {
        key: _vm.forceRerender,
        ref: "treeRef",
        class: {
          required: _vm.isInvalid,
          "hide-unit-checkboxes": _vm.hideUnitCheckboxes,
          "disabled-reason": !!_vm.disabledReason,
        },
        attrs: {
          tree: _vm.tree,
          selectable: true,
          "auto-select-descendants": "",
          "auto-deselect-descendants": "",
          "default-expanded": _vm.defaultExpanded,
        },
        on: { select: _vm.onSelect, expand: _vm.onExpand },
      }),
      _vm._v(" "),
      _vm.isInvalid
        ? _c("div", { staticClass: "red--text" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("toSentenceCase")(_vm.$t(_vm.validationMessage))
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.disabledReason && _vm.isShowDisabledReason
        ? _c("div", { staticClass: "disabled-reason-container" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.disabledReason)) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }