import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "icon-renderer" },
    [
      _vm.params.value.tooltipText
        ? _c(
            VTooltip,
            {
              attrs: { "z-index": "300", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "span",
                          _vm._g(_vm._b({}, "span", attrs, false), on),
                          [
                            _c("icon", {
                              staticClass: "icon-renderer__icon",
                              attrs: { "icon-name": _vm.params.value.icon },
                            }),
                            _vm._v(" "),
                            _vm.params.value.text
                              ? _c(
                                  "span",
                                  { staticClass: "icon-renderer__text" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("toSentenceCase")(
                                            _vm.params.value.text
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1321305987
              ),
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.params.value.tooltipText))]),
            ]
          )
        : _c(
            "span",
            [
              _c("icon", {
                staticClass: "icon-renderer__icon",
                attrs: { "icon-name": _vm.params.value.icon },
              }),
              _vm._v(" "),
              _vm.params.value.text
                ? _c("span", { staticClass: "icon-renderer__text" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("toSentenceCase")(_vm.params.value.text)
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }