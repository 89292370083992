import { camelCase, get } from 'lodash';
import workflowEntities from '@enums/workflow-entities';
import store from '@/js/store';

/**
 * Generate config for accessing root level workflow states.
 * This will be the default for each function, with functionality
 * being overridden for certain entities if bespoke logic is required.
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {string} RORO.entity - The entity whose config is defined.
 */
const getRootEntityConfig = ({ entity }) => {
    const entityStore = `${entity}s`;

    return {
        /**
         * Return the id for the sub-campaign the entity belongs to.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        getSubCampaignId: ({ entityId }) =>
            store.getters[`${entityStore}/getSubCampaignIdForEntity`]({
                entityId,
            }),
        /**
         * Return workflow state for the entity.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        getWorkflowState: ({ entityId }) =>
            store.getters[`${entityStore}/entityWorkflowStateById`]({
                id: entityId,
            }),

        /**
         * By default, do not return a parentWorkflow state
         *
         * @returns null
         */
        getParentWorkflowState: () => null,

        /**
         * Sets the specified workflow state to the given value.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityIds - The ids of the entities to update the state on.
         * @param {Array} RORO.states - The array of workflow states to be updated.
         */
        setWorkflowState: async ({ entityIds, states }) =>
            store.dispatch(`${entityStore}/updateWorkflowState`, {
                entityIds,
                states,
            }),

        /**
         * Sets the specified workflow state to the given value.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityIds - The ids of the entities to update the state on.
         */
        setClientState: async ({ entityIds }) =>
            store.dispatch(`${entityStore}/updateClientState`, {
                entityIds,
            }),

        /**
         * Runs the logging function for the given entity.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        logEntity: ({ entityId }) => store.dispatch(`${entityStore}/logEntity`, { entityId }),

        /**
         * Get the entity by id.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        getEntityById: ({ entityId }) =>
            store.getters[`${entityStore}/${camelCase(`get${entity}ById`)}`]({
                _id: entityId,
                usePluralResourceName: true,
            }),

        /**
         * Create a notification
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityIds - The ids of the entity's
         * @param {string} RORO.notificationKey - The type of notification to create
         * @param {string} RORO.resource - The resource we are create a notification for e.g. promotion
         */
        createNotification: async ({ entityIds, notificationKey, resource }) => {
            entityIds.forEach(entityId => {
                store.dispatch(`notifications/createNotification`, {
                    resourceId: entityId,
                    notificationKey,
                    resource,
                });
            });
        },
        /**
         * Close notifications
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityIds - The ids of the entity's
         * @param {string} RORO.notificationKey - The type of notification to create
         * @param {string} RORO.resource - The resource we are create a notification for e.g. promotion
         */
        closeNotification: async ({ entityIds, notificationKey, resource }) => {
            entityIds.forEach(entityId => {
                store.dispatch(`notifications/closeNotification`, {
                    resourceId: entityId,
                    notificationKey,
                    resource,
                });
            });
        },

        /**
         * Returns the list of fields which should be displayed
         * in the task button confirmation window.
         */
        getConfirmationFields: () => {
            return get(
                store.state.clientConfig.toggleLogic,
                `workflowConfirmationFields.${entity}`,
                []
            );
        },
    };
};

const promotion = {
    ...getRootEntityConfig({ entity: 'promotion' }),

    // Override getEntityById as promotions are not retrieved using the generic
    // getter function, and therefore require a slightly different param
    // structure.
    getEntityById: ({ entityId }) => store.getters['promotions/getPromotionById'](entityId),

    getParentWorkflowState: ({ entityId }) => {
        const childPromotion = store.getters['promotions/getPromotionById'](entityId);

        if (childPromotion && childPromotion.parentPromotionId) {
            return store.getters[`promotions/parentPromotionWorkflowStateById`]({
                id: childPromotion.parentPromotionId,
            });
        }
        return null;
    },

    // send promotion to execution system
    sendToExecutionSystem: async ({ entityIds }) => {
        entityIds.forEach(entityId => {
            store.dispatch(`execution/executeSinglePromotionById`, {
                namespace: entityId,
            });
        });
    },

    // send multiple promotions to execution system
    sendMultipleToExecutionSystem: async ({ entityIds }) => {
        store.dispatch(`execution/executePromotionsById`, {
            entityIds,
        });
    },

    updateForecastReference: async ({ entityIds }) => {
        entityIds.forEach(entityId => {
            store.dispatch(`forecasting/updateForecastReferenceById`, {
                namespace: entityId,
            });
        });
    },
};

const scenario = {
    ...getRootEntityConfig({ entity: 'scenario' }),
};

const subCampaign = {
    ...getRootEntityConfig({ entity: 'subCampaign' }),

    /**
     * Return the id for the sub-campaign the entity belongs to.
     * For a sub-campaign, we know that the entity is the sub-campaign
     * so we can just return the entityId.
     *
     * @param {Object} RORO - The RORO wrapper
     * @param {string} RORO.entityId - The id of the entity.
     */
    getSubCampaignId: ({ entityId }) => entityId,
};

const promoResource = resource => {
    return {
        /**
         * Return the id for the sub-campaign the entity belongs to.
         * We know that for a promoResource, the owning entity is always a
         * sub-campaign, so just return that ID.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        getSubCampaignId: ({ owningEntityId }) => owningEntityId,

        /**
         * Return workflow state for the promo-resource.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityId - The id of the entity.
         */
        getWorkflowState: ({ entityId, owningEntityId }) =>
            store.getters['subCampaigns/promoResourceWorkflowStateById']({
                id: entityId,
                subCampaignId: owningEntityId,
            }),

        /**
         * Sets the specified workflow state to the given value.
         *
         * @param {Object} RORO - The RORO wrapper
         * @param {string} RORO.entityIds - The ids of the entities to update the state on.
         * @param {string} RORO.owningEntityId - The id of the owning entity to update the state on.
         * @param {Array} RORO.states - The array of workflow states to be updated.
         */
        setWorkflowState: async ({ entityIds, owningEntityId, states }) =>
            store.dispatch(`subCampaigns/updatePromoResourceWorkflowState`, {
                entityIds,
                subCampaignId: owningEntityId,
                states,
                resource,
            }),
    };
};

export default {
    [workflowEntities.subCampaign]: subCampaign,
    [workflowEntities.scenario]: scenario,
    [workflowEntities.promotion]: promotion,
    [workflowEntities.leaflet]: promoResource(workflowEntities.leaflet),
};
