import numeral from 'numeral';
import { isNil } from 'lodash';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '../utils/string-utils';

const maxValue = (value, message, max = 100) => {
    if (isNil(value)) {
        return true;
    }

    value = numeral(value).value();
    return value <= max || (message || toSentenceCase(i18n.t('validation.common.max', { max })));
};

export default maxValue;
