<template>
    <div class="page">
        <tabs :tabs="tabs" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import routeNames from '@enums/route-names';
import { canAccessProxyProducts, canAccessProductGroups } from '@enums/feature-flags';
import navigation from '@/js/navigation';

export default {
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        tabs() {
            const tabs = [];

            if (this.toggleLogic[canAccessProxyProducts]) {
                tabs.push({
                    label: this.$options.filters.toSentenceCase(
                        this.$t('productsTabs.proxyProducts')
                    ),
                    disabled: false,
                    component: 'proxy-products',
                    navigateTo: navigation.toProxyProductsView,
                    routeNames: [routeNames.proxyProductsView],
                });
            }

            if (this.toggleLogic[canAccessProductGroups]) {
                tabs.push({
                    label: this.$options.filters.toSentenceCase(
                        this.$t('productsTabs.productGroups')
                    ),
                    disabled: false,
                    component: 'product-groups',
                    navigateTo: navigation.toProductGroupsView,
                    routeNames: [routeNames.productGroupsView],
                });
            }

            return tabs;
        },
    },
};
</script>

<style scoped lang="scss">
.page {
    height: 100%;
    padding: 2rem 1rem;
}
</style>
