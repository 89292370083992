import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "rtls-calendar-picker" },
    [
      _c(
        VMenu,
        {
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "nudge-bottom": "25",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    VTextField,
                    _vm._g(
                      {
                        staticClass:
                          "rtls-text-field rtls-text-field--grey rtls-text-field--reverse-order",
                        attrs: {
                          value: _vm._f("localShortDate")(_vm.model),
                          placeholder: _vm._f("toSentenceCase")(
                            _vm.$t(_vm.placeholder)
                          ),
                          "hide-details": "",
                          readonly: "",
                          disabled: _vm.disabled,
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            VIcon,
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  _vm.menu = true
                                },
                              },
                            },
                            [_vm._v("$date-picker")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c("date-picker", {
            attrs: { dates: _vm.model, min: _vm.min, max: _vm.max },
            on: { complete: _vm.closeWhenComplete },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }