<template>
    <button
        :class="['icon-btn', iconBtnClassObject, verticalAlignmentClass]"
        :data-id-e2e="dataIdE2e"
        :disabled="isDisabled"
        @click.stop="onClick($event)"
    >
        <icon
            :icon-name="icon"
            :material-icon-class="materialIconClass"
            class="icon-btn__icon"
            :disabled="isDisabled"
        />
        <span v-if="btnText" class="icon-btn__text font-weight-bold">
            {{ $t(btnText) | toSentenceCase }}
        </span>
    </button>
</template>

<script>
export default {
    props: {
        events: Object,
        preventDefault: Boolean,
        icon: String,
        dataIdE2e: String,
        btnText: {
            required: false,
            type: String,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        background: {
            required: false,
            type: Boolean,
            default: false,
        },
        verticalAlignment: {
            required: false,
            type: String,
            default: 'middle',
        },
        materialIconClass: String,
        iconOnRight: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {};
    },

    computed: {
        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
        verticalAlignmentClass() {
            return this.verticalAlignment
                ? `icon-btn--${this.verticalAlignment}`
                : 'icon-btn--middle';
        },

        iconBtnClassObject() {
            return {
                'icon-btn--bg': this.background,
                'icon-btn--disabled': this.isDisabled,
                'icon-btn--invert-order': this.iconOnRight,
            };
        },
    },

    methods: {
        onClick(e) {
            if (this.isDisabled) return;
            if (this.events && this.events.click) this.events.click(e);
            if (!this.disabled && !this.preventDefault) this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins';
@import '@style/base/_variables';

.icon-btn {
    outline: none;

    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &--invert-order {
        flex-direction: row-reverse;
    }

    &--disabled {
        cursor: not-allowed;
    }

    &--middle {
        vertical-align: middle;
    }

    &--top {
        vertical-align: top;
    }

    &--bottom {
        vertical-align: bottom;
    }

    &--bg::v-deep {
        .icon-btn__icon {
            background-color: $promo-primary-colour;
            @include icon-border-radius;
            color: $promo-white;
        }
    }

    &__text {
        color: $promo-primary-colour;
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }
}
::v-deep .icon-btn__icon {
    color: $promo-primary-colour;
    height: 1.8rem;
    width: 1.8rem;
}
</style>
