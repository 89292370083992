import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.allowApplyAllNotifications } },
    [
      _vm.isChild
        ? _c(
            VBtn,
            {
              attrs: { primary: "", depressed: "", disabled: _vm.isDisabled },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$refs.dialog.open()
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(`acceptAllNotifications.acceptAllNotifications`)
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-dialog", {
        ref: "dialog",
        attrs: {
          "question-text": _vm.isScenario
            ? _vm.$t(`acceptAllNotifications.confirmTextScenario`)
            : _vm.$t(`acceptAllNotifications.confirmTextPromotion`),
          "action-text": _vm.$t("actions.confirm"),
          "has-activator": false,
        },
        on: {
          confirm: function ($event) {
            return _vm.$store.dispatch(`${_vm.entity}/acceptAllNotifications`, {
              entityId: _vm.entityId,
            })
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }