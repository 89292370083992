'use strict';

module.exports = {
    onInvoiceValue: {
        displayComponent: 'vuex-percentage-input',
        path: 'uiFields.onInvoiceValue',
        typePath: 'uiFields.onInvoiceType',
        numberFormatter: {
            type: 'percentageWithDecimals',
        },
    },
    offInvoiceValue: {
        displayComponent: 'vuex-percentage-input',
        path: 'uiFields.offInvoiceValue',
        typePath: 'uiFields.offInvoiceType',
        numberFormatter: {
            type: 'percentageWithDecimals',
        },
    },
    buyingPrice: {
        displayComponent: 'vuex-currency-input',
        path: 'buyingPrice',
        numberFormatter: {
            type: 'currencyNoLabelPadded3dp',
        },
    },
    supplierCompensation: {
        displayComponent: 'vuex-currency-input',
        path: 'supplierCompensation',
        numberFormatter: {
            type: 'currencyNoLabelPadded3dp',
        },
    },
};
