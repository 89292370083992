import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c(
        VAppBar,
        {
          staticClass: "promo-toolbar elevation-3",
          class: { warning: _vm.notUsingLocalDatabase },
          attrs: { app: "" },
        },
        [
          _c(
            VToolbarItems,
            { staticClass: "toolbar-items" },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    exact: "",
                    text: "",
                    to: _vm.defaultRoute,
                    "active-class": "no-active",
                  },
                },
                [
                  _c(VImg, {
                    staticStyle: { visiblility: "invisible" },
                    attrs: {
                      id: "client-logo",
                      src: "/organisation-assets/logo.png",
                    },
                    on: { load: _vm.logoLoaded },
                  }),
                  _vm._v(" "),
                  _c(VImg, { attrs: { src: _vm.logo, width: "185px" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _vm._l(_vm.routes, function (route, index) {
            return _c(
              VToolbarItems,
              {
                key: index,
                staticClass: "toolbar-items",
                class: route.cssClasses,
              },
              [
                _c("feature-toggle", {
                  attrs: {
                    toggle: route.meta.canAccess,
                    "slot-class": "v-toolbar__items",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                key: index,
                                staticClass: "text-none nav-link",
                                class:
                                  _vm.$route.fullPath.indexOf(route.path) === 0
                                    ? "active-link font-weight-bold"
                                    : "",
                                attrs: {
                                  slot: "activator",
                                  exact: "",
                                  text: "",
                                  to: route.path,
                                },
                                slot: "activator",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t(route.linkText)
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _c(VToolbarItems, { staticClass: "toolbar-items" }, [
            !_vm.hideWeeks
              ? _c("div", { staticClass: "date" }, [
                  _c("span", { staticClass: "week-of-year font-weight-bold" }, [
                    _vm._v(_vm._s(_vm._f("weekNumberClient")(_vm.weekOfYear))),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("fullDateText")(_vm.date))),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            VToolbarItems,
            { staticClass: "toolbar-items" },
            [
              _c(VDivider, {
                staticClass: "vertical-divider",
                attrs: { vertical: "" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "feature-toggle",
            { attrs: { toggle: _vm.canAccessAdminArea } },
            [
              _c(
                VToolbarItems,
                { staticClass: "toolbar-items" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { text: "", exact: "", depressed: "" },
                      on: { click: _vm.toAdminPage },
                    },
                    [_c("span", [_vm._m(0)], 1)]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VToolbarItems,
            [
              _c(
                VMenu,
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                staticClass: "user-menu",
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.isExpanded = !_vm.isExpanded
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _c(
                                VAvatar,
                                {
                                  staticClass: "avatar",
                                  attrs: {
                                    height: "4rem",
                                    width: "4rem",
                                    color: "primary",
                                    size: "36",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "white--text headline" },
                                    [_vm._v(_vm._s(_vm.userProfile.initials))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "name-text" }, [
                                _vm._v(_vm._s(_vm.userProfile.fullName)),
                              ]),
                              _vm._v(" "),
                              _c("main-expand-button", {
                                attrs: { "is-expanded": _vm.isExpanded },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    VList,
                    [
                      _c(
                        VListItem,
                        [
                          _c(
                            VListItemTitle,
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "text-none nav-link",
                                  attrs: { id: "navbar-logout", text: "" },
                                  on: { click: _vm.onLogout },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t("actions.logout")
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.shouldDisplayUserDocsLink
                        ? _c(
                            VListItem,
                            [
                              _c(
                                VListItemTitle,
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "text-none nav-link",
                                      attrs: {
                                        id: "navbar-user-docs",
                                        text: "",
                                        href: "/api/docs/",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm._f("toSentenceCase")(
                                              _vm.$t("actions.viewUserDocs")
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { color: "primary" } }, [
      _vm._v("mdi-settings"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }