var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supplier-commitments-viewer" },
    [
      _c("supplier-commitment-type-toggle", {
        staticClass: "supplier-commitments-viewer__toggle",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "supplier-commitments-viewer__filter" },
        [
          _c("supplier-commitments-filter-bar", {
            attrs: { "max-filter-count": _vm.maxFilterCount },
            on: {
              "change-categories-filter": function ($event) {
                return _vm.hideNewCommitmentForm()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSpecifiedAllocationsSection
        ? _c(
            "div",
            {
              staticClass:
                "supplier-commitments-viewer__specified-allocations-grid",
            },
            [
              _vm._l(_vm.fields, function (field, index) {
                return _c(
                  "div",
                  {
                    key: `supplierCommitmentHeader::${index}`,
                    staticClass: "supplier-commitments-viewer__header",
                  },
                  [
                    field.header
                      ? _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    `supplierCommitments.headers.${field.header}`
                                  )
                                )
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(
                _vm.filteredSupplierCommitmentsMap.current.specifiedAllocations,
                function (supplierCommitment) {
                  return [
                    _c("supplier-commitment-viewer", {
                      key: `supplierCommitment::${supplierCommitment._id}`,
                      attrs: {
                        "supplier-commitment": supplierCommitment,
                        fields: _vm.fields,
                        "form-fields": _vm.formFields,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCommitmentClick({
                            id: supplierCommitment._id,
                          })
                        },
                      },
                    }),
                  ]
                }
              ),
              _vm._v(" "),
              _vm.isGridFillerVisible
                ? _c(
                    "div",
                    { class: ["supplier-commitments-viewer__header", "bold"] },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("supplierCommitments.filterNotSelected")
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              [
                _c("supplier-commitment-form-wrapper", {
                  ref: "addNewCommitmentForm",
                  attrs: { "form-fields": _vm.formFields },
                }),
              ],
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "supplier-commitments-viewer__historical-title",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("supplierCommitments.historicalTitle")
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(
                _vm.filteredSupplierCommitmentsMap.historical
                  .specifiedAllocations,
                function (supplierCommitment) {
                  return [
                    _c("supplier-commitment-viewer", {
                      key: `historicalSupplierCommitment::${supplierCommitment._id}`,
                      attrs: {
                        "supplier-commitment": supplierCommitment,
                        fields: _vm.fields,
                        "form-fields": _vm.formFields,
                        "make-read-only": "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCommitmentClick({
                            id: supplierCommitment._id,
                            isCurrent: false,
                          })
                        },
                      },
                    }),
                  ]
                }
              ),
            ],
            2
          )
        : _vm.isTotalSpendSection
        ? _c("total-spend-viewer", { ref: "totalSpendViewer" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }