<template>
    <v-footer app height="40px" elevation="3" class="footer">
        <v-btn
            text
            class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
            color="white"
            :href="termsOfUse"
        >
            <v-flex class="subheading">{{ $t('footer.termsOfUse') }}</v-flex>
        </v-btn>
        <v-btn
            text
            class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
            color="white"
            :href="privacyNote"
        >
            <v-flex class="subheading">{{ $t('footer.privacyNote') }}</v-flex>
        </v-btn>
        <template v-if="isManageCookiesButtonVisible">
            <v-btn
                text
                class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
                color="white"
                :href="cookieNotice"
            >
                <v-flex class="subheading">{{ $t('footer.cookieNotice') }}</v-flex>
            </v-btn>
            <v-btn
                text
                class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
                color="white"
                @click="onManageCookiesClick"
            >
                <v-flex class="subheading">{{ $t('footer.manageCookiesButtonTitle') }}</v-flex>
            </v-btn>
        </template>
        <v-flex class="subheading">
            {{ copyrightOwText }}
        </v-flex>
        <v-spacer />
        <v-btn text class="text-none ml-5" :href="link">
            <v-flex class="subheading">{{ $t('footer.solutionFrom') }}:</v-flex>
            <v-flex offset-md1 xs12 class="ow-logo">
                <img :src="logo" height="12px" width="103px" />
            </v-flex>
        </v-btn>
    </v-footer>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';
import { mapState } from 'vuex';
import logo from '../../img/logo-oliver-wyman-white.svg';

export default {
    data() {
        return {
            moment,
            logo,
            link: 'https://labs.oliverwyman.com/index.html',
            termsOfUse:
                'https://www.oliverwyman.com/content/dam/oliver-wyman/v2/legal/OW-General-Web-Portal-ToU.pdf',
            privacyNote: 'https://www.oliverwyman.com/policies/privacy-notice-platforms.html',
            cookieNotice: 'https://www.oliverwyman.com/policies/cookie-notice.html',
        };
    },
    computed: {
        ...mapState('clientConfig', ['generalConfig']),
        isManageCookiesButtonVisible() {
            return get(this.generalConfig, 'showCookieBanner', false);
        },
        copyrightOwText() {
            return `Copyright © ${moment().format('YYYY')} Oliver Wyman`;
        },
    },
    methods: {
        onManageCookiesClick() {
            // eslint-disable-next-line no-undef
            Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.v-footer {
    &.footer {
        background-color: $promo-black;
        z-index: $main-footer-z-index;
        color: $promo-white;
        text-decoration-color: $promo-white;
    }

    .footer-link:hover:before {
        opacity: 0;
    }

    .cookie-manager-btn {
        text-decoration: underline;
    }
}

.subheading {
    font-size: 1.25rem;
    color: $promo-white;
    text-decoration-color: $promo-white;
}

.ow-logo {
    padding-top: 5px;
}
</style>
