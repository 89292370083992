var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "create-new-button",
    _vm._b(
      { attrs: { background: "" }, on: { createNew: _vm.createNewResource } },
      "create-new-button",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }