import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "confirm" },
    [
      _c(VCardText, { staticClass: "confirm__container text-center" }, [
        _c("p", { staticClass: "confirm__question mb-3" }, [
          _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.questionText))),
        ]),
        _vm._v(" "),
        _vm.warningText
          ? _c("p", { staticClass: "confirm__warning" }, [
              _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.warningText))),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._t("warningContent"),
      _vm._v(" "),
      _c(
        VCardActions,
        { staticClass: "justify-center flex-column" },
        [
          _c(
            VBtn,
            {
              staticClass: "ma-1",
              attrs: { primary: "", depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("confirm")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.actionText)) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "confirm__cancel ma-1",
              attrs: { secondary: "", depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.cancel"))) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }