<template>
    <feature-toggle :toggle="enableSplitPromotions">
        <v-btn primary depressed :disabled="disabled" @click.stop="$refs.dialog.open()">
            {{ $tkey(`splitPromotion`) }}
        </v-btn>

        <confirm-dialog
            ref="dialog"
            :question-text="$tkey(`confirmationQuestion`, { productCount })"
            :action-text="$t('actions.confirm')"
            :has-activator="false"
            @confirm="confirm"
        />
    </feature-toggle>
</template>

<script>
import { mapActions } from 'vuex';
import { enableSplitPromotions } from '@enums/feature-flags';

export default {
    localizationKey: 'splitPromotion',
    props: {
        promotionId: {
            required: true,
            type: String,
        },
        productCount: {
            required: true,
            type: Number,
        },
        disabled: {
            required: true,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            enableSplitPromotions,
        };
    },
    methods: {
        ...mapActions('promotions', ['queuePromotionForSplitting']),
        async confirm() {
            await this.queuePromotionForSplitting({ promotionId: this.promotionId });
            this.$emit('split-requested');
        },
    },
};
</script>
