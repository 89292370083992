<template>
    <v-tooltip
        v-if="isVisible"
        z-index="1000"
        max-width="300"
        bottom
        :disabled="hideTooltip || !disabledState.reason"
    >
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
                <v-btn v-if="text" text :disabled="disabledState.disabled" @click="onClick">
                    {{ $tckey(buttonText, actionableEntities.length) | toSentenceCase }}
                </v-btn>
                <positive-action-button
                    v-else-if="!icon && !isNegativeAction"
                    :class="{ 'small-btn': small }"
                    :style="buttonStyle"
                    :disabled="disabledState.disabled"
                    @onClick="onClick"
                >
                    {{ $tckey(buttonText, actionableEntities.length) | toSentenceCase }}
                </positive-action-button>
                <v-btn
                    v-else-if="!icon && isNegativeAction"
                    :class="{ 'small-btn': small }"
                    :style="buttonStyle"
                    :disabled="disabledState.disabled"
                    color="red"
                    outlined
                    @click="onClick"
                >
                    {{ $tckey(buttonText, actionableEntities.length) | toSentenceCase }}
                </v-btn>
                <icon-button
                    v-else
                    :icon="taskIcon"
                    :disabled="disabledState.disabled"
                    @click="onClick"
                />
                <span v-if="showActionDateTime" class="action-date-time">
                    <span class="action-date-time__label">{{ actionDateTimeLabel.label }}</span>
                    <span>{{ actionDateTimeLabel.actionDateTime }}</span>
                </span>

                <task-confirmation-dialog
                    v-if="confirmFirst"
                    ref="confirmationDialog"
                    :btn-confirm-text="
                        $tckey(buttonText, actionableEntities.length) | toSentenceCase
                    "
                    :entity-type="entityType"
                    :entity-ids="entityIds"
                    :actionable-entity-ids="actionableEntities"
                    @confirm="confirmTask"
                    @close="closeConfirmationDialog"
                />
            </span>
        </template>
        <span>
            {{ disabledState.reason | toSentenceCase }}
        </span>
    </v-tooltip>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { intersection, find, minBy, isObject, uniq, includes } from 'lodash';
import store from '@/js/store';

import workflowUtilsFactory from '@sharedModules/workflow-utils-factory';
import workflowEntityOperations from './workflow-entity-operations';

const workflowUtils = workflowUtilsFactory(Vue.moment);

export default {
    localizationKey: 'workflow.taskButton',
    props: {
        task: {
            required: true,
            type: String,
        },
        entityType: {
            required: true,
            type: String,
        },
        entityIds: {
            required: true,
            type: Array,
        },
        owningEntityId: {
            required: false,
            type: String,
        },
        subCampaignId: {
            type: String,
        },
        subCampaign: {
            type: Object,
            required: false,
        },
        categories: {
            required: true,
            type: Array,
        },
        multipleEntities: {
            type: Boolean,
            default: false,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        disabledFunction: {
            type: Function,
            default: () => false,
        },
        disabledReason: {
            type: String,
        },
        isNegativeAction: {
            required: false,
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        showActionDateTime: {
            required: false,
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: true,
        },
        buttonStyle: {
            type: Object,
            required: false,
            default: () => {},
        },
        text: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideTooltip: {
            type: Boolean,
            required: false,
            default: false,
        },
        alwaysShow: {
            type: Boolean,
            required: false,
            default: false,
        },
        shortText: {
            type: Boolean,
            required: false,
            default: false,
        },
        confirmFirst: {
            type: Boolean,
            required: false,
            default: false,
        },
        isStoreWidePromotion: {
            type: Boolean,
            required: false,
            default: false,
        },
        splitPromotions: {
            type: Array,
            required: false,
        },
    },

    data() {
        const taskDefinition = workflowUtils.taskDefinitions[this.entityType][this.task];
        return {
            taskDefinition,
            identifier: {
                entityId: this.entityId,
                owningEntityId: this.owningEntityId,
            },
            buttonText: `tasks.${
                this.isNegativeAction
                    ? taskDefinition.negativeButtonText
                    : taskDefinition.buttonText
            }.${this.shortText ? 'short' : 'long'}`,
            overrideReadOnly: true,
        };
    },

    computed: {
        ...mapGetters('subCampaigns', ['getSubCampaignById']),
        ...mapGetters('context', ['userRoles']),

        actionDateTimeLabel() {
            // Currently, the actionDateTimeLabel is only available for single
            // promotions that have been submitted.
            const entity = workflowEntityOperations[this.entityType].getEntityById({
                entityId: this.entityIds[0],
            });

            const workflowState = entity && entity.workflowState;
            const completedTask = find(workflowState, {
                entity: this.entityType,
                state: this.taskDefinition.completionIndicator,
                value: true,
            });

            return completedTask
                ? {
                      label: this.$options.filters.toSentenceCase(
                          this.$t(
                              `workflow.taskButton.completedTasks.${
                                  this.taskDefinition.completionIndicator
                              }`
                          )
                      ),
                      actionDateTime: this.$options.filters.dateTime(completedTask.actionDateTime),
                  }
                : {};
        },

        workflowStepForTask() {
            // The entire workflow object is always stored on the sub-campaign of the current entity.
            let subCampaignId = this.subCampaignId;
            if (!subCampaignId && this.subCampaign) {
                subCampaignId = this.subCampaign._id;
            }
            const { workflow } = this.getSubCampaignById({
                _id: subCampaignId,
                usePluralResourceName: true,
            });

            // If the sub-campaign has no corresponding workflow, or the workflow has no steps,
            // then the task cannot be actioned, so return with no corresponding workflow task.
            if (!workflow || !workflow.steps) return;

            const mapCategories = this.categories.map(category =>
                isObject(category) ? category.levelEntryKey : category
            );
            // Find a step in the workflow which corresponds to this task.
            const matchingSteps = workflow.steps.filter(
                step =>
                    step.task === this.taskDefinition.type &&
                    step.entity === this.taskDefinition.entity &&
                    (!step.categories ||
                        intersection(step.categories, mapCategories).length ||
                        this.isStoreWidePromotion)
            );

            // If a matching task cannot be found for the workflow's steps, then the task
            // should not be actionable.
            if (!matchingSteps.length) return;

            // If there are multiple matching steps, return the one with the earliest deadline
            return minBy(matchingSteps, 'deadlineDateTime');
        },

        actionableEntities() {
            // Filter down the list of entities passed in to those which are able to be actioned
            // based on their current workflow state.
            return uniq(this.entityIds).filter(entityId => {
                // Split promotions cannot be executed
                if (includes(this.splitPromotions, entityId)) return false;

                const workflowState = workflowEntityOperations[this.entityType].getWorkflowState({
                    entityId,
                    owningEntityId: this.owningEntityId,
                });

                // Create callback function so parent states are only evaluated if required.
                const getParentWorkflowState = () =>
                    workflowEntityOperations[this.entityType].getParentWorkflowState({
                        entityId,
                        owningEntityId: this.owningEntityId,
                    });

                return workflowUtils.canStepBeCompleted({
                    workflowState,
                    step: this.workflowStepForTask,
                    getParentWorkflowState,
                });
            });
        },

        // Unless set to always be shown, the task button should only be visible
        // if there is a workflow task corresponding to the task.
        isVisible() {
            return this.alwaysShow || !!this.workflowStepForTask;
        },

        // The task button should be disabled if one of the following case:
        //  * The user does not have permission to action the task;
        //  * The start date for the task is in the future;
        //  * There are no actionable entities.
        disabledState() {
            if (this.disabled) {
                return {
                    disabled: this.disabled,
                    reason: this.disabledReason || this.$tkey('disabledReason.parentDisabled'),
                };
            }

            // The task should not be actionable if the workflow step has a start date in the future.
            const currentDateTime = this.$moment().utc();
            const workflowStepIsInPast =
                !this.workflowStepForTask.earliestCompletionDate ||
                currentDateTime.isAfter(
                    this.$moment(this.workflowStepForTask.earliestCompletionDate).utc()
                );

            if (!workflowStepIsInPast) {
                const earliestCompletionDate = new Date(
                    this.workflowStepForTask.earliestCompletionDate
                ).toLocaleDateString();

                return {
                    disabled: true,
                    reason: this.$tkey('disabledReason.futureStartDate', {
                        date: earliestCompletionDate,
                    }),
                };
            }

            // Ensure the button is enabled/disabled based on the user's permissions.
            const requiredUserRoles = this.taskDefinition.userRoles;
            const hasTaskPermission = intersection(requiredUserRoles, this.userRoles).length > 0;

            if (!hasTaskPermission) {
                return {
                    disabled: true,
                    reason: this.$tkey('disabledReason.insufficientPrivileges', {
                        permissions: requiredUserRoles.join(', '),
                    }),
                };
            }

            if (
                !this.actionableEntities.length ||
                this.disabledFunction({ promotions: this.actionableEntities })
            ) {
                return {
                    disabled: true,
                    reason: this.$tkey('disabledReason.requirementsNotMet'),
                };
            }

            return {
                disabled: false,
            };
        },
        taskIcon() {
            if (!this.icon) {
                return null;
            }

            return this.isNegativeAction
                ? this.taskDefinition.negativeIcon
                : this.taskDefinition.positiveIcon;
        },
    },

    methods: {
        onClick() {
            if (this.confirmFirst) {
                // disable action buttons while modal is open
                this.$emit('toggle-action-buttons', true);
                this.$refs.confirmationDialog.open();
            } else {
                this.runTask();
            }
        },

        closeConfirmationDialog() {
            // enable action buttons when modal is closed
            this.$emit('toggle-action-buttons', false);
        },

        async confirmTask() {
            await this.runTask();
            this.$refs.confirmationDialog.close();
        },

        async runTask() {
            this.$emit('click');
            const storeMap = {
                scenario: 'scenarios/runTask',
                subCampaign: 'subCampaigns/runTask',
                promotion: 'promotions/runTask',
                leaflet: 'subCampaigns/runTask',
            };

            await store.dispatch(storeMap[this.entityType], {
                task: this.task,
                isNegativeAction: this.isNegativeAction,
                workflowEntity: this.entityType,
                entityIds: this.actionableEntities,
                owningEntityId: this.owningEntityId,
            });

            this.$emit('action-completed');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.small-btn {
    &.v-btn {
        &.v-size--default {
            height: 2rem !important;
        }
    }
}

.action-date-time {
    margin-left: 0.5rem;
    color: $rtls-font-colour;

    &__label {
        font-weight: bold;
    }
}
</style>
