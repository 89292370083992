<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { bind, difference, get, isEmpty, findIndex, pick, isNumber } from 'lodash';
import { subCampaigns } from '@enums/resources';
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { componentTypes } from '@enums/custom-components';
import { object } from '@enums/value-types';
import workflowUtilsFactory from '@sharedModules/workflow-utils-factory';
import namespaces from '@enums/namespaces';
import {
    canEditSubCampaign,
    canPublishSubCampaigns,
    canReadScenario,
    enableParentChildModule,
    canEditChannel,
    canViewScenariosFrontEnd,
} from '@enums/feature-flags';
import commonFormDialogComponent from './common-form-dialog';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import { getDefaultValuePropName, getDefaultValuePropValue } from '@/js/utils/component-utils';

const createForm = function({
    isSubCampaignEditor,
    isChannelEditor,
    isDetailsOpened,
    isNominationsOpened,
    isTagsOpened,
    isChildrenOpened,
    onDetailsPanelStateChange,
    onNominationsPanelStateChange,
    onTagsPanelStateChange,
    onChildrenPanelStateChange,
    isPrivateDefault,
    subCampaignDefaults,
    workflowTemplateSetter,
    updateWorkflowInstance,
    tagsInFilter,
    isParentChildModuleEnabled,
    categoriesTree,
    handleTagUpdate,
    editContext,
    dialogCloseFunction,
    updateCategoriesInStagingArea,
}) {
    return {
        vuexModule: 'subCampaigns',
        editable: isSubCampaignEditor,
        addEvent: 'createSubCampaign',
        editEvent: 'subCampaignUpdated',
        fields: [
            {
                fieldName: 'name',
                editable: isSubCampaignEditor,
                type: fieldTypes.text,
                label: 'planning.createSubCampaign.title',
                placeholder: 'planning.createSubCampaign.titlePlaceholder',
                cssClass: 'vuex-form__name',
                isRequired: true,
                validations: [
                    {
                        message: i18n.t('validation.subCampaign.nameRequired'),
                        validator: validators.required,
                    },
                ],
            },
            {
                type: componentTypes.parentPermalink,
                subCampaignId: get(editContext, '_id', ''),
                dialogCloseFunction,
                cssClass: 'vuex-form__link',
            },
            {
                fieldName: 'briefing',
                editable: isSubCampaignEditor,
                type: fieldTypes.textarea,
                label: 'planning.createSubCampaign.briefing',
                placeholder: 'planning.createSubCampaign.briefingPlaceholder',
                cssClass: 'vuex-form__briefing',
            },
            {
                type: fieldTypes.dateRange,
                label: 'planning.createSubCampaign.dates',
                cssClass: 'vuex-form__dates',
                isRequired: true,
                editable: isSubCampaignEditor,
                minDate: {
                    source: sourceTypes.context,
                    path: 'startDate',
                },
                maxDate: {
                    source: sourceTypes.context,
                    path: 'endDate',
                },
                from: {
                    fieldName: 'startDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'startDate',
                    },
                },

                to: {
                    fieldName: 'endDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'endDate',
                    },
                },
                entity: 'sub-campaign',
                validations: [
                    {
                        message: i18n.t('validation.subCampaign.datesRequired'),
                        validator: validators.required,
                    },
                    {
                        validator: validators.dateRangeRequired,
                    },
                ],
                onChange: value => updateWorkflowInstance(value),
            },
            {
                fieldName: 'workflowTemplateKey',
                fieldPath: 'workflow',
                type: fieldTypes.select,
                cssClass: 'vuex-form__workflow',
                isRequired: true,
                filled: true,
                editable: isSubCampaignEditor,
                label: 'planning.createSubCampaign.workflow',
                placeholder: 'planning.createSubCampaign.workflowPlaceholder',
                options: {
                    source: sourceTypes.getter,
                    identifier: 'getWorkflowTemplateOptions',
                    module: 'clientConfig',
                    params: {},
                },
                setter: workflowTemplateKey => workflowTemplateSetter({ workflowTemplateKey }),
                itemText: 'text',
                itemValue: 'value',
                clearable: true,
                validations: [],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createSubCampaign.categories',
                cssClass: 'vuex-form__details',
                expansionPanelClass: 'details__wrapper',
                isOpenState: isDetailsOpened,
                change: onDetailsPanelStateChange,
                children: [
                    {
                        fieldName: 'campaignId',
                        dynamicDefaultValue: {
                            source: sourceTypes.context,
                            path: '_id',
                        },
                    },
                    {
                        fieldName: 'resourceDefinitions',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'categories',
                        type: componentTypes.categoriesSelect,
                        tree: categoriesTree,
                        cssClass: 'vuex-form__categories',
                        editable: isSubCampaignEditor,
                        isRequired: true,
                        update: updateCategoriesInStagingArea,
                        [getDefaultValuePropName(
                            subCampaignDefaults.categories
                        )]: getDefaultValuePropValue(subCampaignDefaults.categories, {
                            source: sourceTypes.context,
                            path: 'categories',
                        }),
                    },
                    {
                        fieldName: 'customerGroups',
                        type: fieldTypes.checkboxesList,
                        cssClass: 'vuex-form__customer-restrictions',
                        isRequired: true,
                        editable: isSubCampaignEditor,
                        label: 'planning.customerRestrictions',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'subCampaigns',
                            params: {
                                promotionAttributeName: 'offerMechanic',
                                promotionAttributeKey: 'customerAvailability',
                                campaignAttributeName: 'customerGroups',
                                campaignAttributeKey: 'key',
                                resource: 'clientConfig',
                                getOptionsFunction: 'getCustomerRestrictions',
                                campaignId: {
                                    source: sourceTypes.context,
                                    path: '_id',
                                },
                                subCampaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                        itemText: 'description',
                        itemValue: 'key',
                        valueType: object,
                        [getDefaultValuePropName(
                            subCampaignDefaults.customerRestrictions
                        )]: getDefaultValuePropValue(subCampaignDefaults.customerRestrictions, {
                            source: sourceTypes.context,
                            path: 'customerGroups',
                        }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createSubCampaign.resources',
                cssClass: 'vuex-form__nominations',
                isOpenState: isNominationsOpened,
                change: onNominationsPanelStateChange,
                children: [
                    {
                        type: componentTypes.nominationTabs,
                        createMode: true,
                        parentContextType: subCampaigns,
                        onResourceChange: updateWorkflowInstance,
                        storeGroups: {
                            fieldName: 'storeGroups',
                            editable: isSubCampaignEditor,
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getCheckboxListOptions',
                                module: 'subCampaigns',
                                params: {
                                    promotionAttributeName: 'storeGroups',
                                    promotionAttributeKey: 'key',
                                    resource: 'storeGroups',
                                    getOptionsFunction: 'getStoreGroupsOptions',
                                    getUserAccessOptionsMap: 'userStoreGroupsMap',
                                    campaignId: {
                                        source: sourceTypes.context,
                                        path: '_id',
                                    },
                                    subCampaignId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                            itemText: 'description',
                            itemValue: 'key',
                            valueType: object,
                            [getDefaultValuePropName(
                                subCampaignDefaults.storeGroups
                            )]: getDefaultValuePropValue(subCampaignDefaults.storeGroups, {
                                source: sourceTypes.context,
                                path: 'storeGroups',
                            }),
                        },
                        resources: {
                            isRequired: true,
                            fieldName: 'resources',
                            // Resources can be updated by users who have permission to
                            // update a sub-campaign, or by users who have explicit
                            // permission to update channels.
                            editable: isSubCampaignEditor || isChannelEditor,
                            itemText: 'description',
                            itemValue: 'key',
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getResourceOptions',
                                module: 'subCampaigns',
                                params: {
                                    parentModule: 'campaigns',
                                    parentGetter: 'getCampaignById',
                                    parentContextId: {
                                        source: sourceTypes.context,
                                        path: '_id',
                                    },
                                    contextId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                    contextModule: 'subCampaigns',
                                    contextGetter: 'getSubCampaignById',
                                    childModule: 'promotions',
                                    childGetter: 'allPromotionsBySubCampaignId',
                                },
                            },
                            [getDefaultValuePropName(
                                subCampaignDefaults.promoResources
                            )]: getDefaultValuePropValue(subCampaignDefaults.promoResources, {
                                source: sourceTypes.getter,
                                identifier: 'getDefaultResources',
                                module: 'subCampaigns',
                                params: {
                                    resources: {
                                        source: sourceTypes.context,
                                        path: 'promoResources',
                                    },
                                    storeGroups: {
                                        source: sourceTypes.context,
                                        path: 'storeGroups',
                                    },
                                },
                            }),
                        },
                        defaultStoreGroups: {
                            fieldName: 'defaultStoreGroups',
                            editable: isSubCampaignEditor,
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getSelectedSubCampaignStoreGroupOptions',
                                module: 'subCampaigns',
                                params: {
                                    subCampaignId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                            itemText: 'description',
                            itemValue: 'key',
                            valueType: object,
                        },
                        defaultResources: {
                            isRequired: true,
                            fieldName: 'defaultResources',
                            // Resources can be updated by users who have permission to
                            // update a sub-campaign, or by users who have explicit
                            // permission to update channels.
                            editable: isSubCampaignEditor || isChannelEditor,
                            itemText: 'description',
                            itemValue: 'key',
                            options: {
                                source: sourceTypes.getter,
                                identifier: 'getSelectedSubCampaignResourceOptions',
                                module: 'subCampaigns',
                                params: {
                                    subCampaignId: {
                                        source: sourceTypes.editContext,
                                        path: '_id',
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createSubCampaign.tags',
                cssClass: 'vuex-form__tags',
                isOpenState: isTagsOpened,
                change: onTagsPanelStateChange,
                children: [
                    {
                        fieldName: 'tags',
                        editable: isSubCampaignEditor,
                        type: fieldTypes.comboBox,
                        placeholder: 'planning.createSubCampaign.tagsPlaceholder',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'subCampaigns',
                            params: {
                                promotionAttributeName: 'tags',
                                promotionAttributeKey: 'tagKey',
                                resource: 'tagMetadata',
                                getOptionsFunction: 'getTagOptions',
                                subCampaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                        multiple: true,
                        chips: true,
                        deletableChips: true,
                        returnObject: true,
                        itemValue: 'tagKey',
                        itemText: 'tagName',
                        createAction: 'tagMetadata/createTag',
                        deleteAction: tag => handleTagUpdate('delete', tag),
                        updateAction: tag => handleTagUpdate('update', tag),
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createSubCampaign.children',
                cssClass: 'vuex-form__children',
                isOpenState: isChildrenOpened,
                change: onChildrenPanelStateChange,
                omitField: !isParentChildModuleEnabled,
                children: [
                    {
                        fieldName: 'children',
                        chips: true,
                        editable: isSubCampaignEditor,
                        type: fieldTypes.select,
                        placeholder: 'planning.createSubCampaign.childrenPlaceholder',
                        multiple: true,
                        autocomplete: true,
                        itemText: 'name',
                        itemValue: 'id',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getSubCampaignChildOptions',
                            module: 'subCampaigns',
                            params: {
                                subCampaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                    },
                ],
            },
            {
                fieldName: 'isPrivate',
                defaultValue: isPrivateDefault,
                editable: isSubCampaignEditor,
            },
            {
                fieldName: 'resourceDefinitions',
                defaultValue: [],
                // Resource definitions are updated to align with any updates
                // that are made to resources, therefore both resources and
                // resourceDefinitions need to have the same 'editable' value
                // to ensure they both exist on the form.
                editable: isSubCampaignEditor || isChannelEditor,
            },
            {
                fieldName: 'workflowState',
                defaultValue: [],
            },
            {
                fieldName: 'workflow',

                editable: isSubCampaignEditor,
            },
            {
                fieldName: 'tags',
                defaultValue: tagsInFilter,
                editable: isSubCampaignEditor,
            },
            {
                fieldName: 'children',
                editable: isSubCampaignEditor,
                defaultValue: [],
            },
        ],
    };
};

export default {
    extends: commonFormDialogComponent,

    preparationView: {
        type: Boolean,
        default: false,
    },

    data: () => ({
        resource: subCampaigns,
        btnCancelId: 'subCampaignCancelId',
        btnConfirmDialogCancel: 'subCampaignConfirmDialogCancelId',
        btnConfirmId: 'subCampaignBtnConfirmId',
        isDetailsOpened: null,
        isNominationsOpened: null,
        isTagsOpened: null,
        isChildrenOpened: null,
        dialogQuestionText: 'dialogs.deleteChildSubCampaign.header',
        dialogActionText: 'actions.unlink',
        dialogComponentName: 'delete-children-warning',
        dialogContext: {},
        dialogFieldName: 'children',
        hasConfirmationDialog: true,
        dialogConfirmFunction: null,
        dialogProps: {},
    }),

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'additionalDetailsDefaultSelection']),
        ...mapGetters('subCampaigns', [
            'getStagingAreaField',
            'getSubCampaignChildOptions',
            'getSubCampaignNamesByIds',
            'getPromotionCategoriesBySubCampaignId',
        ]),
        ...mapGetters('clientConfig', ['getWorkflowTemplateByKey']),
        ...mapGetters('tagMetadata', ['getTagsInFilter']),
        ...mapGetters('hierarchy', ['getCategoryKeysForHierarchyNode', 'getCategoriesTree']),

        form() {
            return createForm({
                isSubCampaignEditor: !!this.toggleLogic[canEditSubCampaign],
                isChannelEditor: !!this.toggleLogic[canEditChannel],
                isDetailsOpened: this.isDetailsOpened,
                isNominationsOpened: this.isNominationsOpened,
                isTagsOpened: this.isTagsOpened,
                isChildrenOpened: this.isChildrenOpened,
                onDetailsPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isDetailsOpened'
                ),
                onNominationsPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isNominationsOpened'
                ),
                onChildrenPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isChildrenOpened'
                ),
                isPrivateDefault: !!this.toggleLogic[canPublishSubCampaigns],
                subCampaignDefaults: this.additionalDetailsDefaultSelection.subCampaign,
                workflowTemplateSetter: this.workflowTemplateSetter,
                updateWorkflowInstance: this.updateWorkflowInstance,
                tagsInFilter: this.getTagsInFilter,
                isParentChildModuleEnabled: !!this.toggleLogic[enableParentChildModule],
                handleTagUpdate: this.handleTagUpdate,
                editContext: this.editContext,
                dialogCloseFunction: this.close,
                updateCategoriesInStagingArea: this.updateCategoriesInStagingArea,
                categoriesTree: this.getCategoriesTree({
                    parentCategories: this.context.categories,
                    childCategories: this.getPromotionCategoriesBySubCampaignId({
                        subCampaignId: get(this.editContext, '_id'),
                    }),
                    selectedCategories: this.getStagingAreaField({
                        namespace: this.editContext ? this.editContext._id : 'default',
                        fieldName: 'categories',
                    }),
                    isEditable: !!this.toggleLogic[canEditSubCampaign] && !this.isReadOnly,
                }),
            });
        },
        labels() {
            return {
                btnText: 'planning.addSubCampaign',
                heading: this.editMode
                    ? 'planning.editSubCampaign.heading'
                    : 'planning.createSubCampaign.heading',
                hasBorder: true,
                planCampaign: 'planning.planSubCampaign',
            };
        },
    },

    methods: {
        ...mapActions('subCampaigns', ['setStagingAreaField', 'refreshSubCampaigns']),

        planOutCampaign() {
            this.selectCampaignSingleView();

            if (this.toggleLogic[canViewScenariosFrontEnd]) {
                this.viewScenarios();
            }
        },

        setModalExpansionPanelsState() {
            this.setDetailsPanelState();
            this.setNominationsPanelState();
            this.setTagsPanelState();
            this.setChildrenPanelState();
        },

        setDetailsPanelState() {
            this.setExpansionPanelState('isDetailsOpened', ['customerGroups', 'categories']);
        },

        setNominationsPanelState() {
            this.setExpansionPanelState('isNominationsOpened', ['storeGroups', 'resources']);
        },

        setTagsPanelState() {
            this.setExpansionPanelState('isTagsOpened', ['tags']);
        },

        setChildrenPanelState() {
            this.setExpansionPanelState('isChildrenOpened', ['children']);
        },

        closeModal() {
            this.close();
        },

        viewScenarios() {
            this.close();
            if (this.toggleLogic[canReadScenario]) {
                this.$emit('sub-campaign-selected', this.editContext);
            }
        },

        async handleConfirm() {
            if (this.editContext) {
                const subCampaign = this.$store.state[this.entityType].stagingArea[
                    this.editContext._id
                ];

                const deletedChildrenIds = difference(
                    this.editContext.children,
                    subCampaign.children
                );

                if (!isEmpty(deletedChildrenIds)) {
                    this.dialogContext[this.dialogFieldName] = this.getSubCampaignNamesByIds(
                        deletedChildrenIds
                    );
                    this.$refs['confirmation-dialog'].open();
                    this.$refs['confirmation-dialog'].confirm = async () => {
                        this.$refs['confirmation-dialog'].close();
                        await this.saveEntity();
                        await this.refreshSubCampaigns({ subCampaignIds: deletedChildrenIds });
                    };
                } else {
                    await this.saveEntity();
                    const children = get(subCampaign, 'children', []);
                    await this.refreshSubCampaigns({ subCampaignIds: children });
                }
            } else {
                const childrenAdded = get(
                    this.$store.state[this.entityType].stagingArea.default,
                    'children',
                    []
                );
                await this.saveEntity();
                if (!isEmpty(childrenAdded)) {
                    await this.refreshSubCampaigns({ subCampaignIds: childrenAdded });
                }
            }
        },

        async handleTagUpdate(action, tag) {
            const isDelete = action === 'delete';

            // In case we edit tags we may end with two tags with the same name,
            // so we check if tags with the same name exist.
            let tagDuplicate;
            if (!isDelete) {
                // we expect to only have 1 or less matching tag names
                tagDuplicate = (await this.$store.dispatch('tagMetadata/fetchTagMetadata', {
                    params: {
                        where: {
                            _id: { $ne: tag.id },
                            tagName: { $regex: `^${tag.tagName}$`, $options: 'i' },
                            $or: [{ isDeleted: { $eq: false } }, { isDeleted: { $exists: false } }],
                        },
                    },
                    returnDocuments: true,
                }))[0];
            }

            const dependencies = await this.$store.dispatch('tagMetadata/fetchChildDependencies', {
                // fetching dependencies that will be influenced by tag update or delete
                id: tag.id,
            });

            if (!isEmpty(tagDuplicate)) {
                // case of merging two tags.
                this.dialogComponentName = 'update-tag-warning';
                this.dialogContext = 'tagMetadata';
                this.dialogFieldName = 'childDependencies';
                this.dialogQuestionText = 'dialogs.mergeTags.header';
                this.dialogActionText = 'actions.update';
                this.dialogConfirmFunction = async () => {
                    await this.mergeTags(tag, tagDuplicate);
                };
                this.dialogProps = {
                    mergeTags: true,
                    tagName: tag.tagName,
                    duplicateTagName: tagDuplicate.tagName,
                    childDependencies: dependencies,
                    warningText: 'dialogs.mergeTags.warning',
                    descriptionText: 'dialogs.mergeTags.description',
                };
                this.$refs['confirmation-dialog'].open();
            } else if (!isEmpty(dependencies)) {
                // case of updating or deleting tag with dependencies.
                this.dialogComponentName = 'update-tag-warning';
                this.dialogContext = 'tagMetadata';
                this.dialogFieldName = 'childDependencies';
                this.dialogQuestionText = isDelete
                    ? 'dialogs.deleteTag.header'
                    : 'dialogs.changeTagName.header';
                this.dialogActionText = isDelete ? 'actions.delete' : 'actions.update';
                this.dialogConfirmFunction = async () => {
                    if (isDelete) {
                        await this.deleteTag(tag);
                    } else {
                        await this.updateTag(tag);
                    }
                };
                this.dialogProps = {
                    childDependencies: dependencies,
                    warningText: isDelete
                        ? 'dialogs.deleteTag.warning'
                        : 'dialogs.changeTagName.warning',
                    ...(isDelete ? { tagName: tag.tagName, deleteTag: true } : {}),
                };
                this.$refs['confirmation-dialog'].open();
            } else if (isDelete) {
                // Cases of updating or deleting tag without dependencies.
                await this.deleteTag(tag);
            } else {
                await this.updateTag(tag);
            }
        },

        workflowTemplateSetter({ workflowTemplateKey }) {
            let workflowInstance = {};
            const namespace = this.editContext ? this.editContext._id : namespaces.default;

            if (workflowTemplateKey) {
                const workflowTemplate = this.getWorkflowTemplateByKey(workflowTemplateKey);

                const subCampaign = this.$store.state[this.entityType].stagingArea[namespace];
                const workflowUtils = workflowUtilsFactory(Vue.moment);
                const getCategoryKeysForHierarchyNode = this.getCategoryKeysForHierarchyNode;

                workflowInstance = workflowUtils.convertWorkflowTemplateToInstance({
                    workflowTemplate,
                    subCampaign,
                    getCategoryKeysForHierarchyNode,
                });
            }

            this.setStagingAreaField({
                namespace,
                fieldName: 'workflow',
                value: workflowInstance,
            });
        },

        updateWorkflowInstance() {
            const namespace = this.editContext ? this.editContext._id : namespaces.default;
            const workflow = this.getStagingAreaField({
                namespace,
                fieldName: 'workflow',
            });

            if (workflow && workflow.workflowTemplateKey) {
                this.workflowTemplateSetter({ workflowTemplateKey: workflow.workflowTemplateKey });
            }
        },

        async deleteTag(tag) {
            await this.$store.dispatch('tagMetadata/deleteTagMetadata', { id: tag.id });
            this.updateTagsInStagingArea({ action: 'delete', tag });
            this.$refs['confirmation-dialog'].close();
            this.resetConfirmDialogConfig();
        },

        async updateTag(tag) {
            // sub-campaign to refetch after tag update
            const namespace = this.editContext ? this.editContext._id : namespaces.default;
            await this.$store.dispatch('tagMetadata/updateTagName', { tag, namespace });
            this.updateTagsInStagingArea({ action: 'update', tag });
            this.$refs['confirmation-dialog'].close();
            this.resetConfirmDialogConfig();
        },

        async mergeTags(tag, tagDuplicate) {
            // sub-campaign to refetch after tag merge
            const namespace = this.editContext ? this.editContext._id : namespaces.default;
            await this.$store.dispatch('tagMetadata/mergeTags', {
                tag,
                tagDuplicate,
                namespace,
            });
            this.mergeTagsInStagingArea({ tag, tagDuplicate });
            this.$refs['confirmation-dialog'].close();
            this.resetConfirmDialogConfig();
        },

        mergeTagsInStagingArea({ tag, tagDuplicate }) {
            const namespace = this.editContext ? this.editContext._id : namespaces.default;
            const tags = this.$store.state[this.entityType].stagingArea[namespace].tags;
            const index = findIndex(tags, { tagKey: tag.tagKey });
            // delete tag (with old name) and duplicate tag
            // then, if tag or duplicate tag are present, push in tag but with a new name
            if (isNumber(index)) {
                tags.splice(index, 1);
            }
            const indexDuplicate = findIndex(tags, { tagKey: tagDuplicate.tagKey });
            if (isNumber(indexDuplicate)) {
                tags.splice(indexDuplicate, 1);
            }
            if (isNumber(index) || isNumber(indexDuplicate)) {
                tags.push(tag);
            }
        },

        updateTagsInStagingArea({ action, tag }) {
            const namespace = this.editContext ? this.editContext._id : namespaces.default;
            const tags = this.$store.state[this.entityType].stagingArea[namespace].tags;
            const index = findIndex(tags, { tagKey: tag.tagKey });
            if (index > -1) {
                if (action === 'delete') {
                    tags.splice(index, 1);
                } else {
                    tags.splice(index, 1, pick(tag, ['tagKey', 'tagName']));
                }
            }
            this.$emit('change');
        },

        updateCategoriesInStagingArea(selectedCategories) {
            const namespace = this.editContext ? this.editContext._id : 'default';

            this.setStagingAreaField({
                namespace,
                fieldName: 'categories',
                value: selectedCategories,
            });
            this.updateWorkflowInstance();
        },

        resetConfirmDialogConfig() {
            this.dialogComponentName = 'delete-children-warning';
            this.dialogContext = {};
            this.dialogFieldName = 'children';
            this.dialogQuestionText = 'dialogs.deleteChildSubCampaign.header';
            this.dialogActionText = 'actions.unlink';
            this.dialogConfirmFunction = null;
            this.dialogProps = {};
        },
    },
};
</script>
