import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rtls-select-container", class: _vm.cssClass },
    [
      _vm.label
        ? _c("p", { staticClass: "rtls-select-container__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        VAutocomplete,
        {
          ref: "select",
          staticClass: "rtls-select",
          class: {
            "rtls-select--auto-height":
              (_vm.chips || _vm.multiple) && !_vm.horizontal,
            "rtls-select--white": !_vm.filled,
          },
          attrs: {
            items: _vm.getOptions,
            placeholder: _vm._f("toSentenceCase")(
              _vm.$t(_vm.placeholder || "placeholders.select")
            ),
            "menu-props": {
              bottom: true,
              offsetY: true,
              openOnClick: false,
              ..._vm.maxHeight,
            },
            disabled: _vm.isDisabled,
            multiple: _vm.multiple,
            "item-text": _vm.itemText,
            "item-value": _vm.itemValue,
            "small-chips": _vm.chips,
            "deletable-chips": _vm.deletableChips,
            "single-line": "",
            rules: _vm.rules,
            "validate-on-blur": _vm.validateOnBlur,
            clearable: _vm.showClearButton,
            "return-object": _vm.returnObject,
            "search-input": _vm.searchInput,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.searchInput = $event
            },
            "update:search-input": function ($event) {
              _vm.searchInput = $event
            },
            input: function ($event) {
              _vm.searchInput = null
            },
            change: _vm.change,
            "click:clear": _vm.clear,
            click: _vm.handleClick,
            blur: function ($event) {
              return _vm.cleanNewlyChangedItems()
            },
            keypress: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
            },
          },
          scopedSlots: _vm._u(
            [
              _vm.multiple && _vm.hasSelectAll
                ? {
                    key: "prepend-item",
                    fn: function () {
                      return [
                        _c(
                          VListItem,
                          { attrs: { ripple: "" }, on: { click: _vm.toggle } },
                          [
                            _c(
                              VListItemAction,
                              [
                                _c(
                                  VIcon,
                                  { attrs: { color: _vm.iconColor } },
                                  [_vm._v(_vm._s(_vm.icon))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VListItemContent,
                              [
                                _c(VListItemTitle, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(_vm.selectText)
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(VDivider, { staticClass: "mt-2" }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.chips
                ? {
                    key: "selection",
                    fn: function ({ parent, item, index }) {
                      return [
                        _vm.maxItemsToDisplaySelected ||
                        index < _vm.maxItemsInList - 1 ||
                        !_vm.finiteList
                          ? _c(
                              VTooltip,
                              {
                                attrs: {
                                  right: "",
                                  "content-class": "rtls-tooltip",
                                  disabled: !item.invalid,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            VChip,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "v-chip--select",
                                                  attrs: {
                                                    small: "",
                                                    close:
                                                      _vm.deletableChips &&
                                                      !_vm.isDisabled &&
                                                      !_vm.itemDisabled(item),
                                                    disabled:
                                                      _vm.itemDisabled(item),
                                                  },
                                                  on: {
                                                    "click:close": () =>
                                                      parent.onChipInput(item),
                                                  },
                                                },
                                                "v-chip",
                                                item.invalid
                                                  ? {
                                                      color: "red",
                                                      outlined: true,
                                                    }
                                                  : {},
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: {
                                                    disabled: _vm.hideTooltip,
                                                    top: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on: criteria,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    ref: "truncated",
                                                                    staticClass:
                                                                      "truncate-text",
                                                                    on: {
                                                                      mouseover:
                                                                        _vm.checkTooltipRequired,
                                                                    },
                                                                  },
                                                                  "span",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                criteria
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                                    " +
                                                                    _vm._s(
                                                                      _vm.getItemString(
                                                                        item
                                                                      )
                                                                    ) +
                                                                    "\n                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getItemString(item)
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$tkey(_vm.invalidSelectionText)
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.maxItemsToDisplaySelected &&
                        index === _vm.maxItemsInList - 1 &&
                        _vm.finiteList
                          ? _c(
                              "span",
                              [
                                _c(
                                  VChip,
                                  {
                                    staticClass: "v-chip--select pr-2",
                                    attrs: { small: "" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "truncate-text" },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "planning.createCampaign.xMore",
                                                { number: _vm.xOthers }
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                        _c(
                                          VMenu,
                                          {
                                            attrs: {
                                              bottom: "",
                                              "offset-y": "",
                                              "open-on-hover": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      !_vm.isDisabled
                                                        ? _c(
                                                            VIcon,
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "pl-1",
                                                                  attrs: {
                                                                    id: "mdi",
                                                                    size: "20",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-dots-horizontal-circle"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              VList,
                                              _vm._l(
                                                _vm.xOthersArray,
                                                function (
                                                  othersValue,
                                                  othersIndex
                                                ) {
                                                  return _c(
                                                    VListItem,
                                                    {
                                                      key: othersIndex,
                                                      attrs: {
                                                        disabled:
                                                          _vm.itemDisabled(
                                                            item
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(VListItemTitle, [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(othersValue)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.deletableChips
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                VIcon,
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.itemDisabled(
                                                                        item
                                                                      ),
                                                                    color:
                                                                      _vm.iconColor,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deselectOne(
                                                                          othersValue
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            mdi-close-circle\n                                        "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  }
                : {
                    key: "selection",
                    fn: function ({ item }) {
                      return [
                        _c(
                          VTooltip,
                          {
                            attrs: { disabled: _vm.hideTooltip, top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on: criteria, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              ref: "truncated",
                                              staticClass: "truncate-text",
                                              on: {
                                                mouseover:
                                                  _vm.checkTooltipRequired,
                                              },
                                            },
                                            "span",
                                            attrs,
                                            false
                                          ),
                                          criteria
                                        ),
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.getItemString(item)) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.getItemString(item)) +
                                "\n                "
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.getItemString(item))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
              {
                key: "item",
                fn: function ({ item, attrs, on }) {
                  return [
                    _c(
                      VListItem,
                      _vm._g(
                        _vm._b(
                          {
                            class: _vm.itemColourClass(item),
                            attrs: {
                              dense: "",
                              disabled: _vm.itemDisabled(item),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeOne(item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ active }) {
                                    return [
                                      _vm.multiple
                                        ? _c(
                                            VListItemAction,
                                            [
                                              _c(VCheckbox, {
                                                attrs: {
                                                  "input-value": active,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(
                                            VListItemTitle,
                                            [
                                              _c(
                                                VRow,
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.text(item))
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(VSpacer),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          "v-list-item",
                          attrs,
                          false
                        ),
                        on
                      )
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        [
          _c(
            "template",
            { slot: "append" },
            [
              _c("icon-button", {
                attrs: { icon: _vm.appendIcon, size: "28" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleClick.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }