<template>
    <div class="promo-planner">
        <aside class="promo-planner__sidebar elevation-2">
            <health-sidebar />
        </aside>
        <div class="flex-column flex-column--full">
            <div class="promo-planner__weekly-planner" :style="{ height: weeklyPlannerHeight }">
                <campaigns-weekly-view
                    v-if="shouldDisplayWeeklyView"
                    :class="campaignsListCss"
                    :make-read-only="preparationView"
                />
            </div>
            <template v-if="selectedSubCampaignId">
                <div
                    v-if="preparationView"
                    ref="details-component"
                    class="promo-planner__details-component"
                >
                    <slot name="details-component" />
                </div>
                <feature-toggle :toggle="canReadScenario">
                    <!--show on the Planning tab in a single campaign view-->
                    <div
                        v-if="!preparationView && isCampaignSelected"
                        ref="scenarios-component"
                        class="promo-planner__scenarios-viewer"
                    >
                        <slot name="scenarios-component" />
                    </div>
                </feature-toggle>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { canReadScenario, canViewScenariosFrontEnd } from '@enums/feature-flags';

export default {
    props: {
        preparationView: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return { canReadScenario };
    },
    computed: {
        ...mapState('subCampaigns', ['selectedSubCampaignId']),
        ...mapState('weeks', ['weeks', 'isLoadingPastWeeks']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('campaigns', ['isCampaignSelected', 'selectedCampaignId']),
        ...mapGetters('subCampaigns', ['getSubCampaignsByCampaignId']),

        weeklyPlannerHeight() {
            // in Planning tab in a single campaign view for user that can edit scenario
            if (this.selectedSubCampaignId) {
                if (
                    this.preparationView ||
                    (this.isCampaignSelected &&
                        !!this.toggleLogic[this.canReadScenario] &&
                        this.toggleLogic[canViewScenariosFrontEnd])
                ) {
                    const subCampaigns = this.getSubCampaignsByCampaignId(this.selectedCampaignId);
                    // one promo-planner-container-item consist of 4 grid-rows
                    return `${subCampaigns.length * 4 + 17}rem`;
                }
            }
            return '';
        },

        shouldDisplayWeeklyView() {
            return this.weeks && this.weeks.length > 0;
        },

        campaignsListCss() {
            return this.isLoadingPastWeeks || this.isCampaignSelected
                ? 'promo-planner__campaigns-list--unscrollable-x'
                : 'promo-planner__campaigns-list';
        },
    },
    events: {
        onFocusDetailComponent() {
            const componentName =
                this.selectedSubCampaignId && !this.preparationView
                    ? 'scenarios-component'
                    : 'details-component';
            this.$scrollTo(this.$refs[componentName]);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.promo-planner {
    height: calc(100% - 6rem);
    background-color: $promo-white;
    position: relative;

    &__sidebar {
        @include promo-planner-health-sidebar;
        z-index: $health-sidebar-z-index;
    }

    &__planner-view {
        @include flex-column;
    }

    &__campaigns-list {
        margin-right: $health-sidebar-width;
        height: 100%;
        overflow-x: auto;
        overflow-y: auto;
        &--unscrollable-x {
            margin-right: $health-sidebar-width;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &__scenarios-viewer {
        flex-grow: 1;
        margin-bottom: 3rem;
        background-color: $promo-grey-3;
    }
    &__weekly-planner {
        height: calc(100vh - 17.4rem);
    }
}
</style>
