<template>
    <highcharts :options="chartOptions" />
</template>

<script>
import { mapGetters } from 'vuex';
import { omit, get } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import * as scssVariables from '@style/base/_exports.module.scss';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    localizationKey: 'charts.waterfall',
    props: {
        chartConfig: {
            type: Object,
            required: true,
        },
        localizationKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chartData: {},
        };
    },

    computed: {
        ...mapGetters('clientConfig', ['currencySymbol']),

        data() {
            return this.model;
        },

        chartOptions() {
            const config = this.chartConfig;
            return {
                chart: {
                    type: 'waterfall',
                    // adding 2px during render so the last grid line does not look duplicate with the xAxis line
                    height: config.height + 2,
                },
                title: {
                    text: this.chartTitle,
                    // Position title to start right after the y axis
                    align: 'left',
                    x: 30,
                    style: {
                        fontSize: '1.2rem',
                    },
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        rotation: 0, // forces labels to display horizontally (default is 90)
                        style: {
                            fontSize: '1.1rem',
                            textOverflow: 'none', // avoids three dots with tooltip for labels that don't fit the screen
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: toSentenceCase(
                            this.$tkey(`${this.localizationKey}.${config.name}.yAxis.label`)
                        ),
                    },
                    labels: {
                        enabled: false,
                    },
                    tickAmount: 7, // number of grid lines in the chart
                    lineWidth: 1,
                    height: config.height - 82, // removing height below yAxis line
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dashStyle: 'Dash',
                        // minimum height of a column, so values close to zero do not end up with completely invisible columns
                        minPointLength: 5, // column min height
                        pointWidth: 42, // column width
                    },
                },
                // Chart data
                series: [
                    {
                        data: this.chartData,
                        dataLabels: {
                            enabled: true,
                            formatter() {
                                return this.point.columnValue;
                            },
                            style: {
                                fontWeight: 'normal',
                                fontSize: '10.8px',
                                textOutline: '0.9px contrast',
                            },
                        },
                    },
                ],
                tooltip: {
                    formatter() {
                        return this.point.tooltipValue;
                    },
                },
                legend: { enabled: false },
                credits: { enabled: false },
            };
        },

        // Generates chart title based on config
        chartTitle() {
            const prefix = toSentenceCase(
                this.$tkey(`${this.localizationKey}.${this.chartConfig.name}.title.prefix`, {
                    currencySymbol: this.currencySymbol,
                })
            );
            const suffix = toSentenceCase(
                this.$tkey(`${this.localizationKey}.${this.chartConfig.name}.title.suffix`, {
                    currencySymbol: this.currencySymbol,
                })
            );

            return `<b>${prefix}</b> ${suffix}`;
        },
    },

    watch: {
        data: {
            immediate: true,
            handler() {
                this.setChartData();
            },
        },
    },

    methods: {
        setChartData() {
            const data = this.chartConfig.axis.map(axis => {
                const value = this.data[axis.fieldName];
                const axisAttributes = {
                    // total values should have the column pointing to the opposite direction
                    // since it displays the difference from the first column to the sum of the others
                    y: axis.isTotal ? -1 * value : value,
                    columnValue: this.getValueFormatter()(value),
                    tooltipValue: this.getValueFormatter(this.chartConfig.tooltipFormat)(value),
                    color: get(scssVariables.default, axis.colour),
                    name: toSentenceCase(
                        this.$tkey(
                            `${this.localizationKey}.${this.chartConfig.name}.xAxis.${
                                axis.fieldName
                            }`
                        )
                    ),
                };
                return {
                    ...omit(axis, ['fieldName', 'source', 'format']),
                    ...axisAttributes,
                };
            });

            this.chartData = data;
        },

        getValueFormatter(format = 'numberShorthand') {
            return value => get(this.$options.filters, format)(value);
        },
    },
};
</script>
