export default [
    {
        header: 'hierarchyLevel',
        name: 'hierarchy.levelEntryDescription',
        functionName: 'getCategoryNames',
    },
    {
        header: 'scope',
        name: 'scope',
    },
    {
        header: 'measurement',
        component: {
            name: 'measurement',
            contextualProps: {
                selectedMeasurement: 'measurement',
            },
        },
    },
    {
        header: 'totalCurrent',
        functionName: 'getTotalCurrentAllocations',
    },
    {
        header: 'value',
        functionName: 'getValue',
    },
    {
        header: 'period',
        functionName: 'getPeriod',
    },
    {
        component: {
            name: 'notes',
            contextualProps: {
                notes: 'notes',
            },
            events: {
                post: {
                    method: 'postNote',
                    params: [],
                },
                save: {
                    method: 'saveNote',
                    params: [],
                },
                delete: {
                    method: 'removeNote',
                    params: [],
                },
            },
        },
    },
    {
        component: {
            name: 'main-expand-button',
            props: {
                isExpanded: false,
            },
        },
    },
];
