<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { filter } from 'lodash';
import commonDeleteDialogComponent from '../common-delete-dialog';

export default {
    extends: commonDeleteDialogComponent,

    computed: {
        ...mapGetters('promotions', ['candidates']),

        btnDeleteId() {
            return 'deleteProposedProduct';
        },

        labels() {
            return {
                deleteBtnText: 'actions.delete',
                deleteHeading: 'dialogs.deleteProduct.header',
            };
        },
    },

    methods: {
        ...mapActions('products', ['deleteProduct']),
        ...mapMutations('promotionCandidates', ['setPromotionCandidates']),

        async deleteSelectedItem() {
            const { result } = await this.deleteProduct({ id: this.resourceId });
            if (result) {
                await this.setPromotionCandidates(
                    filter(this.candidates, candidate => candidate._id !== this.resourceId)
                );
            }
            this.globalEmit('proposed-product-deleted', this.resourceId);
            this.closeAll();
        },
    },
};
</script>
