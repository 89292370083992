import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "downloadCentre.heading",
      "btn-confirm-text": "actions.export",
      "has-activator": true,
      "confirm-btn-disabled": _vm.isExportDisabled,
    },
    on: { confirm: _vm.onExport, close: _vm.closeDialog },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ activator }) {
          return [
            _c(
              VBtn,
              _vm._g(
                {
                  staticClass: "download-centre-btn",
                  attrs: { secondary: "", depressed: "", outlined: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                activator
              ),
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.export"))) +
                    "\n            "
                ),
                _c("icon", {
                  attrs: { "icon-name": "download", right: "", small: "" },
                }),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "download-centre" }, [
              _c("div", { staticClass: "download-centre__description" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("downloadCentre.description")
                      )
                    ) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "download-centre__content" }, [
                _c(
                  "div",
                  { staticClass: "download-centre__filter" },
                  [
                    _c("h4", { staticClass: "py-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("downloadCentre.level")
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(VDivider),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "py-2",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t("downloadCentre.subCampaigns")
                        ),
                        options: _vm.subCampaigns,
                        "item-text": "name",
                        "item-value": "_id",
                        getter: () => _vm.selectedSubCampaignIds,
                        setter: (value) => (_vm.selectedSubCampaignIds = value),
                        multiple: "",
                        chips: "",
                        "deletable-chips": "",
                        clearable: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "py-2",
                      attrs: {
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t("downloadCentre.categories")
                        ),
                        options: _vm.categoriesOptions,
                        getter: () => _vm.selectedCategories,
                        setter: (value) => (_vm.selectedCategories = value),
                        multiple: "",
                        chips: "",
                        "deletable-chips": "",
                        clearable: "",
                        disabled: _vm.selectedSubCampaignIds.length === 0,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "download-centre__data" },
                  [
                    _c("h4", { staticClass: "py-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("downloadCentre.data")
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(VDivider),
                    _vm._v(" "),
                    _c("vuex-select", {
                      staticClass: "py-2",
                      attrs: {
                        clearable: "",
                        placeholder: _vm._f("toSentenceCase")(
                          _vm.$t("downloadCentre.exportType")
                        ),
                        options: _vm.exportDataOptions,
                        getter: () => _vm.fieldToUnwind,
                        setter: (value) => (_vm.fieldToUnwind = value),
                        filled: "",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "download-centre__format" },
                  [
                    _c("h4", { staticClass: "py-2" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("downloadCentre.format")
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(VDivider),
                    _vm._v(" "),
                    _c("vuex-radio-group", {
                      attrs: {
                        options: _vm.exportFormatOptions,
                        getter: () => _vm.exportFormat,
                        setter: (value) => (_vm.exportFormat = value),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }