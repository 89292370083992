import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rtls-combobox-container", class: _vm.cssClass },
    [
      _vm.label
        ? _c("p", { staticClass: "rtls-combobox-container__label" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.label))) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        VCombobox,
        {
          staticClass: "rtls-combobox",
          class: {
            "rtls-select--auto-height": _vm.chips || _vm.multiple,
            "rtls-select--white": !_vm.filled,
          },
          attrs: {
            items: _vm.getOptions,
            placeholder: _vm._f("toSentenceCase")(_vm.$t(_vm.placeholder)),
            "menu-props": { bottom: true, offsetY: true },
            disabled: _vm.isDisabled,
            multiple: _vm.multiple,
            "item-text": _vm.itemText,
            "item-value": _vm.itemValue,
            "small-chips": _vm.chips,
            "deletable-chips": _vm.deletableChips,
            "single-line": "",
            rules: _vm.rules,
            "validate-on-blur": _vm.validateOnBlur,
            clearable: _vm.clearable,
            "return-object": _vm.returnObject,
            "search-input": _vm.searchInput,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.searchInput = $event
            },
            "update:search-input": function ($event) {
              _vm.searchInput = $event
            },
            change: _vm.updateStagingArea,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.ensureEnterButtonEventOccurs()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemContent,
                        [
                          _c(VListItemTitle, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(_vm.noResultsPlaceholder, {
                                      searchInput: _vm.searchInput,
                                    })
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function ({ item, attrs, on }) {
                return [
                  _c(
                    VListItem,
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { dense: "", disabled: false },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ active }) {
                                  return [
                                    _vm.multiple
                                      ? _c(
                                          VListItemAction,
                                          [
                                            _c(VCheckbox, {
                                              attrs: {
                                                "input-value": active,
                                                value: "",
                                                disabled: attrs.disabled,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      VListItemContent,
                                      [
                                        _vm.editing[_vm.itemValue] ===
                                        item[_vm.itemValue]
                                          ? _c(VTextField, {
                                              attrs: {
                                                autofocus: "",
                                                flat: "",
                                                "background-color":
                                                  "transparent",
                                                "hide-details": "",
                                              },
                                              on: {
                                                keyup: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.edit(item)
                                                },
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.editing[_vm.itemText],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editing,
                                                    _vm.itemText,
                                                    $$v
                                                  )
                                                },
                                                expression: "editing[itemText]",
                                              },
                                            })
                                          : _c(
                                              VListItemTitle,
                                              [
                                                _c(
                                                  VRow,
                                                  {
                                                    attrs: { "no-gutters": "" },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item[_vm.itemText]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(VSpacer),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      VListItemAction,
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c("icon-button", {
                                          attrs: {
                                            icon:
                                              _vm.editing[_vm.itemValue] !==
                                              item[_vm.itemValue]
                                                ? "edit"
                                                : "check",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.edit(item)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    item.canBeDeleted
                                      ? _c(
                                          VListItemAction,
                                          {
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                              },
                                            },
                                          },
                                          [
                                            _c("delete-button", {
                                              attrs: { background: "" },
                                              on: {
                                                delete: function ($event) {
                                                  return _vm.deleteItem(item)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        "v-list-item",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectedItems,
            callback: function ($$v) {
              _vm.selectedItems = $$v
            },
            expression: "selectedItems",
          },
        },
        [
          _c(
            "template",
            { slot: "append" },
            [_c(VIcon, { attrs: { size: "28" } }, [_vm._v("expand_more")])],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }