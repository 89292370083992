const filtersMap = {
    campaigns: {
        categories: {
            module: 'hierarchy',
            isFieldArray: true,
        },
        _id: {
            module: 'campaigns',
        },
        storeGroups: {
            module: 'storeGroups',
            isFieldArray: true,
            objectKey: 'key',
        },
    },
    subCampaigns: {
        categories: {
            module: 'hierarchy',
            isFieldArray: true,
        },
        tags: {
            module: 'tagMetadata',
            isFieldArray: true,
            objectKey: 'tagKey',
        },
        storeGroups: {
            module: 'storeGroups',
            isFieldArray: true,
            objectKey: 'key',
        },
    },
    scenarios: {
        categories: {
            module: 'hierarchy',
            isFieldArray: true,
        },
        storeGroups: {
            module: 'storeGroups',
            isFieldArray: true,
            objectKey: 'key',
        },
    },
    promotions: {
        scenarioId: {
            module: 'scenarios',
        },
        categories: {
            module: 'hierarchy',
            isFieldArray: true,
            objectKey: 'levelEntryKey',
            fieldKey: 'userSelectedCategories',
        },
        tags: {
            module: 'tagMetadata',
            isFieldArray: true,
            objectKey: 'tagKey',
        },
        clientState: {
            module: 'promotions',
        },
        storeGroups: {
            module: 'storeGroups',
            isFieldArray: true,
            objectKey: 'key',
        },
    },
    supplierCommitments: {
        suppliers: {
            module: 'suppliers',
            customFilter: (item, filterValues) => {
                // Currently there are 2 different ways to store supplier commitments,
                // each of which has a slightly different supplier schema.
                // The following code ensures that both types of supplier commitments
                // are filtered appropriately.  Note that this will be aligned in
                // PROWEB-1483.
                if (!item.supplier) {
                    return filterValues.some(i => i === item.supplierKey);
                }

                return filterValues.some(i => i === item.supplier.supplierKey);
            },
        },
        categories: {
            module: 'suppliers',
            fieldKey: 'hierarchy',
            customFilter: (item, filterValue) => {
                return item.hierarchy.some(category => category.levelEntryKey === filterValue);
            },
        },
        storeGroups: {
            module: 'suppliers',
            customFilter: (item, filterValues) => {
                if (item.storeGroups) {
                    const storeGroups = item.storeGroups.map(storeGroup => storeGroup.key);
                    return filterValues.some(i => storeGroups.includes(i));
                }
            },
        },
    },
    variableFundingAgreements: {
        suppliers: {
            module: 'suppliers',
            customFilter: (item, filterValues) => {
                return filterValues.some(i => i === item.supplier.supplierKey);
            },
        },
        categories: {
            module: 'suppliers',
            fieldKey: 'hierarchy',
            customFilter: (item, filterValue) => {
                return item.hierarchy.some(category => category.levelEntryKey === filterValue);
            },
        },
    },
};

export default filtersMap;
