'use strict';

/**
 * Enum definition file for the routes names.
 *
 * @module data/enums/route-names
 */
module.exports = {
    planningAllCampaignsView: 'planningAllCampaignsView',
    planningSingleCampaignView: 'planningSingleCampaignView',
    planningSingleSubCampaignView: 'planningSingleSubCampaignView',
    supplierCommitmentsView: 'supplierCommitmentsView',
    supplierCommitmentView: 'supplierCommitmentView',
    preparationAllCampaignsView: 'preparationAllCampaignsView',
    preparationSingleCampaignView: 'preparationSingleCampaignView',
    preparationSingleSubCampaignView: 'preparationSingleSubCampaignView',
    errorView: 'errorView',
    variableFundingView: 'variableFundingView',
    rateCardView: 'rateCardView',
    promotionView: 'promotionView',
    promotionTabView: 'promotionTabView',
    scenarioView: 'scenarioView',
    promotionsViewer: 'promotionsViewer',

    // Admin area
    freeGiftsView: 'freeGiftsView',
    loyaltyPointsView: 'loyaltyPointsView',
    admin: 'admin',
    clientSystemsTesting: 'clientSystemsTesting',
    redisBull: 'redisBull',
    executionErrors: 'executionErrors',
    featureToggler: 'featureToggler',

    // User Permissions
    userPermissions: 'userPermissions',

    // Parking Lot area
    parkingLotView: 'parkingLotView',
    pastPromotionsView: 'pastPromotionsView',
    subCampaignAllocationView: 'subCampaignAllocationView',

    // reporting
    reporting: 'reporting',
    bookmark: 'bookmark',

    // Products
    productsView: 'productsView',
    proxyProductsView: 'proxyProductsView',
    productGroupsView: 'productGroupsView',
};
