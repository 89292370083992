var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { class: { "override-container": true, disabled: _vm.readOnly } },
        [
          _c("div", { staticClass: "ag-override-input" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.readOnly,
                    expression: "!readOnly",
                  },
                ],
                staticClass: "button-container",
              },
              [
                _vm.isRevertVisible
                  ? _c("icon-button", {
                      staticClass: "button",
                      attrs: { icon: "new-revert" },
                      on: {
                        click: function ($event) {
                          return _vm.revert()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-container" },
              [
                _c("input-text-field", {
                  staticClass: "input",
                  class: {
                    "input--bold": _vm.isBold,
                    "input--blue": _vm.isShowForecastedValue,
                    disabled: _vm.readOnly,
                  },
                  attrs: {
                    getter: () => _vm.overrideValue,
                    setter: (value) => _vm.setValue(value),
                    validations: _vm.params.colDef.validationRules || [],
                    "field-type": _vm.params.fieldType,
                    placeholder: _vm.initialValue,
                    disabled: _vm.readOnly,
                    format: "numbers.default.overwrittenVolumeEntry",
                    "positive-only": "",
                    filled: "",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isShowForecastedValue
              ? _c(
                  "div",
                  {
                    class: { "initial-value": true, "input--bold": _vm.isBold },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.readOnlyValue) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }