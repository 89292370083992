var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vuex-checkbox", {
    attrs: {
      getter: _vm.allocationGetter,
      setter: _vm.allocateToCommitment,
      "ignore-parent-read-only": true,
      "make-read-only": _vm.isDisabled,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }