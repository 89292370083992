import { kebabCase } from 'lodash';

const formUtilsMixin = {
    methods: {
        async submitForm({
            vuexModule,
            namespace,
            editMode,
            editableFields,
            submitAction,
            postSubmitEvent,
        }) {
            const action = `${vuexModule}/submitForm`;
            // We don't have an error to catch here as we handle any server errors in the action itself
            // We do however pass the error and result back so that we can choose whether to perform
            // more actions. e.g. don't close a create/edit dialog if there was an error
            // - the user should be able to edit and click save again
            const { error, result } = await this.$store.dispatch(
                action,
                {
                    namespace,
                    editMode,
                    editableFields,
                    submitAction,
                },
                { root: true }
            );
            // Don't emit postSubmitEvent if dispatch returns an error
            if (!error && postSubmitEvent) this.globalEmit(kebabCase(postSubmitEvent), result);
            return { error, result };
        },
    },
};

export default formUtilsMixin;
