'use strict';

module.exports = {
    productGroup: 'pg',
    current: 'cur',
    New: 'new',
    architecture: 'architecture',
    combined: 'combined',
    proposed: 'prop',
};
