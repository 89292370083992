'use strict';

module.exports = {
    campaigns: 'campaigns',
    products: 'products',
    promotionProductGroups: 'promotion-product-groups',
    promotions: 'promotions',
    promotionVersions: 'promotion-versions',
    promotionChangesets: 'promotion-changesets',
    notifications: 'notifications',
    scenarios: 'scenarios',
    subCampaigns: 'sub-campaigns',
    suppliers: 'suppliers',
    userProfiles: 'user-profiles',
    storeGroups: 'store-groups',
    productPrices: 'product-prices',
    nominationTemplates: 'nomination-templates',
    supplierCommitments: 'supplier-commitments',
    rateCards: 'rate-cards',
    promotionRateCards: 'promotion-rate-cards',
    variableFundingAgreements: 'variable-funding-agreements',
    freeGifts: 'free-gifts',
    loyaltyPoints: 'loyalty-points',
    detailedProvisions: 'detailed-provisions',
    bookmarks: 'bookmarks',
    parkingLot: 'parkingLot',
    targets: 'targets',
    productOfferGroups: 'productOfferGroups',
    documentChangesets: 'documentChangesets',
};
