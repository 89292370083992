var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: { pointer: _vm.promotionIdExists } },
    [
      _vm.renderIcon
        ? _c("execution-errors-dialog", {
            attrs: {
              "execution-errors": _vm.executionErrors,
              "forecasting-errors": _vm.forecastingErrors,
              "in-tool-validations": _vm.inToolValidations,
              disabled: !_vm.promotionIdExists,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }