<template>
    <div :class="{ pointer: promotionIdExists }">
        <execution-errors-dialog
            v-if="renderIcon"
            :execution-errors="executionErrors"
            :forecasting-errors="forecastingErrors"
            :in-tool-validations="inToolValidations"
            :disabled="!promotionIdExists"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { get, groupBy, isEmpty } from 'lodash';
import notificationTypes from '@enums/notification-types';
import { mapGetters } from 'vuex';

export default Vue.extend({
    computed: {
        ...mapGetters('promotions', ['getPromotionNotifications']),

        renderIcon() {
            // conditional icon render based on row data
            if (this.params.renderFunction) {
                return this.params.renderFunction(this.params);
            }
            return true;
        },
        executionErrors() {
            return this.params.data.executionErrors || [];
        },

        forecastingErrors() {
            return this.params.data.forecastingErrors || [];
        },

        promotionIdExists() {
            return !!this.params.data.promotionId;
        },

        inToolValidations() {
            if (!isEmpty(this.params.data.inToolValidations)) {
                return groupBy(this.params.data.inToolValidations, 'severity');
            }

            if (this.promotionIdExists) {
                const notificationKey = notificationTypes.ruleValidationError;
                const notification = this.getPromotionNotifications({
                    promotionId: this.params.data.promotionId,
                    notificationKey,
                });
                if (notification && notification.length) {
                    const ruleValidationTypes = get(
                        notification[0],
                        'details.ruleValidationTypes',
                        []
                    );
                    return groupBy(ruleValidationTypes, 'severity');
                }
            }

            return null;
        },
    },
});
</script>

<style lang="scss" scoped>
.pointer {
    &:hover {
        cursor: pointer;
    }
}
</style>
