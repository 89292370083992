var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.parentPromotionId
    ? _c("child-promotion-ghost-dialog", {
        key: `childPromotionDeclineDialog::${_vm.promotionId}`,
        attrs: { "promotion-id": _vm.promotionId, "is-ghost": _vm.isGhost },
      })
    : _vm.renderIcon
    ? _c("common-delete-dialog", {
        attrs: {
          resource: _vm.resource,
          "resource-id": _vm.resourceId,
          "child-dependencies-warning": false,
          disabled: _vm.isDisabled,
          "is-delete-from-sub-campaign-allocation":
            _vm.isDeleteFromSubCampaignAllocation,
        },
        on: { delete: _vm.onResourceDeleted },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }