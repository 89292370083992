<template>
    <div class="btn-checkbox-wrapper">
        <vuex-checkbox
            :getter="() => isSelected()"
            :setter="value => selectionHandler(value)"
            :indeterminate="indeterminate && !allSelectedInHierarchy"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { last } from 'lodash';

export default Vue.extend({
    computed: {
        indeterminate() {
            if (this.params.data.promotionId) return false;
            const promotionsForHierarchy = this.getPromotionsForHierarchy;
            return (
                !!this.params.selectedIds.length &&
                promotionsForHierarchy.some(id => this.params.selectedIds.includes(id))
            );
        },
        allSelectedInHierarchy() {
            const promotionsForHierarchy = this.getPromotionsForHierarchy;
            return (
                !!this.params.selectedIds.length &&
                promotionsForHierarchy.every(id => this.params.selectedIds.includes(id))
            );
        },
        getPromotionsForHierarchy() {
            const relevantId = last(this.params.data.id.split(':'));
            const promotionsForHierarchy = this.params.promotionsForHierarchy(relevantId);
            return promotionsForHierarchy;
        },
    },
    methods: {
        isSelected() {
            if (this.params.data.promotionId) {
                return this.params.selectedIds.find(id => id === this.params.data.promotionId);
            }
            return this.allSelectedInHierarchy;
        },
        selectionHandler(value) {
            if (this.params.data.promotionId) {
                this.params.selection(this.params.data.promotionId, value);
            } else this.params.hierarchySelection(last(this.params.data.id.split(':')), value);
        },
    },
});
</script>

<style lang="scss" scoped>
// centers the checkbox within it's parent
.btn-checkbox-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // Removes the default 8px margin that comes in v-checkbox
    ::v-deep .rtls-checkbox .v-input--selection-controls__input {
        margin: 0;
    }
}
</style>
