import to from 'await-to-js';
import axios from 'axios';
import {
    filter,
    find,
    groupBy,
    includes,
    isEmpty,
    isEqual,
    isObject,
    map,
    merge,
    uniq,
    flatten,
    get,
    isArray,
    flattenDeep,
    isNil,
} from 'lodash';
import Vue from 'vue';
import namespaces from '@enums/namespaces';
import UXEvents from '@enums/ux-events';
import storeMixin from '@/js/store/mixins/vuex-store';

import i18n from '../../vue-i18n';

const getInitialState = () => ({
    scenarios: [],
    childDependencies: null,
    stagingArea: {},
    filter: {
        scenarioId: null,
    },
    applyAllNotificationsReport: {},
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 * Inherits from the store form mixin which takes care of creating a staging area for creations / updates
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getScenarioById
     * - getFilteredScenarios
     */
    getters: {
        selectedScenarioId: state => state.filter.scenarioId,
        getScenariosBySubCampaignId: state => subCampaignId =>
            filter(state.scenarios, { subCampaignId }),
        selectedScenario: state => find(state.scenarios, { _id: state.filter.scenarioId }),
        hasNoPromotions: (state, getters, rootState, rootGetters) => ({ subCampaignId }) => {
            const scenarioIds = filter(state.scenarios, { subCampaignId }).map(
                scenario => scenario._id
            );

            return isEmpty(rootGetters['promotions/byScenarioIds'](scenarioIds));
        },
        selectedScenarioApplyAllNotificationsReport: state => scenarioId =>
            get(state.applyAllNotificationsReport, scenarioId, {}),
        activeScenariosBySubCampaignId: state => subCampaignId =>
            // Active scenarios are those that have the isFavourite attribute set to true.
            filter(state.scenarios, { subCampaignId, isFavourite: true }),
        scenariosGroupedByCategory: (state, getters, rootState, rootGetters) => subCampaignId => {
            const categoryByKey = rootGetters['hierarchy/getHierarchyByKey'];

            // Group scenarios by their category combinations.
            return groupBy(filter(getters.getFilteredScenarios, { subCampaignId }), scenario => {
                const categoryDescriptions = map(scenario.categories.sort(), category => {
                    return categoryByKey(category) && categoryByKey(category).levelEntryDescription;
                });

                return categoryDescriptions.join(' + ');
            });
        },
        // get all scenario with the same categories combination
        scenariosInCategoryGroup: state => (subCampaignId, categories) => {
            return state.scenarios.filter(
                scenario =>
                    scenario.subCampaignId === subCampaignId &&
                    isEqual(scenario.categories, categories)
            );
        },
        getSelectedScenarioStoreGroupOptions: (state, getters, rootState, rootGetters) => ({
            scenarioId,
        }) => {
            const { storeGroups = [] } = get(state.stagingArea, scenarioId || namespaces.default);

            return storeGroups.map(storeGroup => ({
                reference: storeGroup,
                disabled: isNil(rootGetters['context/userStoreGroupsMap'][storeGroup.key]) || false,
            }));
        },
        getSelectedScenarioResourceOptions: state => ({ scenarioId }) => {
            const { resources = [] } = get(state.stagingArea, scenarioId || namespaces.default);

            return resources.map(resource => ({
                key: resource.type,
                instances: resource.instances,
                clientKey: resource.clientKey,
                disabled: false,
            }));
        },
        getCheckboxListOptions: (state, getters, rootState, rootGetters) => ({
            attributeName,
            attributeKey,
            resource,
            getOptionsFunction,
            attributePathOnChildEntity,
            scenarioId,
            getUserAccessOptionsMap,
        }) => {
            const selectedSubCampaign = rootGetters['subCampaigns/selectedSubCampaign'];

            if (selectedSubCampaign) {
                // Retrieve all promotions associated with the selected scenario.
                const promotions = scenarioId
                    ? rootGetters['promotions/getPromotionsByScenarioId'](scenarioId)
                    : [];

                const resourceOptions = filter(
                    rootGetters[`${resource}/${getOptionsFunction}`],
                    resourceOption => {
                        const selectedResourceOptionsKeys = map(
                            selectedSubCampaign[attributeName],
                            value => (isObject(value) ? value[attributeKey] : value)
                        );
                        return includes(selectedResourceOptionsKeys, resourceOption[attributeKey]);
                    }
                );

                // When any category within a unit is disabled, disable the unit.
                if (attributeName === 'units') {
                    const categoriesOptions = getters.getCheckboxListOptions({
                        scenarioId,
                        resource,
                        attributeKey,
                        getOptionsFunction: 'userCategories',
                        attributeName: 'categories',
                    });

                    return map(resourceOptions, resourceOption => {
                        return {
                            reference: resourceOption,
                            disabled: categoriesOptions.some(
                                option =>
                                    option.disabled &&
                                    option.reference.parentId === resourceOption.levelEntryKey
                            ),
                        };
                    });
                }

                const { isFavourite } = scenarioId
                    ? rootGetters['scenarios/getScenarioById']({
                          _id: scenarioId,
                          usePluralResourceName: true,
                      })
                    : {};

                if (isFavourite && attributeName === 'categories') {
                    return map(resourceOptions, resourceOption => {
                        return {
                            reference: resourceOption,
                            disabled: true,
                        };
                    });
                }

                // Retrieve a list of all keys that have been planned at promotion level.
                const plannedAttributeKeys =
                    attributeName === 'categories'
                        ? rootGetters['products/getProductsCategories'](
                              uniq(flattenDeep(map(promotions, promotion => promotion.products)))
                          )
                        : uniq(
                              flatten(
                                  map(promotions, promotion => {
                                      const attribute = get(promotion, attributePathOnChildEntity);

                                      if (isArray(attribute)) {
                                          return attribute.map(a => a[attributeKey]);
                                      }

                                      return isObject(attribute)
                                          ? attribute[attributeKey]
                                          : attribute;
                                  })
                              )
                          );

                return map(resourceOptions, resourceOption => {
                    const optionKey = resourceOption[attributeKey];
                    return {
                        reference: resourceOption,
                        disabled:
                            // check if user has no access to scenario resource option
                            (getUserAccessOptionsMap &&
                                isNil(
                                    rootGetters[`context/${getUserAccessOptionsMap}`][optionKey]
                                )) ||
                            // check if scenario resource option is selected in child promotions
                            includes(plannedAttributeKeys, optionKey),
                    };
                });
            }

            return [];
        },
        allScenariosIdsBySubCampaignId: (state, getters) => subCampaignId =>
            getters.getScenariosBySubCampaignId(subCampaignId).map(scenario => scenario._id),
        entityWorkflowStateById: (state, getters, rootState, rootGetters) => ({
            id,
            getChildEntities = true,
            getParentEntities = true,
        }) => {
            const scenario = getters.getScenarioById({
                _id: id,
                usePluralResourceName: true,
            });

            const subCampaignState = getParentEntities
                ? rootGetters['subCampaigns/entityWorkflowStateById']({
                      id: get(scenario, 'subCampaignId'),
                      getChildEntities: false,
                  })
                : [];

            const aggregatedPromotionsState = getChildEntities
                ? getters.getChildWorkflowStates({
                      namespace: 'promotions',
                      getter: 'getPromotionsByScenarioId',
                      id,
                  })
                : [];

            return [
                ...subCampaignState,
                ...get(scenario, 'workflowState'),
                ...aggregatedPromotionsState,
            ];
        },
        getSubCampaignIdForEntity: (state, getters) => ({ entityId }) => {
            const scenario = getters.getScenarioById({
                _id: entityId,
                usePluralResourceName: true,
            });

            return scenario ? scenario.subCampaignId : null;
        },

        getPromotionCategoriesByScenarioId: (state, getters, rootState, rootGetters) => ({
            scenarioId,
        }) => {
            const promotions = rootGetters['promotions/getPromotionsByScenarioId'](scenarioId);
            const allCategories = flatten(
                map(promotions, promotion => [
                    ...promotion.categories,
                    ...(promotion.userSelectedCategories || []),
                ])
            );

            return uniq(
                map(allCategories, value => (isObject(value) ? value.levelEntryKey : value))
            );
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setScenarios
     * - deleteScenario
     * - addScenario
     * - updateScenario
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        setSelectedScenarioId(state, scenarioId) {
            Vue.set(state.filter, 'scenarioId', scenarioId);
        },
        setChildDependencies(state, dependencies) {
            state.childDependencies = dependencies;
        },
        setScenarioIsPrivateAttribute(state, { id, isPrivate }) {
            if (state.stagingArea[id]) {
                state.stagingArea[id].isPrivate = isPrivate;
            }
        },
        setScenarioApplyingNotificationsFlag(state, { entityId, value = true }) {
            const scenario = state.scenarios.find(s => s._id === entityId);
            Vue.set(scenario, 'applyingNotifications', value);
        },
        setApplyAllNotificationsReportForScenario(state, { report, scenarioId }) {
            Vue.set(state.applyAllNotificationsReport, scenarioId, report);
        },
    },

    /**
     * Default actions available:
     * - fetchScenarios
     * - deleteScenario
     * - updateScenario
     * - createScenario
     * - submitForm
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        setSelectedScenarioId({ commit }, scenarioId) {
            commit('setSelectedScenarioId', scenarioId);
        },

        clearSelectedScenario({ commit }) {
            commit('setSelectedScenarioId', null);
        },

        toggleSelectedScenario({ state, dispatch }, { scenarioId }) {
            const newScenarioId = scenarioId === state.filter.scenarioId ? null : scenarioId;
            dispatch('setSelectedScenarioId', newScenarioId);
            dispatch('promotions/setSelectedPromotion', { promotionId: null }, { root: true });
        },

        async fetchChildDependencies({ commit }, { id, includeGhosts = false }) {
            let url = `/api/hierarchy/child-dependencies/scenarios/${id}`;
            // when we delete sub-campaign we also need to pick up ghost promotions
            if (includeGhosts) {
                url += `/includeGhosts`;
            }
            const { data: dependencies } = await axios.get(url);
            commit('setChildDependencies', dependencies);
        },

        fetchScenariosForSubCampaign({ rootState, dispatch }, subCampaign) {
            // If we click on subCampaign with the same parent we add scenarios to the store and patch stored ones
            if (subCampaign.campaignId === rootState.subCampaigns.selectedSubCampaignParentId) {
                return dispatch(
                    'scenarios/fetchScenarios',
                    {
                        params: {
                            where: { subCampaignId: subCampaign._id },
                        },
                        // if we already have scenarios stored for this sub-campaign they will be overwritten
                        patchState: true,
                    },
                    { root: true }
                );
            }
            // If we switched to sub-campaign with different parent scenarios will be overwritten.
            return dispatch(
                'scenarios/fetchScenarios',
                {
                    params: {
                        where: { subCampaignId: subCampaign._id },
                    },
                },
                { root: true }
            );
        },

        logEntity({ getters }, { entityId }) {
            console.log(
                `scenario: ${entityId} `,
                getters.getScenarioById({ _id: entityId, usePluralResourceName: true })
            );
        },

        async setFavourite({ dispatch }, { scenarioId, subCampaignId, isFavourite = true }) {
            const [error, response] = await to(
                axios.patch(`/api/scenarios/${scenarioId}/setFavourite`, { isFavourite })
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(`notifications.${isFavourite ? '' : 'un'}favouriteSuccess`, {
                    resource: 'scenario',
                }),
                errorMessage: i18n.t(`notifications.favouriteError`, { resource: 'scenario' }),
            });
            if (error) return { error };

            dispatch('fetchScenarios', {
                params: { where: { subCampaignId } },
            });
            return { result: response.data };
        },

        setScenarioApplyingNotificationsFlag(
            { commit, dispatch },
            { entityId, value = true, updateScenario = true }
        ) {
            commit('setScenarioApplyingNotificationsFlag', { entityId, value });
            if (updateScenario) {
                dispatch('updateScenario', {
                    id: entityId,
                    updates: { applyingNotifications: value },
                });
            }
        },

        async updateScenarioAfterNotificationsApplication(
            { dispatch, getters, rootGetters, commit },
            { entityId: scenarioId, report }
        ) {
            await dispatch(
                'notifications/fetchOpenNotificationsForScenario',
                { scenarioId },
                { root: true }
            );

            const scenario = getters.getScenarioById({
                _id: scenarioId,
                usePluralResourceName: true,
            });

            const subCampaign = rootGetters['subCampaigns/getSubCampaignById']({
                _id: scenario.subCampaignId,
                usePluralResourceName: true,
            });
            commit('setApplyAllNotificationsReportForScenario', { report, scenarioId });
            await dispatch('subCampaigns/refreshSubCampaign', { subCampaign }, { root: true });
            this.$app.globalEmit(UXEvents.allNotificationsAccepted, { scenarioId });
        },

        async acceptAllNotifications({ dispatch, getters }, { entityId: scenarioId }) {
            const scenario = getters.getScenarioById({
                _id: scenarioId,
                usePluralResourceName: true,
            });
            if (scenario.applyingNotifications) {
                return;
            }
            dispatch('setScenarioApplyingNotificationsFlag', { entityId: scenarioId });
            const [error, response] = await to(
                axios.patch(`/api/promotions/acceptChangesForScenarioPromotions/${scenarioId}`)
            );

            dispatch('handleResponseNotifications', {
                error,
                response,
                successMessage: i18n.t(
                    `notifications.successfullyQueuedPromotionsForNotifications`,
                    {
                        count: response.data.count,
                    }
                ),
                errorMessage: i18n.t(`notifications.errorQueuingPromotionsForNotifications`),
            });
            if (error) {
                // just in case of error we also need to remove overlay
                dispatch('setScenarioApplyingNotificationsFlag', {
                    entityId: scenarioId,
                    value: false,
                });
                return { error };
            }
            if (response.data.count === 0) {
                dispatch('setScenarioApplyingNotificationsFlag', {
                    entityId: scenarioId,
                    value: false,
                });
            }
        },
    },
};

const mixinParams = {
    resource: 'scenario',
    useForm: true,
    useFilters: true,
    useNominationMatrix: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
