<template>
    <main-dialog
        ref="dialog"
        heading="planning.promotionsMaintenance.funding.agreementSelector.header"
        btn-confirm-text="actions.select"
        :has-activator="hasActivator"
        :show-negative-action-button="false"
        :confirm-btn-disabled="true"
        @close="closeDialog"
    >
        <template v-slot:default>
            <div class="variable-funding-agreements-container">
                <promo-ag-grid
                    :row-data="variableFundingAgreements"
                    :column-defs="columnDefs"
                    :grid-options="gridOptions"
                    :grid-style="gridStyle"
                    :default-col-def="defaultColDef"
                    @cell-value-changed="() => null"
                />
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { isNil, keyBy, get, set, merge } from 'lodash';

import { calculateProductVariableFunding } from '@sharedModules/funding-utils';
import { groupOfProducts } from '@enums/agreements-applicable';
import agreementDisplayTypes from '@enums/agreement-display-types';
import agreementFunctions from '@enums/invoice-calculation-types';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import { showOnlyMinPrices } from '@enums/feature-flags';
import { toSentenceCase } from '@/js/utils/string-utils';
import { getFieldPathByProductKey } from '@/js/utils/promotion-products-utils';
import promoTabsEnum from '@enums/promotion-tabs';

import AgreementSelectorActions from './agreement-selector-actions';

export default {
    localizationKey: 'planning.promotionsMaintenance.funding.agreementSelector',
    props: {
        supplierKey: {
            type: Number,
            required: false,
        },
        categoryKey: {
            type: String,
            required: false,
        },
        promotion: {
            type: Object,
            required: false,
        },
        products: {
            type: Array,
            required: false,
        },
        hasActivator: {
            type: Boolean,
            default: true,
        },
        namespace: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            gridOptions: {
                context: {
                    componentParent: this,
                },
                rowHeight: 35,
                paginationAutoPageSize: true,
                pagination: true,
            },
            gridStyle: 'width: 100%; height: 100%;',
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                flex: 1,
                resizable: true,
                filter: true,
                minWidth: 150,
            },
        };
    },
    computed: {
        ...mapGetters('clientConfig', ['getHierarchyConfig']),
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapGetters('variableFundingAgreements', ['getVariableFundingAgreementById']),
        ...mapState('variableFundingAgreements', ['variableFundingAgreements']),
        ...mapState('clientConfig', ['toggleLogic']),

        columnDefs() {
            return [
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.mechanism'))}`,
                    field: 'mechanic',
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.agreement'))}`,

                    valueGetter: params => {
                        const agreementType = params.data.agreementType;

                        return toSentenceCase(
                            this.$t(`variableFundingAgreements.agreementTypes.${agreementType}`)
                        );
                    },
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.function'))}`,

                    valueGetter: params => {
                        const agreementFunction =
                            params.data.agreementFunction || agreementFunctions.noInvoice;

                        return toSentenceCase(
                            this.$t(
                                `variableFundingAgreements.agreementFunctions.${agreementFunction}`
                            )
                        );
                    },
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.products'))}`,
                    valueGetter: params => {
                        const applicableTo = params.data.applicableTo;

                        return toSentenceCase(
                            this.$t(`variableFundingAgreements.applicableTo.${applicableTo}`)
                        );
                    },
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.productScope'))}`,
                    field: 'scope.description',
                },
                {
                    headerName: `${toSentenceCase(this.$tkey('table.headers.value'))}`,
                    valueGetter: params => {
                        const value = params.data.scope.value;

                        return isNil(value)
                            ? this.$t('placeholders.noText')
                            : this.$n(
                                  `numbers.default.${
                                      agreementDisplayTypes[params.data.agreementType]
                                          .numberFormatter.type
                                  }`,
                                  value
                              );
                    },
                },
                {
                    headerName: '',
                    cellRendererFramework: AgreementSelectorActions,
                    cellRendererParams: {
                        onSelectClicked: async selectedAgreementId => {
                            await this.addAgreement(selectedAgreementId);
                            this.closeDialog();
                        },
                    },
                    minWidth: 120,
                    width: 120,
                    cellClass: 'flex-cell-center',
                },
            ];
        },
        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },
    },
    methods: {
        ...mapActions('variableFundingAgreements', [
            'fetchVariableFundingAgreements',
            'resetVariableFundingAgreementsState',
        ]),
        ...mapActions('promotions', ['setStagingAreaFields']),
        ...mapMutations('promotions', ['setUnsavedPromotion']),
        async openDialog() {
            await this.loadVariableFundingAgreements();

            this.$refs.dialog.open();
        },
        closeDialog() {
            this.resetVariableFundingAgreementsState();
            this.$refs.dialog.close();
        },
        loadVariableFundingAgreements() {
            const categoryLevel = this.getHierarchyConfig.categoryLevel;
            const selectedPromotion = this.getStagingAreaPromotionById(this.namespace);

            const dateRange = {};
            if (selectedPromotion.startDate) {
                dateRange.startDate = {
                    $lte: this.promotion.startDate,
                };
            }
            if (selectedPromotion.endDate) {
                dateRange.endDate = {
                    $gte: this.promotion.endDate,
                };
            }

            return this.fetchVariableFundingAgreements({
                params: {
                    where: {
                        hierarchy: {
                            $elemMatch: {
                                level: categoryLevel,
                                levelEntryKey: this.categoryKey,
                            },
                        },
                        'supplier.supplierKey': this.supplierKey,
                        ...dateRange,
                    },
                    pick: [
                        '_id',
                        'agreementType',
                        'agreementFunction',
                        'applicableTo',
                        'mechanic',
                        'scope',
                        'sellInPeriod',
                    ].map(field => `{ "${field}": 1}`),
                },
            });
        },
        async addAgreement(selectedAgreementId) {
            // Find the agreement which has been selected.
            const selectedAgreement = this.getVariableFundingAgreementById({
                _id: selectedAgreementId,
                usePluralResourceName: true,
            });

            if (!selectedAgreement) {
                throw new Error(
                    `Variable funding agreement for id ${selectedAgreementId} could not be found.`
                );
            }

            const isGrouped = selectedAgreement.applicableTo === groupOfProducts;
            const keyedProductValues = !isGrouped
                ? keyBy(selectedAgreement.scope.products, 'productKey')
                : {};

            // Generate an array of product updates which can be applied to the staging area
            // at the same time.
            const productUpdates = this.products.map(product => {
                const { productKey, onInvoiceCosts, promoPrices, volumes, funding } = product;
                const fieldPath = getFieldPathByProductKey({
                    productKey,
                    path: 'funding',
                    promotion: this.promotion,
                });

                const currentVariableFunding = funding.variableFunding;

                // If the agreement is grouped, then the scope will have just a single value which is applied to all
                // products. If not grouped, scope will contain an array of products with a value for each.
                const agreementValue = isGrouped
                    ? selectedAgreement.scope.value
                    : get(keyedProductValues[productKey], 'value', null);

                const newProductVariableFunding = {
                    uiFields: {
                        onInvoiceValue: null,
                        onInvoiceType: null,
                        offInvoiceValue: null,
                        offInvoiceType: null,
                    },
                    buyingPrice: null,
                    supplierCompensation: null,
                    sellInPeriod: {
                        daysBefore: selectedAgreement.sellInPeriod.daysBefore,
                        daysAfter: selectedAgreement.sellInPeriod.daysAfter,
                    },
                };

                if (selectedAgreement.agreementType) {
                    set(
                        newProductVariableFunding,
                        agreementDisplayTypes[selectedAgreement.agreementType].path,
                        agreementValue
                    );
                }

                if (selectedAgreement.agreementFunction) {
                    set(
                        newProductVariableFunding,
                        agreementDisplayTypes[selectedAgreement.agreementType].typePath,
                        selectedAgreement.agreementFunction
                    );
                }

                const {
                    unitFunding,
                    buyingPrice,
                    supplierCompensation,
                    uiFields,
                } = calculateProductVariableFunding({
                    product: {
                        onInvoiceCosts,
                        promoPrices,
                        funding: {
                            variableFunding: newProductVariableFunding,
                        },
                        volumes,
                        standardWeight: product.standardWeight,
                    },
                    featureAwareFactory: this.featureAwareFactory,
                    showOnlyMinimumPrices: this.toggleLogic[showOnlyMinPrices],
                });
                newProductVariableFunding.unitFundingValue = unitFunding;
                newProductVariableFunding.uiFields = uiFields;

                if (!isNil(get(newProductVariableFunding, 'uiFields.onInvoiceValue'))) {
                    newProductVariableFunding.buyingPrice = buyingPrice;
                }

                if (!isNil(get(newProductVariableFunding, 'uiFields.offInvoiceValue'))) {
                    newProductVariableFunding.supplierCompensation = supplierCompensation;
                }

                const updatedVariableFunding = merge(
                    {},
                    currentVariableFunding,
                    newProductVariableFunding
                );

                return {
                    fieldPath,
                    fieldName: 'variableFunding',
                    value: updatedVariableFunding,
                };
            });

            await this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate: productUpdates,
            });
            this.setUnsavedPromotion({
                namespace: this.namespace,
                tab: promoTabsEnum.funding,
                value: true,
            });
            this.resetVariableFundingAgreementsState();
        },
    },
};
</script>

<style lang="scss" scoped>
.variable-funding-agreements-container {
    height: 30rem;
    width: 100rem;

    &::v-deep {
        .flex-cell-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
