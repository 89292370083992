<template>
    <div class="expand-collapse">
        <v-btn class="expand-collapse__btn" text @click="expandAll">
            <icon class="expand-collapse__btn--icon" icon-name="expand-all" />
            {{ $t('subCampaignAllocation.expandCollapse.expandAll') | toSentenceCase }}
        </v-btn>
        <v-btn class="expand-collapse__btn" text @click="collapseAll">
            <icon class="expand-collapse__btn--icon" icon-name="collapse-all" />
            {{ $t('subCampaignAllocation.expandCollapse.collapseAll') | toSentenceCase }}
        </v-btn>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    methods: {
        expandAll() {
            if (!this.params.expandAll) return;
            this.params.expandAll();
        },
        collapseAll() {
            if (!this.params.collapseAll) return;
            this.params.collapseAll();
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.expand-collapse {
    display: flex;
    &__btn {
        color: $promo-primary-colour;
        text-decoration: underline;

        ::v-deep {
            .v-btn__content {
                font-size: 1.4rem;

                .v-icon {
                    padding-top: 0.2rem;
                    padding-right: 0.4rem;
                }
            }
        }
    }
}
</style>
