var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workflow-task-alert-list" },
    [
      _c("div", { staticClass: "workflow-task-alert-list__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(_vm.$t("workflow.taskAlertList.header"))
            ) +
            "\n\n        "
        ),
        _c(
          "div",
          { staticClass: "alert-count-badges" },
          _vm._l(
            _vm.alertsGroupedByConfiguredCategories,
            function (alertsGroup) {
              return _c(
                "div",
                { key: alertsGroup.key[0] },
                [
                  _c("alert-count-badge", {
                    attrs: {
                      priority: alertsGroup.key[0],
                      "alert-count": alertsGroup.alerts.length,
                    },
                  }),
                ],
                1
              )
            }
          ),
          0
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.alerts, function (alert) {
        return _c(
          "div",
          {
            key: `${alert.subCampaignId}::${alert.task}::${alert.entity}}::${alert.hierarchyGroup}`,
            staticClass: "workflow-task-alert-list__item",
          },
          [
            _c(
              "workflow-task-alert",
              _vm._b(
                {
                  on: {
                    "sidebar-closed": function ($event) {
                      return _vm.$emit("sidebar-closed")
                    },
                  },
                },
                "workflow-task-alert",
                alert,
                false
              )
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }