<template functional>
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Workflow open cross</title>
        <g id="simple-UPDATE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Workflow-4-1-A-01" transform="translate(-1297.000000, -551.000000)">
                <g id="Success-/-Tick" transform="translate(1297.000000, 551.000000)">
                    <circle id="Combined-Shape" stroke="#C13939" cx="8" cy="8" r="7.5" />
                    <polygon
                        id="Path"
                        fill="#C13939"
                        points="11.5 10.513 10.513 11.5 8 8.987 5.487 11.5 4.5 10.513 7.013 8 4.5 5.487 5.487 4.5 8 7.013 10.513 4.5 11.5 5.487 8.987 8"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'WorkflowOpenCross',
};
</script>
