<template>
    <div class="flex-row">
        <v-text-field
            class="rtls-text-field rtls-text-field--white product-offer-group__filter-bar"
            :placeholder="$tkey('filterAllRows')"
            append-icon="mdi-magnify"
            @input="callInputFunc"
        />
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.offer.offerGroup',
    methods: {
        callInputFunc(event) {
            this.params.inputFunc(event);
        },
    },
});
</script>

<style lang="scss" scoped>
.product-offer-group__filter-bar {
    margin-left: 1rem;
    ::v-deep {
        .v-input__slot {
            width: 30rem;
        }
    }
}
</style>
