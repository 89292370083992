import {
    keyBy,
    map,
    merge,
    uniq,
    filter,
    isNil,
    isEmpty,
    get,
    cloneDeep,
    size,
    unionBy,
} from 'lodash';
import to from 'await-to-js';
import axios from 'axios';
import i18n from '@/js/vue-i18n';

import storeMixin from '@/js/store/mixins/vuex-store';
import { isProductMatchSupplierAndCategory } from '@/js/utils/products-utils';

const getInitialState = () => ({
    products: [],
    brands: [],
    suppliers: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */

    state: {
        ...getInitialState(),
        productsWithMinimalData: [],
        productsForPromotionProductOfferGroups: {},
    },

    /**
     * Default getters available:
     * - getProductById
     * - getFilteredProducts
     */
    getters: {
        getProductsCategories: () => products => {
            return uniq(map(products, 'category'));
        },

        productByKeyMap: state => keyBy(state.products, 'productKey'),
        getProductByKey: (state, getter) => productKey => getter.productByKeyMap[productKey],
        getProductsBySupplierAndCategory: (state, getters, rootState, rootGetters) => ({
            supplierKey,
            categoryKeys,
        }) => {
            const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];
            const filteredProducts = filter(state.products, product =>
                isProductMatchSupplierAndCategory({
                    product,
                    supplierKey,
                    categoryKeys,
                    categoryLevel,
                })
            );
            return map(filteredProducts, product => ({
                ...product,
                displayLabel: product.isProposed
                    ? product.name
                    : `${product.productKey} — ${product.name}`,
            }));
        },
        getBrands: state => state.brands,
        getSuppliers: state => state.suppliers,
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setProducts
     * - deleteProduct
     * - updateProduct
     * - addProduct
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {
        setProductBrands(state, brands) {
            state.brands = brands;
        },
        setProductSuppliers(state, suppliers) {
            state.suppliers = suppliers;
        },
        addProductsWithMinimalData(state, productsWithMinimalData) {
            state.productsWithMinimalData = productsWithMinimalData;
        },
        setProductsForPromotionProductOfferGroups(state, { productOfferGroupId, products }) {
            if (!productOfferGroupId) {
                state.productsForPromotionProductOfferGroups = {};
            } else {
                state.productsForPromotionProductOfferGroups = {
                    ...state.productsForPromotionProductOfferGroups,
                    [productOfferGroupId]: products,
                };
            }
        },
    },

    /**
     * Default actions available:
     * - fetchProducts
     * - deleteProduct
     * - updateProduct
     * - updateProducts
     * - createProduct
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {
        fetchProductsBySupplierAndCategory({ rootState, dispatch }, { supplier, categories }) {
            if (isNil(supplier) || isEmpty(categories)) {
                return;
            }
            return dispatch('fetchProducts', {
                params: {
                    where: {
                        supplierKey: supplier,
                        hierarchy: {
                            $elemMatch: {
                                level: rootState.clientConfig.hierarchyConfig.categoryLevel,
                                levelEntryKey: { $in: categories },
                            },
                        },
                    },
                },
                patchState: true,
                patchOptions: {
                    fieldToMatchOn: '_id',
                    isMatchFunction: (
                        product,
                        {
                            supplierKey,
                            hierarchy: {
                                $elemMatch: {
                                    level: categoryLevel,
                                    levelEntryKey: { $in: categoryKeys },
                                },
                            },
                        }
                    ) =>
                        isProductMatchSupplierAndCategory({
                            product,
                            supplierKey,
                            categoryKeys,
                            categoryLevel,
                        }),
                },
            });
        },
        async fetchProductBrands({ commit, dispatch }, { params = {} } = {}) {
            const [error, response] = await to(axios.get(`/api/products/brands`, { params }));

            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', { resource: 'brands' }),
                });

                return;
            }

            commit('setProductBrands', response.data);
        },

        async fetchProductBrandsByCategory({ dispatch, rootState }) {
            const selectedCategory = rootState.promotionCandidates.selectedCategory;
            if (!isNil(selectedCategory)) {
                return dispatch('fetchProductBrands', {
                    params: {
                        where: {
                            hierarchy: {
                                $elemMatch: {
                                    level: rootState.clientConfig.hierarchyConfig.categoryLevel,
                                    levelEntryKey: selectedCategory,
                                },
                            },
                        },
                    },
                });
            }
        },

        async fetchProductSuppliers({ commit, dispatch }) {
            const [error, response] = await to(axios.get(`/api/products/suppliers`));

            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: i18n.tc('entities.suppliers', 2),
                    }),
                });

                return;
            }
            commit('setProductSuppliers', response.data);
        },
        async fetchProductsWithMinimalData(
            { commit, dispatch },
            { query, returnDocuments = false, storeDocuments = false } = {}
        ) {
            const pick = [
                '{ "_id": 1 }',
                '{ "productKey": 1 }',
                '{ "name": 1 }',
                '{ "category": 1 }',
                '{ "clientProductKey": 1 }',
                '{ "brandDescription": 1 }',
                '{ "supplierName": 1 }',
                '{ "standardWeight": 1 }',
                '{ "packSize": 1 }',
                '{ "isUnitProduct": 1 }',
            ];
            let params = {};
            if (query) {
                params = {
                    where: query,
                    pick,
                };
            } else {
                params = {
                    pick,
                };
            }
            const [error, response] = await to(
                axios.get('/api/products/promotable-products', { params })
            );
            if (error) {
                dispatch('handleResponseNotifications', {
                    error,
                    errorMessage: i18n.t('notifications.fetchError', {
                        resource: 'promotion-candidates',
                    }),
                });
            }

            if (storeDocuments) {
                commit('addProductsWithMinimalData', response.data);
            }
            if (returnDocuments) {
                return response.data;
            }
        },

        async fetchProductsAndAttributesForPromotionProductOfferGroup(
            { commit, dispatch },
            { productOfferGroup, attributes, returnDocuments = false, storeDocuments = false }
        ) {
            commit('setLoading', true);
            let productsForPromotion = await dispatch(
                'fetchProductsForPromotionProductOfferGroup',
                { productOfferGroup, returnDocuments: true, storeDocuments }
            );

            const productOfferGroupId = productOfferGroup._id;

            if (size(productsForPromotion) && size(attributes)) {
                productsForPromotion = await dispatch('fetchProductAttributesForPromotion', {
                    productOfferGroupId,
                    attributes,
                    productsForPromotion,
                    returnDocuments: true,
                });
            }

            if (storeDocuments) {
                commit('setProductsForPromotionProductOfferGroups', {
                    productOfferGroupId,
                    products: productsForPromotion,
                });
            }
            commit('setLoading', false);
            if (returnDocuments) {
                return productsForPromotion;
            }
        },

        resetProductsForPromotionProductOfferGroup({ commit }, { productOfferGroup }) {
            commit('setProductsForPromotionProductOfferGroups', {
                productOfferGroupId: productOfferGroup._id,
                products: [],
            });
        },

        async fetchProductAttributesForPromotion(
            { state, commit, dispatch },
            {
                productOfferGroupId,
                attributes,
                productsForPromotion,
                returnDocuments = false,
                storeDocuments = false,
            } = {}
        ) {
            if (!productsForPromotion) {
                productsForPromotion = cloneDeep(
                    state.productsForPromotionProductOfferGroups[productOfferGroupId]
                );
            }

            // Determine the list of categories that we require.
            const productCategories = uniq(map(productsForPromotion, 'category'));

            // Identify which attributes we already have data for by comparing the list
            // of attributes on each product and taking the intersection across all products.
            const attributesAcrossProducts = map(
                productsForPromotion,
                product => new Set(map(product.attributes || [], 'attributeKey'))
            );

            const requiredAttributes = attributes.filter(
                attribute =>
                    // Require attribute if we do not already have it for each product
                    !attributesAcrossProducts.every(productSet => productSet.has(attribute))
            );

            if (productCategories.length && requiredAttributes.length) {
                const params = {
                    where: {
                        requiredCategories: productCategories,
                        attributes: requiredAttributes,
                    },
                };
                commit('setLoading', true);
                const [error, response] = await to(
                    axios.get('/api/products/product-attributes', {
                        params,
                    })
                );
                if (error) {
                    dispatch('handleResponseNotifications', {
                        error,
                        errorMessage: i18n.t('notifications.fetchError', {
                            resource: 'promotion-candidates',
                        }),
                    });
                }

                const productAttributes = response.data;
                const keyedProductAttributes = keyBy(productAttributes, 'productKey');

                productsForPromotion.forEach(product => {
                    if (keyedProductAttributes[product.productKey]) {
                        product.attributes = unionBy(
                            product.attributes,
                            keyedProductAttributes[product.productKey].attributes,
                            'attributeKey'
                        );
                    }
                });

                if (storeDocuments) {
                    commit('setProductsForPromotionProductOfferGroups', {
                        productOfferGroupId,
                        products: productsForPromotion,
                    });
                }
                commit('setLoading', false);
            }

            if (returnDocuments) {
                return productsForPromotion;
            }
        },

        async fetchProductsForPromotionProductOfferGroup(
            { commit, dispatch },
            { productOfferGroup, returnDocuments = false, storeDocuments = false } = {}
        ) {
            // Get the categories this promotion is for
            const productOfferGroupCategories = get(
                productOfferGroup,
                'userSelectedCategories',
                []
            );

            // Exit early if a categories have not been selected yet.
            if (!productOfferGroupCategories.length) return [];

            const userSelectedCategories = productOfferGroupCategories.map(
                ({ levelEntryKey }) => levelEntryKey
            );

            const productsAvailableForPromotion = await dispatch('fetchProductsWithMinimalData', {
                query: { userSelectedCategoryIds: userSelectedCategories },
                returnDocuments: true,
                storeDocuments,
            });

            if (storeDocuments) {
                commit('setProductsForPromotionProductOfferGroups', {
                    productOfferGroupId: productOfferGroup._id,
                    products: productsAvailableForPromotion,
                });
            }

            if (returnDocuments) {
                return productsAvailableForPromotion;
            }
        },
    },
};

const mixinParams = {
    resource: 'product',
    useForm: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
