<template>
    <div>
        <!-- POC code - No need for translations as it will be changed in upcoming tickets -->
        <div class="ma-1 mr-3">
            <main-dialog
                ref="bookmark-modal"
                heading="bookmarks.heading"
                :show-positive-action-button="false"
                :persistent="false"
                :has-activator="false"
            >
                <template v-slot:default>
                    <bookmarks @reset="$router.go()" />
                </template>
            </main-dialog>
        </div>
        <div id="powerbi-container" />
        <confirm-dialog
            ref="clicked-promotion-dialog"
            :has-activator="false"
            :question-text="`You clicked on promotion: ${promotionToView}`"
            action-text="ok"
        />
    </div>
</template>

<script>
import { canEditBookmarks, reportFilters } from '@enums/feature-flags';
import severityEnums from '@enums/severity';
import { get, isEmpty } from 'lodash';
// eslint-disable-next-line no-unused-vars
import * as pbi from 'powerbi-client'; // We need to import the pbi client like this
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import userRoles from '@enums/user-roles';
import navigation from '@/js/navigation';

const models = pbi.models;

export default {
    data() {
        return {
            canEditBookmarks,
            report: null,
            reportRendered: false,
            promotionToView: null,
            // For a full list of settings available, see: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings
            defaultReportConfig: {
                type: 'report',
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            visible: false, // must be false otherwise users can remove their category filter
                        },
                        pageNavigation: {
                            visible: false,
                        },
                    },
                },
            },
            tokenExpiration: {
                expirationTime: null,
                checkFrequency: 1 * 60 * 1000, // Check every 1 minute
                timeToUpdateBeforeExpiration: 30 * 60 * 1000, // Refresh embed token 30 minutes before expiry
            },
        };
    },

    computed: {
        ...mapState('clientConfig', ['hierarchyConfig', 'toggleLogic']),
        ...mapGetters('context', [
            'hasRole',
            'userRDWCategoryKeys',
            'userCategoryValues',
            'userStoreGroups',
            'userCategoriesDescription',
            'userBrandsDescription',
            'userChannelsDescription',
        ]),
        ...mapGetters('bookmarks', ['getBookmarkById']),
    },

    watch: {
        async $route() {
            await this.loadBookmarkFromUrl();
            this.$refs['bookmark-modal'].closeDialog();
        },
    },

    async mounted() {
        const embedToken = await this.updateToken();
        const reportContainer = document.getElementById('powerbi-container');
        // eslint-disable-next-line no-undef
        powerbi.bootstrap(reportContainer, {
            type: 'report',
            embedUrl: `${embedToken.embedUrl[0].embedUrl}&language=en&formatLocale=en-US`,
        });

        // Create a config object with type of the object, Embed details and Token Type
        const reportLoadConfig = {
            ...this.defaultReportConfig,

            accessToken: embedToken.accessToken,
            // Use other embed report config based on the requirement. We have used the first one for demo purpose
            embedUrl: `${embedToken.embedUrl[0].embedUrl}&language=en&formatLocale=en-US`,

            // ensure users only see data for categories they are assigned to
            filters: this.getFilters(),
        };

        // Embed Power BI report when Access token and Embed URL are available
        // eslint-disable-next-line no-undef
        this.report = powerbi.embed(reportContainer, reportLoadConfig);

        // Use the token expiry to regenerate Embed token for seamless end user experience
        // Refer https://aka.ms/RefreshEmbedToken
        setInterval(() => this.checkTokenAndUpdate(), this.tokenExpiration.checkFrequency);

        // TODO: create ticket and implement custom data selection notifcations
        // this.report.on('dataSelected', event => {
        //     // Example of handling a dataSelected event
        //     // More info: https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/2530115749/Events+Buttons
        //     this.handleDataClickedEvent(event);
        // });

        this.report.on('rendered', async () => {
            // load bookmark state from url on first render
            if (!this.reportRendered) await this.loadBookmarkFromUrl();

            this.reportRendered = true;
        });
        this.report.on('buttonClicked', async event => {
            this.handleButtonClickedEvent(event);
        });
    },

    methods: {
        ...mapMutations('bookmarks', ['setCurrentReportState']),
        ...mapActions('reporting', ['fetchEmbedToken']),
        ...mapActions('notifications', ['addNotification']),
        handleButtonClickedEvent(event) {
            if (event.detail.title === 'BookmarksButton') {
                this.saveBookmark();
                this.$refs['bookmark-modal'].open();
            }
        },
        /**
         * This function is likely to change as it is just a POC.
         * More info on it can be found here: https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/2530115749/Events+Buttons
         */
        handleDataClickedEvent(event) {
            const dataPoints = get(event, 'detail.dataPoints');
            if (!dataPoints) return;
            let promotionData;
            dataPoints.forEach(dp => {
                const promotionKeyDetails = dp.values.find(
                    ({ target }) =>
                        target.table === 'DimPromotion' && target.column === 'PromotionKey'
                );
                if (!promotionKeyDetails) return;
                promotionData = promotionKeyDetails;
                this.promotionToView = promotionData.value;
                this.$refs['clicked-promotion-dialog'].open();
            });
        },
        getFilters() {
            // admins see all categories, no need for filter
            if (this.hasRole(userRoles.admin) || this.hasRole(userRoles.adminReportingUser)) {
                return [];
            }
            return this.toggleLogic[reportFilters].reduce((acc, filter) => {
                const columnValue =
                    filter.column ||
                    filter.columnTemplate.replace(
                        '{categoryLevel}',
                        this.hierarchyConfig.categoryLevel
                    );

                let values;
                if (filter.valueTransformation === 'userStoreGroupKeys') {
                    values = this.userStoreGroups.map(obj => obj.key);
                } else {
                    values = this[filter.valueTransformation];
                }
                // if value is [] it means that for this type user has full access and no need send filter
                // and we can skip next action
                if (!isEmpty(values)) {
                    acc.push({
                        $schema: filter.schema,
                        target: {
                            table: filter.table,
                            column: columnValue,
                        },
                        operator: filter.operator,
                        values,
                        filterType: filter.filterType,
                    });
                }
                return acc;
            }, []);
        },
        async saveBookmark() {
            const bookmarkToSave = await this.report.bookmarksManager.capture({
                allPages: true,
                personalizeVisuals: true,
            });
            this.setCurrentReportState(bookmarkToSave.state);
        },
        async loadBookmarkFromUrl() {
            const bookmarkId = get(this.$route, 'params.bookmarkId');
            if (!bookmarkId) {
                return false;
            }

            const bookmark = this.getBookmarkById({ _id: bookmarkId, usePluralResourceName: true });
            if (!bookmark) {
                this.addNotification({
                    message: `${this.$t('bookmarks.notifications.notFound')} ${bookmarkId}`,
                    severity: severityEnums.error,
                });
                // redirect to reports (remove bad bookmark id from url)
                navigation.toReporting();
                return false;
            }

            await this.report.bookmarksManager.applyState(bookmark.state);
            this.addNotification({
                message: `${this.$t('bookmarks.notifications.loaded')}`,
                severity: severityEnums.success,
            });
            return true;
        },
        checkTokenAndUpdate() {
            // Time until token expiration in milliseconds
            const timeUntilExpiration = this.tokenExpiration.expirationTime - Date.now();

            // Update the token if it is about to expired
            if (timeUntilExpiration <= this.tokenExpiration.timeToUpdateBeforeExpiration) {
                this.updateToken();
            }
        },
        async updateToken() {
            // Generate a new embed token or refresh the user Azure AD access token
            const embedToken = await this.fetchEmbedToken();

            // Update the new token expiration time
            if (this.report != null) {
                await this.report.setAccessToken(embedToken.accessToken);
                this.tokenExpiration.expirationTime = Date.parse(embedToken.expiry);
            }

            return embedToken;
        },
    },
};
</script>

<style scoped lang="scss">
#powerbi-container {
    height: 87vh;
}
</style>

<style lang="scss">
iframe {
    border: none;
}
</style>
