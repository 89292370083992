var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "footer-renderer" },
    [
      _c(
        "div",
        {
          staticClass: "total",
          class: {
            valid: _vm.isValid && _vm.selectedRateCardIdForSplitting,
            invalid: !_vm.isValid && _vm.selectedRateCardIdForSplitting,
          },
        },
        [
          _c("vuex-currency-input", {
            attrs: {
              getter: () => _vm.formattedValue(_vm.lumpSumTotal),
              disabled: true,
              filled: "",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "simple-button",
        {
          attrs: { disabled: _vm.isAddTotalDisabled },
          on: {
            onClick: function ($event) {
              return _vm.addToTotal()
            },
          },
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.$tkey("buttons.addToTotal")) + "\n    "
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }