<template>
    <main-dialog
        ref="dialog"
        :translated-header="$tkey(`${localizationKey}.title`)"
        :has-activator="hasActivator"
        show-negative-action-button
        :show-positive-action-button="false"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <div class="details-btn">
                <v-btn
                    :disabled="isSelectedPromotionCategoryOrStoreWide"
                    secondary
                    depressed
                    outlined
                    v-on="activator"
                    @click="openDialog"
                >
                    <b>{{ $tkey(`${localizationKey}.activatorButton`) | toSentenceCase }}</b>
                </v-btn>
            </div>
        </template>
        <template v-slot:default>
            <div class="forecast-details-container">
                <div class="forecast-details-container__filters">
                    <div class="forecast-details-container__filters--label">
                        {{ $tkey(`${localizationKey}.filters.storeGroupsLabel`) | toSentenceCase }}:
                    </div>
                    <vuex-select
                        class="forecast-details-container__filters--select"
                        :getter="() => filter.storeGroups"
                        :setter="value => setStoreGroupsFilter(value)"
                        :options="storeGroupsFilterOptions"
                        :placeholder="
                            $tkey(`${localizationKey}.filters.storeGroupsPlaceholder`)
                                | toSentenceCase
                        "
                        item-text="name"
                        item-value="key"
                        multiple
                        chips
                        deletable-chips
                        clearable
                    />
                </div>

                <div class="waterfall-charts">
                    <div
                        v-for="chart in getChartsConfig({ type: 'waterfall' })"
                        :key="'forecasting-highchart-' + chart.name"
                        class="chart-container"
                    >
                        <waterfall-chart
                            :chart-config="chart"
                            :localization-key="localizationKey"
                            :getter="() => totals"
                        />
                    </div>
                </div>
                <promo-ag-grid
                    :row-data="rowData"
                    :column-defs="columnDefs"
                    :default-col-def="defaultColDef"
                    :grid-options="gridOptions"
                    grid-style="width: 150rem; height: 44rem"
                    grid-class="ag-theme-custom__product-details"
                />
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { includes, isEmpty, sumBy, get, isNil, uniqBy } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';

import vuexComponentMixin from '@/js/mixins/vuex-component';
import configDrivenGridComponentMixin from '@/js/mixins/config-driven-grid-component';
import productDetailsMixin from './product-details-mixin';

export default {
    mixins: [vuexComponentMixin, configDrivenGridComponentMixin, productDetailsMixin],
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialogContext: 'forecastDetails',
            localizationKey: 'forecastDetailsModal',
            filter: { storeGroups: [] },
            filteredProducts: [],
            displayedProducts: [],
            gridOptions: {
                groupIncludeTotalFooter: true, // total footer containing sum of any KPI columns
                columnTypes: {
                    forecastField: {
                        sortable: true,
                        minWidth: 94,
                        maxWidth: 94,
                        headerClass: 'product-information__metrics',
                        cellClass: ['product-information', 'product-information__metrics'],
                        cellClassRules: {
                            'product-information--footer': params => params.node.footer,
                        },
                        aggFunc: 'sum',
                        valueGetter: this.valueGetter,
                    },
                },
            },
        };
    },

    computed: {
        ...mapGetters('storeGroups', ['storeGroupsMapByClientKey']),
        ...mapGetters('promotions', ['isSelectedPromotionCategoryOrStoreWide']),
        ...mapState('clientConfig', ['highchartsConfig', 'releaseFlags']),

        rowData() {
            return this.filteredProducts;
        },
        storeGroupsFilterOptions() {
            const allStoreGroups = uniqBy(
                this.products.flatMap(product => {
                    if (product.forecastingAggregations) {
                        return product.forecastingAggregations.storeGroups || [];
                    }

                    return [];
                }),
                'storeGroupKey'
            );

            return allStoreGroups.map(sg => {
                const storeGroup = this.storeGroupsMapByClientKey[sg.storeGroupKey];
                return {
                    name: storeGroup.description,
                    key: storeGroup.clientStoreGroupKey,
                };
            });
        },

        // Total sum of the filtered products KPIs to be displayed in the charts
        totals() {
            if (isEmpty(this.displayedProducts)) return [];
            const aggregations = this.displayedProducts.flatMap(p =>
                get(p, 'forecastingAggregations.storeGroupFiltered', {})
            );
            // Aggregate filtered metrics to promotion level based on filtered storeGroups
            return this.sumByFields(aggregations);
        },
    },

    watch: {
        products: {
            immediate: true,
            handler() {
                this.filterProducts();
            },
        },
    },

    created() {
        this.setGridColumns();
    },

    methods: {
        setStoreGroupsFilter(value) {
            this.filter.storeGroups = value;
            this.filterProducts();
        },
        setGridColumns() {
            this.columnDefs = this.getForecastColumns();
        },

        getFooterCellRenderer(params) {
            const totalRowPrefix = toSentenceCase(
                this.$tkey(`${this.localizationKey}.totalRowLabel.prefix`)
            );
            const totalRowSuffix = this.$tkey(`${this.localizationKey}.totalRowLabel.suffix`);

            if (params.node.footer) {
                // Display totals label if footer row
                return `<b>${totalRowPrefix}</b> ${totalRowSuffix}`;
            }
            if (params.value) {
                return `<b>${params.value}</b>`;
            }

            return null;
        },

        getGridColumnGroups() {
            return this.getGridConfig({ context: this.dialogContext }).columns.filter(col =>
                get(this.releaseFlags.releaseFlags, col.releaseFlag, true)
            );
        },

        getGridChildColumns() {
            return this.getGridColumnGroups()
                .filter(columns => columns.children)
                .flatMap(columns => columns.children);
        },

        getForecastColumns() {
            const columnGroups = this.getGridColumnGroups();

            return columnGroups.map(group => {
                return {
                    ...group,
                    headerName: group.headerName
                        ? toSentenceCase(this.$tkey(`headers.${group.headerName}`))
                        : undefined,
                    ...(group.valueGetter
                        ? {
                              valueGetter: this[group.valueGetter],
                          }
                        : {}),
                    ...(group.cellRendererFramework
                        ? {
                              cellRendererFramework: this.getCellRendererFramework(
                                  group.cellRendererFramework
                              ),
                          }
                        : {}),
                    ...(group.cellRenderer
                        ? {
                              cellRenderer: this[group.cellRenderer],
                          }
                        : {}),
                    ...(group.menuTabs ? group.menuTabs.map(x => x) : {}),
                    ...(group.children
                        ? {
                              children: group.children
                                  .filter(field =>
                                      get(this.releaseFlags.releaseFlags, field.releaseFlag, true)
                                  )
                                  .map((field, i) => {
                                      const valueFormatter = this.getCellFormatter(field.format);
                                      const fieldName = field.fieldName;
                                      const fieldPath = field.source;

                                      return {
                                          headerName: toSentenceCase(
                                              this.$tkey(`headers.${field.fieldName}`)
                                          ),
                                          type: 'forecastField',
                                          field: fieldPath
                                              ? `${fieldPath}.${fieldName}`
                                              : fieldName,
                                          fieldName,
                                          fieldPath,
                                          valueFormatter,
                                          hide: field.hide,
                                          cellClassRules: {
                                              'product-information--first-column': () => i === 0,
                                          },
                                          cellRendererParams: {
                                              onTheFly: field.onTheFly,
                                              onTheFlyFunction: field.onTheFlyFunction,
                                              onTheFlyParams: field.onTheFlyParams,
                                          },
                                          cellRenderer: params => {
                                              if (params.node.footer && params.onTheFly) {
                                                  return this.onTheFlyAggregatedGetter(params);
                                              }

                                              return params.valueFormatted || params.value;
                                          },
                                      };
                                  }),
                          }
                        : {}),
                };
            });
        },

        openDialog() {
            setTimeout(() => {
                // Listen to column filters so we can update the charts according to
                // the products being displayed in the grid
                this.gridOptions.api.addEventListener('filterChanged', e => {
                    const productsAfterFilter = [];
                    e.api.forEachNodeAfterFilter(node => {
                        productsAfterFilter.push(node.data);
                    });

                    this.displayedProducts = productsAfterFilter;
                });
            }, 200);
        },

        closeDialog() {
            if (this.$refs.dialog) {
                this.gridOptions.api.removeEventListener('filterChanged');
                this.$refs.dialog.close();
            }
        },

        filterProducts() {
            this.filteredProducts = this.products
                .map(p => {
                    if (!p.forecastingAggregations) return p;

                    let filteredStoreGroups = [];
                    if (!isEmpty(this.filter.storeGroups)) {
                        // If filter is applied, include only the matching store groups in the filtered product
                        filteredStoreGroups = p.forecastingAggregations.storeGroups.filter(
                            sg =>
                                includes(this.filter.storeGroups, sg.storeGroupKey) &&
                                !isEmpty(sg.forecastingAggregations)
                        );
                    } else {
                        // If no filter, add all store groups to the filtered product
                        filteredStoreGroups = p.forecastingAggregations.storeGroups;
                    }

                    // If no matching store group, return null to remove product from the modal view
                    if (isEmpty(filteredStoreGroups)) return null;

                    // Generate a list of forecastingAggregations by storeGroup, so we easily sum all KPIs
                    const aggregations = filteredStoreGroups.flatMap(
                        sg => sg.forecastingAggregations
                    );

                    return {
                        ...p,
                        forecastingAggregations: {
                            ...p.forecastingAggregations,
                            storeGroups: filteredStoreGroups,
                            // Aggregate filtered metrics to product level based on filtered store groups
                            storeGroupFiltered: this.sumByFields(aggregations),
                        },
                    };
                })
                .filter(p => !isNil(p));

            this.displayedProducts = this.filteredProducts;
        },

        onTheFlyGetter({ onTheFlyFunction, onTheFlyParams, fieldPath, source }) {
            const columns = this.getGridChildColumns();
            const value = this[onTheFlyFunction].call(
                null,
                ...onTheFlyParams.map(fieldName => {
                    const fieldConfig = columns.find(col => col.fieldName === fieldName);

                    if (fieldConfig.onTheFly) {
                        return this.onTheFlyGetter({
                            onTheFlyFunction: fieldConfig.onTheFlyFunction,
                            onTheFlyParams: fieldConfig.onTheFlyParams,
                            fieldPath,
                            source,
                        });
                    }

                    return get(source, `${fieldPath}.${fieldName}`);
                })
            );
            return value;
        },

        valueGetter(params) {
            const source = params.data;
            const { fieldPath, fieldName } = params.colDef;
            if (!get(source, fieldPath)) return null;

            const { onTheFly, onTheFlyFunction, onTheFlyParams } = params.colDef.cellRendererParams;
            // Calculate values on the fly based on column config
            // e.g. promoMarginRate = promoMargin / promoSales
            if (onTheFly) {
                return this.onTheFlyGetter({
                    onTheFlyFunction,
                    onTheFlyParams,
                    fieldPath,
                    source,
                });
            }
            return get(source, `${fieldPath}.${fieldName}`);
        },

        // Calculate values on the fly based on column config
        onTheFlyAggregatedGetter({ colDef, node, formatValue }) {
            // `node.aggData` will contain the aggregated value for all columns in the grid
            // if a non-displayed kpi is needed for calculating displayed kpi,
            // we must add that kpi as an invisible column to the grid by setting `hide: true`
            // then we'll have its aggregated value in here
            // e.g. promoMarginRate = promoMargin / promoSales
            const { fieldPath, cellRendererParams } = colDef;
            const value = this.onTheFlyGetter({
                onTheFlyFunction: cellRendererParams.onTheFlyFunction,
                onTheFlyParams: cellRendererParams.onTheFlyParams,
                fieldPath,
                source: node.aggData,
            });
            return formatValue(value);
        },

        getChartsConfig({ type = '' }) {
            // Charts in highcharts.yaml are based on the modal context
            return get(this.highchartsConfig, `${this.dialogContext}`).charts.filter(
                chart => chart.type === type
            );
        },

        // Helper function to sum all metrics in an array
        // Used to aggregate data at product and promotion levels based on filtered storeGroups
        sumByFields(arrayToSum) {
            const result = Object.keys(arrayToSum[0]).reduce((acc, field) => {
                acc[field] = sumBy(arrayToSum, field);
                return acc;
            }, {});

            return result;
        },

        handleDivision(dividend, divisor) {
            if (divisor === 0) return 0;
            return dividend / divisor || 0;
        },

        handleAddition(val1, val2) {
            return (val1 || 0) + (val2 || 0);
        },

        handleMultiplication(val1, val2) {
            return (val1 || 0) * (val2 || 0);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';
.chart-container {
    height: 100%;
    width: 50%;
    margin: 1rem;
}

.details-btn {
    align-self: flex-end;
    margin-right: 1.5rem;
}

.forecast-details-container {
    display: flex;
    flex-direction: column;

    .waterfall-charts {
        display: flex;
        height: 32rem;
    }

    .filter {
        height: 3rem;
    }

    &__filters {
        display: flex;
        align-items: baseline;
        width: 70%;
        height: 4rem;
        margin-top: 1rem;

        &--label {
            font-size: 1.2rem;
            padding-right: 1rem;
        }

        &--select {
            width: 80%;
        }
    }
}

::v-deep {
    .product-information {
        &__metrics {
            justify-content: center;
            text-align: right;
        }
    }
}
</style>
