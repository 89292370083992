<template>
    <main-dialog
        ref="dialog"
        :heading="labels.heading"
        :button-text="labels.btnText"
        @confirm="save"
        @close="close"
    >
        <template v-slot:actions="{ activator }">
            <resource-actions
                :add-button="addBtn"
                :edit-button="editMode"
                :button-text="labels.btnText"
                :has-border="labels.hasBorder"
                :activator="activator"
            />
        </template>
        <vuex-form ref="form" v-bind="vBindObj" />
    </main-dialog>
</template>

<script>
import vuexFormDialogMixin from '../../mixins/vuex-form-dialog';

export default {
    mixins: [vuexFormDialogMixin],

    computed: {
        vBindObj() {
            return {
                ...this.form,
                ...this.$props,
            };
        },
    },
};
</script>
