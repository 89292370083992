<template>
    <div>
        <div class="funded-promotions-list-wrapper__funded-promotions">
            {{ $t('supplierCommitments.fundedPromotions') | toSentenceCase }}
        </div>

        <template v-if="hasPromotionsToDisplay">
            <template v-for="(promotionGroup, pgIndex) in promotions">
                <template v-for="(promotion, pIndex) in promotionGroup.data">
                    <h3
                        v-if="pIndex === 0"
                        :key="`label::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__promotion-group-title"
                    >
                        {{ $t(promotionGroup.label) | toSentenceCase }}
                    </h3>
                    <div
                        v-else
                        :key="`noLabel::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__single-column"
                    />
                    <div
                        :key="`fundedPromotionsList::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__funded-promotions-list"
                    >
                        <funded-promotions-list :promotion="promotion" :fields="fields" />
                    </div>

                    <div
                        :key="`committedValueFiller::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__single-column"
                    />

                    <div
                        :key="`executedFunding::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__funding-value"
                    >
                        {{
                            $n('numbers.default.currency', promotion.executedFunding, {
                                usePlaceholder: true,
                            })
                        }}
                    </div>

                    <div
                        :key="`plannedFunding::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__single-column funded-promotions-list-wrapper__funding-value total-spend-viewer__funding-value--right-border"
                    >
                        {{
                            $n('numbers.default.currency', promotion.plannedFunding, {
                                usePlaceholder: true,
                            })
                        }}
                    </div>

                    <div
                        :key="`remainingRowFiller::${pIndex}::${pgIndex}::${promotion._id}`"
                        class="funded-promotions-list-wrapper__remaining-row-filler"
                    />
                </template>
                <div
                    :key="`productGroupDivider::${pgIndex}::${promotionGroup.label}`"
                    class="funded-promotions-list-wrapper__row-divider"
                />
            </template>
        </template>
        <div
            v-else
            class="funded-promotions-list-wrapper__funded-promotions funded-promotions-list-wrapper__funded-promotions--no-allocated-promotions"
        >
            {{ $t('supplierCommitments.noAllocatedPromotions') | toSentenceCase }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { find, sumBy } from 'lodash';
import fields from '../../../config/total-spend-promotion-fields';

export default {
    data() {
        return {
            fields: Object.freeze(fields),
        };
    },
    computed: {
        ...mapGetters('promotions', ['getSupplierCommitmentPromotions']),
        ...mapGetters('supplierCommitments', ['getSelectedSupplierCommitment']),
        ...mapGetters('clientConfig', ['getHierarchyConfig']),

        hasPromotionsToDisplay() {
            return this.promotions.some(promotion => promotion.data.length);
        },

        promotions() {
            const { supplier, hierarchy } = this.getSelectedSupplierCommitment;
            const { categoryLevel } = this.getHierarchyConfig;
            const supplierCommitmentCategoryKey = hierarchy.levelEntryKey;

            const sumLumpFunding = promotion => {
                const products = promotion.products.filter(product => {
                    const productCategoryKey = find(product.hierarchy, {
                        level: categoryLevel,
                    }).levelEntryKey;
                    return (
                        product.supplierKey === supplier.supplierKey &&
                        productCategoryKey === supplierCommitmentCategoryKey
                    );
                });

                /* Only sum the lump funding for products where there is a match on supplier
                and category with the supplier commitment. */
                return products.length
                    ? sumBy(products, product => product.funding.lumpFundingTotal)
                    : 0;
            };

            const plannedPromotions = this.getSupplierCommitmentPromotions.planned.map(
                promotion => {
                    return {
                        ...promotion,
                        plannedFunding: sumLumpFunding(promotion),
                        executedFunding: null,
                    };
                }
            );

            const executedPromotions = this.getSupplierCommitmentPromotions.executed.map(
                promotion => {
                    return {
                        ...promotion,
                        plannedFunding: null,
                        executedFunding: sumLumpFunding(promotion),
                    };
                }
            );

            return [
                {
                    data: plannedPromotions,
                    label: 'supplierCommitments.plannedPromotions',
                },
                {
                    data: executedPromotions,
                    label: 'supplierCommitments.executedPromotions',
                },
            ];
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.funded-promotions-list-wrapper {
    &__funded-promotions {
        @include span-full-row;
        font-size: 1.3rem;
        line-height: 1.4rem;
        text-align: left;
        color: $promo-text-colour;
        padding-left: 1.5rem;
        font-weight: 600;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        border-top: $promo-divider-colour 0.1rem solid;
        border-bottom: $promo-divider-colour 0.1rem solid;
        background-color: $promo-white;

        &--no-allocated-promotions {
            border-top: none;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    &__promotion-group-title {
        padding-right: 1rem;
        text-align: right;
    }

    &__funding-value {
        text-align: right;
        padding-right: 1rem;
        border-left: $promo-grey 0.1rem solid;

        &--right-border {
            border-right: $promo-grey 0.1rem solid;
        }
    }

    &__remaining-row-filler {
        grid-column: span 5;
        background-color: $promo-white;
    }

    &__funded-promotions-list {
        background-color: $promo-white;
        display: flex;
        grid-column: 2 / 4;
    }

    &__single-column {
        background-color: $promo-white;
        grid-column: span 1;
        padding-bottom: 1.5rem;
    }

    &__row-divider {
        @include span-full-row;
        border-bottom: $promo-divider-colour 0.1rem solid;
    }
}
</style>
