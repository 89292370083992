<template>
    <main-dialog
        ref="dialog"
        heading="dialogs.promotionErrors.header"
        :has-activator="hasActivator"
        :show-positive-action-button="false"
        :is-error-dialog="hasExecutionErrors"
        btn-close-text="actions.close"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <v-tooltip z-index="300" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                        <icon
                            v-if="errors.length"
                            icon-name="red-error-triangle"
                            small
                            v-on="!disabled && activator"
                            @click.stop
                        />
                        <icon
                            v-else-if="warnings.length"
                            icon-name="orange-warning-circle"
                            small
                            v-on="!disabled && activator"
                            @click.stop
                        />
                        <icon
                            v-else-if="infos.length"
                            icon-name="grey-warning-square"
                            small
                            v-on="!disabled && activator"
                            @click.stop
                        />
                    </span>
                </template>
                <span>
                    {{ toolTipMessage | toSentenceCase }}
                </span>
            </v-tooltip>
        </template>
        <template v-slot:default>
            <div
                v-for="errorSeverity in Object.keys(allErrorsMap)"
                :key="errorSeverity"
                class="error-list-container"
            >
                <div
                    v-if="allErrorsMap[errorSeverity] && allErrorsMap[errorSeverity].length"
                    class="error-list"
                >
                    <div :class="`error-list__${errorSeverity}`">
                        <icon v-if="errorSeverity === 'error'" icon-name="red-error-triangle" />
                        <icon
                            v-if="errorSeverity === 'warning'"
                            icon-name="orange-warning-circle"
                        />
                        <icon v-if="errorSeverity === 'info'" icon-name="grey-warning-square" />
                        <span :class="`error-list__${errorSeverity}--text`">
                            {{ $tkey(`${errorSeverity}Description`) }}
                        </span>
                    </div>
                    <div class="error-list__table">
                        <span
                            ><b>{{ $tkey('table.code') | toSentenceCase }}</b></span
                        >
                        <span
                            ><b>{{ $tkey('table.source') | toSentenceCase }}</b></span
                        >
                        <span
                            ><b>
                                {{ $tkey('table.description') | toSentenceCase }}
                            </b></span
                        >
                        <div
                            v-for="(error, index) in allErrorsMap[errorSeverity]"
                            :key="error.key"
                            class="error-list__table--contents"
                            :class="{ 'error-list__table--striped': index % 2 === 0 }"
                        >
                            <span>{{ error.key | toSentenceCase }}</span>
                            <span>{{ $tkey(`sources.${error.source}`) | toSentenceCase }}</span>
                            <span>{{ error.message | toSentenceCase }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { isEmpty, map, uniq, get } from 'lodash';
import severities from '@enums/severity';
import promotionErrorSeverities from '@enums/promotion-error-severities';
import promotionErrorSources from '@enums/promotion-error-sources';
import { toSentenceCase } from '@/js/utils/string-utils';
import { mapState } from 'vuex';

export default {
    localizationKey: 'dialogs.promotionErrors',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        hasActivator: {
            type: Boolean,
            default: true,
        },
        executionErrors: {
            type: Array,
            required: false,
        },
        forecastingErrors: {
            type: Array,
            required: false,
        },
        inToolValidations: {
            type: Object,
            required: false,
            default: null,
        },
    },
    computed: {
        ...mapState('clientConfig', ['releaseFlags', 'toggleLogic']),
        allErrorsMap() {
            return { error: this.errors, warning: this.warnings, info: this.infos };
        },
        errors() {
            const execution = (this.executionErrors || [])
                .filter(
                    e =>
                        e.errorSeverity.toLowerCase() ===
                        promotionErrorSeverities.ERROR.toLowerCase()
                )
                .map(err => {
                    return {
                        key: err.errorKey,
                        message: err.errorMessage,
                        source: promotionErrorSources.EXECUTION,
                    };
                });

            const inTool = this.turnOnInToolValidation
                ? (get(this.inToolValidations, 'error') || []).map(err => {
                      // For joi errors, display the full error as there is no need to translate based on the type
                      // For joi custom errors, get the translation key from ruleValidationMessage
                      const messageKey =
                          err.ruleValidationType === severities.joiCustom
                              ? err.ruleValidationMessage
                              : err.ruleValidationType;
                      const message =
                          err.ruleValidationType === severities.joi
                              ? err.ruleValidationMessage
                              : toSentenceCase(
                                    this.$t(
                                        `validation.failureMessages.${this.$options.filters.toCamelCase(
                                            messageKey
                                        )}`
                                    )
                                );

                      return {
                          message,
                          source: promotionErrorSources.INTOOL,
                      };
                  })
                : [];

            return [...execution, ...inTool];
        },
        warnings() {
            const execution = (this.executionErrors || [])
                .filter(
                    e =>
                        e.errorSeverity.toLowerCase() ===
                        promotionErrorSeverities.WARNING.toLowerCase()
                )
                .map(err => {
                    return {
                        key: err.errorKey,
                        message: err.errorMessage,
                        source: promotionErrorSources.EXECUTION,
                    };
                });
            const forecasting = this.forecastingErrorsMapped.map(err => {
                return {
                    message: err,
                    source: promotionErrorSources.FORECASTING,
                };
            });

            const inTool = this.turnOnInToolValidation
                ? (get(this.inToolValidations, 'warning') || []).map(err => {
                      // For joi warnings, display the full warning message as there is no need to translate based on the type
                      // For joi custom warnings, get the translation key from ruleValidationMessage
                      const messageKey =
                          err.ruleValidationType === severities.joiCustom
                              ? err.ruleValidationMessage
                              : err.ruleValidationType;
                      const message =
                          err.ruleValidationType === severities.joi
                              ? err.ruleValidationMessage
                              : toSentenceCase(
                                    this.$t(
                                        `validation.failureMessages.${this.$options.filters.toCamelCase(
                                            messageKey
                                        )}`
                                    )
                                );

                      return {
                          message,
                          source: promotionErrorSources.INTOOL,
                      };
                  })
                : [];

            return [...execution, ...forecasting, ...inTool];
        },
        infos() {
            const inTool = this.turnOnInToolValidation
                ? (get(this.inToolValidations, 'info') || []).map(err => {
                      return {
                          //   key: err.ruleValidationType,
                          message: toSentenceCase(
                              this.$t(
                                  `validation.failureMessages.${this.$options.filters.toCamelCase(
                                      err.ruleValidationType
                                  )}`
                              )
                          ),
                          source: promotionErrorSources.INTOOL,
                      };
                  })
                : [];

            return [...inTool];
        },
        hasExecutionErrors() {
            return !isEmpty(
                (this.executionErrors || []).filter(
                    e =>
                        e.errorSeverity.toLowerCase() ===
                        promotionErrorSeverities.ERROR.toLowerCase()
                )
            );
        },
        hasExecutionWarnings() {
            return !isEmpty(
                (this.executionErrors || []).filter(
                    e =>
                        e.errorSeverity.toLowerCase() ===
                        promotionErrorSeverities.WARNING.toLowerCase()
                )
            );
        },
        hasForecastingErrors() {
            return !isEmpty(this.forecastingErrors);
        },
        forecastingErrorsMapped() {
            return uniq(map(this.forecastingErrors, 'msg'));
        },
        allowSeveritiesList() {
            return (this.toggleLogic.allowValidationSeverities || []).map(
                allowSeverity => severities[allowSeverity]
            );
        },

        inToolValidationsMap() {
            const result = {};
            let hasSomeValidationResult = false;
            this.allowSeveritiesList.forEach(item => {
                const key = `${item}s`;
                result[key] = this.checkValidationBySeverity(item);
                if (result[key]) {
                    hasSomeValidationResult = true;
                }
            });
            result.hasSomeValidationResult = hasSomeValidationResult;
            return result;
        },

        turnOnInToolValidation() {
            return get(this.releaseFlags, 'releaseFlags.turnOnInToolValidation', false);
        },

        toolTipMessage() {
            if (this.hasExecutionErrors) {
                return this.$tkey('tooltips.executionErrors');
            }
            if (this.hasExecutionWarnings) {
                return this.$tkey('tooltips.executionWarnings');
            }
            if (this.hasForecastingErrors) {
                return this.$tkey('tooltips.forecastingErrorsWarnings');
            }
            if (this.inToolValidationsMap.hasSomeValidationResult) {
                return this.$tkey('tooltips.validationErrorsWarnings');
            }
            return '';
        },
    },
    methods: {
        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.$refs.dialog.close();
        },
        checkValidationBySeverity(severityLevel) {
            return this.inToolValidations && !isEmpty(this.inToolValidations[severityLevel]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.error-list {
    font-size: 1.2rem;
    border-top: 1px solid $promo-divider-colour;
    border-bottom: 1px solid $promo-divider-colour;

    &__table {
        display: grid;
        grid-template-columns: 12rem 12rem 50rem;
        padding-bottom: 1rem;

        span {
            padding: 0.5rem 1rem;
        }

        &--contents {
            display: contents;
        }

        &--striped {
            span {
                background-color: $rtls-table-alternate-row;
            }
        }
    }

    &__error,
    &__warning,
    &__info {
        padding: 1rem 0 0.5rem;

        &--text {
            padding-left: 0.5rem;
        }
    }

    &__error {
        color: $promo-error;
    }

    &__warning {
        color: $promo-orange;
    }

    &__info {
        color: $promo-text-colour-2;
    }
}
</style>
