<template>
    <div class="channels-tab">
        <div>
            <span class="font-weight-bold">{{
                $tkey('descriptionHighlight') | toSentenceCase
            }}</span>
            {{ $tkey('description') }}
        </div>
        <vuex-form ref="form" v-bind="vBindObj" class="channels-tab__form" />
    </div>
</template>

<script>
export default {
    localizationKey: 'planning.promotionsMaintenance.resources',

    props: {
        form: Object,
    },

    computed: {
        vBindObj() {
            return {
                ...this.$props,
                ...this.form,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.channels-tab {
    padding: 1rem 1.4rem;
    max-width: calc(100vw - 22rem);
    &__form {
        padding: 0 0.8rem;
        &::v-deep .channels-stores-expansion-panel {
            border-top: 0.1rem solid $promo-grey;
            .rtls-expansion-panel-header__label {
                width: 25%;
                color: $promo-primary-colour;
            }

            .expand-btn {
                width: 75%;
                text-align: right;
                padding-right: 1rem;

                &__icon {
                    font-size: 3rem;
                }
            }
        }
    }
}
</style>
