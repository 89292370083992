<template>
    <div class="parkinglot-grid-container">
        <overview-filter include-date-filter @change="handleSelectorChange" />

        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 100%;"
            grid-class="ag-theme-custom__parkinglot-grid"
            dom-layout="normal"
            @grid-ready="onGridReady"
        />

        <promotion-viewer-dialog
            ref="promotion-viewer-dialog"
            :key="`promotion-viewer::${selectedPromotion._id}::${promotionToggle}`"
            :promotion="selectedPromotion"
            is-past-promotions
            @close="promotionToggle = !promotionToggle"
        />

        <v-btn
            class="status-bar__action--right"
            primary
            :disabled="allocationDialogDisabled"
            @click="openAllocationDialog"
        >
            {{ $t('parkingLot.allocateSelectedPromotions') | toSentenceCase }}
        </v-btn>

        <allocate-past-promotions
            ref="promotion-allocation-dialog"
            :promotion-ids="selectedPromotionIds"
            :has-activator="false"
            is-past-promotions
            @confirm="confirmAllocatedPromotions"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isEmpty } from 'lodash';
import parkingLotGridMixin from '../mixins/parking-lot-grids-mixin';

export default {
    mixins: [parkingLotGridMixin],
    data() {
        return {
            where: {},
            isPastPromotions: true,
        };
    },
    computed: {
        ...mapState('clientConfig', ['overviewPageConfig']),
        allocationDialogDisabled() {
            return isEmpty(this.selectedPromotionIds);
        },
    },
    created() {
        this.limit = this.overviewPageConfig.pastPromotions.maxPromotionsToLoad;
        this.identifierColumns = this.getIdentifierColumns();
        this.detailColumns = this.getDetailsColumns({
            columnGroups: this.overviewPageConfig.pastPromotions.detailColumns,
        });

        this.columnDefs = [
            ...this.identifierColumns,
            ...this.getActionColumns({ isPastPromotions: true }),
            ...this.detailColumns,
            {
                minWidth: 40,
                width: 40,
                flex: 1,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerClass: 'last-header',
            },
        ];
    },
    methods: {
        async handleSelectorChange({ where }) {
            this.where = where;
            this.refetchPromotions();
        },

        confirmAllocatedPromotions() {
            this.selectedPromotionIds = [];
        },
    },
};
</script>
