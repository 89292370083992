import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex-row" },
    [
      _c(VTextField, {
        staticClass:
          "rtls-text-field rtls-text-field--white product-offer-group__filter-bar",
        attrs: {
          placeholder: _vm.$tkey("filterAllRows"),
          "append-icon": "mdi-magnify",
        },
        on: { input: _vm.callInputFunc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }