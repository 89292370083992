<template>
    <div class="page">
        <tabs :tabs="tabs" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import routeNames from '@enums/route-names';
import { canAccessClientSystemsTesting, reportingOnly } from '@enums/feature-flags';
import navigation from '@/js/navigation';

export default {
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),

        canOnlyAccessUserProfiles() {
            return this.toggleLogic[reportingOnly];
        },

        tabs() {
            let tabs = [];
            let clientAdminTabs = [];
            if (!this.canOnlyAccessUserProfiles) {
                tabs = [
                    {
                        label: this.$options.filters.toSentenceCase(this.$t('admin.tabs.rateCard')),
                        disabled: false,
                        component: 'rate-card',
                        navigateTo: navigation.toRateCardView,
                        routeNames: [routeNames.rateCardView],
                    },
                    {
                        label: this.$options.filters.toSentenceCase(
                            this.$t('admin.tabs.freeGifts')
                        ),
                        disabled: false,
                        component: 'free-gifts-viewer',
                        navigateTo: navigation.toFreeGiftsView,
                        routeNames: [routeNames.toFreeGiftsView],
                    },
                    {
                        label: this.$options.filters.toSentenceCase(
                            this.$t('admin.tabs.loyaltyPoints')
                        ),
                        disabled: false,
                        component: 'loyalty-points-viewer',
                        navigateTo: navigation.toLoyaltyPointsView,
                        routeNames: [routeNames.toLoyaltyPointsView],
                    },
                ];

                if (this.toggleLogic[canAccessClientSystemsTesting]) {
                    clientAdminTabs = [
                        {
                            label: this.$options.filters.toSentenceCase(
                                this.$t('admin.tabs.clientSystemsTesting')
                            ),
                            disabled: false,
                            linkText: 'navBar.clientSystemsTesting',
                            omitFromNav: true,
                            component: 'client-systems-testing',
                            navigateTo: navigation.toClientSystemsTesting,
                            routeNames: [routeNames.clientSystemsTesting],
                        },
                        {
                            label: this.$options.filters.toSentenceCase(
                                this.$t('admin.tabs.redisBull')
                            ),
                            disabled: false,
                            linkText: 'navBar.redisBull',
                            omitFromNav: true,
                            component: 'redis-bull',
                            navigateTo: navigation.toRedisBull,
                            routeNames: [routeNames.redisBull],
                        },
                        {
                            label: this.$options.filters.toSentenceCase(
                                this.$t('admin.tabs.featureToggler')
                            ),
                            disabled: false,
                            linkText: 'navBar.featureToggler',
                            omitFromNav: true,
                            component: 'feature-toggler',
                            navigateTo: navigation.toFeatureToggler,
                            routeNames: [routeNames.featureToggler],
                        },
                    ];
                }
            }

            const userPermissionTab = [
                {
                    label: this.$options.filters.toSentenceCase(
                        this.$t('admin.tabs.userPermissions')
                    ),
                    disabled: false,
                    linkText: 'navBar.userPermissions',
                    omitFromNav: true,
                    component: 'user-permissions',
                    navigateTo: navigation.toUserPermissions,
                    routeNames: [routeNames.userPermissions],
                },
            ];

            return [...tabs, ...userPermissionTab, ...clientAdminTabs];
        },
    },
};
</script>

<style scoped lang="scss">
.page {
    height: 100%;
    padding: 0 1rem;
    padding-top: 2rem;
}

// Vuetify overrides needed for making the tabs fill the available vertical space.
::v-deep .v-tabs.promo-tabs.theme--light {
    height: 100%;
    display: flex;
    flex-direction: column;
}

::v-deep .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text.transparent {
    flex: 0 1 0;
}

::v-deep .v-window.v-item-group.theme--light.v-tabs-items {
    flex: 1 0 0;
}

::v-deep .v-window__container {
    height: 100%;
}

::v-deep .v-window-item.tab.v-window-item--active {
    height: 100%;
}
</style>
