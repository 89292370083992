'use strict';

/**
 * Enum definition tab names.
 *
 * @module data/enums/tab-names
 */
module.exports = {
    planning: 'planning',
    supplierCommitments: 'supplierCommitments',
    preparation: 'preparation',
};
