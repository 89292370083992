import { isString } from 'lodash';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '../utils/string-utils';

const dateRangeRequired = (
    value,
    message = toSentenceCase(i18n.t('validation.common.dateRangeRequired'))
) => (isString(value) && value.includes(i18n.t('general.dates.separator'))) || message;

export default dateRangeRequired;
