/* eslint-disable import/prefer-default-export */

import { groupBy, isUndefined, isNil, find, some } from 'lodash';
import i18n from '@/js/vue-i18n';
import temporaryIds from '@enums/temporary-ids-for-new-resources';

export function generateCandidatesFromFlatProductsList({
    productGroups = [],
    products,
    includeTemporaryProductGroups = false,
}) {
    const groupedProducts = groupBy(products, product => {
        return product.productGroup || 'nonGrouped';
    });
    const candidates = [];

    productGroups.forEach(pg => {
        if (!pg) return;
        if (groupedProducts[pg._id]) {
            candidates.push({
                ...pg,
                products: groupedProducts[pg._id],
            });
        } else if (includeTemporaryProductGroups && pg._id === temporaryIds.newProductGroupId) {
            // If we reach here it means this product group is one we are currently creating
            // So we just add it as a candidate
            candidates.push(pg);
        }
    });

    // Include all products which are not in a product group.
    candidates.push(...groupedProducts.nonGrouped);

    return candidates;
}

export function getSupplierTotalFunding({ supplierProducts }) {
    return supplierProducts.reduce((total, product) => {
        const { totalFunding: productTotalFunding } = product.funding;
        return total + productTotalFunding;
    }, 0);
}

const getProductIndexInPromotion = ({ productKey, promotion }) =>
    promotion.products.findIndex(p => p.productKey === productKey);

const getPropertyPathByProductKey = ({ productKey, promotion }) => {
    // Products are nested within an array so logic is required to get the correct index for the field path.
    const productIndex = getProductIndexInPromotion({ productKey, promotion });

    if (productIndex === -1) {
        throw new Error('Product index could not be found for the given product key.');
    }

    return `products[${productIndex}]`;
};

/**
 * Gets the path to the product in a promotion (using array indices).
 *
 * @param {Object} params - The params.
 * @param {String} params.productKey - The product key to find the path for in the promotion.
 * @param {String} params.path - An additional path to add to the product path.
 * @param {Object} params.promotion - The promotion which contains the product.
 *
 * @returns {String} - The path to the product in the promotion.
 */
export function getFieldPathByProductKey({ productKey, path, promotion }) {
    const productPath = `${getPropertyPathByProductKey({ productKey, promotion })}`;

    return path ? `${productPath}.${path}` : productPath;
}

export function getProductsGroupedBySupplierCategory(products, categoryLevel, separator) {
    return groupBy(products, product => {
        const category = find(product.hierarchy, {
            level: categoryLevel,
        });
        return [
            product.supplierKey,
            product.clientSupplierKey,
            category.levelEntryDescription,
            category.levelEntryKey,
        ].join(separator);
    });
}

export function getFormattedProductSizeAndUnit(params) {
    if (params.data.packSize !== undefined && params.data.packUnit) {
        return `${params.data.packSize}${params.data.packUnit}`;
    }
    return '';
}

export function formatValueAsCurrency(value, display3dp) {
    return i18n.n(`numbers.default.${display3dp ? 'currencyPadded3dp' : 'currencyPadded'}`, value, {
        usePlaceholder: true,
    });
}

/**
 * Recalculates uplift, baseline, totalVolume for promotion products
 * After overriding:
 * - baseline, we need to recalculate totalVolume and uplift (if totalVolume has been overridden before)
 * - totalVolume, we need to recalculate uplift and baseline (if uplift=0)
 * - uplift, we need to recalculate just totalVolume
 *
 * @param {String} fieldPath - The override field path.
 * @param {String} fieldName - The override field name.
 * @param {Array} products - The promotion products
 * @param {Function} updateProductValues - A function to update product values in staging area
 */
export function recalculateTotalFundingValues({
    fieldPath,
    fieldName,
    products,
    updateProductValues = () => {},
}) {
    const baselineFieldValues = [];
    const upliftFieldValues = [];
    const totalVolumeFieldValues = [];
    products.forEach(product => {
        const { totalVolume, uplift, forecasted } = product.volumes;
        let { baseline } = product.volumes;
        let upliftForCalculations;
        let overriddenUplift;
        let overriddenBaseline;
        let overriddenTotalVolume;
        let actualBaseLine;

        // if totalVolume is reverted, need to revert baseline and uplift
        if (fieldName === 'totalVolume' && isNil(totalVolume)) {
            baselineFieldValues.push(null);
            upliftFieldValues.push(null);
            return;
        }

        // if baseline is reverted and uplift is not overwritten or
        // if uplift is reverted and baseline is not overwritten
        // revert totalVolume
        if (
            (fieldName === 'baseline' || fieldName === 'uplift') &&
            isNil(baseline) &&
            isNil(uplift)
        ) {
            totalVolumeFieldValues.push(0);
            return;
        }

        switch (fieldName) {
            case 'uplift':
                overriddenTotalVolume =
                    (isNil(baseline) ? forecasted.calcBaseline : baseline) +
                    (isNil(uplift) ? forecasted.calcUplift : uplift);
                break;
            case 'totalVolume':
                if (totalVolume > forecasted.calcBaseline) {
                    baseline = forecasted.calcBaseline;
                    baselineFieldValues.push(forecasted.calcBaseline);
                }
                if (totalVolume === 0) {
                    overriddenBaseline = 0;
                    overriddenUplift = 0;
                    break;
                }

                actualBaseLine = isNil(baseline) ? forecasted.calcBaseline : baseline;

                if (totalVolume < actualBaseLine) {
                    overriddenBaseline = totalVolume;
                    overriddenUplift = 0;
                    break;
                }

                overriddenUplift = Number((totalVolume - actualBaseLine).toFixed(2));
                break;
            case 'baseline':
                if (forecasted.calcUplift < 0 && isNil(uplift)) {
                    overriddenUplift = 0;
                    upliftForCalculations = overriddenUplift;
                } else {
                    upliftForCalculations = isNil(uplift) ? forecasted.calcUplift : uplift;
                }

                overriddenTotalVolume = Number(
                    (
                        (isNil(baseline) ? forecasted.calcBaseline : baseline) +
                        upliftForCalculations
                    ).toFixed(2)
                );
                break;
            default:
                break;
        }

        if (!isUndefined(overriddenBaseline)) {
            baselineFieldValues.push(overriddenBaseline);
        }
        if (!isUndefined(overriddenUplift)) {
            upliftFieldValues.push(overriddenUplift);
        }
        if (!isUndefined(overriddenTotalVolume)) {
            totalVolumeFieldValues.push(overriddenTotalVolume);
        }
    });

    const callUpdateProductValues = ({ fieldValues, field }) => {
        if (fieldValues.length) {
            updateProductValues({
                products,
                path: fieldPath,
                fieldName: field,
                fieldValues,
            });
        }
    };

    // update uplift
    callUpdateProductValues({ fieldValues: upliftFieldValues, field: 'uplift' });
    // update baseline
    callUpdateProductValues({
        fieldValues: baselineFieldValues,
        field: 'baseline',
    });
    // update totalVolume
    callUpdateProductValues({
        fieldValues: totalVolumeFieldValues,
        field: 'totalVolume',
    });
}

export function getActualCustomerAvailabilityForPromotion({
    defaultCustomerAvailability,
    scenario,
}) {
    const isScenarioCustomersGroupIncludesDefault = some(
        scenario.customerGroups,
        cg => cg.key === defaultCustomerAvailability
    );
    if (isScenarioCustomersGroupIncludesDefault) {
        return defaultCustomerAvailability;
    }
    return scenario.customerGroups[0].key;
}
