'use strict';

/**
 * Enum definition file for the detailed provisions names
 *
 * @module data/enums/detailed-provisions-names
 */

module.exports = {
    location: 'location',
    slotSize: 'slotSize',
};
