import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VAutocomplete, {
    ref: "autocomplete",
    attrs: {
      items: _vm.options,
      "no-data-text": _vm._f("toSentenceCase")(
        _vm.$t(
          "planning.promotionsMaintenance.products.newProductSetupModal.noMatchingProducts"
        )
      ),
      "menu-props": { auto: true },
      dense: "",
      "allow-overflow": "",
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }