import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-dialog", {
        ref: "dialog",
        staticClass: "dialog",
        attrs: {
          heading:
            "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.header",
          "btn-confirm-text":
            "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.selectProducts",
          "confirm-btn-disabled": !_vm.noOfPastedProductKeys || _vm.loading,
          "has-activator": _vm.hasActivator,
          "show-negative-action-button": false,
        },
        on: { confirm: _vm.addBulkProductsToPromo, close: _vm.closeDialog },
        scopedSlots: _vm._u(
          [
            _vm.hasActivator
              ? {
                  key: "actions",
                  fn: function ({ activator }) {
                    return [
                      _c(
                        "icon",
                        _vm._g(
                          {
                            attrs: { "icon-name": "duplicate", small: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          activator
                        )
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "default",
              fn: function () {
                return [
                  _c("div", { staticClass: "bulk-upload" }, [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t(
                                "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.information"
                              )
                            )
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t(
                                  "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.instruction"
                                )
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t(
                                  "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.warning"
                                )
                              )
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-key-area--input" },
                      [
                        _c(VTextarea, {
                          staticClass: "rtls-text-field--grey pb-1 pt-2",
                          attrs: { "hide-details": "", "no-resize": "" },
                          model: {
                            value: _vm.productKeysText,
                            callback: function ($$v) {
                              _vm.productKeysText = $$v
                            },
                            expression: "productKeysText",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-key-area--report" }, [
                      _c("div", [
                        _vm.noOfPastedProductKeys
                          ? _c(
                              "div",
                              {
                                class: {
                                  "product-key-area--report--warning":
                                    _vm.allProductKeysNotAdded,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t(
                                          "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.totalPastedKeys",
                                          {
                                            noOfPastedProductKeys:
                                              _vm.noOfPastedProductKeys,
                                          }
                                        )
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.requestInProgress && !_vm.isNil(_vm.noOfCopiedKeys)
                          ? _c(
                              "div",
                              {
                                class: {
                                  "product-key-area--report--warning":
                                    _vm.allProductKeysNotAdded,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t(
                                          "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.totalCopiedKeys",
                                          { noOfCopiedKeys: _vm.noOfCopiedKeys }
                                        )
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.requestInProgress
                          ? _c(
                              "div",
                              { staticClass: "in-progress--message" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("toSentenceCase")(
                                        _vm.$t(
                                          "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.productsAreBeingAddedToThePromotion"
                                        )
                                      )
                                    ) +
                                    "\n                            "
                                ),
                                _c("spinner-dynamic", {
                                  staticClass: "spinner",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          !_vm.isNil(_vm.noOfCopiedKeys) && _vm.showMoreDetails
                            ? _c(
                                VBtn,
                                {
                                  attrs: { color: "primary", depressed: "" },
                                  on: { click: _vm.openUploadReportDialog },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t(
                                            "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.uploadReport"
                                          )
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c("tree-view-dialog", {
        ref: "bulk-upload-errors-dialog",
        attrs: {
          "error-tree": _vm.uploadReportTree,
          header:
            "planning.promotionsMaintenance.offer.offerGroup.bulkProductUpload.uploadReportKeys.header",
        },
        on: { close: _vm.closeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }