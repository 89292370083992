'use strict';

const { isEmpty, isNull, get, keyBy } = require('lodash');
const chagesetTabs = require('./data/enums/changeset-tabs');

/**
 * Checks if products has changes
 * return true if products section has changes
 *
 * @param changeset
 * @returns {boolean}
 */
const checkProducts = ({ products }) =>
    !isEmpty(products.products.added) || !isEmpty(products.products.removed);

/**
 * Checks if the same products has changes that should be auto applied
 * return true if same products section has changes for rank and isDefault fields in auto mode
 * or percentageVolumeBuffer in both auto and manual modes
 *
 * @param changeset
 * @param promotion
 * @returns {boolean}
 */
const checkSameProducts = ({ products }, isAutoMode, { products: promotionProducts } = {}) => {
    // added and removed products arrays are empty,
    // however the same products array can have changes that should be auto applied
    if (!isEmpty(products.products.same) && promotionProducts) {
        const keyedPromotionProducts = keyBy(promotionProducts, 'productKey');
        return products.products.same.some(product => {
            const promotionProduct = keyedPromotionProducts[product.productKey];

            return (
                (isAutoMode &&
                    (product.rank !== promotionProduct.rank ||
                        product.isDefault !== promotionProduct.isDefault)) ||
                product.volumes.percentageVolumeBuffer !==
                    promotionProduct.volumes.percentageVolumeBuffer
            );
        });
    }
    return false;
};

/**
 * Checks if the same POGs has changes in the scope
 * return true if the scope was changed (promotion scope changed to 'store wide' or 'selected products')
 *
 * @param changeset
 * @param promotion
 * @returns {boolean}
 */
const checkSamePOGs = ({ topLevelFields }, { productOfferGroups: promotionPOGs } = {}) => {
    const { productOfferGroups } = topLevelFields;
    if (!productOfferGroups || isEmpty(productOfferGroups.same) || isEmpty(promotionPOGs)) {
        return;
    }
    const keyedPromotionPOGs = keyBy(promotionPOGs, '_id');

    return productOfferGroups.same.some(pog => pog.scope !== keyedPromotionPOGs[pog._id].scope);
};

/**
 * Checks if dates has been changed
 * return true if dates has been changed
 *
 * @param dateTime
 * @returns {boolean}
 */
const checkDates = ({ dateTime }) => dateTime.startDate || dateTime.endDate;

/**
 * Checks if topLevelFields has changes
 *
 * @param topLevelFields
 * @returns {boolean}
 */
const checkTopLevelFields = ({ topLevelFields }) => {
    const { tags } = topLevelFields;

    return !isEmpty(tags.added) || !isEmpty(tags.removed) || !isEmpty(topLevelFields.name);
};

/** *
 * Check changeset for changes
 *
 * @param changeset
 * @param isAutoMode
 * @returns {boolean}
 */
const checkChanges = ({ changeset, isAutoMode = false, promotion }) => {
    if (!changeset) return false;

    const result =
        checkProducts(changeset) ||
        checkSameProducts(changeset, isAutoMode, promotion) ||
        checkSamePOGs(changeset, promotion) ||
        !isEmpty(changeset.funding.va) ||
        !isEmpty(changeset.mechanic) ||
        !isEmpty(changeset.offer) ||
        !isEmpty(changeset.additionalClientSpecificFields);

    if (isAutoMode) {
        return (
            result ||
            checkTopLevelFields(changeset) ||
            checkDates(changeset) ||
            !isNull(get(changeset, 'resourcesStores.rankedProductsCount', null)) ||
            !isEmpty(changeset.products.userSelectedCategories.added) ||
            !isEmpty(changeset.products.userSelectedCategories.removed) ||
            !isEmpty(changeset.supply)
        );
    }

    return result;
};

const defaultChangeset = {
    [chagesetTabs.topLevelFields]: {
        tags: { added: [], removed: [] },
    },
    [chagesetTabs.offerProducts]: [],
    [chagesetTabs.products]: {
        products: { added: [], removed: [], same: [] },
        productGroups: { added: [], removed: [] },
        userSelectedCategories: { added: [], removed: [] },
    },
    [chagesetTabs.resourcesStores]: {
        storeGroups: { added: [], removed: [] },
        resources: { added: [], removed: [] },
    },
    [chagesetTabs.mechanic]: {},
    [chagesetTabs.offer]: {},
    [chagesetTabs.additionalClientSpecificFields]: {},
    [chagesetTabs.funding]: { va: {}, volumes: {} },
    [chagesetTabs.supply]: {},
    [chagesetTabs.productOfferGroup]: {
        added: [],
        removed: [],
        same: [],
    },
};

module.exports = {
    checkProducts,
    checkTopLevelFields,
    checkChanges,
    defaultChangeset,
};
