import { differenceWith, merge, get, intersectionWith } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    attributeMetadata: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getAttributeMetadataById
     */
    getters: {
        getAvailableAttributes: (state, getters, rootState) => {
            const excludedAttributes = get(
                rootState,
                'clientConfig.toggleLogic.excludedAttributes',
                []
            );

            const availableAttributes = differenceWith(
                state.attributeMetadata,
                excludedAttributes,
                (attribute, excludedAttribute) => attribute.attributeName === excludedAttribute
            );

            return availableAttributes;
        },

        getDefaultAttributes: (state, getters, rootState) => {
            const defaultAttributeNames = get(
                rootState,
                'clientConfig.toggleLogic.defaultAttributes',
                []
            );

            const defaultAttributes = intersectionWith(
                state.attributeMetadata,
                defaultAttributeNames,
                (attribute, defaultAttribute) => attribute.attributeName === defaultAttribute
            );

            return defaultAttributes;
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setAttributeMetadata
     * - deleteAttributeMetadata
     * - updateAttributeMetadata
     * - addAttributeMetadata
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchAttributeMetadata
     * - createAttributeMetadata
     * - deleteAttributeMetadata
     * - updateAttributeMetadata
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'attribute-metadata',
    isPlural: true,
    readOnly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
