import { get, find as _find } from 'lodash';

import ProductLozengeOptionTypes from '@enums/product-lozenge-option-types';

const concatFields = (product, { fields, separator }) => {
    return fields.map(field => get(product, field)).join(separator);
};

const concatAttributes = (product, { attributeNames, separator }) => {
    return product.attributes
        .filter(attr => attributeNames.includes(attr.attributeName))
        .map(attr => attr.attributeValue)
        .join(separator);
};

const concatValues = (product, options) => {
    const defaultValue = '';

    let stringToConcat = '';
    (options || []).forEach(option => {
        switch (option.type) {
            case ProductLozengeOptionTypes.field: {
                stringToConcat += get(product, option.value, defaultValue);
                break;
            }
            case ProductLozengeOptionTypes.text: {
                stringToConcat += option.value;
                break;
            }
            case ProductLozengeOptionTypes.attribute: {
                const attribute = _find(product.attributes, { attributeName: option.value });
                stringToConcat += get(attribute, 'attributeValue', defaultValue);
                break;
            }
            default:
        }
    });

    return stringToConcat;
};

/**
 * You can read about how these formatters are used and how to add new ones here:
 * https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1885536326/Product+Lozenge+Configuration#How-to-add-a-new-formatter
 */
export default {
    concatFields,
    concatAttributes,
    concatValues,
};
