<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { sum, some, isNaN, get, isNil, set } from 'lodash';
import { isTotalRow, apportionMappings } from '@sharedModules/funding-utils';
import { toSentenceCase } from '@/js/utils/string-utils';
import validators from '@/js/validators';
import { getFieldPathByProductKey } from '@/js/utils/promotion-products-utils';
import TextField from '@/js/pages/planning/components/funding-tab/input-text-field-simple';

// this record is used to generate sticky total record for the grid
const overallTotal = {
    supplierKey: 'dummySupplier',
    supplierName: 'dummySupplier',
    supplierTotalRow: true,
    productKey: 'bottom product',
    name: '',
    overallTotalRow: true,
};

export default {
    localizationKey: 'planning.productDetails',
    data() {
        return {
            supplierCategories: [],
            gridOptions: {
                pinnedBottomRowData: [overallTotal],
            },
        };
    },

    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapState('rateCards', ['selectedRateCardIdForSplitting']),

        promotion() {
            return this.getStagingAreaPromotionById(this.namespace);
        },

        promotionRateCards() {
            return this.promotion.rateCards;
        },

        isSingleSupplierCategory() {
            return (
                this.supplierCategories.length === 1 && !isNil(this.selectedRateCardIdForSplitting)
            );
        },
    },

    watch: {
        promotionRateCards(newRateCards) {
            this.setGroupHeaderHeight(newRateCards);
        },

        // this function is triggered only on change of the rate card selection in the lumpFunding column
        selectedRateCardIdForSplitting() {
            this.rateCardChanged();
        },
    },

    beforeMount() {
        // context for ag-grid (allows on calling functions from this file by renderers)
        this.gridContext = {
            supplierGrid: this,
        };
    },

    methods: {
        ...mapMutations('rateCards', ['setLumpSumTotal', 'setSelectedRateCardIdForSplitting']),

        getLumpFundingColumns() {
            return [
                {
                    headerName: toSentenceCase(this.$tkey('headers.lumpFunding')),
                    headerGroupComponent: 'lumpFundingHeader',
                    headerGroupComponentParams: {
                        promotionId: this.namespace,
                    },
                    groupId: 'lumpFunding',
                    minWidth: 420,
                    openByDefault: false,
                    children: [
                        // this column need to expand functionality is working on ag-greed
                        // ag-grid required columns for both state open and close in other case column group not expand
                        {
                            columnGroupShow: 'close',
                            maxWidth: 0,
                            sortable: false,
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('headers.total')),
                            headerClass: [
                                'funding-information--first-column__left-border',
                                'lump-funding__cell',
                            ],
                            cellRendererFramework: TextField,
                            cellRendererParams: {
                                isDisabled: () => true,
                                visible: params => !params.data.overallTotalRow,
                                formatter: 'numbers.default.lumpFunding',
                                size: 'medium',
                            },
                            field: 'lumpFundingAllocation',
                            cellClass: [
                                'lump-funding__cell',
                                'funding-information',
                                'funding-information--first-column__left-border',
                            ],
                            cellClassRules: {
                                'total-cell': params => isTotalRow(params),
                            },
                            width: 136,
                            sortable: true,
                            columnGroupShow: 'open',
                        },
                        {
                            headerName: '',
                            field: 'tmpLumpFunding',
                            cellClass: ['funding-information', 'lump-funding__cell'],
                            width: 600,
                            fieldPath: undefined,
                            fieldName: 'tmpLumpFunding',
                            sortable: false,
                            validationRules: [
                                {
                                    validator: validators.minValue,
                                    params: [0],
                                },
                            ],
                            cellRendererFramework: TextField,
                            cellRendererParams: {
                                onInputChange: params => {
                                    this.setRootLevelField(params);
                                    this.onCellValueChanged(params);
                                },
                                isDisabled: params => {
                                    return params.disabled || !this.selectedRateCardIdForSplitting;
                                },
                                formatter: 'numbers.default.lumpFunding',
                                size: 'medium',
                            },
                            editable: false,
                            cellClassRules: {
                                'total-cell': params => isTotalRow(params),
                            },
                            columnGroupShow: 'open',
                            pinnedRowCellRenderer: 'lumpFundingFooter',
                            pinnedRowCellRendererParams: {
                                context: this.gridContext,
                                namespace: this.namespace,
                            },
                        },
                    ],
                },
            ];
        },
        setGroupHeaderHeight(rateCards) {
            // Heights based on the contents of the advertisement-fees component.
            // Each rate card row is 34px high.
            const height = 140 + 34 * rateCards.length;
            this.gridOptions.api.setGroupHeaderHeight(height);
        },

        rateCardChanged() {
            const allProducts = [];

            this.supplierCategories.forEach(supplier => {
                const { products } = supplier;
                let lumpSum = 0;

                products.forEach(product => {
                    let fundingAllocation = 0;
                    if (this.selectedRateCardIdForSplitting) {
                        // find rate card amount for product
                        fundingAllocation = this.getRateCardAmountForProduct({ product });
                        lumpSum += fundingAllocation;
                    }
                    // assign rate card funding to the product
                    product.tmpLumpFunding = fundingAllocation;

                    const lumpFundingAllocation = this.calculateLumpFundingAllocation(
                        product,
                        true
                    );

                    product.lumpFundingAllocation = lumpFundingAllocation;
                });

                const supplierLumpFundingAllocation = sum(
                    products.map(p => this.calculateLumpFundingAllocation(p, true))
                );

                // update totals on the supplier rows (top totals)
                supplier.lumpFundingAllocation = supplierLumpFundingAllocation;
                supplier.tmpLumpFunding = lumpSum;

                this.upsertGridData({ updatedItems: allProducts.concat(products) });
            });

            if (this.isSingleSupplierCategory) {
                // automatic apportion only for single supplier/category
                const supplier = this.supplierCategories[0];
                this.automaticSupplierFundingApportion({ supplier });
            }

            this.updateOverallTotalSupplierFunding();
        },

        calculateLumpFundingAllocation(product, excludeSelectedRateCardFunding = false) {
            const lumpFunding = get(product, 'funding.lumpFunding', []);
            const funds = excludeSelectedRateCardFunding
                ? lumpFunding.filter(f => f.rateCardId !== this.selectedRateCardIdForSplitting)
                : lumpFunding;
            return funds.reduce((allocation, f) => {
                allocation += f.rateCardAmount;
                return allocation;
            }, 0);
        },

        // propagates lump funding to the staging area
        // called on click of `Add to total`
        async addLumpFundingTotal() {
            const { path, fieldName } = apportionMappings.tmpLumpFunding.staging;
            const productUpdatesForStaging = [];
            this.model.forEach(prod => {
                const { productKey, tmpLumpFunding } = prod;
                const existingLumpFunding = prod.funding.lumpFunding;

                // apply new lump funding split from the grid
                const newFunding = [
                    {
                        rateCardId: this.selectedRateCardIdForSplitting,
                        rateCardAmount: tmpLumpFunding || 0,
                    },
                ];
                // merge new funding with all already existing funding for the product and
                // exclude any previously existing funding for selected rate card so it can be overwritten by `newFunding`
                const updatedFunding = existingLumpFunding
                    .filter(f => f.rateCardId !== this.selectedRateCardIdForSplitting)
                    .concat(newFunding);

                const fieldPath = getFieldPathByProductKey({
                    productKey,
                    path,
                    promotion: this.getStagingAreaPromotionById(this.namespace),
                });
                const totalLumpFunding = updatedFunding.reduce(
                    (fundingSum, item) => fundingSum + item.rateCardAmount,
                    0
                );
                productUpdatesForStaging.push(
                    {
                        fieldPath,
                        fieldName,
                        value: updatedFunding,
                    },
                    {
                        fieldPath,
                        fieldName: 'lumpFundingTotal',
                        value: totalLumpFunding,
                    }
                );
            });
            await this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate: productUpdatesForStaging,
            });
            this.setSelectedRateCardIdForSplitting(null);

            // reset temp values
            this.products.forEach(p => {
                p.tmpLumpFunding = 0;
            });
        },

        // triggered only when there is one supplier category
        // it will run apportion function only if there was no previous allocations of the selected rate card
        automaticSupplierFundingApportion({ supplier }) {
            // check if there are already allocations for selected rate card
            const hasAnyRateCardAllocations = this.hasRateCardAllocations({
                supplier,
                rateCardId: this.selectedRateCardIdForSplitting,
            });

            const rateCard = this.promotion.rateCards.find(
                rc => rc._id === this.selectedRateCardIdForSplitting
            );
            if (!rateCard) return;
            supplier.tmpLumpFunding = rateCard.amount;
            this.supplierValueChanged({
                data: supplier,
                fieldPath: undefined,
                fieldName: 'tmpLumpFunding',
            });

            // Automatically add to total.
            if (!hasAnyRateCardAllocations) {
                this.addLumpFundingTotal();
            }
        },

        // returns product rate card amount for selected rate card
        getRateCardAmountForProduct({ product }) {
            const funding = product.funding.lumpFunding.find(
                rateCard => rateCard.rateCardId === this.selectedRateCardIdForSplitting
            );
            const { rateCardAmount } = funding || {};
            return rateCardAmount || 0;
        },

        hasRateCardAllocations({ supplier, rateCardId }) {
            return some(
                supplier.products.map(
                    product =>
                        product.funding.lumpFunding.filter(f => f.rateCardId === rateCardId)
                            .length > 0
                )
            );
        },
        resetRateCardSelection() {
            this.setSelectedRateCardIdForSplitting(null);
        },

        setRootLevelField(params) {
            const { fieldName } = params.colDef;
            set(params.data, fieldName, params.newValue);
        },

        isTotalRowValid(params) {
            const supplier = this.supplierCategories.find(
                s =>
                    s.supplierKey === params.data.supplierKey &&
                    s.categoryKey === params.data.categoryKey
            );
            const supplierLumpFunding = supplier.tmpLumpFunding;

            // when both values are not valid numbers return
            if (isNaN(supplierLumpFunding) && isNaN(params.data.tmpLumpFunding)) return false;
            return supplierLumpFunding === params.data.tmpLumpFunding;
        },

        updateOverallTotalSupplierFunding() {
            const overallSum = this.products
                .filter(p => !p.isSupplierTotal)
                .reduce((total, product) => total + get(product, 'tmpLumpFunding', 0), 0);
            this.setLumpSumTotal(overallSum);
        },
    },
};
</script>
