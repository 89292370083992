<template>
    <div class="total-spend-viewer-target__grid">
        <div class="total-year-target">
            <div class="primary-target">
                <span class="bold-text">{{ $tkey('fundingTarget') | toSentenceCase }}</span>
                {{ $tkey('selectedYear') }}
            </div>
            <div class="primary-target bold-text">
                {{ getCurrencyValue(targetsData.yearTarget) }}
            </div>
            <div>
                <span class="bold-text">{{ $tkey('totals') | toSentenceCase }}</span>
                {{ $tkey('toDate') }}
            </div>
        </div>
        <div class="number-cell bold-text">
            {{ getCurrencyValue(targetsData.commitmentTarget) }}
        </div>
        <div class="number-cell bold-text">
            {{ getCurrencyValue(targetsData.executedTarget) }}
        </div>
        <div class="number-cell">
            {{ getCurrencyValue(targetsData.plannedTarget) }}
        </div>
        <div />
    </div>
</template>

<script>
export default {
    props: {
        targetsData: {
            type: Object,
            required: true,
        },
    },

    localizationKey: 'supplierCommitments.totalSpendTarget',

    methods: {
        getCurrencyValue(value) {
            return this.$n('numbers.default.currency', value, { usePlaceholder: true });
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';
.total-spend-viewer-target__grid {
    font-size: 1.2rem;
    border-bottom: 0.1rem solid $promo-grey;
    display: grid;
    grid-template-columns:
        auto
        15rem
        10rem
        10rem
        45rem;

    .bold-text {
        font-weight: bold;
    }

    > div {
        padding: 1rem;
    }

    .total-year-target {
        display: flex;
        line-height: 1.4rem;
        justify-self: flex-end;

        .primary-target {
            font-size: 1.4rem;
            color: $promo-light-blue;
            margin-right: 2rem;
        }
    }

    .number-cell {
        text-align: right;
        background-color: $rtls-targets-bg-colour;
        border-right: 0.1rem solid $promo-grey;
    }
}
</style>
