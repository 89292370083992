<template>
    <div class="funding-changes-viewer">
        <div class="funding-changes-viewer__grid">
            <promo-ag-grid
                ref="agGrid"
                :row-data="rowData"
                :column-defs="columnDefs"
                :grid-options="gridOptions"
                :default-col-def="defaultColDef"
                :grid-style="gridStyle"
                :grid-class="gridClass"
                dom-layout="autoHeight"
            />
        </div>
        <div class="funding-changes-viewer__actions">
            <v-btn
                depressed
                outlined
                class="funding-changes-viewer__actions--button"
                @click="acceptAll('parent')"
            >
                {{ $tkey('acceptAllParent') | toSentenceCase }}
            </v-btn>
            <v-btn
                depressed
                outlined
                class="funding-changes-viewer__actions--button"
                @click="acceptAll('child')"
            >
                {{ $tkey('acceptAllChild') | toSentenceCase }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isEmpty, map, pickBy, has } from 'lodash';
import parentChildTypes from '@enums/parent-child';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';
import AgTextWithTooltip from '@/js/components/promo-ag-grid/ag-text-with-tooltip';
import ButtonField from '@/js/components/promo-ag-grid/ag-button-field';

export default {
    localizationKey: 'planning.parentChanges',
    props: {
        childProducts: {
            type: Array,
            default: null,
        },
        parentChangeset: {
            type: Object,
            default: null,
        },
        selectAllChild: {
            type: Boolean,
            default: false,
        },
        selectAllParent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                filter: true,
                menuTabs: [],
            },
            gridOptions: {
                suppressContextMenu: true, // only show filter
                rowHeight: 35, // Specified in pixels.
                headerHeight: 30, // Specified in pixels.
                groupHeaderHeight: 25, // Specified in pixels.
                columnTypes: {
                    productField: {
                        cellClass: ['parent-child'],
                        maxWidth: 80,
                    },
                    childField: {
                        headerClass: ['border-left__child'],
                        cellClass: ['parent-child', 'border-left__child'],
                        cellClassRules: {
                            'parent-child--selected': params =>
                                params.data.selectedChangeset === parentChildTypes.child,
                            'parent-child--not-selected': params =>
                                params.data.selectedChangeset !== parentChildTypes.child,
                        },
                        maxWidth: 150,
                    },
                    parentField: {
                        headerClass: ['border-left__parent'],
                        cellClass: ['parent-child', 'border-left__parent'],
                        cellClassRules: {
                            'parent-child--selected': params =>
                                params.data.selectedChangeset === parentChildTypes.parent,
                            'parent-child--not-selected': params =>
                                params.data.selectedChangeset !== parentChildTypes.parent,
                        },
                        maxWidth: 150,
                    },
                    parentChildButton: {
                        headerName: '',
                        headerHeight: 0,
                        filter: false,
                        maxWidth: 70,
                        cellRendererFramework: ButtonField,
                        cellRendererParams: {
                            onClick: params => {
                                params.data.selectedChangeset = params.value;
                                this.selectChanges(params.value, params.data.child.productKey);
                            },
                        },
                    },
                },
            },
            gridStyle: 'width: 144rem; height: 100%;',
            gridClass: 'ag-theme-custom__parent-child-changes',
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'generalConfig']),
        ...mapGetters('clientConfig', ['currencySymbol']),

        rowData() {
            if (!this.parentChangeset || isEmpty(this.parentChangeset)) {
                return [];
            }

            let data = [];
            const promotionProducts = this.childProducts;
            const funding = this.parentChangeset;
            const productsMap = promotionProducts.reduce((acc, product) => {
                acc[product.productKey] = product;
                return acc;
            }, {});

            if (!isEmpty(funding)) {
                // Here we want to only display funding changes where supplierCompensation has been changed,
                // so the user can choose whether to accept it.
                data = map(
                    pickBy(funding, (item, key) => {
                        // filter out products with funding changes that are not on the promotion
                        return productsMap[key] && has(item, 'supplierCompensation');
                    }),
                    (changeset, key) => {
                        return {
                            child: { ...productsMap[key] },
                            parent: changeset,
                            selectedChangeset: parentChildTypes.parent,
                        };
                    }
                );
            }

            return data;
        },
        columnDefs() {
            return [
                {
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.productKey`)),
                    field: 'child.clientProductKey',
                    type: 'productField',
                },
                {
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.productName`)),
                    field: 'child.name',
                    type: 'productField',
                    cellRendererFramework: AgTextWithTooltip,
                    maxWidth: 260,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.brandName`)),
                    field: 'child.brandDescription',
                    type: 'productField',
                    maxWidth: 100,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.supplierName`)),
                    field: 'child.supplierName',
                    type: 'productField',
                    cellRendererFramework: AgTextWithTooltip,
                    maxWidth: 100,
                },
                {
                    headerName: toSentenceCase(this.$tkey(`gridHeadings.sizeAndUnits`)),
                    valueGetter: params =>
                        this.getFormattedProductSizeAndUnit(params, parentChildTypes.child),
                    type: 'productField',
                },
                {
                    headerName: toSentenceCase(
                        this.$tkey(`gridHeadings.originalCost`, {
                            currencySymbol: this.currencySymbol,
                        })
                    ),
                    field: 'child.onInvoiceCosts.avgCost',
                    valueFormatter: this.formatSupplierPriceCellAsCurrency,
                    type: 'productField',
                    maxWidth: 120,
                },
                {
                    headerName: toSentenceCase(this.$tkey('gridHeadings.variableFunding')),
                    headerClass: 'bold-text',
                    children: [
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.child')),
                            headerClass: ['border-left__child', 'bold-text'],
                            children: [
                                {
                                    headerName: toSentenceCase(
                                        this.$tkey(`gridHeadings.supplierCompensation`, {
                                            currencySymbol: this.currencySymbol,
                                        })
                                    ),
                                    field: 'child.funding.variableFunding.supplierCompensation',
                                    type: 'childField',
                                    valueFormatter: this.formatSupplierPriceCellAsCurrency,
                                },
                                {
                                    headerName: toSentenceCase(
                                        this.$tkey(`gridHeadings.estimatedValue`, {
                                            currencySymbol: this.currencySymbol,
                                        })
                                    ),
                                    type: 'childField',
                                    valueFormatter: this.formatCellValueAsCurrency,
                                    valueGetter: this.getOriginalEstimated,
                                },
                            ],
                        },
                        {
                            headerName: toSentenceCase(this.$tkey('gridHeadings.parent')),
                            headerClass: ['border-left__parent', 'bold-text'],
                            children: [
                                {
                                    headerName: toSentenceCase(
                                        this.$tkey(`gridHeadings.supplierCompensation`, {
                                            currencySymbol: this.currencySymbol,
                                        })
                                    ),
                                    field: 'parent.supplierCompensation',
                                    type: 'parentField',
                                    valueFormatter: this.formatSupplierPriceCellAsCurrency,
                                },
                                {
                                    headerName: toSentenceCase(
                                        this.$tkey(`gridHeadings.estimatedValue`, {
                                            currencySymbol: this.currencySymbol,
                                        })
                                    ),
                                    type: 'parentField',
                                    valueFormatter: this.formatCellValueAsCurrency,
                                    valueGetter: this.getChangesetEstimated,
                                },
                            ],
                        },
                        {
                            headerName: toSentenceCase(
                                this.$tkey(`gridHeadings.${parentChildTypes.accept}`)
                            ),
                            headerClass: 'bold-text',
                            children: [
                                {
                                    fieldName: 'childButton',
                                    type: 'parentChildButton',
                                    cellClass: [
                                        'parent-child',
                                        'parent-child__button',
                                        'parent-child__button--child',
                                    ],
                                    cellClassRules: {
                                        'parent-child__button--child-selected': params =>
                                            params.data.selectedChangeset ===
                                            params.colDef.cellRendererParams.value,
                                    },
                                    cellRendererParams: {
                                        text: toSentenceCase(
                                            this.$tkey(`gridHeadings.${parentChildTypes.child}`)
                                        ),
                                        value: parentChildTypes.child,
                                    },
                                },
                                {
                                    fieldName: 'parentButton',
                                    type: 'parentChildButton',
                                    cellClass: [
                                        'parent-child',
                                        'parent-child__button',
                                        'parent-child__button--parent',
                                    ],
                                    cellClassRules: {
                                        'parent-child__button--parent-selected': params =>
                                            params.data.selectedChangeset ===
                                            params.colDef.cellRendererParams.value,
                                    },
                                    cellRendererParams: {
                                        text: toSentenceCase(
                                            this.$tkey(`gridHeadings.${parentChildTypes.parent}`)
                                        ),
                                        value: parentChildTypes.parent,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ];
        },
    },
    watch: {
        selectAllChild() {
            this.acceptAll(parentChildTypes.child);
        },
        selectAllParent() {
            this.acceptAll(parentChildTypes.parent);
        },
    },
    methods: {
        getFormattedProductSizeAndUnit(params, header) {
            const data = params.data[`${header}`];
            if (data.packSize !== undefined && data.packUnit) {
                return `${data.packSize}${data.packUnit}`;
            }
        },

        formatCellValueAsCurrency(params) {
            return i18n.n('numbers.default.currencyNoLabelPadded', params.value, {
                usePlaceholder: true,
            });
        },

        formatSupplierPriceCellAsCurrency(params) {
            return i18n.n(
                `numbers.default.${
                    this.generalConfig.displaySupplierPrices3dp
                        ? 'currencyNoLabelPadded3dp'
                        : 'currencyNoLabelPadded'
                }`,
                params.value,
                {
                    usePlaceholder: true,
                }
            );
        },

        getOriginalEstimated(params) {
            const { funding, volumes } = params.data.child;

            return funding.variableFunding.unitFundingValue * volumes.totalVolume;
        },

        getChangesetEstimated(params) {
            const { volumes } = params.data.child;
            const { unitFundingValue } = params.data.parent;

            return unitFundingValue * volumes.totalVolume;
        },

        selectChanges(value, productKey) {
            if (value === parentChildTypes.parent) {
                const funding = this.parentChangeset[`${productKey}`];
                this.$emit('select-funding', { value: funding, productKey });
            } else {
                this.$emit('select-funding', { value: null, productKey });
            }
        },

        acceptAll(selectedChangeset) {
            const data = this.rowData.map(row => {
                this.selectChanges(parentChildTypes[`${selectedChangeset}`], row.child.productKey);
                return { ...row, selectedChangeset: parentChildTypes[`${selectedChangeset}`] };
            });

            this.gridOptions.api.setRowData(data);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.funding-changes-viewer {
    @include flex-column;

    &__actions {
        width: 100%;
        background-color: $promo-white;

        &--button {
            float: right;
            margin: 0.5rem;
            color: $promo-primary-colour;
        }
    }
}

.funding-changes-viewer::v-deep {
    .ag-header {
        border-bottom: none !important;
    }

    .ag-header-row {
        border-bottom: solid 0.1rem $promo-table-blue-bg-colour !important;
    }

    .border-left {
        border-left: solid 1px !important;
        border-left-color: $promo-menu-border-colour !important;
    }

    .bold-text {
        font-weight: bold;
    }
}
</style>
