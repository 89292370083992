import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible && _vm.isEntityEverWasSubmitted
    ? _c(
        "div",
        { staticClass: "task-buttons-dropdown" },
        [
          _c(
            VMenu,
            {
              attrs: {
                "offset-y": "",
                "nudge-right": "6",
                "nudge-top": "2",
                "min-width": "26",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "task-buttons-dropdown__activator-wrapper",
                          },
                          [
                            _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "notes-icon",
                                    attrs: { icon: "", color: "primary" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("icon", {
                                  attrs: {
                                    "icon-name": _vm.activatorIcon,
                                    color: "promo-light-blue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                920068427
              ),
              model: {
                value: _vm.isOpenModel,
                callback: function ($$v) {
                  _vm.isOpenModel = $$v
                },
                expression: "isOpenModel",
              },
            },
            [
              _vm._v(" "),
              _vm._l(_vm.tasks, function (task) {
                return _c(
                  "div",
                  {
                    key: `${task.task}::${task.isNegativeAction}`,
                    staticClass: "task-buttons-dropdown__task",
                  },
                  [
                    _c(
                      "task-button",
                      _vm._b(
                        {
                          attrs: {
                            "entity-type": _vm.entityType,
                            "entity-ids": _vm.entityIds,
                            "sub-campaign-id": _vm.subCampaignId,
                            "owning-entity-id": _vm.owningEntityId,
                            icon: true,
                            categories: _vm.categories,
                            "is-store-wide-promotion": _vm.isStoreWidePromotion,
                            disabled: _vm.isDisabled,
                            "disabled-reason": _vm.disabledReason,
                          },
                          on: {
                            click: () => _vm.$emit("click"),
                            "action-completed": () =>
                              _vm.$emit("action-completed"),
                          },
                        },
                        "task-button",
                        task,
                        false
                      )
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }