import { merge, keyBy, map, pick } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    storeGroups: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     * - filter
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getStoreGroupById
     * - getFilteredStoreGroups
     */
    getters: {
        getStoreGroups: state => state.storeGroups,
        getStoreGroupsOptions: state =>
            state.storeGroups.map(storeGroup =>
                pick(storeGroup, ['_id', 'key', 'description', 'clientStoreGroupKey', 'attributes'])
            ),
        getStoreGroupKeys: state => map(state.storeGroups, 'key'),
        getFilteredStoreGroups: state => ({ storeGroups }) =>
            state.storeGroups.filter(storeGroup => storeGroups.includes(storeGroup.key)),
        getDescriptions: state => storeGroups =>
            state.storeGroups
                .filter(storeGroup => storeGroups.includes(storeGroup.key))
                .map(storeGroup => storeGroup.description)
                .join(', '),
        storeGroupsMapByKey: state => keyBy(state.storeGroups, 'key'),
        storeGroupsMapByClientKey: state => keyBy(state.storeGroups, 'clientStoreGroupKey'),
        getStoresByKey: state => storeGroupKey =>
            state.storeGroups.find(storeGroup => storeGroup.key === storeGroupKey).stores,
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setStoreGroups
     * - deleteStoregroup
     * - updateStoreGroup
     * - addStoreGroup
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchStoreGroups
     * - deleteStoreGroup
     * - updateStoregroup
     * - createStoreGroup
     * - submitForm
     * - handleResponseNotifications
     * - setSelectedFilter
     * - resetFilter
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'store-group',
    useFilters: true,
    readOnly: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
