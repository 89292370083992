import { merge } from 'lodash';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    offerMechanicPresets: [],
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getOfferMechanicPresetsById
     */
    getters: {
        offerMechanicPresetsKeyed(state) {
            return state.offerMechanicPresets.reduce((acc, ofp) => {
                acc[ofp.offerTemplate] = ofp.templateStructure;
                return acc;
            }, {});
        },
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setOfferMechanicPresets
     * - deleteOfferMechanicPresets
     * - updateOfferMechanicPresets
     * - addOfferMechanicPresets
     * - resetState
     */
    mutations: {},

    /**
     * Default actions available:
     * - fetchOfferMechanicPresets
     * - createOfferMechanicPresets
     * - deleteOfferMechanicPresets
     * - updateOfferMechanicPresets
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {},
};

const mixinParams = {
    resource: 'offer-mechanic-preset',
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
