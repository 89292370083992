<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { campaigns } from '@enums/resources';
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { componentTypes } from '@enums/custom-components';
import { canEditCampaign, canPublishCampaigns } from '@enums/feature-flags';
import { object } from '@enums/value-types';
import { bind, get } from 'lodash';
import validators from '@/js/validators';
import { getDefaultValuePropName, getDefaultValuePropValue } from '@/js/utils/component-utils';
import commonFormDialogComponent from './common-form-dialog';
import i18n from '@/js/vue-i18n';

const createForm = function({
    isEditable,
    isDetailsOpened,
    isStoreGroupsOpened,
    onDetailsPanelStateChange,
    isPrivateDefault,
    campaignDefaults,
    categoriesTree,
    updateCategoriesInStagingArea,
}) {
    return {
        vuexModule: 'campaigns',
        editable: true,
        addEvent: 'createCampaign',
        editEvent: 'updateCampaign',
        fields: [
            {
                fieldName: 'name',
                label: 'planning.createCampaign.title',
                type: fieldTypes.text,
                placeholder: 'planning.createCampaign.titlePlaceholder',
                cssClass: 'vuex-form__name',
                isRequired: true,
                editable: isEditable,
                dataIdE2E: 'fld-campaign-title',
                validations: [
                    {
                        message: i18n.t('validation.campaign.nameRequired'),
                        validator: validators.required,
                    },
                    // example validation with params
                    // {
                    //     message: i18n.t('validation.campaign.nameMaxLength'),
                    //     validator: validators.maxLength,
                    //     params: [50],
                    // },
                ],
            },
            {
                fieldName: 'briefing',
                label: 'planning.createCampaign.briefing',
                type: fieldTypes.textarea,
                placeholder: 'planning.createCampaign.briefingPlaceholder',
                cssClass: 'vuex-form__briefing',
                editable: isEditable,
                dataIdE2E: 'fld-campaign-briefing',
            },
            {
                label: 'planning.createCampaign.dates',
                type: fieldTypes.dateRange,
                cssClass: 'vuex-form__dates',
                isRequired: true,
                editable: isEditable,
                minDate: {
                    source: sourceTypes.context,
                    path: 'startDate',
                },
                from: {
                    fieldName: 'startDate',
                },
                to: {
                    fieldName: 'endDate',
                },
                entity: 'campaign',
                dataIdE2E: 'fld-campaign-dates',
                validations: [
                    {
                        message: i18n.t('validation.campaign.datesRequired'),
                        validator: validators.required,
                    },
                    {
                        validator: validators.dateRangeRequired,
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createCampaign.additionalDetails',
                cssClass: 'vuex-form__details',
                expansionPanelClass: 'details__wrapper',
                isOpenState: isDetailsOpened,
                change: onDetailsPanelStateChange,
                children: [
                    {
                        fieldName: 'promoResources',
                        type: fieldTypes.iconCheckboxesList,
                        cssClass: 'vuex-form__promo-resources',
                        isRequired: true,
                        editable: isEditable,
                        label: 'planning.promoResources',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'campaigns',
                            params: {
                                attributeName: 'resources',
                                attributeKey: 'type',
                                resourceOptionAttributeKey: 'key',
                                resource: 'clientConfig',
                                getOptionsFunction: 'getPromoResources',
                                campaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                        itemText: 'description',
                        itemValue: 'key',
                        valueType: object,
                        [getDefaultValuePropName(
                            campaignDefaults.promoResources
                        )]: getDefaultValuePropValue(campaignDefaults.promoResources, {
                            source: sourceTypes.getter,
                            identifier: 'getPromoResources',
                            module: 'clientConfig',
                        }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'categories',
                        type: componentTypes.categoriesSelect,
                        tree: categoriesTree,
                        cssClass: 'vuex-form__categories',
                        editable: isEditable,
                        isRequired: true,
                        update: updateCategoriesInStagingArea,
                        [getDefaultValuePropName(
                            campaignDefaults.categories
                        )]: getDefaultValuePropValue(campaignDefaults.categories, {
                            source: sourceTypes.getter,
                            identifier: 'userCategoryKeys',
                            module: 'context',
                        }),
                    },
                    {
                        fieldName: 'customerGroups',
                        type: fieldTypes.checkboxesList,
                        cssClass: 'vuex-form__customer-restrictions',
                        isRequired: true,
                        editable: isEditable,
                        label: 'planning.customerRestrictions',
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'campaigns',
                            params: {
                                attributeName: 'customerGroups',
                                attributeKey: 'key',
                                resource: 'clientConfig',
                                getOptionsFunction: 'getCustomerRestrictions',
                                campaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                            },
                        },
                        itemText: 'description',
                        itemValue: 'key',
                        valueType: object,
                        [getDefaultValuePropName(
                            campaignDefaults.customerRestrictions
                        )]: getDefaultValuePropValue(campaignDefaults.customerRestrictions, {
                            source: sourceTypes.getter,
                            identifier: 'getCustomerRestrictions',
                            module: 'clientConfig',
                        }),
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                ],
            },
            {
                type: componentTypes.expansionPanel,
                isContainer: true,
                label: 'planning.createCampaign.storeGroups',
                cssClass: 'vuex-form__store-groups',
                isOpenState: isStoreGroupsOpened,
                children: [
                    {
                        fieldName: 'storeGroups',
                        type: fieldTypes.select,
                        cssClass: 'vuex-form__store-groups-selector',
                        placeholder: 'planning.createCampaign.storeGroupsPlaceholder',
                        isRequired: true,
                        autocomplete: true,
                        multiple: true,
                        deletableChips: true,
                        chips: true,
                        editable: isEditable,
                        returnObject: true,
                        finiteList: true,
                        options: {
                            source: sourceTypes.getter,
                            identifier: 'getCheckboxListOptions',
                            module: 'campaigns',
                            params: {
                                attributeName: 'storeGroups',
                                attributeKey: 'key',
                                resource: 'storeGroups',
                                getOptionsFunction: 'getStoreGroupsOptions',
                                getUserAccessOptionsMap: 'userStoreGroupsMap',
                                campaignId: {
                                    source: sourceTypes.editContext,
                                    path: '_id',
                                },
                                useReference: false,
                            },
                        },
                        itemText: 'description',
                        itemValue: 'key',
                        valueType: object,
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                ],
            },
            {
                fieldName: 'isPrivate',
                defaultValue: isPrivateDefault,
                editable: isEditable,
            },
        ],
    };
};

export default {
    extends: commonFormDialogComponent,

    props: {
        preparationView: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        resource: campaigns,
        btnCancelId: 'campaignCancelId',
        btnConfirmDialogCancel: 'campaignConfirmDialogCancelId',
        btnConfirmId: 'campaignBtnConfirmId',
        isDetailsOpened: null,
        hasConfirmationDialog: false,
    }),

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'additionalDetailsDefaultSelection']),
        ...mapGetters('hierarchy', ['getCategoriesTree']),
        ...mapGetters('campaigns', ['getSubCampaignsCategories', 'getStagingAreaField']),

        form() {
            return createForm({
                isEditable: !!this.toggleLogic[canEditCampaign],
                isDetailsOpened: this.isDetailsOpened,
                isStoreGroupsOpened: 0,
                onDetailsPanelStateChange: bind(
                    this.onExpansionPanelStateChange,
                    this,
                    'isDetailsOpened'
                ),
                isPrivateDefault: !!this.toggleLogic[canPublishCampaigns],
                campaignDefaults: this.additionalDetailsDefaultSelection.campaign,
                categoriesTree: this.getCategoriesTree({
                    selectedCategories: this.getStagingAreaField({
                        namespace: this.editContext ? this.editContext._id : 'default',
                        fieldName: 'categories',
                    }),
                    childCategories: this.getSubCampaignsCategories({
                        campaignId: get(this.editContext, '_id'),
                    }),
                    isEditable: !!this.toggleLogic[canEditCampaign] && !this.isReadOnly,
                }),
                updateCategoriesInStagingArea: this.updateCategoriesInStagingArea,
            });
        },
        labels() {
            return {
                btnText: 'planning.createCampaign.createButton',
                heading: this.editMode
                    ? 'planning.editCampaign.heading'
                    : 'planning.createCampaign.heading',
                planCampaign: 'planning.planCampaign',
            };
        },
    },

    methods: {
        ...mapActions('campaigns', ['setStagingAreaField']),

        setModalExpansionPanelsState() {
            this.setExpansionPanelState('isDetailsOpened', [
                'customerGroups',
                'categories',
                'promoResources',
            ]);
        },

        planOutCampaign() {
            this.selectCampaignSingleView();
        },

        updateCategoriesInStagingArea(selectedCategories) {
            const namespace = this.editContext ? this.editContext._id : 'default';

            this.setStagingAreaField({
                namespace,
                fieldName: 'categories',
                value: selectedCategories,
            });
        },
    },
};
</script>
