<template>
    <main-dialog
        ref="copy-resource-dialog"
        :show-footer="!showConfirmCopy"
        :confirm-btn-disabled="confirmBtnDisabled"
        heading="preparation.copyDialog.heading"
        btn-confirm-text="actions.copy"
        @confirm="toggleShowConfirmCopy(true)"
        @close="close"
    >
        <template v-slot:actions="{ activator }">
            <v-btn depressed outlined :disabled="copyDocumentBtnDisabled" v-on="activator">
                {{ $t('preparation.buttons.copyDocument') | toSentenceCase }}
            </v-btn>
        </template>
        <template v-slot:default>
            <div v-if="!showConfirmCopy">
                <div v-if="isPagesResource" class="choose-pages-wrapper pt-3">
                    <div class="choose-pages-wrapper__cover-inside-pages">
                        <span class="choose-pages-wrapper__cover-inside-pages--title">
                            {{ $t(`preparation.copyDialog.cover`) | toSentenceCase }}
                        </span>
                        <vuex-checkbox
                            :getter="() => pagesSelections[pageTypes.cover]"
                            :setter="
                                value =>
                                    pagesSelectionsSetter({
                                        value,
                                        type: pageTypes.cover,
                                        applyToAllPages: true,
                                    })
                            "
                            :disabled="pagesSectionDisabled"
                        />
                    </div>
                    <div class="choose-pages-wrapper__cover-inside-pages">
                        <span class="choose-pages-wrapper__cover-inside-pages--title">
                            {{ $t(`preparation.copyDialog.insidePages`) | toSentenceCase }}
                        </span>
                        <vuex-checkbox
                            :getter="() => pagesSelections[pageTypes.inside]"
                            :setter="
                                value =>
                                    pagesSelectionsSetter({
                                        value,
                                        type: pageTypes.inside,
                                        applyToAllPages: true,
                                    })
                            "
                            :disabled="insidePagesDisabled"
                        />
                    </div>
                    <div class="choose-pages-wrapper__all-pages">
                        <span>
                            {{ $t(`preparation.copyDialog.choosePages`) | toSentenceCase }}
                        </span>
                        <v-btn
                            text
                            class="choose-pages-wrapper__all-pages--btn"
                            :disabled="insidePagesDisabled"
                            @click="selectAllHandler"
                        >
                            {{ $t(selectAllPagesLabel) | toSentenceCase }}
                        </v-btn>
                    </div>
                    <div class="choose-pages-wrapper__pages-scroller">
                        <pagination
                            class="pagination-container__pagination"
                            :length="paginationLength"
                            :disabled="insidePagesDisabled"
                            :start-from-second="true"
                            :multi-select-values="selectedPages"
                            is-multi-select
                            :items-in-screen="itemsInScreen"
                            is-basic-navigate-button
                            @input="paginationHandler"
                        />
                    </div>
                </div>
                <div v-if="isPagesResource" class="checkbox-wrapper">
                    <div
                        v-for="(checkbox, index) in checkboxes"
                        :key="index"
                        class="checkbox-wrapper__row"
                    >
                        <div class="checkbox-wrapper__row--title">
                            {{ $t(`preparation.copyDialog.${checkbox.key}`) | toSentenceCase }}
                        </div>
                        <div class="checkbox-wrapper__row--cover">
                            <vuex-checkbox
                                :getter="
                                    () =>
                                        checkboxesGetter({
                                            checkboxKey: checkbox.key,
                                            pageType: pageTypes.cover,
                                        })
                                "
                                :setter="
                                    value =>
                                        checkboxesSetter({
                                            value,
                                            checkboxKey: checkbox.key,
                                            pageType: pageTypes.cover,
                                        })
                                "
                                :disabled="isDisabled(checkbox.deps, pageTypes.cover)"
                            />
                        </div>
                        <div class="checkbox-wrapper__row--inside">
                            <vuex-checkbox
                                :getter="
                                    () =>
                                        checkboxesGetter({
                                            checkboxKey: checkbox.key,
                                            pageType: pageTypes.inside,
                                        })
                                "
                                :setter="
                                    value =>
                                        checkboxesSetter({
                                            value,
                                            checkboxKey: checkbox.key,
                                            pageType: pageTypes.inside,
                                        })
                                "
                                :disabled="isDisabled(checkbox.deps, pageTypes.inside)"
                            />
                        </div>
                    </div>
                </div>
                <h4 class="copy-resource__text">
                    {{ $t('preparation.copyDialog.copyTo') | toSentenceCase }}
                </h4>
                <document-scroller
                    v-if="resourceDefinitions.length"
                    :documents="resourceDefinitions"
                    :selected-document-key="documentToCopyTo"
                    :num-documents-to-show="3"
                    :select-document-on-open="false"
                    class="copy-resource__document-scroller"
                    @document-selected="setDocumentToCopyTo"
                />
                <div v-else class="copy-resource__text">
                    {{ $t('preparation.copyDialog.noDocumentsAvailable') }}
                </div>
            </div>
            <confirm-action
                v-else
                :action-text="$t('preparation.copyDialog.confirmCopyAction')"
                :question-text="$t('preparation.copyDialog.confirmCopyQuestion')"
                :warning-text="$t('preparation.copyDialog.confirmCopyWarning')"
                @cancel="toggleShowConfirmCopy(false)"
                @confirm="copyDocument"
            />
        </template>
    </main-dialog>
</template>

<script>
import uuid from 'uuid/v4';
import { mapState, mapGetters, mapActions } from 'vuex';
import { isNil } from 'lodash';
import PageTypes from '@enums/leaflet-page-types';
import vuexComponentMixin from '@/js/mixins/vuex-component';
import checkboxes from './copy-resource-definition-config';

export default {
    mixins: [vuexComponentMixin],
    props: ['promoResource'],
    data() {
        return {
            documentToCopyTo: null,
            showConfirmCopy: false,
            checkboxes,
            pageTypes: PageTypes,
            pagesSelections: {
                [PageTypes.cover]: false,
                [PageTypes.inside]: false,
            },
            sectionSelections: {
                templates: {
                    [PageTypes.cover]: false,
                    [PageTypes.inside]: false,
                },
                categories: {
                    [PageTypes.cover]: false,
                    [PageTypes.inside]: false,
                },
                promotions: {
                    [PageTypes.cover]: false,
                    [PageTypes.inside]: false,
                },
                products: {
                    [PageTypes.cover]: false,
                    [PageTypes.inside]: false,
                },
            },
            selectedPages: [],
            defaultPagesCount: 8,
            itemsInScreen: 7,
        };
    },
    computed: {
        ...mapState('subCampaigns', ['stagingArea', 'selectedResourceDefinitionKey']),
        ...mapGetters('subCampaigns', [
            'selectedResourceDefinition',
            'getResourceDefinitionsForResourceType',
            'isPagesResource',
        ]),
        resourceDefinitions() {
            return this.getResourceDefinitionsForResourceType(this.promoResource).filter(
                // We should not be able to copy to the same document
                def => def.key !== this.selectedResourceDefinitionKey
            );
        },
        confirmBtnDisabled() {
            if (this.isPagesResource) {
                const isChecked = Object.values(this.sectionSelections).find(
                    value => value.cover || value.inside
                );
                return isNil(this.documentToCopyTo) || !isChecked;
            }

            return isNil(this.documentToCopyTo);
        },
        copyDocumentBtnDisabled() {
            return this.isReadOnly || isNil(this.selectedResourceDefinitionKey);
        },
        pagesSectionDisabled() {
            return isNil(this.documentToCopyTo);
        },
        availablePagesCount() {
            const doc = this.model.find(def => def.key === this.documentToCopyTo);

            if (!doc) {
                return this.defaultPagesCount;
            }

            return Math.min(doc.pageCount, this.selectedResourceDefinition.pageCount);
        },
        paginationLength() {
            return this.availablePagesCount === 1
                ? this.defaultPagesCount
                : this.availablePagesCount;
        },
        selectAllPagesLabel() {
            return this.pagesSelections[PageTypes.inside]
                ? 'preparation.copyDialog.unselectAll'
                : 'preparation.copyDialog.selectAll';
        },
        insidePagesDisabled() {
            // in case if we have just 1 page all inside pages are disabled
            // it means that we have just cover page
            return this.pagesSectionDisabled || this.availablePagesCount === 1;
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        close() {
            this.reset();
            this.$refs['copy-resource-dialog'].close();
        },
        reset() {
            this.documentToCopyTo = null;
            this.showConfirmCopy = false;
            this.resetPagination();
        },
        setDocumentToCopyTo(selectedDocumentKey) {
            this.documentToCopyTo = selectedDocumentKey;
            this.resetPagination();
        },
        toggleShowConfirmCopy(selection) {
            this.showConfirmCopy = selection;
        },
        copyDocument() {
            const documentToCopyToIndex = this.model.findIndex(
                def => def.key === this.documentToCopyTo
            );
            const documentToCopyTo = this.model[documentToCopyToIndex];
            let updatedDocument;

            if (!this.isPagesResource) {
                updatedDocument = {
                    ...this.selectedResourceDefinition,
                    key: documentToCopyTo.key,
                    storeGroups: documentToCopyTo.storeGroups,
                    pages: this.selectedResourceDefinition.pages.map(page => ({
                        ...page,
                        notes: [],
                        key: uuid(),
                    })),
                };
            } else {
                if (this.pagesSelections.cover) {
                    this.selectedPages.push(1);
                }

                updatedDocument = {
                    ...documentToCopyTo,
                    pages: documentToCopyTo.pages.map(page => {
                        if (!this.selectedPages.includes(page.pageNumber)) {
                            return page;
                        }
                        const toCopy = this.getCopyPartition(page);
                        return {
                            ...page,
                            notes: [],
                            key: uuid(),
                            ...toCopy,
                        };
                    }),
                };
            }

            this.$set(this.model, documentToCopyToIndex, updatedDocument);
            this.addNotification({
                message: this.$t('preparation.copyDialog.copiedSuccessMessage'),
            });
            this.close();
        },
        checkboxesGetter({ checkboxKey, pageType }) {
            return this.sectionSelections[checkboxKey][pageType];
        },
        checkboxesSetter({ value, checkboxKey, pageType }) {
            this.sectionSelections[checkboxKey][pageType] = value;
            if (!value) {
                this.resetDeps({ checkboxKey, pageType });
            }
        },
        isDisabled(deps, pageType) {
            if (
                !this.resourceDefinitions.length ||
                (pageType === PageTypes.inside &&
                    this.selectedResourceDefinition &&
                    this.selectedResourceDefinition.pageCount === 1)
            ) {
                return true;
            }

            return (
                deps.some(dep => !this.sectionSelections[dep][pageType]) ||
                !this.pagesSelections[pageType]
            );
        },
        resetDeps({ checkboxKey, pageType }) {
            this.checkboxes.forEach(item => {
                if (item.deps.includes(checkboxKey)) {
                    this.sectionSelections[item.key][pageType] = false;
                }
            });
        },
        getCopyPartition(page) {
            const type = page.pageNumber === 1 ? PageTypes.cover : PageTypes.inside;

            const sourcePage = this.selectedResourceDefinition.pages.find(
                pageItem => pageItem.pageNumber === page.pageNumber
            );

            const partition = {
                layoutTemplate: page.layoutTemplate,
                assignment: page.assignment,
            };
            if (this.sectionSelections.templates[type]) {
                partition.layoutTemplate = sourcePage.layoutTemplate;
                partition.assignment = sourcePage.assignment.map(item => ({
                    area: item.area,
                    categoryKey: this.sectionSelections.categories[type] ? item.categoryKey : null,
                    promotionId: this.sectionSelections.promotions[type] ? item.promotionId : null,
                    products: this.sectionSelections.products[type] ? item.products : [],
                }));
            }
            return partition;
        },
        cleanCheckboxes() {
            this.pagesSelectionsSetter({ value: false, type: PageTypes.cover });
            this.pagesSelectionsSetter({ value: false, type: PageTypes.inside });
        },
        pagesSelectionsSetter({ value, type, applyToAllPages }) {
            this.pagesSelections[type] = value;
            if (!value) {
                this.checkboxesSetter({ value: false, checkboxKey: 'templates', pageType: type });
            }

            if (applyToAllPages && type === PageTypes.inside) {
                this.setAllPagesState({ value });
            }
        },
        paginationHandler(page) {
            if (this.selectedPages.includes(page)) {
                this.selectedPages = this.selectedPages.filter(
                    selectedPage => selectedPage !== page
                );
            } else {
                this.selectedPages.push(page);
            }

            this.setupInsideCheckbox();
        },
        setupInsideCheckbox() {
            if (this.selectedPages.length) {
                this.pagesSelectionsSetter({ value: true, type: PageTypes.inside });
            } else {
                this.pagesSelectionsSetter({ value: false, type: PageTypes.inside });
            }
        },
        setAllPagesState({ value }) {
            if (!value) {
                this.selectedPages = [];
            } else {
                let pagesCount = this.availablePagesCount;
                while (pagesCount >= 2) {
                    this.selectedPages.push(pagesCount);
                    pagesCount -= 1;
                }
            }
        },
        selectAllHandler() {
            this.pagesSelectionsSetter({
                value: !this.pagesSelections.inside,
                type: PageTypes.inside,
                applyToAllPages: true,
            });
        },
        resetPagination() {
            this.cleanCheckboxes();
            this.selectedPages = [];
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.copy-resource {
    &__text {
        font-size: 1.2rem;
        font-weight: 1.2rem;
        margin-top: 0.5rem;
    }
    &__document-scroller {
        width: 60rem;
    }
}

.checkbox-wrapper {
    border-bottom: 1px solid $promo-divider-colour;
    border-top: 1px solid $promo-divider-colour;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 0.5rem;

    &__row {
        display: grid;
        grid-template-columns: 12rem 5rem 7rem;
        font-size: 1.2rem;

        &--title {
            grid-area: span 1;
        }

        &--cover {
            grid-area: span 2;
        }

        &--inside {
            grid-area: span 3;
            display: flex;
            justify-content: flex-end;
        }
    }
}
.choose-pages-wrapper {
    display: grid;
    grid-template-columns: 14rem 10rem 11rem auto;
    font-size: 1.2rem;
    margin-top: 0.5rem;

    &__cover-inside-pages {
        display: flex;
        justify-content: flex-end;

        &--title {
            margin-right: 1.2rem;
        }
    }

    &__all-pages {
        display: flex;
        flex-direction: column;
        border-right: 0.1rem solid $promo-divider-colour;
        align-items: flex-end;
        padding-right: 0.5rem;

        &--btn {
            padding: 0 !important;
            color: $rtls-primary-colour;
            font-weight: bold;
        }
    }

    &__pages-scroller {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0.5rem;
    }
}
</style>
