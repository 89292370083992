import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      staticClass: "confirm-dialog",
      attrs: { persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.hasActivator
            ? {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _vm._t(
                      "actions",
                      function () {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  small: "",
                                  depressed: "",
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("toSentenceCase")(
                                      _vm.$t(_vm.buttonText)
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                      { activator: on }
                    ),
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "confirm-dialog__container", attrs: { tile: "" } },
        [
          _c(
            "div",
            { staticClass: "confirm-dialog__header" },
            [
              _c(
                VBtn,
                { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                [
                  _c("icon", {
                    attrs: {
                      "icon-name": "cancel",
                      small: "",
                      "icon-colour-type": "primary-path",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("confirm-action", {
            staticClass: "confirm-dialog__action",
            attrs: {
              "question-text": _vm.questionText,
              "warning-text": _vm.warningText,
              "action-text": _vm.actionText,
            },
            on: {
              cancel: function ($event) {
                return _vm.closeDialog()
              },
              confirm: function ($event) {
                return _vm.confirm()
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "warningContent",
                  fn: function () {
                    return [_vm._t("warningContent")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }