var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      class: `alert-count-badge alert-count-badge--${_vm.props.priority.toLowerCase()}`,
    },
    [_vm._v("\n    " + _vm._s(_vm.props.alertCount) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }