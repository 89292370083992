var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "supply-form-wrapper" },
    [
      _c(
        "supply-tab",
        _vm._b(
          { attrs: { "form-ref": _vm.formRef, "cache-dom": _vm.cacheDom } },
          "supply-tab",
          _vm.form,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }