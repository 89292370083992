import { find, filter, some, sumBy, get, join, map, includes } from 'lodash';
import measurements from '@enums/measurements';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';

/**
 * Returns measurements options
 */
export function getMeasurementsOptions() {
    return Object.keys(measurements).map(key => ({
        value: measurements[key],
        text: toSentenceCase(i18n.t(`supplierCommitments.measurements.${measurements[key]}`)),
    }));
}

/**
 * Counts the number of promotion products that have a matching supplier and category.
 *
 * @param {Array} promotions - The promotions allocated to the supplier commitment.
 * @param {Number} categoryLevel - The category level in the hierarchy.
 * @param {Array} supplierCommitmentCategories - The categories of supplier commitment.
 * @param {Number} supplierKey - A supplier key.
 */
export function getProductCount({
    promotions,
    categoryLevel,
    supplierCommitmentCategories,
    supplierKey,
}) {
    return sumBy(promotions, promotion => {
        const products = filter(promotion.products, product => {
            const { levelEntryDescription } = find(product.hierarchy, {
                level: categoryLevel,
            });

            return (
                product.supplierKey === supplierKey &&
                includes(supplierCommitmentCategories, levelEntryDescription)
            );
        });

        return products.length;
    });
}

/**
 * Counts the number of executed and planned promotions/products based on the
 * measurement attribute of the supplier commitment.
 *
 * @param {Array} promotions - The promotions allocated to the supplier commitment.
 * @param {String} detailedProvisionKey - The detailedProvisionKey of the instance to count.
 * @param {String} resourceKey - The resourceKey of the instance to count.
 * @param {Object} supplierCommitment - The supplier commitment.
 * @param {Number} categoryLevel - The category level in the hierarchy.
 * @param {Array} detailedProvisions - A list of detailed provisions available.
 */
export function getMeasurementCounts({
    promotions,
    detailedProvisionKey,
    resourceKey,
    supplierCommitment,
    categoryLevel,
    detailedProvisions,
}) {
    if (!supplierCommitment) return 0;

    const { measurement, supplierKey, hierarchy } = supplierCommitment;
    const detailedProvision = find(detailedProvisions, {
        type: resourceKey,
    });

    return ['executed', 'planned'].reduce((acc, key) => {
        // Filter promotions where instances are present with a matching
        // detailedProvisionKey.
        const filteredPromotions = filter(promotions[key], promotion => {
            const filteredResources =
                resourceKey === 'any'
                    ? promotion.resources
                    : filter(promotion.resources, { type: resourceKey });

            return some(filteredResources, resource =>
                some(resource.instances, instance => {
                    const matchingDetailedProvision =
                        detailedProvision &&
                        instance.detailedProvision.find(dp => dp.name === detailedProvision.name);
                    return (
                        get(matchingDetailedProvision, 'value') === detailedProvisionKey ||
                        detailedProvisionKey === 'any'
                    );
                })
            );
        });

        // If the measurement of the supplier commitment is at promotion
        // level, then we can return the promotion count here.
        if (measurement === measurements.promotion) {
            acc[key] = filteredPromotions.length;
            return acc;
        }

        // If the measurement of the supplier commitment is at product
        // level, then we need to count the products of the filteredPromotions
        // that have a matching supplier and category.
        acc[key] = getProductCount({
            promotions: filteredPromotions,
            categoryLevel,
            supplierKey,
            supplierCommitmentCategories: map(hierarchy, 'levelEntryDescription'),
        });

        return acc;
    }, {});
}

export function getPeriod({ startDate, endDate }) {
    const formattedStartDate = i18n.d('dates.default.dateShorthand', startDate);
    const formattedEndDate = i18n.d('dates.default.dateShorthand', endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
}

/**
 * Calculates the remaining lump funding by subtracting the executed and planned
 * lump funding from the target lump funding value.
 *
 * @param {Number} value - The target lump funding value.
 * @param {Number} executed - Lump funding associated with a executed promotion.
 * @param {Number} planned - Lump funding associated with a planned promotion.
 */
export function getRemainingLumpFunding({ fundingValue, executed, planned }) {
    return (fundingValue || 0) - (executed || 0) - (planned || 0);
}

/**
 * Concatenates the client supplier key and name.
 *
 * @param {String} clientSupplierKey - The client supplier key.
 * @param {String} name - The supplier name.
 */
export function getSupplierKeyName({ supplier: { clientSupplierKey, name } }) {
    return `${clientSupplierKey} - ${name}`;
}

/**
 * Returns category names separated by commas
 *
 * @param {Array} hierarchy - The item hierarchy field
 * @returns {string}
 */
export function getCategoryNames({ hierarchy }) {
    return join(map(hierarchy, 'levelEntryDescription'), ', ');
}
