<template>
    <div class="number-input" :class="cssClass">
        <span v-if="prefix" class="number-input--prefix"> {{ prefix }} </span>
        <input
            ref="input"
            v-model="formattedNumber"
            name="input-text-field"
            :disabled="isDisabled"
            reverse
            :class="`${defaultCss} ${validationErrorCss}`"
            :title="validationError"
            :placeholder="placeholder"
            @keypress="
                params =>
                    positiveOnly
                        ? validatePositiveIntegerInput(params)
                        : validateIntegerInput(params)
            "
            @paste="validateNumberOnPaste"
            @change="updateModel"
        />
        <span v-if="suffix" class="number-input--suffix"> {{ suffix }} </span>
    </div>
</template>

<script>
import { isNil, isEmpty } from 'lodash';
import numeral from 'numeral';
import inputTypes from '@enums/input-types';
import vuexComponentMixin from '../../../../mixins/vuex-component';
import validateNumberInputMixin from '../../../../mixins/validate-number-input';

const defaultFormat = 'numbers.default.number';

export default {
    mixins: [vuexComponentMixin, validateNumberInputMixin],
    props: {
        disabled: Boolean,
        change: {
            type: Function,
            default: () => {},
        },
        format: {
            type: String,
            default: defaultFormat,
        },
        placeholder: {
            type: String,
            default: '—',
        },
        prefix: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        saveAsString: {
            type: Boolean,
            default: () => false,
        },
        positiveOnly: {
            type: Boolean,
            default: false,
        },
        fieldType: String,
        validations: Array,
        cssClass: String,
        size: String,
    },

    data() {
        return {
            validationError: null,
            numberInput: null,
            defaultCss: `rtls-text-field rtls-text-field--${this.size ||
                'small'} rtls-text-field--white`,
        };
    },

    computed: {
        formattedNumber: {
            get() {
                if ([inputTypes.percentage, inputTypes.plainPercentage].includes(this.fieldType)) {
                    const isPlainValue = this.fieldType === inputTypes.plainPercentage;
                    return this.percentageGetter(this.model, isPlainValue);
                }

                const value = !this.saveAsString ? this.$n(this.format, this.model) : this.model;
                return this.defaultGetter(value);
            },
            set(newValue) {
                if ([inputTypes.percentage, inputTypes.plainPercentage].includes(this.fieldType)) {
                    const isPlainValue = this.fieldType === inputTypes.plainPercentage;
                    this.percentageSetter(newValue, isPlainValue);
                } else {
                    // Format the inputted number. This applies any rounding required for the
                    // number formatting. E.g. 10.6666 with format '0.0' will convert to 10.7
                    const formattedInput = this.$n(this.format, newValue);

                    this.defaultSetter(formattedInput);
                }
            },
        },

        validationErrorCss() {
            return this.validationError ? 'validation-error' : '';
        },

        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    watch: {
        model() {
            this.validate();
        },
    },

    created() {
        this.numberInput = this.model;
    },

    methods: {
        updateModel() {
            this.model = this.numberInput;
            this.$emit('change', this.numberInput);
        },
        validate() {
            if (!this.validations || isEmpty(this.validations)) {
                return true;
            }

            const rules = this.validations
                .map(validation =>
                    validation.validator(
                        this.formattedNumber,
                        validation.message,
                        ...validation.params
                    )
                )
                .filter(v => v !== true);
            if (!isEmpty(rules)) {
                this.validationError = rules[0];

                return false;
            }

            this.validationError = null;
            return true;
        },
        defaultSetter(newValue) {
            this.numberInput = this.saveAsString ? newValue : numeral(newValue).value();
        },
        defaultGetter(value) {
            return isNil(this.model) ? null : value;
        },
        percentageSetter(newValue, isPlainValue) {
            if (isNil(newValue) || newValue === '') {
                this.numberInput = null;
            } else {
                this.numberInput = isPlainValue
                    ? numeral(newValue).value()
                    : numeral(newValue).value() / 100;
            }
        },
        percentageGetter(value, isPlainValue) {
            return isNil(this.model)
                ? null
                : this.$n('numbers.default.numberWithDecimals', isPlainValue ? value : value * 100);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.number-input {
    &--prefix {
        left: 2rem;
        position: relative;
    }

    &--suffix {
        padding-right: 1rem;
    }
    .rtls-text-field {
        &--small {
            width: 4rem;
        }

        &--medium {
            width: 6rem;
        }

        &--large {
            width: 8rem;
        }
        height: 2.5rem;
        padding-right: 0.4rem;

        background-image: none !important;

        &.readonly {
            display: block;
        }

        &:focus {
            background-image: none !important;
            outline: none !important;
        }

        &.validation-error {
            color: $promo-error;
            border: 0.1rem solid $promo-error;
            border-radius: 0.5rem;

            &:focus {
                border: 0.2rem solid $promo-error !important;
            }
        }
    }
}
</style>
