import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.selectedResourceDefinition
      ? _c(
          "div",
          { staticClass: "allocation-area-grid" },
          [
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(2),
              },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("preparation.allocation.categoryAllocation")
                        )
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "allocation-area-grid__cell row-right-align" },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("preparation.allocation.thisPage")
                      )
                    ) + "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "allocation-area-grid__cell row-right-align" },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("preparation.allocation.total")
                      )
                    ) + "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "allocation-area-grid__cell row-right-align" },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("preparation.allocation.percentSymbol")
                      )
                    ) + "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "allocation-area-grid__cell row-right-align" },
              [
                _c(
                  VTooltip,
                  {
                    attrs: { "z-index": "400", top: "", "max-width": 500 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                VIcon,
                                _vm._g(
                                  _vm._b(
                                    { attrs: { color: "primary", size: "20" } },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n                        mdi-information-outline\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2295272181
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t("preparation.allocation.explanation")
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(6),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-bar",
                    class: { "no-search-field": !_vm.showSearchField },
                  },
                  [
                    _vm.showSearchField
                      ? _c("vuex-search-field", {
                          staticClass: "search-bar__search-field",
                          attrs: {
                            placeholder: _vm._f("toSentenceCase")(
                              _vm.$t("preparation.allocation.subCategory")
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(6),
              },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("preparation.allocation.category")
                        )
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(6),
              },
              [_c(VDivider)],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.categories, function (category, categoryIx) {
              return _vm._l(_vm.allocationFields, function (field, fieldIx) {
                return _c(
                  "div",
                  {
                    key: `category-${fieldIx}-${categoryIx}`,
                    staticClass: "allocation-area-grid__cell",
                    class: _vm.getCellClasses(
                      field.cellClasses,
                      categoryIx,
                      category.categoryKey
                    ),
                    style: _vm.getSpanCss(field.span),
                  },
                  [
                    field.text
                      ? _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$n(
                                  field.numberFormat,
                                  _vm.getCategoryCount(
                                    category.categoryKey,
                                    field.countFunction
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : field.field
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(category[field.field]) +
                                "\n                    "
                            ),
                          ]),
                        ])
                      : field.component
                      ? _c(
                          "div",
                          [
                            field.featureToggle
                              ? _c(
                                  "feature-toggle",
                                  { attrs: { toggle: field.featureToggle } },
                                  [
                                    _c(
                                      field.component.name,
                                      _vm._g(
                                        _vm._b(
                                          { tag: "component" },
                                          "component",
                                          _vm.constructVBindObj({
                                            context: category,
                                            field: field.field,
                                            ...field.component,
                                          }),
                                          false
                                        ),
                                        _vm.constructVBindEventObj({
                                          events: _vm.getAttribute(
                                            field.component,
                                            "events"
                                          ),
                                          context: category,
                                        })
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("toSentenceCase")(
                                              _vm.$t(field.component.text)
                                            )
                                          ) + "\n                        "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  field.component.name,
                                  _vm._g(
                                    _vm._b(
                                      { tag: "component" },
                                      "component",
                                      _vm.constructVBindObj({
                                        context: category,
                                        field: field.field,
                                        ...field.component,
                                      }),
                                      false
                                    ),
                                    _vm.constructVBindEventObj({
                                      events: _vm.getAttribute(
                                        field.component,
                                        "events"
                                      ),
                                      context: category,
                                    })
                                  ),
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("toSentenceCase")(
                                          _vm.$t(field.component.text)
                                        )
                                      ) + "\n                    "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              })
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(6),
              },
              [_c(VDivider)],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(1),
              },
              [
                _c("label", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("preparation.allocation.nonAllocated")
                        )
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "allocation-area-grid__cell",
                style: _vm.getSpanCss(1),
              },
              [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.canEditChannel } },
                  [
                    _c(
                      "simple-button",
                      {
                        attrs: { disabled: _vm.isCategoryDisabled() },
                        on: {
                          onClick: function ($event) {
                            return _vm.setCategory({
                              categoryKey:
                                _vm.categoryAllocationsTypes.nonAllocated,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t("preparation.buttons.select")
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }