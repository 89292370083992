<template>
    <button
        class="copy-btn"
        :class="[createNewBtnClassObject, verticalAlignmentClass]"
        :disabled="isDisabled"
        @click="onClick($event)"
    >
        <v-icon color="primary" small>mdi-content-copy</v-icon>
        <span v-if="btnText" class="copy-btn__text font-weight-bold">
            {{ $t(btnText) | toSentenceCase }}
        </span>
    </button>
</template>

<script>
export default {
    props: {
        events: Object,
        preventDefault: Boolean,
        btnText: {
            required: false,
            type: String,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        background: {
            required: false,
            type: Boolean,
            default: false,
        },
        verticalAlignment: {
            required: false,
            type: String,
            default: 'middle',
        },
        hasBorder: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            icon: 'add',
        };
    },

    computed: {
        createNewBtnClassObject() {
            return {
                'copy-btn--bg': this.background,
                'copy-btn--disabled': this.disabled,
                'copy-btn--border': this.hasBorder,
            };
        },

        verticalAlignmentClass() {
            return this.verticalAlignment
                ? `copy-btn--${this.verticalAlignment}`
                : 'copy-btn--middle';
        },

        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    methods: {
        onClick(event) {
            if (this.events && this.events.click) this.events.click(event);
            if (!this.disabled && !this.preventDefault) this.$emit('copy', event);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.copy-btn {
    outline: none;

    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &--disabled {
        cursor: not-allowed;
    }

    &--middle {
        vertical-align: middle;
    }

    &--top {
        vertical-align: top;
    }

    &--bottom {
        vertical-align: bottom;
    }

    &--border {
        border-radius: 0.4rem;
        background-color: $promo-primary-colour;
        padding: 0.3rem 1.6rem;

        .copy-btn__text {
            color: $promo-white;
            font-size: 1.2rem;
            font-weight: 400 !important;
        }
    }

    &__text {
        color: $promo-primary-colour;
        margin-left: 0.5rem;
    }
}
</style>
