<template>
    <main-dialog
        ref="dialog"
        heading="downloadCentre.heading"
        btn-confirm-text="actions.export"
        :has-activator="true"
        :confirm-btn-disabled="isExportDisabled"
        @confirm="onExport"
        @close="closeDialog"
    >
        <template v-slot:actions="{ activator }">
            <v-btn
                secondary
                depressed
                outlined
                class="download-centre-btn"
                v-on="activator"
                @click.stop
            >
                {{ $t('actions.export') | toSentenceCase }}
                <icon icon-name="download" right small />
            </v-btn>
        </template>
        <template v-slot:default>
            <div class="download-centre">
                <div class="download-centre__description">
                    {{ $t('downloadCentre.description') | toSentenceCase }}
                </div>
                <div class="download-centre__content">
                    <div class="download-centre__filter">
                        <h4 class="py-2">{{ $t('downloadCentre.level') | toSentenceCase }}</h4>
                        <v-divider />
                        <vuex-select
                            class="py-2"
                            :placeholder="$t('downloadCentre.subCampaigns') | toSentenceCase"
                            :options="subCampaigns"
                            item-text="name"
                            item-value="_id"
                            :getter="() => selectedSubCampaignIds"
                            :setter="value => (selectedSubCampaignIds = value)"
                            multiple
                            chips
                            deletable-chips
                            clearable
                        />
                        <vuex-select
                            class="py-2"
                            :placeholder="$t('downloadCentre.categories') | toSentenceCase"
                            :options="categoriesOptions"
                            :getter="() => selectedCategories"
                            :setter="value => (selectedCategories = value)"
                            multiple
                            chips
                            deletable-chips
                            clearable
                            :disabled="selectedSubCampaignIds.length === 0"
                        />
                    </div>
                    <div class="download-centre__data">
                        <h4 class="py-2">{{ $t('downloadCentre.data') | toSentenceCase }}</h4>
                        <v-divider />
                        <vuex-select
                            class="py-2"
                            clearable
                            :placeholder="$t('downloadCentre.exportType') | toSentenceCase"
                            :options="exportDataOptions"
                            :getter="() => fieldToUnwind"
                            :setter="value => (fieldToUnwind = value)"
                            filled
                        />
                    </div>
                    <div class="download-centre__format">
                        <h4 class="py-2">{{ $t('downloadCentre.format') | toSentenceCase }}</h4>
                        <v-divider />
                        <vuex-radio-group
                            :options="exportFormatOptions"
                            :getter="() => exportFormat"
                            :setter="value => (exportFormat = value)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import ExportFormats from '@enums/export-formats';
import { mapGetters, mapState, mapActions } from 'vuex';
import { flatten, uniq, isEmpty } from 'lodash';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';

export default {
    props: {},
    data() {
        return {
            selectedSubCampaignIds: [],
            selectedCategories: [],
            fieldToUnwind: null,
            exportFormat: ExportFormats.excel,
            exportDataOptions: [
                {
                    text: toSentenceCase(i18n.t('downloadCentre.promotions')),
                    value: null,
                },
                {
                    text: toSentenceCase(i18n.t('downloadCentre.promotionsWithProducts')),
                    value: 'products',
                },
            ],
            exportFormatOptions: [
                {
                    text: '.xlsx',
                    value: ExportFormats.excel,
                },
                {
                    text: '.json',
                    value: ExportFormats.json,
                },
            ],
        };
    },

    computed: {
        ...mapState('subCampaigns', ['subCampaigns']),
        ...mapGetters('subCampaigns', ['getSubCampaignById', 'selectedSubCampaign']),
        ...mapGetters('context', ['userCategoriesKeyMap']),

        categoriesOptions() {
            return uniq(
                flatten(
                    this.selectedSubCampaignIds.map(
                        subCampaignId =>
                            this.getSubCampaignById({
                                _id: subCampaignId,
                                usePluralResourceName: true,
                            }).categories
                    )
                )
            )
                .filter(levelEntryKey => !!this.userCategoriesKeyMap[levelEntryKey])
                .map(levelEntryKey => ({
                    text: this.userCategoriesKeyMap[levelEntryKey].levelEntryDescription,
                    value: levelEntryKey,
                }));
        },

        isExportDisabled() {
            return isEmpty(this.selectedSubCampaignIds);
        },
    },

    created() {
        if (this.selectedSubCampaign && this.selectedSubCampaign._id) {
            this.selectedSubCampaignIds = [this.selectedSubCampaign._id];
        }
    },

    methods: {
        ...mapActions('promotions', ['download']),
        openDialog() {
            this.$refs.dialog.open();
        },

        closeDialog() {
            this.$refs.dialog.close();
            this.resetState();
        },

        async onExport() {
            return this.download({
                params: {
                    subCampaignIds: this.selectedSubCampaignIds,
                    categories: this.selectedCategories,
                    export: true,
                    exportFormat: this.exportFormat,
                    exportSchema: 'promotions-simple-flat',
                    ...(this.fieldToUnwind && {
                        fieldToUnwind: this.fieldToUnwind,
                    }),
                },
            });
        },

        resetState() {
            this.selectedSubCampaignIds = [this.selectedSubCampaign._id];
            this.selectedCategories = [];
            this.fieldToUnwind = null;
            this.exportFormat = ExportFormats.excel;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.download-centre-btn {
    margin-right: 24rem;
    margin-top: 2rem;
}

.download-centre {
    font-size: 1.2rem;
    width: 70rem;
    height: 40rem;
    overflow: hidden;
    overflow-y: auto;

    &__description {
        padding: 1.5rem 0;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        height: fit-content;
        min-height: 34rem;
    }

    &__filter,
    &__data,
    &__format {
        flex-basis: 23rem;
        padding: 0 1rem;
    }

    &__filter {
        max-width: 23rem;
        background-color: $promo-grey-blue-tint;
    }
}
</style>
