<template>
    <div>
        <div v-if="customFirstRow" class="details">
            <div class="details__label col-1">
                <slot name="firstRowLabel" />
            </div>
            <div class="details__content col-11">
                <slot name="firstRowContent" />
            </div>
        </div>

        <div class="wrapper">
            <div v-for="detailsRow in detailsConfig" :key="detailsRow.field" class="details">
                <div class="details__label col-1 spacer">
                    {{ $t(detailsRow.label) | toSentenceCase | suffixWithColon }}
                </div>
                <div v-if="hasEntityDetails" class="details__content col-11">
                    <chips-list
                        v-if="detailsRow.type === entityDisplayTypes.chipsList"
                        :items="entity[detailsRow.field]"
                        :field="detailsRow.chipField"
                        :max-num-chips-to-display="detailsRow.maxNumChips"
                    />
                    <div v-else-if="detailsRow.type === entityDisplayTypes.startAndEndDates">
                        {{ startDateWeek | weekNumberClient }} -
                        {{ endDateWeek | weekNumberClient }} |
                        {{ entity.startDate | dateShorthand }} -
                        {{ entity.endDate | dateShorthand }}
                    </div>
                    <div v-else>
                        {{ entity[detailsRow.field] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';
import entityDisplayTypes from '@enums/entity-display-types';

export default {
    props: {
        entity: {
            type: Object,
            required: true,
        },
        detailsConfig: {
            type: Array,
            required: true,
        },
        customFirstRow: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            entityDisplayTypes,
        };
    },

    computed: {
        ...mapGetters('weeks', ['getWeekByDate']),

        hasEntityDetails() {
            return !isEmpty(this.entity);
        },
        endDateWeek() {
            return get(this.getWeekByDate(this.entity.endDate), 'weekOfYear');
        },
        startDateWeek() {
            return get(this.getWeekByDate(this.entity.startDate), 'weekOfYear');
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: grid;
    grid-template-columns: minmax(15rem, 30rem) minmax(15rem, 1fr);
}
.details {
    width: 75%;
    display: flex;
    align-items: baseline;
    margin-left: 2rem;
    margin-top: 1rem;

    &__label {
        font-size: 1.2rem;
        text-align: right;
        padding: 0;
    }

    &__content {
        margin-left: 2rem;
        font-size: 1.2rem;
        padding: 0;
    }
}
.spacer {
    margin-left: 9rem;
}
</style>
