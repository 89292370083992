import Vue from 'vue';
import { filter, get, merge, uniq, map, includes, find, flatMap, isEmpty } from 'lodash';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import supplierCommitmentTypes from '@enums/supplier-commitment-types';
import { categories, year, storeGroups } from '@enums/filters';
import axios from 'axios';
import namespaces from '@enums/namespaces';
import { getExecutedAndPlannedFunding } from '@sharedModules/supplier-commitment-funding-utils';
import { getProductCount } from '@/js/utils/supplier-commitment-utils';
import { getStoreGroupAttributeFilters } from '@/js/utils/store-group-attribute-utils';
import storeMixin from '@/js/store/mixins/vuex-store';

const getInitialState = () => ({
    supplierCommitments: [],
    selectedSupplierCommitmentId: null,
    activeSupplierCommitmentsSection: null,
    targetsData: {
        yearTarget: 0,
        executedTarget: 0,
        plannedTarget: 0,
        commitmentTarget: 0,
    },
});

/**
 * Inherits from the default store mixin which takes care of all CRUD operations.
 */
const store = {
    namespaced: true,

    /**
     * Default state available:
     * - loading
     */
    state: getInitialState(),

    /**
     * Default getters available:
     * - getSupplierCommitmentById
     */
    getters: {
        getSelectedSupplierCommitment: state =>
            state.supplierCommitments.find(sp => sp._id === state.selectedSupplierCommitmentId),

        getSupplierCommitmentsBySupplierKey: (state, getters, rootState, rootGetters) => ({
            supplierKey,
            category,
            promotionNamespace,
            useActiveSupplierCommitmentsSection = true,
        }) => {
            const selectedPromotion = rootGetters['promotions/getStagingAreaPromotionById'](
                promotionNamespace
            );
            if (selectedPromotion) {
                const toggleLogic = rootState.clientConfig.toggleLogic;

                const promotionProducts = get(selectedPromotion, 'products', []);

                const supplierCommitmentsFilterFunction = createFeatureAwareFactory(
                    toggleLogic
                ).getSupplierCommitmentsFilterFunction();

                const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];

                const supplierProductsByCategory = filter(promotionProducts, product => {
                    const { levelEntryKey } = find(product.hierarchy, { level: categoryLevel });
                    return product.supplierKey === supplierKey && levelEntryKey === category;
                });
                const productsCategories = rootGetters['products/getProductsCategories'](
                    supplierProductsByCategory
                );
                return supplierCommitmentsFilterFunction({
                    supplierCommitments: useActiveSupplierCommitmentsSection
                        ? filter(state.supplierCommitments, {
                              type: state.activeSupplierCommitmentsSection,
                          })
                        : state.supplierCommitments,
                    supplierKey,
                    productsCategories,
                    startDate: selectedPromotion.startDate,
                    endDate: selectedPromotion.endDate,
                });
            }
            return [];
        },

        filteredSupplierCommitmentsMap: (state, getters) => {
            const today = Vue.moment().utc();

            const current = { totalSpend: [], specifiedAllocations: [] };
            const historical = { totalSpend: [], specifiedAllocations: [] };

            getters.getFilteredSupplierCommitments.forEach(supplierCommitment => {
                if (
                    Vue.moment(supplierCommitment.endDate)
                        .utc()
                        .isBefore(today)
                ) {
                    switch (supplierCommitment.type) {
                        case supplierCommitmentTypes.specifiedAllocations: {
                            historical.specifiedAllocations.push(supplierCommitment);
                            break;
                        }
                        case supplierCommitmentTypes.totalSpend: {
                            historical.totalSpend.push(supplierCommitment);
                            break;
                        }
                        default:
                    }
                } else {
                    switch (supplierCommitment.type) {
                        case supplierCommitmentTypes.specifiedAllocations: {
                            current.specifiedAllocations.push(supplierCommitment);
                            break;
                        }
                        case supplierCommitmentTypes.totalSpend: {
                            current.totalSpend.push(supplierCommitment);
                            break;
                        }
                        default:
                    }
                }
            });

            return {
                current,
                historical,
            };
        },

        getProductCurrent: (state, getters, rootState, rootGetters) => ({
            allocatedPromotions,
            supplierKey,
            hierarchy,
        }) => {
            return getProductCount({
                promotions: allocatedPromotions,
                categoryLevel: rootGetters['clientConfig/getHierarchyConfig'].categoryLevel,
                supplierKey,
                supplierCommitmentCategories: map(hierarchy, 'levelEntryDescription'),
            });
        },

        getExecutedAndPlannedFunding: (state, getters, rootState, rootGetters) => ({
            allocatedPromotions,
            selectedSupplierCommitment,
        }) => {
            const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];

            return getExecutedAndPlannedFunding({
                allocatedPromotions,
                selectedSupplierCommitment,
                categoryLevel,
                moment: Vue.moment,
            });
        },

        getCheckboxListOptions: (state, getters, rootState, rootGetters) => ({
            resource,
            getOptionsFunction,
        }) => {
            const resourceOptions = rootGetters[`${resource}/${getOptionsFunction}`];

            return map(resourceOptions, resourceOption => {
                return {
                    reference: resourceOption,
                    disabled: false,
                };
            });
        },

        isTotalSpendSection: state =>
            state.activeSupplierCommitmentsSection === supplierCommitmentTypes.totalSpend,

        isSpecifiedAllocationsSection: state =>
            state.activeSupplierCommitmentsSection === supplierCommitmentTypes.specifiedAllocations,
    },

    /**
     * Default mutations available:
     * - setLoading
     * - setSupplierCommitment
     * - deleteSupplierCommitment
     * - updateSupplierCommitment
     * - addSupplierCommitment
     * - resetState
     */
    mutations: {
        setSelectedSupplierCommitmentId(state, supplierCommitmentId) {
            state.selectedSupplierCommitmentId = supplierCommitmentId;
        },

        setActiveSupplierCommitmentsSection(state, activeSupplierCommitmentsSection) {
            state.activeSupplierCommitmentsSection = activeSupplierCommitmentsSection;
        },

        setTargetData(state, { targetsData }) {
            state.targetsData = { ...state.targetsData, ...targetsData };
        },
    },

    /**
     * Default actions available:
     * - fetchSupplierCommitments
     * - createSupplierCommitment
     * - deleteSupplierCommitment
     * - updateSupplierCommitment
     * - updateSupplierCommitments
     * - submitForm
     * - handleResponseNotifications
     * - resetState
     */
    actions: {
        setSelectedSupplierCommitmentId(
            { commit },
            { supplierCommitmentId } = { supplierCommitmentId: null }
        ) {
            commit('setSelectedSupplierCommitmentId', supplierCommitmentId);
        },

        setActiveSupplierCommitmentsSection({ commit }, { activeSupplierCommitmentsSection }) {
            commit('setActiveSupplierCommitmentsSection', activeSupplierCommitmentsSection);
        },

        clearSelectedSupplierCommitment({ commit }) {
            commit('setSelectedSupplierCommitmentId', null);
        },

        fetchSupplierCommitmentsForPromotion({ dispatch, rootGetters }, selectedPromotion) {
            const promotion = selectedPromotion || rootGetters['promotions/selectedPromotion'];
            if (promotion) {
                const supplierKeys = uniq(map(promotion.products, product => product.supplierKey));

                // Currently there are 2 different ways to store supplier commitments,
                // each of which has a slightly different supplier schema.
                // The following code ensures that both types of supplier commitments
                // are fetched appropriately.  Note that this will be aligned in
                // PROWEB-1483.
                dispatch('fetchSupplierCommitments', {
                    params: { where: { supplierKey: { $in: supplierKeys } } },
                    patchState: true,
                    patchOptions: {
                        fieldToMatchOn: '_id',
                        isMatchFunction: (
                            resourceItem,
                            { supplierKey: { $in: supplierKeysArray } }
                        ) => includes(supplierKeysArray, resourceItem.supplierKey),
                    },
                });

                dispatch('fetchSupplierCommitments', {
                    params: { where: { 'supplier.supplierKey': { $in: supplierKeys } } },
                    patchState: true,
                    patchOptions: {
                        fieldToMatchOn: '_id',
                        isMatchFunction: (
                            resourceItem,
                            { 'supplier.supplierKey': { $in: supplierKeysArray } }
                        ) => {
                            const resourceItemSupplierKey = resourceItem.supplier
                                ? resourceItem.supplier.supplierKey
                                : null;

                            return includes(supplierKeysArray, resourceItemSupplierKey);
                        },
                    },
                });
            }
        },

        allocateDeallocatePromotion(
            { dispatch, rootGetters },
            {
                supplierCommitmentId,
                allocatedPromotionIds,
                selectedPromotionId,
                type,
                promotionCurrent,
                productCurrent,
                storeGroupResources,
                executed,
                planned,
            }
        ) {
            let promotionId = selectedPromotionId;
            if (!selectedPromotionId) {
                const promotion = rootGetters['promotions/selectedPromotion'];
                if (promotion) {
                    promotionId = promotion._id;
                }
            }
            if (promotionId) {
                // The attributes to update are dependent on the supplier
                // commitment type.
                const updates =
                    type === supplierCommitmentTypes.totalSpend
                        ? {
                              allocatedPromotionIds,
                              executed,
                              planned,
                          }
                        : {
                              allocatedPromotionIds,
                              promotionCurrent,
                              productCurrent,
                              storeGroupResources,
                          };

                dispatch('updateSupplierCommitment', {
                    id: supplierCommitmentId,
                    updates,
                });
            }
        },

        async fetchAllocatedPromotions({ dispatch }, supplierCommitments) {
            // Get all allocatedPromotionIds across all the associated
            // supplier commitments.
            const allocatedPromotionIds = uniq(
                flatMap(supplierCommitments, 'allocatedPromotionIds')
            );

            // Fetch all promotions for all the associated supplier commitments, but
            // return the documents rather than updating state.
            const promotions = await dispatch(
                'promotions/fetchPromotions',
                {
                    params: {
                        where: {
                            _id: {
                                $in: allocatedPromotionIds,
                            },
                        },
                    },
                    returnDocuments: true,
                },
                { root: true }
            );

            return promotions;
        },

        async fetchTargetsData({ commit, dispatch, rootGetters }, filters) {
            const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];
            const allStoreGroups = rootGetters['storeGroups/getStoreGroupKeys'];

            const selectedCategory = filters.find(e => e.filterKey === categories);
            const selectedYear = filters.find(e => e.filterKey === year);
            const selectedStoreGroups = filters.find(e => e.filterKey === storeGroups);

            const firstYearDate = Vue.moment(new Date(selectedYear.filterValue))
                .startOf('year')
                .format('YYYY-MM-DD');
            const lastYearDate = Vue.moment(new Date(selectedYear.filterValue))
                .endOf('year')
                .format('YYYY-MM-DD');
            const today = Vue.moment().format('YYYY-MM-DD');

            // fetch targets data only when year and categories are selected
            if (selectedCategory && selectedYear) {
                const targetParams = {
                    where: {
                        categories: { $in: [selectedCategory.filterValue] },
                        storeGroups: {
                            $elemMatch: {
                                key: {
                                    $in:
                                        selectedStoreGroups &&
                                        !isEmpty(selectedStoreGroups.filterValue)
                                            ? selectedStoreGroups.filterValue
                                            : allStoreGroups,
                                },
                            },
                        },
                    },
                };

                let storeGroupAttributeFilters = {};

                if (selectedStoreGroups && !isEmpty(selectedStoreGroups.filterValue)) {
                    const filteredStoreGroups = rootGetters['storeGroups/getFilteredStoreGroups']({
                        storeGroups: selectedStoreGroups.filterValue,
                    });

                    storeGroupAttributeFilters = getStoreGroupAttributeFilters({
                        storeGroups: filteredStoreGroups,
                    });
                }

                const yearTarget = await dispatch(
                    'targets/fetchTargetsAggregations',
                    {
                        params: {
                            where: {
                                hierarchyLevel: categoryLevel,
                                hierarchyValue: selectedCategory.filterValue,
                                ...storeGroupAttributeFilters,
                            },
                            minDate: firstYearDate,
                            maxDate: lastYearDate,
                        },
                    },
                    { root: true }
                );

                const executedTarget = await dispatch(
                    'promotions/fetchPromotionsAggregations',
                    {
                        params: {
                            ...targetParams,
                            minDate: firstYearDate,
                            maxDate: today,
                            onlyFavoriteScenarios: true,
                        },
                    },
                    { root: true }
                );

                const plannedTarget = await dispatch(
                    'promotions/fetchPromotionsAggregations',
                    {
                        params: {
                            ...targetParams,
                            minDate: today,
                            maxDate: lastYearDate,
                            onlyFavoriteScenarios: true,
                        },
                    },
                    { root: true }
                );

                const executedTargetValue = executedTarget.find(
                    target => target.categoryLevelEntryKey === selectedCategory.filterValue
                );
                const plannedTargetValue = plannedTarget.find(
                    target => target.categoryLevelEntryKey === selectedCategory.filterValue
                );

                const targetsData = {
                    yearTarget: yearTarget.length ? yearTarget[0].lumpFunding : 0,
                    executedTarget: executedTargetValue ? executedTargetValue.lumpFunding : 0,
                    plannedTarget: plannedTargetValue ? plannedTargetValue.lumpFunding : 0,
                };

                commit('setTargetData', { targetsData });
            }
        },

        updateSupplierCommitmentTotalSpendAttributesByPromotionId(
            { dispatch, rootGetters },
            { namespace: promotionId }
        ) {
            const selectedPromotion = rootGetters['promotions/getPromotionById'](promotionId);
            return dispatch('updateSupplierCommitmentTotalSpendAttributes', selectedPromotion);
        },

        async updateSupplierCommitmentTotalSpendAttributes(
            { dispatch, state, getters, rootGetters },
            selectedPromotion
        ) {
            const { _id: promotionId } =
                selectedPromotion || rootGetters['promotions/selectedPromotion'];

            if (promotionId) {
                const associatedSupplierCommitments = state.supplierCommitments.filter(
                    sc =>
                        sc.allocatedPromotionIds.includes(promotionId) &&
                        sc.type === supplierCommitmentTypes.totalSpend
                );

                const promotions = await dispatch(
                    'fetchAllocatedPromotions',
                    associatedSupplierCommitments
                );

                const updatedPromotions = promotions
                    .filter(p => p._id !== selectedPromotion._id)
                    .concat([selectedPromotion]);

                const supplierCommitments = map(associatedSupplierCommitments, sc => {
                    const { executed, planned } = getters.getExecutedAndPlannedFunding({
                        allocatedPromotions: filter(updatedPromotions, promotion =>
                            includes(sc.allocatedPromotionIds, promotion._id)
                        ),
                        selectedSupplierCommitment: sc,
                    });

                    return {
                        _id: sc._id,
                        executed,
                        planned,
                    };
                });

                if (supplierCommitments.length) {
                    dispatch('updateSupplierCommitments', {
                        updates: supplierCommitments,
                        refreshData: true,
                    });
                }
            }
        },

        async updateSupplierCommitmentProductCurrent(
            { dispatch, state, getters, rootGetters },
            selectedPromotion
        ) {
            const { _id: promotionId } =
                selectedPromotion || rootGetters['promotions/selectedPromotion'];

            if (promotionId) {
                const associatedSupplierCommitments = state.supplierCommitments.filter(
                    sc =>
                        sc.allocatedPromotionIds.includes(promotionId) &&
                        supplierCommitmentTypes.specifiedAllocations
                );

                const promotions = await dispatch(
                    'fetchAllocatedPromotions',
                    associatedSupplierCommitments
                );

                const supplierCommitments = map(associatedSupplierCommitments, sc => {
                    return {
                        _id: sc._id,
                        productCurrent: getters.getProductCurrent({
                            allocatedPromotions: promotions,
                            supplierKey: sc.supplierKey,
                            hierarchy: sc.hierarchy,
                        }),
                    };
                });

                dispatch('updateSupplierCommitments', {
                    updates: supplierCommitments,
                    refreshData: false,
                });
            }
        },

        async submitSupplierCommitment({ dispatch }, { namespace }) {
            if (namespace === namespaces.default) return;
            const { error } = await dispatch('submitForm', {
                namespace,
                editMode: true,
                submitAction: 'updateSupplierCommitment',
            });
            // handle any error
            if (error) return { error };
        },

        resetSupplierCommitmentsState({ commit }) {
            commit('setSupplierCommitments', []);
        },

        fetchSupplierCommitmentsAndSuppliersByCategory({ dispatch, rootGetters }, filters) {
            const { categoryLevel } = rootGetters['clientConfig/getHierarchyConfig'];
            const categoryFilter = filters.find(e => e.filterKey === categories);
            const yearFilter = filters.find(e => e.filterKey === year);

            if (categoryFilter) {
                let filterParams = {
                    'hierarchy.level': categoryLevel,
                    'hierarchy.levelEntryKey': categoryFilter.filterValue,
                };

                if (yearFilter) {
                    const startDate = new Date(yearFilter.filterValue, 0, 1);
                    const endDate = new Date(yearFilter.filterValue, 11, 31);
                    filterParams = Object.assign(filterParams, {
                        $or: [
                            {
                                startDate: { $gte: startDate, $lte: endDate },
                            },
                            {
                                endDate: { $gte: startDate, $lte: endDate },
                            },
                            {
                                startDate: { $lte: startDate },
                                endDate: { $gte: endDate },
                            },
                        ],
                    });
                }

                dispatch('suppliers/fetchSuppliersByCategories', categoryFilter.filterValue, {
                    root: true,
                });
                dispatch('fetchSupplierCommitments', {
                    params: {
                        where: {
                            ...filterParams,
                        },
                    },
                });
            }
        },

        async getSupplierCommitmentsWithTheSameCategoryById({ commit }, id) {
            commit('setLoading', true);

            const supplierCommitments = await axios.get(
                '/api/supplier-commitments/getSupplierCommitmentsWithTheSameCategoryById',
                {
                    params: {
                        id,
                    },
                }
            );

            commit('setSupplierCommitments', supplierCommitments.data.supplierCommitments);

            commit('setLoading', false);
        },
    },
};

const mixinParams = {
    resource: 'supplier-commitment',
    useForm: true,
    useFilters: true,
    useNotes: true,
    getInitialState,
};

export default merge({}, storeMixin(mixinParams), store);
