<template>
    <div class="supply-form-wrapper">
        <supply-tab v-bind="form" :form-ref="formRef" :cache-dom="cacheDom" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import promotionTabFormWrapperMixin from '@/js/pages/planning/components/mixins/promotion-tab-form-wrapper';

export default {
    mixins: [promotionTabFormWrapperMixin],
    props: {
        formRef: {
            type: Object,
            required: false,
        },
    },
    computed: {
        ...mapState('promotions', ['validationStates']),
        form() {
            return {
                vuexModule: 'promotions',
                editMode: this.editMode,
                submitAction: this.isNewPromotion ? null : 'prepareAndUpdatePromotion',
                preventDefault: true,
                namespace: this.namespace,
                context: this.context,
                editContext: this.context.selectedPromotion,
                validation: this.validationStates[this.namespace],
                fields: [
                    {
                        fieldName: 'products',
                    },
                ],
            };
        },
    },
};
</script>

<style lang="scss" scoped></style>
