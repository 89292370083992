var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.customFirstRow
      ? _c("div", { staticClass: "details" }, [
          _c(
            "div",
            { staticClass: "details__label col-1" },
            [_vm._t("firstRowLabel")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "details__content col-11" },
            [_vm._t("firstRowContent")],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wrapper" },
      _vm._l(_vm.detailsConfig, function (detailsRow) {
        return _c("div", { key: detailsRow.field, staticClass: "details" }, [
          _c("div", { staticClass: "details__label col-1 spacer" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm._f("suffixWithColon")(
                    _vm._f("toSentenceCase")(_vm.$t(detailsRow.label))
                  )
                ) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.hasEntityDetails
            ? _c(
                "div",
                { staticClass: "details__content col-11" },
                [
                  detailsRow.type === _vm.entityDisplayTypes.chipsList
                    ? _c("chips-list", {
                        attrs: {
                          items: _vm.entity[detailsRow.field],
                          field: detailsRow.chipField,
                          "max-num-chips-to-display": detailsRow.maxNumChips,
                        },
                      })
                    : detailsRow.type ===
                      _vm.entityDisplayTypes.startAndEndDates
                    ? _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("weekNumberClient")(_vm.startDateWeek)
                            ) +
                            " -\n                    " +
                            _vm._s(
                              _vm._f("weekNumberClient")(_vm.endDateWeek)
                            ) +
                            " |\n                    " +
                            _vm._s(
                              _vm._f("dateShorthand")(_vm.entity.startDate)
                            ) +
                            " -\n                    " +
                            _vm._s(
                              _vm._f("dateShorthand")(_vm.entity.endDate)
                            ) +
                            "\n                "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.entity[detailsRow.field]) +
                            "\n                "
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }