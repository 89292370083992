<template>
    <div class="page-layout">
        <div class="page-layout__labels">
            <label class="page-layout__labels-label">
                {{
                    isPagesResource
                        ? $t('preparation.templates.pageLayout')
                        : $t('preparation.templates.slots') | toSentenceCase
                }}
            </label>
            <feature-toggle :toggle="canEditChannel">
                <confirm-dialog
                    v-if="isTemplatesSection"
                    ref="changeTemplateModal"
                    class="confirm-dialog"
                    :question-text="$t('preparation.templates.changeTemplateModal.heading')"
                    :warning-text="$t('preparation.templates.changeTemplateModal.message')"
                    :action-text="$t('preparation.templates.changeTemplateModal.confirm')"
                    @confirm="confirmChangeTemplate()"
                >
                    <template v-slot:actions>
                        <v-btn
                            class="page-layout__labels-label nav-link"
                            text
                            depressed
                            :disabled="changeTemplateButtonDisabled"
                            @click="openChangeTemplateModal"
                        >
                            {{ $t('preparation.templates.changeTemplate') | toSentenceCase }}
                        </v-btn>
                    </template>
                </confirm-dialog>
            </feature-toggle>
        </div>
        <div class="page-layout-wrapper">
            <div v-show="showPagesLayout" class="page-layout-wrapper__selector">
                <v-carousel
                    ref="carousel"
                    v-model="carouselModel"
                    height="100%"
                    hide-delimiters
                    :continuous="false"
                    :show-arrows="false"
                    :disabled="isDisabled"
                >
                    <v-carousel-item
                        v-for="(template, index) in allowedTemplates"
                        :key="createCarouselItemKey(template, index)"
                    >
                        <page-layout-grid
                            :key="template._id + selectedResourceDefinitionPageNumber"
                            :layout="template.layout"
                            :layout-key="template.key"
                            :selected="isTemplateSelected"
                            :model="model"
                            @auto-save="$emit('auto-save')"
                        />
                    </v-carousel-item>
                </v-carousel>
                <template v-if="changeTemplateEnabled">
                    <navigate-button
                        class="page-layout-wrapper__carousel-button page-layout-wrapper__carousel-button--prev"
                        :left="true"
                        :disabled="isPrevButtonDisabled"
                        @click="onCarouselButtonClick(true)"
                    />
                    <navigate-button
                        class="page-layout-wrapper__carousel-button page-layout-wrapper__carousel-button--next"
                        :right="true"
                        :disabled="isNextButtonDisabled"
                        @click="onCarouselButtonClick(false)"
                    />
                    <div class="page-layout-wrapper__panel">
                        <div class="page-layout-wrapper__panel--select-template">
                            <vuex-select
                                class="mt-3 mb-3"
                                :options="templateOptions"
                                :sort-results="false"
                                return-object
                                filled
                                :getter="() => currentLayout.key"
                                :setter="value => (carouselModel = value.index)"
                            />
                        </div>
                        <p class="page-layout-wrapper__panel--description">
                            <b
                                >{{
                                    $t('preparation.templates.selectTemplateDescription.template')
                                }}
                                {{ carouselModel + 1 }}</b
                            >
                            {{ $t('preparation.templates.selectTemplateDescription.of') }}
                            {{ templateOptions.length }}
                        </p>
                        <feature-toggle :toggle="canEditChannel">
                            <v-btn
                                outlined
                                class="page-layout-wrapper__panel--select-button mt-3"
                                :disabled="isDisabled"
                                @click="setSelectedTemplate(currentLayout.key)"
                            >
                                {{ $t('actions.select') | toSentenceCase }}
                            </v-btn>
                        </feature-toggle>
                    </div>
                </template>
            </div>
            <!-- This section is for resources that use slots e.g. tv and therefore will only ever have 1 page -->
            <!-- Slot resources have their grid stored directly on the page object as it can be changed by the user -->
            <div v-if="showSlotsPageLayout" class="page-layout-wrapper__selector">
                <!--
                    Using index 0 of the pages array as any resource that has slots will have
                    all info stored in the first page even though UI shows no indication of pages.
                    This is so everything else works seemlessly behind the scenes.
                 -->
                <page-layout-grid
                    :layout="selectedResourceDefinition.pages[0].slots || []"
                    :layout-key="null"
                    :model="model"
                    :selected="true"
                    @auto-save="$emit('auto-save')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { canEditChannel } from '@enums/feature-flags';
import { isEmpty } from 'lodash';
import uuid from 'uuid/v4';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    props: ['promoResource'],
    data() {
        return {
            carouselModel: 0,
            canEditChannel,
        };
    },
    computed: {
        ...mapGetters('clientConfig', ['sortedResourceTemplateLayouts']),
        ...mapState('subCampaigns', [
            'selectedResourceDefinitionKey',
            'selectedResourceDefinitionPageNumber',
            'activeDocumentSection',
        ]),
        ...mapGetters('subCampaigns', [
            'isTemplatesSection',
            'isCategoriesSection',
            'isPromotionsSection',
            'selectedResourceDefinition',
            'isSlotsResource',
            'isPagesResource',
            'isCover',
        ]),

        allowedTemplates() {
            if (this.isSlotsResource) {
                // we use v-show for carousel to make it work properly,
                // so for slot resources there are no allowed templates
                return [];
            }

            const { selectedResourceDefinition } = this.selectedResourceDefinitionWithIndex;

            if (selectedResourceDefinition) {
                const selectedPage = this.getSelectedPage(selectedResourceDefinition);

                if (selectedPage) {
                    if (!isEmpty(selectedPage.allowedTemplates) || this.isCover) {
                        return this.$store.getters['clientConfig/filteredResourceTemplateLayouts']({
                            allowedTemplates: selectedPage.allowedTemplates,
                        });
                    }
                }
            }

            return this.sortedResourceTemplateLayouts;
        },

        templateOptions() {
            return this.allowedTemplates.map((template, index) => {
                return {
                    value: template.key,
                    text: this.$t(`preparation.${template.translationKey}`),
                    index,
                };
            });
        },

        currentLayout() {
            return this.allowedTemplates[this.carouselModel] || {};
        },

        isPrevButtonDisabled() {
            return this.carouselModel === 0;
        },

        isNextButtonDisabled() {
            return this.carouselModel === this.allowedTemplates.length - 1;
        },

        showPagesLayout() {
            return (
                this.selectedResourceDefinitionKey &&
                this.selectedResourceDefinitionPageNumber &&
                this.isPagesResource &&
                this.allowedTemplates.length
            );
        },

        showSlotsPageLayout() {
            return (
                this.isSlotsResource &&
                this.selectedResourceDefinitionKey &&
                this.selectedResourceDefinitionPageNumber
            );
        },

        selectedResourceDefinitionWithIndex() {
            const selectedResourceDefinitionIndex = this.model.findIndex(
                resource => resource.key === this.selectedResourceDefinitionKey
            );

            return {
                selectedResourceDefinitionIndex,
                selectedResourceDefinition: this.model[selectedResourceDefinitionIndex],
            };
        },
        selectedLayoutIndex() {
            if (this.selectedResourceDefinitionKey && this.model && this.model.length) {
                const { selectedResourceDefinition } = this.selectedResourceDefinitionWithIndex;

                const selectedPage = this.getSelectedPage(selectedResourceDefinition);
                if (selectedPage && selectedPage.layoutTemplate) {
                    return this.allowedTemplates.findIndex(
                        layout => layout.key === selectedPage.layoutTemplate
                    );
                }
            }

            return 0;
        },

        // need to check both carouselModel and promoResource from selectedResourceDefinition
        isTemplateSelected() {
            return (
                this.pageHasSelectedTemplate &&
                this.carouselModel === this.selectedLayoutIndex &&
                this.promoResource ===
                    this.selectedResourceDefinitionWithIndex.selectedResourceDefinition.resourceKey
            );
        },
        pageHasSelectedTemplate() {
            const { selectedResourceDefinition } = this.selectedResourceDefinitionWithIndex;
            const selectedPage = this.getSelectedPage(selectedResourceDefinition);
            return !!(selectedPage && selectedPage.layoutTemplate);
        },
        changeTemplateEnabled() {
            return (
                this.isTemplatesSection &&
                (!this.selectedResourceDefinitionPageNumber || !this.pageHasSelectedTemplate)
            );
        },
        changeTemplateButtonDisabled() {
            return (
                !this.selectedResourceDefinitionPageNumber ||
                !this.pageHasSelectedTemplate ||
                this.isReadOnly
            );
        },
        isDisabled() {
            return this.isReadOnly;
        },
    },
    watch: {
        selectedResourceDefinitionPageNumber() {
            this.resetCarouselModel();
        },
        activeDocumentSection() {
            if (this.isCategoriesSection || this.isPromotionsSection) {
                this.resetCarouselModel();
            }
        },
    },
    events: {
        onUpdatePageCount(pageCount) {
            const { selectedResourceDefinition } = this.selectedResourceDefinitionWithIndex;
            // remove pages after changing pageNumber if page.pageNumber > pageCount
            if (selectedResourceDefinition.pages.length > pageCount) {
                selectedResourceDefinition.pages = selectedResourceDefinition.pages.filter(
                    page => page.pageNumber <= pageCount
                );
            } else {
                // add additional pages if page.pageNumber < pageCount
                while (selectedResourceDefinition.pages.length < pageCount) {
                    const nextPage = {
                        key: uuid(),
                        pageNumber: selectedResourceDefinition.pages.length + 1,
                        layoutTemplate: null,
                        assignment: [],
                        notes: [],
                    };
                    selectedResourceDefinition.pages.push(nextPage);
                }
            }
            this.$emit('auto-save');
        },
        onPageGridUpdated() {
            this.resetCarouselModel();
        },
    },
    mounted() {
        this.resetCarouselModel();
    },
    methods: {
        getSelectedPage(selectedResourceDefinition) {
            return selectedResourceDefinition.pages.find(
                page => page.pageNumber === this.selectedResourceDefinitionPageNumber
            );
        },
        setSelectedTemplate(template) {
            const {
                selectedResourceDefinitionIndex,
                selectedResourceDefinition,
            } = this.selectedResourceDefinitionWithIndex;

            const selectedPage = this.getSelectedPage(selectedResourceDefinition);
            const selectedPageIndex = selectedPage.pageNumber - 1;

            this.$set(selectedResourceDefinition.pages, selectedPageIndex, {
                ...selectedPage,
                layoutTemplate: template,
                assignment: template ? selectedPage.assignment : [],
            });
            this.$set(this.model, selectedResourceDefinitionIndex, selectedResourceDefinition);
            // autosave of selected page layout
            this.$emit('auto-save');
        },

        onCarouselButtonClick(isPrev) {
            if (isPrev) {
                this.$refs.carousel.prev();
            } else {
                this.$refs.carousel.next();
            }
        },

        resetCarouselModel() {
            this.carouselModel = this.selectedLayoutIndex;
        },
        openChangeTemplateModal() {
            this.$refs.changeTemplateModal.open();
        },
        confirmChangeTemplate() {
            this.setSelectedTemplate(null);
            this.resetCarouselModel();
            this.$set(this.selectedResourceDefinition, 'templateName', 'custom');
            this.globalEmit('change-template');
            this.$refs.changeTemplateModal.close();
        },
        createCarouselItemKey(template, index) {
            return index + template.key + this.selectedResourceDefinitionKey;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.page-layout {
    padding-left: 1rem;
    height: 100%;
    @include flex-column;

    &__labels {
        @include flex-row;
        justify-content: space-between;
        &-label {
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: 600;
            padding: 0.5rem 0;
        }
    }
}
.page-layout-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border: $component-border-divider;

    &__selector {
        height: 100%;
        width: 100%;
        display: grid;
    }

    &__carousel-button {
        position: absolute;
        top: calc(50% - 1rem);
        z-index: 2;

        &--next {
            right: 1rem;
        }

        &--prev {
            left: 1rem;
        }
    }

    &__panel {
        position: absolute;
        width: 32rem;
        height: 12rem;
        top: calc(50% - 6rem);
        left: calc(50% - 16rem);
        background: rgba(255, 255, 255, 0.8);

        &--description {
            text-align: center;
            font-size: 1.2rem;
        }

        &--select-template {
            display: flex;
            justify-content: center;
        }

        &--select-button {
            background: transparent;
            border: 0.0625rem solid $promo-primary-colour;
            color: $promo-primary-colour;
            width: 7rem;
            margin-left: calc(50% - 3.5rem);

            &:hover {
                color: $promo-white;
                background: $promo-primary-colour;
            }
        }
    }
}
.nav-link {
    color: $promo-primary-colour;
}
</style>
