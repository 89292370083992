<template>
    <main-dialog
        ref="dialog"
        :translated-header="translatedHeader"
        :has-activator="hasActivator"
        show-negative-action-button
        :show-positive-action-button="false"
    >
        <template v-slot:default>
            <div class="promotion-forecasts">
                <div
                    class="promotion-forecasts__forecasts-block"
                    :style="{ gridTemplate: gridLayout, gridTemplateColumns: gridTemplateColumns }"
                >
                    <div
                        v-for="header in headers"
                        :key="header.fieldName"
                        class="promotion-forecasts__forecasts-block--header"
                    >
                        <div class="cell" :style="{ 'grid-area': `header${header.fieldName}` }">
                            {{ header.header }}
                        </div>
                    </div>
                    <div
                        v-for="(item, key) in unitData"
                        :key="key"
                        class="promotion-forecasts__forecasts-block--wrapper"
                    >
                        <div class="promotion-forecasts__forecasts-block--name">
                            <div
                                class="border-top-blue"
                                :style="{ gridArea: `${item.templateKey}name` }"
                            >
                                <span
                                    :class="{
                                        'selected-hierarchy': isSelectedHierarchy(
                                            key,
                                            selectedHierarchyId
                                        ),
                                    }"
                                    >{{ item.name }}</span
                                >
                            </div>
                        </div>
                        <div class="promotion-forecasts__forecasts-block--target">
                            <div
                                class="border-right border-top-blue"
                                :style="{ gridArea: `${item.templateKey}target` }"
                            >
                                {{ $tkey('forecastsModal.target') }}
                                {{ dateLabel }}
                            </div>
                        </div>
                        <div
                            v-for="header in headers"
                            :key="`${key}:target:${header.fieldName}`"
                            class="promotion-forecasts__forecasts-block--forecasts"
                        >
                            <div
                                class="border-top-blue cell highlighted"
                                :style="{
                                    'grid-area': `${item.templateKey}forecast${header.fieldName}`,
                                }"
                            >
                                {{
                                    cellTypeToFormatter(
                                        get(item.target, [header.fieldName], 0),
                                        header.type
                                    )
                                }}
                            </div>
                            <div
                                class="border-top-grey border-bottom-grey cell"
                                :style="{
                                    'grid-area': `${item.templateKey}currentForecasts${
                                        header.fieldName
                                    }`,
                                }"
                            >
                                {{
                                    cellTypeToFormatter(
                                        get(item.current, [header.fieldName], 0),
                                        header.type
                                    )
                                }}
                            </div>
                            <div
                                class="cell"
                                :class="
                                    deltaValue(
                                        get(item.product, [header.fieldName], 0),
                                        get(item.target, [header.fieldName], 0)
                                    ) > 0
                                        ? 'positive'
                                        : 'negative'
                                "
                                :style="{
                                    'grid-area': `${item.templateKey}deltaForecasts${
                                        header.fieldName
                                    }`,
                                }"
                            >
                                {{
                                    cellTypeToFormatter(
                                        deltaValue(
                                            get(item.product, [header.fieldName], 0),
                                            get(item.target, [header.fieldName], 0)
                                        ),
                                        header.type
                                    )
                                }}
                            </div>
                            <div
                                class="background-blue cell"
                                :style="{
                                    'grid-area': `${item.templateKey}productForecasts${
                                        header.fieldName
                                    }`,
                                }"
                            >
                                {{
                                    cellTypeToFormatter(
                                        get(item.product, [header.fieldName], 0),
                                        header.type
                                    )
                                }}
                            </div>
                        </div>
                        <div class="promotion-forecasts__forecasts-block--productTarget">
                            <div
                                class="border-right"
                                :style="{ 'grid-area': `${item.templateKey}productTarget` }"
                            >
                                {{ dateLabel }}
                            </div>
                        </div>
                        <div class="promotion-forecasts__forecasts-block--delta">
                            <div
                                class="border-right"
                                :style="{ 'grid-area': `${item.templateKey}delta` }"
                            >
                                {{ $tkey('forecastsModal.delta') }}
                            </div>
                        </div>

                        <div class="promotion-forecasts__forecasts-block--current">
                            <div
                                class="border-right border-top-grey"
                                :style="{ 'grid-area': `${item.templateKey}current` }"
                            >
                                {{ $tkey('forecastsModal.current') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, keys, isEmpty } from 'lodash';
import i18n from '@/js/vue-i18n';

export default {
    localizationKey: 'subCampaignAllocation',
    props: {
        subCampaign: {
            type: Object,
            default: () => {},
        },
        hasActivator: {
            type: Boolean,
            default: false,
        },
        unitData: {
            type: Object,
            default: () => {},
        },
        selectedHierarchyId: {
            type: String,
            default: null,
        },
        isUnit: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('weeks', ['getWeekByDate']),
        ...mapState('clientConfig', ['overviewPageConfig']),

        dateLabel() {
            const startWeek = this.$options.filters.weekNumberShorthandClient(
                get(this.getWeekByDate(this.subCampaign.startDate), 'weekOfYear')
            );
            const lastWeek = this.$options.filters.weekNumberShorthandClient(
                get(this.getWeekByDate(this.subCampaign.endDate), 'weekOfYear')
            );
            return `${startWeek} - ${lastWeek}`;
        },

        headers() {
            return this.overviewPageConfig.subCampaign.forecastsModal.reduce((acc, field) => {
                acc.push({
                    header: this.$tkey(field.translationKey),
                    fieldName: field.fieldName,
                    type: field.type || 'decimal',
                });
                return acc;
            }, []);
        },

        gridTemplateColumns() {
            return `repeat(${3 + this.headers.length}, 1fr)`;
        },

        gridLayout() {
            // This function generates the grid template for the component
            if (isEmpty(this.unitData)) return '';
            // helper function to build strings like 'header header header header'
            const generateTemplateFromHeaders = field =>
                this.headers
                    .reduce((acc, header) => `${acc} ${field}${header.fieldName}`, '')
                    .trim();
            // These are the template strings that we need for each unit
            const headerTemplate = `. . . ${generateTemplateFromHeaders('header')}`;
            const targetTemplate = `name target target ${generateTemplateFromHeaders('forecast')}`;
            const productTemplate = `name . productTarget ${generateTemplateFromHeaders(
                'productForecasts'
            )}`;
            const deltaTemplate = `name . delta ${generateTemplateFromHeaders('deltaForecasts')}`;
            const currentTemplate = `name current current ${generateTemplateFromHeaders(
                'currentForecasts'
            )}`;
            const basicTemplate = [targetTemplate, productTemplate, deltaTemplate, currentTemplate];
            // ensure 'all' is always first
            const orderedKeys = [
                'all',
                ...keys(this.unitData)
                    .filter(key => key !== 'all')
                    .sort(),
            ];
            // In here we apply some of the unit data so that we have unique area names
            // per unit. This means none of our units overlap
            // we use the templateKey to make each area unique
            const unitTemplate = orderedKeys.reduce((accumulator, key) => {
                const templateKey = this.unitData[key].templateKey;
                basicTemplate.forEach(template => {
                    const updatedTemplate = template
                        .split(' ')
                        .reduce(
                            (acc, val) =>
                                val === '.' ? `${acc} .` : `${acc} ${templateKey}${val}`,
                            ''
                        );
                    accumulator.push(updatedTemplate);
                });
                return accumulator;
            }, []);
            return unitTemplate.reduce((a, next) => `${a} '${next}'`, `'${headerTemplate}'`);
        },

        translatedHeader() {
            const hierarchyString = this.$tkey(
                `forecastsModal.${this.isUnit ? 'unit' : 'category'}`
            );
            return `${this.$tkey('forecastsModal.forecastPerformance')}: ${hierarchyString} ${
                this.dateLabel
            }`;
        },
    },
    methods: {
        openDialog() {
            this.$refs.dialog.open();
        },
        closeDialog() {
            this.$refs.dialog.close();
        },
        formatCellValueAsDecimal(val) {
            return i18n.n('numbers.default.numberWithDecimals', val, {
                usePlaceholder: true,
            });
        },
        formatCellValueAsPercentage(val) {
            return i18n.n('numbers.default.percentage', val, {
                usePlaceholder: true,
            });
        },
        cellTypeToFormatter(val, type) {
            const functionMapper = {
                decimal: this.formatCellValueAsDecimal,
                percentage: this.formatCellValueAsPercentage,
            };
            return functionMapper[type](val);
        },
        isSelectedHierarchy(currentHierarchy, selectedHierarchy) {
            const selectedKey = this.isUnit ? selectedHierarchy : selectedHierarchy.split(':')[1];
            return String(currentHierarchy) === String(selectedKey);
        },
        deltaValue(targetValue, currentValue) {
            return Number(targetValue) - Number(currentValue);
        },
        get,
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.promotion-forecasts {
    width: 90rem;

    &__forecasts-block {
        font-size: 1.4rem;
        padding-bottom: 1rem;
        display: grid;

        .border-top-blue {
            border-top: 0.1rem solid $promo-light-blue;
            margin-top: 0.5rem;
        }

        .border-bottom-grey {
            border-bottom: 0.1rem solid $promo-grey-dark;
            color: $promo-grey-dark;
        }

        .border-top-grey {
            border-top: 0.1rem solid $promo-grey-dark;
        }

        .background-blue {
            background-color: $promo-table-blue-bg-colour;
        }

        .border-right {
            border-right: 0.1rem solid $promo-grey-dark;
            display: flex;
            justify-content: flex-end;
            padding-right: 0.5rem;
        }

        .cell {
            display: flex;
            justify-content: flex-end;
        }

        .positive {
            color: $positive-action;
        }

        .negative {
            color: $negative-action;
        }

        .selected-hierarchy {
            font-weight: 700;
        }

        &--name {
            font-size: 1.8rem;
        }

        &--current {
            color: $promo-grey-dark;
        }

        .highlighted,
        &--target {
            color: $promo-light-blue;
        }

        &--header,
        &--wrapper,
        &--name,
        &--productTarget,
        &--delta,
        &--forecasts,
        &--current,
        &--target {
            display: contents;
        }

        &--forecasts {
            font-weight: 600;
        }
    }
}
</style>
