<template>
    <v-card class="confirm">
        <v-card-text class="confirm__container text-center">
            <p class="confirm__question mb-3">{{ questionText | toSentenceCase }}</p>
            <p v-if="warningText" class="confirm__warning">{{ warningText | toSentenceCase }}</p>
        </v-card-text>

        <slot name="warningContent" />

        <v-card-actions class="justify-center flex-column">
            <v-btn primary depressed class="ma-1" @click="$emit('confirm')">
                {{ actionText | toSentenceCase }}
            </v-btn>
            <v-btn secondary depressed class="confirm__cancel ma-1" @click="$emit('cancel')">
                {{ $t('actions.cancel') | toSentenceCase }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        questionText: {
            type: String,
            required: true,
        },
        warningText: {
            type: String,
            required: false,
        },
        actionText: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.confirm {
    box-shadow: none;
    &__container {
        line-height: 1.5rem;
        p {
            color: $promo-text-colour;
        }
    }

    &__question {
        font-size: 1.4rem;
    }

    &__warning {
        font-weight: 600;
        font-size: 1.2rem;
    }

    &__cancel {
        color: $btn-ok-color;
    }
}
</style>
