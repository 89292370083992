var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "channels-tab" },
    [
      _c("div", [
        _c("span", { staticClass: "font-weight-bold" }, [
          _vm._v(
            _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("descriptionHighlight")))
          ),
        ]),
        _vm._v("\n        " + _vm._s(_vm.$tkey("description")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "vuex-form",
        _vm._b(
          { ref: "form", staticClass: "channels-tab__form" },
          "vuex-form",
          _vm.vBindObj,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }