var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "commitment-matrix", style: _vm.gridTemplate },
    [
      _c("div", { staticClass: "commitment-matrix__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("supplierCommitments.commitmentMatrix.storeGroups")
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.computedPromoResources, function (resource) {
        return _c(
          "div",
          {
            key: resource.key,
            staticClass: "commitment-matrix__resources-wrapper",
          },
          [
            _c(
              "div",
              {
                staticClass: "border-left align-top",
                style: _vm.getStyle({
                  resource: _vm.modelResources[resource.key],
                }),
              },
              [
                _c("vuex-icon-checkbox", {
                  staticClass: "commitment-matrix__resource-header",
                  attrs: {
                    getter: () => _vm.enabledModelResources,
                    setter: (resources) =>
                      _vm.resourcesSetter({ key: resource.key, resources }),
                    icon: resource.key,
                    "tooltip-label": resource.description,
                    value: resource.key,
                    validations: _vm.resourcesValidations,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.shouldRenderDetailedProvisions({ key: resource.key })
              ? _vm._l(
                  _vm.modelResources[resource.key].optionsKeys,
                  function (option) {
                    return _c(
                      "div",
                      {
                        key: option,
                        staticClass: "commitment-matrix__resource-options",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "commitment-matrix__resource-options--top",
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t(
                                      `general.detailedProvision.${option}`
                                    )
                                  )
                                ) +
                                "\n                    "
                            ),
                            _c("vuex-checkbox", {
                              attrs: {
                                getter: () =>
                                  _vm.getModelResourcesInstancesNames(
                                    resource.key
                                  ),
                                setter: (instances) =>
                                  _vm.instancesValueSetter({
                                    key: resource.key,
                                    type: option,
                                    instances,
                                  }),
                                value: _vm.getInstanceValue({
                                  key: resource.key,
                                  type: option,
                                }),
                                validations: _vm.detailedProvisionValidations(
                                  resource.key
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "commitment-matrix__resource-options--bottom resource-options--bottom-width",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "bottom-no-of-occurrences" },
                              [
                                _vm.modelResources[resource.key].instances[
                                  option
                                ].value
                                  ? _c(
                                      "div",
                                      { staticClass: "flex-row" },
                                      [
                                        _c("vuex-number-input", {
                                          staticClass:
                                            "commitment-matrix__occurrence",
                                          attrs: {
                                            getter: () =>
                                              _vm.instancesFieldGetter({
                                                key: resource.key,
                                                type: option,
                                                field: "noOfOccurrences",
                                              }),
                                            setter: (value) =>
                                              _vm.instancesFieldSetter({
                                                key: resource.key,
                                                type: option,
                                                value,
                                                field: "noOfOccurrences",
                                              }),
                                            validations: _vm.valueValidations,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "commitment-matrix__measurement-counts commitment-matrix__measurement-counts--executed",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.instancesFieldGetter({
                                                    key: resource.key,
                                                    type: option,
                                                    field: "executed",
                                                  })
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "commitment-matrix__measurement-counts commitment-matrix__measurement-counts--planned",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.instancesFieldGetter({
                                                    key: resource.key,
                                                    type: option,
                                                    field: "planned",
                                                  })
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        "\n                            -\n                        "
                                      ),
                                    ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "bottom-notes" },
                              [
                                _vm.modelResources[resource.key].instances[
                                  option
                                ].value
                                  ? _c("vuex-textarea", {
                                      staticClass: "commitment-matrix__note",
                                      attrs: {
                                        "auto-grow": false,
                                        "no-resize": true,
                                        placeholder:
                                          "supplierCommitments.commitmentMatrix.addNotes",
                                        getter: () =>
                                          _vm.instancesFieldGetter({
                                            key: resource.key,
                                            type: option,
                                            field: "note",
                                          }),
                                        setter: (value) =>
                                          _vm.instancesFieldSetter({
                                            key: resource.key,
                                            type: option,
                                            value,
                                            field: "note",
                                          }),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                )
              : [_vm._m(0, true)],
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commitment-matrix__store-groups" },
        [
          _c("vuex-select", {
            attrs: {
              options: _vm.storeGroupsOptions,
              getter: () => _vm.getStoreGroups(),
              setter: (value) => _vm.setStoreGroups({ value }),
              "validate-on-blur": false,
              "item-text": "description",
              "item-value": "key",
              multiple: "",
              chips: "",
              "deletable-chips": "",
              "finite-list": "",
              clearable: "",
              validations: _vm.storeGroupsValidations,
              placeholder: _vm._f("toSentenceCase")(
                _vm.$t("placeholders.selectOption.storeGroups")
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "border-left" }),
      _vm._v(" "),
      _c("div", { staticClass: "commitment-matrix__value border-left" }, [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm._f("toSentenceCase")(
                _vm.$t("supplierCommitments.commitmentMatrix.value")
              )
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commitment-matrix__value border-left" },
        [
          _c("vuex-currency-input", {
            attrs: {
              getter: () => _vm.getValue(),
              setter: (value) => _vm.setValue({ value }),
              validations: _vm.valueValidations,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "lastRow", staticClass: "commitment-matrix__key" }, [
        _c("div", { staticClass: "commitment-matrix__label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(_vm.$t("supplierCommitments.key"))
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "commitment-matrix__measurement-counts commitment-matrix__measurement-counts--executed",
        }),
        _vm._v(" "),
        _c("div", { staticClass: "commitment-matrix__label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("supplierCommitments.executedPromotions")
                )
              ) +
              "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "commitment-matrix__measurement-counts commitment-matrix__measurement-counts--planned",
        }),
        _vm._v(" "),
        _c("div", { staticClass: "commitment-matrix__label" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("supplierCommitments.plannedPromotions")
                )
              ) +
              "\n        "
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "commitment-matrix__resource-options" }, [
      _c("div", { staticClass: "commitment-matrix__resource-options--top" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "commitment-matrix__resource-options--bottom" },
        [
          _c("div", { staticClass: "bottom-no-of-occurrences" }),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-notes" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }