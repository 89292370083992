<template>
    <vuex-checkbox
        :getter="allocationGetter"
        :setter="allocateToCommitment"
        :ignore-parent-read-only="true"
        :make-read-only="isDisabled"
    />
</template>

<script>
import routeNames from '@enums/route-names';
import supplierCommitmentTypes from '@enums/supplier-commitment-types';
import { mapActions, mapState, mapGetters } from 'vuex';
import { includes, filter } from 'lodash';
import namespaces from '@enums/namespaces';
import { getMeasurementCounts } from '@/js/utils/supplier-commitment-utils';
import navigation from '@/js/navigation';

export default {
    props: {
        supplierCommitment: {
            required: true,
            type: Object,
        },
        promotionNamespace: {
            type: String,
            default: namespaces.default,
        },
    },

    data() {
        return {
            routeNames,
            navigation,
            defaultNamespace: namespaces.default,
        };
    },

    computed: {
        ...mapState('clientConfig', ['detailedProvisions']),
        ...mapGetters('scenarios', ['selectedScenario']),
        ...mapGetters('supplierCommitments', ['getProductCurrent', 'getExecutedAndPlannedFunding']),
        ...mapGetters('clientConfig', ['getHierarchyConfig']),
        ...mapGetters('promotions', [
            'selectedPromotion',
            'getSupplierCommitmentPromotions',
            'getStagingAreaPromotionById',
            'getPromotionsForSelectedSupplierCommitment',
        ]),

        allocateButtonName() {
            return this.isPromotionAllocated ? 'deallocate' : 'allocateToCommitment';
        },

        isPromotionAllocated() {
            return includes(this.supplierCommitment.allocatedPromotionIds, this.promotionNamespace);
        },

        isDisabled() {
            const currentPromotion = this.getStagingAreaPromotionById(this.promotionNamespace);
            return !(currentPromotion && currentPromotion.scenarioId);
        },
    },

    methods: {
        ...mapActions('supplierCommitments', [
            'allocateDeallocatePromotion',
            'updateSupplierCommitment',
        ]),
        ...mapActions('promotions', ['fetchPromotionsForSupplierCommitment']),

        allocationGetter() {
            return this.isPromotionAllocated;
        },

        async allocateToCommitment() {
            // Load in all allocated promotions for the selected supplier commitment.
            await this.fetchPromotionsForSupplierCommitment(this.supplierCommitment._id);

            let allocatedPromotions;
            let allocatedPromotionIds;
            if (this.isPromotionAllocated) {
                allocatedPromotions = filter(
                    this.getPromotionsForSelectedSupplierCommitment,
                    promotion => promotion._id !== this.promotionNamespace
                );

                allocatedPromotionIds = filter(
                    this.supplierCommitment.allocatedPromotionIds,
                    id => id !== this.promotionNamespace
                );
            } else {
                allocatedPromotions = [
                    ...this.getPromotionsForSelectedSupplierCommitment,
                    this.getStagingAreaPromotionById(this.promotionNamespace),
                ];

                allocatedPromotionIds = [
                    ...this.supplierCommitment.allocatedPromotionIds,
                    this.promotionNamespace,
                ];
            }

            let params;
            const selectedSupplierCommitment = this.supplierCommitment;

            if (this.supplierCommitment.type === supplierCommitmentTypes.totalSpend) {
                const updatedFunding = this.getExecutedAndPlannedFunding({
                    allocatedPromotions,
                    selectedSupplierCommitment,
                });

                params = {
                    supplierCommitmentId: selectedSupplierCommitment._id,
                    ...updatedFunding,
                    allocatedPromotionIds,
                    selectedPromotionId: this.promotionNamespace,
                    type: supplierCommitmentTypes.totalSpend,
                };
            } else {
                const storeGroupResources = this.updateMeasurementCounts();

                params = {
                    supplierCommitmentId: selectedSupplierCommitment._id,
                    promotionCurrent: allocatedPromotionIds.length,
                    productCurrent: this.getProductCurrent({
                        allocatedPromotions,
                        supplierKey: this.supplierCommitment.supplierKey,
                        hierarchy: this.supplierCommitment.hierarchy,
                    }),
                    allocatedPromotionIds,
                    selectedPromotionId: this.promotionNamespace,
                    storeGroupResources,
                    type: supplierCommitmentTypes.specifiedAllocations,
                };
            }

            this.allocateDeallocatePromotion(params);
        },

        updateMeasurementCounts() {
            const supplierCommitment = this.supplierCommitment;
            const promotion = this.selectedPromotion;
            const allocatedPromotions = this.getSupplierCommitmentPromotions;

            // If isPromotionAllocated is true then we want to deallocate the
            // promotion, so filter it out of the executed and planned arrays.
            if (this.isPromotionAllocated) {
                allocatedPromotions.executed = filter(
                    allocatedPromotions.executed,
                    p => p._id !== this.promotionNamespace
                );
                allocatedPromotions.planned = filter(
                    allocatedPromotions.planned,
                    p => p._id !== this.promotionNamespace
                );
            } else {
                // If we are allocating a new promotion, then we need to add it
                // to either the planned or executed promotions array
                // depending on the promotion start date.
                const today = this.$moment().utc();
                if (
                    this.$moment(promotion.startDate)
                        .utc()
                        .isAfter(today)
                ) {
                    allocatedPromotions.planned.push(promotion);
                } else {
                    allocatedPromotions.executed.push(promotion);
                }
            }

            // Traverse through each of the instances in storeGroupResources to
            // update the measurement counts.
            supplierCommitment.storeGroupResources.forEach(storeGroupResource => {
                storeGroupResource.resources.forEach(resource => {
                    resource.instances.forEach(instance => {
                        const { executed, planned } = getMeasurementCounts({
                            promotions: allocatedPromotions,
                            detailedProvisionKey: instance.type,
                            resourceKey: resource.resource,
                            supplierCommitment,
                            categoryLevel: this.getHierarchyConfig.categoryLevel,
                            detailedProvisions: this.detailedProvisions,
                        });

                        instance.planned = planned;
                        instance.executed = executed;
                    });
                });
            });

            return supplierCommitment.storeGroupResources;
        },
    },
};
</script>
