var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promotion-products-sidebar" }, [
    !_vm.candidate.products
      ? _c(
          "div",
          {
            staticClass: "promotion-products-sidebar__inner",
            class: { "disabled-color": _vm.isDisabledColor },
          },
          [
            _c("losenge-monetary-display", {
              attrs: {
                "incremental-amount": _vm.getMetrics({ product: _vm.candidate })
                  .incrementalAmount,
                efficiency: _vm.getMetrics({ product: _vm.candidate })
                  .efficiency,
                compact: "",
              },
            }),
          ],
          1
        )
      : _vm.showPgMetrics
      ? _c(
          "div",
          [
            _c("div", {
              staticClass:
                "product-group-filler promotion-products-sidebar__filler",
            }),
            _vm._v(" "),
            _vm._l(_vm.candidate.products, function (product, index) {
              return _c(
                "div",
                {
                  key: index + product.productKey,
                  staticClass:
                    "promotion-products-sidebar__inner promotion-products-sidebar__ppg",
                  class: { "disabled-color": _vm.isDisabledColor },
                },
                [
                  _c("losenge-monetary-display", {
                    attrs: {
                      "incremental-amount": _vm.getMetrics({ product })
                        .incrementalAmount,
                      efficiency: _vm.getMetrics({ product }).efficiency,
                      compact: "",
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }