<template>
    <create-new-button
        v-if="isCreateMode"
        :disabled="isCreateDisabled"
        background
        @createNew="addProductProxy"
    />
    <div v-else>
        <v-btn v-if="isEditMode" primary @click="addProductProxy">
            {{ $t('actions.save') | toSentenceCase }}
        </v-btn>
        <v-btn outlined @click="openRemoveProductProxyModal">
            {{ $tkey('removeProxy') | toSentenceCase }}
        </v-btn>
        <confirm-dialog
            :ref="`dialog_${productId}`"
            class="confirm-dialog"
            :has-activator="false"
            :question-text="$t('dialogs.removeProxy.header')"
            :action-text="$t('actions.remove')"
            @confirm="removeProductProxy()"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { products as productsResource } from '@enums/resources';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.products.newProductSetupModal',
    data() {
        return {
            productsResource,
        };
    },
    computed: {
        productId() {
            // This component should always be rendered as part of an ag-grid row
            // which makes the params object available. The data within the params
            // represents the row's model (i.e. a product).
            return this.params.data._id;
        },
        isCreateMode() {
            return !this.params.data.proxyProductKey;
        },
        isEditMode() {
            return !!this.params.context.componentParent.editedRows[this.productId];
        },

        isCreateDisabled() {
            const editedRow = this.params.context.componentParent.editedRows[this.productId];
            return !(editedRow && editedRow.proxyProductKey);
        },
    },
    methods: {
        ...mapActions('products', ['updateProducts']),
        ...mapActions('promotionCandidates', ['updatePromotionCandidate']),

        openRemoveProductProxyModal() {
            this.$refs[`dialog_${this.productId}`].open();
        },
        async removeProductProxy() {
            // Perform the update to remove the proxy product key.
            const productUpdates = [
                {
                    _id: this.productId,
                    proxyProductKey: null,
                    proxyClientProductKey: null,
                    isProposed: false,
                },
            ];
            const { result } = await this.updateProducts({
                updates: productUpdates,
                refreshData: false,
            });

            // Ensure the candidates data is also updated appropriately.
            if (result.ok) {
                this.params.context.componentParent.clearState();

                const promotionCandidateUpdates = {
                    proxyProductKey: null,
                    proxyClientProductKey: null,
                };
                await this.updatePromotionCandidate({
                    id: this.productId,
                    updates: promotionCandidateUpdates,
                });
                this.params.api.sizeColumnsToFit();
            }

            // Close the confirmation dialog.
            if (this.$refs[`dialog_${this.productId}`]) {
                this.$refs[`dialog_${this.productId}`].close();
            }
        },
        async addProductProxy() {
            // The grid isn't updated until editing is stopped (which normally occurs
            // when the user focuses out of the grid). If the user were to change
            // the proxy selected without us stopping editing, the proxyProductKey value
            // would be its old value (or null if it doesn't have an old value).
            this.params.context.componentParent.gridOptions.api.stopEditing();
            const proxyProductKey = this.params.data.proxyProductKey;
            const proxyClientProductKey = this.params.data.proxyClientProductKey;

            // Add the proxy to the editing product.
            const productUpdates = [
                { _id: this.productId, proxyProductKey, proxyClientProductKey },
            ];
            const { result } = await this.updateProducts({
                updates: productUpdates,
                refreshData: false,
            });

            // Ensure the candidates data is also updated appropriately.
            // Also stop any further editing in the grid.
            if (result.ok) {
                this.params.context.componentParent.clearState({ productId: this.productId });

                const promotionCandidateUpdates = { proxyProductKey, proxyClientProductKey };
                await this.updatePromotionCandidate({
                    id: this.productId,
                    updates: promotionCandidateUpdates,
                });
                this.params.api.sizeColumnsToFit();
            }
        },
    },
});
</script>

<style scoped></style>
