var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "campaign-container", style: _vm.gridCss },
    [
      _c("campaign", {
        key: _vm.campaign._id,
        attrs: {
          campaign: _vm.campaign,
          "parent-start-date": _vm.containerStartDate,
          "parent-end-date": _vm.containerEndDate,
          "is-locked": _vm.campaignReadOnly,
        },
        on: {
          opened: _vm.campaignOpened,
          "duplicate-campaign-clicked": _vm.campaignDuplicated,
        },
      }),
      _vm._v(" "),
      _vm._l(_vm.subCampaigns, function (subCampaign) {
        return _c("sub-campaign", {
          key: subCampaign._id,
          attrs: {
            campaign: _vm.campaign,
            "sub-campaign": subCampaign,
            "parent-start-date": _vm.containerStartDate,
            "parent-end-date": _vm.containerEndDate,
            "is-locked": _vm.subCampaignsReadOnlyById[subCampaign._id],
          },
          on: { opened: _vm.subCampaignOpened },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }