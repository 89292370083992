import featureFlags from '@enums/feature-flags';
import routeNames from '@enums/route-names';
import promotionTabs from '@enums/promotion-tabs';
import PlanningPage from './planning';
import promotionMaintenance from './components/promotion-maintenance';
import guardsMap from './planning-guard-map';

const defaultBeforeEnter = async (to, from, next) => {
    await guardsMap[to.name](to, from, next);
};

const routes = [
    {
        path: '/planning',
        id: 'planning',
        featureToggle: 'canAccessPlanning',
        component: PlanningPage,
        linkText: 'navBar.planning',
        index: 0,
        meta: { canAccess: featureFlags.canAccessPlanning, isSideBarCalendarExpanded: true },
        children: [
            // Default route to ensure that all '/planning' requests are redirected to the planningAllCampaignsView.
            {
                path: '',
                name: 'planning',
                redirect: { name: routeNames.planningAllCampaignsView },
            },
            {
                path: 'allCampaignsView',
                name: routeNames.planningAllCampaignsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'singleCampaignView',
                redirect: { name: routeNames.planningAllCampaignsView },
                children: [
                    {
                        path: ':campaignId',
                        name: routeNames.planningSingleCampaignView,
                        beforeEnter: defaultBeforeEnter,
                    },
                ],
            },
            {
                path: 'singleSubCampaignView',
                redirect: { name: routeNames.planningAllCampaignsView },
                children: [
                    {
                        path: ':subCampaignId',
                        name: routeNames.planningSingleSubCampaignView,
                        beforeEnter: (to, from, next) =>
                            guardsMap[routeNames.planningSingleSubCampaignView](to, from, next),
                        children: [
                            {
                                path: 'scenario',
                                children: [
                                    {
                                        path: ':scenarioId',
                                        name: routeNames.scenarioView,
                                        beforeEnter: (to, from, next) =>
                                            guardsMap[routeNames.scenarioView](to, from, next),
                                        children: [
                                            {
                                                path: 'promotion',
                                                name: routeNames.promotionsViewer,
                                                children: [
                                                    {
                                                        path: ':promotionId',
                                                        name: routeNames.promotionView,
                                                        component: promotionMaintenance,
                                                        redirect: to => ({
                                                            name: routeNames.promotionTabView,
                                                            params: {
                                                                ...to.params,
                                                                tabName: promotionTabs.offer,
                                                            },
                                                        }),
                                                        children: [
                                                            {
                                                                path: ':tabName',
                                                                name: routeNames.promotionTabView,
                                                                beforeEnter: (to, from, next) =>
                                                                    guardsMap[
                                                                        routeNames.promotionTabView
                                                                    ](to, from, next),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default routes;
