var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main-expansion-panel",
    {
      attrs: {
        label: _vm.$t(`supplierCommitments.viewPromotions`),
        "is-open-state": null,
      },
    },
    _vm._l(_vm.promotions, function (promotionGroup, index) {
      return _c(
        "div",
        { key: index, staticClass: "sp-promotions" },
        [
          promotionGroup.data.length > 0
            ? _c("h3", { staticClass: "sp-promotions__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(_vm.$t(promotionGroup.label))
                    ) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("promotions-list", {
            attrs: { promotions: promotionGroup.data, fields: _vm.fields },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }