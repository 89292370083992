<template>
    <div class="file-upload">
        <form enctype="multipart/form-data">
            <input
                :id="uploadId"
                type="file"
                name="file"
                :accept="allowedExtensionsForInput"
                class="file-upload__input"
                :disabled="disabled || uploadInProgress"
                @change="fileSelected($event.target.name, $event.target.files)"
            />
            <label :for="uploadId" class="file-upload__label" :class="{ disabled: disabled }">
                <div class="file-upload__label-contents">
                    <span>{{ $t('actions.upload') | toSentenceCase }}</span>
                    <icon icon-name="upload" right small />
                </div>
            </label>
        </form>
    </div>
</template>

<script>
import { each } from 'lodash';
import { mapActions } from 'vuex';

export default {
    props: {
        uploadId: {
            required: true,
            type: String,
        },
        allowedExtensions: {
            required: true,
            type: Array,
        },
        vuexModule: {
            required: true,
            type: String,
        },
        vuexAction: {
            required: true,
            type: String,
        },
        onUpload: {
            required: false,
            type: Function,
            default: () => {},
        },
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    data() {
        return {
            uploadInProgress: false,
        };
    },
    computed: {
        allowedExtensionsForInput() {
            return this.allowedExtensions.join(',');
        },
    },
    methods: {
        ...mapActions({
            upload(dispatch, formData) {
                return dispatch(`${this.vuexModule}/${this.vuexAction}`, {
                    dataFile: formData,
                    onUpload: this.onUpload,
                });
            },
        }),
        async fileSelected(fieldName, fileList) {
            if (!fileList.length) return;

            const formData = new FormData();
            each(fileList, file => formData.append(fieldName, file, file.name));
            this.uploadInProgress = true;
            await this.upload(formData);
            this.uploadInProgress = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.file-upload {
    // The choose-file input is hidden from view to avoid the browser default styling, and to enable a custom label
    // which can use translated text.
    // The input cannot be completely removed from the DOM, or even hidden, otherwise the file chosen will not be sent
    // with the form request.
    &__input {
        width: 0.01rem;
        height: 0.01rem;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__label {
        display: inline-block;
        padding: 0 1rem;

        background-color: transparent;
        border: thin solid $promo-primary-colour;
        border-radius: 0.4rem;
        color: $promo-primary-colour;
        height: 2.4rem;

        &:hover {
            cursor: pointer;
            color: $rtls-secondary-button-hover-colour;
            border-color: $rtls-secondary-button-hover-colour;
            span > svg path {
                fill: $rtls-secondary-button-hover-colour;
            }
        }

        &.disabled {
            opacity: 0.2;
        }
    }

    &__label-contents {
        @include flex-center;
        padding-top: 0.2rem;
    }
}
</style>
