import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "parkingLot.allocatePromotion.heading",
      "btn-confirm-text": "actions.copy",
      "has-activator": _vm.hasActivator,
      "confirm-btn-disabled": _vm.disabled,
    },
    on: {
      confirm: _vm.allocatePromotionsDialogConfirm,
      close: _vm.closeDialog,
    },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    "icon",
                    _vm._g(
                      {
                        attrs: { "icon-name": "duplicate", small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      activator
                    )
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _c("div", { staticClass: "allocate-description" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        "parkingLot.allocatePromotion.pastPromotionsDescription"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "allocate-destination" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("parkingLot.allocatePromotion.destination")) +
                    ":\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "allocate-past-promotion" },
                [
                  _c(
                    VRadioGroup,
                    {
                      staticClass: "allocate-past-promotion__radio",
                      model: {
                        value: _vm.selectedType,
                        callback: function ($$v) {
                          _vm.selectedType = $$v
                        },
                        expression: "selectedType",
                      },
                    },
                    [
                      _c(VRadio, {
                        attrs: {
                          label: _vm.toSentenceCase(
                            _vm.$t("parkingLot.allocatePromotion.parkingLot")
                          ),
                          value: _vm.parkingLot,
                        },
                        on: {
                          click: function ($event) {
                            _vm.selectedType = _vm.parkingLot
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("vuex-date-range", {
                    key: _vm.dateRange.length,
                    staticClass: "allocate-past-promotion__date-range",
                    attrs: {
                      from: { fieldName: "from" },
                      to: { fieldName: "to" },
                      "use-vuex-staging-area": false,
                      getter: () => _vm.dateRange,
                      "min-date": _vm.minDateRangeDate,
                    },
                    on: { change: _vm.dateRangeSelected },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.requestInProgress
                ? _c(
                    "div",
                    { staticClass: "in-progress--message" },
                    [
                      _c("label", { staticClass: "in-progress__message" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t(
                                "parkingLot.allocatePromotion.inProgressMessage"
                              )
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("spinner-dynamic"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.requestInProgress
                ? _c(VOverlay, {
                    staticClass: "allocate-past-promotion__overlay",
                    attrs: { absolute: true, value: true, opacity: 0.3 },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }