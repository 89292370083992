<template>
    <v-menu
        v-model="showNotes"
        content-class="notes-wrapper"
        :close-on-content-click="false"
        v-bind="positionProps"
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                :disabled="disabled"
                icon
                color="primary"
                class="notes-icon"
                v-on="on"
            >
                <icon v-if="hasNotes" icon-name="book-notification" small />
                <icon v-else icon-name="book" small />
            </v-btn>
        </template>

        <div class="notes">
            <div class="notes__header">
                <h3>{{ $t('notes.title') | toSentenceCase }}</h3>
                <v-btn icon @click="close">
                    <icon icon-name="cancel" small icon-colour-type="primary-path" />
                </v-btn>
            </div>
            <div v-if="!showConfirmDelete">
                <div v-for="note in notes" :key="note._id" class="notes__container">
                    <v-avatar class="notes__author" color="primary" size="26">
                        <span class="white--text headline">{{ getInitials(note) }}</span>
                    </v-avatar>
                    <div class="notes__content">
                        <div class="notes__date-container">
                            <p class="notes__date">{{ note.lastUpdatedOn | dateTime }}</p>
                            <v-menu v-if="!noteActionsDisabled(note)" close-on-click>
                                <template v-slot:activator="{ on }">
                                    <v-icon color="black" v-on="on">mdi-dots-horizontal</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item @click="editNote(note)">
                                        {{ $t('actions.edit') | toSentenceCase }}
                                    </v-list-item>
                                    <v-list-item @click="deleteNote(note._id)">
                                        {{ $t('actions.delete') | toSentenceCase }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="notes__text">
                            <div v-if="noteToEdit === note._id" class="notes__input">
                                <v-textarea
                                    v-model="editText"
                                    :label="$t('notes.editNotePlaceholder') | toSentenceCase"
                                    hide-details
                                    no-resize
                                    solo
                                    autocomplete
                                    flat
                                    rows="0"
                                />
                                <div class="notes__button float-right">
                                    <v-btn secondary small depressed height="18" @click="clear">{{
                                        $t('actions.cancel') | toSentenceCase
                                    }}</v-btn>
                                    <v-btn
                                        primary
                                        small
                                        depressed
                                        height="18"
                                        class="ml-1"
                                        :disabled="isSaveButtonDisabled(editText)"
                                        @click="save(note)"
                                        >{{ $t('actions.save') | toSentenceCase }}</v-btn
                                    >
                                </div>
                            </div>
                            <p v-else>{{ note.text }}</p>
                        </div>
                    </div>
                </div>
                <div class="notes__input-container">
                    <div class="notes__input">
                        <v-textarea
                            v-model="text"
                            :label="$t('notes.addNewNotePlaceholder') | toSentenceCase"
                            hide-details
                            no-resize
                            solo
                            autocomplete
                            flat
                            rows="0"
                        />
                    </div>
                    <div class="notes__button">
                        <v-btn
                            primary
                            small
                            depressed
                            height="18"
                            :disabled="isSaveButtonDisabled(text)"
                            @click="post"
                        >
                            {{ $t('notes.postNoteButton') | toSentenceCase }}
                        </v-btn>
                    </div>
                </div>
            </div>
            <confirm-action
                v-else
                :action-text="$t('actions.delete')"
                :question-text="$t('notes.confirmDeleteQuestion')"
                :warning-text="$t('notes.confirmDeleteWarning')"
                @cancel="clear"
                @confirm="confirmDelete"
            />
        </div>
    </v-menu>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';

export default {
    props: {
        notes: Array,
        disabled: {
            type: Boolean,
            default: false,
        },
        openLeft: {
            type: Boolean,
            default: false,
        },
        openRight: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        overrideReadOnly: true,
        showNotes: false,
        text: null,
        editText: null,
        noteToEdit: null,
        noteToDelete: null,
        showConfirmDelete: false,
    }),

    computed: {
        ...mapState('context', ['profile']),
        hasNotes() {
            return !isEmpty(this.notes);
        },
        positionProps() {
            if (this.openLeft && !this.openRight) return { left: true };
            if (this.openRight && !this.openLeft) return { right: true };
            // not sure if offset-x is needed but leaving in to avoid any regressions
            return { auto: true, 'offset-x': true };
        },
    },

    methods: {
        post() {
            this.$emit('post', { text: this.text });
            this.text = null;
        },

        save(note) {
            this.$emit('save', { ...note, text: this.editText });
            this.clear();
        },

        clear() {
            this.noteToEdit = null;
            this.editText = null;
            this.noteToDelete = null;
            this.showConfirmDelete = false;
        },

        deleteNote(noteId) {
            this.showConfirmDelete = true;
            this.noteToDelete = noteId;
        },

        confirmDelete() {
            this.$emit('delete', this.noteToDelete);
            this.clear();
        },

        editNote(note) {
            this.noteToEdit = note._id;
            this.editText = note.text;
        },

        close() {
            this.showNotes = false;
        },

        noteActionsDisabled(note) {
            // User cannot delete a note if they did not create it
            // Should disable actions for a note when editing it.
            return (
                this.profile &&
                (this.profile._id !== note.createdBy || note._id === this.noteToEdit)
            );
        },

        isSaveButtonDisabled(text) {
            return !text || text.length === 0;
        },

        getInitials(note) {
            if (note && note.user) {
                const { firstname, surname } = note.user;
                return this.$options.filters.toSentenceCase(`${firstname[0]}${surname[0]}`);
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.notes-wrapper {
    z-index: $notes-z-index !important;
    border-radius: 0;
}

.notes {
    width: 32rem;
    max-height: 85vh;
    overflow-y: auto;
    background: $promo-white;
    padding: 0.7rem;
    border-left: 0.5rem solid $promo-primary-colour;

    &__header {
        font-size: 1.4rem;
        padding: 0.3rem 0 1rem 1rem;
        display: flex;
        justify-content: space-between;
    }

    &__container {
        display: flex;
        background: $promo-grey-5;
        padding: 1rem 0.7rem;
        margin-bottom: 0.5rem;
    }

    &__author {
        height: 2.6rem;
        width: 2.6rem;
    }

    &__content {
        width: calc(100% - 3.6rem);
        padding-left: 1rem;
    }

    &__date {
        font-weight: bold;
        font-size: 1.2rem;
    }

    &__date-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__input-container {
        padding: 1rem 1rem 0.5rem;
        background: $promo-grey-6;
    }

    &__button {
        position: relative;
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-start;
    }

    &__text {
        padding-right: 0.5rem;
        font-size: 1.2rem;
        word-break: break-word;
    }

    &__input {
        ::v-deep {
            .v-textarea {
                border-radius: 0;
                box-shadow: 0;
            }

            .v-label {
                top: 0.5rem !important;
            }

            .v-input__slot {
                .v-text-field__slot {
                    textarea {
                        margin: 0;
                    }
                }
            }
        }
    }
}
</style>
