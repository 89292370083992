'use strict';

/**
 * Enum definition file for promotion-versions reasons.
 *
 * @module data/enums/reward-types
 */
module.exports = {
    automaticCreation: 'AUTOMATIC_CREATION',
};
