import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "excludeStoresDialog",
    attrs: {
      "translated-header": _vm._f("toSentenceCase")(
        _vm.$tkey(`storeList`, { name: _vm.storeGroup.description })
      ),
      "btn-confirm-text": "actions.save",
      "btn-cancel-text": "actions.cancel",
      "has-activator": "",
      "confirm-btn-disabled": _vm.isSaveDisabled,
    },
    on: { confirm: _vm.saveExcludeStoresList, close: _vm.closeDialog },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function ({ activator }) {
          return [
            _c(
              VBtn,
              _vm._g(
                { attrs: { disabled: _vm.disabled, icon: "", small: "" } },
                activator
              ),
              [
                _c("div", {
                  staticClass: "activator",
                  class: { excluded: _vm.excludedStores.length },
                }),
              ]
            ),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "exclude-stores-dialog" },
              [
                _c("promo-ag-grid", {
                  ref: "agGrid",
                  attrs: {
                    "row-data": _vm.stores,
                    "column-defs": _vm.columnDefs,
                    "grid-options": _vm.gridOptions,
                    "default-col-def": _vm.defaultColDef,
                    "grid-style": "width: 115rem; height: 35rem;",
                    "grid-class": "ag-theme-custom__exclude-stores-dialog",
                  },
                  on: {
                    "row-data-updated": _vm.setIsSaveDisabled,
                    "cell-value-changed": _vm.setIsSaveDisabled,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }