<template>
    <funding-tab
        v-bind="form"
        :form-ref="formRef"
        :is-parking-lot="isParkingLot"
        :is-past-promotions="isPastPromotions"
        :cache-dom="cacheDom"
    />
</template>

<script>
import promotionTabFormWrapperMixin from '@/js/pages/planning/components/mixins/promotion-tab-form-wrapper';

export default {
    mixins: [promotionTabFormWrapperMixin],
    props: {
        isParkingLot: {
            type: Boolean,
            default: false,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
        formRef: {
            type: Object,
            required: false,
        },
    },
    computed: {
        form() {
            return {
                vuexModule: 'promotions',
                editMode: this.editMode,
                submitAction: this.isNewPromotion ? null : 'prepareAndUpdatePromotion',
                preventDefault: true,
                namespace: this.namespace,
                context: this.context,
                editContext: this.context.selectedPromotion,
                fields: [
                    {
                        fieldName: 'products',
                        editable: true,
                    },
                ],
            };
        },
    },
};
</script>
