/* eslint-disable import/prefer-default-export */

/**
 * This function provide needed arrow icon for healthbar kpi metric
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.kpi - kpi metric.
 */
export function getArrowClass({ kpi }) {
    if (!kpi) return '';
    return kpi > 0 ? ['fa fa-arrow-up'] : ['fa fa-arrow-down'];
}
