<template>
    <v-tabs
        v-model="selectedTab"
        height="2.5rem"
        background-color="transparent"
        class="promo-tabs"
        active-class="promo-tabs__tab--active"
        hide-slider
        @change="navigateToTab"
    >
        <template v-for="tab in tabs">
            <!-- Used to fill and style the space between tabs. -->
            <v-tab
                :key="`tab-${tab.label}`"
                :disabled="tab.disabled"
                class="promo-tabs__tab"
                :class="tab.class"
            >
                {{ tab.label | toSentenceCase }}
            </v-tab>
            <div :key="`spacer-${tab.label}`" class="promo-tabs__spacer" />
        </template>

        <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
        <div class="promo-tabs__filler" />

        <v-tab-item
            v-for="tab in tabs"
            :key="`tab-item-${tab.label}`"
            :transition="false"
            :reverse-transition="false"
            :active-class="tab.class"
            class="tab"
        >
            <component :is="tab.component" />
        </v-tab-item>
    </v-tabs>
</template>

<script>
import { findIndex, includes } from 'lodash';

export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedTab: 0,
        };
    },
    created() {
        this.setDefaultTab();
    },
    methods: {
        navigateToTab() {
            this.tabs[this.selectedTab].navigateTo();
        },

        setDefaultTab() {
            const routeName = this.$route.name;
            const tabToOpen = findIndex(this.tabs, tab => includes(tab.routeNames, routeName));

            this.selectedTab = tabToOpen;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-tabs-items {
    background-color: transparent !important;
}
</style>
