<template>
    <main-dialog
        ref="dialog"
        heading="planning.copyPromotion.heading"
        btn-confirm-text="actions.copy"
        :has-activator="hasActivator"
        :confirm-btn-disabled="!selectedScenarioId || copyInProgress"
        @confirm="copyPromotionDialogConfirm"
        @close="closeDialog"
    >
        <template v-if="hasActivator" v-slot:actions="{ activator }">
            <icon icon-name="duplicate" small v-on="activator" @click.stop />
        </template>
        <template v-slot:default>
            <div class="copy-promotion">
                <div class="copy-promotion__description">
                    {{ $t('planning.copyPromotion.description') | toSentenceCase }}
                    <v-tooltip z-index="400" top :max-width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" color="primary" size="20" v-on="on">
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('planning.copyPromotion.tooltip') | toSentenceCase }}</span>
                    </v-tooltip>
                </div>
                <div class="copy-campaign__form-item select-resource">
                    <label class="select-resource__label">
                        {{ $t('planning.copyPromotion.campaign') | toSentenceCase }}
                    </label>
                    <vuex-select
                        :placeholder="
                            $t('planning.copyPromotion.placeholder.selectCampaign') | toSentenceCase
                        "
                        class="select-resource__select"
                        :options="editableCampaigns"
                        filled
                        :getter="() => selectedCampaignId"
                        :setter="value => setSelectedCampaign(value)"
                    />
                </div>
                <div class="copy-campaign__form-item select-resource">
                    <label class="select-resource__label">
                        {{ $t('planning.copyPromotion.subCampaign') | toSentenceCase }}
                    </label>
                    <vuex-select
                        :placeholder="
                            $t('planning.copyPromotion.placeholder.selectSubcampaign')
                                | toSentenceCase
                        "
                        class="select-resource__select"
                        :options="editableSubCampaigns"
                        :disabled="!selectedCampaignId"
                        filled
                        :getter="() => selectedSubCampaignId"
                        :setter="value => setSelectedSubCampaign(value)"
                    />
                </div>
                <div class="copy-campaign__form-item select-resource">
                    <label class="select-resource__label">
                        {{ $t('planning.copyPromotion.scenario') | toSentenceCase }}
                    </label>
                    <vuex-select
                        :placeholder="
                            $t('planning.copyPromotion.placeholder.selectScenario') | toSentenceCase
                        "
                        class="select-resource__select"
                        :options="editableScenarios"
                        :disabled="!selectedSubCampaignId"
                        filled
                        :getter="() => selectedScenarioId"
                        :setter="value => setSelectedScenario(value)"
                    />
                </div>
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import to from 'await-to-js';

export default {
    props: {
        hasActivator: {
            type: Boolean,
            default: true,
        },
        promotions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedCampaignId: null,
            selectedSubCampaignId: null,
            selectedScenarioId: null,
            overrideReadOnly: true,
            copyInProgress: false,
        };
    },
    computed: {
        ...mapState('campaigns', ['campaigns']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('subCampaigns', ['getSubCampaignsByCampaignId', 'getSubCampaignById']),
        ...mapGetters('campaigns', ['getCampaignById']),
        ...mapGetters('scenarios', ['getScenariosBySubCampaignId', 'getScenarioById']),
        ...mapGetters('products', ['getProductsCategories']),

        promotionCategories() {
            return (this.promotions || []).flatMap(promotion => {
                return promotion.categories;
            });
        },

        featureAwareFactory() {
            return createFeatureAwareFactory(this.toggleLogic);
        },
        editableCampaigns() {
            return this.campaigns
                .filter(campaign => {
                    const editingState = this.featureAwareFactory.isCampaignEditingDisabled(
                        {
                            campaign: this.getCampaignById({
                                _id: campaign._id,
                                usePluralResourceName: true,
                            }),
                        },
                        this.$moment
                    );

                    const allowCopy =
                        !editingState.disabled ||
                        !this.featureAwareFactory.isEndDateInThePast(
                            { endDate: campaign.endDate },
                            this.$moment
                        );

                    return (
                        allowCopy &&
                        this.promotionCategories.every(category =>
                            campaign.categories.includes(category)
                        )
                    );
                })
                .map(campaign => this.formatForSelect(campaign));
        },
        editableSubCampaigns() {
            if (!this.selectedCampaignId) return [];

            const subCampaigns = this.getSubCampaignsByCampaignId(this.selectedCampaignId);

            return subCampaigns
                .filter(subCampaign => {
                    const editingState = this.featureAwareFactory.isSubCampaignEditingDisabled(
                        {
                            subCampaign: this.getSubCampaignById({
                                _id: subCampaign._id,
                                usePluralResourceName: true,
                            }),
                        },
                        this.$moment
                    );

                    const allowCopy =
                        !editingState.disabled ||
                        !this.featureAwareFactory.isEndDateInThePast(
                            {
                                endDate: subCampaign.endDate,
                            },
                            this.$moment
                        );

                    return (
                        allowCopy &&
                        this.promotionCategories.every(category =>
                            subCampaign.categories.includes(category)
                        )
                    );
                })
                .map(subCampaign => this.formatForSelect(subCampaign));
        },
        editableScenarios() {
            if (!this.selectedSubCampaignId) return [];

            const scenarios = this.getScenariosBySubCampaignId(this.selectedSubCampaignId);

            return scenarios
                .filter(scenario => {
                    const editingState = this.featureAwareFactory.isScenarioEditingDisabled(
                        {
                            scenario: this.getScenarioById({
                                _id: scenario._id,
                                usePluralResourceName: true,
                            }),
                        },
                        this.$moment
                    );

                    const allowCopy =
                        !editingState.disabled ||
                        !this.featureAwareFactory.isEndDateInThePast(
                            { endDate: scenario.endDate },
                            this.$moment
                        );

                    return (
                        allowCopy &&
                        this.promotionCategories.every(category =>
                            scenario.categories.includes(category)
                        )
                    );
                })
                .map(scenario => this.formatForSelect(scenario));
        },
    },
    methods: {
        ...mapActions('promotions', ['copyPromotions']),
        ...mapActions('scenarios', ['fetchScenarios']),

        async setSelectedCampaign(value) {
            this.selectedCampaignId = value;
            this.selectedSubCampaignId = null;
            this.selectedScenarioId = null;
        },
        async setSelectedSubCampaign(value) {
            // All campaigns and sub-campaigns are available in the tool on app load
            // however, scenarios are only loaded when the sub-campaign is viewed in detail.
            // As a result we need to retrieve the scenarios for the selected sub-campaign.
            this.fetchScenarios({
                params: {
                    where: { subCampaignId: value },
                },
                patchState: true,
            });

            this.selectedSubCampaignId = value;
            this.selectedScenarioId = null;
        },
        async setSelectedScenario(value) {
            this.selectedScenarioId = value;
        },

        async copyPromotionDialogConfirm() {
            this.copyInProgress = true;
            const [error] = await to(
                this.copyPromotions({
                    scenarioId: this.selectedScenarioId,
                    promotionIds: this.promotions.map(p => p._id),
                })
            );

            if (!error) {
                this.closeDialog();
            }
            this.copyInProgress = false;
        },

        openDialog() {
            this.$refs.dialog.open();
            this.copyInProgress = false;
        },
        closeDialog() {
            this.$refs.dialog.close();
            this.selectedCampaignId = null;
            this.selectedSubCampaignId = null;
            this.selectedScenarioId = null;
        },

        formatForSelect(item) {
            return {
                value: item._id,
                text: `${item.name} - ${this.$d(
                    'dates.default.localShortDate',
                    item.startDate
                )} - ${this.$d('dates.default.localShortDate', item.endDate)}`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.copy-promotion {
    font-size: 1.2rem;

    &__description {
        padding: 1rem 0;
    }

    &__form-item {
        @include flex-row;
        align-items: center;
        padding: 1rem 2rem;
    }
    .select-resource {
        @include flex-row;
        align-items: center;
        justify-content: flex-end;

        padding-bottom: 1rem;

        &__label {
            margin-right: 1rem;
            font-weight: bold;
        }

        &__select {
            width: 40rem;
        }
    }
}
</style>
