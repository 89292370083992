<template>
    <div class="summary-kpis-row">
        <span
            :class="[isHeaderBold ? 'summary-kpis-row__header--bold' : 'summary-kpis-row__header']"
        >
            {{ (!isHeaderRow ? header : '') | toSentenceCase }}
        </span>
        <span class="summary-kpis-row__data">
            {{ (!isHeaderRow ? KPIMetric : header) | toSentenceCase }}
        </span>
        <span class="summary-kpis-row__data">
            {{ !isHeaderRow ? deltaKPIMetric : '' }}
            <v-icon v-if="isHeaderRow" size="15">change_history</v-icon>
        </span>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default {
    props: {
        weekObj: {
            type: Object,
            required: false,
        },
        metric: {
            type: String,
            required: false,
        },
        header: {
            type: String,
            required: true,
        },
        isHeaderRow: {
            type: Boolean,
            default: false,
        },
        isHeaderBold: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('weeks', ['getDeltaKPIMetrics']),
        ...mapState('promotions', ['weeklyMetrics']),
        KPIMetric() {
            const yearWeekKey = `${this.weekObj.year}${this.weekObj.weekOfYear}`;
            const metricValue = get(
                this.weeklyMetrics[yearWeekKey],
                `KPIMetrics.${this.metric}`,
                null
            );
            // 0 is a valid value
            return this.$options.filters.totalSales(metricValue);
        },

        deltaKPIMetric() {
            const currentWeekKey = `${this.weekObj.year}${this.weekObj.weekOfYear}`;
            const deltaKPIMetrics = this.getDeltaKPIMetrics(currentWeekKey);

            if (deltaKPIMetrics) {
                const deltaValue = deltaKPIMetrics[this.metric];
                const deltaValueFormatted = this.$options.filters.totalSales(deltaValue);
                return deltaValue > 0 ? `+${deltaValueFormatted}` : deltaValueFormatted;
            }

            // return placeholder '-' for no current week data in all conditions
            return '-';
        },
    },
};
</script>

<style lang="scss" scoped>
.summary-kpis-row {
    display: flex;
    text-align: right;

    &__header {
        width: 20%;
        &--bold {
            width: 20%;
            font-weight: 600;
        }
    }

    &__data {
        width: 40%;
        font-weight: 600;
    }
}
</style>
