<template>
    <v-layout>
        <p>{{ data.title }}</p>
    </v-layout>
</template>

<script>
export default {
    data() {
        return {
            data: {
                title: 'This is template for PROMOv3',
            },
        };
    },
};
</script>
