<template functional>
    <div class="losenge-monetary-display" :class="$options.methods.getFlexClass(props.compact)">
        <div
            class="incremental-amount"
            :class="$options.methods.getCompactIncrementalAmountClass(props.compact)"
        >
            {{ props.incrementalAmount | totalSales }}
        </div>
        <div v-if="!props.compact">
            {{ parent.$t('planning.promotionsViewer.efficiency') | toSentenceCase }}
        </div>
        <div :class="$options.methods.getCompactEfficiencyClass(props.compact)">
            {{
                parent.$n('numbers.default.efficiencyRatio', props.efficiency, {
                    usePlaceholder: true,
                })
            }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        incrementalAmount: {
            type: Number,
        },
        efficiency: {
            type: [Number, String],
        },
        compact: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    methods: {
        getFlexClass(compact) {
            return compact ? 'flex-row' : 'flex-column';
        },
        getCompactEfficiencyClass(compact) {
            return compact ? 'compact-efficiency' : '';
        },
        getCompactIncrementalAmountClass(compact) {
            return compact ? 'compact-incremental-amount' : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.losenge-monetary-display {
    width: 100%;
}
.incremental-amount {
    font-size: 1.4rem;
}
.compact-incremental-amount {
    width: 50%;
    text-align: right;
}
.compact-efficiency {
    width: 50%;
    padding-left: 1rem;
    padding-right: 0.5rem;
    font-size: 1.2rem;
    text-align: right;
}
</style>
