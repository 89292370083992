var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("aside", { staticClass: "page__details-sidebar elevation-2" }, [
      _c("div", { staticClass: "sidebar-wrapper" }, [
        _c("h2", { staticClass: "sidebar-wrapper__heading" }, [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm._f("toSentenceCase")(_vm.$t("landing.detailsSidebarTitle"))
              ) +
              "\n            "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page__content" }, [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "top__alerts" }, [
          _c(
            "div",
            { staticClass: "alerts-wrapper" },
            [
              _c("h2", { staticClass: "alerts-wrapper__heading" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm._f("toSentenceCase")(_vm.$t("landing.alertsTitle"))
                    ) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("landing-page-alerts", {
                staticClass: "alerts-wrapper__content",
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "top__notifications" }, [
          _c(
            "div",
            { staticClass: "notifications-wrapper" },
            [
              _c("h2", { staticClass: "notifications-wrapper__heading" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("landing.notifications.notifications")
                      )
                    ) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("notifications-list", {
                staticClass: "notifications-wrapper__content",
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page__filter-bar" }, [
      _c("div", { staticClass: "page__filter-bar--placeholder" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "bottom__last-viewed" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }