import { mapActions, mapGetters } from 'vuex';
import { find, isNil, map } from 'lodash';

const mixin = {
    computed: {
        ...mapGetters('suppliers', ['categoryFromFilter', 'suppliersFromFilter']),
        ...mapGetters('context', ['userCategories']),

        defaultSupplier() {
            return this.suppliersFromFilter.length === 1 ? this.suppliersFromFilter[0] : null;
        },

        categoryKeys() {
            const hierarchy = this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'hierarchy',
            });
            return hierarchy ? map(hierarchy, 'levelEntryKey') : [];
        },

        supplierKey() {
            const supplier = this.getStagingAreaField({
                namespace: this.namespace,
                fieldName: 'supplier',
            });
            return supplier ? supplier.supplierKey : null;
        },
    },
    methods: {
        ...mapActions('suppliers', ['fetchSuppliersByCategories']),

        async onSupplierCategoryChange({
            supplier = null,
            categories = null,
            getAdditionalFieldsToUpdate = () => {
                return [];
            },
            // specified allocations has 2 fields instead of 1 supplier object
            supplierFields = ['supplier'],
        }) {
            let supplierKey = null;
            let categoryKeys;
            let fieldsToUpdate = [];

            // if categories are updated
            if (!isNil(categories)) {
                categoryKeys = categories ? map(categories, 'levelEntryKey') : [];
                // add categories to the list to send for update
                fieldsToUpdate.push({ fieldName: 'hierarchy', value: categories });

                supplierKey = this.supplierKey;
                await this.fetchSuppliersByCategories(categoryKeys);
                // if supplier has been already selected,
                // but updated suppliers options list doesn't include selected supplier
                // we need clear supplier dropdown
                if (
                    !isNil(this.supplierKey) &&
                    !find(this.suppliers, { supplierKey: this.supplierKey })
                ) {
                    supplierKey = null;
                    // add supplier to the list to send for update
                    fieldsToUpdate.push(
                        ...map(supplierFields, fieldName => ({ fieldName, value: null }))
                    );
                }
            }

            // if supplier is updated
            if (!isNil(supplier)) {
                // add supplier to the list to send for update
                fieldsToUpdate.push({ fieldName: 'supplier', value: supplier });

                supplierKey = supplier.supplierKey;
                categoryKeys = this.categoryKeys;
            }

            // call function to get other fields to update on supplier or category change
            const additionalFieldsToUpdate = await getAdditionalFieldsToUpdate({
                supplierKey,
                categoryKeys,
            });
            fieldsToUpdate = [...fieldsToUpdate, ...additionalFieldsToUpdate];

            // all the updated fields depend on each other,
            // they need to be updated together
            // if form is invalid, they won't be sent to mongo
            this.setStagingAreaFields({
                namespace: this.namespace,
                fieldsToUpdate,
            });
        },
    },
};

export default mixin;
