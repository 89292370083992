var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isEditMode
        ? _c("free-gifts-delete-dialog", {
            attrs: {
              resource: _vm.freeGiftsResource,
              "resource-id": _vm.freeGiftId,
              "child-dependencies-warning": false,
            },
          })
        : _c("delete-button", {
            attrs: { background: "" },
            on: { delete: _vm.cancelCreation },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }