import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VList,
    { staticClass: "notifications-list" },
    [
      !_vm.landingPageNotifications.length
        ? _c(
            "h2",
            { staticClass: "notifications-list__no-notifications-label" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("toSentenceCase")(_vm.$tkey("noNotifications"))
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(
        _vm.landingPageNotifications.slice(0, 20),
        function (notification) {
          return _c(
            VListItem,
            {
              key: notification._id,
              staticClass: "notification",
              on: {
                click: function ($event) {
                  return _vm.handleNotificationClick(notification)
                },
              },
            },
            [
              _c(
                VListItemContent,
                [
                  _vm._l(notification.entityDisplayConfig, function (row) {
                    return _c(
                      VListItemSubtitle,
                      { key: row.entity, staticClass: "notification__title" },
                      [
                        _c("span", [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm._f("suffixWithColon")(
                                  _vm._f("toSentenceCase")(
                                    _vm.$tc(`${row.prefix}`)
                                  )
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _c("truncate-text-tooltip", {
                                attrs: {
                                  text: _vm.getDetails({
                                    notification,
                                    rowDetails: row,
                                  }),
                                  length:
                                    (_vm.sidebar ? 33 : 51) - row.prefix.length,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    VListItemSubtitle,
                    { staticClass: "notification__content" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              `landingPageNotifications.${notification.messageKey}`
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VListItemSubtitle,
                    { staticClass: "notification__content" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("dateTime")(notification.lastUpdatedOn)
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                VListItemIcon,
                { staticClass: "notification__icon" },
                [
                  _c(VIcon, { attrs: { size: "33", color: "primary" } }, [
                    _vm._v("\n                navigate_next\n            "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }