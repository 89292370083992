<template>
    <div class="vuex-number-input" :class="cssClass">
        <v-text-field
            ref="textField"
            v-model="formattedNumber"
            :disabled="isDisabled"
            reverse
            :class="`rtls-text-field rtls-text-field--${theme}`"
            :placeholder="placeholder"
            :hide-details="hideDetails"
            :rules="rules"
            :height="height"
            validate-on-blur
            @keypress="
                params =>
                    positiveOnly
                        ? validatePositiveIntegerInput(params)
                        : validateIntegerInput(params)
            "
            @paste="validateNumberOnPaste"
            @change="updateModel"
        />
    </div>
</template>

<script>
import { isNil } from 'lodash';
import numeral from 'numeral';
import vuexComponentMixin from '../../mixins/vuex-component';
import validateNumberInputMixin from '../../mixins/validate-number-input';

const defaultFormat = 'numbers.default.number';

export default {
    mixins: [vuexComponentMixin, validateNumberInputMixin],
    props: {
        disabled: Boolean,
        change: {
            type: Function,
            default: () => {},
        },
        theme: {
            type: String,
            default: 'grey',
        },
        format: {
            type: String,
            default: defaultFormat,
        },
        placeholder: {
            type: String,
            default: '—',
        },
        saveAsString: {
            type: Boolean,
            default: () => false,
        },
        positiveOnly: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        cssClass: String,
        height: String,
    },

    data() {
        return {
            numberInput: null,
        };
    },

    computed: {
        formattedNumber: {
            get() {
                const value = !this.saveAsString ? this.$n(this.format, this.model) : this.model;
                return isNil(this.model) ? null : value;
            },
            set(newValue) {
                // Format the inputted number. This applies any rounding required for the
                // number formatting. E.g. 10.6666 with format '0.0' will convert to 10.7
                const formattedInput = this.$n(this.format, newValue);

                this.numberInput = this.saveAsString
                    ? formattedInput
                    : numeral(formattedInput).value();
            },
        },

        isDisabled() {
            return this.disabled || this.isReadOnly;
        },
    },

    created() {
        this.numberInput = this.model;
    },

    methods: {
        updateModel() {
            this.model = this.numberInput;
            this.$emit('change', this.numberInput);
        },
    },
};
</script>
