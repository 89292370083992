<template>
    <div class="reward-requirement-option">
        <div class="reward-requirement-option__label">{{ prefixLabel }}</div>

        <vuex-number-input
            v-if="rewardRequirementConfig.showNumberInput"
            class="reward-requirement-option__number-input"
            theme="white"
            height="21"
            :format="`numbers.default.${rewardRequirementConfig.format}`"
            :getter="() => rewardRequirement[rewardRequirementConfig.attributeToMapToNumberInput]"
            :setter="value => updateValue(value)"
            :validations="rewardRequirementConfig.validations"
            :disabled="!isRewardRequirementEditable"
            :hide-details="true"
            :data-id-e2e="'reward-requirement-number-input'"
        />

        <vuex-select
            v-if="rewardRequirementConfig.showDropDown"
            class="reward-requirement-option__additional-reward"
            :getter="() => rewardRequirement.identifier"
            :setter="value => updateAdditionalReward(value)"
            :options="additionalRewardDropDownOptions"
            :disabled="!isRewardRequirementEditable"
            :placeholder="
                $tkey(`placeholders.${rewardRequirementConfig.dropDownPlaceholder}`)
                    | toSentenceCase
            "
            :data-id-e2e="'reward-requirement-additional-reward'"
            item-text="text"
            item-value="value"
        />

        <div class="reward-requirement-option__label">{{ suffixLabel }}</div>

        <v-menu v-if="isRewardRequirementTypeEditable" bottom offset-y :disabled="isReadOnly">
            <template v-slot:activator="{ on, attrs }">
                <div class="icon-container">
                    <v-icon small v-bind="attrs" :class="{ disabled: isReadOnly }" v-on="on">
                        mdi-dots-vertical
                    </v-icon>
                </div>
            </template>
            <v-list>
                <v-list-item
                    v-for="option in changeOptionToDropDownOptions"
                    :key="option"
                    @click="updateRewardRequirement(option)"
                >
                    {{
                        $tkey(`rewardRequirementOptions.${option}`, { currencySymbol })
                            | toSentenceCase
                    }}
                </v-list-item>

                <v-divider />

                <v-list-item
                    class="reward-requirement-option__remove-option-item"
                    @click="removeRewardRequirement"
                >
                    <icon icon-name="cancel" />
                    <div class="reward-requirement-option__remove-option-label">
                        {{ $tkey('labels.remove') | toSentenceCase }}
                    </div>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import optionsConfig from './reward-requirement-options-config';
import { toSentenceCase } from '@/js/utils/string-utils';
import i18n from '@/js/vue-i18n';

export default {
    localizationKey: 'planning.promotionsMaintenance.offer.offerMechanic',

    props: {
        rewardRequirement: {
            type: Object,
            required: true,
        },
        tierIndex: {
            type: Number,
            required: true,
        },
        conditionIndex: {
            type: Number,
            required: true,
        },
        changeOptionToDropDownOptions: {
            type: Array,
            required: true,
        },
        additionalRewardDropDownOptions: {
            type: Array,
            required: false,
        },
        shouldPrefixWithAnd: {
            type: Boolean,
            required: true,
        },
        isRewardRequirementEditable: {
            type: Boolean,
            required: true,
        },
        isRewardRequirementTypeEditable: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        ...mapGetters('clientConfig', ['currencySymbol']),

        rewardRequirementOptionsConfig() {
            return optionsConfig(i18n, this.currencySymbol);
        },

        rewardRequirementConfig() {
            return this.rewardRequirementOptionsConfig[this.rewardRequirement.type] || {};
        },

        suffixLabel() {
            return this.rewardRequirementConfig.suffixLabel
                ? this.$tkey(`labels.${this.rewardRequirementConfig.suffixLabel}`, {
                      currencySymbol: this.currencySymbol,
                  })
                : '';
        },

        prefixLabel() {
            const translatedPrefix = this.rewardRequirementConfig.prefixLabel
                ? this.$tkey(`labels.${this.rewardRequirementConfig.prefixLabel}`, {
                      currencySymbol: this.currencySymbol,
                  })
                : '';

            return this.shouldPrefixWithAnd
                ? `${this.$tkey('labels.and')} ${translatedPrefix}`
                : toSentenceCase(translatedPrefix);
        },
    },

    methods: {
        removeRewardRequirement() {
            this.$emit('remove', {
                rewardRequirement: this.rewardRequirement,
                tierIndex: this.tierIndex,
                conditionIndex: this.conditionIndex,
            });
        },

        updateRewardRequirement(rewardRequirement) {
            if (rewardRequirement !== this.rewardRequirement.type) {
                this.$emit('update', {
                    newValue: rewardRequirement,
                    tierIndex: this.tierIndex,
                    conditionIndex: this.conditionIndex,
                    colIndex: this.rewardRequirement.colOrder,
                });
            }
        },

        updateAdditionalReward(value) {
            if (value !== this.rewardRequirement.identifier) {
                this.$emit('updateAdditionalReward', {
                    newValue: value,
                    tierIndex: this.tierIndex,
                    conditionIndex: this.conditionIndex,
                    colIndex: this.rewardRequirement.colOrder,
                });
            }
        },

        updateValue(value) {
            this.rewardRequirement[
                this.rewardRequirementConfig.attributeToMapToNumberInput
            ] = value;
            this.$nextTick(() => {
                this.$emit('save');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.reward-requirement-option {
    @include flex-row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    &__label {
        color: $promo-text-colour;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.4rem;
        white-space: nowrap;
    }

    &__number-input {
        margin-left: 0.6rem;
        width: 4rem;
        margin-right: 0.3rem;
    }

    &__remove-option-item {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    &__remove-option-label {
        margin-left: 0.5rem;
    }

    &__additional-reward {
        margin-left: 0.6rem;

        ::v-deep {
            .v-input__slot,
            .v-select__slot {
                height: 2.1rem !important;
            }
        }
    }

    .icon-container {
        min-width: 2rem;
        .v-icon {
            display: none;
            color: $promo-primary-colour;
        }
        .disabled {
            opacity: 0.5;
        }
    }

    &:hover {
        background-color: $promo-offer-mechanic-hover-colour;

        .icon-container {
            .v-icon {
                display: block;
            }
        }
    }
}

.v-list-item {
    font-size: 1.2rem;
    min-height: 2rem;
    color: $promo-text-colour;
    letter-spacing: 0;
    line-height: 1.4rem;
    padding-left: 0.8rem;
}
</style>
