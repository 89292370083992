<template>
    <div class="user-permissions-grid-container">
        <promo-ag-grid
            :row-data="rowData"
            :column-defs="columnDefs"
            :default-col-def="defaultColDef"
            :grid-options="gridOptions"
            grid-style="width: 100%; height: 100%;"
            dom-layout="normal"
        />
        <checkbox-list-dialog ref="hierarchy-dialog" :user="selectedUser" />
        <store-group-attributes-dialog ref="store-group-dialog" :user="selectedUser" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, has, isNil, uniq, isArray } from 'lodash';
import { admin } from '@enums/user-roles';
import {
    restrictAccessByStoreGroups,
    restrictAccessByCategories,
    userProfilesDeletePermission,
} from '@enums/feature-flags';
import { userProfiles } from '@enums/resources';
import { toSentenceCase } from '@/js/utils/string-utils';
import promoAgIconHeader from '@/js/components/promo-ag-grid/ag-icon-header';
import editIconRenderer from '../../../parking-lot/components/edit-icon-renderer';
import deleteIconRenderer from '../../../parking-lot/components/delete-icon-renderer';
import checkboxRenderer from './components/checkbox-renderer';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
    data() {
        return {
            defaultColDef: {
                editable: false, // read only for now, make-read-only on component too
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                flex: 1,
                resizable: true,
                filter: true,
                minWidth: 120,
                headerComponentParams: {},
                menuTabs: [],
                columnDefs: null,
                rowData: null,
                useValueFormatterForExport: true,
            },
            gridOptions: {
                rowHeight: 35, // Specified in pixels.
                frameworkComponents: {
                    promoAgIconHeader,
                    editIconRenderer,
                    checkboxRenderer,
                    deleteIconRenderer,
                },
                columnTypes: {
                    numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
                },
                ensureDomOrder: true,
                enableRangeSelection: true,
                processCellForClipboard: null,
            },
            selectedUser: null,
            columnDefs: null,
        };
    },

    computed: {
        ...mapState('userProfiles', ['userProfiles']),
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapGetters('hierarchy', ['getHierarchyByKey']),

        users() {
            // all users apart from the ADMIN
            return this.userProfiles.filter(user => !user.roles.includes(admin));
        },

        rowData() {
            return this.users.map(u => {
                u.isAdmin = get(u, 'roles', []).some(role =>
                    role.toLowerCase().includes(admin.toLowerCase())
                );
                const attributes = uniq(
                    get(u, 'accessRestrictions.storeGroupAttributeRestrictions', []).reduce(
                        (acc, row) => {
                            return [
                                ...acc,
                                ...row.reduce((innerAcc, attr) => {
                                    if (attr.values && attr.values.length) {
                                        return [...innerAcc, ...attr.values];
                                    }
                                    return innerAcc;
                                }, []),
                            ];
                        },
                        []
                    )
                );
                if (attributes.length) {
                    u.attributes = attributes;
                } else {
                    u.attributes = null;
                }

                u.rolesString = get(u, 'roles', []).join(', ');
                if (!has(u, 'access')) {
                    u.access = { categories: [], storeGroups: [] };
                }
                if (!has(u, 'accessRestrictions')) {
                    u.accessRestrictions = { storeGroupAttributeRestrictions: [] };
                }
                return u;
            });
        },
    },

    created() {
        this.columnDefs = [
            {
                headerName: toSentenceCase(this.$t('userPermissions.headings.username')),
                field: 'username',
            },
            {
                headerName: toSentenceCase(this.$t('userPermissions.headings.role')),
                field: 'roles',
                minWidth: 300,
            },
            // Access
            ...(this.toggleLogic[restrictAccessByCategories]
                ? [
                      {
                          minWidth: 50,
                          sortable: false,
                          field: 'access',
                          headerName: toSentenceCase(
                              this.$t('userPermissions.headings.hierarchyAccess')
                          ),
                          valueGetter: ({ data }) => {
                              const cat = (data.access || {}).categories;
                              if (cat && cat.length) {
                                  return cat;
                              }

                              return null;
                          },
                          filterParams: {
                              valueFormatter: ({ value }) => {
                                  if (isNil(value)) {
                                      return value;
                                  }
                                  const hierarchy = this.getHierarchyByKey(value);

                                  return isNil(hierarchy) ? null : hierarchy.levelEntryDescription;
                              },
                          },
                          cellRenderer: 'editIconRenderer',
                          cellRendererParams: {
                              onClick: params => {
                                  this.openHierarchyDialog(params);
                              },
                          },
                      },
                  ]
                : []),
            ...(this.toggleLogic[restrictAccessByStoreGroups]
                ? [
                      {
                          minWidth: 50,
                          sortable: false,
                          headerName: toSentenceCase(
                              this.$t('userPermissions.headings.storeGroupAccess')
                          ),
                          field: 'attributes',
                          cellRenderer: 'editIconRenderer',
                          cellRendererParams: {
                              onClick: params => {
                                  this.openStoreGroupDialog(params);
                              },
                          },
                      },
                  ]
                : []),
            // isAdmin
            {
                headerName: toSentenceCase(this.$t('userPermissions.headings.admin')),
                field: 'isAdmin',
                cellClass: ['cell-padding'],
                filterParams: {
                    valueFormatter: ({ value }) => {
                        return value === 'true'
                            ? this.$t('userPermissions.yes')
                            : this.$t('userPermissions.no');
                    },
                },
                cellRenderer: 'checkboxRenderer',
            },
            ...(this.toggleLogic[userProfilesDeletePermission]
                ? [
                      {
                          minWidth: 50,
                          sortable: false,
                          headerName: toSentenceCase(this.$t('actions.remove')),
                          cellRenderer: 'deleteIconRenderer',
                          cellRendererParams: {
                              resource: userProfiles,
                              resourceId: '_id',
                          },
                      },
                  ]
                : []),
        ];
        this.gridOptions.processCellForClipboard = this.processCellForClipboardFunction;
    },

    methods: {
        processCellForClipboardFunction(params) {
            if (params.column.getColId() === 'access') {
                const { value } = params;
                if (isNil(value) || !isArray(value)) {
                    return value;
                }
                const hierarchyList = value.map(valueItem => this.getHierarchyByKey(valueItem));
                return (hierarchyList || []).map(hierarchy =>
                    isNil(hierarchy) ? '' : hierarchy.levelEntryDescription
                );
            }
            return params.value;
        },
        openHierarchyDialog(user = {}) {
            this.selectedUser = user;
            this.$nextTick(() => {
                this.$refs['hierarchy-dialog'].openDialog();
            });
        },
        openStoreGroupDialog(user = {}) {
            this.selectedUser = user;
            this.$nextTick(() => {
                this.$refs['store-group-dialog'].openDialog();
            });
        },
    },
};
</script>

<style scoped lang="scss">
.user-permissions-grid-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    ::v-deep {
        .cell-padding {
            .ag-cell-value {
                padding-left: 0.3rem;
            }
        }
    }
}
</style>
