<template>
    <v-app>
        <link
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap"
            rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />
        <link
            href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css"
            rel="stylesheet"
        />

        <main-navbar v-if="!hideNavbar" />
        <main-sidebar
            v-if="!hideNavbar && !hideSidebar"
            :calendar-options="sidebarCalendarOptions"
        />

        <v-content :style="contentStyle">
            <div id="app-container">
                <transition name="fade" mode="out-in">
                    <router-view class="main-page" />
                </transition>
            </div>
        </v-content>

        <v-overlay :value="isNavigating" :z-index="1000">
            <spinner-dynamic />
        </v-overlay>

        <notifications :popup-timeout="popupTimeout" />

        <events-handler />

        <main-footer />

        <div id="consent_blackbar" />
    </v-app>
</template>

<script>
import { find, get } from 'lodash';
import { mapState } from 'vuex';
import { reportingOnly } from '@enums/feature-flags';

export default {
    data() {
        return {
            snackbars: {},
        };
    },

    computed: {
        ...mapState('clientConfig', ['generalConfig', 'toggleLogic']),
        ...mapState(['isNavigating']),

        popupTimeout() {
            return this.generalConfig.defaultNotificationTimeout;
        },
        topLevelRoute() {
            const topLevelNavPath = get(this, '$route.matched.0.path');
            return find(this.$router.options.routes, { path: topLevelNavPath }) || {};
        },

        contentStyle() {
            // 3rem here pertains to $main-sidebar-width in _variables.scss
            return !this.hideNavbar && !this.hideSidebar ? 'padding-left: 3rem !important' : '';
        },

        hideNavbar() {
            if (!this.topLevelRoute.meta) return false;
            return this.topLevelRoute.meta.hideNavbar;
        },
        sidebarCalendarOptions() {
            return {
                path: this.$router.currentRoute.fullPath.split('/')[1],
                showSidebarCalendar: get(
                    this.topLevelRoute,
                    'meta.isSideBarCalendarExpanded',
                    false
                ),
            };
        },

        hideSidebar() {
            return this.toggleLogic[reportingOnly];
        },
    },

    mounted() {
        if (get(this.generalConfig, 'showCookieBanner', false)) {
            const cookieScript = document.createElement('script');
            cookieScript.setAttribute(
                'src',
                '//cmp.osano.com/16CFuWSuPfE35eNP/9e220dc6-b4e9-4b7d-8de4-9aa0e81a60b0/osano.js'
            );
            document.body.appendChild(cookieScript);
        }
    },
};
</script>

<style lang="scss">
@import '@style/base/_variables';
html {
    position: relative;
    min-height: 100%;
}

#app-container {
    height: 100%;
}
</style>
