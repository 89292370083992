import routeNames from '@enums/route-names';
import tabNames from '@enums/tab-names';
import { enablePromotionPermalink } from '@enums/feature-flags';
import { includes } from 'lodash';
import store from '@/js/store';

const guardsMap = tabName => {
    return {
        async [routeNames[`${tabName}AllCampaignsView`]](to, from, next) {
            const selectedCampaign = store.getters['campaigns/selectedCampaign'];
            const selectedScenario = store.getters['scenarios/selectedScenario'];
            const selectedSubCampaign = store.getters['subCampaigns/selectedSubCampaign'];
            const selectedPromotion = store.getters['promotions/selectedPromotion'];

            if (selectedCampaign) {
                if (selectedSubCampaign) {
                    if (
                        selectedPromotion &&
                        tabName === tabNames.planning &&
                        !includes(from.fullPath, tabNames.preparation) &&
                        store.state.clientConfig.toggleLogic[enablePromotionPermalink]
                    ) {
                        // If there is a selected promotion set in planning tab, redirect to it.
                        // This will be the case if switching between top level navigation tabs (except preparation)
                        // to planning tab with a selected promotion.
                        next(
                            `/${tabName}/singleSubCampaignView/${
                                selectedSubCampaign._id
                            }/scenario/${selectedScenario._id}/promotion/${selectedPromotion._id}`
                        );
                    } else {
                        // If there is just a selected sub-campaign set, redirect to it.
                        // This will be the case if switching between the preparation and planning/preparation pages
                        // with a selected sub-campaign.
                        await Promise.all([
                            store.dispatch('scenarios/clearSelectedScenario'),
                            store.dispatch('promotions/clearSelectedPromotion'),
                        ]);
                        next(`/${tabName}/singleSubCampaignView/${selectedSubCampaign._id}`);
                    }
                } else {
                    // If sub-campaign is not selected, redirect to selected campaign.
                    next(`/${tabName}/singleCampaignView/${selectedCampaign._id}`);
                }
            } else {
                store.dispatch('campaigns/clearSelectedCampaign');
                store.dispatch('subCampaigns/clearSelectedSubCampaignId');

                next();
            }

            // because of redirect logic above there is a chance afterEach navigation guard will not be called
            // reset navigation animation in here
            store.commit('setIsNavigating', false);
        },
        async [routeNames[`${tabName}SingleCampaignView`]](to, from, next) {
            const { campaignId } = to.params;

            const campaign = store.getters['campaigns/getCampaignById']({
                _id: campaignId,
                usePluralResourceName: true,
            });

            if (!campaign) {
                // If the campaign cannot be found, then either it does not exist or the user does not have access
                // to it, so redirect to the 404 page.
                next(`/error/404`);
            } else {
                store.dispatch('selectedDates/setSelectedYearWeekFromEntity', campaign);
                store.dispatch('campaigns/setSelectedCampaignId', campaignId);
                store.dispatch('subCampaigns/clearSelectedSubCampaignId');
                store.dispatch('scenarios/clearSelectedScenario');

                next();
            }
        },
        async [routeNames[`${tabName}SingleSubCampaignView`]](to, from, next) {
            const { subCampaignId } = to.params;

            const subCampaign = store.getters['subCampaigns/getSubCampaignById']({
                _id: subCampaignId,
                usePluralResourceName: true,
            });

            if (!subCampaign) {
                // If the sub-campaign cannot be found, then either it does not exist or the user does not have access
                // to it, so redirect to the 404 page.
                next(`/error/404`);
            } else {
                if (store.state.subCampaigns.selectedSubCampaignId !== subCampaignId) {
                    // If changing sub-campaigns, first clear out any promotions relating to the previous sub-campaign
                    await Promise.all([
                        store.dispatch('promotions/resetPromotions'),
                        store.dispatch('promotions/resetPromotionsInView'),
                    ]);
                    // It's important that setSelectedSubCampaign runs to completion before dispatching
                    // the other actions because this ensures all attributes of the selected sub-campaign are
                    // available. Without all of the attributes, some computeds may error when recalculating.
                    await store.dispatch('subCampaigns/setSelectedSubCampaign', subCampaign);

                    await Promise.all([
                        store.dispatch('selectedDates/setSelectedYearWeekFromEntity', subCampaign),
                        store.dispatch('campaigns/setSelectedCampaignId', subCampaign.campaignId),
                        store.dispatch('scenarios/clearSelectedScenario'),
                    ]);
                }

                next();
            }
        },
    };
};

export default guardsMap;
