'use strict';

module.exports = {
    radio: 'radio',
    checkbox: 'checkbox',
    text: 'text',
    percentage: 'percentage',
    plainPercentage: 'plainPercentage',
    currency: 'currency',
};
