<template>
    <v-container class="rtls-calendar-picker">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            nudge-bottom="25"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    class="rtls-text-field rtls-text-field--grey rtls-text-field--reverse-order"
                    :value="model | localShortDate"
                    :placeholder="$t(placeholder) | toSentenceCase"
                    hide-details
                    readonly
                    :disabled="disabled"
                    v-on="on"
                >
                    <template slot="append">
                        <v-icon small @click="menu = true">$date-picker</v-icon>
                    </template>
                </v-text-field>
            </template>
            <date-picker :dates="model" :min="min" :max="max" @complete="closeWhenComplete" />
        </v-menu>
    </v-container>
</template>

<script>
import vuexComponentMixin from '../../mixins/vuex-component';
import vuexDateMixin from '../../mixins/vuex-date-bounds';

export default {
    mixins: [vuexComponentMixin, vuexDateMixin],

    props: ['minDate', 'maxDate', 'disabled'],

    data() {
        return {
            menu: false,
        };
    },

    methods: {
        closeWhenComplete(date) {
            this.model = date;
            this.menu = false;
        },
    },
};
</script>
