var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "15px",
        height: "15px",
        viewBox: "0 0 15 15",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Spinner")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "api-v1-2op",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "API-call-–-06",
                transform: "translate(-52.000000, -151.000000)",
                fill: "#4A4A4A",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "Combined-Shape",
                  d: "M63.908865,164.568095 C61.1602974,166.565046 57.5107789,166.424313 54.9439015,164.460064 L56.7872253,163.121253 C58.5680409,164.165697 60.8723037,164.125582 62.6493311,162.834496 C64.426595,161.543238 65.1767664,159.363742 64.7333671,157.34711 L66.5764122,156.008425 C67.6513718,159.05668 66.6574626,162.571122 63.908865,164.568095 Z M64.0570915,152.540929 L62.2137677,153.87974 C60.4329521,152.835296 58.1286893,152.875411 56.3516619,154.166497 C54.574398,155.457755 53.8242266,157.637251 54.2676259,159.653883 L52.4245808,160.992568 C51.3496212,157.944313 52.3435304,154.429871 55.092128,152.432898 C57.8406956,150.435947 61.4902141,150.57668 64.0570915,152.540929 Z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }