var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("icon-button", {
    class: ["delete-btn"],
    attrs: {
      "data-id-e2e": _vm.dataIdE2e,
      disabled: _vm.isDisabled,
      events: _vm.events,
      icon: "trash",
    },
    on: {
      click: function ($event) {
        $event.stopPropagation()
        return _vm.onClick.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }