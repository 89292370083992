<template>
    <div v-if="isExpanded" class="summary-header lump-funding__header">
        <div class="summary-header__title">
            <label class="summary-header__label">
                {{ params.displayName }}
            </label>
        </div>
        <div class="summary-header__info">
            <div class="line1">
                <div class="total">
                    {{ formattedValue(totalAllocatedFunding) }}
                </div>
                <div class="indication-icons">
                    <icon v-if="!allAssignedMatchExpected" icon-name="failure-circle" small />
                    <icon v-else-if="allRateCardsAssigned" icon-name="success-circle" small />
                    <icon v-else icon-name="empty-success-circle" small />
                </div>
            </div>
            <div class="line2">
                <advertisement-fees
                    :namespace="params.promotionId"
                    :assigned-rate-cards="assignedRateCards"
                    field-name="rateCards"
                    vuex-module="promotions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { size, isNumber, get } from 'lodash';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
    localizationKey: 'planning.promotionsMaintenance.funding',
    data() {
        return {
            isExpanded: false,
        };
    },
    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        ...mapState('rateCards', ['selectedRateCardIdForSplitting']),
        selectedPromotion() {
            return this.getStagingAreaPromotionById(this.params.promotionId);
        },

        totalAllocatedFunding() {
            const { products } = this.selectedPromotion;
            return products.reduce((fundingSum, product) => {
                fundingSum += get(product, 'funding.lumpFundingTotal', 0);
                return fundingSum;
            }, 0);
        },
        totalRateCards() {
            return this.selectedPromotion.rateCards.length;
        },
        allRateCardsAssigned() {
            if (!this.totalRateCards) return false;
            const noOfAssignedRateCards = size(this.assignedRateCards.filter(rc => rc.isAssigned));
            return this.totalRateCards === noOfAssignedRateCards;
        },

        allAssignedMatchExpected() {
            return this.assignedRateCards.every(rateCard => {
                return rateCard.isAssigned === rateCard.matchesExpected;
            });
        },
        // return list of rate cards that are already assigned to the product
        assignedRateCards() {
            const { products } = this.selectedPromotion;
            const productRateCards = products.flatMap(product =>
                get(product, 'funding.lumpFunding', [])
            );

            const rateCardTotals = productRateCards.reduce((result, value) => {
                result[value.rateCardId] = (result[value.rateCardId] || 0) + value.rateCardAmount;
                return result;
            }, {});

            return this.selectedPromotion.rateCards.map(rateCard => {
                return {
                    rateCardId: rateCard._id,
                    isAssigned: isNumber(rateCardTotals[rateCard._id]),
                    matchesExpected: rateCardTotals[rateCard._id] === rateCard.amount,
                };
            });
        },
    },
    events: {
        onLumpFundingCollapsed(state) {
            if (state === this.isExpanded) return;
            this.toggleCollapse();
        },
    },
    methods: {
        formattedValue(value) {
            return this.$options.filters.totalMargin(value);
        },
        toggleCollapse() {
            this.isExpanded = !this.params.columnGroup.isExpanded();
            this.params.setExpanded(this.isExpanded);
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.summary-header {
    flex-grow: 1;
    height: 100%;
    display: flex;
    padding-left: 0.5rem;

    flex-direction: column;
    border-left: $promo-table-border;

    &__title {
        display: flex;
        justify-content: space-between;

        padding: 0;
        margin-left: 0.5rem;
    }

    &__label {
        white-space: normal;
        min-height: 3.4rem;
    }

    &__info {
        display: flex;
        flex-grow: 1 !important;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        margin-left: 0.5rem;
    }

    .line1,
    .line2 {
        display: flex;
        width: 100%;
    }
    .line1 {
        height: 2.7rem;
        background-color: $promo-table-blue-bg-colour-3;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .total {
        display: flex;
        width: 100%;
        flex-grow: 1;
        justify-content: space-between;
    }

    .expand-btn {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5rem;
    }
}
</style>
