import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VAlert, { staticClass: "alert-banner" }, [
    _c(
      "span",
      { staticClass: "alert-banner__lock-icon" },
      [_c("icon", { attrs: { "icon-name": "locked" } })],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "alert-banner__locked" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("toSentenceCase")(_vm.$t("general.alerts.locked"))) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "alert-banner__locked-msg" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm._f("toSentenceCase")(_vm.$t(_vm.reason))) +
          "\n    "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }