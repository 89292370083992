var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main-dialog", {
    ref: "dialog",
    attrs: {
      heading: "planning.copyCampaign.heading",
      "btn-confirm-text": "actions.copy",
      "has-activator": _vm.hasActivator,
    },
    on: { confirm: _vm.copyCampaignDialogConfirm, close: _vm.closeDialog },
    scopedSlots: _vm._u(
      [
        _vm.hasActivator
          ? {
              key: "actions",
              fn: function ({ activator }) {
                return [
                  _c(
                    "icon",
                    _vm._g(
                      {
                        attrs: { "icon-name": "duplicate", small: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      activator
                    )
                  ),
                ]
              },
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _c("div", { staticClass: "copy-campaign" }, [
                _c("p", { staticClass: "copy-campaign__description" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("toSentenceCase")(
                          _vm.$t("planning.copyCampaign.description")
                        )
                      ) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "copy-campaign__form-item border" },
                  [
                    _c(
                      "span",
                      { staticClass: "copy-campaign__form-item--label" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$tc("entities.campaigns", 1)
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("vuex-checkbox", {
                      attrs: {
                        disabled: "",
                        getter: () =>
                          _vm.modelsGetter({
                            model: _vm.copyCampaignModels.campaignModel,
                          }),
                        setter: (value) =>
                          _vm.modelsSetter({
                            model: _vm.copyCampaignModels.campaignModel,
                            value,
                          }),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "copy-campaign__form-item" },
                  [
                    _c(
                      "span",
                      { staticClass: "copy-campaign__form-item--label" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(
                                _vm.$t("planning.copyCampaign.startDateField")
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("vuex-date-picker", {
                      staticClass: "copy-campaign__form-item--picker",
                      attrs: {
                        getter: () =>
                          _vm.modelsGetter({
                            model: _vm.copyCampaignModels.startDateModel,
                          }),
                        setter: (value) =>
                          _vm.modelsSetter({
                            model: _vm.copyCampaignModels.startDateModel,
                            value,
                          }),
                        "min-date": _vm.minDate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }