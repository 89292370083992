import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuex-percentage-input", class: _vm.cssClass },
    [
      _c(VTextField, {
        staticClass: "rtls-text-field rtls-text-field--white",
        attrs: {
          disabled: _vm.isDisabled,
          reverse: "",
          rules: _vm.rules,
          "validate-on-blur": "",
          type: "text",
          placeholder: _vm.placeholderValue,
        },
        on: {
          keypress: _vm.validateNumber,
          paste: _vm.validateNumberOnPaste,
          change: _vm.updateModel,
        },
        model: {
          value: _vm.percentage,
          callback: function ($$v) {
            _vm.percentage = $$v
          },
          expression: "percentage",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vuex-percentage-input__percentage-symbol",
          class: _vm.isDisabled
            ? "vuex-percentage-input__percentage-symbol--disabled"
            : "",
        },
        [_vm._v("\n        %\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }