var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rag-color-and-icon" }, [
    _c(
      "div",
      { staticClass: "rag-color" },
      [
        _c("promotion-rag-colour", {
          attrs: {
            vertical: true,
            colour: _vm.colour,
            "make-read-only": _vm.makeReadOnly,
            "read-only-reason": _vm.readOnlyReason,
            "display-count": false,
            "big-circle": _vm.bigCircle,
          },
        }),
        _vm._v(" "),
        _vm.showParentChildIcon
          ? _c("icon", { attrs: { "icon-name": "parent-child-icon-red" } })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "icon" },
      [
        _c("promotion-unlock-dialog", {
          staticClass: "unlock-dialog-wrapper",
          attrs: {
            "icon-name": _vm.iconName,
            "promotion-id": _vm.promotionId,
            "sub-campaign": _vm.subCampaign,
          },
        }),
        _vm._v(" "),
        _vm.execution.executionId ? _c("spinner-dynamic") : _vm._e(),
        _vm._v(" "),
        _vm.hasErrors
          ? _c("execution-errors-dialog", {
              attrs: {
                "execution-errors": _vm.executionErrors,
                "forecasting-errors": _vm.forecastingErrors,
                "in-tool-validations": _vm.inToolValidations,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }