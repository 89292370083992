import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "variable-funding-agreements-viewer" }, [
    _c(
      "div",
      { staticClass: "variable-funding-agreements-viewer__filter" },
      [
        _c("variable-funding-filter-bar", {
          on: {
            "change-categories-filter": function ($event) {
              _vm.isNewVariableFunding = false
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "variable-funding-agreements-viewer__grid" },
      [
        _vm._l(_vm.fields, function (field, index) {
          return [
            field.header && _vm.allowColumnForRelease(field)
              ? _c(
                  "div",
                  {
                    key: `variableFundingHeader::${index}`,
                    class: [
                      "variable-funding-agreements-viewer__header",
                      field.headerClasses,
                    ],
                  },
                  [
                    field.header
                      ? _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("toSentenceCase")(
                                  _vm.$t(
                                    `variableFundingAgreements.headers.${field.header}`
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        }),
        _vm._v(" "),
        _c("div", { staticClass: "filler" }),
        _vm._v(" "),
        _vm._l(
          _vm.getFilteredVariableFundingAgreements,
          function (variableFunding, index) {
            return [
              _c("variable-funding-viewer", {
                key: variableFunding._id,
                attrs: {
                  "variable-funding": variableFunding,
                  fields: _vm.fields,
                  index: index,
                },
              }),
              _vm._v(" "),
              _c("div", {
                key: `${variableFunding._id}_filler`,
                staticClass: "filler",
              }),
            ]
          }
        ),
        _vm._v(" "),
        _vm.isNewVariableFunding
          ? _c("variable-funding-viewer", {
              attrs: { fields: [] },
              on: {
                close: function ($event) {
                  _vm.isNewVariableFunding = false
                },
              },
            })
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.isGridFillerVisible
      ? _c(
          "div",
          { class: ["variable-funding-agreements-viewer__header", "bold"] },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("variableFundingAgreements.filterNotSelected")
                  )
                ) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "variable-funding-agreements-viewer__new-funding" },
      [
        _c(
          "div",
          {
            staticClass:
              "variable-funding-agreements-viewer__new-single-funding",
          },
          [
            _c("create-new-button", {
              attrs: {
                "btn-text": _vm._f("toSentenceCase")(
                  _vm.$tkey("variableFundingAgreements.newFundingButton")
                ),
                disabled: _vm.isCreateButtonDisabled,
              },
              on: { createNew: _vm.addNewVariableFunding },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(VDivider, { attrs: { vertical: "" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "variable-funding-agreements-viewer__new-funding-bulk-upload",
          },
          [
            _c("file-upload", {
              attrs: {
                "upload-id": "bulk-upload-variable-funding-agreements",
                "allowed-extensions": [".xlsx"],
                "vuex-module": "variableFundingAgreements",
                "vuex-action": "bulkUploadVariableFundingAgreements",
                "on-upload": (dispatch) =>
                  dispatch("refreshVariableFundingAgreements"),
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }