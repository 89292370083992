<template>
    <div class="message-title py-2">
        <div class="warning-text">{{ $t('planning.warnings.warning') | toSentenceCase }}!</div>
        <div>
            {{ $t('planning.warnings.deletingChildSubCampaignWarning') | toSentenceCase }}
        </div>
        <ul>
            <li v-for="(child, index) in children" :key="index">{{ child }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        children: Array,
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.warning-text {
    color: $negative-action;
}

.message-title {
    font-size: 1.4rem;
}
</style>
