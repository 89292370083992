<template>
    <ag-grid-vue
        class="ag-theme-custom"
        :style="gridStyle"
        :class="gridClass"
        :row-data="rowData"
        :column-defs="columnDefs"
        :grid-options="gridOptionsExtended"
        :default-col-def="defaultColDefExtended"
        :edit-type="editType"
        :single-click-edit="singleClickEdit"
        :modules="modules"
        group-headers
        auto-params-refresh
        :dom-layout="domLayout"
        @grid-ready="onReady"
        @cell-value-changed="onCellValueChanged"
        @cell-key-down="onCellKeyDown"
        @row-data-updated="onRowDataUpdated"
        @model-updated="onModelUpdated"
    />
</template>

<script>
// There are two ways of importing and using the AgGrid library - packages and modules.
// Modules are recommended as they allow developers to opt-in to install and enable features of AgGrid explicitly,
// leading to a more optimised bundle size. For more info, see: https://www.ag-grid.com/documentation/javascript/modules/
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { AgGridVue } from '@ag-grid-community/vue';
import { merge, debounce } from 'lodash';

export default {
    name: 'PromoAgGrid',
    components: {
        'ag-grid-vue': AgGridVue,
    },
    props: {
        rowData: {
            required: true,
            type: Array,
            default: () => [],
        },
        columnDefs: {
            required: true,
            type: Array,
            default: () => [],
        },
        gridClass: {
            required: false,
            type: String,
            default: '',
        },
        gridStyle: {
            required: false,
            type: String,
            default: '',
        },
        gridOptions: {
            required: false,
            type: Object,
        },
        defaultColDef: {
            required: false,
            type: Object,
        },
        editType: {
            required: false,
            type: String,
        },
        domLayout: {
            required: false,
            type: String,
        },
        singleClickEdit: {
            type: Boolean,
            default: false,
        },
        resizeToFit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            gridApi: null,
            modules: [
                ClientSideRowModelModule,
                RichSelectModule,
                LicenseManager,
                RowGroupingModule,
                StatusBarModule,
                SetFilterModule,
                RangeSelectionModule,
                ClipboardModule,
            ],
        };
    },
    computed: {
        defaultColDefExtended() {
            return merge(this.defaultColDef, {
                suppressKeyboardEvent: params => {
                    // return true to suppress ag grid next cell focus behaviour if user hit end/home/left arrow/right arrow keys
                    const { keyCode } = params.event;

                    return keyCode === 35 || keyCode === 36 || keyCode === 37 || keyCode === 39;
                },
            });
        },
        gridOptionsExtended() {
            return merge(this.gridOptions, {
                // ag-grid suppressCellFocus: true grid option is not working,
                // need to override navigation methods to prevent focusing next cell when user presses arrow keys
                // in order for navigation to work inside inputs
                navigateToNextCell: () => {
                    return null;
                },
                // there is an input inside rateCards header, so need to override navigateToNextHeader method as well
                navigateToNextHeader: () => {
                    return null;
                },
            });
        },
    },
    methods: {
        onReady(params) {
            this.gridApi = params.api;
            if (this.resizeToFit) this.gridApi.sizeColumnsToFit();
            this.$emit('grid-ready', params);
        },
        onCellValueChanged(event) {
            this.$emit('cell-value-changed', event);
        },
        onCellKeyDown(event) {
            this.$emit('cell-key-down', event);
        },
        debouncedOnCellMouseOver: debounce(function($event) {
            this.onCellMouseOver($event);
        }, 100),
        onCellMouseOver(event) {
            this.$emit('cell-mouse-over', event);
        },
        onRowDataUpdated(event) {
            this.$emit('row-data-updated', event);
            if (this.resizeToFit) {
                this.$nextTick(() => {
                    this.gridApi.sizeColumnsToFit();
                });
            }
        },
        onModelUpdated(event) {
            this.$emit('model-updated', event);
        },
    },
};
</script>

<style scoped lang="scss"></style>
