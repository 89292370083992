<script>
import { mapGetters, mapState } from 'vuex';
import {
    explicitlyAllow,
    canReadPromotion,
    canEditScenario,
    enablePublicPrivateEntities,
} from '@enums/feature-flags';
import WorkflowEntities from '@enums/workflow-entities';
import tabNames from '@enums/tab-names';
import promoPlannerContainerItem from './promo-planner-container-item';
import navigation from '@/js/navigation';

const icons = [
    {
        field: 'showWorkflowState',
        featureToggle: canEditScenario,
        component: {
            name: 'workflow-state',
            featureToggle: explicitlyAllow,
            showPropName: 'showWorkflowDebugIcon',
            props: {
                workflowEntity: WorkflowEntities.subCampaign,
                small: true,
            },
            contextualProps: {
                entityId: '_id',
            },
        },
    },
    {
        field: 'showScenarioIcon',
        featureToggle: canReadPromotion,
        component: {
            name: 'icon-button',
            featureToggle: canReadPromotion,
            props: {
                dataIdE2e: 'showScenariosBtnId',
                icon: 'expand-sub-campaign',
            },
            events: {
                click: {
                    method: 'viewScenarios',
                    params: [],
                },
            },
        },
    },
];

export default {
    extends: promoPlannerContainerItem,
    props: ['campaign', 'subCampaign', 'parentStartDate', 'parentEndDate', 'isLocked'],
    data() {
        return { icons };
    },
    computed: {
        ...mapState('subCampaigns', ['subCampaigns', 'selectedSubCampaignId']),
        ...mapState('scenarios', ['scenarios']),
        ...mapState('clientConfig', ['toggleLogic', 'generalConfig']),
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
        ...mapGetters('campaigns', ['isCampaignSelected']),

        context() {
            return this.subCampaign;
        },

        selectedFadedClass() {
            // 1. check for selectedSubCampaign and
            // 2. if preparation tab (isReadOnly = true) =>
            // 2.1. check if current subCampaign id is the same selected one
            // 3. if planning tab (isReadOnly = false) =>
            // 3.1. check if current view is a single campaign view and user can edit scenario =>
            // 3.1. check for 2.1
            if (
                this.selectedSubCampaign &&
                (this.isReadOnly ||
                    (this.isCampaignSelected && !!this.toggleLogic[canEditScenario]))
            ) {
                if (this.selectedSubCampaign._id !== this.subCampaign._id) {
                    return 'sub-campaign--faded';
                }
                return 'sub-campaign--selected';
            }

            return '';
        },

        showWorkflowDebugIcon() {
            return this.isCampaignSelected && this.generalConfig.showWorkflowDebugIcon;
        },

        isPrivate() {
            return (
                this.toggleLogic[enablePublicPrivateEntities] &&
                this.subCampaign &&
                this.subCampaign.isPrivate
            );
        },

        cssClasses() {
            return ['sub-campaign', this.selectedFadedClass];
        },
    },
    methods: {
        onClick() {
            if (this.isReadOnly) {
                // show pagination
                navigation.toSubCampaignView({
                    tabName: tabNames.preparation,
                    subCampaignId: this.subCampaign._id,
                });
            } else {
                this.$emit('opened', { subCampaign: this.subCampaign });
            }
        },

        viewScenarios() {
            if (
                !this.selectedSubCampaignId ||
                this.selectedSubCampaignId !== this.subCampaign._id
            ) {
                navigation.toFavouriteScenario({ subCampaign: this.subCampaign });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sub-campaign {
    background-color: $promo-campaign-color-3;
    border-color: $promo-campaign-border-3;
    color: $promo-text-colour;
    &:hover {
        background-color: $promo-campaign-color-4;
        border-color: $promo-campaign-border-4;
        color: $promo-text-colour;
        cursor: pointer;

        .sub-campaign__scenario-btn::v-deep {
            svg path {
                fill: $rtls-primary-colour;
            }
        }
    }

    &--faded {
        background-color: $faded-promo-campaign-color-3;
        border-color: $faded-promo-campaign-border-3;
        color: $faded-promo-text-colour;
    }

    &#{&}--selected {
        .sub-campaign__scenario-btn::v-deep {
            svg path {
                fill: $rtls-primary-colour;
            }
        }
    }

    & &__scenario-btn::v-deep {
        svg path {
            fill: $promo-grey-7;
        }

        &:active {
            svg path {
                fill: $rtls-primary-button-hover-colour;
            }
        }
    }
}
</style>
