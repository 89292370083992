import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    _vm._b(
      {
        attrs: {
          "content-class": "notes-wrapper",
          "close-on-content-click": false,
          "offset-y": "",
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  VBtn,
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "notes-icon",
                        attrs: {
                          disabled: _vm.disabled,
                          icon: "",
                          color: "primary",
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _vm.hasNotes
                      ? _c("icon", {
                          attrs: {
                            "icon-name": "book-notification",
                            small: "",
                          },
                        })
                      : _c("icon", {
                          attrs: { "icon-name": "book", small: "" },
                        }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showNotes,
          callback: function ($$v) {
            _vm.showNotes = $$v
          },
          expression: "showNotes",
        },
      },
      "v-menu",
      _vm.positionProps,
      false
    ),
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notes" },
        [
          _c(
            "div",
            { staticClass: "notes__header" },
            [
              _c("h3", [
                _vm._v(_vm._s(_vm._f("toSentenceCase")(_vm.$t("notes.title")))),
              ]),
              _vm._v(" "),
              _c(
                VBtn,
                { attrs: { icon: "" }, on: { click: _vm.close } },
                [
                  _c("icon", {
                    attrs: {
                      "icon-name": "cancel",
                      small: "",
                      "icon-colour-type": "primary-path",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.showConfirmDelete
            ? _c(
                "div",
                [
                  _vm._l(_vm.notes, function (note) {
                    return _c(
                      "div",
                      { key: note._id, staticClass: "notes__container" },
                      [
                        _c(
                          VAvatar,
                          {
                            staticClass: "notes__author",
                            attrs: { color: "primary", size: "26" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "white--text headline" },
                              [_vm._v(_vm._s(_vm.getInitials(note)))]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "notes__content" }, [
                          _c(
                            "div",
                            { staticClass: "notes__date-container" },
                            [
                              _c("p", { staticClass: "notes__date" }, [
                                _vm._v(
                                  _vm._s(_vm._f("dateTime")(note.lastUpdatedOn))
                                ),
                              ]),
                              _vm._v(" "),
                              !_vm.noteActionsDisabled(note)
                                ? _c(
                                    VMenu,
                                    {
                                      attrs: { "close-on-click": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  VIcon,
                                                  _vm._g(
                                                    {
                                                      attrs: { color: "black" },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      "mdi-dots-horizontal"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        VList,
                                        [
                                          _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editNote(note)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("toSentenceCase")(
                                                      _vm.$t("actions.edit")
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VListItem,
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteNote(
                                                    note._id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm._f("toSentenceCase")(
                                                      _vm.$t("actions.delete")
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "notes__text" }, [
                            _vm.noteToEdit === note._id
                              ? _c(
                                  "div",
                                  { staticClass: "notes__input" },
                                  [
                                    _c(VTextarea, {
                                      attrs: {
                                        label: _vm._f("toSentenceCase")(
                                          _vm.$t("notes.editNotePlaceholder")
                                        ),
                                        "hide-details": "",
                                        "no-resize": "",
                                        solo: "",
                                        autocomplete: "",
                                        flat: "",
                                        rows: "0",
                                      },
                                      model: {
                                        value: _vm.editText,
                                        callback: function ($$v) {
                                          _vm.editText = $$v
                                        },
                                        expression: "editText",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "notes__button float-right",
                                      },
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              secondary: "",
                                              small: "",
                                              depressed: "",
                                              height: "18",
                                            },
                                            on: { click: _vm.clear },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toSentenceCase")(
                                                  _vm.$t("actions.cancel")
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          VBtn,
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              primary: "",
                                              small: "",
                                              depressed: "",
                                              height: "18",
                                              disabled:
                                                _vm.isSaveButtonDisabled(
                                                  _vm.editText
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.save(note)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("toSentenceCase")(
                                                  _vm.$t("actions.save")
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c("p", [_vm._v(_vm._s(note.text))]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "notes__input-container" }, [
                    _c(
                      "div",
                      { staticClass: "notes__input" },
                      [
                        _c(VTextarea, {
                          attrs: {
                            label: _vm._f("toSentenceCase")(
                              _vm.$t("notes.addNewNotePlaceholder")
                            ),
                            "hide-details": "",
                            "no-resize": "",
                            solo: "",
                            autocomplete: "",
                            flat: "",
                            rows: "0",
                          },
                          model: {
                            value: _vm.text,
                            callback: function ($$v) {
                              _vm.text = $$v
                            },
                            expression: "text",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "notes__button" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              primary: "",
                              small: "",
                              depressed: "",
                              height: "18",
                              disabled: _vm.isSaveButtonDisabled(_vm.text),
                            },
                            on: { click: _vm.post },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm._f("toSentenceCase")(
                                    _vm.$t("notes.postNoteButton")
                                  )
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _c("confirm-action", {
                attrs: {
                  "action-text": _vm.$t("actions.delete"),
                  "question-text": _vm.$t("notes.confirmDeleteQuestion"),
                  "warning-text": _vm.$t("notes.confirmDeleteWarning"),
                },
                on: { cancel: _vm.clear, confirm: _vm.confirmDelete },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }