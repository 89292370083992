import featureFlags from '@enums/feature-flags';
import routeNames from '@enums/route-names';
import ProductsPage from './products-page';
import guardsMap from './products-guard-map';

const defaultBeforeEnter = async (to, from, next) => {
    await guardsMap[to.name](to, from, next);
};

const routes = [
    {
        path: '/products',
        id: 'products',
        component: ProductsPage,
        linkText: 'Products',
        index: null,
        meta: { canAccess: featureFlags.canAccessProductsPage, isSideBarCalendarExpanded: false },
        children: [
            // Default route to ensure that all '/products' requests are redirected to the productsView.
            {
                path: '',
                name: routeNames.productsView,
                beforeEnter: defaultBeforeEnter,
            },
            {
                path: 'proxy-products',
                name: routeNames.proxyProductsView,
                meta: { canAccess: featureFlags.canAccessProxyProducts },
            },
            {
                path: 'product-groups',
                meta: { canAccess: featureFlags.canAccessProductGroups },
                name: routeNames.productGroupsView,
            },
        ],
    },
];

export default routes;
