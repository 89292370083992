<template>
    <v-tabs
        v-model="selectedTab"
        height="2.5rem"
        background-color="transparent"
        class="promo-tabs"
        active-class="promo-tabs__tab--active"
        hide-slider
    >
        <template v-for="tab in tabs">
            <!-- Used to fill and style the space between tabs. -->
            <div :key="`spacer-${tab.label}`" class="promo-tabs__spacer" />
            <v-tab :key="`tab-${tab.label}`" :disabled="tab.disabled" class="promo-tabs__tab">
                {{ tab.label | toSentenceCase }}
            </v-tab>
        </template>

        <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
        <div class="promo-tabs__filler" />

        <v-tab-item
            v-for="tab in tabs"
            :key="`tab-item-${tab.label}`"
            :transition="false"
            :reverse-transition="false"
        >
            <!-- Only display the grids if we are on the channels tab - this is for performance & to ensure we display the correct data -->
            <div v-if="tab.component && isOnChannelTab">
                <component
                    :is="tab.component"
                    :namespace="namespace"
                    :promotion-editing-state="promotionEditingState"
                />
            </div>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import { mapState } from 'vuex';
import { canEditListPrice } from '@enums/feature-flags';
import { channels } from '@enums/promotion-tabs';

const localizationKey = 'planning.promotionsMaintenance.resources.featuredProducts';

export default {
    props: {
        namespace: {
            required: true,
            type: String,
        },
        promotionEditingState: {
            type: Object,
            required: true,
        },
        cacheDom: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedTab: 0, // Set the first tab to be the default.
        };
    },

    computed: {
        ...mapState('clientConfig', ['toggleLogic', 'releaseFlags']),
        ...mapState('promotions', ['selectedPromotionMaintenanceTab']),

        tabs() {
            let tabsToDisplay;
            if (this.releaseFlags.releaseFlags.useCombinedRankingAndStoreProvisionTab) {
                tabsToDisplay = [
                    {
                        label: `${this.$t(
                            `${localizationKey}.rankingAndSecondPlacementTabHeading`
                        )}`,
                        disabled: false,
                        component: 'ranking-and-placement',
                    },
                ];
            } else {
                tabsToDisplay = [
                    {
                        label: this.$t(`${localizationKey}.rankingTabHeading`),
                        disabled: false,
                        component: 'ranking-tab',
                    },
                    {
                        label: this.$t(`${localizationKey}.storeProvisionTabHeading`),
                        disabled: false,
                        component: 'store-provision-grid',
                    },
                ];
            }

            if (this.toggleLogic[canEditListPrice]) {
                tabsToDisplay.push({
                    label: this.$t(`${localizationKey}.listPriceTabHeading`),
                    disabled: false,
                    component: 'list-price-grid',
                });
            }

            return tabsToDisplay;
        },
        isOnChannelTab() {
            return this.cacheDom || this.selectedPromotionMaintenanceTab === channels;
        },
    },
};
</script>

<style lang="scss" scoped></style>
