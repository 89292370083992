import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-group" },
    [
      _c("h4", { staticClass: "filter-group__header" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("toSentenceCase")(_vm.$tkey("title"))) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(VDivider, { staticClass: "mb-2" }),
      _vm._v(" "),
      _c("vuex-radio-group", {
        staticClass: "mb-2",
        attrs: {
          label: _vm.$tkey("soldBy"),
          options: _vm.promotionTypeOptions,
          getter: () => _vm.promotionType,
          disabled: _vm.promotionHasProducts,
          "vuex-module": _vm.vuexModule,
          namespace: _vm.namespace,
          row: "",
        },
        on: { change: _vm.onProductTypeChange },
      }),
      _vm._v(" "),
      _c("required-category-filter", {
        on: { change: _vm.onCategoryFilterChange },
      }),
      _vm._v(" "),
      _c(VDivider, { staticClass: "mt-2 mb-2" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "filter-group__content flex-column flex-column--guttered",
        },
        [
          _vm._l(_vm.userFilters, function (filter, index) {
            return _c(
              "div",
              { key: filter.filterId, staticClass: "filter-group__filters" },
              [
                _c(
                  "div",
                  { staticClass: "filter-group__wrapper" },
                  [
                    _vm.canUpdateFilters
                      ? _c("vuex-select", {
                          staticClass: "filter-group__parent-filter",
                          attrs: {
                            placeholder: _vm._f("toSentenceCase")(
                              _vm.$tkey("placeholder.products")
                            ),
                            options: _vm.supportedUserFilters,
                            disabled: _vm.isProductSelectDisabled,
                            "item-value": "filterKey",
                            "item-text": "filterText",
                            getter: () => _vm.userFilterKeyGetter({ index }),
                            setter: (key) =>
                              _vm.userFilterKeySetter({ index, key }),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    filter.filterKey
                      ? _c(_vm.componentMap[filter.filterType], {
                          tag: "component",
                          attrs: {
                            "prevent-default": _vm.setPreventDefault,
                            options: _vm.getFilterValueOptionsByFilterId({
                              filterId: filter.filterId,
                            }),
                            "item-value": "value",
                            "item-text": "text",
                            getter: () => _vm.userFilterValueGetter({ index }),
                            setter: (value) =>
                              _vm.userFilterValueSetter({ index, value }),
                            "finite-list": _vm.finiteList,
                            "max-items-in-list": _vm.maxItemsInList,
                            multiple: "",
                            chips: "",
                            "deletable-chips": "",
                            "invalid-selection-text":
                              "general.info.invalidFilterSelection",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showRemoveFilterBtn
                  ? _c(
                      VBtn,
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.removeFilter(index)
                          },
                        },
                      },
                      [
                        _c("icon", {
                          attrs: {
                            "icon-name": "cancel",
                            small: "",
                            "icon-colour-type": "primary-path",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("create-new-button", {
            attrs: { background: "", disabled: !_vm.canAddFilter },
            on: { createNew: _vm.addFilter },
          }),
          _vm._v(" "),
          _c(VDivider),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }