import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isCreateMode
    ? _c("create-new-button", {
        attrs: { disabled: _vm.isCreateDisabled, background: "" },
        on: { createNew: _vm.createNewProduct },
      })
    : _c(
        "div",
        [
          _vm.isEditMode
            ? _c(
                VBtn,
                {
                  attrs: { primary: "", disabled: _vm.isEditDisabled },
                  on: { click: _vm.updateProposedProduct },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("toSentenceCase")(_vm.$t("actions.save"))) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("proposed-product-delete-dialog", {
            attrs: {
              resource: _vm.productsResource,
              "resource-id": _vm.productId,
              "child-dependencies-warning": false,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }