<template>
    <div>
        <main-dialog
            ref="dialog"
            heading="userPermissions.storeGroupAccess"
            btn-confirm-text="actions.save"
            btn-cancel-text="actions.cancel"
            :has-activator="hasActivator"
            @confirm="save"
            @close="closeDialog"
        >
            <template v-slot:default>
                <div class="attributes" :style="templateStyle">
                    <div
                        v-for="attribute in storeGroupAttributes"
                        :key="attribute.attributeName"
                        class="attributes__header"
                    >
                        {{ attribute.attributeName }}
                    </div>
                    <div
                        v-for="(row, rowIndex) in userStoreGroupAttributeRestrictions"
                        :key="row.name"
                        class="attributes__loop-wrapper"
                    >
                        <div
                            v-for="(attribute, columnIndex) in storeGroupAttributes"
                            :key="attribute.attributeName"
                            class="attributes__value"
                            :class="{ attributes__firstValue: columnIndex === 0 }"
                        >
                            <vuex-select
                                :placeholder="
                                    $t('userPermissions.placeholder.attribute') | toSentenceCase
                                "
                                :options="attribute.options"
                                :getter="() => getAttributeValues(row, attribute.attributeName)"
                                :setter="
                                    value => setAttributeValues(row, attribute.attributeName, value)
                                "
                                item-value="value"
                                item-text="value"
                                multiple
                                chips
                                deletable-chips
                                autocomplete
                                clearable
                                :finite-list="true"
                                :max-items-in-list="1"
                            />
                        </div>
                        <icon-button
                            icon="cancel"
                            :disabled="disableDeleteButton"
                            @click.stop="() => removeRow(rowIndex)"
                        />
                    </div>
                </div>
                <icon-button
                    class="attributes__add-button"
                    icon="add"
                    :disabled="disableAddButton"
                    @click.stop="addRow"
                />
            </template>
        </main-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { omit, cloneDeep, pull } from 'lodash';
import {
    getAccessibleStoreGroups,
    filterInactiveAttributes,
    getStoreGroupAttributeOption,
} from '@sharedModules/profile-utils';

export default {
    props: {
        user: {
            type: Object,
            default: () => {
                return {};
            },
        },

        hasActivator: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        userStoreGroupAttributeRestrictions: [],
    }),

    computed: {
        ...mapState('storeGroups', ['storeGroups']),

        storeGroupAttributes() {
            return getStoreGroupAttributeOption({ storeGroups: this.storeGroups });
        },

        templateStyle() {
            return {
                // Add one column to the grid for each attribute, plus an one for the delete button.
                'grid-template-columns': `repeat(${this.storeGroupAttributes.length + 1}, auto)`,
            };
        },

        disableAddButton() {
            return this.userStoreGroupAttributeRestrictions.some(attributes => !attributes.length);
        },

        disableDeleteButton() {
            return this.userStoreGroupAttributeRestrictions.length === 1;
        },
    },

    methods: {
        ...mapActions('userProfiles', ['updateUserProfile']),

        openDialog() {
            const currentUser = cloneDeep(this.user) || { access: undefined };

            const rawStoreGroupAttributeRestrictions = currentUser.accessRestrictions
                .storeGroupAttributeRestrictions || [[]];

            this.userStoreGroupAttributeRestrictions = filterInactiveAttributes({
                storeGroupAttributeRestrictions: rawStoreGroupAttributeRestrictions,
                storeGroupAttributeOptions: this.storeGroupAttributes,
            });

            // If the user has not attribute restrictions, add an default empty row.
            if (!this.userStoreGroupAttributeRestrictions.length) this.addRow();

            this.$refs.dialog.open();
        },
        closeDialog() {
            if (this.$refs.dialog) {
                this.$refs.dialog.close();
            }
            this.$emit('close');
        },

        addRow() {
            this.userStoreGroupAttributeRestrictions.push([]);
        },

        removeRow(index) {
            this.userStoreGroupAttributeRestrictions.splice(index, 1);
        },

        getAttributeValues(userAttributes, attributeName) {
            const attribute = (userAttributes || []).find(
                attr => attr.attributeName === attributeName
            );
            return (attribute || { values: [] }).values;
        },
        setAttributeValues(userAttributes, attributeName, values) {
            const attribute = (userAttributes || []).find(
                attr => attr.attributeName === attributeName
            );

            if (attribute) {
                attribute.values = values;
                if (!values.length) {
                    pull(userAttributes, attribute);
                }
            } else {
                userAttributes.push({
                    attributeName,
                    values,
                });
            }
        },

        async save() {
            this.user.accessRestrictions.storeGroupAttributeRestrictions = this.userStoreGroupAttributeRestrictions;

            this.user.access.storeGroups = getAccessibleStoreGroups({
                storeGroupAttributeRestrictions: this.userStoreGroupAttributeRestrictions,
                storeGroups: this.storeGroups,
            });
            await this.updateUserProfile({
                _id: this.user._id,
                updatedItem: omit(this.user, ['isAdmin', 'rolesString']),
            });
            this.$refs.dialog.close();
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.attributes {
    display: grid;

    &__header {
        border-bottom: 1px solid $promo-divider-colour;
        padding: 1rem;
        font-size: 1.4rem;
        letter-spacing: 0;
        color: $promo-text-colour;
    }

    &__loop-wrapper {
        display: contents;
    }

    &__value {
        padding: 1rem;
        align-self: end;
    }

    &__firstValue {
        grid-column-start: 1;
    }

    &__add-button {
        display: flex;
        align-self: start;
        margin: 1rem 2rem;
    }
}
</style>
