var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loyalty-points-viewer" },
    [
      _c("promo-ag-grid", {
        attrs: {
          "row-data": _vm.loyaltyPointsData,
          "column-defs": _vm.columnDefs,
          "grid-options": _vm.gridOptions,
          "grid-style": _vm.gridStyle,
          "default-col-def": _vm.defaultColDef,
        },
        on: { "cell-value-changed": _vm.onCellValueChanged },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid-controls" },
        [
          _c("create-new-button", {
            attrs: {
              disabled: _vm.isAddingLoyaltyPoint || _vm.isCreateInProgress,
              "btn-text": _vm.buttonText,
              background: "",
            },
            on: { createNew: _vm.addNewLoyaltyPoint },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }