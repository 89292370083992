import { VForm } from 'vuetify/lib/components/VForm';
import { VMessages } from 'vuetify/lib/components/VMessages';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VForm,
    {
      ref: "priceWeightForm",
      attrs: { "hide-details": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.isValid,
        callback: function ($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid",
      },
    },
    [
      _c("div", { staticClass: "price-weight" }, [
        _c(
          "div",
          { staticClass: "price-weight__container" },
          [
            _c("vuex-number-input", {
              staticClass: "price-weight__container--input",
              attrs: {
                getter: () => _vm.getValue("weight"),
                setter: (newValue) =>
                  _vm.setValue(newValue, _vm.productTypes.weight),
                disabled: _vm.isReadOnly,
                "hide-details": "",
                "positive-only": "",
              },
              on: {
                change: function ($event) {
                  return _vm.onChange(false)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "price-weight__container" },
          [
            _c("vuex-select", {
              staticClass: "price-weight__container--select",
              attrs: {
                options: _vm.unitOptions,
                getter: () => _vm.getValue(_vm.productTypes.unit),
                setter: (newValue) =>
                  _vm.setValue(newValue, _vm.productTypes.unit),
                filled: "",
                "validate-on-blur": false,
                disabled: _vm.isReadOnly,
              },
              on: {
                change: function ($event) {
                  return _vm.onChange(true)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.formValidationError.length
        ? _c(VMessages, {
            attrs: { color: "error", value: _vm.formValidationError },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }