import axios from 'axios';
import { get, sortBy, keyBy, map, uniq, lowerCase } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import { canAccessAllCategories, canAccessAllStoreGroups } from '@enums/feature-flags';

const getInitialContextState = () => ({
    loginMethod: null, // primary authMethod from auth.js config file
    loginRedirectUrl: null,
    oktaClientId: null,
    versionTag: null,
    env: null,
    subBrand: null,
    datadog: {},
    clientUrl: null,
});

const getInitialUserContextState = () => ({
    csrfToken: null,
    profile: null,
    loading: false,
    currentLoginMethod: null, // authMethod used on session scope, needed for correct logout
    expireAt: null,
});

const store = {
    namespaced: true,

    state: { ...getInitialContextState(), ...getInitialUserContextState },

    getters: {
        userUnits: (state, getters, rootState, rootGetters) => {
            if (rootState.clientConfig.toggleLogic[canAccessAllCategories]) {
                return sortBy(rootGetters['hierarchy/getAllUnits'], [
                    i => lowerCase(i.levelEntryDescription),
                ]);
            }

            if (state.profile) {
                const categories = sortBy(
                    state.profile.access.categories.map(levelEntryKey =>
                        rootGetters['hierarchy/getHierarchyByKey'](levelEntryKey)
                    ),
                    [i => lowerCase(i.levelEntryDescription)]
                );

                const units = uniq(map(categories, 'parentId'));

                return sortBy(
                    units.map(levelEntryKey =>
                        rootGetters['hierarchy/getHierarchyByKey'](levelEntryKey)
                    ),
                    [i => lowerCase(i.levelEntryDescription)]
                );
            }

            return [];
        },
        userCategories: (state, getters, rootState, rootGetters) => {
            if (rootState.clientConfig.toggleLogic[canAccessAllCategories]) {
                return sortBy(rootGetters['hierarchy/getAllCategories'], [
                    i => lowerCase(i.levelEntryDescription),
                ]);
            }
            return state.profile
                ? sortBy(
                      get(state, 'profile.access.categories', [])
                          .map(levelEntryKey =>
                              rootGetters['hierarchy/getHierarchyByKey'](levelEntryKey)
                          )
                          .filter(cat => cat), // Sometimes users might have categories assigned that are not actually categories. Can be seen in test users on different envs
                      [i => lowerCase(i.levelEntryDescription)]
                  )
                : [];
        },
        userAcccess: state => key => get(state, `profile.access.${key}`),
        userUnitKeys: (state, getter) => map(getter.userUnits, 'levelEntryKey'),
        userCategoryKeys: (state, getter) => map(getter.userCategories, 'levelEntryKey'),
        userCategoryValues: state => get(state, 'profile.access.categories'),
        userRDWCategoryKeys: (state, getter) => map(getter.userCategories, 'rdwHierarchyKey'),
        userCategoriesDescription: (state, getter) => getter.userAcccess('categories'),
        userBrandsDescription: (state, getter) => getter.userAcccess('brands'),
        userChannelsDescription: (state, getter) => getter.userAcccess('channels'),

        userCategoriesKeyMap: (state, getters) => {
            return keyBy(getters.userCategories, 'levelEntryKey');
        },
        userId: state => state.profile && state.profile._id,
        userRoles: state => state.profile && state.profile.roles,
        hasRole: state => role => get(state, 'profile.roles', []).includes(role),
        userPermissions: state => new Set(get(state, 'profile.permissions')),
        hasPermission: (state, getters) => permission => getters.userPermissions.has(permission),
        userStoreGroups: (state, getters, rootState, rootGetters) => {
            if (rootState.clientConfig.toggleLogic[canAccessAllStoreGroups]) {
                return rootGetters['storeGroups/getStoreGroupsOptions'];
            }
            return state.profile && state.profile.access && state.profile.access.storeGroups
                ? state.profile.access.storeGroups.map(
                      key => rootGetters['storeGroups/storeGroupsMapByKey'][key]
                  )
                : [];
        },
        userStoreGroupsMap: (state, getters) => {
            return keyBy(getters.userStoreGroups, 'key');
        },
    },

    mutations: {
        setContext(state, contextData) {
            Object.assign(state, contextData);
        },
        setUserContext(state, profile) {
            state.profile = profile;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setLoginMethod(state, method) {
            state.loginMethod = method;
        },
        setCurrentLoginMethod(state, method) {
            state.currentLoginMethod = method;
        },
        setExpireAt(state, expireAt) {
            state.expireAt = expireAt;
        },
        setCsrfToken(state, token) {
            state.csrfToken = token;
        },
        resetUserContextState(state) {
            Object.assign(state, { ...state, ...getInitialUserContextState() });
        },
    },

    actions: {
        loadContext({ commit }) {
            return axios.get('/api/context').then(res => commit('setContext', res.data));
        },
        loginHardcoded({ dispatch, commit }, credentials) {
            return axios.post('/api/login', credentials).then(
                () => {
                    commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
                    return dispatch('loadUserContext');
                },
                err => {
                    console.error(err.response.data.message);
                    commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
                }
            );
        },

        loginOkta({ dispatch, commit }, token) {
            return axios.post('/api/ow-auth/login', { token }).then(
                () => {
                    commit('setCurrentLoginMethod', 'ow-auth-okta');
                    return dispatch('loadUserContext');
                },
                err => {
                    console.error(err.response.data.message);
                    commit('setCurrentLoginMethod', 'ow-auth-okta');
                }
            );
        },

        setCurrentLoginMethod({ commit }, method) {
            commit('setCurrentLoginMethod', method);
        },

        async updateUserContext({ commit }) {
            const { data } = await axios.get('/api/user-context');
            if (!data) return;

            // save tokenLifeTime in milliseconds if valid login exists
            const tokenLifetime = (data.exp - data.iat) * 1000;
            const expirationDate = Date.now() + tokenLifetime;
            commit('setUserContext', data.profile);
            commit('setCsrfToken', data.csrfToken);
            commit('setExpireAt', expirationDate);
        },

        async loadUserContext({ state, dispatch, commit, rootState }) {
            const { data } = await axios.get('/api/user-context');
            if (!data) return;

            datadogRum.setUser({
                id: data.profile._id,
                name: data.profile.username,
                hostname: state.clientUrl,
            });
            datadogRum.startSessionReplayRecording();

            // save tokenLifeTime in milliseconds if valid login exists
            const tokenLifetime = (data.exp - data.iat) * 1000;
            const expirationDate = Date.now() + tokenLifetime;
            commit('setUserContext', data.profile);
            commit('setCsrfToken', data.csrfToken);
            commit('setExpireAt', expirationDate);

            // this is where we know the user has successfully authenticated so we can load state
            await dispatch('initialiseStateBlocking', null, { root: true });
            if (!get(rootState, 'clientConfig.toggleLogic.reportingOnly', false)) {
                dispatch('initialiseStateBackground', null, { root: true });
            }
        },

        refreshUserContext({ dispatch }) {
            return axios.post('/api/token/refresh').then(() => {
                return dispatch('updateUserContext');
            });
        },

        logout({ state, commit }) {
            let logoutUrl = '/api/logout';
            if (state.currentLoginMethod === 'ow-auth-okta') logoutUrl = '/api/ow-auth/logout';
            if (state.currentLoginMethod === 'auth-saml') logoutUrl = '/api/auth-saml/logout';

            return axios.get(logoutUrl).then(res => {
                commit('setUserContext', null);
                commit('setCsrfToken', null);
                datadogRum.removeUser();
                datadogRum.stopSessionReplayRecording();

                if (state.currentLoginMethod === 'auth-saml') {
                    // The SAML strategy will redirect to SAML logout page, this one
                    // to our logout/callback and finally back to our client app
                    window.location.href = res.data.redirectUrl;
                    return Promise.reject();
                }
                return Promise.resolve(
                    state.currentLoginMethod === 'ow-auth-okta' ? '/ow-auth/login' : '/login'
                );
            });
        },

        resetState({ commit }) {
            commit('resetUserContextState');
        },
    },
};

export default store;
