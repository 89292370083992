var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-promotion-container" },
    [
      _c("main-dialog", {
        ref: "dialog",
        staticClass: "promotion-dialog",
        attrs: {
          heading: "parkingLot.promotion.createDialogHeading",
          "btn-confirm-text": "actions.save",
          "btn-cancel-text": "actions.cancel",
          "has-activator": _vm.hasActivator,
          "show-footer": false,
        },
        on: { close: _vm.closeDialog },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "promotion-dialog-container" },
                  [
                    _c("promotion-viewer", {
                      ref: "promotionViewer",
                      attrs: {
                        promotion: _vm.currentPromotion,
                        scenario: _vm.scenario,
                        "sub-campaign": _vm.subCampaign,
                        "split-promotion": _vm.splitPromotion,
                        "promotion-name": _vm.promotionName,
                        "is-parking-lot": _vm.isParkingLot,
                        "is-past-promotions": _vm.isPastPromotions,
                        "is-sub-campaign-allocation":
                          _vm.isSubCampaignAllocation,
                        "is-in-dialog": "",
                      },
                      on: {
                        "split-requested": _vm.closeDialog,
                        "save-button-clicked": function ($event) {
                          return _vm.handleSaveButtonClick($event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }