<template>
    <v-dialog v-model="dialog" persistent class="error-dialog">
        <template v-if="hasActivator" v-slot:activator="{ on }">
            <slot :activator="on" name="actions">
                <v-btn color="primary" dark small depressed v-on="on">
                    {{ $t(buttonText) | toSentenceCase }}
                </v-btn>
            </slot>
        </template>
        <v-card class="error-dialog__container">
            <div class="error-dialog__header">
                <span>{{ headerText }}</span>
                <v-btn icon @click="closeDialog">
                    <icon icon-name="cancel" small />
                </v-btn>
            </div>
            <v-divider class="error-dialog__header-divider" />
            <div>
                <div v-if="size(formattingErrors)" class="error-dialog__formatting-errors">
                    <icon icon-name="warning" />
                    <span class="error-dialog__formatting-errors--upload-failed">
                        {{ $t('planning.promotionsMaintenance.funding.errors.uploadFailed') }}:
                    </span>
                    {{ $t('planning.promotionsMaintenance.funding.errors.formattingErrors') }}
                </div>
                <v-treeview v-else :items="errorTree || treeViewErrors" />
            </div>
            <div class="error-dialog__close-button">
                <v-btn primary depressed @click="closeDialog">
                    {{ $t('actions.close') | toSentenceCase }}
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { size, pick, omit, keys } from 'lodash';

export default {
    props: {
        errors: {
            type: Object,
            default: null,
        },
        hasActivator: {
            type: Boolean,
            default: false,
        },
        errorTree: {
            type: Array,
            default: null,
        },
        header: {
            type: String,
            default: '',
        },
        buttonText: String,
    },
    data: () => ({
        dialog: false,
    }),

    computed: {
        treeViewErrors() {
            return keys(omit(this.errors, ['formattingErrors'])).reduce((acc, key, index) => {
                acc.push({
                    id: index,
                    name: `${this.$t(
                        `planning.promotionsMaintenance.funding.errors.${key}`
                    )} - ${this.$t(`planning.promotionsMaintenance.funding.total`)}: ${size(
                        this.errors[key]
                    )}`,
                    children: this.errors[key].map((pKey, ix) => {
                        return {
                            id: ix,
                            name: pKey,
                        };
                    }),
                });
                return acc;
            }, []);
        },

        formattingErrors() {
            return pick(this.errors, ['formattingErrors']);
        },

        headerText() {
            if (this.header) {
                return this.$options.filters.toSentenceCase(this.$t(this.header));
            }
            return this.$t(`planning.promotionsMaintenance.funding.fileUploadReport`);
        },
    },

    methods: {
        size,

        close() {
            this.dialog = false;
        },
        open() {
            this.dialog = true;
        },
        closeDialog() {
            this.$emit('close');

            if (!this.preventDefault) {
                this.close();
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.error-dialog {
    &__header {
        display: flex;
        justify-content: space-between;
        font-size: 1.6rem;
        font-weight: 600;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    &__header-divider {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-bottom: 1rem;
    }

    &__container {
        width: 60rem;
        border-left: 0.4rem solid $promo-primary-colour;
        font-size: 1.3rem;
    }

    &__formatting-errors {
        height: 5rem;
        padding-left: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &--upload-failed {
            font-weight: 600;
        }
    }

    &__close-button {
        display: flex;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        padding-right: 1rem;

        .v-btn {
            margin-left: auto;
        }
    }
}

::v-deep {
    .v-treeview-node__root {
        min-height: 2rem;
    }
    .v-treeview-node__children {
        :nth-child(even) {
            background-color: $promo-table-blue-bg-colour-2;
        }
    }

    .v-treeview-node__root {
        padding-left: unset;

        .v-treeview-node__content {
            margin-left: unset;
        }
    }

    .v-treeview-node__children {
        overflow-y: auto;
        max-height: 15rem;
    }

    .v-treeview-node {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }
}
</style>
