<template>
    <div class="rate-cards-viewer">
        <!--Header section start-->
        <div class="rate-cards-viewer__top rate-cards-grid">
            <div
                v-for="(field, index) in rateCardFields"
                :key="`rateCardHeader::${index}`"
                :class="['rate-cards-viewer__header', field.headerClasses]"
            >
                <div v-if="field.header">
                    {{ getHeader(field) }}
                </div>
            </div>
        </div>
        <!--Header section end-->
        <div class="rate-cards-viewer__bottom rate-cards-grid">
            <!-- Rate cards section start-->
            <template v-for="rateCard in rateCards">
                <vuex-form-viewer
                    :key="rateCard._id"
                    :context="rateCard"
                    :fields="rateCardFields"
                    :make-read-only="selectedRateCardId !== rateCard._id"
                    :form="getFullForm(rateCard)"
                    @edit="onEdit"
                >
                    <template v-if="selectedRateCardId === rateCard._id">
                        <div :key="`${rateCard._id}_edit`" class="rate-cards-viewer__icons">
                            <icon-button icon="edit" @click="setSelectedRateCardId(null)" />
                        </div>
                        <div :key="`${rateCard._id}_delete`" class="rate-cards-viewer__icons">
                            <common-delete-dialog
                                :resource="rateCardsEnum"
                                :child-dependencies-warning="false"
                                @delete="deleteRateCard({ id: rateCard._id })"
                            />
                        </div>
                    </template>
                </vuex-form-viewer>
                <div :key="`${rateCard._id}_filler`" class="filler" />
            </template>
            <!-- Rate cards section end-->
            <!--New rate card section start-->
            <vuex-form-viewer
                v-if="isNewRateCard"
                ref="newFormViewer"
                :context="{}"
                :fields="[]"
                :form="getFullForm()"
            >
                <div class="rate-cards-viewer__icons" />
                <div class="rate-cards-viewer__icons" />
                <div class="rate-cards-viewer__buttons">
                    <v-btn text class="cancel-button" @click="cancelCardRate">
                        {{ $t('actions.cancel') | toSentenceCase }}
                    </v-btn>
                    <simple-button :disabled="isSaveNewRateCardDisabled" @onClick="saveCardRate()">
                        {{ $t('actions.save') | toSentenceCase }}
                    </simple-button>
                </div>
            </vuex-form-viewer>
            <!--New rate card section End-->
        </div>
        <!--Create button-->
        <div class="rate-cards-viewer__new-card">
            <create-new-button
                :btn-text="$tkey('rateCards.newRateCardButton') | toSentenceCase"
                :disabled="isNewRateCard"
                @createNew="addNewRateCard"
            />
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';
import namespaces from '@enums/namespaces';
import { fieldTypes } from '@enums/vuex-form';
import { rateCards as rateCardsEnum } from '@enums/resources';
import rateCardFields from './rate-card-fields';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import storeGroupsOptionsMixin from '@/js/pages/mixins/store-groups-options-mixin';

export default {
    mixins: [storeGroupsOptionsMixin],
    data() {
        return {
            rateCardFields: Object.freeze(rateCardFields),
            isNewRateCard: false,
            rateCardsEnum,
        };
    },
    computed: {
        ...mapGetters('clientConfig', ['currencySymbol']),
        ...mapState('rateCards', ['rateCards', 'selectedRateCardId', 'validationStates']),
        isSaveNewRateCardDisabled() {
            const namespace = namespaces.default;
            return (
                !this.validationStates[namespace] ||
                (this.validationStates[namespace] && !this.validationStates[namespace].isValid)
            );
        },
    },
    methods: {
        ...mapActions('rateCards', ['setSelectedRateCardId', 'updateRateCard', 'deleteRateCard']),
        minDateForRateCard(rateCard) {
            // if the ratecard is new or has not started (i.e. effectiveFrom is in future)
            // we should not let the user set an effectiveFrom date in the past.
            const effectiveFrom = this.$moment(get(rateCard, 'effectiveFrom'));
            const rateCardStarted = effectiveFrom.isBefore(this.$moment(), 'day');

            if (this.isNewRateCard || !rateCardStarted) {
                return this.$d('dates.default.shortDate', this.$moment());
            }
            // if the effectiveFrom date is already in the past, no restriction is applied. user can choose any date.
        },
        getHeader(field) {
            if (field.isCurrency) {
                return this.currencySymbol;
            }

            const header = this.$t(`rateCards.headers.${field.header}`);
            return this.$options.filters.toSentenceCase(header);
        },
        addNewRateCard() {
            this.isNewRateCard = true;
            this.setSelectedRateCardId(null);
        },
        onEdit(event) {
            const id = get(event, 'params.context._id', null);
            this.setSelectedRateCardId(id);
            this.isNewRateCard = false;
        },
        form(rateCard) {
            return {
                vuexModule: 'rateCards',
                editable: true,
                addEvent: 'createRateCard',
                isStagingAreaResetNeeded: false,
                fields: [
                    {
                        fieldName: 'identifier',
                        editable: true,
                        type: fieldTypes.text,
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'description',
                        editable: true,
                        type: fieldTypes.text,
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'storeGroups',
                        placeholder: 'rateCards.placeholders.storeGroups',
                        editable: true,
                        type: fieldTypes.select,
                        options: this.storeGroupsOptions,
                        defaultValue: this.userStoreGroups,
                        validateOnBlur: false,
                        itemText: 'description',
                        itemValue: 'key',
                        multiple: true,
                        chips: true,
                        deletableChips: true,
                        returnObject: true,
                        autocomplete: true,
                        finiteList: true,
                        maxItemsInList: 3,
                        needPreValidationOnSet: !this.isNewRateCard,
                        validations: [
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        fieldName: 'amount',
                        editable: true,
                        type: fieldTypes.currency,
                        needPreValidationOnSet: !this.isNewRateCard,
                        validations: [
                            {
                                validator: validators.minValue,
                                params: [0, false],
                            },
                            {
                                validator: validators.required,
                            },
                        ],
                    },
                    {
                        type: fieldTypes.dateRange,
                        cssClass: 'vuex-form__dates',
                        editable: true,
                        from: {
                            fieldName: 'effectiveFrom',
                        },
                        to: {
                            fieldName: 'effectiveTo',
                        },
                        minDate: this.minDateForRateCard(rateCard),
                        validations: [
                            {
                                message: i18n.t('validation.supplierCommitment.datesRequired'),
                                validator: validators.required,
                            },
                            {
                                validator: validators.dateRangeRequired,
                            },
                        ],
                    },
                ],
            };
        },
        getFullForm(rateCard) {
            const namespace = rateCard ? rateCard._id : namespaces.default;
            return {
                editContext: rateCard || null,
                editMode: !!rateCard,
                validation: this.validationStates[namespace],
                ...this.form(rateCard),
            };
        },
        cancelCardRate() {
            this.isNewRateCard = false;
        },
        async saveCardRate() {
            if (this.$refs.newFormViewer) {
                const { error } = await this.$refs.newFormViewer.submit();
                if (!error) {
                    this.isNewRateCard = false;
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.rate-cards-viewer {
    padding-left: 0.5rem;
    .rate-cards-grid {
        display: grid;
        grid-template-columns:
            1fr
            1fr
            1fr
            1fr
            1fr
            5rem
            5rem[last-column];
    }
    &__top {
        margin-bottom: 1.5rem;
    }

    &__header {
        padding-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $promo-text-colour;
        background-color: $promo-white;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
        border-bottom: $promo-divider-colour 0.1rem solid;
        &.last {
            grid-column-end: span 3;
            padding-right: 1.5rem;
        }
    }

    &__icons {
        @include flex-column;
        padding: 1.5rem 1rem;
        background-color: $promo-white;
        height: 100%;
    }

    &__new-card {
        grid-column: 1 / span 6;
        border-top: $promo-divider-colour 0.1rem solid;
        padding: 1rem 0;
    }

    .filler {
        @include span-full-row;
        margin-bottom: 1.5rem;
    }

    &__buttons {
        @include span-full-row;
        background: $promo-white;
        padding-bottom: 1rem;
        text-align: right;
        padding-right: 2rem;

        .cancel-button {
            color: $promo-primary-colour;
            font-weight: 600;
        }
    }

    ::v-deep {
        .vuex-form-viewer__grid-cell {
            @include flex-column;
            padding: 0.6rem 1rem;
            background-color: $promo-white;
            font-weight: 400;
            line-height: 3rem;
            font-size: 1.2rem;
            color: $promo-text-colour;
            letter-spacing: 0;
            height: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        .text-bold {
            font-weight: 600;
        }
        .v-input--is-disabled {
            .v-input__control > .v-input__slot:before {
                border-image: none !important;
                border: none !important;
            }
            .v-input__append-inner {
                display: none;
            }
        }

        .vuex-form-viewer__edit {
            form > div {
                margin: 0 !important;
            }

            .vuex-currency-input__currency-symbol {
                font-size: 1.2rem;
            }
        }

        .vuex-from-grid {
            & > div {
                background: #fff;
                padding: 1rem 1rem 0.7rem;
                height: 100%;
            }
            .rtls-select-container {
                padding-top: 0.6rem;
            }
        }
    }
}
</style>
