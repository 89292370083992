<template>
    <div class="btn-checkbox-wrapper">
        <vuex-checkbox
            v-if="params.data.promotionId"
            :getter="() => isSelected()"
            :setter="value => selectionHandler(value)"
        />

        <v-btn
            v-if="!params.data.promotionId && !isCategoryOrStoreWidePromotion(params.data.id)"
            x-small
            depressed
            @click="btnClickedHandler()"
        >
            {{ $t('subCampaignAllocation.forecastsModal.view') | toSentenceCase }}
        </v-btn>
    </div>
</template>

<script>
import Vue from 'vue';
import pogScope from '@enums/product-offer-group-scope';

export default Vue.extend({
    methods: {
        isSelected() {
            return this.params.selectedIds.find(id => id === this.params.data.promotionId);
        },
        btnClickedHandler() {
            const isUnit = this.params.data.hierarchy.length <= 2;
            this.params.clicked(this.params.data.id, isUnit);
        },
        selectionHandler(value) {
            this.params.selection(this.params.data.promotionId, value);
        },
        isCategoryOrStoreWidePromotion(scope) {
            return scope === pogScope.storeWide || scope === pogScope.categoryWide;
        },
    },
});
</script>

<style lang="scss" scoped>
// centers the checkbox within it's parent
.btn-checkbox-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    // Removes the default 8px margin that comes in v-checkbox
    ::v-deep .rtls-checkbox .v-input--selection-controls__input {
        margin: 0;
    }
}
</style>
