<template functional>
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Icon / Parent Child</title>
        <path
            d="M10.6493 11.376L10.854 11.7303H10.6088L9.22129 9.32274L8.28809 7.70346V9.5724V15.3674H6.25874V11.6263V11.1263H5.75874H5.34993V7.99109C5.34993 7.60077 5.67136 7.27935 6.06167 7.27935H7.88117C8.12172 7.28397 8.35657 7.41194 8.48943 7.63705C8.4897 7.63751 8.48997 7.63797 8.49023 7.63843L10.6493 11.376ZM14.6465 10.7156V12.3362H14.5406H14.0406V12.8362V15.3655H12.6171V11.5638V10.3068H14.2377C14.4623 10.3068 14.6465 10.4928 14.6465 10.7156ZM12.0721 10.5768C12.1408 10.4572 12.2478 10.3739 12.3678 10.3339L11.7075 11.2771L11.3903 11.7303H11.2705L11.4925 11.4118L12.0487 10.6138L12.0612 10.5958L12.0721 10.5768ZM9.99988 18.8333C14.8784 18.8333 18.8332 14.8785 18.8332 10C18.8332 5.12148 14.8784 1.16667 9.99988 1.16667C5.12136 1.16667 1.16654 5.12148 1.16654 10C1.16654 14.8785 5.12136 18.8333 9.99988 18.8333ZM19.4999 10C19.4999 15.2467 15.2466 19.5 9.99988 19.5C4.75317 19.5 0.499878 15.2467 0.499878 10C0.499878 4.75329 4.75317 0.5 9.99988 0.5C15.2466 0.5 19.4999 4.75329 19.4999 10ZM6.97048 4.25C7.36079 4.25 7.68222 4.57142 7.68222 4.96174C7.68222 5.35205 7.36079 5.67348 6.97048 5.67348C6.58016 5.67348 6.25874 5.35205 6.25874 4.96174C6.25874 4.57142 6.58016 4.25 6.97048 4.25ZM13.3289 8.70094C13.1022 8.70094 12.9201 8.51887 12.9201 8.29214C12.9201 8.06541 13.1022 7.88333 13.3289 7.88333C13.5556 7.88333 13.7377 8.06541 13.7377 8.29214C13.7377 8.51887 13.5556 8.70094 13.3289 8.70094Z"
            fill="#9b9b9bad"
            stroke="#9b9b9bad"
        />
    </svg>
</template>

<script>
export default {
    name: 'ParentChildIconGrey',
};
</script>
