var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "simple-button",
    {
      attrs: { disabled: _vm.disabled },
      on: {
        onClick: function ($event) {
          return _vm.forecastPromotion(_vm.promotion)
        },
      },
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm._f("toSentenceCase")(
              _vm.isNewForecast
                ? _vm.$t("forecasting.actions.forecastNewPromotion")
                : _vm.$t("forecasting.actions.forecastPromotion")
            )
          ) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }