import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "expand-collapse" },
    [
      _c(
        VBtn,
        {
          staticClass: "expand-collapse__btn",
          attrs: { text: "" },
          on: { click: _vm.expandAll },
        },
        [
          _c("icon", {
            staticClass: "expand-collapse__btn--icon",
            attrs: { "icon-name": "expand-all" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("subCampaignAllocation.expandCollapse.expandAll")
                )
              ) +
              "\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "expand-collapse__btn",
          attrs: { text: "" },
          on: { click: _vm.collapseAll },
        },
        [
          _c("icon", {
            staticClass: "expand-collapse__btn--icon",
            attrs: { "icon-name": "collapse-all" },
          }),
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("subCampaignAllocation.expandCollapse.collapseAll")
                )
              ) +
              "\n    "
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }