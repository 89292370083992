'use strict';

module.exports = {
    products: 'products',
    dateTime: 'dateTime',
    resourcesStores: 'resourcesStores',
    offer: 'offer',
    mechanic: 'mechanic',
    additionalClientSpecificFields: 'additionalClientSpecificFields',
    funding: 'funding',
    topLevelFields: 'topLevelFields',
    productOfferGroup: 'productOfferGroup',
    supply: 'supply',
    offerProducts: 'offerProducts',
};
