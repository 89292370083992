var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vuex-form-viewer" }, [
    _vm.isFormReadOnly
      ? _c(
          "div",
          {
            staticClass: "vuex-form-viewer__read",
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          },
          _vm._l(_vm.fields, function (field, index) {
            return _c(
              "div",
              {
                key: `${_vm.context._id}::field::${index}`,
                class: [
                  "vuex-form-viewer__grid-cell",
                  ...(field.cellClasses || []),
                  _vm.isExpandable
                    ? "vuex-form-viewer__grid-cell--expandable"
                    : "",
                ],
              },
              [
                field.contextFunction
                  ? _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.callContextFunction({
                              contextFunction: field.contextFunction,
                              context: _vm.context,
                              params: field.params,
                            })
                          )
                        ),
                      ]),
                    ])
                  : field.component
                  ? _c(
                      "div",
                      [
                        _c(
                          field.component.name,
                          _vm._g(
                            _vm._b(
                              { tag: "component" },
                              "component",
                              _vm.constructVBindObj({
                                context: _vm.context,
                                field: field.name,
                                ...field.component,
                                componentRef: _vm.$parent,
                              }),
                              false
                            ),
                            _vm.constructVBindEventObj({
                              events: _vm.getAttribute(
                                field.component,
                                "events"
                              ),
                              context: _vm.context,
                            })
                          )
                        ),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v(_vm._s(_vm.getAttribute(_vm.context, field.name))),
                    ]),
              ]
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "vuex-form-viewer__edit" },
          [
            _c(
              "vuex-form",
              _vm._b(
                { ref: "form", staticClass: "vuex-from-grid" },
                "vuex-form",
                _vm.form,
                false
              )
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }