<template>
    <div class="total-spend-viewer">
        <div class="total-spend-viewer total-spend-viewer__grid">
            <template v-for="(field, index) in fields">
                <div
                    v-if="field.header"
                    :key="`${field.header}::${index}`"
                    :class="setClassName(field)"
                >
                    {{ $t(`supplierCommitments.headers.${field.header}`) | toSentenceCase }}
                </div>
            </template>
            <div
                v-if="isPromotionSupplierFundingView"
                :class="['total-spend-viewer__header', 'last-column', 'select-heading']"
            >
                {{ $t('supplierCommitments.headers.select') | toSentenceCase }}
            </div>

            <supplier-commitment-target
                v-if="!isPromotionSupplierFundingView && supplierCommitments.length"
                :targets-data="targets"
                class="total-spend-viewer__target"
            />

            <div
                class="total-spend-viewer__filler"
                :class="{
                    'total-spend-viewer__filler--reseted-margin': !isPromotionSupplierFundingView,
                }"
            />

            <template v-for="(supplierCommitment, index) in supplierCommitments">
                <vuex-form-viewer
                    :key="supplierCommitment._id"
                    :context="supplierCommitment"
                    :fields="fields"
                    :is-expandable="!isPromotionSupplierFundingView"
                    :should-override-form-read-only="
                        selectedSupplierCommitmentId === supplierCommitment._id
                    "
                    :make-read-only="selectedSupplierCommitmentId !== supplierCommitment._id"
                    :form="getForm(supplierCommitment)"
                    v-on="
                        !isPromotionSupplierFundingView
                            ? { click: () => onSelect({ id: supplierCommitment._id }) }
                            : {}
                    "
                    @delete="removeNote"
                    @post="postNote"
                    @save="saveNote"
                >
                    <template v-if="selectedSupplierCommitmentId === supplierCommitment._id">
                        <div class="total-spend-viewer__icons" :style="getStyle(index, 9)">
                            <notes
                                :notes="supplierCommitment.notes"
                                open-left
                                @delete="removeNote"
                                @post="postNote"
                                @save="saveNote"
                            />
                        </div>
                        <div
                            v-if="!isPromotionSupplierFundingView"
                            class="total-spend-viewer__icons"
                            :style="getStyle(index, 10)"
                        >
                            <common-delete-dialog
                                :resource="'supplierCommitments'"
                                :resource-id="selectedSupplierCommitmentId"
                                :child-dependencies-warning="false"
                            />
                        </div>
                        <div class="total-spend-viewer__icons" :style="getStyle(index, 11)">
                            <main-expand-button
                                :is-expanded="isExpanded"
                                @expand="onSelect({ id: supplierCommitment._id })"
                            />
                        </div>

                        <funded-promotions-list-wrapper
                            v-if="!isPromotionSupplierFundingView"
                            class="total-spend-viewer__funded-promotions-list-wrapper"
                        />
                    </template>
                </vuex-form-viewer>
                <div
                    :key="`filler::${supplierCommitment._id}::${index}`"
                    class="total-spend-viewer__filler"
                    :class="fillerStyle(supplierCommitment._id)"
                />
            </template>

            <vuex-form-viewer
                v-if="isNewSupplierCommitment && !isPromotionSupplierFundingView"
                ref="newSupplierCommitmentFormViewer"
                :context="{}"
                :fields="[]"
                :form="getForm()"
            >
                <div
                    class="total-spend-viewer__icons"
                    :style="getStyle(filteredSupplierCommitmentsMap.current.totalSpend.length, 9)"
                >
                    <notes :disabled="true" />
                </div>
                <div
                    class="total-spend-viewer__icons"
                    :style="getStyle(filteredSupplierCommitmentsMap.current.totalSpend.length, 10)"
                >
                    <icon-button disabled icon="trash" />
                </div>
                <div
                    class="total-spend-viewer__icons"
                    :style="getStyle(filteredSupplierCommitmentsMap.current.totalSpend.length, 11)"
                >
                    <main-expand-button disabled :is-expanded="false" />
                </div>
                <div class="total-spend-viewer__buttons">
                    <v-btn
                        text
                        class="total-spend-viewer__buttons--cancel-button"
                        @click="onCancel"
                    >
                        {{ $t('actions.cancel') | toSentenceCase }}
                    </v-btn>
                    <simple-button :disabled="isSaveDisabled" @onClick="onSave">
                        {{ $t('actions.save') | toSentenceCase }}
                    </simple-button>
                </div>
            </vuex-form-viewer>
        </div>

        <!--grid filler when filter is empty-->
        <div
            v-if="!isPromotionSupplierFundingView && isGridFillerVisible"
            :class="['total-spend-viewer__header', 'bold']"
        >
            {{ $t('supplierCommitments.filterNotSelected') | toSentenceCase }}
        </div>

        <!--grid filler when there are no matching commitments-->
        <div
            v-else-if="!supplierCommitments.length"
            :class="['total-spend-viewer__header', 'bold']"
        >
            {{ $t('supplierCommitments.noMatchingCommitments') | toSentenceCase }}
        </div>

        <div v-if="!isPromotionSupplierFundingView">
            <div class="total-spend-viewer__bottom">
                <create-new-button
                    class="total-spend-viewer__create-button"
                    :btn-text="$tkey('supplierCommitments.buttons.newCommitment') | toSentenceCase"
                    :disabled="isCreateButtonDisabled"
                    @createNew="onCreate"
                />
            </div>

            <div class="total-spend-viewer__historical-title">
                {{ $t('supplierCommitments.historicalTitle') | toSentenceCase }}
            </div>

            <div class="total-spend-viewer total-spend-viewer__grid">
                <template
                    v-for="(supplierCommitment, index) in filteredSupplierCommitmentsMap.historical
                        .totalSpend"
                >
                    <div
                        :key="`filler::${supplierCommitment._id}`"
                        class="total-spend-viewer__filler"
                        :class="{
                            'total-spend-viewer__filler': !isPromotionSupplierFundingView,
                        }"
                    />
                    <vuex-form-viewer
                        :key="supplierCommitment._id"
                        :context="supplierCommitment"
                        :fields="fields"
                        :form="getForm(supplierCommitment)"
                        :make-read-only="selectedSupplierCommitmentId != supplierCommitment._id"
                        @click="onSelect({ id: supplierCommitment._id, isCurrent: false })"
                    >
                        <div class="total-spend-viewer__icons" :style="getStyle(index, 9)">
                            <notes
                                :notes="supplierCommitment.notes"
                                open-left
                                @delete="removeNote"
                                @post="postNote"
                                @save="saveNote"
                            />
                        </div>
                        <div class="total-spend-viewer__icons" :style="getStyle(index, 10)">
                            <common-delete-dialog
                                :resource="'supplierCommitments'"
                                :resource-id="selectedSupplierCommitmentId"
                                :child-dependencies-warning="false"
                            />
                        </div>
                        <div class="total-spend-viewer__icons" :style="getStyle(index, 11)">
                            <main-expand-button
                                :is-expanded="isExpanded"
                                @expand="onSelect({ id: supplierCommitment._id })"
                            />
                        </div>
                        <div
                            v-if="selectedSupplierCommitmentId === supplierCommitment._id"
                            class="total-spend-viewer__new-card"
                        >
                            <funded-promotions-list-wrapper
                                class="total-spend-viewer__funded-promotions-list-wrapper"
                            />
                        </div>
                    </vuex-form-viewer>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { mapState, mapActions, mapGetters } from 'vuex';
import { get, includes, isNil, isEmpty, map } from 'lodash';
import { object } from '@enums/value-types';
import supplierCommitmentTypes from '@enums/supplier-commitment-types';
import notesFieldKeys from '@enums/notes-field-keys';
import routeNames from '@enums/route-names';
import namespaces from '@enums/namespaces';
import fields from '../../../config/total-spend-viewer-fields';
import i18n from '@/js/vue-i18n';
import validators from '@/js/validators';
import { getRemainingLumpFunding } from '@/js/utils/supplier-commitment-utils';
import { getDefaultValuePropName, getDefaultValuePropValue } from '@/js/utils/component-utils';
import storeGroupsOptionsMixin from '@/js/pages/mixins/store-groups-options-mixin';
import suppliersCategoriesOptionsMixin from '../../mixins/suppliers-categories-options-mixin';

export default {
    mixins: [storeGroupsOptionsMixin, suppliersCategoriesOptionsMixin],
    props: {
        isPromotionSupplierFundingView: {
            type: Boolean,
            default: false,
        },
        promotionNamespace: String,
        promotionSupplierKey: {
            required: false,
            type: Number,
        },
        promotionCategory: {
            required: false,
            type: String,
        },
    },
    data() {
        return {
            isNewSupplierCommitment: false,
            isSaveInProgress: false,
            isExpanded: false,
            routeNames,
        };
    },
    computed: {
        ...mapState('supplierCommitments', [
            'selectedSupplierCommitmentId',
            'validationStates',
            'targetsData',
        ]),
        ...mapState('suppliers', ['suppliers']),
        ...mapState('clientConfig', ['additionalDetailsDefaultSelection']),
        ...mapGetters('supplierCommitments', [
            'filteredSupplierCommitmentsMap',
            'getSelectedSupplierCommitment',
            'getCheckboxListOptions',
            'getSupplierCommitmentsBySupplierKey',
            'getStagingAreaField',
        ]),

        fields() {
            return fields.filter(field =>
                this.isPromotionSupplierFundingView
                    ? !field.hidePromotionSupplierFundingView
                    : !field.showPromotionSupplierFundingView
            );
        },

        supplierCommitments() {
            // If we are viewing supplier commitments from the supplier funding
            // area then we should only return supplier commitments that can
            // be associated with the selected promotion. Otherwise we should
            // display all current supplier commitments.
            return this.isPromotionSupplierFundingView
                ? this.getSupplierCommitmentsBySupplierKey({
                      supplierKey: this.promotionSupplierKey,
                      category: this.promotionCategory,
                      promotionNamespace: this.promotionNamespace,
                  })
                : this.filteredSupplierCommitmentsMap.current.totalSpend;
        },

        isSaveDisabled() {
            const namespace = namespaces.default;

            return (
                this.isSaveInProgress ||
                (!this.validationStates[namespace] ||
                    (this.validationStates[namespace] && !this.validationStates[namespace].isValid))
            );
        },

        isHistoricalSupplierCommitment() {
            const historicalSupplierCommitmentIds = this.filteredSupplierCommitmentsMap.historical.totalSpend.map(
                supplierCommitment => supplierCommitment._id
            );

            return includes(historicalSupplierCommitmentIds, this.selectedSupplierCommitmentId);
        },

        isEditableField() {
            // All fields are disabled in the supplier funding / historical view.
            if (this.isPromotionSupplierFundingView || this.isHistoricalSupplierCommitment) {
                return false;
            }

            // Only planned supplier commitments that have no allocated promotions are editable.
            return !get(this.getSelectedSupplierCommitment, 'allocatedPromotionIds.length', false);
        },

        form() {
            return {
                vuexModule: 'supplierCommitments',
                context: this.getSelectedSupplierCommitment,
                editable: true,
                addEvent: 'createSupplierCommitment',
                editEvent: 'updateSupplierCommitment',
                isStagingAreaResetNeeded: false,
                fields: this.formFields,
            };
        },

        formFields() {
            return [
                {
                    fieldName: 'type',
                    defaultValue: supplierCommitmentTypes.totalSpend,
                    hidden: true,
                },
                {
                    fieldName: 'notes',
                    hidden: true,
                    defaultValue: [],
                },
                {
                    fieldName: 'allocatedPromotionIds',
                    hidden: true,
                    defaultValue: [],
                },
                {
                    fieldName: 'supplier',
                    editable: this.isEditableField,
                    restricted: true,
                    type: fieldTypes.select,
                    options: this.suppliers,
                    defaultValue: this.defaultSupplier,
                    itemValue: 'supplierKey',
                    itemText: 'name',
                    filled: true,
                    placeholder: 'supplierCommitments.placeholder.supplier',
                    returnObject: true,
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.supplierRequired'),
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'supplierKey',
                    editable: this.isEditableField,
                    hidden: true,
                },
                {
                    fieldName: 'hierarchy',
                    cssClass: 'vuex-form-viewer__grid-cell category',
                    editable: this.isEditableField,
                    restricted: true,
                    type: fieldTypes.select,
                    filled: true,
                    options: this.userCategories,
                    defaultValue: [this.categoryFromFilter],
                    itemText: 'levelEntryDescription',
                    itemValue: 'levelEntryKey',
                    returnObject: true,
                    multiple: true,
                    chips: true,
                    deletableChips: true,
                    autocomplete: true,
                    finiteList: true,
                    setter: categories =>
                        this.onSupplierCategoryChange({
                            categories,
                        }),

                    validations: [
                        {
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'scope',
                    editable:
                        !this.isPromotionSupplierFundingView &&
                        !this.isHistoricalSupplierCommitment,
                    type: fieldTypes.text,
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.scopeRequired'),
                            validator: validators.required,
                        },
                        {
                            validator: validators.maxLength,
                            params: [250],
                        },
                    ],
                },
                {
                    fieldName: 'fundingValue',
                    cssClass: 'border-right bold',
                    editable: this.isEditableField,
                    type: fieldTypes.currency,
                    validations: [
                        {
                            validator: validators.required,
                        },
                        {
                            validator: validators.minValue,
                            params: [0],
                        },
                    ],
                },
                {
                    fieldName: 'executed',
                    cssClass: 'border-right bold',
                    contextFunction: ({ context }) =>
                        this.$n('numbers.default.currencyRounded', context ? context.executed : 0),
                    defaultValue: 0,
                },
                {
                    fieldName: 'planned',
                    cssClass: 'border-right',
                    contextFunction: ({ context }) =>
                        this.$n('numbers.default.currencyRounded', context ? context.planned : 0),
                    defaultValue: 0,
                },
                {
                    cssClass: 'align-right',
                    contextFunction: ({ context }) =>
                        this.$n(
                            'numbers.default.currencyRounded',
                            getRemainingLumpFunding({ ...context })
                        ),
                },
                {
                    type: fieldTypes.dateRange,
                    cssClass: 'vuex-form__dates',
                    editable: this.isEditableField,
                    restricted: true,
                    from: {
                        fieldName: 'startDate',
                    },
                    to: {
                        fieldName: 'endDate',
                    },
                    validations: [
                        {
                            message: i18n.t('validation.supplierCommitment.datesRequired'),
                            validator: validators.required,
                        },
                        {
                            validator: validators.dateRangeRequired,
                        },
                    ],
                },
                {
                    fieldName: 'storeGroups',
                    cssClass: 'store-groups',
                    placeholder: 'supplierCommitments.placeholder.storeGroups',
                    label: 'supplierCommitments.headers.storeGroups',
                    editable:
                        !this.isPromotionSupplierFundingView &&
                        !this.isHistoricalSupplierCommitment,
                    type: fieldTypes.select,
                    options: this.storeGroupsOptions,
                    defaultValue: this.userStoreGroups,
                    needPreValidationOnSet: !this.isNewSupplierCommitment,
                    validateOnBlur: false,
                    itemText: 'description',
                    itemValue: 'key',
                    multiple: true,
                    chips: true,
                    autocomplete: true,
                    finiteList: true,
                    deletableChips: true,
                    returnObject: true,
                    validations: [
                        {
                            validator: validators.required,
                        },
                    ],
                },
                {
                    fieldName: 'promoResources',
                    type: fieldTypes.iconCheckboxesList,
                    cssClass: 'promo-resources',
                    editable: this.isEditableField,
                    options: {
                        source: sourceTypes.getter,
                        identifier: 'getCheckboxListOptions',
                        module: 'supplierCommitments',
                        params: {
                            resource: 'clientConfig',
                            getOptionsFunction: 'getPromoResources',
                        },
                    },
                    itemText: 'description',
                    itemValue: 'key',
                    valueType: object,
                    [getDefaultValuePropName(
                        this.additionalDetailsDefaultSelection.supplierCommitment.promoResources
                    )]: getDefaultValuePropValue(
                        this.additionalDetailsDefaultSelection.supplierCommitment.promoResources,
                        {
                            source: sourceTypes.getter,
                            identifier: 'getPromoResources',
                            module: 'clientConfig',
                        }
                    ),
                    validations: [
                        {
                            validator: validators.required,
                        },
                    ],
                },
            ];
        },

        isCreateButtonDisabled() {
            return this.isNewSupplierCommitment || isNil(this.categoryFromFilter);
        },

        isGridFillerVisible() {
            // If at least one filter field is not fully filled and
            // supplierCommitments is empty we return true.
            const { filterKey, filterType, filterValue } = this.$store.state.suppliersFilters[0];
            return (!filterKey || !filterType || !filterValue) && isEmpty(this.supplierCommitments);
        },

        targets() {
            const supplierCommitmentsTargets = {
                commitmentTarget: 0,
                executedTarget: 0,
                plannedTarget: 0,
            };

            if (!isEmpty(this.supplierCommitments)) {
                this.supplierCommitments.reduce((acc, sc) => {
                    acc.commitmentTarget += sc.fundingValue;
                    acc.executedTarget += sc.executed;
                    acc.plannedTarget += sc.planned;
                    return acc;
                }, supplierCommitmentsTargets);
            }

            return { ...this.targetsData, ...supplierCommitmentsTargets };
        },
        namespace() {
            return this.isNewSupplierCommitment
                ? namespaces.default
                : this.selectedSupplierCommitmentId;
        },
    },
    destroyed() {
        // Clean-up state when the user leaves the page.
        this.setSelectedSupplierCommitmentId({
            supplierCommitmentId: null,
        });
    },
    methods: {
        ...mapActions('supplierCommitments', [
            'deleteSupplierCommitment',
            'setSelectedSupplierCommitmentId',
            'addNote',
            'deleteNote',
            'updateNote',
            'setStagingAreaFields',
        ]),
        ...mapActions('promotions', ['fetchPromotionsForSupplierCommitment']),
        ...mapActions('suppliers', ['fetchSuppliersByFilterCategories']),

        fillerStyle(supplierCommitmentId) {
            if (this.isPromotionSupplierFundingView) return '';
            return this.selectedSupplierCommitmentId === supplierCommitmentId
                ? 'total-spend-viewer__filler--reseted-margin'
                : 'total-spend-viewer__filler--grey';
        },

        setClassName(field) {
            const periodClassName =
                field.header === 'period' && this.isPromotionSupplierFundingView === false
                    ? ['total-spend-viewer__header', 'last-column']
                    : ['total-spend-viewer__header', field.headerClasses];
            return periodClassName;
        },

        getStyle(index, targetGridColumn) {
            // Calculate the cell position based on the current index
            // and the target grid column.
            const numberOfGridRowsPerSupplierCommitmentRow = 2;
            if (this.isNewSupplierCommitment) {
                return {
                    'grid-row': index * numberOfGridRowsPerSupplierCommitmentRow + 4,
                    'grid-column': targetGridColumn,
                };
            }

            return {
                'grid-row': (index + 1) * numberOfGridRowsPerSupplierCommitmentRow + 2,
                'grid-column': targetGridColumn,
            };
        },

        getForm(supplierCommitment) {
            const namespace = supplierCommitment ? supplierCommitment._id : namespaces.default;

            return {
                editContext: supplierCommitment || null,
                editMode: !!supplierCommitment,
                validation: this.validationStates[namespace],
                // we need to create new default object in staging area in create mode
                // to avoid situation when 2 types of commitments merge their fields in one object
                resetStagingAreaNamespaceOnCreate: !supplierCommitment,
                ...this.form,
            };
        },

        onCreate() {
            this.isNewSupplierCommitment = true;
            this.setSelectedSupplierCommitmentId({ supplierCommitmentId: null });
            this.fetchSuppliersByFilterCategories();
        },

        onCancel() {
            this.isNewSupplierCommitment = false;
        },

        getNoteAndSupplierCommitmentId(params) {
            // If the user has selected the supplier commitment prior to the note
            // being added/updated/deleted, then we can use selectedSupplierCommitmentId
            // as the id and params as the note/noteId, otherwise we need to get
            // the id and the note/noteId from the params object.
            const note = params.event || params;
            const id = get(params, 'params.context._id', this.selectedSupplierCommitmentId);

            return {
                note,
                id,
            };
        },

        postNote(params) {
            const { note, id } = this.getNoteAndSupplierCommitmentId(params);

            this.addNote({
                note,
                id,
                fieldKey: notesFieldKeys.root,
            });
        },

        removeNote(params) {
            const { note, id } = this.getNoteAndSupplierCommitmentId(params);

            this.deleteNote({
                noteId: note,
                id,
                fieldKey: notesFieldKeys.root,
            });
        },

        saveNote(params) {
            const { note, id } = this.getNoteAndSupplierCommitmentId(params);

            this.updateNote({
                note,
                id,
                fieldKey: notesFieldKeys.root,
            });
        },

        async onSave() {
            if (this.$refs.newSupplierCommitmentFormViewer && !this.isSaveInProgress) {
                this.isSaveInProgress = true;
                const { error } = await this.$refs.newSupplierCommitmentFormViewer.submit();
                this.isSaveInProgress = false;
                if (!error) {
                    this.isNewSupplierCommitment = false;
                }
            }
        },

        async onSelect({ id, isCurrent = false }) {
            this.isNewSupplierCommitment = false;

            if (id === this.selectedSupplierCommitmentId) {
                this.setSelectedSupplierCommitmentId({
                    supplierCommitmentId: null,
                });

                this.isExpanded = false;
            } else {
                this.setSelectedSupplierCommitmentId({
                    supplierCommitmentId: id,
                });

                this.isExpanded = true;

                await this.fetchPromotionsForSupplierCommitment(id);

                if (isCurrent) {
                    // get suppliers if sc is current
                    const { hierarchy } = this.getSelectedSupplierCommitment;
                    const categoryKeys = map(hierarchy, 'levelEntryKey');
                    this.fetchSuppliersByCategories(categoryKeys);
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.total-spend-viewer {
    &__funded-promotions-list-wrapper {
        display: contents;
    }

    &__icons {
        @include flex-column;
        padding: 0.9rem 1.5rem;
        background-color: $promo-white;
    }

    &__filler {
        @include span-full-row;
        margin: 1rem 0;
        height: 1rem;

        &--grey {
            background-color: $promo-background;
        }

        &--reseted-margin {
            margin-top: 0;
            background-color: $promo-background;
        }
    }

    &__new-card {
        @include span-full-row;
        border-top: $promo-divider-colour 0.1rem solid;
        background-color: $promo-white;
    }

    &__buttons {
        @include span-full-row;
        background: $promo-white;
        padding-bottom: 1rem;
        text-align: right;
        padding-right: 2rem;

        &--cancel-button {
            color: $promo-primary-colour;
            font-weight: 600;
        }
    }

    &__create-button {
        margin-left: 1rem;
        font-size: 1.2rem;
    }

    &__bottom {
        @include span-full-row;
        margin-top: 1rem;
    }

    &__grid {
        display: grid;
        grid-template-columns:
            20rem
            20rem
            auto
            15rem
            10rem
            10rem
            10rem
            20rem
            5rem
            5rem
            5rem;
    }

    &__header {
        padding-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $promo-text-colour;
        background-color: $promo-white;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
        border-bottom: $promo-grey 0.1rem solid;

        &.last-column {
            grid-column-end: span 4;
            padding-right: 1rem;
        }

        &.select-heading {
            grid-column-end: span 3;
            text-align: center;
            padding-right: 3rem;
        }

        &.bold {
            font-weight: 600;
        }
    }

    &__historical-title {
        @include span-full-row;
        padding: 2rem 1rem 1rem;
        font-weight: 700;
        font-size: 1.4rem;
        background-color: $promo-white;
    }

    &__target {
        @include span-full-row;
    }

    ::v-deep {
        .vuex-form-viewer__edit {
            .store-groups {
                display: flex;
                grid-column: 1 / span 7;
            }

            .promo-resources {
                grid-column: span 4;
            }

            .border-right {
                border-right: $promo-grey 0.1rem solid;
                text-align: right;
                padding-right: 1rem;
            }

            .align-right {
                text-align: right;
            }

            .bold {
                font-weight: 600;
            }

            .icon-checkboxes-list-container__control {
                display: flex;
                padding: 0;
            }

            font-weight: 400;
            line-height: 3rem;
            font-size: 1.2rem;
            color: $promo-text-colour;
            letter-spacing: 0;

            .rtls-select-container {
                display: flex;

                &__label {
                    margin-right: 1rem;
                    line-height: 4rem;
                }
            }
        }

        .vuex-form-viewer__grid-cell {
            @include flex-column;
            padding: 0rem 1rem;
            background-color: $promo-white;
            font-weight: 400;
            line-height: 3rem;
            font-size: 1.2rem;
            color: $promo-text-colour;
            letter-spacing: 0;
            height: 100%;

            &.category {
                .v-select.v-select--chips.v-select--chips--small {
                    .v-chip {
                        margin: 0.2rem 0.4rem;
                    }
                    .v-select__selections {
                        min-height: 2.8rem;
                    }
                }
            }

            &.store-groups {
                padding-top: 2rem;
                grid-column: 1 / span 7;
            }

            &.allocation-checkbox {
                display: flex;
                justify-content: center;
            }

            &.promo-resources {
                padding-top: 2rem;
                grid-column: span 4;
            }

            &.border-right {
                border-right: $promo-grey 0.1rem solid;
                text-align: right;
                padding-right: 1rem;
            }

            &.align-right {
                text-align: right;
            }

            &.bold {
                font-weight: 600;
            }

            .rtls-select-container {
                display: flex;

                &__label {
                    margin-right: 1rem;
                    font-weight: 400;
                    line-height: 3rem;
                }

                .v-input--is-disabled {
                    .v-input__control > .v-input__slot:before {
                        border-image: none !important;
                        border: none !important;
                    }

                    .v-input__append-inner {
                        display: none;
                    }
                }
            }

            .icon-checkboxes-list-container__control {
                display: flex;
                padding: 0;
            }
        }

        .v-input--is-disabled {
            .v-input__control > .v-input__slot:before {
                border-image: none !important;
                border: none !important;
            }
            .v-input__append-inner {
                display: none;
            }
        }

        .vuex-form-viewer__edit {
            form > div {
                margin: 0.3rem 0 !important;
            }
        }

        .vuex-from-grid {
            & > div {
                background: #fff;
                padding: 0 1rem 1.4rem;
                height: 60%;
                margin-top: 1rem;
            }
        }

        .icon-checkboxes-list-container {
            padding: 0;
            display: flex;
            position: relative;

            .v-input--checkbox.error--text {
                padding-bottom: 1rem;
            }

            .v-messages.error--text {
                position: absolute;
                top: 3.5rem;
                left: 0.3rem;
            }
        }
    }
}
</style>
