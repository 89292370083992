'use strict';

/**
 * Enum definition file for notification types.
 *
 * @module data/enums/notification-types
 */
module.exports = {
    parentPromotionUpdated: 'parent-promotion-updated',
    requestPromotionUnlock: 'request-promotion-unlock',
    promotionUnlockRequestRejected: 'promotion-unlock-request-rejected',
    promotionUnlocked: 'promotion-unlocked',
    promotionOrphaned: 'promotion-orphaned',
    ruleValidationError: 'rule-validation-error',
};
