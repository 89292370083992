<template>
    <div v-if="isExpanded" class="summary-header volume-funding__header">
        <div class="summary-header__title">
            <label class="summary-header__label">
                {{ params.displayName }}
            </label>
        </div>
        <div class="summary-header__info">
            {{ formattedValue }}
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default Vue.extend({
    data() {
        return {
            isExpanded: true,
        };
    },
    computed: {
        ...mapGetters('promotions', ['getStagingAreaPromotionById']),
        formattedValue() {
            return this.$options.filters.totalMargin(this.totalPromotionFunding);
        },

        totalPromotionFunding() {
            const { products } = this.promotion;

            // Total promotion funding is the sum of the lump funding
            // and variable funding across each product.
            return products.reduce((fundingSum, product) => {
                const lumpFunding = get(product, 'funding.lumpFundingTotal', 0);
                const volume = get(product, 'volumes.totalVolume', 0);
                const unitFundingValue = get(
                    product,
                    'funding.variableFunding.unitFundingValue',
                    0
                );
                const unitFunding = volume * unitFundingValue;
                fundingSum += lumpFunding + unitFunding;
                return fundingSum;
            }, 0);
        },
        promotion() {
            return this.getStagingAreaPromotionById(this.params.promotionId);
        },
    },
    events: {
        onVolumeFundingCollapsed(state) {
            if (state === this.isExpanded) return;
            this.toggleCollapse();
        },
    },
    methods: {
        toggleCollapse() {
            this.isExpanded = !this.params.columnGroup.isExpanded();
            this.params.setExpanded(this.isExpanded);
        },
    },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.summary-header {
    flex-grow: 1;
    height: 100%;
    display: flex;
    padding: 0 0 0 1rem;

    flex-direction: column;
    justify-content: space-between;

    &__title {
        padding: 0;
        display: flex;
        justify-content: space-between;
    }

    &__label {
        white-space: normal;
        min-height: 3.4rem;
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background-color: $promo-table-blue-bg-colour-3;
        padding: 0.5rem;
    }

    .expand-btn {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.5rem;
    }
}
</style>
