<template>
    <div class="message-title py-2">
        <div v-if="childDependencies">
            <div class="warning-text">{{ $t('planning.warnings.warning') | toSentenceCase }}!</div>
            <div>
                {{
                    $t('planning.warnings.deletingParentWarning', {
                        entity: $tc(`entities.${resource}`, 1),
                    }) | toSentenceCase
                }}
            </div>
            <ul>
                <li v-for="(entityIds, entity) in childDependencies" :key="entity">
                    {{ $tc(`entities.${entity}`, entityIds.length) | toSentenceCase }}:
                    {{ entityIds.length }}
                    <span v-if="extraMessages[entity]">
                        ({{ $t(extraMessages[entity], { entity: $tc(`entities.${entity}`, 2) }) }})
                    </span>
                </li>
            </ul>
            <div v-if="promotionsToMoveToParkingLot">
                {{
                    $t('planning.warnings.revertingParkingLotPromotionsWarning', {
                        entity: $tc(`entities.promotions`, promotionsToMoveToParkingLot),
                        count: promotionsToMoveToParkingLot,
                    }) | toSentenceCase
                }}
            </div>
        </div>
        <div v-else>
            <div class="information-text">
                {{ $t('planning.information.information') | toSentenceCase }}!
            </div>
            <div>
                {{
                    $t('planning.information.noAssociatedChildren', {
                        entity: $tc(`entities.${resource}`, 1),
                    }) | toSentenceCase
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { get, cloneDeep, difference, has } from 'lodash';
import { promotions } from '@enums/resources';

export default {
    props: {
        resource: String,
    },
    data() {
        return {
            extraMessages: {
                [promotions]: 'planning.warnings.andAllChildEntities',
            },
        };
    },
    computed: {
        childDependencies() {
            const dependencies = cloneDeep(this.$store.state[this.resource].childDependencies);
            if (get(dependencies, 'promotionsToMoveToParkingLot')) {
                if (has(dependencies, 'promotions')) {
                    dependencies.promotions = difference(
                        dependencies.promotions,
                        dependencies.promotionsToMoveToParkingLot
                    );
                }
                delete dependencies.promotionsToMoveToParkingLot;
            }
            return dependencies && Object.keys(dependencies).length ? dependencies : null;
        },
        promotionsToMoveToParkingLot() {
            const dependencies = this.$store.state[this.resource].childDependencies;
            const promotionsToMoveToParkingLot = get(
                dependencies,
                'promotionsToMoveToParkingLot',
                []
            );
            return promotionsToMoveToParkingLot.length;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.warning-text {
    color: $negative-action;
}
.information-text {
    color: $positive-action;
}
.message-title {
    font-size: 1.4rem;
}
</style>
