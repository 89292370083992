<template>
    <div class="variable-funding-agreements-viewer">
        <!--filter bar-->
        <div class="variable-funding-agreements-viewer__filter">
            <variable-funding-filter-bar @change-categories-filter="isNewVariableFunding = false" />
        </div>
        <div class="variable-funding-agreements-viewer__grid">
            <!--grid header-->
            <template v-for="(field, index) in fields">
                <div
                    v-if="field.header && allowColumnForRelease(field)"
                    :key="`variableFundingHeader::${index}`"
                    :class="['variable-funding-agreements-viewer__header', field.headerClasses]"
                >
                    <div v-if="field.header">
                        {{
                            $t(`variableFundingAgreements.headers.${field.header}`) | toSentenceCase
                        }}
                    </div>
                </div>
            </template>
            <div class="filler" />

            <!--variable-funding-agreements-->
            <template v-for="(variableFunding, index) in getFilteredVariableFundingAgreements">
                <variable-funding-viewer
                    :key="variableFunding._id"
                    :variable-funding="variableFunding"
                    :fields="fields"
                    :index="index"
                />
                <div :key="`${variableFunding._id}_filler`" class="filler" />
            </template>

            <!--new variable-funding-->
            <variable-funding-viewer
                v-if="isNewVariableFunding"
                :fields="[]"
                @close="isNewVariableFunding = false"
            />
        </div>

        <!--grid filler when filter is empty-->
        <div
            v-if="isGridFillerVisible"
            :class="['variable-funding-agreements-viewer__header', 'bold']"
        >
            {{ $t('variableFundingAgreements.filterNotSelected') | toSentenceCase }}
        </div>

        <!--Create button-->
        <div class="variable-funding-agreements-viewer__new-funding">
            <div class="variable-funding-agreements-viewer__new-single-funding">
                <create-new-button
                    :btn-text="$tkey('variableFundingAgreements.newFundingButton') | toSentenceCase"
                    :disabled="isCreateButtonDisabled"
                    @createNew="addNewVariableFunding"
                />
            </div>
            <v-divider vertical />
            <div class="variable-funding-agreements-viewer__new-funding-bulk-upload">
                <file-upload
                    upload-id="bulk-upload-variable-funding-agreements"
                    :allowed-extensions="['.xlsx']"
                    vuex-module="variableFundingAgreements"
                    vuex-action="bulkUploadVariableFundingAgreements"
                    :on-upload="dispatch => dispatch('refreshVariableFundingAgreements')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { isNil, isEmpty } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import fields from '@/js/pages/supplier/config/variable-funding-fields';

export default {
    data() {
        return {
            fields: Object.freeze(fields),
            isNewVariableFunding: false,
        };
    },

    computed: {
        ...mapGetters('variableFundingAgreements', ['getFilteredVariableFundingAgreements']),
        ...mapGetters('suppliers', ['categoryFromFilter']),
        ...mapState('clientConfig', ['releaseFlags']),

        isGridFillerVisible() {
            // If at least one filter field is not fully filled and
            // filteredVariableFundingAgreements is empty we return true.
            const { filterKey, filterType, filterValue } = this.$store.state.suppliersFilters[0];
            return (
                (!filterKey || !filterType || !filterValue) &&
                isEmpty(this.getFilteredVariableFundingAgreements)
            );
        },

        isCreateButtonDisabled() {
            return this.isNewVariableFunding || isNil(this.categoryFromFilter);
        },
    },

    destroyed() {
        //  We need to clean state when user leaves the page
        this.setSelectedVariableFundingId(null);
    },

    methods: {
        ...mapActions('variableFundingAgreements', ['setSelectedVariableFundingId']),
        ...mapActions('suppliers', ['fetchSuppliersByFilterCategories']),
        addNewVariableFunding() {
            this.isNewVariableFunding = true;
            this.setSelectedVariableFundingId(null);
            this.fetchSuppliersByFilterCategories();
        },

        allowColumnForRelease(field) {
            if (field.releaseFlag) {
                return this.releaseFlags.releaseFlags[field.releaseFlag];
            }
            return true;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

$grid-padding: 2rem;

.variable-funding-agreements-viewer {
    padding-bottom: $grid-padding;

    &__grid {
        display: grid;
        grid-template-columns:
            20rem
            20rem
            15rem
            20rem
            20rem
            20rem
            auto
            5rem
            20rem
            4rem
            4rem
            4rem
            4rem[last-column];
        background-color: $promo-white;
    }

    &__header {
        padding-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: $promo-text-colour;
        background-color: $promo-white;
        padding-bottom: 0.3rem;
        border-bottom: 0.1rem solid $promo-grey;
        padding-left: 1rem;
        &:first-child {
            padding-left: 1.5rem;
        }
        &.last {
            grid-column-end: span 4;
            padding-right: 1.5rem;
        }
        &.bold {
            font-weight: 600;
        }
    }

    &__new-funding {
        @include flex-row;
        align-items: center;
        font-size: 1.2rem;
        border-top: $promo-grey-4 0.1rem solid;
        padding: 1rem 0;
        margin-top: 1.5rem;
    }

    &__new-single-funding {
        margin-right: 1.5rem;
    }

    &__new-funding-bulk-upload {
        margin-left: 1.5rem;
    }

    // The choose-file input is hidden from view to avoid the browser default styling, and to enable a custom label
    // which can use translated text.
    // The input cannot be completely removed from the DOM, or even hidden, otherwise the file chosen will not be sent
    // with the form request.
    &__choose-file {
        width: 0.01rem;
        height: 0.01rem;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    &__choose-file-label {
        display: inline-block;
        padding: 0 1rem;
        margin-right: 1.5rem;

        background-color: transparent;
        border: thin solid $promo-primary-colour;
        border-radius: 0.4rem;
        color: $promo-primary-colour;

        &:hover {
            cursor: pointer;
            color: $rtls-secondary-button-hover-colour;
            border-color: $rtls-secondary-button-hover-colour;
        }
    }

    &__choose-file-label-contents {
        @include flex-center;
    }

    .filler {
        @include span-full-row;
        height: 1rem;
        background-color: $promo-background;
    }
}
</style>
