/* eslint-disable import/prefer-default-export */

import sortDirection from '@enums/sort-direction';

export function sortByDate(a, b, order) {
    if (order === sortDirection.ascending) {
        return new Date(a.lastUpdatedOn) - new Date(b.lastUpdatedOn);
    }

    return new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn);
}
