import i18n from '@/js/vue-i18n';

//  function to convert numbers to thousands

function formatNumber(translationKey, num) {
    if (!num) {
        return i18n.n(translationKey);
    }
    const numberFormat = i18n.t(translationKey);
    const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: i18n.t('general.numberOptions.k') },
    ];

    let i;
    for (i = si.length - 1; i > 0; i -= 1) {
        if (Math.abs(num) >= si[i].value) {
            break;
        }
    }
    const number = Number(num / si[i].value);

    return i18n.n(numberFormat, number) + si[i].symbol;
}

export default { formatNumber };
