import FunctionProp from '@/js/utils/component-utils';
import { getPeriod } from '@/js/utils/supplier-commitment-utils';

export default [
    {
        header: 'identifier',
        name: 'identifier',
        cellClasses: ['text-bold'],
    },
    {
        header: 'description',
        name: 'description',
        cellClasses: ['text-bold'],
    },
    {
        header: 'storeGroups',
        component: {
            name: 'vuex-select',
            contextualProps: {
                options: 'storeGroups',
                model: 'storeGroups',
            },
            props: {
                disabled: true,
                multiple: true,
                chips: true,
                autocomplete: true,
                finiteList: true,
                maxItemsInList: 3,
                itemText: 'description',
                itemValue: 'key',
                setter: () => null,
            },
            functionProps: [
                new FunctionProp('getter', ['storeGroups'], ({ storeGroups }) => {
                    return () => storeGroups;
                }),
            ],
        },
    },
    {
        header: 'amount',
        name: 'amount',
        cellClasses: ['text-bold'],
        isCurrency: true,
        component: {
            name: 'vuex-currency-input',
            functionProps: [new FunctionProp('getter', ['amount'], ({ amount }) => () => amount)],
        },
    },
    {
        header: 'period',
        contextFunction: ({ effectiveFrom, effectiveTo }) => {
            return getPeriod({ startDate: effectiveFrom, endDate: effectiveTo });
        },
    },
    {
        header: '',
        component: {
            name: 'icon-button',
            props: {
                icon: 'edit',
                ignoreParentReadOnly: true,
                disabled: false,
            },
            events: {
                click: {
                    shouldEmitEvent: true,
                    name: 'edit',
                },
            },
        },
    },
    {
        header: '',
        component: {
            name: 'icon-button',
            props: {
                icon: 'trash',
                ignoreParentReadOnly: true,
                disabled: true,
            },
        },
    },
];
