var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "17px",
        height: "16px",
        viewBox: "0 0 17 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("import")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "------Icons",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Icons",
                transform: "translate(-696.000000, -190.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "import",
                    transform: "translate(696.135258, 190.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M8.00520833,2.5 C8.28125,2.5 8.5,2.71817343 8.5,2.97186347 L8.5,7.72601476 L8.45833333,8.43634686 L8.75,8.07103321 L9.41145833,7.38607011 C9.49479167,7.2947417 9.61979167,7.24907749 9.73958333,7.24907749 C9.97916667,7.24907749 10.171875,7.41651292 10.171875,7.65498155 C10.171875,7.78182657 10.125,7.87315498 10.0364583,7.95433579 L8.38541667,9.50184502 C8.26041667,9.62869004 8.13541667,9.66928044 8.00520833,9.66928044 C7.875,9.66928044 7.75520833,9.62869004 7.63020833,9.50184502 L5.97916667,7.95433579 C5.890625,7.87315498 5.84375,7.78182657 5.84375,7.65498155 C5.84375,7.41651292 6.04166667,7.24907749 6.27604167,7.24907749 C6.40104167,7.24907749 6.52083333,7.2947417 6.60416667,7.38607011 L7.265625,8.07103321 L7.55729167,8.43127306 L7.515625,7.72601476 L7.515625,2.97186347 C7.515625,2.71817343 7.734375,2.5 8.00520833,2.5 Z M5.16145833,13.5 C4.078125,13.5 3.5,12.9469557 3.5,11.9017528 L3.5,7.27444649 C3.5,6.22416974 4.078125,5.67112546 5.16145833,5.67112546 L6.484375,5.67112546 L6.484375,6.71125461 L5.24479167,6.71125461 C4.80729167,6.71125461 4.56770833,6.92942804 4.56770833,7.37592251 L4.56770833,11.795203 C4.56770833,12.2467712 4.80729167,12.4598708 5.24479167,12.4598708 L10.7552083,12.4598708 C11.1927083,12.4598708 11.4322917,12.2467712 11.4322917,11.795203 L11.4322917,7.37592251 C11.4322917,6.92942804 11.1927083,6.71125461 10.7552083,6.71125461 L9.53125,6.71125461 L9.53125,5.67112546 L10.84375,5.67112546 C11.9270833,5.67112546 12.5,6.22924354 12.5,7.27444649 L12.5,11.9017528 C12.5,12.9469557 11.9270833,13.5 10.84375,13.5 L5.16145833,13.5 Z",
                      fill: "#2F477C",
                      "fill-rule": "nonzero",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }