import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "promo-resource-tab-section" }, [
      _c(
        "div",
        { staticClass: "promo-resource-tab-section__container" },
        [
          _c("document-scroller", {
            staticClass: "document-scroller",
            attrs: {
              documents: _vm.resourceDefinitions,
              "selected-document-key": _vm.selectedResourceDefinitionKey,
              "num-documents-to-show": 4,
            },
            on: { "document-selected": _vm.documentSelectedHandler },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "promo-resource-tab-section__container--right",
              class: { "disabled-text": !_vm.selectedResourceDefinitionKey },
            },
            [
              _c("strong", [
                _vm._v(
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("preparation.documentPerformance")
                    )
                  ) + ":"
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$n(
                      "numbers.default.percentage",
                      _vm.documentPercentagePerformance
                    )
                  )
                ),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("preparation.complete")) +
                  "\n            "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promo-resource-tab-section__sidebar-content" },
        [
          _c(
            "div",
            {
              staticClass: "promo-resource-tab-section__sidebar-header--title",
              class: { "disabled-text": !_vm.selectedResourceDefinitionKey },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("preparation.incrSalesEfficiency")
                    )
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.selectedResourceDefinitionKey
            ? _c("losenge-monetary-display", {
                attrs: {
                  "incremental-amount":
                    _vm.selectedDocumentPerformance.aggregatedSales,
                  efficiency: _vm.$n(
                    "numbers.default.currencyNoLabel",
                    _vm.selectedDocumentPerformance.aggregatedSalesEfficiency
                  ),
                  compact: true,
                },
              })
            : _c("span", [_vm._v("--")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "promo-resource-tab-section__sidebar-content" },
        [
          _c(
            "div",
            {
              staticClass: "promo-resource-tab-section__sidebar-header--title",
              class: { "disabled-text": !_vm.selectedResourceDefinitionKey },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("preparation.incrMarginEfficiency")
                    )
                  ) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.selectedResourceDefinitionKey
            ? _c("losenge-monetary-display", {
                attrs: {
                  "incremental-amount":
                    _vm.selectedDocumentPerformance.aggregatedMargin,
                  efficiency: _vm.$n(
                    "numbers.default.currencyNoLabel",
                    _vm.selectedDocumentPerformance.aggregatedMarginEfficiency
                  ),
                  compact: true,
                },
              })
            : _c("span", [_vm._v("--")]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.selectedResourceDefinition
      ? _c("div", { staticClass: "promo-resource-tab-section" }, [
          _c(
            "div",
            { staticClass: "promo-resource-tab-section__container" },
            [
              _c("promo-resources-toolbar", {
                attrs: {
                  "vuex-module": "subCampaigns",
                  namespace: _vm.selectedSubCampaign._id,
                  "field-name": "resourceDefinitions",
                  "make-read-only": _vm.promoResourceEditingState.disabled,
                  "read-only-reason": _vm.promoResourceEditingState.reason,
                },
              }),
              _vm._v(" "),
              !_vm.isSlotsResource
                ? _c(
                    "div",
                    {
                      staticClass:
                        "promo-resource-tab-section__container--right",
                      class: {
                        "disabled-text": !_vm.selectedResourceDefinitionPage,
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toSentenceCase")(
                              _vm.$t("preparation.pagePerformance")
                            )
                          ) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$n(
                              "numbers.default.percentage",
                              _vm.pagePerformance
                                ? _vm.pagePerformance.assignedAreasPerformance
                                : 0
                            )
                          )
                        ),
                      ]),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("preparation.complete")) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSlotsResource
            ? _c(
                "div",
                { staticClass: "promo-resource-tab-section__sidebar-content" },
                [
                  _vm.selectedResourceDefinitionPage
                    ? _c("losenge-monetary-display", {
                        attrs: {
                          "incremental-amount":
                            _vm.pagePerformance.incrementalSales,
                          efficiency: _vm.$n(
                            "numbers.default.currencyNoLabel",
                            _vm.pagePerformance.salesEfficiency
                          ),
                          compact: true,
                        },
                      })
                    : _c("span", [_vm._v("--")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSlotsResource
            ? _c(
                "div",
                { staticClass: "promo-resource-tab-section__sidebar-content" },
                [
                  _vm.selectedResourceDefinitionPage
                    ? _c("losenge-monetary-display", {
                        attrs: {
                          "incremental-amount":
                            _vm.pagePerformance.incrementalMargin,
                          efficiency: _vm.$n(
                            "numbers.default.currencyNoLabel",
                            _vm.pagePerformance.marginEfficiency
                          ),
                          compact: true,
                        },
                      })
                    : _c("span", [_vm._v("--")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.promoResourceEditingState.disabled
      ? _c(
          "div",
          { staticClass: "promo-resource__locked" },
          [
            _c("alert-banner", {
              staticClass: "promo-resource__locked-msg",
              attrs: { reason: _vm.promoResourceEditingState.reason },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "promo-resource-tab-section", class: _vm.promotionsClass },
      [
        _c("div", { staticClass: "page-layout-container" }, [
          _c(
            "div",
            { staticClass: "page-layout-left-column" },
            [
              _vm.selectedResourceDefinitionKey
                ? _c("page-layout", {
                    attrs: {
                      namespace: _vm.selectedSubCampaign._id,
                      "vuex-module": "subCampaigns",
                      "field-name": "resourceDefinitions",
                      "promo-resource": _vm.promoResource,
                      "make-read-only": _vm.promoResourceEditingState.disabled,
                      "read-only-reason": _vm.promoResourceEditingState.reason,
                    },
                    on: { "auto-save": _vm.debouncedSaveResourceProvisions },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedResourceDefinitionPage
                ? _c(
                    "div",
                    [
                      _c("vuex-textarea", {
                        staticClass: "page-brief",
                        attrs: {
                          getter: _vm.pageBriefGetter,
                          setter: _vm.pageBriefSetter,
                          label: _vm.briefAreaLabel,
                          placeholder: _vm.briefAreaPlaceholder,
                          "make-read-only": _vm.isPageBriefReadOnly,
                          "read-only-reason":
                            _vm.promoResourceEditingState.reason,
                        },
                        on: { blur: _vm.debouncedSaveResourceProvisions },
                        model: {
                          value: _vm.selectedResourceDefinitionPage.pageBrief,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.selectedResourceDefinitionPage,
                              "pageBrief",
                              $$v
                            )
                          },
                          expression:
                            "selectedResourceDefinitionPage.pageBrief",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(VDivider, {
          staticClass: "promo-resource-tab-section__divider",
          attrs: { vertical: "" },
        }),
        _vm._v(" "),
        _vm.isCategoriesSection &&
        _vm.isPromoResourceWithCategoriesSection({
          promoResource: _vm.promoResource,
        })
          ? _c("categories-allocation-area", {
              attrs: {
                "make-read-only": _vm.promoResourceEditingState.disabled,
                "read-only-reason": _vm.promoResourceEditingState.reason,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showPromotionsAllocationArea
          ? _c("promotion-allocation-area", {
              attrs: {
                "make-read-only": _vm.promoResourceEditingState.disabled,
                "read-only-reason": _vm.promoResourceEditingState.reason,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.canSignOffLeaflet
      ? _c("div", { staticClass: "promo-resource-tab-section" }, [
          _c(
            "div",
            { staticClass: "workflow-tasks-dropdown" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("toSentenceCase")(
                      _vm.$t("preparation.leafletSignOff")
                    )
                  ) +
                  "\n            "
              ),
              _c("task-buttons-dropdown", {
                attrs: {
                  tasks: _vm.workflowTasksList,
                  "entity-type": _vm.workflowEntities.leaflet,
                  "entity-ids": [_vm.selectedResourceDefinitionKey],
                  "sub-campaign-id": _vm.selectedSubCampaignId,
                  "entity-instance": _vm.selectedResourceDefinition,
                  "owning-entity-id": _vm.selectedSubCampaignId,
                  categories: _vm.selectedSubCampaign.categories,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "promo-resource-tab-section margin-top" }, [
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "feature-toggle",
            { attrs: { toggle: _vm.canEditChannel } },
            [
              _c("copy-resource-definition-dialog", {
                attrs: {
                  namespace: _vm.selectedSubCampaign._id,
                  "promo-resource": _vm.promoResource,
                  "vuex-module": "subCampaigns",
                  "field-name": "resourceDefinitions",
                  "make-read-only": _vm.promoResourceEditingState.disabled,
                  "read-only-reason": _vm.promoResourceEditingState.reason,
                },
              }),
              _vm._v(" "),
              _c(VDivider, { staticClass: "horizontal-divider" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "feature-toggle",
            { attrs: { toggle: _vm.showJsonExportButton } },
            [
              _c("download-resource-button", {
                attrs: {
                  resource: _vm.ResourcesEnum.subCampaigns,
                  format: _vm.ExportFormats.json,
                  "text-btn": true,
                  label: _vm.$t("preparation.buttons.exportDocument"),
                  "download-action": _vm.downloadAction,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("download-resource-button", {
            staticClass: "promo-resource-tab__download-btn",
            attrs: {
              "is-secondary-btn": true,
              resource: _vm.ResourcesEnum.subCampaigns,
              format: _vm.ExportFormats.excel,
              "text-btn": true,
              label: _vm.$t("preparation.buttons.export"),
              "download-action": _vm.exportPreparation,
              "export-schema": _vm.exportSchema,
              "extra-params": _vm.extraParams,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }