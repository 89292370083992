var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "promotion-dates",
    _vm._b(
      { attrs: { "cache-dom": _vm.cacheDom } },
      "promotion-dates",
      _vm.form,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }