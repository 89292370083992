<template>
    <div class="promotion-forecasting">
        <span class="font-weight-bold">{{
            $tkey(`header.${hasActuals ? 'actual' : 'forecast'}`) | toSentenceCase
        }}</span>
        <v-divider />
        <div class="promotion-forecasting--column-divider">
            <span class="forecast-date">
                {{ $tkey('header.lastForecastDate') | toSentenceCase }}:
                <span class="font-weight-bold">
                    {{ lastForecastTime }}
                </span>
            </span>
            <forecast-promotion-button
                v-if="!hasActuals"
                :promotion="promotion"
                :disabled="isDisabled || isPastPromotions"
            />
        </div>
        <v-divider />
        <div class="metrics">
            <template v-for="(metric, ix) in metricFields">
                <template v-if="get(releaseFlags, ['releaseFlags', metric.releaseFlag], true)">
                    <v-divider
                        v-if="metric.isDivider"
                        :key="`metric--${ix}--divider`"
                        class="metrics__cell metrics__divider"
                    />
                    <template v-else>
                        <div
                            :key="`metric--${metric.nameKey}--type`"
                            class="metrics__cell metrics__type"
                        >
                            {{ $tkey(`metrics.${metric.nameKey}`) | toSentenceCase }}
                        </div>
                        <div
                            :key="`metric--${metric.nameKey}--value`"
                            class="metrics__cell metrics__value font-weight-bold"
                        >
                            {{
                                formattedValue(
                                    metric.numberFormatter.type,
                                    aggregations[metric.field]
                                )
                            }}
                        </div>
                    </template>
                </template>
            </template>
        </div>
        <forecast-details-dialog ref="forecast-details-dialog" :getter="() => promotion.products" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { get, isEmpty, isNil } from 'lodash';
import namespaces from '@enums/namespaces';
import metricFields from '../config/promotion-forecasting-fields';

export default {
    localizationKey: 'planning.promotionsMaintenance.forecasting',
    props: {
        promotion: {
            type: Object,
            required: true,
        },
        isPastPromotions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            metricFields: Object.freeze(metricFields),
        };
    },
    computed: {
        ...mapState('clientConfig', ['releaseFlags']),
        ...mapState('promotions', ['unsavedPromotion']),
        aggregations() {
            return get(this.promotion, 'forecastingAggregations.promotion', {});
        },
        lastForecastTime() {
            const lastForecastDate = get(this.promotion, 'lastForecastDate', null);
            if (lastForecastDate) {
                return this.$options.filters.timeStamp(lastForecastDate);
            }
            return '';
        },
        hasProducts() {
            return !isEmpty(this.promotion.products);
        },
        isPromotionUnsaved() {
            const tabValues = Object.values(this.unsavedPromotion[this.promotion._id] || {});
            return tabValues.filter(v => v).length > 0;
        },
        isDisabled() {
            return (
                this.readOnly ||
                this.isPromotionUnsaved ||
                !this.hasProducts ||
                isNil(this.promotion._id) ||
                this.promotion._id === namespaces.default
            );
        },
        hasActuals() {
            return !!get(this.promotion, 'actuals.promotion');
        },
    },
    methods: {
        get,
        openDetailsDialog() {
            this.$refs['forecast-details-dialog'].openDialog();
        },
        formattedValue(type, field) {
            return this.$options.filters[type](field);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@style/base/_mixins.scss';
@import '@style/base/_variables.scss';

.promotion-forecasting {
    @include flex-column;

    padding: 0 0.5rem;
}

.metrics {
    display: grid;
    grid-template-columns: 50% 50%;

    &__cell {
        padding: 0.3rem 0;
    }

    &__type {
        text-align: right;
    }

    &__value {
        text-align: right;
        padding-right: 3rem;
    }

    &__divider {
        grid-column: span 2;
    }
}
</style>

<style lang="scss">
.promotion-forecasting {
    button {
        margin: 0.5rem 0.5rem;
    }

    &--column-divider {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .forecast-date {
        width: 10rem;
        margin-right: 2.5rem;

        span {
            display: flex;
            flex-direction: column;
        }
    }

    .v-divider {
        &:not(.metrics__divider) {
            margin: 0.5rem 0;
        }
    }
}
</style>
