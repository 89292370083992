<template>
    <div class="promotion-products-sidebar">
        <div
            v-if="!candidate.products"
            class="promotion-products-sidebar__inner"
            :class="{ 'disabled-color': isDisabledColor }"
        >
            <losenge-monetary-display
                :incremental-amount="getMetrics({ product: candidate }).incrementalAmount"
                :efficiency="getMetrics({ product: candidate }).efficiency"
                compact
            />
        </div>
        <div v-else-if="showPgMetrics">
            <div class="product-group-filler promotion-products-sidebar__filler" />
            <div
                v-for="(product, index) of candidate.products"
                :key="index + product.productKey"
                class="promotion-products-sidebar__inner promotion-products-sidebar__ppg"
                :class="{ 'disabled-color': isDisabledColor }"
            >
                <losenge-monetary-display
                    :incremental-amount="getMetrics({ product }).incrementalAmount"
                    :efficiency="getMetrics({ product }).efficiency"
                    compact
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { get } from 'lodash';

export default {
    props: {
        listNamespace: {
            type: String,
            required: true,
        },
        candidate: {
            type: Object,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        isDisabledColor: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState('promotionCandidates', ['productGroupOpenState']),
        showPgMetrics() {
            return (this.productGroupOpenState[this.listNamespace] || {})[this.candidate._id];
        },
    },
    methods: {
        getMetrics({ product }) {
            const incrementalAmountField =
                this.field === 'sales' ? 'incrementalSalesExcTax' : 'incrementalMargin';
            const efficiencyField = this.field === 'sales' ? 'salesEfficiency' : 'marginEfficiency';

            return {
                incrementalAmount: get(
                    product,
                    `forecastingAggregations.product.${incrementalAmountField}`
                ),
                efficiency: get(product, `forecastingAggregations.product.${efficiencyField}`),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.promotion-products-sidebar {
    height: 100%;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding-left: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
    }

    &__ppg {
        border-top: 0.1rem solid $promo-campaign-border-3;
        height: 5rem;
    }

    .disabled-color {
        color: $promo-grey;
        font-weight: 300;
    }

    &__filler {
        height: 6.8rem;
    }
}
</style>
