var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        width: "21px",
        height: "18px",
        viewBox: "0 0 21 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Group 19")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c("defs", [
        _c("path", {
          attrs: {
            id: "path-1",
            d: "M18.000857,18 C18.4317806,18 18.8235294,17.7593261 18.8235294,17.1817088 L18.8235294,4.377858 C18.4121932,3.27075812 16.3163371,2 13.7993512,2 C11.7622575,2 9.99938789,2.83754513 9.41176471,3.61732852 C8.82414152,2.83754513 7.06127196,2 5.02417825,2 C2.49739854,2 0.401542511,3.27075812 0,4.377858 L0,17.1817088 C0,17.7593261 0.381955071,18 0.812878742,18 C1.16545265,18 1.37112077,17.8941035 1.57678888,17.7304452 C2.04688743,17.2779783 3.20254637,16.5848375 5.02417825,16.5848375 C6.84581012,16.5848375 8.09940626,17.2587244 8.52053621,17.6823105 C8.7164106,17.8459687 8.97104732,18 9.41176471,18 C9.84268838,18 10.0875314,17.8363418 10.2931995,17.6823105 C10.7339169,17.2779783 11.9777193,16.5848375 13.7993512,16.5848375 C15.6111893,16.5848375 16.776642,17.2876053 17.2467405,17.7304452 C17.4426149,17.8941035 17.6580768,18 18.000857,18 Z M1.28297729,16.4981949 L1.28297729,4.75330927 C1.44947053,4.21419976 2.88914733,3.17448857 5.02417825,3.17448857 C7.15920916,3.17448857 8.62826712,4.21419976 8.77517292,4.75330927 L8.77517292,16.4693141 C7.9231193,15.7858002 6.53241109,15.410349 5.02417825,15.410349 C3.5159454,15.410349 2.12523719,15.7858002 1.28297729,16.4981949 Z M17.5405521,16.4981949 C16.6884985,15.7858002 15.307584,15.410349 13.7993512,15.410349 C12.2813246,15.410349 10.9004101,15.7858002 10.0483565,16.4693141 L10.0483565,4.75330927 C10.1952623,4.21419976 11.6643203,3.17448857 13.7993512,3.17448857 C15.9343821,3.17448857 17.3740589,4.21419976 17.5405521,4.75330927 L17.5405521,16.4981949 Z",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Planner",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "0.17-Planner-(Date-configure-time)",
                transform: "translate(-1189.000000, -338.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Promotion-bar",
                    transform: "translate(46.000000, 280.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "tabs" } }, [
                    _c(
                      "g",
                      {
                        attrs: { transform: "translate(0.000000, 55.000000)" },
                      },
                      [
                        _c("g", { attrs: { id: "Group-28" } }, [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Group-19",
                                transform: "translate(1143.000000, 3.000000)",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Book",
                                    "fill-rule": "nonzero",
                                    fill: "#2F477C",
                                  },
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#path-1" },
                                  }),
                                  _vm._v(" "),
                                  _c("use", {
                                    attrs: { "xlink:href": "#path-1" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("circle", {
                                attrs: {
                                  id: "Oval",
                                  fill: "#D7534E",
                                  cx: "17",
                                  cy: "4",
                                  r: "4",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }