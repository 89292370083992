<template>
    <div class="page-navigator">
        <div :class="flexClass">
            <feature-toggle :toggle="canEditChannel">
                <vuex-select
                    :class="dropDownCss"
                    :placeholder="$t('actions.selectTemplate') | toSentenceCase"
                    :options="filteredPromoResourceTemplates"
                    item-value="templateName"
                    item-text="translatedTemplateName"
                    :getter="getTemplateName"
                    :setter="templateName => setSelectedPromoResourceTemplateName(templateName)"
                    filled
                />
                <confirm-dialog
                    ref="change-template-confirmation-dialog"
                    :question-text="$t('dialogs.changeResourceDefinitionTemplate.heading')"
                    :action-text="$t('dialogs.changeResourceDefinitionTemplate.confirm')"
                    :warning-text="$t('dialogs.changeResourceDefinitionTemplate.message')"
                    :has-activator="false"
                    @confirm="updatePromoResourceTemplate"
                    @close="resetPromoResourceTemplateDropDown"
                />
            </feature-toggle>
            <div class="flex-row">
                <template v-if="isPagesResource">
                    <v-btn-toggle
                        :value="activeDocumentSection"
                        mandatory
                        class="page-navigator__section-toggle"
                        @change="changeActiveSection"
                    >
                        <v-btn secondary :disabled="isTemplatesDisabled">
                            {{ $t('preparation.templates.templates') | toSentenceCase }}
                        </v-btn>
                        <v-btn secondary :disabled="isCategoriesDisabled">
                            {{ $t('preparation.templates.categories') | toSentenceCase }}
                        </v-btn>
                        <v-btn secondary :disabled="isPromotionsDisabled">
                            {{ $t('preparation.templates.promotions') | toSentenceCase }}
                        </v-btn>
                    </v-btn-toggle>
                    <page-navigator
                        :vuex-module="vuexModule"
                        :namespace="namespace"
                        :field-name="fieldName"
                    />
                </template>
                <slots-selector
                    v-if="isSlotsResource"
                    :vuex-module="vuexModule"
                    :namespace="namespace"
                    :field-name="fieldName"
                />
                <div class="page-navigator__notifications-icon">
                    <notes
                        :notes="notes"
                        :disabled="isNotesDisabled"
                        open-right
                        @delete="removeNote"
                        @post="postNote"
                        @save="saveNote"
                    />
                </div>
                <feature-toggle :toggle="canEditChannel">
                    <template v-if="isLeafletResource">
                        <div class="page-navigator__buttons">
                            <task-button
                                :task="workflowTasks.submit"
                                :entity-type="workflowEntities.leaflet"
                                :entity-ids="[selectedResourceDefinitionKey]"
                                :owning-entity-id="selectedSubCampaignId"
                                :sub-campaign-id="selectedSubCampaignId"
                                :categories="selectedSubCampaign.categories"
                                short-text
                            />
                            <workflow-state
                                v-if="generalConfig.showWorkflowDebugIcon"
                                :workflow-entity="workflowEntities.leaflet"
                                :entity-id="selectedResourceDefinitionKey"
                                :owning-entity-id="selectedSubCampaignId"
                            />
                        </div>
                    </template>
                </feature-toggle>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { sortBy, filter, find, findIndex, cloneDeep } from 'lodash';
import NotesFieldKeys from '@enums/notes-field-keys';
import promoResources from '@enums/promo-resources';
import workflowEntities from '@enums/workflow-entities';
import workflowTasks from '@enums/workflow-tasks';
import { canEditChannel } from '@enums/feature-flags';
import vuexComponentMixin from '@/js/mixins/vuex-component';

export default {
    mixins: [vuexComponentMixin],
    data() {
        return {
            selectedPromoResourceTemplateName: null,
            workflowTasks,
            workflowEntities,
            canEditChannel,
        };
    },
    computed: {
        ...mapState('clientConfig', ['generalConfig', 'promoResourceTemplates']),
        ...mapState('subCampaigns', [
            'activeDocumentSection',
            'selectedSubCampaignId',
            'selectedResourceDefinitionKey',
            'selectedResourceDefinitionPageNumber',
        ]),
        ...mapGetters('subCampaigns', [
            'selectedResourceDefinition',
            'selectedResourceDefinitionPage',
            'selectedSubCampaign',
            'isSlotsResource',
            'isPagesResource',
        ]),
        ...mapGetters('clientConfig', ['templateLayoutsByKey']),
        isCategoriesDisabled() {
            return (
                !this.selectedResourceDefinitionPage ||
                (this.selectedResourceDefinitionPage &&
                    !this.selectedResourceDefinitionPage.layoutTemplate)
            );
        },
        isLeafletResource() {
            return this.selectedResourceDefinition.resourceKey === promoResources.leaflet;
        },

        isPromotionsDisabled() {
            return this.isCategoriesDisabled;
        },
        isTemplatesDisabled() {
            return this.isCategoriesDisabled && this.isPromotionsDisabled;
        },

        notes() {
            return this.selectedResourceDefinitionPage
                ? sortBy(this.selectedResourceDefinitionPage.notes, 'createdOn')
                : [];
        },

        isNotesDisabled() {
            // notes are enabled just for pages save in DB
            // try to find selectedResourceDefinition and selected page in original subCampaign object
            const selectedResourceDefinition = this.selectedSubCampaign.resourceDefinitions.find(
                rd => rd.key === this.selectedResourceDefinitionKey
            );
            if (selectedResourceDefinition) {
                return !selectedResourceDefinition.pages.find(
                    page => page.pageNumber === this.selectedResourceDefinitionPageNumber
                );
            }
            return true;
        },

        filteredPromoResourceTemplates() {
            // Get all resource templates for the selected resource key.
            let templates = filter(this.promoResourceTemplates, {
                resourceKey: this.selectedResourceDefinition.resourceKey,
            });

            // Append with the translated template names.
            templates = templates.map(template => {
                return {
                    ...template,
                    translatedTemplateName: this.$options.filters.toSentenceCase(
                        this.$t(`preparation.templates.${template.templateName}`)
                    ),
                };
            });

            // only add custom option, if the current template was modified
            if (this.selectedResourceDefinition.templateName === 'custom') {
                templates.push({
                    templateName: this.selectedResourceDefinition.templateName,
                    translatedTemplateName: this.$options.filters.toSentenceCase(
                        this.$t(`preparation.templates.custom`)
                    ),
                });
            }

            return templates;
        },

        flexClass() {
            return this.isPagesResource ? 'flex-column' : 'flex-row';
        },

        dropDownCss() {
            const pageResourceTemplateDropDownCss = this.isPagesResource
                ? 'page-navigator__template-drop-down--is-page-resource'
                : '';

            return ['page-navigator__template-drop-down', pageResourceTemplateDropDownCss];
        },
    },
    methods: {
        ...mapMutations('subCampaigns', [
            'setActiveDocumentSection',
            'setSelectedResourceDefinitionPageNumber',
        ]),
        ...mapMutations('promotions', ['setSelectedPromotionId']),
        ...mapActions('subCampaigns', ['addNote', 'deleteNote', 'updateNote']),
        changeActiveSection(activeSection) {
            this.setActiveDocumentSection(activeSection);
            this.globalEmit('active-document-section-updated', activeSection);
        },

        postNote(note) {
            this.addNote({
                note,
                id: this.selectedSubCampaignId,
                fieldKey: NotesFieldKeys.pages,
                parentId: this.selectedResourceDefinitionPage.key,
                updateFieldNotesCopy: true,
            });
        },
        removeNote(noteId) {
            this.deleteNote({
                noteId,
                id: this.selectedSubCampaignId,
                fieldKey: NotesFieldKeys.pages,
                parentId: this.selectedResourceDefinitionPage.key,
                updateFieldNotesCopy: true,
            });
        },
        saveNote(note) {
            this.updateNote({
                note,
                id: this.selectedSubCampaignId,
                fieldKey: NotesFieldKeys.pages,
                parentId: this.selectedResourceDefinitionPage.key,
                updateFieldNotesCopy: true,
            });
        },
        getTemplateName() {
            const { templateName } = this.selectedResourceDefinition;
            return this.selectedPromoResourceTemplateName || templateName;
        },
        setSelectedPromoResourceTemplateName(value) {
            if (value !== this.selectedResourceDefinition.templateName) {
                this.selectedPromoResourceTemplateName = value;
                this.$refs['change-template-confirmation-dialog'].open();
            }
        },
        updatePromoResourceTemplate() {
            const selectedPromoResourceTemplate = find(this.promoResourceTemplates, {
                templateName: this.selectedPromoResourceTemplateName,
                resourceKey: this.selectedResourceDefinition.resourceKey,
            });

            const newResourceDefinition = {
                ...cloneDeep(selectedPromoResourceTemplate),
                storeGroups: this.selectedResourceDefinition.storeGroups,
                key: this.selectedResourceDefinition.key,
                resourceKey: this.selectedResourceDefinition.resourceKey,
                workflowState: this.selectedResourceDefinition.workflowState,
            };

            // Replace existing resourceDefinition in the staging area with the
            // new template.
            const selectedResourceDefinitionIndex = findIndex(
                this.selectedSubCampaign.resourceDefinitions,
                rd => rd.key === this.selectedResourceDefinitionKey
            );

            this.$set(
                this.$store.state.subCampaigns.stagingArea[this.selectedSubCampaignId]
                    .resourceDefinitions,
                selectedResourceDefinitionIndex,
                newResourceDefinition
            );

            if (!this.isPagesResource && this.selectedResourceDefinition.pages.length) {
                const slots = this.selectedResourceDefinition.pages[0].slots;
                this.globalEmit('slots-updated', { slots });
            } else {
                this.globalEmit('page-grid-updated');
            }

            this.setSelectedPromotionId(null);
            this.setSelectedResourceDefinitionPageNumber(1);

            // Emit an event to automatically save the channel provisions on update
            // of the document template.
            this.globalEmit('promo-resource-template-change');
        },

        resetPromoResourceTemplateDropDown() {
            this.selectedPromoResourceTemplateName = this.selectedResourceDefinition.templateName;
        },
    },

    events: {
        onPromoResourceDocumentChange() {
            this.resetPromoResourceTemplateDropDown();
        },
        onUpdatePageCount() {
            this.resetPromoResourceTemplateDropDown();
        },
        onChangeTemplate() {
            this.resetPromoResourceTemplateDropDown();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.page-navigator {
    border-top: $component-border-divider;
    padding: 2rem 4.5rem 2.5rem;
    height: 100%;
    @include flex-row;
    justify-content: flex-start;

    &__notifications-icon {
        padding-left: 3rem;
        padding-top: 0.2rem;
    }

    &__buttons {
        padding-top: 0.2rem;
    }

    &__template-drop-down {
        width: 25rem;

        &--is-page-resource {
            margin-bottom: 2rem;
        }
    }
}
</style>
