import agreementTypes from '@enums/agreement-types';
import agreementFunctions from '@enums/invoice-calculation-types';
import applicableToOptions from '@enums/agreements-applicable';
import { getPeriod, getCategoryNames } from '@/js/utils/supplier-commitment-utils';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';

function getSupplierKeyName({ supplier: { clientSupplierKey, name } }) {
    return `${clientSupplierKey} - ${name}`;
}

function getAgreementType({ agreementType }) {
    return toSentenceCase(
        i18n.t(`variableFundingAgreements.agreementTypes.${agreementTypes[agreementType]}`)
    );
}

function getAgreementFunction({ agreementFunction }) {
    const functionType = agreementFunction
        ? agreementFunctions[agreementFunction]
        : agreementFunctions.noInvoice;
    return toSentenceCase(i18n.t(`variableFundingAgreements.agreementFunctions.${functionType}`));
}

function getApplicableTo({ applicableTo }) {
    return toSentenceCase(
        i18n.t(`variableFundingAgreements.applicableTo.${applicableToOptions[applicableTo]}`)
    );
}

export default [
    {
        header: 'supplierName',
        contextFunction: getSupplierKeyName,
    },
    {
        header: 'hierarchyLevel',
        cellClasses: ['category'],
        contextFunction: getCategoryNames,
    },
    {
        header: 'mechanic',
        name: 'mechanic',
    },
    {
        header: 'agreementType',
        contextFunction: getAgreementType,
        cellClasses: ['agreement'],
    },
    {
        header: 'agreementFunction',
        contextFunction: getAgreementFunction,
        cellClasses: ['agreement'],
    },
    {
        header: 'applicableTo',
        contextFunction: getApplicableTo,
    },
    {
        header: 'scope',
        cellClasses: ['scope'],
        component: {
            name: 'scope',
            value: 'scope',
            computedProps: [
                {
                    name: 'scope',
                    executor: 'getScope',
                },
            ],
        },
    },
    {
        header: 'value',
        cellClasses: ['value'],
    },
    {
        header: 'period',
        contextFunction: getPeriod,
    },
    {
        header: 'sellInPeriod',
        headerClasses: ['last'],
        cellClasses: ['sellInPeriod'],
        name: 'sellInPeriod.daysBefore',
    },
    {
        header: '',
        cellClasses: ['sellInPeriod'],
        name: 'sellInPeriod.daysAfter',
    },
    {
        component: {
            name: 'main-expand-button',
            props: {
                isExpanded: false,
            },
        },
    },
    {
        component: {
            name: 'icon-button',
            props: {
                icon: 'trash',
                disabled: true,
            },
        },
    },
];
