'use strict';

/**
 * Enum definition for promotion states in the parking lot.
 *
 * @type {{available: string, planned: string, executed: string}}
 */
module.exports = {
    available: 'available',
    planned: 'planned',
    executed: 'executed',
};
