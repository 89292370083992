<template>
    <simple-button :disabled="disabled" @onClick="forecastPromotion(promotion)">
        {{
            isNewForecast
                ? $t('forecasting.actions.forecastNewPromotion')
                : $t('forecasting.actions.forecastPromotion') | toSentenceCase
        }}
    </simple-button>
</template>

<script>
import { get } from 'lodash';
import { to } from 'await-to-js';
import { mapActions } from 'vuex';

export default {
    props: {
        promotion: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isNewForecast() {
            return !get(this.promotion, 'lastForecastDate', null);
        },
    },
    methods: {
        ...mapActions('forecasting', ['forecastSinglePromotion']),

        async forecastPromotion(promotion) {
            const [err] = await to(this.forecastSinglePromotion({ promotion }));
            if (err) return console.error(err); // TODO: better error handling
        },
    },
};
</script>
