'use strict';

module.exports = {
    fieldTypes: {
        text: 'text',
        number: 'number',
        textarea: 'textarea',
        select: 'select',
        toggle: 'toggle',
        date: 'date',
        datePair: 'datePair',
        dateRange: 'dateRange',
        checkboxesList: 'checkboxesList',
        iconCheckboxesList: 'iconCheckboxesList',
        vuexRadioGroup: 'vuexRadioGroup',
        comboBox: 'comboBox',
        currency: 'currency',
    },
    sourceTypes: {
        context: 'context',
        getter: 'getter',
        editContext: 'editContext',
        contextFunction: 'contextFunction',
    },
};
