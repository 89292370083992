<template>
    <create-resource-btn
        ref="createResourceBtn"
        v-bind="form"
        :context="selectedScenario"
        :btn-text="btnText"
        :before-create-function="beforeCreateFunction"
        @resourceCreationFailed="$emit('promotionCreationFailed', $event)"
        @resourceCreated="$emit('promotionCreated', $event)"
    />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { get, isEmpty, cloneDeep } from 'lodash';
import { sourceTypes } from '@enums/vuex-form';
import namespaces from '@enums/namespaces';
import pogScope from '@enums/product-offer-group-scope';
import i18n from '@/js/vue-i18n';
import { toSentenceCase } from '@/js/utils/string-utils';
import { getDefaultResourcesForPromotion } from '@/js/utils/nomination-matrix-utils';
import { getActualCustomerAvailabilityForPromotion } from '@/js/utils/promotion-products-utils';

export default {
    props: {
        promotionName: {
            required: false,
            type: String,
            default: '',
        },
        splitPromotion: {
            type: Boolean,
            default: false,
        },
        btnText: {
            type: String,
            default: 'planning.promotionsViewer.createNewPromotion',
        },
        namespace: {
            required: false,
            type: String,
            default: namespaces.default,
        },
        beforeCreateFunction: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        ...mapGetters('scenarios', ['selectedScenario', 'selectedScenarioId', 'getScenarioById']),
        ...mapGetters('tagMetadata', ['getSelectedSubCampaignTagsInFilter']),
        ...mapState('clientConfig', [
            'rewardsDefaults',
            'detailedProvisions',
            'generalConfig',
            'clientStates',
            'offerDefaults',
        ]),
        ...mapGetters('context', ['userCategories']),

        form() {
            // Select category by default when creating a promo for the user with access to one category
            const userSelectedCategories =
                this.userCategories.length === 1 ? this.userCategories : [];

            return {
                vuexModule: 'promotions',
                addEvent: 'createPromotion',
                namespace: this.namespace,
                isReactive: true,
                fields: [
                    {
                        fieldName: 'name',
                        defaultValue: this.promotionName,
                    },
                    {
                        fieldName: 'startDate',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                // if startDate is in the past, use current date
                                const dates = [context.startDate, this.$moment()].map(date =>
                                    this.$moment(date)
                                );
                                return this.$options.filters.datepickerFormat(
                                    this.$moment.max(dates)
                                );
                            },
                        },
                    },
                    {
                        fieldName: 'endDate',
                        dynamicDefaultValue: {
                            source: sourceTypes.context,
                            path: 'endDate',
                        },
                    },
                    {
                        fieldName: 'scenarioId',
                        dynamicDefaultValue: {
                            source: sourceTypes.context,
                            path: '_id',
                        },
                    },
                    {
                        fieldName: 'products',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'productGroups',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'notes',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'categories',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'offerMechanic',
                        defaultValue: this.getOfferMechanic,
                    },
                    {
                        fieldName: 'productOfferGroups',
                        defaultValue: [
                            {
                                _id: 1,
                                description: toSentenceCase(
                                    i18n.t(
                                        'planning.promotionsMaintenance.offer.offerMechanic.productOfferGroup.default'
                                    )
                                ),
                                products: [],
                                userSelectedCategories,
                                scope: pogScope.selectedProducts,
                            },
                        ],
                        editable: true,
                    },
                    {
                        fieldName: 'supplierNotes',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'workflowState',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'tags',
                        defaultValue: this.getSelectedSubCampaignTagsInFilter,
                    },
                    {
                        fieldName: 'storeGroups',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                const scenario = this.getScenarioById({
                                    _id: context._id,
                                    usePluralResourceName: true,
                                });
                                const { defaultStoreGroups, storeGroups } = scenario;
                                const groups = isEmpty(defaultStoreGroups)
                                    ? storeGroups
                                    : defaultStoreGroups;
                                return groups.map(sg => ({
                                    ...sg,
                                    excludedStores: get(sg, 'excludedStores', []),
                                }));
                            },
                        },
                    },
                    {
                        fieldName: 'resources',
                        dynamicDefaultValue: {
                            source: sourceTypes.contextFunction,
                            contextFunction: ({ context }) => {
                                const scenario = this.getScenarioById({
                                    _id: context._id,
                                    usePluralResourceName: true,
                                });
                                const resources = get(scenario, 'defaultResources', []);
                                return getDefaultResourcesForPromotion({
                                    scenarioResources: resources,
                                });
                            },
                        },
                    },
                    {
                        fieldName: 'rankedProductsCount',
                        defaultValue: this.generalConfig.defaultRankedProductsCount,
                    },
                    {
                        fieldName: 'rateCards',
                        defaultValue: [],
                    },
                    {
                        fieldName: 'additionalClientSpecificFields',
                        defaultValue: {
                            allVariantsFlag: null,
                            offerCommercialField: null,
                        },
                    },
                    {
                        fieldName: 'execution',
                        defaultValue: {},
                    },
                    {
                        fieldName: 'clientState',
                        defaultValue: this.determineClientState,
                    },
                    {
                        fieldName: 'userSelectedCategories',
                        defaultValue: userSelectedCategories,
                    },
                    {
                        fieldName: 'splitPromotion',
                        defaultValue: this.splitPromotion,
                    },
                ],
            };
        },

        getOfferMechanic() {
            const offerMechanic = cloneDeep(this.offerDefaults);
            const defaultCustomerAvailability = offerMechanic.customerAvailability;
            offerMechanic.customerAvailability = getActualCustomerAvailabilityForPromotion({
                defaultCustomerAvailability,
                scenario: this.selectedScenario,
            });
            return offerMechanic;
        },

        determineClientState() {
            return this.clientStates.promotion.find(state => state.isDefaultPlanningState).state;
        },
    },
};
</script>
