<template>
    <child-promotion-ghost-dialog
        v-if="parentPromotionId"
        :key="`childPromotionDeclineDialog::${promotionId}`"
        :promotion-id="promotionId"
        :is-ghost="isGhost"
    />
    <common-delete-dialog
        v-else-if="renderIcon"
        :resource="resource"
        :resource-id="resourceId"
        :child-dependencies-warning="false"
        :disabled="isDisabled"
        :is-delete-from-sub-campaign-allocation="isDeleteFromSubCampaignAllocation"
        @delete="onResourceDeleted"
    />
</template>

<script>
import Vue from 'vue';
import { get, isFunction } from 'lodash';

export default Vue.extend({
    computed: {
        renderIcon() {
            // conditional icon render based on row data
            if (this.params.conditionalRender) {
                return get(this.params.data, this.params.conditionPath, false);
            }
            return true;
        },
        resource() {
            return this.params.resource;
        },
        resourceId() {
            return this.params.data[this.params.resourceId];
        },
        isDisabled() {
            const data = this.params.data;

            if (isFunction(this.params.isDisabled)) {
                return this.params.isDisabled(data);
            }

            return false;
        },
        isDeleteFromSubCampaignAllocation() {
            return this.params.isDeleteFromSubCampaignAllocation || false;
        },
        parentPromotionId() {
            return this.params.data.parentPromotionId;
        },
        promotionId() {
            return this.params.data.promotionId;
        },
        isGhost() {
            return this.params.data.isGhost || false;
        },
    },
    methods: {
        onResourceDeleted(resourceId) {
            if (this.params.successCallback) this.params.successCallback(resourceId);
        },
    },
});
</script>

<style lang="scss" scoped></style>
