<template>
    <main-dialog
        ref="excludeStoresDialog"
        :translated-header="$tkey(`storeList`, { name: storeGroup.description }) | toSentenceCase"
        btn-confirm-text="actions.save"
        btn-cancel-text="actions.cancel"
        has-activator
        :confirm-btn-disabled="isSaveDisabled"
        @confirm="saveExcludeStoresList"
        @close="closeDialog"
    >
        <template v-slot:actions="{ activator }">
            <v-btn :disabled="disabled" icon small v-on="activator">
                <div class="activator" :class="{ excluded: excludedStores.length }" />
            </v-btn>
        </template>
        <template v-slot:default>
            <div class="exclude-stores-dialog">
                <promo-ag-grid
                    ref="agGrid"
                    :row-data="stores"
                    :column-defs="columnDefs"
                    :grid-options="gridOptions"
                    :default-col-def="defaultColDef"
                    grid-style="width: 115rem; height: 35rem;"
                    grid-class="ag-theme-custom__exclude-stores-dialog"
                    @row-data-updated="setIsSaveDisabled"
                    @cell-value-changed="setIsSaveDisabled"
                />
            </div>
        </template>
    </main-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { map, pick } from 'lodash';
import promoAgSelectAllHeader from '@/js/components/promo-ag-grid/ag-select-all-header';
import AgCheckbox from '@/js/components/promo-ag-grid/ag-checkbox';
import { toSentenceCase } from '@/js/utils/string-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
    props: {
        storeGroup: {
            type: Object,
            required: true,
        },
        excludedStores: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    localizationKey: 'planning.promotionsMaintenance.resources.excludedStores',
    data() {
        return {
            defaultColDef: {
                editable: false,
                suppressMovable: true, // Stop users from being able to rearrange columns.
                lockPinned: true, // Stop users from being able to pin columns.
                sortable: true, // All columns default to being sortable.
                unSortIcon: true, // Ensures the sort icon displays all the time (not just when hovered over).
                filter: true,
                menuTabs: ['filterMenuTab'],
            },
            gridOptions: {
                frameworkComponents: { promoAgSelectAllHeader },
                suppressContextMenu: true, // only show filter
                rowHeight: 35, // Specified in pixels.
            },
            isSaveDisabled: false,
        };
    },
    computed: {
        ...mapGetters('storeGroups', ['getStoresByKey']),
        stores() {
            const excludedStoreKeys = map(this.excludedStores, 'key');
            return map(this.getStoresByKey(this.storeGroup.key), store => ({
                ...store,
                // checkbox should be checked if store is include
                // and unchecked if store is exclude
                isIncluded: !excludedStoreKeys.includes(store.key),
            }));
        },
        columnDefs() {
            return [
                ...['clientStoreKey', 'name', 'storeStatus', 'city', 'storeProfile'].map(field => ({
                    headerName: toSentenceCase(this.$tkey(`gridHeaders.${field}`)),
                    field,
                    minWidth: 155,
                })),
                ...['openDate', 'closeDate'].map(field => {
                    return {
                        headerName: toSentenceCase(this.$tkey(`gridHeaders.${field}`)),
                        filter: 'agDateColumnFilter',
                        filterParams: {
                            // https://www.ag-grid.com/javascript-grid/filter-date/
                            comparator: agGridUtils.agGridDateComparator,
                        },
                        field,
                        minWidth: 155,
                        valueFormatter: params => this.$options.filters.dateShorthand(params.value),
                    };
                }),
                {
                    headerName: '',
                    field: 'isIncluded',
                    headerComponent: 'promoAgSelectAllHeader',
                    cellRendererFramework: AgCheckbox,
                    headerClass: 'flex-cell',
                    cellClass: 'flex-cell',
                    minWidth: 35,
                },
            ];
        },
    },
    methods: {
        openDialog() {
            this.$refs.excludeStoresDialog.open();
        },
        closeDialog() {
            if (this.$refs.agGrid) {
                // reset state if user cancel changes
                this.$refs.agGrid.gridOptions.api.forEachNode(rowNode => {
                    const isExcludedStore = this.excludedStores.find(
                        excludedStoreItem => excludedStoreItem.key === rowNode.data.key
                    );
                    rowNode.setDataValue('isIncluded', !isExcludedStore);
                });
            }
            this.$refs.excludeStoresDialog.close();
        },

        saveExcludeStoresList() {
            const excludeStores = this.stores
                .filter(store => !store.isIncluded)
                .map(store => pick(store, ['_id', 'key', 'clientStoreKey', 'name']));
            this.$emit('save', excludeStores);
            this.closeDialog();
        },
        // save button disabled if user exclude all stores
        // We don't use computed because when ag-grid mutate model  this.store
        // recounting of computed property doesn't run
        setIsSaveDisabled() {
            this.isSaveDisabled = !this.stores.some(store => store.isIncluded);
        },
    },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.exclude-stores-dialog {
    .flex-cell {
        @include flex-center;
    }
}

.activator {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: $promo-primary-colour;
    cursor: pointer;

    &:hover {
        background: $promo-light-blue;
    }
}
.excluded {
    border: 0.1rem solid $promo-primary-colour;
    background: $promo-white;

    &:hover {
        background: $promo-white;
    }
}
</style>
