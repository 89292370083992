<template>
    <promotion-dates v-bind="form" :cache-dom="cacheDom" />
</template>

<script>
import { fieldTypes, sourceTypes } from '@enums/vuex-form';
import { isEmpty } from 'lodash';
import validators from '@/js/validators';
import promotionTabFormWrapperMixin from '@/js/pages/planning/components/mixins/promotion-tab-form-wrapper';

export default {
    mixins: [promotionTabFormWrapperMixin],
    props: {
        isParkingLot: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        form() {
            let field = {
                type: fieldTypes.dateRange,
                from: {
                    fieldName: 'startDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'selectedPromotion.startDate',
                    },
                },
                to: {
                    fieldName: 'endDate',
                    dynamicDefaultValue: {
                        source: sourceTypes.context,
                        path: 'selectedPromotion.endDate',
                    },
                },
                minDate: {
                    source: sourceTypes.context,
                    path: this.$moment,
                },
                editable: true,
                class: 'promotion-period__calendar',
                validations: [
                    {
                        validator: validators.required,
                    },
                    {
                        validator: validators.dateRangeRequired,
                    },
                ],
                theme: 'white',
                skipComputedMin: true,
            };
            if (!isEmpty(this.context.selectedScenario)) {
                field = {
                    ...field,
                    minDate: {
                        source: sourceTypes.contextFunction,
                        contextFunction: ({ context }) => {
                            const dates = [context.selectedScenario.startDate, this.$moment()].map(
                                date => this.$moment(date)
                            );
                            return this.$options.filters.datepickerFormat(this.$moment.max(dates));
                        },
                    },
                    maxDate: {
                        source: sourceTypes.context,
                        path: 'selectedScenario.endDate',
                    },
                };
            }
            // if promotion startDate is in the past, don't allow user to change startDate.
            const { startDate } = this.context.selectedPromotion;
            if (
                !this.isParkingLot &&
                this.$moment(startDate).isBefore(this.$moment().startOf('day'))
            ) {
                field.fixedStartDate = startDate;
            }
            return {
                vuexModule: 'promotions',
                editMode: this.editMode,
                submitAction: this.isNewPromotion ? null : 'prepareAndUpdatePromotion',
                preventDefault: true,
                namespace: this.namespace,
                context: this.context,
                editContext: this.context.selectedPromotion,
                fields: [field],
            };
        },
    },
};
</script>
