<template>
    <div>
        <!-- Document scroller & document performance -->
        <div class="promo-resource-tab-section">
            <div class="promo-resource-tab-section__container">
                <document-scroller
                    :documents="resourceDefinitions"
                    :selected-document-key="selectedResourceDefinitionKey"
                    :num-documents-to-show="4"
                    class="document-scroller"
                    @document-selected="documentSelectedHandler"
                />
                <div
                    class="promo-resource-tab-section__container--right"
                    :class="{ 'disabled-text': !selectedResourceDefinitionKey }"
                >
                    <strong>{{ $t('preparation.documentPerformance') | toSentenceCase }}:</strong>
                    <span>{{
                        $n('numbers.default.percentage', documentPercentagePerformance)
                    }}</span>
                    {{ $t('preparation.complete') }}
                </div>
            </div>
            <div class="promo-resource-tab-section__sidebar-content">
                <div
                    class="promo-resource-tab-section__sidebar-header--title"
                    :class="{ 'disabled-text': !selectedResourceDefinitionKey }"
                >
                    {{ $t('preparation.incrSalesEfficiency') | toSentenceCase }}
                </div>
                <losenge-monetary-display
                    v-if="selectedResourceDefinitionKey"
                    :incremental-amount="selectedDocumentPerformance.aggregatedSales"
                    :efficiency="
                        $n(
                            'numbers.default.currencyNoLabel',
                            selectedDocumentPerformance.aggregatedSalesEfficiency
                        )
                    "
                    :compact="true"
                />
                <span v-else>--</span>
            </div>
            <div class="promo-resource-tab-section__sidebar-content">
                <div
                    class="promo-resource-tab-section__sidebar-header--title"
                    :class="{ 'disabled-text': !selectedResourceDefinitionKey }"
                >
                    {{ $t('preparation.incrMarginEfficiency') | toSentenceCase }}
                </div>
                <losenge-monetary-display
                    v-if="selectedResourceDefinitionKey"
                    :incremental-amount="selectedDocumentPerformance.aggregatedMargin"
                    :efficiency="
                        $n(
                            'numbers.default.currencyNoLabel',
                            selectedDocumentPerformance.aggregatedMarginEfficiency
                        )
                    "
                    :compact="true"
                />
                <span v-else>--</span>
            </div>
        </div>
        <!-- Toolbar & page performance -->
        <div v-if="selectedResourceDefinition" class="promo-resource-tab-section">
            <div class="promo-resource-tab-section__container">
                <promo-resources-toolbar
                    vuex-module="subCampaigns"
                    :namespace="selectedSubCampaign._id"
                    field-name="resourceDefinitions"
                    :make-read-only="promoResourceEditingState.disabled"
                    :read-only-reason="promoResourceEditingState.reason"
                />
                <!-- We shouldn't show page performance if currently viewing a slots resource -->
                <div
                    v-if="!isSlotsResource"
                    class="promo-resource-tab-section__container--right"
                    :class="{ 'disabled-text': !selectedResourceDefinitionPage }"
                >
                    <strong>{{ $t('preparation.pagePerformance') | toSentenceCase }}:</strong>
                    <span>{{
                        $n(
                            'numbers.default.percentage',
                            pagePerformance ? pagePerformance.assignedAreasPerformance : 0
                        )
                    }}</span>
                    {{ $t('preparation.complete') }}
                </div>
            </div>
            <div v-if="!isSlotsResource" class="promo-resource-tab-section__sidebar-content">
                <losenge-monetary-display
                    v-if="selectedResourceDefinitionPage"
                    :incremental-amount="pagePerformance.incrementalSales"
                    :efficiency="
                        $n('numbers.default.currencyNoLabel', pagePerformance.salesEfficiency)
                    "
                    :compact="true"
                />
                <span v-else>--</span>
            </div>
            <div v-if="!isSlotsResource" class="promo-resource-tab-section__sidebar-content">
                <losenge-monetary-display
                    v-if="selectedResourceDefinitionPage"
                    :incremental-amount="pagePerformance.incrementalMargin"
                    :efficiency="
                        $n('numbers.default.currencyNoLabel', pagePerformance.marginEfficiency)
                    "
                    :compact="true"
                />
                <span v-else>--</span>
            </div>
            <div />
        </div>
        <div v-if="promoResourceEditingState.disabled" class="promo-resource__locked">
            <alert-banner
                :reason="promoResourceEditingState.reason"
                class="promo-resource__locked-msg"
            />
        </div>
        <!-- Page layout & allocation areas -->
        <div class="promo-resource-tab-section" :class="promotionsClass">
            <div class="page-layout-container">
                <div class="page-layout-left-column">
                    <page-layout
                        v-if="selectedResourceDefinitionKey"
                        :namespace="selectedSubCampaign._id"
                        vuex-module="subCampaigns"
                        field-name="resourceDefinitions"
                        :promo-resource="promoResource"
                        :make-read-only="promoResourceEditingState.disabled"
                        :read-only-reason="promoResourceEditingState.reason"
                        @auto-save="debouncedSaveResourceProvisions"
                    />

                    <!-- Page brief area -->
                    <div v-if="selectedResourceDefinitionPage">
                        <vuex-textarea
                            v-model="selectedResourceDefinitionPage.pageBrief"
                            class="page-brief"
                            :getter="pageBriefGetter"
                            :setter="pageBriefSetter"
                            :label="briefAreaLabel"
                            :placeholder="briefAreaPlaceholder"
                            :make-read-only="isPageBriefReadOnly"
                            :read-only-reason="promoResourceEditingState.reason"
                            @blur="debouncedSaveResourceProvisions"
                        />
                    </div>
                </div>
            </div>
            <v-divider class="promo-resource-tab-section__divider" vertical />
            <categories-allocation-area
                v-if="
                    isCategoriesSection && isPromoResourceWithCategoriesSection({ promoResource })
                "
                :make-read-only="promoResourceEditingState.disabled"
                :read-only-reason="promoResourceEditingState.reason"
            />
            <promotion-allocation-area
                v-if="showPromotionsAllocationArea"
                :make-read-only="promoResourceEditingState.disabled"
                :read-only-reason="promoResourceEditingState.reason"
            />
        </div>
        <!-- Approve / Reject Leaflet Area -->
        <div v-if="canSignOffLeaflet" class="promo-resource-tab-section">
            <div class="workflow-tasks-dropdown">
                {{ $t('preparation.leafletSignOff') | toSentenceCase }}
                <task-buttons-dropdown
                    :tasks="workflowTasksList"
                    :entity-type="workflowEntities.leaflet"
                    :entity-ids="[selectedResourceDefinitionKey]"
                    :sub-campaign-id="selectedSubCampaignId"
                    :entity-instance="selectedResourceDefinition"
                    :owning-entity-id="selectedSubCampaignId"
                    :categories="selectedSubCampaign.categories"
                />
            </div>
        </div>

        <!-- Actions -->
        <div class="promo-resource-tab-section margin-top">
            <div class="text-right">
                <feature-toggle :toggle="canEditChannel">
                    <copy-resource-definition-dialog
                        :namespace="selectedSubCampaign._id"
                        :promo-resource="promoResource"
                        vuex-module="subCampaigns"
                        field-name="resourceDefinitions"
                        :make-read-only="promoResourceEditingState.disabled"
                        :read-only-reason="promoResourceEditingState.reason"
                    />
                    <!-- Was commented out according to PROWEB-1495
                        <positive-action-button
                            class="margin-left"
                            :make-read-only="promoResourceEditingState.disabled"
                            :read-only-reason="promoResourceEditingState.reason"
                            @onClick="saveResourceProvisions"
                        >
                            {{ $t('preparation.saveResourceProvisions') | toSentenceCase }}
                        </positive-action-button>
                    -->
                    <v-divider class="horizontal-divider" />
                </feature-toggle>
                <feature-toggle :toggle="showJsonExportButton">
                    <download-resource-button
                        :resource="ResourcesEnum.subCampaigns"
                        :format="ExportFormats.json"
                        :text-btn="true"
                        :label="$t('preparation.buttons.exportDocument')"
                        :download-action="downloadAction"
                    />
                </feature-toggle>
                <download-resource-button
                    class="promo-resource-tab__download-btn"
                    :is-secondary-btn="true"
                    :resource="ResourcesEnum.subCampaigns"
                    :format="ExportFormats.excel"
                    :text-btn="true"
                    :label="$t('preparation.buttons.export')"
                    :download-action="exportPreparation"
                    :export-schema="exportSchema"
                    :extra-params="extraParams"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { find, get, debounce } from 'lodash';
import ExportFormats from '@enums/export-formats';
import ResourcesEnum from '@enums/resources';
import workflowEntities from '@enums/workflow-entities';
import workflowTasks from '@enums/workflow-tasks';
import workflowStates from '@enums/workflow-states';
import createFeatureAwareFactory from '@/js/feature-toggles/feature-factory';
import promoResources from '@enums/promo-resources';
import { canEditChannel, showJsonExportButton } from '@enums/feature-flags';
import vuexForm from '@/js/mixins/vuex-form';

export default {
    mixins: [vuexForm],
    props: ['promoResource'],
    data() {
        return {
            ExportFormats,
            ResourcesEnum,
            downloadAction: 'downloadJsonResourceDefinition',
            exportPreparation: 'downloadExcelResourceDefinition',
            workflowEntities,
            workflowTasksList: [
                {
                    task: workflowTasks.approve,
                    isNegativeAction: false,
                },
                {
                    task: workflowTasks.approve,
                    isNegativeAction: true,
                },
            ],
            canEditChannel,
            showJsonExportButton,
            exportSchema: 'preparation-flat',
            extraParams: {
                fieldToUnwind: 'assignment',
            },
        };
    },
    computed: {
        ...mapState('clientConfig', ['toggleLogic']),
        ...mapState('subCampaigns', [
            'stagingArea',
            'selectedResourceDefinitionKey',
            'selectedResourceDefinitionPageNumber',
            'selectedSubCampaignId',
        ]),
        ...mapGetters('subCampaigns', [
            'selectedSubCampaign',
            'isCategoriesSection',
            'isPromotionsSection',
            'selectedDocumentPerformance',
            'selectedResourceDefinitionPage',
            'getResourceDefinitionsForResourceType',
            'selectedResourceDefinition',
            'isSlotsResource',
            'promoResourceWorkflowStateById',
            'getSubCampaignById',
        ]),
        isPageBriefReadOnly() {
            return !this.toggleLogic[canEditChannel] || this.promoResourceEditingState.disabled;
        },
        resourceDefinitions() {
            return this.getResourceDefinitionsForResourceType(this.promoResource);
        },
        promotionsClass() {
            return this.showPromotionsAllocationArea
                ? 'promo-resource-tab-section__split-with-sidebar'
                : 'promo-resource-tab-section__split-without-sidebar';
        },
        pagePerformance() {
            if (this.selectedResourceDefinitionPage) {
                return this.selectedDocumentPerformance.pages[
                    this.selectedResourceDefinitionPage.key
                ];
            }

            return {};
        },
        documentPercentagePerformance() {
            return this.selectedDocumentPerformance.allSpaces
                ? this.selectedDocumentPerformance.completeSpaces /
                      this.selectedDocumentPerformance.allSpaces
                : 0;
        },
        showPromotionsAllocationArea() {
            return this.isPromotionsSection || this.isSlotsResource;
        },
        briefAreaLabel() {
            return this.isSlotsResource
                ? 'preparation.templates.slotsBrief'
                : 'preparation.templates.pageBrief';
        },
        briefAreaPlaceholder() {
            return this.isSlotsResource
                ? 'preparation.templates.slotsBriefPlaceholder'
                : 'preparation.templates.pageBriefPlaceholder';
        },
        promoResourceEditingState() {
            if (
                this.selectedResourceDefinitionKey &&
                (this.selectedSubCampaign && this.selectedSubCampaign._id)
            ) {
                const result = createFeatureAwareFactory(
                    this.toggleLogic
                ).isPromoResourceEditingDisabled(
                    {
                        promoResourceId: this.selectedResourceDefinitionKey,
                        subCampaignId: this.selectedSubCampaign._id,
                        promoResourceWorkflowStateById: this.promoResourceWorkflowStateById,
                        getSubCampaignById: this.getSubCampaignById,
                    },
                    this.$moment
                );

                return result;
            }

            return { disabled: false, reason: null };
        },
        canSignOffLeaflet() {
            const workflowState = get(this.selectedResourceDefinition, 'workflowState', []);

            return !!find(workflowState, {
                entity: workflowEntities.leaflet,
                state: workflowStates.submitted,
                value: true,
            });
        },
    },
    methods: {
        ...mapMutations('subCampaigns', [
            'setSelectedResourceDefinitionKey',
            'setSelectedResourceDefinitionPageNumber',
            'setSelectedAreaForPromotionAllocation',
        ]),
        ...mapActions('promotions', ['toggleSelectedPromotion']),

        debouncedSaveResourceProvisions: debounce(async function() {
            await this.saveResourceProvisions();
        }, 800),

        async saveResourceProvisions() {
            await this.submit();
            // need populated fields after saving
            this.populateFieldsInStagingArea();
        },

        pageBriefGetter() {
            if (this.selectedResourceDefinitionPage) {
                return this.selectedResourceDefinitionPage.pageBrief;
            }
            return null;
        },
        pageBriefSetter(value) {
            this.$set(this.selectedResourceDefinitionPage, 'pageBrief', value);
        },
        documentSelectedHandler(selectedResourceDefinitionKey) {
            this.setSelectedResourceDefinitionKey(selectedResourceDefinitionKey);
            this.setSelectedResourceDefinitionPageNumber(null);
            this.toggleSelectedPromotion({ promotionId: null });
            this.setSelectedAreaForPromotionAllocation(null);
            this.globalEmit('promo-resource-document-change');
            this.$nextTick(() => {
                // nextTick needed otherwise the keys for the carousel are not updating and re-rendering on time
                if (this.selectedResourceDefinition.pages.length) {
                    this.setSelectedResourceDefinitionPageNumber(1);
                }
            });
        },
        isPromoResourceWithCategoriesSection({ promoResource }) {
            // Only the leaflet resource has a categories section.
            return promoResource === promoResources.leaflet;
        },
    },
    events: {
        onDocumentSelected({ key, resource }) {
            if (this.promoResource === resource) {
                this.documentSelectedHandler(key);
            }
        },

        onPromoResourceTemplateChange() {
            this.debouncedSaveResourceProvisions();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.promo-resource-tab-section {
    display: grid;
    // This number of columns here must match the number of data columns to be shown in the grid.
    // Each column can be adjusted in width within the row.
    // The sidebar columns are calculated based on the width of the sidebar element.
    grid-template-columns:
        auto
        $health-sidebar-width / 2
        $health-sidebar-width / 2;

    position: relative;

    &__sidebar-header {
        @include promo-component-border($top: true);
        display: flex;
        margin-top: 2rem;
        background-color: $promo-grey-blue-tint;
        height: 4rem;
        align-items: center;
        z-index: $promo-details-z-index;
        position: relative;

        &--title {
            position: absolute;
            top: -6rem;
            padding: 1.5rem;
            font-size: 1.2rem;
        }
    }

    &__sidebar-content {
        @include flex-center;
        z-index: $promo-details-z-index;
        font-weight: 600;
        line-height: 1.5rem;
    }

    &__split-without-sidebar {
        min-height: 70rem;
        grid-template-columns: calc(50% - 12rem) 2rem calc(50% - 12rem) $health-sidebar-width;
    }

    &__split-with-sidebar {
        min-height: 70rem;
        grid-template-columns: calc(50% - 12rem) 2rem calc((50% - 12rem) + #{$health-sidebar-width});
    }

    &__divider {
        padding-left: 1.5rem;
    }

    &__container {
        display: flex;
        justify-content: space-between;

        &--right {
            align-self: center;
            margin-right: 2rem;
            font-size: 1.2rem;

            strong {
                margin-right: 1rem;
            }
        }
    }
    .disabled-text {
        color: $promo-grey;
    }

    .text-right {
        margin-right: 1.2rem;
        padding-bottom: 1.2rem;
    }
}
.document-scroller {
    width: 60%;
}
.page-brief {
    padding-top: 1rem;
    padding-left: 1rem;
}
.promo-resource__locked {
    &-msg {
        margin-left: 4rem;
        margin-right: $health-sidebar-width + 2rem;
    }
}

.horizontal-divider {
    margin: 1rem 0;
}

.margin-top {
    margin-top: 1.2rem;
}

.margin-left {
    margin-left: 0.8rem;
}

.workflow-tasks-dropdown {
    @include flex-row;
    justify-content: flex-end;
    padding-top: 0.7rem;
    padding-right: 2rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: $promo-light-green;
}

.page-layout-container {
    height: 100%;
    max-height: 70rem;
}

.page-layout-left-column {
    padding-left: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
</style>
