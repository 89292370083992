import UXEvents from '@enums/ux-events';

export default [
    {
        // This event is run after a promotion has been saved to mongo
        key: UXEvents.promotionSaved,
        actions: [
            'promotions/fetchSelectedSubcampaignPromotionAggregations',
            'promotions/validatePromotionMaintenanceArea',
        ],
    },
    {
        key: UXEvents.promotionProductsUpdated,
        actions: [
            'supplierCommitments/updateSupplierCommitmentProductCurrent',
            'supplierCommitments/fetchSupplierCommitmentsForPromotion',
        ],
    },
    {
        key: UXEvents.promotionCreated,
        actions: ['promotions/fetchSelectedSubcampaignPromotionAggregations'],
    },
    {
        key: UXEvents.promotionDeleted,
        actions: [
            'promotions/fetchSelectedSubcampaignPromotionAggregations',
            'promotions/setSelectedPromotion',
            'subCampaigns/updateSubCampaignResourceDefinitions',
            'notifications/fetchOpenNotifications',
        ],
    },
    {
        key: UXEvents.candidateRemovedFromPromotion,
        actions: ['promotions/applyPromoPricesToProducts'], // This action will then emit an event to trigger forecasting/saving.
    },
    {
        key: UXEvents.candidateAddedToPromotion,
        actions: [
            'promotions/setUserSelectedCategories',
            'promotions/applyPromoPricesToProducts', // This action will then emit an event to trigger forecasting/saving.
        ],
    },
    {
        key: UXEvents.promotionUpdated,
        actions: ['promotions/submitPromotion'],
    },
    // {
    //     key: UXEvents.promotionRateCardsUpdated,
    //     actions: [
    //         'supplierCommitments/updateSupplierCommitmentTotalSpendAttributesByPromotionId',
    //     ],
    // },
    {
        key: UXEvents.promotionUpdatedForUpdatingPrices,
        actions: [
            'rateCards/fetchRateCardsByStoreGroupsAndDates',
            'promotions/updateProductPricesOnPromotionById',
        ],
    },
    {
        key: UXEvents.promotionUpdatedForPromoPriceRecalculation,
        actions: [
            'promotions/applyPromoPricesToProductsWithoutSaving',
            'promotions/updateOfferMechanicDescription', // PROWEB-1969 Triggered when editing new (unsaved) promotion in parkinglot
        ],
    },
    {
        key: UXEvents.updateSuppliersForPromotion,
        actions: ['promotions/updatePromotionProductSuppliers'],
    },
    {
        key: UXEvents.promotionPricesUpdated,
        actions: ['promotions/updatePromoFunding'],
    },
    {
        key: UXEvents.promotionUpdatedForForecasting,
        actions: ['forecasting/forecastSinglePromotionById'],
    },
    {
        key: UXEvents.lastCandidateRemovedFromPromotion,
        actions: ['promotions/updateOfferMechanicDescription'],
    },
    {
        key: UXEvents.updateMaintenanceAreaValidation,
        actions: ['promotions/validatePromotionMaintenanceArea'],
    },
    {
        key: UXEvents.updatePromotionMechanicDescription,
        actions: ['promotions/updateOfferMechanicDescription'],
    },
];
