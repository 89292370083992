'use strict';

const { isNumber, max, isNull, sum, map } = require('lodash');
const { divide, multiply, subtract } = require('mathjs');
const requirementTypes = require('./data/enums/requirement-types');
const rewardTypes = require('./data/enums/reward-types');

function validatePreRewardPrice(preRewardPrice) {
    if (!isNumber(preRewardPrice) && !isNull(preRewardPrice)) {
        throw new Error(
            `Reward price cannot be calculated because pre-reward price is not a number: ${preRewardPrice}`
        );
    }
}

function validateParams(params = {}) {
    if (isNumber(params.requirementAmount) && params.requirementAmount <= 0) {
        throw new Error(`Requirement amount must be greater than zero.`);
    }
}

const noDiscount = {
    type: rewardTypes.noDiscount,
    promoPrice(preRewardPrice) {
        validatePreRewardPrice(preRewardPrice);

        return preRewardPrice;
    },
    paramPaths: {},
};

const savePercent = {
    type: rewardTypes.savePercent,
    promoPrice(preRewardPrice, params) {
        validatePreRewardPrice(preRewardPrice);
        let divider = 1;
        (params.requirements || []).forEach(({ type, amount }) => {
            if (type === requirementTypes.onNthItem) {
                divider = amount || 1;
            }
        });
        return multiply(preRewardPrice, divide(subtract(100, divide(params.value, divider)), 100));
    },
    paramPaths: {
        value: 'reward.amount',
        requirements: 'requirements',
    },
};

const saveMoney = {
    type: rewardTypes.saveMoney,
    promoPrice(preRewardPrice, params) {
        validatePreRewardPrice(preRewardPrice);
        validateParams(params);

        const { value, requirements, pogRequirements } = params;

        const getMaxRequirement = r =>
            max([
                1,
                ...r.map(({ type, amount }) => {
                    if (type === requirementTypes.spendMoney) {
                        return Math.ceil(amount / preRewardPrice);
                    }
                    return amount;
                }),
            ]);

        // For global rewards, we may have POG level requirements. If we do,
        // We need to calculate the total requirements across all POG
        if (pogRequirements) {
            const totalPogRequirements = sum(map(pogRequirements, getMaxRequirement));
            requirements.push({
                amount: totalPogRequirements,
            });
        }

        const requirementAmount = getMaxRequirement(requirements);
        const savingPerProduct = value / requirementAmount;

        return preRewardPrice - savingPerProduct;
    },
    paramPaths: {
        value: 'reward.amount',
        requirements: 'requirements',
        pogRequirements: 'pogRequirements',
    },
    optionalParams: ['pogRequirements'],
};

const saveMoneyPerItem = {
    type: rewardTypes.saveMoneyPerItem,
    promoPrice(preRewardPrice, params) {
        validatePreRewardPrice(preRewardPrice);
        validateParams(params);

        return preRewardPrice - params.value;
    },
    paramPaths: {
        value: 'reward.amount',
    },
};

const setMoney = {
    type: rewardTypes.setMoney,
    promoPrice(preRewardPrice, params) {
        validateParams(params);

        const { value, requirements, pogRequirements } = params;

        const getMaxRequirement = r =>
            max([
                1,
                ...r.map(({ type, amount }) => {
                    if (type === requirementTypes.onNthItem) {
                        return 1;
                    }
                    if (type === requirementTypes.spendMoney) {
                        return Math.ceil(amount / preRewardPrice);
                    }
                    return amount;
                }),
            ]);

        // For global rewards, we may have POG level requirements. If we do,
        // We need to calculate the total requirements across all POG
        if (pogRequirements) {
            const totalPogRequirements = sum(map(pogRequirements, getMaxRequirement));
            requirements.push({ amount: totalPogRequirements });
        }

        const requirementAmount = getMaxRequirement(requirements);

        return value / requirementAmount;
    },
    paramPaths: {
        value: 'reward.amount',
        requirements: 'requirements',
        pogRequirements: 'pogRequirements',
    },
    optionalParams: ['pogRequirements'],
};

const getNFree = {
    type: rewardTypes.getNFree,
    promoPrice(preRewardPrice, params) {
        validatePreRewardPrice(preRewardPrice);

        const { value, requirements } = params;

        const requirementAmount = max([
            1,
            ...requirements.map(({ type, amount }) => {
                if (type === requirementTypes.buyNumber) {
                    return amount;
                }

                if (type === requirementTypes.spendMoney) {
                    return Math.ceil(amount / preRewardPrice);
                }

                return amount;
            }),
        ]);

        // Calculate the average price from the products being paid for,
        // and the products that are free.
        return (preRewardPrice * requirementAmount) / (value + requirementAmount);
    },
    paramPaths: {
        value: 'reward.amount',
        requirements: 'requirements',
    },
};

const newPricePerItem = {
    type: rewardTypes.newPricePerItem,
    promoPrice(preRewardPrice) {
        validatePreRewardPrice(preRewardPrice);

        return preRewardPrice;
    },
    paramPaths: {},
};

const newShelfPricePerItem = {
    type: rewardTypes.newShelfPricePerItem,
    promoPrice(preRewardPrice) {
        validatePreRewardPrice(preRewardPrice);

        return preRewardPrice;
    },
    paramPaths: {},
};

const rewardDefinitions = {
    [rewardTypes.noDiscount]: noDiscount,
    [rewardTypes.savePercent]: savePercent,
    [rewardTypes.saveMoney]: saveMoney,
    [rewardTypes.saveMoneyPerItem]: saveMoneyPerItem,
    [rewardTypes.setMoney]: setMoney,
    [rewardTypes.getNFree]: getNFree,
    [rewardTypes.newPricePerItem]: newPricePerItem,
    [rewardTypes.newShelfPricePerItem]: newShelfPricePerItem,

    // Loyalty points and free gifts do not impact the promo price
    // so are treated as a no discount offer.
    [rewardTypes.getLoyaltyPoints]: noDiscount,
    [rewardTypes.getFreeGift]: noDiscount,
};

module.exports = { rewardDefinitions };
