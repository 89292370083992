import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "total-spend-viewer" }, [
    _c(
      "div",
      { staticClass: "total-spend-viewer total-spend-viewer__grid" },
      [
        _vm._l(_vm.fields, function (field, index) {
          return [
            field.header
              ? _c(
                  "div",
                  {
                    key: `${field.header}::${index}`,
                    class: _vm.setClassName(field),
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("toSentenceCase")(
                            _vm.$t(
                              `supplierCommitments.headers.${field.header}`
                            )
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        }),
        _vm._v(" "),
        _vm.isPromotionSupplierFundingView
          ? _c(
              "div",
              {
                class: [
                  "total-spend-viewer__header",
                  "last-column",
                  "select-heading",
                ],
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("toSentenceCase")(
                        _vm.$t("supplierCommitments.headers.select")
                      )
                    ) +
                    "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isPromotionSupplierFundingView && _vm.supplierCommitments.length
          ? _c("supplier-commitment-target", {
              staticClass: "total-spend-viewer__target",
              attrs: { "targets-data": _vm.targets },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "total-spend-viewer__filler",
          class: {
            "total-spend-viewer__filler--reseted-margin":
              !_vm.isPromotionSupplierFundingView,
          },
        }),
        _vm._v(" "),
        _vm._l(_vm.supplierCommitments, function (supplierCommitment, index) {
          return [
            _c(
              "vuex-form-viewer",
              _vm._g(
                {
                  key: supplierCommitment._id,
                  attrs: {
                    context: supplierCommitment,
                    fields: _vm.fields,
                    "is-expandable": !_vm.isPromotionSupplierFundingView,
                    "should-override-form-read-only":
                      _vm.selectedSupplierCommitmentId ===
                      supplierCommitment._id,
                    "make-read-only":
                      _vm.selectedSupplierCommitmentId !==
                      supplierCommitment._id,
                    form: _vm.getForm(supplierCommitment),
                  },
                  on: {
                    delete: _vm.removeNote,
                    post: _vm.postNote,
                    save: _vm.saveNote,
                  },
                },
                !_vm.isPromotionSupplierFundingView
                  ? {
                      click: () => _vm.onSelect({ id: supplierCommitment._id }),
                    }
                  : {}
              ),
              [
                _vm.selectedSupplierCommitmentId === supplierCommitment._id
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "total-spend-viewer__icons",
                          style: _vm.getStyle(index, 9),
                        },
                        [
                          _c("notes", {
                            attrs: {
                              notes: supplierCommitment.notes,
                              "open-left": "",
                            },
                            on: {
                              delete: _vm.removeNote,
                              post: _vm.postNote,
                              save: _vm.saveNote,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isPromotionSupplierFundingView
                        ? _c(
                            "div",
                            {
                              staticClass: "total-spend-viewer__icons",
                              style: _vm.getStyle(index, 10),
                            },
                            [
                              _c("common-delete-dialog", {
                                attrs: {
                                  resource: "supplierCommitments",
                                  "resource-id":
                                    _vm.selectedSupplierCommitmentId,
                                  "child-dependencies-warning": false,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "total-spend-viewer__icons",
                          style: _vm.getStyle(index, 11),
                        },
                        [
                          _c("main-expand-button", {
                            attrs: { "is-expanded": _vm.isExpanded },
                            on: {
                              expand: function ($event) {
                                return _vm.onSelect({
                                  id: supplierCommitment._id,
                                })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isPromotionSupplierFundingView
                        ? _c("funded-promotions-list-wrapper", {
                            staticClass:
                              "total-spend-viewer__funded-promotions-list-wrapper",
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", {
              key: `filler::${supplierCommitment._id}::${index}`,
              staticClass: "total-spend-viewer__filler",
              class: _vm.fillerStyle(supplierCommitment._id),
            }),
          ]
        }),
        _vm._v(" "),
        _vm.isNewSupplierCommitment && !_vm.isPromotionSupplierFundingView
          ? _c(
              "vuex-form-viewer",
              {
                ref: "newSupplierCommitmentFormViewer",
                attrs: { context: {}, fields: [], form: _vm.getForm() },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "total-spend-viewer__icons",
                    style: _vm.getStyle(
                      _vm.filteredSupplierCommitmentsMap.current.totalSpend
                        .length,
                      9
                    ),
                  },
                  [_c("notes", { attrs: { disabled: true } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "total-spend-viewer__icons",
                    style: _vm.getStyle(
                      _vm.filteredSupplierCommitmentsMap.current.totalSpend
                        .length,
                      10
                    ),
                  },
                  [
                    _c("icon-button", {
                      attrs: { disabled: "", icon: "trash" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "total-spend-viewer__icons",
                    style: _vm.getStyle(
                      _vm.filteredSupplierCommitmentsMap.current.totalSpend
                        .length,
                      11
                    ),
                  },
                  [
                    _c("main-expand-button", {
                      attrs: { disabled: "", "is-expanded": false },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "total-spend-viewer__buttons" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass:
                          "total-spend-viewer__buttons--cancel-button",
                        attrs: { text: "" },
                        on: { click: _vm.onCancel },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.cancel"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "simple-button",
                      {
                        attrs: { disabled: _vm.isSaveDisabled },
                        on: { onClick: _vm.onSave },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("toSentenceCase")(_vm.$t("actions.save"))
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    !_vm.isPromotionSupplierFundingView && _vm.isGridFillerVisible
      ? _c("div", { class: ["total-spend-viewer__header", "bold"] }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("supplierCommitments.filterNotSelected")
                )
              ) +
              "\n    "
          ),
        ])
      : !_vm.supplierCommitments.length
      ? _c("div", { class: ["total-spend-viewer__header", "bold"] }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("toSentenceCase")(
                  _vm.$t("supplierCommitments.noMatchingCommitments")
                )
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isPromotionSupplierFundingView
      ? _c("div", [
          _c(
            "div",
            { staticClass: "total-spend-viewer__bottom" },
            [
              _c("create-new-button", {
                staticClass: "total-spend-viewer__create-button",
                attrs: {
                  "btn-text": _vm._f("toSentenceCase")(
                    _vm.$tkey("supplierCommitments.buttons.newCommitment")
                  ),
                  disabled: _vm.isCreateButtonDisabled,
                },
                on: { createNew: _vm.onCreate },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "total-spend-viewer__historical-title" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("toSentenceCase")(
                    _vm.$t("supplierCommitments.historicalTitle")
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "total-spend-viewer total-spend-viewer__grid" },
            [
              _vm._l(
                _vm.filteredSupplierCommitmentsMap.historical.totalSpend,
                function (supplierCommitment, index) {
                  return [
                    _c("div", {
                      key: `filler::${supplierCommitment._id}`,
                      staticClass: "total-spend-viewer__filler",
                      class: {
                        "total-spend-viewer__filler":
                          !_vm.isPromotionSupplierFundingView,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "vuex-form-viewer",
                      {
                        key: supplierCommitment._id,
                        attrs: {
                          context: supplierCommitment,
                          fields: _vm.fields,
                          form: _vm.getForm(supplierCommitment),
                          "make-read-only":
                            _vm.selectedSupplierCommitmentId !=
                            supplierCommitment._id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onSelect({
                              id: supplierCommitment._id,
                              isCurrent: false,
                            })
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "total-spend-viewer__icons",
                            style: _vm.getStyle(index, 9),
                          },
                          [
                            _c("notes", {
                              attrs: {
                                notes: supplierCommitment.notes,
                                "open-left": "",
                              },
                              on: {
                                delete: _vm.removeNote,
                                post: _vm.postNote,
                                save: _vm.saveNote,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "total-spend-viewer__icons",
                            style: _vm.getStyle(index, 10),
                          },
                          [
                            _c("common-delete-dialog", {
                              attrs: {
                                resource: "supplierCommitments",
                                "resource-id": _vm.selectedSupplierCommitmentId,
                                "child-dependencies-warning": false,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "total-spend-viewer__icons",
                            style: _vm.getStyle(index, 11),
                          },
                          [
                            _c("main-expand-button", {
                              attrs: { "is-expanded": _vm.isExpanded },
                              on: {
                                expand: function ($event) {
                                  return _vm.onSelect({
                                    id: supplierCommitment._id,
                                  })
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.selectedSupplierCommitmentId ===
                        supplierCommitment._id
                          ? _c(
                              "div",
                              { staticClass: "total-spend-viewer__new-card" },
                              [
                                _c("funded-promotions-list-wrapper", {
                                  staticClass:
                                    "total-spend-viewer__funded-promotions-list-wrapper",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                }
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }