<template>
    <promotion-resources
        :key="selectedSubCampaign._id"
        v-bind="form"
        :edit-context="selectedSubCampaign"
    />
</template>

<script>
import { mapGetters } from 'vuex';

const form = {
    vuexModule: 'subCampaigns',
    editMode: true,
    editEvent: 'subCampaignUpdated',
    fields: [
        {
            fieldName: 'resourceDefinitions',
            editable: true,
        },
    ],
};

export default {
    data: () => ({
        form,
    }),
    computed: {
        ...mapGetters('subCampaigns', ['selectedSubCampaign']),
    },
};
</script>
