'use strict';

const { get, round, isNil, isNull, intersectionBy, map, cloneDeep } = require('lodash');
const rewardTypes = require('./data/enums/reward-types');

const { rewardDefinitions } = require('./reward-definitions');

/**
 * Calculate promo prices for the product using the given mechanic and params.
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.product - The product to calculate the prices on.
 * @param {String} RORO.mechanic - The mechanic being applied.
 * @param {Object} RORO.params - Params to use in the price calculation.
 */
function calculatePromoPrices({ productPrices, nonPromoPrices, mechanic, params }) {
    // Get mechanic being used
    let mechanicDefinition = rewardDefinitions[mechanic];

    // if the mechanic type is product then use the nested reward mechanic
    if (mechanic === rewardTypes.product) {
        mechanicDefinition = rewardDefinitions[params.type];
    }

    const paramPaths = mechanicDefinition.paramPaths;
    const optionalParams = mechanicDefinition.optionalParams;

    // Verify Params
    Object.keys(paramPaths).forEach(param => {
        if (isNil(get(params, param)) && !(optionalParams && optionalParams.includes(param))) {
            throw new Error(`Missing required parameter: ${param}`);
        }
    });
    const promoPrices = {
        minPrice: !isNull(productPrices.minPrice)
            ? mechanicDefinition.promoPrice(productPrices.minPrice, params)
            : null,
        maxPrice: !isNull(productPrices.maxPrice)
            ? mechanicDefinition.promoPrice(productPrices.maxPrice, params)
            : null,
        avgPrice: !isNull(productPrices.avgPrice)
            ? mechanicDefinition.promoPrice(productPrices.avgPrice, params)
            : null,
    };
    if (isNull(nonPromoPrices.avgPrice)) {
        promoPrices.avgPriceExcTax = null;
    } else {
        promoPrices.avgPriceExcTax =
            nonPromoPrices.avgPrice === 0
                ? 0
                : (nonPromoPrices.avgPriceExcTax / nonPromoPrices.avgPrice) * promoPrices.avgPrice;
    }
    return promoPrices;
}

/**
 * Rounds the promo prices to two decimal places for all products.
 * @param {Object[]} products - The products to round prices on.
 */
function roundProductPrices({ productPrices, precision = 2 }) {
    return {
        minPrice: !isNull(productPrices.minPrice) ? round(productPrices.minPrice, precision) : null,
        maxPrice: !isNull(productPrices.maxPrice) ? round(productPrices.maxPrice, precision) : null,
        avgPrice: !isNull(productPrices.avgPrice) ? round(productPrices.avgPrice, precision) : null,
        avgPriceExcTax: !isNull(productPrices.avgPriceExcTax)
            ? round(productPrices.avgPriceExcTax, precision)
            : null,
    };
}

/**
 * Generate a parameters object for use in calculating promo prices.
 * @param {Object} RORO - The RORO wrapper
 * @param {Object} RORO.product - The product to calculate the promo price for.
 * @param {Object} RORO.offerMechanic - The complete offerMechanic object.
 * @param {Object} RORO.reward - The specific reward being processed.
 * @param {Object} RORO.tier - The offer mechanic tier being processed.
 * @param {Object} RORO.pogIndex - The POG index for individual grouped OM.
 */
function generateParams({
    product,
    offerMechanic,
    reward,
    requirements,
    pogRequirements,
    tier,
    pogIndex,
}) {
    const params = {};
    const mechanicDefinition = rewardDefinitions[reward.type];

    // generate params
    const parameterPaths = mechanicDefinition.paramPaths;

    if (!parameterPaths) {
        throw new Error(`Unable to extract parameters for offerMechanic: ${reward.type}`);
    }

    const mechanicData = {
        product,
        offerMechanic: cloneDeep(offerMechanic),
        reward,
        requirements,
        pogRequirements,
        tier,
        pogIndex,
    };

    Object.entries(parameterPaths).forEach(([key, value]) => {
        const paramValue = get(mechanicData, value);
        if (
            isNil(paramValue) &&
            !(mechanicDefinition.optionalParams && mechanicDefinition.optionalParams.includes(key))
        ) {
            throw new Error(`Missing required parameter: ${value}`);
        }

        params[key] = paramValue;
    });

    return params;
}

/**
 * Calculates the promo prices for all products using the supplier offer mechanic.
 *
 * @param {Object} products - The products to apply the offer to.
 * @param {Object} offerMechanic - The complete offer mechanic object.
 */
const promoPriceCalculator = ({ offerMechanic, products, productOfferGroups, precision = 2 }) => {
    // Iterate over each product and tier applying the offer mechanic to each.
    // Only process products which have nonPromoPrices.
    return products
        .filter(product => product.nonPromoPrices)
        .map(product => {
            const existingPromoPrices = product.promoPrices || [];
            const initialProductPrices = {
                minPrice: product.nonPromoPrices.minPrice,
                maxPrice: product.nonPromoPrices.maxPrice,
                avgPrice: product.nonPromoPrices.avgPrice,
                avgPriceExcTax: product.nonPromoPrices.avgPriceExcTax,
            };

            // Store the non-promo prices as these are needed in the calculation.
            const nonPromoPrices = {
                ...initialProductPrices,
            };

            let promoPrices = [];

            const productsProductOfferGroups = productOfferGroups.filter(pog =>
                pog.products.includes(product.productKey)
            );

            promoPrices = cloneDeep(offerMechanic).tiers.map((tier, tierIndex) => {
                // Rewards consist of the global rewards and product offer group specific rewards.
                // Both sets need to be applied when calculating the promo price.
                // First, apply each global reward successively to the product price.
                const globalRewardPromoPrices = tier.globalRewards.reduce((acc, reward) => {
                    const params = generateParams({
                        product,
                        offerMechanic,
                        reward,
                        requirements: tier.globalRequirements,
                        pogRequirements: map(tier.productOfferGroups, 'requirements'),
                        tier,
                    });
                    const newPrices = calculatePromoPrices({
                        productPrices: acc,
                        nonPromoPrices,
                        mechanic: reward.type,
                        params,
                    });
                    return newPrices;
                }, initialProductPrices);

                // Now find the productOfferGroup for the product being processed.
                const tierPOGs = intersectionBy(
                    tier.productOfferGroups,
                    productsProductOfferGroups,
                    '_id'
                );

                let pogRewardPromoPrices = globalRewardPromoPrices;

                tierPOGs.forEach(pog => {
                    pogRewardPromoPrices = pog.rewards.reduce((acc, reward) => {
                        const params = generateParams({
                            product,
                            offerMechanic,
                            reward,
                            requirements: pog.requirements,
                            tier,
                        });
                        if (
                            rewardTypes.newPriceRewardTypes.includes(reward.type) &&
                            existingPromoPrices[tierIndex]
                        ) {
                            return existingPromoPrices[tierIndex];
                        }
                        const newPrices = calculatePromoPrices({
                            productPrices: acc,
                            nonPromoPrices,
                            mechanic: reward.type,
                            params,
                        });

                        return newPrices;
                    }, pogRewardPromoPrices);
                });

                return roundProductPrices({
                    productPrices: pogRewardPromoPrices,
                    precision,
                });
            });

            return {
                product,
                promoPrices,
            };
        });
};

module.exports = { promoPriceCalculator, roundProductPrices };
