<script>
import { mapActions, mapState } from 'vuex';
import { get, find, isNull, indexOf, filter } from 'lodash';
import filterEnums from '@enums/filters';

export default {
    computed: {
        ...mapState(['suppliersFilterDefinitions', 'suppliersFilters']),
        ...mapState('clientConfig', ['hierarchyConfig']),

        filters() {
            return this.suppliersFilters;
        },

        filterDefinitions() {
            return this.suppliersFilterDefinitions;
        },
    },

    methods: {
        ...mapActions([
            'resetSuppliersFilters',
            'removeSuppliersFilter',
            'addSuppliersFilter',
            'suppliersFilterKeySetter',
            'suppliersFilterValueSetter',
        ]),
        ...mapActions('suppliers', ['fetchSuppliersByCategories']),

        addFilter() {
            this.addSuppliersFilter();
        },

        filterKeySetter({ index, filterId, key }) {
            this.suppliersFilterKeySetter({ index, filterId, key });
        },

        async setFilterValue({ index, filterId, value }) {
            await this.suppliersFilterValueSetter({ index, filterId, value });
        },

        removeFilter(index) {
            const filterId = get(this.filters, [index, 'filterId']);
            if (this.filters.length === 1) {
                return this.resetFilters({ filterId });
            }
            return this.removeSuppliersFilter({ filterId, index });
        },

        removeSupplierFilter() {
            const suppliersFilter = find(this.filters, { filterKey: filterEnums.suppliers });
            if (suppliersFilter) {
                this.removeSuppliersFilter({
                    filterId: suppliersFilter.filterId,
                    index: indexOf(this.filters, suppliersFilter),
                });
            }
        },

        getFilterOptions(index) {
            const mainFilters = filter(this.filterDefinitions, { isMain: true });

            if (mainFilters && index < mainFilters.length) {
                return this.getOptions(index, mainFilters);
            }

            return this.getOptions(index, this.filterDefinitions);
        },

        getOptions(index, filterDefinitions) {
            const filterKeyAtIndex = get(this.filters, [index, 'filterKey'], null);
            const options = filterDefinitions.filter(
                def => !this.filterKeysInUse.includes(def.filterKey)
            );

            if (!filterKeyAtIndex) {
                return options;
            }
            const currentValue = filterDefinitions.find(def => def.filterKey === filterKeyAtIndex);
            return [...options, currentValue];
        },

        isDeleteDisabled(index) {
            if (
                this.userCategories.length === 1 &&
                get(this.filters, [index, 'filterKey']) === filterEnums.categories
            ) {
                return true;
            }
            return index === 0 && !isNull(this.mainFilter) && this.filters.length > 1;
        },

        resetFilters({ filterId }) {
            this.resetSuppliersFilters({ filterId });
        },

        // override function to do nothing, as parent function from filter-bar
        // make changes that we don't need in suppliers tab
        filterChange() {},
    },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/base/_mixins.scss';

.filter-bar {
    @include promo-component-border($top: true);

    width: 100%;
    padding: 1rem;
    display: flex;
    background-color: $promo-table-blue-bg-colour-3;

    &__grouping {
        display: flex;
        flex: 1;
        align-items: baseline;

        & > * {
            margin-right: 1rem;
        }
    }

    &__parent-filter {
        margin-right: 1rem;
    }

    &__label {
        color: $filter-bar-text-colour;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 300;
    }

    &__main-select {
        flex: 0 1 20rem;
    }

    &__cancel-button {
        padding-top: 0.8rem;
        margin-left: 0.8rem;
    }

    &__add-button {
        line-height: 2.8rem;
    }

    .filter-group__filters::v-deep {
        .v-autocomplete .v-select__slot > input {
            padding-left: 0.5rem;
        }
    }
}
</style>
