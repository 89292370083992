import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.textBtn
    ? _c("icon-button", {
        attrs: {
          disabled: _vm.isDisabled,
          "btn-text": _vm.btnText,
          icon: "get_app",
        },
        on: { click: _vm.downloadResource },
      })
    : _vm.isSecondaryBtn
    ? _c(
        VBtn,
        {
          staticClass: "secondary-btn",
          attrs: {
            disabled: _vm.isDisabled,
            secondary: "",
            depressed: "",
            outlined: "",
          },
          on: { click: _vm.downloadResource },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm._f("toSentenceCase")(_vm.btnText)) + "\n    "
          ),
          _c("icon", {
            attrs: { "icon-name": "download", right: "", small: "" },
          }),
        ],
        1
      )
    : _c(
        VBtn,
        {
          staticClass: "primary-btn",
          attrs: { primary: "", depressed: "", outlined: "" },
          on: { click: _vm.downloadResource },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm._f("toSentenceCase")(_vm.btnText)) + "\n    "
          ),
          _c("icon", {
            attrs: { "icon-name": "download", right: "", small: "" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }